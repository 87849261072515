unit uAssetStatusEdit;

interface

uses
  System.SysUtils, System.Classes,
  JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLIB.REST, WEBLib.JSON,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel;

type
  TAssetStatusEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    AssetStatusNameEdit: TEdit;
    AssetStatusNameLabel: TLabel;
    StatusBarPanel: TPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    ActionLabel: TLabel;
    ActionMailCheckBox: TCheckBox;
    OwnerCheckBox: TCheckBox;
    TenantCheckBox: TCheckBox;
    TeamLeaderCheckBox: TCheckBox;
    DriverCheckBox: TCheckBox;
    OwnerDepartmentEdit: TEdit;
    TenantDepartmentEdit: TEdit;
    OwnerDepartmentButton: TSpeedButton;
    TenantDepartmentButton: TSpeedButton;
    StatusTextMemo: TMemo;
    WebLabel1: TLabel;
    MyMessageDlg: TMessageDlg;
    WebLabel2: TLabel;
    ColorPanel: TTMSFNCPanel;
    procedure WebFormCreate(Sender: TObject);
    procedure RecordChange(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure DepartmentButtonClick(Sender: TObject); async;
    [async]
    procedure ColorPanelClick(Sender: TObject); async;
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    function ValidInput(): Boolean; async;
    [async]
    function SaveChanges(): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    IsSet: Boolean;
    OwnerDepartmentPKey: String;
    TenantDepartmentPKey: String;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  AssetStatusEditForm: TAssetStatusEditForm;

implementation

{$R *.dfm}

uses uCommon, uDepartmentList, uColorPicker;

procedure TAssetStatusEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Visible := False;
end;

procedure TAssetStatusEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TAssetStatusEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetStatusEditForm.ColorPanelClick(Sender: TObject);
var
  MyWebForm: TColorPickerForm;
  MyModalResult: TModalResult;
begin
  MyWebForm := TColorPickerForm.Create(Self);
  try
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TColorPickerForm, MyWebForm.Load());
    MyWebForm.SetColor(ColorToHex(ColorPanel.Color));
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      ColorPanel.Color := HexToColor(MyWebForm.HexValue);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetStatusEditForm.DepartmentButtonClick(Sender: TObject);
var
  DepartmentList: TDepartmentListForm;
  MyModalResult: TModalResult;
begin
  DepartmentList := TDepartmentListForm.Create(Self);
  DepartmentList.Popup := True;
  DepartmentList.Border := fbNone;
  await(TDepartmentListForm, DepartmentList.Load());
  try
    case TSpeedButton(Sender).Tag of
      1: DepartmentList.PKeyList.DelimitedText := OwnerDepartmentPKey;
      2: DepartmentList.PKeyList.DelimitedText := TenantDepartmentPKey;
    end;
    DepartmentList.IsSet := True;
    // execute form and wait for close
    MyModalResult := await(TModalResult, DepartmentList.Execute);
    if MyModalResult = mrOK then begin
      case TSpeedButton(Sender).Tag of
        1: begin
          OwnerDepartmentPKey := DepartmentList.PKeyList.DelimitedText;
          OwnerDepartmentEdit.Text := DepartmentList.Department;
        end;
        2: begin
          TenantDepartmentPKey := DepartmentList.PKeyList.DelimitedText;
          TenantDepartmentEdit.Text := DepartmentList.Department;
        end;
      end;
      RecordChange(TObject(Sender));
    end;
  finally
    DepartmentList.Free;
  end;
end;

procedure TAssetStatusEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
begin
  Result := await(ValidInput());
  if Result then begin
    SaveChanges();
  end;
end;

function TAssetStatusEditForm.ValidInput(): Boolean;
begin
  Result := True;
end;

procedure TAssetStatusEditForm.WebFormCreate(Sender: TObject);
begin
  IsSet := False;
  OwnerDepartmentPKey := EmptyStr;
  TenantDepartmentPKey := EmptyStr;
end;

procedure TAssetStatusEditForm.WebFormShow(Sender: TObject);
begin
  AssetStatusNameEdit.SetFocus;
  RecordChanged := False;
end;

function TAssetStatusEditForm.SaveChanges(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  MyString: String;
begin
  MyString :=
    '{' +
    '"sessionid":"' + SessionID + '",' +
    '"pkey":"' + IntToStr(PKey) + '", ' +
    '"name":"' + AssetStatusNameEdit.Text + '",' +
    '"email":{' +
      '"checkbox":"' + BooleanToString(ActionMailCheckBox.Checked) + '",' +
      '"owner":{' +
        '"checkbox":"' + BooleanToString(OwnerCheckBox.Checked) + '",' +
        '"department":"' + OwnerDepartmentPKey + '"' +
      '},' +
      '"tenant":{' +
        '"checkbox":"' + BooleanToString(TenantCheckBox.Checked) + '",' +
        '"department":"' + TenantDepartmentPKey + '"' +
      '},' +
      '"teamleader":{' +
        '"checkbox":"' + BooleanToString(TeamLeaderCheckBox.Checked) + '"' +
      '},' +
      '"driver":{' +
        '"checkbox":"' + BooleanToString(DriverCheckBox.Checked) + '"' +
      '}' +
    '},' +
    '"text":"' + Window.btoa(StatusTextMemo.Text) + '",' +
    '"hexcolor":"' + ColorToHex(ColorPanel.Color) + '"' +
  '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_status_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      PKey := StrToInt(MyJO.GetJSONValue('pkey'));
      Result := PKey >= 0;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;

  ModalResult := mrOK;
end;


procedure TAssetStatusEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  AssetStatusNameLabel := TLabel.Create(Self);
  ActionLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  AssetStatusNameEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  ActionMailCheckBox := TCheckBox.Create(Self);
  OwnerCheckBox := TCheckBox.Create(Self);
  TenantCheckBox := TCheckBox.Create(Self);
  TeamLeaderCheckBox := TCheckBox.Create(Self);
  DriverCheckBox := TCheckBox.Create(Self);
  OwnerDepartmentEdit := TEdit.Create(Self);
  TenantDepartmentEdit := TEdit.Create(Self);
  OwnerDepartmentButton := TSpeedButton.Create(Self);
  TenantDepartmentButton := TSpeedButton.Create(Self);
  StatusTextMemo := TMemo.Create(Self);
  ColorPanel := TTMSFNCPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  AssetStatusNameLabel.BeforeLoadDFMValues;
  ActionLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  AssetStatusNameEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ActionMailCheckBox.BeforeLoadDFMValues;
  OwnerCheckBox.BeforeLoadDFMValues;
  TenantCheckBox.BeforeLoadDFMValues;
  TeamLeaderCheckBox.BeforeLoadDFMValues;
  DriverCheckBox.BeforeLoadDFMValues;
  OwnerDepartmentEdit.BeforeLoadDFMValues;
  TenantDepartmentEdit.BeforeLoadDFMValues;
  OwnerDepartmentButton.BeforeLoadDFMValues;
  TenantDepartmentButton.BeforeLoadDFMValues;
  StatusTextMemo.BeforeLoadDFMValues;
  ColorPanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  try
    Name := 'AssetStatusEditForm';
    Width := 835;
    Height := 688;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 835;
    FormPanel.Height := 688;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 829;
    ContentPanel.Height := 645;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    AssetStatusNameLabel.SetParentComponent(ContentPanel);
    AssetStatusNameLabel.Name := 'AssetStatusNameLabel';
    AssetStatusNameLabel.Left := 20;
    AssetStatusNameLabel.Top := 15;
    AssetStatusNameLabel.Width := 42;
    AssetStatusNameLabel.Height := 21;
    AssetStatusNameLabel.Caption := 'Name';
    AssetStatusNameLabel.Font.Charset := ANSI_CHARSET;
    AssetStatusNameLabel.Font.Color := 9010544;
    AssetStatusNameLabel.Font.Height := -16;
    AssetStatusNameLabel.Font.Name := 'Segoe UI';
    AssetStatusNameLabel.Font.Style := [];
    AssetStatusNameLabel.HeightPercent := 100.000000000000000000;
    AssetStatusNameLabel.ParentFont := False;
    AssetStatusNameLabel.WidthPercent := 100.000000000000000000;
    ActionLabel.SetParentComponent(ContentPanel);
    ActionLabel.Name := 'ActionLabel';
    ActionLabel.Left := 20;
    ActionLabel.Top := 143;
    ActionLabel.Width := 88;
    ActionLabel.Height := 21;
    ActionLabel.Caption := 'Notifications';
    ActionLabel.Font.Charset := ANSI_CHARSET;
    ActionLabel.Font.Color := 9010544;
    ActionLabel.Font.Height := -16;
    ActionLabel.Font.Name := 'Segoe UI';
    ActionLabel.Font.Style := [];
    ActionLabel.HeightPercent := 100.000000000000000000;
    ActionLabel.ParentFont := False;
    ActionLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 399;
    WebLabel1.Width := 132;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Default notification';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 20;
    WebLabel2.Top := 103;
    WebLabel2.Width := 38;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Color';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    AssetStatusNameEdit.SetParentComponent(ContentPanel);
    AssetStatusNameEdit.Name := 'AssetStatusNameEdit';
    AssetStatusNameEdit.Left := 20;
    AssetStatusNameEdit.Top := 50;
    AssetStatusNameEdit.Width := 748;
    AssetStatusNameEdit.Height := 34;
    AssetStatusNameEdit.AutoFocus := True;
    AssetStatusNameEdit.ChildOrder := 1;
    AssetStatusNameEdit.Font.Charset := ANSI_CHARSET;
    AssetStatusNameEdit.Font.Color := clWindowText;
    AssetStatusNameEdit.Font.Height := -16;
    AssetStatusNameEdit.Font.Name := 'Segoe UI';
    AssetStatusNameEdit.Font.Style := [];
    AssetStatusNameEdit.HeightPercent := 100.000000000000000000;
    AssetStatusNameEdit.MaxLength := 20;
    AssetStatusNameEdit.ParentFont := False;
    AssetStatusNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusNameEdit, Self, 'OnChange', 'RecordChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 596;
    StatusBarPanel.Width := 829;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 3;
    StatusBarPanel.Color := clWhite;
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 20;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alLeft;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetStatusEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 709;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetStatusEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    ActionMailCheckBox.SetParentComponent(ContentPanel);
    ActionMailCheckBox.Name := 'ActionMailCheckBox';
    ActionMailCheckBox.Left := 20;
    ActionMailCheckBox.Top := 170;
    ActionMailCheckBox.Width := 74;
    ActionMailCheckBox.Height := 22;
    ActionMailCheckBox.Caption := 'E-Mail';
    ActionMailCheckBox.ChildOrder := 4;
    ActionMailCheckBox.HeightPercent := 100.000000000000000000;
    ActionMailCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(ActionMailCheckBox, Self, 'OnClick', 'RecordChange');
    OwnerCheckBox.SetParentComponent(ContentPanel);
    OwnerCheckBox.Name := 'OwnerCheckBox';
    OwnerCheckBox.Left := 46;
    OwnerCheckBox.Top := 214;
    OwnerCheckBox.Width := 74;
    OwnerCheckBox.Height := 22;
    OwnerCheckBox.Caption := 'Owner';
    OwnerCheckBox.ChildOrder := 4;
    OwnerCheckBox.HeightPercent := 100.000000000000000000;
    OwnerCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(OwnerCheckBox, Self, 'OnClick', 'RecordChange');
    TenantCheckBox.SetParentComponent(ContentPanel);
    TenantCheckBox.Name := 'TenantCheckBox';
    TenantCheckBox.Left := 46;
    TenantCheckBox.Top := 262;
    TenantCheckBox.Width := 74;
    TenantCheckBox.Height := 22;
    TenantCheckBox.Caption := 'Tenant';
    TenantCheckBox.ChildOrder := 4;
    TenantCheckBox.HeightPercent := 100.000000000000000000;
    TenantCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(TenantCheckBox, Self, 'OnClick', 'RecordChange');
    TeamLeaderCheckBox.SetParentComponent(ContentPanel);
    TeamLeaderCheckBox.Name := 'TeamLeaderCheckBox';
    TeamLeaderCheckBox.Left := 46;
    TeamLeaderCheckBox.Top := 306;
    TeamLeaderCheckBox.Width := 107;
    TeamLeaderCheckBox.Height := 22;
    TeamLeaderCheckBox.Caption := 'Teamleader';
    TeamLeaderCheckBox.ChildOrder := 4;
    TeamLeaderCheckBox.HeightPercent := 100.000000000000000000;
    TeamLeaderCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(TeamLeaderCheckBox, Self, 'OnClick', 'RecordChange');
    DriverCheckBox.SetParentComponent(ContentPanel);
    DriverCheckBox.Name := 'DriverCheckBox';
    DriverCheckBox.Left := 46;
    DriverCheckBox.Top := 350;
    DriverCheckBox.Width := 107;
    DriverCheckBox.Height := 22;
    DriverCheckBox.Caption := 'Driver';
    DriverCheckBox.ChildOrder := 4;
    DriverCheckBox.HeightPercent := 100.000000000000000000;
    DriverCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(DriverCheckBox, Self, 'OnClick', 'RecordChange');
    OwnerDepartmentEdit.SetParentComponent(ContentPanel);
    OwnerDepartmentEdit.Name := 'OwnerDepartmentEdit';
    OwnerDepartmentEdit.Left := 174;
    OwnerDepartmentEdit.Top := 210;
    OwnerDepartmentEdit.Width := 594;
    OwnerDepartmentEdit.Height := 34;
    OwnerDepartmentEdit.AutoFocus := True;
    OwnerDepartmentEdit.ChildOrder := 1;
    OwnerDepartmentEdit.Font.Charset := ANSI_CHARSET;
    OwnerDepartmentEdit.Font.Color := clWindowText;
    OwnerDepartmentEdit.Font.Height := -16;
    OwnerDepartmentEdit.Font.Name := 'Segoe UI';
    OwnerDepartmentEdit.Font.Style := [];
    OwnerDepartmentEdit.HeightPercent := 100.000000000000000000;
    OwnerDepartmentEdit.ParentFont := False;
    OwnerDepartmentEdit.ReadOnly := True;
    OwnerDepartmentEdit.WidthPercent := 100.000000000000000000;
    TenantDepartmentEdit.SetParentComponent(ContentPanel);
    TenantDepartmentEdit.Name := 'TenantDepartmentEdit';
    TenantDepartmentEdit.Left := 174;
    TenantDepartmentEdit.Top := 258;
    TenantDepartmentEdit.Width := 594;
    TenantDepartmentEdit.Height := 34;
    TenantDepartmentEdit.AutoFocus := True;
    TenantDepartmentEdit.ChildOrder := 1;
    TenantDepartmentEdit.Font.Charset := ANSI_CHARSET;
    TenantDepartmentEdit.Font.Color := clWindowText;
    TenantDepartmentEdit.Font.Height := -16;
    TenantDepartmentEdit.Font.Name := 'Segoe UI';
    TenantDepartmentEdit.Font.Style := [];
    TenantDepartmentEdit.HeightPercent := 100.000000000000000000;
    TenantDepartmentEdit.ParentFont := False;
    TenantDepartmentEdit.ReadOnly := True;
    TenantDepartmentEdit.WidthPercent := 100.000000000000000000;
    OwnerDepartmentButton.SetParentComponent(ContentPanel);
    OwnerDepartmentButton.Name := 'OwnerDepartmentButton';
    OwnerDepartmentButton.Tag := 1;
    OwnerDepartmentButton.AlignWithMargins := True;
    OwnerDepartmentButton.Left := 774;
    OwnerDepartmentButton.Top := 210;
    OwnerDepartmentButton.Width := 34;
    OwnerDepartmentButton.Height := 34;
    OwnerDepartmentButton.Margins.Top := 8;
    OwnerDepartmentButton.Margins.Right := 5;
    OwnerDepartmentButton.Margins.Bottom := 10;
    OwnerDepartmentButton.Color := clNone;
    OwnerDepartmentButton.ElementClassName := 'MyWebButton';
    OwnerDepartmentButton.Glyph.LoadFromFile('uAssetStatusEdit.ContentPanel.OwnerDepartmentButton.Glyph.png');
    OwnerDepartmentButton.HeightPercent := 100.000000000000000000;
    OwnerDepartmentButton.TabOrder := 9;
    OwnerDepartmentButton.WidthPercent := 100.000000000000000000;
    SetEvent(OwnerDepartmentButton, Self, 'OnClick', 'DepartmentButtonClick');
    TenantDepartmentButton.SetParentComponent(ContentPanel);
    TenantDepartmentButton.Name := 'TenantDepartmentButton';
    TenantDepartmentButton.Tag := 2;
    TenantDepartmentButton.AlignWithMargins := True;
    TenantDepartmentButton.Left := 774;
    TenantDepartmentButton.Top := 258;
    TenantDepartmentButton.Width := 34;
    TenantDepartmentButton.Height := 34;
    TenantDepartmentButton.Margins.Top := 8;
    TenantDepartmentButton.Margins.Right := 5;
    TenantDepartmentButton.Margins.Bottom := 10;
    TenantDepartmentButton.Color := clNone;
    TenantDepartmentButton.ElementClassName := 'MyWebButton';
    TenantDepartmentButton.Glyph.LoadFromFile('uAssetStatusEdit.ContentPanel.TenantDepartmentButton.Glyph.png');
    TenantDepartmentButton.HeightPercent := 100.000000000000000000;
    TenantDepartmentButton.TabOrder := 10;
    TenantDepartmentButton.WidthPercent := 100.000000000000000000;
    SetEvent(TenantDepartmentButton, Self, 'OnClick', 'DepartmentButtonClick');
    StatusTextMemo.SetParentComponent(ContentPanel);
    StatusTextMemo.Name := 'StatusTextMemo';
    StatusTextMemo.Left := 20;
    StatusTextMemo.Top := 434;
    StatusTextMemo.Width := 788;
    StatusTextMemo.Height := 143;
    StatusTextMemo.AutoSize := False;
    StatusTextMemo.HeightPercent := 100.000000000000000000;
    StatusTextMemo.SelLength := 0;
    StatusTextMemo.SelStart := 0;
    StatusTextMemo.WidthPercent := 100.000000000000000000;
    SetEvent(StatusTextMemo, Self, 'OnChange', 'RecordChange');
    ColorPanel.SetParentComponent(ContentPanel);
    ColorPanel.Name := 'ColorPanel';
    ColorPanel.Tag := 1;
    ColorPanel.Left := 174;
    ColorPanel.Top := 99;
    ColorPanel.Width := 168;
    ColorPanel.Height := 35;
    ColorPanel.BevelInner := bvNone;
    ColorPanel.BevelOuter := bvNone;
    ColorPanel.Ctl3D := True;
    ColorPanel.ParentCtl3D := False;
    ColorPanel.ParentDoubleBuffered := False;
    ColorPanel.DoubleBuffered := True;
    ColorPanel.TabOrder := 12;
    SetEvent(ColorPanel, Self, 'OnClick', 'ColorPanelClick');
    ColorPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ColorPanel.SectionsAppearance.Font.Color := clWindowText;
    ColorPanel.SectionsAppearance.Font.Height := -11;
    ColorPanel.SectionsAppearance.Font.Name := 'Tahoma';
    ColorPanel.SectionsAppearance.Font.Style := [];
    ColorPanel.Stroke.Width := 2.000000000000000000;
    ColorPanel.Header.Text := 'Header';
    ColorPanel.Header.Font.Charset := DEFAULT_CHARSET;
    ColorPanel.Header.Font.Color := clWindowText;
    ColorPanel.Header.Font.Height := -11;
    ColorPanel.Header.Font.Name := 'Tahoma';
    ColorPanel.Header.Font.Style := [];
    ColorPanel.Header.Visible := False;
    ColorPanel.Header.Fill.Color := 16380654;
    ColorPanel.Header.Stroke.Kind := gskNone;
    ColorPanel.Footer.Text := 'Footer';
    ColorPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ColorPanel.Footer.Font.Color := clWindowText;
    ColorPanel.Footer.Font.Height := -11;
    ColorPanel.Footer.Font.Name := 'Tahoma';
    ColorPanel.Footer.Font.Style := [];
    ColorPanel.Footer.Fill.Color := 16380654;
    ColorPanel.GlobalFont.Scale := 1.000000000000000000;
    ColorPanel.GlobalFont.Style := [];
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 835;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 86;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Status';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 806;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetStatusEdit.CaptionPanel.CloseImage.Picture.png');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 222;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    AssetStatusNameLabel.AfterLoadDFMValues;
    ActionLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    AssetStatusNameEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ActionMailCheckBox.AfterLoadDFMValues;
    OwnerCheckBox.AfterLoadDFMValues;
    TenantCheckBox.AfterLoadDFMValues;
    TeamLeaderCheckBox.AfterLoadDFMValues;
    DriverCheckBox.AfterLoadDFMValues;
    OwnerDepartmentEdit.AfterLoadDFMValues;
    TenantDepartmentEdit.AfterLoadDFMValues;
    OwnerDepartmentButton.AfterLoadDFMValues;
    TenantDepartmentButton.AfterLoadDFMValues;
    StatusTextMemo.AfterLoadDFMValues;
    ColorPanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
  end;
end;

end.
program instatrack;

uses
  Vcl.Forms,
  Web,
  WEBLib.WebTools,
  WEBLib.Forms,
  uCommon in 'uCommon.pas',
  uMain in 'uMain.pas'{*.html},
  uRealTimeMap in 'uRealTimeMap.pas'{*.html},
  uLogin in 'uLogin.pas'{*.html},
  uAlertList in 'uAlertList.pas'{*.html},
  uCompanyEdit in 'uCompanyEdit.pas'{*.html},
  uTypeFilterList in 'uTypeFilterList.pas'{*.html},
  uDepartmentList in 'uDepartmentList.pas'{*.html},
  uHistoryMap in 'uHistoryMap.pas'{*.html},
  uReportPrint in 'uReportPrint.pas'{*.html},
  uDeviceEdit in 'uDeviceEdit.pas'{*.html},
  uGeneralSettings in 'uGeneralSettings.pas'{*.html},
  uComponent in 'uComponent.pas'{*.html},
  uAssetStatusEdit in 'uAssetStatusEdit.pas',
  uDepartmentEdit in 'uDepartmentEdit.pas',
  uSecurityGroupEdit in 'uSecurityGroupEdit.pas',
  uReportFilter in 'uReportFilter.pas'{*.html},
  uReportEdit in 'uReportEdit.pas'{*.html},
  uTeamFilterList in 'uTeamFilterList.pas',
  uHistoryMapPrint in 'uHistoryMapPrint.pas',
  uPersonalSettings in 'uPersonalSettings.pas'{*.html},
  uSecurityGroupList in 'uSecurityGroupList.pas'{*.html},
  uUserEdit in 'uUserEdit.pas'{*.html},
  uManagerList in 'uManagerList.pas',
  uTypeFilterEdit in 'uTypeFilterEdit.pas',
  uReportList in 'uReportList.pas',
  uAssetList in 'uAssetList.pas',
  uGeofenceList in 'uGeofenceList.pas',
  uAssetRepairTicketList in 'uAssetRepairTicketList.pas',
  uAssetEdit in 'uAssetEdit.pas',
  uDeviceReasonDeleteEdit in 'uDeviceReasonDeleteEdit.pas',
  uAssetTypeEdit in 'uAssetTypeEdit.pas',
  uDeviceList in 'uDeviceList.pas',
  uPOIGroupEdit in 'uPOIGroupEdit.pas',
  uDeviceReasonDeleteSelect in 'uDeviceReasonDeleteSelect.pas',
  uAssetGroupEdit in 'uAssetGroupEdit.pas',
  uAlertEdit in 'uAlertEdit.pas',
  uModelEdit in 'uModelEdit.pas',
  uAssetTypePicture in 'uAssetTypePicture.pas',
  uPOIGroupList in 'uPOIGroupList.pas',
  uGeofenceEdit in 'uGeofenceEdit.pas',
  uAssetDocumentEdit in 'uAssetDocumentEdit.pas',
  uAssetStatusList in 'uAssetStatusList.pas',
  uAssetRepairTextEdit in 'uAssetRepairTextEdit.pas',
  uPOIGroupSelect in 'uPOIGroupSelect.pas',
  uAssetGroupList in 'uAssetGroupList.pas',
  uUserList in 'uUserList.pas',
  uTeamFilterEdit in 'uTeamFilterEdit.pas',
  uUserTypeFilterEdit in 'uUserTypeFilterEdit.pas',
  uBrandList in 'uBrandList.pas',
  uPOIImport in 'uPOIImport.pas',
  uDeviceModelEdit in 'uDeviceModelEdit.pas',
  uDeviceHistory in 'uDeviceHistory.pas',
  uDeviceHistoryMap in 'uDeviceHistoryMap.pas',
  uUserTeamFilterEdit in 'uUserTeamFilterEdit.pas',
  uColorPicker in 'uColorPicker.pas',
  uAssetModelInstructionEdit in 'uAssetModelInstructionEdit.pas',
  uAssetModelDocumentEdit in 'uAssetModelDocumentEdit.pas',
  uAssetMOTEdit in 'uAssetMOTEdit.pas',
  uPOIList in 'uPOIList.pas',
  uPOIEdit in 'uPOIEdit.pas',
  uSMS in 'uSMS.pas',
  uAssetRepairTicketView in 'uAssetRepairTicketView.pas'{*.html},
  uModelList in 'uModelList.pas',
  uAssetStatusChange in 'uAssetStatusChange.pas',
  uAssetStatusChangeList in 'uAssetStatusChangeList.pas',
  uAssetStatusChangeView in 'uAssetStatusChangeView.pas',
  uBrandEdit in 'uBrandEdit.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;

  // Define Base URL
  url_www := window.location.href;
  url_php := url_www + 'php/';
  url_rpt := url_php + 'download_report_pdf.php';
  url_img := url_www + 'images/';
  url_kml := url_www + 'kml/';

  // Debug Mode
  // DebugMode := HasQueryParam('debugmode', DebugValue);

  // Defaults
  MarkerVisible := True;
  Application.CreateForm(TMainForm, MainForm);
  Application.Run;
end.

unit uTeamFilterList;

interface

uses
  uTeamFilterEdit,
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg;

type
  TTeamFilterListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    WebImageControl1: TImageControl;
    FilterDataset: TClientDataSet;
    FilterDatasetfilter_pk: TStringField;
    FilterDatasetfilter_naam: TStringField;
    FilterDatasetfilter_publiek: TStringField;
    FilterDatasetfilter_standaard: TStringField;
    FilterSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    FilterGrid: TTMSFNCGrid;
    FilterTimer: TTimer;
    ToolbarPanel: TPanel;
    FilterEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebMessageDlg1: TMessageDlg;
    DefaultButton: TSpeedButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebImageControl1Click(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure EditButtonClick(Sender: TObject); async;
    procedure FilterTimerTimer(Sender: TObject);
    procedure FilterEditChange(Sender: TObject);
    procedure FilterDatasetFilterRecord(DataSet: TDataSet; var Accept: Boolean);
    procedure SearchTimerTimer(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure DefaultButtonClick(Sender: TObject);
    procedure FilterGridDblClick(Sender: TObject);
    procedure FilterDatasetAfterScroll(DataSet: TDataSet);
  private
    { Private declarations }
    FilterPKey: Integer;
    [async]
    procedure LoadFilterList(SearchValue: String); async;
    [async]
    procedure ShowTeamFilterEditDialog(PKey: Integer); async;
    [async]
    procedure SetDefaultFilter(PKey: Integer); async;
    [async]
    procedure DeleteFilter(PKey: Integer); async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  TeamFilterListForm: TTeamFilterListForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TTeamFilterListForm.WebFormCreate(Sender: TObject);
begin
  FilterPKey := -1;
  SetGridStyle(FilterGrid, False);
end;

procedure TTeamFilterListForm.WebFormShow(Sender: TObject);
begin
  FilterEdit.SetFocus();
  LoadFilterList(EmptyStr);
end;

procedure TTeamFilterListForm.WebImageControl1Click(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TTeamFilterListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowTeamFilterEditDialog(-1));
end;

procedure TTeamFilterListForm.EditButtonClick(Sender: TObject);
begin
  if FilterDataset.Active and not(FilterDataset.IsEmpty) then begin
    await(ShowTeamFilterEditDialog(FilterDataset.FieldByName('filter_pk').AsInteger));
  end;
end;

procedure TTeamFilterListForm.FilterDatasetAfterScroll(DataSet: TDataSet);
begin
  if not(Dataset.ControlsDisabled) then begin
    if WebWaitMessage.Showing then begin
      FilterTimer.Enabled := True;
    end;
  end;
end;

procedure TTeamFilterListForm.FilterDatasetFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  Len: Integer;
  S1, S2: String;
begin
  Len := Length(FilterEdit.Text);
  S1 := Trim(UpperCase(FilterEdit.Text));
  S2 := Copy(Trim(UpperCase(Dataset.FieldByName('filter_naam').AsString)), 1, Len);
  Accept := (S1 = S2);
end;

procedure TTeamFilterListForm.FilterEditChange(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TTeamFilterListForm.FilterGridDblClick(Sender: TObject);
begin
  EditButton.Click();
end;

procedure TTeamFilterListForm.FilterTimerTimer(Sender: TObject);
begin
  FilterTimer.Enabled := False;
  FilterGrid.UpdateGridCells;
  WebWaitMessage.Hide;
end;

procedure TTeamFilterListForm.LoadFilterList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_team_filter_list_v2.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  FilterDataset.DisableControls;
  try
    // Load Data
    FilterDataset.Close;
    FilterDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
    await(FilterDataset.Open);
    // Locate Record
    if (FilterPKey = -1) then begin
      FilterDataset.First;
    end else begin
      FilterDataset.Locate('filter_pk', FilterPKey, []);
    end;
  finally
    FilterDataset.EnableControls;
    FilterDatasetAfterScroll(FilterDataset);
  end;
end;

procedure TTeamFilterListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadFilterList(FilterEdit.Text);
  end;
end;

procedure TTeamFilterListForm.ShowTeamFilterEditDialog(PKey: Integer);
var
  MyModalResult: TModalResult;
begin
  if
    FilterDataset.Active
  and
    (
      (PKey = -1)
    or
      ((PKey > 0) and not(FilterDataset.IsEmpty))
    )
  then begin
    TeamFilterEditForm := TTeamFilterEditForm.Create(Self);
    TeamFilterEditForm.Popup := True;
    TeamFilterEditForm.Border := fbNone;
    TeamFilterEditForm.TeamFilterInt := PKey;
    await(TTeamFilterEditForm, TeamFilterEditForm.Load());
    try
      TeamFilterEditForm.TeamFilterInt := PKey;
//    TeamFilterEditForm.FilterPublicCheckBox.Enabled := (PKey = -1);
      if PKey > 0 then begin
        TeamFilterEditForm.TeamFilterName :=
          Trim(FilterDataset.FieldByName('filter_naam').AsString);
        TeamFilterEditForm.TeamFilterPublic :=
          FilterDataset.FieldByName('filter_publiek').AsInteger = 1;
        TeamFilterEditForm.TeamFilterDefault :=
          FilterDataset.FieldByName('filter_standaard').AsInteger = 1;
      end;
      TeamFilterEditForm.TeamFilterSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, TeamFilterEditForm.Execute);
      if MyModalResult = mrOK then begin
        FilterPKey := PKey;
        LoadFilterList(FilterEdit.Text);
      end;
    finally
      TeamFilterEditForm.Free;
    end;
  end;
end;

procedure TTeamFilterListForm.DefaultButtonClick(Sender: TObject);
begin
  if FilterDataSet.Active and not(FilterDataSet.IsEmpty) then begin
    SetDefaultFilter(FilterDataset.FieldByName('filter_pk').AsInteger);
  end;
end;

procedure TTeamFilterListForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if FilterDataSet.Active and not(FilterDataSet.IsEmpty) then begin
    if FilterDataSet.FieldByName('filter_pk').AsInteger > 0 then begin
      MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog('Delete asset group filter?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        DeleteFilter(FilterDataset.FieldByName('filter_pk').AsInteger);
      end;
    end;
  end;
end;

procedure TTeamFilterListForm.SetDefaultFilter(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  ErrorStatus, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_team_filter_set.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      // Response
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        ErrorStatus := MyJSONObject.GetJSONValue('status');
        if ErrorStatus = 'OK' then begin
          FilterPKey := PKey;
          SearchTimer.Enabled := True;
        end else begin
          ErrorMessage := MyJSONObject.GetJSONValue('error_message');
          MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TTeamFilterListForm.DeleteFilter(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  ErrorStatus, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_team_filter_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      ErrorStatus := MyJO.GetJSONValue('error_status');
      if ErrorStatus = '1' then begin
        ErrorMessage := MyJO.GetJSONValue('error_message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end else begin
        SearchTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TTeamFilterListForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;


procedure TTeamFilterListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  FilterGrid := TTMSFNCGrid.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  FilterEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  DefaultButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  FilterDataset := TClientDataSet.Create(Self);
  FilterDatasetfilter_pk := TStringField.Create(Self);
  FilterDatasetfilter_naam := TStringField.Create(Self);
  FilterDatasetfilter_publiek := TStringField.Create(Self);
  FilterDatasetfilter_standaard := TStringField.Create(Self);
  FilterSource := TDataSource.Create(Self);
  FilterTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  FilterGrid.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  FilterEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  DefaultButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  FilterDataset.BeforeLoadDFMValues;
  FilterDatasetfilter_pk.BeforeLoadDFMValues;
  FilterDatasetfilter_naam.BeforeLoadDFMValues;
  FilterDatasetfilter_publiek.BeforeLoadDFMValues;
  FilterDatasetfilter_standaard.BeforeLoadDFMValues;
  FilterSource.BeforeLoadDFMValues;
  FilterTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  try
    Name := 'TeamFilterListForm';
    Width := 580;
    Height := 628;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 580;
    FormPanel.Height := 628;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 574;
    ContentPanel.Height := 585;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    FilterGrid.SetParentComponent(ContentPanel);
    FilterGrid.Name := 'FilterGrid';
    FilterGrid.AlignWithMargins := True;
    FilterGrid.Left := 20;
    FilterGrid.Top := 54;
    FilterGrid.Width := 534;
    FilterGrid.Height := 472;
    FilterGrid.Margins.Left := 20;
    FilterGrid.Margins.Top := 0;
    FilterGrid.Margins.Right := 20;
    FilterGrid.Margins.Bottom := 10;
    FilterGrid.AdaptToStyle := True;
    FilterGrid.Align := alClient;
    FilterGrid.BevelInner := bvNone;
    FilterGrid.BevelOuter := bvNone;
    FilterGrid.BevelWidth := 2;
    FilterGrid.Ctl3D := False;
    FilterGrid.ParentCtl3D := False;
    FilterGrid.ParentDoubleBuffered := False;
    FilterGrid.DoubleBuffered := True;
    FilterGrid.Font.Charset := DEFAULT_CHARSET;
    FilterGrid.Font.Color := clWindowText;
    FilterGrid.Font.Height := -11;
    FilterGrid.Font.Name := 'Tahoma';
    FilterGrid.Font.Style := [];
    FilterGrid.ParentFont := False;
    FilterGrid.TabOrder := 1;
    SetEvent(FilterGrid, Self, 'OnDblClick', 'FilterGridDblClick');
    FilterGrid.DefaultRowHeight := 34.000000000000000000;
    FilterGrid.FixedColumns := 0;
    FilterGrid.ColumnCount := 3;
    FilterGrid.RowCount := 1;
    FilterGrid.Options.Borders.FixedCellBorders := bNone;
    FilterGrid.Options.ColumnSize.Stretch := True;
    FilterGrid.Options.ColumnSize.StretchColumn := 0;
    FilterGrid.Options.Editing.CalcFormat := '%g';
    FilterGrid.Options.Editing.Enabled := False;
    FilterGrid.Options.Grouping.CalcFormat := '%g';
    FilterGrid.Options.Grouping.GroupCountFormat := '(%d)';
    FilterGrid.Options.HTMLExport.CellPadding := 10;
    FilterGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    FilterGrid.Options.Mouse.WheelScrollKeepSelection := True;
    FilterGrid.Options.Mouse.ClickMargin := 0;
    FilterGrid.Options.Mouse.ColumnSizeMargin := 6;
    FilterGrid.Options.Mouse.RowSizeMargin := 6;
    FilterGrid.Options.URL.Color := clSkyBlue;
    FilterGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    FilterGrid.Options.Selection.Mode := smSingleRow;
    FilterGrid.Adapter := GridDatabaseAdapter;
    FilterGrid.HorizontalScrollBarVisible := False;
    FilterGrid.Columns.Clear;
    with FilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Name := 'Default';
      ReadOnly := True;
      Width := 422.000000000000000000;
    end;
    with FilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'Name';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with FilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'Public';
      ReadOnly := True;
      Width := 111.000000000000000000;
    end;
    FilterGrid.Fill.ColorTo := clSilver;
    FilterGrid.DefaultFont.Charset := ANSI_CHARSET;
    FilterGrid.DefaultFont.Color := clWindowText;
    FilterGrid.DefaultFont.Height := -15;
    FilterGrid.DefaultFont.Name := 'Segoe UI';
    FilterGrid.DefaultFont.Style := [];
    FilterGrid.Stroke.Color := 15987699;
    FilterGrid.Stroke.Width := 2.000000000000000000;
    FilterGrid.TopRow := 1;
    FilterGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    FilterGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    FilterGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.FixedLayout.Font.Color := clWhite;
    FilterGrid.Appearance.FixedLayout.Font.Height := -16;
    FilterGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.FixedLayout.Font.Style := [];
    FilterGrid.Appearance.NormalLayout.Fill.Color := -1;
    FilterGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    FilterGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.NormalLayout.Font.Color := clGray;
    FilterGrid.Appearance.NormalLayout.Font.Height := -11;
    FilterGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.NormalLayout.Font.Style := [];
    FilterGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    FilterGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    FilterGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    FilterGrid.Appearance.GroupLayout.Font.Color := clBlack;
    FilterGrid.Appearance.GroupLayout.Font.Height := -11;
    FilterGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    FilterGrid.Appearance.GroupLayout.Font.Style := [];
    FilterGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    FilterGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    FilterGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    FilterGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    FilterGrid.Appearance.SummaryLayout.Font.Height := -11;
    FilterGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    FilterGrid.Appearance.SummaryLayout.Font.Style := [];
    FilterGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    FilterGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    FilterGrid.Appearance.SelectedLayout.Font.Height := -16;
    FilterGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.SelectedLayout.Font.Style := [];
    FilterGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    FilterGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    FilterGrid.Appearance.FocusedLayout.Font.Height := -16;
    FilterGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.FocusedLayout.Font.Style := [];
    FilterGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    FilterGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    FilterGrid.Appearance.BandLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    FilterGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.BandLayout.Font.Color := clBlack;
    FilterGrid.Appearance.BandLayout.Font.Height := -16;
    FilterGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    FilterGrid.Appearance.BandLayout.Font.Style := [];
    FilterGrid.Appearance.ProgressLayout.Color := 15385233;
    FilterGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    FilterGrid.LeftCol := 0;
    FilterGrid.ScrollMode := scmItemScrolling;
    FilterGrid.GlobalFont.Scale := 1.000000000000000000;
    FilterGrid.GlobalFont.Style := [];
    FilterGrid.DesignTimeSampleData := True;
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 447;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Active := True;
    GridDatabaseAdapter.Grid := FilterGrid;
    GridDatabaseAdapter.DataSource := FilterSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'filter_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'filter_publiek';
      Header := 'Public';
      CheckBoxField := True;
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'filter_standaard';
      Header := 'Default';
      CheckBoxField := True;
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 574;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    FilterEdit.SetParentComponent(ToolbarPanel);
    FilterEdit.Name := 'FilterEdit';
    FilterEdit.Left := 20;
    FilterEdit.Top := 10;
    FilterEdit.Width := 345;
    FilterEdit.Height := 34;
    FilterEdit.AutoFocus := True;
    FilterEdit.ChildOrder := 1;
    FilterEdit.Font.Charset := ANSI_CHARSET;
    FilterEdit.Font.Color := clWindowText;
    FilterEdit.Font.Height := -16;
    FilterEdit.Font.Name := 'Segoe UI';
    FilterEdit.Font.Style := [];
    FilterEdit.HeightPercent := 100.000000000000000000;
    FilterEdit.ParentFont := False;
    FilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterEdit, Self, 'OnChange', 'FilterEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 536;
    StatusBarPanel.Width := 574;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 454;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uTeamFilterList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 128;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 5;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uTeamFilterList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 20;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 20;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 5;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uTeamFilterList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 236;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 5;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uTeamFilterList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    DefaultButton.SetParentComponent(StatusBarPanel);
    DefaultButton.Name := 'DefaultButton';
    DefaultButton.AlignWithMargins := True;
    DefaultButton.Left := 344;
    DefaultButton.Top := 8;
    DefaultButton.Width := 100;
    DefaultButton.Height := 31;
    DefaultButton.Margins.Top := 8;
    DefaultButton.Margins.Right := 5;
    DefaultButton.Margins.Bottom := 10;
    DefaultButton.Align := alLeft;
    DefaultButton.Caption := 'Default';
    DefaultButton.Color := clNone;
    DefaultButton.ElementClassName := 'MyWebButton';
    DefaultButton.HeightPercent := 100.000000000000000000;
    DefaultButton.TabOrder := 4;
    DefaultButton.WidthPercent := 100.000000000000000000;
    SetEvent(DefaultButton, Self, 'OnClick', 'DefaultButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 580;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 167;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Group Filter - List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    WebImageControl1.SetParentComponent(CaptionPanel);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.AlignWithMargins := True;
    WebImageControl1.Left := 551;
    WebImageControl1.Top := 9;
    WebImageControl1.Width := 23;
    WebImageControl1.Height := 22;
    WebImageControl1.Margins.Top := 9;
    WebImageControl1.Margins.Right := 6;
    WebImageControl1.Margins.Bottom := 9;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alRight;
    WebImageControl1.ChildOrder := 1;
    SetEvent(WebImageControl1, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl1.Picture.LoadFromFile('uTeamFilterList.CaptionPanel.WebImageControl1.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uTeamFilterList.WebWaitMessage.Picture.gif');
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 68;
    WebMessageDlg1.Top := 487;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    FilterDataset.SetParentComponent(Self);
    FilterDataset.Name := 'FilterDataset';
    FilterDataset.Active := True;
    FilterDataset.AfterScroll := FilterDatasetAfterScroll;
    SetEvent(FilterDataset, Self, 'OnFilterRecord', 'FilterDatasetFilterRecord');
    FilterDataset.Left := 412;
    FilterDataset.Top := 488;
    FilterDatasetfilter_pk.SetParentComponent(FilterDataset);
    FilterDatasetfilter_pk.Name := 'FilterDatasetfilter_pk';
    FilterDatasetfilter_pk.FieldName := 'filter_pk';
    FilterDatasetfilter_pk.Visible := False;
    FilterDatasetfilter_pk.Size := 256;
    FilterDatasetfilter_naam.SetParentComponent(FilterDataset);
    FilterDatasetfilter_naam.Name := 'FilterDatasetfilter_naam';
    FilterDatasetfilter_naam.FieldName := 'filter_naam';
    FilterDatasetfilter_naam.Size := 256;
    FilterDatasetfilter_publiek.SetParentComponent(FilterDataset);
    FilterDatasetfilter_publiek.Name := 'FilterDatasetfilter_publiek';
    FilterDatasetfilter_publiek.FieldName := 'filter_publiek';
    FilterDatasetfilter_publiek.Size := 256;
    FilterDatasetfilter_standaard.SetParentComponent(FilterDataset);
    FilterDatasetfilter_standaard.Name := 'FilterDatasetfilter_standaard';
    FilterDatasetfilter_standaard.FieldName := 'filter_standaard';
    FilterDatasetfilter_standaard.Size := 256;
    FilterSource.SetParentComponent(Self);
    FilterSource.Name := 'FilterSource';
    FilterSource.DataSet := FilterDataset;
    FilterSource.Left := 484;
    FilterSource.Top := 488;
    FilterTimer.SetParentComponent(Self);
    FilterTimer.Name := 'FilterTimer';
    FilterTimer.Enabled := False;
    FilterTimer.Interval := 100;
    SetEvent(FilterTimer, Self, 'OnTimer', 'FilterTimerTimer');
    FilterTimer.Left := 283;
    FilterTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    FilterGrid.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    FilterEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    DefaultButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    FilterDataset.AfterLoadDFMValues;
    FilterDatasetfilter_pk.AfterLoadDFMValues;
    FilterDatasetfilter_naam.AfterLoadDFMValues;
    FilterDatasetfilter_publiek.AfterLoadDFMValues;
    FilterDatasetfilter_standaard.AfterLoadDFMValues;
    FilterSource.AfterLoadDFMValues;
    FilterTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
  end;
end;

end.



{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCWXHTMLMemo.Common;

interface

const
  // HTML TAG
  LB = #13;
  METACHARSETTAG = '<meta charset="UTF-8">';
  HTMLLANGTAG = '<html lang="en">';

  // SUMMERNOTE INFOS
  DATA_OBJECT = '#TTMSFNCWXHTMLMemoDataObject#';
  JSHASHTAGSUMMERNOTEID = '#TTMSFNCWXHTMLMemoSummernoteID#';
  SUMNTJSCMD = '$("#' + JSHASHTAGSUMMERNOTEID + '").summernote("%s");';
  SUMNTJSPARAMSCMD = '$("#' + JSHASHTAGSUMMERNOTEID + '").summernote(%s);';
  SUMNTJSFALSE = 'false';
  SUMNTJSTRUE = 'true';
  SUMNTJSRETURNNULL = 'null';
  DISABLERESIZEEDITOR = 'disableResizeEditor: true,';
  FOCUSTRUE = 'focus: true';
  TOOLBARVISIBLEFALSE = 'toolbar: false,';

  // SUMMERNOTE EVENTS
  ONINITEVENT = 'ONINITEVENT';
  ONCHANGEEVENT = 'ONCHANGEEVENT';
  ONFOCUSVENT = 'ONFOCUSVENT';
  ONBLUREVENT = 'ONBLUREVENT';
  ONKEYDOWNEVENT = 'ONKEYDOWNEVENT';
  ONKEYUPEVENT = 'ONKEYUPEVENT';
  ONMOUSEDOWNEVENT = 'ONMOUSEDOWNEVENT';
  ONMOUSEUPEVENT = 'ONMOUSEUPEVENT';
  ONSTYLETEXTUPDATEEVENT = 'ONSTYLETEXTUPDATEEVENT';

  // SUMMERNOTE JS FUNCTIONS
  SUMNTJSTGFULLSCREEN = 'fullscreen.toggle';
  SUMNTJSISFULLSCREEN = 'fullscreen.isFullscreen';
  SUMNTJSRESET = 'reset';
  SUMNTJSUNDO = 'undo';
  SUMNTJSREDO = 'redo';
  SUMNTJSTGCODEVIEW = 'codeview.toggle';
  SUMNTJSBOLD = 'bold';
  SUMNTJSITALIC = 'italic';
  SUMNTJSUNDERLINE = 'underline';
  SUMNTJSSTRIKETHROUGH = 'strikethrough';
  SUMNTJSREMOVEFORMAT = 'removeFormat';
  SUMNTJSFORMATPARA = 'formatPara';
  SUMNTJSINDENT = 'indent';
  SUMNTJSOUTDENT = 'outdent';
  SUMNTJSINSERTOL = 'insertOrderedList';
  SUMNTJSINSERTUOL = 'insertUnorderedList';
  SUMNTJSINSERTPARA = 'insertParagraph';
  SUMNTJSJUSTIFYLEFT = 'justifyLeft';
  SUMNTJSJUSTIFYRIGHT = 'justifyRight';
  SUMNTJSJUSTIFYCENTER = 'justifyCenter';
  SUMNTJSJUSTIFYFULL = 'justifyFull';
  SUMNTJSLINEHEIGHT = '"lineHeight", %d';
  SUMNTJSINSERTTEXT = '"insertText", "%s"';
  SUMNTJSLOADHTML = '"code", "%s"';
  SUMNTJSSETFONTNAME = '"fontName", "%s"';
  SUMNTJSSETFONTSIZE = '"fontSize", %d';
  SUMNTJSSETBACKCOLOR = '"backColor", "%s"';
  SUMNTJSSETFORECOLOR = '"foreColor", "%s"';
  SUMNTJSCHECKALLSTYLETEXT = JSHASHTAGSUMMERNOTEID + 'CheckAllStyleText()';
  SUMNTJSSELECTEDTEXT = 'function ' + SUMNTJSCHECKALLSTYLETEXT + LB +
    '{' + LB +
    '    var isBold = document.queryCommandState("bold");' + LB +
    '    var isItalic = document.queryCommandState("Italic");' + LB +
    '    var isUnderline = document.queryCommandState("Underline");' + LB +
    '    var isStrikeThrough = document.queryCommandState("strikethrough");' + LB +
    '    var fontName = document.queryCommandValue("fontName");' + LB +
    '    var fontSize = document.queryCommandValue("fontSize");' + LB +
    '    var foreColor = document.queryCommandValue("foreColor");' + LB +
    '    var backColor = document.queryCommandValue("backColor");' + LB +
    '    function getComputedStyleProperty(el, propName) {' + LB +
    '        if (window.getComputedStyle) {' + LB +
    '            return window.getComputedStyle(el, null)[propName];' + LB +
    '        } else if (el.currentStyle) {' + LB +
    '            return el.currentStyle[propName];' + LB +
    '        }' + LB +
    '    }' + LB +
    '    var containerEl, sel, fontSize;' + LB +
    '    if (window.getSelection) {' + LB +
    '        sel = window.getSelection();' + LB +
    '        if (sel.rangeCount) {' + LB +
    '            containerEl = sel.getRangeAt(0).commonAncestorContainer;' + LB +
    '            if (containerEl.nodeType == 3) {' + LB +
    '                containerEl = containerEl.parentNode;' + LB +
    '            }' + LB +
    '        }' + LB +
    '    } else if ( (sel = document.selection) && sel.type != "Control") {' + LB +
    '        containerEl = sel.createRange().parentElement();' + LB +
    '    }' + LB +
    '    if (containerEl) {' + LB +
    '        fontSize = getComputedStyleProperty(containerEl, "fontSize");' + LB +
    '    }' + LB +
    '    return [isBold,isItalic,isUnderline,isStrikeThrough,fontName,fontSize,foreColor,backColor];' + LB +
    '}';

  SUMNTJSDEFAULTFONT =
  'function ' + JSHASHTAGSUMMERNOTEID + 'GetDefaultFont() {' + LB +
  '    let genericFontFamilies = ["sans-serif", "serif", "monospace", "cursive", "fantasy"];' + LB +
  '    function validFontName(fontName) {' + LB +
  '        return ($.inArray(fontName.toLowerCase(), genericFontFamilies) === -1) ? `''${fontName}''` : fontName;' +
  '    };' + LB +
  '    function isFontInstalled(fontName) {' + LB +
  '        const testFontName = fontName === "Comic Sans MS" ? "Courier New" : "Comic Sans MS";' + LB +
  '        const testText = "mmmmmmmmmmwwwww";' + LB +
  '        const testSize = "200px";' + LB +
  '        var canvas = document.createElement("canvas");' + LB +
  '        var context = canvas.getContext("2d");' + LB +
  '        context.font = testSize + " ''" + testFontName + "''";' + LB +
  '        const originalWidth = context.measureText(testText).width;' + LB +
  '        context.font = testSize + '' '' + validFontName(fontName) + '', "'' + testFontName + ''"'';' + LB +
  '        const width = context.measureText(testText).width;' + LB +
  '        return originalWidth !== width;' + LB +
  '    }' + LB +
  '    let defFont = "";' + LB +
  '    const cssFonts = window.getComputedStyle(document.body).getPropertyValue("font-family")' + LB +
  '    $.each(cssFonts.split(","), (idx, fontname) => {' + LB +
  '        fontname = fontname.trim().replace(/[''"]+/g, '''');' + LB +
  '        if (isFontInstalled(fontname)) {' + LB +
  '          defFont = fontname;' + LB +
  '          return false;' + LB +
  '        }' + LB +
  '      });' + LB +
  '    return defFont;' + LB +
  '}';

  SUMNTJSFIRSTFONT =
  'function ' + JSHASHTAGSUMMERNOTEID + 'GetFirstAvailableFont() {' + LB +
  '    let res = "";' + LB +
  '    let elems = document.getElementsByClassName("dropdown-fontname");' + LB +
  '    if (elems.length > 0) {' + LB +
  '        let first = elems[0].getElementsByTagName("A");' + LB +
  '        if (first.length > 0) {' + LB +
  '            res = first[0].innerText;' + LB +
  '        }' + LB +
  '    }' + LB +
  '    return res;' + LB +
  '}';

  SUMNTCSSHEIGHTEDITOR = '.note-editor.note-frame.fullscreen, .note-editing-area {' + LB +
    '  height: 100%;' + LB +
    '}' + LB +
    '.note-codable { ' + LB +
    '  height: 100% !important; ' + LB +
    '}';

  CALLBACKSEVENTS = 'callbacks: {' + LB +
    '  onInit: function() {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONINITEVENT + '";' + LB +
    '    var customdata = [' + JSHASHTAGSUMMERNOTEID + 'GetDefaultFont(), ' + JSHASHTAGSUMMERNOTEID + 'GetFirstAvailableFont()]' + LB +
    '    var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  },' + LB +
    '  onChange: function(contents, $editable) {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    plainText = contents.replace(/<\/p>/gi, "\n");' + LB +
    '    plainText = plainText.replace(/<br\/?>/gi, "\n");' + LB +
    '    plainText = plainText.replace(/<\/?[^>]+(>|$)/g, "");' + LB +
    '    o["EventName"] = "' + ONCHANGEEVENT + '";' + LB +
    '    var customdata = [contents,plainText];' + LB +
    '    var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '    var StyleText = ' + SUMNTJSCHECKALLSTYLETEXT + ';' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONSTYLETEXTUPDATEEVENT + '";' + LB +
    '    customdata = StyleText;' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  },' + LB +
    '  onFocus: function() {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONFOCUSVENT + '";' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o));' + LB +
    '    var StyleText = ' + SUMNTJSCHECKALLSTYLETEXT + ';' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONSTYLETEXTUPDATEEVENT + '";' + LB +
    '    customdata = StyleText;' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  },' + LB +
    '  onBlur: function() {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONBLUREVENT + '";' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o));' + LB +
    '  },' + LB +
    '  onMousedown: function(e) {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONMOUSEDOWNEVENT + '";' + LB +
    '    customdata = [e.button,e.shiftKey,e.altKey,e.ctrlKey,e.clientX,e.clientY];' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  },' + LB +
    '  onMouseup: function(e) {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONMOUSEUPEVENT + '";' + LB +
    '    customdata = [e.button,e.shiftKey,e.altKey,e.ctrlKey,e.clientX,e.clientY];' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o),cd);' + LB +
    '    var StyleText = ' + SUMNTJSCHECKALLSTYLETEXT + ';' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONSTYLETEXTUPDATEEVENT + '";' + LB +
    '    customdata = StyleText;' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  },' + LB +
    '  onKeydown: function(e) {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONKEYDOWNEVENT + '";' + LB +
    '    var customdata = [e.keyCode,e.key,e.shiftKey,e.altKey,e.ctrlKey];' + LB +
    '    var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  },' + LB +
    '  onKeyup: function(e) {' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONKEYUPEVENT + '";' + LB +
    '    var customdata = [e.keyCode,e.key,e.shiftKey,e.altKey,e.ctrlKey];' + LB +
    '    var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '    var StyleText = ' + SUMNTJSCHECKALLSTYLETEXT + ';' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONSTYLETEXTUPDATEEVENT + '";' + LB +
    '    customdata = StyleText;' + LB +
    '    cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + JSHASHTAGSUMMERNOTEID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  }' + LB +
    '},';

implementation


end.



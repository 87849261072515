unit uPersonalSettings;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.ExtCtrls,
  WEBLIB.REST, WEBLIB.JSON,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Buttons,
  Vcl.Imaging.GIFImg, VCL.TMSFNCCustomComponent, VCL.TMSFNCPopup,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCListBox, VCL.TMSFNCCheckedListBox;

type
  TPersonalSettingsForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    MarkerClusteringEdit: TCheckBox;
    WebWaitMessage: TWaitMessage;
    ObjectFilterTypeLabel: TLabel;
    ObjectFilterTypeEdit: TLookupComboBox;
    MarkerOverlayEdit: TCheckBox;
    MarkerOverlayTypeEdit: TLookupComboBox;
    POIZoomToBoundsEdit: TCheckBox;
    POIGroupEdit: TEdit;
    POIGroupClearButton: TSpeedButton;
    POIGroupPopup: TTMSFNCPopup;
    POIGroupList: TTMSFNCCheckedListBox;
    WebLabel1: TLabel;
    CountryEdit: TLookupComboBox;
    WebLabel2: TLabel;
    MyMessageDlg: TMessageDlg;
    [async]
    AuthorizationEdit: TEdit;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    UserTypeEdit: TEdit;
    UsernameEdit: TEdit;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    AssetSortOrderEdit: TLookupComboBox;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure POIGroupClearButtonClick(Sender: TObject);
    procedure POIGroupEditClick(Sender: TObject);
    procedure POIGroupListItemCheckChanged(Sender: TObject;
      AItem: TTMSFNCCheckedListBoxItem);
    procedure RecordChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadPOIGroupList(S: String);
    function MyPOIGroups(): String;
    [async]
    function LoadPersonalSettings(): Boolean; async;
    [async]
    function SavePersonalSettings(): Boolean; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  PersonalSettingsForm: TPersonalSettingsForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TPersonalSettingsForm.WebFormCreate(Sender: TObject);
begin
  AssetSortOrderEdit.Value := '0';
end;

procedure TPersonalSettingsForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  await(LoadLookupValues());
  await(LoadPersonalSettings());
  WebWaitMessage.Hide;
end;

procedure TPersonalSettingsForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      ModalResult := mrOK;
    end;
  end else begin
    ModalResult := mrOK;
  end;
end;

procedure TPersonalSettingsForm.LoadLookupValues;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_poi_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadPOIGroupList(TJSONPair(MyJSONObject.Get('poi_group')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TPersonalSettingsForm.LoadPOIGroupList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  Item: TTMSFNCCheckedListBoxItem;
begin
  POIGroupList.BeginUpdate;
  try
    POIGroupList.Items.Clear;
    // Parse JSON
    MyJSON := TJSON.Create;
    try
      MyJSONArray := TJSONArray(MyJSON.Parse(S));
      for i := 0 to MyJSONArray.Count-1 do begin
        MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
        Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items.Add);
        Item.Tag := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        Item.Text := MyJSONObject.GetJSONValue('id');
      end;
    finally
      MyJSON.Free;
    end;
  finally
    POIGroupList.EndUpdate;
  end;
end;

procedure TPersonalSettingsForm.POIGroupClearButtonClick(Sender: TObject);
var
  I: Integer;
begin
  POIGroupEdit.Text := EmptyStr;
  POIGroupEdit.Tag := 0;
  for I := 0 to POIGroupList.Items.Count-1 do begin
    POIGroupList.Items[I].Checked := False;
  end;
  RecordChange(TObject(Sender));
end;

procedure TPersonalSettingsForm.POIGroupEditClick(Sender: TObject);
begin
  POIGroupList.BeginUpdate;
  POIGroupPopup.Popup;
  POIGroupList.EndUpdate;
end;

procedure TPersonalSettingsForm.POIGroupListItemCheckChanged(Sender: TObject;
  AItem: TTMSFNCCheckedListBoxItem);
var
  I: Integer;
  StringList: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  StringList := TStringList.Create();
  try
    for I := 0 to POIGroupList.Items.Count-1 do begin
      Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]);
      if Item.Checked then StringList.Add(Item.Text);
    end;
    POIGroupEdit.Text := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
  RecordChange(TObject(Sender));
end;

function TPersonalSettingsForm.LoadPersonalSettings(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MySON: TJSON;
  MyJSONObject: TJSONObject;
  I: Integer;
  StringList: TStringList;
  StringName: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  Result := True;
  try
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_personal_settings_load.php?sessionid=' + SessionId;
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      //
      MySON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MySON.Parse(string(MyRequest.response)));
        // User
        UserNameEdit.Text := MyJSONObject.GetJSONValue('username');
        UserTypeEdit.Text := MyJSONObject.GetJSONValue('usertype');
        case StrToInt(MyJSONObject.GetJSONValue('usertype')) of
          0: UserTypeEdit.Text := 'User';
          1: UserTypeEdit.Text := 'Manager';
          2: UserTypeEdit.Text := 'Administrator';
        end;
        AuthorizationEdit.Text := MyJSONObject.GetJSONValue('authorization');
        // Markers
        MarkerClusteringEdit.Checked := StrToInt(MyJSONObject.GetJSONValue('clustering')) = 1;
        MarkerOverlayEdit.Checked := StrToInt(MyJSONObject.GetJSONValue('marker_overlay_visible')) = 1;
        MarkerOverlayTypeEdit.Value := MyJSONObject.GetJSONValue('marker_overlay_type');
        // POI
        POIZoomToBoundsEdit.Checked := StrToInt(MyJSONObject.GetJSONValue('poi_zoom_to_bounds')) = 1;
        StringList := TStringList.Create();
        StringName := TStringList.Create();
        try
          StringList.DelimitedText := MyJSONObject.GetJSONValue('poi_group_list');
          for I := 0 to POIGroupList.Items.Count-1 do begin
            Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]);
            if StringList.IndexOf(IntToStr(Item.Tag)) >= 0 then begin
              Item.Checked := True;
              StringName.Add(Item.Text);
            end;
          end;
          POIGroupEdit.Text := StringName.DelimitedText;
        finally
          StringList.Free;
          StringName.Free;
        end;
        // Defaults
        CountryEdit.Value := MyJSONObject.GetJSONValue('default_country');
        if MyJSONObject.getValue('asset_sort_order') <> nil then begin
          AssetSortOrderEdit.Value := MyJSONObject.GetJSONValue('asset_sort_order');
        end;
        // Reports
        ObjectFilterTypeEdit.Value := MyJSONObject.GetJSONValue('reportobjectfiltertype');
      finally
        MySON.Free;
      end;
    finally
      MyWebRequest.Free;
    end;
  except
    Result := False;
  end;
end;

procedure TPersonalSettingsForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

function TPersonalSettingsForm.MyPOIGroups(): String;
var
  I: Integer;
  StringList: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  StringList := TStringList.Create();
  try
    for I := 0 to POIGroupList.Items.Count-1 do begin
      Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]);
      if Item.Checked then StringList.Add(IntToStr(Item.Tag));
    end;
    Result := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
end;

procedure TPersonalSettingsForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
begin
  Result := await(SavePersonalSettings());
  if Result then begin
    ModalResult := mrOK;
  end;
end;

function TPersonalSettingsForm.SavePersonalSettings(): Boolean;
var
  MySettings, MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  Result := False;
  MySettings :=
    '{' +
      '"clustering":"' + BooleanToString(MarkerClusteringEdit.Checked) + '",' +
      '"marker_overlay_visible":"' + BooleanToString(MarkerOverLayEdit.Checked) + '",' +
      '"marker_overlay_type":"' + MarkerOverlayTypeEdit.Value + '",' +
      '"poi_zoom_to_bounds":"' + BooleanToString(POIZoomToBoundsEdit.Checked) + '",' +
      '"poi_group_list":"' + MyPOIGroups() + '",' +
      '"default_country":"' + CountryEdit.Value + '",' +
      '"asset_sort_order":"' + AssetSortOrderEdit.Value + '",' +
      '"reportobjectfiltertype":"' + ObjectFilterTypeEdit.Value + '"' +
    '}';
  MyString :=
    '{' +
      '"sessionid":"' + SessionID + '",' +
      '"settings":' + MySettings +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_personal_settings_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      await(UpdatePersonalSettings(MySettings));
      Result := True;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TPersonalSettingsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  POIGroupList := TTMSFNCCheckedListBox.Create(Self);
  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ObjectFilterTypeLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  MarkerClusteringEdit := TCheckBox.Create(Self);
  ObjectFilterTypeEdit := TLookupComboBox.Create(Self);
  MarkerOverlayEdit := TCheckBox.Create(Self);
  MarkerOverlayTypeEdit := TLookupComboBox.Create(Self);
  POIZoomToBoundsEdit := TCheckBox.Create(Self);
  POIGroupEdit := TEdit.Create(Self);
  POIGroupClearButton := TSpeedButton.Create(Self);
  CountryEdit := TLookupComboBox.Create(Self);
  AuthorizationEdit := TEdit.Create(Self);
  UserTypeEdit := TEdit.Create(Self);
  UsernameEdit := TEdit.Create(Self);
  AssetSortOrderEdit := TLookupComboBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  POIGroupPopup := TTMSFNCPopup.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);

  POIGroupList.BeforeLoadDFMValues;
  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ObjectFilterTypeLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  MarkerClusteringEdit.BeforeLoadDFMValues;
  ObjectFilterTypeEdit.BeforeLoadDFMValues;
  MarkerOverlayEdit.BeforeLoadDFMValues;
  MarkerOverlayTypeEdit.BeforeLoadDFMValues;
  POIZoomToBoundsEdit.BeforeLoadDFMValues;
  POIGroupEdit.BeforeLoadDFMValues;
  POIGroupClearButton.BeforeLoadDFMValues;
  CountryEdit.BeforeLoadDFMValues;
  AuthorizationEdit.BeforeLoadDFMValues;
  UserTypeEdit.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  AssetSortOrderEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  POIGroupPopup.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  try
    Name := 'PersonalSettingsForm';
    Width := 709;
    Height := 660;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    POIGroupList.SetParentComponent(Self);
    POIGroupList.Name := 'POIGroupList';
    POIGroupList.Left := 239;
    POIGroupList.Top := 298;
    POIGroupList.Width := 345;
    POIGroupList.Height := 351;
    POIGroupList.AllowFocus := False;
    POIGroupList.BevelInner := bvNone;
    POIGroupList.BevelOuter := bvNone;
    POIGroupList.ParentDoubleBuffered := False;
    POIGroupList.DoubleBuffered := True;
    POIGroupList.Font.Charset := ANSI_CHARSET;
    POIGroupList.Font.Color := clWindowText;
    POIGroupList.Font.Height := -16;
    POIGroupList.Font.Name := 'Segoe UI';
    POIGroupList.Font.Style := [];
    POIGroupList.ParentFont := False;
    POIGroupList.TabOrder := 3;
    POIGroupList.Visible := False;
    POIGroupList.Stroke.Color := 11119017;
    POIGroupList.ItemsAppearance.Font.Charset := ANSI_CHARSET;
    POIGroupList.ItemsAppearance.Font.Color := clGray;
    POIGroupList.ItemsAppearance.Font.Height := -16;
    POIGroupList.ItemsAppearance.Font.Name := 'Segoe UI';
    POIGroupList.ItemsAppearance.Font.Style := [];
    POIGroupList.ItemsAppearance.SelectedFill.Color := 16578806;
    POIGroupList.ItemsAppearance.SelectedStroke.Color := 15702829;
    POIGroupList.ItemsAppearance.SelectedStroke.Width := 2.000000000000000000;
    POIGroupList.ItemsAppearance.FixedHeight := 30.000000000000000000;
    POIGroupList.ItemsAppearance.ShowFocus := False;
    POIGroupList.DefaultItem.TextColor := clGray;
    POIGroupList.Items.Clear;
    with POIGroupList.Items.Add do
    begin
      Text := 'Mercedes';
      TextColor := clGray;
      Tag := 1;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'Audi';
      TextColor := clGray;
      Tag := 2;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'BMW';
      TextColor := clGray;
      Tag := 3;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'Land Rover';
      TextColor := clGray;
      Tag := 4;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'Bugatti';
      TextColor := clGray;
      Tag := 5;
    end;
    POIGroupList.Header.Text := 'Header';
    POIGroupList.Header.Fill.Color := 16380654;
    POIGroupList.Header.Font.Charset := DEFAULT_CHARSET;
    POIGroupList.Header.Font.Color := clBlack;
    POIGroupList.Header.Font.Height := -11;
    POIGroupList.Header.Font.Name := 'Tahoma';
    POIGroupList.Header.Font.Style := [];
    POIGroupList.Header.Stroke.Kind := gskNone;
    POIGroupList.Header.Size := 25.000000000000000000;
    POIGroupList.Header.SortIndicatorColor := 11829830;
    POIGroupList.Interaction.Filtering.Enabled := True;
    POIGroupList.GlobalFont.Scale := 1.000000000000000000;
    POIGroupList.GlobalFont.Style := [];
    SetEvent(POIGroupList, Self, 'OnItemCheckChanged', 'POIGroupListItemCheckChanged');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 709;
    FormPanel.Height := 660;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 703;
    ContentPanel.Height := 617;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ObjectFilterTypeLabel.SetParentComponent(ContentPanel);
    ObjectFilterTypeLabel.Name := 'ObjectFilterTypeLabel';
    ObjectFilterTypeLabel.Left := 49;
    ObjectFilterTypeLabel.Top := 187;
    ObjectFilterTypeLabel.Width := 207;
    ObjectFilterTypeLabel.Height := 21;
    ObjectFilterTypeLabel.Caption := 'My Default Report Asset Filter';
    ObjectFilterTypeLabel.Font.Charset := ANSI_CHARSET;
    ObjectFilterTypeLabel.Font.Color := clBlack;
    ObjectFilterTypeLabel.Font.Height := -16;
    ObjectFilterTypeLabel.Font.Name := 'Segoe UI';
    ObjectFilterTypeLabel.Font.Style := [];
    ObjectFilterTypeLabel.HeightPercent := 100.000000000000000000;
    ObjectFilterTypeLabel.ParentFont := False;
    ObjectFilterTypeLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 49;
    WebLabel1.Top := 237;
    WebLabel1.Width := 160;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'My Default POI Groups';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 49;
    WebLabel2.Top := 287;
    WebLabel2.Width := 136;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'My Default Country';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(ContentPanel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 51;
    WebLabel3.Top := 137;
    WebLabel3.Width := 154;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'My authorization level';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ContentPanel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 51;
    WebLabel4.Top := 87;
    WebLabel4.Width := 90;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'My user type';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := clBlack;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(ContentPanel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 51;
    WebLabel5.Top := 37;
    WebLabel5.Width := 93;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'Logged on as';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := clBlack;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(ContentPanel);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 51;
    WebLabel6.Top := 360;
    WebLabel6.Width := 98;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'Sort Assets By';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := clBlack;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 568;
    StatusBarPanel.Width := 703;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 3;
    StatusBarPanel.Color := clWhite;
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 468;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uPersonalSettings.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 583;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uPersonalSettings.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    MarkerClusteringEdit.SetParentComponent(ContentPanel);
    MarkerClusteringEdit.Name := 'MarkerClusteringEdit';
    MarkerClusteringEdit.AlignWithMargins := True;
    MarkerClusteringEdit.Left := 49;
    MarkerClusteringEdit.Top := 410;
    MarkerClusteringEdit.Width := 207;
    MarkerClusteringEdit.Height := 35;
    MarkerClusteringEdit.Margins.Left := 40;
    MarkerClusteringEdit.Margins.Top := 10;
    MarkerClusteringEdit.Margins.Right := 40;
    MarkerClusteringEdit.Caption := 'Show Clustering Markers';
    MarkerClusteringEdit.ChildOrder := 8;
    MarkerClusteringEdit.Color := clWhite;
    MarkerClusteringEdit.Font.Charset := ANSI_CHARSET;
    MarkerClusteringEdit.Font.Color := clBlack;
    MarkerClusteringEdit.Font.Height := -16;
    MarkerClusteringEdit.Font.Name := 'Segoe UI';
    MarkerClusteringEdit.Font.Style := [];
    MarkerClusteringEdit.HeightPercent := 100.000000000000000000;
    MarkerClusteringEdit.ParentFont := False;
    MarkerClusteringEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MarkerClusteringEdit, Self, 'OnClick', 'RecordChange');
    ObjectFilterTypeEdit.SetParentComponent(ContentPanel);
    ObjectFilterTypeEdit.Name := 'ObjectFilterTypeEdit';
    ObjectFilterTypeEdit.AlignWithMargins := True;
    ObjectFilterTypeEdit.Left := 279;
    ObjectFilterTypeEdit.Top := 180;
    ObjectFilterTypeEdit.Width := 345;
    ObjectFilterTypeEdit.Height := 34;
    ObjectFilterTypeEdit.Margins.Left := 20;
    ObjectFilterTypeEdit.Margins.Top := 8;
    ObjectFilterTypeEdit.Margins.Bottom := 8;
    ObjectFilterTypeEdit.Color := clWhite;
    ObjectFilterTypeEdit.ElementClassName := 'myselect';
    ObjectFilterTypeEdit.Font.Charset := DEFAULT_CHARSET;
    ObjectFilterTypeEdit.Font.Color := clWindowText;
    ObjectFilterTypeEdit.Font.Height := 12;
    ObjectFilterTypeEdit.Font.Name := 'Segoe UI';
    ObjectFilterTypeEdit.Font.Style := [];
    ObjectFilterTypeEdit.HeightPercent := 100.000000000000000000;
    ObjectFilterTypeEdit.ParentFont := False;
    ObjectFilterTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ObjectFilterTypeEdit, Self, 'OnChange', 'RecordChange');
    ObjectFilterTypeEdit.ItemIndex := -1;
    ObjectFilterTypeEdit.LookupValues.Clear;
    with ObjectFilterTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'ASSET ID';
    end;
    with ObjectFilterTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'REGISTRATION PLATE';
    end;
    with ObjectFilterTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'GROUP / TYPE FILTER';
    end;
    MarkerOverlayEdit.SetParentComponent(ContentPanel);
    MarkerOverlayEdit.Name := 'MarkerOverlayEdit';
    MarkerOverlayEdit.Left := 49;
    MarkerOverlayEdit.Top := 461;
    MarkerOverlayEdit.Width := 181;
    MarkerOverlayEdit.Height := 22;
    MarkerOverlayEdit.Caption := 'Marker Overlay Visible';
    MarkerOverlayEdit.ChildOrder := 4;
    MarkerOverlayEdit.Font.Charset := ANSI_CHARSET;
    MarkerOverlayEdit.Font.Color := clWindowText;
    MarkerOverlayEdit.Font.Height := -16;
    MarkerOverlayEdit.Font.Name := 'Segoe UI';
    MarkerOverlayEdit.Font.Style := [];
    MarkerOverlayEdit.HeightPercent := 100.000000000000000000;
    MarkerOverlayEdit.ParentFont := False;
    MarkerOverlayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MarkerOverlayEdit, Self, 'OnClick', 'RecordChange');
    MarkerOverlayTypeEdit.SetParentComponent(ContentPanel);
    MarkerOverlayTypeEdit.Name := 'MarkerOverlayTypeEdit';
    MarkerOverlayTypeEdit.AlignWithMargins := True;
    MarkerOverlayTypeEdit.Left := 279;
    MarkerOverlayTypeEdit.Top := 455;
    MarkerOverlayTypeEdit.Width := 345;
    MarkerOverlayTypeEdit.Height := 34;
    MarkerOverlayTypeEdit.Margins.Left := 20;
    MarkerOverlayTypeEdit.Margins.Top := 8;
    MarkerOverlayTypeEdit.Margins.Bottom := 8;
    MarkerOverlayTypeEdit.Color := clWhite;
    MarkerOverlayTypeEdit.ElementClassName := 'myselect';
    MarkerOverlayTypeEdit.Font.Charset := DEFAULT_CHARSET;
    MarkerOverlayTypeEdit.Font.Color := clWindowText;
    MarkerOverlayTypeEdit.Font.Height := -16;
    MarkerOverlayTypeEdit.Font.Name := 'Segoe UI';
    MarkerOverlayTypeEdit.Font.Style := [];
    MarkerOverlayTypeEdit.HeightPercent := 100.000000000000000000;
    MarkerOverlayTypeEdit.ParentFont := False;
    MarkerOverlayTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MarkerOverlayTypeEdit, Self, 'OnChange', 'RecordChange');
    MarkerOverlayTypeEdit.ItemIndex := -1;
    MarkerOverlayTypeEdit.LookupValues.Clear;
    with MarkerOverlayTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'REGISTRATION PLATE';
    end;
    with MarkerOverlayTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'DEVICE NUMBER';
    end;
    with MarkerOverlayTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'ASSET ID';
    end;
    with MarkerOverlayTypeEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'DRIVER';
    end;
    POIZoomToBoundsEdit.SetParentComponent(ContentPanel);
    POIZoomToBoundsEdit.Name := 'POIZoomToBoundsEdit';
    POIZoomToBoundsEdit.Left := 49;
    POIZoomToBoundsEdit.Top := 504;
    POIZoomToBoundsEdit.Width := 181;
    POIZoomToBoundsEdit.Height := 22;
    POIZoomToBoundsEdit.Caption := 'Zoom map to POI'#39's';
    POIZoomToBoundsEdit.ChildOrder := 4;
    POIZoomToBoundsEdit.Font.Charset := ANSI_CHARSET;
    POIZoomToBoundsEdit.Font.Color := clWindowText;
    POIZoomToBoundsEdit.Font.Height := -16;
    POIZoomToBoundsEdit.Font.Name := 'Segoe UI';
    POIZoomToBoundsEdit.Font.Style := [];
    POIZoomToBoundsEdit.HeightPercent := 100.000000000000000000;
    POIZoomToBoundsEdit.ParentFont := False;
    POIZoomToBoundsEdit.WidthPercent := 100.000000000000000000;
    SetEvent(POIZoomToBoundsEdit, Self, 'OnClick', 'RecordChange');
    POIGroupEdit.SetParentComponent(ContentPanel);
    POIGroupEdit.Name := 'POIGroupEdit';
    POIGroupEdit.Left := 279;
    POIGroupEdit.Top := 230;
    POIGroupEdit.Width := 345;
    POIGroupEdit.Height := 34;
    POIGroupEdit.ChildOrder := 1;
    POIGroupEdit.ElementClassName := 'MyWebEdit';
    POIGroupEdit.Font.Charset := ANSI_CHARSET;
    POIGroupEdit.Font.Color := clWindowText;
    POIGroupEdit.Font.Height := -16;
    POIGroupEdit.Font.Name := 'Segoe UI';
    POIGroupEdit.Font.Style := [];
    POIGroupEdit.HeightPercent := 100.000000000000000000;
    POIGroupEdit.ParentFont := False;
    POIGroupEdit.ReadOnly := True;
    POIGroupEdit.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupEdit, Self, 'OnClick', 'POIGroupEditClick');
    POIGroupClearButton.SetParentComponent(ContentPanel);
    POIGroupClearButton.Name := 'POIGroupClearButton';
    POIGroupClearButton.AlignWithMargins := True;
    POIGroupClearButton.Left := 592;
    POIGroupClearButton.Top := 232;
    POIGroupClearButton.Width := 30;
    POIGroupClearButton.Height := 30;
    POIGroupClearButton.Margins.Left := 10;
    POIGroupClearButton.Margins.Top := 8;
    POIGroupClearButton.Margins.Right := 0;
    POIGroupClearButton.Margins.Bottom := 8;
    POIGroupClearButton.Color := clNone;
    POIGroupClearButton.ElementClassName := 'MyClearButton';
    POIGroupClearButton.Glyph.LoadFromFile('uPersonalSettings.ContentPanel.POIGroupClearButton.Glyph.png');
    POIGroupClearButton.HeightPercent := 100.000000000000000000;
    POIGroupClearButton.TabOrder := 7;
    POIGroupClearButton.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupClearButton, Self, 'OnClick', 'POIGroupClearButtonClick');
    CountryEdit.SetParentComponent(ContentPanel);
    CountryEdit.Name := 'CountryEdit';
    CountryEdit.AlignWithMargins := True;
    CountryEdit.Left := 279;
    CountryEdit.Top := 280;
    CountryEdit.Width := 345;
    CountryEdit.Height := 34;
    CountryEdit.Margins.Left := 20;
    CountryEdit.Margins.Top := 8;
    CountryEdit.Margins.Bottom := 8;
    CountryEdit.Color := clWhite;
    CountryEdit.ElementClassName := 'myselect_white';
    CountryEdit.Font.Charset := DEFAULT_CHARSET;
    CountryEdit.Font.Color := clWindowText;
    CountryEdit.Font.Height := 12;
    CountryEdit.Font.Name := 'Segoe UI';
    CountryEdit.Font.Style := [];
    CountryEdit.HeightPercent := 100.000000000000000000;
    CountryEdit.ParentFont := False;
    CountryEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CountryEdit, Self, 'OnChange', 'RecordChange');
    CountryEdit.ItemIndex := -1;
    CountryEdit.LookupValues.Clear;
    with CountryEdit.LookupValues.Add do
    begin
      Value := 'Belgi'#235;
      DisplayText := 'BELGIUM';
    end;
    with CountryEdit.LookupValues.Add do
    begin
      Value := 'Duitsland';
      DisplayText := 'GERMANY';
    end;
    with CountryEdit.LookupValues.Add do
    begin
      Value := 'Frankrijk';
      DisplayText := 'FRANCE';
    end;
    with CountryEdit.LookupValues.Add do
    begin
      Value := 'Luxemburg';
      DisplayText := 'LUXEMBOURG';
    end;
    with CountryEdit.LookupValues.Add do
    begin
      Value := 'Nederland';
      DisplayText := 'NETHERLANDS';
    end;
    AuthorizationEdit.SetParentComponent(ContentPanel);
    AuthorizationEdit.Name := 'AuthorizationEdit';
    AuthorizationEdit.Left := 279;
    AuthorizationEdit.Top := 130;
    AuthorizationEdit.Width := 345;
    AuthorizationEdit.Height := 34;
    AuthorizationEdit.ChildOrder := 1;
    AuthorizationEdit.ElementClassName := 'MyWebEdit';
    AuthorizationEdit.Enabled := False;
    AuthorizationEdit.Font.Charset := ANSI_CHARSET;
    AuthorizationEdit.Font.Color := clWindowText;
    AuthorizationEdit.Font.Height := -16;
    AuthorizationEdit.Font.Name := 'Segoe UI';
    AuthorizationEdit.Font.Style := [];
    AuthorizationEdit.HeightPercent := 100.000000000000000000;
    AuthorizationEdit.ParentFont := False;
    AuthorizationEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AuthorizationEdit, Self, 'OnChange', 'RecordChange');
    UserTypeEdit.SetParentComponent(ContentPanel);
    UserTypeEdit.Name := 'UserTypeEdit';
    UserTypeEdit.Left := 279;
    UserTypeEdit.Top := 80;
    UserTypeEdit.Width := 345;
    UserTypeEdit.Height := 34;
    UserTypeEdit.ChildOrder := 1;
    UserTypeEdit.ElementClassName := 'MyWebEdit';
    UserTypeEdit.Enabled := False;
    UserTypeEdit.Font.Charset := ANSI_CHARSET;
    UserTypeEdit.Font.Color := clWindowText;
    UserTypeEdit.Font.Height := -16;
    UserTypeEdit.Font.Name := 'Segoe UI';
    UserTypeEdit.Font.Style := [];
    UserTypeEdit.HeightPercent := 100.000000000000000000;
    UserTypeEdit.ParentFont := False;
    UserTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UserTypeEdit, Self, 'OnChange', 'RecordChange');
    UsernameEdit.SetParentComponent(ContentPanel);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 279;
    UsernameEdit.Top := 30;
    UsernameEdit.Width := 345;
    UsernameEdit.Height := 34;
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := 'MyWebEdit';
    UsernameEdit.Enabled := False;
    UsernameEdit.Font.Charset := ANSI_CHARSET;
    UsernameEdit.Font.Color := clWindowText;
    UsernameEdit.Font.Height := -16;
    UsernameEdit.Font.Name := 'Segoe UI';
    UsernameEdit.Font.Style := [];
    UsernameEdit.HeightPercent := 100.000000000000000000;
    UsernameEdit.ParentFont := False;
    UsernameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UsernameEdit, Self, 'OnChange', 'RecordChange');
    AssetSortOrderEdit.SetParentComponent(ContentPanel);
    AssetSortOrderEdit.Name := 'AssetSortOrderEdit';
    AssetSortOrderEdit.AlignWithMargins := True;
    AssetSortOrderEdit.Left := 277;
    AssetSortOrderEdit.Top := 354;
    AssetSortOrderEdit.Width := 345;
    AssetSortOrderEdit.Height := 34;
    AssetSortOrderEdit.Margins.Left := 20;
    AssetSortOrderEdit.Margins.Top := 8;
    AssetSortOrderEdit.Margins.Bottom := 8;
    AssetSortOrderEdit.Color := clWhite;
    AssetSortOrderEdit.ElementClassName := 'myselect_white';
    AssetSortOrderEdit.Font.Charset := DEFAULT_CHARSET;
    AssetSortOrderEdit.Font.Color := clWindowText;
    AssetSortOrderEdit.Font.Height := 12;
    AssetSortOrderEdit.Font.Name := 'Segoe UI';
    AssetSortOrderEdit.Font.Style := [];
    AssetSortOrderEdit.HeightPercent := 100.000000000000000000;
    AssetSortOrderEdit.ParentFont := False;
    AssetSortOrderEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetSortOrderEdit, Self, 'OnChange', 'RecordChange');
    AssetSortOrderEdit.ItemIndex := -1;
    AssetSortOrderEdit.LookupValues.Clear;
    with AssetSortOrderEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'Registration Plate';
    end;
    with AssetSortOrderEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Asset ID';
    end;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 709;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 121;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Personal Settings';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 680;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uPersonalSettings.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 645;
    WebWaitMessage.Top := 46;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uPersonalSettings.WebWaitMessage.Picture.gif');
    POIGroupPopup.SetParentComponent(Self);
    POIGroupPopup.Name := 'POIGroupPopup';
    POIGroupPopup.Left := 286;
    POIGroupPopup.Top := 274;
    POIGroupPopup.Width := 26;
    POIGroupPopup.Height := 26;
    POIGroupPopup.Visible := True;
    POIGroupPopup.DropDownHeight := 351.000000000000000000;
    POIGroupPopup.DropDownWidth := 345.000000000000000000;
    POIGroupPopup.PlacementControl := POIGroupEdit;
    POIGroupPopup.ContentControl := POIGroupList;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 678;
    MyMessageDlg.Top := 46;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
  finally
    POIGroupList.AfterLoadDFMValues;
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ObjectFilterTypeLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    MarkerClusteringEdit.AfterLoadDFMValues;
    ObjectFilterTypeEdit.AfterLoadDFMValues;
    MarkerOverlayEdit.AfterLoadDFMValues;
    MarkerOverlayTypeEdit.AfterLoadDFMValues;
    POIZoomToBoundsEdit.AfterLoadDFMValues;
    POIGroupEdit.AfterLoadDFMValues;
    POIGroupClearButton.AfterLoadDFMValues;
    CountryEdit.AfterLoadDFMValues;
    AuthorizationEdit.AfterLoadDFMValues;
    UserTypeEdit.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
    AssetSortOrderEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    POIGroupPopup.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
  end;
end;

end.
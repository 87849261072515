{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGridData;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, SysUtils, WEBLib.Controls, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCDataBinding,
  WEBLib.TMSFNCGridCell, WEBLib.TMSFNCGridDataUtil, WEBLib.Graphics, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCCustomScrollControl, Types, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCGridOptions, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF LCLWEBLIB}
  ,UITypes, Generics.Collections, XmlIntf, XMLDom, XMLDoc
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl, XMLRead, XMLWrite, DOM
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.ListBox, FMX.Types
  {$ENDIF}
  ,WEBLib.StdCtrls
  {$IFDEF CMNLIB}
  ,ExtCtrls
  {$ENDIF}
  ;

const
  CSVSeparators: array[1..10] of char = (',',';','#',#9,'|','@','*','-','+','&');

type
  {$IFNDEF WEBLIB}
  {$DEFINE VARIANTLIST}
  {$ENDIF}

  {$IFDEF VARIANTLIST}
  TTMSFNCGridCellData = string;
  {$ELSE}
  TTMSFNCGridCellData = string;
  {$ENDIF}

  {$IFDEF VARIANTLIST}
  TTMSFNCGridVariantList = class;
  {$ENDIF}

  {$IFDEF CMNWEBLIB}
  TTMSFNCGridEditor = TWinControl;
  {$ENDIF}
  {$IFDEF FMXLIB}
  TTMSFNCGridEditor = TControl;
  {$ENDIF}
  TTMSFNCGridEditorClass = class of TTMSFNCGridEditor;

  {$IFDEF WEBLIB}
  variant = JSValue;
  {$ENDIF}

  {$IFDEF WEBLIB}
  TDomNode = TJSNode;
  TXMLDocument = TJSDocument;

  TJSNodeListHelper = class helper for TJSNodeList
    function FindNode(ANodeName: string): TJSNode;
    function IndexOf(const Name: string): NativeInt; overload;
    function GetCount: NativeInt;
    property Count: NativeInt read GetCount;
  end;
  {$ENDIF}

  {$IFNDEF LCLWEBLIB}
  TTMSFNCGridXMLNode = IXMLNode;
  TTMSFNCGridXMLDocument = IXMLDocument;
  TTMSFNCGridXMLDomNode = IDOMNode;
  {$ELSE}
  TTMSFNCGridXMLNode = TDomNode;
  TTMSFNCGridXMLDomNode = TDomNode;
  TTMSFNCGridXMLDocument = TXMLDocument;
  {$ENDIF}

  {$IFDEF VARIANTLIST}
  TTMSFNCGridVariantItem = record
    FString: TTMSFNCGridCellData;
    FObject: TObject;
  end;

  TTMSFNCGridVariantItemList = array of TTMSFNCGridVariantItem;
  TTMSFNCGridVariantListSortCompare = function(List: TTMSFNCGridVariantList; Index1, Index2: Integer): Integer;

  TTMSFNCGridVariantList = class(TPersistent)
  private
    FList: TTMSFNCGridVariantItemList;
    FCount: Integer;
    FCapacity: Integer;
    FSorted: Boolean;
    FDuplicates: TDuplicates;
    FCaseSensitive: Boolean;
    FOnChange: TNotifyEvent;
    FOnChanging: TNotifyEvent;
    FOwnsObject: Boolean;
    FUpdateCount: Integer;
    procedure ExchangeItems(Index1, Index2: Integer);
    procedure Grow;
    procedure QuickSort(L, R: Integer; SCompare: TTMSFNCGridVariantListSortCompare);
    procedure SetSorted(Value: Boolean);
    procedure SetCaseSensitive(const Value: Boolean);
    function GetVariant(Index: Integer): TTMSFNCGridCellData;
    procedure PutVariant(Index: Integer; const Value: TTMSFNCGridCellData);
    function GetTextStr: string;
  protected
    procedure Changed; virtual;
    procedure Changing; virtual;
    function Get(Index: Integer): TTMSFNCGridCellData;
    function GetCapacity: Integer;
    function GetCount: Integer;
    function GetObject(Index: Integer): TObject;
    procedure Put(Index: Integer; const S: TTMSFNCGridCellData);
    procedure PutObject(Index: Integer; AObject: TObject);
    procedure SetCapacity(NewCapacity: Integer);
    procedure SetUpdateState(Updating: Boolean);
    function CompareVariants(const S1, S2: TTMSFNCGridCellData): Integer;
    procedure InsertItem(Index: Integer; const S: TTMSFNCGridCellData; AObject: TObject); virtual;
    procedure Error(const Msg: string; Data: Integer); overload;
    function CompareStrings(const S1, S2: TTMSFNCGridCellData): Integer; virtual;
    function GetAsText(Delim: Char): string;
  public
    constructor Create; overload;
    constructor Create(OwnsObjects: Boolean); overload;
    destructor Destroy; override;
    procedure BeginUpdate;
    procedure EndUpdate;
    function Add(const S: TTMSFNCGridCellData): Integer;
    function AddObject(const S: TTMSFNCGridCellData; AObject: TObject): Integer;
    procedure Assign(Source: TPersistent); override;
    procedure Clear;
    procedure Delete(Index: Integer);
    procedure Move(CurIndex, NewIndex: Integer); virtual;
    procedure Exchange(Index1, Index2: Integer);
    function Find(const S: TTMSFNCGridCellData; var Index: Integer): Boolean; virtual;
    function IndexOf(const S: TTMSFNCGridCellData): Integer;
    procedure Insert(Index: Integer; const S: TTMSFNCGridCellData);
    procedure InsertObject(Index: Integer; const S: TTMSFNCGridCellData;
      AObject: TObject);
    procedure Sort; virtual;
    procedure CustomSort(Compare: TTMSFNCGridVariantListSortCompare); virtual;
    property Count: Integer read GetCount;
    property Values[Index: Integer]: TTMSFNCGridCellData read GetVariant write PutVariant; default;
    property Objects[Index: Integer]: TObject read GetObject write PutObject;
    property Duplicates: TDuplicates read FDuplicates write FDuplicates;
    property Sorted: Boolean read FSorted write SetSorted;
    property CaseSensitive: Boolean read FCaseSensitive write SetCaseSensitive;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnChanging: TNotifyEvent read FOnChanging write FOnChanging;
    property OwnsObjects: Boolean read FOwnsObject write FOwnsObject;
    property Text: string read GetTextStr;
  end;
  {$ENDIF}

  TTMSFNCGridData = class;

  TTMSFNCGridCellRec = record
    Col, Row: Integer;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCGridCellRec) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCGridCellRecRange = record
    StartCol, StartRow, EndCol, EndRow: Integer;
  end;

  TTMSFNCGridCellState = (csNormal, csFocused, csFixed, csFixedSelected, csSelected);

  TTMSFNCGridSortDirection = (sdAscending, sdDescending);

  TTMSFNCGridSortFormat = (ssAutomatic, ssAlphabetic, ssAlphabeticNoCase, ssNumeric, ssDate, ssHTML, ssCheckBox, ssCustom, ssRaw);

  TTMSFNCGridSortFormatEvent = procedure(Sender: TObject; Col: Integer; var SortFormat: TTMSFNCGridSortFormat; var APrefix, ASuffix: string) of object;

  TTMSFNCGridCustomCompareEvent = procedure(Sender: TObject; Value1, Value2: TTMSFNCGridCellData; var res: integer) of object;

  TTMSFNCGridRawCompareEvent = procedure(Sender: TObject; Col, Row1, Row2: integer; var res: integer) of object;

  TTMSFNCGridClipboardBeforePasteEvent = procedure(Sender: TObject; Col, Row: integer; var Value: string; var Allow: boolean) of object;

  TTMSFNCGridClipboardAfterPasteEvent = procedure(Sender: TObject; Col, Row: integer; Value: string) of object;

  TTMSFNCGridNeedFilterDropDownEvent = procedure(Sender: TObject; Col, Row: integer; var Allow: boolean) of object;

  TTMSFNCGridNeedFilterDropDownDataEvent = procedure(Sender: TObject; Col, Row: integer; AValues: TStrings) of object;

  TTMSFNCGridFilterSelectEvent = procedure(Sender: TObject; Col: integer; var Condition: string) of object;

  TTMSFNCGridAfterApplyFilterEvent = procedure(Sender: TObject; Col: integer; Condition: string; var UpdateCalculations: Boolean) of object;

  TTMSFNCGridCellsChangedEvent = procedure(Sender: TObject; Cells: TTMSFNCGridCellRecRange) of object;

  TTMSFNCGridColumnCalcEvent = procedure(Sender: TObject; ACol, FromRow, ToRow: integer; var Res: double) of object;

  TTMSFNCGridOnSelectCell = procedure(Sender: TObject; ACol, ARow: Integer; var Allow: Boolean) of object;

  TTMSFNCGridOnSelectedCell = procedure(Sender: TObject; ACol, ARow: Integer) of object;

  TTMSFNCGridColumnCalculation = (ccNone,ccSum,ccAvg,ccCount,ccMin,ccMax,ccCUSTOM,ccDistinct,ccStdDev);

  TTMSFNCGridCellCalcState = (csNoCalc,csCalcOk,csCalcErr);

  TTMSFNCGridCellProperty = class(TObject)
  private
    FDefaultFont: TTMSFNCGraphicsFont;
    FColor: TTMSFNCGraphicsColor;
    FBaseCol: integer;
    FBaseRow: integer;
    FColSpan: integer;
    FRowSpan: integer;
    FAlignHorz: TTMSFNCGraphicsTextAlign;
    FAlignVert: TTMSFNCGraphicsTextAlign;
    FControl: TObject;
    FObject: TObject;
    FReadOnly: boolean;
    FPrintPageNr: integer;
    FFontStyle: TFontStyles;
    FFontSize: single;
    FFontName: string;
    FFontColor: TTMSFNCGraphicsColor;
    FAngle: Integer;
    FComment: string;
    FCommentColor: TTMSFNCGraphicsColor;
    FBorderColor: TTMSFNCGraphicsColor;
    FBorderWidth: Integer;
    FWordWrap: boolean;
    FFixed: boolean;
    FCalcState: TTMSFNCGridCellCalcState;
    FCalcValue: variant;
    FNameIndex: integer;
  protected
    property PrintPageNr: integer read FPrintPageNr write FPrintPageNr;
  public
    procedure Assign(Source: TTMSFNCGridCellProperty); virtual;
    constructor Create(DefaultFont: TTMSFNCGraphicsFont);
    destructor Destroy; override;
    function IsBaseCell(Col,Row: integer): boolean;
    procedure FreeControl;
    property AlignHorz: TTMSFNCGraphicsTextAlign read FAlignHorz write FAlignHorz;
    property AlignVert: TTMSFNCGraphicsTextAlign read FAlignVert write FAlignVert;
    property BaseCol: integer read FBaseCol write FBaseCol;
    property BaseRow: integer read FBaseRow write FBaseRow;
    property BorderColor: TTMSFNCGraphicsColor read FBorderColor write FBorderColor;
    property BorderWidth: Integer read FBorderWidth write FBorderWidth;
    property CalcState: TTMSFNCGridCellCalcState read FCalcState write FCalcState;
    property CalcValue: variant read FCalcValue write FCalcValue;
    property CellObject: TObject read FObject write FObject;
    property ColSpan: integer read FColSpan write FColSpan;
    property Color: TTMSFNCGraphicsColor read FColor write FColor;
    property Comment: string read FComment write FComment;
    property CommentColor: TTMSFNCGraphicsColor read FCommentColor write FCommentColor;
    property Control: TObject read FControl write FControl;
    property Fixed: boolean read FFixed write FFixed;
    property FontStyle: TFontStyles read FFontStyle write FFontStyle;
    property FontSize: single read FFontSize write FFontSize;
    property FontName: string read FFontName write FFontName;
    property FontColor: TTMSFNCGraphicsColor read FFontColor write FFontColor;
    property NameIndex: integer read FNameIndex write FNameIndex;
    property ReadOnly: boolean read FReadOnly write FReadOnly;
    property RowSpan: integer read FRowSpan write FRowSpan;
    property WordWrap: boolean read FWordWrap write FWordWrap;
  end;

  TTMSFNCGridCellCheckBox = class(TObject)
  private
    FChecked: boolean;
    FHeader: boolean;
  public
    property Checked: boolean read FChecked write FChecked;
    property Header: boolean read FHeader write FHeader;
  end;

  TTMSFNCGridCellDataCheckBox = class(TObject)
  private
  public
  end;

  TTMSFNCGridCellRadioButton = class(TObject)
  private
    FChecked: boolean;
    FIndex: Integer;
  public
    property Checked: boolean read FChecked write FChecked;
    property Index: integer read FIndex write FIndex;
  end;

  TTMSFNCGridCellButton = class(TObject)
  private
    FText: string;
    FWidth: integer;
    FHeight: integer;
  public
    property Text: string read FText write FText;
    property Width: integer read FWidth write FWidth;
    property Height: integer read FHeight write FHeight;
  end;

  TTMSFNCGridCellBitmapName = class(TObject)
  private
    FBitmapName: string;
    FData: boolean;
  public
    constructor Create;
    property Data: boolean read FData write FData;
    property BitmapName: string read FBitmapName write FBitmapName;
  end;

  TTMSFNCGridCellBitmap = class;

  TTMSFNCGridCellBitmap = class(TObject)
  private
    FBitmap: TTMSFNCBitmap;
    procedure SetBitmap(const Value: TTMSFNCBitmap);
  public
    constructor Create({%H-}AOwner: TTMSFNCGridData);
    destructor Destroy; override;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
  end;

  TTMSFNCGridCellNode = class(TObject)
  private
    FState: TTMSFNCGridNodeState;
    FSpan: integer;
  public
    property State: TTMSFNCGridNodeState read FState write FState;
    property Span: integer read FSpan write FSpan;
  end;

  TTMSFNCGridCellSummary = class(TObject)
  private
    FMerged: boolean;
  public
    property Merged: boolean read FMerged write FMerged;
  end;

  TTMSFNCGridCellProgressBar = class(TObject)
  private
    FValue: single;
    FData: boolean;
  public
    property Value: single read FValue write FValue;
    property Data: boolean read FData write FData;
  end;

  TTMSFNCGridCellCombo = class(TObject)
  private
    FItems: TStringList;
    procedure SetItems(const Value: TStringList);
  public
    constructor Create;
    destructor Destroy; override;
    property Items: TStringList read FItems write SetItems;
  end;

  TTMSFNCGridSortIndexList = class(TTMSFNCGridIntList)
  private
    function GetSortColumns(i: Integer): Integer;
    function GetSortDirections(i: Integer): Boolean;
    procedure SetSortColumns(i: Integer; const Value: Integer);
    procedure SetSortDirections(i: Integer; const Value: Boolean);
  public
    function SaveToString: string;
    procedure LoadFromString(s: string);
    procedure AddIndex(ColumnIndex: integer; ADirection: TTMSFNCGridSortDirection);
    function FindIndex(ColumnIndex: integer):integer;
    procedure ToggleIndex(ColumnIndex: integer);
    property SortColumns[i: Integer]: Integer read GetSortColumns write SetSortColumns;
    property SortDirections[i: Integer]: Boolean read GetSortDirections write SetSortDirections;
  end;

  TTMSFNCGridFilterCells = (fcVirtual, fcNormal, fcStripHTML, fcRow);

  TTMSFNCGridFilterOperation = (foSHORT, foNONE, foAND, foXOR, foOR);

  TTMSFNCGridRowState = (rsDefault, rsSelected);

  TTMSFNCClipOperation = (coCut,coCopy,coNone);

  {$IFDEF VARIANTLIST}
  TTMSFNCGridDataList = class(TTMSFNCGridVariantList);
  {$ELSE}
  TTMSFNCGridDataList = class(TStringList);
  {$ENDIF}

  TTMSFNCGridRowInfo = class(TObject)
  private
    FData: TTMSFNCGridDataList;
    FState: TTMSFNCGridRowState;
    procedure SetState(const Value: TTMSFNCGridRowState);
  public
    constructor Create;
    destructor Destroy; override;
    property Data: TTMSFNCGridDataList read FData;
    property State: TTMSFNCGridRowState read FState write SetState;
  end;

  TTMSFNCGridFilterData = class(TCollectionItem)
  private
    FColumn: SmallInt;
    FCondition: string;
    FCaseSensitive: Boolean;
    FSuffix: string;
    FPrefix: string;
    FOperation: TTMSFNCGridFilterOperation;
    FData: TTMSFNCGridFilterCells;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Column: smallint read FColumn write FColumn;
    property Condition:string read FCondition write FCondition;
    property CaseSensitive: Boolean read FCaseSensitive write FCaseSensitive default True;
    property Data: TTMSFNCGridFilterCells read FData write FData default fcVirtual;
    property Prefix: string read FPrefix write FPrefix;
    property Suffix: string read FSuffix write FSuffix;
    property Operation: TTMSFNCGridFilterOperation read FOperation write FOperation;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridFilter = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridFilter = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGridFilterData>)
  {$ENDIF}
  private
    FOwner: TTMSFNCGridData;
    function GetItem(Index: Integer): TTMSFNCGridFilterData;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGridFilterData);
    function GetColFilter(Col: Integer): TTMSFNCGridFilterData;
  public
    constructor Create(AOwner: TTMSFNCGridData);
    function Add: TTMSFNCGridFilterData;
    function Insert(index: Integer): TTMSFNCGridFilterData;
    property Items[Index: Integer]: TTMSFNCGridFilterData read GetItem write SetItem; default;
    property ColumnFilter[Col: Integer]: TTMSFNCGridFilterData read GetColFilter;
    function HasFilter(Col: integer): Boolean;
    procedure RemoveColumnFilter(Col: integer);
  end;

  TTMSFNCGridCellCoordinate = record
    Col,
    Row: integer;
  end;

  TTMSFNCGridFileStringList = class(TStringList)
  private
    fp: integer;
    cache: string;
    function GetEOF: boolean;
  public
    procedure Reset;
    procedure ReadLn(var s: string);
    procedure Write(s: string);
    procedure WriteLn(s: string);
    property Eof: boolean read GetEOF;
  end;

  TTMSFNCGridFindParameters = (fnMatchCase,fnMatchFull,fnMatchRegular,fnDirectionLeftRight,
    fnMatchStart,fnFindInCurrentRow,fnFindInCurrentCol,fnIncludeFixed,fnAutoGoto,
    fnIgnoreHTMLTags,fnBackward,fnIncludeHiddenColumns,fnFindInPresetCol,fnFindInPresetRow,fnSelectedCells,
    fnIncludeHiddenRows);

  TTMSFNCGridFindParams = set of TTMSFNCGridFindParameters;

  TTMSFNCGridSingleListItem = class(TCollectionItem)
  private
    FOwner: TTMSFNCGridData;
    FValue: Single;
    FCellVal: Integer;
    procedure SetValue(const Value: Single);
  public
    constructor Create(ACollection: TCollection); override;
  published
    property Value: Single read FValue write SetValue;
    property CellVal: Integer read FCellVal write FCellVal;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridSingleList = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridSingleList = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGridSingleListItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCGridData;
    FOnChange: TNotifyEvent;
    function GetItem(Index: Integer): TTMSFNCGridSingleListItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGridSingleListItem);
  protected
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    function IndexOf(ACellVal: Integer): Integer;
    constructor Create(AOwner: TTMSFNCGridData);
    property Items[Index: Integer]: TTMSFNCGridSingleListItem read GetItem write SetItem; default;
    function Add: TTMSFNCGridSingleListItem;
    function Insert(Index: Integer): TTMSFNCGridSingleListItem;
  end;

  TTMSFNCGridMarkType = (mtHighlight, mtError);

  TTMSFNCGridCellMarkItem = class(TCollectionItem)
  private
    FCellRange: TTMSFNCGridCellRecRange;
    FValue: string;
    FCaseSensitive: boolean;
    FMarkType: TTMSFNCGridMarkType;
  public
    property CellRange: TTMSFNCGridCellRecRange read FCellRange write FCellRange;
  published
    property CaseSensitive: boolean read FCaseSensitive write FCaseSensitive;
    property Value: string read FValue write FValue;
    property MarkType: TTMSFNCGridMarkType read FMarkType write FMarkType;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridCellMarkList = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridCellMarkList = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGridCellMarkItem>)
  {$ENDIF}
  private
    function GetItem(Index: Integer): TTMSFNCGridCellMarkItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGridCellMarkItem);
  protected
  public
    constructor Create({%H-}AOwner: TTMSFNCGridData);
    property Items[Index: Integer]: TTMSFNCGridCellMarkItem read GetItem write SetItem; default;
    function Add: TTMSFNCGridCellMarkItem;
    function Insert(Index: Integer): TTMSFNCGridCellMarkItem;
    procedure RemoveMarker(CellRange: TTMSFNCGridCellRecRange; MarkerType: TTMSFNCGridMarkType);
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridCellList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCGridCellRec;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGridCellRec);
  public
    property Items[Index: Integer]: TTMSFNCGridCellRec read GetItem write SetItem; default;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridCellList = class(TList<TTMSFNCGridCellRec>)
  {$ENDIF}
  private
  public
    constructor Create;
    destructor Destroy; override;
    procedure AddCell(Cell: TTMSFNCGridCellRec);
    procedure DeleteCell(Cell: TTMSFNCGridCellRec); overload;
    procedure DeleteCell(index: Integer); overload;
    procedure Clear; reintroduce;
    function Find(Cell: TTMSFNCGridCellRec): integer;
    function FindFixed(Cell: TTMSFNCGridCellRec): integer;
  end;

  TTMSFNCGridExportState = (esExportStart, esExportNewRow, esExportDone, esExportSelRow, esExportFail, esExportNextRow, esExportFindRow);
  TTMSFNCGridImportState = (isImportStart, isImportNewRow, isImportDone, isImportSelRow);

  TTMSFNCGridEditorType = (etEdit, etNumericEdit, etSignedNumericEdit, etFloatEdit, etSignedFloatEdit, etUppercaseEdit, etMixedCaseEdit, etLowerCaseEdit,
    etMoneyEdit, etHexEdit, etAlphaNumericEdit, etValidCharsEdit, etComboBox, etSpinBox, etDatePicker, etColorPicker,
    etTrackBar, etMemo, etCustom);

  TTMSFNCGridIOProgressEvent = procedure(Sender: TObject; CurrentRow, TotalRows: integer; Progres: smallint) of object;

  TTMSFNCGridIOEvent = procedure(Sender: TObject; Col,Row: integer; var Value: string) of object;

  TTMSFNCGridColumnType = (ctDefault, ctButton, ctCheckBox, ctProgressBar, ctRadioButton);

  {$IFDEF LCLLIB}
  TEncoding = class(TObject);
  {$ENDIF}

  TTMSFNCGridColumn = class(TCollectionItem)
  private
    FGrid: TTMSFNCGridData;
    FColor: TTMSFNCGraphicsColor;
    FVertAlignment: TTMSFNCGraphicsTextAlign;
    FHorzAlignment: TTMSFNCGraphicsTextAlign;
    FSortFormat: TTMSFNCGridSortFormat;
    FFont: TTMSFNCGraphicsFont;
    FEditor: TTMSFNCGridEditorType;
    FReadOnly: boolean;
    FBorderColor: TTMSFNCGraphicsColor;
    FID: string;
    FBorderWidth: Integer;
    FWordWrap: boolean;
    FFixed: boolean;
    FSortPrefix: string;
    FSortSuffix: string;
    FTag: NativeInt;
    FComboItems: TStringList;
    FColumnType: TTMSFNCGridColumnType;
    FName: string;
    FFixedFont: TTMSFNCGraphicsFont;
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetHorzAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVertAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBorderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBorderWidth(const Value: Integer);
    procedure SetWordWrap(const Value: boolean);
    procedure SetFixed(const Value: boolean);
    procedure SetComboItems(const Value: TStringList);
    procedure SetColumnType(const Value: TTMSFNCGridColumnType);
    function GetWidth: single;
    procedure SetWidth(const Value: single);
    procedure SetFixedFont(const Value: TTMSFNCGraphicsFont);
    function IsWidthStored: Boolean;
  protected
    procedure Changed;
    function GetDisplayName: string; override;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property BorderColor: TTMSFNCGraphicsColor read FBorderColor write SetBorderColor default gcSilver;
    property BorderWidth: Integer read FBorderWidth write SetBorderWidth;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcNull;
    property ColumnType: TTMSFNCGridColumnType read FColumnType write SetColumnType default ctDefault;
    property ComboItems: TStringList read FComboItems write SetComboItems;
    property Editor: TTMSFNCGridEditorType read FEditor write FEditor default etEdit;
    property Fixed: boolean read FFixed write SetFixed default False;
    property FixedFont: TTMSFNCGraphicsFont read FFixedFont write SetFixedFont;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property HorzAlignment: TTMSFNCGraphicsTextAlign read FHorzAlignment write SetHorzAlignment default gtaLeading;
    property ID: string read FID write FID;
    property Name: string read FName write FName;
    property ReadOnly: boolean read FReadOnly write FReadOnly default false;
    property SortFormat: TTMSFNCGridSortFormat read FSortFormat write FSortFormat default ssAutomatic;
    property SortSuffix: string read FSortSuffix write FSortSuffix;
    property SortPrefix: string read FSortPrefix write FSortPrefix;
    property Tag: NativeInt read FTag write FTag default 0;
    property VertAlignment: TTMSFNCGraphicsTextAlign read FVertAlignment write SetVertAlignment default gtaCenter;
    property Width: single read GetWidth write SetWidth stored IsWidthStored nodefault;
    property WordWrap: boolean read FWordWrap write SetWordWrap default false;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridColumns = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridColumns = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGridColumn>)
  {$ENDIF}
  private
    FGrid: TTMSFNCGridData;
    function GetItem(Index: integer): TTMSFNCGridColumn;
    procedure SetItem(Index: integer; const Value: TTMSFNCGridColumn);
  public
    constructor Create(AGrid: TTMSFNCGridData); virtual;
    function ColumnByName(AName: string): TTMSFNCGridColumn;
    function ColumnByID(AID: string): TTMSFNCGridColumn;
    property Items[Index: integer]: TTMSFNCGridColumn read GetItem write SetItem; default;
    function Add: TTMSFNCGridColumn;
    function Insert(Index: integer): TTMSFNCGridColumn;
    function ByID(Id: string): TTMSFNCGridColumn;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridRowInfoList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCGridRowInfo;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGridRowInfo);
  public
    property Items[Index: Integer]: TTMSFNCGridRowInfo read GetItem write SetItem; default;
  end;
  TTMSFNCGridIntegerList = class(TList)
  private
    function GetItem(Index: Integer): Integer;
    procedure SetItem(Index: Integer; const Value: Integer);
  public
    property Items[Index: Integer]: Integer read GetItem write SetItem; default;
  end;
  TTMSFNCGridSList = class(TList)
  private
    function GetItem(Index: Integer): Single;
    procedure SetItem(Index: Integer; const Value: Single);
  public
    property Items[Index: Integer]: Single read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridRowInfoList = class(TList<TTMSFNCGridRowInfo>);
  TTMSFNCGridIntegerList = class(TList<Integer>);
  TTMSFNCGridSList = class(TList<Single>);
  {$ENDIF}

  TTMSFNCGridData = class(TTMSFNCCustomScrollControl, ITMSFNCBitmapContainer, ITMSFNCDataBinderGrid, ITMSFNCDataBinderSelection, ITMSFNCAppearanceGlobalFont)
  private
    FDefaultLayout: TTMSFNCGridCellLayout;
    FOldSize: Single;
    FInternalColumnUpdate, FInternalRowUpdate: Boolean;
    FColumnProp: TTMSFNCGridCellProperty;
    FColumns: TTMSFNCGridColumns;
    FUseColumns: Boolean;
    FFirstCellApply: Boolean;
    FBlockUpdate: Boolean;
    FFocusedCell, FStartCell, FStopCell: TTMSFNCGridCellRec;
    FRowList: TTMSFNCGridRowInfoList;
    FRowDisplayList: TTMSFNCGridIntegerList;
    FColumnDisplayList: TTMSFNCGridIntegerList;
    FSuppressedColumnList: TTMSFNCGridIntegerList;
    FHiddenColumnList: TTMSFNCGridIntegerList;
    FHiddenRowList: TTMSFNCGridIntegerList;
    FColSelect: TTMSFNCGridIntList;
    FSelectedCells: TTMSFNCGridCellList;
    FRowCount: integer;
    FFilterApplied: boolean;
    FSortDirection: TTMSFNCGridSortDirection;
    FSortColumn: integer;
    FSortDir: integer;
    FIOOffset: TPoint;
    FFilter: TTMSFNCGridFilter;
    FSortIndexes: TTMSFNCGridSortIndexList;
    FCellCalcList: TTMSFNCGridIntList;
    FSaveHiddenCells: boolean;
    FFindCol, FFindRow: integer;
    FSearchCell: TPoint;
    FSearchCache: string;
    FFindParams: TTMSFNCGridFindParams;
    FFindBusy: boolean;
    FColumnCount: Integer;
    FFixedRows: Integer;
    FFixedColumns: Integer;
    FRowH: TTMSFNCGridSingleList;
    FFixedRightColumns: Integer;
    FColumnW, FOrigColumnW: TTMSFNCGridSingleList;
    FFixedFooterRows: Integer;
    FDefaultRowHeight: Single;
    FDefaultColumnWidth: Single;
    FCheckFalse: string;
    FCheckTrue: string;
    FPrevProgress: smallint;
    FGroupColumn: integer;
    FGroupWidth: single;
    FGroupCaption: string;
    FGroupFooter: string;
    FIsGrouping: boolean;
    FFloatFormat: string;
    FClipTopLeft: TPoint;
    FClipOperation: TTMSFNCClipOperation;
    FColumnOrder: TTMSFNCGridIntList;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FOnIOProgress: TTMSFNCGridIOProgressEvent;
    FOnSortFormat: TTMSFNCGridSortFormatEvent;
    FOnCustomCompare: TTMSFNCGridCustomCompareEvent;
    FOnRawCompare: TTMSFNCGridRawCompareEvent;
    FOptions: TTMSFNCGridOptions;
    FMarkList: TTMSFNCGridCellMarkList;
    FDefaultFont: TTMSFNCGraphicsFont;
    FOnClipboardAfterPasteCell: TTMSFNCGridClipboardAfterPasteEvent;
    FOnClipboardBeforePasteCell: TTMSFNCGridClipboardBeforePasteEvent;
    FOnCellsChanged: TTMSFNCGridCellsChangedEvent;
    FOnClipboardPaste: TTMSFNCGridCellsChangedEvent;
    FOnLoadCell: TTMSFNCGridIOEvent;
    FOnSaveCell: TTMSFNCGridIOEvent;
    FOnNeedFilterDropDown: TTMSFNCGridNeedFilterDropDownEvent;
    FOnNeedFilterDropDownData: TTMSFNCGridNeedFilterDropDownDataEvent;
    FOnFilterSelect: TTMSFNCGridFilterSelectEvent;
    FOnGroupCalc: TTMSFNCGridColumnCalcEvent;
    FOnColumnCalc: TTMSFNCGridColumnCalcEvent;
    FOnAfterApplyFilter: TTMSFNCGridAfterApplyFilterEvent;
    FBlockSelectEventHandler: Boolean;
    FOnSelectedCell: TTMSFNCGridOnSelectedCell;
    FOnSelectCell: TTMSFNCGridOnSelectCell;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    procedure SetDefaultColumnWidth(const Value: Single);
    procedure SetDefaultRowHeight(const Value: Single);
    procedure EnsureRow(Row: integer);
    procedure EnsureCol(Col: integer);
    function GetCells(Col, Row: integer): TTMSFNCGridCellData;
    procedure SetCells(Col, Row: integer; const Value: TTMSFNCGridCellData);
    function GetIntObjects(Col, Row: integer): TObject;
    procedure SetIntObjects(Col, Row: integer; const Value: TObject);
    function GetRowCount: integer;
    function GetHiddenColCount: integer;
    function GetHiddenRowCount: integer;
    function GetTotalColCount: integer;
    function GetTotalRowCount: integer;
    function GetAllCells(Col, Row: integer): string;
    procedure SetAllCells(Col, Row: integer; const Value: string);
    function GetColors(Col, Row: integer): TTMSFNCGraphicsColor;
    procedure SetColors(Col, Row: integer; const Value: TTMSFNCGraphicsColor);
    procedure SetColumnCount(const Value: Integer);
    procedure SetRowCount(const Value: Integer);
    procedure SetColumnW(const Value: TTMSFNCGridSingleList);
    procedure SetFixedColumns(const Value: Integer);
    procedure SetFixedFooterRows(const Value: Integer);
    procedure SetFixedRightColumns(const Value: Integer);
    procedure SetFixedRows(const Value: Integer);
    procedure SetRowH(const Value: TTMSFNCGridSingleList);
    function GetColumnCount: Integer;
    procedure SetColWidths(Col: Integer; const Value: Single);
    procedure SetRowHeights(Row: Integer; const Value: Single);
    function GetSelection: TTMSFNCGridCellRecRange;
    procedure SetSelection(const Value: TTMSFNCGridCellRecRange);
    function GetFocusedCell: TTMSFNCGridCellRec;
    procedure SetFocusedCell(const Value: TTMSFNCGridCellRec);
    function GetCellProps(Col, Row: integer): TTMSFNCGridCellProperty;
    function GetRowSelect(Row: integer): boolean;
    procedure SetSortColumn(const Value: integer);
    procedure SetSortDirection(const Value: TTMSFNCGridSortDirection);
    procedure SetOptions(const Value: TTMSFNCGridOptions);
    function GetObjects(Col, Row: integer): TObject;
    procedure SetObjects(Col, Row: integer; const Value: TObject);
    function GetAllFloats(Col, Row: integer): double;
    procedure SetAllFloats(Col, Row: integer; const Value: double);
    function GetAllInts(Col, Row: integer): Integer;
    procedure SetAllInts(Col, Row: integer; const Value: Integer);
    function GetMergeCellPrintPageNr(Col, Row: integer): integer;
    procedure SetMergeCellPrintPageNr(Col, Row: integer; const Value: integer);
    function GetHorzAlignments(Col, Row: integer): TTMSFNCGraphicsTextAlign;
    procedure SetHorzAlignments(Col, Row: integer; const Value: TTMSFNCGraphicsTextAlign);
    function GetVertAlignments(Col, Row: integer): TTMSFNCGraphicsTextAlign;
    procedure SetVertAlignments(Col, Row: integer; const Value: TTMSFNCGraphicsTextAlign);
    function GetFontStyles(Col, Row: integer): TFontStyles;
    procedure SetFontStyles(Col, Row: integer; const Value: TFontStyles);
    function GetFontSizes(Col, Row: integer): single;
    procedure SetFontSizes(Col, Row: integer; const Value: single);
    function GetFontNames(Col, Row: integer): string;
    procedure SetFontNames(Col, Row: integer; const Value: string);
    function GetFontColors(Col, Row: integer): TTMSFNCGraphicsColor;
    procedure SetFontColors(Col, Row: integer; const Value: TTMSFNCGraphicsColor);
    function GetRowSelectionCount: integer;
    function GetReadOnlys(Col, Row: integer): boolean;
    procedure SetReadOnlys(Col, Row: integer; const Value: boolean);
    function SaveToHTMLString(dir: string): string;
    function GetColumnSelect(Col: integer): boolean;
    function GetColumnSelectionCount: integer;
    procedure SetColumnSelect(Col: integer; const Value: boolean);
    function GetCellSelect(Col, Row: integer): boolean;
    function GetCellSelectionCount: integer;
    procedure SetCellSelect(Col, Row: integer; const Value: boolean);
    function GetSelectedColumn(index: integer): integer;
    function GetSelectedRow(index: integer): integer;
    function GetSelectedCell(index: integer): TTMSFNCGridCellRec;
    function GetCheckBoxState(Col, Row: integer): boolean;
    procedure SetCheckBoxState(Col, Row: integer; const Value: boolean);
    function GetFixedCellSelect(Col, Row: integer): boolean;
    function GetComments(Col, Row: Integer): string;
    procedure SetComments(Col, Row: Integer; const Value: string);
    function GetCommentColors(Col, Row: Integer): TTMSFNCGraphicsColor;
    procedure SetCommentColors(Col, Row: Integer; const Value: TTMSFNCGraphicsColor);
    function GetColumnCalculation(Col: Integer): TTMSFNCGridColumnCalculation;
    procedure SetColumnCalculation(Col: Integer;
      const Value: TTMSFNCGridColumnCalculation);
    procedure UpdateColumnCalc(Col: Integer; Value: TTMSFNCGridColumnCalculation);
    function GetFloats(Col, Row: Integer): double;
    procedure SetFloats(Col, Row: Integer; const Value: double);
    function GetInts(Col, Row: Integer): Integer;
    procedure SetInts(Col, Row: Integer; const Value: Integer);
    function GetStrippedCells(Col, Row: Integer): string;
    function GetRowText(Row: integer): string;
    function GetBitmaps(Col, Row: Integer): TTMSFNCBitmap;
    function GetBooleans(Col, Row: Integer): Boolean;
    procedure SetBitmaps(Col, Row: Integer; const Value: TTMSFNCBitmap);
    procedure SetBooleans(Col, Row: Integer; const Value: Boolean);
    procedure SetColumns(const Value: TTMSFNCGridColumns);
    procedure SetOrigColumnW(const Value: TTMSFNCGridSingleList);
    function IsDefaultColumnWidthStored: Boolean;
    function IsDefaultRowHeightStored: Boolean;
    procedure SetRowText(Row: Integer; const Value: string);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetCellCalcStates(Col, Row: Integer): TTMSFNCGridCellCalcState;
    procedure SetCellCalcStates(Col, Row: Integer;
      const Value: TTMSFNCGridCellCalcState);
    function GetCalcValues(Col, Row: Integer): variant;
    procedure SetCalcValues(Col, Row: Integer; const Value: variant);
    procedure SetDefaultFont(const Value: TTMSFNCGraphicsFont);
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    function GetDataRowCount: Integer;
    function DataGetItemIndex: Integer;
    procedure ClearData;
    procedure SetDataColumnCount(AValue: Integer);
    procedure SetDataRowCount(AValue: Integer);
    procedure SetDataValue(AColumn, ARow: Integer; AValue: string);
    procedure SetDataHeader(AColumn: Integer; AValue: string);
    procedure DataBeginUpdate;
    procedure DataEndUpdate;
    procedure DataInsertRow(AInsertPosition: Integer);
    procedure DataSetItemIndex(AValue: Integer);

    procedure SetRowSelect(Row: integer; const Value: boolean); virtual;
    property RowList: TTMSFNCGridRowInfoList read FRowList;
    property HiddenColumnList: TTMSFNCGridIntegerList read FHiddenColumnList;
    property SuppressedColumnList: TTMSFNCGridIntegerList read FSuppressedColumnList;
    property DefaultFont: TTMSFNCGraphicsFont read FDefaultFont write SetDefaultFont;
    property OldSize: Single read FOldSize;
    function GetColumnDisplayName({%H-}ACol: Integer): string; virtual;
    property FirstCellApply: Boolean read FFirstCellApply write FFirstCellApply;
    property GroupColumn: Integer read FGroupColumn;
    procedure SelectCell({%H-}Cell: TTMSFNCGridCellRec; {%H-}Shift: TShiftState = []; {%H-}MouseDragging: Boolean = False); virtual;
    property StartCell: TTMSFNCGridCellRec read FStartCell write FStartCell;
    property StopCell: TTMSFNCGridCellRec read FStopCell write FStopCell;
    property FocCell: TTMSFNCGridCellRec read FFocusedCell write FFocusedCell;
    procedure SetStartCell(ACol, ARow: Integer);
    procedure SetStopCell(ACol, ARow: Integer);
    procedure SetFocusCell(ACol, ARow: Integer);
    function GetColWidths(AIndex: integer): Single;
    function GetRowHeights(AIndex: integer): Single;
    function CompareLine(Col, Row1, Row2: integer): integer;
    function CompareRows(Col, Row1, Row2: integer): integer;
    function CompareRowsIndexed(Col, Row1, Row2: integer): integer;
    procedure QuickSortRows(Col, L, R: Integer);
    procedure QuickSortRowsIndexed(Col, L, R: Integer);
    procedure SortGroupedInt(Indexed: boolean);
    function MatchFilter(ARow: Integer): Boolean; virtual;
    function MatchCell(Col,Row: Integer): Boolean;
    function MaxCharsInCol(ACol: Integer): Integer;
    procedure InputFromCSVStream(AStream: TStream; {%H-}Encoding: TEncoding; insertmode: Boolean; MaxRows: integer = -1);
    procedure InputFromCSV(FileName: string; {%H-}Encoding: TEncoding; insertmode: Boolean; MaxRows: integer = -1);
    {$IFNDEF WEBLIB}
    procedure OutputToCSV(FileName: string;appendmode: Boolean; {%H-}unicode: Boolean);
    procedure OutputToHTML(FileName: string;appendmode: Boolean; ShowHTML: boolean = false; {%H-}Unicode: boolean = true);
    procedure OutputToAscii(FileName: string;appendmode: Boolean; {%H-}unicode: Boolean);
    {$ENDIF}
    procedure OutputToCSVStream(AStream: TStream; Encoding: TEncoding = nil);
    function FindInternal(StartC: TPoint; s:string; FindParams: TTMSFNCGridFindParams): TPoint;
    function CellNameIndex({%H-}AName: string): integer; virtual;
    procedure UpdateGridCells(AScrollOnly: Boolean = False); virtual;
    property ColumnW: TTMSFNCGridSingleList read FColumnW write SetColumnW;
    property OrigColumnW: TTMSFNCGridSingleList read FOrigColumnW write SetOrigColumnW;
    property RowH: TTMSFNCGridSingleList read FRowH write SetRowH;
    property CellProps[Col,Row: integer]: TTMSFNCGridCellProperty read GetCellProps;
    procedure ExportNotification({%H-}AState: TTMSFNCGridExportState; {%H-}ARow: Integer); virtual;
    procedure ImportNotification({%H-}AState: TTMSFNCGridImportState; {%H-}ARow: Integer); virtual;
    procedure DoRowCountChanged; virtual;
    procedure DoColumnCountChanged; virtual;
    procedure DoIOProgress(CurrentRow,TotalRows: integer); virtual;
    procedure DoSortFormat(Col: integer; var SortFormat: TTMSFNCGridSortFormat; var Prefix, Suffix: string); virtual;
    procedure DoCustomCompare(Value1,Value2: TTMSFNCGridCellData; var res: integer); virtual;
    procedure DoRawCompare(Col, Row1, Row2: integer; var res: integer); virtual;
    procedure DoClipboardBeforePasteCell(Col,Row: integer; var AValue: string; var Allow: boolean); virtual;
    procedure DoClipboardAfterPasteCell(Col,Row: integer; AValue: string); virtual;
    procedure DoNeedFilterDropDown(Col,Row: integer; var Allow: boolean); virtual;
    procedure DoNeedFilterDropDownData(Col,Row: integer; Values: TStrings); virtual;
    procedure DoCellsChanged(Cells: TTMSFNCGridCellRecRange); virtual;
    procedure DoClipboardPaste(Cells: TTMSFNCGridCellRecRange); virtual;
    function DoSaveCell(Col,Row: integer): string; virtual;
    procedure DoLoadCell(Col,Row: integer; var Value: string); virtual;
    procedure DoFilterSelect(Col: integer; var Condition: string); virtual;
    procedure DoAfterApplyFilter(Col: Integer; Condition: String; var UpdateCalculations: Boolean); virtual;

    procedure SetHorizontalScrollBarVisible(const Value: Boolean); override;
    procedure SetVerticalScrollBarVisible(const Value: Boolean); override;

    procedure RemoveCellControl(Col,Row: Integer; AClass: TClass);
    function IsCellControl(Col,Row: Integer; AClass: TClass): boolean;

    function IsIgnoredColumn({%H-}Col: integer): boolean;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure Loaded; override;
    property MergeCellPrintPageNr[Col,Row: integer]: integer read GetMergeCellPrintPageNr write SetMergeCellPrintPageNr;
    function GetDefaultFont(Col,Row: integer): TTMSFNCGraphicsFont;
    function GetDefaultFixedLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultNormalLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultGroupLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultSummaryLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultSelectedLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultFocusedLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultFixedSelectedLayout: TTMSFNCGridCellLayout; virtual;
    function GetDefaultBandLayout: TTMSFNCGridCellLayout; virtual;

    function GetDefaultSortFill: TTMSFNCGraphicsFill; virtual;
    function GetDefaultIndexedSortFill: TTMSFNCGraphicsFill; virtual;
    function GetDefaultSortStroke: TTMSFNCGraphicsStroke; virtual;
    function GetDefaultIndexedSortStroke: TTMSFNCGraphicsStroke; virtual;
    function GetDefaultSortFont: TTMSFNCGraphicsFont; virtual;
    function GetDefaultIndexedSortFont: TTMSFNCGraphicsFont; virtual;

    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;

    procedure DoOptionsChange(Sender: TObject);
    property FixedCellSelect[Col,Row: integer]: boolean read GetFixedCellSelect;

    procedure DoGetCellReadOnly(ACol, ARow: Integer; var AReadOnly: Boolean); virtual;
    function DoGetCellIsDataCheckBox(ACol, ARow: Integer): Boolean; virtual;

    procedure DoFixedCellCheckBoxClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoFixedCellSpinBoxChange({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoFixedCellButtonClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoFixedCellDropDownButtonClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoFixedCellBitmapClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;

    procedure DoCellRightClick({%H-}ACol, {%H-}ARow: Integer); virtual;
    procedure DoFixedCellRightClick({%H-}ACol, {%H-}ARow: Integer); virtual;
    procedure DoCellClick({%H-}ACol, {%H-}ARow: Integer); virtual;
    procedure DoFixedCellClick({%H-}Shift: TShiftState; {%H-}ACol, {%H-}ARow: Integer); virtual;
    procedure DoCellDblClick(ACol, ARow: Integer); virtual;
    procedure DoFixedCellDblClick(ACol, ARow: Integer); virtual;

    procedure DoCellCheckBoxClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoCellRadioButtonClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoCellButtonClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoCellBitmapClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoCellCommentClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoCellSortClick({%H-}Shift: TShiftState; {%H-}ACol, {%H-}ARow: Integer; {%H-}Direction: TTMSFNCGridSortDirection; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure DoCellNodeClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;

    procedure DoGetCellIsFixed({%H-}ACol, {%H-}ARow: Integer; var {%H-}ACellFixed: Boolean); virtual;
    procedure DoGetRowIsBand({%H-}ARow: Integer; var {%H-}ARowBand: Boolean); virtual;
    procedure DoCanInsertRow({%H-}ARow: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoCanDeleteRow({%H-}ARow: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoCanAppendRow({%H-}ARow: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoCanAppendColumn({%H-}ACol: Integer; var {%H-}Allow: Boolean); virtual;

    procedure DoInsertRow({%H-}ARow: Integer); virtual;
    procedure DoDeleteRow({%H-}ARow: Integer); virtual;
    procedure DoAppendRow({%H-}ARow: Integer); virtual;
    procedure DoAppendColumn({%H-}ACol: Integer); virtual;

    procedure DoGetCellClass({%H-}ACol, {%H-}ARow: Integer; var {%H-}CellClassType: TTMSFNCGridCellClass); virtual;
    procedure DoGetCellData({%H-}ACol, {%H-}ARow: Integer; var {%H-}CellString: String); virtual;
    procedure DoGetCellRotation(ACol, ARow: Integer; var Angle: Integer); virtual;
    procedure DoGetCellLayout({%H-}ACol, {%H-}ARow: Integer; {%H-}ALayout: TTMSFNCGridCellLayout; {%H-}ACellState: TTMSFNCGridCellState); virtual;
    procedure DoGetCellProperties({%H-}ACol, {%H-}ARow: Integer; {%H-}Cell: TTMSFNCGridCell); virtual;
    procedure DoGetCellMergeInfo({%H-}ACol, {%H-}ARow: Integer; var {%H-}ABaseCol: Integer; var {%H-}ABaseRow: Integer; var {%H-}AColSpan: Integer; var {%H-}ARowSpan: Integer); virtual;

    procedure DoGetCellEditorType({%H-}ACol, {%H-}ARow: Integer; var {%H-}CellEditorType: TTMSFNCGridEditorType); virtual;
    procedure DoGetCellEditorCustomClassType({%H-}ACol, {%H-}ARow: Integer; var {%H-}CellEditorCustomClassType: TTMSFNCGridEditorClass); virtual;
    procedure DoCellEditGetData({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellString: String); virtual;
    procedure DoCellEditValidateData({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellString: String; var {%H-}Allow: Boolean); virtual;
    procedure DoCellEditSetData({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellString: String); virtual;

    procedure DoCellEditGetColor({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellColor: TTMSFNCGraphicsColor); virtual;
    procedure DoCellEditValidateColor({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellColor: TTMSFNCGraphicsColor; var {%H-}Allow: Boolean); virtual;
    procedure DoCellEditSetColor({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellColor: TTMSFNCGraphicsColor); virtual;

    procedure DoCellEditDone({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor); virtual;
    procedure DoGetCellEditorProperties({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor); virtual;

    function DoColumnCalc({%H-}Acol,{%H-}FromRow, {%H-}ToRow: integer): double; virtual;
    function DoColumnCalcGroup({%H-}Acol,{%H-}FromRow, {%H-}ToRow: integer): double; virtual;

    procedure DoCellAnchorClick({%H-}ACol, {%H-}ARow: Integer; {%H-}AAnchor: String); virtual;
    procedure DoCanEditCell({%H-}ACol, {%H-}ARow: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoCanSizeColumn({%H-}ACol: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoCanSizeRow({%H-}ARow: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoColumnSize({%H-}ACol: Integer; var {%H-}NewWidth: Single); virtual;
    procedure DoRowSize({%H-}ARow: Integer; var {%H-}NewHeight: Single); virtual;
    procedure DoColumnSized({%H-}ACol: Integer; {%H-}NewWidth: Single); virtual;
    procedure DoRowSized({%H-}ARow: Integer; {%H-}NewHeight: Single); virtual;
    procedure DoCanSortColumn({%H-}ACol: Integer; var {%H-}Allow: Boolean); virtual;
    procedure DoColumnSorted({%H-}ACol: Integer; {%H-}Direction: TTMSFNCGridSortDirection); virtual;
    function DoIsCellSelected({%H-}ACol, {%H-}ARow: Integer): boolean; virtual;

    procedure CreateColumnProp;
    procedure DoPasteNotify({%H-}Origin: TPoint; {%H-}ARange: TTMSFNCGridCellRecRange; {%H-}AOperation: TTMSFNCClipOperation); virtual;
    property BlockSelectEventHandler: Boolean read FBlockSelectEventHandler write FBlockSelectEventHandler;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
  public
    property BlockUpdate: Boolean read FBlockUpdate write FBlockUpdate;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Clear;
    procedure ClearSortColumn;
    procedure ClearCells(CellRange: TTMSFNCGridCellRecRange);
    procedure ClearNormalCells;

    procedure Debug;

    procedure GetCellMergeInfo({%H-}ACol, {%H-}ARow: Integer; var ABaseCol, ABaseRow, AColSpan, ARowSpan: Integer);
    function GetCellState({%H-}ACol, {%H-}ARow: Integer): TTMSFNCGridCellState; virtual;
    function GetCellLayout({%H-}ACol, {%H-}ARow: Integer; ACellState: TTMSFNCGridCellState): TTMSFNCGridCellLayout; virtual;
    function DisplToRealRow(Row: integer): integer;
    function RealToDisplRow(Row: integer): integer;

    function DisplToRealColumn(Col: integer): integer;
    function RealToDisplColumn(Col: integer): integer;

    function GetColumnRange(Col: integer; DoFixed: boolean = false): TTMSFNCGridCellRecRange;
    function GetRowRange(Row: integer; DoFixed: boolean = false): TTMSFNCGridCellRecRange;
    function GetGridRange(DoFixed: boolean = false): TTMSFNCGridCellRecRange;
    function GetCellRecRange(Col,Row: Integer): TTMSFNCGridCellRecRange;
    procedure InitOriginalColumnSizes;

    procedure RandomFill(DoFixed: Boolean = false;rnd: Integer = 100);
    procedure LinearFill(DoFixed: Boolean = false);

    property Columns: TTMSFNCGridColumns read FColumns write SetColumns;

    property FloatFormat: string read FFloatFormat write FFloatFormat;
    property ColumnSelectionCount: integer read GetColumnSelectionCount;
    property ColumnSelect[Col: integer]: boolean read GetColumnSelect write SetColumnSelect;

    property CellSelectionCount: integer read GetCellSelectionCount;
    property CellSelect[Col,Row: integer]: boolean read GetCellSelect write SetCellSelect;

    property SelectedRow[index: integer]: integer read GetSelectedRow;
    property SelectedColumn[index: integer]: integer read GetSelectedColumn;
    property SelectedCell[index: integer]: TTMSFNCGridCellRec read GetSelectedCell;

    property RowSelectionCount: integer read GetRowSelectionCount;
    property RowSelect[Row: integer]: boolean read GetRowSelect write SetRowSelect;
    property Selection: TTMSFNCGridCellRecRange read GetSelection write SetSelection;
    property FocusedCell: TTMSFNCGridCellRec read GetFocusedCell write SetFocusedCell;
    function CellRange(AStartCol, AStartRow, AEndCol, AEndRow: Integer): TTMSFNCGridCellRecRange;
    function SingleCell(ACol, ARow: Integer): TTMSFNCGridCellRec;

    function IsReadOnly(Col,Row: integer): boolean;

    procedure ClearRowSelect;
    function GetSelectedRowCount: Integer;
    procedure SelectRows(FromRow, ToRow: integer);
    procedure UnSelectRows(FromRow, ToRow: integer);

    procedure ClearColumnSelect;
    function GetSelectedColumnCount: Integer;
    procedure SelectColumns(FromColumn, ToColumn: integer);
    procedure UnSelectColumns(FromColumn, ToColumn: integer);

    procedure ClearCellSelect;
    function GetSelectedCellCount: Integer;
    procedure SelectCells(ARange: TTMSFNCGridCellRecRange);
    procedure UnSelectCells(ARange: TTMSFNCGridCellRecRange);

    procedure SetColumnOrder;
    procedure ResetColumnOrder;

    function ColumnStatesToString: string;
    procedure StringToColumnStates(Value: string);

    function ColumnPosition(ACol: integer): integer;
    function ColumnAtPosition(ACol: integer): integer;

    function Find(StartC:TPoint; s:string; FindParams: TTMSFNCGridFindParams): TPoint;
    function FindFirst(s:string; FindParams: TTMSFNCGridFindParams): TPoint;
    function FindNext: TPoint;
    function Replace(OrigStr,NewStr: string; FindParams: TTMSFNCGridFindParams): integer;
    function IsFixed(Col, Row: Integer): Boolean; virtual;
    function IsBand(Row: Integer): Boolean; virtual;
    function IsNormalFixed(Col, Row: Integer): Boolean; virtual;
    function NextSelectableRow(Col, Row: Integer): TTMSFNCGridCellRec; virtual;
    function NextSelectableColumn(Col, Row: Integer): TTMSFNCGridCellRec; virtual;
    function PreviousSelectableColumn(Col, Row: Integer): TTMSFNCGridCellRec; virtual;
    function PreviousSelectableRow(Col, Row: Integer): TTMSFNCGridCellRec; virtual;
    procedure ApplyFilter;
    procedure RemoveFilter;
    procedure RemoveFilters;
    property FindCol: integer read FFindCol write FFindCol;
    property FindRow: integer read FFindRow write FFindRow;

    function LookupInColumn(Col: integer; AValue: string; AllRows: boolean = false; AutoGoto: boolean = false): integer;
    function LookupInColumnFromRow(Col,Row: integer; AValue: string; AllRows: boolean = false; AutoGoto: boolean = false): integer;

    property SortColumn: integer read FSortColumn write SetSortColumn;
    property SortDirection: TTMSFNCGridSortDirection read FSortDirection write SetSortDirection;
    property IOOffset: TPoint read FIOOffset write FIOOffset;
    property Filter: TTMSFNCGridFilter read FFilter;
    procedure AutoNumberCol(Col: integer; StartRow: Integer = 0; StartValue: Integer = 0);
    procedure AutoNumberRow(Row: integer; StartColumn: Integer = 0; StartValue: Integer = 0);

    procedure AddHeaderCheckBox(Col,Row: integer; State: boolean = false);
    procedure AddCheckBox(Col,Row: integer; State: boolean = false);
    procedure AddCheckBoxColumn(Col: integer);
    procedure RemoveCheckBox(Col,Row: integer);
    function IsCheckBox(Col,Row: integer): boolean;
    property CheckBoxState[Col,Row: integer]: boolean read GetCheckBoxState write SetCheckBoxState;

    procedure AddDataCheckBox(Col,Row: integer; State: boolean = false);
    procedure AddDataCheckBoxColumn(Col: integer);

    procedure AddRadioButton(Col,Row,Index: integer; State: boolean = false);
    procedure AddRadioButtonColumn(Col,Index: integer);
    procedure RemoveRadioButton(Col,Row: integer);
    function IsRadioButton(Col,Row: integer): boolean;
    function RadioButtonState(Col,Row: integer): boolean;

    procedure AddRotated(Col, Row: Integer; AAngle: Integer; const Value: TTMSFNCGridCellData);
    procedure SetRotated(Col, Row: Integer; AAngle: Integer);
    procedure RemoveRotated(Col, Row: Integer);
    function IsRotated(Col, Row: Integer; var AAngle: Integer): Boolean;

    procedure AddButton(Col,Row: integer; AText: string; AWidth: integer = 20; AHeight: Integer = 20);
    function IsButton(Col,Row: integer): boolean;
    procedure RemoveButton(Col,Row: integer);

    procedure AddBitmap(Col,Row: Integer; AName: string); overload;
    procedure AddBitmap(Col,Row: Integer; ABitmap: TTMSFNCBitmap); overload;
    {$IFNDEF WEBLIB}
    procedure AddBitmapFile(Col,Row: Integer; AFileName: string);
    {$ENDIF}
    function CreateBitmap(Col,Row: Integer): TTMSFNCBitmap;
    function CreateCheck(Col,Row: Integer): Boolean;

    procedure AddDataBitmap(Col,Row: Integer);

    procedure RemoveBitmap(Col,Row: Integer);
    function IsBitmap(Col,Row: integer): boolean;
    function GetBitmap(Col,Row: integer): TTMSFNCBitmap;
    procedure SetBitmapName(Col,Row: integer; AName: string);
    function GetBitmapName(Col,Row: integer): string;

    procedure AddProgressBar(Col,Row: Integer; Value: Single);
    procedure AddDataProgressBar(Col,Row: Integer);
    procedure SetProgressBarValue(Col,Row: Integer; Value: single);
    function GetProgressBarValue(Col,Row: integer): single;
    function IsProgressBar(Col,Row: Integer): boolean;
    procedure RemoveProgressBar(Col,Row: Integer);

    procedure AddSummary(Row: integer);
    procedure RemoveSummary(Row: integer);
    function IsSummary(Row: integer): boolean;
    procedure AddNode(Row, Span: Integer);
    procedure RemoveNode(Row: Integer);
    function IsNode(Row: Integer): boolean;
    procedure SetNodeState(Row: Integer; State: TTMSFNCGridNodeState);
    function GetNodeState(Row: integer): TTMSFNCGridNodeState;
    procedure SetNodeSpan(Row: Integer; Span: Integer);
    function GetNodeSpan(Row: Integer): Integer;
    function GetNode(Row: Integer): TTMSFNCGridCellNode;
    procedure OpenNode(Row: Integer);
    procedure CloseNode(Row: Integer);
    procedure OpenAllNodes;
    procedure CloseAllNodes;

    procedure AddComboBox(Col,Row: Integer; Items: TStrings);
    procedure RemoveComboBox(Col,Row: Integer);
    function IsComboBox(Col,Row: integer): boolean;
    function GetComboIndex(Col,Row: integer): integer;
    procedure SetComboIndex(Col,Row,Value: integer);

    procedure MergeSelection(ASelection: TTMSFNCGridCellRecRange);
    procedure MergeCells(Col,Row,ColCount,RowCount: integer);
    function IsMerged(Col,Row: integer): boolean;
    function IsYMergedCell(Col,Row: integer): boolean;
    function IsXMergedCell(Col,Row: integer): boolean;
    function IsBaseCell(Col,Row: integer): boolean;
    function ColSpan(Col,Row: integer): integer;
    function RowSpan(Col,Row: integer): integer;
    function CellSpan(Col,Row: integer): TPoint;
    function GetCellSize(Col, Row: Integer): TSizeF;
    function BaseCell(Col,Row: integer): TTMSFNCGridCellRec;
    function IsBaseCellEx(Col,Row: integer; var BaseCol,BaseRow: integer): boolean;
    procedure SplitCell(Col,Row: integer);

    procedure DeleteRow(Row: integer);
    procedure DeleteRows(Row,Count: integer); virtual;
    procedure InsertRow(Row: integer);
    procedure InsertRows(Row,Count: integer); virtual;
    procedure SwapCells(Col, Row1, Row2: integer);
    procedure SwapNormalCells(Row1, Row2: integer);
    procedure SwapRows(Row1, Row2: integer);
    procedure MoveRow(FromRow, ToRow: integer);

    procedure HighlightInCell(DoCase: Boolean; Col,Row: Integer; HiText: string);
    procedure HighlightInCol(DoFixed,DoCase: Boolean; Col: Integer; HiText: string);
    procedure HighlightInRow(DoFixed,DoCase: Boolean; Row: Integer; HiText: string);
    procedure HighlightInGrid(DoFixed,DoCase: Boolean; HiText: string);
    procedure UnHighlightInCell(Col,Row: Integer);
    procedure UnHighlightInCol(DoFixed: Boolean; Col: Integer);
    procedure UnHighlightInRow(DoFixed: Boolean; Row: Integer);
    procedure UnHighlightInGrid(DoFixed: Boolean);
    procedure UnHighlightAll;
    procedure MarkInCell(DoCase: Boolean; Col,Row: Integer; HiText: string);
    procedure MarkInCol(DoFixed,DoCase: Boolean; Col: Integer; HiText: string);
    procedure MarkInRow(DoFixed,DoCase: Boolean; Row: Integer; HiText: string);
    procedure MarkInGrid(DoFixed,DoCase: Boolean; HiText: string);
    procedure UnMarkInCell(Col,Row: Integer);
    procedure UnMarkInCol(DoFixed: Boolean; Col: Integer);
    procedure UnMarkInRow(DoFixed: Boolean; Row: Integer);
    procedure UnMarkInGrid(DoFixed: Boolean);
    procedure UnMarkAll;

    procedure UnGroup;
    procedure Group(Column: integer);

    procedure GroupCalc(Col,method: Integer);
    procedure GroupSum(Col: Integer);
    procedure GroupAvg(Col: Integer);
    procedure GroupMin(Col: Integer);
    procedure GroupMax(Col: Integer);

    function ColumnSum(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnAvg(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnMin(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnMax(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnDistinct(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnStdDev(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnCustomCalc(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;
    function ColumnCustomCalcGroup(ACol: integer; FromRow: integer = -1; ToRow: Integer = -1): Double;

    procedure UpdateCalculations;
    procedure UpdateCalculation(ACol: integer);

    procedure GroupCustomCalc(Col: Integer);
    procedure GroupCount(Col: Integer);
    procedure GroupDistinct(Col: Integer);
    procedure GroupStdDev(Col: Integer);

    procedure HideRow(Row: integer);
    procedure HideRows(Row: integer; Count: integer);
    procedure UnHideRow(Row: integer);
    procedure UnHideRows(Row: integer; Count: integer);
    procedure UnHideRowsAll;
    function IsHiddenRow(Row: integer): boolean;

    procedure UpdateColumnWidth(ACol: Integer; AWidth: Single);
    procedure UpdateRowHeight(ARow: Integer; AHeight: Single);

    procedure HideColumn(Col: integer);
    procedure HideColumns(Col: integer; Count: integer);
    procedure UnHideColumn(Col: integer);
    procedure UnHideColumns(Col: integer; Count: integer);
    procedure UnHideColumnsAll;
    function IsHiddenColumn(Col: integer): boolean;

    procedure SuppressColumn(Col: integer);
    procedure SuppressColumns(Col: integer; Count: integer);
    procedure UnSuppressColumn(Col: integer);
    procedure UnSuppressColumns(Col: integer; Count: integer);
    procedure UnSuppressColumnsAll;
    function IsSuppressedColumn(Col: integer): boolean;

    procedure DeleteColumn(Col: integer); virtual;
    procedure InsertColumn(Col: integer); virtual;
    procedure MoveColumn(FromCol, ToCol: integer);
    procedure SwapColumns(Col1,Col2: integer);

    procedure SortData(Column: integer; Direction: TTMSFNCGridSortDirection);
    procedure SortIndexed;
    procedure SortGrouped(Column: integer; Direction: TTMSFNCGridSortDirection);
    procedure SortGroupedIndexed;
    property SortIndexes: TTMSFNCGridSortIndexList read FSortIndexes;

    property Cells[Col,Row: Integer]: TTMSFNCGridCellData read GetCells write SetCells;
    property StrippedCells[Col,Row: Integer]: string read GetStrippedCells;
    property RowText[Row: Integer]: string read GetRowText write SetRowText;
    property ColumnWidths[Col: Integer]: Single read GetColWidths write SetColWidths;
    property RowHeights[Row: Integer]: Single read GetRowHeights write SetRowHeights;
    property AllCells[Col,Row: Integer]: string read GetAllCells write SetAllCells;
    property AllFloats[Col,Row: Integer]: double read GetAllFloats write SetAllFloats;
    property AllInts[Col,Row: Integer]: Integer read GetAllInts write SetAllInts;
    property Bitmaps[Col,Row: Integer]: TTMSFNCBitmap read GetBitmaps write SetBitmaps;
    property Booleans[Col,Row: Integer]: Boolean read GetBooleans write SetBooleans;
    property Floats[Col,Row: Integer]: double read GetFloats write SetFloats;
    property Ints[Col,Row: Integer]: Integer read GetInts write SetInts;
    property CalcStates[Col,Row: Integer]: TTMSFNCGridCellCalcState read GetCellCalcStates write SetCellCalcStates;
    property CalcValues[Col,Row: Integer]: variant read GetCalcValues write SetCalcValues;
    property Colors[Col,Row: Integer]: TTMSFNCGraphicsColor read GetColors write SetColors;
    property HorzAlignments[Col,Row: Integer]: TTMSFNCGraphicsTextAlign read GetHorzAlignments write SetHorzAlignments;
    property VertAlignments[Col,Row: Integer]: TTMSFNCGraphicsTextAlign read GetVertAlignments write SetVertAlignments;
    property FontSizes[Col,Row: Integer]: single read GetFontSizes write SetFontSizes;
    property FontStyles[Col,Row: Integer]: TFontStyles read GetFontStyles write SetFontStyles;
    property FontNames[Col,Row: Integer]: string read GetFontNames write SetFontNames;
    property FontColors[Col,Row: Integer]: TTMSFNCGraphicsColor read GetFontColors write SetFontColors;
    property ReadOnlys[Col,Row: Integer]: boolean read GetReadOnlys write SetReadOnlys;
    property Objects[Col,Row: Integer]: TObject read GetObjects write SetObjects;
    property Comments[Col,Row: Integer]: string read GetComments write SetComments;
    property CommentColors[Col,Row: Integer]: TTMSFNCGraphicsColor read GetCommentColors write SetCommentColors;
    property IntObjects[Col,Row: integer]: TObject read GetIntObjects write SetIntObjects;

    property ColumnCalculation[Col: Integer]: TTMSFNCGridColumnCalculation read GetColumnCalculation write SetColumnCalculation;

    property HiddenRowCount: Integer read GetHiddenRowCount;
    property HiddenColumnCount: Integer read GetHiddenColCount;
    property SaveHiddenCells: Boolean read FSaveHiddenCells write FSaveHiddenCells;
    property TotalRowCount: Integer read GetTotalRowCount;
    property TotalColCount: Integer read GetTotalColCount;

    procedure CopyToClipboard(SelectedCells: boolean = True);
    procedure CutToClipboard(SelectedCells: boolean = True);
    procedure PasteFromClipboard;

    procedure LoadFromCSV(FileName: string;  MaxRows: Integer = -1); overload;
    procedure InsertFromCSV(FileName: String; MaxRows: Integer = -1); overload;
    procedure LoadFromCSV(FileName: string; Encoding: TEncoding; MaxRows: Integer = -1); overload;
    procedure InsertFromCSV(FileName: String; Encoding: TEncoding; MaxRows: Integer = -1); overload;

    procedure LoadFromCSVStream(AStream: TStream;  MaxRows: Integer = -1); overload;
    procedure InsertFromCSVStream(AStream: TStream; MaxRows: Integer = -1); overload;
    procedure LoadFromCSVStream(AStream: TStream; Encoding: TEncoding; MaxRows: Integer = -1); overload;
    procedure InsertFromCSVStream(AStream: TStream; Encoding: TEncoding; MaxRows: Integer = -1); overload;

    procedure LoadFromFile(FileName: string);
    {$IFNDEF WEBLIB}
    procedure LoadXMLFromFile(AFileName: string; LoadFieldDescr:Boolean = true; IgnoreFixedColumns: Boolean = false);
    {$ENDIF}
    procedure LoadXMLFromStream(const AStream: TStream; LoadFieldDescr:Boolean = true; IgnoreFixedColumns: Boolean = false);
    procedure LoadXMLFromText(AText: string; LoadFieldDescr:Boolean = true; IgnoreFixedColumns: Boolean = false);
    procedure SaveToCSVStream(AStream: TStream; Encoding: TEncoding = nil);

    {$IFNDEF WEBLIB}
    procedure LoadFromFixed(FileName:string;positions: TTMSFNCGridIntList; DoTrim: boolean = true; MaxRows: integer = -1);
    procedure LoadFromStream(Stream: TStream);

    procedure SaveToFile(FileName: string; {%H-}Unicode: boolean = true);
    procedure SaveToCSV(FileName: string; {%H-}Unicode: boolean = true);
    procedure AppendToCSV(FileName: string; {%H-}Unicode: boolean = true);

    procedure AppendToHTML(FileName: string; ShowHTML: boolean = false; {%H-}Unicode: boolean = true);
    procedure SaveToHTML(FileName: string; ShowHTML: boolean = false; {%H-}Unicode: boolean = true);

    procedure SaveToASCII(FileName: string; {%H-}Unicode: boolean = true);
    procedure AppendToASCII(FileName: string; {%H-}Unicode: boolean = true);

    procedure SaveToFixed(FileName: string; Positions: TTMSFNCGridIntList);
    procedure SaveToStream(Stream: TStream);

    procedure SaveToXML(FileName: string; ListDescr, RecordDescr:string;FieldDescr: TStrings; ExportEmptyCells: boolean = false);
    {$ENDIF}

    property CheckTrue: string read FCheckTrue write FCheckTrue;
    property CheckFalse: string read FCheckFalse write FCheckFalse;
    property UseColumns: Boolean read FUseColumns write FUseColumns;

  published
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;

    property DefaultRowHeight: Single read FDefaultRowHeight write SetDefaultRowHeight stored IsDefaultRowHeightStored nodefault;
    property DefaultColumnWidth: Single read FDefaultColumnWidth write SetDefaultColumnWidth stored IsDefaultColumnWidthStored nodefault;

    property FixedColumns: Integer read FFixedColumns write SetFixedColumns default 1;
    property FixedRows: Integer read FFixedRows write SetFixedRows default 1;
    property FixedRightColumns: Integer read FFixedRightColumns write SetFixedRightColumns default 0;
    property FixedFooterRows: Integer read FFixedFooterRows write SetFixedFooterRows default 0;

    property ColumnCount: Integer read GetColumnCount write SetColumnCount default 5;
    property RowCount: Integer read GetRowCount write SetRowCount default 10;

    property OnColumnCalc: TTMSFNCGridColumnCalcEvent read FOnColumnCalc write FOnColumnCalc;
    property OnGroupCalc: TTMSFNCGridColumnCalcEvent read FOnGroupCalc write FOnGroupCalc;
    property OnCustomCompare: TTMSFNCGridCustomCompareEvent read FOnCustomCompare write FOnCustomCompare;
    property OnIOProgress: TTMSFNCGridIOProgressEvent read FOnIOProgress write FOnIOProgress;
    property OnRawCompare: TTMSFNCGridRawCompareEvent read FOnRawCompare write FOnRawCompare;
    property OnSortFormat: TTMSFNCGridSortFormatEvent read FOnSortFormat write FOnSortFormat;
    property OnCellsChanged: TTMSFNCGridCellsChangedEvent read FOnCellsChanged write FOnCellsChanged;
    property OnClipboardBeforePasteCell: TTMSFNCGridClipboardBeforePasteEvent read FOnClipboardBeforePasteCell write FOnClipboardBeforePasteCell;
    property OnClipboardAfterPasteCell: TTMSFNCGridClipboardAfterPasteEvent read FOnClipboardAfterPasteCell write FOnClipboardAfterPasteCell;
    property OnClipboardPaste: TTMSFNCGridCellsChangedEvent read FOnClipboardPaste write FOnClipboardPaste;
    property OnNeedFilterDropDown: TTMSFNCGridNeedFilterDropDownEvent read FOnNeedFilterDropDown write FOnNeedFilterDropDown;
    property OnNeedFilterDropDownData: TTMSFNCGridNeedFilterDropDownDataEvent read FOnNeedFilterDropDownData write FOnNeedFilterDropDownData;
    property OnFilterSelect: TTMSFNCGridFilterSelectEvent read FOnFilterSelect write FOnFilterSelect;
    property OnAfterApplyFilter: TTMSFNCGridAfterApplyFilterEvent read FOnAfterApplyFilter write FOnAfterApplyFilter;
    property OnLoadCell: TTMSFNCGridIOEvent read FOnLoadCell write FOnLoadCell;
    property OnSaveCell: TTMSFNCGridIOEvent read FOnSaveCell write FOnSaveCell;
    property Options: TTMSFNCGridOptions read FOptions write SetOptions;
    property OnSelectCell: TTMSFNCGridOnSelectCell read FOnSelectCell write FOnSelectCell;
    property OnSelectedCell: TTMSFNCGridOnSelectedCell read FOnSelectedCell write FOnSelectedCell;
  end;

  function MakeCellRange(AStartCol, AStartRow, AEndCol, AEndRow: Integer): TTMSFNCGridCellRecRange;
  function EqualsCellRange(Range1,Range2: TTMSFNCGridCellRecRange): boolean;
  function InCellRange(Col,Row: integer; Range: TTMSFNCGridCellRecRange): boolean;
  function MakeCell(ACol,ARow: integer): TTMSFNCGridCellRec;

implementation

uses
  RTLConsts, Math, {$IFNDEF WEBLIB}Variants, {$ENDIF}WEBLib.TMSFNCUtils;

function MakeCell(ACol,ARow: integer): TTMSFNCGridCellRec;
begin
  Result.Col := ACol;
  Result.Row := ARow;
end;

function MakeCellRange(AStartCol, AStartRow, AEndCol, AEndRow: Integer): TTMSFNCGridCellRecRange;
begin
  Result.StartCol := AStartCol;
  Result.StartRow := AStartRow;
  Result.EndCol := AEndCol;
  Result.EndRow := AEndRow;
end;

function EqualsCellRange(Range1,Range2: TTMSFNCGridCellRecRange): boolean;
begin
  Result := (Range1.StartCol = Range2.StartCol) and
            (Range1.StartRow = Range2.StartRow) and
            (Range1.EndCol = Range2.EndCol) and
            (Range1.EndRow = Range2.EndRow);
end;

function InCellRange(Col,Row: integer; Range: TTMSFNCGridCellRecRange): boolean;
begin
  Result := (Col >= Range.StartCol) and (Col <= Range.EndCol) and
            (Row >= Range.StartRow) and (Row <= Range.EndRow);
end;

{ TTMSFNCGridCellRec }

{$IFDEF LCLLIB}
class operator TTMSFNCGridCellRec.=(z1, z2: TTMSFNCGridCellRec)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

{$IFDEF VARIANTLIST}

{ TTMSFNCGridVariantList }

destructor TTMSFNCGridVariantList.Destroy;
begin
  FOnChange := nil;
  FOnChanging := nil;

  inherited Destroy;
  FCount := 0;
  SetCapacity(0);
end;

function TTMSFNCGridVariantList.Add(const S: TTMSFNCGridCellData): Integer;
begin
  Result := AddObject(S, nil);
end;

function TTMSFNCGridVariantList.AddObject(const S: TTMSFNCGridCellData; AObject: TObject): Integer;
begin
  if not Sorted then
    Result := FCount
  else
    if Find(S, Result) then
      case Duplicates of
        dupIgnore: Exit;
        dupError: Error(SDuplicateString, 0);
      end;
  InsertItem(Result, S, AObject);
end;

procedure TTMSFNCGridVariantList.Assign(Source: TPersistent);
begin
  inherited Assign(Source);
  if Source is TTMSFNCGridVariantList then
  begin
    FCaseSensitive := TTMSFNCGridVariantList(Source).FCaseSensitive;
    FDuplicates := TTMSFNCGridVariantList(Source).FDuplicates;
    FSorted := TTMSFNCGridVariantList(Source).FSorted;
  end;
end;

procedure TTMSFNCGridVariantList.BeginUpdate;
begin
  if FUpdateCount = 0 then SetUpdateState(True);
  Inc(FUpdateCount);
end;

procedure TTMSFNCGridVariantList.Changed;
begin
  if (FUpdateCount = 0) and Assigned(FOnChange) then
    FOnChange(Self);
end;

procedure TTMSFNCGridVariantList.Changing;
begin
  if (FUpdateCount = 0) and Assigned(FOnChanging) then
    FOnChanging(Self);
end;

procedure TTMSFNCGridVariantList.Clear;
begin
  if FCount <> 0 then
  begin
    Changing;

    FCount := 0;
    SetCapacity(0);

    Changed;
  end;
end;

procedure TTMSFNCGridVariantList.Delete(Index: Integer);
var
  Obj: TObject;
begin
  if (Index < 0) or (Index >= FCount) then
    Error(SListIndexError, Index);

  Changing;

  if OwnsObjects then
    Obj := FList[Index].FObject
  else
    Obj := nil;

  Finalize(FList[Index]);
  Dec(FCount);
  if Index < FCount then
  begin
    System.Move(FList[Index + 1], FList[Index],
      (FCount - Index) * SizeOf(TTMSFNCGridVariantItem));

    PPointer(@FList[FCount])^ := nil;
  end;
  if Obj <> nil then
    Obj.Free;
  Changed;
end;

procedure TTMSFNCGridVariantList.Error(const Msg: string; Data: Integer);
begin
  raise EStringListError.CreateFmt(Msg, [Data]) at
    PPointer(PByte(@Msg) + SizeOf(Msg) + SizeOf(Self) + SizeOf(Pointer))^;
end;

procedure TTMSFNCGridVariantList.EndUpdate;
begin
  Dec(FUpdateCount);
  if FUpdateCount = 0 then SetUpdateState(False);
end;

procedure TTMSFNCGridVariantList.Exchange(Index1, Index2: Integer);
begin
  if (Index1 < 0) or (Index1 >= FCount) then Error(SListIndexError, Index1);
  if (Index2 < 0) or (Index2 >= FCount) then Error(SListIndexError, Index2);
  Changing;
  ExchangeItems(Index1, Index2);
  Changed;
end;

procedure TTMSFNCGridVariantList.ExchangeItems(Index1, Index2: Integer);
var
  Temp: Pointer;
  Item1, Item2: PStringItem;
begin
  Item1 := @FList[Index1];
  Item2 := @FList[Index2];
  Temp := Pointer(Item1^.FString);
  Pointer(Item1^.FString) := Pointer(Item2^.FString);
  Pointer(Item2^.FString) := Temp;
  Temp := Item1^.FObject;
  Item1^.FObject := Item2^.FObject;
  Item2^.FObject := TObject(Temp);
end;

function TTMSFNCGridVariantList.Find(const S: TTMSFNCGridCellData; var Index: Integer): Boolean;
var
  L, H, I, C: Integer;
begin
  Result := False;
  L := 0;
  H := FCount - 1;
  while L <= H do
  begin
    I := (L + H) shr 1;
    C := CompareStrings(FList[I].FString, S);
    if C < 0 then L := I + 1 else
    begin
      H := I - 1;
      if C = 0 then
      begin
        Result := True;
        if Duplicates <> dupAccept then L := I;
      end;
    end;
  end;
  Index := L;
end;

function TTMSFNCGridVariantList.Get(Index: Integer): TTMSFNCGridCellData;
begin
  if Cardinal(Index) >= Cardinal(FCount) then
    Error(SListIndexError, Index);
  Result := FList[Index].FString;
end;

function TTMSFNCGridVariantList.GetCapacity: Integer;
begin
  Result := FCapacity;
end;

function TTMSFNCGridVariantList.GetCount: Integer;
begin
  Result := FCount;
end;

function TTMSFNCGridVariantList.GetObject(Index: Integer): TObject;
begin
  if Cardinal(Index) >= Cardinal(FCount) then
    Error(SListIndexError, Index);
  Result := FList[Index].FObject;
end;

function TTMSFNCGridVariantList.GetAsText(Delim: Char): string;
var
  i: integer;
  delimch: char;
begin
  Result := '';
  delimch := Delim;
  if delimch = #0 then
    delimch := ',';

  for i := 0 to Count - 1 do
  begin
    if (i > 0) and (i <= Count - 1) then
      Result := Result + delimch;
    Result := Result + FList[i].FString;
  end;
end;

function TTMSFNCGridVariantList.GetTextStr: string;
begin
  Result := GetAsText(',');
end;

function TTMSFNCGridVariantList.GetVariant(Index: Integer): TTMSFNCGridCellData;
begin
  if Cardinal(Index) >= Cardinal(FCount) then
    Error(SListIndexError, Index);
  Result := FList[Index].FString;
end;

procedure TTMSFNCGridVariantList.Grow;
var
  Delta: Integer;
begin
  if FCapacity > 64 then Delta := FCapacity div 4 else
    if FCapacity > 8 then Delta := 16 else
      Delta := 4;
  SetCapacity(FCapacity + Delta);
end;

function TTMSFNCGridVariantList.IndexOf(const S: TTMSFNCGridCellData): Integer;
begin
  if not Sorted then Result := IndexOf(S) else
    if not Find(S, Result) then Result := -1;
end;

procedure TTMSFNCGridVariantList.Insert(Index: Integer; const S: TTMSFNCGridCellData);
begin
  InsertObject(Index, S, nil);
end;

procedure TTMSFNCGridVariantList.InsertObject(Index: Integer; const S: TTMSFNCGridCellData;
  AObject: TObject);
begin
  if Sorted then Error(SSortedListError, 0);
  if (Index < 0) or (Index > FCount) then Error(SListIndexError, Index);
  InsertItem(Index, S, AObject);
end;

procedure TTMSFNCGridVariantList.Move(CurIndex, NewIndex: Integer);
var
  TempObject: TObject;
  TempString: TTMSFNCGridCellData;
begin
  if CurIndex <> NewIndex then
  begin
    BeginUpdate;
    try
      TempString := Get(CurIndex);
      TempObject := GetObject(CurIndex);
      PutObject(CurIndex, nil);
      Delete(CurIndex);
      InsertObject(NewIndex, TempString, TempObject);
    finally
      EndUpdate;
    end;
  end;
end;

procedure TTMSFNCGridVariantList.InsertItem(Index: Integer; const S: TTMSFNCGridCellData; AObject: TObject);
begin
  Changing;
  if FCount = FCapacity then Grow;
  if Index < FCount then
    System.Move(FList[Index], FList[Index + 1],
      (FCount - Index) * SizeOf(TTMSFNCGridVariantItem));

  with FList[Index] do
  begin
    Pointer(FString) := nil;
    FObject := AObject;
    FString := S;
  end;
  Inc(FCount);
  Changed;
end;

procedure TTMSFNCGridVariantList.Put(Index: Integer; const S: TTMSFNCGridCellData);
begin
  if Sorted then Error(SSortedListError, 0);
  if Cardinal(Index) >= Cardinal(FCount) then
    Error(SListIndexError, Index);
  Changing;
  FList[Index].FString := S;
  Changed;
end;

procedure TTMSFNCGridVariantList.PutObject(Index: Integer; AObject: TObject);
begin
  if Cardinal(Index) >= Cardinal(FCount) then
    Error(SListIndexError, Index);
  Changing;
  FList[Index].FObject := AObject;
  Changed;
end;

procedure TTMSFNCGridVariantList.PutVariant(Index: Integer; const Value: TTMSFNCGridCellData);
begin
  if Sorted then Error(SSortedListError, 0);
  if Cardinal(Index) >= Cardinal(FCount) then
    Error(SListIndexError, Index);
  Changing;
  FList[Index].FString := Value;
  Changed;
end;

procedure TTMSFNCGridVariantList.QuickSort(L, R: Integer; SCompare: TTMSFNCGridVariantListSortCompare);
var
  I, J, P: Integer;
begin
  repeat
    I := L;
    J := R;
    P := (L + R) shr 1;
    repeat
      while SCompare(Self, I, P) < 0 do Inc(I);
      while SCompare(Self, J, P) > 0 do Dec(J);
      if I <= J then
      begin
        if I <> J then
          ExchangeItems(I, J);
        if P = I then
          P := J
        else if P = J then
          P := I;
        Inc(I);
        Dec(J);
      end;
    until I > J;
    if L < J then QuickSort(L, J, SCompare);
    L := I;
  until I >= R;
end;

procedure TTMSFNCGridVariantList.SetCapacity(NewCapacity: Integer);
begin
  if NewCapacity < FCount then
    Error(SListCapacityError, NewCapacity);
  if NewCapacity <> FCapacity then
  begin
    SetLength(FList, NewCapacity);
    FCapacity := NewCapacity;
  end;
end;

procedure TTMSFNCGridVariantList.SetSorted(Value: Boolean);
begin
  if FSorted <> Value then
  begin
    if Value then Sort;
    FSorted := Value;
  end;
end;

procedure TTMSFNCGridVariantList.SetUpdateState(Updating: Boolean);
begin
  if Updating then Changing else Changed;
end;

function StringListCompareStrings(List: TTMSFNCGridVariantList; Index1, Index2: Integer): Integer;
begin
  Result := List.CompareStrings(List.FList[Index1].FString,
                                List.FList[Index2].FString);
end;

procedure TTMSFNCGridVariantList.Sort;
begin
  CustomSort({$IFDEF LCLLIB}@{$ENDIF}StringListCompareStrings);
end;

procedure TTMSFNCGridVariantList.CustomSort(Compare: TTMSFNCGridVariantListSortCompare);
begin
  if not Sorted and (FCount > 1) then
  begin
    Changing;
    QuickSort(0, FCount - 1, Compare);
    Changed;
  end;
end;

function TTMSFNCGridVariantList.CompareStrings(const S1, S2: TTMSFNCGridCellData): Integer;
begin
  Result := AnsiCompareText(S1, S2);
end;

function TTMSFNCGridVariantList.CompareVariants(const S1, S2: TTMSFNCGridCellData): Integer;
begin
  if S1 > S2 then
    Result := 1
  else
    if S1 = S2 then
      Result := 0
    else
      Result := -1;
end;

constructor TTMSFNCGridVariantList.Create;
begin
  inherited Create;
end;

constructor TTMSFNCGridVariantList.Create(OwnsObjects: Boolean);
begin
  inherited Create;
  FOwnsObject := OwnsObjects;
end;

procedure TTMSFNCGridVariantList.SetCaseSensitive(const Value: Boolean);
begin
  if Value <> FCaseSensitive then
  begin
    FCaseSensitive := Value;
    if Sorted then
    begin
      Sorted := False;
      Sorted := True;
    end;
  end;
end;

{$ENDIF}

{ TTMSFNCGridSortIndexList }

procedure TTMSFNCGridSortIndexList.AddIndex(ColumnIndex: Integer; ADirection: TTMSFNCGridSortDirection);
begin
  if ADirection = sdAscending then
    Add(ColumnIndex)
  else
    Add(integer($80000000) or ColumnIndex);
end;

function TTMSFNCGridSortIndexList.FindIndex(ColumnIndex: integer): integer;
var
  i: Integer;
begin
  Result := -1;
  i := 0;
  while i < Count do
  begin
    if Items[i] and $7FFFFFFF = ColumnIndex then
    begin
      Result := i;
      Break;
    end;
    Inc(i);
  end;
end;

function TTMSFNCGridSortIndexList.GetSortColumns(i: Integer): Integer;
begin
  Result := Items[i] and $7FFFFFFF;
end;

function TTMSFNCGridSortIndexList.GetSortDirections(i: Integer): Boolean;
begin
  Result := not ((Items[i] and $80000000) = $80000000);
end;

procedure TTMSFNCGridSortIndexList.LoadFromString(s: string);
var
  a: string;
  i,{%H-}e: integer;
begin
  Clear;

  while length(s) > 0 do
  begin
    if pos(';',s) > 0 then
    begin
      a := copy(s,1,pos(';',s) - 1);
      System.Delete(s,1,pos(';',s));
    end
    else
    begin
      a := s;
      s := '';
    end;

    if length(a) > 1 then
    begin
      val(copy(a,2,length(a)),i,e);
      if a[1] = 'u' then
        AddIndex(i,sdAscending)
      else
        AddIndex(i,sdDescending);
    end;
  end;
end;

function TTMSFNCGridSortIndexList.SaveToString: string;
var
  i: integer;
  s,a: string;
begin
  s := '';
  a := '';
  for i := 0 to Count - 1 do
  begin
    if Items[i] and $80000000 = $80000000 then
    begin
      a := 'd' + inttostr($FFFF and Items[i]);
    end
    else
    begin
      a := 'u' + inttostr($FFFF and Items[i]);
    end;
    if s = '' then
      s := a
    else
      s := s + ';'+ a;
  end;
  Result := s;
end;

procedure TTMSFNCGridSortIndexList.SetSortColumns(i: Integer; const Value: Integer);
begin
  Items[i] := (DWord(Value) and $7FFFFFFF) + (Items[i] and $80000000);
end;

procedure TTMSFNCGridSortIndexList.SetSortDirections(i: Integer;
  const Value: Boolean);
begin
  if Value then
    Items[i] := (Items[i] and $7FFFFFFF)
  else
    Items[i] := (DWord(Items[i]) or $80000000);
end;

procedure TTMSFNCGridSortIndexList.ToggleIndex(ColumnIndex: integer);
var
  i: Integer;
begin
  i := 0;
  while i < Count do
  begin
    if Items[i] and $7FFFFFFF = ColumnIndex then
    begin
      if Items[i] and $80000000 = $80000000 then
        Items[i] := Items[i] and $7FFFFFFF
      else
        Items[i] := Items[i] or Integer($80000000);
      Break;
    end;
    Inc(i);
  end;
end;


{ TTMSFNCGridData }

{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.AppendToASCII(FileName: string; Unicode: boolean);
begin
  OutputToASCII(FileName, true, Unicode);
end;

procedure TTMSFNCGridData.AppendToCSV(FileName: string; Unicode: boolean);
begin
  OutputToCSV(FileName,True,Unicode);
end;

procedure TTMSFNCGridData.AppendToHTML(FileName: string; ShowHTML,
  Unicode: boolean);
begin
  OutputToHTML(Filename, true, ShowHTML, Unicode);
end;
{$ENDIF}

procedure TTMSFNCGridData.ApplyFilter;
var
  i,h: Integer;
  HideList: TTMSFNCGridIntegerList;
  cnd: boolean;

begin
  HideList := TTMSFNCGridIntegerList.Create;

  try
    for i := FixedRows to RowCount - 1 do
    begin
      cnd := (Options.Filtering.Rows = frAll) or
             (Options.Filtering.Rows = frNormal) and not IsNode(i) and not IsSummary(i) and not IsFixed(FixedColumns,i);

      if not MatchFilter(i) and cnd then
      begin
        HideList.Add(i);
      end;
    end;

    h := 0;

    for i := 0 to HideList.Count - 1 do
    begin
      while FRowDisplayList[h] <> HideList[i] do
        inc(h);

      if FRowDisplayList[h] = HideList[i] then
        FRowDisplayList.Delete(h);
    end;

    FRowCount := FRowCount - HideList.Count;

  finally
    HideList.Free;
    FFilterApplied := true;
    UpdateControl;
  end;
end;

procedure TTMSFNCGridData.AutoNumberCol(Col: integer; StartRow: Integer = 0; StartValue: Integer = 0);
var
  i: integer;
begin
  for i := StartRow to FRowCount - 1 do
  begin
    Cells[Col,i] := inttostr(StartValue);
    Inc(StartValue);
  end;
end;

procedure TTMSFNCGridData.AutoNumberRow(Row: integer; StartColumn: Integer = 0; StartValue: Integer = 0);
var
  i: integer;
begin
  for i := StartColumn to ColumnCount - 1 do
  begin
    Cells[i, Row] := inttostr(StartValue);
    Inc(StartValue);
  end;
end;

function TTMSFNCGridData.IsBaseCellEx(Col,Row: integer; var BaseCol,BaseRow: integer): boolean;
var
  c: TTMSFNCGridCellRec;
begin
  c := BaseCell(Col,Row);
  BaseCol := c.Col;
  BaseRow := c.Row;
  Result := (c.Col = Col) and (c.Row = Row);
end;

function TTMSFNCGridData.IsBand(Row: Integer): Boolean;
begin
  Result := Odd(Row);
  DoGetRowIsBand(Row, Result);
end;

function TTMSFNCGridData.IsBaseCell(Col, Row: integer): boolean;
var
  c: TTMSFNCGridCellRec;
begin
  c := BaseCell(Col,Row);
  Result := (c.Col = Col) and (c.Row = Row);
end;

function TTMSFNCGridData.BaseCell(Col, Row: integer): TTMSFNCGridCellRec;
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[DisplToRealColumn(Col),DisplToRealRow(Row)]);
  Result.Col := Col;
  Result.Row := Row;
  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
  begin
    Result.Col := cp.BaseCol;
    Result.Row := RealToDisplRow(cp.BaseRow);
  end
end;

function TTMSFNCGridData.CellNameIndex(AName: string): integer;
begin
  Result := -1;
end;

function TTMSFNCGridData.CellRange(AStartCol, AStartRow, AEndCol,
  AEndRow: Integer): TTMSFNCGridCellRecRange;
begin
  Result.StartCol := AStartCol;
  Result.StartRow := AStartRow;
  Result.EndCol := AEndCol;
  Result.EndRow := AEndRow;
end;

function TTMSFNCGridData.SingleCell(ACol, ARow: Integer): TTMSFNCGridCellRec;
begin
  Result.Col := ACol;
  Result.Row := ARow;
end;

procedure TTMSFNCGridData.Clear;
var
  i,j: integer;
  cp: TTMSFNCGridCellProperty;
  ri: TTMSFNCGridRowInfo;
begin
  BeginUpdate;

  UnHideRowsAll;
  UnHideColumnsAll;

  for i := FRowList.Count - 1 downto 0 do
  begin
    if Assigned(FRowList[i]) then
    begin
      for j := 0 to FRowList[i].Data.Count - 1 do
      begin
        cp := TTMSFNCGridCellProperty(FRowList[i].Data.Objects[j]);

        if Assigned(cp) and cp.IsBaseCell(j,i) then
        begin
          cp.FreeControl;
          cp.Free;
        end;
      end;
      ri := FRowList[i];
      ri.Free;
    end;
  end;

  FRowList.Clear;
  FRowDisplayList.Clear;
  FColumnDisplayList.Clear;
  FHiddenColumnList.Clear;
  FSuppressedColumnList.Clear;
  FHiddenRowList.Clear;

  EndUpdate;
end;

procedure TTMSFNCGridData.ClearCells(CellRange: TTMSFNCGridCellRecRange);
var
  i,j: integer;
  cp: TTMSFNCGridCellProperty;
  sl: TTMSFNCGridDataList;
begin
  BeginUpdate;


  for i := CellRange.StartRow to CellRange.EndRow do
  begin
    if (i < FRowList.Count) and Assigned(FRowList[i]) then
    begin
      for j := CellRange.StartCol to Min(CellRange.EndCol, FRowList[i].Data.Count - 1) do
      begin

        SplitCell(j,i);

        sl := FRowList[i].Data;

        {$IFDEF VARIANTLIST}
        sl.Values[j] := '';
        {$ELSE}
        sl.Strings[j] := '';
        {$ENDIF}

        cp := TTMSFNCGridCellProperty(sl.Objects[j]);
        if Assigned(cp) then
        begin
          if Assigned(cp.Control) then
            cp.FreeControl;
          cp.Free;
          sl.Objects[j] := nil;
        end;
      end;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCGridData.ClearData;
begin
  Clear;
  RowCount := FixedRows + FixedFooterRows;
  ColumnCount := FixedColumns + FixedRightColumns;
end;

procedure TTMSFNCGridData.DataBeginUpdate;
begin
  BeginUpdate;
end;

procedure TTMSFNCGridData.DataEndUpdate;
begin
  EndUpdate;
end;

function TTMSFNCGridData.DataGetItemIndex: Integer;
begin
  Result := FFocusedCell.Row - FixedRows;
end;

procedure TTMSFNCGridData.DataInsertRow(AInsertPosition: Integer);
begin
  InsertRow(AInsertPosition);
end;

procedure TTMSFNCGridData.DataSetItemIndex(AValue: Integer);
begin
  SelectCell(MakeCell(FFocusedCell.Col, AValue + FixedRows));
end;

function TTMSFNCGridData.GetDataRowCount: Integer;
begin
  Result := RowCount - FixedRows - FixedFooterRows;
end;

procedure TTMSFNCGridData.SetDataValue(AColumn, ARow: Integer; AValue: string);
begin
  Cells[AColumn + FixedColumns, ARow + FixedRows] := AValue;
end;

procedure TTMSFNCGridData.SetDataColumnCount(AValue: Integer);
begin
  ColumnCount := AValue + FixedRows + FixedRightColumns;
end;

procedure TTMSFNCGridData.SetDataRowCount(AValue: Integer);
begin
  RowCount := FixedRows + FixedFooterRows + AValue;
end;

procedure TTMSFNCGridData.SetDataHeader(AColumn: Integer; AValue: string);
begin
  Cells[AColumn + FixedColumns, 0] := AValue;
end;

procedure TTMSFNCGridData.ClearCellSelect;
begin
  FSelectedCells.Clear;
end;

procedure TTMSFNCGridData.ClearColumnSelect;
begin
  FColSelect.Clear;
end;

procedure TTMSFNCGridData.ClearNormalCells;
begin
  ClearCells(CellRange(FixedColumns, FixedRows, ColumnCount - 1, RowCount - 1));
end;

constructor TTMSFNCGridData.Create(AOwner: TComponent);
var
  i: integer;
begin
  inherited;

  FDefaultLayout := TTMSFNCGridCellLayout.Create;

  FUseColumns := True;
  FColumns := TTMSFNCGridColumns.Create(Self);
  for i := 0 to 4 do
    FColumns.Add;

  FGroupColumn := -1;
  FSortColumn := -1;
  FRowCount := 10;
  FColumnCount := 5;
  FDefaultRowHeight := 24;
  FDefaultColumnWidth := 68;
  FFixedRows := 1;
  FFixedColumns := 1;
  FFixedRightColumns := 0;
  FFixedFooterRows := 0;
  FFilterApplied := false;
  FCheckTrue := 'TRUE';
  FCheckFalse := 'FALSE';
  FFloatFormat := '%g';

  FColumnOrder := TTMSFNCGridIntList.Create;

  FDefaultFont := TTMSFNCGraphicsFont.Create;

  FOptions := TTMSFNCGridOptions.Create;
  FOptions.OnChange := @DoOptionsChange;

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);

  FColumnW := TTMSFNCGridSingleList.Create(Self);
  FOrigColumnW := TTMSFNCGridSingleList.Create(Self);
  FRowH := TTMSFNCGridSingleList.Create(Self);

  FRowList := TTMSFNCGridRowInfoList.Create;
  FRowDisplayList := TTMSFNCGridIntegerList.Create;
  FColumnDisplayList := TTMSFNCGridIntegerList.Create;
  FHiddenColumnList := TTMSFNCGridIntegerList.Create;
  FSuppressedColumnList := TTMSFNCGridIntegerList.Create;
  FHiddenRowList := TTMSFNCGridIntegerList.Create;
  FColSelect := TTMSFNCGridIntList.Create;
  FSelectedCells := TTMSFNCGridCellList.Create;

  FMarkList := TTMSFNCGridCellMarkList.Create(Self);
  FCellCalcList := TTMSFNCGridIntList.Create;

  FIOOffset := Point(FixedColumns, FixedRows);
  FFilter := TTMSFNCGridFilter.Create(Self);
  FSortIndexes := TTMSFNCGridSortIndexList.Create;

  CreateColumnProp;

  UpdateCount := UpdateCount + 1;
  SetColumnCount(FColumnCount);
  UpdateCount := UpdateCount - 1;
end;

procedure TTMSFNCGridData.CutToClipboard(SelectedCells: boolean = true);
begin
{$IFNDEF WEBLIB}
  CopyToClipboard(SelectedCells);
  FClipOperation := coCut;
  if SelectedCells then
  begin
    ClearCells(Selection);
    DoCellsChanged(Selection);
  end
  else
  begin
    Clear;
    DoCellsChanged(CellRange(0,0,ColumnCount - 1, RowCount - 1));
  end;
{$ENDIF}
end;

destructor TTMSFNCGridData.Destroy;
begin
  Clear;
  FGlobalFont.Free;
  FDefaultLayout.Free;
  FColumns.Free;
  FColumnProp.Free;
  FDefaultFont.Free;
  FOptions.Free;
  FColumnW.Free;
  FOrigColumnW.Free;
  FRowH.Free;
  FRowDisplayList.Free;
  FColumnDisplayList.Free;
  FHiddenColumnList.Free;
  FSuppressedColumnList.Free;
  FHiddenRowList.Free;
  FRowList.Free;
  FFilter.Free;
  FSortIndexes.Free;
  FMarkList.Free;
  FColSelect.Free;
  FSelectedCells.Free;
  FCellCalcList.Free;
  FColumnOrder.Clear;
  FColumnOrder.Free;
  inherited;
end;

procedure TTMSFNCGridData.DoAfterApplyFilter(Col: Integer; Condition: String; var UpdateCalculations: Boolean);
begin
  if Assigned(OnAfterApplyFilter) then
    OnAfterApplyFilter(Self, Col, Condition, UpdateCalculations);
end;

procedure TTMSFNCGridData.DoAppendColumn(ACol: Integer);
begin

end;

procedure TTMSFNCGridData.DoAppendRow(ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoCanAppendColumn(ACol: Integer; var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCanAppendRow(ARow: Integer; var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCanDeleteRow(ARow: Integer; var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCanEditCell(ACol, ARow: Integer;
  var Allow: Boolean);
begin
  Allow := Options.Editing.Enabled;
  Allow := Allow and not IsReadOnly(ACol, ARow);
end;

procedure TTMSFNCGridData.DoCanInsertRow(ARow: Integer; var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCanSizeColumn(ACol: Integer;
  var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCanSizeRow(ARow: Integer;
  var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCanSortColumn(ACol: Integer;
  var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCellAnchorClick(ACol,
  ARow: Integer; AAnchor: String);
begin

end;

procedure TTMSFNCGridData.DoCellBitmapClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoCellButtonClick(ACol, ARow: Integer;
  ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoCellCheckBoxClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
var
  cp: TTMSFNCGridCellProperty;
  i,rr,ns, c,r: integer;
begin
  c := DisplToRealColumn(ACol);
  r := DisplToRealRow(ARow);
  cp := TTMSFNCGridCellProperty(IntObjects[c, r]);

  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellCheckBox) then
  begin
    (cp.Control as TTMSFNCGridCellCheckBox).Checked := (ACell as TTMSFNCCheckGridCell).Checked;

    if (cp.Control as TTMSFNCGridCellCheckBox).Header then
    begin
      for i := ARow + 1 to RowCount - 1 - FixedFooterRows do
      begin
        if IsCheckBox(ACol,i) then
          CheckBoxState[ACol,i] := (cp.Control as TTMSFNCGridCellCheckBox).Checked
        else
          break;
      end;
    end;
    UpdateGridCells;
  end;

  if Options.Grouping.AutoCheckGroup then
  begin
    rr := DisplToRealRow(ARow);
    if IsNode(rr) then
    begin
      ns := GetNodeSpan(rr);
      for I := rr to rr + ns do
      begin
        CheckBoxState[ACol,i] := (cp.Control as TTMSFNCGridCellCheckBox).Checked
      end;
      UpdateGridCells;
    end;
  end;

  if UseColumns and not Assigned(cp) then
  begin
    c := DisplToRealColumn(ACol);
    if c < Columns.Count then
    begin
      if (ACell is TTMSFNCCheckGridCell) and (Columns[c].ColumnType = ctCheckBox) then
      begin
        if (ACell as TTMSFNCCheckGridCell).Checked then
          Cells[ACol,ARow] := CheckTrue
        else
          Cells[ACol,ARow] := CheckFalse;
      end;
    end;
  end
  else
  begin
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellDataCheckBox) then
    begin
      if (ACell as TTMSFNCCheckGridCell).Checked then
        Cells[ACol,ARow] := CheckTrue
      else
        Cells[ACol,ARow] := CheckFalse;
    end;
  end;
end;

procedure TTMSFNCGridData.DoCellRightClick(ACol, ARow: Integer);
begin
end;

procedure TTMSFNCGridData.DoCellClick(ACol, ARow: Integer);
var
  rr,i,ns: integer;
begin
  if (GroupColumn <> -1) and (Options.Grouping.AutoSelectGroup) then
  begin
    rr := DisplToRealRow(ARow);
    if IsNode(rr) then
    begin
      ns := GetNodeSpan(rr);

      if Options.Selection.Mode = smDisjunctRow then
      begin
        for i := rr to rr + ns  do
          RowSelect[i] := true;
      end
      else
        if Options.Selection.Mode = smCellRange then
          Selection := CellRange(FixedColumns, ARow ,ColumnCount - 1 -  FixedRightColumns, ARow + ns);
    end;
  end;
end;

procedure TTMSFNCGridData.DoCellCommentClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin
end;

procedure TTMSFNCGridData.DoCellDblClick(ACol, ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoCellEditSetColor(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor);
begin
  Colors[ACol, ARow] := CellColor;
end;

procedure TTMSFNCGridData.DoCellEditSetData(ACol,
  ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String);
begin
  Cells[ACol, ARow] := CellString;
end;

procedure TTMSFNCGridData.DoCellEditValidateColor(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor; var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCellEditValidateData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean);
begin

end;

procedure TTMSFNCGridData.DoCellNodeClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
var
  cn: TTMSFNCGridCellNode;
  rr: integer;
begin
  rr := DisplToRealRow(ARow);

  cn := GetNode(rr);

  if not Assigned(cn) then
    Exit;

  if cn.Span <> -1 then
  begin
    if cn.State = nsOpen then
      CloseNode(rr)
    else
      OpenNode(rr);
  end;
end;

procedure TTMSFNCGridData.DoCellRadioButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
var
  cp, cpt: TTMSFNCGridCellProperty;
  r: Integer;
  c, rr: Integer;
begin
  c := DisplToRealColumn(ACol);
  r := DisplToRealRow(ARow);
  cp := TTMSFNCGridCellProperty(IntObjects[c, r]);
  if UseColumns and not Assigned(cp) then
  begin
    for rr := FixedRows to RowCount - 1 - FixedFooterRows do
      Cells[ACol, rr] := CheckFalse;
    Cells[ACol, ARow] := CheckTrue;
  end
  else
  begin
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellRadioButton) then
    begin
      for rr := 0 to RowCount - 1 do
      begin
        cpt := TTMSFNCGridCellProperty(IntObjects[ACol,rr]);
        if Assigned(cpt) and Assigned(cpt.Control) and (cpt.Control is TTMSFNCGridCellRadioButton) and (cpt <> cp) then
        begin
          if (cpt.Control as TTMSFNCGridCellRadioButton).Index = (cp.Control as TTMSFNCGridCellRadioButton).Index then
            (cpt.Control as TTMSFNCGridCellRadioButton).Checked := false;
        end;
      end;
      (cp.Control as TTMSFNCGridCellRadioButton).Checked := (ACell as TTMSFNCRadioGridCell).Checked;
      UpdateGridCells;
    end;
  end;
end;

function TTMSFNCGridData.DoGetCellIsDataCheckBox(ACol, ARow: Integer): Boolean;
var
  cp: TTMSFNCGridCellProperty;
  c, r: Integer;
begin
  c := DisplToRealColumn(ACol);
  r := DisplToRealRow(ARow);
  Result := False;
  cp := CellProps[c,r];
  if UseColumns and not Assigned(IntObjects[c, r]) then
  begin
    if (c < Columns.Count) then
      Result := (Columns[c].ColumnType = ctCheckBox) or (Columns[c].ColumnType = ctRadioButton);
  end
  else
  begin
    if Assigned(cp) then
      Result := cp.Control is TTMSFNCGridCellDataCheckBox;
  end;
end;

procedure TTMSFNCGridData.DoGetCellIsFixed(ACol, ARow: Integer;
  var ACellFixed: Boolean);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := CellProps[ACol,ARow];
  if Assigned(cp) then
    ACellFixed := cp.Fixed
  else
  begin
    if UseColumns and (ACol >= 0) and (ACol <= Columns.Count - 1) then
    begin
      ACol := DisplToRealColumn(ACol);
      ACellFixed := Columns[ACol].Fixed;
    end;
  end;
end;

procedure TTMSFNCGridData.DoCellsChanged(Cells: TTMSFNCGridCellRecRange);
begin
  if Assigned(OnCellsChanged) then
    OnCellsChanged(Self, Cells);
end;

procedure TTMSFNCGridData.DoCellSortClick(Shift: TShiftState; ACol,
  ARow: Integer; Direction: TTMSFNCGridSortDirection; ACell: TTMSFNCGridCell);
begin
  if FGroupColumn <> -1 then
  begin
    case Options.Sorting.Mode of
      gsmNormal: SortGrouped(ACol, Direction);
      gsmIndexed: SortGroupedIndexed;
    end;
  end
  else
  begin
    case Options.Sorting.Mode of
      gsmNormal: SortData(ACol, Direction);
      gsmIndexed: SortIndexed;
    end;
  end;
end;

procedure TTMSFNCGridData.DoClipboardAfterPasteCell(Col, Row: integer;
  AValue: string);
begin
  if Assigned(OnClipboardAfterPasteCell) then
    OnClipboardAfterPasteCell(Self,Col,Row,AValue);
end;

procedure TTMSFNCGridData.DoClipboardBeforePasteCell(Col, Row: integer;
  var AValue: string; var Allow: boolean);
begin
  if Assigned(OnClipboardBeforePasteCell) then
    OnClipboardBeforePasteCell(Self,Col,Row,AValue, Allow);
end;

procedure TTMSFNCGridData.DoClipboardPaste(Cells: TTMSFNCGridCellRecRange);
begin
  if Assigned(OnClipboardPaste) then
    FOnClipboardPaste(Self, Cells);
end;

function TTMSFNCGridData.DoColumnCalc(Acol, FromRow, ToRow: integer): double;
var
  res: double;
begin
  res := 0;
  if Assigned(OnColumnCalc) then
    OnColumnCalc(Self, ACol, FromRow, ToRow, res);

  Result := res;
end;

function TTMSFNCGridData.DoColumnCalcGroup(Acol, FromRow, ToRow: integer): double;
var
  res: double;
begin
  res := 0;
  if Assigned(OnGroupCalc) then
    OnGroupCalc(Self, ACol, FromRow, ToRow, res);

  Result := res;
end;


procedure TTMSFNCGridData.DoColumnCountChanged;
begin

end;

procedure TTMSFNCGridData.DoColumnSize(ACol: Integer;
  var NewWidth: Single);
begin
end;

procedure TTMSFNCGridData.DoColumnSized(ACol: Integer;
  NewWidth: Single);
begin
end;

procedure TTMSFNCGridData.DoColumnSorted(ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
end;

procedure TTMSFNCGridData.DoFilterSelect(Col: integer; var Condition: string);
begin
  if Assigned(OnFilterSelect) then
    OnFilterSelect(Self, Col, Condition);
end;

procedure TTMSFNCGridData.DoFixedCellBitmapClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoFixedCellButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoFixedCellCheckBoxClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoFixedCellRightClick(ACol,
  ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoFixedCellClick(Shift: TShiftState; ACol,
  ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoFixedCellDblClick(ACol, ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoFixedCellDropDownButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoFixedCellSpinBoxChange(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridData.DoInsertRow(ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoIOProgress(CurrentRow, TotalRows: integer);
var
  np: smallint;
begin

  if Assigned(FOnIOProgress) and (TotalRows > 0) then
  begin
    np := Round(CurrentRow/TotalRows*100);

    if np <> FPrevProgress then
    begin
      FOnIOProgress(Self, CurrentRow, TotalRows, np);
      FPrevProgress := np;
    end;
  end;
end;

procedure TTMSFNCGridData.DoGetCellClass(ACol, ARow: Integer;
  var CellClassType: TTMSFNCGridCellClass);
var
  cp: TTMSFNCGridCellProperty;
  c, r: Integer;
begin
  if (((ACol >= FixedColumns) or (ACol <= ColumnCount - 1 - FixedRightColumns))) and ((ARow < FixedRows) or (ARow > RowCount - FixedFooterRows - 1)) then
    CellClassType := TTMSFNCFixedGridCell
  else
    CellClassType := TTMSFNCGridCell;

  c := DisplToRealColumn(ACol);
  r := DisplToRealRow(ARow);

  cp := CellProps[c,r];

  if not Assigned(IntObjects[c, r]) and UseColumns and (c < Columns.Count) and not IsFixed(c,r) then
  begin
    case Columns[c].ColumnType of
    ctButton: CellClassType := TTMSFNCButtonGridCell;
    ctCheckBox: CellClassType := TTMSFNCCheckGridCell;
    ctProgressBar: CellClassType := TTMSFNCProgressGridCell;
    ctRadioButton: CellClassType := TTMSFNCRadioGridCell;
    end;
  end;

  if Assigned(cp) and Assigned(cp.Control) then
  begin
    if cp.Control is TTMSFNCGridCellCheckBox then
      CellClassType := TTMSFNCCheckGridCell;

    if cp.Control is TTMSFNCGridCellDataCheckBox then
      CellClassType := TTMSFNCCheckGridCell;

    if cp.Control is TTMSFNCGridCellRadioButton then
      CellClassType := TTMSFNCRadioGridCell;

    if cp.Control is TTMSFNCGridCellBitmapName then
      CellClassType := TTMSFNCBitmapGridCell;

    if cp.Control is TTMSFNCGridCellButton then
      CellClassType := TTMSFNCButtonGridCell;

    if cp.Control is TTMSFNCGridCellProgressBar then
      CellClassType := TTMSFNCProgressGridCell;

    if cp.Control is TTMSFNCGridCellBitmap then
      CellClassType := TTMSFNCBitmapGridCell;

    if cp.Control is TTMSFNCGridCellNode then
      CellClassType := TTMSFNCNodeGridCell;
  end;

  if Assigned(cp) then
  begin
    if (CellClassType = TTMSFNCGridCell) and (cp.Comment <> '') then
      CellClassType := TTMSFNCCommentGridCell;
  end;
end;

procedure TTMSFNCGridData.DoGetCellData(ACol, ARow: Integer;
  var CellString: String);
var
  cp: TTMSFNCGridCellProperty;
  emptydata: boolean;
  i,vp,rowc: integer;
  rr: integer;
  c: Integer;
begin
  rr := DisplToRealRow(ARow);
  c := DisplToRealColumn(ACol);
  cp := CellProps[c,rr];

  emptydata := false;

  if Assigned(cp) and Assigned(cp.Control) then
  begin
    if (cp.Control is TTMSFNCGridCellBitmapName) then
    begin
      emptydata := (cp.Control as TTMSFNCGridCellBitmapName).Data;
    end;

    if (cp.Control is TTMSFNCGridCellDataCheckBox) then
      emptydata := true;
  end;

  if UseColumns and not Assigned(IntObjects[c, rr]) then
  begin
    if (c >= 0) and (c < Columns.Count) and not IsFixed(c,rr) then
      emptydata := (Columns[c].ColumnType = ctCheckBox) or (Columns[c].ColumnType = ctRadioButton);
  end;

  if not emptydata then
  begin
    CellString := Cells[ACol,ARow];

    for i := 0 to FMarkList.Count - 1 do
    begin
      if InCellRange(ACol,ARow,FMarkList[i].CellRange) then
      begin
        case FMarkList[i].MarkType of
        mtHighlight: CellString := TTMSFNCGraphics.ApplyHilight(CellString,FMarkList[i].Value,'hi',FMarkList[i].CaseSensitive);
        mtError: CellString := TTMSFNCGraphics.ApplyHilight(CellString,FMarkList[i].Value,'e',FMarkList[i].CaseSensitive);
        end;
      end;
    end;

    if Options.URL.Show and not Options.URL.Full then
    begin
      vp := 0;
      if TTMSFNCUtils.VarPos('://', CellString,vp) > 0 then
        CellString := Copy(CellString,vp + 3,255)
      else
        if TTMSFNCUtils.VarPos('mailto:', CellString,vp) = 1 then
          CellString := Copy(CellString,vp + 7,255);
    end;

    if Options.Grouping.ShowGroupCount and (FGroupColumn <> -1) and (ACol = 1) and IsNode(rr) then
    begin
      rowc := GetNodeSpan(rr);

      if Options.Grouping.GroupCountFormat <> '' then
        CellString := CellString + ' ' + Format(Options.Grouping.GroupCountFormat,[rowc])
      else
        CellString := CellString + ' ('+inttostr(rowc)+')';
    end;
  end
  else
    CellString := '';
end;

procedure TTMSFNCGridData.DoCellEditDone(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor);
var
  cc: TTMSFNCGridColumnCalculation;
  I: Integer;
begin
  for I := 0 to ColumnCount - 1 do
  begin
    cc := ColumnCalculation[I];
    if cc <> ccNone then
      UpdateColumnCalc(I,cc);
  end;
end;

procedure TTMSFNCGridData.DoCellEditGetColor(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCGridData.DoCellEditGetData(ACol,
  ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String);
begin

end;

procedure TTMSFNCGridData.DoGetCellEditorProperties(ACol,
  ARow: Integer; CellEditor: TTMSFNCGridEditor);
var
  cp: TTMSFNCGridCellProperty;
  c: Integer;
begin
  inherited;

  if (CellEditor is TComboBox) then
  begin
    (CellEditor as TComboBox).Parent := Self;
    c := DisplToRealColumn(ACol);
    if UseColumns and (c < Columns.Count) then
    begin
      if (Columns[c].Editor in [etComboBox]) then
      begin
        if (CellEditor is TComboBox) then
          (CellEditor as TComboBox).Items.Assign(Columns[c].ComboItems);
      end;
    end;

    if IsCellControl(ACol,ARow, TTMSFNCGridCellCombo) then
    begin
      cp := TTMSFNCGridCellProperty(IntObjects[ACol,ARow]);

      if Assigned(cp) then
      begin
        if (CellEditor is TComboBox) then
          (CellEditor as TComboBox).Items.Assign((cp.Control as TTMSFNCGridCellCombo).Items);
      end;
    end;
    (CellEditor as TComboBox).Parent := nil;
  end;
end;

procedure TTMSFNCGridData.DoGetCellEditorType(ACol, ARow: Integer;
  var CellEditorType: TTMSFNCGridEditorType);
var
  c: Integer;
begin
  c := DisplToRealColumn(ACol);

  if c < Columns.Count then
    CellEditorType := Columns[c].Editor;

  if IsCellControl(ACol,ARow, TTMSFNCGridCellCombo) then
    CellEditorType := etComboBox;
end;

procedure TTMSFNCGridData.DoGetCellLayout(ACol, ARow: Integer;
  ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
var
  cp: TTMSFNCGridCellProperty;
  c, r: Integer;
begin
  c := DisplToRealColumn(ACol);
  r := DisplToRealRow(ARow);
  cp := CellProps[c,r];

  if FUseColumns and (c >= 0) and (c <= Columns.Count - 1) and not Assigned(cp) then
  begin
    if Assigned(FColumnProp.FDefaultFont) then
    begin
      FColumnProp.FontSize := FColumnProp.FDefaultFont.Size;
      FColumnProp.FontStyle := FColumnProp.FDefaultFont.Style;
    end;

    if (ACellState = TTMSFNCGridCellState.csNormal) then
    begin
      FColumnProp.Color := Columns[c].Color;
      FColumnProp.FontColor := Columns[C].Font.Color;
      FColumnProp.FontName := Columns[c].Font.Name;
      FColumnProp.FontStyle := Columns[C].Font.Style;
      FColumnProp.FontSize := Columns[c].Font.Size;
      FColumnProp.BorderColor := Columns[c].BorderColor;
      FColumnProp.BorderWidth := Columns[c].BorderWidth;
    end;
    if (ACellState = TTMSFNCGridCellState.csFixed) or (ACellState = TTMSFNCGridCellState.csFixedSelected) then
    begin
      FColumnProp.FontColor := Columns[C].FixedFont.Color;
      FColumnProp.FontName := Columns[c].FixedFont.Name;
      FColumnProp.FontStyle := Columns[C].FixedFont.Style;
      FColumnProp.FontSize := Columns[c].FixedFont.Size;
    end;

    FColumnProp.AlignHorz := Columns[c].HorzAlignment;
    FColumnProp.AlignVert := Columns[c].VertAlignment;
    FColumnProp.ReadOnly := Columns[c].ReadOnly;
    FColumnProp.Fixed := Columns[c].Fixed;
    FColumnProp.WordWrap := Columns[c].WordWrap;
    cp := FColumnProp;
  end;

  if FUseColumns and (c >= 0) and (c <= Columns.Count - 1) then
    ALayout.WordWrapping := Columns[c].WordWrap;

  if Assigned(cp) then
  begin
    if (cp.Color <> gcNull) and (ACellState = TTMSFNCGridCellState.csNormal) then
      ALayout.Fill.Color := cp.Color;

    if (cp.FontColor <> gcNull) and (ACellState = TTMSFNCGridCellState.csNormal) then
      ALayout.Font.Color := cp.FontColor;

    if (cp.BorderColor <> gcNull) and (ACellState = TTMSFNCGridCellState.csNormal) then
      ALayout.Stroke.Color := cp.BorderColor;

    ALayout.TextAlign := cp.AlignHorz;
    ALayout.VerticalTextAlign := cp.AlignVert;
    ALayout.Font.Style := cp.FontStyle;
    {$IFDEF CMNWEBLIB}
    ALayout.Font.Size := Round(cp.FontSize);
    {$ENDIF}
    {$IFDEF FMXLIB}
    ALayout.Font.Size := cp.FontSize;
    {$ENDIF}
    if cp.FontName <> '' then
      ALayout.Font.Name := cp.FontName;

    ALayout.Stroke.Width := cp.BorderWidth;
    if not FUseColumns then
      ALayout.WordWrapping := cp.WordWrap;
  end;

  if Options.URL.Show then
  begin
    if IsURL(Cells[c,r]) then
    begin
      ALayout.Font.Color := Options.URL.Color;
      if Options.URL.Underline then
        ALayout.Font.Style := ALayout.Font.Style + [TFontStyle.fsUnderline];
    end;
  end;
end;

procedure TTMSFNCGridData.DoGetCellEditorCustomClassType(ACol,
  ARow: Integer; var CellEditorCustomClassType: TTMSFNCGridEditorClass);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[ACol,ARow]);
  if Assigned(cp) and cp.ReadOnly then
    CellEditorCustomClassType := nil;
  inherited;
end;

procedure TTMSFNCGridData.DoGetCellMergeInfo(ACol,
  ARow: Integer; var ABaseCol, ABaseRow, AColSpan, ARowSpan: Integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[DisplToRealColumn(ACol),DisplToRealRow(ARow)]);

  if Assigned(cp) and (cp.BaseCol <> -1) then
  begin
    ABaseCol := RealToDisplColumn(cp.BaseCol);
    ABaseRow := RealToDisplRow(cp.BaseRow);
    AColSpan := cp.ColSpan;
    ARowSpan := cp.RowSpan;
  end;
end;

procedure TTMSFNCGridData.DoGetCellProperties(ACol, ARow: Integer;
  Cell: TTMSFNCGridCell);
var
  cp: TTMSFNCGridCellProperty;
  realc,realr: integer;
  allow: boolean;
  evnt: TNotifyEvent;
  v: Integer;
begin
  realc := DisplToRealColumn(ACol);
  realr := DisplToRealRow(ARow);

  if (Cell is TTMSFNCButtonGridCell) then
  begin
    cp := CellProps[realc,realr];
    if Assigned(cp) and (cp.Control is TTMSFNCGridCellButton) then
    begin
      (Cell as TTMSFNCButtonGridCell).ControlEnabled := not IsReadOnly(realc, realr);
      (Cell as TTMSFNCButtonGridCell).ButtonText := (cp.Control as TTMSFNCGridCellButton).Text;
      (Cell as TTMSFNCButtonGridCell).ButtonWidth := (cp.Control as TTMSFNCGridCellButton).Width;
      (Cell as TTMSFNCButtonGridCell).ButtonHeight := (cp.Control as TTMSFNCGridCellButton).Height;
    end;
  end;

  if (Cell is TTMSFNCRadioGridCell) then
  begin
    cp := CellProps[realc,realr];
    if UseColumns and not Assigned(IntObjects[realc, realr]) then
    begin
      if realc < Columns.Count then
      begin
        if Columns[realc].ColumnType = ctRadioButton then
        begin
          (Cell as TTMSFNCRadioGridCell).ControlEnabled := not IsReadOnly(realc, realr);
          evnt := (Cell as TTMSFNCRadioGridCell).OnCheckChanged;
          (Cell as TTMSFNCRadioGridCell).OnCheckChanged := nil;
          (Cell as TTMSFNCRadioGridCell).Checked := Cells[ACol, ARow] = CheckTrue;
          (Cell as TTMSFNCRadioGridCell).OnCheckChanged := evnt;
        end;
      end;
    end
    else
    begin
      if Assigned(cp) and (cp.Control is TTMSFNCGridCellRadioButton) then
      begin
        (Cell as TTMSFNCRadioGridCell).ControlEnabled := not IsReadOnly(realc, realr);
        evnt := (Cell as TTMSFNCRadioGridCell).OnCheckChanged;
        (Cell as TTMSFNCRadioGridCell).OnCheckChanged := nil;
        (Cell as TTMSFNCRadioGridCell).Checked := (cp.Control as TTMSFNCGridCellRadioButton).Checked;
        (Cell as TTMSFNCRadioGridCell).OnCheckChanged := evnt;
      end;
    end;
  end;

  if (Cell is TTMSFNCCommentGridCell) then
  begin
    cp := CellProps[realc,realr];
    if Assigned(cp) then
    begin
      (Cell as TTMSFNCCommentGridCell).CommentText := cp.Comment;
      if cp.CommentColor <> gcNull then
        (Cell as TTMSFNCCommentGridCell).CommentColor := cp.CommentColor;
    end;
  end;

  if (Cell is TTMSFNCCheckGridCell) then
  begin
    cp := CellProps[realc,realr];
    if UseColumns and not Assigned(IntObjects[realc, realr]) then
    begin
      if realc < Columns.Count then
      begin
        if Columns[realc].ColumnType = ctCheckBox then
        begin
          (Cell as TTMSFNCCheckGridCell).ControlEnabled := not IsReadOnly(realc, realr);
          evnt := (Cell as TTMSFNCCheckGridCell).OnCheckChanged;
          (Cell as TTMSFNCCheckGridCell).OnCheckChanged := nil;
          (Cell as TTMSFNCCheckGridCell).Checked := Cells[ACol, ARow] = CheckTrue;
          (Cell as TTMSFNCCheckGridCell).OnCheckChanged := evnt;
        end;
      end;
    end
    else
    begin
      if Assigned(cp) and (cp.Control is TTMSFNCGridCellCheckBox) then
      begin
        (Cell as TTMSFNCCheckGridCell).ControlEnabled := not IsReadOnly(realc, realr);
        evnt := (Cell as TTMSFNCCheckGridCell).OnCheckChanged;
        (Cell as TTMSFNCCheckGridCell).OnCheckChanged := nil;
        (Cell as TTMSFNCCheckGridCell).Checked := (cp.Control as TTMSFNCGridCellCheckBox).Checked;
        (Cell as TTMSFNCCheckGridCell).OnCheckChanged := evnt;
      end;

      if Assigned(cp) and (cp.Control is TTMSFNCGridCellDataCheckBox) then
      begin
        (Cell as TTMSFNCCheckGridCell).ControlEnabled := not IsReadOnly(realc, realr);
        evnt := (Cell as TTMSFNCCheckGridCell).OnCheckChanged;
        (Cell as TTMSFNCCheckGridCell).OnCheckChanged := nil;
        (Cell as TTMSFNCCheckGridCell).Checked := Cells[ACol,ARow] = CheckTrue;
        (Cell as TTMSFNCCheckGridCell).OnCheckChanged := evnt;
      end;
    end;
  end;

  if (Cell is TTMSFNCBitmapGridCell) then
  begin
    cp := CellProps[realc,realr];

    (Cell as TTMSFNCBitmapGridCell).BitmapContainer := BitmapContainer;

    if Assigned(cp) and (cp.Control is TTMSFNCGridCellBitmapName) then
    begin
      if (cp.Control as TTMSFNCGridCellBitmapName).Data then
        (Cell as TTMSFNCBitmapGridCell).BitmapName := Cells[ACol,ARow]
      else
        (Cell as TTMSFNCBitmapGridCell).BitmapName := (cp.Control as TTMSFNCGridCellBitmapName).BitmapName;
    end;

    if Assigned(cp) and (cp.Control is TTMSFNCGridCellBitmap) then
      (Cell as TTMSFNCBitmapGridCell).Bitmap.Assign((cp.Control as TTMSFNCGridCellBitmap).Bitmap);
  end;

  if (Cell is TTMSFNCNodeGridCell) then
  begin
    cp := CellProps[realc,realr];
    if Assigned(cp) and (cp.Control is TTMSFNCGridCellNode) then
    begin
      (Cell as TTMSFNCNodeGridCell).State := (cp.Control as TTMSFNCGridCellNode).State;
    end;
  end;

  if (Cell is TTMSFNCProgressGridCell) then
  begin
    cp := CellProps[realc,realr];
    if UseColumns and not Assigned(IntObjects[realc, realr]) then
    begin
      if realc < Columns.Count then
      begin
        if Columns[realc].ColumnType = ctProgressBar then
        begin
          v := 0;
          if TryStrToInt(Cells[ACol, ARow], v) then
            (Cell as TTMSFNCProgressGridCell).Value := v
          else
            (Cell as TTMSFNCProgressGridCell).Value := 0;
        end;
      end;
    end
    else
    begin
      if Assigned(cp) and (cp.Control is TTMSFNCGridCellProgressBar) then
      begin
        if (cp.Control as TTMSFNCGridCellProgressBar).Data then
        begin
          v := 0;
          if TryStrToInt(Cells[ACol, ARow], v) then
            (Cell as TTMSFNCProgressGridCell).Value := v
          else
            (Cell as TTMSFNCProgressGridCell).Value := 0
        end
        else
          (Cell as TTMSFNCProgressGridCell).Value := (cp.Control as TTMSFNCGridCellProgressBar).Value;
      end;
    end;
  end;

  if Options.Filtering.DropDown and (Cell is TTMSFNCFixedGridCell) then
  begin
    Allow := false;

    if (Options.Filtering.DropDownFixedRow = realr) and (realc >= FixedColumns) and (realc < ColumnCount - FixedRightColumns) then
    begin
      Allow := true;
      DoNeedFilterDropDown(realc, realr, Allow);
    end;

    (Cell as TTMSFNCFixedGridCell).ShowDropDownButton := Allow;
    (Cell as TTMSFNCFixedGridCell).SizeMargin := Options.Mouse.ColumnSizeMargin - 2;
  end;
end;

procedure TTMSFNCGridData.DoGetCellReadOnly(ACol, ARow: Integer;
  var AReadOnly: Boolean);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := CellProps[ACol,ARow];
  if Assigned(cp) then
    AReadOnly := cp.ReadOnly
  else
  begin
    if UseColumns and (ACol >= 0) and (ACol <= Columns.Count - 1) then
    begin
      ACol := DisplToRealColumn(ACol);
      AReadOnly := Columns[ACol].ReadOnly;
    end;
  end;
end;

procedure TTMSFNCGridData.DoGetCellRotation(ACol, ARow: Integer;
  var Angle: Integer);
var
  cp: TTMSFNCGridCellProperty;
  c, r: Integer;
begin
  c := DisplToRealColumn(ACol);
  r := DisplToRealRow(ARow);
  cp := CellProps[c,r];

  if Assigned(cp) then
    Angle := cp.FAngle;
end;

procedure TTMSFNCGridData.DoGetRowIsBand(ARow: Integer; var ARowBand: Boolean);
begin
  if Options.Bands.TotalRowCount > 0 then
  begin
    ARowBand := (((ARow - FixedRows) mod Options.Bands.TotalRowCount) < Options.Bands.BandRowCount);
  end
  else
    ARowBand := Odd(ARow);
end;

function TTMSFNCGridData.DoIsCellSelected(ACol, ARow: Integer): boolean;
begin
  Result := False;
  case Options.Selection.Mode of
    smDisjunctColumn: Result := ColumnSelect[ACol];
    smDisjunctRow: Result := RowSelect[ARow];
    smDisjunctCell: Result := CellSelect[ACol,ARow];
  end;
end;

procedure TTMSFNCGridData.DoLoadCell(Col, Row: integer; var Value: string);
begin
  if Assigned(FOnLoadCell) then
    OnLoadCell(Self,Col,Row,Value);
  Cells[Col,Row] := Value;
end;

procedure TTMSFNCGridData.DoNeedFilterDropDOwn(Col, Row: integer;
  var Allow: boolean);
begin
  if Assigned(OnNeedFilterDropDown) then
    OnNeedFilterDropDown(Self, Col, Row, Allow);
end;

procedure TTMSFNCGridData.DoNeedFilterDropDownData(Col, Row: integer;
  Values: TStrings);
begin
  if Assigned(OnNeedFilterDropDownData) then
    OnNeedFilterDropDownData(Self, Col, Row, Values);
end;

procedure TTMSFNCGridData.DoOptionsChange(Sender: TObject);
begin
  if (UpdateCount > 0) or BlockUpdate or (csDestroying in ComponentState) then
    Exit;

  SaveScrollPosition;
  BeginUpdate;
  HorizontalScrollBarVisible := Options.ScrollBar.HorizontalScrollBarVisible;
  VerticalScrollBarVisible := Options.ScrollBar.VerticalScrollBarVisible;
  EndUpdate;
  RestoreScrollPosition;
end;

procedure TTMSFNCGridData.DoPasteNotify(Origin: TPoint;
  ARange: TTMSFNCGridCellRecRange; AOperation: TTMSFNCClipOperation);
begin
  //
end;

procedure TTMSFNCGridData.DoRowCountChanged;
begin

end;

procedure TTMSFNCGridData.DoRowSize(ARow: Integer;
  var NewHeight: Single);
begin

end;

procedure TTMSFNCGridData.DoRowSized(ARow: Integer;
  NewHeight: Single);
begin

end;

function TTMSFNCGridData.DoSaveCell(Col, Row: integer): string;
begin
  Result := Cells[Col,Row];
  if Options.IO.SaveVirtualCellData then
    DoGetCellData(Col, Row, Result);
  if Assigned(FOnSaveCell) then
    OnSaveCell(Self,Col,Row,Result);
end;

procedure TTMSFNCGridData.DoSortFormat(Col: integer;
  var SortFormat: TTMSFNCGridSortFormat; var Prefix, Suffix: string);
var
  c: Integer;
begin
  SortFormat := ssAutomatic;
  Prefix := '';
  Suffix := '';

  c := DisplToRealColumn(Col);
  if UseColumns and (c < Columns.Count) then
  begin
    SortFormat := Columns[c].SortFormat;
    Suffix := Columns[c].SortSuffix;
    Prefix := Columns[c].SortPrefix;
  end;

  if Assigned(OnSortFormat) then
    OnSortFormat(Self,Col,SortFormat,Prefix, Suffix);
end;

procedure TTMSFNCGridData.DoCustomCompare(Value1,Value2: TTMSFNCGridCellData; var res: integer);
begin
  if Assigned(OnCustomCompare) then
    OnCustomCompare(Self, Value1, Value2, res);
end;

procedure TTMSFNCGridData.DoDeleteRow(ARow: Integer);
begin

end;

procedure TTMSFNCGridData.DoRawCompare(Col, Row1, Row2: integer; var res: integer);
begin
  if Assigned(OnRawCompare) then
    OnRawCompare(Self, Col, Row1, Row2, res);
end;

function TTMSFNCGridData.Find(StartC: TPoint; s: string;
  FindParams: TTMSFNCGridFindParams): TPoint;
begin
  Result := FindInternal(StartC,s,FindParams);
  if (fnAutoGoto in FindParams) and (Result.X <> -1) then
  begin
    Selection := CellRange(Result.X, Result.Y, Result.X, Result.Y);
  end;
end;

procedure TTMSFNCGridData.MarkInCell(DoCase: Boolean; Col, Row: Integer;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetCellRecRange(Col,Row);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtError;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.MarkInCol(DoFixed, DoCase: Boolean; Col: Integer;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetColumnRange(Col,DoFixed);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtError;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.MarkInGrid(DoFixed, DoCase: Boolean; HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetGridRange(DoFixed);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtError;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.MarkInRow(DoFixed, DoCase: Boolean; Row: Integer;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetRowRange(Row, DoFixed);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtError;
  UpdateGridCells;
end;


function TTMSFNCGridData.MatchCell(Col,Row: Integer): Boolean;
var
  res1,res2: Boolean;
  ct,cs: string;
  ic: Integer;
begin
  res2 := True;

  if fnIncludeHiddenRows in FFindParams then
    cs := AllCells[Col,Row]
  else
    cs := Cells[Col,Row];

  if not (fnMatchCase in FFindParams) then
    ct := AnsiUpperCase(cs)
  else
    ct:= cs;

  if fnIgnoreHTMLTags in FFindParams then
    ct := TTMSFNCUtils.HTMLStrip(ct);

  if FSearchCache = '""' then
  begin
    MatchCell := ct = '';
    Exit;
  end;

  ic := Pos(FSearchCache,ct);

  if fnMatchStart in FFindParams then
    res1 := ic = 1
  else
    res1 := ic > 0;

  if fnMatchFull in FFindParams then
    res2 := FSearchCache = ct;

  if fnMatchRegular in FFindParams then
  begin
    MatchCell := TTMSFNCUtils.MatchStrEx(FSearchCache,ct,(fnMatchCase in FFindParams));
  end
  else
    MatchCell := res1 and res2;
end;


function TTMSFNCGridData.FindInternal(StartC: TPoint; s:string; FindParams: TTMSFNCGridFindParams):TPoint;
var
  MaxCol,MinCol: Integer;
  MaxRow,MinRow: Integer;
  i,j: Integer;

begin
  Result.x := -1;
  Result.y := -1;

  FFindParams := FindParams;
  FFindBusy := True;

  if not (fnMatchCase in FindParams) then
    FSearchCache := AnsiUpperCase(s)
  else
    FSearchCache := s;

  if (fnIncludeFixed in FindParams) then
  begin
    MaxCol := ColumnCount - 1;
    MaxRow := RowCount - 1;
    MinCol := 0;
    MinRow := 0;
  end
  else
  begin
    MaxCol := ColumnCount - 1;
    MaxRow := RowCount - 1;
    MinCol := FIOOffset.X;
    MinRow := FIOOffset.Y;
  end;

  if fnSelectedCells in FindParams then
  begin
    MaxCol := Selection.EndCol;
    MaxRow := Selection.EndRow;
    MinCol := Selection.StartCol;
    MinRow := Selection.StartRow;
  end;

  if fnIncludeHiddenColumns in FindParams then
    MaxCol := MaxCol + HiddenColumnCount;

  if (fnIncludeHiddenRows in FindParams) then
    MaxRow := MaxRow + HiddenRowCount;

  if (StartC.x = -1) and (StartC.y = -1) then
  begin
    if fnBackward in FindParams then
    begin
      StartC.x := MaxCol;
      StartC.y := MaxRow;
    end
    else
    begin
      StartC.x := MinCol;
      StartC.y := MinRow;
    end;
  end
  else
  begin
    if fnDirectionLeftRight in Findparams then
    begin
      if fnBackward in FindParams then
      begin
        if StartC.x >= MinCol then
          Dec(StartC.x)
        else
          if StartC.y >= MinRow then
            Dec(StartC.y)
          else
          begin
            StartC.x := MaxCol;
            StartC.y := MaxRow;
          end;
      end
      else
      begin
        if StartC.x <= MaxCol then
          Inc(StartC.x)
        else
          if StartC.y <= MaxRow then
            Inc(StartC.y)
          else
          begin
            StartC.x := MinCol;
            StartC.y := MinRow;
          end;
      end;
    end
    else
    begin
      if fnBackward in FindParams then
      begin
        if StartC.y >= MinRow then
          Dec(StartC.y)
        else
          if StartC.x >= MinCol then
            Dec(StartC.x)
          else
          begin
            StartC.x := MaxCol;
            StartC.y := MaxRow;
          end;
      end
      else
      begin
        if StartC.y <= MaxRow then
          Inc(StartC.y)
        else
          if StartC.x <= MaxCol then
            Inc(StartC.x)
          else
          begin
            StartC.x := MinCol;
            StartC.y := MinRow;
          end;
      end;
    end;
  end;

  i := StartC.x;
  j := StartC.y;

  if fnFindInCurrentRow in Findparams then
  begin
    j := Selection.StartRow;
    MaxRow := Selection.StartRow;
    MinRow := Selection.StartRow;
  end;

  if fnFindInPresetRow in Findparams then
  begin
    j := FFindRow;
    MaxRow := FFindRow;
    MinRow := FFindRow;
  end;

  if fnFindInCurrentCol in Findparams then
  begin
    i := Selection.StartCol;
    MaxCol := Selection.StartCol;
    MinCol := Selection.StartCol;
  end;

  if fnFindInPresetCol in Findparams then
  begin
    i := FFindCol;
    MaxCol := FFindCol;
    MinCol := FFindCol;
  end;

  StartC.x := i;
  StartC.y := j;

  if fnDirectionLeftRight in Findparams then
  begin
    while (j <= MaxRow) and (j >= MinRow) do
    begin
      while (i <= MaxCol) and (i >= MinCol) do
      begin

          if MatchCell(i,j) then
          begin
            FSearchCell.x := i;
            FSearchCell.y := j;
            Result := FSearchCell;
            Exit;
          end;

        if fnBackward in FindParams then
          Dec(i)
        else
          Inc(i);
      end;

      if fnBackward in FindParams then
        i := MaxCol
      else
        i := MinCol;

      if fnBackward in FindParams then
        Dec(j)
      else
        Inc(j);
    end;

  end
  else
  begin
    while (i <= MaxCol) and (i >= MinCol) do
    begin
      while (j <= MaxRow) and (j >= MinRow) do
      begin

        if MatchCell(i,j) then
        begin
          FSearchCell.x := i;
          FSearchCell.y := j;
          Result := FSearchcell;
          Exit;
      end;
        if fnBackward in Findparams then
          Dec(j)
        else
          Inc(j);
      end;

    if fnBackward in FindParams then
      j := MaxRow
    else
      j := MinRow;

    if fnBackward in Findparams then
      Dec(i)
    else
      Inc(i);
    end;
  end;

  FFindBusy := False;
end;

function TTMSFNCGridData.FindFirst(s: string; FindParams: TTMSFNCGridFindParams): TPoint;
begin
  FSearchCell := Find(Point(-1,-1),s,FindParams);
  Result := FSearchCell;
end;

function TTMSFNCGridData.FindNext: TPoint;
begin
  FSearchCell := Find(FSearchCell,FSearchCache,FFindParams);
  Result := FSearchCell;
end;

procedure TTMSFNCGridData.Debug;
begin

end;

procedure TTMSFNCGridData.DeleteColumn(Col: integer);
var
  i: integer;
  rc: integer;
begin
  BeginUpdate;

  rc := DisplToRealColumn(Col);

  for i := Col to FColumnDisplayList.Count - 1 do
  begin
    if FColumnDisplayList[i] > Col then
      FColumnDisplayList[i] := FColumnDisplayList[i] - 1;
  end;

  FColumnDisplayList.Delete(Col);

  ClearCells(CellRange(rc, 0, rc, RowCount - 1));

  for i := 0 to FRowList.Count - 1 do
  begin
    if Assigned(FRowList[i]) and (FRowList[i].Data.Count > rc) then
      FRowList[i].Data.Delete(rc);
  end;

  ColumnCount := ColumnCount - 1;
  EndUpdate;
end;

procedure TTMSFNCGridData.DeleteRow(Row: integer);
begin
  DeleteRows(Row,1);
end;

procedure TTMSFNCGridData.DeleteRows(Row, Count: integer);
var
  i,j: integer;
  ri: TTMSFNCGridRowInfo;
  cp: TTMSFNCGridCellProperty;
begin
  BeginUpdate;

  ClearCells(CellRange(0, Row, ColumnCount - 1 + HiddenColumnCount, Row + Count - 1));

  for i := Row to FRowDisplayList.Count - 1 do
  begin
    for j := 0 to ColumnCount - 1 do
    begin
      if IsBaseCell(j,i) then
      begin
        cp := TTMSFNCGridCellProperty(IntObjects[j,i]);
        if Assigned(cp) then
          if (cp.BaseRow >= Row) then
          begin
            cp.BaseRow := cp.BaseRow - Count;
          end;
      end;
    end;
  end;

  while (FRowList.Count < RowCount) do
    FRowList.Add(nil);

  for i := 1 to Count do
  begin
    if Assigned(FRowList[Row]) then
    begin
      ri := FRowList[Row];
      ri.Free;
    end;

    if Row < FRowList.Count then
      FRowList.Delete(Row);
    if Row < FRowDisplayList.Count then
      FRowDisplayList.Delete(Row);
    FRowCount := FRowCount - 1;
  end;

  for i := Row to FRowDisplayList.Count - 1 do
  begin
    FRowDisplayList[i] := FRowDisplayList[i] - Count;
  end;


  EndUpdate;
end;

function TTMSFNCGridData.GetHorzAlignments(Col, Row: integer): TTMSFNCGraphicsTextAlign;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := gtaLeading;
  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.AlignHorz;
end;

function TTMSFNCGridData.GetAllCells(Col, Row: integer): string;
var
  sl: TTMSFNCGridDataList;

begin
  Result := '';

  if Row < FRowList.Count then
  begin
    if Assigned(FRowList[Row]) then
    begin
      sl := FRowList[Row].Data;
      if Col < sl.Count then
      {$IFDEF VARIANTLIST}
        Result := sl.Values[Col];
      {$ELSE}
        Result := sl.Strings[COl];
      {$ENDIF}
    end;
  end;
end;

function CleanupSep(value: string): string;
var
  i: integer;
  s: string;
begin
  s := '';

  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to Length(value) - 1 do
  {$ENDIF}
  {$IFNDEF ZEROSTRINGINDEX}
  for i := 1 to Length(value) do
  {$ENDIF}
  begin
    if (Value[i] = FormatSettings.ThousandSeparator) then
      Continue;

    if (Value[i] = FormatSettings.DecimalSeparator) then
      s := s + '.'
    else
      s := s + Value[i];
  end;
  Result := s;
end;

function TTMSFNCGridData.GetAllInts(Col, Row: integer): Integer;
var
  s: string;
  Res: Integer;
  Err: Integer;
begin
  s := AllCells[Col,Row];

  if (s = '') or (s = '-') then
    s := '0';

  s := CleanupSep(s);

  Val(s,Res,Err);

  if Err <> 0 then
    Result := 0
  else
    Result := Res;
end;

function TTMSFNCGridData.GetAllFloats(Col, Row: integer): double;
var
  s: string;
  Res: Double;
  Err: Integer;
begin
  s := AllCells[Col,Row];

  if (s = '') or (s = '-') then
    s := '0';

  s := CleanupSep(s);

  Val(s,Res,Err);

  if Err <> 0 then
    Result := 0
  else
    Result := Res;
end;

function TTMSFNCGridData.GetCellProps(Col, Row: integer): TTMSFNCGridCellProperty;
begin
  Result := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
end;

function TTMSFNCGridData.GetCellRecRange(Col, Row: Integer): TTMSFNCGridCellRecRange;
begin
  Result := CellRange(Col,Row,Col,Row);
end;

function TTMSFNCGridData.GetCells(Col, Row: integer): TTMSFNCGridCellData;
var
  sl: TTMSFNCGridDataList;
  RDisp,CDisp: integer;
begin
  Result := '';

  if Row >= FRowDisplayList.Count then
    Exit;

  if Col >= FColumnDisplayList.Count then
    Exit;

  RDisp := -1;
  if (Row >= 0) and (Row <= FRowDisplayList.Count - 1) then
    RDisp := FRowDisplayList[Row];
  CDisp := -1;
  if (Col >= 0) and (Col <= FColumnDisplayList.Count - 1) then
    CDisp := FColumnDisplayList[Col];

  if (RDisp >= 0) and (RDisp < FRowList.Count) then
  begin
    if Assigned(FRowList[RDisp]) then
    begin
      sl := FRowList[RDisp].Data;
      if (CDisp >= 0) and (CDisp < sl.Count) then
      begin
        {$IFDEF VARIANTLIST}
        Result := sl.Values[CDisp];
        {$ELSE}
        Result := sl.Strings[CDisp];
        {$ENDIF}
      end;
    end;
  end;
end;

function TTMSFNCGridData.GetCellSelect(Col, Row: integer): boolean;
begin
  Result := FSelectedCells.Find(MakeCell(Col,Row)) <> -1;
end;

function TTMSFNCGridData.GetCellSelectionCount: integer;
begin
  Result := FSelectedCells.Count;
end;

function TTMSFNCGridData.GetCellSize(Col, Row: Integer): TSizeF;
begin
  Result.cx := ColumnWidths[Col];
  Result.cy := RowHeights[Row];
end;

function TTMSFNCGridData.GetCellState(ACol, ARow: Integer): TTMSFNCGridCellState;
begin
  Result := TTMSFNCGridCellState.csNormal;
end;

function TTMSFNCGridData.GetCalcValues(Col, Row: Integer): variant;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := '';

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.CalcValue;
end;

function TTMSFNCGridData.GetCellCalcStates(Col, Row: Integer): TTMSFNCGridCellCalcState;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := csNoCalc;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.CalcState;
end;

function TTMSFNCGridData.GetCellLayout(ACol, ARow: Integer; ACellState: TTMSFNCGridCellState): TTMSFNCGridCellLayout;
begin
  FDefaultLayout.Assign(GetDefaultNormalLayout);
  DoGetCellLayout(ACol, ARow, FDefaultLayout, ACellState);
  Result := FDefaultLayout;
end;

procedure TTMSFNCGridData.GetCellMergeInfo(ACol, ARow: Integer; var ABaseCol,
  ABaseRow, AColSpan, ARowSpan: Integer);
begin
  DoGetCellMergeInfo(ACol, ARow, ABaseCol, ABaseRow, AColSpan, ARowSpan);
end;

function TTMSFNCGridData.GetCheckBoxState(Col, Row: integer): boolean;
var
  cp: TTMSFNCGridCellProperty;
  c: integer;
begin
  Result := false;
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if UseColumns and not Assigned(cp) then
  begin
    c := DisplToRealColumn(Col);
    if c < Columns.Count then
    begin
      if (Columns[c].ColumnType = ctCheckBox) then
      begin
        Result := Cells[Col,Row] = CheckTrue;
      end;
    end;
  end
  else
  begin
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellCheckBox) then
    begin
      Result := (cp.Control as TTMSFNCGridCellCheckBox).Checked;
    end;
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellDataCheckBox) then
    begin
      Result := Cells[Col,Row] = CheckTrue;
    end;
  end;
end;

function TTMSFNCGridData.GetColumnCalculation(Col: Integer): TTMSFNCGridColumnCalculation;
begin
  if Col >= FCellCalcList.Count then
  begin
    Result := ccNone;
  end
  else
  begin
    Result := TTMSFNCGridColumnCalculation(FCellCalcList[Col]);
  end;

end;

function TTMSFNCGridData.GetColumnCount: integer;
begin
  Result := FColumnCount;
end;

function TTMSFNCGridData.GetColumnDisplayName(ACol: Integer): string;
var
  c: TTMSFNCGridColumn;
begin
  Result := 'Column ' + inttostr(ACol);
  if (ACol >= 0) and (ACol <= Columns.Count - 1) then
  begin
    c := Columns[ACol];
    if c.Name <> '' then
      Result := c.Name;
  end;
end;

function TTMSFNCGridData.GetColumnRange(Col: integer;
  DoFixed: boolean): TTMSFNCGridCellRecRange;
begin
  if DoFixed then
    Result := CellRange(Col,0,Col,RowCount - 1)
  else
    Result := CellRange(Col,FixedRows,Col,RowCount - 1 - FixedFooterRows);
end;

function TTMSFNCGridData.GetColumnSelect(Col: integer): boolean;
begin
  Result := FColSelect.HasValue(Col);
end;

function TTMSFNCGridData.GetColumnSelectionCount: integer;
begin
  Result := FColSelect.Count;
end;

function TTMSFNCGridData.GetDefaultBandLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultFixedLayout: TTMSFNCGridCellLayout;
begin
   Result := nil;
end;

function TTMSFNCGridData.GetDefaultFixedSelectedLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultFocusedLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultFont(Col, Row: integer): TTMSFNCGraphicsFont;
var
  gc: TTMSFNCGridCellLayout;
begin
  if IsFixed(Col,Row) then
    gc := GetDefaultFixedLayout
  else
    gc := GetDefaultNormalLayout;

  if Assigned(gc) then
    Result := gc.Font
  else
    Result := FDefaultFont;
end;

function TTMSFNCGridData.GetDefaultGroupLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultIndexedSortFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultIndexedSortFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultIndexedSortStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultNormalLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultSelectedLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultSortFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultSortFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultSortStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetDefaultSummaryLayout: TTMSFNCGridCellLayout;
begin
  Result := nil;
end;

function TTMSFNCGridData.GetColWidths(AIndex: integer): Single;
var
  idx: Integer;
begin
  Result := 0;
  if FSuppressedColumnList.IndexOf(AIndex) <> -1 then
    Exit;

  idx := ColumnW.IndexOf(AIndex);
  if idx <> -1 then
    Result := ColumnW[idx].Value
  else
    Result := DefaultColumnWidth;
end;

function TTMSFNCGridData.GetFixedCellSelect(Col, Row: integer): boolean;
begin
  Result := FSelectedCells.FindFixed(MakeCell(Col,Row)) <> -1;
end;

function TTMSFNCGridData.GetInts(Col, Row: Integer): Integer;
var
  s: string;
  Res: Integer;
  Err: Integer;
begin
  s := Cells[Col,Row];
  DoGetCellData(Col, Row, s);

  if (s = '') or (s = '-') then
    s := '0';

  s := CleanupSep(s);

  Val(s,Res,Err);

  if Err <> 0 then
    Result := 0
  else
    Result := Res;
end;

function TTMSFNCGridData.GetFloats(Col, Row: Integer): double;
var
  s: string;
  Res: Double;
  Err: Integer;
begin
  s := Cells[Col,Row];
  DoGetCellData(Col, Row, s);

  if (s = '') or (s = '-') then
    s := '0';

  s := CleanupSep(s);

  Val(s,Res,Err);

  if Err <> 0 then
    Result := 0
  else
    Result := Res;
end;

function TTMSFNCGridData.GetFocusedCell: TTMSFNCGridCellRec;
begin
  Result := FFocusedCell;
end;

function TTMSFNCGridData.GetFontColors(Col, Row: integer): TTMSFNCGraphicsColor;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := gcNull;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.FontColor;
end;

function TTMSFNCGridData.GetFontNames(Col, Row: integer): string;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := FDefaultFont.Name;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.FontName;
end;

function TTMSFNCGridData.GetFontSizes(Col, Row: integer): single;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := 12;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.FontSize;
end;

function TTMSFNCGridData.GetFontStyles(Col, Row: integer): TFontStyles;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := [];

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.FontStyle;
end;

function TTMSFNCGridData.GetGridRange(DoFixed: boolean): TTMSFNCGridCellRecRange;
begin
  if DoFixed then
    Result := CellRange(0,0,ColumnCount - 1, RowCount - 1)
  else
    Result := CellRange(FixedColumns, FixedRows,ColumnCount - 1 - FixedRightColumns, RowCount - 1 - FixedFooterRows);
end;

function TTMSFNCGridData.GetColors(Col, Row: integer): TTMSFNCGraphicsColor;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := gcNull;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.Color;
end;

function TTMSFNCGridData.GetHiddenColCount: integer;
begin
  Result := FHiddenColumnList.Count;
end;

function TTMSFNCGridData.GetHiddenRowCount: integer;
begin
  Result := FRowList.Count - FRowDisplayList.Count;
end;

function TTMSFNCGridData.GetIntObjects(Col, Row: integer): TObject;
var
  sl: TTMSFNCGridDataList;
begin
  Result := nil;

  if (Row >= 0) and (Row <= FRowList.Count - 1) then
  begin
    if Assigned(FRowList[Row]) then
    begin
      sl := FRowList[Row].Data;
      if (Col >= 0) and (Col <= sl.Count -1) then
        Result := sl.Objects[col];
    end;
  end;
end;

function TTMSFNCGridData.GetMergeCellPrintPageNr(Col, Row: integer): integer;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := -1;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) and (cp.BaseCol <> -1) then
  begin
    Result := cp.FPrintPageNr;
  end;
end;

function TTMSFNCGridData.GetReadOnlys(Col, Row: integer): boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := false;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) then
    Result := cp.ReadOnly;
end;

function TTMSFNCGridData.GetRowCount: integer;
begin
  Result := FRowCount;
end;

function TTMSFNCGridData.GetRowHeights(AIndex: integer): Single;
var
  idx: Integer;
begin
  idx := RowH.IndexOf(AIndex);
  if idx <> -1 then
    Result := RowH[idx].Value
  else
    Result := DefaultRowHeight;
end;

function TTMSFNCGridData.GetRowRange(Row: integer; DoFixed: boolean): TTMSFNCGridCellRecRange;
begin
  if DoFixed then
    Result := CellRange(0,Row,ColumnCount - 1,Row)
  else
    Result := CellRange(FixedColumns,Row,ColumnCount - 1 - FixedRightColumns,Row)
end;

function TTMSFNCGridData.GetRowSelect(Row: integer): boolean;
begin
  Result := false;

  if Row < FRowList.Count then
  begin
    if Assigned(FRowList[Row]) then
      Result := FRowList[Row].State = rsSelected;
  end;
end;

function TTMSFNCGridData.GetRowSelectionCount: integer;
var
  i,res: integer;
begin
  res := 0;

  for I := 0 to FRowList.Count - 1 do
  begin
    if RowSelect[i] then
      inc(res);
  end;

  Result := res;
end;

function TTMSFNCGridData.GetRowText(Row: integer): string;
var
  sl: TTMSFNCGridDataList;
  RDisp: integer;
begin
  Result := '';

  if Row >= FRowDisplayList.Count then
    Exit;

  RDisp := FRowDisplayList[Row];

  if RDisp < FRowList.Count then
  begin
    if Assigned(FRowList[RDisp]) then
    begin
      sl := FRowList[RDisp].Data;
      {$IFDEF VARIANTLIST}
      Result := sl.GetAsText(Options.IO.Delimiter);
      {$ELSE}
      sl.Delimiter := Options.IO.Delimiter;
      Result := sl.DelimitedText;
      {$ENDIF}
    end;
  end;
end;

function TTMSFNCGridData.GetSelectedCell(index: integer): TTMSFNCGridCellRec;
begin
  Result := FSelectedCells[Index];
end;

function TTMSFNCGridData.GetSelectedCellCount: Integer;
begin
  Result := FSelectedCells.Count;
end;

function TTMSFNCGridData.GetSelectedColumn(index: integer): integer;
begin
  Result := integer(FColSelect[index]);
end;

function TTMSFNCGridData.GetSelectedColumnCount: Integer;
begin
  Result := FColSelect.Count;
end;

function TTMSFNCGridData.GetSelectedRow(index: integer): integer;
var
  i,cnt: integer;
begin
  Result := -1;
  cnt := 0;
  for i := 0 to RowCount - 1 do
  begin
    if Assigned(FRowList[I]) then
    begin
      if FRowList[i].State = rsSelected then
      begin
        if cnt = index then
        begin
          Result := i;
          break;
        end;
        Inc(cnt);
      end;
    end;
  end;
end;

function TTMSFNCGridData.GetSelectedRowCount: Integer;
var
  i: integer;
begin
  Result := 0;
  for i := FixedRows to RowCount - FixedFooterRows - 1 do
  begin
    if i >= FRowList.Count then
      Break;

    if Assigned(FRowList[i]) and (FRowList[I].State = rsSelected) then
      Inc(Result);
  end;
end;

function TTMSFNCGridData.GetSelection: TTMSFNCGridCellRecRange;
begin
  Result.StartCol := FStartCell.Col;
  Result.StartRow := FStartCell.Row;
  Result.EndCol := FStopCell.Col;
  Result.EndRow := FStopCell.Row;
end;

function TTMSFNCGridData.GetStrippedCells(Col, Row: Integer): string;
begin
  Result := TTMSFNCUtils.HTMLStrip(Cells[Col,Row]);
end;

function TTMSFNCGridData.GetTotalColCount: integer;
begin
  Result := ColumnCount + FHiddenColumnList.Count;
end;

function TTMSFNCGridData.GetTotalRowCount: integer;
begin
  Result := FRowList.Count;
end;


function TTMSFNCGridData.GetVertAlignments(Col, Row: integer): TTMSFNCGraphicsTextAlign;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := gtaCenter;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
    Result := CellProp.AlignVert;
end;

procedure TTMSFNCGridData.UnGroup;
var
  i,j: Integer;
  nc: string;
  grpc: Integer;
begin
  if FGroupColumn <= 0 then
    Exit;

  BeginUpdate;

  OpenAllNodes;

  if FGroupColumn = 1 then
    grpc := 2
  else
    grpc := 1;

  i := FixedRows;

  FIsGrouping := true;

  while i <= RowCount - 1 - FFixedFooterRows do
  begin
    if IsNode(i) then
    begin
      if Options.Grouping.MergeHeader then
        SplitCell(FixedColumns,i);

      if Options.Grouping.MergeSummary then
      begin
        j := GetNodeSpan(i);
        SplitCell(FixedColumns,i + j + 1);
      end;
    end;
    if IsSummary(i) then
      RemoveSummary(i);
    inc(i);
  end;


  FInternalColumnUpdate := True;
  InsertColumn(FGroupColumn);
  FInternalColumnUpdate := False;
  ColumnWidths[FGroupColumn] := FGroupWidth;

  if FixedRows > 0 then
    Cells[FGroupColumn,0] := FGroupCaption;

  if FixedFooterRows > 0 then
    Cells[FGroupColumn, RowCount - 1] := FGroupFooter;

  i := FixedRows;

  FIsGrouping := true;

  while i <= RowCount - 1 - FFixedFooterRows do
  begin
    if IsNode(i) then
    begin
      if Options.Grouping.Summary then
      begin
        j := GetNodeSpan(i);

        ClearCells(CellRange(0,i + j + 1, ColumnCount - 1, i + j + 1));
        DeleteRows(i + j + 1,1);
      end;

      nc := Cells[grpc,i];
      RemoveNode(i);

      ClearCells(CellRange(0,i,ColumnCount - 1,i));

      DeleteRows(i,1);
    end
    else
    begin
      Cells[FGroupColumn,i] := nc;
      inc(i);
    end;
  end;

  FIsGrouping := false;
  FGroupColumn := -1;

  EndUpdate;
end;

procedure TTMSFNCGridData.Group(Column: integer);
var
  i,np: Integer;
  lc,nc: string;
  grp,grpc: Integer;
begin
  if (Column < FixedColumns) then
    Exit;


  if FGroupColumn <> -1 then
    UnGroup;

  if (RowCount - FixedRows < 1) then
    Exit;

  BeginUpdate;

  FIsGrouping := true;

  try
    FGroupColumn := Column;
    FGroupWidth := ColumnWidths[Column];

    if FixedRows > 0 then
      FGroupCaption := Cells[Column,0];

    if FixedFooterRows > 0 then
      FGroupFooter := Cells[Column, RowCount - 1];

    grp := Column;
    grpc := 1;

    if grpc = Column then
      Inc(grpc);

    np := -1;
    lc := Chr(255) + Chr(254);

    i := FixedRows;

    while i <= RowCount - 1 - FFixedFooterRows do
    begin
      nc := Cells[Column,i];

      if lc <> nc then
      begin
        if np <> -1 then
        begin

          if (np > FixedRows) and Options.Grouping.Summary then
          begin
            FInternalRowUpdate := True;
            InsertRows(np,1);
            FInternalRowUpdate := False;
            AddSummary(np);

            inc(np);
            AddNode(np,i - np );
            inc(i);
          end
          else
          begin
            AddNode(np,i - np - 1);
          end;
        end;

        FInternalRowUpdate := True;
        InsertRows(i,1);
        FInternalRowUpdate := False;
        Cells[grpc,i] := nc;

        np := i;
        Inc(i);
      end;

      if i < RowCount - 1 then
        lc := Cells[grp,i];
      Inc(i);
    end;

    if np <> -1 then
    begin

      if (np > FixedRows) and Options.Grouping.Summary then
      begin
        FInternalRowUpdate := True;
        InsertRows(np,1);
        FInternalRowUpdate := False;
        AddSummary(np);

        inc(np);
        inc(i);
      end;

      AddNode(np,i - np - 1);
    end
    else
      AddNode(FixedRows,i - 1  - 1);


    if Options.Grouping.Summary then
    begin
      FInternalRowUpdate := True;
      InsertRows(i,1);
      FInternalRowUpdate := False;
      AddSummary(i);
    end;

    DeleteColumn(grp);

    if Options.Grouping.MergeHeader or (Options.Grouping.MergeSummary and Options.Grouping.Summary) then
    begin
      for i := FixedRows to RowCount - 1 do
      begin
        grpc := 1;
        if IsNode(i) and Options.Grouping.MergeHeader then
        begin
          MergeCells(grpc,i,ColumnCount - grpc - FixedRightColumns,1);
        end;

        if (IsNode(i + 1) or (i = RowCount - 1)) and Options.Grouping.MergeSummary and Options.Grouping.Summary then
        begin
          MergeCells(grpc,i,ColumnCount - grpc - FixedRightColumns,1);
        end;
      end;
    end;
  finally
    FIsGrouping := false;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.GroupAvg(Col: Integer);
begin
  GroupCalc(Col,2);
end;

procedure TTMSFNCGridData.GroupCalc(Col, method: Integer);
var
  i,j,k: Integer;
  res: double;
begin
  i := FixedRows;
  while (i < RowCount) do
  begin
    if IsNode(i) then
    begin
      j := i + GetNodeSpan(i);

      if Options.Grouping.Summary then
        k := i + GetNodeSpan(i) + 1
      else
        k := i + 1;

      res := 0;

      case Method of
      1:res := ColumnSum(Col,i + 1,j );
      2:res := ColumnAvg(Col,i + 1,j );
      3:res := ColumnMin(Col,i + 1,j );
      4:res := ColumnMax(Col,i + 1,j );
      5:res := j - i;
      6:res := ColumnCustomCalcGroup(Col,i + 1,j);
      7:res := ColumnDistinct(Col, i + 1, j );
      8:res := ColumnStdDev(Col, i + 1, j );
      end;

      Cells[Col,k] := Format(Options.Grouping.CalcFormat,[res]);

      i := j;
    end
    else
      Inc(i);
  end;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.GroupCount(Col: Integer);
begin
  GroupCalc(Col,5);
end;

procedure TTMSFNCGridData.GroupCustomCalc(Col: Integer);
begin
  GroupCalc(Col,6);
end;

procedure TTMSFNCGridData.GroupDistinct(Col: Integer);
begin
  GroupCalc(Col,7);
end;

procedure TTMSFNCGridData.GroupMax(Col: Integer);
begin
  GroupCalc(Col,4);
end;

procedure TTMSFNCGridData.GroupMin(Col: Integer);
begin
  GroupCalc(Col,3);
end;

procedure TTMSFNCGridData.GroupStdDev(Col: Integer);
begin
  GroupCalc(Col,8);
end;

procedure TTMSFNCGridData.GroupSum(Col: Integer);
begin
  GroupCalc(Col,1);
end;

function TTMSFNCGridData.IsSuppressedColumn(Col: integer): boolean;
var
  i: integer;
begin
  Result := False;

  for i := 0 to FSuppressedColumnList.Count - 1 do
  begin
    if FSuppressedColumnList[i] = Col then
    begin
      Result := True;
      break;
    end;
  end;
end;

function TTMSFNCGridData.IsHiddenColumn(Col: integer): boolean;
var
  i: integer;
begin
  Result := true;

  for i := 0 to FColumnDisplayList.Count - 1 do
  begin
    if FColumnDisplayList[i] = Col then
    begin
      Result := false;
      break;
    end;
  end;
end;

function TTMSFNCGridData.IsHiddenRow(Row: integer): boolean;
var
  i: integer;
begin
  Result := false;

  for i := 0 to FHiddenRowList.Count - 1 do
  begin
    if integer(FHiddenRowList[i]) = Row then
    begin
      Result := true;
      break;
    end;
  end;
end;

procedure TTMSFNCGridData.HideColumn(Col: integer);
var
  i: integer;
  fnd: boolean;
begin
  if IsHiddenColumn(Col) then
    Exit;

  fnd := false;

  BeginUpdate;

  for i := 0 to FColumnDisplayList.Count - 1 do
  begin
    if FColumnDisplayList[i] = Col then
    begin
      FHiddenColumnList.Add(Col);
      FColumnDisplayList.Delete(i);
      fnd := true;
      break;
    end;
  end;

  if fnd then
    FColumnCount := FColumnCount - 1;

  EndUpdate;
end;

procedure TTMSFNCGridData.SuppressColumn(Col: integer);
var
  i: integer;
begin
  if IsSuppressedColumn(Col) then
    Exit;

  BeginUpdate;

  for i := 0 to FColumnDisplayList.Count - 1 do
  begin
    if FColumnDisplayList[i] = Col then
    begin
      FSuppressedColumnList.Add(Col);
      break;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCGridData.HideColumns(Col, Count: integer);
var
  i,c: integer;
begin
  BeginUpdate;

  c := 0;
  i := 0;

  while (i  < FColumnDisplayList.Count) do
  begin
    if (FColumnDisplayList[i] = Col + c) and (c < Count) then
    begin
      FHiddenColumnList.Add(Col + c);
      FColumnDisplayList.Delete(i);
      inc(c)
    end
    else
      inc(i);
  end;

  FColumnCount := FColumnCount - c;

  EndUpdate;
end;

procedure TTMSFNCGridData.SuppressColumns(Col, Count: integer);
var
  i, c: integer;
begin
  BeginUpdate;

  c := 0;
  i := 0;

  while (i  < FColumnDisplayList.Count) do
  begin
    if (FColumnDisplayList[i] = Col + c) and (c < Count) then
    begin
      FSuppressedColumnList.Add(Col + c);
      inc(c)
    end
    else
      inc(i);
  end;

  EndUpdate;
end;

function TTMSFNCGridData.DisplToRealRow(Row: integer): integer;
begin
  if (Row >= 0) and (Row < FRowDisplayList.Count) then
    Result := FRowDisplayList[Row]
  else
    Result := Row;
end;

function TTMSFNCGridData.RealToDisplRow(Row: integer): integer;
var
  i: integer;
begin
  Result := Row;
  i := 0;
  while i < FRowDisplayList.Count do
  begin
    if FRowDisplayList[i] = Row then
    begin
      Result := i;
      break;
    end;
    inc(i);
  end;
end;

function TTMSFNCGridData.DisplToRealColumn(Col: integer): integer;
begin
  if (Col >= 0) and (Col < FColumnDisplayList.Count) then
    Result := FColumnDisplayList[Col]
  else
    Result := Col;
end;

function TTMSFNCGridData.RealToDisplColumn(Col: integer): integer;
var
  i: integer;
begin
  Result := -1;
  i := 0;
  while i < FColumnDisplayList.Count do
  begin
    if FColumnDisplayList[i] = Col then
    begin
      Result := i;
      break;
    end;
    inc(i);
  end;
end;

procedure TTMSFNCGridData.HideRow(Row: integer);
var
  i: integer;
begin
  BeginUpdate;
  for i := 0 to FRowDisplayList.Count - 1 do
  begin
    if FRowDisplayList[i] = Row then
    begin
      FHiddenRowList.Add(Row);
      FRowDisplayList.Delete(i);
      FRowCount := FRowCount - 1;
      break;
    end;
  end;
  EndUpdate;
end;

procedure TTMSFNCGridData.HideRows(Row, Count: integer);
var
  i,c: integer;
  flg: boolean;
begin
  c := 0;
  i := 0;
  flg := false;

  while i < FRowDisplayList.Count do
  begin
    if FRowDisplayList[i] = Row + c then
    begin
      FHiddenRowList.Add(Row + c);
      FRowDisplayList.Delete(i);
      FRowCount := FRowCount - 1;
      flg := true;
      if c < Count - 1 then
        inc(c)
      else
        break;
    end
    else
      inc(i);
  end;

  if flg then
    UpdateControl;
end;

procedure TTMSFNCGridData.HighlightInCell(DoCase: Boolean; Col, Row: Integer;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetCellRecRange(Col,Row);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtHighlight;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.HighlightInCol(DoFixed, DoCase: Boolean; Col: Integer;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetColumnRange(Col,DoFixed);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtHighlight;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.HighlightInGrid(DoFixed, DoCase: Boolean;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetGridRange(DoFixed);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtHighlight;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.HighlightInRow(DoFixed, DoCase: Boolean; Row: Integer;
  HiText: string);
var
  mi: TTMSFNCGridCellMarkItem;
begin
  mi := FMarkList.Add;
  mi.CellRange := GetRowRange(Row, DoFixed);
  mi.Value := HiText;
  mi.CaseSensitive := DoCase;
  mi.MarkType := mtHighlight;
  UpdateGridCells;
end;


procedure TTMSFNCGridData.ImportNotification(AState: TTMSFNCGridImportState;
  ARow: Integer);
begin
  if AState = isImportStart then
    FPrevProgress := -1;
end;

procedure TTMSFNCGridData.InsertColumn(Col: integer);
var
  i: integer;
  sl: TTMSFNCGridRowInfo;
  cidx: Integer;
begin
  BeginUpdate;

  for i := 0 to FRowList.Count - 1 do
  begin
    sl := FRowList[i];
    if Assigned(sl) then
    begin
      if Col < sl.Data.Count then
        sl.Data.InsertObject(Col,'',nil);
    end;
  end;

  if not FInternalColumnUpdate then
  begin
    if Col <= ColumnW.Count - 1 then
      cidx := ColumnW.Insert(Col).Index
    else
      cidx := ColumnW.Add.Index;

    if (cidx >= 0) and (cidx <= ColumnW.Count - 1) then
    begin
      ColumnW[cidx].Value := DefaultColumnWidth;
      ColumnW[cidx].CellVal := Col;
      for I := cidx + 1 to ColumnW.Count - 1 do
        ColumnW[I].CellVal := ColumnW[I].CellVal + 1;
    end;
  end;

  EndUpdate;

  FColumnDisplayList.Insert(Col,Col);
  for i := Col + 1 to FColumnDisplayList.Count - 1 do
  begin
    FColumnDisplayList[i] := FColumnDisplayList[i] + 1;
  end;

  ColumnCount := ColumnCount + 1;
end;

procedure TTMSFNCGridData.InsertFromCSV(FileName: String; MaxRows: integer = -1);
begin
  InputFromCSV(FileName, nil, True, MaxRows);
end;

procedure TTMSFNCGridData.InsertFromCSV(FileName: String; Encoding: TEncoding; MaxRows: integer = -1);
begin
  InputFromCSV(FileName, Encoding, True, MaxRows);
end;

procedure TTMSFNCGridData.InsertRow(Row: integer);
begin
  InsertRows(Row,1);
end;

procedure TTMSFNCGridData.InsertRows(Row, Count: integer);
var
  i,j: integer;
  ridx: Integer;
  cp: TTMSFNCGridCellProperty;
begin
  if Row > RowCount then
    Exit;

  BeginUpdate;

  EnsureRow(Row);

  for i := Row to FRowDisplayList.Count - 1 do
  begin
    for j := 0 to ColumnCount - 1 do
    begin
      if IsBaseCell(j,i) then
      begin
        cp := TTMSFNCGridCellProperty(IntObjects[j,i]);
        if Assigned(cp) then
          if (cp.BaseRow >= Row) then
          begin
            cp.BaseRow := cp.BaseRow + Count;
          end;
      end;
    end;
  end;

  for i := 1 to Count do
    FRowList.Insert(Row, TTMSFNCGridRowInfo.Create);

  for i := 1 to Count do
  begin
    FRowDisplayList.Insert(Row,Row + i - 1);
  end;

  for i := 1 to Count do
  begin
    FRowDisplayList[Row + i - 1] := Row + i - 1;
  end;

  for i := Row + Count to FRowDisplayList.Count - 1 do
  begin
    FRowDisplayList[i] := FRowDisplayList[i] + Count;
  end;

  if not FInternalRowUpdate then
  begin
    if Row <= RowH.Count - 1 then
      ridx := RowH.Insert(Row).Index
    else
      ridx := RowH.Add.Index;

    if (ridx >= 0) and (ridx <= RowH.Count -1) then
    begin
      RowH[ridx].Value := DefaultRowHeight;
      RowH[ridx].CellVal := Row;
      for I := ridx + 1 to RowH.Count - 1 do
        RowH[I].CellVal := RowH[I].CellVal + 1;
    end;
  end;

  FRowCount := FRowCount + Count;

  EndUpdate;
end;

function TTMSFNCGridData.IsFixed(Col, Row: Integer): Boolean;
begin
  Result := (Row < FixedRows) or (Row > RowCount - 1 - FixedFooterRows) or (Col < FixedColumns) or (Col > ColumnCount - 1 - FixedRightColumns);
  Result := Result or IsNormalFixed(Col, Row);
end;

function TTMSFNCGridData.IsIgnoredColumn(Col: integer): boolean;
begin
  Result := false;
end;

function TTMSFNCGridData.IsYMergedCell(Col,Row: integer): boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := false;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,DisplToRealRow(Row)]);

  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
  begin
    Result := cp.RowSpan > 1;
  end;
end;

function TTMSFNCGridData.IsXMergedCell(Col,Row: integer): boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := false;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,DisplToRealRow(Row)]);

  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
  begin
    Result := cp.ColSpan > 1;
  end;
end;


function TTMSFNCGridData.IsMerged(Col, Row: integer): boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := false;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,DisplToRealRow(Row)]);

  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
  begin
    Result := (cp.ColSpan > 1) or (cp.RowSpan > 1);
  end;
end;

procedure TTMSFNCGridData.LinearFill(DoFixed: Boolean);
var
  i,j: Integer;
  ro,co,re,ce: Integer;
begin
  BeginUpdate;

  if DoFixed then
  begin
    ro := 0;
    co := 0;
    re := RowCount - 1;
    ce := ColumnCount - 1;
  end
  else
  begin
    ro := FixedRows;
    co := FixedColumns;
    re := RowCount - 1 - FixedFooterRows;
    ce := ColumnCount - 1 - FixedRightColumns;
  end;

  for i := ro to re do
    for j := co to ce do
      Cells[j,i] := IntToStr(j)+':'+IntToStr(i);

  EndUpdate;
end;

procedure TTMSFNCGridData.Loaded;
begin
  inherited;
  InitOriginalColumnSizes;
  IOOffset := Point(FixedColumns, FixedRows);
  SetColumnOrder;
end;

procedure TTMSFNCGridData.LoadFromCSVStream(AStream: TStream;  MaxRows: Integer = -1);
begin
  InputFromCSVStream(AStream, nil, False, MaxRows);
end;

procedure TTMSFNCGridData.InsertFromCSVStream(AStream: TStream; MaxRows: Integer = -1);
begin
  InputFromCSVStream(AStream, nil, True, MaxRows);
end;

procedure TTMSFNCGridData.LoadFromCSVStream(AStream: TStream; Encoding: TEncoding; MaxRows: Integer = -1);
begin
  InputFromCSVStream(AStream, Encoding, False, MaxRows);
end;

procedure TTMSFNCGridData.InsertFromCSVStream(AStream: TStream; Encoding: TEncoding; MaxRows: Integer = -1);
begin
  InputFromCSVStream(AStream, Encoding, True, MaxRows);
end;

procedure TTMSFNCGridData.LoadFromCSV(FileName: string; MaxRows: integer = -1);
begin
  InputFromCSV(FileName, nil, False, MaxRows);
end;

procedure TTMSFNCGridData.LoadFromCSV(FileName: string; Encoding: TEncoding; MaxRows: integer = -1);
begin
  InputFromCSV(FileName, Encoding, False, MaxRows);
end;

procedure TTMSFNCGridData.InitOriginalColumnSizes;
var
  i: integer;
begin
  FOrigColumnW.Clear;

  for I := 0 to ColumnCount - 1 do
  begin
    with FOrigColumnW.Add do
      Value := ColumnWidths[I];
  end;

  FOldSize := Width;
end;

procedure TTMSFNCGridData.InputFromCSV(FileName: string; Encoding: TEncoding; InsertMode: boolean; MaxRows: integer = -1);
var
  ms: TMemoryStream;
  procedure InternalInputFromCSV;
  begin
    ms.Position := 0;
    InputFromCSVStream(ms, Encoding, InsertMode, MaxRows);
  end;
begin
  ms := TMemoryStream.Create;
  try
    {$IFDEF WEBLIB}
    ms.LoadFromFile(FileName,
    procedure
    begin
      InternalInputFromCSV;
      ms.Free;
    end
    );
    {$ENDIF}
    {$IFNDEF WEBLIB}
    ms.LoadFromFile(FileName);
    InternalInputFromCSV;
    {$ENDIF}
  finally
    {$IFNDEF WEBLIB}
    ms.Free;
    {$ENDIF}
  end;
end;

procedure TTMSFNCGridData.InputFromCSVStream(AStream: TStream; {%H-}Encoding: TEncoding; insertmode: Boolean; MaxRows: integer = -1);
var
  buffer,celltext: string;
  s,z: Integer;
  strtCol,strtRow: Integer;
  c1,c2,cm: Integer;
  OldDelimiter: Char;
  delimiterpos,quotepos: Integer;
  lr: TStringList;
  NewRows: integer;
  sl: TTMSFNCGridFileStringList;
  sh: integer;
  hasquote: boolean;

begin
  StrtCol := FIOOffset.X;
  StrtRow := FIOOffset.Y;

  sl := TTMSFNCGridFileStringList.Create;
  sl.LoadFromStream(AStream {$IFNDEF LCLLIB},Encoding{$ENDIF});

  if sl.Eof then
  begin
    raise Exception.Create('File is empty');
    sl.Free;
    Exit;
  end;

  z := StrtRow;

  lr := TStringList.Create;

  if InsertMode then
    z := RowCount;

  OldDelimiter := Options.IO.Delimiter;

  BeginUpdate;

  if (Options.IO.Delimiter = #0) then
  begin
    CellText := '';

    buffer := '';
    sl.ReadLn(buffer);

    if not sl.Eof then sl.ReadLn(CellText);
    sl.Reset;

    cm := 0;
    for s := 1 to 10 do
    begin
      c1 := NumSingleChar(CSVSeparators[s],Buffer);
      c2 := NumSingleChar(CSVSeparators[s],Celltext);
      if (c1 = c2) and (c1 > cm) then
      begin
        Options.IO.Delimiter := CSVSeparators[s];
        cm := c1;
      end;
    end;

    if cm = 0 then
      for s := 1 to 10 do
      begin
        c1 := NumChar(CSVSeparators[s],Buffer);
        c2 := NumChar(CSVSeparators[s],Celltext);
        if (c1 = c2) and (c1 > cm) then
        begin
          Options.IO.Delimiter := CSVSeparators[s];
          cm := c1;
        end;
      end;

    if cm = 0 then
      for s := 1 to 10 do
      begin
        c1 := NumChar(CSVSeparators[s],Buffer);
        c2 := NumChar(CSVSeparators[s],Celltext);
        if (c1 > cm) or (c2 > cm) then
        begin
          Options.IO.Delimiter := CSVSeparators[s];
          cm := Max(c1,c2);
        end;
      end;
  end;

  sl.Reset;

  NewRows := 0;

  sh := 0;

  while (not sl.Eof) and ((MaxRows <= 0) or (NewRows < MaxRows)) do
  begin
    sl.ReadLn(buffer);

    inc(NewRows);

    s := StrtCol;

    if z >= RowCount - FixedFooterRows then
    begin
      RowCount := z + 1000;
    end;

    DelimiterPos := -1;
    while VarCharPos(Options.IO.Delimiter,Buffer,DelimiterPos) > 0 do
    begin
      {$IFDEF ZEROSTRINGINDEX}
      DelimiterPos := DelimiterPos + 1;
      hasquote := Buffer[0] = '"';
      {$ELSE}
      hasquote := Buffer[1] = '"';
      {$ENDIF}
      if hasquote then
      begin
        Delete(buffer,1,1);

        QuotePos := -1;
        if SinglePos('"',Buffer,QuotePos) > 0 then
        begin
          CellText := Copy(buffer,1,QuotePos - 1);
          CellText := DoubleToSingleChar('"',CellText);
          Delete(buffer,1,QuotePos);
        end
        else
          CellText := '';

        VarCharPos(Options.IO.Delimiter,buffer,DelimiterPos);
        {$IFDEF ZEROSTRINGINDEX}
        DelimiterPos := DelimiterPos + 1;
        {$ENDIF}
      end
      else
      begin
        CellText := Copy(buffer,1,DelimiterPos - 1);
      end;

      CSVToLineFeeds(CellText);

      Cells[s,z] := CellText;

      Delete(buffer,1,DelimiterPos);

      Inc(s);
      if (s >= ColumnCount + sh) then
      begin
        ColumnCount := s - sh;
      end;
    end;

    if Length(Buffer) > 0 then
    begin
      {$IFDEF ZEROSTRINGINDEX}
      hasquote := Buffer[0] = '"';
      {$ELSE}
      hasquote := Buffer[1] = '"';
      {$ENDIF}
      if hasquote then
        Delete(buffer,1,1);

      if Length(Buffer) > 0 then
      begin
        {$IFDEF ZEROSTRINGINDEX}
        if hasquote and (Buffer[Length(Buffer) - 1] = '"') then
        {$ELSE}
        if hasquote and (Buffer[Length(Buffer)] = '"') then
        {$ENDIF}
          Delete(Buffer,Length(Buffer),1);
      end;
      if hasquote then
        CellText := DoubleToSingleChar('"',Buffer)
      else
        CellText := Buffer;

      CSVToLineFeeds(CellText);

      Cells[s,z] := CellText;

      Inc(s);

      if (s > ColumnCount + sh) then
      begin
        ColumnCount := s - sh;
      end;
    end;

    Inc(z);

    DoIOProgress(z, sl.Count);
  end;

  sl.Free;

  RowCount := z + FixedFooterRows;

  lr.Free;

  Options.IO.Delimiter := OldDelimiter;

  DoCellsChanged(CellRange(0,0,ColumnCount - 1, RowCount - 1));

  EndUpdate;
end;


procedure TTMSFNCGridData.LoadFromFile(FileName: String);
var
  X,Y,CW: Integer;
  ss,ss1:string;
  strtCol,strtRow: Integer;
  oprogr: Integer;
  seppos: Integer;
  sl: TTMSFNCGridFileStringList;

  function MStrToInt(s:string): Integer;
  var
    {%H-}code,i: Integer;
  begin
    val(s,i,code);
    Result := i;
  end;

begin
  sl := TTMSFNCGridFileStringList.Create;

  sl.LoadFromFile(FileName);

  StrtCol := 0;
  StrtRow := 0;

  ss := '';
  sl.ReadLn(ss);

  if ss <> '' then
  begin
    {$IFDEF ZEROSTRINGINDEX}
    ss1 := Copy(ss,0,CharPos(',',ss));
    {$ELSE}
    ss1 := Copy(ss,1,CharPos(',',ss) - 1);
    {$ENDIF}
    ColumnCount := MStrToInt(ss1) + StrtCol;
    {$IFDEF ZEROSTRINGINDEX}
    ss1 := Copy(ss,CharPos(',',ss) + 2,Length(ss));
    {$ELSE}
    ss1 := Copy(ss,CharPos(',',ss) + 1,Length(ss));
    {$ENDIF}
    FRowCount := MStrToInt(ss1) + StrtRow;
  end;

  if (ColumnCount = 0) or (FRowCount = 0) then
  begin
    sl.Free;
    raise Exception.Create('File contains no data or corrupt file '+FileName);
  end;

  BeginUpdate;

  try
    Cells[ColumnCount - 1, FRowCount - 1] := '';

    oprogr := 1;

    while not sl.Eof do
    begin
      sl.Readln(ss);
      if Pos('cw',ss) = 1 then {parse cw i,Width }
      begin
        seppos := CharPos(',',ss);
        ss1 := Copy(ss,4,seppos - 4);
        ss := Copy(ss,seppos + 1,255);
        CW := MStrToInt(ss1);
        if (cw >= 0) and (cw < ColumnCount) then
          ColumnWidths[cw] := mstrtoint(ss);
      end
      else
      begin
        ss1 := GetToken(ss,',');
        X := mStrToInt(ss1);
        ss1 := GetToken(ss,',');
        Y := mStrToInt(ss1);

        if (X < ColumnCount) and (Y < FRowCount) then
        begin
          ss := FileToLF(ss);
          DoLoadCell(X,Y,ss);
        end;
      end;

      inc(oprogr);
      DoIOProgress(oprogr, sl.Count);
    end;
  finally
    sl.Free;

    DoCellsChanged(CellRange(0,0,ColumnCount - 1, RowCount - 1));

    EndUpdate;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.LoadFromFixed(FileName: string; positions: TTMSFNCGridIntList;
  DoTrim: boolean; MaxRows: integer);
var
  f: TextFile;
  s,sub: string;
  c,r,i: Integer;
  rc: integer;
begin
  AssignFile(f, FileName);
  {$i-}
  Reset(f);
  {$i+}
  if IOResult <> 0 then
    raise Exception.Create('File ' + FileName + ' not found');

  ColumnCount := FIOOffset.X + Positions.Count - 1;

  r := FIOOffset.Y;

  rc := 1;

  while not Eof(f) do
  begin
    ReadLn(f,s);
    c := FIOOffset.X;

    for i := 2 to Positions.Count do
    begin
      sub := Copy(s,Positions[i-2],Positions[i-1] - Positions[i-2]);
      if DoTrim then
        sub := Trim(sub);

      DoLoadCell(c,r,sub);
      Inc(c);
    end;

    Inc(r);

    Inc(rc);

    if (MaxRows <> -1) then
      if rc > MaxRows then
        break;


    if (r >= RowCount) and not Eof(f) then
      RowCount := r + 1;
  end;

  CloseFile(f);

  DoCellsChanged(CellRange(0,0,ColumnCount - 1, RowCount - 1));
end;

procedure TTMSFNCGridData.LoadFromStream(Stream: TStream);
var
  X,Y,LP: Integer;
  ss,ss1: string;

  function ReadString(var s:string): Integer;
  var
    c: char;
  begin
    c := '0';
    s := '';
    while (Stream.Position < Stream.Size) and (c <> #13) do
    begin
      Stream.Read(c,2);
      if (c <> #13) then s := s + c;
    end;
    Stream.Read(c,2);
    Result := Length(s);
  end;

begin
  {Allow to put other data before Grid's data in the stream}
  {stream.position:=0;}

  ss := '';
  BeginUpdate;

  try
    if (Stream.Position < Stream.Size) then
    begin
      if (Readstring(ss) > 0) then
      begin
        ss1 := Copy(ss,1,Pos(',',ss) - 1);
        ColumnCount := StrToInt(ss1) + FIOOffset.X;
        ss1 := Copy(ss,Pos(',',ss) + 1,Length(ss));
        FRowCount := StrToInt(ss1) + FIOOffset.Y;
      end;

      Cells[ColumnCount - 1, FRowCount - 1] := '';
    end;

    while (Stream.Position < Stream.Size) do
    begin
      LP := Stream.Position;

      ReadString(ss);
      if Pos('cw',ss) = 1 then
      begin
        Delete(ss,1,3);
        ss1 := GetToken(ss,',');
        X := StrToInt(ss1);
        Y := StrToInt(ss);
        ColumnWidths[X] := Y;
      end
      else
      begin
        ss1 := GetToken(ss,',');
        if (ss1 = '') then
        begin
          Stream.Position := LP;
          Break;
        end;
        X := StrToInt(ss1);
        ss1 := GetToken(ss,',');
        if (ss1 = '') then
        begin
          Stream.Position := LP;
          Break;
        end;
        Y := StrToInt(ss1);
        ss := FileToLF(ss);
        DoLoadCell(X  + FIOOffset.X, Y + FIOOffset.Y,  ss);
      end;
    end;
  finally
    DoCellsChanged(CellRange(0,0,ColumnCount - 1,RowCount - 1));
    EndUpdate;
  end;
end;

{$ENDIF}

{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.LoadXMLFromFile(AFileName: string; LoadFieldDescr, IgnoreFixedColumns: Boolean);
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(AFileName);
    LoadXMLFromStream(ms, LoadFieldDescr, IgnoreFixedColumns);
  finally
    ms.Free;
  end;
end;
{$ENDIF}

procedure TTMSFNCGridData.LoadXMLFromStream(const AStream: TStream; LoadFieldDescr, IgnoreFixedColumns: Boolean);
var
  s: TStringStream;
begin
  s := TStringStream.Create('');
  try
    AStream.Position := 0;
    s.CopyFrom(AStream, AStream.Size);
    LoadXMLFromText(s.DataString, LoadFieldDescr, IgnoreFixedColumns);
  finally
    s.Free;
  end;
end;

procedure TTMSFNCGridData.LoadXMLFromText(AText: string; LoadFieldDescr, IgnoreFixedColumns: Boolean);
var
  xmldoc: TTMSFNCGridXMLDocument;
  r, c, fc, fr: Integer;
  parentNode, eNode: TTMSFNCGridXMLNode;
  I: Integer;
  sl: TStringList;
  txt: string;
  {$IFDEF LCLLIB}
  ss: TStringStream;
  {$ENDIF}

  procedure LoadChildNodes(ANode: TTMSFNCGridXMLNode);
  var
    J, idx: Integer;
  begin
    for J:= 0 to ANode.ChildNodes.Count - 1 do
    begin
      if ANode.ChildNodes[J].ChildNodes.Count > 1 then
      begin
        LoadChildNodes(ANode.ChildNodes[J]);
      end
      {$IFNDEF LCLWEBLIB}
      else if ANode.ChildNodes[J].NodeType <> ntComment then
      {$ENDIF}
      {$IFDEF LCLWEBLIB}
      else if (ANode.ChildNodes[J].ChildNodes.Count = 1) then
      {$ENDIF}
      begin
        eNode := ANode.ChildNodes[J];

        idx := sl.IndexOf(eNode.NodeName);
        if idx = -1 then
        begin
          sl.Add(eNode.NodeName);
          idx := sl.Count - 1;

          if LoadFieldDescr then
          begin
            txt := eNode.NodeName;
            DoLoadCell(idx + fc, fr - 1, txt);
          end;
        end;

        {$IFNDEF LCLWEBLIB}
        txt := eNode.NodeValue;
        {$ENDIF}
        {$IFDEF LCLWEBLIB}
        txt := eNode.ChildNodes[0].NodeValue;
        {$ENDIF}

        DoLoadCell(idx + fc, r, txt);
      end;
    end;

    if ANode.ChildNodes.Count > 0 then
      r := r + 1;
  end;

begin
  {$IFDEF LCLLIB}
  ss := TStringStream.Create(AText);
  try
    ReadXMLFile(xmldoc, ss);
  finally
    ss.Free;
  end;
  {$ELSE}
  {$IFDEF WEBLIB}
  asm
    var parser = new DOMParser();
    xmldoc = parser.parseFromString(AText, 'text/xml');
  end;
  {$ELSE}
  xmldoc := TXmlDocument.Create(nil);
  xmldoc.LoadFromXML(AText);
  {$ENDIF}
  {$ENDIF}

  parentNode := xmldoc.DocumentElement;

  if IgnoreFixedColumns then
    fc := 0
  else
    fc := FixedColumns;

  if LoadFieldDescr and (FixedRows < 1) then
    fr := 1
  else
    fr := FixedRows;

  r := fr;

  sl := TStringList.Create;
  try
    for I := 0 to parentNode.ChildNodes.Count - 1 do
    begin
      LoadChildNodes(parentNode.ChildNodes[I]);
    end;
  finally
    c := sl.Count + fc;
    sl.Free;
  end;

  ColumnCount := c;
  RowCount := r;
end;

function TTMSFNCGridData.LookupInColumn(Col: integer; AValue: string;
  AllRows: boolean = false; AutoGoto: boolean = false): integer;
var
  sr: integer;
begin
  if AllRows then
    sr := 0
  else
    sr := FixedRows;

  Result := LookupInColumnFromRow(Col, sr, AValue, AllRows, AutoGoto);
end;


function TTMSFNCGridData.LookupInColumnFromRow(Col,Row: integer; AValue: string;
  AllRows: boolean = false; AutoGoto: boolean = false): integer;
var
  i: integer;
  sr,er,m: integer;
  s,v: string;

begin
  Result := -1;

  v := AValue;
  if not Options.Lookup.CaseSensitive then
    v := Uppercase(v);

  if AllRows then
  begin
    sr := FixedRows;
    er := RowCount - 1;
  end
  else
  begin
    sr := Row;
    er := RowCount - 1 - FixedFooterRows;
  end;

  if Col = SortColumn then
  begin
    repeat
      m := sr + round((er - sr) / 2);

      s := Cells[Col,DisplToRealRow(m)];

      if not Options.Lookup.CaseSensitive then
        s := UpperCase(s);

      if Pos(v, s) = 1  then
      begin
        Result := m;
        break;
      end;

      if v > s then
        sr := m
      else
        er := m;
    until (er - sr <= 1);

    if (m > sr) and (Result <> -1) then
    begin
      repeat
        s := Cells[Col,DisplToRealRow(m - 1)];
        if not Options.Lookup.CaseSensitive then
          s := UpperCase(s);

        if Pos(v, s) = 1  then
          dec(m)
        else
          break;
      until (m <= sr);
      Result := m;
    end;
  end
  else
  begin
    for i := sr to er do
    begin
      s := Cells[Col,DisplToRealRow(i)];

      if not Options.Lookup.CaseSensitive then
        s := UpperCase(s);

      if Pos(v, s) = 1 then
      begin
        Result := i;
        break;
      end;
    end;
  end;

  if AutoGoto and (Result <> -1) then
    FocusedCell := MakeCell(Col,Result);
end;

function TTMSFNCGridData.MatchFilter(ARow: Integer): Boolean;
var
  i: Integer;
  s:string;
  temp: Boolean;

begin
  Result := True;

  for i := 1 to FFilter.Count do
  begin
    with FFilter[i - 1] do
    begin
      case FFilter[i - 1].Data of
      fcVirtual: s := Cells[Column,ARow];
      fcNormal:
        begin
          s := Cells[Column, ARow];
          DoGetCellData(Column,ARow,s);
        end;
      fcStripHTML: s := StrippedCells[Column, ARow];
      fcRow: s := RowText[ARow];
      end;

      s := Trim(s);

      if FFilter[i - 1].Data = fcRow then
      begin
        temp := Pos(Uppercase(Condition), Uppercase(s)) > 0;
      end
      else
      begin
        if Prefix <> '' then
          if Pos(Prefix,s) = 1 then
            Delete(s,1,Length(FFilter[i - 1].Prefix));

        if Suffix <> '' then
          if Pos(Suffix,s) = 1 + Length(s) - Length(Suffix) then
            Delete(s,1 + Length(s) - Length(Suffix),Length(s));

        try
          temp := TTMSFNCUtils.MatchStrEx(Condition,s,CaseSensitive);
        except
          temp := false;
        end;
      end;

      case FFilter[i - 1].Operation of
      foSHORT:
        begin
          Result := temp;
          if not Result then
            Break;
        end;
      foNONE: Result := temp;
      foAND: Result := Result AND temp;
      foOR:  Result := Result OR temp;
      foXOR: Result := Result XOR temp;
      end;
    end;
  end;
end;

function TTMSFNCGridData.MaxCharsInCol(ACol: Integer): Integer;
var
  i,k,rc: Integer;
  s,substr: string;

begin
  Result := 0;

  rc := ACol;

  for i := 0 to RowCount - 1 do
  begin
    s := DoSaveCell(rc,i);
    repeat
      substr := GetNextLine(s,true);
      k := Length(substr);
      if k > Result then
        Result := k;
    until s = '';
  end;
end;

procedure TTMSFNCGridData.MergeCells(Col, Row, ColCount, RowCount: integer);
var
  cp,oldcp: TTMSFNCGridCellProperty;
  i,j: integer;
  cl: TTMSFNCGridCellRec;
  usecp: boolean;
begin
  BlockUpdate := True;

  usecp := false;
  cp := nil;

  for i := Col to Col + ColCount - 1 do
  begin
    for j := Row to Row + RowCount - 1 do
    begin
      if not usecp then
      begin
        cp := TTMSFNCGridCellProperty(IntObjects[i,j]);

        if not Assigned(cp) then
          cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(RealToDisplColumn(Col),RealtoDisplRow(Row)));

        cp.BaseCol := Col;
        cp.BaseRow := Row;
        cp.ColSpan := ColCount;
        cp.RowSpan := RowCount;
        usecp := true;
      end
      else
      begin
        oldcp := TTMSFNCGridCellProperty(IntObjects[i,j]);
        if Assigned(oldcp) then
        begin
          if Assigned(oldcp.Control) then
            oldcp.FreeControl;
          oldcp.Free;
        end;
      end;

      IntObjects[i,j] := cp;
    end;
  end;

  cl := BaseCell(Col, Row);
  cl.Col := Col;
  cl.Row := Row;
  SetFocusedCell(cl);

  BlockUpdate := False;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.MergeSelection(ASelection: TTMSFNCGridCellRecRange);
begin
  MergeCells(ASelection.StartCol, ASelection.StartRow, ASelection.EndCol - ASelection.StartCol + 1,
    ASelection.EndRow - ASelection.StartRow + 1);
end;

procedure TTMSFNCGridData.MoveColumn(FromCol, ToCol: integer);
var
  rfi,rti,ii: integer;
  si: TTMSFNCGridSingleListItem;
  cw: TTMSFNCGridSList;
begin
  if FColumnOrder.Count = 0 then
    SetColumnOrder;

  FColumnDisplayList.Move(FromCol, ToCol);

  cw := TTMSFNCGridSList.Create;

  try
    for ii := 0 to ColumnCount - 1 do
      cw.Add(ColumnWidths[ii]);

    cw.Move(FromCol,ToCol);

    ColumnW.Clear;

    for ii := 0 to ColumnCount - 1 do
    begin
      si := ColumnW.Add;
      si.CellVal := ii;
      si.Value := cw[ii];
    end;
  finally
    cw.Free;
  end;

  rfi := FromCol;
  rti := ToCol;

  if (FColumnOrder.Count > Rfi) and
     (FColumnOrder.Count > Rti) then
  begin
    ii := FColumnOrder[Rfi];
    FColumnOrder.Delete(Rfi);
    FColumnOrder.Insert(Rti,ii);
  end;

  UpdateControl;
end;

procedure TTMSFNCGridData.SwapColumns(Col1, Col2: integer);
var
  i, k: integer;
  v: TTMSFNCGridCellData;
  o: TObject;
  val1, val2: Single;
  cc: TTMSFNCGridColumnCalculation;
begin
  for i := 0 to FRowList.Count - 1 do
  begin
    if Assigned(FRowList[i]) then
    begin
      for K := FRowList[i].Data.Count to col1 do
        FRowList[i].Data.Add('');

      for K := FRowList[i].Data.Count to col2 do
        FRowList[i].Data.Add('');

      {$IFDEF VARIANTLIST}
      v := FRowList[i].Data.Values[Col1];
      o := FRowList[i].Data.Objects[Col1];

      FRowList[i].Data.Values[Col1] := FRowList[i].Data.Values[Col2];
      FRowList[i].Data.Objects[Col1] := FRowList[i].Data.Objects[Col2];
      FRowList[i].Data.Values[Col2] := v;
      FRowList[i].Data.Objects[Col2] := o;
      {$ELSE}
      v := FRowList[i].Data.Strings[Col1];
      o := FRowList[i].Data.Objects[Col1];

      FRowList[i].Data.Strings[Col1] := FRowList[i].Data.Strings[Col2];
      FRowList[i].Data.Objects[Col1] := FRowList[i].Data.Objects[Col2];
      FRowList[i].Data.Strings[Col2] := v;
      FRowList[i].Data.Objects[Col2] := o;
      {$ENDIF}
    end;
  end;

  val1 := ColumnWidths[Col1];
  val2 := ColumnWidths[Col2];

  ColumnWidths[Col1] := val2;
  ColumnWidths[Col2] := val1;

  cc := ColumnCalculation[Col1];
  ColumnCalculation[Col1] := ColumnCalculation[Col2];
  ColumnCalculation[Col2] := cc;

  UpdateGridCells;
end;


procedure TTMSFNCGridData.MoveRow(FromRow, ToRow: integer);
var
  rh: TTMSFNCGridSList;
  si: TTMSFNCGridSingleListItem;
  ii: Integer;
begin
  FRowList.Move(FromRow, ToRow);

  rh := TTMSFNCGridSList.Create;

  try
    for ii := 0 to RowCount - 1 do
      rh.Add(RowHeights[ii]);

    rh.Move(FromRow,ToRow);

    RowH.Clear;

    for ii := 0 to RowCount - 1 do
    begin
      si := RowH.Add;
      si.CellVal := ii;
      si.Value := rh[ii];
    end;
  finally
    rh.Free;
  end;

  UpdateControl;
end;

function TTMSFNCGridData.NextSelectableColumn(Col, Row: Integer): TTMSFNCGridCellRec;
var
  c, corig, r, rorig: Integer;
  cs: Integer;
  chk: Boolean;
begin
  c := Col;
  r := Row;

  corig := c;
  rorig := r;

  Result.Col := Col;
  Result.Row := Row;

  cs := ColSpan(c, r);
  if cs > 0 then
  begin
    chk := (Result.Col + cs <= ColumnCount - 1 - FixedRightColumns);
    if chk then
      Result.Col := Result.Col + cs
  end
  else
  begin
    chk := (Result.Col + 1 <= ColumnCount - 1 - FixedRightColumns);
    if chk then
      Result.Col := Result.Col + 1;
  end;

  if chk then
  begin
    while (IsNormalFixed(Result.Col, Result.Row) or IsSuppressedColumn(Result.Col)) and (Result.Col < ColumnCount - 1 - FixedRightColumns) do
    begin
      cs := ColSpan(c, r);
      if cs > 0 then
        Result.Col := Result.Col + cs
      else
        Result.Col := Result.Col + 1;
    end;
  end;

  if IsNormalFixed(Result.Col, Result.Row) or IsSuppressedColumn(Result.Col) then
  begin
    Result.Col := corig;
    Result.Row := rorig;
  end;
end;

function TTMSFNCGridData.NextSelectableRow(Col, Row: Integer): TTMSFNCGridCellRec;
var
  c, r, corig, rorig: Integer;
  rs: Integer;
  chk: Boolean;
begin
  c := Col;
  r := Row;
  corig := c;
  rorig := r;

  Result.Col := Col;
  Result.Row := Row;

  rs := RowSpan(c, r);
  if rs > 0 then
  begin
    chk := (Result.Row + rs <= RowCount - 1 - FixedFooterRows);
    if chk then
      Result.Row := Result.Row + rs
  end
  else
  begin
    chk := (Result.Row + 1 <= RowCount - 1 - FixedFooterRows);
    if chk then
      Result.Row := Result.Row + 1;
  end;

  if chk then
  begin
    while IsNormalFixed(Result.Col, Result.Row) and (Result.Row < RowCount - 1 - FixedFooterRows) do
    begin
      rs := RowSpan(c, r);
      if rs > 0 then
        Result.Row := Result.Row + rs
      else
        Result.Row := Result.Row + 1;
    end;
  end;

  if IsNormalFixed(Result.Col, Result.Row) then
  begin
    Result.Col := corig;
    Result.Row := rorig;
  end;
end;

procedure TTMSFNCGridData.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCGridData.OutputToCSVStream(AStream: TStream; Encoding: TEncoding = nil);
var
  z,s,n,rs: Integer;
  CellText,LineText: String;
  Delim: Char;
  dblquotes: Boolean;
  sl: TStringList;

begin
  if FSaveHiddenCells then
    n := HiddenColumnCount
  else
    n := 0;

  if Options.IO.Delimiter = #0 then
    Delim := ','
  else
    Delim := Options.IO.Delimiter;

  sl := TStringList.Create;
  sl.LineBreak := #13#10;

  ExportNotification(esExportStart, FIOOffset.Y);

  for z := FIOOffset.Y to RowCount - 1 do
  begin
    ExportNotification(esExportNextRow, z);

    LineText := '';

    for s := FIOOffset.X to ColumnCount - 1 + n do
    begin
      if s > FIOOffset.X then
        LineText := LineText + Delim;

      rs := s;

      CellText := DoSaveCell(rs,z);

      CellText := CSVQuotes(CellText);

      dblquotes := false;

      if Options.IO.AlwaysQuotes or ((Pos(Delim,CellText) = 0) and (Pos('"',CellText) > 0)) then
      begin
        CellText := '"' + CellText + '"';
        dblquotes := true;
      end;

      if CellText = '' then
      begin
        if Options.IO.QuoteEmptyCells then
          CellText := '""';
      end;

      if (Pos(Delim,CellText) > 0) or (LinesInText(CellText {,FMultiLineCells}) > 1) then
      begin
        if not dblquotes then
          LinefeedsToCSV(CellText)
        else
          LinefeedsToCSVNQ(CellText);
      end;

      LineText := LineText + CellText;
    end;

    sl.Add(LineText);
    DoIOProgress(z, RowCount - 1);
  end;

  ExportNotification(esExportDone, -1);

  sl.SaveToStream(AStream);
  AStream.Position := 0;
  sl.Free;
end;


{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.OutputToAscii(FileName: string; AppendMode,
  unicode: Boolean);
var
  sc,z,n: Integer;
  CellText,CellStr,str,alistr,remainingstr:string;
  i,rc: Integer;
  MultiLineList: TStringlist;
  anotherlinepos: Integer;
  blanksfiller: string;
  blankscount,NeededLines: Integer;
  AlignValue: TTMSFNCGraphicsTextAlign;
  Colchars:array of byte;
  StrtCol, StrtRow: integer;
  sl: TTMSFNCGridFileStringList;

begin
  sl := TTMSFNCGridFileStringList.Create;
  {$IFNDEF LCLLIB}
  sl.LineBreak := #13#10;
  {$ENDIF}

  if AppendMode then
    if FileExists(FileName) then
      sl.LoadFromFile(FileName);

  if FSaveHiddenCells then
    n := HiddenColumnCount
  else
    n := 0;

  SetLength(ColChars,ColumnCount);

  StrtCol := IOOffset.X;
  StrtRow := IOOffset.Y;

  ColumnCount := ColumnCount + n;

  for i := StrtCol to ColumnCount - 1 do
  begin
    if SaveHiddenCells then
      rc := i
    else
      rc := DisplToRealColumn(i);

    Colchars[rc] := MaxCharsInCol(rc);
  end;

  try
    ExportNotification(esExportStart, StrtRow);

    MultiLineList := TStringlist.Create;
    for z := StrtRow to RowCount - 1 do
    begin
      ExportNotification(esExportNextRow, z);
      str := '';
      for sc := StrtCol to ColumnCount - 1 do
      begin
        if SaveHiddenCells then
          rc := sc
        else
          rc := DisplToRealColumn(sc);

        CellText := DoSaveCell(rc,z);

        if (Pos(#13#10, CellText) > 0) {and MultiLineCells} then
        begin
          CellStr := Copy(CellText,0, Pos(#13#10, CellText) - 1);
          remainingstr := copy(CellText, Pos(#13#10, CellText)+2, Length(CellText));
          NeededLines := 0;
          repeat
            inc(NeededLines);
            blanksfiller := '';
            blankscount := 0;

            if (MultiLineList.Count < NeededLines) then  {we haven't already added a new line for an earlier Colunn}
              MultiLineList.Add('');

            {nr of spaces before cell text}
            for i := 0 to rc - 1 do
              BlanksCount := BlanksCount + ColChars[i] + 1;

            {add to line sufficient blanks}
            for i := 0 to (blankscount - Length(MultiLineList[NeededLines-1])-1) do
              BlanksFiller := BlanksFiller + ' ';

            MultiLineList[NeededLines - 1] := MultiLineList[NeededLines - 1] + BlanksFiller;

            AnotherLinePos := Pos(#13#10, remainingstr);

            if AnotherLinePos > 0 then
            begin
              alistr := Copy(remainingstr, 0, AnotherLinePos - 1);
              remainingstr := Copy(remainingstr,pos(#13#10,remainingstr)+2,Length(remainingstr));
            end
            else
            begin
              alistr := remainingstr;
            end;

            AlignValue := gtaLeading;
            case AlignValue of
            gtaTrailing:while (Length(alistr)<Colchars[rc]) do alistr := ' '+alistr;
            gtaCenter:while (Length(alistr) < Colchars[rc]) do alistr := ' '+alistr+' ';
            end;
            MultiLineList[NeededLines-1] := MultiLineList[NeededLines-1]+alistr;

          until anotherlinepos = 0;
        end
        else
          cellstr := CellText;

        if Pos(#13#10,CellStr) > 0 then
          CellStr := Copy(CellStr,0,pos(#13#10,CellStr)-1);

        AlignValue := gtaLeading;
        case AlignValue of
        gtaTrailing: while (Length(cellstr) < Colchars[rc]) do cellstr := ' ' + cellstr;
        gtaCenter: while (Length(cellstr) < Colchars[rc]) do cellstr := ' ' + cellstr+' ';
        end;

        blanksfiller := '';
        blankscount := Colchars[rc];
        for i := 0 to (blankscount - Length(cellstr)) do
          blanksfiller := blanksfiller + ' ';

        str := str + cellstr + blanksfiller;
      end;  {Column}

      sl.Writeln(Str);
      for i := 0 to MultiLineList.Count-1 do
        sl.Writeln(MultiLineList[i]);     {finally, add the extra lines for this Row}
      MultiLineList.Clear;

    end;    {Row}
    MultiLineList.Free;
  finally
    ColumnCount := ColumnCount - n;
    ExportNotification(esExportDone, -1);

    {$IFNDEF LCLLIB}
    if Unicode then
      sl.SaveToFile(FileName, TEncoding.Unicode)
    else
    {$ENDIF}
      sl.SaveToFile(FileName);

    sl.Free;
  end;
end;

procedure TTMSFNCGridData.OutputToCSV(FileName: string; appendmode, unicode: Boolean);
var
  z,s,n,rs: Integer;
  CellText: String;
  Delim: Char;
  dblquotes: Boolean;
  sl: TTMSFNCGridFileStringList;

begin
  if FSaveHiddenCells then
    n := HiddenColumnCount
  else
    n := 0;

  if Options.IO.Delimiter = #0 then
    Delim := ','
  else
    Delim := Options.IO.Delimiter;

  sl := TTMSFNCGridFileStringList.Create;
  {$IFNDEF LCLLIB}
  sl.LineBreak := #13#10;
  {$ENDIF}

  if AppendMode then
    sl.LoadFromFile(FileName);

  ExportNotification(esExportStart, FIOOffset.Y);

  for z := FIOOffset.Y to RowCount - 1 do
  begin
    ExportNotification(esExportNextRow, z);

    for s := FIOOffset.X to ColumnCount - 1 + n do
    begin
      if s > FIOOffset.X then
        sl.write(Delim);

      rs := s;

      CellText := DoSaveCell(rs,z);

      CellText := CSVQuotes(CellText);

      dblquotes := false;

      if Options.IO.AlwaysQuotes or ((Pos(Delim,CellText) = 0) and (Pos('"',CellText) > 0)) then
      begin
        CellText := '"' + CellText + '"';
        dblquotes := true;
      end;

      if CellText = '' then
      begin
        if Options.IO.QuoteEmptyCells then
          CellText := '""';
      end;

      if (Pos(Delim,CellText) > 0) or (LinesInText(CellText {,FMultiLineCells}) > 1) then
      begin
        if not dblquotes then
          LinefeedsToCSV(CellText)
        else
          LinefeedsToCSVNQ(CellText);
      end;
      sl.Write(CellText);
    end;

    sl.Writeln('');

    DoIOProgress(z, RowCount - 1);
  end;

  ExportNotification(esExportDone, -1);

  {$IFNDEF LCLWEBLIB}
  if Unicode then
    sl.SaveToFile(FileName, TEncoding.UTF8)
  else
  {$ENDIF}
    sl.SaveToFile(FileName);

  sl.Free;
end;

procedure TTMSFNCGridData.OutputToHTML(FileName: string; appendmode, ShowHTML,
  Unicode: boolean);
var
  dir:string;
  i: integer;
  hdrsl, sl: TStringList;

begin
  sl := TStringList.Create;

  if AppendMode then
  try
    sl.LoadFromFile(FileName);
  except
  end;

  if Options.HTMLExport.HeaderFile <> '' then
  begin
    hdrsl := TStringList.Create;
    try
      hdrsl.LoadFromFile(Options.HTMLExport.HeaderFile);
      sl.AddStrings(hdrsl);
    finally
      hdrsl.Free;
    end;
  end;

  dir := ExtractFilePath(filename);

  for i := 1 to length(dir) do
  begin
    if dir[i] = '\' then
      dir[i] := '/';
  end;

  sl.Add(SaveToHTMLString(dir));

  if Options.HTMLExport.FooterFile <> '' then
  begin
    hdrsl := TStringList.Create;
    try
      hdrsl.LoadFromFile(Options.HTMLExport.FooterFile);
      sl.AddStrings(hdrsl);
    finally
      hdrsl.Free;
    end;
  end;

  try
    {$IFNDEF LCLWEBLIB}
    if Unicode then
      sl.SaveToFile(FileName, TEncoding.Unicode)
    else
    {$ENDIF}
      sl.SaveToFile(FileName{$IFNDEF LCLWEBLIB}, TEncoding.ASCII{$ENDIF});
  finally
    sl.Free;
  end;

  if Options.HTMLExport.Show or ShowHTML then
    TTMSFNCUtils.OpenFile(FileName);
end;
{$ENDIF}

procedure TTMSFNCGridData.PasteFromClipboard;
{$IFDEF WEBLIB}
begin
{$ENDIF}
{$IFNDEF WEBLIB}
var
  cliptext: string;
  Content,endofRow: PChar;
  cr: PChar;
  ct,line:string;
  s,z,tabpos,mr,mc: Integer;
  Allow: Boolean;
  nc: boolean;
  ARange: TTMSFNCGridCellRecRange;
begin
  BlockUpdate := True;

  cliptext := TTMSFNCClipBoard.GetText;

  s := Selection.StartCol;
  z := Selection.StartRow;

  if (s = -1) or (z = -1) then
    Exit;

  mr := z;
  mc := s;

  content := PChar(cliptext);

  EndOfRow := StrScan(content,#0);

  repeat
    {$IFDEF MSWINDOWS}
    cr := StrScan(Content, #13);
    {$ELSE}
    cr := StrScan(Content, #10);
    {$ENDIF}
    if cr = nil then
      cr := EndOfRow;
    Line := Copy(StrPas(Content),1,cr - Content);

    if (Length(Line) > 0) and (Options.Clipboard.PasteAction = paInsert) then
    begin
      FInternalRowUpdate := True;
      InsertRows(z,1);
      FInternalRowUpdate := False;
    end;

    TabPos := -1;
    while (TTMSFNCUtils.VarPos(#9,Line,TabPos) > 0) do
    begin
      ct := Copy(line,1,TabPos - 1);

      if (Pos(#10,ct) > 0) then
      begin
        if {FExcelClipboardFormat or} (1 > 0) then
        begin
          if Pos('"',ct)=1 then Delete(ct,1,1);
          if Pos('"',ct) = Length(ct) then
            Delete(ct,Length(ct),1);

          CSVToLineFeeds(ct);
        end;
      end;

      if (s <= ColumnCount) and (z <= RowCount) then
      begin
        if Options.Clipboard.IgnoreReadOnly or not IsReadOnly(s,z) then
        begin
          Allow := True;

          DoClipboardBeforePasteCell(s,z,ct,Allow);

          if Allow then
          begin
            Cells[s,z] := ct;

            if s > mc then
              mc := s;
            if z > mr then
              mr := z;

            DoClipboardAfterPasteCell(s,z,ct);
          end;
        end;
      end;

      Delete(line,1,tabpos);
      inc(s);
      if (s > ColumnCount) and Options.Clipboard.AllowColGrow then
        ColumnCount := s;
    end;

    nc := false;

    if (s <= ColumnCount) and (z <= RowCount) then
    begin
      if Options.Clipboard.IgnoreReadOnly or not IsReadOnly(s,z) then
      begin
        if (cr <> EndOfRow) or (Line <> '') then
        begin
          Allow := True;
          nc := true;

          if (Pos(#10,Line) > 0) then
          begin
            if Pos('"',Line)=1 then Delete(Line,1,1);
            if Pos('"',Line) = Length(Line) then
              Delete(Line,Length(Line),1);
            CSVToLineFeeds(Line);
          end;

          DoClipboardBeforePasteCell(s,z,Line,Allow);

          if Allow then
          begin
            if (s <= ColumnCount) and (z <= RowCount) then
            begin
              Cells[s,z] := Line;

              if s > mc then
                mc := s;
              if z > mr then
                mr := z;

              if (s < ColumnCount) and (z < RowCount) then
                DoClipboardAfterPasteCell(s,z,Line);
            end;
          end;
        end;
      end;
    end;

    if nc then
      Inc(s);

    if (s > ColumnCount) and Options.Clipboard.AllowColGrow then
      ColumnCount := s;

    Content := cr + 1;

    if Content^ = #10 then
    begin
      Content := cr + 2;
    end;

    s := Selection.StartCol;

    if (Content^ <> #0) then
      Inc(z);

    if (z = RowCount) and (cr <> EndOfRow) and (Content^ <> #0) and Options.Clipboard.AllowRowGrow then
      RowCount := RowCount + 1;

  until cr = EndOfRow;

  ARange := CellRange(Selection.StartCol, Selection.StartRow, mc, mr);

  DoPasteNotify(FClipTopLeft, ARange, FClipOperation);

  DoClipboardPaste(ARange);

  DoCellsChanged(ARange);

  BlockUpdate := False;

//  FClipTopLeft := Point(-1,-1);
//  FClipOperation := coNone;

  UpdateGridCells;
  if Options.Clipboard.AllowColGrow or Options.Clipboard.AllowRowGrow then
    UpdateControl;
{$ENDIF}
end;

function TTMSFNCGridData.PreviousSelectableColumn(Col, Row: Integer): TTMSFNCGridCellRec;
var
  c, r, rorig, corig: Integer;
begin
  c := Col;
  r := Row;
  corig := c;
  rorig := r;

  Result.Col := Col;
  Result.Row := Row;

  if (Result.Col - 1 >= FixedColumns) then
  begin
    Result.Col := Result.Col - 1;

    while (IsNormalFixed(Result.Col, Result.Row) or IsSuppressedColumn(Result.Col)) and (Result.Col > FixedColumns) do
      Result.Col := Result.Col - 1;
  end;

  if IsNormalFixed(Result.Col, Result.Row) or IsSuppressedColumn(Result.Col) then
  begin
    Result.Col := corig;
    Result.Row := rorig;
  end;
end;

function TTMSFNCGridData.PreviousSelectableRow(Col, Row: Integer): TTMSFNCGridCellRec;
var
  c, r, corig, rorig: Integer;
begin
  c := Col;
  r := Row;
  corig := c;
  rorig := r;

  Result.Col := Col;
  Result.Row := Row;

  if (Result.Row - 1 >= FixedRows) then
  begin
    Result.Row := Result.Row - 1;

    while IsNormalFixed(Result.Col, Result.Row) and (Result.Row > FixedRows) do
      Result.Row := Result.Row - 1;
  end;

  if IsNormalFixed(Result.Col, Result.Row) then
  begin
    Result.Col := corig;
    Result.Row := rorig;
  end;
end;

function TTMSFNCGridData.ColSpan(Col, Row: integer): integer;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := 0;

  cp := TTMSFNCGridCellProperty(IntObjects[DisplToRealColumn(Col),DisplToRealRow(Row)]);
  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
    Result := cp.ColSpan;
end;

function TTMSFNCGridData.ColumnAtPosition(ACol: integer): integer;
begin
  Result := ACol;
  if FColumnOrder.Count = 0 then
  begin
    SetColumnOrder;
    if FColumnOrder.Count = 0 then
      Exit;
  end;

  Result := FColumnOrder[ACol];

  if GroupColumn <> -1 then
  begin
    if Result >= GroupColumn then
      Result := Result + 1;
  end;
end;

function TTMSFNCGridData.ColumnAvg(ACol, FromRow, ToRow: Integer): Double;
begin
  if (FromRow = -1) or (ToRow = -1) then
  begin
    FromRow := FixedRows;
    ToRow := RowCount - 1 - FixedFooterRows;
  end;

  if (ToRow - FromRow + 1) > 0 then
    Result := ColumnSum(ACol,FromRow,ToRow)/(ToRow - FromRow + 1)
  else
    Result := 0;
end;

function TTMSFNCGridData.ColumnCustomCalc(ACol, FromRow,
  ToRow: Integer): Double;
begin
  Result := DoColumnCalc(ACol,FromRow, ToRow);
end;

function TTMSFNCGridData.ColumnCustomCalcGroup(ACol, FromRow,
  ToRow: Integer): Double;
begin
  Result := DoColumnCalcGroup(ACol,FromRow, ToRow);
end;


function TTMSFNCGridData.ColumnDistinct(ACol, FromRow, ToRow: Integer): Double;
var
  sl: TStringList;
  i: integer;
begin
  if (FromRow = -1) or (ToRow = -1) then
  begin
    FromRow := FixedRows;
    ToRow := RowCount - 1 - FixedFooterRows;
  end;

  ExportNotification(esExportStart, FromRow);

  sl := TStringList.Create;

  try
    sl.Duplicates := dupIgnore;
    sl.Sorted := true;
    sl.CaseSensitive := false;

    for i := FromRow to ToRow do
    begin
      ExportNotification(esExportNextRow, i);
      sl.Add(Cells[ACol,i]);
    end;
    Result := sl.Count;

  finally
    ExportNotification(esExportDone, -1);
    sl.Free;
  end;
end;

function TTMSFNCGridData.ColumnMax(ACol, FromRow, ToRow: Integer): Double;
var
  m: Double;
  i: Integer;

begin
  if (FromRow = -1) or (ToRow = -1) then
  begin
    FromRow := FixedRows;
    ToRow := RowCount - 1 - FixedFooterRows;
  end;

  m := Floats[ACol,fromRow];

  ExportNotification(esExportStart, FromRow);

  for i := FromRow to ToRow do
  begin
    if IsBaseCell(ACol, i) then
    begin
      ExportNotification(esExportNextRow, i);
      try
        if m < Floats[ACol,i] then
          m := Floats[ACol,i];
      except
      end;
    end;
  end;

  ExportNotification(esExportDone, -1);
  Result := m;
end;

function TTMSFNCGridData.ColumnMin(ACol, FromRow, ToRow: Integer): Double;
var
  m: Double;
  i: Integer;

begin
  if (FromRow = -1) or (ToRow = -1) then
  begin
    FromRow := FixedRows;
    ToRow := RowCount - 1 - FixedFooterRows;
  end;

  m := Floats[ACol,fromRow];

  ExportNotification(esExportStart, FromRow);

  for i := FromRow to ToRow do
  begin
    if IsBaseCell(ACol, i) then
    begin
      ExportNotification(esExportNextRow, i);
      try
        if m > Floats[ACol,i] then
          m := Floats[ACol,i];
      except
      end;
    end;
  end;

  ExportNotification(esExportDone, -1);
  Result := m;
end;

function TTMSFNCGridData.ColumnPosition(ACol: integer): integer;
var
  i: integer;
begin
  Result := ACol;

  if FColumnOrder.Count = 0 then
    Exit;

  for i := 1 to ColumnCount + HiddenColumnCount do
  begin
    if ACol = FColumnOrder[i - 1] then
    begin
      Result := i - 1;
      break;
    end;
  end;

  if GroupColumn <> -1 then
  begin
    if ACol >= GroupColumn then
    begin
      Result := Result - 1;
    end;
  end;
end;

function TTMSFNCGridData.ColumnStatesToString: string;
var
  i,ci: integer;
  res: string;
begin
  res := inttostr(ColumnCount + HiddenColumnCount) + '#';

  for i := 0 to ColumnCount - 1 + HiddenColumnCount do
  begin
    ci := trunc(ColumnWidths[i]);
    if i = 0 then
      res := res + inttostr(ci)
    else
      res := res + ',' + inttostr(ci);
  end;

  res := res + '#';

  if FColumnOrder.Count = 0 then
  begin
    for i := 0 to ColumnCount - 1 + HiddenColumnCount do
    begin
      if i = 0 then
        res := res + IntToStr(i)
      else
        res := res + ',' + IntToStr(i);
    end;
  end
  else
    for i := 0 to FColumnOrder.Count - 1 do
    begin
      if i = 0 then
        res := res + IntToStr(FColumnOrder[i])
      else
        res := res + ',' + IntToStr(FColumnOrder[i]);
    end;

  res := res + '#';


  for i := 0 to ColumnCount - 1 + HiddenColumnCount do
  begin
    if i = 0 then
    begin
      if IsHiddenColumn(i) then
        res := res + '0'
      else
        res := res + '1';
    end
    else
    begin
      if IsHiddenColumn(i) then
        res := res + ',0'
      else
        res := res + ',1';
    end;
  end;

  Result := res;
end;

function TTMSFNCGridData.ColumnStdDev(ACol, FromRow, ToRow: Integer): Double;
var
  m,d: double;
  i: integer;
begin
  if (FromRow = -1) or (ToRow = -1) then
  begin
    FromRow := FixedRows;
    ToRow := RowCount - 1 - FixedFooterRows;
  end;

  m := ColumnAvg(ACol, FromRow, ToRow);

  ExportNotification(esExportStart, FromRow);

  d := 0;

  for i := fromRow to toRow do
  begin
    d := d + sqr(Floats[ACol,i] - m);
  end;

  Result := sqrt(d / (ToRow - FromRow + 1));

  ExportNotification(esExportDone, -1);
end;

function TTMSFNCGridData.ColumnSum(ACol, FromRow, ToRow: Integer): Double;
var
  i: Integer;
  sum: Double;
begin
  if (FromRow = -1) or (ToRow = -1) then
  begin
    FromRow := FixedRows;
    ToRow := RowCount - 1 - FixedFooterRows;
  end;

  sum := 0;

  ExportNotification(esExportStart, FromRow);

  for i := FromRow to ToRow do
  begin
    if IsBaseCell(ACol, i) then
    begin
      ExportNotification(esExportNextRow, i);
      try
        Sum := Sum + Floats[ACol,i]
      except
      end;
    end;
  end;

  ExportNotification(esExportDone, -1);
  Result := sum;
end;

function TTMSFNCGridData.CompareRows(Col, Row1, Row2: integer): integer;
var
  {$IFDEF WEBLIB}
  s1,s2: TTMSFNCGridCellData;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  s1,s2: variant;
  {$ENDIF}
  SortFormat: TTMSFNCGridSortFormat;
  Prefix, Suffix: string;
  dt1,dt2: TDateTime;
  db1,db2: double;
  st1,st2: string;
  b1,b2: boolean;
  res,{%H-}err: integer;
  t1,t2: TTMSFNCGridAutoType;
  v: Double;
begin
  s1 := Cells[Col,Row1];
  s2 := Cells[Col,Row2];

  if (Options.Sorting.IgnoreCase) then
  begin
    s1 := Uppercase(s1);
    s2 := Uppercase(s2);
  end;

  Suffix := '';
  Prefix := '';
  SortFormat := ssAutomatic;
  DoSortFormat(Col, SortFormat, Prefix, Suffix);

  case SortFormat of
    ssAutomatic:
      begin
        t1 := isType(s1);
        t2 := isType(s2);

        if (t1 in [atNumeric, atFloat]) and (t2 in [atNumeric, atFloat]) then
        begin
          val(s1,db1,err);
          val(s2,db2,err);
          if db1 = db2 then
            res := 0
          else
            if db1 > db2 then
              res := 1
            else
              res := -1;

        end
        else
        begin
          if s1 = s2 then
            res := 0
          else
            if s1 > s2 then
              res := 1
            else
              res := -1;
        end;
      end;
    ssAlphabetic, ssHTML:
      begin
        st1 := s1;
        st2 := s2;

        if SortFormat = ssHTML then
        begin
          st1 := TTMSFNCUtils.HTMLStrip(st1);
          st2 := TTMSFNCUtils.HTMLStrip(st2);
        end;

        if st1 = st2 then
          res := 0
        else
          if st1 > st2 then
            res := 1
          else
            res := -1;
      end;
    ssAlphabeticNoCase:
      begin
        st1 := Uppercase(VarToStr(s1));
        st2 := Uppercase(VarToStr(s2));
        if st1 = st2 then
          res := 0
        else
          if st1 > st2 then
            res := 1
          else
            res := -1;
      end;
    ssNumeric:
      begin
        if s1 <> '' then
        begin
          v := Infinity;
          TryStrToFloat(s1, v);
        end
        else
          v := Infinity - 1;
        db1 := v;

        if s2 <> '' then
        begin
          v := Infinity;
          TryStrToFloat(s2, v);
        end
        else
          v := Infinity - 1;
        db2 := v;

        if db1 = db2 then
          res := 0
        else
          if db1 > db2 then
            res := 1
          else
            res := -1;
      end;
    ssDate:
      begin
        dt1 := 0;
        if s1 <> '' then
          try
            dt1 := VarToDateTime(s1);
          except
          end;

        dt2 := 0;
        if s2 <> '' then
          try
            dt2 := VarToDateTime(s2);
          except
          end;

        if dt1 = dt2 then
          res := 0
        else
          if dt1 > dt2 then
            res := 1
          else
            res := -1;
      end;
    ssCheckBox:
      begin
        b1 := CheckBoxState[Col,Row1];
        b2 := CheckBoxState[Col,Row2];
        if b1 and not b2 then
          res := 1
        else
          if b1 = b2 then
            res := 0
          else
            res := -1;
      end;
    ssCustom:
      begin
        DoCustomCompare(s1,s2,res);
      end;
    ssRaw:
      begin
        DoRawCompare(Col,Row1,Row2,res);
      end;
  end;

  if Options.Sorting.IgnoreBlanks then
  begin
    case FSortDirection of
    sdAscending:
      begin
        if (s1 = '') and (s2 <> '') then
          if Options.Sorting.BlankPosition = TTMSFNCGridSortBlankPosition.blFirst then
            res := -1
          else
            res := +1;

        if (s2 = '') and (s1 <> '') then
          if Options.Sorting.BlankPosition = TTMSFNCGridSortBlankPosition.blFirst then
            res := +1
          else
            res := -1;

      end;
    sdDescending:
      begin
        if (s1 = '') and (s2 <> '') then
          if Options.Sorting.BlankPosition = TTMSFNCGridSortBlankPosition.blFirst then
            res := +1
          else
            res := -1;

        if (s2 = '') and (s1 <> '') then
          if Options.Sorting.BlankPosition = TTMSFNCGridSortBlankPosition.blFirst then
            res := -1
          else
            res := +1;

      end;
    end;
  end;

  Result := res;
end;

function TTMSFNCGridData.CompareLine(Col, Row1, Row2: Integer): Integer;
var
  res: Integer;
begin
  if IsIgnoredColumn(Col) then
    res := 0
  else
    res := CompareRows(Col, Row1, Row2);

  if (res = 0) and Options.Sorting.MultiColumn then
  begin
    if Col <= ColumnCount - 2 then
    begin
      Inc(Col);
      res := CompareLine(Col, Row1, Row2);
    end;
  end;

  CompareLine := res;
end;


function TTMSFNCGridData.CompareRowsIndexed(Col, Row1, Row2: integer): integer;
var
  res: Integer;
  idx: Integer;
  sd: TTMSFNCGridSortDirection;
begin
  idx := FSortIndexes[Col] and $7FFFFFFF;

  if (FSortIndexes[Col] and $80000000 = $80000000) then
    sd := sdDescending
  else
    sd := sdAscending;

  if IsIgnoredColumn(idx) then
    res := 0
  else
    res := CompareRows(idx,Row1,Row2);

  if (res = 0) and Options.Sorting.MultiColumn then
  begin
    if (Col < FSortIndexes.Count - 1) then
    begin
      Inc(Col);
      res := CompareRowsIndexed(Col, Row1, Row2);
    end;
  end
  else
  begin
    if sd = sdDescending then
    begin
      res := -res;
    end;
  end;

  CompareRowsIndexed := res;
end;

procedure TTMSFNCGridData.CopyToClipboard(SelectedCells: boolean);
{$IFDEF WEBLIB}
begin
{$ENDIF}
{$IFNDEF WEBLIB}
var
  s,z: Integer;
  ct, cliptext: string;
  ACellRange: TTMSFNCGridCellRecRange;
begin
  if SelectedCells then
    ACellRange := Selection
  else
    ACellRange := CellRange(0,0,ColumnCount - 1, RowCount - 1);

  if (ACellRange.StartRow = -1) or (ACellRange.StartCol = -1) or (ACellRange.EndRow = -1) or (ACellRange.EndCol = -1) then
    Exit;

  FClipTopLeft := Point(ACellRange.StartCol, ACellRange.StartRow);
  FClipOperation := coCopy;

  cliptext := '';

  for z := ACellRange.StartRow to ACellRange.EndRow do
  begin
    for s := ACellRange.StartCol to ACellRange.EndCol do
    begin
      if DoIsCellSelected(s, z) then
      begin
        ct := Cells[s,z];

        if (Pos(#13,ct) > 0) then
        begin
          ct := '"' + CRToLF(ct) + '"';
        end;

        if (Pos('</',ct) > 0) then
          ct := TTMSFNCUtils.HTMLStrip(ct);

        if (LinesInText(ct,true)>1) then
          LineFeedsToCSV(ct);

        if s < ACellRange.EndCol then
          cliptext := cliptext + ct + #9
        else
          cliptext := cliptext + ct;
      end
      else
        cliptext := cliptext + #9;
    end;

    if ACellRange.StartRow < ACellRange.EndRow then
      cliptext := cliptext + #13#10;
  end;

  TTMSFNCClipBoard.SetText(cliptext);
{$ENDIF}
end;

procedure TTMSFNCGridData.QuickSortRows(Col, L, R: Integer);
var
  i,j,k,m: Integer;

begin
  if FSortDirection = sdAscending then
    FSortDir := 1
  else
    FSortDir := -1;

  i := L;
  j := R;
  m := (L + R) shr 1;

  for k := 0 to ColumnCount - 1 do
  begin
    Cells[k, RowCount - 1] := Cells[k, m];
    Objects[k, RowCount - 1] := Objects[k, m];
  end;

  repeat
    while (CompareRows(Col,RowCount - 1,i) = FSortDir) and (i < R) do Inc(i);
    while (CompareRows(Col,j,RowCount - 1) = FSortDir) and (j > L) do Dec(j);

    if i <= j then
    begin
      if i <> j then
      begin
        if CompareRows(Col,i,j) <> 0 then
        begin
          case Options.Sorting.Columns of
          scAll: SwapRows(i,j);
          scSingle: SwapCells(Col,i,j);
          scNormal: SwapNormalCells(i,j);
          end;
        end;
      end;
      Inc(i);
      Dec(j);
    end;
  until i > j;

  if L < j then
    QuickSortRows(Col,L,j);
  if i < R then
    QuickSortRows(Col,i,R);
end;


procedure TTMSFNCGridData.QuickSortRowsIndexed(Col, L, R: Integer);
var
  i,j,k,m: Integer;

begin
  if FSortDirection = sdAscending then
    FSortDir := 1
  else
    FSortDir := -1;

  i := L;
  j := R;
  m := (L + R) shr 1;

  for k := 0 to ColumnCount - 1 do
  begin
    Cells[k, RowCount - 1] := Cells[k, m];
    Objects[k, RowCount - 1] := Objects[k, m];
  end;

  repeat
    while (CompareRowsIndexed(Col,RowCount - 1,i) = FSortDir) and (i < R) do Inc(i);
    while (CompareRowsIndexed(Col,j,RowCount - 1) = FSortDir) and (j > L) do Dec(j);

    if i <= j then
    begin
      if i <> j then
      begin
        if CompareRowsIndexed(Col,i,j) <> 0 then
        begin
          case Options.Sorting.Columns of
            scAll: SwapRows(i,j);
            scNormal: SwapNormalCells(i,j);
            scSingle: SwapCells(Col,i,j);
          end;
        end;
      end;
      Inc(i);
      Dec(j);
    end;
  until i > j;

  if L < j then
    QuickSortRowsIndexed(Col,L,j);
  if i < R then
    QuickSortRowsIndexed(Col,i,R);
end;

function TTMSFNCGridData.Replace(OrigStr, NewStr: string;
  FindParams: TTMSFNCGridFindParams): integer;
var
  cp: TPoint;
  i: integer;
begin
  i := 0;

  cp := FindFirst(OrigStr, FindParams);

  while (cp.X <> -1) do
  begin
    Cells[cp.X, cp.Y] := NewStr;
    inc(i);
    cp := FindNext;
  end;

  Result := i;
end;

procedure TTMSFNCGridData.ResetColumnOrder;
var
  i: Integer;
  rst: Boolean;
begin
  UnHideColumnsAll;
  if FColumnOrder.Count = 0 then
  begin
    SetColumnOrder;
    Exit;
  end;

  rst := False;
  while not rst do
  begin
    rst := True;
    for i := 1 to ColumnCount do
    begin
      if i - 1 > FColumnOrder[i - 1] then
      begin
        rst := False;
        MoveColumn(i - 1,FColumnOrder[i - 1]);
      end;
    end;
  end;
end;

function TTMSFNCGridData.RowSpan(Col, Row: integer): integer;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := 0;
  cp := TTMSFNCGridCellProperty(IntObjects[DisplToRealColumn(Col),DisplToRealRow(Row)]);
  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
    Result := cp.RowSpan;
end;

function TTMSFNCGridData.CellSpan(Col, Row: integer): TPoint;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := Point(0,0);
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and (cp.BaseCol <> -1) and (cp.BaseRow <> -1) then
  begin
    Result := Point(cp.ColSpan, cp.RowSpan);
  end;
end;

procedure TTMSFNCGridData.SaveToCSVStream(AStream: TStream; Encoding: TEncoding = nil);
begin
  OutputToCSVStream(AStream, Encoding);
end;


{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.SaveToASCII(FileName: string; Unicode: boolean);
begin
  OutputToASCII(FileName, false, Unicode);
end;

procedure TTMSFNCGridData.SaveToCSV(FileName: string; Unicode: boolean);
begin
  OutputToCSV(FileName,False,Unicode);
end;

procedure TTMSFNCGridData.SaveToFile(FileName: string; Unicode: boolean);
var
  i,j,n: Integer;
  ss,CellText: string;
  sl: TStringList;
begin
  sl := TStringList.Create;

  if FSaveHiddenCells then
    n := HiddenColumnCount
  else
    n := 0;

  ss := IntToStr(ColumnCount+n) + ',' + IntToStr(RowCount);

  sl.Add(ss);

  for i := FIOOffset.X to ColumnCount - 1 + n do
  begin
    ss := 'cw '+IntToStr(i) + ',' + floattostr(ColumnWidths[i]);
    sl.Add(ss);
  end;

  ExportNotification(esExportStart, FIOOffset.Y);

  for i := FIOOffset.Y to RowCount - 1 do
  begin
    ExportNotification(esExportNextRow, i);
    for j := FIOOffset.X to ColumnCount + n - 1 do
    begin
      CellText := DoSaveCell(j,i);

      if CellText <> '' then
      begin
        ss := IntToStr(j) + ',' + IntToStr(i) + ',' + LFToFile(CellText);
        sl.Add(ss);
      end;
    end;

    DoIOProgress(i, RowCount - 1);
  end;

  ExportNotification(esExportDone, -1);

  {$IFNDEF LCLWEBLIB}
  if Unicode then
    sl.SaveToFile(FileName, TEncoding.Unicode)
  else
  {$ENDIF}
    sl.SaveToFile(FileName);

  sl.Free;
end;

procedure TTMSFNCGridData.SaveToFixed(FileName: string; positions: TTMSFNCGridIntList);
var
  f: TextFile;
  c,r,m,n,nh,rc,CC: Integer;
  s,su: string;

begin
  Assignfile(f,FileName);
  {$i-}
  ReWrite(f);
  {$i+}
  if IOResult <> 0 then
    raise Exception.Create('Cannot Create file '+FileName);

  cc := 0;

  if SaveHiddenCells then
    nh := HiddenColumnCount
  else
    nh := 0;

  CC := CC + nh;

  ExportNotification(esExportStart, FIOOffset.Y);

  for r := FIOOffset.Y to RowCount - 1 do
  begin
    ExportNotification(esExportNextRow, r);

    s := '';
    for c := FIOOffset.X to CC - 1 do
    begin
      rc := c;

      su := DoSaveCell(rc,r);
      n := Length(su);

      if n > Positions[c - FIOOffset.X] then
        su := Copy(su,1,Positions[c - FIOOffset.X])
      else
        for m := 1 to Positions[c - FIOOffset.X] - n do
          su := su + ' ';

      s := s + su;
    end;
    WriteLn(f,s);

    DoIOProgress(r, RowCount - 1);
  end;

  ExportNotification(esExportDone, -1);

  CloseFile(f);
end;

procedure TTMSFNCGridData.SaveToHTML(FileName: string; ShowHTML, Unicode: boolean);
begin
  OutputToHTML(Filename,false, ShowHTML, Unicode);
end;
{$ENDIF}

function TTMSFNCGridData.SaveToHTMLString(dir: string): string;
var
  i,j,mc,mr: Integer;
  s,tablestyle: string;
  slist: TStringlist;
  FirstRow: Integer;
  strtCol,strtRow: Integer;
  wraptxt,colwtxt,CellText,SpanTxt: string;
  DoneColW: Boolean;
  Span:TPoint;
  cssval: string;
  cssidx: integer;
  CSSList: TStringList;
  res: string;
  Pic: TTMSFNCBitmap;
  images_counter:integer;
  htmlhdr: string;
  summ: string;
  l: TTMSFNCGridCellLayout;
  cellclasstype,prevclasstype: TTMSFNCGridCellClass;
  cellstr: string;
  cell: TTMSFNCGridCell;
  a: Integer;

  function HTMLAddImage(Pic: TTMSFNCBitmap;counter:integer;dir:string):string;
  var
    fname:string;
  begin
    {$IFNDEF WEBLIB}
    if not DirectoryExists(dir + 'images') then
      if not CreateDir(dir + 'images') then
        raise Exception.Create('Cannot create ' + dir + 'images');
    {$ENDIF}

    fname := dir + 'images/img' + inttostr(counter) + '.png';
    {$IFNDEF WEBLIB}
    Pic.SaveToFile(fname);
    {$ENDIF}
    Result := fname;
  end;

  function MakeHREF(s:string):string;
  begin
   Result := s;

   if not Options.URL.Show then
     Exit;

   if (pos('://',s) > 0) and (pos('</',s) = 0) then
   begin
     if not Options.URL.Full then
       Result := '<a href=' + s + '>' + copy(s,pos('://',s)+3,255) + '</a>'
     else
       Result := '<a href=' + s + '>' + s + '</a>';
    end;

   if (pos('mailto:',s) > 0) and (pos('</',s) = 0) then
    begin
     if not Options.URL.Full then
        Result := '<a href=' + s + '>' + copy(s,pos('mailto:',s) + 7,255)+'</a>'
      else
        Result := '<a href=' + s + '>' + s + '</a>';
    end;
  end;

  procedure AppendStrEx(var s: string; newstr: string);
  begin
    if s = '' then
      s := newstr
    else
      s := s + ';' + newstr;
  end;

  function CSS(AFont: TTMSFNCGraphicsFont; AColor, AFontColor: TTMSFNCGraphicsColor; AHAlign, AVAlign: TTMSFNCGraphicsTextAlign; AAngle: Integer): string;
  var
    res: string;
  begin
    res := '';

    if (AColor <> gcNull) and Options.HTMLExport.SaveColors then
      AppendStrEx(res,'background-color: '+ TTMSFNCGraphics.ColorToHTML(AColor)+'');

    if (AFontColor <> gcNull) and Options.HTMLExport.SaveColors then
      AppendStrEx(res,'color: '+ TTMSFNCGraphics.ColorToHTML(AFontColor)+'');

    AppendStrEx(res,'font-size:'+IntTostr(Round(AFont.Size))+'pt');

    if (TFontStyle.fsItalic in AFont.Style) then
      AppendStrEx(res,'font-style: italic');

    if (TFontStyle.fsBold in AFont.Style) then
      AppendStrEx(res,'font-weight: bold');

    AppendStrEx(res,'font-family: '+AFont.Name+'');

    case AHAlign of
    gtaCenter: AppendStrEx(res,'text-align: center');
    gtaTrailing: AppendStrEx(res,'text-align: right');
    end;

    case AVAlign of
    gtaLeading: AppendStrEx(res,'vertical-align: top');
    gtaCenter: AppendStrEx(res,'vertical-align: middle');
    gtaTrailing: AppendStrEx(res,'vertical-align: bottom');
    end;

    Result := res;
  end;

  function ExportImg({%H-}ACol, {%H-}ARow: integer; lst: TStringList; Pic: TTMSFNCBitmap): string;
  var
    image: string;
  begin
    Result := '';
    if not IsBitmapEmpty(Pic) then
    begin
      image := '<img src= "file://' + HTMLAddImage(Pic,images_counter,dir)+'">';
      lst.Add('<TD class="c'+inttostr(cssidx)+'"'+spantxt + wraptxt + colwtxt+'>' + image + s +'</td>');
      inc(images_counter);
    end
    else
      lst.Add('<TD class="c'+inttostr(cssidx)+'"'+spantxt + wraptxt + colwtxt+'>' +s+'</td>');
  end;
begin
  StrtCol := IOOffset.X;
  StrtRow := IOOffset.Y;

  SList := TStringlist.Create;
  SList.Sorted := False;

  CSSList := TStringList.Create;

  DoneColW := False;

  htmlhdr := '';

  cell := nil;
  cellclasstype := nil;
  prevclasstype := nil;

  l := TTMSFNCGridCellLayout.Create;

  try

    if Options.HTMLExport.XHTML then
    begin
      htmlhdr := htmlhdr + '<?xml version="1.0" encoding="utf-8"?>'#13#10
      +'<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"'#13#10
      +'"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">'#13#10
      +'<HTML xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">'#13#10;
    end;

    if Options.HTMLExport.PrefixTag <> '' then
      htmlhdr := htmlhdr + Options.HTMLExport.PrefixTag;

    if Options.HTMLExport.TableStyle <> '' then
      TableStyle := ' ' + Options.HTMLExport.TableStyle + ' '
    else
      TableStyle := '';

    if Options.HTMLExport.Summary <> '' then
      summ := 'SUMMARY="' + Options.HTMLExport.Summary + '" '
    else
      summ := '';

    SList.Add('<TABLE BORDER="'+IntToStr(Options.HTMLExport.BorderSize)+'" '+
        'CELLSPACING="'+IntToStr(Options.HTMLExport.CellSpacing)+'" ' +
        'CELLPADDING="'+IntToStr(Options.HTMLExport.CellPadding)+'" ' +
        summ +
        TableStyle +
        ' WIDTH="' + IntToStr(Options.HTMLExport.Width) + '%">');

    FirstRow := StrtRow;

    if (FixedRows > 0) and (IOOffset.Y < FixedRows) then
    begin
      SList.Add('<TR>');
      DoneColW := True;

      for i := StrtCol to ColumnCount - 1 do
      begin
        mc := 0;
        mr := 0;
        if IsBaseCellEx(i,0,mc,mr) then
        begin
          Span := CellSpan(i,0);

          SpanTxt := '';

          if Span.X > 0 then
            SpanTxt := ' COLSPAN="' + IntToStr(Span.X) + '"';

          if Span.Y > 0 then
            SpanTxt := SpanTxt + ' ROWSPAN="' + IntToStr(Span.Y) + '"';

          CellText := DoSaveCell(i,0);

          s := HTMLLineBreaks(CellText);
          s := MakeHREF(s);

          if s = '' then
            s := '&nbsp;';

          if Options.HTMLExport.ColumnWidths.Count > i then
            colwtxt := ' WIDTH=' + IntToStr(Options.HTMLExport.ColumnWidths[i])
          else
            colwtxt := '';

          cellclassType := nil;
          DoGetCellClass(i, 0, cellclasstype);
          cellstr := '';
          DoGetCellData(i, 0, cellstr);

          if (prevclasstype <> cellclasstype) and (prevclasstype <> nil) and Assigned(cell) then
            cell.Free;

          if (prevclasstype <> cellclasstype) then
          begin
            cell := cellclasstype.Create(Self);

            if (cell is TTMSFNCGridCell) then
              (cell as TTMSFNCGridCell).BitmapContainer := BitmapContainer;

            prevclasstype := cellclasstype;
          end;

          if (Cell is TTMSFNCGridCell) then
          begin
            l.Assign((Cell as TTMSFNCGridCell).Layout);
            l.Assign(GetDefaultNormalLayout);

            DoGetCellLayout(i, 0, l, TTMSFNCGridCellState.csNormal);
            (Cell as TTMSFNCGridCell).AssignLayout(l);
          end;

          DoGetCellProperties(i, 0, cell);

          a := 0;
          DoGetCellRotation(i, 0, a);

          WrapTxt := '';

          cssval := css(l.Font, l.Fill.Color, l.Font.Color, l.TextAlign, l.VerticalTextAlign, a);

          cssidx := CSSList.IndexOf(cssval);
          if cssidx = -1 then
            cssidx := CSSList.Add(cssval);

        if Options.HTMLExport.ExportImages then
          Pic := GetBitmap(i,0)
        else
          Pic := nil;

        ExportImg(i, 0, SList, pic);

        end;
      end;
      SList.Add('</TR>');
      FirstRow := 1;
    end;

    ExportNotification(esExportStart, FirstRow);

    images_counter := 1;

    for i := FirstRow to RowCount - 1 do
    begin
      ExportNotification(esExportNextRow, i);
      SList.Add('<TR>');

      for j := StrtCol to ColumnCount - 1 do
      begin
        if IsBaseCellEx(j,i,mc,mr) then
        begin
          Span := CellSpan(j,i);

          SpanTxt := '';

          if Span.X > 0 then
            SpanTxt := ' COLSPAN="' + IntToStr(Span.X) + '"';

          if Span.Y > 0 then
            SpanTxt := SpanTxt + ' ROWSPAN="' + IntToStr(Span.Y) + '"';

          CellText := DoSaveCell(j,i);
          s := CellText;

          if Options.HTMLExport.ConvertSpecialChars then
            s := TTMSFNCUtils.FixMarkup(s);

          if Options.HTMLExport.NonBreakingText then
            s := FixNonBreaking(s);

          s := HTMLLineBreaks(s);
          s := MakeHREF(s);

          cellclasstype := nil;
          DoGetCellClass(j, i, cellclasstype);
          cellstr := '';
          DoGetCellData(j, i, cellstr);

          if (prevclasstype <> cellclasstype) and (prevclasstype <> nil) and Assigned(cell) then
            cell.Free;

          if (prevclasstype <> cellclasstype) then
          begin
            cell := cellclasstype.Create(Self);

            if (cell is TTMSFNCGridCell) then
              (cell as TTMSFNCGridCell).BitmapContainer := BitmapContainer;

            prevclasstype := cellclasstype;
          end;

          if (Cell is TTMSFNCGridCell) then
          begin
            l.Assign((Cell as TTMSFNCGridCell).Layout);
            l.Assign(GetDefaultNormalLayout);

            DoGetCellLayout(j, i, l, TTMSFNCGridCellState.csNormal);
            (Cell as TTMSFNCGridCell).AssignLayout(l);
          end;

          DoGetCellProperties(j, i, cell);

          a := 0;
          DoGetCellRotation(j, i, a);

          cssval := css(l.Font, l.Fill.Color, l.Font.Color, l.TextAlign, l.VerticalTextAlign, a);

          cssidx := CSSList.IndexOf(cssval);
          if cssidx = -1 then
            cssidx := CSSList.Add(cssval);

          if (Options.HTMLExport.ColumnWidths.Count > j - strtcol) and not DoneColW then
            colwtxt := ' width=' + IntToStr(Options.HTMLExport.ColumnWidths[j - strtcol ])
          else
            colwtxt := '';

          if Trim(s) = '' then
            s := '&nbsp;';

          WrapTxt := '';
          
          if Options.HTMLExport.ExportImages then
            Pic := GetBitmap(j,i)
          else
            Pic := nil;

          ExportImg(j,i,SList, Pic);
        end;
      end;

      SList.Add('</TR>');
    end;

    ExportNotification(esExportDone, -1);

    SList.Add('</TABLE>');

    if Options.HTMLExport.SuffixTag <> '' then
      SList.Add(Options.HTMLExport.SuffixTag);

    if Options.HTMLExport.XHTML then
      SList.Add('</HTML>');

    res := '<style>'#13#10;
    for cssidx := 0 to CSSList.Count - 1 do
      res := res + '.c' + IntToStr(cssidx)+' {'+csslist[cssidx]+'}'#13#10;
    res := res + '</style>'#13#10;


    Result := htmlhdr + res + SList.Text;

  finally
    if Assigned(cell) then
      cell.Free;
    l.Free;
    SList.Free;
    CSSList.Free;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.SaveToStream(Stream: TStream);
var
  ss,CellText: string;
  i,j: Integer;

  procedure Writestring(s:string);
  var
    buf:PChar;
    c: array[0..1] of char;
    l,len: integer;
  begin
    l := length(s) * 2;
    len := l + 2;
    GetMem(buf,len);
    System.Move(s[1],buf^,l);
    Stream.Writebuffer(buf^,l);
    c[0] := #13;
    c[1] := #10;
    Stream.Writebuffer(c,4);
    FreeMem(buf);
  end;

begin
  ss := IntToStr(ColumnCount - FIOOffset.X) + ',' + IntToStr(RowCount - FIOOffset.Y);
  WriteString(ss);

  for i := 1 to ColumnCount do
    WriteString('cw '+IntToStr(i-1) + ',' + IntToStr(Round(ColumnWidths[i - 1])));

  ExportNotification(esExportStart, FIOOffset.Y);

  for i := FIOOffset.Y to RowCount - 1 do
  begin
    ExportNotification(esExportNextRow, i);
    for j := FIOOffset.X to ColumnCount - 1 do
    begin
      CellText := DoSaveCell(j,i);

      if CellText <> '' then
      begin
        ss := IntToStr(j - FIOOffset.X) + ',' + IntToStr(i - FIOOffset.Y) + ',' + LFToFile(CellText);
        Writestring(ss);
      end;
    end;

    DoIOProgress(i, RowCount - 1);
  end;

  ExportNotification(esExportDone, -1);
end;

procedure TTMSFNCGridData.SaveToXML(FileName, ListDescr, RecordDescr: string;
  FieldDescr: TStrings; ExportEmptyCells: boolean);
var
  i,j: Integer;
  f: TextFile;
  s: string;
  cr, n: Integer;
  StrtCol,StrtRow: integer;

begin
  Assignfile(f,filename);
  {$i-}
  Rewrite(f);
  {$i+}
  if IOResult <> 0 then
    raise Exception.Create('Cannot Create file '+FileName);

  writeln(f,'<?xml version="1.0" encoding="'+ Options.IO.XMLEncoding +'" ?>');
  writeln(f,'<' + ListDescr + '>');

  StrtCol := IOOffset.X;
  StrtRow := IOOffset.Y;

  ExportNotification(esExportStart, StrtRow);

  if FSaveHiddenCells then
    n := HiddenColumnCount
  else
    n := 0;

  for i := StrtRow to RowCount - 1 do
  begin
    ExportNotification(esExportNextRow, i);

    writeln(f,'<'+RecordDescr+'>');
    for j := StrtCol to ColumnCount - 1 + n do
    begin
      cr := DisplToRealColumn(j);

      if IsBaseCell(cr,i) then
      begin
        s := DoSaveCell(j,i);

        if (s <> '') or ExportEmptyCells then
        begin
          if Options.IO.QuoteEmptyCells and (s = '') then
          begin
            s := '""';
          end;

          if Assigned(FieldDescr) and (j - StrtCol < FieldDescr.Count) then
            write(f,'<' + FieldDescr.Strings[j - StrtCol]+'>')
          else
            write(f,'<FIELD' + IntToStr(j - StrtCol)+'>');

          s := StringReplace(s,'&','$amp;',[rfReplaceAll]);
          s := StringReplace(s,'>','&gt;',[rfReplaceAll]);
          s := StringReplace(s,'<','&lt;',[rfReplaceAll]);
          s := StringReplace(s,'"','&quot;',[rfReplaceAll]);

          write(f,s);

          if Assigned(FieldDescr) and (j - StrtCol< FieldDescr.Count) then
            writeln(f,'</' + FieldDescr.Strings[j - StrtCol]+'>')
          else
            writeln(f,'</FIELD' + IntToStr(j - StrtCol)+'>');
        end;
      end;
    end;
    writeln(f,'</' + RecordDescr + '>');

    DoIOProgress(i, RowCount - 1 - StrtRow);
  end;

  writeln(f,'</' + ListDescr + '>');

  ExportNotification(esExportDone, -1);
  CloseFile(f);
end;
{$ENDIF}

procedure TTMSFNCGridData.SelectCell(Cell: TTMSFNCGridCellRec; Shift: TShiftState;
  MouseDragging: Boolean);
begin
end;

procedure TTMSFNCGridData.SelectCells(ARange: TTMSFNCGridCellRecRange);
begin
  SelectCell(MakeCell(Arange.StartCol, ARange.StartRow));
  SelectCell(MakeCell(ARange.EndCol, ARange.EndRow), [ssShift, ssCtrl]);
end;

procedure TTMSFNCGridData.SelectColumns(FromColumn, ToColumn: integer);
begin
  SelectCell(MakeCell(FromColumn, 0));
  SelectCell(MakeCell(ToColumn, 0), [ssCtrl, ssShift]);
end;

procedure TTMSFNCGridData.SetHorizontalScrollBarVisible(const Value: Boolean);
begin
  inherited;
  if UpdateCount = 0 then
    Options.ScrollBar.HorizontalScrollBarVisible := HorizontalScrollBarVisible;
end;

procedure TTMSFNCGridData.SetHorzAlignments(Col, Row: integer;
  const Value: TTMSFNCGraphicsTextAlign);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.AlignHorz := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.AlignHorz := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetAllCells(Col, Row: integer; const Value: string);
var
  sl: TTMSFNCGridRowInfo;
begin
  while (FRowList.Count <= Row) do
  begin
    FRowList.Add(nil);
  end;

  while (FRowDisplayList.Count + FHiddenRowList.Count <= Row) do
  begin
    FRowDisplayList.Add(FRowDisplayList.Count);
  end;

  while (FColumnDisplayList.Count + FHiddenColumnList.Count <= Col) do
  begin
    FColumnDisplayList.Add(FColumnDisplayList.Count);
    ColumnCount := Col + 1;
  end;

  if Assigned(FRowList[Row]) then
  begin
    sl := FRowList[Row];
  end
  else
  begin
    sl := TTMSFNCGridRowInfo.Create;
    FRowList[Row] := sl;
  end;

  while sl.Data.Count <= Col do
    sl.Data.AddObject('',nil);

  {$IFDEF VARIANTLIST}
  sl.Data.Values[col] := Value;
  {$ELSE}
  sl.Data.Strings[col] := Value;
  {$ENDIF}
end;

procedure TTMSFNCGridData.SetAllInts(Col, Row: integer; const Value: Integer);
begin
  AllCells[Col,Row] := IntToStr(Value);
end;

procedure TTMSFNCGridData.SetAllFloats(Col, Row: integer; const Value: double);
begin
  AllCells[Col,Row] := Format(FloatFormat,[Value]);
end;

procedure TTMSFNCGridData.EnsureRow(Row: integer);
begin
  while (FRowList.Count <= Row) do
  begin
    FRowList.Add(nil);
  end;

  while (FRowDisplayList.Count <= Row) do
  begin
    FRowDisplayList.Add(FRowDisplayList.Count);
  end;
end;

procedure TTMSFNCGridData.ExportNotification(AState: TTMSFNCGridExportState;
  ARow: Integer);
begin
  if AState = esExportStart then
    FPrevProgress := -1;
end;

procedure TTMSFNCGridData.EnsureCol(Col: integer);
begin
  while (FColumnDisplayList.Count <= Col) do
  begin
    FColumnDisplayList.Add(FColumnDisplayList.Count);
    ColumnCount := Col + 1;
  end;
end;

procedure TTMSFNCGridData.SetCells(Col, Row: integer; const Value: TTMSFNCGridCellData);
var
  sl: TTMSFNCGridRowInfo;
  RDisp,CDisp: integer;
begin
  EnsureRow(Row);
  EnsureCol(Col);

  RDisp := FRowDisplayList[Row];
  CDisp := FColumnDisplayList[Col];

  if Assigned(FRowList[RDisp]) then
  begin
    sl := FRowList[RDisp];
  end
  else
  begin
    sl := TTMSFNCGridRowInfo.Create;
    FRowList[RDisp] := sl;
  end;

  while sl.Data.Count <= CDisp do
    sl.Data.AddObject('',nil);

  {$IFDEF VARIANTLIST}
  sl.Data.Values[CDisp] := Value;
  {$ELSE}
  sl.Data.Strings[CDisp] := Value;
  {$ENDIF}
  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetCellSelect(Col, Row: integer;
  const Value: boolean);
var
  idx: integer;
  c: TTMSFNCGridCellRec;
begin
  c := MakeCell(Col,Row);
  idx := FSelectedCells.Find(c);

  if (idx = -1) and Value then
    FSelectedCells.AddCell(c);

  if (idx <> -1) and not Value then
    FSelectedCells.DeleteCell(idx);

  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetCalcValues(Col, Row: Integer; const Value: variant);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.CalcValue := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.CalcValue := Value;
    IntObjects[Col,Row] := cp;
  end;

end;

procedure TTMSFNCGridData.SetCellCalcStates(Col, Row: Integer;
  const Value: TTMSFNCGridCellCalcState);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.CalcState := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.CalcState := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetCheckBoxState(Col, Row: integer;
  const Value: boolean);
var
  cp: TTMSFNCGridCellProperty;
  c: integer;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if UseColumns and not Assigned(cp) then
  begin
    c := DisplToRealColumn(Col);
    if c < Columns.Count then
    begin
      if (Columns[c].ColumnType = ctCheckBox) then
      begin
        if Value then
          Cells[Col,Row] := CheckTrue
        else
          Cells[Col,Row] := CheckFalse;
      end;
    end;
  end
  else
  begin
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellCheckBox) then
    begin
     (cp.Control as TTMSFNCGridCellCheckBox).Checked := Value;
    end;
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellDataCheckBox) then
    begin
      if Value then
        Cells[Col,Row] := CheckTrue
      else
        Cells[Col,Row] := CheckFalse;
    end;
  end;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.AddBitmap(Col,Row: integer; AName: string);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellBitmapName.Create;
  (cp.Control as TTMSFNCGridCellBitmapName).BitmapName := AName;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.AddDataBitmap(Col,Row: integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellBitmapName.Create;
  (cp.Control as TTMSFNCGridCellBitmapName).Data := true;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;


function TTMSFNCGridData.CreateBitmap(Col,Row: integer): TTMSFNCBitmap;
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellBitmap.Create(Self);
  Result := (cp.Control as TTMSFNCGridCellBitmap).Bitmap;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

function TTMSFNCGridData.CreateCheck(Col, Row: Integer): Boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellCheckBox.Create;
  Result := (cp.Control as TTMSFNCGridCellCheckBox).Checked;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.CreateColumnProp;
begin
  if Assigned(FColumnProp) then
    FColumnProp.Free;

  FColumnProp := TTMSFNCGridCellProperty.Create(FDefaultFont);
end;

procedure TTMSFNCGridData.AddBitmap(Col, Row: Integer; ABitmap: TTMSFNCBitmap);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellBitmap.Create(Self);
  (cp.Control as TTMSFNCGridCellBitmap).Bitmap.Assign(ABitmap);
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCGridData.AddBitmapFile(Col, Row: Integer; AFileName: string);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellBitmap.Create(Self);
  (cp.Control as TTMSFNCGridCellBitmap).Bitmap.LoadFromFile(AFileName);
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;
{$ENDIF}

procedure TTMSFNCGridData.AddRotated(Col, Row: Integer; AAngle: Integer; const Value: TTMSFNCGridCellData);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  cp.FAngle := AAngle;

  IntObjects[Col, Row] := cp;
  Cells[Col, Row] := Value;
end;

procedure TTMSFNCGridData.SetRotated(Col, Row: Integer; AAngle: Integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp) then
  begin
    cp.FAngle := AAngle;
    IntObjects[Col, Row] := cp;
  end;
end;

procedure TTMSFNCGridData.RemoveRotated(Col, Row: Integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) then
  begin
    cp.FAngle := 0;
    UpdateGridCells;
  end;
end;

function TTMSFNCGridData.IsRotated(Col, Row: Integer; var AAngle: Integer): Boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) then
    AAngle := cp.FAngle;

  Result := AAngle <> 0;
end;

procedure TTMSFNCGridData.AddButton(Col, Row: integer; AText: string;
  AWidth: integer = 20; AHeight: Integer = 20);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellButton.Create;
  (cp.Control as TTMSFNCGridCellButton).Width := AWidth;
  (cp.Control as TTMSFNCGridCellButton).Height := AHeight;
  (cp.Control as TTMSFNCGridCellButton).Text := AText;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.RemoveBitmap(Col,Row: integer);
begin
  RemoveCellControl(Col,Row,TTMSFNCGridCellBitmapName);
  RemoveCellControl(Col,Row,TTMSFNCGridCellBitmap);
end;

procedure TTMSFNCGridData.RemoveButton(Col, Row: integer);
begin
  RemoveCellControl(Col,Row,TTMSFNCGridCellButton);
end;

procedure TTMSFNCGridData.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
end;

procedure TTMSFNCGridData.SetBitmapName(Col,Row: integer; AName: string);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellBitmapName) then
  begin
    (cp.Control as TTMSFNCGridCellBitmapName).BitmapName := AName;
    UpdateGridCells;
  end;
end;

procedure TTMSFNCGridData.SetBitmaps(Col, Row: Integer; const Value: TTMSFNCBitmap);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellBitmap.Create(Self);
  (cp.Control as TTMSFNCGridCellBitmap).Bitmap.Assign(Value);
  IntObjects[Col,Row] := cp;
end;

procedure TTMSFNCGridData.SetBooleans(Col, Row: Integer; const Value: Boolean);
begin
  AddDataCheckBox(Col, Row, Value);
end;

function TTMSFNCGridData.GetBitmapName(Col,Row: integer): string;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := '';
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellBitmapName) then
  begin
    Result := (cp.Control as TTMSFNCGridCellBitmapName).BitmapName;
  end;
end;

function TTMSFNCGridData.GetBitmaps(Col, Row: Integer): TTMSFNCBitmap;
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellBitmap) then
    Result := (cp.Control as TTMSFNCGridCellBitmap).Bitmap
  else
    Result := CreateBitmap(Col, Row);
end;

function TTMSFNCGridData.GetBooleans(Col, Row: Integer): Boolean;
begin
  Result := CheckBoxState[Col, Row]
end;

function TTMSFNCGridData.IsBitmap(Col: Integer; Row: Integer): boolean;
begin
  Result := IsCellControl(Col,Row, TTMSFNCGridCellBitmapName) or IsCellControl(Col, Row, TTMSFNCGridCellBitmap);
end;

function TTMSFNCGridData.IsButton(Col, Row: integer): boolean;
begin
  Result := IsCellControl(Col,Row, TTMSFNCGridCellButton);
end;

function TTMSFNCGridData.GetBitmap(Col: Integer; Row: Integer): TTMSFNCBitmap;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := nil;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellBitmapName) then
  begin
  end;

  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellBitmap) then
  begin
    Result := (cp.Control as TTMSFNCGridCellBitmap).Bitmap;
  end;
end;

function TTMSFNCGridData.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

procedure TTMSFNCGridData.AddNode(Row, Span: Integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  if FixedColumns - 1 >= 0 then
  begin
    cp := TTMSFNCGridCellProperty(IntObjects[FixedColumns - 1,Row]);

    if not Assigned(cp) then
      cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(FixedColumns - 1,Row));

    if Assigned(cp.Control) then
      cp.FreeControl;

    cp.Control := TTMSFNCGridCellNode.Create;
    (cp.Control as TTMSFNCGridCellNode).Span := Span;
    (cp.Control as TTMSFNCGridCellNode).State := nsOpen;
    IntObjects[FixedColumns - 1,Row] := cp;
    UpdateGridCells;
  end;
end;

procedure TTMSFNCGridData.AddProgressBar(Col, Row: Integer; Value: single);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellProgressBar.Create;
  (cp.Control as TTMSFNCGridCellProgressBar).Value := Value;
  (cp.Control as TTMSFNCGridCellProgressBar).Data := false;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetProgressBarValue(Col,Row: Integer; Value: single);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    Exit;

  if (cp.Control is TTMSFNCGridCellProgressBar) then
  begin
    (cp.Control as TTMSFNCGridCellProgressBar).Value := Value;
    UpdateGridCells;
  end;
end;

function TTMSFNCGridData.GetProgressBarValue(Col,Row: integer): single;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := 0;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    Exit;

  if (cp.Control is TTMSFNCGridCellProgressBar) then
    Result := (cp.Control as TTMSFNCGridCellProgressBar).Value;
end;

procedure TTMSFNCGridData.AddDataProgressBar(Col, Row: Integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellProgressBar.Create;
  (cp.Control as TTMSFNCGridCellProgressBar).Value := 0;
  (cp.Control as TTMSFNCGridCellProgressBar).Data := true;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;


procedure TTMSFNCGridData.RemoveNode(Row: Integer);
begin
  RemoveCellControl(FixedColumns - 1, Row, TTMSFNCGridCellNode);
end;

procedure TTMSFNCGridData.RemoveProgressBar(Col, Row: Integer);
begin
  RemoveCellControl(Col,Row,TTMSFNCGridCellProgressBar);
end;

function TTMSFNCGridData.IsNode(Row: Integer): boolean;
begin
  Result := IsCellControl(FixedColumns - 1, Row, TTMSFNCGridCellNode);
end;

function TTMSFNCGridData.IsNormalFixed(Col, Row: Integer): Boolean;
var
  ACellFixed: Boolean;
begin
  ACellFixed := False;
  DoGetCellIsFixed(Col, Row, ACellFixed);
  Result := ACellFixed;
end;

function TTMSFNCGridData.IsProgressBar(Col, Row: Integer): boolean;
begin
  Result := IsCellControl(Col, Row, TTMSFNCGridCellProgressBar);
end;

procedure TTMSFNCGridData.SetNodeState(Row: Integer; State: TTMSFNCGridNodeState);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[FixedColumns - 1,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellNode) then
  begin
    (cp.Control as TTMSFNCGridCellNode).State := State;
  end;
end;

procedure TTMSFNCGridData.OpenAllNodes;
var
  i,rr: integer;
begin
  i := FixedRows;

  while  i < RowCount - FixedFooterRows do
  begin
    rr := DisplToRealRow(i);
    if IsNode(rr) then
      OpenNode(rr);
    inc(i);
  end;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.OpenNode(Row: Integer);
var
  cn: TTMSFNCGridCellNode;
begin
  cn := GetNode(Row);

  if not Assigned(cn) then
    Exit;

  if cn.Span <> -1 then
  begin
    if cn.State = nsClosed then
    begin
      cn.State := nsOpen;
      UnHideRows(Row + 1, cn.Span);      
    end;
  end;
end;

procedure TTMSFNCGridData.CloseAllNodes;
var
  i,rr: integer;
begin
  i := FixedRows;
  while i < RowCount - FixedFooterRows do
  begin
    rr := DisplToRealRow(i);
    if IsNode(i) then
      CloseNode(rr);
    inc(i);
  end;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.CloseNode(Row: Integer);
var
  cn: TTMSFNCGridCellNode;
begin
  cn := GetNode(Row);

  if not Assigned(cn) then
    Exit;

  if cn.Span <> -1 then
  begin
    if cn.State = nsOpen then
    begin
      cn.State := nsClosed;
      HideRows(Row + 1, cn.Span );      
    end
  end;
end;

procedure TTMSFNCGridData.SetNodeSpan(Row: Integer; Span: Integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[FixedColumns - 1,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellNode) then
  begin
    (cp.Control as TTMSFNCGridCellNode).Span := Span;
  end;
end;

function TTMSFNCGridData.GetNodeSpan(Row: Integer): Integer;
var
  cn: TTMSFNCGridCellNode;
begin
  Result := -1;

  cn := GetNode(Row);
  if Assigned(cn) then
    Result := cn.Span;
end;

function TTMSFNCGridData.GetNodeState(Row: Integer): TTMSFNCGridNodeState;
var
  cn: TTMSFNCGridCellNode;
begin
  Result := nsOpen;

  cn := GetNode(Row);
  if Assigned(cn) then
    Result := cn.State;
end;


function TTMSFNCGridData.GetObjects(Col, Row: integer): TObject;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := nil;

  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) then
    Result := cp.CellObject;
end;


function TTMSFNCGridData.GetNode(Row: Integer): TTMSFNCGridCellNode;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := nil;
  cp := TTMSFNCGridCellProperty(IntObjects[FixedColumns - 1,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellNode) then
  begin
    Result := (cp.Control as TTMSFNCGridCellNode);
  end;
end;

procedure TTMSFNCGridData.AddCheckBoxColumn(Col: integer);
var
  i:integer;
begin
  for i := FixedRows to RowCount - FixedFooterRows - 1 do
  begin
    if (GroupColumn <> -1) then
    begin
      if IsNode(i) then
      begin
        if Options.Grouping.AutoCheckGroup then
          AddCheckBox(Col,i);
      end
      else
        if not IsSummary(i) then
          AddCheckBox(Col,i);
    end
    else
      AddCheckBox(Col,i);
  end;
end;


procedure TTMSFNCGridData.AddComboBox(Col, Row: Integer; Items: TStrings);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellCombo.Create;
  (cp.Control as TTMSFNCGridCellCombo).Items.Assign(Items);
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.AddCheckBox(Col,Row: integer; State: boolean = false);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellCheckBox.Create;
  (cp.Control as TTMSFNCGridCellCheckBox).Checked := state;
  (cp.Control as TTMSFNCGridCellCheckBox).Header := false;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.AddHeaderCheckBox(Col,Row: integer; State: boolean = false);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellCheckBox.Create;
  (cp.Control as TTMSFNCGridCellCheckBox).Checked := state;
  (cp.Control as TTMSFNCGridCellCheckBox).Header := true;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;


procedure TTMSFNCGridData.AddDataCheckBox(Col,Row: integer; State: boolean = false);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellDataCheckBox.Create;
  IntObjects[Col,Row] := cp;
  if State then
    Cells[Col,Row] := CheckTrue
  else
    Cells[Col,Row] := CheckFalse;

  UpdateGridCells;
end;


procedure TTMSFNCGridData.AddDataCheckBoxColumn(Col: integer);
var
  i:integer;
begin
  for i := FixedRows to RowCount - FixedFooterRows - 1 do
    AddDataCheckBox(Col,i);
end;

procedure TTMSFNCGridData.RemoveCheckBox(Col,Row: integer);
begin
  RemoveCellControl(Col,Row, TTMSFNCGridCellCheckBox);
end;

procedure TTMSFNCGridData.RemoveComboBox(Col, Row: Integer);
begin
  RemoveCellControl(Col,Row, TTMSFNCGridCellCombo);
end;

procedure TTMSFNCGridData.RemoveFilter;
begin
  UnHideRowsAll;
  FFilterApplied := false;
end;

procedure TTMSFNCGridData.RemoveFilters;
begin
  Filter.Clear;
  UnHideRowsAll;
  FFilterApplied := false;
end;

function TTMSFNCGridData.IsCheckBox(Col,Row: integer): boolean;
begin
  Result := IsCellControl(Col,Row, TTMSFNCGridCellCheckBox) or IsCellControl(Col,Row, TTMSFNCGridCellDataCheckBox);
end;

function TTMSFNCGridData.IsComboBox(Col, Row: integer): boolean;
begin
  Result := IsCellControl(Col,Row, TTMSFNCGridCellCombo)
end;

function TTMSFNCGridData.IsDefaultColumnWidthStored: Boolean;
begin
  Result := DefaultColumnWidth <> 68;
end;

function TTMSFNCGridData.IsDefaultRowHeightStored: Boolean;
begin
  Result := DefaultRowHeight <> 24;
end;

function TTMSFNCGridData.GetComboIndex(Col,Row: integer): integer;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := -1;
  if IsCellControl(Col,Row,TTMSFNCGridCellCombo) then
  begin
    cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
    if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellCombo) then
      Result := (cp.Control as TTMSFNCGridCellCombo).Items.IndexOf(Cells[Col,Row]);
  end;
end;

function TTMSFNCGridData.GetCommentColors(Col, Row: Integer): TTMSFNCGraphicsColor;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := gcNull;

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
   Result := CellProp.CommentColor;

end;

function TTMSFNCGridData.GetComments(Col, Row: Integer): string;
var
  CellProp: TTMSFNCGridCellProperty;
begin
  Result := '';

  CellProp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(CellProp) then
   Result := CellProp.Comment;
end;

procedure TTMSFNCGridData.SetComboIndex(Col,Row,Value: integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  if Value = -1 then
    Cells[Col,Row] := ''
  else
    if IsCellControl(Col,Row,TTMSFNCGridCellCombo) then
    begin
      cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
      if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellCombo) then
      begin
        if Value < (cp.Control as TTMSFNCGridCellCombo).Items.Count then
          Cells[Col,Row] := (cp.Control as TTMSFNCGridCellCombo).Items[Value];
      end;
    end;
end;


procedure TTMSFNCGridData.SetCommentColors(Col, Row: Integer;
  const Value: TTMSFNCGraphicsColor);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.CommentColor := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.CommentColor := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetComments(Col, Row: Integer; const Value: string);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.Comment := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.Comment := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

function TTMSFNCGridData.IsCellControl(Col,Row: Integer;  AClass: TClass): boolean;
var
  cp: TTMSFNCGridCellProperty;
  c,r: integer;
begin
  c := DisplToRealColumn(Col);
  r := DisplToRealRow(Row);

  cp := TTMSFNCGridCellProperty(IntObjects[c,r]);
  Result := Assigned(cp) and Assigned(cp.Control) and (cp.Control is AClass);
end;

procedure TTMSFNCGridData.RemoveCellControl(Col, Row: Integer; AClass: TClass);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is AClass) then
  begin
    cp.FreeControl;
    UpdateGridCells;
  end;
end;

procedure TTMSFNCGridData.AddRadioButtonColumn(Col,Index: integer);
var
  i:integer;
begin
  for i := FixedRows to RowCount - FixedFooterRows - 1 do
  begin
    AddRadioButton(Col,i,Index);
  end;
end;

procedure TTMSFNCGridData.AddSummary(Row: integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[FixedColumns - 1,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(FixedColumns - 1,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellSummary.Create;
  IntObjects[FixedColumns - 1,Row] := cp;
end;

procedure TTMSFNCGridData.AddRadioButton(Col,Row,Index: integer; State: boolean = false);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  if Assigned(cp.Control) then
    cp.FreeControl;

  cp.Control := TTMSFNCGridCellRadioButton.Create;
  (cp.Control as TTMSFNCGridCellRadioButton).Checked := state;
  (cp.Control as TTMSFNCGridCellRadioButton).Index := Index;
  IntObjects[Col,Row] := cp;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.RemoveRadioButton(Col,Row: integer);
begin
  RemoveCellControl(Col,Row,TTMSFNCGridCellRadioButton);
end;

procedure TTMSFNCGridData.RemoveSummary(Row: integer);
begin
  RemoveCellControl(FixedColumns - 1, Row, TTMSFNCGridCellSummary);
end;

function TTMSFNCGridData.IsRadioButton(Col,Row: integer): boolean;
begin
  Result := IsCellControl(Col,Row,TTMSFNCGridCellRadioButton);
end;

function TTMSFNCGridData.IsReadOnly(Col, Row: integer): boolean;
begin
  Result := False;
  DoGetCellReadOnly(Col,Row,Result);
end;

function TTMSFNCGridData.IsSummary(Row: integer): boolean;
begin
  Result := IsCellControl(0,Row,TTMSFNCGridCellSummary);
end;

function TTMSFNCGridData.RadioButtonState(Col,Row: integer): boolean;
var
  cp: TTMSFNCGridCellProperty;
begin
  Result := false;
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);
  if Assigned(cp) and Assigned(cp.Control) and (cp.Control is TTMSFNCGridCellRadioButton) then
  begin
    Result := (cp.Control as TTMSFNCGridCellRadioButton).Checked;
  end;
end;

procedure TTMSFNCGridData.RandomFill(DoFixed: Boolean; rnd: Integer);
var
  i,j: Integer;
  ro,co,re,ce: Integer;
begin
  BeginUpdate;
  if DoFixed then
  begin
    ro := 0;
    co := 0;
    re := RowCount - 1;
    ce := ColumnCount - 1;
  end
  else
  begin
    ro := FixedRows;
    co := FixedColumns;
    re := RowCount - 1 - FixedFooterRows;
    ce := ColumnCount - 1 - FixedRightColumns;
  end;

  for i := ro to re do
    for j := co to ce do
      Cells[j,i] := IntToStr(Random(rnd));

  EndUpdate;
end;

procedure TTMSFNCGridData.SetColors(Col, Row: integer; const Value: TTMSFNCGraphicsColor);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.Color := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.Color := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetColumnCalculation(Col: Integer;
  const Value: TTMSFNCGridColumnCalculation);
begin
  while (FCellCalcList.Count <= Col) do
    FCellCalcList.Add(integer(ccNone));

  FCellCalcList[Col] := integer(Value);

  UpdateColumnCalc(Col, Value);
end;

procedure TTMSFNCGridData.SetColumnCount(const Value: Integer);
var
  i: integer;
begin
  while (Value > FColumns.Count) do
    FColumns.Add;

  BeginUpdate;

  if (Value < FColumnCount) then
    ClearCells(CellRange(Value, 0, FColumnCount - 1, RowCount - 1));

  FColumnCount := Value;

  if Value < FColumnDisplayList.Count then
  begin
    while (FColumnDisplayList.Count > Value) do
    begin
      FColumnDisplayList.Delete(FColumnDisplayList.Count - 1);
    end;
  end
  else
  begin
    i := 0;
    if  FColumnDisplayList.Count > 0 then
      i := FColumnDisplayList[FColumnDisplayList.Count - 1] + 1;

    while (FColumnDisplayList.Count < Value) do
    begin
      FColumnDisplayList.Add(i);
      inc(i);
    end;
  end;

  DoColumnCountChanged;
  EndUpdate;
end;

procedure TTMSFNCGridData.SetColumnOrder;
var
  i: Integer;
begin
  FColumnOrder.Clear;
  for i := 1 to ColumnCount do
    FColumnOrder.Add(i - 1);
end;

procedure TTMSFNCGridData.SetColumns(const Value: TTMSFNCGridColumns);
begin
  FColumns.Assign(Value);
end;

procedure TTMSFNCGridData.SetColumnSelect(Col: integer; const Value: boolean);
var
  idx: integer;
begin
  idx := FColSelect.IndexOfValue(Col);

  if (idx = -1) and Value then
    FColSelect.Add(Col);

  if (idx <> -1) and not Value then
    FColSelect.Delete(idx);

  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetColumnW(const Value: TTMSFNCGridSingleList);
begin
  if FColumnW <> Value then
  begin
    BeginUpdate;
    FColumnW.Assign(Value);
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetColWidths(Col: Integer; const Value: Single);
var
  idx: Integer;
  c: TTMSFNCGridSingleListItem;
begin
  idx := ColumnW.IndexOf(Col);
  if (idx >= 0) and (idx <= ColumnW.Count - 1) then
  begin
    if Value = DefaultColumnWidth then
      ColumnW.Delete(idx)
    else
    begin
      ColumnW[idx].Value := Max(0, Value);
      ColumnW[idx].CellVal := Col;
    end;
  end
  else if Value <> DefaultColumnWidth then
  begin
    c := ColumnW.Add;
    c.Value := Max(0, Value);
    c.CellVal := Col;
  end;
end;

procedure TTMSFNCGridData.SetDefaultColumnWidth(const Value: Single);
begin
  if FDefaultColumnWidth <> Value then
  begin
    BeginUpdate;
    FDefaultColumnWidth := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetDefaultFont(const Value: TTMSFNCGraphicsFont);
begin
  FDefaultFont.Assign(Value);
end;

procedure TTMSFNCGridData.SetDefaultRowHeight(const Value: Single);
begin
  if FDefaultRowHeight <> Value then
  begin
    BeginUpdate;
    FDefaultRowHeight := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetFixedColumns(const Value: Integer);
begin
  if (FFixedColumns <> Value) and (Value >= 0) then
  begin
    BeginUpdate;
    FFirstCellApply := False;
    FFixedColumns := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetFixedFooterRows(const Value: Integer);
begin
  if (FFixedFooterRows <> Value) and (Value >= 0) then
  begin
    BeginUpdate;
    FFirstCellApply := False;
    FFixedFooterRows := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetFixedRightColumns(const Value: Integer);
begin
  if (FFixedRightColumns <> Value) and (Value >= 0) then
  begin
    BeginUpdate;
    FFirstCellApply := False;
    FFixedRightColumns := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetFixedRows(const Value: Integer);
begin
  if (FFixedRows <> Value) and (Value >= 0) then
  begin
    BeginUpdate;
    FFirstCellApply := False;
    FFixedRows := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetInts(Col, Row: Integer; const Value: Integer);
begin
  AllCells[Col,Row] := IntToStr(Value);
end;

procedure TTMSFNCGridData.SetFloats(Col, Row: Integer; const Value: double);
begin
  AllCells[Col,Row] := Format(FloatFormat,[Value]);
end;

procedure TTMSFNCGridData.SetFocusCell(ACol, ARow: Integer);
begin
  FFocusedCell.Col := ACol;
  FFocusedCell.Row := ARow;
  FFocusedCell.Col := Max(FixedColumns, Min(ColumnCount - 1 - FixedRightColumns, FFocusedCell.Col));
  FFocusedCell.Row := Max(FixedRows, Min(RowCount - 1 - FixedFooterRows, FFocusedCell.Row));
end;

procedure TTMSFNCGridData.SetFocusedCell(const Value: TTMSFNCGridCellRec);
var
  val: TTMSFNCGridCellRec;
begin
  SetFocusCell(Value.Col, Value.Row);
  if IsNormalFixed(FocusedCell.Col, FocusedCell.Row) then
  begin
    val := NextSelectableColumn(FocusedCell.Col, FocusedCell.Row);
    SetFocusCell(val.Col, val.Row);
  end;
  SelectCell(FFocusedCell, []);
end;

procedure TTMSFNCGridData.SetFontColors(Col, Row: integer; const Value: TTMSFNCGraphicsColor);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.FontColor := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.FontColor := Value;
    IntObjects[Col,Row] := cp;
  end;

end;

procedure TTMSFNCGridData.SetFontNames(Col, Row: integer; const Value: string);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.FontName := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.FontName := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
var
  I,J: Integer;
begin
  BeginUpdate;

  GlobalFont.ApplyChange(DefaultFont, ASetType);
  GlobalFont.ApplyChange(FDefaultLayout.Font, ASetType);

  for I := 0 to Columns.Count - 1 do
  begin
    GlobalFont.ApplyChange(Columns[I].Font, ASetType);
    GlobalFont.ApplyChange(Columns[I].FixedFont, ASetType);

    for J := 0 to RowCount - 1 do
    begin
      GlobalFont.ApplyChange(GetDefaultBandLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultNormalLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultFixedLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultSelectedLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultFixedSelectedLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultFocusedLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultGroupLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultSummaryLayout.Font, ASetType);
      GlobalFont.ApplyChange(GetDefaultBandLayout.Font, ASetType);
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCGridData.SetFontSizes(Col, Row: integer; const Value: single);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.FontSize := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.FontSize := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetFontStyles(Col, Row: integer;
  const Value: TFontStyles);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.FontStyle := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.FontStyle := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCGridData.SetIntObjects(Col, Row: integer; const Value: TObject);
var
  sl: TTMSFNCGridRowInfo;
begin
  while (FRowList.Count <= Row) do
  begin
    FRowList.Add(nil);
    FRowDisplayList.Add(FRowDisplayList.Count);
  end;

  if Assigned(FRowList[Row]) then
  begin
    sl := FRowList[Row];
  end
  else
  begin
    sl := TTMSFNCGridRowInfo.Create;
    FRowList[Row] := sl;
  end;

  while sl.Data.Count <= Col do
    sl.Data.AddObject('',nil);

  sl.Data.Objects[col] := Value;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetMergeCellPrintPageNr(Col, Row: integer;
  const Value: integer);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) and (cp.BaseCol <> -1) then
  begin
    cp.FPrintPageNr := Value;
  end;
end;

procedure TTMSFNCGridData.SetObjects(Col, Row: integer; const Value: TObject);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  cp.CellObject := Value;
  IntObjects[Col,Row] := cp;
end;

procedure TTMSFNCGridData.SetOptions(const Value: TTMSFNCGridOptions);
begin
  if (FOptions <> Value) then
    FOptions.Assign(Value);
end;

procedure TTMSFNCGridData.SetOrigColumnW(const Value: TTMSFNCGridSingleList);
begin
  FOrigColumnW.Assign(Value);
end;

procedure TTMSFNCGridData.SetReadOnlys(Col, Row: integer; const Value: boolean);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if not Assigned(cp) then
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));

  cp.ReadOnly := Value;
  IntObjects[Col,Row] := cp;
end;

procedure TTMSFNCGridData.SetRowCount(const Value: integer);
var
  ri: TTMSFNCGridRowInfo;
begin
  BeginUpdate;

  if (Value < FRowCount) then
    ClearCells(CellRange(0, Value, ColumnCount - 1, FRowCount - 1));

  FRowCount := Value;

  if Value < FRowList.Count then
  begin
    while (FRowList.Count > Value) do
    begin
      if Assigned(FRowList[FRowList.Count - 1]) then
      begin
        ri := FRowList[FRowList.Count - 1];
        ri.Free;
      end;
      FRowList.Delete(FRowList.Count - 1);
    end;
  end
  else
    while (FRowList.Count < Value) do
      FRowList.Add(nil);

  while (FRowDisplayList.Count > FRowCount) do
    FRowDisplayList.Delete(FRowDisplayList.Count - 1);

  DoRowCountChanged;
  EndUpdate;
end;

procedure TTMSFNCGridData.SetRowH(const Value: TTMSFNCGridSingleList);
begin
  if FRowH <> Value then
  begin
    BeginUpdate;
    FRowH.Assign(Value);
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SetRowHeights(Row: Integer; const Value: Single);
var
  idx: Integer;
  r: TTMSFNCGridSingleListItem;
begin
  idx := RowH.IndexOf(Row);
  if (idx >= 0) and (idx <= RowH.Count - 1) then
  begin
    if Value = DefaultRowHeight then
      RowH.Delete(idx)
    else
    begin
      RowH[idx].Value := Max(0, Value);
      RowH[idx].CellVal := Row;
    end;
  end
  else if Value <> DefaultRowHeight then
  begin
    r := RowH.Add;
    r.Value := Max(0, Value);
    r.CellVal := Row;
  end;
end;

procedure TTMSFNCGridData.UnSelectCells(ARange: TTMSFNCGridCellRecRange);
var
  i,j: integer;
begin
  for i := ARange.StartCol to ARange.EndCol do
    for j := ARange.StartRow to ARange.EndRow do
      CellSelect[i,j] := false;
end;

procedure TTMSFNCGridData.UnSelectColumns(FromColumn, ToColumn: integer);
var
  i,idx: integer;
begin
  for i := FromColumn to ToColumn do
  begin
    idx := FColSelect.IndexOfValue(i);
    if idx <> -1 then
      FColSelect.Delete(idx);
  end;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnSelectRows(FromRow, ToRow: integer);
var
  i: integer;
begin
  for i := FromRow to ToRow do
    RowSelect[i] := false;
end;

procedure TTMSFNCGridData.SelectRows(FromRow, ToRow: integer);
var
  i: integer;
begin
  if Options.Selection.Mode = smRowRange then
    Selection := CellRange(0,FromRow, 0, ToRow)
  else
  if Options.Selection.Mode = smSingleRow then
    Selection := CellRange(0,FromRow, 0, FromRow)
  else
  if Options.Selection.Mode = smDisjunctRow then
    for i := FromRow to ToRow do
      RowSelect[i] := true;
end;

procedure TTMSFNCGridData.ClearRowSelect;
var
  i: integer;
begin
  for i := FixedRows to RowCount - FixedFooterRows - 1 do
  begin
    if i >= FRowList.Count then
      Break;

    if Assigned(FRowList[i]) then
      FRowList[i].State := rsDefault;
  end;
end;


procedure TTMSFNCGridData.ClearSortColumn;
begin
  FSortColumn := -1;
end;

procedure TTMSFNCGridData.SetRowSelect(Row: integer; const Value: boolean);
var
  sl: TTMSFNCGridRowInfo;
begin
  EnsureRow(Row);

  if not Assigned(FRowList[Row]) then
  begin
    sl := TTMSFNCGridRowInfo.Create;
    FRowList[Row] := sl;
  end;

  if Value then
    FRowList[Row].State := rsSelected
   else
    FRowList[Row].State := rsDefault;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetRowText(Row: Integer; const Value: string);
var
  str: TStringList;
  I: Integer;
  procedure Split(const ADelimiter: Char; AInput: string; const AStrings: TStrings);
  begin
    AStrings.Clear;
    AStrings.Delimiter := ADelimiter;
    AStrings.StrictDelimiter := True;
    AStrings.DelimitedText := AInput;
  end;
begin
  str := TStringList.Create;
  Split(Options.IO.Delimiter, Value, str);
  for I := IOOffset.X to IOOffset.X + str.Count - 1 do
    Cells[I, Row] := str[i - IOOffset.X];
  str.Free;
end;

procedure TTMSFNCGridData.SetSelection(const Value: TTMSFNCGridCellRecRange);
var
  start, stop: TTMSFNCGridCellRec;
  val: TTMSFNCGridCellRecRange;
  vl: TTMSFNCGridCellRec;
  a: Boolean;
begin
  val := Value;

  if IsNormalFixed(val.StartCol, val.StartRow) then
  begin
    vl := NextSelectableColumn(val.StartCol, val.StartRow);
    val.StartCol := vl.Col;
    val.StartRow := vl.Row;
  end;

  FStartCell.Col := val.StartCol;
  FStartCell.Row := val.StartRow;
  FStopCell.Col := val.EndCol;
  FStopCell.Row := val.EndRow;

  start := FStartCell;
  stop := FStopCell;
  a := True;
  if Assigned(OnSelectCell) then
    OnSelectCell(Self, start.Col, start.Row, a);

  if a then
  begin
    BlockSelectEventHandler := True;
    SelectCell(start, [], True);
    SelectCell(stop, [], True);
    BlockSelectEventHandler := False;
    if Assigned(OnSelectedCell) then
      OnSelectedCell(Self, start.Col, start.Row);
  end;

  FFocusedCell := FStartCell;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.SetSortColumn(const Value: integer);
begin
  if Value <> FSortColumn then
    SortData(Value, SortDirection);
end;

procedure TTMSFNCGridData.SetSortDirection(const Value: TTMSFNCGridSortDirection);
begin
  if Value <> FSortDirection then
    SortData(SortColumn, Value);
end;

procedure TTMSFNCGridData.SetStartCell(ACol, ARow: Integer);
begin
  FStartCell.Col := ACol;
  FStartCell.Row := ARow;

  FStartCell.Col := Max(FixedColumns, Min(ColumnCount - 1 - FixedRightColumns, FStartCell.Col));
  FStartCell.Row := Max(FixedRows, Min(RowCount - 1 - FixedFooterRows, FStartCell.Row));
end;

procedure TTMSFNCGridData.SetStopCell(ACol, ARow: Integer);
begin
  FStopCell.Col := ACol;
  FStopCell.Row := ARow;

  FStopCell.Col := Max(FixedColumns, Min(ColumnCount - 1 - FixedRightColumns, FStopCell.Col));
  FStopCell.Row := Max(FixedRows, Min(RowCount - 1 - FixedFooterRows, FStopCell.Row));
end;

procedure TTMSFNCGridData.SetVertAlignments(Col, Row: integer;
  const Value: TTMSFNCGraphicsTextAlign);
var
  cp: TTMSFNCGridCellProperty;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) then
    cp.AlignVert := Value
  else
  begin
    cp := TTMSFNCGridCellProperty.Create(GetDefaultFont(Col,Row));
    cp.AlignVert := Value;
    IntObjects[Col,Row] := cp;
  end;
end;

procedure TTMSFNCGridData.SetVerticalScrollBarVisible(const Value: Boolean);
begin
  inherited;
  if UpdateCount = 0 then
    Options.ScrollBar.VerticalScrollBarVisible := VerticalScrollBarVisible;
end;

procedure TTMSFNCGridData.SortData(Column: integer; Direction: TTMSFNCGridSortDirection);
var
  fltr: boolean;
begin
  BlockUpdate := True;
  fltr := false;

  if FFilterApplied then
  begin
    RemoveFilter;
    fltr := true;
  end;

  FSortColumn := Column;
  FSortDirection := Direction;

  // allocate space for pivot row
  FRowCount := FRowCount + 1;

  QuickSortRows(FSortColumn, FixedRows, FRowCount - 2 - FixedFooterRows);

  // remove pivot row space
  DeleteRow(RowCount - 1);

  if fltr then
    ApplyFilter;

  BlockUpdate := False;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.SortGrouped(Column: integer; Direction: TTMSFNCGridSortDirection);
begin
  FSortColumn := Column;
  FSortDirection := Direction;
  SortGroupedInt(false);
end;

procedure TTMSFNCGridData.SortGroupedIndexed;
begin
  SortGroupedInt(true);
end;

procedure TTMSFNCGridData.SortGroupedInt(Indexed: boolean);
var
  i,rr,r1,r2,span,idx: integer;
  nodeClosed: array of boolean;
  fltr: boolean;
begin
  SetLength(nodeClosed, 0);
  fltr := false;

  i := FixedRows;
  while  i <= (FRowCount - FixedFooterRows) do
  begin
    rr := DisplToRealRow(i);
    if IsNode(rr) then
    begin
      idx := Length(nodeClosed);
      SetLength(nodeClosed, idx + 1);
      if GetNodeState(rr) = nsClosed then
        nodeClosed[idx] := True
      else
        nodeClosed[idx] := False;
    end;
    inc(i);
  end;

  OpenAllNodes;

  if FFilterApplied then
  begin
    RemoveFilter;
    fltr := true;
  end;

  BeginUpdate;
  try
    RowCount := RowCount + 1;
    i := FixedRows;
    while  i <= (FRowCount - FixedFooterRows - 3) do
    begin
      rr := DisplToRealRow(i);
      if IsNode(rr) then
      begin
        span := GetNodeSpan(rr);
        r1 := rr + 1;
        r2 := rr + span;

        if r2 - r1 >= 1 then
        begin
          if Indexed then
            QuickSortRowsIndexed(0,r1,r2)
          else
            QuickSortRows(FSortColumn,r1,r2);
        end;

        i := i + span;
      end
      else
        inc(i);
    end;

    ClearCells(CellRange(0,RowCount - 1, ColumnCount - 1, RowCount - 1));
    RowCount := RowCount - 1;

    i := FixedRows;
    idx := 0;
    while  i <= (FRowCount - FixedFooterRows) do
    begin
      rr := DisplToRealRow(i);
      if IsNode(rr) then
      begin
        if nodeClosed[idx] then
          CloseNode(rr);
        inc(idx);
      end;
      inc(i);
    end;
  finally
    if fltr then
      ApplyFilter;

    EndUpdate;
  end;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.SortIndexed;
var
  rdl,rl: integer;
  ri: TTMSFNCGridRowInfo;
  fltr: boolean;

begin
  if FSortIndexes.Count = 0 then
    raise Exception.Create('No indexes specified for indexed sort');

  fltr := false;

  if FFilterApplied then
  begin
    RemoveFilter;
    fltr := true;
  end;

  BeginUpdate;
  try
    rdl := FRowDisplayList.Count;
    rl := FRowList.Count;

    // allocate space for pivot row
    FRowCount := FRowCount + 1;

    QuickSortRowsIndexed(0,FixedRows, FRowCount - 2 - FixedFooterRows);

    // remove pivot row space
    ClearCells(CellRange(0,RowCount - 1, ColumnCount - 1, RowCount - 1));

    FRowCount := FRowCount - 1;

    while rdl < FRowDisplayList.Count do
    begin
      FRowDisplayList.Delete(FRowDisplayList.Count - 1);
    end;

    while rl < FRowList.Count do
    begin
      ri := FRowList[FRowList.Count - 1];
      ri.Free;
      FRowList.Delete(FRowList.Count - 1);
    end;
  finally
    if fltr then
      ApplyFilter;
    EndUpdate;
  end;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.SplitCell(Col, Row: integer);
var
  cp: TTMSFNCGridCellProperty;
  i,j: integer;
  fromc,toc,fromr,tor: integer;
begin
  cp := TTMSFNCGridCellProperty(IntObjects[Col,Row]);

  if Assigned(cp) and cp.IsBaseCell(Col,Row)then
  begin
    fromc := cp.BaseCol;
    fromr := cp.BaseRow;
    toc := cp.BaseCol + cp.ColSpan;
    tor := cp.BaseRow + cp.RowSpan;

    for i := fromc to toc - 1 do
    begin
      for j := fromr to tor - 1 do
      begin
        cp := TTMSFNCGridCellProperty(IntObjects[i,j]);
        if Assigned(cp) then
        begin
          cp.BaseCol := -1;
          cp.BaseRow := -1;
          cp.ColSpan := 0;
          cp.RowSpan := 0;
        end;

        if not ((j = fromr) and (i = fromc)) then
          IntObjects[i,j] := nil;
      end;
    end;

    UpdateGridCells;
  end;
end;

procedure TTMSFNCGridData.StringToColumnStates(Value: string);
// sample: 5#27,64,22,64,64#4,0,1,2,3#1,1,1,1,1
var
  i,j: integer;
  il: TTMSFNCGridIntList;
  sl: TStringList;
  s: string;
  NewPos,cw: integer;
  stretch: boolean;
begin
  if pos('#',value) = 0 then
    Exit;

  BeginUpdate;

  try
    FColumnOrder.Clear;

    UnHideColumnsAll;

    // nr. of columns
    s := copy(value, 1, pos('#',value) - 1);

    ColumnCount := StrToInt(s);

    delete(value,1, pos('#',value));

    // order + visible part
    s := copy(value,pos('#',value) + 1,length(value));

    // order part
    s := copy(s,1, pos('#',s) - 1);

    sl := TStringList.Create;
    sl.CommaText := s;

    if s <> '' then
    begin
      for i := 0 to ColumnCount - 1 do
      begin
        if i < sl.Count then
        begin
          NewPos := StrToInt(sl.Strings[i]);
          if (NewPos <> -1) then
            FColumnOrder.Add(NewPos);
        end;
      end;

      // prepare reorganisation
      il := TTMSFNCGridIntList.Create;
      for i := 0 to ColumnCount - 1 do
      begin
        for j := 0 to FColumnOrder.Count - 1 do
        begin
          if (FColumnOrder[j] = i) then
            il.Add(j);
        end;
      end;

      FColumnOrder.Clear;
      for i := 0 to ColumnCount - 1 do
      begin
        FColumnOrder.Add(il.Items[i]);
      end;

      il.Free;

      // do reorganisation
      if FColumnOrder.Count > 0 then
        ResetColumnOrder;

      FColumnOrder.Clear;
      for i := 0 to ColumnCount - 1 do
      begin
        NewPos := StrToInt(sl.Strings[i]);
        if (NewPos <> -1) then
          FColumnOrder.Add(NewPos);
      end;
    end;

    // order + visible part
    s := copy(value,pos('#',value)+1,length(value));

    // visible part
    s := copy(s,pos('#',s)+1,length(s));

    sl.CommaText := s;

    for i := ColumnCount - 1 downto 0 do
    begin
      if sl.Strings[i] = '0' then
        HideColumn(i);
    end;

    s := copy(value,1,pos('#',value)-1);

    sl.CommaText := s;

    stretch := Options.ColumnSize.Stretch;

    Options.ColumnSize.Stretch := false;

    if stretch then
    begin
      if Options.ColumnSize.StretchColumn = -1 then
        DefaultColumnWidth := ColumnWidths[ColumnCount - 1]
      else
        DefaultColumnWidth := ColumnWidths[Options.ColumnSize.StretchColumn];
    end;

    for i := 0 to ColumnCount - 1 do
    begin
      cw := StrToInt(sl.Strings[i]);
      if cw = 0 then
        SuppressColumn(i)
      else
        ColumnWidths[RealToDisplColumn(i)] := cw;
    end;

    Options.ColumnSize.Stretch := stretch;

    sl.Free;
  finally
    EndUpdate;
  end;
end;

procedure TTMSFNCGridData.SwapCells(Col, Row1, Row2: integer);
var
  v: TTMSFNCGridCellData;
  o: TObject;
begin
  v := Cells[Col,Row1];
  o := IntObjects[Col,Row1];
  Cells[Col,Row1] := Cells[Col,Row2];
  IntObjects[Col,Row1] := IntObjects[Col,Row2];
  Cells[Col,Row2] := v;
  IntObjects[Col,Row2] := o;
end;

procedure TTMSFNCGridData.SwapNormalCells(Row1, Row2: integer);
var
  i: integer;
  v: TTMSFNCGridCellData;
  o: TObject;
begin
  for i := FixedColumns to ColumnCount - FixedRightColumns - 1 do
  begin
    v := Cells[i,Row1];
    o := IntObjects[i,Row1];
    Cells[i,Row1] := Cells[i,Row2];
    IntObjects[i,Row1] := IntObjects[i,Row2];
    Cells[i,Row2] := v;
    IntObjects[i,Row2] := o;
  end;
end;

procedure TTMSFNCGridData.SwapRows(Row1, Row2: integer);
var
  item: TTMSFNCGridRowInfo;
  val1, val2: Single;
  rr1,rr2: integer;
  sel1, sel2: Boolean;
begin
  rr1 := DisplToRealRow(row1);
  rr2 := DisplToRealRow(row2);

  item := FRowList[rr1];
  FRowList[rr1] := FRowList[rr2];
  FRowList[rr2] := item;

  val1 := RowHeights[Row1];
  val2 := RowHeights[Row2];

  RowHeights[Row1] := val2;
  RowHeights[Row2] := val1;

  sel1 := RowSelect[Row1];
  sel2 := RowSelect[Row2];

  RowSelect[Row1] := sel2;
  RowSelect[Row2] := sel1;

  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnSuppressColumn(Col: integer);
var
  i: integer;
  fnd: boolean;
begin
  if not IsSuppressedColumn(Col) or (FSuppressedColumnList.Count = 0) then
    Exit;

  i := 0;
  fnd := FSuppressedColumnList.IndexOf(Col) <> - 1;

  while i < FColumnDisplayList.Count do
  begin
    if FColumnDisplayList[i] >= Col then
    begin
      fnd := true;
      break;
    end
    else
      inc(i);
  end;

  if fnd then
  begin
    FSuppressedColumnList.Delete(FSuppressedColumnList.IndexOf(Col));
    UpdateGridCells;
  end;
end;


procedure TTMSFNCGridData.UnHideColumn(Col: integer);
var
  i: integer;
  fnd: boolean;
begin
  if not IsHiddenColumn(Col) or (FHiddenColumnList.Count = 0) then
    Exit;

  i := 0;
  fnd := FHiddenColumnList.IndexOf(Col) <> - 1;

  while i < FColumnDisplayList.Count do
  begin
    if FColumnDisplayList[i] >= Col then
    begin
      fnd := true;
      break;
    end
    else
      inc(i);
  end;

  if fnd then
  begin
    FColumnDisplayList.Insert(i, Col);
    FHiddenColumnList.Delete(FHiddenColumnList.IndexOf(Col));
    ColumnCount := ColumnCount + 1;
    UpdateGridCells;
  end;
end;

procedure TTMSFNCGridData.UnSuppressColumns(Col: integer; Count: integer);
var
  i: integer;
begin
  for i := Col to Col + Count - 1 do
    UnSuppresscolumn(i);
end;

procedure TTMSFNCGridData.UnHideColumns(Col: integer; Count: integer);
var
  i: integer;
begin
  for i := Col to Col + Count - 1 do
    UnHidecolumn(i);
end;

procedure TTMSFNCGridData.UnHideColumnsAll;
var
  i: integer;
begin
  BeginUpdate;
  while FColumnDisplayList.Count < TotalColCount do
  begin
    FColumnDisplayList.Add(0);
  end;

  for i := 0 to TotalColCount - 1 do
  begin
    FColumnDisplayList[i] := i;
  end;

  FColumnCount := TotalColCount;
  FHiddenColumnList.Clear;

  EndUpdate;
end;

procedure TTMSFNCGridData.UnSuppressColumnsAll;
begin
  BeginUpdate;
  FSuppressedColumnList.Clear;
  EndUpdate;
end;


procedure TTMSFNCGridData.UnHideRow(Row: integer);
var
  i: integer;
  fnd: boolean;
begin
  if not IsHiddenRow(Row) then
    Exit;

  i := 0;
  fnd := FHiddenRowList.Count > 0;

  while i < FRowDisplayList.Count do
  begin
    if FRowDisplayList[i] >= Row then
    begin
      fnd := true;
      break;
    end
    else
      inc(i);
  end;

  if fnd then
  begin
    FRowDisplayList.Insert(i, Row);
    FHiddenRowList.Delete(FHiddenRowList.IndexOf(Row));
    FRowCount := FRowCount + 1;
    UpdateGridCells;
  end;
end;

procedure TTMSFNCGridData.UnHideRows(Row: integer; Count: Integer);
var
  i,j,nr,ri: integer;
  fnd: boolean;
begin
  if FHiddenRowList.Count = 0 then
    Exit;

  i := 0;
  nr := 0;

  if (FRowDisplayList.Count = 0) or (Row > FRowDisplayList[FRowDisplayList.Count - 1]) then
  begin
    for i := 0 to Max(0, Count - 1) do
    begin
      if FHiddenRowList.IndexOf(i + Row) <> -1 then
      begin
        inc(nr);
        FRowDisplayList.Add(i + Row);
        FHiddenRowList.Delete(FHiddenRowList.IndexOf(i + Row));
      end;
    end;
    FRowCount := FRowCount + nr;
    UpdateControl;
    Exit;
  end;

  fnd := false;

  while i < FRowDisplayList.Count do
  begin
    if FRowDisplayList[i] >= Row then
    begin
      fnd := true;
      break;
    end
    else
      inc(i);
  end;

  if fnd then
  begin
    ri := FHiddenRowList.IndexOf(Row);

    if (ri >= 0) then
    begin
      FRowDisplayList.Insert(i, Row);
      FHiddenRowList.Delete(ri);
      FRowCount := FRowCount + 1;
      j := 1;
      inc(i);

      while (j <= Count) do
      begin
        if FRowDisplayList[i] <> Row + j then
        begin
          ri := FHiddenRowList.IndexOf(Row + j);
          if (ri >= 0) then
          begin
            FRowDisplayList.Insert(i, Row + j);
            FHiddenRowList.Delete(ri);
            FRowCount := FRowCount + 1;
          end;
        end;

        inc(i);
        inc(j);
      end;
    end;
  end;

  if fnd then
    UpdateControl;
end;

procedure TTMSFNCGridData.UnHideRowsAll;
var
  i: integer;
begin
  while FRowDisplayList.Count < FRowList.Count do
  begin
    FRowDisplayList.Add(0);
  end;

  for i := 0 to FRowList.Count - 1 do
  begin
    FRowDisplayList[i] := i;
  end;

  FRowCount := FRowList.Count;

  UpdateControl;
end;

procedure TTMSFNCGridData.UnHighlightAll;
var
  i: integer;
begin
  for i := FMarkList.Count - 1 downto 0 do
  begin
    if FMarkList[i].MarkType = mtHighlight then
      FMarkList.Delete(i);
  end;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnHighlightInCell(Col, Row: Integer);
begin
  FMarkList.RemoveMarker(GetCellRecRange(Col,Row), mtHighlight);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnHighlightInCol(DoFixed: Boolean; Col: Integer);
begin
  FMarkList.RemoveMarker(GetColumnRange(Col,DoFixed), mtHighlight);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnHighlightInGrid(DoFixed: Boolean);
begin
  FMarkList.RemoveMarker(GetGridRange(DoFixed), mtHighlight);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnHighlightInRow(DoFixed: Boolean; Row: Integer);
begin
  FMarkList.RemoveMarker(GetRowRange(Row,DoFixed), mtHighlight);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnMarkAll;
var
  i: integer;
begin
  for i := FMarkList.Count - 1 downto 0 do
  begin
    if FMarkList[i].MarkType = mtError then
      FMarkList.Delete(i);
  end;
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnMarkInCell(Col, Row: Integer);
begin
  FMarkList.RemoveMarker(GetCellRecRange(Col,Row), mtError);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnMarkInCol(DoFixed: Boolean; Col: Integer);
begin
  FMarkList.RemoveMarker(GetColumnRange(Col,DoFixed), mtError);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnMarkInGrid(DoFixed: Boolean);
begin
  FMarkList.RemoveMarker(GetGridRange(DoFixed), mtError);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UnMarkInRow(DoFixed: Boolean; Row: Integer);
begin
  FMarkList.RemoveMarker(GetRowRange(Row,DoFixed), mtError);
  UpdateGridCells;
end;

procedure TTMSFNCGridData.UpdateCalculation(ACol: integer);
var
  cc: TTMSFNCGridColumnCalculation;
begin
  cc := ColumnCalculation[ACol];
  if cc <> ccNone then
    UpdateColumnCalc(ACol,cc);
end;

procedure TTMSFNCGridData.UpdateCalculations;
var
  i: integer;
  cc: TTMSFNCGridColumnCalculation;
begin
  for i := 0 to ColumnCount - 1 do
  begin
    cc := ColumnCalculation[i];
    if cc <> ccNone then
      UpdateColumnCalc(i,cc);
  end;
end;

procedure TTMSFNCGridData.UpdateColumnCalc(Col: Integer;
  Value: TTMSFNCGridColumnCalculation);
var
  str: string;
  res: double;
begin  

  if FixedFooterRows = 0 then
    Exit;

  case Value of
    ccNone: str := '';
    ccSUM: str := Format(Options.Editing.CalcFormat,[ColumnSum(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
    ccAVG: str := Format(Options.Editing.CalcFormat,[ColumnAvg(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
    ccCOUNT:
      begin
        res := RowCount - FixedFooterRows - FixedRows;
        str := Format(Options.Editing.CalcFormat,[res]);
      end;
    ccMIN: str := Format(Options.Editing.CalcFormat,[ColumnMin(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
    ccMAX: str := Format(Options.Editing.CalcFormat,[ColumnMax(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
    ccCUSTOM: str := Format(Options.Editing.CalcFormat,[ColumnCustomCalc(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
    ccDISTINCT: str := Format(Options.Editing.CalcFormat,[ColumnDistinct(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
    ccSTDDEV: str := Format(Options.Editing.CalcFormat,[ColumnStdDev(Col,FixedRows, RowCount - FixedFooterRows - 1)]);
  end;

  Cells[Col, RowCount - FixedFooterRows] := str;
end;

procedure TTMSFNCGridData.UpdateColumnWidth(ACol: Integer; AWidth: Single);
begin
  BeginUpdate;
  ColumnWidths[ACol] := AWidth;
  EndUpdate;
end;

procedure TTMSFNCGridData.UpdateGridCells(AScrollOnly: Boolean = False);
begin

end;

procedure TTMSFNCGridData.UpdateRowHeight(ARow: Integer; AHeight: Single);
begin
  BeginUpdate;
  RowHeights[ARow] := AHeight;
  EndUpdate;
end;

{ TTMSFNCGridFileStringList }

procedure TTMSFNCGridFileStringList.Reset;
begin
  fp := 0;
  cache := '';
end;

function TTMSFNCGridFileStringList.GetEOF: Boolean;
begin
  Result := fp >= Count;
end;

procedure TTMSFNCGridFileStringList.ReadLn(var s: string);
begin
  s := Strings[fp];
  inc(fp);
end;

procedure TTMSFNCGridFileStringList.Write(s: string);
begin
  cache := cache + s;
end;

procedure TTMSFNCGridFileStringList.WriteLn(s: string);
begin
  Add(cache + s);
  cache := '';
end;


{ TTMSFNCGridFilterData }

procedure TTMSFNCGridFilterData.Assign(Source: TPersistent);
var
  ASrcFilterData: TTMSFNCGridFilterData;
begin
  ASrcFilterData := Source as TTMSFNCGridFilterData;
  if Assigned(ASrcFilterData) then
  begin
    FColumn  := ASrcFilterData.Column;
    FCondition := ASrcFilterData.Condition;
    FCaseSensitive := ASrcFilterData.CaseSensitive;
    FData := ASrcFilterData.Data;
    FPrefix := ASrcFilterData.Prefix;
    FSuffix := ASrcFilterData.Suffix;
    FOperation := ASrcFilterData.Operation;
  end;

end;

constructor TTMSFNCGridFilterData.Create(ACollection: TCollection);
begin
  inherited;
  FCaseSensitive := True;
  FData := fcVirtual;
end;

{ TTMSFNCGridFilter }

function TTMSFNCGridFilter.Add: TTMSFNCGridFilterData;
begin
  Result := TTMSFNCGridFilterData(inherited Add);
  if Count = 1 then
    Result.Operation := foNone
  else
    Result.Operation := foAND;
end;

constructor TTMSFNCGridFilter.Create(AOwner: TTMSFNCGridData);
begin
  inherited Create(AOwner, TTMSFNCGridFilterData);
  FOwner := AOwner;
end;

function TTMSFNCGridFilter.GetColFilter(Col: Integer): TTMSFNCGridFilterData;
var
  i: Integer;
begin
  for i := 1 to Count do
  begin
    if Items[i - 1].Column = Col then
    begin
      Result := Items[i - 1];
      Exit;
    end;
  end;
  Result := Add;
  Result.Column := Col;
end;

function TTMSFNCGridFilter.GetItem(Index: Integer): TTMSFNCGridFilterData;
begin
  Result := TTMSFNCGridFilterData(inherited GetItem(Index));
end;

procedure TTMSFNCGridFilter.RemoveColumnFilter(Col: integer);
var
  i: integer;
begin
  i := Count;

  while i > 0 do
  begin
    dec(i);
    if Items[i].Column = Col then
      Delete(i);
  end;
end;

function TTMSFNCGridFilter.HasFilter(Col: integer): Boolean;
var
  i: Integer;
begin
  Result := false;

  for i := 1 to Count do
  begin
    if Items[i - 1].Column = Col then
    begin
      Result := true;
      Break;
    end;
  end;
end;

function TTMSFNCGridFilter.Insert(index: Integer): TTMSFNCGridFilterData;
begin
  Result := TTMSFNCGridFilterData(inherited Insert(Index));
end;

procedure TTMSFNCGridFilter.SetItem(Index: Integer; const Value: TTMSFNCGridFilterData);
begin
  inherited SetItem(Index, Value);
end;


{ TTMSFNCGridRowInfo }

constructor TTMSFNCGridRowInfo.Create;
begin
  inherited;
  FData := TTMSFNCGridDataList.Create;
  FState := rsDefault;
end;

destructor TTMSFNCGridRowInfo.Destroy;
begin
  FData.Free;
  inherited;
end;

procedure TTMSFNCGridRowInfo.SetState(const Value: TTMSFNCGridRowState);
begin
  FState := Value;
end;

{ TCellProperty }

procedure TTMSFNCGridCellProperty.Assign(Source: TTMSFNCGridCellProperty);
begin
  FColSpan := Source.ColSpan;
  FRowSpan := Source.RowSpan;
  FBaseCol := Source.BaseCol;
  FBaseRow := Source.BaseRow;
  FColor := Source.Color;
  FAlignHorz := Source.AlignHorz;
  FAlignVert := Source.AlignVert;
  FFontStyle := Source.FontStyle;
  FReadOnly := false;
  FCalcState := Source.CalcState;
end;

constructor TTMSFNCGridCellProperty.Create(DefaultFont: TTMSFNCGraphicsFont);
begin
  inherited Create;
  FDefaultFont := DefaultFont;
  FColSpan := -1;
  FRowSpan := -1;
  FBaseCol := -1;
  FBaseRow := -1;
  FColor := gcNull;
  FBorderColor := gcNull;
  FControl := nil;
  FPrintPageNr := -1;
  FBorderWidth := 1;
  FAngle := 0;
  FAlignHorz := gtaLeading;
  FAlignVert := gtaCenter;
  FFontSize := DefaultFont.Size;
  FFontStyle := DefaultFont.Style;
  FCommentColor := gcNull;
  FComment := '';
  FCalcState := csNoCalc;
  FNameIndex := -1;
end;

destructor TTMSFNCGridCellProperty.Destroy;
begin
  if Assigned(FControl) then
    FreeAndNil(FControl);
  inherited;
end;

procedure TTMSFNCGridCellProperty.FreeControl;
begin
  FreeAndNil(FControl);
end;

function TTMSFNCGridCellProperty.IsBaseCell(Col, Row: integer): boolean;
begin
  Result := ((Col = BaseCol) and (Row = BaseRow)) or ((BaseCol = -1) and (BaseRow = -1));
end;

{ TTMSFNCGridSingleListItem }

constructor TTMSFNCGridSingleListItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FOwner := (Collection as TTMSFNCGridSingleList).FOwner;
end;

procedure TTMSFNCGridSingleListItem.SetValue(const Value: Single);
begin
  FValue := Value;
end;

{ TTMSFNCGridSingleList }

function TTMSFNCGridSingleList.Add: TTMSFNCGridSingleListItem;
begin
  Result := TTMSFNCGridSingleListItem(inherited Add);
end;

constructor TTMSFNCGridSingleList.Create(AOwner: TTMSFNCGridData);
begin
  inherited Create(AOwner, TTMSFNCGridSingleListItem);
  FOwner := AOwner;
end;

function TTMSFNCGridSingleList.GetItem(
  Index: Integer): TTMSFNCGridSingleListItem;
begin
  Result := TTMSFNCGridSingleListItem(inherited Items[Index]);
end;

function TTMSFNCGridSingleList.IndexOf(ACellVal: Integer): Integer;
var
  r: Integer;
begin
  Result := -1;
  for r := 0 to Count - 1 do
  begin
    if Items[r].CellVal = ACellVal then
    begin
      Result := r;
      Exit;
    end;
  end;
end;

function TTMSFNCGridSingleList.Insert(
  Index: Integer): TTMSFNCGridSingleListItem;
begin
  Result := TTMSFNCGridSingleListItem(inherited Insert(Index));
end;

procedure TTMSFNCGridSingleList.SetItem(Index: Integer;
  const Value: TTMSFNCGridSingleListItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGridCellBitmap }

constructor TTMSFNCGridCellBitmap.Create(AOwner: TTMSFNCGridData);
begin
  FBitmap := TTMSFNCBitmap.Create;
end;

destructor TTMSFNCGridCellBitmap.Destroy;
begin
  FBitmap.Free;
  inherited;
end;

procedure TTMSFNCGridCellBitmap.SetBitmap(const Value: TTMSFNCBitmap);
begin
  FBitmap.Assign(Value);
end;

{ TTMSFNCGridCellCombo }

constructor TTMSFNCGridCellCombo.Create;
begin
  inherited;
  FItems := TStringList.Create;
end;

destructor TTMSFNCGridCellCombo.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TTMSFNCGridCellCombo.SetItems(const Value: TStringList);
begin
  FItems.Assign(Value);
end;

{ TTMSFNCGridCellBitmapName }

constructor TTMSFNCGridCellBitmapName.Create;
begin
  inherited Create;
  FBitmapName := '';
  FData := false;
end;

{ TTMSFNCGridCellMarkList }

function TTMSFNCGridCellMarkList.Add: TTMSFNCGridCellMarkItem;
begin
  Result := TTMSFNCGridCellMarkItem(inherited Add);
end;

constructor TTMSFNCGridCellMarkList.Create(AOwner: TTMSFNCGridData);
begin
  inherited Create(AOwner, TTMSFNCGridCellMarkItem);
end;

function TTMSFNCGridCellMarkList.GetItem(Index: Integer): TTMSFNCGridCellMarkItem;
begin
  Result := TTMSFNCGridCellMarkItem(inherited Items[Index]);
end;

function TTMSFNCGridCellMarkList.Insert(Index: Integer): TTMSFNCGridCellMarkItem;
begin
  Result := TTMSFNCGridCellMarkItem(inherited Insert(Index));
end;

procedure TTMSFNCGridCellMarkList.RemoveMarker(CellRange: TTMSFNCGridCellRecRange; MarkerType: TTMSFNCGridMarkType);
var
  i: integer;
begin
  for i := Count - 1 downto 0 do
  begin
    if EqualsCellRange(Items[i].CellRange,CellRange) and (MarkerType = Items[i].MarkType) then
      Delete(i);
  end;
end;

procedure TTMSFNCGridCellMarkList.SetItem(Index: Integer; const Value: TTMSFNCGridCellMarkItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGridCellList }

procedure TTMSFNCGridCellList.AddCell(Cell: TTMSFNCGridCellRec);
begin
  Add(Cell);
end;

procedure TTMSFNCGridCellList.Clear;
begin
  while Count > 0 do
  begin
    Delete(Count - 1);
  end;
end;

constructor TTMSFNCGridCellList.Create;
begin
  inherited Create;
end;

procedure TTMSFNCGridCellList.DeleteCell(Cell: TTMSFNCGridCellRec);
var
  i: integer;
  p: TTMSFNCGridCellRec;
begin
  for i := Count - 1 downto 0 do
  begin
    p := Items[i];
    if (p.Col  = Cell.Col) and (p.Row = Cell.Row) then
      Delete(i);
  end;
end;

procedure TTMSFNCGridCellList.DeleteCell(index: integer);
begin
  if (index >= 0) and (index < Count) then
    Delete(index);
end;


destructor TTMSFNCGridCellList.Destroy;
begin
  Clear;
  inherited;
end;

function TTMSFNCGridCellList.Find(Cell: TTMSFNCGridCellRec): integer;
var
  i: integer;
  p: TTMSFNCGridCellRec;
begin
  Result := -1;

  for i := Count - 1 downto 0 do
  begin
    p := Items[i];
    if (p.Col  = Cell.Col) and (p.Row = Cell.Row) then
    begin
      Result := i;
      Break;
    end;
  end;
end;

function TTMSFNCGridCellList.FindFixed(Cell: TTMSFNCGridCellRec): integer;
var
  i: integer;
  p: TTMSFNCGridCellRec;
begin
  Result := -1;
  for i := Count - 1 downto 0 do
  begin
    p := Items[i];
    if (p.Row = Cell.Row) OR (p.Col  = Cell.Col) then
    begin
      Result := i;
      Break;
    end;
  end;
end;

{ TTMSFNCGridColumns }

function TTMSFNCGridColumns.Add: TTMSFNCGridColumn;
begin
  Result := TTMSFNCGridColumn(inherited Add);
end;

function TTMSFNCGridColumns.ByID(Id: string): TTMSFNCGridColumn;
var
  i: integer;
begin
  Result := nil;
  for i := 0 to Count - 1 do
  begin
    if Items[i].Id = Id then
    begin
      Result := Items[i];
      break;
    end;
  end;
end;

function TTMSFNCGridColumns.ColumnByID(AID: string): TTMSFNCGridColumn;
var
  i: integer;
begin
  Result := nil;
  for i := 0 to Count - 1 do
  begin
    if (Items[i].ID = AID) then
    begin
      Result := Items[i];
      Break;
    end;
  end;
end;

function TTMSFNCGridColumns.ColumnByName(AName: string): TTMSFNCGridColumn;
var
  i: integer;
begin
  Result := nil;
  for i := 0 to Count - 1 do
  begin
    if (Items[i].Name = AName) then
    begin
      Result := Items[i];
      Break;
    end;
  end;
end;

constructor TTMSFNCGridColumns.Create(AGrid: TTMSFNCGridData);
begin
  inherited Create(AGrid, TTMSFNCGridColumn);
  FGrid := AGrid;
end;

function TTMSFNCGridColumns.GetItem(Index: integer): TTMSFNCGridColumn;
begin
  Result := TTMSFNCGridColumn(inherited Items[Index]);
end;

function TTMSFNCGridColumns.Insert(Index: integer): TTMSFNCGridColumn;
begin
  Result := TTMSFNCGridColumn(inherited Insert(Index));
end;

procedure TTMSFNCGridColumns.SetItem(Index: integer;
  const Value: TTMSFNCGridColumn);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGridColumn }

procedure TTMSFNCGridColumn.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridColumn) then
  begin
    FBorderColor := (Source as TTMSFNCGridColumn).BorderColor;
    FBorderWidth := (Source as TTMSFNCGridColumn).BorderWidth;
    FColor := (Source as TTMSFNCGridColumn).Color;
    FFixed := (Source as TTMSFNCGridColumn).Fixed;
    FFont.Assign((Source as TTMSFNCGridColumn).Font);
    FHorzAlignment := (Source as TTMSFNCGridColumn).HorzAlignment;
    FID := (Source as TTMSFNCGridColumn).ID;
    FVertAlignment := (Source as TTMSFNCGridColumn).VertAlignment;
    FEditor := (Source as TTMSFNCGridColumn).Editor;
    FReadOnly := (Source as TTMSFNCGridColumn).ReadOnly;
    FSortFormat := (Source as TTMSFNCGridColumn).SortFormat;
    FSortSuffix := (Source as TTMSFNCGridColumn).SortSuffix;
    FSortPrefix := (Source as TTMSFNCGridColumn).SortPrefix;
    FWordWrap := (Source as TTMSFNCGridColumn).WordWrap;
    FColumnType := (Source as TTMSFNCGridColumn).ColumnType;
    FComboItems.Assign((Source as TTMSFNCGridColumn).ComboItems);
  end;
end;

procedure TTMSFNCGridColumn.Changed;
begin
  if Assigned(FGrid) then
    FGrid.UpdateGridCells;
end;

constructor TTMSFNCGridColumn.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FGrid := (Collection as TTMSFNCGridColumns).FGrid;
  FColor := gcNull;
  FFont := TTMSFNCGraphicsFont.Create;
  FFixedFont := TTMSFNCGraphicsFont.Create;
  FFixedFont.Style := [TFontStyle.fsBold];

//  {$IFDEF VCLLIB}
//  if Assigned(FGrid) then
//  begin
//    FFont.Size := FGrid.ScalePaintValue(6);
//    FFixedFont.Size := FGrid.ScalePaintValue(6);
//  end;
//  {$ENDIF}

  FEditor := etEdit;
  FBorderColor := gcSilver;
  FBorderWidth := 1;
  FComboItems := TStringList.Create;
  FColumnType := ctDefault;
  FHorzAlignment := gtaLeading;
end;

destructor TTMSFNCGridColumn.Destroy;
begin
  FComboItems.Free;
  FFont.Free;
  FFixedFont.Free;
  inherited;
end;

function TTMSFNCGridColumn.GetDisplayName: string;
begin
  Result := '';
  if Assigned(FGrid) then
    Result := FGrid.GetColumnDisplayName(Index)
end;

function TTMSFNCGridColumn.GetWidth: single;
begin
  Result := (Collection.Owner as TTMSFNCGridData).ColumnWidths[Index];
end;

function TTMSFNCGridColumn.IsWidthStored: Boolean;
begin
  Result := Width <> 64;
end;

procedure TTMSFNCGridColumn.SetBorderColor(const Value: TTMSFNCGraphicsColor);
begin
  if (FBorderColor <> Value) then
  begin
    FBorderColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetBorderWidth(const Value: Integer);
begin
  if (FBorderWidth <> Value) then
  begin
    FBorderWidth := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if (FColor <> Value) then
  begin
    FColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetColumnType(const Value: TTMSFNCGridColumnType);
begin
  if (FColumnType <> Value) then
  begin
    FColumnType := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetComboItems(const Value: TStringList);
begin
  FComboItems.Assign(Value);
end;

procedure TTMSFNCGridColumn.SetFixed(const Value: boolean);
begin
  if (FFixed <> Value) then
  begin
    FFixed := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetFixedFont(const Value: TTMSFNCGraphicsFont);
begin
  FFixedFont.Assign(Value);
  Changed;
end;

procedure TTMSFNCGridColumn.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
  Changed;
end;

procedure TTMSFNCGridColumn.SetHorzAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if (FHorzAlignment <> Value) then
  begin
    FHorzAlignment := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetVertAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if (FVertAlignment <> Value) then
  begin
    FVertAlignment := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridColumn.SetWidth(const Value: single);
begin
  if Collection <> nil then
  begin
    (Collection.Owner as TTMSFNCGridData).BeginUpdate;
    (Collection.Owner as TTMSFNCGridData).ColumnWidths[Index] := Value;
    (Collection.Owner as TTMSFNCGridData).EndUpdate;
  end;
end;

procedure TTMSFNCGridColumn.SetWordWrap(const Value: boolean);
begin
  if (FWordWrap <> Value) then
  begin
    FWordWrap := Value;
    Changed;
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCGridIntegerList.GetItem(Index: integer): Integer;
begin
  Result := Integer(inherited Items[Index]);
end;

procedure TTMSFNCGridIntegerList.SetItem(Index: integer; const Value: Integer);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCGridSList.GetItem(Index: integer): Single;
begin
  Result := Single(inherited Items[Index]);
end;

procedure TTMSFNCGridSList.SetItem(Index: integer; const Value: Single);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCGridRowInfoList.GetItem(Index: integer): TTMSFNCGridRowInfo;
begin
  Result := TTMSFNCGridRowInfo(inherited Items[Index]);
end;

procedure TTMSFNCGridRowInfoList.SetItem(Index: integer; const Value: TTMSFNCGridRowInfo);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCGridCellList.GetItem(Index: integer): TTMSFNCGridCellRec;
begin
  Result := TTMSFNCGridCellRec(inherited Items[Index]);
end;

procedure TTMSFNCGridCellList.SetItem(Index: integer; const Value: TTMSFNCGridCellRec);
var
  v: TTMSFNCGridCellRec;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TJSNodeListHelper.GetCount: NativeInt;
begin
  Result := length;
end;

function TJSNodeListHelper.FindNode(ANodeName: string): TJSNode;
var
  Index: Integer;
begin
  Index := IndexOf(ANodeName);
  if Index >= 0 then
    Result := Nodes[Index]
  else
    Result := nil;
end;

function TJSNodeListHelper.IndexOf(const Name: string): NativeInt;
var
  n: TJSNode;
begin
  for Result := 0 to Count - 1 do
  begin
    n := Nodes[Result];
    if (n.nodeName = Name) then
      Exit;
  end;
  Result := -1;
end;

{$ENDIF}

end.

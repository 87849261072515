unit uDeviceList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  WEBLib.RegularExpressions,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel, VCL.TMSFNCMapsCommonTypes;

type
  TDeviceListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MyGrid: TTMSFNCGrid;
    DatasetTimer: TTimer;
    ToolbarPanel: TPanel;
    FilterEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    MyConnection: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    MyDatasetIMEI: TStringField;
    MyDatasetModel: TStringField;
    MyDatasetSimcardNo: TStringField;
    MyDatasetPhoneNo: TStringField;
    MyDatasetSerialNo: TStringField;
    SelectButton: TSpeedButton;
    ViewButton: TSpeedButton;
    FilterActiveEdit: TCheckBox;
    MyDatasetReasonDeactivation: TStringField;
    ClearFilterButton: TSpeedButton;
    ClearSearchEditButton: TSpeedButton;
    GridClipboardButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    MyDatasetNo: TStringField;
    MyDatasetLastMessage: TStringField;
    MyDatasetAssetID: TStringField;
    MyDatasetLicensePlate: TStringField;
    MyDatasetLatitude: TStringField;
    MyDatasetLongitude: TStringField;
    MyDatasetMessageCode: TStringField;
    MyDatasetGPS: TStringField;
    MyDatasetSAT: TStringField;
    MyDatasetGSM: TStringField;
    MyDatasetSpeed: TStringField;
    MyDatasetIntBatt: TStringField;
    MyDatasetExtBatt: TStringField;
    MyDatasetIOStatus: TStringField;
    RefreshButton: TSpeedButton;
    HistoryButton: TSpeedButton;
    CellClipboardButton: TSpeedButton;
    MapButton: TSpeedButton;
    SelectedButton: TSpeedButton;
    FilterTypeEdit: TLookupComboBox;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure EditButtonClick(Sender: TObject); async;
    procedure DatasetTimerTimer(Sender: TObject);
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure FilterEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    procedure SelectButtonClick(Sender: TObject);
    procedure MyGridDblClick(Sender: TObject);
    procedure WebRadioButton1Click(Sender: TObject);
    procedure FilterActiveEditClick(Sender: TObject);
    procedure MyGridColumnSorted(Sender: TObject; ACol: Integer;
      Direction: TTMSFNCGridSortDirection);
    procedure MyGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
    procedure ClearFilterButtonClick(Sender: TObject);
    procedure ClearSearchEditButtonClick(Sender: TObject);
    procedure MyGridFilterSelect(Sender: TObject; Col: Integer;
      var Condition: string);
    procedure MyGridAfterApplyFilter(Sender: TObject; Col: Integer;
      Condition: string; var UpdateCalculations: Boolean);
    procedure GridClipboardButtonClick(Sender: TObject);
    procedure RefreshButtonClick(Sender: TObject);
    [async]
    procedure HistoryButtonClick(Sender: TObject); async;
    procedure CellClipboardButtonClick(Sender: TObject);
    [Async]
    procedure MapButtonClick(Sender: TObject); async;
    procedure SelectedButtonClick(Sender: TObject);
    procedure FilterTypeEditChange(Sender: TObject);
  private
    FocusPKey: Int64;
    Permission: Integer;
    { Private declarations }
    [async]
    procedure LoadList(SearchValue: String); async;
    [async]
    procedure ShowEditDialog(PKey: Integer); async;
    [async]
    function CanDeleteRecord(PKey: Integer): Boolean; async;
    [async]
    procedure DeleteRecord(PKey, ReasonPKey: Integer); async;
  public
    DevicePKey: String;
    DeviceNo: String;
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  DeviceListForm: TDeviceListForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceEdit, uDeviceReasonDeleteSelect, uDeviceHistory,
  uDeviceHistoryMap;

procedure TDeviceListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  FilterTypeEdit.Value := '0';
  FilterEdit.EditType := weNumeric;
  SetGridStyle(MyGrid, False);
end;

procedure TDeviceListForm.SetPermission(MyPermission: Integer);
begin
  Permission := MyPermission;
  AddButton.Visible := MyPermission in [2,3,6,7];
  ViewButton.Visible := MyPermission in [1,5];
  EditButton.Visible := MyPermission in [2,3,7];
  DeleteButton.Visible := MyPermission in [4,5,6,7];
end;

procedure TDeviceListForm.WebFormShow(Sender: TObject);
begin
  FilterEdit.SetFocus;
  LoadList(EmptyStr);
end;

procedure TDeviceListForm.WebRadioButton1Click(Sender: TObject);
begin
  //
end;

procedure TDeviceListForm.ClearFilterButtonClick(Sender: TObject);
begin
  MyGrid.RemoveFilter;
  ClearFilterButton.Visible := False;
end;

procedure TDeviceListForm.ClearSearchEditButtonClick(Sender: TObject);
begin
  FilterEdit.Text := EmptyStr;
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TDeviceListForm.GridClipboardButtonClick(Sender: TObject);
begin
  GridToClipBoard(MyGrid, True);
end;

procedure TDeviceListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TDeviceListForm.DeleteButtonClick(Sender: TObject);
var
  Result: Boolean;
  MyWebForm: TDeviceReasonDeleteSelectForm;
  MyModalResult: TModalResult;
  RowIdx: Integer;
  Value: String;
begin
  if not(FilterActiveEdit.Checked) then begin
    if MyGrid.RowSelectionCount = 1 then begin
      RowIdx := MyGrid.SelectedRow[0];
      Value := MyGrid.Cells[1, RowIdx];
      Result := await(CanDeleteRecord(StrToInt(Value)));
      if Result then begin
        // Get Reason
        MyWebForm := TDeviceReasonDeleteSelectForm.Create(Self);
        MyWebForm.Popup := True;
        MyWebForm.Border := fbNone;
        await(TDeviceReasonDeleteSelectForm, MyWebForm.Load());
        try
          // excute form and wait for close
          MyModalResult := await(TModalResult, MyWebForm.Execute);
          if MyModalResult = mrOK then begin
            DeleteRecord(StrToInt(Value), MyWebForm.PKey);
          end;
        finally
          MyWebForm.Free;
        end;
      end;
    end;
  end;
end;

procedure TDeviceListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowEditDialog(-1));
end;

procedure TDeviceListForm.EditButtonClick(Sender: TObject);
var
  RowIdx: Integer;
  Value: String;
begin
  if MyGrid.RowSelectionCount = 1 then begin
    RowIdx := MyGrid.SelectedRow[0];
    Value := MyGrid.Cells[1, RowIdx];
    ShowEditDialog(StrToInt(Value));
  end;
end;

procedure TDeviceListForm.MapButtonClick(Sender: TObject);
var
  DevicePKey: String;
  MyWebForm: TDeviceHistoryMapForm;
  MyModalResult: TModalResult;
  Latitude, Longitude: Double;
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    if MyGrid.RowSelectionCount = 1 then begin
      DevicePKey := MyGrid.Cells[1, MyGrid.SelectedRow[0]];
      if MyDataset.Locate('IMEI', DevicePKey, []) then begin
        // Create Form
        MyWebForm := TDeviceHistoryMapForm.Create(Self);
        MyWebForm.Popup := True;
        MyWebForm.Border := fbNone;
        MyWebForm.Latitude := StrToFloatDot(Trim(MyDataset.FieldByName('Latitude').AsString));
        MyWebForm.Longitude := StrToFloatDot(Trim(MyDataset.FieldByName('Longitude').AsString));
        // Load Form
        await(TDeviceHistoryMapForm, MyWebForm.Load());
        try
          // Show Form
          MyModalResult := await(TModalResult, MyWebForm.Execute);
          if MyModalResult = mrOK then begin
          end;
        finally
          MyWebForm.Free;
        end;
      end;
    end;
  end;
end;

procedure TDeviceListForm.MyDatasetAfterOpen(DataSet: TDataSet);
var
  SearchValue: String;
begin
  // Update Caption
  CaptionLabel.Caption :=
    'Device - List (' + IntToStr(Dataset.RecordCount) + ')';
  // Selected Record
  if not(Dataset.IsEmpty) then begin
    if FocusPKey >= 0 then begin
      SearchValue := '  ' + IntToStr(FocusPKey);
      if not(MyDataset.Locate('IMEI', SearchValue, [])) then begin
        FocusPKey := Dataset.FieldByName('IMEI').AsInteger;
      end;
    end;
  end else begin
    FocusPKey := -1;
  end;
  // Link Grid
  MyGrid.OnSelectedCell := nil;
  GridDatabaseAdapter.Active := True;
  GridDatabaseAdapter.LoadAllDataAndDisconnect;
  if FocusPKey > 0 then GridSelectRow(MyGrid, 1, IntToStr(FocusPKey));
  MyGrid.OnSelectedCell := MyGridSelectedCell;
  DatasetTimer.Enabled := True;
end;

procedure TDeviceListForm.MyGridAfterApplyFilter(Sender: TObject; Col: Integer;
  Condition: string; var UpdateCalculations: Boolean);
begin
  ClearFilterButton.Visible := MyGrid.Filter.Count > 0;
end;

procedure TDeviceListForm.MyGridColumnSorted(Sender: TObject; ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
  GridSelectRow(MyGrid, 1, IntToStr(FocusPKey));
end;

procedure TDeviceListForm.MyGridDblClick(Sender: TObject);
begin
  if ViewButton.Visible or EditButton.Visible then begin
    EditButton.Click();
  end;
end;

procedure TDeviceListForm.MyGridFilterSelect(Sender: TObject; Col: Integer;
  var Condition: string);
begin
  ClearFilterButton.Visible := True;
end;

procedure TDeviceListForm.MyGridSelectedCell(Sender: TObject; ACol,
  ARow: Integer);
begin
  FocusPKey := StrToInt(Trim(MyGrid.Cells[1, ARow]));
end;

procedure TDeviceListForm.RefreshButtonClick(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TDeviceListForm.FilterActiveEditClick(Sender: TObject);
begin
  LoadList(FilterEdit.Text);
end;

procedure TDeviceListForm.FilterEditChange(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TDeviceListForm.FilterTypeEditChange(Sender: TObject);
var
  FilterValue: String;
begin
  FilterValue := FilterEdit.Text;
  FilterEdit.Text := EmptyStr;
  case StrToInt(TLookupComboBox(Sender).Value) of
    0, 1: FilterEdit.EditType := weNumeric;
    else FilterEdit.EditType := weString;
  end;
  // Refresh List
  if not(FilterValue.IsEmpty) then begin
    SearchTimer.Interval := 0;
    FilterEditChange(Self);
  end;
  FilterEdit.SetFocus;
end;

procedure TDeviceListForm.HistoryButtonClick(Sender: TObject);
var
  MyWebForm: TDeviceHistoryForm;
  MyModalResult: TModalResult;
begin
  if not(MyDataset.IsEmpty) and (MyGrid.RowSelectionCount=1) then begin
    // Create Form
    MyWebForm := TDeviceHistoryForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.DevicePKey := MyGrid.Cells[1, MyGrid.SelectedRow[0]];
    MyWebForm.DateFrom := Date();
    MyWebForm.DateTill := Date();
    // Load Form
    await(TDeviceHistoryForm, MyWebForm.Load());
    try
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TDeviceListForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  WebWaitMessage.Hide;
end;

procedure TDeviceListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL := url_php +
      'default_device_list.php?sessionid=' + SessionId +
      '&f=' + FilterTypeEdit.Value;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    if FilterActiveEdit.Checked then begin
      MyWebRequest.URL := MyWebRequest.URL + '&active=0';
    end else begin
      MyWebRequest.URL := MyWebRequest.URL + '&active=1';
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TDeviceListForm.SearchTimerTimer(Sender: TObject);
begin
  SearchTimer.Interval := 300;
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadList(FilterEdit.Text);
  end;
end;

procedure TDeviceListForm.SelectButtonClick(Sender: TObject);
var
  RowIdx: Integer;
begin
  if MyGrid.RowSelectionCount = 1 then begin
    RowIdx := MyGrid.SelectedRow[0];
    DevicePKey := MyGrid.Cells[1, RowIdx];
    DeviceNo := MyGrid.Cells[0, RowIdx];
    ModalResult := mrOK;
  end;
end;

procedure TDeviceListForm.SelectedButtonClick(Sender: TObject);
var
  FilterValue: String;
  StringList, FilterList: TStringList;
  I, ColIdx: Integer;
begin
  StringList := TStringList.Create();
  FilterList := TStringList.Create();
  try
    // Get Device PKey
    for I := 0 to MyGrid.RowSelectionCount-1 do begin
      StringList.Add(Trim(MyGrid.Cells[1, MyGrid.SelectedRow[I]]));
    end;
    // Set Filter Text
    for I := 0 to StringList.Count-1 do begin
      if MyDataset.Locate('IMEI', '  ' + StringList[I], []) then begin
        case StrToInt(FilterTypeEdit.Value) of
          0: FilterValue := MyDataset.FieldByName('No').AsString;
          1: FilterValue := MyDataset.FieldByName('IMEI').AsString;
          2: FilterValue := MyDataset.FieldByName('Asset ID').AsString;
          3: FilterValue := MyDataset.FieldByName('License Plate').AsString;
          4: FilterValue := MyDataset.FieldByName('Phone No').AsString;
          5: FilterValue := MyDataset.FieldByName('Serial No').AsString;
        else
          FilterValue := EmptyStr;
        end;
//      if not(Trim(FilterValue).IsEmpty) then
          FilterList.Add(Trim(FilterValue));
      end;
    end;
    FilterEdit.Text := FilterList.DelimitedText;
    SearchTimer.Enabled := False;
    SearchTimer.Enabled := True;
  finally
    StringList.Free;
    FilterList.Free;
  end;

end;

procedure TDeviceListForm.ShowEditDialog(PKey: Integer);
var
  MyWebForm: TDeviceEditForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TDeviceEditForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  MyWebForm.PKey := PKey;
  // Load Form
  await(TDeviceEditForm, MyWebForm.Load());
  try
    MyWebForm.PKey := PKey;
    // Permission
    if Permission in [1,5] then begin
      MyWebForm.SetReadOnly;
    end;
    MyWebForm.IsSet := True;
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      FocusPKey := MyWebForm.PKey;
      LoadList(FilterEdit.Text);
    end;
  finally
    MyWebForm.Free;
  end;
end;

function TDeviceListForm.CanDeleteRecord(PKey: Integer): Boolean;
var
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_device_can_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        // Build Message
        if (MyJO.GetJSONValue('AssetPKey') = '0') then begin
          // No Links
          MyMessage := 'Delete asset?';
        end else begin
          MyMessage :=
            'The device is linked to asset "' + MyJO.GetJSONValue('AssetID') + '". ' +
            'This link will be removed. Delete device?';
        end;
        MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog(MyMessage, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
        Result := MyModalResult = mrYes;
      end else begin
        Result := False;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TDeviceListForm.CellClipboardButtonClick(Sender: TObject);
begin
  CellToClipBoard(MyGrid);
end;

procedure TDeviceListForm.DeleteRecord(PKey, ReasonPKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_device_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) + '&reason=' + IntToStr(ReasonPKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        SearchTimer.Enabled := True;
      end else begin
        MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TDeviceListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  FilterEdit := TEdit.Create(Self);
  FilterActiveEdit := TCheckBox.Create(Self);
  ClearFilterButton := TSpeedButton.Create(Self);
  ClearSearchEditButton := TSpeedButton.Create(Self);
  RefreshButton := TSpeedButton.Create(Self);
  HistoryButton := TSpeedButton.Create(Self);
  MapButton := TSpeedButton.Create(Self);
  FilterTypeEdit := TLookupComboBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  ViewButton := TSpeedButton.Create(Self);
  GridClipboardButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  CellClipboardButton := TSpeedButton.Create(Self);
  SelectedButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetIMEI := TStringField.Create(Self);
  MyDatasetNo := TStringField.Create(Self);
  MyDatasetModel := TStringField.Create(Self);
  MyDatasetSimcardNo := TStringField.Create(Self);
  MyDatasetPhoneNo := TStringField.Create(Self);
  MyDatasetSerialNo := TStringField.Create(Self);
  MyDatasetReasonDeactivation := TStringField.Create(Self);
  MyDatasetLastMessage := TStringField.Create(Self);
  MyDatasetAssetID := TStringField.Create(Self);
  MyDatasetLicensePlate := TStringField.Create(Self);
  MyDatasetLatitude := TStringField.Create(Self);
  MyDatasetLongitude := TStringField.Create(Self);
  MyDatasetMessageCode := TStringField.Create(Self);
  MyDatasetGPS := TStringField.Create(Self);
  MyDatasetSAT := TStringField.Create(Self);
  MyDatasetGSM := TStringField.Create(Self);
  MyDatasetSpeed := TStringField.Create(Self);
  MyDatasetIntBatt := TStringField.Create(Self);
  MyDatasetExtBatt := TStringField.Create(Self);
  MyDatasetIOStatus := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  MyConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  FilterEdit.BeforeLoadDFMValues;
  FilterActiveEdit.BeforeLoadDFMValues;
  ClearFilterButton.BeforeLoadDFMValues;
  ClearSearchEditButton.BeforeLoadDFMValues;
  RefreshButton.BeforeLoadDFMValues;
  HistoryButton.BeforeLoadDFMValues;
  MapButton.BeforeLoadDFMValues;
  FilterTypeEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  ViewButton.BeforeLoadDFMValues;
  GridClipboardButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  CellClipboardButton.BeforeLoadDFMValues;
  SelectedButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetIMEI.BeforeLoadDFMValues;
  MyDatasetNo.BeforeLoadDFMValues;
  MyDatasetModel.BeforeLoadDFMValues;
  MyDatasetSimcardNo.BeforeLoadDFMValues;
  MyDatasetPhoneNo.BeforeLoadDFMValues;
  MyDatasetSerialNo.BeforeLoadDFMValues;
  MyDatasetReasonDeactivation.BeforeLoadDFMValues;
  MyDatasetLastMessage.BeforeLoadDFMValues;
  MyDatasetAssetID.BeforeLoadDFMValues;
  MyDatasetLicensePlate.BeforeLoadDFMValues;
  MyDatasetLatitude.BeforeLoadDFMValues;
  MyDatasetLongitude.BeforeLoadDFMValues;
  MyDatasetMessageCode.BeforeLoadDFMValues;
  MyDatasetGPS.BeforeLoadDFMValues;
  MyDatasetSAT.BeforeLoadDFMValues;
  MyDatasetGSM.BeforeLoadDFMValues;
  MyDatasetSpeed.BeforeLoadDFMValues;
  MyDatasetIntBatt.BeforeLoadDFMValues;
  MyDatasetExtBatt.BeforeLoadDFMValues;
  MyDatasetIOStatus.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  MyConnection.BeforeLoadDFMValues;
  try
    Name := 'DeviceListForm';
    Width := 1368;
    Height := 634;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1368;
    FormPanel.Height := 634;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1362;
    ContentPanel.Height := 591;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 54;
    MyGrid.Width := 1322;
    MyGrid.Height := 478;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 0;
    SetEvent(MyGrid, Self, 'OnDblClick', 'MyGridDblClick');
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 20;
    MyGrid.RowCount := 1;
    SetEvent(MyGrid, Self, 'OnFilterSelect', 'MyGridFilterSelect');
    SetEvent(MyGrid, Self, 'OnAfterApplyFilter', 'MyGridAfterApplyFilter');
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.StretchColumn := 0;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Editing.Enabled := False;
    MyGrid.Options.Filtering.Rows := frAll;
    MyGrid.Options.Filtering.DropDown := True;
    MyGrid.Options.Filtering.DropDownWidth := 240;
    MyGrid.Options.Filtering.DropDownHeight := 240;
    MyGrid.Options.Filtering.MultiColumn := True;
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ColumnSizing := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.Sorting.IgnoreCase := True;
    MyGrid.Options.Sorting.IgnoreBlanks := True;
    MyGrid.Options.Sorting.Mode := gsmNormal;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    SetEvent(MyGrid, Self, 'OnSelectedCell', 'MyGridSelectedCell');
    MyGrid.Adapter := GridDatabaseAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      SortFormat := ssNumeric;
      Width := 57.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 120.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 140.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 140.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 180.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 180.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 240.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -16;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 0;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    SetEvent(MyGrid, Self, 'OnColumnSorted', 'MyGridColumnSorted');
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1362;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    FilterEdit.SetParentComponent(ToolbarPanel);
    FilterEdit.Name := 'FilterEdit';
    FilterEdit.Left := 20;
    FilterEdit.Top := 10;
    FilterEdit.Width := 345;
    FilterEdit.Height := 34;
    FilterEdit.AutoFocus := True;
    FilterEdit.ChildOrder := 1;
    FilterEdit.Font.Charset := ANSI_CHARSET;
    FilterEdit.Font.Color := clWindowText;
    FilterEdit.Font.Height := -16;
    FilterEdit.Font.Name := 'Segoe UI';
    FilterEdit.Font.Style := [];
    FilterEdit.HeightPercent := 100.000000000000000000;
    FilterEdit.ParentFont := False;
    FilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterEdit, Self, 'OnChange', 'FilterEditChange');
    FilterActiveEdit.SetParentComponent(ToolbarPanel);
    FilterActiveEdit.Name := 'FilterActiveEdit';
    FilterActiveEdit.Left := 672;
    FilterActiveEdit.Top := 14;
    FilterActiveEdit.Width := 161;
    FilterActiveEdit.Height := 22;
    FilterActiveEdit.Caption := 'Show inactive only';
    FilterActiveEdit.ChildOrder := 1;
    FilterActiveEdit.HeightPercent := 100.000000000000000000;
    FilterActiveEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterActiveEdit, Self, 'OnClick', 'FilterActiveEditClick');
    ClearFilterButton.SetParentComponent(ToolbarPanel);
    ClearFilterButton.Name := 'ClearFilterButton';
    ClearFilterButton.AlignWithMargins := True;
    ClearFilterButton.Left := 843;
    ClearFilterButton.Top := 8;
    ClearFilterButton.Width := 125;
    ClearFilterButton.Height := 36;
    ClearFilterButton.Margins.Left := 20;
    ClearFilterButton.Margins.Top := 8;
    ClearFilterButton.Margins.Right := 5;
    ClearFilterButton.Margins.Bottom := 10;
    ClearFilterButton.Caption := 'Clear Filters';
    ClearFilterButton.Color := clNone;
    ClearFilterButton.ElementClassName := 'MyWebButton';
    ClearFilterButton.Glyph.LoadFromFile('uDeviceList.ToolbarPanel.ClearFilterButton.Glyph.png');
    ClearFilterButton.HeightPercent := 100.000000000000000000;
    ClearFilterButton.TabOrder := 2;
    ClearFilterButton.Visible := False;
    ClearFilterButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearFilterButton, Self, 'OnClick', 'ClearFilterButtonClick');
    ClearSearchEditButton.SetParentComponent(ToolbarPanel);
    ClearSearchEditButton.Name := 'ClearSearchEditButton';
    ClearSearchEditButton.AlignWithMargins := True;
    ClearSearchEditButton.Left := 332;
    ClearSearchEditButton.Top := 12;
    ClearSearchEditButton.Width := 30;
    ClearSearchEditButton.Height := 30;
    ClearSearchEditButton.Margins.Left := 10;
    ClearSearchEditButton.Margins.Top := 8;
    ClearSearchEditButton.Margins.Right := 0;
    ClearSearchEditButton.Margins.Bottom := 8;
    ClearSearchEditButton.Color := clNone;
    ClearSearchEditButton.ElementClassName := 'MyClearButton';
    ClearSearchEditButton.Glyph.LoadFromFile('uDeviceList.ToolbarPanel.ClearSearchEditButton.Glyph.png');
    ClearSearchEditButton.HeightPercent := 100.000000000000000000;
    ClearSearchEditButton.TabOrder := 3;
    ClearSearchEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearSearchEditButton, Self, 'OnClick', 'ClearSearchEditButtonClick');
    RefreshButton.SetParentComponent(ToolbarPanel);
    RefreshButton.Name := 'RefreshButton';
    RefreshButton.AlignWithMargins := True;
    RefreshButton.Left := 1242;
    RefreshButton.Top := 8;
    RefreshButton.Width := 100;
    RefreshButton.Height := 36;
    RefreshButton.Margins.Left := 0;
    RefreshButton.Margins.Top := 8;
    RefreshButton.Margins.Right := 20;
    RefreshButton.Margins.Bottom := 10;
    RefreshButton.Align := alRight;
    RefreshButton.Caption := 'Refresh';
    RefreshButton.Color := clNone;
    RefreshButton.ElementClassName := 'MyWebButton';
    RefreshButton.Glyph.LoadFromFile('uDeviceList.ToolbarPanel.RefreshButton.Glyph.png');
    RefreshButton.HeightPercent := 100.000000000000000000;
    RefreshButton.MaterialGlyph := 'refresh';
    RefreshButton.TabOrder := 4;
    RefreshButton.WidthPercent := 100.000000000000000000;
    SetEvent(RefreshButton, Self, 'OnClick', 'RefreshButtonClick');
    HistoryButton.SetParentComponent(ToolbarPanel);
    HistoryButton.Name := 'HistoryButton';
    HistoryButton.AlignWithMargins := True;
    HistoryButton.Left := 1122;
    HistoryButton.Top := 8;
    HistoryButton.Width := 100;
    HistoryButton.Height := 36;
    HistoryButton.Margins.Left := 0;
    HistoryButton.Margins.Top := 8;
    HistoryButton.Margins.Right := 20;
    HistoryButton.Margins.Bottom := 10;
    HistoryButton.Align := alRight;
    HistoryButton.Caption := 'History';
    HistoryButton.Color := clNone;
    HistoryButton.ElementClassName := 'MyWebButton';
    HistoryButton.Glyph.LoadFromFile('uDeviceList.ToolbarPanel.HistoryButton.Glyph.png');
    HistoryButton.HeightPercent := 100.000000000000000000;
    HistoryButton.MaterialGlyph := 'refresh';
    HistoryButton.TabOrder := 5;
    HistoryButton.WidthPercent := 100.000000000000000000;
    SetEvent(HistoryButton, Self, 'OnClick', 'HistoryButtonClick');
    MapButton.SetParentComponent(ToolbarPanel);
    MapButton.Name := 'MapButton';
    MapButton.AlignWithMargins := True;
    MapButton.Left := 1002;
    MapButton.Top := 8;
    MapButton.Width := 100;
    MapButton.Height := 36;
    MapButton.Margins.Left := 0;
    MapButton.Margins.Top := 8;
    MapButton.Margins.Right := 20;
    MapButton.Margins.Bottom := 10;
    MapButton.Align := alRight;
    MapButton.Caption := 'Map';
    MapButton.Color := clNone;
    MapButton.ElementClassName := 'MyWebButton';
    MapButton.Glyph.LoadFromFile('uDeviceList.ToolbarPanel.MapButton.Glyph.png');
    MapButton.HeightPercent := 100.000000000000000000;
    MapButton.TabOrder := 6;
    MapButton.WidthPercent := 100.000000000000000000;
    SetEvent(MapButton, Self, 'OnClick', 'MapButtonClick');
    FilterTypeEdit.SetParentComponent(ToolbarPanel);
    FilterTypeEdit.Name := 'FilterTypeEdit';
    FilterTypeEdit.AlignWithMargins := True;
    FilterTypeEdit.Left := 378;
    FilterTypeEdit.Top := 10;
    FilterTypeEdit.Width := 280;
    FilterTypeEdit.Height := 34;
    FilterTypeEdit.Margins.Left := 20;
    FilterTypeEdit.Margins.Top := 8;
    FilterTypeEdit.Margins.Bottom := 8;
    FilterTypeEdit.Color := clWhite;
    FilterTypeEdit.ElementClassName := 'myselect_white';
    FilterTypeEdit.Font.Charset := DEFAULT_CHARSET;
    FilterTypeEdit.Font.Color := clWindowText;
    FilterTypeEdit.Font.Height := 12;
    FilterTypeEdit.Font.Name := 'Segoe UI';
    FilterTypeEdit.Font.Style := [];
    FilterTypeEdit.HeightPercent := 100.000000000000000000;
    FilterTypeEdit.ParentFont := False;
    FilterTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterTypeEdit, Self, 'OnChange', 'FilterTypeEditChange');
    FilterTypeEdit.ItemIndex := -1;
    FilterTypeEdit.LookupValues.Clear;
    with FilterTypeEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'No';
    end;
    with FilterTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'IMEI';
    end;
    with FilterTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Asset ID';
    end;
    with FilterTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'Registration Plate';
    end;
    with FilterTypeEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'Phone No.';
    end;
    with FilterTypeEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'Serial No.';
    end;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 542;
    StatusBarPanel.Width := 1362;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1242;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 361;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 145;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 469;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 4;
    SelectButton.Visible := False;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    ViewButton.SetParentComponent(StatusBarPanel);
    ViewButton.Name := 'ViewButton';
    ViewButton.AlignWithMargins := True;
    ViewButton.Left := 253;
    ViewButton.Top := 8;
    ViewButton.Width := 100;
    ViewButton.Height := 31;
    ViewButton.Margins.Left := 0;
    ViewButton.Margins.Top := 8;
    ViewButton.Margins.Right := 8;
    ViewButton.Margins.Bottom := 10;
    ViewButton.Align := alLeft;
    ViewButton.Caption := 'View';
    ViewButton.Color := clNone;
    ViewButton.ElementClassName := 'MyWebButton';
    ViewButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.ViewButton.Glyph.png');
    ViewButton.HeightPercent := 100.000000000000000000;
    ViewButton.TabOrder := 5;
    ViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewButton, Self, 'OnClick', 'EditButtonClick');
    GridClipboardButton.SetParentComponent(StatusBarPanel);
    GridClipboardButton.Name := 'GridClipboardButton';
    GridClipboardButton.AlignWithMargins := True;
    GridClipboardButton.Left := 577;
    GridClipboardButton.Top := 8;
    GridClipboardButton.Width := 136;
    GridClipboardButton.Height := 31;
    GridClipboardButton.Margins.Left := 0;
    GridClipboardButton.Margins.Top := 8;
    GridClipboardButton.Margins.Right := 8;
    GridClipboardButton.Margins.Bottom := 10;
    GridClipboardButton.Align := alLeft;
    GridClipboardButton.Caption := 'Copy Rows';
    GridClipboardButton.Color := clNone;
    GridClipboardButton.ElementClassName := 'MyWebButton';
    GridClipboardButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.GridClipboardButton.Glyph.png');
    GridClipboardButton.HeightPercent := 100.000000000000000000;
    GridClipboardButton.TabOrder := 6;
    GridClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(GridClipboardButton, Self, 'OnClick', 'GridClipboardButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 128;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 7;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    CellClipboardButton.SetParentComponent(StatusBarPanel);
    CellClipboardButton.Name := 'CellClipboardButton';
    CellClipboardButton.AlignWithMargins := True;
    CellClipboardButton.Left := 721;
    CellClipboardButton.Top := 8;
    CellClipboardButton.Width := 136;
    CellClipboardButton.Height := 31;
    CellClipboardButton.Margins.Left := 0;
    CellClipboardButton.Margins.Top := 8;
    CellClipboardButton.Margins.Right := 8;
    CellClipboardButton.Margins.Bottom := 10;
    CellClipboardButton.Align := alLeft;
    CellClipboardButton.Caption := 'Copy Cell';
    CellClipboardButton.Color := clNone;
    CellClipboardButton.ElementClassName := 'MyWebButton';
    CellClipboardButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.CellClipboardButton.Glyph.png');
    CellClipboardButton.HeightPercent := 100.000000000000000000;
    CellClipboardButton.TabOrder := 8;
    CellClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CellClipboardButton, Self, 'OnClick', 'CellClipboardButtonClick');
    SelectedButton.SetParentComponent(StatusBarPanel);
    SelectedButton.Name := 'SelectedButton';
    SelectedButton.AlignWithMargins := True;
    SelectedButton.Left := 865;
    SelectedButton.Top := 8;
    SelectedButton.Width := 136;
    SelectedButton.Height := 31;
    SelectedButton.Margins.Left := 0;
    SelectedButton.Margins.Top := 8;
    SelectedButton.Margins.Right := 8;
    SelectedButton.Margins.Bottom := 10;
    SelectedButton.Align := alLeft;
    SelectedButton.Caption := 'Selected';
    SelectedButton.Color := clNone;
    SelectedButton.ElementClassName := 'MyWebButton';
    SelectedButton.Glyph.LoadFromFile('uDeviceList.StatusBarPanel.SelectedButton.Glyph.png');
    SelectedButton.HeightPercent := 100.000000000000000000;
    SelectedButton.TabOrder := 9;
    SelectedButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectedButton, Self, 'OnClick', 'SelectedButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1368;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 86;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Device - List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1339;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uDeviceList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uDeviceList.WebWaitMessage.Picture.gif');
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 59;
    WebMessageDlg1.Top := 487;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 485;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := MyGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'No';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'IMEI';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Model';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Asset ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'License Plate';
      Header := 'Reg. Plate';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Last Message';
      Header := 'Last Message';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Latitude';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Longitude';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Message Code';
      Header := 'Code';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'GPS';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'SAT';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'GSM';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Speed';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Int. Batt.';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Ext. Batt.';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'I/O Status';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Simcard No';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Phone No';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Serial No';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Reason Deactivation';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 488;
    MyDatasetIMEI.SetParentComponent(MyDataset);
    MyDatasetIMEI.Name := 'MyDatasetIMEI';
    MyDatasetIMEI.FieldName := 'IMEI';
    MyDatasetIMEI.Size := 256;
    MyDatasetNo.SetParentComponent(MyDataset);
    MyDatasetNo.Name := 'MyDatasetNo';
    MyDatasetNo.FieldName := 'No';
    MyDatasetNo.Size := 256;
    MyDatasetModel.SetParentComponent(MyDataset);
    MyDatasetModel.Name := 'MyDatasetModel';
    MyDatasetModel.FieldName := 'Model';
    MyDatasetModel.Size := 256;
    MyDatasetSimcardNo.SetParentComponent(MyDataset);
    MyDatasetSimcardNo.Name := 'MyDatasetSimcardNo';
    MyDatasetSimcardNo.FieldName := 'Simcard No';
    MyDatasetSimcardNo.Size := 256;
    MyDatasetPhoneNo.SetParentComponent(MyDataset);
    MyDatasetPhoneNo.Name := 'MyDatasetPhoneNo';
    MyDatasetPhoneNo.FieldName := 'Phone No';
    MyDatasetPhoneNo.Size := 256;
    MyDatasetSerialNo.SetParentComponent(MyDataset);
    MyDatasetSerialNo.Name := 'MyDatasetSerialNo';
    MyDatasetSerialNo.FieldName := 'Serial No';
    MyDatasetSerialNo.Size := 256;
    MyDatasetReasonDeactivation.SetParentComponent(MyDataset);
    MyDatasetReasonDeactivation.Name := 'MyDatasetReasonDeactivation';
    MyDatasetReasonDeactivation.FieldName := 'Reason Deactivation';
    MyDatasetReasonDeactivation.Size := 256;
    MyDatasetLastMessage.SetParentComponent(MyDataset);
    MyDatasetLastMessage.Name := 'MyDatasetLastMessage';
    MyDatasetLastMessage.FieldName := 'Last Message';
    MyDatasetLastMessage.Size := 256;
    MyDatasetAssetID.SetParentComponent(MyDataset);
    MyDatasetAssetID.Name := 'MyDatasetAssetID';
    MyDatasetAssetID.FieldName := 'Asset ID';
    MyDatasetAssetID.Size := 256;
    MyDatasetLicensePlate.SetParentComponent(MyDataset);
    MyDatasetLicensePlate.Name := 'MyDatasetLicensePlate';
    MyDatasetLicensePlate.FieldName := 'License Plate';
    MyDatasetLicensePlate.Size := 256;
    MyDatasetLatitude.SetParentComponent(MyDataset);
    MyDatasetLatitude.Name := 'MyDatasetLatitude';
    MyDatasetLatitude.FieldName := 'Latitude';
    MyDatasetLatitude.Size := 256;
    MyDatasetLongitude.SetParentComponent(MyDataset);
    MyDatasetLongitude.Name := 'MyDatasetLongitude';
    MyDatasetLongitude.FieldName := 'Longitude';
    MyDatasetLongitude.Size := 256;
    MyDatasetMessageCode.SetParentComponent(MyDataset);
    MyDatasetMessageCode.Name := 'MyDatasetMessageCode';
    MyDatasetMessageCode.FieldName := 'Message Code';
    MyDatasetMessageCode.Size := 256;
    MyDatasetGPS.SetParentComponent(MyDataset);
    MyDatasetGPS.Name := 'MyDatasetGPS';
    MyDatasetGPS.FieldName := 'GPS';
    MyDatasetGPS.Size := 256;
    MyDatasetSAT.SetParentComponent(MyDataset);
    MyDatasetSAT.Name := 'MyDatasetSAT';
    MyDatasetSAT.FieldName := 'SAT';
    MyDatasetSAT.Size := 256;
    MyDatasetGSM.SetParentComponent(MyDataset);
    MyDatasetGSM.Name := 'MyDatasetGSM';
    MyDatasetGSM.FieldName := 'GSM';
    MyDatasetGSM.Size := 256;
    MyDatasetSpeed.SetParentComponent(MyDataset);
    MyDatasetSpeed.Name := 'MyDatasetSpeed';
    MyDatasetSpeed.FieldName := 'Speed';
    MyDatasetSpeed.Size := 256;
    MyDatasetIntBatt.SetParentComponent(MyDataset);
    MyDatasetIntBatt.Name := 'MyDatasetIntBatt';
    MyDatasetIntBatt.FieldName := 'Int. Batt.';
    MyDatasetIntBatt.Size := 256;
    MyDatasetExtBatt.SetParentComponent(MyDataset);
    MyDatasetExtBatt.Name := 'MyDatasetExtBatt';
    MyDatasetExtBatt.FieldName := 'Ext. Batt.';
    MyDatasetExtBatt.Size := 256;
    MyDatasetIOStatus.SetParentComponent(MyDataset);
    MyDatasetIOStatus.Name := 'MyDatasetIOStatus';
    MyDatasetIOStatus.FieldName := 'I/O Status';
    MyDatasetIOStatus.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 484;
    MyDataSource.Top := 488;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 283;
    DatasetTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    MyConnection.SetParentComponent(Self);
    MyConnection.Name := 'MyConnection';
    MyConnection.Active := False;
    MyConnection.Delimiter := ';';
    MyConnection.URI := 'https://instatrack.eu:44321/php/default_device_list.php?sessionid=d27cd004477d733d910789c672be2403&active=1';
    MyConnection.Left := 191;
    MyConnection.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    FilterEdit.AfterLoadDFMValues;
    FilterActiveEdit.AfterLoadDFMValues;
    ClearFilterButton.AfterLoadDFMValues;
    ClearSearchEditButton.AfterLoadDFMValues;
    RefreshButton.AfterLoadDFMValues;
    HistoryButton.AfterLoadDFMValues;
    MapButton.AfterLoadDFMValues;
    FilterTypeEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    ViewButton.AfterLoadDFMValues;
    GridClipboardButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    CellClipboardButton.AfterLoadDFMValues;
    SelectedButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetIMEI.AfterLoadDFMValues;
    MyDatasetNo.AfterLoadDFMValues;
    MyDatasetModel.AfterLoadDFMValues;
    MyDatasetSimcardNo.AfterLoadDFMValues;
    MyDatasetPhoneNo.AfterLoadDFMValues;
    MyDatasetSerialNo.AfterLoadDFMValues;
    MyDatasetReasonDeactivation.AfterLoadDFMValues;
    MyDatasetLastMessage.AfterLoadDFMValues;
    MyDatasetAssetID.AfterLoadDFMValues;
    MyDatasetLicensePlate.AfterLoadDFMValues;
    MyDatasetLatitude.AfterLoadDFMValues;
    MyDatasetLongitude.AfterLoadDFMValues;
    MyDatasetMessageCode.AfterLoadDFMValues;
    MyDatasetGPS.AfterLoadDFMValues;
    MyDatasetSAT.AfterLoadDFMValues;
    MyDatasetGSM.AfterLoadDFMValues;
    MyDatasetSpeed.AfterLoadDFMValues;
    MyDatasetIntBatt.AfterLoadDFMValues;
    MyDatasetExtBatt.AfterLoadDFMValues;
    MyDatasetIOStatus.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    MyConnection.AfterLoadDFMValues;
  end;
end;

end.

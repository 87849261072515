unit uDepartmentEdit;

interface

uses
  System.SysUtils, System.Classes,
  JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLIB.REST, WEBLib.JSON,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls;

type
  TDepartmentEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    DepartmentNameEdit: TEdit;
    UsernameLabel: TLabel;
    StatusBarPanel: TPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    WebListBox1: TListBox;
    MyMessageDlg: TMessageDlg;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure WebFormShow(Sender: TObject);
    procedure RecordChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    function ValidInput(): Boolean; async;
    [async]
    function SaveChanges(): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  DepartmentEditForm: TDepartmentEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TDepartmentEditForm.SetReadOnly();
begin
  // Disabled Buttons
  SaveButton.Visible := False;
end;

procedure TDepartmentEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TDepartmentEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TDepartmentEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
begin
  Result := await(ValidInput());
  if Result then begin
    Result := await(SaveChanges());
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg('Error: Failed to save department', mtError, [mbOK]);
    end;
  end;
end;

function TDepartmentEditForm.ValidInput(): Boolean;
begin
  Result := True;
end;

procedure TDepartmentEditForm.WebFormShow(Sender: TObject);
begin
  DepartmentNameEdit.SetFocus;
end;

function TDepartmentEditForm.SaveChanges(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_department_save.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&name=' + DepartmentNameEdit.Text;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      PKey := StrToInt(MyJO.GetJSONValue('pkey'));
      Result := PKey > 0;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;


procedure TDepartmentEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  DepartmentNameEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  WebListBox1 := TListBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  DepartmentNameEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  WebListBox1.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  try
    Name := 'DepartmentEditForm';
    Width := 640;
    Height := 261;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 640;
    FormPanel.Height := 261;
    FormPanel.HeightPercent := 100.000000000000000000;
    FormPanel.WidthPercent := 100.000000000000000000;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 634;
    ContentPanel.Height := 218;
    ContentPanel.Margins.Top := 0;
    ContentPanel.HeightPercent := 100.000000000000000000;
    ContentPanel.WidthPercent := 100.000000000000000000;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    UsernameLabel.SetParentComponent(ContentPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 20;
    UsernameLabel.Top := 15;
    UsernameLabel.Width := 42;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'Name';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    DepartmentNameEdit.SetParentComponent(ContentPanel);
    DepartmentNameEdit.Name := 'DepartmentNameEdit';
    DepartmentNameEdit.Left := 20;
    DepartmentNameEdit.Top := 50;
    DepartmentNameEdit.Width := 594;
    DepartmentNameEdit.Height := 34;
    DepartmentNameEdit.AutoFocus := True;
    DepartmentNameEdit.ChildOrder := 1;
    DepartmentNameEdit.Font.Charset := ANSI_CHARSET;
    DepartmentNameEdit.Font.Color := clWindowText;
    DepartmentNameEdit.Font.Height := -16;
    DepartmentNameEdit.Font.Name := 'Segoe UI';
    DepartmentNameEdit.Font.Style := [];
    DepartmentNameEdit.HeightPercent := 100.000000000000000000;
    DepartmentNameEdit.ParentFont := False;
    DepartmentNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentNameEdit, Self, 'OnChange', 'RecordChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 169;
    StatusBarPanel.Width := 634;
    StatusBarPanel.Height := 49;
    StatusBarPanel.HeightPercent := 100.000000000000000000;
    StatusBarPanel.WidthPercent := 100.000000000000000000;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderColor := clSilver;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 3;
    StatusBarPanel.Color := clWhite;
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 20;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alLeft;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uDepartmentEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 514;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uDepartmentEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    WebListBox1.SetParentComponent(ContentPanel);
    WebListBox1.Name := 'WebListBox1';
    WebListBox1.Left := 20;
    WebListBox1.Top := 90;
    WebListBox1.Width := 594;
    WebListBox1.Height := 76;
    WebListBox1.HeightPercent := 100.000000000000000000;
    WebListBox1.ItemHeight := 19;
    WebListBox1.ItemIndex := -1;
    WebListBox1.WidthPercent := 100.000000000000000000;
    SetEvent(WebListBox1, Self, 'OnChange', 'RecordChange');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 640;
    CaptionPanel.Height := 40;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 83;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Department';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 611;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uDepartmentEdit.CaptionPanel.CloseImage.Picture.png');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 222;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.HeightPercent := 100.000000000000000000;
    MyMessageDlg.WidthPercent := 100.000000000000000000;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.DialogText.BeginUpdate;
    try
      MyMessageDlg.DialogText.Clear;
      MyMessageDlg.DialogText.Add('Warning');
      MyMessageDlg.DialogText.Add('Error');
      MyMessageDlg.DialogText.Add('Information');
      MyMessageDlg.DialogText.Add('Confirm');
      MyMessageDlg.DialogText.Add('Custom');
      MyMessageDlg.DialogText.Add('OK');
      MyMessageDlg.DialogText.Add('Cancel');
      MyMessageDlg.DialogText.Add('Yes');
      MyMessageDlg.DialogText.Add('No');
      MyMessageDlg.DialogText.Add('Abort');
      MyMessageDlg.DialogText.Add('Retry');
      MyMessageDlg.DialogText.Add('Ignore');
      MyMessageDlg.DialogText.Add('All');
      MyMessageDlg.DialogText.Add('Yes to All');
      MyMessageDlg.DialogText.Add('No to All');
      MyMessageDlg.DialogText.Add('Help');
      MyMessageDlg.DialogText.Add('Close');
    finally
      MyMessageDlg.DialogText.EndUpdate;
    end;
    MyMessageDlg.Opacity := 0.200000000000000000;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    DepartmentNameEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    WebListBox1.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
  end;
end;

end.
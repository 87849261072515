{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGridDataUtil;

interface

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

uses
  Classes, {%H-}Types, SysUtils
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Generics.Collections
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

type
  TTMSFNCGridAutoType = (atNumeric, atFloat, atString, atDate, atTime, atScientific);

  {$IFDEF WEBLIB}
  TTMSFNCGridIntList = class(TList)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridIntList = class(TList<Integer>)
  {$ENDIF}
  private
    procedure SetInteger(Index: Integer; const Value: Integer);
    function GetInteger(Index: Integer):Integer;
    function GetStrValue: string;
    procedure SetStrValue(const Value: string);
  public
    procedure DeleteValue(Value: Integer);
    function HasValue(Value: Integer): Boolean;
    function IndexOfValue(Value: Integer): integer;
    property Items[index: Integer]: Integer read GetInteger write SetInteger; default;
    procedure Add(Value: Integer);
    procedure Insert(Index,Value: Integer);
    procedure Delete(Index: Integer);
    property StrValue: string read GetStrValue write SetStrValue;
  end;

  function IsType(s:string): TTMSFNCGridAutoType;
  function CharPos(ch: Char; const s: string): Integer;
  function NumSingleChar(p:char;s:string):Integer;
  function DoubleToSingleChar(ch: Char;const s:string):string;
  procedure CSVToLineFeeds(var s:string);
  function VarCharPos(ch: Char; const s: string; var Res: Integer): Integer;
  function NumChar(p:char;s:string):Integer;
  function SinglePos(p:char;s:string;var sp: Integer):Integer;
  function GetToken(var s:string;separator:string): string;
  function FileToLf(s:string;multiline:Boolean = True): string;
  function LfToFile(s:string): string;
  function GetNextLine(var s: string;multiline:Boolean = True): string;
  function CSVQuotes(const S: string): string;
  procedure LineFeedsToCSV(var s: string);
  procedure LineFeedsToCSVNQ(var s: string);
  function LinesInText(s: string;multiline:Boolean = True): Integer;
  function HTMLLineBreaks(s:string):string;
  function CRToLF(s: string): string;
  function FixNonBreaking(su: string): string;
  function IsURL(const s:string):boolean;

implementation

uses
  WEBLib.TMSFNCUtils;

const
  LINEFEED = #13;


{ TTMSFNCGridIntList }

procedure TTMSFNCGridIntList.SetInteger(Index:Integer; const Value:Integer);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCGridIntList.GetInteger(Index: Integer): Integer;
begin
  Result := Integer(inherited Items[Index]);
end;

procedure TTMSFNCGridIntList.DeleteValue(Value: Integer);
var
  i: integer;
begin
  i := IndexOf(Value);
  if i <> -1 then
    Delete(i);
end;

function TTMSFNCGridIntList.HasValue(Value: Integer): Boolean;
begin
  Result := IndexOfValue(Value) <> -1;
end;

function TTMSFNCGridIntList.IndexOfValue(Value: Integer): integer;
begin
  Result := IndexOf(Value);
end;

procedure TTMSFNCGridIntList.Add(Value: Integer);
begin
  inherited Add(Value);
end;

procedure TTMSFNCGridIntList.Delete(Index: Integer);
begin
  inherited Delete(Index);
end;

function TTMSFNCGridIntList.GetStrValue: string;
var
  i: integer;
begin
  for i := 1 to Count do
    if i = 1 then
      Result:= IntToStr(Items[i - 1])
    else
      Result := Result + ',' + IntToStr(Items[i - 1]);
end;

procedure TTMSFNCGridIntList.SetStrValue(const Value: string);
var
  sl:TStringList;
  i: Integer;
begin
  sl := TStringList.Create;
  sl.CommaText := Value;
  Clear;
  for i := 1 to sl.Count do
   Add(StrToInt(sl.Strings[i - 1]));
  sl.Free;
end;

procedure TTMSFNCGridIntList.Insert(Index, Value: Integer);
begin
  inherited Insert(Index, Value);
end;

function CRToLF(s:string): string;
var
  i: integer;
  res:string;
begin
  res := '';

  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    if (s[i] <> #13) then
      res := res + s[i]
    else
      res := res + #10;

  Result := res;
end;

function CSVQuotes(const s: string): string;
var
  i: Integer;
begin
  Result := '';
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
  begin
    Result := Result + s[i];
    if s[i]='"' then
      Result := Result + '"';
  end;
end;


function CharPos(ch: Char; const s: string): Integer;
var
  i: Integer;
begin
  Result := 0;
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    if s[i] = ch then
    begin
      Result := i;
      Break;
    end;
end;

function DoubleToSingleChar(ch: Char;const s:string):string;
var
  Res: string;
  i: Integer;
begin
  if (s = '') or (CharPos(ch,s) = 0) then
  begin
    Result := s;
    Exit;
  end;

  res := '';
  {$IFDEF ZEROSTRINGINDEX}
  i   := 0;
  {$ELSE}
  i   := 1;
  {$ENDIF}

  repeat
    Res := Res + s[i];
    // skip dbl quote
    if ((s[i] = ch) and (s[i + 1] = ch)) then
      Inc(i);
    Inc(i);
  until (i > Length(s));

  Result := Res;
end;

procedure CSVToLineFeeds(var s:string);
var
  res: string;
  i: Integer;
begin
  if CharPos(#11,s) = 0 then
    Exit;
  Res := '';
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    if s[i] = #11 then
      Res := Res + #13#10
    else
      Res := Res + s[i];
  s := Res;
end;

function VarCharPos(ch: Char; const s: string; var Res: Integer): Integer;
var
  i: Integer;
begin
  Result := 0;
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    if s[i] = ch then
    begin
      Res := i;
      Result := i;
      Break;
    end;
end;

function NumChar(p:char;s:string):Integer;
var
  Res,vp: Integer;
begin
  Res := 0;
  vp := -1;
  while TTMSFNCUtils.VarPos(p,s,vp) > 0 do
  begin
    Delete(s,1,vp);
    Inc(Res);
  end;
  Result := Res;
end;


function SinglePos(p:char;s:string;var sp: Integer):Integer;
var
  i: Integer;
  QuoteCount: Integer;
begin
  i := 1;
  QuoteCount:= 0;
  while i <= Length(s) do
  begin
    if s[i] = p then
    begin
      if i < Length(s) then
        Inc(QuoteCount)
      else
        if i = Length(s) then
        begin
          Result := i;
          sp := i;
          Exit;
        end;
    end
    else
    begin
      if (Odd(QuoteCount)) then
      begin
        Result := i - 1;
        sp := i - 1;
        Exit;
      end
      else
        QuoteCount := 0;
    end;
    Inc(i);
  end;
  Result := 0;
  sp := 0;
end;

function NumSingleChar(p:char;s:string):Integer;
var
  Res,sp: Integer;
begin
  Res := 0;
  sp := -1;
  while SinglePos(p,s,sp) > 0 do
  begin
    Delete(s,1,sp);
    Inc(Res);
  end;
  Result := Res;
end;

function GetToken(var s:string;separator:string):string;
var
  sp:Integer;
begin
  Result := '';
  sp := Pos(separator,s);
  if sp > 0 then
  begin
    Result := Copy(s,1,sp - 1);
    Delete(s,1,sp);
  end;
end;


function GetNextLine(var s:string;multiline:boolean = True):string;
var
  vp: Integer;
begin
  vp := -1;
  if TTMSFNCUtils.VarPos(LINEFEED,s,vp) > 0 then
  begin
    Result := Copy(s,1,vp-1);
    Delete(s,1,vp);
    if s<>'' then
      if s[1] = #10 then
        Delete(s,1,1);
    if not Multiline then
      s := '';
  end
  else
  begin
    Result := s;
    s := '';
  end;
end;


function Lftofile(s:string):string;
var
  i:Integer;
begin
  if Pos(#13,s) > 0 then
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    begin
      if s[i] = #13 then s[i] := #9;
      if s[i] = #10 then s[i] := #8;
      if s[i] = #0 then s[i] := #1;

    end;
  Result := s;
end;


function FileToLF(s:string;multiline:boolean = true):string;
var
  i:Integer;
begin
  if Pos(#8,s)>0 then
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    begin
      if s[i] = #9 then s[i] := #13;
      if s[i] = #8 then s[i] := #10;
      if s[i] = #1 then s[i] := #0;
    end;
  if not MultiLine then
    Result := GetNextLine(s,multiline)
  else
    Result := s;
end;


procedure LineFeedsToCSV(var s:string);
var
  vp: Integer;
begin
  vp := -1;
  while TTMSFNCUtils.VarPos(#13#10,s,vp)>0 do
  begin
    Delete(s,vp,1);
    s[vp] := #11;
  end;
  s := '"' + s + '"';
end;

procedure LineFeedsToCSVNQ(var s:string);
var
  vp: Integer;
begin
  vp := -1;
  while TTMSFNCUtils.VarPos(#13#10,s,vp)>0 do
    Delete(s,vp,1);
end;

function LinesInText(s:string;multiline:boolean = true):Integer;
var
  vp: Integer;
begin
  Result := 1;
  if not Multiline then
    Exit;

  vp := -1;
  while TTMSFNCUtils.VarPos(LINEFEED,s,vp)>0 do
  begin
    Inc(Result);
    Delete(s,1,vp);
  end;
end;

function IsNumber(ch: char): Boolean;
begin
 Result := Ord(ch) in [$0030..$0039];
end;

function CheckNum(ch:char): boolean;
begin
  Result := IsNumber(ch);
end;

function CheckSignedNum(ch:char): boolean;
begin
  Result := IsNumber(ch) or (ch = '-');
end;


function CheckExpNum(ch:char): boolean;
begin
  Result := IsNumber(ch) or (ch = 'e') or (ch = 'E');
end;


function CheckFloatNum(ch: char): boolean;
begin
  Result := IsNumber(ch) or (ch = FormatSettings.ThousandSeparator) or (ch = FormatSettings.DecimalSeparator) or (ch = 'e') or (ch = 'E');
end;

function CheckSignedDottedNum(ch: char): boolean;
begin
  Result := IsNumber(ch) or (ch = '.') or (ch = '-') or (ch = 'e') or (ch = 'E') or (ch = '+');
end;

function CheckSignedFloatNum(ch: char): boolean;
begin
  Result := CheckFloatNum(ch) or (ch ='+') or (ch = '-');
end;

function IsType(s:string): TTMSFNCGridAutoType;
var
  i: Integer;
  isI,isF,isS: Boolean;
  th,de,mi: Integer;
  isE: boolean;

begin
  Result := atString;
  if s = '' then
    Exit;

  isI := True;
  isF := True;
  isS := True;
  isE := False;

  th := -1;
  de := 0;
  mi := 0;

  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
  begin
    if not CheckSignedNum(s[i]) then
      isI := False;

    if (s[i] = 'e') or (s[i] = 'E') then
    begin
      if isE then
        isS := false;
      isE := true;
    end;

    // - sign must be first char
    if (i > 1) and (s[i] = '-') then
    begin
      isI := false;
      isF := false;
    end;

    if (i = 1) and ((s[i] = 'e') or (s[i] = 'E')) then
      isS := false;

    if (i = length(s)) and ( (s[i] = 'e') or (s[i] = 'E') or (s[i] = '+') or (s[i] = '-') ) then
      isS := false;

    if not (CheckNum(s[i]) or (s[i] = '-') or (s[i] = FormatSettings.ThousandSeparator) or (s[i] = FormatSettings.DecimalSeparator) ) then
      isF := False;

    if not (CheckSignedFloatNum(s[i]) and (s[i] <> FormatSettings.ThousandSeparator)) then
      isS := False;

    if (s[i] = FormatSettings.ThousandSeparator) and (i - th < 4) and (th <> -1) then
    begin
      isF := False;
    end;

    if (s[i] = FormatSettings.ThousandSeparator) then
    begin
      th := i;
    end;

    if (s[i] = FormatSettings.DecimalSeparator) then
      Inc(de);
    if (s[i] = '-') then
      Inc(mi);
  end;

  if isI then
    Result := atNumeric
  else
  begin
    if isF and not isE then
      Result := atFloat;
    if isS and isE then
    begin
      if mi = 2 then
        mi := 1;
      Result := atScientific;
    end;
  end;

  if (mi > 1) or (de > 1) then
    Result := atString;
end;

function HTMLLineBreaks(s:string):string;
var
  i: Integer;
  res: String;
begin
  res := '';

  if Pos(#13,s) = 0 then
    res := s
  else
  {$IFDEF ZEROSTRINGINDEX}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
    begin
      if s[i] <> #13 then
        res := res + s[i]
      else
        res := res + '<br>';
    end;

  Result := res;
end;

function IPos(su,s:string):Integer;
begin
  Result := Pos(UpperCase(su),UpperCase(s));
end;

function TagReplaceString(const Srch,Repl:string;var Dest:string):Boolean;
var
  i: Integer;
begin
  i := IPos(srch,dest);
  if i > 0 then
  begin
    Result := True;
    Delete(Dest,i,Length(Srch));
    Dest := Copy(Dest,1,i-1) + Repl + Copy(Dest,i,Length(Dest));
  end
  else
    Result := False;
end;


function FixNonBreaking(su: string): string;
begin
  while Pos(' ',su) > 0 do
  begin
    TagReplacestring(' ','&nbsp;',su);
  end;
  Result := su;
end;

function IsURL(const s:string):boolean;
var
  su: string;
begin
  su := Uppercase(s);
  Result := (Pos('HTTP://',su) = 1) or
            (Pos('HTTPS://',su) = 1) or
            (Pos('FTP://',su) = 1) or
            (Pos('NNTP://',su) = 1) or
            (Pos('MAILTO:',su) = 1);
end;

end.


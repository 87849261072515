unit uPOIEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, WEBLib.WebCtrls;

type
  TPOIEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    WebBrowserControl: TBrowserControl;
    WebFormTimer: TTimer;
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure WebFormTimerTimer(Sender: TObject); async;
    procedure CloseImageClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    PKey: Int64;
  protected procedure LoadDFMValues; override; end;

var
  POIEditForm: TPOIEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TPOIEditForm.CloseImageClick(Sender: TObject);
begin
  DeleteWaitFlag('POIEdit');
end;

procedure TPOIEditForm.WebFormShow(Sender: TObject);
begin
  // Set Wait Flag
  SetWaitFlag('POIEdit');
  // Show URL
  WebBrowserControl.URL := url_www + 'poi_map.html' +
    '?sessionid=' + SessionID +
    '&pkey=' + IntToStr(PKey);
  // Wait For Close
  WebFormTimer.Enabled := True;
end;

procedure TPOIEditForm.WebFormTimerTimer(Sender: TObject);
var
  Result: Boolean;
  WebFormPKey: Int64;
begin
  WebFormTimer.Enabled := False;
  WebFormTimer.Interval := 100;
  try
    Result := await(GetWaitFlag('POIEdit', WebFormPKey));
  finally
    if Result and (WebFormPKey = -1) then begin
      WebFormTimer.Enabled := True;
    end else begin
      if Result then begin
        PKey := WebFormPKey;
        ModalResult := mrOK;
      end else begin
        ModalResult := mrCancel;
      end;
    end;
  end;
end;

procedure TPOIEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  WebBrowserControl := TBrowserControl.Create('MyPOIEditBrowserControl');
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebFormTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  WebBrowserControl.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebFormTimer.BeforeLoadDFMValues;
  try
    Name := 'POIEditForm';
    Width := 1368;
    Height := 790;
    Align := alClient;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1368;
    FormPanel.Height := 790;
    FormPanel.HeightPercent := 100.000000000000000000;
    FormPanel.WidthPercent := 100.000000000000000000;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1362;
    ContentPanel.Height := 747;
    ContentPanel.Margins.Top := 0;
    ContentPanel.HeightPercent := 100.000000000000000000;
    ContentPanel.WidthPercent := 100.000000000000000000;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    WebBrowserControl.SetParentComponent(ContentPanel);
    WebBrowserControl.Name := 'WebBrowserControl';
    WebBrowserControl.Left := 0;
    WebBrowserControl.Top := 0;
    WebBrowserControl.Width := 1362;
    WebBrowserControl.Height := 747;
    WebBrowserControl.HeightPercent := 100.000000000000000000;
    WebBrowserControl.WidthPercent := 100.000000000000000000;
    WebBrowserControl.Align := alClient;
    WebBrowserControl.ChildOrder := 1;
    WebBrowserControl.Sandbox := [];
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1368;
    CaptionPanel.Height := 40;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 160;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Point Of Interest - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1339;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uPOIEdit.CaptionPanel.CloseImage.Picture.png');
    WebFormTimer.SetParentComponent(Self);
    WebFormTimer.Name := 'WebFormTimer';
    WebFormTimer.Enabled := False;
    SetEvent(WebFormTimer, Self, 'OnTimer', 'WebFormTimerTimer');
    WebFormTimer.Left := 51;
    WebFormTimer.Top := 64;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    WebBrowserControl.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebFormTimer.AfterLoadDFMValues;
  end;
end;

end.

{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCustomGrid;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  {$IFDEF VCLLIB}
  Windows, Messages,
  {$ENDIF}
  {$IFDEF LCLLIB}
  LCLType,
  {$ENDIF}
  Classes, WEBLib.TMSFNCEdit, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCCustomComponent, WEBLib.StdCtrls, WEBLib.TMSFNCColorPicker, WEBLib.TMSFNCImage,
  WEBLib.TMSFNCGridData, WEBLib.TMSFNCGridCell, WEBLib.TMSFNCPopup, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCToolBarPopup,
  Types, WEBLib.TMSFNCTypes, WEBLib.Controls, WEBLib.TMSFNCGridOptions, WEBLib.TMSFNCGridDataUtil, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.ExtCtrls
  {$IFDEF FMXLIB}
  {$WARNINGS OFF}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 28}
  ,FMX.Memo.Types
  {$IFEND}
  {$HINTS ON}
  {$WARNINGS ON}
  ,FMX.DateTimeCtrls, FMX.SpinBox,
  FMX.ListBox, FMX.Memo, FMX.Types
  {$ENDIF}
  {$IFDEF CMNLIB}
  ,ComCtrls, Spin
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,UITypes
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,DateTimePicker
  {$ENDIF}
  ;

const
  TTMSFNCGridDocURL = TTMSFNCBaseDocURL + 'tmsfncuipack/components/ttmsfncgrid/';
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 1; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 5; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.0.1 : Fixed: Issue with combining indexed filtering & sorting
  //          : Fixed: Issue with missing clear filter option in dropdown
  // v1.0.0.2 : Fixed: Issue with setting FixedRows / FixedColumns updating scrollbars
  // v1.0.0.3 : Improved: ControlPosition property to control the position of cell checkbox, radiobutton and bitmap
  //          : Improved: Using Grid.IO.Delimiter for retrieving text through the RowText[] property
  //          : Fixed: Issue with setting fixed font style to [] at designtime
  //          : Fixed: Issue with disappearing combobox when configured with autocompletion in OnGetCellEditorProperties
  //          : Fixed: Issue with grid.RowText[] property on 2nd column in TTMSFNCGrid
  // v1.0.0.4 : Improved: Support for setting RowText[] property
  // v1.0.0.5 : Improved: Column sizing detection sensitivity
  //          : Fixed: Issue with retrieving right fixed column cell
  //          : Fixed: Issue with getting and setting date in inplace datepicker
  //          : Fixed: Issue with setting correct editor type
  //          : Fixed: Issue with TAB key and ENTER key handling
  // v1.0.0.6 : Fixed: Issue with cell scrolling when having fixed right rows / fixed footer rows
  //          : Fixed: Issue with touch-scrolling with non-scrollable grid
  //          : Fixed: Issue clearing cells
  // v1.0.0.7 : Fixed: Issue with using the Fixed property on column level
  // v1.0.0.8 : Fixed: Issue with calculation of bitmaps and fixed grid cells when applying auto-sizing
  // v1.0.1.0 : New: Button support via AddButton, RemoveButton and IsButton
  //          : Fixed: Issue with grouping cells with fixed right columns
  // v1.0.2.0 : New: OnAfterApplyFilter event, event triggered after filtering is applied.
  //          : Fixed: Issue combining sorting/filtering
  // v1.0.2.1 : Fixed: Issue filtering on Android
  // v1.0.2.2 : Fixed: Issue with adding non-data type checkboxes via columns collection
  // v1.0.3.0 : New : Overloads added for LoadFromCSV(), InsertFromCSV() with TEncoding parameter
  // v1.0.3.1 : Fixed : Issue with exporting merged cells to XLS
  // v1.0.3.2 : Fixed : Issue with retrieving the selected row under some conditions
  // v1.0.4.0 : New : Options.Editing.EditWithTags: boolean property added
  // v1.0.4.1 : Fixed : Issue with URL handling when URL.Show = false
  //          : Fixed : Issue with getting/setting checkbox state for ctCheckBox column
  // v1.0.4.2 : Fixed : Issue with applying word-wrapping
  // v1.0.4.3 : Fixed : Issue with copying disjunct selected cells
  // v1.0.4.4 : Fixed : Issue with OnSelectedCell not being called when performing CTRL+A
  // v1.0.4.5 : Fixed : Issue with detecting column and row sizing when scrolled
  // v1.0.4.6 : Fixed : Issue with painting cell borders
  //          : Fixed : Issue with handling enter key for inserting rows at read-only cells
  //          : Fixed : Column calculation incorrectly updated or not updated at all
  //          : Fixed : Issue with calculating text rectangle in Android release mode
  // v1.0.5.0 : New : OnCellComboBoxCloseUp event
  // v1.0.5.1 : Fixed : Issue with readonly cell not selectable with keyboard
  // v1.0.5.2 : Fixed : OnCellComboBoxCloseUp event not exposed
  // v1.0.5.3 : Fixed : Issue with column resizing sensitivity
  // v1.0.5.4 : Fixed : Issue with mousewheel scrolling and merged cells
  // v1.0.5.5 : Fixed : Issue with exception being raised to early when detecting column stretching
  // v1.0.5.6 : Improved : Option to turn of focused border
  // v1.0.5.7 : Fixed : Issue with autocomplete dropdown
  //          : Fixed : Issue with destroying custom inplace editor
  // v1.0.5.8 : Fixed : Issue with custom calc group incorrect index
  // v1.0.5.9 : Fixed : Issue with control has no parent window in VCL when editing
  //          : Fixed : Issue with autosizing rows and columns after moving
  // v1.0.5.10: Fixed : Issue with UTF8 keys not being processed correctly
  // v1.0.5.11: Fixed : Issue with disjunct cell selection mode
  // v1.0.5.12: Fixed : Issue with selection and grid scrolling
  // v1.0.5.13: Fixed : Access violation at designtime when switching column type
  // v1.0.5.14: Fixed : Issue with scrolling and hidden columns
  // v1.0.5.15: Fixed : Issue with drag & drop column size index
  // v1.0.5.16: Fixed : Issue with editor sel start and sel length settings
  //          : New : Added methods LoadXMLFromText, LoadXMLFromFile and LoadXMLFromStream
  // v1.0.5.17: Fixed : Issue with edit control and mouse wheel in TMS WEB Core
  // v1.0.5.18: Improved : Missing Ints property
  //          : Fixed : Issue with etSpinBox editor type not functioning properly in TMS WEB Core
  //          : Fixed : Issue with etMoneyEdit throwing JavaScript error in TMS WEB Core
  //          : Fixed : Issue with etUpperCase editor type not functioning properly in TMS WEB Core
  // v1.0.5.19: Fixed : Issue with leading and trailing spaces in filtering
  //          : Fixed : Issue with hiding certain editors when clicking on the checkbox of a checkbox column in TMS WEB Core
  //          : Fixed : Issue with inserting text before the caret in inplace editor
  // v1.0.5.20: Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.5.21: Fixed : Issue with drawing merged cells out of the cell range
  // v1.0.6.0 : Improved : TDataset Append & Insert in combination with database adapter
  //          : Fixed : Issue when copying/pasting cells from clipboard
  //          : Fixed : Issue with InsertRow not called with correct parameters
  //          : Fixed : Issue with font scaling in FMX
  // v1.0.6.1 : Improved : OnCellEditValidate* events now blocking from leaving inplace editor
  //          : Fixed : Issue with inplace editor size and position in TMS WEB Core
  //          : Fixed : Issue with copy/pasting cells from clipboard
  // v1.0.6.2 : Improved : OnCellEditValidate* events blocked clicking on another cell to cancel editing
  //          : Fixed : Issue editing cell contents on Android when all text is selected
  // v1.0.7.0 : New : ClearSelection method added
  // v1.0.7.1 : Fixed : Issue with StringToColumnStates
  // v1.0.8.0 : New : GlobalFont interface implemented
  //          : New : Update initial look
  //          : New : AutoSize method with padding parameter
  //          : Fixed : Issue with empty cells when sorting numeric
  // v1.0.8.1 : Fixed : Borders with new look
  //          : Fixed : Initializing font size in VCL
  // v1.0.8.2 : Fixed : Issue with inplace editor
  // v1.0.8.3 : Fixed : Issue with Options.ScrollBar not applying changes
  // v1.1.0.0 : New : Rotated text support
  //          : Fixed : Issue with editing merged cells in FMX
  // v1.1.0.1 : Fixed : Issue with LookupInColumn and sorted rows
  // v1.1.0.2 : Fixed : Issue with IgnoreCase in sort operation
  //          : Fixed : Issue with sorting causing miscalculation of scrollbar range
  // v1.1.0.3 : Fixed : Issue with closing combobox dropdownwindow with Return Key
  // v1.1.0.4 : Fixed : Issue with certain keys causing access violations in handling edit controls
  // v1.1.0.5 : Fixed : Issue with OnCellValidate* events being called multiple times when editing & moving the mouse

  SCROLLINGDELAY = 0;
  {$IFDEF LCLLIB}
  SWIPECOUNT = 300;
  DOWNCOUNT = 15;
  {$ELSE}
  {$IFDEF MSWINDOWS}
  SWIPECOUNT = 300;
  DOWNCOUNT = 15;
  {$ENDIF}
  {$IFDEF MACOS}
  {$IFDEF IOS}
  SWIPECOUNT = 300;
  DOWNCOUNT = 200;
  {$ELSE}
  SWIPECOUNT = 300;
  DOWNCOUNT = 200;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF ANDROID}
  SWIPECOUNT = 300;
  DOWNCOUNT = 100;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  SWIPECOUNT = 300;
  DOWNCOUNT = 100;
  {$ENDIF}

resourcestring
  sTMSFNCGridFilterAll = '(All)';

type
  TTMSFNCCustomGrid = class;

  TTMSFNCGridCustomToolBarPopupMode = (tpmActiveCell, tpmHoverCell, tpmHoverNormalCell, tpmNone);

  TTMSFNCGridCustomToolBarPopup = class(TTMSFNCCustomToolBarPopup)
  private
    FGrid: TTMSFNCCustomGrid;
    FGridCell: TTMSFNCGridCellRec;
  protected
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    property Grid: TTMSFNCCustomGrid read FGrid write FGrid;
    property GridCell: TTMSFNCGridCellRec read FGridCell write FGridCell;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  TTMSFNCGridColorPicker = class(TTMSFNCColorPicker)
  end;

  TTMSFNCGridEdit = class(TTMSFNCEdit)
  {$IFDEF VCLLIB}
  private
    procedure WMGetDlgCode(var Message: TWMGetDlgCode); message WM_GETDLGCODE;
  {$ENDIF}
  public
    constructor Create(AOwner: TComponent); override;
  end;
  TTMSFNCGridComboBox = class(TComboBox);
  TTMSFNCGridTrackBar = class(TTrackBar);
  TTMSFNCGridMemo = class(TMemo);

  {$IFDEF FMXLIB}
  TTMSFNCGridDatePicker = class(TDateEdit)
  protected
    procedure KeyDown(var Key: Word; var KeyChar: System.WideChar; Shift: TShiftState); override;
  end;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCGridDatePicker = class(TDateTimePicker);
  {$ENDIF}

  {$IFDEF FMXLIB}
  TTMSFNCGridSpinBox = class(TSpinBox)
  protected
    {$IFDEF FMXLIB}
    function GetDefaultStyleLookupName: string; override;
    {$ENDIF}
  end;
  TTMSFNCGridCaretPosition = TCaretPosition;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCGridSpinBox = class(TSpinEdit);
  TTMSFNCGridCaretPosition = TPoint;
  {$ENDIF}

  TTMSFNCGridProtectedControl = class(TTMSFNCGridEditor);

  TTMSFNCGridOnGetCellClass = procedure(Sender: TObject; ACol, ARow: Integer; var CellClassType: TTMSFNCGridCellClass) of object;
  TTMSFNCGridTopLeftChanged = procedure(Sender: TObject; ATopRow, ALeftCol: Integer) of object;
  TTMSFNCGridOnGetCellData = procedure(Sender: TObject; ACol, ARow: Integer; var CellString: String) of object;
  TTMSFNCGridOnGetCellProperties = procedure(Sender: TObject; ACol, ARow: Integer; Cell: TTMSFNCGridCell) of object;
  TTMSFNCGridOnGetCellMergeInfo = procedure(Sender: TObject; ACol, ARow: Integer; var ABaseCol: Integer; var ABaseRow: Integer; var AColSpan: Integer; var ARowSpan: Integer) of object;
  TTMSFNCGridOnGetCellEditorCustomClassType = procedure(Sender: TObject; ACol, ARow: Integer; var CellEditorCustomClassType: TTMSFNCGridEditorClass) of object;
  TTMSFNCGridOnGetCellEditorType = procedure(Sender: TObject; ACol, ARow: Integer; var CellEditorType: TTMSFNCGridEditorType) of object;
  TTMSFNCGridOnCellEditGetData = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String) of object;
  TTMSFNCGridOnGetCellEditorProperties = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor) of object;
  TTMSFNCGridOnCellEditValidateData = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean) of object;
  TTMSFNCGridOnCellEditSetData = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String) of object;
  TTMSFNCGridOnCellComboCloseUp = procedure(Sender: TObject; ACol, ARow, ItemIndex: Integer; AValue: string) of object;

  TTMSFNCGridOnCellEditGetColor = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor) of object;
  TTMSFNCGridOnCellEditValidateColor = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor; var Allow: Boolean) of object;
  TTMSFNCGridOnCellEditSetColor = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor) of object;

  TTMSFNCGridOnCellEditDone = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor) of object;
  TTMSFNCGridOnCellEditCancel = procedure(Sender: TObject; ACol, ARow: Integer) of object;
  TTMSFNCGridOnCanSizeColumn = procedure(Sender: TObject; ACol: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnCanSizeRow = procedure(Sender: TObject; ARow: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnColumnSize = procedure(Sender: TObject; ACol: Integer; var NewWidth: Single) of object;
  TTMSFNCGridOnRowSize = procedure(Sender: TObject; ARow: Integer; var NewHeight: Single) of object;
  TTMSFNCGridOnColumnSized = procedure(Sender: TObject; ACol: Integer; NewWidth: Single) of object;
  TTMSFNCGridOnRowSized = procedure(Sender: TObject; ARow: Integer; NewHeight: Single) of object;
  TTMSFNCGridOnColumnDragged = procedure(Sender: TObject; FromCol, ToCol: integer) of object;
  TTMSFNCGridOnRowDragged = procedure(Sender: TObject; FromRow, ToRow: integer) of object;
  TTMSFNCGridOnBeforeRowDrop = procedure(Sender: TObject; FromRow: Integer; var ToRow: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnBeforeColumnDrop = procedure(Sender: TObject; FromColumn: Integer; var ToColumn: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnCanSortColumn = procedure(Sender: TObject; ACol: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnColumnSorted = procedure(Sender: TObject; ACol: Integer; Direction: TTMSFNCGridSortDirection) of object;
  TTMSFNCGridOnCellAnchorClick = procedure(Sender: TObject; ACol, ARow: Integer; AAnchor: String) of object;
  TTMSFNCGridOnGetCellReadOnly = procedure(Sender: TObject; ACol, ARow: Integer; var AReadOnly: Boolean) of object;
  TTMSFNCGridOnGetCellLayout = procedure(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState) of object;
  TTMSFNCGridOnCellClick = procedure(Sender: TObject; ACol, ARow: Integer) of object;
  TTMSFNCGridOnGetCellIsFixed = procedure(Sender: TObject; ACol, ARow: Integer; var ACellFixed: Boolean) of object;

  TTMSFNCGridOnGetCellRotation = procedure(Sender: TObject; ACol, ARow: Integer; var Angle: Integer) of object;
  TTMSFNCGridOnGetRowIsBand = procedure(Sender: TObject; ARow: Integer; var ARowBand: Boolean) of object;
  TTMSFNCGridOnCanInsertRow = procedure(Sender: TObject; ARow: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnCanDeleteRow = procedure(Sender: TObject; ARow: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnCanAppendColumn = procedure(Sender: TObject; ACol: Integer; var Allow: Boolean) of object;
  TTMSFNCGridOnCanAppendRow = procedure(Sender: TObject; ARow: Integer; var Allow: Boolean) of object;

  TTMSFNCGridOnInsertRow = procedure(Sender: TObject; ARow: Integer) of object;
  TTMSFNCGridOnDeleteRow = procedure(Sender: TObject; ARow: Integer) of object;
  TTMSFNCGridOnAppendColumn = procedure(Sender: TObject; ACol: Integer) of object;
  TTMSFNCGridOnAppendRow = procedure(Sender: TObject; ARow: Integer) of object;

  TTMSFNCGridCellControlEvent = procedure(Sender: TObject; ACol, ARow: Integer; ACell: TTMSFNCGridCell) of object;

  TTMSFNCGridCellArray = array of array of TTMSFNCGridCell;

  TTMSFNCGridSizeMode = (smColumnSizing, smRowSizing, smPreviousColumnSizing, smPreviousRowSizing);

  TTMSFNCGridDraggingMode = (dmNone, dmRowDragging, dmColumnDragging);

  TTMSFNCGridCellBeforeDraw = procedure(Sender: TObject; ACol, ARow: Integer; AGraphics: TTMSFNCGraphics; var ARect: TRectF;
    var ATextRect: TRectF; var ADrawText: Boolean; var ADrawBackGround: Boolean; var ADrawBorder: Boolean; var AllowDraw: Boolean) of object;

  TTMSFNCGridCellEditBeforeExit = procedure(Sender: TObject; ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var AllowExit: Boolean) of object;

  TTMSFNCGridCellAfterDraw = procedure(Sender: TObject; ACol, ARow: Integer; AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF) of object;

  TTMSFNCGridEditCellStart = (csNone, csStart, csEnd);

  TTMSFNCGridTabKeyMode = (tkmNone, tkmNormal, tkmShift);

  TTMSFNCGridAppearance = class(TPersistent)
  private
    FBandLayout: TTMSFNCGridCellLayout;
    FFocusedLayout: TTMSFNCGridCellLayout;
    FFixedSelectedLayout: TTMSFNCGridCellLayout;
    FNormalLayout: TTMSFNCGridCellLayout;
    FSummaryLayout: TTMSFNCGridCellLayout;
    FOnChange: TNotifyEvent;
    FSelectedLayout: TTMSFNCGridCellLayout;
    FFixedLayout: TTMSFNCGridCellLayout;
    FGroupLayout: TTMSFNCGridCellLayout;
    FProgressLayout: TTMSFNCGridCellProgressLayout;
    FShowFocus: Boolean;
    procedure SetBandLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetFixedLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetFixedSelectedLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetFocusedLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetGroupLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetNormalLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetSelectedLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetSummaryLayout(const Value: TTMSFNCGridCellLayout);
    procedure SetProgressLayout(const Value: TTMSFNCGridCellProgressLayout);
    procedure SetShowFocus(const Value: Boolean);
  protected
    procedure LayoutChanged(Sender: TObject);
    procedure InitializeDefaultLayout; virtual;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property FixedLayout: TTMSFNCGridCellLayout read FFixedLayout write SetFixedLayout;
    property NormalLayout: TTMSFNCGridCellLayout read FNormalLayout write SetNormalLayout;
    property GroupLayout: TTMSFNCGridCellLayout read FGroupLayout write SetGroupLayout;
    property SummaryLayout: TTMSFNCGridCellLayout read FSummaryLayout write SetSummaryLayout;
    property SelectedLayout: TTMSFNCGridCellLayout read FSelectedLayout write SetSelectedLayout;
    property FocusedLayout: TTMSFNCGridCellLayout read FFocusedLayout write SetFocusedLayout;
    property FixedSelectedLayout: TTMSFNCGridCellLayout read FFixedSelectedLayout write SetFixedSelectedLayout;
    property BandLayout: TTMSFNCGridCellLayout read FBandLayout write SetBandLayout;
    property ProgressLayout: TTMSFNCGridCellProgressLayout read FProgressLayout write SetProgressLayout;
    property ShowFocus: Boolean read FShowFocus write SetShowFocus default True;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridAdapter = class(TTMSFNCCustomComponent)
  private
    FBlockAdd: Boolean;
    FGrid: TTMSFNCCustomGrid;
    FActive: boolean;
    procedure SetActive(const Value: boolean);
    procedure SetGrid(const Value: TTMSFNCCustomGrid);
  protected
    function GetInstance: NativeUInt; override;
    function AlternateDisplayBuildUp: Boolean; virtual;
    function CanCancelEdit: Boolean; virtual;
    procedure StartBuild({%H-}ARow: Integer); virtual;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure UpdateBounds; virtual;
    procedure Initialize; virtual;
    procedure ScrollGrid({%H-}ADelta: Integer; {%H-}ABeforeDisplayCells: Boolean = True; {%H-}AScrollOnly: Boolean = False); virtual;
    procedure SelectCell({%H-}ACell: TTMSFNCGridCellRec); virtual;
    procedure GetCellData({%H-}ACol, {%H-}ARow: Integer; var {%H-}ACellData: String); virtual;
    procedure GetCellClass({%H-}ACol, {%H-}ARow: Integer; var {%H-}ACellClassType: TTMSFNCGridCellClass); virtual;
    procedure GetCellProperties({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure GetCellEditorProperties({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor); virtual;
    procedure GetCellEditorType({%H-}ACol, {%H-}ARow: Integer; var {%H-}ACellEditorType: TTMSFNCGridEditorType); virtual;
    procedure GetCellReadOnly({%H-}ACol, {%H-}ARow: Integer; var {%H-}AReadOnly: Boolean); virtual;
    procedure CellEditGetData({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellString: String); virtual;
    procedure CellEditValidateData({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellString: String; var {%H-}Allow: Boolean); virtual;
    procedure CellEditSetData({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}CellString: String); virtual;
    procedure CellEditDone({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor); virtual;
    procedure CellEditCancel({%H-}ACol, {%H-}ARow: Integer); virtual;
    procedure CellBeforeEdit({%H-}ACol, {%H-}ARow: Integer); virtual;
    procedure CellCheckBoxClick({%H-}ACol, {%H-}ARow: Integer; {%H-}ACell: TTMSFNCGridCell); virtual;
    procedure CellBeforeEditExit({%H-}ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var {%H-}AllowExit: Boolean); virtual;
    procedure ExportNotification({%H-}AState: TTMSFNCGridExportState; {%H-}ARow: Integer); virtual;
    function GetColumnDisplayName({%H-}ACol: Integer): String; virtual;
  public
    constructor Create(AOwner: TComponent); override;
  published
    property Active: Boolean read FActive write SetActive default False;
    property Grid: TTMSFNCCustomGrid read FGrid write SetGrid;
  end;

  TTMSFNCCustomGrid = class(TTMSFNCGridData)
  private
    FToolBarPopupCell: TTMSFNCGridCellRec;
    FDummyButtonBitmap: TTMSFNCBitmap;
    FBlockUpdateAdapter: Integer;
    FBlockAdd: Boolean;
    FKeyUsed: Boolean;
    FTimeStart, FTimeStop: Double;
    FAnimating: Boolean;
    FAnimateVerticalPos, FAnimateHorizontalPos: Boolean;
    FScrollVTo, FScrollHTo: Double;
    FAnimateTimer, FFreeEditControlTimer: TTimer;
    FDoubleSelection: Boolean;
    FSpX, FSpY: Double;
    FScrollX, FScrollY: Double;
    FCommentPopup: TTMSFNCPopup;
    FDoTouchScroll: Boolean;
    FFilterPopup: TTMSFNCPopup;
    FActiveComment: String;
    FActiveCommentTextColor: TTMSFNCGraphicsColor;
    FNodeOpenBitmap: TTMSFNCBitmap;
    FNodeClosedBitmap: TTMSFNCBitmap;
    FCellKeyDown: Boolean;
    FCellPosX, FCellPosY: Integer;
    FContainerWidth, FContainerHeight: Single;
    FClearMouseDown: Boolean;
    ChangeCursor: Boolean;
    OldCursor: TCursor;
    FBlockHide: Boolean;
    FTabKey: TTMSFNCGridTabKeyMode;
    FTopr, FLeftC: Integer;
    FDblClick: Boolean;
    FSaveTopCell: TTMSFNCGridCell;
    FPrevSelection: TTMSFNCGridCellRecRange;
    FCellAnchor: TTMSFNCGridCellRec;
    FCellAnchorString: string;
    FLookupString: string;
    FDraggingMode: TTMSFNCGridDraggingMode;
    FDragBitmap: TTMSFNCImage;
    FDragFixed, FTouchScrolling: Boolean;
    FMouseX, FMouseY: Double;
    FDragCell, FDragScrollCell: TTMSFNCGridCellRec;
    FEditCell: TTMSFNCGridCellRec;
    FEditControl: TTMSFNCGridEditor;
    FCellEdit: TTMSFNCGridEdit;
    FCellComboBox: TTMSFNCGridComboBox;
    FRealCell: TTMSFNCGridCellRec;
    FX, FY, FPrevX, FPrevY, FSizeX, FSaveDragX, FSaveDragY,
    FSizeY, FDragX, FDragY, FDownX, FDownY, FTouchX, FTouchY: Single;
    FScrollTimer, FDragTimer: TTimer;
    FPrevSelectedCell: TTMSFNCGridCellRec;
    FFirstCell, FSaveCell: TTMSFNCGridCellRec;
    FMouseDown,FMouseDblClick, FMouseUp, FMouseScrollingUp, FMouseSizing, FSizing: Boolean;
    FSizeMode: TTMSFNCGridSizeMode;
    FMouseDragging: Boolean;
    FCStart, FRStart, FCStop, FRStop: Integer;
    FOldTopRow: Integer;
    FReset: Boolean;
    FCellArray: TTMSFNCGridCellArray;
    FOnGetCellClass: TTMSFNCGridOnGetCellClass;
    FOnGetCellData: TTMSFNCGridOnGetCellData;
    FFreezeRows: Integer;
    FFreezeColumns: Integer;
    FOnGetCellMergeInfo: TTMSFNCGridOnGetCellMergeInfo;
    FOnCellEditSetData: TTMSFNCGridOnCellEditSetData;
    FOnCellEditDone: TTMSFNCGridOnCellEditDone;
    FOnGetCellEditorCustomClassType: TTMSFNCGridOnGetCellEditorCustomClassType;
    FOnCellEditValidateData: TTMSFNCGridOnCellEditValidateData;
    FOnCellEditGetData: TTMSFNCGridOnCellEditGetData;
    FOnGetCellEditorProperties: TTMSFNCGridOnGetCellEditorProperties;
    FOnCellRadioButtonClick: TTMSFNCGridCellControlEvent;
    FOnCellCheckBoxClick: TTMSFNCGridCellControlEvent;
    FOnCellBitmapClick: TTMSFNCGridCellControlEvent;
    FOnCellCommentClick: TTMSFNCGridCellControlEvent;
    FOnCellSortClick: TTMSFNCGridCellControlEvent;
    FOnRowSized: TTMSFNCGridOnRowSized;
    FOnColumnSized: TTMSFNCGridOnColumnSized;
    FOnCanSizeRow: TTMSFNCGridOnCanSizeRow;
    FOnCanSizeColumn: TTMSFNCGridOnCanSizeColumn;
    FOnCanDragRow: TTMSFNCGridOnCanSizeRow;
    FOnCanDragColumn: TTMSFNCGridOnCanSizeColumn;
    FOnColumnDragged: TTMSFNCGridOnColumnDragged;
    FOnRowDragged: TTMSFNCGridOnRowDragged;
    FOnRowSize: TTMSFNCGridOnRowSize;
    FOnColumnSize: TTMSFNCGridOnColumnSize;
    FOnCellNodeClick: TTMSFNCGridCellControlEvent;
    FOnCanSortColumn: TTMSFNCGridOnCanSortColumn;
    FOnColumnSorted: TTMSFNCGridOnColumnSorted;
    FOnCellAnchorClick: TTMSFNCGridOnCellAnchorClick;
    FOnCellClick: TTMSFNCGridOnCellClick;
    FOnFixedCellClick: TTMSFNCGridOnCellClick;
    FOnCellRightClick: TTMSFNCGridOnCellClick;
    FOnFixedCellRightClick: TTMSFNCGridOnCellClick;
    FOnGetCellReadOnly: TTMSFNCGridOnGetCellReadOnly;
    FOnGetCellLayout: TTMSFNCGridOnGetCellLayout;
    FOnGetCellIsFixed: TTMSFNCGridOnGetCellIsFixed;
    FOnGetCellEditorType: TTMSFNCGridOnGetCellEditorType;
    FCellSpinBox: TTMSFNCGridSpinBox;
    FCellColorPicker: TTMSFNCGridColorPicker;
    FCellDatePicker: TTMSFNCGridDatePicker;
    FCellTrackBar: TTMSFNCGridTrackBar;
    FOnCellEditGetColor: TTMSFNCGridOnCellEditGetColor;
    FOnCellEditSetColor: TTMSFNCGridOnCellEditSetColor;
    FOnCellEditValidateColor: TTMSFNCGridOnCellEditValidateColor;
    FOnGetRowIsBand: TTMSFNCGridOnGetRowIsBand;
    FOnCanDeleteRow: TTMSFNCGridOnCanDeleteRow;
    FOnCanAppendRow: TTMSFNCGridOnCanAppendRow;
    FOnCanAppendColumn: TTMSFNCGridOnCanAppendColumn;
    FOnCanInsertRow: TTMSFNCGridOnCanInsertRow;
    FOnDeleteRow: TTMSFNCGridOnDeleteRow;
    FOnAppendRow: TTMSFNCGridOnAppendRow;
    FOnAppendColumn: TTMSFNCGridOnAppendColumn;
    FOnInsertRow: TTMSFNCGridOnInsertRow;
    FOnCellAfterDraw: TTMSFNCGridCellAfterDraw;
    FOnCellBeforeDraw: TTMSFNCGridCellBeforeDraw;
    FOnFixedCellDropDownButtonClick: TTMSFNCGridCellControlEvent;
    FEditing: boolean;
    FFilterListBox: TListbox;
    FFilterTimer: TTimer;
    FOnTopLeftChanged: TTMSFNCGridTopLeftChanged;
    FOnCellEditCancel: TTMSFNCGridOnCellEditCancel;
    FCellMemo: TTMSFNCGridMemo;
    FOnBeforeRowDrop: TTMSFNCGridOnBeforeRowDrop;
    FOnBeforeColumnDrop: TTMSFNCGridOnBeforeColumnDrop;
    FOnGetCellProperties: TTMSFNCGridOnGetCellProperties;
    FAppearance: TTMSFNCGridAppearance;
    FOnBeforeCellEditExit: TTMSFNCGridCellEditBeforeExit;
    FAdapter: TTMSFNCGridAdapter;
    FOnCellButtonClick: TTMSFNCGridCellControlEvent;
    FToolBarPopupMode: TTMSFNCGridCustomToolBarPopupMode;
    FToolBarPopup: TTMSFNCGridCustomToolBarPopup;
    FOnCellComboCloseUp: TTMSFNCGridOnCellComboCloseUp;
    FOnInternalCellEditDone: TTMSFNCGridOnCellEditDone;
    FDesignTimeSampleData: Boolean;
    FOnFixedCellDblClick: TTMSFNCGridOnCellClick;
    FOnCellDblClick: TTMSFNCGridOnCellClick;
    FOnGetCellRotation: TTMSFNCGridOnGetCellRotation;
    procedure SetFreezeColumns(const Value: Integer);
    procedure SetFreezeRows(const Value: Integer);
    procedure SetLeftCol(const Value: Integer);
    procedure SetTopRow(const Value: Integer);
    procedure SetC(const Value: TCursor);
    function GetLeftCol: Integer;
    function GetTopRow: Integer;
    function GetCellMemo: TTMSFNCGridMemo;
    function GetCellEdit: TTMSFNCGridEdit;
    function GetCellSpinBox: TTMSFNCGridSpinBox;
    function GetCellColorPicker: TTMSFNCGridColorPicker;
    function GetCellDatePicker: TTMSFNCGridDatePicker;
    function GetCellTrackBar: TTMSFNCGridTrackBar;
    function GetCellComboBox: TTMSFNCGridComboBox;
    function GetC: TCursor;
    procedure SetAppearance(const Value: TTMSFNCGridAppearance);
    procedure SetAdapter(const Value: TTMSFNCGridAdapter);
    function GetBottomRow: Integer;
    function GetRightCol: Integer;
    function GetPrevFocusedCell: TTMSFNCGridCellRec;
    procedure SetDesignTimeSampleData(const Value: Boolean);
  protected
    procedure Animate(Sender: TObject);
    procedure DoFreeEditControl(Sender: TObject);
    procedure ApplyStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure ResetToDefaultStyle; override;
    procedure BeforeExport; override;
    procedure AfterExport; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure SetRowSelect(Row: integer; const Value: boolean); override;
    {$IFDEF FMXLIB}
    function FindPopup(AControl: TComponent): TPopup;
    procedure EditApplyStyleLookup(Sender: TObject);
    {$ENDIF}
    function GetColumnDisplayName({%H-}ACol: Integer): string; override;
    function GetCellAppearance(AState: TTMSFNCGridCellState; {%H-}ACol, ARow: Integer): TTMSFNCGridCellLayout;
    function GetPageScrollSize: Integer;
    function GetColumnViewPortSize: Single;
    function GetRowViewPortSize: Single;
    function GetVersion: string; override;
    function GetDocURL: string; override;
    function GetHorizontalContentCount: Integer; override;
    function GetHorizontalContentViewPortSize: Double; override;
    function GetVerticalContentCount: Integer; override;
    function GetVerticalContentViewPortSize: Double; override;
    function GetHorizontalPos(AStartCol, AStopCol: Integer): Single;
    function GetVerticalPos(AStartRow, AStopRow: Integer): Single;
    function DoIsCellSelected(ACol, ARow: Integer): Boolean; override;
    function DoIsFixedCellSelected(ACol, ARow: Integer): Boolean;
    function GetDefaultFixedLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultNormalLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultGroupLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultSummaryLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultSelectedLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultFocusedLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultFixedSelectedLayout: TTMSFNCGridCellLayout; override;
    function GetDefaultBandLayout: TTMSFNCGridCellLayout; override;
    function GetNodeBitmap(AState: TTMSFNCGridNodeState): TTMSFNCBitmap;
    function GetDummyButtonBitmap(ACell: TTMSFNCButtonGridCell): TTMSFNCBitmap; virtual;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure AppearanceChanged(Sender: TObject);
    procedure DoPaintCommentPopup(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure ClearFixedSorting(ACell: TTMSFNCGridCell);
    procedure HandleMouseLeave; override;
    procedure HandleDblClick(X, Y: Single); override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseWheel(Shift: TShiftState; WheelDelta: Integer; var Handled: Boolean); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    {$IFDEF LCLLIB}
    procedure HandleUTF8KeyPress(var {%$H-}UTF8Key: TUTF8Char); override;
    {$ELSE}
    procedure HandleKeyPress(var Key: Char); override;
    {$ENDIF}
    procedure HandleDialogKey(var Key: Word; Shift: TShiftState); override;
    procedure HandleFilterListClick(Sender: TObject);
    {$IFDEF FMXLIB}
    procedure ApplyFilterListBoxStyleLookUp(Sender: TObject);
    {$ENDIF}
    procedure HandleFilterTimer(Sender: TObject);
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure DoCellKeyDown(ACol, ARow: Integer; var Key: Word; {%H-}Shift: TShiftState);
    procedure DoCellKeyUp(ACol, ARow: Integer; var Key: Word; {%H-}Shift: TShiftState);
    procedure ProcessTab(var Key: Word; Shift: TShiftState; Mode: TTMSFNCGridTabKeyDirection; Handling: TTMSFNCGridTabKeyHandling; DirectEdit: Boolean; CellStart: TTMSFNCGridEditCellStart = csNone);
    procedure UpdateCursor(ACursor: TCursor);
    procedure DoExit; override;
    procedure DoEnter; override;
    procedure StopAnimationTimer; override;
    procedure UpdateControlCache; override;
    procedure UpdateControlDisplay; override;
    procedure VerticalScrollPositionChanged; override;
    procedure HorizontalScrollPositionChanged; override;
    function HideEdit(AEnterKey: Boolean = False; ATestHiding: Boolean = False): Boolean; virtual;
    procedure CellControlKeyDown(Sender: TObject; var Key: Word; {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState); virtual;
    procedure CellEditKeyDown(Sender: TObject; var Key: Word; {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState); virtual;
    procedure CellExit(Sender: TObject); virtual;
    procedure CellEditBtnKeyDown(Sender: TObject; var Key: Word; {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState); virtual;
    procedure CellComboBoxKeyDown(Sender: TObject; var Key: Word; {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState); virtual;
    procedure CellComboBoxChange(Sender: TObject); virtual;
    procedure CellComboBoxCloseUp(Sender: TObject); virtual;
    procedure ScrollTimer(Sender: TObject);
    procedure DragTimer(Sender: TObject);
    procedure AutoSizeColumnInt(ACol: Integer; Accurate: Boolean = True; const Padding: Integer = 5);
    procedure AutoSizeRowInt(ARow: integer; const Padding: Integer = 5);

    procedure DoCheckBoxClick(Sender: TObject);
    procedure DoRadioButtonClick(Sender: TObject);
    procedure DoButtonClick(Sender: TObject);
    procedure DoNodeClick(Sender: TObject);
    procedure DoBitmapClick(Sender: TObject);
    procedure DoCommentClick(Sender: TObject);

    procedure DoFixedDropDownButtonClick(Sender: TObject);

    procedure DoCellAnchorClick(ACol, ARow: Integer; AAnchor: String); override;
    procedure DoCanSizeColumn(ACol: Integer; var Allow: Boolean); override;
    procedure DoCanSizeRow(ARow: Integer; var Allow: Boolean); override;
    procedure DoColumnSize(ACol: Integer; var NewWidth: Single); override;
    procedure DoRowSize(ARow: Integer; var NewHeight: Single); override;
    procedure DoColumnSized(ACol: Integer; NewWidth: Single); override;
    procedure DoRowSized(ARow: Integer; NewHeight: Single); override;
    procedure DoCanSortColumn(ACol: Integer; var Allow: Boolean); override;
    procedure DoColumnSorted(ACol: Integer; Direction: TTMSFNCGridSortDirection); override;
    procedure DoCanDragColumn(ACol: integer; var Allow: Boolean); virtual;
    procedure DoCanDragRow(ARow: integer; var Allow: Boolean); virtual;
    procedure DoColumnDragged(FromColumn, ToColumn: integer); virtual;
    procedure DoRowDragged(FromRow, ToRow: integer); virtual;
    procedure DoBeforeCellEditExit(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var AllowExit: Boolean); virtual;
    procedure DoBeforeColumnDrop(FromColumn: Integer; var ToColumn: integer; var Allow: Boolean); virtual;
    procedure DoBeforeRowDrop(FromRow: Integer; var ToRow: integer; var Allow: Boolean); virtual;

    procedure DoFixedCellDropDownButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;

    procedure DoCellCheckBoxClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure DoCellRadioButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure DoCellButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure DoGetCellIsFixed(ACol, ARow: Integer; var ACellFixed: Boolean); override;
    procedure DoGetRowIsBand(ARow: Integer; var ARowBand: Boolean); override;
    procedure DoCellBitmapClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure DoCellCommentClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure DoCellSortClick(Shift: TShiftState; ACol, ARow: Integer; Direction: TTMSFNCGridSortDirection; ACell: TTMSFNCGridCell); override;
    procedure DoCellNodeClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure DoInsertRow(ARow: Integer); override;
    procedure DoDeleteRow(ARow: Integer); override;
    procedure DoAppendRow(ARow: Integer); override;
    procedure DoAppendColumn(ACol: Integer); override;

    procedure DoCanInsertRow(ARow: Integer; var Allow: Boolean); override;
    procedure DoCanDeleteRow(ARow: Integer; var Allow: Boolean); override;
    procedure DoCanAppendRow(ARow: Integer; var Allow: Boolean); override;
    procedure DoCanAppendColumn(ACol: Integer; var Allow: Boolean); override;

    procedure DoCellRightClick(ACol, ARow: Integer); override;
    procedure DoFixedCellRightClick(ACol, ARow: Integer); override;
    procedure DoCellClick(ACol, ARow: Integer); override;
    procedure DoFixedCellClick(Shift: TShiftState; ACol, ARow: Integer); override;
    procedure DoCellDblClick(ACol, ARow: Integer); override;
    procedure DoFixedCellDblClick(ACol, ARow: Integer); override;

    procedure DoGetCellClass(ACol, ARow: Integer; var CellClassType: TTMSFNCGridCellClass); override;
    procedure DoGetCellData(ACol, ARow: Integer; var CellString: String); override;
    procedure DoGetCellRotation(ACol, ARow: Integer; var Angle: Integer); override;
    procedure DoGetCellLayout(ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState); override;
    procedure DoGetCellProperties(ACol, ARow: Integer; Cell: TTMSFNCGridCell); override;
    procedure DoGetCellMergeInfo(ACol, ARow: Integer; var ABaseCol: Integer; var ABaseRow: Integer; var AColSpan: Integer; var ARowSpan: Integer); override;
    procedure DoGetCellEditorCustomClassType(ACol, ARow: Integer; var CellEditorCustomClassType: TTMSFNCGridEditorClass); override;
    procedure DoGetCellEditorType(ACol, ARow: Integer; var CellEditorType: TTMSFNCGridEditorType); override;
    procedure DoCellEditGetData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String); override;
    procedure DoCellEditValidateData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean); override;
    procedure DoCellEditSetData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String); override;
    procedure DoCellComboCloseUp(ACol, ARow, ItemIndex: integer; Value: string);

    procedure DoCellEditGetColor(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor); override;
    procedure DoCellEditValidateColor(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor; var Allow: Boolean); override;
    procedure DoCellEditSetColor(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor); override;

    procedure DoCellEditDone(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor); override;
    procedure DoCellEditCancel(ACol, ARow: Integer);
    procedure DoGetCellEditorProperties(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor); override;

    procedure DoGetCellReadOnly(ACol, ARow: Integer; var AReadOnly: Boolean); override;

    procedure DoBeforeDrawCell(Sender: TObject; AGraphics: TTMSFNCGraphics; var ARect: TRectF;
    var ATextRect: TRectF; var ADrawText: Boolean; var ADrawBackGround: Boolean; var ADrawBorder: Boolean; var AllowDraw: Boolean); virtual;
    procedure DoAfterDrawCell(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF); virtual;

    procedure DoBeforeDrawGridCell(ACol, ARow: Integer; AGraphics: TTMSFNCGraphics; var ARect: TRectF;
      var ATextRect: TRectF; var ADrawText: Boolean; var ADrawBackGround: Boolean; var ADrawBorder: Boolean; var AllowDraw: Boolean); virtual;
    procedure DoAfterDrawGridCell(ACol, ARow: Integer; AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF); virtual;

    property Adapter: TTMSFNCGridAdapter read FAdapter write SetAdapter;
    property TopRow: Integer read GetTopRow write SetTopRow;
    property BottomRow: Integer read GetBottomRow;
    property LeftCol: Integer read GetLeftCol write SetLeftCol;
    property RightCol: Integer read GetRightCol;
    property FreezeColumns: Integer read FFreezeColumns write SetFreezeColumns default 0;
    property FreezeRows: Integer read FFreezeRows write SetFreezeRows default 0;
    property OnTopLeftChanged: TTMSFNCGridTopLeftChanged read FOnTopLeftChanged write FOnTopLeftChanged;
    property OnGetCellClass: TTMSFNCGridOnGetCellClass read FOnGetCellClass write FOnGetCellClass;
    property OnGetCellData: TTMSFNCGridOnGetCellData read FOnGetCellData write FOnGetCellData;
    property OnGetCellProperties: TTMSFNCGridOnGetCellProperties read FOnGetCellProperties write FOnGetCellProperties;
    property OnGetCellLayout: TTMSFNCGridOnGetCellLayout read FOnGetCellLayout write FOnGetCellLayout;
    property OnGetCellMergeInfo: TTMSFNCGridOnGetCellMergeInfo read FOnGetCellMergeInfo write FOnGetCellMergeInfo;
    property OnGetCellReadOnly: TTMSFNCGridOnGetCellReadOnly read FOnGetCellReadOnly write FOnGetCellReadOnly;
    property OnGetRowIsBand: TTMSFNCGridOnGetRowIsBand read FOnGetRowIsBand write FOnGetRowIsBand;
    property OnGetCellRotation: TTMSFNCGridOnGetCellRotation read FOnGetCellRotation write FOnGetCellRotation;

    property FilterListbox: TListBox read FFilterListBox;
    property OnCanInsertRow: TTMSFNCGridOnCanInsertRow read FOnCanInsertRow write FOnCanInsertRow;
    property OnCanAppendRow: TTMSFNCGridOnCanAppendRow read FOnCanAppendRow write FOnCanAppendRow;
    property OnCanAppendColumn: TTMSFNCGridOnCanAppendColumn read FOnCanAppendColumn write FOnCanAppendColumn;
    property OnCanDeleteRow: TTMSFNCGridOnCanDeleteRow read FOnCanDeleteRow write FOnCanDeleteRow;

    property OnInsertRow: TTMSFNCGridOnInsertRow read FOnInsertRow write FOnInsertRow;
    property OnAppendRow: TTMSFNCGridOnAppendRow read FOnAppendRow write FOnAppendRow;
    property OnAppendColumn: TTMSFNCGridOnAppendColumn read FOnAppendColumn write FOnAppendColumn;
    property OnDeleteRow: TTMSFNCGridOnDeleteRow read FOnDeleteRow write FOnDeleteRow;

    property OnCellAnchorClick: TTMSFNCGridOnCellAnchorClick read FOnCellAnchorClick write FOnCellAnchorClick;

    property OnGetCellEditorCustomClassType: TTMSFNCGridOnGetCellEditorCustomClassType read FOnGetCellEditorCustomClassType write FOnGetCellEditorCustomClassType;
    property OnGetCellEditorType: TTMSFNCGridOnGetCellEditorType read FOnGetCellEditorType write FOnGetCellEditorType;
    property OnCellEditGetData: TTMSFNCGridOnCellEditGetData read FOnCellEditGetData write FOnCellEditGetData;
    property OnCellEditValidateData: TTMSFNCGridOnCellEditValidateData read FOnCellEditValidateData write FOnCellEditValidateData;
    property OnCellEditSetData: TTMSFNCGridOnCellEditSetData read FOnCellEditSetData write FOnCellEditSetData;
    property OnCellComboCloseUp: TTMSFNCGridOnCellComboCloseUp read FOnCellComboCloseUp write FOnCellComboCloseUp;

    property OnCellEditGetColor: TTMSFNCGridOnCellEditGetColor read FOnCellEditGetColor write FOnCellEditGetColor;
    property OnCellEditValidateColor: TTMSFNCGridOnCellEditValidateColor read FOnCellEditValidateColor write FOnCellEditValidateColor;
    property OnCellEditSetColor: TTMSFNCGridOnCellEditSetColor read FOnCellEditSetColor write FOnCellEditSetColor;

    property OnInternalCellEditDone: TTMSFNCGridOnCellEditDone read FOnInternalCellEditDone write FOnInternalCellEditDone;
    property OnCellEditDone: TTMSFNCGridOnCellEditDone read FOnCellEditDone write FOnCellEditDone;
    property OnCellEditCancel: TTMSFNCGridOnCellEditCancel read FOnCellEditCancel write FOnCellEditCancel;
    property OnGetCellEditorProperties: TTMSFNCGridOnGetCellEditorProperties read FOnGetCellEditorProperties write FOnGetCellEditorProperties;
    property OnGetCellIsFixed: TTMSFNCGridOnGetCellIsFixed read FOnGetCellIsFixed write FOnGetCellIsFixed;

    property OnFixedCellDropDownButtonClick: TTMSFNCGridCellControlEvent read FOnFixedCellDropDownButtonClick write FOnFixedCellDropDownButtonClick;

    property OnCellBeforeDraw: TTMSFNCGridCellBeforeDraw read FOnCellBeforeDraw write FOnCellBeforeDraw;
    property OnBeforeCellEditExit: TTMSFNCGridCellEditBeforeExit read FOnBeforeCellEditExit write FOnBeforeCellEditExit;
    property OnCellAfterDraw: TTMSFNCGridCellAfterDraw read FOnCellAfterDraw write FOnCellAfterDraw;
    property OnCellBitmapClick: TTMSFNCGridCellControlEvent read FOnCellBitmapClick write FOnCellBitmapClick;
    property OnCellRadioButtonClick: TTMSFNCGridCellControlEvent read FOnCellRadioButtonClick write FOnCellRadioButtonClick;
    property OnCellButtonClick: TTMSFNCGridCellControlEvent read FOnCellButtonClick write FOnCellButtonClick;
    property OnCellCheckBoxClick: TTMSFNCGridCellControlEvent read FOnCellCheckBoxClick write FOnCellCheckBoxClick;
    property OnCellCommentClick: TTMSFNCGridCellControlEvent read FOnCellCommentClick write FOnCellCommentClick;
    property OnCellSortClick: TTMSFNCGridCellControlEvent read FOnCellSortClick write FOnCellSortClick;
    property OnCellNodeClick: TTMSFNCGridCellControlEvent read FOnCellNodeClick write FOnCellNodeClick;

    property OnCanSizeColumn: TTMSFNCGridOnCanSizeColumn read FOnCanSizeColumn write FOnCanSizeColumn;
    property OnCanSizeRow: TTMSFNCGridOnCanSizeRow read FOnCanSizeRow write FOnCanSizeRow;
    property OnColumnSize: TTMSFNCGridOnColumnSize read FOnColumnSize write FOnColumnSize;
    property OnRowSize: TTMSFNCGridOnRowSize read FOnRowSize write FOnRowSize;
    property OnColumnSized: TTMSFNCGridOnColumnSized read FOnColumnSized write FOnColumnSized;
    property OnRowSized: TTMSFNCGridOnRowSized read FOnRowSized write FOnRowSized;

    property OnCanDragColumn: TTMSFNCGridOnCanSizeColumn read FOnCanDragColumn write FOnCanDragColumn;
    property OnCanDragRow: TTMSFNCGridOnCanSizeRow read FOnCanDragRow write FOnCanDragRow;
    property OnColumnDragged: TTMSFNCGridOnColumnDragged read FOnColumnDragged write FOnColumnDragged;
    property OnRowDragged: TTMSFNCGridOnRowDragged read FOnRowDragged write FOnRowDragged;
    property OnBeforeColumnDrop: TTMSFNCGridOnBeforeColumnDrop read FOnBeforeColumnDrop write FOnBeforeColumnDrop;
    property OnBeforeRowDrop: TTMSFNCGridOnBeforeRowDrop read FOnBeforeRowDrop write FOnBeforeRowDrop;

    property OnColumnSorted: TTMSFNCGridOnColumnSorted read FOnColumnSorted write FOnColumnSorted;
    property OnCanSortColumn: TTMSFNCGridOnCanSortColumn read FOnCanSortColumn write FOnCanSortColumn;

    property OnCellClick: TTMSFNCGridOnCellClick read FOnCellClick write FOnCellClick;
    property OnFixedCellClick: TTMSFNCGridOnCellClick read FOnFixedCellClick write FOnFixedCellClick;

    property OnCellRightClick: TTMSFNCGridOnCellClick read FOnCellRightClick write FOnCellRightClick;
    property OnFixedCellRightClick: TTMSFNCGridOnCellClick read FOnFixedCellRightClick write FOnFixedCellRightClick;

    property OnCellDblClick: TTMSFNCGridOnCellClick read FOnCellDblClick write FOnCellDblClick;
    property OnFixedCellDblClick: TTMSFNCGridOnCellClick read FOnFixedCellDblClick write FOnFixedCellDblClick;

    property Appearance: TTMSFNCGridAppearance read FAppearance write SetAppearance;
    property PrevFocusedCell: TTMSFNCGridCellRec read GetPrevFocusedCell;

    property ToolBarPopup: TTMSFNCGridCustomToolBarPopup read FToolBarPopup write FToolBarPopup;
    property ToolBarPopupMode: TTMSFNCGridCustomToolBarPopupMode read FToolBarPopupMode write FToolBarPopupMode default tpmActiveCell;

    property DesignTimeSampleData: Boolean read FDesignTimeSampleData write SetDesignTimeSampleData nodefault;
    procedure DisplayCellsAlternate(x, y, {%H-}xOrig, {%H-}yOrig: Single; CStart, CStop, RStart, RStop, Ci, Ri: Integer; {%H-}ResetCi: Integer; {%H-}Fixed: Boolean = False);
    procedure DisplayCellsNormal(x, y, {%H-}xOrig, {%H-}yOrig: Single; CStart, CStop, RStart, RStop, Ci, Ri: Integer; {%H-}ResetCi: Integer; {%H-}Fixed: Boolean = False);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function VisibleRowCount: Integer;
    function VisibleColumnCount: Integer;
    function GetDisplayCell(ACell: TTMSFNCGridCellRec): TTMSFNCGridCellRec;
    function GetRealCell(ACell: TTMSFNCGridCellRec): TTMSFNCGridCellRec;
    function GetCellObject(ACell: TTMSFNCGridCellRec): TTMSFNCGridCell;
    function GetCell(ACell: TTMSFNCGridCellRec): TTMSFNCGridCell;
    function GetCellByObject(Cell: TTMSFNCGridCell): TTMSFNCGridCellRec;
    function GetDisplayCellByObject(Cell: TTMSFNCGridCell): TTMSFNCGridCellRec;
    function GetTotalColumnWidth: Single;
    function GetTotalRowHeight: Single;
    function GetTotalContentWidth: Double; override;
    function GetTotalContentHeight: Double; override;
    procedure EndUpdate; override;
    procedure RemoveAllCells; virtual;
    procedure ClearSelection; virtual;
    procedure ExportNotification(AState: TTMSFNCGridExportState; ARow: Integer); override;

    procedure ApplyCellState(ACol, ARow: Integer; Cell: TTMSFNCGridCell);
    function GetCellState(ACol, ARow: Integer): TTMSFNCGridCellState; override;
    function XYToCell(X, Y: Single): TTMSFNCGridCellRec;

    function GetFixedCols: Integer;
    function GetFixedRows: Integer;
    function GetFixedWidth: Single;
    function GetFixedOnlyHeight: Single;
    function GetFixedOnlyWidth: Single;
    function GetFixedHeight: Single;
    function GetFixedRightWidth: Single;
    function GetFixedFooterHeight: Single;
    function GetCellContainerPosX: Integer;
    function GetCellContainerPosY: Integer;
    function ColumnStretchingActive: Boolean; override;

    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;

    procedure Assign(Source: TPersistent); override;
    procedure EditCell(ACell: TTMSFNCGridCellRec; CellStart: TTMSFNCGridEditCellStart = csNone; InsertKey: Boolean = False; Value: string = ''; {%H-}Key: Word = 0); virtual;
    procedure CancelEdit;
    procedure StopEdit; virtual;
    procedure NextPage;
    procedure PreviousPage;

    procedure LoadSettingsFromFile(AFileName: string); override;
    procedure LoadSettingsFromStream(AStream: TStreamEx); override;

    procedure Navigate(ACell: TTMSFNCGridCellRec; ForceScroll: Boolean = False);
    procedure UpdateControlAfterResize; override;

    procedure AutoSizeGrid(Accurate: Boolean; const PaddingCol: Integer = 5; const PaddingRow: Integer = 5);
    procedure AutoSizeColumn(ACol: Integer; Accurate: Boolean = True; const Padding: Integer = 5);
    procedure AutoSizeColumns(Accurate: Boolean = True; const Padding: Integer = 5);
    procedure AutoSizeRow(ARow: integer; const Padding: Integer = 5);
    procedure AutoSizeRows(const Padding: Integer = 5);
//    procedure AutoSizeCells(const DoFixedCells: Boolean; const PaddingX,PaddingY: Integer);
//    procedure AutoGrowColumns(const DoFixedCols: Boolean; const Padding: Integer = 4);
//    procedure AutoGrowCol(const ACol: Integer; const Padding: Integer = 0);
    procedure StretchColumn(ACol: Integer; ANewWidth: Single = -1);

    procedure SelectCell(Cell: TTMSFNCGridCellRec; Shift: TShiftState = []; MouseDragging: Boolean = False); override;
    procedure UpdateGridCells(AScrollOnly: Boolean = False); override;
    procedure UpdateControlScroll(AHorizontalPos, AVerticalPos, ANewHorizontalPos, ANewVerticalPos: Double); override;
    procedure UpdateGridCellDisplay;
    procedure DoUpdateGridCellDisplay(ASelection: TTMSFNCGridCellRecRange);
    procedure PaintCells(AGraphics: TTMSFNCGraphics; CStart, CStop, RStart, RStop, Ci, Ri, ResetCi: Integer);
    procedure DisplayCells(x, y, {%H-}xOrig, yOrig: Single; CStart, CStop, RStart, RStop, Ci, Ri: Integer; ResetCi: Integer; {%H-}Fixed: Boolean = False);

    procedure InitSample; virtual;
    procedure LoadSampleData; virtual;

    property Editing: boolean read FEditing;
    property Cursor: TCursor read GetC write SetC;
    property CellEdit: TTMSFNCGridEdit read GetCellEdit write FCellEdit;
    property CellMemo: TTMSFNCGridMemo read GetCellMemo write FCellMemo;
    property CellComboBox: TTMSFNCGridComboBox read GetCellComboBox write FCellComboBox;
    property CellSpinBox: TTMSFNCGridSpinBox read GetCellSpinBox write FCellSpinBox;
    property CellColorPicker: TTMSFNCGridColorPicker read GetCellColorPicker write FCellColorPicker;
    property CellDatePicker: TTMSFNCGridDatePicker read GetCellDatePicker write FCellDatePicker;
    property CellTrackBar: TTMSFNCGridTrackBar read GetCellTrackBar write FCellTrackBar;
    property ActiveEditControl: TTMSFNCGridEditor read FEditControl write FEditControl;
  end;

{$IFDEF WEBLIB}
const
  TMSFNCGRIDCELLNODEOPEN = 'data:image/PNG;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlY'+
                           'WR5ccllPAAAARJJREFUeNpi/P//P0NcX2oCAwNDJhCbMRAGp4B4+qKi2QsYY3tT6hTFVRrdTQIYZETkCep88u'+
                           'Yhw84zGxjuv7xTzwSyMdo5mUFRQpGBlYWJIAapA6kH6WMBEhIifAIMpAB2VrB6CSYIhwkrLqzrxSkHAiCbGVi'+
                           'ZmXDagk8OrJnhPx434pEDa/77DyGQUdmJoiC1HMGf0V6OqfnPH4TAlGaEgpzaThQ+sjq45p+//+F0Gj45sObv'+
                           'P3/jVIBPDqz587cfWCXLczNwysE0P3329rk0Nwcf0Ynk649PIOops767Eefrjy+dBLllGP79YwT68Q9e/OHrR'+
                           '4ZD1zYxfP7xvosRmqvqoLlKggiLX0BzVRNAgAEAJkJ1yu0zs+kAAAAASUVORK5CYII=';
  TMSFNCGRIDCELLNODECLOSED = 'data:image/PNG;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJ'+
                             'lYWR5ccllPAAAAOxJREFUeNpi/P//P0NcX2oCAwNDJhCbMRAGp4B4+qKi2QsYY3tT6hTFVRrdTQIYZETkCe'+
                             'p88uYhw84zGxjuv7xTzwSyMdo5mUFRQpGBlYWJIAapA6kH6WMBEhIifAIMpAB2VrB6CRYIh4mBHADWzMpMg'+
                             'WaG/wzka/77DyGQUdmJU/GM9nJMzX/+IASmNJfj1IysDq755+9/5Dv7+8/f5Gv+/O0H2ZqfPnv7XJqbg49o'+
                             'TV9/fAJRT5n13Y04X3986STILcPw7x8j0P9/8OIPXz8yHLq2ieHzj/ddjNBcVQfNVRJEWPwCmquaAAIMAGY'+
                             'uZD5GuUOeAAAAAElFTkSuQmCC';
{$ENDIF}

implementation

uses
  WEBLib.TMSFNCCustomScrollControl, WEBLib.TMSFNCUtils, SysUtils, WEBLib.TMSFNCColorSelector,
  Math, WEBLib.Graphics, WEBLib.Forms, WEBLib.TMSFNCStyles, WEBLib.Dialogs
  {$IFNDEF WEBLIB}
  ,Variants
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Styles.Objects, FMX.Edit.Style
  {$ENDIF}
  ;

{$R TMSFNCGridCell.res}

function GetTickCountX: DWORD;
var
  h, m, s, ms: Word;
begin
  DecodeTime(Now, h, m, s, ms);
  Result := ms + s * 1000 + m * 60 * 1000 + h * 60 * 60 * 1000;
end;

function AnimateDouble(var Start, Stop: Double; Delta, Margin: Double): Boolean;
begin
  Result := true;
  if (Start > Stop - Margin) and (Start < Stop + Margin) then
  begin
    Start := Stop;
    Result := false;
  end
  else
  begin
    Delta := Max(Margin, Delta);
    if Start < Stop then
      Start := Start + Delta
    else
      Start := Start - Delta;
  end;
end;

function PtInR(const Rect: TRectF; const P: TPointF): Boolean;
begin
  Result := (P.X >= Rect.Left) and (P.X < Rect.Right) and (P.Y >= Rect.Top)
    and (P.Y < Rect.Bottom);
end;

{ TTMSFNCGridCustomToolBarPopup }

constructor TTMSFNCGridCustomToolBarPopup.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TTMSFNCCustomGrid) then
      begin
        Grid := AOwner.Components[i] as TTMSFNCCustomGrid;
        Grid.ToolBarPopup := Self;
        Break;
      end;
    end;
  end;
end;

procedure TTMSFNCGridCustomToolBarPopup.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = Grid) then
    Grid := nil;
end;

{ TTMSFNCCustomGrid }

procedure TTMSFNCCustomGrid.RemoveAllCells;
var
  c, r: Integer;
begin
  for c := 0 to Length(FCellArray) - 1 do
  begin
    for r := 0 to Length(FCellArray[c]) - 1 do
    begin
      if Assigned(FCellArray[c, r]) then
      begin
        FCellArray[c, r].Free;
        FCellArray[c, r] := nil;
      end;
    end;
  end;
  SetLength(FCellArray, 0);
end;

procedure TTMSFNCCustomGrid.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
  I, J: Integer;
  cp: TTMSFNCGridCellProperty;
begin
  inherited;
  BeginUpdate;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
    Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    Stroke.Color := c;
    Appearance.FixedLayout.Stroke.Color := c;
    Appearance.FixedSelectedLayout.Stroke.Color := c;
    Appearance.SelectedLayout.Stroke.Color := c;
    Appearance.NormalLayout.Stroke.Color := c;
    Appearance.FocusedLayout.Stroke.Color := c;
    Appearance.BandLayout.Stroke.Color := c;
    Appearance.GroupLayout.Stroke.Color := c;
    Appearance.SummaryLayout.Stroke.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColor(c) then
  begin
    Appearance.FixedLayout.Fill.Kind := gfkSolid;
    Appearance.FixedLayout.Fill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColorTo(c) then
  begin
    Appearance.FixedLayout.Fill.Kind := gfkGradient;
    Appearance.FixedLayout.Fill.ColorTo := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    Appearance.FixedSelectedLayout.Fill.Color := c;
    Appearance.FocusedLayout.Fill.Color := c;
    Appearance.SelectedLayout.Fill.Color := c;
    Appearance.GroupLayout.Fill.Color := c;
    Appearance.SummaryLayout.Fill.Color := c;
    Appearance.ProgressLayout.Color := c;
    Appearance.FixedSelectedLayout.Fill.Kind := gfkSolid;
    Appearance.FocusedLayout.Fill.Kind := gfkSolid;
    Appearance.SelectedLayout.Fill.Kind := gfkSolid;
    Appearance.GroupLayout.Fill.Kind := gfkSolid;
    Appearance.SummaryLayout.Fill.Kind := gfkSolid;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColorTo(c) then
  begin
    Appearance.FixedSelectedLayout.Fill.ColorTo := c;
    Appearance.FocusedLayout.Fill.ColorTo := c;
    Appearance.SelectedLayout.Fill.ColorTo := c;
    Appearance.GroupLayout.Fill.ColorTo := c;
    Appearance.SummaryLayout.Fill.ColorTo := c;
    Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    Appearance.GroupLayout.Fill.Kind := gfkGradient;
    Appearance.SummaryLayout.Fill.Kind := gfkGradient;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    DefaultFont.Color := c;
    Appearance.FixedLayout.Font.Color := c;
    Appearance.NormalLayout.Font.Color := c;
    Appearance.FixedSelectedLayout.Font.Color := c;
    Appearance.SelectedLayout.Font.Color := c;
    Appearance.FocusedLayout.Font.Color := c;
    Appearance.GroupLayout.Font.Color := c;
    Appearance.SummaryLayout.Font.Color := c;
  end;

  RemoveAllCells;

  for i := 0 to RowList.Count - 1 do
  begin
    if Assigned(RowList[i]) then
    begin
      for j := 0 to RowList[i].Data.Count - 1 do
      begin
        cp := TTMSFNCGridCellProperty(RowList[i].Data.Objects[j]);
        if Assigned(cp) then
          cp.FontColor := c;
      end;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomGrid.ResetToDefaultStyle;
begin
  inherited;
  Appearance.InitializeDefaultLayout;

  Appearance.ShowFocus := True;
  {$IFDEF FMXLIB}
  Appearance.FixedLayout.Fill.Color := $FFEEF2F9;
  Appearance.FixedLayout.Font.Color := $FF454545;
  Appearance.NormalLayout.Fill.Color := $FFF6F8FC;
  Appearance.NormalLayout.Font.Color := $FF7A7A7A;
  Appearance.SelectedLayout.Fill.Color := $FFBBDEFA;
  Appearance.SelectedLayout.Font.Color := $FF454545;
  Appearance.FocusedLayout.Fill.Color := $FF74BDF4;
  Appearance.FocusedLayout.Font.Color := $FF454545;
  Appearance.BandLayout.Fill.Color := $FFFFFFFE;
  Appearance.BandLayout.Font.Color := $FF7A7A7A;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  Appearance.FixedLayout.Fill.Color := $F9F2EE;
  Appearance.FixedLayout.Font.Color := $454545;
  Appearance.NormalLayout.Fill.Color := $FCF8F6;
  Appearance.NormalLayout.Font.Color := $7A7A7A;
  Appearance.SelectedLayout.Fill.Color := $FADEBB;
  Appearance.SelectedLayout.Font.Color := $454545;
  Appearance.FocusedLayout.Fill.Color := $F4BD74;
  Appearance.FocusedLayout.Font.Color := $454545;
  Appearance.BandLayout.Fill.Color := $FEFFFF;
  Appearance.BandLayout.Font.Color := $7A7A7A;
  {$ENDIF}
  Appearance.SelectedLayout.Stroke.Color := gcSilver;
  Appearance.FocusedLayout.Stroke.Color := gcSilver;

  Appearance.NormalLayout.Stroke.Color := gcSilver;
  Appearance.NormalLayout.Stroke.Kind := gskSolid;

  Appearance.FixedLayout.Fill.Kind := gfkSolid;
  Appearance.FixedLayout.Stroke.Color := gcSilver;
  Appearance.FixedLayout.Stroke.Kind := gskSolid;
  Appearance.FixedLayout.Font.Style := [TFontStyle.fsBold];
  TTMSFNCUtils.SetFontSize(Appearance.FixedLayout.Font, 14);

  Appearance.FixedSelectedLayout.Font.Color := gcBlack;
  Appearance.FixedSelectedLayout.Stroke.Color := gcSilver;
  Appearance.FixedSelectedLayout.Fill.Kind := gfkSolid;

  Appearance.SelectedLayout.Fill.Kind := gfkSolid;

  Appearance.BandLayout.Stroke.Kind := gskSolid;
  Appearance.BandLayout.Stroke.Color := gcSilver;

  Appearance.GroupLayout.Font.Color := gcBlack;
  Appearance.GroupLayout.Fill.Kind := gfkSolid;

  Appearance.SummaryLayout.Fill.Kind := gfkSolid;
  Appearance.SummaryLayout.Font.Color := gcBlack;

  Appearance.FocusedLayout.Fill.Kind := gfkSolid;
  Appearance.FocusedLayout.Stroke.Kind := gskSolid;

  Fill.Color := gcWhite;
  Stroke.Color := gcSilver;

  RemoveAllCells;
  UpdateGridCells;
end;

procedure TTMSFNCCustomGrid.AfterExport;
begin
  inherited;
  UpdateControl;
end;

procedure TTMSFNCCustomGrid.Animate(Sender: TObject);
var
  dx, dy, posx, posy: Double;
  animh, animv: Boolean;
begin
  posy := GetVScrollValue;
  posx := GetHScrollValue;
  dx := Abs(FScrollHTo - posx) / Max(1, Abs(FSpX) * 6);
  dy := Abs(FScrollVTo - posy) / Max(1, Abs(FSpY) * 6);
  animv := False;
  if FAnimateVerticalPos then
    animv := AnimateDouble(posy, FScrollVTo, dy, 0.01);

  animh := False;
  if FAnimateHorizontalPos then
    animh := AnimateDouble(posx, FScrollHTo, dx, 0.01);

  FAnimating := animv or animh;
  if FAnimating then
    Scroll(posx, posy)
  else
  begin
    FAnimateVerticalPos := False;
    FAnimateTimer.Enabled := False;
    FAnimateHorizontalPos := False;
  end;
end;

procedure TTMSFNCCustomGrid.AppearanceChanged(Sender: TObject);
begin
  UpdateControl;
end;

procedure TTMSFNCCustomGrid.ApplyCellState(ACol, ARow: Integer; Cell: TTMSFNCGridCell);
var
  cl: TTMSFNCGridCell;
  ctl: TTMSFNCFixedGridCell;
  idx: Integer;
begin
  if (Cell is TTMSFNCGridCell) then
  begin
    cl := cell as TTMSFNCGridCell;

    if Assigned(cl) then
    begin
      if (Options.Selection.Mode <> smNone) and ((FocusedCell.Row = ARow) and (FocusedCell.Col = ACol)) and IsFocused then
        cl.AssignLayout(GetCellAppearance(csFocused, ACol, ARow))
      else
      begin
        if IsFixed(ACol, ARow) then
        begin
          if (Options.Selection.Mode <> smNone) and Options.Selection.ShowSelectionInFixedCells and DoIsFixedCellSelected(ACol, ARow) and not IsNormalFixed(ACol, ARow) then
            cl.AssignLayout(GetCellAppearance(csFixedSelected, ACol, ARow))
          else
            cl.AssignLayout(GetCellAppearance(csFixed, ACol, ARow));
        end
        else if (Options.Selection.Mode <> smNone) and DoIsCellSelected(ACol,ARow) then
          cl.AssignLayout(GetCellAppearance(csSelected, ACol, ARow))
        else
          cl.AssignLayout(GetCellAppearance(csNormal, ACol, ARow));
      end;

      if IsFixed(ACol, ARow) then
      begin
        case Options.Borders.FixedCellBorders of
          bNone: cl.Layout.Sides := [];
          bVertical: cl.Layout.Sides := cl.Layout.Sides - [gsTop, gsBottom];
          bHorizontal: cl.Layout.Sides := cl.Layout.Sides - [gsLeft, gsRight];
        end;
      end
      else
      begin
        case Options.Borders.CellBorders of
          bNone: cl.Layout.Sides := [];
          bVertical: cl.Layout.Sides := cl.Layout.Sides - [gsTop, gsBottom];
          bHorizontal: cl.Layout.Sides := cl.Layout.Sides - [gsLeft, gsRight];
        end;
      end;

      if (cl is TTMSFNCFixedGridCell) and (ARow = Options.Sorting.Row) then
      begin
        ctl := cl as TTMSFNCFixedGridCell;
        ctl.SortIndex := -1;
        ctl.SortKind := skNone;

        case Options.Sorting.Mode of
          gsmNone: ctl.SortKind := skNone;
          gsmNormal:
          begin
            if ACol = SortColumn then
            begin
              case SortDirection of
                TTMSFNCGridSortDirection.sdAscending: ctl.SortKind := skAscending;
                TTMSFNCGridSortDirection.sdDescending: ctl.SortKind := skDescending;
              end;
            end;
          end;
          gsmIndexed:
          begin
            idx := SortIndexes.FindIndex(ACol);
            if idx <> -1 then
            begin
              ctl.SortIndex := idx + 1;
              if SortIndexes.SortDirections[idx] then
                ctl.SortKind := skAscending
              else
                ctl.SortKind := skDescending;
            end;
          end;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomGrid) then
  begin
    Appearance.Assign((Source as TTMSFNCCustomGrid).Appearance);
    Options.Assign((Source as TTMSFNCCustomGrid).Options);
    ColumnCount := (Source as TTMSFNCCustomGrid).ColumnCount;
    RowCount := (Source as TTMSFNCCustomGrid).RowCount;
    FixedColumns := (Source as TTMSFNCCustomGrid).FixedColumns;
    FixedRows := (Source as TTMSFNCCustomGrid).FixedRows;
    FixedRightColumns := (Source as TTMSFNCCustomGrid).FixedRightColumns;
    FixedFooterRows := (Source as TTMSFNCCustomGrid).FixedFooterRows;
    FreezeColumns := (Source as TTMSFNCCustomGrid).FreezeColumns;
    FreezeRows := (Source as TTMSFNCCustomGrid).FreezeRows;
    BitmapContainer := (Source as TTMSFNCCustomGrid).BitmapContainer;
    Enabled := (Source as TTMSFNCCustomGrid).Enabled;
    DefaultColumnWidth := (Source as TTMSFNCCustomGrid).DefaultColumnWidth;
    DefaultRowHeight := (Source as TTMSFNCCustomGrid).DefaultRowHeight;
    LeftCol := (Source as TTMSFNCCustomGrid).LeftCol;
    TopRow := (Source as TTMSFNCCustomGrid).TopRow;
  end;
end;

procedure TTMSFNCCustomGrid.AutoSizeColumn(ACol: Integer; Accurate: Boolean = True; const Padding: Integer = 5);
begin
  AutoSizeColumnInt(ACol, Accurate, Padding);
  UpdateControl;
end;

procedure TTMSFNCCustomGrid.AutoSizeColumnInt(ACol: Integer; Accurate: Boolean = True; const Padding: Integer = 5);
var
  i, k: integer;
  cell: TTMSFNCGridCell;
  cellclasstype,prevclasstype: TTMSFNCGridCellClass;
  cellstr: string;
  m,nw: single;
  tw: Single;
  ctl: TTMSFNCGridCell;
  l: TTMSFNCGridCellLayout;
  bmp: TTMSFNCBitmapHelperClass;
  totalh: Single;
  rs: Integer;
  c: TTMSFNCGridCellRec;
  a: Integer;
begin
  l := TTMSFNCGridCellLayout.Create;
  m := 0;
  nw := 0;

  Cell := nil;
  prevclasstype := nil;

  if not Accurate then
  begin
    prevclasstype := TTMSFNCGridCell;
    cell := TTMSFNCGridCell.Create(Self);
    cell.ScaleFactor := PaintScaleFactor;
    DoGetCellLayout(FixedColumns, FixedRows, cell.Layout, csNormal);
    cell.Width := 10000;
    cell.Height := 10000;
    (Cell as TTMSFNCGridCell).Text := 'g';
    nw := (Cell as TTMSFNCGridCell).GetTextWidth;
  end;

  for i := 0 to RowCount - 1 do
  begin
    if IsXMergedCell(ACol, i) then
      Continue;

    cellclasstype := nil;
    DoGetCellClass(ACol,  i, cellclasstype);
    cellstr := '';
    DoGetCellData(ACol, i, cellstr);

    if (prevclasstype <> cellclasstype) and (prevclasstype <> nil) and Assigned(cell) then
      cell.Free;

    if (prevclasstype <> cellclasstype) then
    begin
      cell := cellclasstype.Create(Self);

      if (cell is TTMSFNCGridCell) then
      begin
        (cell as TTMSFNCGridCell).BitmapContainer := BitmapContainer;
        (cell as TTMSFNCGridCell).OptimizedHTMLDrawing := OptimizedHTMLDrawing;
      end;
    end;

    cell.ScaleFactor := PaintScaleFactor;

    prevclasstype := cellclasstype;

    if (cell is TTMSFNCGridCell) then
    begin
      a := 0;
      DoGetCellRotation(ACol, i, a);
      (cell as TTMSFNCGridCell).Angle := a;

      (cell as TTMSFNCGridCell).Text := cellstr;
      (cell as TTMSFNCGridCell).DisplayHTMLFormatting := Options.Cell.DisplayHTMLFormatting;
      (cell as TTMSFNCGridCell).Width := ColumnWidths[ACol];
      (cell as TTMSFNCGridCell).Height := RowHeights[i];
    end;

    if cell is TTMSFNCProgressGridCell then
    begin
      (cell as TTMSFNCProgressGridCell).Format := Appearance.ProgressLayout.Format;
      (cell as TTMSFNCProgressGridCell).TextColor := Appearance.ProgressLayout.TextColor;
      (cell as TTMSFNCProgressGridCell).Color := Appearance.ProgressLayout.Color;
      (cell as TTMSFNCProgressGridCell).ShowText := Appearance.ProgressLayout.ShowText;
    end;

    if cell is TTMSFNCCheckGridCell then
      (cell as TTMSFNCCheckGridCell).ControlBitmap := GetCheckBoxBitmap((cell as TTMSFNCCheckGridCell).Checked, False);

    if cell is TTMSFNCRadioGridCell then
      (cell as TTMSFNCRadioGridCell).ControlBitmap := GetRadioButtonBitmap((cell as TTMSFNCRadioGridCell).Checked, False);

    if cell is TTMSFNCNodeGridCell then
      (cell as TTMSFNCNodeGridCell).ControlBitmap := GetNodeBitmap((cell as TTMSFNCNodeGridCell).State);

    if cell is TTMSFNCButtonGridCell then
      (cell as TTMSFNCButtonGridCell).ControlBitmap := GetDummyButtonBitmap(cell as TTMSFNCButtonGridCell);

    ApplyCellState(ACol, i, cell);

    if (Cell is TTMSFNCGridCell) then
    begin
      l.Assign((Cell as TTMSFNCGridCell).Layout);
      DoGetCellLayout(ACol, i, l, GetCellState(ACol, i));
      (Cell as TTMSFNCGridCell).AssignLayout(l);
    end;

    DoGetCellProperties(ACol, i, cell);

    totalh := 0;
    c := MakeCell(ACol, i);
    c := BaseCell(c.Col, c.Row);
    rs := RowSpan(c.Col, c.Row);

    if rs > 0 then
    begin
      for K := c.Row to c.Row + rs - 1 do
        totalh := totalh + RowHeights[K];
    end
    else
      totalh := RowHeights[c.Row];

    if (cell is TTMSFNCGridCell) then
    begin
      ctl := (cell as TTMSFNCGridCell);
      ctl.Width := 10000;
      ctl.Height := totalh - 4;
      if not Accurate then
      begin
        tw := Length(ctl.GetStrippedHTMLText) * nw;
        bmp := ctl.ControlBitmap;
        if Assigned(bmp) and not IsBitmapEmpty(bmp) then
          tw := tw + bmp.Width;

        if ctl is TTMSFNCFixedGridCell then
        begin
          if (ctl as TTMSFNCFixedGridCell).ShowDropDownButton then
            nw := nw + 19;
          if (ctl as TTMSFNCFixedGridCell).SortKind <> skNone then
            nw := nw + 16;
        end;

        if tw > m then
          m := tw;
      end
      else
      begin
        nw := ctl.GetTextWidth;
        bmp := ctl.ControlBitmap;
        if Assigned(bmp) and not IsBitmapEmpty(bmp) then
          nw := nw + bmp.Width;

        if ctl is TTMSFNCFixedGridCell then
        begin
          if (ctl as TTMSFNCFixedGridCell).ShowDropDownButton then
            nw := nw + 19;
          if (ctl as TTMSFNCFixedGridCell).SortKind <> skNone then
            nw := nw + 16;
        end;

        if nw > m then
          m := nw;
      end;
    end;
  end;

  if Assigned(cell) then
    cell.Free;

  ColumnWidths[ACol] := m + Padding;
  l.Free;
end;

procedure TTMSFNCCustomGrid.AutoSizeColumns(Accurate: Boolean = True; const Padding: Integer = 5);
var
  i: integer;
begin
  for i := 0 to ColumnCount - 1 do
    AutoSizeColumn(i, Accurate, Padding);

  UpdateControl;
end;

procedure TTMSFNCCustomGrid.AutoSizeGrid(Accurate: Boolean; const PaddingCol: Integer = 5; const PaddingRow: Integer = 5);
begin
  AutoSizeColumns(Accurate, PaddingCol);
  AutoSizeRows(PaddingRow);
end;

procedure TTMSFNCCustomGrid.AutoSizeRow(ARow: integer; const Padding: Integer = 5);
begin
  AutoSizeRowInt(ARow, Padding);
  UpdateControl;
end;

procedure TTMSFNCCustomGrid.AutoSizeRowInt(ARow: integer; const Padding: Integer = 5);
var
  i, k: integer;
  cell: TTMSFNCGridCell;
  cellclasstype, prevclasstype: TTMSFNCGridCellClass;
  cellstr: string;
  m,nw: single;
  ctl: TTMSFNCGridCell;
  l: TTMSFNCGridCellLayout;
  bmp: TTMSFNCBitmapHelperClass;
  c: TTMSFNCGridCellRec;
  totalw: Single;
  cs: Integer;
  a: Integer;
begin
  l := TTMSFNCGridCellLayout.Create;
  m := 0;
  prevclasstype := nil;
  cell := nil;

  for i := 0 to ColumnCount - 1 do
  begin
    if IsYMergedCell(i, ARow) then
      Continue;

    cellclasstype := nil;
    DoGetCellClass(i, ARow, cellclasstype);
    cellstr := '';
    DoGetCellData(i, ARow, cellstr);

    if (prevclasstype <> cellclasstype) and (prevclasstype <> nil) and Assigned(cell) then
      cell.Free;

    if (prevclasstype <> cellclasstype) then
    begin
      cell := cellclasstype.Create(Self);
      if (cell is TTMSFNCGridCell) then
      begin
        (cell as TTMSFNCGridCell).Layout := nil;
        (cell as TTMSFNCGridCell).BitmapContainer := BitmapContainer;
        (cell as TTMSFNCGridCell).OptimizedHTMLDrawing := OptimizedHTMLDrawing;
      end;
    end;

    cell.ScaleFactor := PaintScaleFactor;

    prevclasstype := cellclasstype;

    if (cell is TTMSFNCGridCell) then
    begin
      a := 0;
      DoGetCellRotation(i, ARow, a);
      (cell as TTMSFNCGridCell).Angle := a;
      (cell as TTMSFNCGridCell).Text := cellstr;
      (cell as TTMSFNCGridCell).DisplayHTMLFormatting:= Options.Cell.DisplayHTMLFormatting;
      (cell as TTMSFNCGridCell).Width := ColumnWidths[i];
      (cell as TTMSFNCGridCell).Height := RowHeights[ARow];
    end;

    if cell is TTMSFNCProgressGridCell then
    begin
      (cell as TTMSFNCProgressGridCell).Format := Appearance.ProgressLayout.Format;
      (cell as TTMSFNCProgressGridCell).TextColor := Appearance.ProgressLayout.TextColor;
      (cell as TTMSFNCProgressGridCell).Color := Appearance.ProgressLayout.Color;
      (cell as TTMSFNCProgressGridCell).ShowText := Appearance.ProgressLayout.ShowText;
    end;

    if cell is TTMSFNCCheckGridCell then
      (cell as TTMSFNCCheckGridCell).ControlBitmap := GetCheckBoxBitmap((cell as TTMSFNCCheckGridCell).Checked, False);

    if cell is TTMSFNCRadioGridCell then
      (cell as TTMSFNCRadioGridCell).ControlBitmap := GetRadioButtonBitmap((cell as TTMSFNCRadioGridCell).Checked, False);

    if cell is TTMSFNCNodeGridCell then
      (cell as TTMSFNCNodeGridCell).ControlBitmap := GetNodeBitmap((cell as TTMSFNCNodeGridCell).State);

    if cell is TTMSFNCButtonGridCell then
      (cell as TTMSFNCButtonGridCell).ControlBitmap := GetDummyButtonBitmap(cell as TTMSFNCButtonGridCell);

    ApplyCellState(i, ARow, cell);
    if (Cell is TTMSFNCGridCell) then
    begin
      l.Assign((Cell as TTMSFNCGridCell).Layout);
      DoGetCellLayout(i, ARow, l, GetCellState(i, ARow));
      (Cell as TTMSFNCGridCell).AssignLayout(l);
    end;

    DoGetCellProperties(i, ARow, cell);

    totalw := 0;
    c := MakeCell(i, ARow);
    c := BaseCell(c.Col, c.Row);
    cs := ColSpan(c.Col, c.Row);

    if cs > 0 then
    begin
      for K := c.Col to c.Col + cs - 1 do
        totalw := totalw + ColumnWidths[K];
    end
    else
      totalw := ColumnWidths[c.Col];

    if (cell is TTMSFNCGridCell) then
    begin
      ctl := (cell as TTMSFNCGridCell);
      ctl.Width := totalw - 4;
      ctl.Height := 10000;
      nw := ctl.GetTextHeight;

      bmp := ctl.ControlBitmap;
      if Assigned(bmp) and not IsBitmapEmpty(bmp) then
      begin
        if bmp.Height > nw then
          nw := bmp.Height;
      end;

      if ctl is TTMSFNCFixedGridCell then
      begin
        if (ctl as TTMSFNCFixedGridCell).ShowDropDownButton then
        begin
          if 15 > nw then
            nw := 15;
        end;
        if (ctl as TTMSFNCFixedGridCell).SortKind <> skNone then
        begin
          if 12 > nw then
            nw := 12;
        end;
      end;

      if nw > m then
        m := nw;
    end;
  end;

  if Assigned(cell) then
    cell.Free;

  RowHeights[ARow] := m + Padding;

  l.Free;
end;

procedure TTMSFNCCustomGrid.AutoSizeRows(const Padding: Integer = 5);
var
  i: integer;
begin
  for i := 0 to RowCount - 1 do
    AutoSizeRowInt(i, Padding);

  UpdateControl;
end;

procedure TTMSFNCCustomGrid.BeforeExport;
begin
  inherited;
  UpdateControl;
end;

procedure TTMSFNCCustomGrid.StopAnimationTimer;
begin
  FAnimateTimer.Enabled := False;
  FAnimating := False;
end;

procedure TTMSFNCCustomGrid.StopEdit;
begin
  HideEdit;
end;

procedure TTMSFNCCustomGrid.CancelEdit;
var
  celleditor: TTMSFNCGridEditorType;
  {$IFDEF FMXLIB}
  p: TPopup;
  {$ENDIF}
begin
  if FBlockHide then
    Exit;

  FBlockHide := True;
  celleditor := etEdit;
  DoGetCellEditorType(FEditCell.Col, FEditCell.Row, cellEditor);
  if cellEditor <> TTMSFNCGridEditorType.etCustom then
  begin
    {$IFDEF FMXLIB}
    p := FindPopup(FEditControl);
    if Assigned(p) then
    begin
      if p.IsOpen then
        p.IsOpen := False;
    end;
    {$ENDIF}
    if Assigned(FEditControl) then
      FEditControl.Parent := nil;

    FEditControl := nil;
  end
  else
    FFreeEditControlTimer.Enabled := True;

  FEditing := False;

  DoCellEditCancel(FEditCell.Col, FEditCell.Row);

  if CanFocus then
    SetFocus;

  FBlockHide := False;
end;

procedure TTMSFNCCustomGrid.CellComboBoxChange(Sender: TObject);
begin
  if FKeyUsed then
  begin
    FKeyUsed := False;
    Exit;
  end;

  if (Options.Editing.DirectComboClose) and Editing then
    StopEdit;
end;

procedure TTMSFNCCustomGrid.CellComboBoxCloseUp(Sender: TObject);
var
  v: string;
  i: integer;
begin
  i := -1;
  v := '';

  if (Sender is TComboBox) then
  begin
    i := (Sender as TComboBox).ItemIndex;
    if i >= 0 then
      v := (Sender as TComboBox).Items[i]
    else
      v := '';
  end;

  if Options.Editing.DirectComboClose then
    HideEdit(True);

  DoCellComboCloseUp(FocusedCell.Col,FocusedCell.Row, i ,v);
  CellComboBoxChange(Sender);
end;

procedure TTMSFNCCustomGrid.CellComboBoxKeyDown(Sender: TObject; var Key: Word;
  {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState);
begin
  case Key of
    KEY_TAB: HandleDialogKey(Key, Shift);
  end;

  FKeyUsed := True;
  if Shift <> [] then
    Exit;
  case Key of
    KEY_F4:
    begin
      if FEditControl = CellDatePicker then
      begin
        {$IFDEF FMXLIB}
        CellDatePicker.OpenPicker
        {$ENDIF}
      end
      {$IFDEF FMXLIB}
      else if FEditControl = CellColorPicker then
        CellColorPicker.DropDown
      {$ENDIF}
    end;
    KEY_RETURN, KEY_F2:
    begin
      {$IFDEF FMXLIB}
      if not ((FEditControl = CellComboBox) and (CellComboBox.DroppedDown)) then
      {$ENDIF}
      begin
        HideEdit(Key = KEY_RETURN);
        if Key = KEY_RETURN then
          Key := 0;
      end;
    end;
    KEY_ESCAPE: CancelEdit;
  end;
end;

procedure TTMSFNCCustomGrid.CellControlKeyDown(Sender: TObject; var Key: Word;
  {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState);
begin
  case Key of
    KEY_TAB: HandleDialogKey(Key, Shift);
  end;
  if Shift <> [] then
    Exit;
  case Key of
    KEY_ESCAPE: CancelEdit;
    KEY_RETURN, KEY_F2:
    begin
      HideEdit(Key = KEY_RETURN);
      if Key = KEY_RETURN then
        Key := 0;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.CellEditBtnKeyDown(Sender: TObject; var Key: Word;
  {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState);
var
  k: Word;
  a, Allow: Boolean;
begin
  {$IFDEF FMXLIB}
  if KeyChar <> #0 then
    Exit;
  {$ENDIF}

  case Key of
    KEY_TAB: HandleDialogKey(Key, Shift);
  end;
  if Shift <> [] then
    Exit;

  case Key of
    KEY_ESCAPE: CancelEdit;
    KEY_RETURN, KEY_F2: HideEdit(Key = KEY_RETURN);
    KEY_LEFT:
    begin
      if Options.Keyboard.ArrowKeyDirectEdit then
      begin
        if FEditControl = CellEdit then
        begin
          if CellEdit.SelStart = 0 then
          begin
            k := KEY_TAB;
            Key := 0;
            ProcessTab(k, [ssShift], tkdNextColumnCell, tkhNextCell, True, csEnd);
          end;
        end;
      end;
    end;
    KEY_RIGHT:
    begin
      if Options.Keyboard.ArrowKeyDirectEdit then
      begin
        if FEditControl = CellEdit then
        begin
          if CellEdit.SelStart = Length(CellEdit.Text) then
          begin
            k := KEY_TAB;
            Key := 0;
            ProcessTab(k, [], tkdNextColumnCell, tkhNextCell, True, csStart);
          end;
        end;
      end;
    end;
    KEY_UP, KEY_DOWN:
    begin
      a := HideEdit;
      if a then
      begin
        HandleKeyDown(Key, Shift);

        if Options.Keyboard.ArrowKeyDirectEdit then
        begin
          Allow := True;
          DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
          if Allow then
            EditCell(FocusedCell);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.CellEditKeyDown(Sender: TObject; var Key: Word;
  {$IFDEF FMXLIB}var KeyChar: WideChar; {$ENDIF}Shift: TShiftState);
var
  k: Word;
  a, Allow: Boolean;
begin
  {$IFDEF FMXLIB}
  if KeyChar <> #0 then
    Exit;
  {$ENDIF}

  case Key of
    KEY_TAB: HandleDialogKey(Key, Shift);
  end;
  if Shift <> [] then
    Exit;
  case Key of
    KEY_ESCAPE: CancelEdit;
    KEY_RETURN, KEY_F2: HideEdit(Key = KEY_RETURN);
    KEY_LEFT:
    begin
      if Options.Keyboard.ArrowKeyDirectEdit then
      begin
        if ((FEditControl = CellEdit) and (CellEdit.SelStart = 0)) or ((FEditControl = CellMemo) and (CellMemo.SelStart = 0)) then
        begin
          k := KEY_TAB;
          Key := 0;
          ProcessTab(k, [ssShift], tkdNextColumnCell, tkhNextCell, True, csEnd);
        end;
      end;
    end;
    KEY_RIGHT:
    begin
      if Options.Keyboard.ArrowKeyDirectEdit then
      begin
        if ((FEditControl = CellEdit) and (CellEdit.SelStart = Length(CellEdit.Text))) or ((FEditControl = CellMemo) and (CellMemo.SelStart = Length(CellMemo.Text))) then
        begin
          k := KEY_TAB;
          Key := 0;
          ProcessTab(k, [], tkdNextColumnCell, tkhNextCell, True, csStart);
        end;
      end;
    end;
    KEY_UP, KEY_DOWN:
    begin
      a := HideEdit;
      if a then
      begin
        HandleKeyDown(Key, Shift);
        if Options.Keyboard.ArrowKeyDirectEdit then
        begin
          Allow := True;
          DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
          if Allow then
            EditCell(FocusedCell);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.CellExit(Sender: TObject);
var
  b: Boolean;
  p: TTMSFNCPopup;
begin
  if Sender is TTMSFNCColorPicker then
  begin
    p := (Sender as TTMSFNCColorPicker).Popup;
    if Assigned(p) and p.IsOpen then
      Exit;
  end;

  if Sender is TTMSFNCEdit then
  begin
    p := (Sender as TTMSFNCEdit).LookupList;
    if Assigned(p) and p.IsOpen then
      Exit;
  end;

  b := True;
  if FEditing and Assigned(FEditControl) then
    DoBeforeCellEditExit(FEditCell.Col, FEditCell.Row, FEditControl, b);

  if b then
    StopEdit;
end;

procedure TTMSFNCCustomGrid.ChangeDPIScale(M, D: Integer);
var
  I, J: Integer;
  cp: TTMSFNCGridCellProperty;
begin
  inherited;
  BeginUpdate;
  Options.Filtering.DropDownWidth := TTMSFNCUtils.MulDivInt(Options.Filtering.DropDownWidth, M, D);
  Options.Filtering.DropDownHeight := TTMSFNCUtils.MulDivInt(Options.Filtering.DropDownHeight, M, D);
  DefaultRowHeight := TTMSFNCUtils.MulDivSingle(DefaultRowHeight, M, D);
  DefaultColumnWidth := TTMSFNCUtils.MulDivSingle(DefaultColumnWidth, M, D);
  DefaultFont.Height := TTMSFNCUtils.MulDivInt(DefaultFont.Height, M, D);
  Appearance.FixedLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.FixedLayout.Font.Height, M, D);
  Appearance.NormalLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.NormalLayout.Font.Height, M, D);
  Appearance.GroupLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.GroupLayout.Font.Height, M, D);
  Appearance.SummaryLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.SummaryLayout.Font.Height, M, D);
  Appearance.SelectedLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.SelectedLayout.Font.Height, M, D);
  Appearance.FocusedLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.FocusedLayout.Font.Height, M, D);
  Appearance.FixedSelectedLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.FixedSelectedLayout.Font.Height, M, D);
  Appearance.BandLayout.Font.Height := TTMSFNCUtils.MulDivInt(Appearance.BandLayout.Font.Height, M, D);

  CreateColumnProp;

  for I := 0 to Columns.Count - 1 do
  begin
    Columns[I].FixedFont.Height := TTMSFNCUtils.MulDivInt(Columns[I].FixedFont.Height, M, D);
    Columns[I].Font.Height := TTMSFNCUtils.MulDivInt(Columns[I].Font.Height, M, D);
  end;

  for i := RowList.Count - 1 downto 0 do
  begin
    if Assigned(RowList[i]) then
    begin
      for j := 0 to RowList[i].Data.Count - 1 do
      begin
        cp := TTMSFNCGridCellProperty(RowList[i].Data.Objects[j]);
        if Assigned(cp) and cp.IsBaseCell(j,i) then
          cp.FontSize := TTMSFNCUtils.MulDivSingle(cp.FontSize, M, D);
      end;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomGrid.ClearFixedSorting(ACell: TTMSFNCGridCell);
var
  c, r: Integer;
  ctl: TTMSFNCFixedGridCell;
  cell: TTMSFNCGridCell;
begin
  for c := 0 to Length(FCellArray) - 1 do
  begin
    for r := 0 to FixedRows - 1 do
    begin
      cell := FCellArray[c, r];
      if Assigned(cell) and (cell is TTMSFNCFixedGridCell) and (cell <> ACell) then
      begin
        ctl := cell as TTMSFNCFixedGridCell;
        ctl.SortIndex := - 1;
        ctl.SortKind := skNone;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.ClearSelection;
var
  cl: TTMSFNCGridCellRec;
begin
  cl.Col := -1;
  cl.Row := -1;
  StartCell := cl;
  StopCell := cl;
  FocCell := cl;

  FPrevSelectedCell := FocusedCell;
  FSaveCell := FocusedCell;
  FFirstCell.Col := -1;
  FFirstCell.Row := -1;
  FRealCell := FocusedCell;
  FPrevSelection := Selection;

  UpdateGridCells;
end;

constructor TTMSFNCCustomGrid.Create(AOwner: TComponent);
begin
  inherited;
  UpdateScrollingMode(scmItemScrolling);
  ChangeCursor := True;
  FLeftC := 0;
  FTopr := 0;
  FFreezeRows := 0;
  FFreezeColumns := 0;
  FToolBarPopupMode := tpmActiveCell;

  FFreeEditControlTimer := TTimer.Create(Self);
  FFreeEditControlTimer.Interval := 1;
  FFreeEditControlTimer.Enabled := False;
  FFreeEditControlTimer.OnTimer := DoFreeEditControl;

  FAnimateTimer := TTimer.Create(Self);
  FAnimateTimer.Interval := 1;
  FAnimateTimer.Enabled := False;
  FAnimateTimer.OnTimer := Animate;

  FScrollTimer := TTimer.Create(Self);
  FScrollTimer.OnTimer := ScrollTimer;
  FScrollTimer.Interval := Options.Mouse.AutoScrollingInterval;
  FScrollTimer.Enabled := False;

  FDragTimer := TTimer.Create(Self);
  FDragTimer.OnTimer := DragTimer;
  FDragTimer.Interval := Options.Mouse.AutoScrollingInterval;
  FDragTimer.Enabled := False;

  FActiveCommentTextColor := gcBlack;
  FActiveComment := '';

  FFilterListBox := TListBox.Create(Self);
  {$IFDEF LCLLIB}
  FFilterListBox.ClickOnSelChange := False;
  {$ENDIF}
  FFilterListBox.Tag := -1;
  FFilterListBox.OnClick := HandleFilterListClick;
  {$IFDEF FMXLIB}
  FFilterListBox.OnApplyStyleLookup := ApplyFilterListBoxStyleLookUp;
  {$ENDIF}

  FFilterTimer := TTimer.Create(Self);
  FFilterTimer.OnTimer := HandleFilterTimer;
  FFilterTimer.Interval := 100;
  FFilterTimer.Enabled := False;

  FCommentPopup := TTMSFNCPopup.Create(Self);
  FCommentPopup.Placement := ppAbsolute;
  FCommentPopup.OnPopupPaint := DoPaintCommentPopup;

  FFilterPopup := TTMSFNCPopup.Create(Self);
  FFilterPopup.Placement := ppAbsolute;

  FAppearance := TTMSFNCGridAppearance.Create;
  FAppearance.OnChange := @AppearanceChanged;

  Width := 370;
  Height := 270;

  if IsDesignTime then
    InitSample;

  FDesignTimeSampleData := True;
end;

procedure TTMSFNCCustomGrid.HandleDblClick(X, Y: Single);
var
  pt: TPointF;
  cl: TTMSFNCGridCellRec;
begin
  inherited;
  FDblClick := True;
  if FMouseDblClick then
  begin
    FMouseDblClick := False;
    case FSizeMode of
      smPreviousColumnSizing, smColumnSizing: if Options.Mouse.ColumnAutoSizeOnDblClick then AutoSizeColumn(FDragCell.Col);
      smPreviousRowSizing, smRowSizing: if Options.Mouse.RowAutoSizeOnDblClick then AutoSizeRow(FDragCell.Row);
    end;
  end;

  pt := ScreenToLocalEx(TTMSFNCUtils.GetMousePos);
  cl := XYToCell(pt.X, pt.Y);
  if (cl.Col <> -1) and (cl.Row <> -1) then
  begin
    if IsFixed(cl.Col, cl.Row) then
      DoFixedCellDblClick(cl.Col, cl.Row)
    else
      DoCellDblClick(cl.Col, cl.Row);
  end;
end;

destructor TTMSFNCCustomGrid.Destroy;
begin
  FreeAndNil(FDragTimer);
  FreeAndNil(FFilterTimer);
  FreeAndNil(FScrollTimer);
  FreeAndNil(FAnimateTimer);
  FreeAndNil(FFreeEditControlTimer);

  RemoveAllCells;
  if Assigned(FDummyButtonBitmap) then
    FDummyButtonBitmap.Free;
  if Assigned(FNodeOpenBitmap) then
    FNodeOpenBitmap.Free;
  if Assigned(FNodeClosedBitmap) then
    FNodeClosedBitmap.Free;
  if Assigned(FCellMemo) then
    FCellMemo.Free;
  if Assigned(FCellEdit) then
    FCellEdit.Free;
  if Assigned(FCellColorPicker) then
    FCellColorPicker.Free;
  if Assigned(FCellSpinBox) then
    FCellSpinBox.Free;
  if Assigned(FCellComboBox) then
    FCellComboBox.Free;
  if Assigned(FCellDatePicker) then
    FCellDatePicker.Free;
  if Assigned(FCellTrackBar) then
    FCellTrackBar.Free;

  FFilterListBox.Free;
  FCommentPopup.Free;
  FFilterPopup.Free;
  FAppearance.Free;
  inherited;
end;

procedure TTMSFNCCustomGrid.HandleFilterListClick(Sender: TObject);
begin
  FFilterTimer.Enabled := True;
end;

procedure TTMSFNCCustomGrid.HandleFilterTimer(Sender: TObject);
var
  fd: TTMSFNCGridFilterData;
  cnd: string;
  i, filtercol: integer;
  u: Boolean;
begin
  FFilterTimer.Enabled := False;

  if Assigned(FFilterListBox) then
  begin
    filtercol := FFilterListBox.Tag;

    if Options.Filtering.MultiColumn then
    begin
      for i := Filter.Count - 1 downto 0 do
      begin
        if Filter[i].Column = filtercol then
          Filter.Delete(i);
      end;
    end
    else
      Filter.Clear;

    fd := Filter.Add;
    fd.Column := filtercol;

    cnd := '';
    if (FFilterListBox.ItemIndex >= 0) and (FFilterListBox.ItemIndex <= FFilterListBox.Items.Count - 1) then
      cnd := FFilterListbox.Items[FFilterListbox.ItemIndex];

    if pos(' ',cnd) > 0 then
      cnd := '"' + cnd + '"';

    FFilterPopup.IsOpen := False;

    if cnd = sTMSFNCGridFilterAll then
    begin
      BlockUpdate := True;
      UnhideRowsAll;
      BlockUpdate := False;
      Filter.Clear;
      ApplyFilter;
    end
    else
    begin
      DoFilterSelect(fd.Column, cnd);
      fd.Condition := cnd;

      BlockUpdate := True;
      UnhideRowsAll;
      BlockUpdate := False;
      ApplyFilter;
    end;
    u := False;
    DoAfterApplyFilter(fd.Column, cnd, u);
    if u then
    begin
      BeginUpdate;
      UpdateCalculations;
      EndUpdate;
    end;
  end;
end;


procedure TTMSFNCCustomGrid.HandleDialogKey(var Key: Word; Shift: TShiftState);
begin
  FTabKey := tkmNone;
  if (Key = KEY_TAB) and (Options.Keyboard.TabKeyHandling = tkhMixed) then
  begin
    if ssShift in Shift then
      FTabKey := tkmShift
    else
      FTabKey := tkmNormal;
  end;

  if IsFocused or ((FEditControl <> nil) and FEditControl.IsFocused) then
    ProcessTab(Key, Shift, Options.Keyboard.TabKeyDirection, Options.Keyboard.TabKeyHandling, Options.Keyboard.TabKeyDirectEdit);

  inherited;
end;

procedure TTMSFNCCustomGrid.DisplayCells(x, y, xOrig, yOrig: Single; CStart, CStop, RStart, RStop, Ci, Ri: Integer; ResetCi: Integer; Fixed: Boolean = False);
begin
  if Assigned(Adapter) and Adapter.AlternateDisplayBuildUp then
    DisplayCellsAlternate(x, y, xOrig, yOrig, CStart, CStop, RStart, RStop, Ci, Ri, ResetCi, Fixed)
  else
    DisplayCellsNormal(x, y, xOrig, yOrig, CStart, CStop, RStart, RStop, Ci, Ri, ResetCi, Fixed);
end;

procedure TTMSFNCCustomGrid.DisplayCellsAlternate(x, y, xOrig, yOrig: Single; CStart,
  CStop, RStart, RStop, Ci, Ri, ResetCi: Integer; Fixed: Boolean);
var
  cw, rh, cwr, rhr, cwrt, rhrt, xr, yr: Single;
  cell: TTMSFNCGridCell;
  c, r, bc, br, cs, rs: Integer;
  cellclasstype: TTMSFNCGridCellClass;
  cellr: TRectF;
  cellstr: String;
  I: Integer;
  l: TTMSFNCGridCellLayout;
  a: Integer;
begin
  l := TTMSFNCGridCellLayout.Create;
  for r := RStart to RStop do
  begin
    rh := RowHeights[r];
    if Assigned(Adapter) then
      Adapter.StartBuild(r);

    for c := CStart to CStop do
    begin
      bc := c;
      br := r;
      cs := 0;
      rs := 0;

      cw := ColumnWidths[c];

      DoGetCellMergeInfo(c, r, bc, br, cs, rs);

      cellclasstype := nil;
      DoGetCellClass(bc, br, cellclasstype);

      cell := nil;
      if (Length(FCellArray) > ci) then
        if (Length(FCellArray[ci]) > ri) then
        begin
          cell := FCellArray[ci, ri];
        end;

      if Assigned(cell) then
      begin
        if (cell.ClassType <> cellclasstype) then
        begin
          cell.Free;
          cell := nil;
          FCellArray[ci, ri] := nil;
        end;
      end;

      cwr := cw;
      rhr := rh;

      cwrt := 0;
      for I := bc to bc + cs - 1 do
        cwrt := cwrt + ColumnWidths[I];

      if cwrt > 0 then
        cwr := cwrt;

      rhrt := 0;
      for I := br to br + rs - 1 do
        rhrt := rhrt + RowHeights[I];

      if rhrt > 0 then
        rhr := rhrt;

      xr := 0;
      for I := bc to c - 1 do
        xr := xr + ColumnWidths[I];

      yr := 0;
      for I := br to r - 1 do
        yr := yr + RowHeights[I];

      cellr := RectF(x - xr, y - yr, x - xr + cwr + 1, y - yr + rhr + 1);
      if not Assigned(cell) then
      begin
        cell := cellclasstype.Create(Self);

        if (Length(FCellArray) <= ci) then
          SetLength(FCellArray, ci + 1);

        if (Length(FCellArray[ci]) <= ri) then
          SetLength(FCellArray[ci], ri + 1);
      end;

      if Assigned(cell) then
      begin
        cell.ScaleFactor := PaintScaleFactor;

        FCellArray[bc, br] := cell;
        cellstr := '';
        DoGetCellData(bc, br, cellstr);
        if cell is TTMSFNCGridCell then
        begin
          a := 0;
          DoGetCellRotation(bc, br, a);
          (cell as TTMSFNCGridCell).Angle := a;
          (cell as TTMSFNCGridCell).OnBeforeDraw := DoBeforeDrawCell;
          (cell as TTMSFNCGridCell).OnAfterDraw := DoAfterDrawCell;
          (cell as TTMSFNCGridCell).Layout := nil;
          (cell as TTMSFNCGridCell).Text := cellstr;
          (cell as TTMSFNCGridCell).DisplayHTMLFormatting := Options.Cell.DisplayHTMLFormatting;
          (cell as TTMSFNCGridCell).BitmapContainer := BitmapContainer;
          (cell as TTMSFNCGridCell).OptimizedHTMLDrawing := OptimizedHTMLDrawing;
        end;

        if cell is TTMSFNCProgressGridCell then
        begin
          (cell as TTMSFNCProgressGridCell).Format := Appearance.ProgressLayout.Format;
          (cell as TTMSFNCProgressGridCell).TextColor := Appearance.ProgressLayout.TextColor;
          (cell as TTMSFNCProgressGridCell).Color := Appearance.ProgressLayout.Color;
          (cell as TTMSFNCProgressGridCell).ShowText := Appearance.ProgressLayout.ShowText;
        end;

        if (cell is TTMSFNCNodeGridCell) then
          (cell as TTMSFNCNodeGridCell).OnControlClick := DoNodeClick;

        if (cell is TTMSFNCCommentGridCell) then
          (cell as TTMSFNCCommentGridCell).OnControlClick := DoCommentClick;

        if (cell is TTMSFNCBitmapGridCell) then
          (cell as TTMSFNCBitmapGridCell).OnControlClick := DoBitmapClick;


        if cell is TTMSFNCFixedGridCell then
          (cell as TTMSFNCFixedGridCell).OnControlClick := DoFixedDropDownButtonClick;

        cell.Left := cellr.Left;
        cell.Top := cellr.Top;
        cell.Width := cellr.Right - cellr.Left;
        cell.Height := cellr.Bottom - cellr.Top;

        if (cell is TTMSFNCCheckGridCell) then
          (cell as TTMSFNCCheckGridCell).OnCheckChanged := DoCheckBoxClick;

        if (cell is TTMSFNCButtonGridCell) then
          (cell as TTMSFNCButtonGridCell).OnControlClick := DoButtonClick;

        if (cell is TTMSFNCRadioGridCell) then
          (cell as TTMSFNCRadioGridCell).OnCheckChanged := DoRadioButtonClick;

        ApplyCellState(bc, br, cell);

        if (Cell is TTMSFNCGridCell) then
        begin
          l.Assign((Cell as TTMSFNCGridCell).Layout);
          DoGetCellLayout(bc, br, l, GetCellState(bc, br));
          (Cell as TTMSFNCGridCell).AssignLayout(l);
        end;

        DoGetCellProperties(bc, br, cell);
      end;

      x := x + cw;
      Inc(ci);
    end;

    x := xOrig;
    y := y + rh;
    Inc(ri);
    ci := CStart;
  end;

  l.Free;
end;

procedure TTMSFNCCustomGrid.DisplayCellsNormal(x, y, xOrig, yOrig: Single;
  CStart, CStop, RStart, RStop, Ci, Ri, ResetCi: Integer; Fixed: Boolean);
var
  cw, rh, cwr, rhr, cwrt, rhrt, xr, yr: Single;
  cell: TTMSFNCGridCell;
  c, r, bc, br, cs, rs: Integer;
  cellclasstype: TTMSFNCGridCellClass;
  cellr: TRectF;
  cellstr: String;
  I: Integer;
  l: TTMSFNCGridCellLayout;
  a: Integer;
begin
  l := TTMSFNCGridCellLayout.Create;
  for c := CStart to CStop do
  begin
    cw := ColumnWidths[c];
    for r := RStart to RStop do
    begin
      if Assigned(Adapter) then
        Adapter.StartBuild(r);

      bc := c;
      br := r;
      cs := 0;
      rs := 0;

      rh := RowHeights[r];

      DoGetCellMergeInfo(c, r, bc, br, cs, rs);

      cellclasstype := nil;
      DoGetCellClass(bc, br, cellclasstype);

      cell := nil;
      if (Length(FCellArray) > ci) then
        if (Length(FCellArray[ci]) > ri) then
        begin
          cell := FCellArray[ci, ri];
        end;

      if Assigned(cell) then
      begin
        if (cell.ClassType <> cellclasstype) then
        begin
          cell.Free;
          cell := nil;
          FCellArray[ci, ri] := nil;
        end;
      end;

      cwr := cw;
      rhr := rh;

      cwrt := 0;
      for I := bc to bc + cs - 1 do
        cwrt := cwrt + ColumnWidths[I];

      if cwrt > 0 then
        cwr := cwrt;

      rhrt := 0;
      for I := br to br + rs - 1 do
        rhrt := rhrt + RowHeights[I];

      if rhrt > 0 then
        rhr := rhrt;

      xr := 0;
      for I := bc to c - 1 do
        xr := xr + ColumnWidths[I];

      yr := 0;
      for I := br to r - 1 do
        yr := yr + RowHeights[I];

      cellr := RectF(x - xr, y - yr, x - xr + cwr + 1, y - yr + rhr + 1);
      OffsetRectEx(cellr, GetContentRect.Left, GetContentRect.Top);
      if not Assigned(cell) then
      begin
        cell := cellclasstype.Create(Self);

        if (Length(FCellArray) <= ci) then
          SetLength(FCellArray, ci + 1);

        if (Length(FCellArray[ci]) <= ri) then
          SetLength(FCellArray[ci], ri + 1);
      end;

      if Assigned(cell) then
      begin
        a := 0;
        DoGetCellRotation(bc, br, a);
        (cell as TTMSFNCGridCell).Angle := a;

        cell.ScaleFactor := PaintScaleFactor;

        FCellArray[ci, ri] := cell;
        cellstr := '';
        DoGetCellData(bc, br, cellstr);
        if cell is TTMSFNCGridCell then
        begin
          (cell as TTMSFNCGridCell).OnBeforeDraw := DoBeforeDrawCell;
          (cell as TTMSFNCGridCell).OnAfterDraw := DoAfterDrawCell;
          (cell as TTMSFNCGridCell).Layout := nil;
          (cell as TTMSFNCGridCell).Text := cellstr;
          (cell as TTMSFNCGridCell).DisplayHTMLFormatting := Options.Cell.DisplayHTMLFormatting;
          (cell as TTMSFNCGridCell).BitmapContainer := BitmapContainer;
          (cell as TTMSFNCGridCell).OptimizedHTMLDrawing := OptimizedHTMLDrawing;
        end;

        if cell is TTMSFNCProgressGridCell then
        begin
          (cell as TTMSFNCProgressGridCell).Format := Appearance.ProgressLayout.Format;
          (cell as TTMSFNCProgressGridCell).TextColor := Appearance.ProgressLayout.TextColor;
          (cell as TTMSFNCProgressGridCell).Color := Appearance.ProgressLayout.Color;
          (cell as TTMSFNCProgressGridCell).ShowText := Appearance.ProgressLayout.ShowText;
        end;

        if (cell is TTMSFNCNodeGridCell) then
          (cell as TTMSFNCNodeGridCell).OnControlClick := DoNodeClick;

        if (cell is TTMSFNCCommentGridCell) then
          (cell as TTMSFNCCommentGridCell).OnControlClick := DoCommentClick;

        if (cell is TTMSFNCBitmapGridCell) then
          (cell as TTMSFNCBitmapGridCell).OnControlClick := DoBitmapClick;


        if cell is TTMSFNCFixedGridCell then
          (cell as TTMSFNCFixedGridCell).OnControlClick := DoFixedDropDownButtonClick;

        cell.Left := cellr.Left;
        cell.Top := cellr.Top;
        cell.Width := cellr.Right - cellr.Left;
        cell.Height := cellr.Bottom - cellr.Top;

        if (cell is TTMSFNCCheckGridCell) then
          (cell as TTMSFNCCheckGridCell).OnCheckChanged := DoCheckBoxClick;

        if (cell is TTMSFNCButtonGridCell) then
          (cell as TTMSFNCButtonGridCell).OnControlClick := DoButtonClick;

        if (cell is TTMSFNCRadioGridCell) then
          (cell as TTMSFNCRadioGridCell).OnCheckChanged := DoRadioButtonClick;

        ApplyCellState(bc, br, cell);

        if (Cell is TTMSFNCGridCell) then
        begin
          l.Assign((Cell as TTMSFNCGridCell).Layout);
          DoGetCellLayout(bc, br, l, GetCellState(bc, br));
          (Cell as TTMSFNCGridCell).AssignLayout(l);
        end;

        DoGetCellProperties(bc, br, cell);
      end;

      y := y + rh;
      Inc(ri);
    end;

    y := yOrig;
    x := x + cw;
    Inc(ci);
    ri := ResetCi;
  end;

  l.Free;
end;

procedure TTMSFNCCustomGrid.DoGetCellClass(ACol, ARow: Integer;
  var CellClassType: TTMSFNCGridCellClass);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.GetCellClass(ACol, ARow, CellClassType);

  if Assigned(OnGetCellClass) then
    OnGetCellClass(Self, ACol, ARow, CellClassType);
end;

procedure TTMSFNCCustomGrid.DoGetCellData(ACol, ARow: Integer;
  var CellString: String);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.GetCellData(ACol, ARow, CellString);

  if Assigned(OnGetCellData) then
    OnGetCellData(Self, ACol, ARow, CellString);
end;

procedure TTMSFNCCustomGrid.DoCellEditGetColor(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if Assigned(OnCellEditGetColor) then
    OnCellEditGetColor(Self, ACol, ARow, CellEditor, CellColor);
end;

procedure TTMSFNCCustomGrid.DoCellEditGetData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.CellEditGetData(ACol, ARow, CellEditor, CellString);

  if Assigned(OnCellEditGetData) then
    OnCellEditGetData(Self, ACol, ARow, CellEditor, CellString);
end;

procedure TTMSFNCCustomGrid.DoGetCellEditorProperties(ACol,
  ARow: Integer; CellEditor: TTMSFNCGridEditor);
begin
  inherited;
  if CellEditor is TComboBox then
    (CellEditor as TComboBox).Parent := Self;

  if Assigned(Adapter) then
    Adapter.GetCellEditorProperties(ACol, ARow, CellEditor);

  if Assigned(OnGetCellEditorProperties) then
    OnGetCellEditorProperties(Self, ACol, ARow, CellEditor);

  if CellEditor is TComboBox then
    (CellEditor as TComboBox).Parent := nil;
end;

procedure TTMSFNCCustomGrid.DoGetCellEditorType(ACol, ARow: Integer;
  var CellEditorType: TTMSFNCGridEditorType);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.GetCellEditorType(ACol, ARow, CellEditorType);

  if Assigned(OnGetCellEditorType) then
    OnGetCellEditorType(Self, ACol, ARow, CellEditorType);
end;

procedure TTMSFNCCustomGrid.DoGetCellLayout(ACol, ARow: Integer;
  ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
begin
  inherited;
  if Assigned(OnGetCellLayout) then
    OnGetCellLayout(Self, ACol, ARow, ALayout, ACellState);
end;

procedure TTMSFNCCustomGrid.DoGetCellEditorCustomClassType(ACol, ARow: Integer;
  var CellEditorCustomClassType: TTMSFNCGridEditorClass);
begin
  inherited;
  if Assigned(OnGetCellEditorCustomClassType) then
    OnGetCellEditorCustomClassType(Self, ACol, ARow, CellEditorCustomClassType);
end;

procedure TTMSFNCCustomGrid.DoGetCellMergeInfo(ACol, ARow: Integer; var ABaseCol: Integer; var ABaseRow: Integer; var AColSpan: Integer; var ARowSpan: Integer);
begin
  inherited;
  if Assigned(OnGetCellMergeInfo) then
    OnGetCellMergeInfo(Self, ACol, ARow, ABaseCol, ABaseRow, AColSpan, ARowSpan);
end;

procedure TTMSFNCCustomGrid.DoGetCellProperties(ACol, ARow: Integer;
  Cell: TTMSFNCGridCell);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.GetCellProperties(ACol, ARow, Cell);

  if Assigned(OnGetCellProperties) then
    OnGetCellProperties(Self, ACol, ARow, Cell);
end;

procedure TTMSFNCCustomGrid.DoCanAppendColumn(ACol: Integer; var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanAppendColumn) then
    OnCanAppendColumn(Self, ACol, Allow);
end;

procedure TTMSFNCCustomGrid.DoCanAppendRow(ARow: Integer; var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanAppendRow) then
    OnCanAppendRow(Self, ARow, Allow);
end;

procedure TTMSFNCCustomGrid.DoBeforeRowDrop(FromRow: Integer; var ToRow: integer;
  var Allow: Boolean);
begin
  if Assigned(OnBeforeRowDrop) then
    OnBeforeRowDrop(Self, FromRow, ToRow, Allow);
end;

procedure TTMSFNCCustomGrid.DoBeforeDrawCell(Sender: TObject; AGraphics: TTMSFNCGraphics;
  var ARect, ATextRect: TRectF; var ADrawText, ADrawBackGround, ADrawBorder,
  AllowDraw: Boolean);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoBeforeDrawGridCell(Cell.Col, Cell.Row, AGraphics, ARect, ATextRect, ADrawText, ADrawBackGround, ADrawBorder, AllowDraw);
  end;
end;

procedure TTMSFNCCustomGrid.DoBeforeDrawGridCell(ACol, ARow: Integer;
  AGraphics: TTMSFNCGraphics; var ARect, ATextRect: TRectF; var ADrawText,
  ADrawBackGround, ADrawBorder, AllowDraw: Boolean);
begin
  if Assigned(OnCellBeforeDraw) then
    OnCellBeforeDraw(Self, ACol, ARow, AGraphics, Arect, ATextRect, ADrawText, ADrawBackGround, ADrawBorder, AllowDraw);
end;

procedure TTMSFNCCustomGrid.DoBeforeCellEditExit(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var AllowExit: Boolean);
begin
  if Assigned(Adapter) then
    Adapter.CellBeforeEditExit(ACol, ARow, CellEditor, AllowExit);

  if Assigned(OnBeforeCellEditExit) then
    OnBeforeCellEditExit(Self, ACol, ARow, CellEditor, AllowExit);
end;

procedure TTMSFNCCustomGrid.DoBeforeColumnDrop(FromColumn: Integer; var ToColumn: integer;
  var Allow: Boolean);
begin
  if Assigned(OnBeforeColumnDrop) then
    OnBeforeColumnDrop(Self, FromColumn, ToColumn, Allow);
end;

procedure TTMSFNCCustomGrid.DoBitmapClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoCellBitmapClick(Cell.Col, Cell.Row, cl);
  end;
end;

procedure TTMSFNCCustomGrid.DoButtonClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoCellButtonClick(Cell.Col, Cell.Row, cl);
  end;
end;

procedure TTMSFNCCustomGrid.DoCheckBoxClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  {$IFDEF WEBLIB}
  StopEdit;
  {$ENDIF}
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
    begin
      if Assigned(Adapter) then
        Adapter.CellCheckBoxClick(Cell.Col, Cell.Row, cl);
      DoCellCheckBoxClick(Cell.Col, Cell.Row, cl);
    end;
  end;
end;

procedure TTMSFNCCustomGrid.DoCellComboCloseUp(ACol, ARow, ItemIndex: integer;
  Value: string);
begin
  if Assigned(OnCellComboCloseUp) then
    OnCellComboCloseUp(Self, ACol, ARow, ItemIndex, Value);
end;

procedure TTMSFNCCustomGrid.DoCellCommentClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
var
  g: TTMSFNCGraphics;
  sz: TSizeF;
  pt: TPointF;
begin
  inherited;
  FCommentPopup.PlacementControl := Self;

  FActiveComment := (ACell as TTMSFNCCommentGridCell).CommentText;
  FActiveCommentTextColor := (ACell as TTMSFNCCommentGridCell).CommentTextColor;
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  g.BitmapContainer := BitmapContainer;
  g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
  try
    sz := g.CalculateTextSize(FActiveComment, RectF(0, 0, 10000, 10000), True, True);
    FCommentPopup.DropDownHeight := sz.cy + 10;
    FCommentPopup.DropDownWidth := sz.cx + 10;
  finally
    g.EndScene;
    g.Free;
  end;

  pt := LocalToScreenEx(PointF(ACell.Left + ACell.Width - FCommentPopup.DropDownWidth, ACell.Top - FCommentPopup.DropDownHeight));
  FCommentPopup.PlacementRectangle.Left := pt.X;
  FCommentPopup.PlacementRectangle.Top := pt.Y;
  FCommentPopup.PlacementRectangle.Right := pt.X + FCommentPopup.DropDownWidth;
  FCommentPopup.PlacementRectangle.Bottom := pt.Y + FCommentPopup.DropDownHeight;

  FCommentPopup.Popup;

  if Assigned(OnCellCommentClick) then
    OnCellCommentClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoCellDblClick(ACol, ARow: Integer);
begin
  inherited;
  if Assigned(OnCellDblClick) then
    OnCellDblClick(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoColumnDragged(FromColumn, ToColumn: integer);
begin
  if Assigned(OnColumnDragged) then
    OnColumnDragged(Self,FromColumn,ToColumn);
end;

procedure TTMSFNCCustomGrid.DoColumnSize(ACol: Integer;
  var NewWidth: Single);
begin
  inherited;
  if Assigned(OnColumnSize) then
    OnColumnSize(Self, ACol, NewWidth);
end;

procedure TTMSFNCCustomGrid.DoColumnSized(ACol: Integer;
  NewWidth: Single);
begin
  inherited;
  if Assigned(OnColumnSized) then
    OnColumnSized(Self, ACol, NewWidth);
end;

procedure TTMSFNCCustomGrid.DoColumnSorted(ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
  inherited;
  if Assigned(OnColumnSorted) then
    OnColumnSorted(Self, ACol, Direction);
end;

procedure TTMSFNCCustomGrid.DoCommentClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoCellCommentClick(Cell.Col, Cell.Row, cl);
  end;
end;

procedure TTMSFNCCustomGrid.DoCanDeleteRow(ARow: Integer; var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanDeleteRow) then
    OnCanDeleteRow(Self, Arow, Allow);
end;

procedure TTMSFNCCustomGrid.DoCanDragColumn(ACol: integer; var Allow: Boolean);
begin
  if Assigned(OnCanDragColumn) then
    OnCanDragColumn(Self, ACol, Allow);
end;

procedure TTMSFNCCustomGrid.DoCanDragRow(ARow: integer; var Allow: Boolean);
begin
  if Assigned(OnCanDragRow) then
    OnCanDragRow(Self, ARow, Allow);
end;

procedure TTMSFNCCustomGrid.DoFixedCellRightClick(ACol, ARow: Integer);
begin
  inherited;
  if Assigned(OnFixedCellRightClick) then
    OnFixedCellRightClick(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoFixedCellClick(Shift: TShiftState; ACol, ARow: Integer);
var
  cell: TTMSFNCGridCell;
  cl: TTMSFNCGridCellRec;
begin
  inherited;
  cl.Col := ACol;
  cl.Row := ARow;
  cell := GetCellObject(GetDisplayCell(cl));
  if Assigned(cell) then
  begin
    if (cell is TTMSFNCFixedGridCell) and (ARow = Options.Sorting.Row) then
      DoCellSortClick(Shift, ACol, ARow, TTMSFNCGridSortDirection.sdAscending, cell);
  end;

  if Assigned(OnFixedCellClick) then
    OnFixedCellClick(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoFixedCellDblClick(ACol, ARow: Integer);
begin
  inherited;
  if Assigned(OnFixedCellDblClick) then
    OnFixedCellDblClick(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoFixedCellDropDownButtonClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
var
  sl: TStringList;
  i, c: integer;
  pt: TPointF;
begin
  inherited;
  if (Options.Filtering.DropDown) and (Options.Filtering.DropDownFixedRow = ARow) then
  begin
    sl := TStringList.Create;

    try
      sl.Duplicates := dupIgnore;
      sl.Sorted := true;
      sl.Add(sTMSFNCGridFilterAll);

      c := DisplToRealColumn(ACol);

      for i := FixedRows to RowCount - FixedFooterRows - 1 + HiddenRowCount do
        sl.Add(Trim(AllCells[c,i]));

      DoNeedFilterDropDownData(c,ARow,sl);

      FFilterListBox.Parent := Self;
      {$IFDEF FMXLIB}
      FFilterListBox.BeginUpdate;
      {$ENDIF}
      FFilterListBox.Items.Assign(sl);
      FFilterListBox.ItemIndex := -1;
      {$IFDEF FMXLIB}
      FFilterListBox.EndUpdate;
      {$ENDIF}
      {$IFDEF CMNLIB}
      FFilterListBox.Font.Height := ScalePaintValue(-13);
      {$ENDIF}
      FFilterListBox.Parent := nil;

      FFilterListBox.Tag := ACol;
    finally
      sl.Free;
    end;
  end;

  FFilterPopup.PlacementControl := Self;

  pt := LocalToScreenEx(PointF(ACell.Left + ACell.Width - 17 * PaintScaleFactor, ACell.Top + (ACell.Height + 15 * PaintScaleFactor) / 2));
  FFilterPopup.PlacementRectangle.Left := pt.X;
  FFilterPopup.PlacementRectangle.Top := pt.Y;
  FFilterPopup.PlacementRectangle.Right := pt.X + FFilterPopup.DropDownWidth;
  FFilterPopup.PlacementRectangle.Bottom := pt.Y + FFilterPopup.DropDownHeight;

  FFilterListBox.Width := Options.Filtering.DropDownWidth;
  FFilterListBox.Height := Options.Filtering.DropDownHeight;
  FFilterPopup.DropDownHeight := Options.Filtering.DropDownHeight;
  FFilterPopup.DropDownWidth := Options.Filtering.DropDownWidth;

  FFilterPopup.ContentControl := FFilterListBox;
  FFilterPopup.FocusedControl := FFilterListBox;

  FFilterPopup.Popup;

  if Assigned(OnFixedCellDropDownButtonClick) then
    OnFixedCellDropDownButtonClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoFixedDropDownButtonClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoFixedCellDropDownButtonClick(Cell.Col, Cell.Row, cl);
  end;
end;

procedure TTMSFNCCustomGrid.DoFreeEditControl(Sender: TObject);
begin
  if Assigned(FEditControl) then
  begin
    FEditControl.Free;
    FEditControl := nil;
  end;

  FFreeEditControlTimer.Enabled := False;
end;

procedure TTMSFNCCustomGrid.DoAfterDrawCell(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoAfterDrawGridCell(Cell.Col, Cell.Row, AGraphics, ARect, ATextRect);
  end;
end;

procedure TTMSFNCCustomGrid.DoAfterDrawGridCell(ACol, ARow: Integer;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF);
begin
  if Assigned(OnCellAfterDraw) then
    OnCellAfterDraw(Self, ACol, ARow, AGraphics, ARect, ATextRect);
end;

procedure TTMSFNCCustomGrid.DoAppendColumn(ACol: Integer);
begin
  inherited;
  if Assigned(OnAppendColumn) then
    OnAppendColumn(Self, ACol);
end;

procedure TTMSFNCCustomGrid.DoAppendRow(ARow: Integer);
begin
  inherited;
  if Assigned(OnAppendRow) then
    OnAppendRow(Self, ARow);
end;

procedure TTMSFNCCustomGrid.DoDeleteRow(ARow: Integer);
begin
  inherited;
  if Assigned(OnDeleteRow) then
    OnDeleteRow(Self, ARow);
end;

procedure TTMSFNCCustomGrid.DoEnter;
var
  cl: TTMSFNCGridCellRec;
begin
  inherited;
  if FTabKey = tkmNormal then
    FocusedCell := MakeCell(FixedColumns, FixedRows)
  else if FTabKey = tkmShift then
  begin
    cl.Col := ColumnCount - 1 - FixedRightColumns;
    cl.Row := RowCount - 1 - FixedFooterRows;
    FocusedCell := cl;
  end
  else
    UpdateGridCellDisplay;

  FTabKey := tkmNone;
end;

procedure TTMSFNCCustomGrid.DoExit;
begin
  inherited;
  FClearMouseDown := FBlockHide;
  FDblClick := False;
  FMouseDown := False;
  FMouseDblClick := False;
  UpdateGridCellDisplay;
end;

procedure TTMSFNCCustomGrid.DoInsertRow(ARow: Integer);
begin
  inherited;
  if Assigned(OnInsertRow) then
    OnInsertRow(Self, ARow);
end;

procedure TTMSFNCCustomGrid.DoCanSizeColumn(ACol: Integer;
  var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanSizeColumn) then
    OnCanSizeColumn(Self, ACol, Allow);
end;

procedure TTMSFNCCustomGrid.DoCanSizeRow(ARow: Integer;
  var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanSizeRow) then
    OnCanSizeRow(Self, ARow, Allow);
end;

procedure TTMSFNCCustomGrid.DoCanSortColumn(ACol: Integer;
  var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanSortColumn) then
    OnCanSortColumn(Self, ACol, Allow);
end;

procedure TTMSFNCCustomGrid.DoCellAnchorClick(ACol, ARow: Integer;
  AAnchor: String);
begin
  inherited;
  if Options.URL.Open then
    TTMSFNCUtils.OpenURL(AAnchor);

  if Assigned(OnCellAnchorClick) then
    OnCellAnchorClick(Self, ACol, ARow, AAnchor);
end;

procedure TTMSFNCCustomGrid.DoCellBitmapClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin
  inherited;
  if Assigned(OnCellBitmapClick) then
    OnCellBitmapClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoCellButtonClick(ACol, ARow: Integer;
  ACell: TTMSFNCGridCell);
begin
  inherited;
  if Assigned(OnCellButtonClick) then
    OnCellButtonClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoCellCheckBoxClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin
  inherited;
  if Assigned(OnCellCheckBoxClick) then
    OnCellCheckBoxClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoCellRightClick(ACol, ARow: Integer);
begin
  inherited;
  if Assigned(OnCellRightClick) then
    OnCellRightClick(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoCellClick(ACol, ARow: Integer);
begin
  inherited;
  if Assigned(OnCellClick) then
    OnCellClick(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoCellEditDone(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.CellEditDone(ACol, ARow, CellEditor);

  if Assigned(OnCellEditDone) then
    OnCellEditDone(Self, ACol, ARow, CellEditor);

  if Assigned(OnInternalCellEditDone) then
    OnInternalCellEditDone(Self, ACol, ARow, CellEditor);
end;

procedure TTMSFNCCustomGrid.DoCellEditCancel(ACol, ARow: Integer);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.CellEditCancel(ACol, ARow);

  if Assigned(OnCellEditCancel) then
    OnCellEditCancel(Self, ACol, ARow);
end;

procedure TTMSFNCCustomGrid.DoCellEditSetColor(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor);
begin
  if Assigned(OnCellEditSetColor) then
    OnCellEditSetColor(Self, ACol, ARow, CellEditor, CellColor);

  inherited;
end;

procedure TTMSFNCCustomGrid.DoCellEditSetData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String);
begin
  if Assigned(Adapter) then
    Adapter.CellEditSetData(ACol, ARow, CellEditor, CellString);

  if Assigned(OnCellEditSetData) then
    OnCellEditSetData(Self, ACol, ARow, CellEditor, CellString);

  inherited;
end;

procedure TTMSFNCCustomGrid.DoCellEditValidateColor(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellColor: TTMSFNCGraphicsColor; var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCellEditValidateColor) then
    OnCellEditValidateColor(Self, ACol, ARow, CellEditor, CellColor, Allow);
end;

procedure TTMSFNCCustomGrid.DoCellEditValidateData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCellEditValidateData) then
    OnCellEditValidateData(Self, ACol, ARow, CellEditor, CellString, Allow);

  if Assigned(Adapter) then
    Adapter.CellEditValidateData(ACol, ARow, CellEditor, CellString, Allow);
end;

procedure TTMSFNCCustomGrid.DoCellKeyDown(ACol, ARow: Integer; var Key: Word; Shift: TShiftState);
var
  cell: TTMSFNCGridCell;
  cl: TTMSFNCGridCellRec;
begin
  cl.Col := ACol;
  cl.Row := ARow;
  cell := GetCellObject(GetDisplayCell(cl));
  if Assigned(cell) then
  begin
    case Key of
      KEY_SPACE: FCellKeyDown := True;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.DoCellKeyUp(ACol, ARow: Integer; var Key: Word; Shift: TShiftState);
var
  cell: TTMSFNCGridCell;
  cl: TTMSFNCGridCellRec;
begin
  cl.Col := ACol;
  cl.Row := ARow;
  cell := GetCellObject(GetDisplayCell(cl));
  if Assigned(cell) then
  begin
    case Key of
      KEY_SPACE:
      begin
        if FCellKeyDown and not IsReadOnly(cl.Col, cl.Row) then
        begin
          if (Cell is TTMSFNCCheckGridCell) then
            (cell as TTMSFNCCheckGridCell).Checked := not (cell as TTMSFNCCheckGridCell).Checked
          else if (Cell is TTMSFNCRadioGridCell) then
            (cell as TTMSFNCRadioGridCell).Checked := not (cell as TTMSFNCRadioGridCell).Checked;
        end;
        FCellKeyDown := False;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.DoGetCellIsFixed(ACol, ARow: Integer; var ACellFixed: Boolean);
begin
  inherited;
  if Assigned(OnGetCellIsFixed) then
    OnGetCellIsFixed(Self, ACol, ARow, ACellFixed);
end;

procedure TTMSFNCCustomGrid.DoCellNodeClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell);
begin
  inherited;
  if Assigned(OnCellNodeClick) then
    OnCellNodeClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoCellRadioButtonClick(ACol,
  ARow: Integer; ACell: TTMSFNCGridCell);
begin
  inherited;
  if Assigned(OnCellRadioButtonClick) then
    OnCellRadioButtonClick(Self, ACol, ARow, ACell);
end;

procedure TTMSFNCCustomGrid.DoRadioButtonClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoCellRadioButtonClick(Cell.Col, Cell.Row, cl);
  end;
end;

procedure TTMSFNCCustomGrid.DoRowDragged(FromRow, ToRow: integer);
begin
  if Assigned(OnRowDragged) then
    OnRowDragged(Self, FromRow, ToRow);
end;

procedure TTMSFNCCustomGrid.DoRowSize(ARow: Integer;
  var NewHeight: Single);
begin
  inherited;
  if Assigned(OnRowSize) then
    OnRowSize(Self, ARow, NewHeight);
end;

procedure TTMSFNCCustomGrid.DoRowSized(ARow: Integer;
  NewHeight: Single);
begin
  inherited;
  if Assigned(OnRowSized) then
    OnRowSized(Self, ARow, NewHeight);
end;

procedure TTMSFNCCustomGrid.DoUpdateGridCellDisplay(ASelection: TTMSFNCGridCellRecRange);
var
  c, r, ci, ri: Integer;
  cell: TTMSFNCGridCell;
  bc, br, cs, rs: Integer;
  fc, fr: Integer;
  incfr, incfc: Boolean;
  cStart, cStop, rStart, rStop: Integer;
  clStart, clStop: TTMSFNCGridCellRec;
  l: TTMSFNCGridCellLayout;
begin
  l := TTMSFNCGridCellLayout.Create;
  fc := 0;
  FSaveTopCell := nil;
  incfc := False;

  cStart := ASelection.StartCol;
  cStop := ASelection.EndCol;
  rStart := ASelection.StartRow;
  rStop := ASelection.EndRow;

  clStart.Col := cStart;
  clStop.Col := cStop;
  clStart.Row := rStart;
  clStop.Row := rStop;

  clStart := GetDisplayCell(clStart);
  clStop := GetDisplayCell(clStop);

  for c := clStart.Col to clStop.Col do
  begin
    if (c >= 0) and (c <= Length(FCellArray) - 1) then
    begin
      if incfc then
        Inc(fc);

      fr := 0;
      incfr := false;
      for r := clStart.Row to clStop.Row do
      begin
        if (r >= 0) and (r <= Length(FCellArray[c]) - 1) then
        begin
          if incfr then
            inc(fr);

          cell := FCellArray[c, r];
          if Assigned(cell) then
          begin
            if cell is TTMSFNCGridCell then
            begin
              bc := c + FCStart - GetFixedCols;
              if bc > FCStop then
              begin
                bc := ColumnCount - FixedRightColumns + fc;
                incfc := True;
              end
              else if bc < FCStart then
                bc := c;

              br := r + FRStart - GetFixedRows;
              if br > FRStop then
              begin
                br := RowCount - FixedFooterRows + fr;
                incfr := True;
              end
              else if br < FRStart then
                br := r;

              cs := 0;
              rs := 0;
              DoGetCellMergeInfo(bc, br, bc, br, cs, rs);

              ci := bc;
              ri := br;

              ci := Max(0, Min(ci, ColumnCount - 1));
              ri := Max(0, Min(ri, RowCount - 1));

              if (ci = FocusedCell.Col) and (ri = FocusedCell.Row) then
                FSaveTopCell := cell;

              ApplyCellState(ci, ri, cell);

              if (Cell is TTMSFNCGridCell) then
              begin
                l.Assign((Cell as TTMSFNCGridCell).Layout);
                DoGetCellLayout(ci, ri, l, GetCellState(ci, ri));
                (Cell as TTMSFNCGridCell).AssignLayout(l);
              end;

              DoGetCellProperties(ci, ri, cell);
            end;
          end;
        end;
      end;
    end;
  end;
  l.Free;
end;

procedure TTMSFNCCustomGrid.DoGetCellReadOnly(ACol, ARow: Integer;
  var AReadOnly: Boolean);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.GetCellReadOnly(ACol, ARow, AReadOnly);

  if Assigned(OnGetCellReadOnly) then
    OnGetCellReadOnly(Self, ACol, ARow, AReadOnly);
end;

procedure TTMSFNCCustomGrid.DoGetCellRotation(ACol, ARow: Integer;
  var Angle: Integer);
begin
  inherited;
  if Assigned(OnGetCellRotation) then
    OnGetCellRotation(Self, ACol, ARow, Angle);
end;

procedure TTMSFNCCustomGrid.DoGetRowIsBand(ARow: Integer; var ARowBand: Boolean);
begin
  inherited;
  if Assigned(OnGetRowIsBand) then
    OnGetRowIsBand(Self, ARow, ARowBand);
end;

function TTMSFNCCustomGrid.DoIsFixedCellSelected(ACol, ARow: Integer): Boolean;
begin
  Result := ((ACol >= StartCell.Col) and (ACol <= StopCell.Col) and (Options.Selection.Mode <> smSingleRow) and (Options.Selection.Mode <> smDisjunctRow) and (Options.Selection.Mode <> smDisjunctCell)
    and (Options.Selection.Mode <> smRowRange)) or ((ARow >= StartCell.Row) and (ARow <= StopCell.Row)
      and (Options.Selection.Mode <> smSingleColumn) and (Options.Selection.Mode <> smDisjunctColumn) and (Options.Selection.Mode <> smDisjunctCell) and (Options.Selection.Mode <> smColumnRange));

  if Options.Selection.Mode = smDisjunctRow then
    Result := RowSelect[ARow]
  else if Options.Selection.Mode = smDisjunctColumn then
    Result := ColumnSelect[ACol]
  else if Options.Selection.Mode = smDisjunctCell then
    Result := FixedCellSelect[ACol, ARow];
end;

procedure TTMSFNCCustomGrid.DoCanInsertRow(ARow: Integer; var Allow: Boolean);
begin
  inherited;
  if Assigned(OnCanInsertRow) then
    OnCanInsertRow(Self, ARow, Allow);
end;

function TTMSFNCCustomGrid.DoIsCellSelected(ACol, ARow: Integer): boolean;
begin
  if (Options.Selection.Mode in [smDisjunctRow, smDisjunctColumn, smDisjunctCell]) then
    Result := inherited DoIsCellSelected(ACol,ARow)
  else
    Result := ((ACol >= StartCell.Col) and (ARow >= StartCell.Row) and
      (ACol <= StopCell.Col) and (ARow <= StopCell.Row));
end;

procedure TTMSFNCCustomGrid.DoNodeClick(Sender: TObject);
var
  cell: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCell;
begin
  if Sender is TTMSFNCGridCell then
  begin
    cl := Sender as TTMSFNCGridCell;
    cell := GetCellByObject(cl);
    if (Cell.Row <> -1) and (Cell.Col <> -1) then
      DoCellNodeClick(Cell.Col, Cell.Row, cl);
  end;
end;

procedure TTMSFNCCustomGrid.DoPaintCommentPopup(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  r: TRectF;
begin
  AGraphics.Font.Color := FActiveCommentTextColor;
  r := ARect;
  InflateRectEx(r, -4, -4);
  AGraphics.DrawText(r, FActiveComment, True, gtaLeading, gtaLeading);
end;

procedure TTMSFNCCustomGrid.DoCellSortClick(Shift: TShiftState; ACol, ARow: Integer; Direction: TTMSFNCGridSortDirection; ACell: TTMSFNCGridCell);
var
  AllowSort: Boolean;
  sortc: TTMSFNCFixedGridCell;
begin
  if Options.Sorting.Mode <> gsmNone then
  begin
    AllowSort := true;
    DoCanSortColumn(ACol, AllowSort);
    if AllowSort then
    begin
      sortc := (ACell as TTMSFNCFixedGridCell);
      if Assigned(sortc) then
      begin
        if (Options.Sorting.Mode = gsmIndexed) then
        begin
          if not (ssShift in Shift) then
          begin
            SortIndexes.Clear;
            ClearFixedSorting(sortc);
          end;
        end
        else
          ClearFixedSorting(sortc);

        case sortc.SortKind of
           skAscending:
          begin
            Direction := TTMSFNCGridSortDirection.sdDescending;

            if Options.Sorting.Mode = gsmIndexed then
            begin
              if SortIndexes.FindIndex(ACol) = -1 then
                SortIndexes.AddIndex(ACol, TTMSFNCGridSortDirection.sdDescending)
              else
                SortIndexes.ToggleIndex(ACol);

              sortc.SortIndex := SortIndexes.FindIndex(ACol) + 1;
            end
            else
              sortc.SortIndex := -1;

            sortc.SortKind := skDescending;

          end;
          skNone, skDescending:
          begin
            Direction := TTMSFNCGridSortDirection.sdAscending;
            if Options.Sorting.Mode = gsmIndexed then
            begin
              if SortIndexes.FindIndex(ACol) = -1 then
                SortIndexes.AddIndex(ACol, TTMSFNCGridSortDirection.sdAscending)
              else
                SortIndexes.ToggleIndex(ACol);

              sortc.SortIndex := SortIndexes.FindIndex(ACol) + 1;
            end
            else
              sortc.SortIndex := -1;

            sortc.SortKind := skAscending;
          end;
        end;

        inherited DoCellSortClick(Shift, ACol, ARow, Direction, ACell);

        if Assigned(OnCellSortClick) then
          OnCellSortClick(Self, ACol, ARow, ACell);

        DoColumnSorted(ACol, Direction);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.DragTimer(Sender: TObject);
var
  contentr: TRectF;
begin
  if not Options.Mouse.AutoDragging then
  begin
    FDragTimer.Enabled := False;
    Exit;
  end;

  contentr := GetContentRect;

  if (FX >= (contentr.Right - contentr.Left)) then
  begin
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      FDragScrollCell.Col := FDragScrollCell.Col + Round(FX - (contentr.Right - contentr.Left)) * Options.Mouse.AutoScrollingSpeed
    else
      FDragScrollCell.Col := FDragScrollCell.Col + Options.Mouse.AutoScrollingSpeed;
    Navigate(FDragScrollCell);
  end
  else if FX <= 0 then
  begin
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      FDragScrollCell.Col := FDragScrollCell.Col + Round(FX) * Options.Mouse.AutoScrollingSpeed
    else
      FDragScrollCell.Col := FDragScrollCell.Col - Options.Mouse.AutoScrollingSpeed;

    Navigate(FDragScrollCell);
  end;

  if (FY >= (contentr.Bottom - contentr.Top)) then
  begin
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      FDragScrollCell.Row := FDragScrollCell.Row + Round(FY - (contentr.Bottom - contentr.Top)) * Options.Mouse.AutoScrollingSpeed
    else
      FDragScrollCell.Row := FDragScrollCell.Row + Options.Mouse.AutoScrollingSpeed;
    Navigate(FDragScrollCell);
  end
  else if FY <= 0 then
  begin
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      FDragScrollCell.Row := FDragScrollCell.Row + Round(FY) * Options.Mouse.AutoScrollingSpeed
    else
      FDragScrollCell.Row := FDragScrollCell.Row - Options.Mouse.AutoScrollingSpeed;
    Navigate(FDragScrollCell);
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomGrid.EditApplyStyleLookup(Sender: TObject);
var
  bkg: TFmxObject;
  function FindStyleResourceEx(AControl: TControl; AResourceName: string): TFMXObject;
  var
    I: Integer;
  begin
    Result := nil;
    if Assigned(AControl) then
    begin
      Result := AControl.FindStyleResource(AResourceName);
      if not Assigned(Result) then
      begin
        for I := 0 to AControl.ControlCount - 1 do
        begin
          Result := FindStyleResourceEx(AControl.Controls[I], AResourceName);
          if Assigned(Result) then
            Break;
        end;
      end;
    end;
  end;
begin
  if (Sender is TControl) then
  begin
    bkg := FindStyleResourceEx((Sender as TControl), 'background');
    if Assigned(bkg) then
    begin
      if Assigned(bkg) and (bkg is TCustomStyleObject) then
        (bkg as TCustomStyleObject).Source := nil;
    end
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomGrid.EditCell(ACell: TTMSFNCGridCellRec; CellStart: TTMSFNCGridEditCellStart = csNone; InsertKey: Boolean = False; Value: string = ''; Key: Word = 0);
function CreateCaretPos(ALine, APos: Integer): TTMSFNCGridCaretPosition;
begin
  {$IFDEF FMXLIB}
  Result.Line := ALine;
  Result.Pos := APos;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Result.X := ALine;
  Result.Y := APos;
  {$ENDIF}
end;

var
  CellEditorClassType: TTMSFNCGridEditorClass;
  cellstring: String;
  ctl: TTMSFNCGridCell;
  realc: TTMSFNCGridCellRec;
  r: TRectF;
  AReadOnly: Boolean;
  EditorType: TTMSFNCGridEditorType;
  c: TTMSFNCGraphicsColor;
  dtc: Boolean;
  {$IFDEF CMNWEBLIB}
  s: String;
  {$ENDIF}
  {$IFDEF FMXLIB}
  k: Char;
  {$ENDIF}
  ectl: TTMSFNCGridEditor;
  ioe: ITMSFNCCustomEditor;
  {$IFDEF FMXLIB}
  p: Integer;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  fc: TTMSFNCGraphicsColor;
  {$ENDIF}
begin
  if not Options.Editing.Enabled or IsFixed(ACell.Col, ACell.Row) then
    Exit;

  Navigate(ACell);

  AReadOnly := False;
  DoGetCellReadOnly(ACell.Col, ACell.Row, AReadOnly);

  dtc := DoGetCellIsDataCheckBox(ACell.Col, ACell.Row);

  if AReadOnly or dtc then
    Exit;

  if Assigned(Adapter) then
    Adapter.CellBeforeEdit(ACell.Col, ACell.Row);

  realc := GetDisplayCell(ACell);

  ctl := nil;
  if (realc.Col >= 0) and (realc.Col <= Length(FCellArray) - 1) then
  begin
    if (realc.Row >= 0) and (realc.Row <= Length(FCellArray[realc.Col]) - 1) then
      ctl := FCellArray[realc.Col, realc.Row];
  end;

  if Assigned(ctl) and (ctl is TTMSFNCGridCell) then
  begin
    EditorType := etEdit;
    FEditControl := CellEdit;
    CellEditorClassType := TTMSFNCGridEdit;
    DoGetCellEditorType(ACell.Col, ACell.Row, EditorType);

    if EditorType = TTMSFNCGridEditorType.etCustom then
    begin
      DoGetCellEditorCustomClassType(ACell.Col, ACell.Row, CellEditorClassType);
      if CellEditorClassType <> nil then
      begin
        FEditControl := CellEditorClassType.Create(Self);
        {$IFDEF FMXLIB}
        FEditControl.DisableFocusEffect := True;
        {$ENDIF}
        {$IFDEF WEBLIB}
        FEditControl.ShowFocus := False;
        {$ENDIF}
        TTMSFNCGridProtectedControl(FEditControl).OnKeyDown := @CellControlKeyDown;
        TTMSFNCGridProtectedControl(FEditControl).OnExit := @CellExit;
      end;
    end
    else
    begin
      case EditorType of
        etMemo: FEditControl := CellMemo;
        etSpinBox: FEditControl := CellSpinBox;
        etColorPicker: FEditControl := CellColorPicker;
        etComboBox: FEditControl := CellComboBox;
        etDatePicker: FEditControl := CellDatePicker;
        etTrackBar: FEditControl := CellTrackBar;
        etEdit: CellEdit.EditType := etString;
        etNumericEdit: CellEdit.EditType := etNumeric;
        etSignedNumericEdit: CellEdit.EditType := etSignedNumeric;
        etFloatEdit: CellEdit.EditType := etFloat;
        etSignedFloatEdit: CellEdit.EditType := etSignedFloat;
        etUppercaseEdit: CellEdit.EditType := etUppercase;
        etMixedCaseEdit: CellEdit.EditType := etMixedCase;
        etLowerCaseEdit: CellEdit.EditType := etLowerCase;
        etMoneyEdit: CellEdit.EditType := etMoney;
        etHexEdit: CellEdit.EditType := etHex;
        etAlphaNumericEdit: CellEdit.EditType := etAlphaNumeric;
        etValidCharsEdit: CellEdit.EditType := etValidChars;
      end;

      if (EditorType in [etEdit]) and (Options.Editing.AutoComplete <> acDisabled) then
      begin
        CellEdit.Lookup.DisplayList.Assign(Options.Editing.AutoCompleteItems);
        CellEdit.AutoComplete := Options.Editing.AutoComplete = acNormal;
        CellEdit.Lookup.Enabled := Options.Editing.AutoComplete <> acNormal;
      end;
    end;

    if Assigned(FEditControl) then
    begin
      {$IFDEF FMXLIB}
      if FEditControl is TStyledControl then
      begin
        (FEditControl as TStyledControl).NeedStyleLookUp;
        (FEditControl as TStyledControl).Invalidate;
      end;
      {$ENDIF}

      if FEditControl is TComboBox then
        (FEditControl as TComboBox).Parent := Self;

      DoGetCellEditorProperties(ACell.Col, ACell.Row, FEditControl);

      if FEditControl is TComboBox then
        (FEditControl as TComboBox).Parent := nil;

      cellstring := Cells[ACell.Col, ACell.Row];

      if not Options.Editing.EditWithTags and TTMSFNCUtils.IsHTML(cellstring) then
        cellstring := TTMSFNCUtils.HTMLStrip(cellstring);

      c := Colors[ACell.Col, ACell.Row];
      if (FEditControl is TTMSFNCGridColorPicker) then
        DoCellEditGetColor(ACell.Col, ACell.Row, FEditControl, c)
      else
        DoCellEditGetData(ACell.Col, ACell.Row, FEditControl, cellstring);

      if Supports(FEditControl, ITMSFNCCustomEditor, ioe) then
      begin
        if InsertKey then
          ioe.SetText(Value);
      end;

      if (FEditControl is TTMSFNCGridEdit) or (FEditControl is TTMSFNCGridMemo) then
      begin
        if InsertKey then
        begin
          if (FEditControl is TTMSFNCGridMemo) then
            (FEditControl as TTMSFNCGridMemo).Text := '';
          if (FEditControl is TTMSFNCGridEdit) then
            (FEditControl as TTMSFNCGridEdit).Text := '';

          if (FEditControl is TTMSFNCGridEdit) and (EditorType in [etMoneyEdit, etFloatEdit, etSignedFloatEdit, etNumericEdit]) then
          begin
            TTMSFNCGridEdit(FEditControl).Parent := Self;
            TTMSFNCGridEdit(FEditControl).SelectAll;
            TTMSFNCGridEdit(FEditControl).Parent := nil;
          end;

          {$IFDEF FMXLIB}
          k := #0;
          if Length(Value) > 0 then
            k := Value[{$IFDEF ZEROSTRINGINDEX}0{$ELSE}1{$ENDIF}];

          TTMSFNCGridProtectedControl(FEditControl).KeyDown(Key, k, [])
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          s := Value;
          if (FEditControl is TTMSFNCGridMemo) then
            (FEditControl as TTMSFNCGridMemo).Text := s;
          if (FEditControl is TTMSFNCGridEdit) then
            (FEditControl as TTMSFNCGridEdit).Text := s;
          {$ENDIF}
        end
        else
        begin
          if (FEditControl is TTMSFNCGridMemo) then
            (FEditControl as TTMSFNCGridMemo).Text := cellString;
          if (FEditControl is TTMSFNCGridEdit) then
            (FEditControl as TTMSFNCGridEdit).Text := cellString;
        end;
      end
      else if (FEditControl is TTMSFNCGridTrackBar) then
      begin
        {$IFDEF FMXLIB}
        if (CellString <> '') then
          (FEditControl as TTMSFNCGridTrackBar).Value := StrToFloat(cellString)
        else
          (FEditControl as TTMSFNCGridTrackBar).Value := 0;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        if (CellString <> '') then
          (FEditControl as TTMSFNCGridTrackBar).Position := StrToInt(cellString)
        else
          (FEditControl as TTMSFNCGridTrackBar).Position := 0;
        {$ENDIF}
      end
      else if (FEditControl is TTMSFNCGridColorPicker) then
        (FEditControl as TTMSFNCGridColorPicker).SelectedColor := c
      else if FEditControl is TTMSFNCGridSpinBox then
        (FEditControl as TTMSFNCGridSpinBox).Text := cellString
      else if (FEditControl is TTMSFNCGridDatePicker) then
      begin
        (FEditControl as TTMSFNCGridDatePicker).Parent := Self;
        if cellString = '' then
        begin
          (FEditControl as TTMSFNCGridDatePicker).Date := Int(Now);
          (FEditControl as TTMSFNCGridDatePicker).Text := DateToStr(Now);
        end
        else
        begin
          (FEditControl as TTMSFNCGridDatePicker).Date := VarToDateTime(cellString);
          (FEditControl as TTMSFNCGridDatePicker).Text := cellstring;
        end;
        (FEditControl as TTMSFNCGridDatePicker).Parent := nil;
      end
      else if FEditControl is TTMSFNCGridComboBox then
      begin
        (FEditControl as TTMSFNCGridComboBox).Parent := Self;
        (FEditControl as TTMSFNCGridComboBox).ItemIndex := (FEditControl as TTMSFNCGridComboBox).Items.IndexOf(cellstring);
        (FEditControl as TTMSFNCGridComboBox).Parent := nil;
      end;

      {$IFDEF FMXLIB}
      FEditControl.StyleName := 'grideditcontrol';
      {$ENDIF}

      ectl := FEditControl;

      if ectl is TTMSFNCGridEdit then
      begin
        {$IFDEF VCLLIB}
        ectl.Parent := Self;
        {$ENDIF}
        {$IFNDEF FMXLIB}
        fc := (ectl as TTMSFNCGridEdit).Font.Color;
        {$ENDIF}

        (ectl as TTMSFNCGridEdit).Font.Assign(ctl.Layout.Font);
        {$IFNDEF FMXLIB}
        (ectl as TTMSFNCGridEdit).Font.Color := fc;
        case ctl.Layout.TextAlign of
          gtaCenter: (ectl as TTMSFNCGridEdit).Alignment := taCenter;
          gtaLeading: (ectl as TTMSFNCGridEdit).Alignment := taLeftJustify;
          gtaTrailing: (ectl as TTMSFNCGridEdit).Alignment := taRightJustify;
        end;
        {$ENDIF}

        {$IFDEF FMXLIB}
        case ctl.Layout.TextAlign of
          gtaCenter: (ectl as TTMSFNCGridEdit).TextSettings.HorzAlign := TTextAlign.Center;
          gtaLeading: (ectl as TTMSFNCGridEdit).TextSettings.HorzAlign := TTextAlign.Leading;
          gtaTrailing: (ectl as TTMSFNCGridEdit).TextSettings.HorzAlign := TTextAlign.Trailing;
        end;

        case ctl.Layout.VerticalTextAlign of
          gtaCenter: (ectl as TTMSFNCGridEdit).TextSettings.VertAlign  := TTextAlign.Center;
          gtaLeading: (ectl as TTMSFNCGridEdit).TextSettings.VertAlign := TTextAlign.Leading;
          gtaTrailing: (ectl as TTMSFNCGridEdit).TextSettings.VertAlign := TTextAlign.Trailing;
        end;
        {$ENDIF}

        {$IFDEF VCLLIB}
        ectl.Parent := nil;
        {$ENDIF}

        {$IFNDEF VCLLIB}
        r := ctl.GetTextRect;
        {$ELSE}
        r := ctl.GetRealTextRect;
        r.Top := Max(1, r.Top - ScalePaintValue(0.5));
        {$ENDIF}
      end
      else
        r := ctl.GetTextRect;

      {$IFDEF FMXLIB}
      ectl.SetBounds(ctl.Left + 1.5, ctl.Top + r.Top, ctl.Width - 0.5, r.Height);
      ectl.SetBounds(Round(ectl.Position.X + (r.Width - ectl.Width) / 2), Round(ectl.Position.Y + (r.Height - ectl.Height) / 2), Round(ectl.Width), Round(ectl.Height));
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      {$IFDEF VCLLIB}
      ectl.Parent := Self;
      {$ENDIF}
      ectl.SetBounds(Round(ctl.Left + r.Left), Round(ctl.Top + r.Top), Round(r.Right - r.Left), Round(r.Bottom - r.Top));
      ectl.SetBounds(Round(ectl.Left + ((r.Right - r.Left) - ectl.Width) / 2), Round(ectl.Top + ((r.Bottom - r.Top) - ectl.Height) / 2), ectl.Width, ectl.Height);
      if ((ectl is TCustomEdit) or (ectl is TCustomMemo)) and not (ectl is TTMSFNCGridSpinBox) and (ectl.Height > (r.Bottom - r.Top)) then
      begin
        {$IFDEF LCLLIB}
        ectl.Top := ectl.Top + 2;
        ectl.Height := ectl.Height - 4;
        ectl.Left := ectl.Left - 2;
        ectl.Width := ectl.Width + 4;
        {$ENDIF}
        {$IFNDEF LCLLIB}
        ectl.Top := ectl.Top + 4;
        ectl.Height := ectl.Height - 8;
        {$ENDIF}
      end;
      {$ENDIF}

      {$IFNDEF VCLLIB}
      FEditControl.Parent := Self;
      {$ENDIF}
      FEditControl.Visible := True;
      FEditControl.BringToFront;

      {$IFNDEF WEBLIB}
      if FEditControl.CanFocus then
        FEditControl.SetFocus;
      {$ENDIF}

      if Supports(FEditControl, ITMSFNCCustomEditor, ioe) then
      begin
        if InsertKey then
        begin
          ioe.SetSelStart(ioe.GetTextLength);
          ioe.SetSelLength(0);
        end
        else
        begin
          case CellStart of
            csNone:
            begin
              ioe.SetSelStart(0);
              ioe.SetSelLength(ioe.GetTextLength);
            end;
            csStart:
            begin
              ioe.SetSelStart(0);
              ioe.SetSelLength(0);
            end;
            csEnd:
            begin
              ioe.SetSelStart(ioe.GetTextLength);
              ioe.SetSelLength(0);
            end;
          end;
        end;
      end;

      if FEditControl is TTMSFNCGridEdit then
      begin
        {$IFDEF FMXLIB}
        p := (FEditControl as TTMSFNCGridEdit).CaretPosition;
        {$ENDIF}
        try
          if InsertKey then
          begin
            (FEditControl as TTMSFNCGridEdit).SelStart := Length((FEditControl as TTMSFNCGridEdit).Text);
            (FEditControl as TTMSFNCGridEdit).SelLength := 0;
          end
          else
          begin
            case CellStart of
              csNone:
              begin
                (FEditControl as TTMSFNCGridEdit).SelStart := 0;
                (FEditControl as TTMSFNCGridEdit).SelLength := Length((FEditControl as TTMSFNCGridEdit).Text);
              end;
              csStart:
              begin
                (FEditControl as TTMSFNCGridEdit).SelStart := 0;
                (FEditControl as TTMSFNCGridEdit).SelLength := 0;
              end;
              csEnd:
              begin
                (FEditControl as TTMSFNCGridEdit).SelStart := Length((FEditControl as TTMSFNCGridEdit).Text);
                (FEditControl as TTMSFNCGridEdit).SelLength := 0;
              end;
            end;
          end;
        finally
          {$IFDEF FMXLIB}
          (FEditControl as TTMSFNCGridEdit).CaretPosition := p;
          {$ENDIF}
        end;
      end;

      if FEditControl is TTMSFNCGridMemo then
      begin
        if InsertKey then
        begin
          if (FEditControl as TTMSFNCGridMemo).Lines.Count > 0 then
            (FEditControl as TTMSFNCGridMemo).CaretPosition := CreateCaretPos((FEditControl as TTMSFNCGridMemo).Lines.Count - 1, Length((FEditControl as TTMSFNCGridMemo).Lines[(FEditControl as TTMSFNCGridMemo).Lines.Count - 1]))
          else
            (FEditControl as TTMSFNCGridMemo).CaretPosition := CreateCaretPos(0, 0);

          (FEditControl as TTMSFNCGridMemo).SelLength := 0;
        end
        else
        begin
          case CellStart of
            csNone:
            begin
              (FEditControl as TTMSFNCGridMemo).CaretPosition := CreateCaretPos(0, 0);
              (FEditControl as TTMSFNCGridMemo).SelLength := (FEditControl as TTMSFNCGridMemo).Lines.Count - 1;
            end;
            csStart:
            begin
              (FEditControl as TTMSFNCGridMemo).CaretPosition := CreateCaretPos(0, 0);
              (FEditControl as TTMSFNCGridMemo).SelLength := 0;
            end;
            csEnd:
            begin
              if (FEditControl as TTMSFNCGridMemo).Lines.Count > 0 then
                (FEditControl as TTMSFNCGridMemo).CaretPosition := CreateCaretPos((FEditControl as TTMSFNCGridMemo).Lines.Count - 1, Length((FEditControl as TTMSFNCGridMemo).Lines[(FEditControl as TTMSFNCGridMemo).Lines.Count - 1]))
              else
                (FEditControl as TTMSFNCGridMemo).CaretPosition := CreateCaretPos(0, 0);

              (FEditControl as TTMSFNCGridMemo).SelLength := 0;
            end;
          end;
        end;
      end;

      if (Options.Editing.DirectComboDrop) then
      begin
        if (FEditControl is TTMSFNCGridComboBox) then
        {$IFDEF FMXLIB}
        if (FEditControl is TTMSFNCGridComboBox) then
          (FEditControl as TTMSFNCGridComboBox).DropDown;
        {$ENDIF}

        {$IFDEF CMNWEBLIB}
        if (FEditControl is TTMSFNCGridComboBox) then
          (FEditControl as TTMSFNCGridComboBox).DroppedDown := True;
        {$ENDIF}

        if (FEditControl is TTMSFNCGridDatePicker) then
        begin
          {$IFDEF FMXLIB}
          (FEditControl as TTMSFNCGridDatePicker).OpenPicker;
          {$ENDIF}
        end;
      end;

      {$IFDEF WEBLIB}
      if FEditControl.CanFocus then
        FEditControl.SetFocus;
      {$ENDIF}

      FEditCell := ACell;
      FEditing := true;
    end;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomGrid.EndUpdate;
begin
  inherited;
  if Assigned(FDragTimer) then
    FDragTimer.Interval := Options.Mouse.AutoScrollingInterval;

  if Assigned(FScrollTimer) then
    FScrollTimer.Interval := Options.Mouse.AutoScrollingInterval;
end;

procedure TTMSFNCCustomGrid.ExportNotification(AState: TTMSFNCGridExportState;
  ARow: Integer);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.ExportNotification(AState, ARow);

  {$IFDEF VCLLIB}
  if AState = esExportStart then
    SendMessage(Handle, WM_SETREDRAW, 0, 0);

  if AState = esExportDone then
  begin
    SendMessage(Handle, WM_SETREDRAW, 1, 0);
    RedrawWindow(Handle, nil, 0, RDW_ERASE or RDW_INVALIDATE or RDW_FRAME or RDW_ALLCHILDREN);
  end;
  {$ENDIF}
end;

{$IFDEF FMXLIB}
function TTMSFNCCustomGrid.FindPopup(AControl: TComponent): TPopup;
var
  i: integer;
begin
  Result := nil;
  if not Assigned(AControl) then
    Exit;

  for I := 0 to AControl.ComponentCount - 1 do
  begin
    if AControl.Components[I] is TPopup then
    begin
      Result := AControl.Components[I] as TPopup;
      Break;
    end
    else
      Result := FindPopup(AControl.Components[i]);
  end;
end;
{$ENDIF}

function TTMSFNCCustomGrid.GetCell(ACell: TTMSFNCGridCellRec): TTMSFNCGridCell;
begin
  ACell := GetDisplayCell(ACell);
  Result := nil;
  if (ACell.Col >= 0) and (ACell.Col <= Length(FCellArray) - 1) then
    if (ACell.Row >= 0) and (ACell.Row <= Length(FcellArray[ACell.Col]) - 1) then
      Result := FCellArray[ACell.Col, ACell.Row];
end;

function TTMSFNCCustomGrid.GetCellAppearance(AState: TTMSFNCGridCellState; ACol, ARow: Integer): TTMSFNCGridCellLayout;
var
  c: TTMSFNCGridCellLayout;
  rr: Integer;
begin
  case AState of
    csNormal:
    begin
      if GroupColumn <> -1 then
      begin
        rr := ARow;
        if IsNode(rr) then
          c := GetDefaultGroupLayout
        else if IsSummary(rr) then
          c := GetDefaultSummaryLayout
        else
        begin
          if IsBand(DisplToRealRow(ARow)) and Options.Bands.Enabled then
            c := GetDefaultBandLayout
          else
            c := GetDefaultNormalLayout;
        end;
      end
      else
      begin
        if IsBand(DisplToRealRow(ARow)) and Options.Bands.Enabled then
          c := GetDefaultBandLayout
        else
          c := GetDefaultNormalLayout;
      end;
    end;
    csFocused: c := GetDefaultFocusedLayout;
    csFixed: c := GetDefaultFixedLayout;
    csFixedSelected: c := GetDefaultFixedSelectedLayout;
    csSelected: c := GetDefaultSelectedLayout;
    else
      c := nil;
  end;

  Result := c;
end;

function TTMSFNCCustomGrid.GetCellByObject(Cell: TTMSFNCGridCell): TTMSFNCGridCellRec;
var
  c: Integer;
  r: Integer;
begin
  Result.Col := -1;
  Result.Row := -1;
  for c := 0 to Length(FCellArray) - 1 do
  begin
    for r := 0 to Length(FCellArray[c]) - 1 do
    begin
      if FCellArray[c, r] = Cell then
      begin
        Result.Col := c;
        Result.Row := r;
        Result := GetRealCell(Result);
        Exit;
      end;
    end;
  end;
end;

function TTMSFNCCustomGrid.GetCellColorPicker: TTMSFNCGridColorPicker;
begin
  if not Assigned(FCellColorPicker) then
  begin
    FCellColorPicker := TTMSFNCGridColorPicker.Create(Self);
    FCellColorPicker.CloseOnSelection := True;
    FCellColorPicker.Mode := csmExtended;
    FCellColorPicker.OnKeyDown := @CellComboBoxKeyDown;
    FCellColorPicker.OnExit := @CellExit;
  end;

  Result := FCellColorPicker;
end;

function TTMSFNCCustomGrid.GetCellComboBox: TTMSFNCGridComboBox;
begin
  if not Assigned(FCellComboBox) then
  begin
    FCellComboBox := TTMSFNCGridComboBox.Create(Self);
    {$IFDEF FMXLIB}
    FCellComboBox.DisableFocusEffect := True;
    FCellComboBox.OnClosePopup := CellComboBoxCloseUp;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FCellComboBox.Style := csDropDownList;
    FCellComboBox.OnChange := @CellComboBoxChange;
    {$ENDIF}
    {$IFDEF WEBLIB}
    FCellComboBox.ShowFocus := False;
    {$ENDIF}
    FCellComboBox.OnKeyDown := @CellComboBoxKeyDown;
    FCellComboBox.OnExit := @CellExit;
  end;

  Result := FCellComboBox;
end;

function TTMSFNCCustomGrid.GetCellContainerPosX: Integer;
begin
  Result := FCellPosX;
end;

function TTMSFNCCustomGrid.ColumnStretchingActive: Boolean;
begin
  Result := Options.ColumnSize.Stretch and Options.ColumnSize.StretchAll;
end;

function TTMSFNCCustomGrid.GetCellContainerPosY: Integer;
begin
  Result := FCellPosY;
end;

function TTMSFNCCustomGrid.GetCellDatePicker: TTMSFNCGridDatePicker;
begin
  if not Assigned(FCellDatePicker) then
  begin
    FCellDatePicker := TTMSFNCGridDatePicker.Create(Self);
    {$IFDEF FMXLIB}
    FCellDatePicker.DisableFocusEffect := True;
    {$ENDIF}
    {$IFDEF WEBLIB}
    FCellDatePicker.ShowFocus := False;
    {$ENDIF}
    FCellDatePicker.OnKeyDown := @CellComboBoxKeyDown;
    FCellDatePicker.OnExit := @CellExit;
  end;

  Result := FCellDatePicker;
end;

function TTMSFNCCustomGrid.GetCellEdit: TTMSFNCGridEdit;
begin
  if not Assigned(FCellEdit) then
  begin
    FCellEdit := TTMSFNCGridEdit.Create(Self);
    {$IFDEF FMXLIB}
    FCellEdit.DisableFocusEffect := True;
    FCellEdit.TextAlign := TTextAlign.Leading;
    FCellEdit.OnApplyStyleLookup := EditApplyStyleLookup;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FCellEdit.BorderStyle := bsNone;
    FCellEdit.AutoSize := False;
    {$IFDEF VCLLIB}
    FCellEdit.ParentCtl3D := False;
    FCellEdit.Ctl3D := False;
    {$ENDIF}
    {$IFDEF WEBLIB}
    FCellEdit.ShowFocus := False;
    {$ENDIF}
    FCellEdit.TabStop := False;
    FCellEdit.BorderStyle := bsNone;
    FCellEdit.DoubleBuffered := False;
    {$ENDIF}
    FCellEdit.OnKeyDown := @CellEditKeyDown;
    FCellEdit.OnExit := @CellExit;
  end;

  Result := FCellEdit;
end;

function TTMSFNCCustomGrid.GetCellMemo: TTMSFNCGridMemo;
begin
  if not Assigned(FCellMemo) then
  begin
    FCellMemo := TTMSFNCGridMemo.Create(Self);
    {$IFDEF FMXLIB}
    FCellMemo.DisableFocusEffect := True;
    FCellMemo.OnApplyStyleLookup := EditApplyStyleLookup;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FCellMemo.BorderStyle := bsNone;
    FCellMemo.AutoSize := False;
    {$IFDEF VCLLIB}
    FCellMemo.ParentCtl3D := False;
    FCellMemo.Ctl3D := False;
    {$ENDIF}
    FCellMemo.TabStop := False;
    FCellMemo.BorderStyle := bsNone;
    FCellMemo.DoubleBuffered := False;
    {$ENDIF}
    {$IFDEF WEBLIB}
    FCellMemo.ShowFocus := False;
    {$ENDIF}
    FCellMemo.OnKeyDown := @CellEditKeyDown;
    FCellMemo.OnExit := @CellExit;
  end;

  Result := FCellMemo;
end;

function TTMSFNCCustomGrid.GetCellObject(ACell: TTMSFNCGridCellRec): TTMSFNCGridCell;
begin
  Result := nil;
  if (ACell.Col >= 0) and (ACell.Col <= Length(FCellArray) - 1) then
    if (ACell.Row >= 0) and (ACell.Row <= Length(FcellArray[ACell.Col]) - 1) then
      Result := FCellArray[ACell.Col, ACell.Row];
end;

function TTMSFNCCustomGrid.GetCellSpinBox: TTMSFNCGridSpinBox;
begin
  if not Assigned(FCellSpinBox) then
  begin
    FCellSpinBox := TTMSFNCGridSpinBox.Create(Self);
    {$IFDEF FMXLIB}
    FCellSpinBox.DisableFocusEffect := True;
    FCellSpinBox.TextAlign := TTextAlign.Leading;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FCellSpinBox.BorderStyle := bsNone;
    FCellSpinBox.AutoSize := False;
    {$IFDEF VCLLIB}
    FCellSpinBox.ParentCtl3D := False;
    FCellSpinBox.Ctl3D := False;
    {$ENDIF}
    {$IFDEF WEBLIB}
    FCellSpinBox.ShowFocus := False;
    {$ENDIF}
    FCellSpinBox.TabStop := False;
    FCellSpinBox.BorderStyle := bsNone;
    FCellSpinBox.DoubleBuffered := False;
    {$ENDIF}
    FCellSpinBox.OnKeyDown := @CellControlKeyDown;
    FCellSpinBox.OnExit := @CellExit;
  end;

  Result := FCellSpinBox;
end;

function TTMSFNCCustomGrid.GetCellState(ACol, ARow: Integer): TTMSFNCGridCellState;
begin
  if (Options.Selection.Mode <> smNone) and (FocusedCell.Row = ARow) and (FocusedCell.Col = ACol) then
    Result := csFocused
  else
  begin
    if IsFixed(ACol, ARow) then
    begin
      if (Options.Selection.Mode <> smNone) and Options.Selection.ShowSelectionInFixedCells and DoIsFixedCellSelected(ACol,ARow) then
        Result := csFixedSelected
      else
        Result := csFixed
    end
    else if (Options.Selection.Mode <> smNone) and DoIsCellSelected(ACol,ARow) then
      Result := csSelected
    else
      Result := csNormal;
  end;
end;

function TTMSFNCCustomGrid.GetCellTrackBar: TTMSFNCGridTrackBar;
begin
  if not Assigned(FCellTrackBar) then
  begin
    FCellTrackBar := TTMSFNCGridTrackBar.Create(Self);
    {$IFDEF FMXLIB}
    FCellTrackBar.DisableFocusEffect := True;
    {$ENDIF}
    {$IFDEF WEBLIB}
    FCellTrackBar.ShowFocus := False;
    {$ENDIF}
    FCellTrackBar.OnKeyDown := @CellControlKeyDown;
    FCellTrackBar.OnExit := @CellExit;
  end;

  Result := FCellTrackBar;
end;

function TTMSFNCCustomGrid.GetColumnDisplayName(ACol: Integer): string;
begin
  Result := inherited GetColumnDisplayName(ACol);
  if Assigned(Adapter) then
    Result := Adapter.GetColumnDisplayName(ACol);
end;

function TTMSFNCCustomGrid.GetColumnViewPortSize: Single;
var
  I,cnt: Integer;
  w: Single;
  contentr: TRectF;
begin
  Result := 0;
  w := GetFixedWidth + GetFixedRightWidth;
  cnt := 0;
  contentr := GetContentRect;
  for I := ColumnCount - 1 - FixedRightColumns downto 0 do
  begin
    w := w + ColumnWidths[I];
    if w > (contentr.Right - contentr.Left) then
    begin
      Result := Max(1, cnt);
      Break;
    end;
    Inc(cnt);
  end;
end;

function TTMSFNCCustomGrid.GetBottomRow: Integer;
begin
  Result := FRStop;
end;

function TTMSFNCCustomGrid.GetDummyButtonBitmap(
  ACell: TTMSFNCButtonGridCell): TTMSFNCBitmap;
begin
  if Assigned(FDummyButtonBitmap) and ((FDummyButtonBitmap.Width <> ACell.Width)
    or (FDummyButtonBitmap.Height <> ACell.Height)) then
  begin
    FDummyButtonBitmap.Free;
    FDummyButtonBitmap := nil;
  end;

  if not Assigned(FDummyButtonBitmap) then
  begin
    FDummyButtonBitmap := TTMSFNCBitmap.Create;
    {$IFDEF CMNLIB}
    FDummyButtonBitmap.Bitmap.SetSize(ACell.ButtonWidth, ACell.ButtonHeight);
    {$ENDIF}
    {$IFDEF WEBLIB}
    FDummyButtonBitmap.SetSize(ACell.ButtonWidth, ACell.ButtonHeight);
    {$ENDIF}
    {$IFDEF FMXLIB}
    FDummyButtonBitmap.SetSize(ACell.ButtonWidth, ACell.ButtonHeight);
    {$ENDIF}
  end;

  Result := FDummyButtonBitmap;
end;

function TTMSFNCCustomGrid.GetC: TCursor;
begin
  Result := inherited Cursor;
end;

function TTMSFNCCustomGrid.GetDefaultBandLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.BandLayout;
end;

function TTMSFNCCustomGrid.GetDefaultFixedLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.FixedLayout;
end;

function TTMSFNCCustomGrid.GetDefaultFixedSelectedLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.FixedSelectedLayout;
end;

function TTMSFNCCustomGrid.GetDefaultFocusedLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.FocusedLayout;
end;

function TTMSFNCCustomGrid.GetDefaultGroupLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.GroupLayout;
end;

function TTMSFNCCustomGrid.GetDefaultNormalLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.NormalLayout;
end;

function TTMSFNCCustomGrid.GetDefaultSelectedLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.SelectedLayout;
end;

function TTMSFNCCustomGrid.GetDefaultSummaryLayout: TTMSFNCGridCellLayout;
begin
  Result := Appearance.SummaryLayout;
end;

function TTMSFNCCustomGrid.GetDisplayCell(ACell: TTMSFNCGridCellRec): TTMSFNCGridCellRec;
var
  maxc, maxr: Integer;
begin
  maxc := Length(FCellArray) - 1;

  if ACell.Col > ColumnCount - 1 - FixedRightColumns then
    Result.Col := FCStop - FCStart + FixedRightColumns + FixedColumns - (ColumnCount - ACell.Col - 1)
  else if ACell.Col >= GetFixedCols then
    Result.Col := ACell.Col - FCStart + GetFixedCols
  else
    Result.Col := ACell.Col;

  Result.Col := Max(0, Min(Result.Col, maxc));

  maxr := 0;
  if (Result.Col >= 0) and (Result.Col <= maxc) then
  begin
    maxr := Length(FCellArray[Result.Col]) - 1;
    if ACell.Row > RowCount - 1 - FixedRightColumns then
      Result.Row := FRStop - FRStart + FixedFooterRows + FixedRows - (RowCount - ACell.Row - 1)
    else if ACell.Row >= GetFixedRows then
      Result.Row := ACell.Row - FRStart + GetFixedRows
    else
      Result.Row := ACell.Row;
  end;

  Result.Row := Max(0, Min(Result.Row, maxr));
end;

function TTMSFNCCustomGrid.GetDisplayCellByObject(Cell: TTMSFNCGridCell): TTMSFNCGridCellRec;
var
  c: Integer;
  r: Integer;
begin
  Result.Col := -1;
  Result.Row := -1;
  for c := 0 to Length(FCellArray) - 1 do
  begin
    for r := 0 to Length(FCellArray[c]) - 1 do
    begin
      if FCellArray[c, r] = Cell then
      begin
        Result.Col := c;
        Result.Row := r;
        Exit;
      end;
    end;
  end;
end;

function TTMSFNCCustomGrid.GetFixedCols: Integer;
begin
  Result := FixedColumns + FreezeColumns;
end;

function TTMSFNCCustomGrid.GetFixedFooterHeight: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := RowCount - 1 downto RowCount - FixedFooterRows do
    Result := Result + RowHeights[I];
end;

function TTMSFNCCustomGrid.GetFixedHeight: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to FixedRows + FreezeRows - 1 do
    Result := Result + RowHeights[I];
end;

function TTMSFNCCustomGrid.GetFixedOnlyHeight: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to FixedRows - 1 do
    Result := Result + RowHeights[I];
end;

function TTMSFNCCustomGrid.GetFixedOnlyWidth: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to FixedColumns - 1 do
    Result := Result + ColumnWidths[I];
end;

function TTMSFNCCustomGrid.GetFixedRightWidth: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := ColumnCount - 1 downto ColumnCount - FixedRightColumns do
    Result := Result + ColumnWidths[I];
end;

function TTMSFNCCustomGrid.GetFixedRows: Integer;
begin
  Result := FixedRows + FreezeRows;
end;

function TTMSFNCCustomGrid.GetFixedWidth: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to FixedColumns + FreezeColumns - 1 do
    Result := Result + ColumnWidths[I];
end;

function TTMSFNCCustomGrid.GetHorizontalContentCount: Integer;
begin
  Result := ColumnCount - FixedColumns - FreezeColumns - FixedRightColumns;
end;

function TTMSFNCCustomGrid.GetHorizontalContentViewPortSize: Double;
begin
  Result := GetColumnViewPortSize;
end;

function TTMSFNCCustomGrid.GetHorizontalPos(AStartCol, AStopCol: Integer): Single;
var
  I: Integer;
begin
  Result := 0;
  for I := AStartCol to AStopCol - 1 do
    Result := Result + ColumnWidths[I];
end;

function TTMSFNCCustomGrid.GetLeftCol: Integer;
begin
  Result := FCStart;
end;

function TTMSFNCCustomGrid.GetNodeBitmap(
  AState: TTMSFNCGridNodeState): TTMSFNCBitmap;
begin
  case AState of
    nsOpen:
    begin
      if not Assigned(FNodeClosedBitmap) then
        FNodeClosedBitmap := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCGRIDCELLNODECLOSED, HInstance));

      Result := FNodeClosedBitmap;
    end;
    nsClosed:
    begin
      if not Assigned(FNodeOpenBitmap) then
        FNodeOpenBitmap := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCGRIDCELLNODEOPEN, HInstance));

      Result := FNodeOpenBitmap;
    end;
    else
      Result := nil;
  end;
end;

function TTMSFNCCustomGrid.GetPageScrollSize: Integer;
begin
  if (Options.Keyboard.PageScrollSize = 0) and (GetVerticalScrollMax * RowCount > 0) then
    Result := Round(GetVerticalContentViewPortSize / GetVerticalScrollMax * RowCount)
  else
    Result := Options.Keyboard.PageScrollSize;
end;

function TTMSFNCCustomGrid.GetPrevFocusedCell: TTMSFNCGridCellRec;
begin
  Result := FPrevSelectedCell;
end;

function TTMSFNCCustomGrid.GetRealCell(ACell: TTMSFNCGridCellRec): TTMSFNCGridCellRec;
begin
  if ACell.Col > Length(FCellArray) - 1 - FixedRightColumns then
    Result.Col := (ColumnCount - 1) - (Length(FCellArray) - 1 - ACell.Col)
  else if ACell.Col >= GetFixedCols then
    Result.Col := ACell.Col + FCStart - GetFixedCols
  else
    Result.Col := ACell.Col;

  if (ACell.Col >= 0) and (ACell.Col <= Length(FCellArray) - 1) then
  begin
    if ACell.Row > Length(FCellArray[ACell.Col]) - 1 - FixedRightColumns then
      Result.Row := (RowCount - 1) - (Length(FCellArray[ACell.Col]) - 1 - ACell.Row)
    else if ACell.Row >= GetFixedRows then
      Result.Row := ACell.Row + FRStart - GetFixedRows
    else
      Result.Row := ACell.Row;
  end;
end;

function TTMSFNCCustomGrid.GetRightCol: Integer;
begin
  Result := FCStop;
end;

function TTMSFNCCustomGrid.GetRowViewPortSize: Single;
var
  I,cnt: Integer;
  h: Single;
  contentr: TRectF;
begin
  Result := 0;
  h := GetFixedHeight + GetFixedFooterHeight;
  cnt := 0;
  contentr := GetContentRect;
  for I := RowCount - 1 - FixedFooterRows downto 0 do
  begin
    h := h + RowHeights[I];
    if h > (contentr.Bottom - contentr.Top) then
    begin
      Result := Max(1, cnt);
      Break;
    end;
    Inc(cnt);
  end;
end;

function TTMSFNCCustomGrid.GetTopRow: Integer;
begin
  Result := FRStart;
end;

function TTMSFNCCustomGrid.GetTotalContentHeight: Double;
begin
  inherited;
  Result := FContainerHeight;
end;

function TTMSFNCCustomGrid.GetTotalContentWidth: Double;
begin
  inherited;
  Result := FContainerWidth;
end;

function TTMSFNCCustomGrid.GetTotalColumnWidth: Single;
var
  I: Integer;
begin
  Result := ((ColumnCount - Min(ColumnCount, ColumnW.Count)) * DefaultColumnWidth);
  for I := 0 to Min(ColumnCount - 1, ColumnW.Count - 1) do
    Result := Result + ColumnW[I].Value;
end;

function TTMSFNCCustomGrid.GetTotalRowHeight: Single;
var
  I: Integer;
begin
  Result := ((RowCount - Min(RowCount, RowH.Count)) * DefaultRowHeight);
  for I := 0 to Min(RowCount - 1, RowH.Count - 1) do
    Result := Result + RowH[I].Value;
end;

function TTMSFNCCustomGrid.GetDocURL: string;
begin
  Result := TTMSFNCGridDocURL;
end;

function TTMSFNCCustomGrid.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomGrid.GetVerticalContentCount: Integer;
begin
  Result := RowCount - FixedRows - FreezeRows - FixedFooterRows;
end;

function TTMSFNCCustomGrid.GetVerticalContentViewPortSize: Double;
begin
  Result := GetRowViewPortSize;
end;

function TTMSFNCCustomGrid.GetVerticalPos(AStartRow, AStopRow: Integer): Single;
var
  I: Integer;
begin
  Result := 0;
  for I := AStartRow to AStopRow - 1 do
    Result := Result + RowHeights[I];
end;

function TTMSFNCCustomGrid.HideEdit(AEnterKey: Boolean = False; ATestHiding: Boolean = False): Boolean;
var
  str: String;
  allow: Boolean;
  cl, rl: TTMSFNCGridCellRec;
  nav: Boolean;
  cellEditor: TTMSFNCGridEditorType;
  col: TTMSFNCGraphicsColor;
  {$IFDEF FMXLIB}
  p: TPopup;
  {$ENDIF}
  r, c: Integer;
begin
  Result := True;
  if not Assigned(FEditControl) or FBlockHide then
    Exit;

  FBlockHide := True;

  try
    if Assigned(FEditControl) then
    begin
      str := '';
      if FEditControl is TTMSFNCGridEdit then
        str := (FEditControl as TTMSFNCGridEdit).Text
      else if FEditControl is TTMSFNCGridMemo then
        str := (FEditControl as TTMSFNCGridMemo).Text
      else if FEditControl is TTMSFNCGridSpinBox then
        str := (FEditControl as TTMSFNCGridSpinBox).Text
      else if FEditControl is TTMSFNCGridDatePicker then
        str := DateToStr((FEditControl as TTMSFNCGridDatePicker).Date)
      else if FEditControl is TTMSFNCGridColorPicker then
        col := (FEditControl as TTMSFNCGridColorPicker).SelectedColor
      else if FEditControl is TTMSFNCGridTrackBar then
      {$IFDEF FMXLIB}
        str := FloatToStr((FEditControl as TTMSFNCGridTrackBar).Value)
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
        str := IntToStr((FEditControl as TTMSFNCGridTrackBar).Position)
      {$ENDIF}
      {$IFDEF FMXLIB}
      else if (FEditControl is TTMSFNCGridComboBox) and Assigned((FEditControl as TTMSFNCGridComboBox).Selected) then
        str := (FEditControl as TTMSFNCGridComboBox).Selected.Text;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      else if (FEditControl is TTMSFNCGridComboBox) then
        str := (FEditControl as TTMSFNCGridComboBox).Text;
      {$ENDIF}

      Allow := True;
      if (FEditControl is TTMSFNCGridColorPicker) then
        DoCellEditValidateColor(FEditCell.Col, FEditCell.Row, FEditControl, col, Allow)
      else
        DoCellEditValidateData(FEditCell.Col, FEditCell.Row, FEditControl, str, Allow);

      if ATestHiding then
      begin
        Result := Allow;
        Exit;
      end;

      if Allow then
      begin
        FEditing := false;

        if (FEditControl is TTMSFNCGridColorPicker) then
          DoCellEditSetColor(FEditCell.Col, FEditCell.Row, FEditControl, col)
        else
          DoCellEditSetData(FEditCell.Col, FEditCell.Row, FEditControl, str);

        if Options.Editing.AutoHistory and (FEditControl is TTMSFNCGridEdit) then
        begin
          Options.Editing.AutoCompleteItems.Add(str);
        end;

        DoCellEditDone(FEditCell.Col, FEditCell.Row, FEditControl);

        celleditor := etEdit;
        DoGetCellEditorType(FEditCell.Col, FEditCell.Row, cellEditor);

        if cellEditor <> TTMSFNCGridEditorType.etCustom then
        begin
          {$IFDEF FMXLIB}
          p := FindPopup(FEditControl);
          if Assigned(p) then
          begin
            if p.IsOpen then
              p.IsOpen := False;
          end;
          {$ENDIF}

          if Assigned(FEditControl) then
          begin
            FEditControl.Parent := nil;
            FEditControl := nil;
          end;
        end
        else
          FFreeEditControlTimer.Enabled := True;

        nav := false;
        cl := FocusedCell;
        rl := FRealCell;

        if AEnterKey then
        begin
          case Options.Keyboard.EnterKeyHandling of
            ekhNextColumn, ekhNextColumnInRow, ekhNextColumnAndAppend, ekhNextColumnAndRowAppend:
            begin
              nav := True;
              c := cl.Col;
              cl := NextSelectableColumn(cl.Col, cl.Row);
              cl.Row := rl.Row;

              Allow := True;
              DoCanAppendColumn(cl.Col + 1, Allow);
              if Allow and (Options.Keyboard.EnterKeyHandling = ekhNextColumnAndAppend) then
              begin
                while (c = cl.Col) do
                begin
                  InsertRow(ColumnCount - FixedRightColumns);
                  cl := NextSelectableColumn(cl.Col, cl.Row);
                  DoAppendColumn(ColumnCount - FixedRightColumns - 1);
                end;
              end;

              if (c = cl.Col) then
              begin
                r := cl.row;
                cl.Col := FixedColumns - 1;
                cl := NextSelectableColumn(cl.Col, cl.Row);

                if r = cl.Row then
                begin
                  if Options.Keyboard.EnterKeyHandling = ekhNextColumnInRow then
                    cl := NextSelectableColumn(FixedColumns - 1, cl.Row - 1)
                  else
                  begin
                    Allow := True;
                    DoCanAppendRow(cl.Row + 1, Allow);
                    if Allow and (cl.Row = RowCount - 1 - FixedFooterRows) then
                    begin
                      if (Options.KeyBoard.EnterKeyHandling = ekhNextColumnAndRowAppend) then
                      begin
                        while (r = cl.Row) do
                        begin
                          InsertRow(RowCount - FixedFooterRows);
                          cl := NextSelectableColumn(FixedColumns - 1, cl.Row + 1);
                          DoAppendRow(RowCount - FixedFooterRows - 1);
                        end;
                      end;
                    end
                    else
                      cl := NextSelectableColumn(FixedColumns - 1, cl.Row + 1);
                  end;

                  while (cl.Col = FixedColumns - 1) and (cl.Row = r + 1) do
                  begin
                    r := cl.Row;
                    cl := NextSelectableColumn(FixedColumns - 1, cl.row + 1);
                  end;
                end;
              end;
            end;
            ekhNextRow, ekhNextRowInColumn, ekhNextRowAndAppend, ekhNextRowAndColumnAppend:
            begin
              nav := True;
              r := cl.Row;
              cl := NextSelectableRow(cl.Col, cl.Row);
              cl.Col := rl.Col;

              Allow := True;
              DoCanAppendRow(cl.Row + 1, Allow);
              if Allow and (Options.Keyboard.EnterKeyHandling = ekhNextRowAndAppend) then
              begin
                while (r = cl.Row) do
                begin
                  InsertRow(RowCount - FixedFooterRows);
                  cl := NextSelectableRow(cl.Col, cl.Row);
                  DoAppendRow(RowCount - FixedFooterRows - 1);
                end;
              end;

              if (r = cl.Row) then
              begin
                c := cl.Col;
                cl.Row := FixedRows - 1;
                cl := NextSelectableRow(cl.Col, cl.Row);

                if c = cl.Col then
                begin
                  if Options.Keyboard.EnterKeyHandling = ekhNextColumnInRow then
                    cl := NextSelectableRow(cl.Col - 1, FixedRows - 1)
                  else
                  begin
                    Allow := True;
                    DoCanAppendColumn(cl.Col + 1, Allow);
                    if Allow and (cl.Col = ColumnCount - 1 - FixedRightColumns) then
                    begin
                      if (Options.KeyBoard.EnterKeyHandling = ekhNextRowAndColumnAppend) then
                      begin
                        while (c = cl.Col) do
                        begin
                          InsertColumn(ColumnCount - FixedRightColumns);
                          cl := NextSelectableRow(cl.Col + 1, FixedRows - 1);
                          DoAppendColumn(ColumnCount - FixedRightColumns - 1);
                        end;
                      end;
                    end
                    else
                      cl := NextSelectableRow(cl.Col + 1, FixedRows - 1);
                  end;

                  while (cl.Row = FixedRows - 1) and (cl.Col = c + 1) do
                  begin
                    c := cl.Col;
                    cl := NextSelectableRow(cl.Col + 1, FixedRows - 1);
                  end;
                end;
              end;
            end;
          end;
        end;

        if CanFocus then
          SetFocus;

        if nav then
        begin
          SelectCell(cl);
          if AEnterKey and Options.Keyboard.EnterKeyDirectEdit then
          begin
            Allow := True;
            DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
            if Allow then
              EditCell(FocusedCell);
          end;
        end;


        Result := True;
      end
      else
        Result := False;
    end;
  finally
    FBlockHide := False;
  end;
end;

{$IFDEF LCLLIB}
procedure TTMSFNCCustomGrid.HandleUTF8KeyPress(var {%$H-}UTF8Key: TUTF8Char);
var
  Allow: boolean;
  s: string;
  k: Integer;
begin
  inherited;
  s := UTF8Key;
  k := 0;
  if Length(s) >= 1 then
    k := Ord(s[1]);

  if k in [KEY_TAB] then
    Exit;

  if not Options.Editing.Enabled and Options.Lookup.Enabled then
  begin
    if k in [KEY_ESCAPE,KEY_INSERT,KEY_DELETE,KEY_UP,KEY_DOWN,KEY_LEFT,KEY_RIGHT,KEY_HOME,KEY_END,KEY_PRIOR,KEY_NEXT,KEY_TAB] then
      FLookupString := ''
    else
    begin
      if Options.Lookup.Incremental then
        FLookupString := FLookupString + s
      else
        FLookupString := s;

      LookupInColumn(Focusedcell.Col,FLookupString,true,true);
    end;
  end;

  if Options.Editing.Enabled then
  begin
    Allow := True;
    DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);

    if Allow then
    begin
      if (k >= 32) then
      begin
        EditCell(FocusedCell, csStart, True, s, k);
        UTF8Key := #0;
        Exit;
      end;
    end;
  end;
end;
{$ELSE}

procedure TTMSFNCCustomGrid.HandleKeyPress(var Key: Char);
var
  Allow: boolean;
  k: Integer;
begin
  inherited;
  k := Ord(Key);
  if k in [KEY_TAB] then
    Exit;

  if not Options.Editing.Enabled and Options.Lookup.Enabled then
  begin
    if k in [KEY_ESCAPE,KEY_INSERT,KEY_DELETE,KEY_UP,KEY_DOWN,KEY_LEFT,KEY_RIGHT,KEY_HOME,KEY_END,KEY_PRIOR,KEY_NEXT,KEY_TAB] then
      FLookupString := ''
    else
    begin
      if Options.Lookup.Incremental then
        FLookupString := FLookupString + Key
      else
        FLookupString := Key;

      LookupInColumn(Focusedcell.Col,FLookupString,true,true);
    end;
  end;

  if Options.Editing.Enabled then
  begin
    Allow := True;
    DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);

    if Allow then
    begin
      if (k >= 32) then
      begin
        EditCell(FocusedCell, csStart, True, Key, Ord(Key));
        Key := #0;
        Exit;
      end;
    end;
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomGrid.HandleKeyDown(var Key: Word; Shift: TShiftState);
var
  sel, real: TTMSFNCGridCellRec;
  cs, rs, i, j: Integer;
  Allow: Boolean;
begin
  if Key = KEY_TAB then
    Exit;

  inherited;

  if Key in [KEY_ESCAPE,KEY_INSERT,KEY_DELETE,KEY_UP,KEY_DOWN,KEY_LEFT,KEY_RIGHT,KEY_HOME,KEY_END,KEY_PRIOR,KEY_NEXT,KEY_TAB] then
    FLookupString := '';

  if Options.Editing.Enabled then
  begin
    Allow := True;
    DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);

    if Allow then
    begin
      if (Key = KEY_F2) or (Key = KEY_RETURN) then
      begin
        EditCell(FocusedCell);
        Exit;
      end
    end;
  end;

  if (Key = KEY_SPACE) then
  begin
    Key := KEY_SPACE;
    if (FocusedCell.Row <> -1) and (FocusedCell.Col <> -1) then
      DoCellKeyDown(FocusedCell.Col, FocusedCell.Row, Key, Shift);

    Exit;
  end;

  FPrevSelectedCell := FocusedCell;
  if (ssShift in Shift) and (not (ssCtrl in Shift)) and (not (ssAlt in Shift)) and
      (Key = KEY_INSERT) and not FEditing and Options.Clipboard.Enabled then
        PasteFromClipboard;

  if ((ssCtrl in Shift) {$IFDEF FMXLIB}or (ssCommand in Shift){$ENDIF}) and not (ssShift in Shift) and not (ssAlt in Shift) then
  begin
    case Key of
      Ord('M'): if Options.Keyboard.AllowCellMergeShortCut then MergeSelection(Selection);
      Ord('S'): if Options.Keyboard.AllowCellMergeShortCut then SplitCell(Selection.StartCol, Selection.StartRow);
      Ord('X'):
      begin
        if Options.Clipboard.Enabled and not FEditing then
          CutToClipboard(True);
      end;
      Ord('C'):
      begin
        if Options.Clipboard.Enabled and not FEditing then
          CopyToClipboard(True);
      end;
      Ord('V'):
      begin
        if Options.Clipboard.Enabled and not FEditing then
          PasteFromClipboard;
      end;
      Ord('A'):
      begin
        if (Options.Selection.Mode in [smCellRange, smRowRange, smColumnRange, smDisjunctCell, smDisjunctColumn, smDisjunctRow]) then
        begin
          SetStartCell(FixedColumns, FixedRows);
          SetStopCell(ColumnCount - 1 - FixedRightColumns, RowCount - 1 - FixedFooterRows);
          SetFocusCell(FixedColumns, FixedRows);
        end;

        if (Options.Selection.Mode in [smDisjunctRow]) then
        begin
          BlockUpdate := True;
          for i := FixedRows to RowCount - FixedFooterRows - 1 do
            RowSelect[i] := true;
          BlockUpdate := False;
        end;

        if (Options.Selection.Mode in [smDisjunctColumn]) then
        begin
          BlockUpdate := True;
          for i := FixedColumns to ColumnCount - 1 - FixedRightColumns do
            ColumnSelect[i] := true;
          BlockUpdate := False;
        end;

        if (Options.Selection.Mode in [smDisjunctCell]) then
        begin
          BlockUpdate := True;
          for i := FixedColumns to ColumnCount - 1 - FixedRightColumns do
            for j := FixedRows to RowCount - FixedFooterRows - 1 do
              CellSelect[i, j] := True;
          BlockUpdate := False;
        end;

        Navigate(FocusedCell);
        if Assigned(OnSelectedCell) then
          OnSelectedCell(Self, FocusedCell.Col, FocusedCell.Row);
      end;
      KEY_DELETE:
      begin
        if Options.Clipboard.Enabled then
          CutToClipboard;
      end;
      KEY_INSERT:
      begin
        if Options.Clipboard.Enabled then
            CopyToClipboard
      end;
      KEY_NEXT: TopRow := TopRow + GetPageScrollSize;
      KEY_PRIOR: TopRow := TopRow - GetPageScrollSize;
      KEY_UP:
      begin
        TopRow := TopRow - 1;
      end;
      KEY_DOWN:
      begin
        rs := RowSpan(LeftCol, TopRow);
        if rs > 0 then
          TopRow := TopRow + rs
        else
          TopRow := TopRow + 1;
      end;
      KEY_LEFT: LeftCol := LeftCol - 1;
      KEY_RIGHT:
      begin
        cs := ColSpan(LeftCol, TopRow);
        if cs > 0 then
          LeftCol := LeftCol + cs
        else
          LeftCol := LeftCol + 1;
      end;
      KEY_HOME: TopRow := FixedRows;
      KEY_END: TopRow := RowCount - 1 - FixedFooterRows;
    end;
  end
  else
  begin
    sel := FocusedCell;
    real := FRealCell;
    
    case Key of
      KEY_DELETE:
      begin
        if Shift = [] then
        begin
          if Options.Keyboard.DeleteKeyHandling = dkhDeleteRow then
          begin
            if RowCount > FixedRows + FixedFooterRows then
            begin
              Allow := true;
              DoCanDeleteRow(sel.Row,Allow);
              if Allow then
              begin
                DeleteRow(sel.Row);
                sel.Row := Max(FixedRows, Min(sel.Row, RowCount - 1 - FixedFooterRows));
                DoDeleteRow(sel.Row);
              end;
            end;
          end;
        end;
      end;
      KEY_INSERT:
      begin
        if Shift = [] then
        begin
          if sel.Row = -1 then
            sel.Row := FixedRows - 1;

          case Options.Keyboard.InsertKeyHandling of
            ikhInsertRowBefore:
            begin
              Allow := true;
              DoCanInsertRow(sel.Row,Allow);
              if Allow then
              begin
                InsertRow(sel.Row);
                sel.Row := Max(FixedRows, Min(sel.Row, RowCount - 1 - FixedFooterRows));
                DoInsertRow(sel.Row);
              end;
            end;
            ikhInsertRowAfter:
            begin
              Allow := true;
              DoCanInsertRow(sel.Row,Allow);
              if Allow then
              begin
                InsertRow(sel.Row + 1);
                sel.Row := Max(FixedRows, Min(sel.Row + 1, RowCount - 1 - FixedFooterRows));
                DoInsertRow(sel.Row);
              end;
            end;
          end;
        end;
      end;
      KEY_NEXT:
      begin
        sel := NextSelectableRow(sel.Col, sel.Row + GetPageScrollSize - 1);
        sel.Col := real.Col;
      end;
      KEY_PRIOR:
      begin
        sel := PreviousSelectableRow(sel.Col, sel.Row - GetPageScrollSize + 1);
        sel.Col := real.Col;
      end;
      KEY_UP:
      begin
        sel := PreviousSelectableRow(sel.Col, sel.Row);
        sel.Col := real.Col;
      end;
      KEY_DOWN:
      begin
        sel := NextSelectableRow(sel.Col, sel.Row);
        sel.Col := real.Col;
      end;
      KEY_LEFT:
      begin
        sel := PreviousSelectableColumn(sel.Col, sel.Row);
        sel.Row := real.Row;
      end;
      KEY_RIGHT:
      begin
        sel := NextSelectableColumn(sel.Col, sel.Row);
        sel.Row := real.Row;
      end;
      KEY_HOME:
      begin
        if (ssAlt in Shift) then
        begin
          sel.Row := FixedRows;
          sel.Col := real.Col;
          sel := NextSelectableRow(sel.Col, sel.Row - 1);
        end
        else
        begin
          sel.Col := FixedColumns;
          sel.Row := real.Row;
          sel := NextSelectableColumn(sel.Col - 1, sel.Row);
        end;
      end;
      KEY_END:
      begin
        if (ssAlt in Shift) then
        begin
          sel.Row := RowCount - 1 - FixedFooterRows;
          sel.Col := real.Col;
          sel := PreviousSelectableRow(sel.Col, sel.Row + 1);
        end
        else
        begin
          sel.Col := ColumnCount - 1 - FixedRightColumns;
          sel.Row := real.Row;
          sel := PreviousSelectableColumn(sel.Col + 1, sel.Row);
        end;
      end;
    end;

    case Key of
      KEY_UP, KEY_LEFT, KEY_DOWN, KEY_RIGHT, KEY_HOME, KEY_END, KEY_NEXT, KEY_PRIOR, KEY_DELETE, KEY_INSERT:
      begin
        if (ssCtrl in Shift) then
          Navigate(sel)
        else
          SelectCell(sel, Shift);

        Key := 0;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.HandleKeyUp(var Key: Word; Shift: TShiftState);
begin
  if Key = KEY_TAB then
    Exit;

  inherited;
  if (Key = KEY_SPACE) then
  begin
    Key := KEY_SPACE;
    if (FocusedCell.Row <> -1) and (FocusedCell.Col <> -1) then
      DoCellKeyUp(FocusedCell.Col, FocusedCell.Row, Key, Shift);
  end;
end;

procedure TTMSFNCCustomGrid.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X,
  Y: Single);
var
  cell: TTMSFNCGridCellRec;
  obj: TTMSFNCGridCell;
  sx, sy: Single;
  fixed, rowsz, colsz: Boolean;
  allow: Boolean;
  bctl: Boolean;
  ct: TTMSFNCGridCellRec;
  s: Boolean;
begin
  inherited;

  if not HideEdit(False, True) then
  begin
    ct := XYToCell(X, Y);
    s := True;
    if (ct.Col <> FEditCell.Col) or (ct.Row <> FEditCell.Row) then
    begin
      CancelEdit;
      s := False;
    end;

    if s then
      Exit;
  end;

  if CanFocus then
    SetFocus;

  CaptureEx;

  if not (Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbLeft) then
    Exit;

  sx := X;
  sy := Y;

  cell := XYToCell(X, Y);

  obj := GetCellObject(GetDisplayCell(cell));
  if Assigned(obj) then
  begin
    FDragCell := cell;
    fixed := IsFixed(cell.Col, cell.Row);

    if fixed then
    begin
      rowsz := Options.Mouse.FixedRowSizing;
      colsz := Options.Mouse.FixedColumnSizing;
    end
    else
    begin
      rowsz := Options.Mouse.RowSizing;
      colsz := Options.Mouse.ColumnSizing;
    end;
    if rowsz and (((cell.Row < RowCount - FixedFooterRows) and fixed) or not fixed) and (sy >= obj.Top + obj.Height - Options.Mouse.RowSizeMargin) and (sx > obj.Left + Options.Mouse.ColumnSizeMargin) and (sx < obj.Left + obj.Width - Options.Mouse.ColumnSizeMargin) then
    begin
      allow := True;
      DoCanSizeRow(cell.Row, allow);
      if allow then
      begin
        FMouseSizing := True;
        FSizeMode := smRowSizing;
      end;
    end
    else if rowsz and (((cell.Row > FixedRows) and fixed) or not fixed) and (sy <= obj.Top + Options.Mouse.RowSizeMargin) and (sx > obj.Left + Options.Mouse.ColumnSizeMargin) and (sx < obj.Left + obj.Width - Options.Mouse.ColumnSizeMargin) then
    begin
      Allow := True;
      DoCanSizeRow(cell.Row - 1, allow);
      if allow then
      begin
        FMouseSizing := True;
        FSizeMode := smPreviousRowSizing;
      end;
    end
    else if colsz and (((cell.Col < ColumnCount - FixedRightColumns) and fixed) or not fixed) and (sx >= obj.Left + obj.Width - Options.Mouse.ColumnSizeMargin) and (sy > obj.Top + Options.Mouse.RowSizeMargin) and (sy < obj.Top + obj.Height - Options.Mouse.RowSizeMargin) then
    begin
      Allow := True;
      DoCanSizeColumn(cell.Col, allow);
      if allow then
      begin
        FMouseSizing := True;
        FSizeMode := smColumnSizing;
      end;
    end
    else if colsz and (((cell.Col > FixedColumns) and fixed) or not fixed) and (sx <= obj.Left - Options.Mouse.ColumnSizeMargin) and (sy > obj.Top + Options.Mouse.RowSizeMargin) and (sy < obj.Top + obj.Height - Options.Mouse.RowSizeMargin) then
    begin
      Allow := True;
      DoCanSizeColumn(cell.Col - 1, allow);
      if Allow then
      begin
        FMouseSizing := True;
        FSizeMode := smPreviousColumnSizing;
      end;
    end
    else
    begin
      FMouseDown := True;
      FMouseUp := True;

      bctl := False;

      if obj is TTMSFNCGridCell then
      begin
        bctl := False;
        (obj as TTMSFNCGridCell).HandleMouseDown(Button, Shift, X, Y, bctl);
        if bctl then
          Invalidate;
      end;

      if not bctl then
      begin
        if not IsFixed(Cell.Col, Cell.Row) then
        begin
          Cell := BaseCell(Cell.Col, Cell.Row);
          if ((Cell.Col = FocusedCell.Col) and (Cell.Row = FocusedCell.Row)) and not Options.Mouse.DirectEdit then
          begin
            FMouseUp := False;
            Allow := True;
            DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
            FMouseUp := not Allow;

            if Allow then
              EditCell(FocusedCell);
          end
          else
            HideEdit;
        end
        else
        begin
          FDragFixed := (Options.Mouse.RowDragging and ((Cell.Col < FixedColumns) or (Cell.Col > ColumnCount - 1 - FixedRightColumns)));
          FDragFixed := FDragFixed or ((Options.Sorting.Mode <> gsmNone) or Options.Mouse.ColumnDragging) and ((Cell.Row < FixedRows) or (Cell.Row > RowCount - 1 - FixedFooterRows));
        end;
      end;
    end;
  end;

  FTouchScrolling := False;

  FPrevX := X;
  FPrevY := Y;

  FSizeX := FPrevX;
  FSizeY := FPrevY;

  FDragX := FPrevX;
  FDragY := FPrevY;

  FDownX := FPrevX;
  FDownY := FPrevY;

  FTouchX := FPrevX;
  FTouchY := FPrevY;

  FScrollX := FPrevX;
  FScrollY := FPrevY;

  FMouseX := FPrevX;
  FMouseY := FPrevY;

  if GetHVisible then
    FScrollHTo := GetHScrollValue
  else
    FScrollHTo := 0;

  if GetVVisible then
    FScrollVTo := GetVScrollValue
  else
    FScrollVTo := 0;

  FCellAnchor.Col := -1;
  FCellAnchor.Row := -1;
  FFirstCell.Col := -1;
  FFirstCell.Row := -1;
  FCellAnchorString := '';
  FDoubleSelection := not FAnimateTimer.Enabled;
  FTimeStart := GetTickCountX;
  FTimeStop := FTimeStart;
  IsMouseDown := True;
  FMouseScrollingUp := False;
end;

procedure TTMSFNCCustomGrid.HandleMouseLeave;
var
  p: TPointF;
begin
  inherited;
  if Assigned(ToolBarPopup) and ToolBarPopup.Activated and not Editing then
  begin
    p := TTMSFNCUtils.GetMousePos;
    if not ToolBarPopup.PointInPopup(p) and not ToolBarPopup.DropDownActive then
      ToolBarPopup.Deactivate;
  end;
end;

procedure TTMSFNCCustomGrid.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  res: TTMSFNCGridCellRec;
  enablescroll: Boolean;
  obj: TTMSFNCGridCell;
  sx, sy: Single;
  fixed: Boolean;
  rowsz, colsz: Boolean;
  cell: TTMSFNCGridCell;
  g: TTMSFNCGraphics;
  bmp: TTMSFNCBitmapHelperClass;
  dx, dy: Single;
  Allow: Boolean;
  newsz: Single;
  newposx, newposy: Single;
  vss, hss: Single;
  fxd: Boolean;
  contentr: TRectF;
  bctl: Boolean;
  cr: TCursor;
  r: TRectF;
  f, h, v: Double;
  pt: TPointF;

  procedure ShowToolBar;
  begin
    ToolBarPopup.PlacementControl := Self;
    ToolBarPopup.Placement := ppAbsolute;
    ToolBarPopup.Grid := Self;
    FToolBarPopupCell := res;
    ToolBarPopup.GridCell := FToolBarPopupCell;
    ToolBarPopup.Activate;
    pt := PointF(obj.Left + (obj.Width - ToolBarPopup.ToolBar.Width) / 2, obj.Top - ToolBarPopup.ToolBar.Height + 1);
    pt := ConvertClientToScreen(pt);
    ToolBarPopup.PlacementRectangle.Left := pt.X;
    ToolBarPopup.PlacementRectangle.Top := pt.Y;
    ToolBarPopup.PlacementRectangle.Right := pt.X;
    ToolBarPopup.PlacementRectangle.Bottom := pt.Y;
  end;
begin
  inherited;

  if FMouseDown and not HideEdit(False, True) then
    Exit;

  if FClearMouseDown then
  begin
    FMouseDown := False;
    FClearMouseDown := False;
  end;

  if FDblClick then
  begin
    FDblClick := False;
    FMouseDown := False;
    ReleaseCaptureEx;
  end;

  FCellAnchor.Col := -1;
  FCellAnchor.Row := -1;
  FCellAnchorString := '';

  FX := X;
  FY := Y;

  r := GetContentClipRect;

  if FMouseDown and (((FX < FDownX - Options.Mouse.ClickMargin) or (FX > FDownX + Options.Mouse.ClickMargin))
    or ((FY < FDownY - Options.Mouse.ClickMargin) or (FY > FDownY + Options.Mouse.ClickMargin))) then
  begin
    if not FDragFixed then
    begin
      HideEdit;

      hss := GetCellContainerPosX;
      vss := GetCellContainerPosY;
      contentr := GetContentRect;

      FDoTouchScroll := Options.Mouse.TouchScrolling and ((Options.Selection.Mode = smSingleCell) or (Options.Selection.Mode = smSingleRow) or (Options.Selection.Mode = smSingleColumn) or (Options.Selection.Mode = smNone));

      enablescroll := False;
      if (((-hss > 0) and (FX < GetFixedWidth))
       or ((-hss < GetTotalContentWidth - GetFixedRightWidth) and (FX > (contentr.Right - contentr.Left) - GetFixedRightWidth)))
       or (((-vss > 0) and (FY < GetFixedHeight))
        or ((-vss < GetTotalContentHeight - GetFixedFooterHeight) and (FY > (contentr.Bottom - contentr.Top) - GetFixedFooterHeight))) then
      enablescroll := Options.Mouse.AutoScrolling and not FDoTouchScroll;

      if (FX > (contentr.Right - contentr.Left) - GetFixedRightWidth) then
        enablescroll := not (fcsRow in Options.Mouse.FixedCellSelection) and not (fcsRowRange in Options.Mouse.FixedCellSelection);

      if (FY > (contentr.Bottom - contentr.Top) - GetFixedFooterHeight) then
        enablescroll := not (fcsColumn in Options.Mouse.FixedCellSelection) and not (fcsColumnRange in Options.Mouse.FixedCellSelection);


      FScrollTimer.Enabled := enablescroll;

      if not FScrollTimer.Enabled then
      begin
        if Options.Mouse.TouchScrolling and FDoTouchScroll then
        begin
          f := Options.Mouse.TouchScrollingSensitivity;
          if ScrollMode = scmItemScrolling then
            f := f / 10;

          if (FTouchScrolling or (Abs(FMouseX - X) > 3) or (Abs(FMouseY - Y) > 3)) then
          begin
            if (Abs(X - FTouchX) > SCROLLINGDELAY) or (Abs(Y - FTouchY) > SCROLLINGDELAY) then
            begin
              FTouchScrolling := True;
              FDoubleSelection := False;
              if IsMouseDown and not FMouseScrollingUp then
              begin
                if GetHVisible then
                  h := GetHScrollValue - (X - FTouchX) * f
                else
                  h := 0;

                if GetVVisible then
                  v := GetVScrollValue - (Y - FTouchY) * f
                else
                  v := 0;

                Scroll(h, v);
                FTouchY := Y;
                FTouchX := X;
              end;
            end;
          end
        end
        else
        begin
          FMouseDragging := True;
          res := XYToCell(X, Y);
          if (res.Col <> -1) and (res.Row <> -1) then
          begin
            fxd := IsFixed(res.Col, res.Row);
            case Options.Selection.Mode of
              smSingleRow: if (fcsRow in Options.Mouse.FixedCellSelection) then fxd := False;
              smSingleColumn: if (fcsColumn in Options.Mouse.FixedCellSelection) then fxd := False;
              smRowRange: if (fcsRowRange in Options.Mouse.FixedCellSelection) then fxd := False;
              smColumnRange: if (fcsColumnRange in Options.Mouse.FixedCellSelection) then fxd := False;
            end;

            if not fxd then
              SelectCell(res, Shift, FMouseDragging);
          end;
        end;
      end;
    end
    else
    begin
      if (FDragCell.Col <> -1) and (FDragCell.Row <> -1) then
      begin
        if not Assigned(FDragBitmap) then
        begin
          cell := GetCellObject(GetDisplayCell(FDragCell));
          if Assigned(cell) then
          begin
            Allow := Options.Mouse.ColumnDragging;
            if Allow then
              DoCanDragColumn(FDragCell.Col, Allow);

            if ((FDragCell.Row < FixedRows) or (FDragCell.Row > RowCount - 1 - FixedFooterRows)) and (Allow) then
            begin
              UpdateCursor(crUpArrow);
              FDragBitmap := TTMSFNCImage.Create(Self);
              {$IFDEF FMXLIB}
              FDragBitmap.HitTest := False;
              {$ENDIF}
              FDragBitmap.Width := Round(cell.Width) + 2;
              FDragBitmap.Height := Round(Min(Height, r.Bottom - r.Top)) + 2;
              dx := -cell.Left;
              FDragBitmap.Left := Round(-dx);
              FDragBitmap.Top := 0;
              g := TTMSFNCGraphics.CreateBitmapCanvas(Round(FDragBitmap.Width), Round(FDragBitmap.Height));
              g.BeginScene;
              g.BitmapContainer := BitmapContainer;
              g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
              bmp := MakeScreenshot;
              try
                g.BeginScene;
                g.DrawRectangle(RectF(0, 0, FDragBitmap.Width, FDragBitmap.Height));
                g.DrawBitmapPart(RectF(FDragBitmap.Left, FDragBitmap.Top, FDragBitmap.Left + FDragBitmap.Width - 2, FDragBitmap.Top + FDragBitmap.Height - 2), RectF(0, 0, FDragBitmap.Width, FDragBitmap.Height), bmp);
                g.EndScene;
                FDragBitmap.Bitmaps.AddDrawBitmap(g.Bitmap);
              finally
                bmp.Free;
                g.EndScene;
                g.Free;
              end;

              FDraggingMode := dmColumnDragging;
              FSaveDragX := FDragBitmap.Left;
              FDragBitmap.Parent := Self;
              FDragX := FX;
              FDragY := FY;
            end
            else
            begin
              Allow := Options.Mouse.RowDragging;
              if Allow then
                DoCanDragRow(FDragCell.Row, Allow);

              if ((FDragCell.Col < FixedColumns) or (FDragCell.Col > ColumnCount - 1 - FixedRightColumns)) and (Allow) then
              begin
                UpdateCursor(crUpArrow);
                FDragBitmap := TTMSFNCImage.Create(Self);
                {$IFDEF FMXLIB}
                FDragBitmap.HitTest := False;
                {$ENDIF}
                FDragBitmap.Width := Round(Min(Width, r.Right - r.Left)) + 2;
                FDragBitmap.Height := Round(cell.Height) + 2;
                dy := -cell.Top;
                FDragBitmap.Left := 0;
                FDragBitmap.Top := Round(-dy);
                g := TTMSFNCGraphics.CreateBitmapCanvas(Round(FDragBitmap.Width), Round(FDragBitmap.Height));
                g.BeginScene;
                g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
                g.BitmapContainer := BitmapContainer;
                bmp := MakeScreenshot;
                try
                  g.BeginScene;
                  g.DrawRectangle(RectF(0, 0, FDragBitmap.Width, FDragBitmap.Height));
                  g.DrawBitmapPart(RectF(FDragBitmap.Left, FDragBitmap.Top, FDragBitmap.Left + FDragBitmap.Width - 2, FDragBitmap.Top + FDragBitmap.Height - 2), RectF(0, 0, FDragBitmap.Width, FDragBitmap.Height), bmp);
                  g.EndScene;
                  FDragBitmap.Bitmaps.AddDrawBitmap(g.Bitmap);
                finally
                  bmp.Free;
                  g.EndScene;
                  g.Free;
                end;

                FDraggingMode := dmRowDragging;
                FSaveDragY := FDragBitmap.Top;
                FDragBitmap.Parent := Self;
                FDragX := FX;
                FDragY := FY;
              end;
            end;
          end
        end
        else
        begin
          if (((FDragCell.Row < FixedRows) or (FDragCell.Row > RowCount - 1 - FixedFooterRows))) and (Options.Mouse.ColumnDragging) then
          begin
            newposx := FSaveDragX + (FX - FDragX);
            if (newposx <= (r.Right - r.Left) - FDragBitmap.Width) and (newposx >= 0) then
            begin
              FDragBitmap.Left := Round(Max(0, Min((r.Right - r.Left) - FDragBitmap.Width,  newposx)));
              FSaveDragX := FDragBitmap.Left;
              FDragTimer.Enabled := False;
              if (newposx > GetFixedWidth) and (newposx < (r.Right - r.Left) - GetFixedRightWidth) then
                FDragScrollCell := XYToCell(newposx, (r.Bottom - r.Top) / 2);
            end
            else if (newposx > (r.Right - r.Left) - FDragBitmap.Width) then
            begin
               FDragBitmap.Left := Round((r.Right - r.Left) - FDragBitmap.Width);
               FSaveDragX := newposx;
               FDragTimer.Enabled := True;
            end
            else if (newposx < 0) then
            begin
               FDragBitmap.Left := 0;
               FSaveDragX := newposx;
               FDragTimer.Enabled := True;
            end;

            FDragX := FX;
            FDragY := FY;
          end
          else if (((FDragCell.Col < FixedColumns) or (FDragCell.Col > ColumnCount - 1 - FixedRightColumns))) and (Options.Mouse.RowDragging) then
          begin
            newposy := FSaveDragY + (FY - FDragY);
            if (newposy <= (r.Bottom - r.Top) - FDragBitmap.Height) then
            begin
              FDragBitmap.Top := Round(Max(0, Min((r.Bottom - r.Top) - FDragBitmap.Height,  newposy)));
              FSaveDragY := FDragBitmap.Top;
              FDragTimer.Enabled := False;
              if (newposy > GetFixedHeight) and (newposy < (r.Bottom - r.Top) - GetFixedFooterHeight) then
                FDragScrollCell := XYToCell((r.Right - r.Left) / 2, newposy);
            end
            else if (newposy > (r.Bottom - r.Top) - FDragBitmap.Height) then
            begin
              FDragBitmap.Top := Round((r.Bottom - r.Top) - FDragBitmap.Height);
              FSaveDragY := newposy;
              FDragTimer.Enabled := True;
            end;

            FDragX := FX;
            FDragY := FY;
          end;

          if PtInRectEx(LocalRect, PointF(X, Y)) then
            UpdateCursor(crUpArrow)
          else
            UpdateCursor(crNoDrop);
        end;
      end;
    end;
  end
  else if not FMouseSizing then
  begin
    sx := X;
    sy := Y;

    res := XYToCell(X, Y);
    if (res.col <> -1) and (res.row <> -1) then
    begin
      obj := GetCellObject(GetDisplayCell(res));
      if Assigned(obj) then
      begin
        fixed := IsFixed(res.Col, res.Row);

        if Assigned(ToolBarPopup) and (ToolBarPopupMode <> tpmNone) then
        begin
          if ToolBarPopupMode = tpmActiveCell then
          begin
            if (res.Col = FocusedCell.Col) and (res.Row = FocusedCell.Row) and not ToolBarPopup.Activated then
              ShowToolBar;

            if ((res.Col <> FocusedCell.Col) or (res.Row <> FocusedCell.Row)) and Assigned(ToolBarPopup) and ToolBarPopup.Activated and not ToolBarPopup.DropDownActive then
              ToolBarPopup.Deactivate;
          end;

          if (ToolBarPopupMode in [tpmHoverCell, tpmHoverNormalCell]) then
          begin
            if ((res.Col <> FToolBarPopupCell.Col) or (res.Row <> FToolBarPopupCell.Row)) and not ToolBarPopup.Activated then
            begin
              if not fixed or (ToolBarPopupMode = tpmHoverCell) then
                ShowToolBar;
            end;

            if ((res.Col <> FToolBarPopupCell.Col) or (res.Row <> FToolBarPopupCell.Row)) and ToolBarPopup.Activated and not ToolBarPopup.DropDownActive then
              ToolBarPopup.Deactivate;
          end;
        end;

        if fixed then
        begin
          rowsz := Options.Mouse.FixedRowSizing;
          colsz := Options.Mouse.FixedColumnSizing;
        end
        else
        begin
          rowsz := Options.Mouse.RowSizing;
          colsz := Options.Mouse.ColumnSizing;
        end;

        if rowsz and (res.Row > FixedRows) and (sy <= obj.Top + Options.Mouse.RowSizeMargin) and (sx > obj.Left + Options.Mouse.ColumnSizeMargin) and (sx < obj.Left + obj.Width - Options.Mouse.ColumnSizeMargin) then
        begin
          allow := True;
          DoCanSizeRow(res.Row - 1, allow);
          if allow then
            UpdateCursor(crSizeNS)
        end
        else if rowsz and (((res.Row < RowCount - FixedRows) and fixed) or not fixed) and (sy >= obj.Top + obj.Height - Options.Mouse.RowSizeMargin) and (sx > obj.Left + Options.Mouse.ColumnSizeMargin) and (sx < obj.Left + obj.Width - Options.Mouse.ColumnSizeMargin) then
        begin
          allow := True;
          DoCanSizeRow(res.Row, allow);
          if allow then
            UpdateCursor(crSizeNS)
        end
        else if colsz and (res.Col > FixedColumns) and (sx <= obj.Left - Options.Mouse.ColumnSizeMargin) and (sy > obj.Top + Options.Mouse.RowSizeMargin) and (sy < obj.Top + obj.Height - Options.Mouse.RowSizeMargin) then
        begin
          allow := True;
          DoCanSizeColumn(res.Col - 1, allow);
          if allow then
            UpdateCursor(crSizeWE)
        end
        else if colsz and (((res.Col < ColumnCount - FixedColumns) and fixed) or not fixed) and (sx >= obj.Left + obj.Width - Options.Mouse.ColumnSizeMargin) and (sy > obj.Top + Options.Mouse.RowSizeMargin) and (sy < obj.Top + obj.Height - Options.Mouse.RowSizeMargin) then
        begin
          allow := True;
          DoCanSizeColumn(res.Col, allow);
          if allow then
            UpdateCursor(crSizeWE)
        end
        else
        begin
          if (obj is TTMSFNCGridCell) then
          begin
            bctl := False;
            cr := OldCursor;
            (obj as TTMSFNCGridCell).HandleMouseMove(Shift, X, Y, bctl, cr);
            if bctl then
            begin
              UpdateCursor(cr);
              Invalidate;
            end;

            if not bctl then
            begin
              if (obj as TTMSFNCGridCell).IsHTML then
                FCellAnchorString := (obj as TTMSFNCGridCell).XYToAnchor(x, y)
              else
              begin
                FCellAnchorString := (obj as TTMSFNCGridCell).Text;
                if not IsUrl(FCellAnchorString) then
                  FCellAnchorString := '';
              end;

              if (FCellAnchorString <> '') and Options.URL.Show then
                UpdateCursor(crHandPoint)
              else
                UpdateCursor(OldCursor);
            end;
          end;
        end;
      end;
    end
    else
      UpdateCursor(OldCursor);
  end
  else
  begin
    if (FDragCell.Col <> -1) and (FDragCell.Col <> -1) then
    begin
      HideEdit;
      case FSizeMode of
        smColumnSizing:
        begin
          newsz := ColumnWidths[FDragCell.Col] + (FX - FSizeX);
          DoColumnSize(FDragCell.Col, newsz);
          ColumnWidths[FDragCell.Col] := newsz;
        end;
        smRowSizing:
        begin
          newsz := RowHeights[FDragCell.Row] + (FY - FSizeY);
          DoRowSize(FDragCell.Row, newsz);
          RowHeights[FDragCell.Row] := newsz;
        end;
        smPreviousColumnSizing:
        begin
          newsz := ColumnWidths[FDragCell.Col - 1] + (FX - FSizeX);
          DoColumnSize(FDragCell.Col - 1, newsz);
          ColumnWidths[FDragCell.Col - 1] := newsz;
        end;
        smPreviousRowSizing:
        begin
          newsz := RowHeights[FDragCell.Row - 1] + (FY - FSizeY);
          DoRowSize(FDragCell.Row - 1, newsz);
          RowHeights[FDragCell.Row - 1] := newsz;
        end;
      end;

      FSizeX := FX;
      FSizeY := FY;
      UpdateControl;
      FSizing := True;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X,
  Y: Single);
var
  res: TTMSFNCGridCellRec;
  Allow: Boolean;
  obj: TTMSFNCGridCell;
  fxd: Boolean;
  bctl: Boolean;
  b: Boolean;
  f: Double;
begin
  inherited;

  if not HideEdit(False, True) then
    Exit;

  ReleaseCaptureEx;
  if not (Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbLeft) then
  begin
    if Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbRight then
    begin
      res := XYToCell(X, Y);
      if (res.Col <> -1) and (res.Row <> -1) then
      begin
        fxd := IsFixed(res.Col, res.Row);
        if fxd then
          DoFixedCellRightClick(res.Col, res.Row)
        else
          DoCellRightClick(res.Col, res.Row);
      end;
    end;
    Exit;
  end;

  if not IsMouseDown then
    Exit;

  FMouseScrollingUp := True;
  IsMouseDown := False;
  FScrollTimer.Enabled := False;
  FDragTimer.Enabled := False;

  if FSizing then
  begin
    case FSizeMode of
      smColumnSizing: DoColumnSized(FDragCell.Col, ColumnWidths[FDragCell.Col]);
      smRowSizing: DoRowSized(FDragCell.Row, RowHeights[FDragCell.Row]);
      smPreviousColumnSizing: DoColumnSized(FDragCell.Col - 1, ColumnWidths[FDragCell.Col - 1]);
      smPreviousRowSizing: DoRowSized(FDragCell.Row - 1, RowHeights[FDragCell.Row - 1]);
    end;
    UpdateControl;
  end;

  if Assigned(FDragBitmap) then
  begin
    FDragBitmap.Parent := nil;
    FDragBitmap.Free;
    FDragBitmap := nil;
  end;

  if not FDoubleSelection and Options.Mouse.TouchScrolling and FDoTouchScroll then
  begin
    f := Options.Mouse.TouchScrollingSensitivity;
    if ScrollMode = scmItemScrolling then
      f := f / 10;

    FTimeStop := GetTickCountX;
    if ((FTimeStop - FTimeStart) < SWIPECOUNT) and ((FTimeStop - FTimeStart) > 0) then
    begin
      FSpY := Abs(Y - FScrollY) / (FTimeStop - FTimeStart);
      if (FSpY > 0) then
      begin
        if GetVVisible then
        begin
          if (Y - FScrollY) > 0 then
            FScrollVTo := Max(0, Min(GetVMax - GetVViewportSize, FScrollVTo - Round(Abs(Y - FScrollY) * FSpY * f * 3)))
          else
            FScrollVTo := Max(0, Min(GetVMax - GetVViewportSize, FScrollVTo + Round(Abs(Y - FScrollY) * FSpY * f * 3)));
        end
        else
          FScrollVTo := 0;

        FAnimateVerticalPos := True;
        FAnimateTimer.Enabled := True;
      end;

      FSpX := Abs(X - FScrollX) / (FTimeStop - FTimeStart);
      if (FSpX > 0) then
      begin
        if GetHVisible then
        begin
          if (X - FScrollX) > 0 then
            FScrollHTo := Max(0, Min(GetHMax - GetHViewportSize, FScrollHTo - Round(Abs(X - FScrollX) * FSpX * f * 3)))
          else
            FScrollHTo := Max(0, Min(GetHMax - GetHViewportSize, FScrollHTo + Round(Abs(X - FScrollX) * FSpX * f * 3)));
        end
        else
          FScrollHTo := 0;

        FAnimateHorizontalPos := True;
        FAnimateTimer.Enabled := True;
      end;
    end;
  end;

  bctl := False;
  if FMouseUp then
  begin
    res := XYToCell(X, Y);
    if (res.Col <> -1) and (res.Row <> -1) then
    begin
      obj := GetCellObject(GetDisplayCell(res));
      if Assigned(obj) then
      begin
        FCellAnchor.Col := -1;
        FCellAnchor.Row := -1;
        if (obj is TTMSFNCGridCell) and not FMouseDragging then
        begin
          bctl := False;
          (obj as TTMSFNCGridCell).HandleMouseUp(Button, Shift, X, Y, bctl);
          if bctl then
            Invalidate;

          if (obj as TTMSFNCGridCell).IsHTML then
            FCellAnchorString := (obj as TTMSFNCGridCell).XYToAnchor(x, y)
          else
          begin
            FCellAnchorString := (obj as TTMSFNCGridCell).Text;
            if not IsUrl(FCellAnchorString) or not Options.URL.Show then
              FCellAnchorString := '';
          end;

          if FCellAnchorString <> '' then
            FCellAnchor := res;
        end;
      end;

      if not bctl then
      begin
        if (FCellAnchor.Col = -1) and (FCellAnchor.Row = -1) and (FCellAnchorString = '') then
        begin
          fxd := IsFixed(res.Col, res.Row);
          case Options.Selection.Mode of
            smCellRange:
            begin
              if (fcsAll in Options.Mouse.FixedCellSelection) and (res.Col < FixedColumns) and (res.Row < FixedRows) then
                fxd := False;
            end;
            smSingleRow: if (fcsRow in Options.Mouse.FixedCellSelection) then fxd := False;
            smSingleColumn: if (fcsColumn in Options.Mouse.FixedCellSelection) then fxd := False;
            smRowRange: if (fcsRowRange in Options.Mouse.FixedCellSelection) then fxd := False;
            smColumnRange: if (fcsColumnRange in Options.Mouse.FixedCellSelection) then fxd := False;
            smDisjunctRow: if (fcsDisjunctRow in Options.Mouse.FixedCellSelection) then fxd := False;
            smDisjunctColumn: if (fcsDisjunctColumn in Options.Mouse.FixedCellSelection) then fxd := False;
          end;

          if not fxd and not FDragFixed and not FTouchScrolling then
          begin
            SelectCell(res, Shift, FMouseDragging);
            DoCellClick(res.Col, res.Row);

            if not FMouseDragging and Options.Mouse.DirectEdit then
            begin
              Allow := True;
              DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
              if Allow then
                EditCell(FocusedCell);
            end;
          end
          else
          begin
            if FDraggingMode = dmColumnDragging then
            begin
              b := True;
              DoBeforeColumnDrop(FDragCell.Col, res.Col, b);
              if b then
              begin
                BeginUpdate;
                MoveColumn(FDragCell.Col, res.Col);
                EndUpdate;
                DoColumnDragged(FDragCell.Col, res.Col);
              end;
            end
            else
            if FDraggingMode = dmRowDragging then
            begin
              b := True;
              DoBeforeRowDrop(FDragCell.Row, res.Row, b);
              if b then
              begin
                BeginUpdate;
                MoveRow(FDragCell.Row, res.Row);
                EndUpdate;
                DoRowDragged(FDragCell.Row, res.Row);
              end;
            end
            else
              DoFixedCellClick(Shift, res.Col, res.Row);
          end;
        end
        else
          DoCellAnchorClick(FCellAnchor.Col, FCellAnchor.Row, FCellAnchorString);
      end;
    end;
  end;

  FTouchScrolling := False;
  FDraggingMode := dmNone;
  FDragFixed := False;
  FFirstCell.Row := -1;
  FFirstCell.Col := -1;
  FMouseDown := False;
  FMouseUp := False;
  FMouseDragging := False;
  FMouseDblClick := FMouseSizing;
  FMouseSizing := False;
  FSizing := False;
end;

procedure TTMSFNCCustomGrid.HandleMouseWheel(Shift: TShiftState; WheelDelta: Integer;
  var Handled: Boolean);
var
  sel: TTMSFNCGridCellRec;
  cl: TTMSFNCGridCellRec;
  r: Integer;
begin
  inherited;
  if (ssCtrl in Shift) or (Options.Mouse.WheelScrollKeepSelection) then
  begin
      if WheelDelta > 0 then
        TopRow := TopRow - Options.Mouse.WheelScrollSize
      else
        TopRow := TopRow + Options.Mouse.WheelScrollSize;

      r := TopRow;

      cl := BaseCell(LeftCol, r);
      TopRow := cl.Row;
  end
  else
  begin
    sel := FocusedCell;
    if WheelDelta > 0 then
      sel.Row := sel.Row - Options.Mouse.WheelScrollSize
    else
      sel.Row := sel.Row + Options.Mouse.WheelScrollSize;

    cl := BaseCell(sel.Col, sel.Row);

    sel := cl;

    CancelEdit;
    SelectCell(sel, Shift);
  end;

  Handled := True;
end;

procedure TTMSFNCCustomGrid.Navigate(ACell: TTMSFNCGridCellRec; ForceScroll: Boolean = False);
var
  hs, vs, rhs, rvs, vss, hss: Single;
  totalh, totalw: Single;
  cs, rs: Integer;
  c, r: Integer;
  toth, totw: Single;
  cl: TTMSFNCGridCellRec;
  contentr: TRectF;
begin
  if BlockUpdate then
    Exit;

  contentr := GetContentRect;

  vs := GetVerticalScrollPosition;
  hs := GetHorizontalScrollPosition;

  vss := vs;
  hss := hs;

  if ScrollMode = scmItemScrolling then
  begin
    vss := -GetCellContainerPosY;
    hss := -GetCellContainerPosX;
  end;

  totalw := 0;
  totalh := 0;
  ACell := BaseCell(ACell.Col, ACell.Row);
  cs := ColSpan(ACell.Col, ACell.Row);
  rs := RowSpan(ACell.Col, ACell.Row);
  if cs > 0 then
  begin
    for c := ACell.Col to ACell.Col + cs - 1 do
      totalw := totalw + ColumnWidths[c];
  end
  else
    totalw := ColumnWidths[ACell.Col];

  if rs > 0 then
  begin
    for r := ACell.Row to ACell.Row + rs - 1 do
      totalh := totalh + RowHeights[r];
  end
  else
    totalh := RowHeights[ACell.Row];

  rvs := GetVerticalPos(0, ACell.Row);
  if ForceScroll then
  begin
    case ScrollMode of
      scmPixelScrolling: vs := rvs - GetFixedHeight;
      scmItemScrolling: vs := ACell.Row - FixedRows - FreezeRows;
    end;
  end
  else
  begin
    if (rvs < vss + GetFixedHeight) then
    begin
      case ScrollMode of
        scmPixelScrolling: vs := rvs - GetFixedHeight;
        scmItemScrolling: vs := (ACell.Row - FixedRows - FreezeRows);
      end;
    end
    else
    begin
      case ScrollMode of
        scmPixelScrolling:
        begin
          if (rvs + totalh > vss + (contentr.Bottom - contentr.Top) - GetFixedFooterHeight) then
            vs := rvs + totalh - (contentr.Bottom - contentr.Top) + GetFixedFooterHeight;
        end;
        scmItemScrolling:
        begin
          while (rvs + totalh > vss + (contentr.Bottom - contentr.Top) - GetFixedFooterHeight) do
          begin
            toth := 0;
            vs := vs + 1;
            cl := BaseCell(ACell.Col, Round(vs));
            rs := RowSpan(cl.Col, cl.Row);
            if rs > 0 then
            begin
              for r := cl.Row to cl.Row + rs - 1 do
                toth := toth + RowHeights[r];
            end
            else
            begin
              rs := 1;
              toth := RowHeights[cl.Row];
            end;

            vs := vs + rs - 1;
            vss := vss + toth;
          end;
        end;
      end;
    end;
  end;

  rhs := GetHorizontalPos(0, ACell.Col);
  if ForceScroll then
  begin
    case ScrollMode of
      scmPixelScrolling: hs := rhs - GetFixedWidth;
      scmItemScrolling: hs := ACell.Col - FixedColumns - FreezeColumns;
    end;
  end
  else
  begin
    if (rhs < hss + GetFixedWidth) then
    begin
      case ScrollMode of
        scmPixelScrolling: hs := rhs - GetFixedWidth;
        scmItemScrolling: hs := ACell.Col - FixedColumns - FreezeColumns;
      end;
    end
    else
    begin
      case ScrollMode of
        scmPixelScrolling:
        begin
          if (rhs + totalw > hss + (contentr.Right - contentr.Left) - GetFixedRightWidth) then
            hs := rhs + totalw - (contentr.Right - contentr.Left) +  GetFixedRightWidth;
        end;
        scmItemScrolling:
        begin
          while (rhs + totalw > hss + (contentr.Right - contentr.Left) - GetFixedRightWidth) do
          begin
            totw := 0;
            hs := hs + 1;
            cl := BaseCell(Round(hs), ACell.Row);
            cs := ColSpan(cl.Col, cl.Row);
            if cs > 0 then
            begin
              for c := cl.Col to cl.Col + cs - 1 do
                totw := totw + ColumnWidths[c];
            end
            else
            begin
              cs := 1;
              totw := ColumnWidths[cl.Col];
            end;

            hs := hs + cs - 1;
            hss := hss + totw;
          end;
        end;
      end;
    end;
  end;

  Scroll(hs, vs);
end;


procedure TTMSFNCCustomGrid.NextPage;
var
  c: TTMSFNCGridCellRec;
begin
  c := FocusedCell;
  FocusedCell := NextSelectableRow(c.Col, c.Row + GetPageScrollSize - 1);
end;

procedure TTMSFNCCustomGrid.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (AComponent = FAdapter) and (Operation = opRemove) then
    Adapter := nil;

  if (AComponent = FToolBarPopup) and (Operation = opRemove) then
    FToolBarPopup := nil;
end;

procedure TTMSFNCCustomGrid.DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  cfs, rfs, cfst, rfst: Integer;
begin
  inherited;

  AGraphics.BitmapContainer := BitmapContainer;

  {$IFDEF TMSDEBUG}
  TMSLogger.StartTimer;
  {$ENDIF}

  cfs := GetFixedCols;
  rfs := GetFixedRows;
  cfst := FixedRightColumns;
  rfst := FixedFooterRows;

  if (ColumnCount > FixedColumns) and (RowCount > FixedRows) then
    PaintCells(AGraphics, FCStart, FCStop, FRStart, FRStop, cfs, rfs, rfs);

  if RowCount > FixedRows then
    PaintCells(AGraphics, 0, cfs - 1, FRStart, FRStop, 0, rfs, rfs);

  if ColumnCount > FixedColumns then
    PaintCells(AGraphics, FCStart, FCStop, 0, rfs - 1, cfs, 0, 0);

  PaintCells(AGraphics, 0, cfs - 1, 0, rfs - 1, 0, 0, 0);

  if (ColumnCount > FixedColumns + FixedRightColumns) and (RowCount > FixedRows) then
  begin
    PaintCells(AGraphics, ColumnCount - cfst, ColumnCount - 1, FRStart , FRStop, FCStop - FCStart + cfs + 1, rfs, rfs);
    PaintCells(AGraphics, ColumnCount - cfst, ColumnCount - 1, 0, rfs - 1, FCStop - FCStart + cfs + 1, 0, 0);
  end;

  if (ColumnCount > FixedColumns) and (RowCount > FixedRows + FixedFooterRows) then
    PaintCells(AGraphics, FCStart, FCStop, RowCount - rfst, RowCount - 1, cfs, FRStop - FRStart + rfs + 1, FRStop - FRStart + rfs + 1);

  if (ColumnCount > FixedColumns + FixedRightColumns) and (RowCount > FixedRows + FixedFooterRows) then
    PaintCells(AGraphics, ColumnCount - cfst, ColumnCount - 1, RowCount - rfst, RowCount - 1, FCStop - FCStart + cfs + 1 ,
     FRStop - FRStart + rfs + 1, FRStop - FRStart + rfs + 1);

  if RowCount > FixedRows then
    PaintCells(AGraphics, 0, cfs - 1, RowCount - rfst, RowCount - 1, 0, FRStop - FRStart + rfs + 1, FRStop - FRStart + rfs + 1);

  {$IFDEF TMSDEBUG}
  TMSLogger.StopTimer(True, lsmMilliseconds, 'Draw Content : {%s} ms');
  {$ENDIF}
end;

procedure TTMSFNCCustomGrid.PaintCells(AGraphics: TTMSFNCGraphics; CStart, CStop, RStart,
  RStop, Ci, Ri, ResetCi: Integer);
var
  c, r: Integer;
  cell: TTMSFNCGridCell;
  rctl, rt: TRectF;
  ck: Boolean;
  dr: Boolean;
  bc: TTMSFNCGridCellRec;
begin
  for c := CStart to CStop do
  begin
    for r := RStart to RStop do
    begin
      if (Length(FCellArray) > ci) then
      begin
        if (Length(FCellArray[ci]) > ri) then
        begin
          dr := True;
          ck := (FocusedCell.Col = c) and (FocusedCell.Row = r);
          if IsMerged(c, r) then
          begin
            bc := BaseCell(c, r);
            ck := ck or ((bc.Col = FocusedCell.Col) and (bc.Row = FocusedCell.Row));
          end;
          
          if (Editing and ck) then
            dr := False;

          cell := FCellArray[ci, ri];
          if Assigned(cell) then
          begin
            rctl := RectF(cell.Left, cell.Top, cell.Left + cell.Width, cell.Top + cell.Height);

            if (rctl.Bottom - rctl.Top > 1) and (rctl.Right - rctl.Left > 1) then
            begin
              if cell is TTMSFNCCheckGridCell then
                (cell as TTMSFNCCheckGridCell).ControlBitmap := GetCheckBoxBitmap((cell as TTMSFNCCheckGridCell).Checked, False);

              if cell is TTMSFNCRadioGridCell then
                (cell as TTMSFNCRadioGridCell).ControlBitmap := GetRadioButtonBitmap((cell as TTMSFNCRadioGridCell).Checked, False);

              if cell is TTMSFNCNodeGridCell then
                (cell as TTMSFNCNodeGridCell).ControlBitmap := GetNodeBitmap((cell as TTMSFNCNodeGridCell).State);

              if cell is TTMSFNCButtonGridCell then
                (cell as TTMSFNCButtonGridCell).ControlBitmap := GetDummyButtonBitmap(cell as TTMSFNCButtonGridCell);

              if not dr then
              begin
                cell.Layout.Fill.Assign(Appearance.NormalLayout.Fill);
                cell.Layout.Stroke.Assign(Appearance.NormalLayout.Stroke);
              end;

              rt := cell.GetTextRect;
              {$IFDEF LCLLIB}
              InflateRectEx(rt, -4, -2);
              {$ENDIF}
              {$IFNDEF LCLLIB}
              InflateRectEx(rt, -2, -2);
              {$ENDIF}

              cell.Draw(AGraphics, dr, dr, True);
              if (FocusedCell.Col = c) and (FocusedCell.Row = r) and not Editing and IsFocused and dr and Appearance.ShowFocus then
                AGraphics.DrawFocusRectangle(RectF(rctl.Left + 1, rctl.Top + 1, rctl.Right - 1, rctl.Bottom - 1), gcBlack, gcrmShrinkAll);
            end;           
          end;
        end;
      end;

      Inc(ri);
    end;

    Inc(ci);
    ri := ResetCi;
  end;
end;

procedure TTMSFNCCustomGrid.PreviousPage;
var
  c: TTMSFNCGridCellRec;
begin
  c := FocusedCell;
  FocusedCell := NextSelectableRow(c.Col, c.Row - GetPageScrollSize + 1);
end;

procedure TTMSFNCCustomGrid.ProcessTab(var Key: Word; Shift: TShiftState; Mode: TTMSFNCGridTabKeyDirection; Handling: TTMSFNCGridTabKeyHandling; DirectEdit: Boolean; CellStart: TTMSFNCGridEditCellStart = csNone);
var
  cl, clc, rl: TTMSFNCGridCellRec;
  c, r: integer;
  a, Allow: Boolean;
  SaveFocusCell: TTMSFNCGridCellRec;
begin
  if (Key = KEY_TAB) and (RowCount > FixedRows + FixedFooterRows) and (ColumnCount > FixedColumns + FixedRightColumns) then
  begin
    SaveFocusCell := FocusedCell;
    a := HideEdit;
    if not a then
      Exit;

    FocusedCell := SaveFocusCell;
    if IsFocused then
    begin
      case Handling of
        tkhNextCell: Key := 0;
        tkhMixed:
        begin
          if ssShift in Shift then
          begin
            cl.Col := FixedColumns;
            cl.Row := FixedRows;

            if IsNormalFixed(cl.Col, cl.Row) then
            begin
              case Mode of
                tkdNextColumnCell: cl := NextSelectableColumn(cl.Col, cl.Row);
                tkdNextRowCell: cl := NextSelectableRow(cl.Col, cl.Row);
              end;
            end;

            if (Handling = tkhMixed) and not ((FocusedCell.Col = cl.Col) and (FocusedCell.Row = cl.Row)) then
              Key := 0;
          end
          else
          begin
            cl.Col := ColumnCount - 1 - FixedRightColumns;
            cl.Row := RowCount - 1 - FixedFooterRows;

            if IsNormalFixed(cl.Col, cl.Row) then
            begin
              case Mode of
                tkdNextColumnCell: cl := PreviousSelectableColumn(cl.Col, cl.Row);
                tkdNextRowCell: cl := PreviousSelectableRow(cl.Col, cl.Row);
              end;
            end;

            if (Handling = tkhMixed) and not ((FocusedCell.Col = cl.Col) and (FocusedCell.Row = cl.Row)) then
              Key := 0;
          end;
        end
      end;

      if Key = 0 then
      begin
        case Handling of
          tkhNextCell, tkhMixed:
          begin
            cl := FocusedCell;
            rl := FRealCell;
            cl := BaseCell(cl.Col, cl.Row);

            if ssShift in Shift then
            begin
              clc := MakeCell(FixedColumns, FixedRows);
              if (FocusedCell.Col = clc.Col) and (FocusedCell.Row = clc.Row) then
              begin
                clc := MakeCell(ColumnCount - 1 - FixedRightColumns, RowCount - 1 - FixedFooterRows);
                FocusedCell := clc;
                Exit;
              end;

              case Mode of
                tkdNextColumnCell:
                begin
                  c := cl.Col;
                  cl := PreviousSelectableColumn(cl.Col, cl.Row);
                  cl.Row := rl.Row;
                  if (c = cl.Col) then
                  begin
                    r := cl.row;
                    cl.Col := ColumnCount - 1 - FixedRightColumns;
                    cl := PreviousSelectableColumn(cl.Col, cl.Row);
                    if r = cl.Row then
                    begin
                      cl := PreviousSelectableColumn(ColumnCount - FixedRightColumns, cl.Row - 1);
                      while (cl.Col = ColumnCount - FixedRightColumns) and (cl.Row = r - 1) do
                      begin
                        r  := cl.Row;
                        cl := PreviousSelectableColumn(ColumnCount - FixedRightColumns, cl.Row - 1);
                      end;
                    end;
                    if (Handling = tkhNextCell) and ((cl.Row < FixedRows) or (r = cl.row)) then
                    begin
                      cl.Col := ColumnCount - 1 - FixedRightColumns;
                      cl.Row := RowCount - 1 - FixedFooterRows;
                      cl := PreviousSelectableColumn(cl.Col, cl.Row);
                    end;
                  end;
                end;
                tkdNextRowCell:
                begin
                  r := cl.Row;
                  cl := PreviousSelectableRow(cl.Col, cl.Row);
                  cl.Col := rl.Col;
                  if (r = cl.Row) then
                  begin
                    c := cl.Col;
                    cl.Row := RowCount - 1 - FixedFooterRows;
                    cl := PreviousSelectableRow(cl.Col, cl.Row);
                    if c = cl.Col then
                    begin
                      cl := PreviousSelectableRow(cl.Col - 1, RowCount - FixedFooterRows);
                      while (cl.Row = RowCount - FixedFooterRows) and (cl.Col = c - 1) do
                      begin
                        c  := cl.Col;
                        cl := PreviousSelectableRow(cl.Col - 1, RowCount - FixedFooterRows);
                      end;
                    end;
                    if (Handling = tkhNextCell) and ((cl.Col < FixedColumns) or (c = cl.Col)) then
                    begin
                      cl.Col := ColumnCount - 1 - FixedRightColumns;
                      cl.Row := RowCount - 1 - FixedFooterRows;
                      cl := PreviousSelectableRow(cl.Col, cl.Row);
                    end;
                  end;
                end;
              end;
              SelectCell(cl);
              if DirectEdit then
              begin
                Allow := True;
                DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
                if Allow then
                  EditCell(FocusedCell, CellStart);
              end;
            end
            else
            begin
              clc := MakeCell(ColumnCount - 1 - FixedRightColumns, RowCount - 1 - FixedFooterRows);
              if (FocusedCell.Col = clc.Col) and (FocusedCell.Row = clc.Row) then
              begin
                clc := MakeCell(FixedColumns, FixedRows);
                FocusedCell := clc;
                Exit;
              end;

              case Mode of
                tkdNextColumnCell:
                begin
                  c := cl.Col;
                  cl := NextSelectableColumn(cl.Col, cl.Row);
                  cl.Row := rl.Row;
                  if (c = cl.Col) then
                  begin
                    r := cl.row;
                    cl.Col := FixedColumns;
                    cl := NextSelectableColumn(cl.Col, cl.Row);
                    if r = cl.Row then
                    begin
                      cl := NextSelectableColumn(FixedColumns - 1, cl.Row + 1);
                      while (cl.Col = FixedColumns - 1) and (cl.Row = r + 1) do
                      begin
                        r := cl.Row;
                        cl := NextSelectableColumn(FixedColumns - 1, cl.row + 1);
                      end;
                    end;

                    if (Handling = tkhNextCell) and ((cl.Row > RowCount - 1 - FixedFooterRows) or (r = cl.row)) then
                    begin
                      cl.Col := FixedColumns;
                      cl.Row := FixedRows;
                      cl := NextSelectableColumn(cl.Col, cl.Row);
                    end;
                  end;
                end;
                tkdNextRowCell:
                begin
                  r := cl.Row;
                  cl := NextSelectableRow(cl.Col, cl.Row);
                  cl.Col := rl.Col;
                  if (r = cl.Row) then
                  begin
                    c := cl.Col;
                    cl.Row := FixedRows;
                    cl := NextSelectableRow(cl.Col, cl.Row);
                    if c = cl.Col then
                    begin
                      cl := NextSelectableRow(cl.Col + 1, FixedRows - 1);
                      while (cl.Row = FixedRows - 1) and (cl.Col = c + 1) do
                      begin
                        c := cl.Col;
                        cl := NextSelectableRow(cl.Col + 1, FixedRows - 1);
                      end;
                    end;
                    if (Handling = tkhNextCell) and ((cl.Col > ColumnCount - 1 - FixedRightColumns) or (c = cl.Col)) then
                    begin
                      cl.Col := FixedColumns;
                      cl.Row := FixedRows;
                      cl := NextSelectableRow(cl.Col, cl.Row);
                    end;
                  end;
                end;
              end;
              SelectCell(cl);
              if DirectEdit then
              begin
                Allow := True;
                DoCanEditCell(FocusedCell.Col, FocusedCell.Row, Allow);
                if Allow then
                  EditCell(FocusedCell, CellStart);
              end;
            end;
          end;
        end;
      end;
    end
    else
    begin
      if Handling <> tkhNextControl then
      begin
        if ssShift in Shift then
        begin
          cl.Col := ColumnCount - 1 - FixedRightColumns;
          cl.Row := RowCount - 1 - FixedFooterRows;
          if IsNormalFixed(cl.Col, cl.Row) then
          begin
            case Mode of
              tkdNextColumnCell: cl := PreviousSelectableColumn(cl.Col, cl.Row);
              tkdNextRowCell: cl := PreviousSelectableRow(cl.Col, cl.Row);
            end;
          end;
          SelectCell(cl);
        end
        else
        begin
          cl.Col := FixedColumns;
          cl.Row := FixedRows;
          if IsNormalFixed(cl.Col, cl.Row) then
          begin
            case Mode of
              tkdNextColumnCell: cl := NextSelectableColumn(cl.Col, cl.Row);
              tkdNextRowCell: cl := NextSelectableRow(cl.Col, cl.Row);
            end;
          end;
          SelectCell(cl)
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomGrid.UpdateControlAfterResize;
var
  a: Boolean;
begin
  inherited;
  a := True;
  if Assigned(Adapter) then
  begin
    Adapter.UpdateBounds;
    a := Adapter.CanCancelEdit;
  end;

  if Editing and a then
    CancelEdit;
end;

procedure TTMSFNCCustomGrid.VerticalScrollPositionChanged;
begin
  if FReset then
    Exit;

  StopEdit;

  UpdateGridCells(True);
end;

procedure TTMSFNCCustomGrid.HorizontalScrollPositionChanged;
begin
  if FReset then
    Exit;

  StopEdit;

  UpdateGridCells(True);
end;

procedure TTMSFNCCustomGrid.InitSample;
var
  I: Integer;
begin
  BeginUpdate;

  UseColumns := False;

  ResetToDefaultStyle;

  DefaultRowHeight := ScalePaintValue(40);
  FixedColumns := 0;
  Options.Bands.Enabled := True;

  for I := 0 to Columns.Count - 1 do
  begin
    Columns[I].Width := ScalePaintValue(90);
    {$IFDEF FMXLIB}
    Columns[I].FixedFont.Color := $FF454545;
    Columns[I].Font.Color := $FF7A7A7A;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    Columns[I].FixedFont.Color := $454545;
    Columns[I].Font.Color := $7A7A7A;
    {$ENDIF}
  end;

  Columns[0].Width := ScalePaintValue(70);
  Columns[1].Width := ScalePaintValue(250);
  Columns[2].Width := ScalePaintValue(100);
  Columns[3].Width := ScalePaintValue(100);

  LoadSampleData;

  ColumnCount := 4;
  Width := ScalePaintValue(520);

  Endupdate;
end;

procedure TTMSFNCCustomGrid.LoadSampleData;
var
  I: Integer;
  J: Integer;
  s, p: string;
  d: TDate;
begin
  BeginUpdate;

  for I := 0 to FixedRows - 1 do
  begin
    if I > 0 then
      p := 'Sub '
    else
      p := '';

    for J := FixedColumns to ColumnCount - 1 do
    begin
      case J of
        0: s := p + 'ID';
        1: s := p + 'Name';
        2: s := p + 'Joined';
        3: s := p + 'Status';
        else
          s := p + 'Number';
      end;

      Cells[J, I] := s;
    end;
  end;

  for I := FixedRows to RowCount - 1 do
  begin
    for J := FixedColumns to ColumnCount - 1 do
    begin
      s := '';

      case J of
        0: s := '00' + I.ToString;
        1: begin
          case Random(120) mod 5 of
            0: s := 'David';
            1: s := 'Laura';
            2: s := 'Isaac';
            3: s := 'Marie';
            else
              s := 'Leon';
          end;

          case Random(120) mod 5 of
            0: s := s + ' Kleermakers';
            1: s := s + ' Tailor';
            2: s := s + ' Terzi';
            3: s := s + ' Sastre';
            else
              s := s + ' Schneider';
          end;
        end;
        2: begin
          d := Round(Now) - Random(5475);
          s := DateToStr(d);
        end;
        3: begin
          case Random(120) mod 5 of
            0: s := 'Flight';
            1: s := 'On Leave';
            2: s := 'Abroad';
            else
              s := 'Office';
          end;
        end;
        else
        begin
          s := Random(100).ToString;
        end;
      end;

      Cells[J, I] := s;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomGrid.LoadSettingsFromFile(AFileName: string);
begin
  UseColumns := False;
  inherited;
end;

procedure TTMSFNCCustomGrid.LoadSettingsFromStream(AStream: TStreamEx);
begin
  UseColumns := False;
  inherited;
end;

procedure TTMSFNCCustomGrid.ScrollTimer(Sender: TObject);
var
  c: TTMSFNCGridCellRec;
  contentr: TRectF;
begin
  if not Options.Mouse.AutoScrolling then
  begin
    FScrollTimer.Enabled := False;
    Exit;
  end;

  contentr := GetContentRect;

  if (FX >= (contentr.Right - contentr.Left) - GetFixedRightWidth) then
  begin
    c := FocusedCell;
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      c.Col := c.Col + Round(FX - ((contentr.Right - contentr.Left) - GetFixedRightWidth)) * Options.Mouse.AutoScrollingSpeed
    else
      c.Col := c.Col + Options.Mouse.AutoScrollingSpeed;
    SetFocusCell(c.Col, c.Row);
    SelectCell(FocusedCell, [], FMouseDragging);
  end
  else if FX <= GetFixedWidth then
  begin
    c := FocusedCell;
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      c.Col := c.Col + Round(FX - GetFixedWidth) * Options.Mouse.AutoScrollingSpeed
    else
      c.Col := c.Col - Options.Mouse.AutoScrollingSpeed;
    SetFocusCell(c.Col, c.Row);
    SelectCell(FocusedCell, [], FMouseDragging);
  end;

  if (FY >= (contentr.Bottom - contentr.Top) - GetFixedFooterHeight) then
  begin
    c := FocusedCell;
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      c.Row := c.Row + Round(FY - ((contentr.Bottom - contentr.Top) - GetFixedFooterHeight)) * Options.Mouse.AutoScrollingSpeed
    else
      c.Row := c.Row + Options.Mouse.AutoScrollingSpeed;
    SetFocusCell(c.Col, c.Row);
    SelectCell(FocusedCell, [], FMouseDragging);
  end
  else if FY <= GetFixedHeight then
  begin
    c := FocusedCell;
    if Options.Mouse.AutoScrollingSpeedMode = asmPixels then
      c.Row := c.Row + Round(FY - GetFixedHeight) * Options.Mouse.AutoScrollingSpeed
    else
      c.Row := c.Row - Options.Mouse.AutoScrollingSpeed;
    SetFocusCell(c.Col, c.Row);
    SelectCell(FocusedCell, [], FMouseDragging);
  end;
end;

procedure TTMSFNCCustomGrid.SelectCell(Cell: TTMSFNCGridCellRec; Shift: TShiftState = []; MouseDragging: Boolean = False);
var
  sta, stc: TTMSFNCGridCellRec;
  rs, cs, rsstart, csstart: Integer;
  bs: TTMSFNCGridCellRec;
  cst, rst: Integer;
  ic, ir: Integer;
  I, J: Integer;
  doUpdate: Boolean;
  Allow: Boolean;
  key: Word;
  k: Char;
  cntr, cntc, cntcl: Integer;
begin
  inherited;
  FPrevSelection := Selection;

  if (Cell.Row < FixedRows) and (Cell.Col < FixedColumns) and (fcsAll in Options.Mouse.FixedCellSelection) then
  begin
    k := #31;
    key := Ord('A');
    HandleKeyPress(k);
    HandleKeyDown(key, [ssCtrl]);
    Exit;
  end;

  Cell.Row := Max(FixedRows, Min(Cell.Row, RowCount - 1 - FixedFooterRows));
  Cell.Col := Max(FixedColumns, Min(Cell.Col, ColumnCount - 1 - FixedRightColumns));

  Allow := True;
  if Assigned(OnSelectCell) and not BlockSelectEventHandler then
    OnSelectCell(Self, Cell.Col, Cell.Row, Allow);

  if not Allow then
    Exit;

  FPrevSelectedCell := FocusedCell;
  FRealCell := Cell;
  Cell := BaseCell(Cell.Col, Cell.Row);
  SetFocusCell(Cell.Col, Cell.Row);
  case Options.Selection.Mode of
    smSingleCell:
    begin
      SetStartCell(FocusedCell.Col, FocusedCell.Row);
      SetStopCell(FocusedCell.Col, FocusedCell.Row);
    end;
    smSingleRow:
    begin
      SetStartCell(FixedColumns, FocusedCell.Row);
      SetStopCell(ColumnCount - 1 - FixedRightColumns, FocusedCell.Row);
    end;
    smSingleColumn:
    begin
      SetStartCell(FocusedCell.Col, FixedRows);
      SetStopCell(FocusedCell.Col, RowCount - 1 - FixedFooterRows);
    end;
    smCellRange, smRowRange, smColumnRange, smDisjunctRow, smDisjunctColumn, smDisjunctCell:
    begin
      doUpdate := True;
      if (not MouseDragging or (FFirstCell.Row = -1) or (FFirstCell.Col = -1)) and not (ssShift in Shift) and not (ssCtrl in shift) then
      begin
        SetStartCell(FRealCell.Col, FocusedCell.Row);
        SetStopCell(FocusedCell.Col, FocusedCell.Row);
        FFirstCell := StartCell;
        FSaveCell := StartCell;
      end
      else if (ssShift in Shift) and ((FSaveCell.Row = -1) or (FSaveCell.Col = -1)) then
      begin
        SetStartCell(FocusedCell.Col, FocusedCell.Row);
        SetStopCell(FocusedCell.Col, FocusedCell.Row);
        FSaveCell := StartCell;
      end
      else if (ssCtrl in Shift) and ((Options.Selection.Mode = smDisjunctRow) or (Options.Selection.Mode = smDisjunctColumn) or (Options.Selection.Mode = smDisjunctCell)) then
      begin
        case Options.Selection.Mode of
          smDisjunctRow:
          begin
            if FocusedCell.Row < StartCell.Row then
            begin
              SetStartCell(FRealCell.Col, FocusedCell.Row);
              FFirstCell := StopCell;
              FSaveCell := StopCell;
            end
            else if FocusedCell.Row > StopCell.Row then
            begin
              SetStopCell(FocusedCell.Col, FocusedCell.Row);
              FFirstCell := StartCell;
              FSaveCell := StartCell;
            end
            else
              doUpdate := False;
          end;
          smDisjunctColumn:
          begin
            if FocusedCell.Col < StartCell.Col then
            begin
              SetStartCell(FocusedCell.Col, FRealCell.Row);
              FFirstCell := StopCell;
              FSaveCell := StopCell;
            end
            else if FocusedCell.Col > StopCell.Col then
            begin
              SetStopCell(FocusedCell.Col, FocusedCell.Row);
              FFirstCell := StartCell;
              FSaveCell := StartCell;
            end
            else
              doUpdate := False;
          end;
          smDisjunctCell:
          begin

            doUpdate := False;
            if FocusedCell.Col < StartCell.Col then
            begin
              if FocusedCell.Row < StartCell.Row then
                SetStartCell(FocusedCell.Col, FocusedCell.Row)
              else if FocusedCell.Row > StopCell.Row then
                SetStopCell(FocusedCell.Col, FocusedCell.Row)
              else if (FocusedCell.Row >= StartCell.Row) or (FocusedCell.Row <= StopCell.Row) then
                SetStartCell(FocusedCell.Col, StartCell.Row);
            end
            else if FocusedCell.Col > StopCell.Col then
            begin
              if FocusedCell.Row < StartCell.Row then
                SetStartCell(FocusedCell.Col, FocusedCell.Row)
              else if FocusedCell.Row > StopCell.Row then
                SetStopCell(FocusedCell.Col, FocusedCell.Row)
              else if (FocusedCell.Row >= StartCell.Row) or (FocusedCell.Row >= StopCell.Row) then
                SetStopCell(FocusedCell.Col, StopCell.Row);
            end
            else if (FocusedCell.Col >= StartCell.Col) and (FocusedCell.Col <= StopCell.Col) then
            begin
              if FocusedCell.Row < StartCell.Row then
                SetStartCell(StartCell.Col, FocusedCell.Row)
              else if FocusedCell.Row > StopCell.Row then
                SetStopCell(StopCell.Col, FocusedCell.Row);
            end
          end;
        end;
      end;

      if (ssShift in Shift) then
        FFirstCell := FSaveCell;

      if doUpdate then
      begin
        if (FocusedCell.Col < FFirstCell.Col) then
        begin
          SetStartCell(FocusedCell.Col, StartCell.Row);
          SetStopCell(FFirstCell.Col, StopCell.Row);
        end
        else
        begin
          SetStopCell(FocusedCell.Col, StopCell.Row);
          SetStartCell(FFirstCell.Col, StartCell.Row);
        end;

        if (FocusedCell.Row < FFirstCell.Row) then
        begin
          SetStartCell(StartCell.Col, FocusedCell.Row);
          SetStopCell(StopCell.Col, FFirstCell.Row);
        end
        else
        begin
          SetStopCell(StopCell.Col, FocusedCell.Row);
          SetStartCell(StartCell.Col, FFirstCell.Row);
        end;
      end;

      case Options.Selection.Mode of
        smDisjunctRow, smDisjunctColumn, smDisjunctCell:
        begin
          if not (ssCtrl in Shift) and not (ssShift in Shift) then
          begin
            SetStartCell(FocusedCell.Col, FocusedCell.Row);
            SetStopCell(FocusedCell.Col, FocusedCell.Row);
            FSaveCell := FocusedCell;
          end;
        end;
      end;

      case Options.Selection.Mode of
        smRowRange, smDisjunctRow:
        begin
          SetStartCell(FixedColumns, StartCell.Row);
          SetStopCell(ColumnCount - 1 - FixedRightColumns, StopCell.Row);
        end;
        smColumnRange, smDisjunctColumn:
        begin
          SetStartCell(StartCell.Col, FixedRows);
          SetStopCell(StopCell.Col, RowCount - 1 - FixedFooterRows);
        end;
      end;
    end;
  end;

  stc := StopCell;
  sta := StartCell;

  if StopCell.Col < StartCell.Col then
  begin
    SetStopCell(sta.Col, StopCell.Row);
    SetStartCell(stc.Col, StartCell.Row);
  end;

  if StopCell.Row < StartCell.Row then
  begin
    SetStopCell(StopCell.Col, sta.Row);
    SetStartCell(StartCell.Col, stc.Row);
  end;

  Navigate(FocusedCell);

  cs := StopCell.Col;
  csstart := StartCell.Col;
  rs := StopCell.Row;
  rsstart := StartCell.Row;
  for ic := Max(FCStart, csstart) to Min(FCStop, cs) do
  begin
    for ir := Max(FRStart, rsstart) to Min(FRStop, rs) do
    begin
      bs := BaseCell(ic, ir);
      cst := ColSpan(bs.Col, bs.Row);
      if bs.Col + cst - 1 > cs then
        cs := bs.Col + cst - 1;

      if bs.Col < csstart then
        csstart := bs.Col;

      rst := RowSpan(bs.Col, bs.Row);
      if bs.Row + rst - 1 > rs then
        rs := bs.Row + rst - 1;

      if bs.Row < rsstart then
        rsstart := bs.Row;
    end;
  end;

  SetStopCell(cs,rs);
  SetStartCell(csstart, rsstart);

  case Options.Selection.Mode of
    smDisjunctRow:
    begin
      BlockUpdate := True;

      if not (ssCtrl in Shift) then
        ClearRowSelect;

      if ssShift in Shift then
      begin
        for I := StartCell.Row to StopCell.Row do
          RowSelect[I] := not RowSelect[I];
      end
      else
      begin
        RowSelect[FocusedCell.Row] := not RowSelect[FocusedCell.Row];
        cntr := GetSelectedRowCount;
        if not RowSelect[FocusedCell.Row] and (cntr = 0) then
          RowSelect[FocusedCell.Row] := True;
      end;

      if not RowSelect[FocusedCell.Row] then
        for I := StartCell.Row to StopCell.Row do
          if (I <> FocusedCell.Row) and (RowSelect[I]) then
            SetFocusCell(FocusedCell.Col, I);

      BlockUpdate := False;
    end;
    smDisjunctColumn:
    begin
      BlockUpdate := True;

      if not (ssCtrl in Shift) then
        ClearColumnSelect;

      if ssShift in Shift then
      begin
        for I := StartCell.Col to StopCell.Col do
          ColumnSelect[I] := not ColumnSelect[I];
      end
      else
      begin
        ColumnSelect[FocusedCell.Col] := not ColumnSelect[FocusedCell.Col];
        cntc := GetSelectedColumnCount;
        if not ColumnSelect[FocusedCell.Col] and (cntc = 0) then
          ColumnSelect[FocusedCell.Col] := True;
      end;

      if not ColumnSelect[FocusedCell.Col] then
        for I := StartCell.Col to StopCell.Col do
          if (I <> FocusedCell.Col) and (ColumnSelect[I]) then
            SetFocusCell(I, FocusedCell.Row);

      BlockUpdate := False;
    end;
    smDisjunctCell:
    begin
      BlockUpdate := True;

      if not (ssCtrl in Shift) then
        ClearCellSelect;

      if ssShift in Shift then
      begin
        for I := StartCell.Col to StartCell.Row do
          for J := StartCell.Row to StopCell.Row do
            CellSelect[I, J] := not CellSelect[I, J];
      end
      else
      begin
        CellSelect[FocusedCell.Col, FocusedCell.Row] := not CellSelect[FocusedCell.Col, FocusedCell.Row];
        cntcl := GetSelectedCellCount;
        if not CellSelect[FocusedCell.Col, FocusedCell.Row] and (cntcl = 0) then
          CellSelect[FocusedCell.Col, FocusedCell.Row] := True;
      end;

      if not CellSelect[FocusedCell.Col, FocusedCell.Row] then
        for I := StartCell.Col to StartCell.Row do
          for J := StartCell.Row to StopCell.Row do
            if (I <> FocusedCell.Col) and (J <> FocusedCell.Row) and CellSelect[I, J] then
              SetFocusCell(I, J);

      BlockUpdate := False;
    end;
  end;

  Navigate(FocusedCell);
  if Allow and Assigned(Adapter) then
    Adapter.SelectCell(FocusedCell);

  if Allow and Assigned(OnSelectedCell) and not BlockSelectEventHandler then
    OnSelectedCell(Self, FocusedCell.Col, FocusedCell.Row);

end;

procedure TTMSFNCCustomGrid.SetAdapter(const Value: TTMSFNCGridAdapter);
begin
  if FAdapter <> Value then
  begin
    FAdapter := Value;
    if FBlockAdd then
      Exit;

    FBlockAdd := True;
    if Assigned(FAdapter) then
    begin
      FAdapter.Grid := Self;
      FAdapter.Initialize;
    end;
    FBlockAdd := False;
  end;
end;

procedure TTMSFNCCustomGrid.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FCommentPopup) then
    FCommentPopup.AdaptToStyle := True;

  if Assigned(FFilterPopup) then
    FFilterPopup.AdaptToStyle := True;
end;

procedure TTMSFNCCustomGrid.SetAppearance(const Value: TTMSFNCGridAppearance);
begin
  if FAppearance <> Value then
  begin
    FAppearance.Assign(Value);
  end;
end;

procedure TTMSFNCCustomGrid.SetC(const Value: TCursor);
begin
  inherited Cursor := Value;
  if ChangeCursor then
    OldCursor := Value;
end;

procedure TTMSFNCCustomGrid.SetDesignTimeSampleData(const Value: Boolean);
begin
  FDesignTimeSampleData := Value;

  if FDesignTimeSampleData and ((csLoading in ComponentState) or (csReading in ComponentState)) and (csDesigning in ComponentState) then
  begin
    LoadSampleData;
  end;
end;

procedure TTMSFNCCustomGrid.SetFreezeColumns(const Value: Integer);
begin
  if FFreezeColumns <> Value then
  begin
    FFreezeColumns := Value;
    UpdateControl;
  end;
end;

procedure TTMSFNCCustomGrid.SetFreezeRows(const Value: Integer);
begin
  if FFreezeRows <> Value then
  begin
    FFreezeRows := Value;
    UpdateControl;
  end;
end;

procedure TTMSFNCCustomGrid.SetLeftCol(const Value: Integer);
var
  cl: TTMSFNCGridCellRec;
begin
  cl.Row := Max(FixedRows, Min(FRStart, RowCount - 1 - FixedFooterRows));
  cl.Col := Max(FixedColumns, Min(Value, ColumnCount - 1 - FixedRightColumns));

  Navigate(cl,  True);
end;

procedure TTMSFNCCustomGrid.SetRowSelect(Row: integer; const Value: boolean);
begin
  if not BlockUpdate and Value then
  begin
    BlockSelectEventHandler := True;
    SelectCell(MakeCell(FixedColumns, Row), [ssCtrl]);
  end
  else
    inherited;
end;

procedure TTMSFNCCustomGrid.SetTopRow(const Value: Integer);
var
  cl: TTMSFNCGridCellRec;
begin
  cl.Row := Max(FixedRows, Min(Value, RowCount - 1 - FixedFooterRows));
  cl.Col := Max(FixedColumns, Min(FCStart, ColumnCount - 1 - FixedRightColumns));
  Navigate(cl, True);
end;

procedure TTMSFNCCustomGrid.StretchColumn(ACol: Integer; ANewWidth: Single = -1);
var
  i: Integer;
  w, nw, d: Single;
  cnt: Integer;
  contentr: TRectF;
begin
  contentr := GetContentRect;
  if ANewWidth = -1 then
    nw := (contentr.Right - contentr.Left) - 1
  else
    nw := ANewWidth;

  cnt := ColumnCount;

  if ACol = - 1 then
    ACol := cnt - 1;

  if (cnt = 0) or not Options.ColumnSize.Stretch then
    Exit;

  if ACol >= cnt then
    raise Exception.Create('Stretch column index out of range');

  if cnt = 1 then
  begin
    ColumnWidths[0] := (contentr.Right - contentr.Left) - 1;
    Exit;
  end;

  w := 0;

  if Options.ColumnSize.StretchAll then
  begin
    if (cnt - FixedColumns - FixedRightColumns > 0) then
    begin
      d := nw;

      for i := 1 to FixedColumns do
        d := d - ColumnWidths[i - 1];

      for i := 1 to FixedRightColumns do
        d := d - ColumnWidths[cnt - i];

      w := d / (cnt - FixedColumns - FixedRightColumns);

      for i := FixedColumns to cnt - FixedRightColumns - 1 do
        ColumnWidths[i] := w;
    end;
  end
  else
  begin
    for i := 0 to cnt - 1 do
    begin
      if i <> ACol then
        w := w + ColumnWidths[i];
    end;

    if w < Width then
      ColumnWidths[ACol] := nw - w {- 1};
  end;
end;

procedure TTMSFNCCustomGrid.UpdateCursor(ACursor: TCursor);
begin
  ChangeCursor := False;
  Cursor := ACursor;
  ChangeCursor := True;
end;

procedure TTMSFNCCustomGrid.UpdateControlCache;
var
  r: Single;
  scol, ecol, i: Integer;
  cl: TTMSFNCGridCellRec;
begin
  if (UpdateCount > 0) or BlockUpdate or (csDestroying in ComponentState) then
    Exit;

  if (OldSize > 0) and Options.ColumnSize.SyncWithGrid then
  begin
    r := Width / OldSize;

    if Options.ColumnSize.SyncNormalCellsOnly then
    begin
      scol := FixedColumns;
      ecol := ColumnCount - 1 - FixedRightColumns;
    end
    else
    begin
      scol := 0;
      ecol := ColumnCount - 1;
    end;

    for i := scol to ecol do
      if OrigColumnW.Count > i then
        ColumnWidths[i] := OrigColumnW[i].Value * r;
  end;

  if Options.ColumnSize.Stretch then
    StretchColumn(Options.ColumnSize.StretchColumn);

  UpdateControlScrollBars;

  FContainerWidth := GetTotalColumnWidth;
  FContainerHeight := GetTotalRowHeight;

  UpdateControlScrollBars(False, False);

  if Options.ColumnSize.Stretch then
    StretchColumn(Options.ColumnSize.StretchColumn);

  FContainerWidth := GetTotalColumnWidth;
  FContainerHeight := GetTotalRowHeight;

  if (ColumnCount > FixedColumns) and (RowCount > FixedRows) then
  begin
    if not FirstCellApply then
    begin
      cl.Col := FixedColumns;
      cl.Row := FixedRows;
      if IsNormalFixed(cl.Col, cl.Row) then
        cl := NextSelectableColumn(cl.Col, cl.Row);

      SetStartCell(cl.Col, cl.Row);
      SetStopCell(cl.Col, cl.Row);
      SetFocusCell(cl.Col, cl.Row);
      FPrevSelectedCell := FocusedCell;
      FSaveCell := FocusedCell;
      FFirstCell.Col := -1;
      FFirstCell.Row := -1;
      FRealCell := FocusedCell;
      FPrevSelection := Selection;
      case Options.Selection.Mode of
        smRowRange, smSingleRow, smDisjunctRow: SetStopCell(ColumnCount - 1 - FixedRightColumns, StopCell.Row);
        smColumnRange, smSingleColumn, smDisjunctColumn: SetStopCell(StopCell.Col, RowCount - 1 - FixedFooterRows);
      end;

      BlockUpdate := True;
      case Options.Selection.Mode of
        smDisjunctRow: RowSelect[FocusedCell.Row] := True;
        smDisjunctColumn: ColumnSelect[FocusedCell.Col] := True;
        smDisjunctCell: CellSelect[FocusedCell.Col, FocusedCell.Row] := True;
       end;
      BlockUpdate := False;

      FirstCellApply := True;
    end
    else
    begin
      case Options.Selection.Mode of
        smRowRange, smSingleRow, smDisjunctRow: SetStopCell(ColumnCount - 1 - FixedRightColumns, StopCell.Row);
        smColumnRange, smSingleColumn, smDisjunctColumn: SetStopCell(StopCell.Col, RowCount - 1 - FixedFooterRows);
      end;
    end;
  end
  else
  begin
    cl.Col := -1;
    cl.Row := -1;
    StartCell := cl;
    StopCell := cl;
    FocCell := cl;

    FPrevSelectedCell := FocusedCell;
    FSaveCell := FocusedCell;
    FFirstCell.Col := -1;
    FFirstCell.Row := -1;
    FRealCell := FocusedCell;
    FPrevSelection := Selection;
  end;

  UpdateGridCells;
end;

procedure TTMSFNCCustomGrid.UpdateControlDisplay;
begin
  inherited;
  UpdateGridCellDisplay;
end;

procedure TTMSFNCCustomGrid.UpdateControlScroll(AHorizontalPos, AVerticalPos,
  ANewHorizontalPos, ANewVerticalPos: Double);
begin
  inherited;
  if (AHorizontalPos <> ANewHorizontalPos) or (AVerticalPos <> ANewVerticalPos) then
    UpdateGridCells
  else
    UpdateGridCellDisplay;
end;

procedure TTMSFNCCustomGrid.UpdateGridCellDisplay;
var
  cl: TTMSFNCGridCellRecRange;
begin
  if (UpdateCount > 0) or BlockUpdate or (csDestroying in ComponentState) then
    Exit;

  BlockUpdate := True;

  cl.StartCol := FPrevSelection.StartCol;
  cl.EndCol := FPrevSelection.EndCol;
  cl.StartRow := 0;
  cl.EndRow := FixedRows - 1;
  DoUpdateGridCellDisplay(cl);

  cl.StartCol := 0;
  cl.EndCol := FixedColumns - 1;
  cl.StartRow := FPrevSelection.StartRow;
  cl.EndRow := FPrevSelection.EndRow;
  DoUpdateGridCellDisplay(cl);

  cl.StartCol := FPrevSelection.StartCol;
  cl.EndCol := FPrevSelection.EndCol;
  cl.StartRow := RowCount - FixedFooterRows;
  cl.EndRow := RowCount - 1;
  DoUpdateGridCellDisplay(cl);

  cl.StartCol := ColumnCount - FixedRightColumns;
  cl.EndCol := ColumnCount - 1;
  cl.StartRow := FPrevSelection.StartRow;
  cl.EndRow := FPrevSelection.EndRow;
  DoUpdateGridCellDisplay(cl);

  DoUpdateGridCellDisplay(FPrevSelection);

  cl.StartCol := Selection.StartCol;
  cl.EndCol := Selection.EndCol;
  cl.StartRow := 0;
  cl.EndRow := FixedRows - 1;
  DoUpdateGridCellDisplay(cl);

  cl.StartCol := 0;
  cl.EndCol := FixedColumns - 1;
  cl.StartRow := Selection.StartRow;
  cl.EndRow := Selection.EndRow;
  DoUpdateGridCellDisplay(cl);

  cl.StartCol := Selection.StartCol;
  cl.EndCol := Selection.EndCol;
  cl.StartRow := RowCount - FixedFooterRows;
  cl.EndRow := RowCount - 1;
  DoUpdateGridCellDisplay(cl);

  cl.StartCol := ColumnCount - FixedRightColumns;
  cl.EndCol := ColumnCount - 1;
  cl.StartRow := Selection.StartRow;
  cl.EndRow := Selection.EndRow;
  DoUpdateGridCellDisplay(cl);

  DoUpdateGridCellDisplay(Selection);

  BlockUpdate := False;
  Invalidate;
end;

procedure TTMSFNCCustomGrid.UpdateGridCells(AScrollOnly: Boolean = False);
var
  vVal, hVal: Single;
  cw, rh: Single;
  x, y, xs, ys: Single;
  rBounds: TRectF;
  xOrig, yOrig: Single;
  scrollv, scrollh: Single;
  i: Integer;
  fw, fh, fwr, fhr: Single;
  cfs, rfs, cfst, rfst: Integer;
  hMax, vMax: Single;
  containerh, containerw: Single;
begin
  if (UpdateCount > 0) or BlockUpdate or (csDestroying in ComponentState) then
    Exit;

  {$IFDEF TMSDEBUG}
  TMSLogger.StartTimer;
  {$ENDIF}

  BlockUpdate := true;
  vVal := GetVerticalScrollPosition;
  hVal := GetHorizontalScrollPosition;
  hMax := GetHorizontalScrollMax;
  vMax := GetVerticalScrollMax;
  scrollv := 0;
  scrollh := 0;

  if ScrollMode = scmItemScrolling then
  begin
    hMax := GetTotalContentWidth;
    vMax := GetTotalContentHeight;

    for I := FixedRows + FreezeRows to Round(vVal) - 1 + FixedRows + FreezeRows do
      scrollv := scrollv + RowHeights[I];

    for I := FixedColumns + FreezeColumns to Round(hVal) - 1 + FixedColumns + FreezeColumns do
      scrollh := scrollh + ColumnWidths[I];

    vVal := scrollv;
    hval := scrollh;
  end;

  FCellPosX := Round(-hVal);
  FCellPosY := Round(-vVal);

  containerw := GetTotalContentWidth;
  containerh := GetTotalContentHeight;

  if (containerw > 0) and (containerh > 0) then
  begin
    cfs := GetFixedCols;
    rfs := GetFixedRows;
    cfst := FixedRightColumns;
    rfst := FixedFooterRows;

    FOldTopRow := FRStart;

    FCStart := cfs;
    FRStart := rfs;

    rBounds := GetContentRect;
    rBounds := RectF(rBounds.Left, rBounds.Top, rBounds.Right - 1, rBounds.Bottom - 1);
    fw := GetFixedWidth;
    fh := GetFixedHeight;
    fwr := GetFixedRightWidth;
    fhr := GetFixedFooterHeight;
    xOrig := 0;
    yOrig := 0;

    if (hVal > hMax / 2) and (ColumnW.Count = 0) then
    begin
      FCStop := ColumnCount - 1 - cfst;
      x := containerw - fwr;
      repeat
        cw := ColumnWidths[FCStop];
        if (x - cw > hVal + (rBounds.Right - rBounds.Left) - fwr) then
        begin
          x := x - cw;
          Dec(FCStop);
        end;
      until x - cw <= hVal + (rBounds.Right - rBounds.Left) - fwr;
    end
    else
    begin
      x := fw;
      repeat
        cw := ColumnWidths[FCStart];
        if (x + cw <= hVal + fw) then
        begin
          x := x + cw;
          inc(FCStart);
        end;
      until x + cw > hVal + fw;

      xOrig := x;
    end;

    if (vval > vMax / 2) and (RowH.Count = 0) then
    begin
      FRStop := RowCount - 1 - rfst;
      y := containerh - fhr;
      repeat
        rh := RowHeights[FRStop];
        if (y - rh > vVal + (rBounds.Bottom - rBounds.Top) - fhr) then
        begin
          y := y - rh;
          Dec(FRStop);
        end;
      until y - rh <= vVal + (rBounds.Bottom - rBounds.Top) - fhr;
    end
    else
    begin
      y := fh;
      repeat
        rh := RowHeights[FRStart];
        if (y + rh <= vVal + fh) then
        begin
          y := y + rh;
          inc(FRStart);
        end;
      until y + rh > vVal + fh;

      yOrig := y;
    end;

    if (hval > hMax / 2) and (ColumnW.Count = 0) then
    begin
      FCStart := FCStop;
      while (FCStart > 0) and (x > hVal + fw) do
      begin
        x := x - ColumnWidths[FCStart];
        if not ((FCStart > 0) and (x > hVal + fw)) then
          Break;
        Dec(FCStart);
      end;

      xOrig := x;
    end
    else
    begin
      FCStop := FCStart;
      while (FCStop < ColumnCount - 1) and (x < hVal + (rBounds.Right - rBounds.Left) - fwr) do
      begin
        x := x + ColumnWidths[FCStop];
        if not ((FCStop < ColumnCount - 1) and (x < hVal + (rBounds.Right - rBounds.Left) - fwr)) then
          Break;
        Inc(FCStop);
      end;
    end;

    if (vval > vMax / 2) and (RowH.Count = 0) then
    begin
      FRStart := FRStop;
      while (FRStart > 0) and (y > vVal + fh) do
      begin
        y := y - RowHeights[FRStart];
        if not ((FRStart > 0) and (y > vVal + fh)) then
          Break;
        Dec(FRStart);
      end;

      yOrig := y;
    end
    else
    begin
      FRStop := FRStart;
      while (FRStop < RowCount - 1) and (y < vVal + (rBounds.Bottom - rBounds.Top) - fhr) do
      begin
        y := y + RowHeights[FRStop];
        if not ((FRStop < RowCount - 1) and (y < vVal + (rBounds.Bottom - rBounds.Top) - fhr)) then
          Break;
        Inc(FRStop);
      end;
    end;

    FRStart := Max(FixedRows, Min(RowCount - 1 - FixedFooterRows, FRStart));
    FCStart := Max(FixedColumns, Min(ColumnCount - 1 - FixedRightColumns, FCStart));
    FRStop := Max(FixedRows, Min(RowCount - 1 - FixedFooterRows, FRStop));
    FCStop := Max(FixedColumns, Min(ColumnCount - 1 - FixedRightColumns, FCStop));

    if Assigned(Adapter) and (FBlockUpdateAdapter = 0) then
    begin
      Inc(FBlockUpdateAdapter);
      Adapter.ScrollGrid(TopRow - FOldTopRow, True, AScrollOnly);
      Dec(FBlockUpdateAdapter);
    end;

    x := xOrig - hVal;
    y := yOrig - vVal;

    xs := 0;
    ys := 0;

    if (ColumnCount > GetFixedCols) and (RowCount > GetFixedRows) then
      DisplayCells(x, y, x, y, FCStart, FCStop, FRStart, FRStop, cfs, rfs, rfs);

    if RowCount > GetFixedRows then
      DisplayCells(xs, y, xs, y, 0, cfs - 1, FRStart, FRStop, 0, rfs, rfs, True);

    if ColumnCount > GetFixedCols then
      DisplayCells(x, ys, x, ys, FCStart, FCStop, 0, rfs - 1, cfs, 0, 0, True);

    DisplayCells(xs, ys, xs, ys, 0, cfs - 1, 0, rfs - 1, 0, 0, 0, True);

    if (ColumnCount > GetFixedCols + FixedRightColumns) and (RowCount > GetFixedRows) then
    begin
      DisplayCells(xs + (rBounds.Right - rBounds.Left) - fwr, y, xs + (rBounds.Right - rBounds.Left) - fwr, y,
        ColumnCount - cfst, ColumnCount - 1, FRStart , FRStop, FCStop - FCStart + cfs + 1, rfs, rfs, True);

      DisplayCells(xs + (rBounds.Right - rBounds.Left) - fwr, ys, xs + (rBounds.Right - rBounds.Left) - fwr, ys,
        ColumnCount - cfst, ColumnCount - 1, 0, rfs - 1, FCStop - FCStart + cfs + 1, 0, 0, True);
    end;

    if (ColumnCount > GetFixedCols) and (RowCount > GetFixedRows + FixedFooterRows) then
    begin
      DisplayCells(x, ys + (rBounds.Bottom - rBounds.Top) - fhr, xs, ys + (rBounds.Bottom - rBounds.Top) - fhr,
        FCStart, FCStop, RowCount - rfst, RowCount - 1, cfs, FRStop - FRStart + rfs + 1, FRStop - FRStart + rfs + 1, True);
    end;

    if (ColumnCount > GetFixedCols + FixedRightColumns) and (RowCount > GetFixedRows + FixedFooterRows) then
    begin
      DisplayCells(xs + (rBounds.Right - rBounds.Left) - fwr, ys + (rBounds.Bottom - rBounds.Top) - fhr, xs + (rBounds.Right - rBounds.Left) - fwr,
        ys + (rBounds.Bottom - rBounds.Top) - fhr, ColumnCount - cfst, ColumnCount - 1, RowCount - rfst, RowCount - 1, FCStop - FCStart + cfs + 1 ,
       FRStop - FRStart + rfs + 1, FRStop - FRStart + rfs + 1, True);
    end;

    if RowCount > GetFixedRows then
    begin
      DisplayCells(xs, ys + (rBounds.Bottom - rBounds.Top) - fhr, xs,  ys + (rBounds.Bottom - rBounds.Top) - fhr,
        0, cfs - 1, RowCount - rfst, RowCount - 1, 0, FRStop - FRStart + rfs + 1, FRStop - FRStart + rfs + 1, True);
    end;
  end
  else
    RemoveAllCells;

  if Assigned(FEditControl) and FEditing and not FFreeEditControlTimer.Enabled then
  begin
    FEditControl.BringToFront;
    FEditControl.Visible := True;
    if FEditControl.CanFocus then
      FEditControl.SetFocus;
  end;

  if (FTopr <> TopRow) or (FLeftC <> LeftCol) then
  begin
    if Assigned(OnTopLeftChanged) then
      OnTopLeftChanged(Self, TopRow, LeftCol);
  end;

  FTopr := TopRow;
  FLeftC := LeftCol;

  BlockUpdate := False;

  {$IFDEF TMSDEBUG}
  TMSLogger.StopTimer(True, lsmMilliseconds, 'Display Cells : {%s} ms');
  {$ENDIF}

  if Assigned(Adapter) and (FBlockUpdateAdapter = 0) then
  begin
    Inc(FBlockUpdateAdapter);
    Adapter.ScrollGrid(TopRow - FOldTopRow, False, AScrollOnly);
    Dec(FBlockUpdateAdapter);
  end;

  Invalidate;
end;

function TTMSFNCCustomGrid.VisibleColumnCount: Integer;
begin
  Result := FCStop - FCStart + FixedRightColumns + GetFixedCols + 1;
end;

function TTMSFNCCustomGrid.VisibleRowCount: Integer;
begin
  Result := FRStop - FRStart + FixedFooterRows + GetFixedRows + 1;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCGridDatePicker.KeyDown(var Key: Word; var KeyChar: Char; Shift: TShiftState);
var
  IsReturnKey: boolean;
begin
  IsReturnKey := Key = vkReturn;
  inherited;
  if IsReturnKey and Assigned(OnKeyDown) then
  begin
    Key := vkReturn;
    OnKeyDown(Self, Key, KeyChar, Shift);
  end;
end;

procedure TTMSFNCCustomGrid.ApplyFilterListBoxStyleLookUp(Sender: TObject);
begin
  (Sender as TListBox).ViewportPosition := PointF(0, 0);
end;
{$ENDIF}

function TTMSFNCCustomGrid.XYToCell(X, Y: Single): TTMSFNCGridCellRec;
var
  I: Integer;
  val: Single;
  contentr: TRectF;
begin
  Result.Row := -1;
  Result.Col := -1;

  if (RowCount = 0) or (ColumnCount = 0) then
    Exit;

  contentr := GetContentRect;

  if not PtInRectEx(contentr, PointF(X, Y)) then
    Exit;

  if (Y < GetFixedHeight) then
  begin
    val := 0;
    for I := 0 to GetFixedRows do
    begin
      val := val + RowHeights[I];
      if val >= Y then
      begin
        Result.Row := I;
        Break;
      end;
    end;
  end
  else if (Y > (contentr.Bottom - contentr.Top) - GetFixedFooterHeight) then
  begin
    val := (contentr.Bottom - contentr.Top);
    for I := RowCount - 1 downto RowCount - 1 - FixedFooterRows do
    begin
      val := val - RowHeights[I];
      if val <= Y then
      begin
        Result.Row := I;
        Break;
      end;
    end;
  end
  else
  begin
    val := GetCellContainerPosY;
    for I := 0 to FRStop do
    begin
      val := val + RowHeights[I];
      if val >= Y then
      begin
        Result.Row := I;
        Break;
      end;
    end;
  end;

  if (X < GetFixedWidth) then
  begin
    val := 0;
    for I := 0 to GetFixedCols do
    begin
      val := val + ColumnWidths[I];
      if val >= X then
      begin
        Result.Col := I;
        Break;
      end;
    end;
  end
  else if (X > (contentr.Right - contentr.Left) - GetFixedRightWidth) then
  begin
    val := (contentr.Right - contentr.Left);
    for I := ColumnCount - 1 downto ColumnCount - 1 - FixedRightColumns do
    begin
      val := val - ColumnWidths[I];
      if val <= X then
      begin
        Result.Col := I;
        Break;
      end;
    end;
  end
  else
  begin
    val := GetCellContainerPosX;
    for I := 0 to FCStop do
    begin
      val := val + ColumnWidths[I];
      if val >= X then
      begin
        Result.Col := I;
        Break;
      end;
    end;
  end;
end;

{$IFDEF FMXLIB}
function TTMSFNCGridSpinBox.GetDefaultStyleLookupName: string;
begin
  Result := 'spinboxstyle';
end;
{$ENDIF}

{ TTMSFNCGridAppearance }

procedure TTMSFNCGridAppearance.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridAppearance) then
  begin
    FBandLayout.Assign((Source as TTMSFNCGridAppearance).BandLayout);
    FFixedSelectedLayout.Assign((Source as TTMSFNCGridAppearance).FixedSelectedLayout);
    FFixedLayout.Assign((Source as TTMSFNCGridAppearance).FixedLayout);
    FNormalLayout.Assign((Source as TTMSFNCGridAppearance).NormalLayout);
    FGroupLayout.Assign((Source as TTMSFNCGridAppearance).GroupLayout);
    FSummaryLayout.Assign((Source as TTMSFNCGridAppearance).SummaryLayout);
    FSelectedLayout.Assign((Source as TTMSFNCGridAppearance).SelectedLayout);
    FFocusedLayout.Assign((Source as TTMSFNCGridAppearance).FocusedLayout);
    FProgressLayout.Assign((Source as TTMSFNCGridAppearance).ProgressLayout);
    FShowFocus := (Source as TTMSFNCGridAppearance).ShowFocus;
  end;
end;

constructor TTMSFNCGridAppearance.Create;
begin
  FBandLayout := TTMSFNCGridCellLayout.Create;
  FFixedLayout := TTMSFNCGridCellLayout.Create;
  FNormalLayout := TTMSFNCGridCellLayout.Create;
  FGroupLayout := TTMSFNCGridCellLayout.Create;
  FSummaryLayout := TTMSFNCGridCellLayout.Create;
  FSelectedLayout := TTMSFNCGridCellLayout.Create;
  FFocusedLayout := TTMSFNCGridCellLayout.Create;
  FFixedSelectedLayout := TTMSFNCGridCellLayout.Create;
  FProgressLayout := TTMSFNCGridCellProgressLayout.Create;

  FShowFocus := True;

  InitializeDefaultLayout;

  FProgressLayout.OnChange := @LayoutChanged;
  FBandLayout.OnChange := @LayoutChanged;
  FFixedLayout.OnChange := @LayoutChanged;
  FNormalLayout.OnChange := @LayoutChanged;
  FGroupLayout.OnChange := @LayoutChanged;
  FSummaryLayout.OnChange := @LayoutChanged;
  FSelectedLayout.OnChange := @LayoutChanged;
  FFocusedLayout.OnChange := @LayoutChanged;
  FFixedSelectedLayout.OnChange := @LayoutChanged;
end;

destructor TTMSFNCGridAppearance.Destroy;
begin
  FProgressLayout.Free;
  FBandLayout.Free;
  FFixedLayout.Free;
  FNormalLayout.Free;
  FGroupLayout.Free;
  FSummaryLayout.Free;
  FSelectedLayout.Free;
  FFocusedLayout.Free;
  FFixedSelectedLayout.Free;
  inherited;
end;

procedure TTMSFNCGridAppearance.InitializeDefaultLayout;
begin
  inherited;
  FNormalLayout.Fill.Color := gcNull;

  FFixedLayout.Fill.Kind := gfkSolid;
  FFixedLayout.Fill.Color := MakeGraphicsColor(230, 230, 230);
  FFixedLayout.Font.Style := [TFontStyle.fsBold];

  FFixedSelectedLayout.Fill.Kind := gfkSolid;
  FFixedSelectedLayout.Fill.Color := gcLightsteelblue;

  FFocusedLayout.Fill.Color := gcLightsteelblue;
  FFocusedLayout.Font.Color := gcWhite;

  FSelectedLayout.Fill.Color := gcLightsteelblue;
  FSelectedLayout.Font.Color := gcWhite;

  FBandLayout.Fill.Color := gcLightyellow;

  FGroupLayout.Fill.Color := MakeGraphicsColor(110, 147, 195);
  FGroupLayout.Font.Color := gcWhite;
  FSummaryLayout.Fill.Color := MakeGraphicsColor(185, 197, 213);
end;

procedure TTMSFNCGridAppearance.LayoutChanged(Sender: TObject);
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TTMSFNCGridAppearance.SetBandLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FBandLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetFixedLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FFixedLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetFixedSelectedLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FFixedSelectedLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetFocusedLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FFocusedLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetGroupLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FGroupLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetNormalLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FNormalLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetProgressLayout(
  const Value: TTMSFNCGridCellProgressLayout);
begin
  FProgressLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetSelectedLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FSelectedLayout.Assign(Value);
end;

procedure TTMSFNCGridAppearance.SetShowFocus(const Value: Boolean);
begin
  if FShowFocus <> Value then
  begin
    FShowFocus := Value;
    LayoutChanged(Self);
  end;
end;

procedure TTMSFNCGridAppearance.SetSummaryLayout(
  const Value: TTMSFNCGridCellLayout);
begin
  FSummaryLayout.Assign(Value);
end;

{ TTMSFNCGridAdapter }

function TTMSFNCGridAdapter.AlternateDisplayBuildup: Boolean;
begin
  Result := False;
end;

function TTMSFNCGridAdapter.CanCancelEdit: Boolean;
begin
  Result := True;
end;

procedure TTMSFNCGridAdapter.CellBeforeEdit(ACol, ARow: Integer);
begin

end;

procedure TTMSFNCGridAdapter.CellBeforeEditExit(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var AllowExit: Boolean);
begin

end;

procedure TTMSFNCGridAdapter.ExportNotification(AState: TTMSFNCGridExportState; ARow: Integer);
begin

end;

procedure TTMSFNCGridAdapter.CellCheckBoxClick(ACol, ARow: Integer;
  ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridAdapter.CellEditCancel(ACol, ARow: Integer);
begin

end;

procedure TTMSFNCGridAdapter.CellEditDone(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor);
begin

end;

procedure TTMSFNCGridAdapter.CellEditGetData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String);
begin

end;

procedure TTMSFNCGridAdapter.CellEditSetData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String);
begin

end;

procedure TTMSFNCGridAdapter.CellEditValidateData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean);
begin

end;

constructor TTMSFNCGridAdapter.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  FActive := False;
  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TTMSFNCCustomGrid) then
      begin
        Grid := AOwner.Components[i] as TTMSFNCCustomGrid;
        Break;
      end;
    end;
  end;
end;

procedure TTMSFNCGridAdapter.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FGrid) then
    FGrid := nil;
end;

procedure TTMSFNCGridAdapter.ScrollGrid(ADelta: Integer; ABeforeDisplayCells: Boolean = True; AScrollOnly: Boolean = False);
begin
end;

procedure TTMSFNCGridAdapter.SelectCell(ACell: TTMSFNCGridCellRec);
begin

end;

procedure TTMSFNCGridAdapter.SetActive(const Value: boolean);
begin
  FActive := Value;
  if not Assigned(Grid) then
    Exit;
  Initialize;
end;

procedure TTMSFNCGridAdapter.SetGrid(const Value: TTMSFNCCustomGrid);
begin
  if FGrid <> Value then
  begin
    FGrid := Value;
    if FBlockAdd then
      Exit;

    FBlockAdd := True;
    if Assigned(FGrid) then
    begin
      FGrid.Adapter := Self;
      Initialize;
    end;
    FBlockAdd := False;
  end;
end;

procedure TTMSFNCGridAdapter.StartBuild(ARow: Integer);
begin

end;

procedure TTMSFNCGridAdapter.UpdateBounds;
begin

end;

procedure TTMSFNCGridAdapter.GetCellClass(ACol, ARow: Integer;
  var ACellClassType: TTMSFNCGridCellClass);
begin

end;

procedure TTMSFNCGridAdapter.GetCellData(ACol, ARow: Integer;
  var ACellData: String);
begin

end;

procedure TTMSFNCGridAdapter.GetCellEditorProperties(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor);
begin

end;

procedure TTMSFNCGridAdapter.GetCellEditorType(ACol, ARow: Integer;
  var ACellEditorType: TTMSFNCGridEditorType);
begin

end;

procedure TTMSFNCGridAdapter.GetCellProperties(ACol, ARow: Integer;
  ACell: TTMSFNCGridCell);
begin

end;

procedure TTMSFNCGridAdapter.GetCellReadOnly(ACol, ARow: Integer;
  var AReadOnly: Boolean);
begin

end;

function TTMSFNCGridAdapter.GetColumnDisplayName(ACol: Integer): String;
begin
  Result := '';
end;

function TTMSFNCGridAdapter.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCGridAdapter.Initialize;
begin
end;

{ TTMSFNCGridEdit }

constructor TTMSFNCGridEdit.Create(AOwner: TComponent);
begin
  inherited;
  AutoClose := False;
end;

{$IFDEF VCLLIB}
procedure TTMSFNCGridEdit.WMGetDlgCode(var Message: TWMGetDlgCode);
begin
  inherited;
  Message.Result := Message.Result or DLGC_WANTTAB;
end;
{$ENDIF}

{$IFDEF WEBLIB}
initialization
begin
  TTMSFNCBitmap.CreateFromResource(TMSFNCGRIDCELLNODEOPEN);
  TTMSFNCBitmap.CreateFromResource(TMSFNCGRIDCELLNODECLOSED);
end;
{$ENDIF}

end.


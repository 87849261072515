unit uPOIGroupSelect;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLIB.Rest, WEBLIB.Json,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCPanel, WEBLib.Buttons,
  VCL.TMSFNCCustomControl, VCL.TMSFNCListBox, VCL.TMSFNCCheckedListBox,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCBitmapContainer;

const
  POIPictureBefore = False;

type
  TPOIGroupSelectForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    POIGroupList: TTMSFNCCheckedListBox;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    SelectAddButton: TSpeedButton;
    SelectNoneButton: TSpeedButton;
    MyBitmapContainer: TTMSFNCBitmapContainer;
    procedure SaveButtonClick(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    procedure CloseButtonClick(Sender: TObject);
    procedure POIGroupListItemClick(Sender: TObject; AItem: TTMSFNCListBoxItem);
    procedure ButtonSelectAllClick(Sender: TObject);
    procedure SelectNoneButtonClick(Sender: TObject);
    procedure TMSFNCBitmapContainer1BitmapChanged(Sender: TObject);
    procedure POIGroupListAfterDrawItem(Sender: TObject;
      AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem);
  private
    { Private declarations }
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadPOIGroupList(S: String);
  public
    { Public declarations }
    List: String;
  protected procedure LoadDFMValues; override; end;

var
  POIGroupSelectForm: TPOIGroupSelectForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TPOIGroupSelectForm.WebFormShow(Sender: TObject);
begin
  await(LoadLookupValues());
end;

procedure TPOIGroupSelectForm.LoadLookupValues;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_poi_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadPOIGroupList(TJSONPair(MyJSONObject.Get('poi_group')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TPOIGroupSelectForm.LoadPOIGroupList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  StringList: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
  BitmapItem: TTMSFNCBitmapItem;
begin
  StringList := TStringList.Create();
  StringList.Sorted := True;
  StringList.DelimitedText := List;
  try
    POIGroupList.BeginUpdate;
    try
      POIGroupList.Items.Clear;
      // Parse JSON
      MyJSON := TJSON.Create;
      try
        MyJSONArray := TJSONArray(MyJSON.Parse(S));
        for i := 0 to MyJSONArray.Count-1 do begin
          MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
          Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items.Add);
          Item.Tag := StrToInt(MyJSONObject.GetJSONValue('pkey'));
          Item.Text := MyJSONObject.GetJSONValue('id');
          Item.Checked := StringList.IndexOf(MyJSONObject.GetJSONValue('pkey')) >= 0;
          if (MyJSONObject.GetJSONValue('picture') <> EmptyStr) then begin
            if POIPictureBefore then begin
              Item.Bitmap.LoadFromURL(MyJSONObject.GetJSONValue('picture'));
            end else begin
              BitmapItem := MyBitmapContainer.Items.Add;
              BitmapItem.Name := MyJSONObject.GetJSONValue('id');
              BitmapItem.Bitmap.LoadFromURL(MyJSONObject.GetJSONValue('picture'));
            end;
          end;
        end;
      finally
        MyJSON.Free;
      end;
    finally
      POIGroupList.EndUpdate;
    end;
    POIGroupList.Paint;
  finally
    StringList.Free;
  end;
end;

procedure TPOIGroupSelectForm.POIGroupListAfterDrawItem(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem);
var
  Bitmap: TTMSFNCBitmap;
  rrt: TRectF;
begin
  if not(POIPictureBefore) then begin
    Bitmap := MyBitmapContainer.FindBitmap(AItem.Text);
    rrt := RectF(ARect.Right-36, ARect.Top, ARect.Right-4, ARect.Bottom); //  Left, Top, Right, Bottom: Single;
    AGraphics.DrawBitmap(rrt, Bitmap);
  end;
end;

procedure TPOIGroupSelectForm.POIGroupListItemClick(Sender: TObject;
  AItem: TTMSFNCListBoxItem);
begin
  AItem.Checked := not(AItem.Checked);
end;

procedure TPOIGroupSelectForm.ButtonSelectAllClick(Sender: TObject);
var
  I: Integer;
begin
  for I := 0 to POIGroupList.Items.Count-1 do begin
    TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]).Checked := True;
  end;
end;

procedure TPOIGroupSelectForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TPOIGroupSelectForm.SaveButtonClick(Sender: TObject);
var
  I: Integer;
  StringList: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  StringList := TStringList.Create();
  try
    for I := 0 to POIGroupList.Items.Count-1 do begin
      Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]);
      if Item.Checked then StringList.Add(IntToStr(Item.Tag));
    end;
    List := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
  ModalResult := mrOK;
end;

procedure TPOIGroupSelectForm.SelectNoneButtonClick(Sender: TObject);
var
  I: Integer;
begin
  for I := 0 to POIGroupList.Items.Count-1 do begin
    TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]).Checked := False;
  end;
end;

procedure TPOIGroupSelectForm.TMSFNCBitmapContainer1BitmapChanged(
  Sender: TObject);
begin
  //
  CaptionLabel.Caption := 'Changed';
end;

procedure TPOIGroupSelectForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  POIGroupList := TTMSFNCCheckedListBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  SelectAddButton := TSpeedButton.Create(Self);
  SelectNoneButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyBitmapContainer := TTMSFNCBitmapContainer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  POIGroupList.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  SelectAddButton.BeforeLoadDFMValues;
  SelectNoneButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyBitmapContainer.BeforeLoadDFMValues;
  try
    Name := 'POIGroupSelectForm';
    Width := 587;
    Height := 543;
    Caption := 'POI Group Select';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    Visible := True;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 587;
    FormPanel.Height := 543;
    FormPanel.HeightPercent := 100.000000000000000000;
    FormPanel.WidthPercent := 100.000000000000000000;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 581;
    ContentPanel.Height := 500;
    ContentPanel.Margins.Top := 0;
    ContentPanel.HeightPercent := 100.000000000000000000;
    ContentPanel.WidthPercent := 100.000000000000000000;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    POIGroupList.SetParentComponent(ContentPanel);
    POIGroupList.Name := 'POIGroupList';
    POIGroupList.AlignWithMargins := True;
    POIGroupList.Left := 20;
    POIGroupList.Top := 3;
    POIGroupList.Width := 541;
    POIGroupList.Height := 445;
    POIGroupList.Margins.Left := 20;
    POIGroupList.Margins.Right := 20;
    POIGroupList.AllowFocus := False;
    POIGroupList.Align := alClient;
    POIGroupList.BevelInner := bvNone;
    POIGroupList.BevelOuter := bvNone;
    POIGroupList.ParentDoubleBuffered := False;
    POIGroupList.DoubleBuffered := True;
    POIGroupList.Font.Charset := ANSI_CHARSET;
    POIGroupList.Font.Color := clWindowText;
    POIGroupList.Font.Height := -16;
    POIGroupList.Font.Name := 'Segoe UI';
    POIGroupList.Font.Style := [];
    POIGroupList.ParentFont := False;
    POIGroupList.TabOrder := 0;
    POIGroupList.ItemsAppearance.Font.Charset := ANSI_CHARSET;
    POIGroupList.ItemsAppearance.Font.Color := clGray;
    POIGroupList.ItemsAppearance.Font.Height := -16;
    POIGroupList.ItemsAppearance.Font.Name := 'Segoe UI';
    POIGroupList.ItemsAppearance.Font.Style := [];
    POIGroupList.ItemsAppearance.Fill.Color := -1;
    POIGroupList.ItemsAppearance.Stroke.Kind := gskNone;
    POIGroupList.ItemsAppearance.Stroke.Color := 11119017;
    POIGroupList.ItemsAppearance.SelectedFill.Color := 16297243;
    POIGroupList.ItemsAppearance.SelectedStroke.Color := 16297243;
    POIGroupList.ItemsAppearance.DisabledFill.Color := 11119017;
    POIGroupList.ItemsAppearance.DisabledStroke.Kind := gskNone;
    POIGroupList.ItemsAppearance.DisabledStroke.Color := 11119017;
    POIGroupList.ItemsAppearance.FixedHeight := 50.000000000000000000;
    POIGroupList.ItemsAppearance.ShowFocus := False;
    POIGroupList.DefaultItem.BitmapWidth := 32.000000000000000000;
    POIGroupList.DefaultItem.BitmapHeight := 32.000000000000000000;
    POIGroupList.DefaultItem.TextColor := clGray;
    POIGroupList.Items.Clear;
    with POIGroupList.Items.Add do
    begin
      BitmapWidth := 32.000000000000000000;
      BitmapHeight := 32.000000000000000000;
      TextColor := clGray;
    end;
    POIGroupList.Header.Text := 'Header';
    POIGroupList.Header.Font.Charset := DEFAULT_CHARSET;
    POIGroupList.Header.Font.Color := clBlack;
    POIGroupList.Header.Font.Height := -11;
    POIGroupList.Header.Font.Name := 'Tahoma';
    POIGroupList.Header.Font.Style := [];
    POIGroupList.Header.Stroke.Color := 11119017;
    POIGroupList.Header.Size := 25.000000000000000000;
    POIGroupList.Header.SortIndicatorColor := 11829830;
    POIGroupList.Interaction.Filtering.Enabled := True;
    SetEvent(POIGroupList, Self, 'OnAfterDrawItem', 'POIGroupListAfterDrawItem');
    SetEvent(POIGroupList, Self, 'OnItemClick', 'POIGroupListItemClick');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 451;
    StatusBarPanel.Width := 581;
    StatusBarPanel.Height := 49;
    StatusBarPanel.HeightPercent := 100.000000000000000000;
    StatusBarPanel.WidthPercent := 100.000000000000000000;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderColor := clSilver;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 461;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uPOIGroupSelect.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 346;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&OK';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uPOIGroupSelect.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    SelectAddButton.SetParentComponent(StatusBarPanel);
    SelectAddButton.Name := 'SelectAddButton';
    SelectAddButton.AlignWithMargins := True;
    SelectAddButton.Left := 20;
    SelectAddButton.Top := 8;
    SelectAddButton.Width := 100;
    SelectAddButton.Height := 31;
    SelectAddButton.Margins.Left := 20;
    SelectAddButton.Margins.Top := 8;
    SelectAddButton.Margins.Right := 5;
    SelectAddButton.Margins.Bottom := 10;
    SelectAddButton.Align := alLeft;
    SelectAddButton.Caption := '&All';
    SelectAddButton.Color := clNone;
    SelectAddButton.ElementClassName := 'MyWebButton';
    SelectAddButton.Glyph.LoadFromFile('uPOIGroupSelect.StatusBarPanel.SelectAddButton.Glyph.png');
    SelectAddButton.HeightPercent := 100.000000000000000000;
    SelectAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectAddButton, Self, 'OnClick', 'ButtonSelectAllClick');
    SelectNoneButton.SetParentComponent(StatusBarPanel);
    SelectNoneButton.Name := 'SelectNoneButton';
    SelectNoneButton.AlignWithMargins := True;
    SelectNoneButton.Left := 135;
    SelectNoneButton.Top := 8;
    SelectNoneButton.Width := 100;
    SelectNoneButton.Height := 31;
    SelectNoneButton.Margins.Left := 10;
    SelectNoneButton.Margins.Top := 8;
    SelectNoneButton.Margins.Right := 20;
    SelectNoneButton.Margins.Bottom := 10;
    SelectNoneButton.Align := alLeft;
    SelectNoneButton.Caption := '&None';
    SelectNoneButton.Color := clNone;
    SelectNoneButton.ElementClassName := 'MyWebButton';
    SelectNoneButton.Glyph.LoadFromFile('uPOIGroupSelect.StatusBarPanel.SelectNoneButton.Glyph.png');
    SelectNoneButton.HeightPercent := 100.000000000000000000;
    SelectNoneButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectNoneButton, Self, 'OnClick', 'SelectNoneButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 587;
    CaptionPanel.Height := 40;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 122;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'POI Group Select';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 558;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseButtonClick');
    CloseImage.Picture.LoadFromFile('uPOIGroupSelect.CaptionPanel.CloseImage.Picture.png');
    MyBitmapContainer.SetParentComponent(Self);
    MyBitmapContainer.Name := 'MyBitmapContainer';
    MyBitmapContainer.Left := 272;
    MyBitmapContainer.Top := 501;
    MyBitmapContainer.Width := 26;
    MyBitmapContainer.Height := 26;
    MyBitmapContainer.Visible := True;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    POIGroupList.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    SelectAddButton.AfterLoadDFMValues;
    SelectNoneButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyBitmapContainer.AfterLoadDFMValues;
  end;
end;

end.
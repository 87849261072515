unit uReportList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel;

type
  TReportListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    ReportDataset: TClientDataSet;
    ReportSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    ReportGrid: TTMSFNCGrid;
    ReportTimer: TTimer;
    ToolbarPanel: TPanel;
    ReportEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    MyConnection: TClientConnection;
    ReportDatasetrl_pk: TStringField;
    ReportDatasetrl_id: TStringField;
    ReportDatasetrl_type: TStringField;
    ReportDatasetrl_format: TStringField;
    ReportDatasetrl_period: TStringField;
    ReportDatasetrl_date_from: TStringField;
    ReportDatasetrl_date_till: TStringField;
    ReportDatasetrl_filter_ogpk: TStringField;
    ReportDatasetrl_filter_otpk: TStringField;
    ReportDatasetrl_option_list: TStringField;
    ReportDatasetrl_owner: TStringField;
    ReportDatasetrl_type_string: TStringField;
    ReportDatasetrl_format_string: TStringField;
    ReportDatasetrl_period_string: TStringField;
    PrintButton: TSpeedButton;
    MyMessageDlg: TMessageDlg;
    ReportDatasetrl_object_no: TStringField;
    ReportDatasetrl_object_id: TStringField;
    ViewButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    ReportDatasetrl_schedule_type: TStringField;
    ReportDatasetrl_schedule_weekday: TStringField;
    ReportDatasetrl_schedule_day: TStringField;
    ReportDatasetrl_schedule_month: TStringField;
    MailButton: TSpeedButton;
    ReportDatasetrl_recipient_list: TStringField;
    ReportDatasetrl_mailto_myself: TStringField;
    ReportDatasetrl_mailto_groups: TStringField;
    ReportDatasetrl_owner_pk: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure EditButtonClick(Sender: TObject); async;
    procedure ReportTimerTimer(Sender: TObject);
    procedure ReportDatasetAfterOpen(DataSet: TDataSet);
    procedure ReportEditChange(Sender: TObject);
    procedure ReportDatasetFilterRecord(DataSet: TDataSet; var Accept: Boolean);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    procedure PrintButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure ReportGridDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure MailButtonClick(Sender: TObject); async;
    [async]
  private
    { Private declarations }
    FocusPKey: Int64;
    Permission: Integer;
    [async]
    procedure LoadReportList(SearchValue: String); async;
    [async]
    procedure ShowReportEditDialog(PKey: Integer); async;
    [async]
    procedure DeleteReport(PKey: Integer); async;
  public
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  ReportListForm: TReportListForm;

implementation

{$R *.dfm}

uses
  uCommon, uReportEdit, uReportPrint;

procedure TReportListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  SetGridStyle(ReportGrid, False);
end;


procedure TReportListForm.SetPermission(MyPermission: Integer);
begin
  Permission := MyPermission;
  AddButton.Visible := MyPermission in [2,3,6,7];
  ViewButton.Visible := MyPermission in [1,5];
  EditButton.Visible := MyPermission in [2,3,7];
  DeleteButton.Visible := MyPermission in [4,5,6,7];
end;

procedure TReportListForm.WebFormShow(Sender: TObject);
begin
  ReportEdit.SetFocus();
  LoadReportList(EmptyStr);
end;

procedure TReportListForm.PrintButtonClick(Sender: TObject);
var
  MyURL: String;
  MyWebForm: TReportPrintForm;
  MyModalResult: TModalResult;
begin
  if ReportDataset.Active and not(ReportDataset.IsEmpty) then begin
    MyWebForm := TReportPrintForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TReportPrintForm, MyWebForm.Load());
    try
      // Initialize
      MyURL :=
        url_php + 'download_report.php' +
          '?sessionid=' + SessionId +
          '&report_pkey=' + Trim(ReportDataset.FieldByName('rl_pk').AsString) +
          '&report_name=' + Trim(ReportDataset.FieldByName('rl_type_string').AsString) +
          '&report_format=' + Trim(ReportDataset.FieldByName('rl_format_string').AsString);
      MyWebForm.WebBrowser.URL := MyURL;
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TReportListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TReportListForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if ReportDataSet.Active and not(ReportDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete report?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteReport(ReportDataset.FieldByName('rl_pk').AsInteger);
    end;
  end;
end;

procedure TReportListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowReportEditDialog(-1));
end;

procedure TReportListForm.EditButtonClick(Sender: TObject);
begin
  if ReportDataset.Active and not(ReportDataset.IsEmpty) then begin
    await(ShowReportEditDialog(ReportDataset.FieldByName('rl_pk').AsInteger));
  end;
end;

procedure TReportListForm.ReportDatasetAfterOpen(DataSet: TDataSet);
begin
  if (FocusPKey = -1) then begin
    ReportDataset.First;
  end else begin
    ReportDataset.Locate('rl_pk', FocusPKey, []);
  end;
  GridDatabaseAdapter.Active := True;
  ReportTimer.Enabled := True;
end;

procedure TReportListForm.ReportDatasetFilterRecord(DataSet: TDataSet;
  var Accept: Boolean);
var
  Len: Integer;
  S1, S2: String;
begin
  Len := Length(ReportEdit.Text);
  S1 := Trim(UpperCase(ReportEdit.Text));
  S2 := Copy(Trim(UpperCase(Dataset.FieldByName('rl_id').AsString)), 1, Len);
  Accept := (S1 = S2);
end;

procedure TReportListForm.ReportEditChange(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TReportListForm.ReportGridDblClick(Sender: TObject);
begin
  if ViewButton.Visible or EditButton.Visible then begin
    EditButton.Click();
  end;
end;

procedure TReportListForm.ReportTimerTimer(Sender: TObject);
begin
  ReportTimer.Enabled := False;
  WebWaitMessage.Hide;
end;

procedure TReportListForm.LoadReportList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_report_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  ReportDataset.Close;
  ReportDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(ReportDataset.Open);
end;

procedure TReportListForm.MailButtonClick(Sender: TObject);
var
  Result: Boolean;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  if ReportDataset.Active and not(ReportDataset.IsEmpty) then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'download_mail_report.php' +
          '?sessionid=' + SessionId +
          '&report_pkey=' + Trim(ReportDataset.FieldByName('rl_pk').AsString) +
          '&report_name=' + Trim(ReportDataset.FieldByName('rl_type_string').AsString) +
          '&report_format=' + Trim(ReportDataset.FieldByName('rl_format_string').AsString);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      if MyRequest.Status = 200 then begin
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then Result := True;
        finally
          MyJSON.Free;
        end;
      end;
      if Result then begin
        MessageDlg('Email was sent', TMsgDlgType.mtInformation, [mbOK], nil);
      end else begin
        MessageDlg('Error sending email', TMsgDlgType.mtError, [mbOK], nil);
      end;
    finally
      MyWebRequest.Free;
    end;
  end;
end;

procedure TReportListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadReportList(ReportEdit.Text);
  end;
end;

procedure TReportListForm.ShowReportEditDialog(PKey: Integer);
var
  MyWebForm: TReportEditForm;
  MyModalResult: TModalResult;
  DateFrom, DateTill: TDate;
  ObjectFilterType: Integer;
  EmailAddress: String;
begin
  if
    ReportDataset.Active
  and
    (
      (PKey = -1)
    or
      ((PKey > 0) and not(ReportDataset.IsEmpty))
    )
  then begin
    MyWebForm := TReportEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.ReportPKey := PKey;
    await(TReportEditForm, MyWebForm.Load());
    try
      MyWebForm.ReportPKey := PKey;
      if PKey > 0 then begin
        MyWebForm.ReportTitleEdit.Text :=
          Trim(ReportDataset.FieldByName('rl_id').AsString);
        MyWebForm.ReportTypeEdit.Value :=
          ReportDataset.FieldByName('rl_type').AsString;
        MyWebForm.ReportFormatEdit.Value :=
          ReportDataset.FieldByName('rl_format').AsString;
        MyWebForm.PeriodEdit.Value :=
          ReportDataset.FieldByName('rl_period').AsString;
        // Period To From Till
        if ReportDataset.FieldByName('rl_period').AsLargeInt = 0 then begin
          MyWebForm.DateFromEdit.Date := ReportDataset.FieldByName('rl_date_from').AsDateTime;
          MyWebForm.DateTillEdit.Date := ReportDataset.FieldByName('rl_date_till').AsDateTime;
        end else begin
          PeriodToFromTill(
            ReportDataset.FieldByName('rl_period').AsLargeInt,
            DateFrom, DateTill
          );
          MyWebForm.DateFromEdit.Date := DateFrom;
          MyWebForm.DateTillEdit.Date := DateTill;
        end;
        ObjectFilterType := 0;
        // Symbool
        if ReportDataset.FieldByName('rl_object_no').AsString <> EmptyStr then begin
          ObjectFilterType := 1;
          MyWebForm.SelectObjectFilterType(IntToStr(ObjectFilterType), True);
          MyWebForm.ObjectNoEdit.Text :=
            ReportDataset.FieldByName('rl_object_no').AsString;
        end;
        // Nummerplaat
        if Trim(ReportDataset.FieldByName('rl_object_id').AsString) <> EmptyStr then begin
          ObjectFilterType := 2;
          MyWebForm.SelectObjectFilterType(IntToStr(ObjectFilterType), True);
          MyWebForm.ObjectIDEdit.Text :=
            ReportDataset.FieldByName('rl_object_id').AsString;
        end;
        // Team / Type
        if ObjectFilterType = 0 then begin
          ObjectFilterType := 3;
          MyWebForm.SelectObjectFilterType(IntToStr(ObjectFilterType), True);
          MyWebForm.TeamFilterEdit.Tag :=
            ReportDataset.FieldByName('rl_filter_ogpk').AsInteger;
          MyWebForm.TypeFilterEdit.Tag :=
            ReportDataset.FieldByName('rl_filter_otpk').AsInteger;
        end;
        // Scheduler
        MyWebForm.SetSchedule(
          ReportDataset.FieldByName('rl_schedule_type').AsString,
          ReportDataset.FieldByName('rl_schedule_weekday').AsString,
          ReportDataset.FieldByName('rl_schedule_day').AsString,
          ReportDataset.FieldByName('rl_schedule_month').AsString
        );
        // Enable / Disable Schedule
        if ReportDataset.FieldByName('rl_type').AsInteger = 4 then begin
          MyWebForm.EnableSchedule(False);
        end;
        // Mail To
        MyWebForm.ReportTypePageControl.Pages[7].TabVisible :=
          MyWebForm.ReportTypeMailtoEnabled(ReportDataset.FieldByName('rl_type').AsInteger);
        MyWebForm.MailToMyselfEdit.Checked :=
          ReportDataset.FieldByName('rl_mailto_myself').AsString = 't';
        MyWebForm.MailToGroupsEdit.Checked :=
          ReportDataset.FieldByName('rl_mailto_groups').AsString = 't';
        // Recipients
        MyWebForm.RecipientListEdit.Text :=
          ReportDataset.FieldByName('rl_recipient_list').AsString;
        // Parse Option List
        MyWebForm.ParseOptionList(
          ReportDataset.FieldByName('rl_type').AsInteger,
          ReportDataset.FieldByName('rl_option_list').AsString
        );
      end else begin
        // Get Email Address
        await(GetUserEmailAddress(EmailAddress));
        // Set Form Values
        MyWebForm.ReportTypeEdit.Value := '1';
        MyWebForm.ReportFormatEdit.Value := '1';
        MyWebForm.PeriodEdit.Value := '1';
        MyWebForm.DateFromEdit.Date := Date();
        MyWebForm.DateTillEdit.Date := IncDay(Date(), 1);
        MyWebForm.MOTDays1Edit.Text := '30';
        MyWebForm.MOTDays2Edit.Text := '42';
        MyWebForm.SelectObjectFilterType(ReportObjectFilterType, True);
        MyWebForm.TeamFilterEdit.Tag := 0;
        MyWebForm.TypeFilterEdit.Tag := 0;
        MyWebForm.SetSchedule('0', '', '1', '1');
        MyWebForm.MailToMyselfEdit.Checked := True;
        MyWebForm.MailToGroupsEdit.Checked := False;
//        MyWebForm.ScheduleTypeEdit.Value := '0';
//        MyWebForm.ScheduleDayEdit.Text := '1';
//        MyWebForm.ScheduleMonthEdit.Value := '1';
//        MyWebForm.RecipientListEdit.Text := EmailAddress;
        MyWebForm.ReportTypePageControl.Pages[0].TabVisible := True;
        MyWebForm.ReportTypePageControl.ActivePageIndex := 0;
        MyWebForm.ReportTypePageControl.Pages[7].TabVisible := True;
        MyWebForm.ReportTypePageControl.Pages[8].TabVisible :=
          MyWebForm.ReportTypeMailtoEnabled(1);
      end;
      MyWebForm.IsSet := True;
      // Hide Print Button
//      MyWebForm.PrintButton.Visible := False;
//      MyWebForm.MailButton.Visible := False;
      // excute form and wait for close
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        FocusPKey := MyWebForm.ReportPKey;
        LoadReportList(ReportEdit.Text);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TReportListForm.DeleteReport(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  ErrorStatus, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_report_delete.php?sessionid=' + SessionId +
      '&report_pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      ErrorStatus := MyJO.GetJSONValue('error_status');
      if ErrorStatus = '1' then begin
        ErrorMessage := MyJO.GetJSONValue('error_message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end else begin
        FocusPKey := -1;
        SearchTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TReportListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ReportGrid := TTMSFNCGrid.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  ReportEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  PrintButton := TSpeedButton.Create(Self);
  ViewButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  MailButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  ReportDataset := TClientDataSet.Create(Self);
  ReportDatasetrl_pk := TStringField.Create(Self);
  ReportDatasetrl_id := TStringField.Create(Self);
  ReportDatasetrl_type := TStringField.Create(Self);
  ReportDatasetrl_type_string := TStringField.Create(Self);
  ReportDatasetrl_format := TStringField.Create(Self);
  ReportDatasetrl_format_string := TStringField.Create(Self);
  ReportDatasetrl_period := TStringField.Create(Self);
  ReportDatasetrl_period_string := TStringField.Create(Self);
  ReportDatasetrl_date_from := TStringField.Create(Self);
  ReportDatasetrl_date_till := TStringField.Create(Self);
  ReportDatasetrl_filter_ogpk := TStringField.Create(Self);
  ReportDatasetrl_filter_otpk := TStringField.Create(Self);
  ReportDatasetrl_option_list := TStringField.Create(Self);
  ReportDatasetrl_owner_pk := TStringField.Create(Self);
  ReportDatasetrl_owner := TStringField.Create(Self);
  ReportDatasetrl_object_no := TStringField.Create(Self);
  ReportDatasetrl_object_id := TStringField.Create(Self);
  ReportDatasetrl_schedule_type := TStringField.Create(Self);
  ReportDatasetrl_schedule_weekday := TStringField.Create(Self);
  ReportDatasetrl_schedule_day := TStringField.Create(Self);
  ReportDatasetrl_schedule_month := TStringField.Create(Self);
  ReportDatasetrl_recipient_list := TStringField.Create(Self);
  ReportDatasetrl_mailto_myself := TStringField.Create(Self);
  ReportDatasetrl_mailto_groups := TStringField.Create(Self);
  ReportSource := TDataSource.Create(Self);
  ReportTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  MyConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ReportGrid.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  ReportEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  PrintButton.BeforeLoadDFMValues;
  ViewButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  MailButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  ReportDataset.BeforeLoadDFMValues;
  ReportDatasetrl_pk.BeforeLoadDFMValues;
  ReportDatasetrl_id.BeforeLoadDFMValues;
  ReportDatasetrl_type.BeforeLoadDFMValues;
  ReportDatasetrl_type_string.BeforeLoadDFMValues;
  ReportDatasetrl_format.BeforeLoadDFMValues;
  ReportDatasetrl_format_string.BeforeLoadDFMValues;
  ReportDatasetrl_period.BeforeLoadDFMValues;
  ReportDatasetrl_period_string.BeforeLoadDFMValues;
  ReportDatasetrl_date_from.BeforeLoadDFMValues;
  ReportDatasetrl_date_till.BeforeLoadDFMValues;
  ReportDatasetrl_filter_ogpk.BeforeLoadDFMValues;
  ReportDatasetrl_filter_otpk.BeforeLoadDFMValues;
  ReportDatasetrl_option_list.BeforeLoadDFMValues;
  ReportDatasetrl_owner_pk.BeforeLoadDFMValues;
  ReportDatasetrl_owner.BeforeLoadDFMValues;
  ReportDatasetrl_object_no.BeforeLoadDFMValues;
  ReportDatasetrl_object_id.BeforeLoadDFMValues;
  ReportDatasetrl_schedule_type.BeforeLoadDFMValues;
  ReportDatasetrl_schedule_weekday.BeforeLoadDFMValues;
  ReportDatasetrl_schedule_day.BeforeLoadDFMValues;
  ReportDatasetrl_schedule_month.BeforeLoadDFMValues;
  ReportDatasetrl_recipient_list.BeforeLoadDFMValues;
  ReportDatasetrl_mailto_myself.BeforeLoadDFMValues;
  ReportDatasetrl_mailto_groups.BeforeLoadDFMValues;
  ReportSource.BeforeLoadDFMValues;
  ReportTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  MyConnection.BeforeLoadDFMValues;
  try
    Name := 'ReportListForm';
    Width := 1093;
    Height := 628;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1093;
    FormPanel.Height := 628;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1087;
    ContentPanel.Height := 585;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ReportGrid.SetParentComponent(ContentPanel);
    ReportGrid.Name := 'ReportGrid';
    ReportGrid.AlignWithMargins := True;
    ReportGrid.Left := 20;
    ReportGrid.Top := 54;
    ReportGrid.Width := 1047;
    ReportGrid.Height := 472;
    ReportGrid.Margins.Left := 20;
    ReportGrid.Margins.Top := 0;
    ReportGrid.Margins.Right := 20;
    ReportGrid.Margins.Bottom := 10;
    ReportGrid.AdaptToStyle := True;
    ReportGrid.Align := alClient;
    ReportGrid.BevelInner := bvNone;
    ReportGrid.BevelOuter := bvNone;
    ReportGrid.BevelWidth := 2;
    ReportGrid.Ctl3D := False;
    ReportGrid.ParentCtl3D := False;
    ReportGrid.ParentDoubleBuffered := False;
    ReportGrid.DoubleBuffered := True;
    ReportGrid.Font.Charset := DEFAULT_CHARSET;
    ReportGrid.Font.Color := clWindowText;
    ReportGrid.Font.Height := -11;
    ReportGrid.Font.Name := 'Tahoma';
    ReportGrid.Font.Style := [];
    ReportGrid.ParentFont := False;
    ReportGrid.TabOrder := 1;
    SetEvent(ReportGrid, Self, 'OnDblClick', 'ReportGridDblClick');
    ReportGrid.DefaultRowHeight := 34.000000000000000000;
    ReportGrid.FixedColumns := 0;
    ReportGrid.ColumnCount := 4;
    ReportGrid.RowCount := 1;
    ReportGrid.Options.Borders.FixedCellBorders := bNone;
    ReportGrid.Options.ColumnSize.Stretch := True;
    ReportGrid.Options.ColumnSize.StretchColumn := 0;
    ReportGrid.Options.Editing.CalcFormat := '%g';
    ReportGrid.Options.Editing.Enabled := False;
    ReportGrid.Options.Grouping.CalcFormat := '%g';
    ReportGrid.Options.Grouping.GroupCountFormat := '(%d)';
    ReportGrid.Options.HTMLExport.CellPadding := 10;
    ReportGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    ReportGrid.Options.Mouse.WheelScrollKeepSelection := True;
    ReportGrid.Options.Mouse.ClickMargin := 0;
    ReportGrid.Options.Mouse.ColumnSizeMargin := 6;
    ReportGrid.Options.Mouse.RowSizeMargin := 6;
    ReportGrid.Options.URL.Color := clSkyBlue;
    ReportGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    ReportGrid.Options.Selection.Mode := smSingleRow;
    ReportGrid.Adapter := GridDatabaseAdapter;
    ReportGrid.HorizontalScrollBarVisible := False;
    ReportGrid.Columns.Clear;
    with ReportGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 446.000000000000000000;
    end;
    with ReportGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with ReportGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with ReportGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    ReportGrid.Fill.ColorTo := clSilver;
    ReportGrid.DefaultFont.Charset := ANSI_CHARSET;
    ReportGrid.DefaultFont.Color := clWindowText;
    ReportGrid.DefaultFont.Height := -15;
    ReportGrid.DefaultFont.Name := 'Segoe UI';
    ReportGrid.DefaultFont.Style := [];
    ReportGrid.Stroke.Color := 15987699;
    ReportGrid.Stroke.Width := 2.000000000000000000;
    ReportGrid.TopRow := 1;
    ReportGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    ReportGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    ReportGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.FixedLayout.Font.Color := clWhite;
    ReportGrid.Appearance.FixedLayout.Font.Height := -16;
    ReportGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.FixedLayout.Font.Style := [];
    ReportGrid.Appearance.NormalLayout.Fill.Color := -1;
    ReportGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    ReportGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.NormalLayout.Font.Color := clGray;
    ReportGrid.Appearance.NormalLayout.Font.Height := -11;
    ReportGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.NormalLayout.Font.Style := [];
    ReportGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    ReportGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    ReportGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    ReportGrid.Appearance.GroupLayout.Font.Color := clBlack;
    ReportGrid.Appearance.GroupLayout.Font.Height := -11;
    ReportGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    ReportGrid.Appearance.GroupLayout.Font.Style := [];
    ReportGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    ReportGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    ReportGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    ReportGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    ReportGrid.Appearance.SummaryLayout.Font.Height := -11;
    ReportGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    ReportGrid.Appearance.SummaryLayout.Font.Style := [];
    ReportGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    ReportGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    ReportGrid.Appearance.SelectedLayout.Font.Height := -16;
    ReportGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.SelectedLayout.Font.Style := [];
    ReportGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    ReportGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    ReportGrid.Appearance.FocusedLayout.Font.Height := -16;
    ReportGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.FocusedLayout.Font.Style := [];
    ReportGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    ReportGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    ReportGrid.Appearance.BandLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    ReportGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.BandLayout.Font.Color := clBlack;
    ReportGrid.Appearance.BandLayout.Font.Height := -16;
    ReportGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    ReportGrid.Appearance.BandLayout.Font.Style := [];
    ReportGrid.Appearance.ProgressLayout.Color := 15385233;
    ReportGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    ReportGrid.LeftCol := 0;
    ReportGrid.ScrollMode := scmItemScrolling;
    ReportGrid.GlobalFont.Scale := 1.000000000000000000;
    ReportGrid.GlobalFont.Style := [];
    ReportGrid.DesignTimeSampleData := True;
    MyMessageDlg.SetParentComponent(ReportGrid);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 51;
    MyMessageDlg.Top := 392;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 447;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := ReportGrid;
    GridDatabaseAdapter.DataSource := ReportSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'rl_id';
      Header := '  Title';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'rl_type_string';
      Header := '  Type';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'rl_period_string';
      Header := '  Period';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'rl_owner';
      Header := '  Owner';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1087;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    ReportEdit.SetParentComponent(ToolbarPanel);
    ReportEdit.Name := 'ReportEdit';
    ReportEdit.Left := 20;
    ReportEdit.Top := 10;
    ReportEdit.Width := 345;
    ReportEdit.Height := 34;
    ReportEdit.AutoFocus := True;
    ReportEdit.ChildOrder := 1;
    ReportEdit.Font.Charset := ANSI_CHARSET;
    ReportEdit.Font.Color := clWindowText;
    ReportEdit.Font.Height := -16;
    ReportEdit.Font.Name := 'Segoe UI';
    ReportEdit.Font.Style := [];
    ReportEdit.HeightPercent := 100.000000000000000000;
    ReportEdit.ParentFont := False;
    ReportEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportEdit, Self, 'OnChange', 'ReportEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 536;
    StatusBarPanel.Width := 1087;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 967;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 236;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 20;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 344;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    PrintButton.SetParentComponent(StatusBarPanel);
    PrintButton.Name := 'PrintButton';
    PrintButton.AlignWithMargins := True;
    PrintButton.Left := 843;
    PrintButton.Top := 8;
    PrintButton.Width := 109;
    PrintButton.Height := 31;
    PrintButton.Margins.Left := 10;
    PrintButton.Margins.Top := 8;
    PrintButton.Margins.Right := 5;
    PrintButton.Margins.Bottom := 10;
    PrintButton.Align := alRight;
    PrintButton.Caption := 'Print';
    PrintButton.Color := clNone;
    PrintButton.ElementClassName := 'MyWebButton';
    PrintButton.Font.Charset := ANSI_CHARSET;
    PrintButton.Font.Color := clWindowText;
    PrintButton.Font.Height := -13;
    PrintButton.Font.Name := 'Segoe UI';
    PrintButton.Font.Style := [];
    PrintButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.PrintButton.Glyph.png');
    PrintButton.HeightPercent := 100.000000000000000000;
    PrintButton.ParentFont := False;
    PrintButton.TabOrder := 4;
    PrintButton.WidthPercent := 100.000000000000000000;
    SetEvent(PrintButton, Self, 'OnClick', 'PrintButtonClick');
    ViewButton.SetParentComponent(StatusBarPanel);
    ViewButton.Name := 'ViewButton';
    ViewButton.AlignWithMargins := True;
    ViewButton.Left := 128;
    ViewButton.Top := 8;
    ViewButton.Width := 100;
    ViewButton.Height := 31;
    ViewButton.Margins.Left := 0;
    ViewButton.Margins.Top := 8;
    ViewButton.Margins.Right := 8;
    ViewButton.Margins.Bottom := 10;
    ViewButton.Align := alLeft;
    ViewButton.Caption := 'View';
    ViewButton.Color := clNone;
    ViewButton.ElementClassName := 'MyWebButton';
    ViewButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.ViewButton.Glyph.png');
    ViewButton.HeightPercent := 100.000000000000000000;
    ViewButton.TabOrder := 5;
    ViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewButton, Self, 'OnClick', 'EditButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 6;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    MailButton.SetParentComponent(StatusBarPanel);
    MailButton.Name := 'MailButton';
    MailButton.AlignWithMargins := True;
    MailButton.Left := 719;
    MailButton.Top := 8;
    MailButton.Width := 109;
    MailButton.Height := 31;
    MailButton.Margins.Left := 10;
    MailButton.Margins.Top := 8;
    MailButton.Margins.Right := 5;
    MailButton.Margins.Bottom := 10;
    MailButton.Align := alRight;
    MailButton.Caption := 'Mail';
    MailButton.Color := clNone;
    MailButton.ElementClassName := 'MyWebButton';
    MailButton.Font.Charset := ANSI_CHARSET;
    MailButton.Font.Color := clWindowText;
    MailButton.Font.Height := -13;
    MailButton.Font.Name := 'Segoe UI';
    MailButton.Font.Style := [];
    MailButton.Glyph.LoadFromFile('uReportList.StatusBarPanel.MailButton.Glyph.png');
    MailButton.HeightPercent := 100.000000000000000000;
    MailButton.ParentFont := False;
    MailButton.TabOrder := 7;
    MailButton.WidthPercent := 100.000000000000000000;
    SetEvent(MailButton, Self, 'OnClick', 'MailButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1093;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 87;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Report - List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1064;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uReportList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uReportList.WebWaitMessage.Picture.gif');
    ReportDataset.SetParentComponent(Self);
    ReportDataset.Name := 'ReportDataset';
    ReportDataset.AfterOpen := ReportDatasetAfterOpen;
    SetEvent(ReportDataset, Self, 'OnFilterRecord', 'ReportDatasetFilterRecord');
    ReportDataset.Left := 412;
    ReportDataset.Top := 488;
    ReportDatasetrl_pk.SetParentComponent(ReportDataset);
    ReportDatasetrl_pk.Name := 'ReportDatasetrl_pk';
    ReportDatasetrl_pk.FieldName := 'rl_pk';
    ReportDatasetrl_pk.Visible := False;
    ReportDatasetrl_pk.Size := 256;
    ReportDatasetrl_id.SetParentComponent(ReportDataset);
    ReportDatasetrl_id.Name := 'ReportDatasetrl_id';
    ReportDatasetrl_id.FieldName := 'rl_id';
    ReportDatasetrl_id.Size := 256;
    ReportDatasetrl_type.SetParentComponent(ReportDataset);
    ReportDatasetrl_type.Name := 'ReportDatasetrl_type';
    ReportDatasetrl_type.FieldName := 'rl_type';
    ReportDatasetrl_type.Visible := False;
    ReportDatasetrl_type.Size := 256;
    ReportDatasetrl_type_string.SetParentComponent(ReportDataset);
    ReportDatasetrl_type_string.Name := 'ReportDatasetrl_type_string';
    ReportDatasetrl_type_string.FieldName := 'rl_type_string';
    ReportDatasetrl_type_string.Size := 256;
    ReportDatasetrl_format.SetParentComponent(ReportDataset);
    ReportDatasetrl_format.Name := 'ReportDatasetrl_format';
    ReportDatasetrl_format.FieldName := 'rl_format';
    ReportDatasetrl_format.Visible := False;
    ReportDatasetrl_format.Size := 256;
    ReportDatasetrl_format_string.SetParentComponent(ReportDataset);
    ReportDatasetrl_format_string.Name := 'ReportDatasetrl_format_string';
    ReportDatasetrl_format_string.FieldName := 'rl_format_string';
    ReportDatasetrl_format_string.Size := 256;
    ReportDatasetrl_period.SetParentComponent(ReportDataset);
    ReportDatasetrl_period.Name := 'ReportDatasetrl_period';
    ReportDatasetrl_period.FieldName := 'rl_period';
    ReportDatasetrl_period.Size := 256;
    ReportDatasetrl_period_string.SetParentComponent(ReportDataset);
    ReportDatasetrl_period_string.Name := 'ReportDatasetrl_period_string';
    ReportDatasetrl_period_string.FieldName := 'rl_period_string';
    ReportDatasetrl_period_string.Size := 256;
    ReportDatasetrl_date_from.SetParentComponent(ReportDataset);
    ReportDatasetrl_date_from.Name := 'ReportDatasetrl_date_from';
    ReportDatasetrl_date_from.FieldName := 'rl_date_from';
    ReportDatasetrl_date_from.Visible := False;
    ReportDatasetrl_date_from.Size := 256;
    ReportDatasetrl_date_till.SetParentComponent(ReportDataset);
    ReportDatasetrl_date_till.Name := 'ReportDatasetrl_date_till';
    ReportDatasetrl_date_till.FieldName := 'rl_date_till';
    ReportDatasetrl_date_till.Visible := False;
    ReportDatasetrl_date_till.Size := 256;
    ReportDatasetrl_filter_ogpk.SetParentComponent(ReportDataset);
    ReportDatasetrl_filter_ogpk.Name := 'ReportDatasetrl_filter_ogpk';
    ReportDatasetrl_filter_ogpk.FieldName := 'rl_filter_ogpk';
    ReportDatasetrl_filter_ogpk.Visible := False;
    ReportDatasetrl_filter_ogpk.Size := 256;
    ReportDatasetrl_filter_otpk.SetParentComponent(ReportDataset);
    ReportDatasetrl_filter_otpk.Name := 'ReportDatasetrl_filter_otpk';
    ReportDatasetrl_filter_otpk.FieldName := 'rl_filter_otpk';
    ReportDatasetrl_filter_otpk.Visible := False;
    ReportDatasetrl_filter_otpk.Size := 256;
    ReportDatasetrl_option_list.SetParentComponent(ReportDataset);
    ReportDatasetrl_option_list.Name := 'ReportDatasetrl_option_list';
    ReportDatasetrl_option_list.FieldName := 'rl_option_list';
    ReportDatasetrl_option_list.Visible := False;
    ReportDatasetrl_option_list.Size := 256;
    ReportDatasetrl_owner_pk.SetParentComponent(ReportDataset);
    ReportDatasetrl_owner_pk.Name := 'ReportDatasetrl_owner_pk';
    ReportDatasetrl_owner_pk.FieldName := 'rl_owner_pk';
    ReportDatasetrl_owner_pk.Size := 256;
    ReportDatasetrl_owner.SetParentComponent(ReportDataset);
    ReportDatasetrl_owner.Name := 'ReportDatasetrl_owner';
    ReportDatasetrl_owner.FieldName := 'rl_owner';
    ReportDatasetrl_owner.Visible := False;
    ReportDatasetrl_owner.Size := 256;
    ReportDatasetrl_object_no.SetParentComponent(ReportDataset);
    ReportDatasetrl_object_no.Name := 'ReportDatasetrl_object_no';
    ReportDatasetrl_object_no.FieldName := 'rl_object_no';
    ReportDatasetrl_object_no.Visible := False;
    ReportDatasetrl_object_no.Size := 256;
    ReportDatasetrl_object_id.SetParentComponent(ReportDataset);
    ReportDatasetrl_object_id.Name := 'ReportDatasetrl_object_id';
    ReportDatasetrl_object_id.FieldName := 'rl_object_id';
    ReportDatasetrl_object_id.Visible := False;
    ReportDatasetrl_object_id.Size := 256;
    ReportDatasetrl_schedule_type.SetParentComponent(ReportDataset);
    ReportDatasetrl_schedule_type.Name := 'ReportDatasetrl_schedule_type';
    ReportDatasetrl_schedule_type.FieldName := 'rl_schedule_type';
    ReportDatasetrl_schedule_type.Size := 256;
    ReportDatasetrl_schedule_weekday.SetParentComponent(ReportDataset);
    ReportDatasetrl_schedule_weekday.Name := 'ReportDatasetrl_schedule_weekday';
    ReportDatasetrl_schedule_weekday.FieldName := 'rl_schedule_weekday';
    ReportDatasetrl_schedule_weekday.Size := 256;
    ReportDatasetrl_schedule_day.SetParentComponent(ReportDataset);
    ReportDatasetrl_schedule_day.Name := 'ReportDatasetrl_schedule_day';
    ReportDatasetrl_schedule_day.FieldName := 'rl_schedule_day';
    ReportDatasetrl_schedule_day.Size := 256;
    ReportDatasetrl_schedule_month.SetParentComponent(ReportDataset);
    ReportDatasetrl_schedule_month.Name := 'ReportDatasetrl_schedule_month';
    ReportDatasetrl_schedule_month.FieldName := 'rl_schedule_month';
    ReportDatasetrl_schedule_month.Size := 256;
    ReportDatasetrl_recipient_list.SetParentComponent(ReportDataset);
    ReportDatasetrl_recipient_list.Name := 'ReportDatasetrl_recipient_list';
    ReportDatasetrl_recipient_list.FieldName := 'rl_recipient_list';
    ReportDatasetrl_recipient_list.Size := 256;
    ReportDatasetrl_mailto_myself.SetParentComponent(ReportDataset);
    ReportDatasetrl_mailto_myself.Name := 'ReportDatasetrl_mailto_myself';
    ReportDatasetrl_mailto_myself.FieldName := 'rl_mailto_myself';
    ReportDatasetrl_mailto_myself.Size := 256;
    ReportDatasetrl_mailto_groups.SetParentComponent(ReportDataset);
    ReportDatasetrl_mailto_groups.Name := 'ReportDatasetrl_mailto_groups';
    ReportDatasetrl_mailto_groups.FieldName := 'rl_mailto_groups';
    ReportDatasetrl_mailto_groups.Size := 256;
    ReportSource.SetParentComponent(Self);
    ReportSource.Name := 'ReportSource';
    ReportSource.DataSet := ReportDataset;
    ReportSource.Left := 484;
    ReportSource.Top := 488;
    ReportTimer.SetParentComponent(Self);
    ReportTimer.Name := 'ReportTimer';
    ReportTimer.Enabled := False;
    ReportTimer.Interval := 100;
    SetEvent(ReportTimer, Self, 'OnTimer', 'ReportTimerTimer');
    ReportTimer.Left := 283;
    ReportTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    MyConnection.SetParentComponent(Self);
    MyConnection.Name := 'MyConnection';
    MyConnection.Active := False;
    MyConnection.Left := 191;
    MyConnection.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ReportGrid.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    ReportEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    PrintButton.AfterLoadDFMValues;
    ViewButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    MailButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    ReportDataset.AfterLoadDFMValues;
    ReportDatasetrl_pk.AfterLoadDFMValues;
    ReportDatasetrl_id.AfterLoadDFMValues;
    ReportDatasetrl_type.AfterLoadDFMValues;
    ReportDatasetrl_type_string.AfterLoadDFMValues;
    ReportDatasetrl_format.AfterLoadDFMValues;
    ReportDatasetrl_format_string.AfterLoadDFMValues;
    ReportDatasetrl_period.AfterLoadDFMValues;
    ReportDatasetrl_period_string.AfterLoadDFMValues;
    ReportDatasetrl_date_from.AfterLoadDFMValues;
    ReportDatasetrl_date_till.AfterLoadDFMValues;
    ReportDatasetrl_filter_ogpk.AfterLoadDFMValues;
    ReportDatasetrl_filter_otpk.AfterLoadDFMValues;
    ReportDatasetrl_option_list.AfterLoadDFMValues;
    ReportDatasetrl_owner_pk.AfterLoadDFMValues;
    ReportDatasetrl_owner.AfterLoadDFMValues;
    ReportDatasetrl_object_no.AfterLoadDFMValues;
    ReportDatasetrl_object_id.AfterLoadDFMValues;
    ReportDatasetrl_schedule_type.AfterLoadDFMValues;
    ReportDatasetrl_schedule_weekday.AfterLoadDFMValues;
    ReportDatasetrl_schedule_day.AfterLoadDFMValues;
    ReportDatasetrl_schedule_month.AfterLoadDFMValues;
    ReportDatasetrl_recipient_list.AfterLoadDFMValues;
    ReportDatasetrl_mailto_myself.AfterLoadDFMValues;
    ReportDatasetrl_mailto_groups.AfterLoadDFMValues;
    ReportSource.AfterLoadDFMValues;
    ReportTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    MyConnection.AfterLoadDFMValues;
  end;
end;

end.

unit uGeneralSettings;

interface

uses
  System.SysUtils, System.Classes, System.StrUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage,
  WEBLib.ExtCtrls, WEBLIB.REST, WEBLib.JSON,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCPageControl,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTabSet, WEBLib.ComCtrls,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, WEBLib.Buttons,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCCustomScrollControl,
  VCL.TMSFNCGridData, VCL.TMSFNCGrid, Data.DB, WEBLib.DB, WEBLib.CDS,
  Vcl.Imaging.GIFImg, VCL.TMSFNCPanel;

type
  TGeneralSettingsForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    TMSFNCPageControl: TTMSFNCPageControl;
    TMSFNCPageControlPage0: TTMSFNCPageControlContainer;
    TMSFNCPageControlPage1: TTMSFNCPageControlContainer;
    TMSFNCPageControlPage2: TTMSFNCPageControlContainer;
    SearchEditTimer: TTimer;
    FocusRecordTimer: TTimer;
    CompanyDataset: TClientDataSet;
    CompanySource: TDataSource;
    TabChangeTimer: TTimer;
    WebWaitMessage: TWaitMessage;
    CompanyGridAdapter: TTMSFNCGridDatabaseAdapter;
    CompanyDatasetof_pk: TStringField;
    CompanyDatasetof_naam: TStringField;
    WebConnection: TClientConnection;
    DepartmentDataSet: TClientDataSet;
    DepartmentSource: TDataSource;
    DepartmentGridAdapter: TTMSFNCGridDatabaseAdapter;
    CompanyFilterPanel: TTMSFNCPanel;
    CompanyEdit: TEdit;
    DepartmentFilterPanel: TTMSFNCPanel;
    DepartmentEdit: TEdit;
    DepartmentButtonPanel: TTMSFNCPanel;
    DepartmentAddButton: TSpeedButton;
    DepartmentEditButton: TSpeedButton;
    DepartmentDeleteButton: TSpeedButton;
    DepartmentCloseButton: TSpeedButton;
    DepartmentGrid: TTMSFNCGrid;
    CompanyGrid: TTMSFNCGrid;
    CompanyButtonPanel: TTMSFNCPanel;
    CompanyAddButton: TSpeedButton;
    CompanyEditButton: TSpeedButton;
    CompanyDeleteButton: TSpeedButton;
    CompanyCloseButton: TSpeedButton;
    DepartmentDataSetda_pk: TStringField;
    DepartmentDataSetda_naam: TStringField;
    MyMessageDlg: TMessageDlg;
    AssetStatusGridAdapter: TTMSFNCGridDatabaseAdapter;
    AssetStatusDataSet: TClientDataSet;
    AssetStatusSource: TDataSource;
    AssetStatusDataSetobs_pk: TStringField;
    AssetStatusDataSetobs_naam: TStringField;
    AssetStatusDataSetobs_mail_checkbox: TStringField;
    AssetStatusDataSetobs_mail_owner_checkbox: TStringField;
    AssetStatusDataSetobs_mail_owner_department: TStringField;
    AssetStatusDataSetobs_mail_tenant_checkbox: TStringField;
    AssetStatusDataSetobs_mail_tenant_department: TStringField;
    AssetStatusDataSetobs_mail_teamleader_checkbox: TStringField;
    AssetStatusDataSetobs_mail_driver_checkbox: TStringField;
    AssetStatusDataSetobs_text: TStringField;
    AssetStatusDataSetobs_mail_owner_department_names: TStringField;
    AssetStatusDataSetobs_mail_tenant_department_names: TStringField;
    TMSFNCPageControlPage3: TTMSFNCPageControlContainer;
    RealTimeMapMaximumDistanceLabel: TLabel;
    RealTimeMapMaximumDistanceEdit: TEdit;
    TMSFNCPanel1: TTMSFNCPanel;
    SaveButton: TSpeedButton;
    SettingsGeneralCloseButton: TSpeedButton;
    ReportServerURLLabel: TLabel;
    ReportServerURLEdit: TEdit;
    CompanyViewButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    TMSFNCPanel2: TTMSFNCPanel;
    DepartmentViewButton: TSpeedButton;
    TMSFNCPageControlPage4: TTMSFNCPageControlContainer;
    DeviceReasonDeleteDataSet: TClientDataSet;
    DeviceReasonDeleteSource: TDataSource;
    DeviceReasonDeleteDataSettrv_pk: TStringField;
    DeviceReasonDeleteDataSettrv_naam: TStringField;
    DeviceReasonDeleteGridAdapter: TTMSFNCGridDatabaseAdapter;
    TMSFNCPageControlAsset: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    AssetStatusFilterPanel: TTMSFNCPanel;
    AssetStatusEdit: TEdit;
    AssetStatusButtonPanel: TTMSFNCPanel;
    AssetStatusAddButton: TSpeedButton;
    AssetStatusEditButton: TSpeedButton;
    AssetStatusDeleteButton: TSpeedButton;
    AssetStatusCloseButton: TSpeedButton;
    TMSFNCPanel3: TTMSFNCPanel;
    AssetStatusViewButton: TSpeedButton;
    AssetStatusGrid: TTMSFNCGrid;
    AssetGroupFilterPanel: TTMSFNCPanel;
    AssetGroupEdit: TEdit;
    AssetGroupButtonPanel: TTMSFNCPanel;
    AssetGroupAddButton: TSpeedButton;
    AssetGroupEditButton: TSpeedButton;
    AssetGroupDeleteButton: TSpeedButton;
    WebSpeedButton5: TSpeedButton;
    TMSFNCPanel9: TTMSFNCPanel;
    AssetGroupViewButton: TSpeedButton;
    AssetGroupGrid: TTMSFNCGrid;
    AssetTypeFilterPanel: TTMSFNCPanel;
    AssetTypeEdit: TEdit;
    AssetTypeButtonPanel: TTMSFNCPanel;
    AssetTypeAddButton: TSpeedButton;
    AssetTypeEditButton: TSpeedButton;
    AssetTypeDeleteButton: TSpeedButton;
    WebSpeedButton10: TSpeedButton;
    TMSFNCPanel12: TTMSFNCPanel;
    AssetTypeViewButton: TSpeedButton;
    AssetTypeGrid: TTMSFNCGrid;
    AssetGroupDataSet: TClientDataSet;
    AssetGroupSource: TDataSource;
    AssetGroupGridAdapter: TTMSFNCGridDatabaseAdapter;
    AssetTypeGridAdapter: TTMSFNCGridDatabaseAdapter;
    AssetTypeDataSet: TClientDataSet;
    AssetTypeSource: TDataSource;
    AssetGroupDataSetog_pk: TStringField;
    AssetGroupDataSetog_naam: TStringField;
    AssetTypeDataSetot_pk: TStringField;
    AssetTypeDataSetot_naam: TStringField;
    [async]
    TMSFNCPageControlDevice: TTMSFNCPageControl;
    TMSFNCPageControlContainer1: TTMSFNCPageControlContainer;
    TMSFNCPageControlContainer2: TTMSFNCPageControlContainer;
    TMSFNCPageControlContainer3: TTMSFNCPageControlContainer;
    TMSFNCPanel4: TTMSFNCPanel;
    DeviceReasonDeleteEdit: TEdit;
    DeviceReasonDeleteGrid: TTMSFNCGrid;
    TMSFNCPanel5: TTMSFNCPanel;
    DeviceReasonDeleteAddButton: TSpeedButton;
    DeviceReasonDeleteEditButton: TSpeedButton;
    DeviceReasonDeleteDeleteButton: TSpeedButton;
    WebSpeedButton4: TSpeedButton;
    TMSFNCPanel6: TTMSFNCPanel;
    DeviceReasonDeleteViewButton: TSpeedButton;
    TMSFNCPanel7: TTMSFNCPanel;
    DeviceModelEdit: TEdit;
    TMSFNCPanel8: TTMSFNCPanel;
    DeviceModelEditButton: TSpeedButton;
    WebSpeedButton6: TSpeedButton;
    TMSFNCPanel10: TTMSFNCPanel;
    DeviceModelViewButton: TSpeedButton;
    DeviceModelGridAdapter: TTMSFNCGridDatabaseAdapter;
    DeviceModelDataSet: TClientDataSet;
    DeviceModelSource: TDataSource;
    DeviceModelGrid: TTMSFNCGrid;
    AssetIDIsReadOnlyEdit: TCheckBox;
    WebLabel1: TLabel;
    MachineMaxEnabledEdit: TCheckBox;
    WebLabel2: TLabel;
    MachineMaxIntervalEdit: TEdit;
    WebLabel3: TLabel;
    MachineMaxFillColorEdit: TTMSFNCPanel;
    DeviceModelDataSettm_pk: TStringField;
    DeviceModelDataSettm_id: TStringField;
    TMSFNCPageControlAssetPage3: TTMSFNCPageControlContainer;
    TMSFNCPageControlAssetPage4: TTMSFNCPageControlContainer;
    AssetBrandGridAdapter: TTMSFNCGridDatabaseAdapter;
    AssetModelGridAdapter: TTMSFNCGridDatabaseAdapter;
    AssetBrandSource: TDataSource;
    AssetModelSource: TDataSource;
    AssetBrandFilterPanel: TTMSFNCPanel;
    AssetBrandEdit: TEdit;
    AssetModelFilterPanel: TTMSFNCPanel;
    AssetModelEdit: TEdit;
    AssetBrandGrid: TTMSFNCGrid;
    AssetModelGrid: TTMSFNCGrid;
    AssetBrandDataset: TClientDataSet;
    AssetBrandDatasetPKey: TStringField;
    AssetBrandDatasetID: TStringField;
    AssetModelDataset: TClientDataSet;
    AssetModelDatasetPKey: TStringField;
    AssetModelDatasetID: TStringField;
    AssetModelDatasetBrandPKey: TStringField;
    AssetModelDatasetBrandID: TStringField;
    TMSFNCPanel11: TTMSFNCPanel;
    AssetBrandAddButton: TSpeedButton;
    AssetBrandEditButton: TSpeedButton;
    AssetBrandDeleteButton: TSpeedButton;
    WebSpeedButton7: TSpeedButton;
    TMSFNCPanel13: TTMSFNCPanel;
    AssetBrandViewButton: TSpeedButton;
    TMSFNCPanel14: TTMSFNCPanel;
    AssetModelAddButton: TSpeedButton;
    AssetModelEditButton: TSpeedButton;
    AssetModelDeleteButton: TSpeedButton;
    WebSpeedButton13: TSpeedButton;
    TMSFNCPanel15: TTMSFNCPanel;
    AssetModelViewButton: TSpeedButton;
    WebLabel4: TLabel;
    RealtimeMapRefreshAssetCountMax: TEdit;
    AssetStatusDataSetobs_hexcolor: TStringField;
    TMSFNCPageControlAssetPage5: TTMSFNCPageControlContainer;
    AssetRepairDataSet: TClientDataSet;
    StringField1: TStringField;
    StringField2: TStringField;
    AssetRepairSource: TDataSource;
    TMSFNCPanel16: TTMSFNCPanel;
    AssetRepairEdit: TEdit;
    AssetRepairGridAdapter: TTMSFNCGridDatabaseAdapter;
    AssetRepairGrid: TTMSFNCGrid;
    AssetRepairDataSetType: TStringField;
    AssetRepairDataSetBrand: TStringField;
    AssetRepairDataSetModel: TStringField;
    TMSFNCPanel17: TTMSFNCPanel;
    AssetRepairAddButton: TSpeedButton;
    AssetRepairEditButton: TSpeedButton;
    AssetRepairDeleteButton: TSpeedButton;
    WebSpeedButton8: TSpeedButton;
    TMSFNCPanel18: TTMSFNCPanel;
    AssetRepairViewButton: TSpeedButton;
    TMSFNCPageControlPage5: TTMSFNCPageControlContainer;
    TMSFNCPanel19: TTMSFNCPanel;
    WebLabel5: TLabel;
    UserManagerYesEdit: TRadioButton;
    UserManagerNoEdit: TRadioButton;
    UserManagerGridAdapter: TTMSFNCGridDatabaseAdapter;
    UserManagerDataset: TClientDataSet;
    UserManagerDataSource: TDataSource;
    TMSFNCPanel20: TTMSFNCPanel;
    UserManagerAddButton: TSpeedButton;
    UserManagerDeleteButton: TSpeedButton;
    TMSFNCPanel21: TTMSFNCPanel;
    UserManagerDatasetga_pk: TStringField;
    UserManagerDatasetga_name: TStringField;
    UserManagerGrid: TTMSFNCGrid;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    procedure TMSFNCPageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    procedure TabChangeTimerTimer(Sender: TObject);
    procedure SearchEditChange(Sender: TObject);
    procedure SearchEditTimerTimer(Sender: TObject);
    procedure DatasetAfterOpen(DataSet: TDataSet);
    procedure FocusRecordTimerTimer(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure DepartmentAddButtonClick(Sender: TObject);
    procedure DepartmentEditButtonClick(Sender: TObject);
    [async]
    procedure DepartmentDeleteButtonClick(Sender: TObject); async;
    [async]
    procedure CompanyEditButtonClick(Sender: TObject); async;
    procedure CompanyAddButtonClick(Sender: TObject);
    [async]
    procedure CompanyDeleteButtonClick(Sender: TObject); async;
    procedure AssetStatusAddButtonClick(Sender: TObject);
    procedure AssetStatusEditButtonClick(Sender: TObject);
    [async]
    procedure AssetStatusDeleteButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure CompanyGridDblClick(Sender: TObject);
    procedure DepartmentGridDblClick(Sender: TObject);
    procedure AssetStatusGridDblClick(Sender: TObject);
    procedure DeviceReasonDeleteAddButtonClick(Sender: TObject);
    procedure DeviceReasonDeleteViewButtonClick(Sender: TObject);
    procedure DeviceReasonDeleteDeleteButtonClick(Sender: TObject);
    procedure DeviceReasonDeleteEditButtonDblClick(Sender: TObject);
    procedure DeviceReasonDeleteEditButtonClick(Sender: TObject);
    procedure TMSFNCPageControlAssetChangePage(Sender: TObject;
      APreviousPageIndex, ACurrentPageIndex: Integer);
    procedure AssetGroupAddButtonClick(Sender: TObject);
    procedure AssetTypeAddButtonClick(Sender: TObject);
    procedure AssetGroupEditButtonClick(Sender: TObject);
    procedure AssetTypeEditButtonClick(Sender: TObject);
    [async]
    procedure AssetGroupDeleteButtonClick(Sender: TObject); async;
    [async]
    procedure AssetTypeDeleteButtonClick(Sender: TObject); async;
    procedure AssetGroupGridDblClick(Sender: TObject);
    procedure AssetTypeGridDblClick(Sender: TObject);
    procedure RecordChange(Sender: TObject);
    procedure TMSFNCPageControlBeforeChangePage(Sender: TObject;
      ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure TMSFNCPageControlAssetBeforeChangePage(Sender: TObject;
      ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure DeviceModelEditButtonClick(Sender: TObject);
    procedure TMSFNCPageControlDeviceBeforeChangePage(Sender: TObject;
      ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure TMSFNCPageControlDeviceChangePage(Sender: TObject;
      APreviousPageIndex, ACurrentPageIndex: Integer);
    [async]
    procedure MachineMaxFillColorEditClick(Sender: TObject); async;
    procedure AssetBrandAddButtonClick(Sender: TObject);
    [async]
    procedure AssetBrandEditButtonClick(Sender: TObject); async;
    [async]
    procedure AssetBrandDeleteButtonClick(Sender: TObject); async;
    [async]
    procedure AssetModelAddButtonClick(Sender: TObject); async;
    [async]
    procedure AssetModelEditButtonClick(Sender: TObject); async;
    [async]
    procedure AssetModelDeleteButtonClick(Sender: TObject); async;
    procedure AssetModelGridDblClick(Sender: TObject);
    procedure AssetBrandGridDblClick(Sender: TObject);
    procedure DeviceReasonDeleteGridDblClick(Sender: TObject);
    procedure DeviceModelGridDblClick(Sender: TObject);
    procedure AssetRepairAddButtonClick(Sender: TObject);
    procedure AssetRepairEditButtonClick(Sender: TObject);
    [async]
    procedure AssetRepairDeleteButtonClick(Sender: TObject); async;
    [async]
    procedure UserManagerAddButtonClick(Sender: TObject); async;
    [async]
    procedure UserManagerDeleteButtonClick(Sender: TObject); async;
  private
    { Private declarations }
    RecordChanged: Boolean;
    Permission: Integer;
    [async]
    procedure LoadList(MainPageIndex, SubPageIndex: Integer; URL: String); async;
    [async]
    procedure ShowCompanyEdit(PKey: Integer); async;
    [async]
    procedure DeleteCompany(PKey: Integer); async;
    [async]
    procedure ShowDepartmentEdit(PKey: Integer); async;
    [async]
    procedure DeleteDepartment(PKey: Integer); async;
    [async]
    procedure ShowAssetStatusEdit(PKey: Integer); async;
    [async]
    procedure DeleteAssetStatus(PKey: Integer); async;
    [async]
    procedure ShowAssetGroupEdit(PKey: Integer); async;
    [async]
    procedure DeleteAssetGroup(PKey: Integer); async;
    [async]
    procedure ShowAssetTypeEdit(PKey: Integer); async;
    [async]
    procedure ShowAssetBrandEdit(PKey: Integer); async;
    [async]
    procedure ShowAssetModelEdit(PKey: Integer); async;
    [async]
    procedure ShowAssetRepairEdit(PKey: Integer); async;
    [async]
    procedure DeleteAssetType(PKey: Integer); async;
    [async]
    procedure DeleteAssetBrand(PKey: Integer); async;
    [async]
    procedure DeleteAssetModel(PKey: Integer); async;
    [async]
    procedure DeleteAssetRepair(PKey: Integer); async;
    [async]
    procedure ShowDeviceModelEdit(PKey: Integer); async;
    [async]
    procedure ShowDeviceReasonDeleteEdit(PKey: Integer); async;
    [async]
    procedure AddUserManager(PKey: String); async;
    [async]
    procedure DeleteUserManager(PKey: String); async;
    [async]
    function SaveGeneralSettings(): Boolean; async;
  public
    { Public declarations }
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  GeneralSettingsForm: TGeneralSettingsForm;

implementation

{$R *.dfm}

uses uCommon, uDepartmentEdit, uCompanyEdit, uAssetStatusEdit,
  uDeviceReasonDeleteEdit, uAssetGroupEdit, uAssetTypeEdit, uDeviceModelEdit,
  uColorPicker, uModelEdit, uBrandEdit, uAssetRepairTextEdit, uUserList,
  uManagerList;

procedure TGeneralSettingsForm.WebFormCreate(Sender: TObject);
begin
  Tag := 0;
  SetGridStyle(CompanyGrid, False);
  SetGridStyle(DepartmentGrid, False);
  SetGridStyle(AssetStatusGrid, False);
  SetGridStyle(AssetGroupGrid, False);
  SetGridStyle(AssetTypeGrid, False);
  SetGridStyle(AssetBrandGrid, False);
  SetGridStyle(AssetModelGrid, False);
  SetGridStyle(AssetRepairGrid, False);
  SetGridStyle(DeviceModelGrid, False);
  SetGridStyle(DeviceReasonDeleteGrid, False);
  SetGridStyle(UserManagerGrid, False);
  TMSFNCPageControl.ActivePageIndex := 0;
end;

procedure TGeneralSettingsForm.SetPermission(MyPermission: Integer);
begin
  Permission := MyPermission;
  // General Settings
  SaveButton.Visible := MyPermission in [2,3,7];
  RealTimeMapMaximumDistanceEdit.ReadOnly := MyPermission in [1,5];
  ReportServerURLEdit.ReadOnly := MyPermission in [1,5];
  // Company
  CompanyAddButton.Visible := MyPermission in [2,3,6,7];
  CompanyViewButton.Visible := MyPermission in [1,5];
  CompanyEditButton.Visible := MyPermission in [2,3,7];
  CompanyDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Department
  DepartmentAddButton.Visible := MyPermission in [2,3,6,7];
  DepartmentViewButton.Visible := MyPermission in [1,5];
  DepartmentEditButton.Visible := MyPermission in [2,3,7];
  DepartmentDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Asset Status
  AssetStatusAddButton.Visible := MyPermission in [2,3,6,7];
  AssetStatusViewButton.Visible := MyPermission in [1,5];
  AssetStatusEditButton.Visible := MyPermission in [2,3,7];
  AssetStatusDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Asset Group
  AssetGroupAddButton.Visible := MyPermission in [2,3,6,7];
  AssetGroupViewButton.Visible := MyPermission in [1,5];
  AssetGroupEditButton.Visible := MyPermission in [2,3,7];
  AssetGroupDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Asset Type
  AssetTypeAddButton.Visible := MyPermission in [2,3,6,7];
  AssetTypeViewButton.Visible := MyPermission in [1,5];
  AssetTypeEditButton.Visible := MyPermission in [2,3,7];
  AssetTypeDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Asset Brand
  AssetBrandAddButton.Visible := MyPermission in [2,3,6,7];
  AssetBrandViewButton.Visible := MyPermission in [1,5];
  AssetBrandEditButton.Visible := MyPermission in [2,3,7];
  AssetBrandDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Asset Model
  AssetModelAddButton.Visible := MyPermission in [2,3,6,7];
  AssetModelViewButton.Visible := MyPermission in [1,5];
  AssetModelEditButton.Visible := MyPermission in [2,3,7];
  AssetModelDeleteButton.Visible := MyPermission in [4,5,6,7];
  // Device Model
  DeviceModelViewButton.Visible := MyPermission in [1,5];
  DeviceModelEditButton.Visible := MyPermission in [2,3,7];
  // Device Delete Reason
  DeviceReasonDeleteAddButton.Visible := MyPermission in [2,3,6,7];
  DeviceReasonDeleteViewButton.Visible := MyPermission in [1,5];
  DeviceReasonDeleteEditButton.Visible := MyPermission in [2,3,7];
  DeviceReasonDeleteDeleteButton.Visible := MyPermission in [4,5,6,7];
end;

procedure TGeneralSettingsForm.WebFormShow(Sender: TObject);
begin
//  WebWaitMessage.Show;
  Tag := 0;
  TabChangeTimer.Enabled := True;
  RecordChanged := False;
end;

procedure TGeneralSettingsForm.AssetBrandAddButtonClick(Sender: TObject);
begin
  ShowAssetBrandEdit(-1);
end;

procedure TGeneralSettingsForm.AssetBrandDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if AssetBrandDataSet.Active and not(AssetBrandDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete asset brand?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteAssetBrand(AssetBrandDataset.FieldByName('PKey').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.AssetBrandEditButtonClick(Sender: TObject);
begin
  if AssetBrandDataset.Active and not(AssetBrandDataset.IsEmpty) then begin
    AssetBrandDataset.Tag := AssetBrandDataset.FieldByName('PKey').AsInteger;
    ShowAssetBrandEdit(AssetBrandDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.AssetBrandGridDblClick(Sender: TObject);
begin
  if AssetBrandViewButton.Visible or AssetBrandEditButton.Visible then begin
    AssetBrandEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.AssetGroupAddButtonClick(Sender: TObject);
begin
  ShowAssetGroupEdit(-1);
end;

procedure TGeneralSettingsForm.AssetGroupDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if AssetGroupDataSet.Active and not(AssetGroupDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete asset group?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteAssetGroup(AssetGroupDataSet.FieldByName('og_pk').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.AssetGroupEditButtonClick(Sender: TObject);
begin
  if AssetGroupDataset.Active and not(AssetGroupDataset.IsEmpty) then begin
    AssetGroupDataset.Tag := AssetGroupDataset.FieldByName('og_pk').AsInteger;
    ShowAssetGroupEdit(AssetGroupDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.AssetGroupGridDblClick(Sender: TObject);
begin
  if AssetGroupViewButton.Visible or AssetGroupEditButton.Visible then begin
    AssetGroupEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.AssetModelAddButtonClick(Sender: TObject);
begin
  ShowAssetModelEdit(-1);
end;

procedure TGeneralSettingsForm.AssetModelDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if AssetModelDataSet.Active and not(AssetModelDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete asset model?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteAssetModel(AssetModelDataset.FieldByName('PKey').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.AssetModelEditButtonClick(Sender: TObject);
begin
  if AssetModelDataset.Active and not(AssetModelDataset.IsEmpty) then begin
    AssetModelDataset.Tag := AssetModelDataset.FieldByName('PKey').AsInteger;
    ShowAssetModelEdit(AssetModelDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.AssetModelGridDblClick(Sender: TObject);
begin
  if AssetModelViewButton.Visible or AssetModelEditButton.Visible then begin
    AssetModelEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.AssetRepairAddButtonClick(Sender: TObject);
begin
  ShowAssetRepairEdit(-1);
end;

procedure TGeneralSettingsForm.AssetRepairDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if AssetRepairDataSet.Active and not(AssetRepairDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete repair?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteAssetRepair(AssetRepairDataset.FieldByName('PKey').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.AssetRepairEditButtonClick(Sender: TObject);
begin
  if AssetRepairDataset.Active and not(AssetRepairDataset.IsEmpty) then begin
    AssetRepairDataset.Tag := AssetRepairDataset.FieldByName('PKey').AsInteger;
    ShowAssetRepairEdit(AssetRepairDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.AssetStatusAddButtonClick(Sender: TObject);
begin
  ShowAssetStatusEdit(-1);
end;

procedure TGeneralSettingsForm.AssetStatusDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if AssetStatusDataSet.Active and not(AssetStatusDataSet.IsEmpty) then begin
    if AssetStatusDataset.FieldByName('obs_pk').AsInteger > 0 then begin
      MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete asset status?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        DeleteAssetStatus(AssetStatusDataset.FieldByName('obs_pk').AsInteger);
      end;
    end;
  end;
end;

procedure TGeneralSettingsForm.DeleteAssetStatus(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  ErrorStatus, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_status_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      ErrorStatus := MyJO.GetJSONValue('error');
      if ErrorStatus = 'True' then begin
        ErrorMessage := MyJO.GetJSONValue('Message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end else begin
        SearchEditTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.AssetStatusEditButtonClick(Sender: TObject);
begin
  if AssetStatusDataset.Active and not(AssetStatusDataset.IsEmpty) then begin
    if AssetStatusDataset.FieldByName('obs_pk').AsInteger > 0 then begin
      AssetStatusDataset.Tag := AssetStatusDataset.FieldByName('obs_pk').AsInteger;
      ShowAssetStatusEdit(AssetStatusDataset.Tag);
    end;
  end;
end;

procedure TGeneralSettingsForm.AssetStatusGridDblClick(Sender: TObject);
begin
  if AssetStatusViewButton.Visible or AssetStatusEditButton.Visible then begin
    AssetStatusEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.AssetTypeAddButtonClick(Sender: TObject);
begin
  ShowAssetTypeEdit(-1);
end;

procedure TGeneralSettingsForm.AssetTypeDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if AssetTypeDataSet.Active and not(AssetTypeDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete asset type?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteAssetType(AssetTypeDataset.FieldByName('ot_pk').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.AssetTypeEditButtonClick(Sender: TObject);
begin
  if AssetTypeDataset.Active and not(AssetTypeDataset.IsEmpty) then begin
    AssetTypeDataset.Tag := AssetTypeDataset.FieldByName('ot_pk').AsInteger;
    ShowAssetTypeEdit(AssetTypeDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.AssetTypeGridDblClick(Sender: TObject);
begin
  if AssetTypeViewButton.Visible or AssetTypeEditButton.Visible then begin
    AssetTypeEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TGeneralSettingsForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      ModalResult := mrOK;
    end;
  end else begin
    ModalResult := mrOK;
  end;
end;

procedure TGeneralSettingsForm.CompanyAddButtonClick(Sender: TObject);
begin
  ShowCompanyEdit(0);
end;

procedure TGeneralSettingsForm.CompanyDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if CompanyDataSet.Active and not(CompanyDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete company?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteCompany(CompanyDataset.FieldByName('of_pk').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.DeleteCompany(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  ErrorStatus, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_company_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      ErrorStatus := MyJO.GetJSONValue('error');
      if ErrorStatus = 'True' then begin
        ErrorMessage := MyJO.GetJSONValue('Message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end else begin
        CompanyDataset.Tag := -1;
        SearchEditTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.CompanyEditButtonClick(Sender: TObject);
begin
  if CompanyDataset.Active and not(CompanyDataset.IsEmpty) then begin
    CompanyDataset.Tag := CompanyDataset.FieldByName('of_pk').AsInteger;
    ShowCompanyEdit(CompanyDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.CompanyGridDblClick(Sender: TObject);
begin
  if CompanyViewButton.Visible or CompanyEditButton.Visible then begin
    CompanyEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.SearchEditChange(Sender: TObject);
begin
  SearchEditTimer.Enabled := False;
  SearchEditTimer.Enabled := True;
end;

procedure TGeneralSettingsForm.SearchEditTimerTimer(Sender: TObject);
begin
//WebWaitMessage.Show;
  TTimer(Sender).Interval := 300;
  TTimer(Sender).Enabled := False;
  TabChangeTimer.Enabled := True;
end;

procedure TGeneralSettingsForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
begin
  Result := await(SaveGeneralSettings());
  if Result then begin
    ModalResult := mrOK;
  end;
end;

function TGeneralSettingsForm.SaveGeneralSettings(): Boolean;
var
  MySettings, MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  Result := False;
  MySettings :=
    '{' +
      '"realtime_filter_distance_max": "' + RealTimeMapMaximumDistanceEdit.Text + '", ' +
      '"realtime_refresh_asset_count_max": "' + RealtimeMapRefreshAssetCountMax.Text + '", ' +
      '"report_server_url": "' + ReportServerURLEdit.Text + '",' +
      '"asset_id_is_readonly": "' + BooleanToPostgreSQL(AssetIDIsReadOnlyEdit.Checked) + '",' +
      '"user_manager_all": "' + BooleanToPostgreSQL(UserManagerYesEdit.Checked) + '",' +
      '"machinemax_enabled": "' + BooleanToPostgreSQL(MachineMaxEnabledEdit.Checked) + '",' +
      '"machinemax_interval": "' + MachineMaxIntervalEdit.Text + '",' +
      '"machinemax_fillcolor": "' + IntToStr(MachineMaxFillColorEdit.Color) + '"' +
    '}';
  MyString :=
    '{' +
      '"sessionid":"' + SessionID + '",' +
      '"settings":' + MySettings +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_general_settings_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      await(UpdateGeneralSettings(MySettings));
      Result := True;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.DatasetAfterOpen(DataSet: TDataSet);
begin
  case TMSFNCPageControl.ActivePageIndex of
    1: begin
      if (CompanyDataset.Tag = -1) then begin
        CompanyDataset.First;
      end else begin
        CompanyDataset.Locate('of_pk', CompanyDataset.Tag, []);
      end;
//    CompanyGridAdapter.Active := True;
    end;
    2: begin
      if (DepartmentDataset.Tag = -1) then begin
        DepartmentDataset.First;
      end else begin
        DepartmentDataset.Locate('da_pk', DepartmentDataset.Tag, []);
      end;
//    DepartmentGridAdapter.Active := True;
    end;
    3: begin // Asset
      case TMSFNCPageControlAsset.ActivePageIndex of
        0: begin // Status
          if (AssetStatusDataset.Tag = -1) then begin
            AssetStatusDataset.First;
          end else begin
            AssetStatusDataset.Locate('obs_pk', AssetStatusDataset.Tag, []);
          end;
//        AssetStatusGridAdapter.Active := True;
        end;
        1: begin // Group
          if (AssetGroupDataset.Tag = -1) then begin
            AssetGroupDataset.First;
          end else begin
            AssetGroupDataset.Locate('og_pk', AssetGroupDataset.Tag, []);
          end;
//        AssetGroupGridAdapter.Active := True;
        end;
        2: begin // Type
          if (AssetTypeDataset.Tag = -1) then begin
            AssetTypeDataset.First;
          end else begin
            AssetTypeDataset.Locate('ot_pk', AssetTypeDataset.Tag, []);
          end;
//        AssetTypeGridAdapter.Active := True;
        end;
        3: begin // Brand
          if (AssetBrandDataset.Tag = -1) then begin
            AssetBrandDataset.First;
          end else begin
            AssetBrandDataset.Locate('PKey', AssetBrandDataset.Tag, []);
          end;
        end;
        4: begin // Model
          if (AssetModelDataset.Tag = -1) then begin
            AssetModelDataset.First;
          end else begin
            AssetModelDataset.Locate('PKey', AssetModelDataset.Tag, []);
          end;
          AssetModelGridAdapter.Active := True;
        end;
        5: begin // Repair
          if (AssetRepairDataset.Tag = -1) then begin
            AssetRepairDataset.First;
          end else begin
            AssetRepairDataset.Locate('PKey', AssetRepairDataset.Tag, []);
          end;
          AssetRepairGridAdapter.Active := True;
        end;
      end;
    end;
    4: begin // Device
      case TMSFNCPageControlDevice.ActivePageIndex of
        0: begin // Model
          if (DeviceModelDataset.Tag = -1) then begin
            DeviceModelDataset.First;
          end else begin
            DeviceModelDataset.Locate('tm_pk', DeviceModelDataset.Tag, []);
          end;
        end;
        1: begin //
          if (DeviceReasonDeleteDataset.Tag = -1) then begin
            DeviceReasonDeleteDataset.First;
          end else begin
            DeviceReasonDeleteDataset.Locate('trv_pk', DeviceReasonDeleteDataset.Tag, []);
          end;
        end;
      end;
//    DeviceReasonDeleteGridAdapter.Active := True;
    end;
    5: begin // Users
      if (UserManagerDataset.Tag = -1) then begin
        UserManagerDataset.First;
      end else begin
        UserManagerDataset.Locate('ga_pk', UserManagerDataset.Tag, []);
      end;
    end;
  end;
//FocusRecordTimer.Enabled := True;
end;

procedure TGeneralSettingsForm.DepartmentAddButtonClick(Sender: TObject);
begin
  ShowDepartmentEdit(0);
end;

procedure TGeneralSettingsForm.DepartmentDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if DepartmentDataSet.Active and not(DepartmentDataset.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete department?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteDepartment(DepartmentDataset.FieldByName('da_pk').AsInteger);
    end;
  end;
end;

procedure TGeneralSettingsForm.DeleteDepartment(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  ErrorStatus, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_department_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      ErrorStatus := MyJO.GetJSONValue('error');
      if ErrorStatus = 'True' then begin
        ErrorMessage := MyJO.GetJSONValue('Message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end else begin
        SearchEditTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.DepartmentEditButtonClick(Sender: TObject);
begin
  if DepartmentDataset.Active and not(DepartmentDataset.IsEmpty) then begin
    DepartmentDataset.Tag := DepartmentDataset.FieldByName('da_pk').AsInteger;
    ShowDepartmentEdit(DepartmentDataset.Tag);
  end;
end;

procedure TGeneralSettingsForm.DepartmentGridDblClick(Sender: TObject);
begin
  if DepartmentViewButton.Visible or DepartmentEditButton.Visible then begin
    DepartmentEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.DeviceModelEditButtonClick(Sender: TObject);
begin
  if DeviceModelDataSet.Active and not(DeviceModelDataSet.IsEmpty) then begin
    DeviceModelDataSet.Tag := DeviceModelDataSet.FieldByName('tm_pk').AsInteger;
    ShowDeviceModelEdit(DeviceModelDataSet.Tag);
  end;
end;

procedure TGeneralSettingsForm.DeviceModelGridDblClick(Sender: TObject);
begin
  if DeviceModelViewButton.Visible or DeviceModelEditButton.Visible then begin
    DeviceModelEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.DeviceReasonDeleteAddButtonClick(
  Sender: TObject);
begin
  ShowDeviceReasonDeleteEdit(-1);
end;

procedure TGeneralSettingsForm.DeviceReasonDeleteDeleteButtonClick(
  Sender: TObject);
//var
//  MyModalResult: TModalResult;
begin
{
  if AssetStatusDataSet.Active and not(AssetStatusDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete asset status?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteAssetStatus(AssetStatusDataset.FieldByName('obs_pk').AsInteger);
    end;
  end;
}
end;

procedure TGeneralSettingsForm.DeviceReasonDeleteEditButtonClick(
  Sender: TObject);
begin
  //
end;

procedure TGeneralSettingsForm.DeviceReasonDeleteEditButtonDblClick(
  Sender: TObject);
begin
  //
end;

procedure TGeneralSettingsForm.DeviceReasonDeleteGridDblClick(Sender: TObject);
begin
  if DeviceReasonDeleteViewButton.Visible or DeviceReasonDeleteEditButton.Visible then begin
    DeviceReasonDeleteEditButton.Click();
  end;
end;

procedure TGeneralSettingsForm.DeviceReasonDeleteViewButtonClick(
  Sender: TObject);
begin
  if DeviceReasonDeleteDataSet.Active and not(DeviceReasonDeleteDataSet.IsEmpty) then begin
    DeviceReasonDeleteDataSet.Tag := DeviceReasonDeleteDataSet.FieldByName('trv_pk').AsInteger;
    ShowDeviceReasonDeleteEdit(DeviceReasonDeleteDataSet.Tag);
  end;
end;

procedure TGeneralSettingsForm.FocusRecordTimerTimer(Sender: TObject);
begin
  TTimer(Sender).Enabled := False;
  case TMSFNCPageControl.ActivePageIndex of
    1: begin
      CompanyGrid.Invalidate;
    end;
    2: begin
      DepartmentGrid.Invalidate;
    end;
    3: begin // Asset
      case TMSFNCPageControlAsset.ActivePageIndex of
        0: begin // Status
          AssetStatusGrid.Invalidate;
        end;
        1: begin // Group
          AssetGroupGrid.Invalidate;
        end;
        2: begin // Type
          AssetTypeGrid.Invalidate;
        end;
      end;
    end;
    4: begin // Device
      case TMSFNCPageControlDevice.ActivePageIndex of
        0: begin // Model
          DeviceModelGrid.Invalidate;
        end;
        1: begin // Reason Delete
          DeviceReasonDeleteGrid.Invalidate;
        end;
      end;
    end;
  end;
end;

procedure TGeneralSettingsForm.LoadList(MainPageIndex, SubPageIndex: Integer; URL: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  // Request Data
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL := URL;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;

  // Fill Dataset
  case MainPageIndex of
    0: begin
      if Tag = 0 then begin
        Tag := 1;
        await(UpdateGeneralSettings(string(MyRequest.response)));
        RealTimeMapMaximumDistanceEdit.Text := IntToStr(RealTimeFilterDistanceMax);
        RealtimeMapRefreshAssetCountMax.Text := IntToStr(RealTimeRefreshAssetCountMax);
        ReportServerURLEdit.Text := ReportServerURL;
        AssetIDIsReadOnlyEdit.Checked := AssetIDIsReadOnly;
        if UserManagerAll then begin
          UserManagerYesEdit.Checked := True;
        end else begin
          UserManagerNoEdit.Checked := True;
        end;
        // MachineMax
        MachineMaxEnabledEdit.Checked := MachineMaxEnabled;
        MachineMaxIntervalEdit.Text := IntToStr(MachineMaxInterval);
        MachineMaxFillColorEdit.Color := HexToColor(MachineMaxFillColor);
      end;
    end;
    1: begin
      CompanyDataset.Close;
      CompanyDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
      await(CompanyDataset.Open);
    end;
    2: begin
      DepartmentDataset.Close;
      DepartmentDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
      await(DepartmentDataset.Open);
    end;
    3: begin
      case SubPageIndex of
        0: begin
          AssetStatusDataset.Close;
          AssetStatusDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(AssetStatusDataset.Open);
        end;
        1: begin
          AssetGroupDataset.Close;
          AssetGroupDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(AssetGroupDataset.Open);
        end;
        2: begin
          AssetTypeDataset.Close;
          AssetTypeDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(AssetTypeDataset.Open);
        end;
        3: begin // Brand
          AssetBrandDataset.Close;
          AssetBrandDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(AssetBrandDataset.Open);
        end;
        4: begin // Model
          AssetModelDataset.Close;
          AssetModelDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(AssetModelDataset.Open);
        end;
        5: begin // Repair
          AssetRepairDataSet.Close;
          AssetRepairDataSet.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(AssetRepairDataset.Open);
        end;
      end;
    end;
    4: begin
      case SubPageIndex of
        0: begin
          DeviceModelDataset.Close;
          DeviceModelDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(DeviceModelDataset.Open);
        end;
        1: begin
          DeviceReasonDeleteDataset.Close;
          DeviceReasonDeleteDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
          await(DeviceReasonDeleteDataset.Open);
        end;
      end;
    end;
    5: begin
      UserManagerDataset.Close;
      UserManagerDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
      await(UserManagerDataset.Open);
    end;
  end;
  WebWaitMessage.Hide;
end;

procedure TGeneralSettingsForm.MachineMaxFillColorEditClick(Sender: TObject);
var
  MyWebForm: TColorPickerForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TColorPickerForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  // Load Form
  await(TColorPickerForm, MyWebForm.Load());
  try
    // Current Color
    MyWebForm.SetColor(ColorToHex(MachineMaxFillColorEdit.Color));
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      RecordChanged := True;
      MachineMaxFillColorEdit.Color := HexToColor(MyWebForm.HexValue);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TGeneralSettingsForm.RecordChange(
  Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TGeneralSettingsForm.TMSFNCPageControlAssetBeforeChangePage(
  Sender: TObject; ACurrentPageIndex, ANewPageIndex: Integer;
  var ACanChange: Boolean);
begin
  case ACurrentPageIndex of
    0: begin // Status
      if AssetStatusDataset.Active and not(AssetStatusDataset.IsEmpty) then begin
        AssetStatusDataset.Tag := AssetStatusDataset.FieldByName('obs_pk').AsInteger;
      end else begin
        AssetStatusDataset.Tag := -1;
      end;
    end;
    1: begin // Group
      if AssetGroupDataset.Active and not(AssetGroupDataset.IsEmpty) then begin
        AssetGroupDataset.Tag := AssetGroupDataset.FieldByName('og_pk').AsInteger;
      end else begin
        AssetGroupDataset.Tag := -1;
      end;
    end;
    2: begin // Type
      if AssetTypeDataset.Active and not(AssetTypeDataset.IsEmpty) then begin
        AssetTypeDataset.Tag := AssetTypeDataset.FieldByName('ot_pk').AsInteger;
      end else begin
        AssetTypeDataset.Tag := -1;
      end;
    end;
    3: begin // Brand
      if AssetBrandDataset.Active and not(AssetBrandDataset.IsEmpty) then begin
        AssetBrandDataset.Tag := AssetBrandDataset.FieldByName('PKey').AsInteger;
      end else begin
        AssetBrandDataset.Tag := -1;
      end;
    end;
    4: begin // Model
      if AssetModelDataset.Active and not(AssetModelDataset.IsEmpty) then begin
        AssetModelDataset.Tag := AssetModelDataset.FieldByName('PKey').AsInteger;
      end else begin
        AssetModelDataset.Tag := -1;
      end;
    end;
    5: begin // Repair
      if AssetRepairDataset.Active and not(AssetRepairDataset.IsEmpty) then begin
        AssetRepairDataset.Tag := AssetRepairDataset.FieldByName('PKey').AsInteger;
      end else begin
        AssetRepairDataset.Tag := -1;
      end;
    end;
  end;
end;

procedure TGeneralSettingsForm.TMSFNCPageControlAssetChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  if TTMSFNCPageControl(Sender).Tag = 0 then begin
    TTMSFNCPageControl(Sender).Tag := 1;
  end else begin
    TabChangeTimer.Enabled := True;
  end;
end;

procedure TGeneralSettingsForm.TMSFNCPageControlBeforeChangePage(
  Sender: TObject; ACurrentPageIndex, ANewPageIndex: Integer;
  var ACanChange: Boolean);
begin
  case ACurrentPageIndex of
    1: begin
      if CompanyDataset.Active and not(CompanyDataset.IsEmpty) then begin
        CompanyDataset.Tag := CompanyDataset.FieldByName('of_pk').AsInteger;
      end else begin
        CompanyDataset.Tag := -1;
      end;
    end;
    2: begin
      if DepartmentDataset.Active and not(DepartmentDataset.IsEmpty) then begin
        DepartmentDataset.Tag := DepartmentDataset.FieldByName('da_pk').AsInteger;
      end else begin
        DepartmentDataset.Tag := -1;
      end;
    end;
    3: begin // Asset
      case TMSFNCPageControlAsset.ActivePageIndex of
        0: begin // Status
          if AssetStatusDataset.Active and not(AssetStatusDataset.IsEmpty) then begin
            AssetStatusDataset.Tag := AssetStatusDataset.FieldByName('obs_pk').AsInteger;
          end else begin
            AssetStatusDataset.Tag := -1;
          end;
        end;
        1: begin // Group
          if AssetGroupDataset.Active and not(AssetGroupDataset.IsEmpty) then begin
            AssetGroupDataset.Tag := AssetGroupDataset.FieldByName('og_pk').AsInteger;
          end else begin
            AssetGroupDataset.Tag := -1;
          end;
        end;
        2: begin // Type
          if AssetTypeDataset.Active and not(AssetTypeDataset.IsEmpty) then begin
            AssetTypeDataset.Tag := AssetTypeDataset.FieldByName('ot_pk').AsInteger;
          end else begin
            AssetTypeDataset.Tag := -1;
          end;
        end;
      end;
    end;
    4: begin // Device
      case TMSFNCPageControlDevice.ActivePageIndex of
        0: begin
          if DeviceModelDataset.Active and not(DeviceModelDataset.IsEmpty) then begin
            DeviceModelDataset.Tag := DeviceModelDataset.FieldByName('tm_pk').AsInteger;
          end else begin
            DeviceReasonDeleteDataset.Tag := -1;
          end;
        end;
        1: begin // Reason Delete
          if DeviceReasonDeleteDataset.Active and not(DeviceReasonDeleteDataset.IsEmpty) then begin
            DeviceReasonDeleteDataset.Tag := DeviceReasonDeleteDataset.FieldByName('trv_pk').AsInteger;
          end else begin
            DeviceReasonDeleteDataset.Tag := -1;
          end;
        end;
      end;
    end;
    5: begin // Users
      if UserManagerDataset.Active and not(UserManagerDataset.IsEmpty) then begin
        UserManagerDataset.Tag := UserManagerDataset.FieldByName('ga_pk').AsInteger;
      end else begin
        UserManagerDataset.Tag := -1;
      end;
    end;
  end;
end;

procedure TGeneralSettingsForm.TMSFNCPageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  if TTMSFNCPageControl(Sender).Tag = 0 then begin
    TTMSFNCPageControl(Sender).Tag := 1;
  end else begin
    TabChangeTimer.Enabled := True;
  end;
end;

procedure TGeneralSettingsForm.TMSFNCPageControlDeviceBeforeChangePage(
  Sender: TObject; ACurrentPageIndex, ANewPageIndex: Integer;
  var ACanChange: Boolean);
begin
  case ACurrentPageIndex of
    0: begin // Model
      if DeviceModelDataset.Active and not(DeviceModelDataset.IsEmpty) then begin
        DeviceModelDataset.Tag := DeviceModelDataset.FieldByName('tm_pk').AsInteger;
      end else begin
        DeviceModelDataset.Tag := -1;
      end;
    end;
    1: begin // Reason Delete
      if DeviceReasonDeleteDataSet.Active and not(DeviceReasonDeleteDataSet.IsEmpty) then begin
        DeviceReasonDeleteDataSet.Tag := DeviceReasonDeleteDataSet.FieldByName('trv_pk').AsInteger;
      end else begin
        DeviceReasonDeleteDataSet.Tag := -1;
      end;
    end;
//    2: begin // Type
//      if AssetTypeDataset.Active and not(AssetTypeDataset.IsEmpty) then begin
//        AssetTypeDataset.Tag := AssetTypeDataset.FieldByName('ot_pk').AsInteger;
//      end else begin
//        AssetTypeDataset.Tag := -1;
//      end;
//    end;
  end;

end;

procedure TGeneralSettingsForm.TMSFNCPageControlDeviceChangePage(
  Sender: TObject; APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  if TTMSFNCPageControl(Sender).Tag = 0 then begin
    TTMSFNCPageControl(Sender).Tag := 1;
  end else begin
    TabChangeTimer.Enabled := True;
  end;
end;

procedure TGeneralSettingsForm.UserManagerAddButtonClick(Sender: TObject);
var
  MyWebForm: TManagerListForm;
  MyModalResult: TModalResult;
begin
  // Show Managers
  MyWebForm := TManagerListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TManagerListForm, MyWebForm.Load());
  try
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      AddUserManager(MyWebForm.UserPKey);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TGeneralSettingsForm.UserManagerDeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if UserManagerDataSet.Active and not(UserManagerDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selected record?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteUserManager(UserManagerDataSet.FieldByName('ga_pk').AsString);
    end;
  end;
end;

procedure TGeneralSettingsForm.AddUserManager(PKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_user_manager_add.php?sessionid=' + SessionId +
      '&list=' + PKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        SearchEditTimer.Enabled := True;
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.DeleteUserManager(PKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_user_manager_delete.php?sessionid=' + SessionId +
      '&list=' + PKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        SearchEditTimer.Enabled := True;
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.TabChangeTimerTimer(Sender: TObject);
var
  URL: String;
  MainPageIndex, SubPageIndex: Integer;
begin
  TTimer(Sender).Enabled := False;
  // Default Values
  MainPageIndex := TMSFNCPageControl.ActivePageIndex;
  SubPageIndex := 0;
  // Create URL
  case MainPageIndex of
    0: begin // Main
        URL :=
          url_php + 'default_general_settings_load.php?sessionid=' + SessionId;
    end;
    1: begin // Company
        CompanyEdit.SetFocus();
        URL :=
          url_php + 'default_company_list.php?sessionid=' + SessionId;
        if Length(CompanyEdit.Text) > 0 then begin
          URL := URL + '&q=' + CompanyEdit.Text;
        end;
    end;
    2: begin // Department
        DepartmentEdit.SetFocus();
        URL :=
          url_php + 'default_department_list.php?sessionid=' + SessionId;
        if Length(DepartmentEdit.Text) > 0 then begin
          URL := URL + '&q=' + DepartmentEdit.Text;
        end;
    end;
    3: begin // Asset
        SubPageIndex := TMSFNCPageControlAsset.ActivePageIndex;
        case SubPageIndex of
          0: begin // Status
            AssetStatusEdit.SetFocus();
            URL :=
              url_php + 'default_asset_status_list_nul.php?sessionid=' + SessionId;
            if Length(AssetStatusEdit.Text) > 0 then begin
              URL := URL + '&q=' + AssetStatusEdit.Text;
            end;
          end;
          1: begin // Group
            AssetGroupEdit.SetFocus();
            URL :=
              url_php + 'default_asset_group_list.php?sessionid=' + SessionId;
            if Length(AssetGroupEdit.Text) > 0 then begin
              URL := URL + '&q=' + AssetGroupEdit.Text;
            end;
          end;
          2: begin // Type
            AssetTypeEdit.SetFocus();
            URL :=
              url_php + 'default_asset_type_list.php?sessionid=' + SessionId;
            if Length(AssetTypeEdit.Text) > 0 then begin
              URL := URL + '&q=' + AssetTypeEdit.Text;
            end;
          end;
          3: begin // Brand
            AssetBrandEdit.SetFocus();
            URL :=
              url_php + 'default_brand_list.php?sessionid=' + SessionId;
            if Length(AssetBrandEdit.Text) > 0 then begin
              URL := URL + '&q=' + AssetBrandEdit.Text;
            end;
          end;
          4: begin // Model
            AssetModelEdit.SetFocus();
            URL :=
              url_php + 'default_model_list.php?sessionid=' + SessionId;
            if Length(AssetModelEdit.Text) > 0 then begin
              URL := URL + '&q=' + AssetModelEdit.Text;
            end;
          end;
          5: begin // Repair
            URL :=
              url_php + 'default_asset_repair_list.php?sessionid=' + SessionId;
            if Length(AssetRepairEdit.Text) > 0 then begin
              URL := URL + '&q=' + AssetRepairEdit.Text;
            end;
          end;
        end;
    end;
    4: begin // Device
        SubPageIndex := TMSFNCPageControlDevice.ActivePageIndex;
        case SubPageIndex of
          0: begin
            // Device Reason Delete
            URL :=
              url_php + 'default_device_model_list.php?sessionid=' + SessionId;
            if Length(DeviceModelEdit.Text) > 0 then begin
              URL := URL + '&q=' + DeviceReasonDeleteEdit.Text;
            end;
          end;
          1: begin
            // Device Reason Delete
            DeviceReasonDeleteEdit.SetFocus();
            URL :=
              url_php + 'default_device_reason_delete_list.php?sessionid=' + SessionId;
            if Length(DeviceReasonDeleteEdit.Text) > 0 then begin
              URL := URL + '&q=' + DeviceReasonDeleteEdit.Text;
            end;
          end;
        end;
    end;
    5: begin // User
      URL :=
        url_php + 'default_user_manager_list.php?sessionid=' + SessionId;
    end;
  end;
  LoadList(MainPageIndex, SubPageIndex, URL);
end;

procedure TGeneralSettingsForm.ShowDepartmentEdit(PKey: Integer);
var
  DepartmentEdit: TDepartmentEditForm;
  MyModalResult: TModalResult;
begin
  if
    (DepartmentDataset.Active)
  and
    ((PKey = 0) or ((PKey > 0) and not(DepartmentDataset.IsEmpty))) then
  begin
    DepartmentEdit := TDepartmentEditForm.Create(Self);
    DepartmentEdit.Popup := True;
    DepartmentEdit.Border := fbNone;
    await(TDepartmentEditForm, DepartmentEdit.Load());
    try
      DepartmentEdit.PKey := PKey;
      if Permission in [1,5] then begin
        DepartmentEdit.SetReadOnly;
      end;
      if PKey > 0 then begin
        DepartmentEdit.DepartmentNameEdit.Text :=
          Trim(DepartmentDataset.FieldByName('da_naam').AsString);
      end;
      // excute form and wait for close
      MyModalResult := await(TModalResult, DepartmentEdit.Execute);
      if MyModalResult = mrOK then begin
        DepartmentDataset.Tag := DepartmentEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      DepartmentEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowDeviceModelEdit(PKey: Integer);
var
  DeviceModelEdit: TDeviceModelEditForm;
  MyModalResult: TModalResult;
begin
  if
    (DeviceModelDataset.Active)
  and
    ((PKey = 0) or ((PKey > 0) and not(DeviceModelDataset.IsEmpty))) then
  begin
    DeviceModelEdit := TDeviceModelEditForm.Create(Self);
    DeviceModelEdit.Popup := True;
    DeviceModelEdit.Border := fbNone;
    await(TDeviceModelEditForm, DeviceModelEdit.Load());
    try
      DeviceModelEdit.PKey := PKey;
      if Permission in [1,5] then begin
        DeviceModelEdit.SetReadOnly;
      end;
      // excute form and wait for close
      MyModalResult := await(TModalResult, DeviceModelEdit.Execute);
      if MyModalResult = mrOK then begin
        DeviceModelDataset.Tag := DeviceModelEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      DeviceModelEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowCompanyEdit(PKey: Integer);
var
  CompanyEdit: TCompanyEditForm;
  MyModalResult: TModalResult;
begin
  if
    (CompanyDataset.Active)
  and
    ((PKey = 0) or ((PKey > 0) and not(CompanyDataset.IsEmpty))) then
  begin
    CompanyEdit := TCompanyEditForm.Create(Self);
    CompanyEdit.Popup := True;
    CompanyEdit.Border := fbNone;
    await(TCompanyEditForm, CompanyEdit.Load());
    try
      CompanyEdit.PKey := PKey;
      if Permission in [1,5] then begin
        CompanyEdit.SetReadOnly;
      end;
      if PKey > 0 then begin
        CompanyEdit.CompanyNameEdit.Text :=
          Trim(CompanyDataset.FieldByName('of_naam').AsString);
      end;
      CompanyEdit.IsSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, CompanyEdit.Execute);
      if MyModalResult = mrOK then begin
        CompanyDataset.Tag := CompanyEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      CompanyEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowDeviceReasonDeleteEdit(PKey: Integer);
var
  DeviceReasonDeleteEdit: TDeviceReasonDeleteEditForm;
  MyModalResult: TModalResult;
begin
  if
    (DeviceReasonDeleteDataSet.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(DeviceReasonDeleteDataset.IsEmpty))) then
  begin
    DeviceReasonDeleteEdit := TDeviceReasonDeleteEditForm.Create(Self);
    DeviceReasonDeleteEdit.Popup := True;
    DeviceReasonDeleteEdit.Border := fbNone;
    DeviceReasonDeleteEdit.PKey := PKey;
    await(TDeviceReasonDeleteEditForm, DeviceReasonDeleteEdit.Load());
    try
      if Permission in [0,1,5] then begin
        DeviceReasonDeleteEdit.SetReadOnly;
      end;
      // excute form and wait for close
      MyModalResult := await(TModalResult, DeviceReasonDeleteEdit.Execute);
      if MyModalResult = mrOK then begin
        DeviceReasonDeleteDataSet.Tag := DeviceReasonDeleteEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      DeviceReasonDeleteEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowAssetStatusEdit(PKey: Integer);
var
  AssetStatusEdit: TAssetStatusEditForm;
  MyModalResult: TModalResult;
begin
  if
    (AssetStatusDataset.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(AssetStatusDataset.IsEmpty))) then
  begin
    AssetStatusEdit := TAssetStatusEditForm.Create(Self);
    AssetStatusEdit.Popup := True;
    AssetStatusEdit.Border := fbNone;
    await(TAssetStatusEditForm, AssetStatusEdit.Load());
    try
      AssetStatusEdit.PKey := PKey;
      if Permission in [1,5] then begin
        AssetStatusEdit.SetReadOnly;
      end;
      if PKey >= 0 then begin
        if PKey = 0 then AssetStatusEdit.AssetStatusNameEdit.ReadOnly := True;
        AssetStatusEdit.AssetStatusNameEdit.Text :=
          Trim(AssetStatusDataset.FieldByName('obs_naam').AsString);
        AssetStatusEdit.ActionMailCheckBox.Checked :=
          AssetStatusDataset.FieldByName('obs_mail_checkbox').AsInteger = 1;
        AssetStatusEdit.OwnerCheckBox.Checked :=
          AssetStatusDataset.FieldByName('obs_mail_owner_checkbox').AsInteger = 1;
        AssetStatusEdit.OwnerDepartmentPKey :=
          AssetStatusDataset.FieldByName('obs_mail_owner_department').AsString;
        AssetStatusEdit.OwnerDepartmentEdit.Text :=
          AssetStatusDataset.FieldByName('obs_mail_owner_department_names').AsString;
        AssetStatusEdit.TenantCheckBox.Checked :=
          AssetStatusDataset.FieldByName('obs_mail_tenant_checkbox').AsInteger = 1;
        AssetStatusEdit.TenantDepartmentPKey :=
          AssetStatusDataset.FieldByName('obs_mail_tenant_department').AsString;
        AssetStatusEdit.TenantDepartmentEdit.Text :=
          AssetStatusDataset.FieldByName('obs_mail_tenant_department_names').AsString;
        AssetStatusEdit.TeamLeaderCheckBox.Checked :=
          AssetStatusDataset.FieldByName('obs_mail_teamleader_checkbox').AsInteger = 1;
        AssetStatusEdit.DriverCheckBox.Checked :=
          AssetStatusDataset.FieldByName('obs_mail_driver_checkbox').AsInteger = 1;
        AssetStatusEdit.StatusTextMemo.Text :=
          AssetStatusDataset.FieldByName('obs_text').AsString;
        AssetStatusEdit.ColorPanel.Color :=
          HexToColor(AssetStatusDataset.FieldByName('obs_hexcolor').AsString);
      end;
      AssetStatusEdit.IsSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, AssetStatusEdit.Execute);
      if MyModalResult = mrOK then begin
        AssetStatusDataset.Tag := AssetStatusEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      AssetStatusEdit.Free;
    end;
  end;
end;


procedure TGeneralSettingsForm.ShowAssetGroupEdit(PKey: Integer);
var
  AssetGroupEdit: TAssetGroupEditForm;
  MyModalResult: TModalResult;
begin
  if
    (AssetGroupDataset.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(AssetGroupDataset.IsEmpty))) then
  begin
    AssetGroupEdit := TAssetGroupEditForm.Create(Self);
    AssetGroupEdit.Popup := True;
    AssetGroupEdit.Border := fbNone;
    AssetGroupEdit.PKey := PKey;
    await(TAssetGroupEditForm, AssetGroupEdit.Load());
    try
      if Permission in [1,5] then begin
        AssetGroupEdit.SetReadOnly;
      end;
      AssetGroupEdit.IsSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, AssetGroupEdit.Execute);
      if MyModalResult = mrOK then begin
        AssetGroupDataset.Tag := AssetGroupEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      AssetGroupEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowAssetTypeEdit(PKey: Integer);
var
  AssetTypeEdit: TAssetTypeEditForm;
  MyModalResult: TModalResult;
begin
  if
    (AssetTypeDataset.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(AssetTypeDataset.IsEmpty))) then
  begin
    AssetTypeEdit := TAssetTypeEditForm.Create(Self);
    AssetTypeEdit.Popup := True;
    AssetTypeEdit.Border := fbNone;
    AssetTypeEdit.PKey := PKey;
    await(TAssetTypeEditForm, AssetTypeEdit.Load());
    try
      AssetTypeEdit.SetPermission(Permission);
      MyModalResult := await(TModalResult, AssetTypeEdit.Execute);
      if MyModalResult = mrOK then begin
        AssetTypeDataset.Tag := AssetTypeEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      AssetTypeEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.DeleteAssetGroup(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  Status, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_group_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      Status := MyJO.GetJSONValue('status');
      if Status = 'OK' then begin
        SearchEditTimer.Enabled := True;
      end else begin
        ErrorMessage := MyJO.GetJSONValue('error_message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.DeleteAssetType(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  Status, ErrorMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_type_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      Status := MyJO.GetJSONValue('status');
      if Status = 'OK' then begin
        SearchEditTimer.Enabled := True;
      end else begin
        ErrorMessage := MyJO.GetJSONValue('error_message');
        MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.ShowAssetBrandEdit(PKey: Integer);
var
  AssetBrandEdit: TBrandEditForm;
  MyModalResult: TModalResult;
begin
  if
    (AssetBrandDataset.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(AssetBrandDataset.IsEmpty))) then
  begin
    AssetBrandEdit := TBrandEditForm.Create(Self);
    AssetBrandEdit.Popup := True;
    AssetBrandEdit.Border := fbNone;
    AssetBrandEdit.PKey := PKey;
    await(TBrandEditForm, AssetBrandEdit.Load());
    try
//    AssetBrandEdit.SetPermission(Permission);
      AssetBrandEdit.PKey := PKey;
      AssetBrandEdit.IsSet := True;
      MyModalResult := await(TModalResult, AssetBrandEdit.Execute);
      if MyModalResult = mrOK then begin
        AssetBrandDataset.Tag := AssetBrandEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      AssetBrandEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowAssetModelEdit(PKey: Integer);
var
  AssetModelEdit: TModelEditForm;
  MyModalResult: TModalResult;
begin
  if
    (AssetModelDataset.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(AssetModelDataset.IsEmpty))) then
  begin
    AssetModelEdit := TModelEditForm.Create(Self);
    AssetModelEdit.Popup := True;
    AssetModelEdit.Border := fbNone;
    AssetModelEdit.PKey := PKey;
    await(TModelEditForm, AssetModelEdit.Load());
    try
//    AssetModelEdit.SetPermission(Permission);
      AssetModelEdit.PKey := PKey;
      AssetModelEdit.IsSet := True;
      MyModalResult := await(TModalResult, AssetModelEdit.Execute);
      if MyModalResult = mrOK then begin
        AssetModelDataset.Tag := AssetModelEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      AssetModelEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.ShowAssetRepairEdit(PKey: Integer);
var
  AssetRepairTextEdit: TAssetRepairTextEditForm;
  MyModalResult: TModalResult;
begin
  if
    (AssetRepairDataset.Active)
  and
    ((PKey = -1) or ((PKey > -1) and not(AssetRepairDataset.IsEmpty))) then
  begin
    AssetRepairTextEdit := TAssetRepairTextEditForm.Create(Self);
    AssetRepairTextEdit.Popup := True;
    AssetRepairTextEdit.Border := fbNone;
    AssetRepairTextEdit.PKey := PKey;
    await(TAssetRepairTextEditForm, AssetRepairTextEdit.Load());
    try
      MyModalResult := await(TModalResult, AssetRepairTextEdit.Execute);
      if MyModalResult = mrOK then begin
        AssetRepairDataset.Tag := AssetRepairTextEdit.PKey;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      AssetRepairTextEdit.Free;
    end;
  end;
end;

procedure TGeneralSettingsForm.DeleteAssetBrand(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_brand_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        AssetBrandDataset.Tag := -1;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.DeleteAssetModel(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_model_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        AssetModelDataset.Tag := -1;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TGeneralSettingsForm.DeleteAssetRepair(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_repair_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        AssetModelDataset.Tag := -1;
        SearchEditTimer.Interval := 50;
        SearchEditTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;


procedure TGeneralSettingsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  TMSFNCPageControl := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControlPage1 := TTMSFNCPageControlContainer.Create(Self);
  DepartmentFilterPanel := TTMSFNCPanel.Create(Self);
  DepartmentEdit := TEdit.Create(Self);
  DepartmentButtonPanel := TTMSFNCPanel.Create(Self);
  DepartmentAddButton := TSpeedButton.Create(Self);
  DepartmentEditButton := TSpeedButton.Create(Self);
  DepartmentDeleteButton := TSpeedButton.Create(Self);
  DepartmentCloseButton := TSpeedButton.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  DepartmentViewButton := TSpeedButton.Create(Self);
  DepartmentGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControlPage0 := TTMSFNCPageControlContainer.Create(Self);
  CompanyFilterPanel := TTMSFNCPanel.Create(Self);
  CompanyEdit := TEdit.Create(Self);
  CompanyButtonPanel := TTMSFNCPanel.Create(Self);
  CompanyAddButton := TSpeedButton.Create(Self);
  CompanyEditButton := TSpeedButton.Create(Self);
  CompanyDeleteButton := TSpeedButton.Create(Self);
  CompanyCloseButton := TSpeedButton.Create(Self);
  CompanyViewButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  CompanyGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControlPage4 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControlDevice := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControlContainer1 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControlContainer2 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel4 := TTMSFNCPanel.Create(Self);
  DeviceReasonDeleteEdit := TEdit.Create(Self);
  DeviceReasonDeleteGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel5 := TTMSFNCPanel.Create(Self);
  DeviceReasonDeleteAddButton := TSpeedButton.Create(Self);
  DeviceReasonDeleteEditButton := TSpeedButton.Create(Self);
  DeviceReasonDeleteDeleteButton := TSpeedButton.Create(Self);
  WebSpeedButton4 := TSpeedButton.Create(Self);
  TMSFNCPanel6 := TTMSFNCPanel.Create(Self);
  DeviceReasonDeleteViewButton := TSpeedButton.Create(Self);
  TMSFNCPageControlContainer3 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel7 := TTMSFNCPanel.Create(Self);
  DeviceModelEdit := TEdit.Create(Self);
  TMSFNCPanel8 := TTMSFNCPanel.Create(Self);
  DeviceModelEditButton := TSpeedButton.Create(Self);
  WebSpeedButton6 := TSpeedButton.Create(Self);
  TMSFNCPanel10 := TTMSFNCPanel.Create(Self);
  DeviceModelViewButton := TSpeedButton.Create(Self);
  DeviceModelGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControlPage2 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControlAsset := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  AssetTypeFilterPanel := TTMSFNCPanel.Create(Self);
  AssetTypeEdit := TEdit.Create(Self);
  AssetTypeButtonPanel := TTMSFNCPanel.Create(Self);
  AssetTypeAddButton := TSpeedButton.Create(Self);
  AssetTypeEditButton := TSpeedButton.Create(Self);
  AssetTypeDeleteButton := TSpeedButton.Create(Self);
  WebSpeedButton10 := TSpeedButton.Create(Self);
  TMSFNCPanel12 := TTMSFNCPanel.Create(Self);
  AssetTypeViewButton := TSpeedButton.Create(Self);
  AssetTypeGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  AssetGroupFilterPanel := TTMSFNCPanel.Create(Self);
  AssetGroupEdit := TEdit.Create(Self);
  AssetGroupButtonPanel := TTMSFNCPanel.Create(Self);
  AssetGroupAddButton := TSpeedButton.Create(Self);
  AssetGroupEditButton := TSpeedButton.Create(Self);
  AssetGroupDeleteButton := TSpeedButton.Create(Self);
  WebSpeedButton5 := TSpeedButton.Create(Self);
  TMSFNCPanel9 := TTMSFNCPanel.Create(Self);
  AssetGroupViewButton := TSpeedButton.Create(Self);
  AssetGroupGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControlAssetPage3 := TTMSFNCPageControlContainer.Create(Self);
  AssetBrandFilterPanel := TTMSFNCPanel.Create(Self);
  AssetBrandEdit := TEdit.Create(Self);
  AssetBrandGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel11 := TTMSFNCPanel.Create(Self);
  AssetBrandAddButton := TSpeedButton.Create(Self);
  AssetBrandEditButton := TSpeedButton.Create(Self);
  AssetBrandDeleteButton := TSpeedButton.Create(Self);
  WebSpeedButton7 := TSpeedButton.Create(Self);
  TMSFNCPanel13 := TTMSFNCPanel.Create(Self);
  AssetBrandViewButton := TSpeedButton.Create(Self);
  TMSFNCPageControlAssetPage4 := TTMSFNCPageControlContainer.Create(Self);
  AssetModelFilterPanel := TTMSFNCPanel.Create(Self);
  AssetModelEdit := TEdit.Create(Self);
  AssetModelGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel14 := TTMSFNCPanel.Create(Self);
  AssetModelAddButton := TSpeedButton.Create(Self);
  AssetModelEditButton := TSpeedButton.Create(Self);
  AssetModelDeleteButton := TSpeedButton.Create(Self);
  WebSpeedButton13 := TSpeedButton.Create(Self);
  TMSFNCPanel15 := TTMSFNCPanel.Create(Self);
  AssetModelViewButton := TSpeedButton.Create(Self);
  TMSFNCPageControlAssetPage5 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel16 := TTMSFNCPanel.Create(Self);
  AssetRepairEdit := TEdit.Create(Self);
  AssetRepairGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel17 := TTMSFNCPanel.Create(Self);
  AssetRepairAddButton := TSpeedButton.Create(Self);
  AssetRepairEditButton := TSpeedButton.Create(Self);
  AssetRepairDeleteButton := TSpeedButton.Create(Self);
  WebSpeedButton8 := TSpeedButton.Create(Self);
  TMSFNCPanel18 := TTMSFNCPanel.Create(Self);
  AssetRepairViewButton := TSpeedButton.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  AssetStatusFilterPanel := TTMSFNCPanel.Create(Self);
  AssetStatusEdit := TEdit.Create(Self);
  AssetStatusButtonPanel := TTMSFNCPanel.Create(Self);
  AssetStatusAddButton := TSpeedButton.Create(Self);
  AssetStatusEditButton := TSpeedButton.Create(Self);
  AssetStatusDeleteButton := TSpeedButton.Create(Self);
  AssetStatusCloseButton := TSpeedButton.Create(Self);
  TMSFNCPanel3 := TTMSFNCPanel.Create(Self);
  AssetStatusViewButton := TSpeedButton.Create(Self);
  AssetStatusGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControlPage3 := TTMSFNCPageControlContainer.Create(Self);
  RealTimeMapMaximumDistanceLabel := TLabel.Create(Self);
  ReportServerURLLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  RealTimeMapMaximumDistanceEdit := TEdit.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  SettingsGeneralCloseButton := TSpeedButton.Create(Self);
  ReportServerURLEdit := TEdit.Create(Self);
  AssetIDIsReadOnlyEdit := TCheckBox.Create(Self);
  MachineMaxEnabledEdit := TCheckBox.Create(Self);
  MachineMaxIntervalEdit := TEdit.Create(Self);
  MachineMaxFillColorEdit := TTMSFNCPanel.Create(Self);
  RealtimeMapRefreshAssetCountMax := TEdit.Create(Self);
  TMSFNCPageControlPage5 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel19 := TTMSFNCPanel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  UserManagerYesEdit := TRadioButton.Create(Self);
  UserManagerNoEdit := TRadioButton.Create(Self);
  TMSFNCPanel20 := TTMSFNCPanel.Create(Self);
  UserManagerAddButton := TSpeedButton.Create(Self);
  UserManagerDeleteButton := TSpeedButton.Create(Self);
  TMSFNCPanel21 := TTMSFNCPanel.Create(Self);
  UserManagerGrid := TTMSFNCGrid.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  CompanyGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DepartmentGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  AssetStatusGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DeviceReasonDeleteGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  AssetGroupGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  AssetTypeGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DeviceModelGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  AssetBrandGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  AssetModelGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  AssetRepairGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  UserManagerGridAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  SearchEditTimer := TTimer.Create(Self);
  FocusRecordTimer := TTimer.Create(Self);
  CompanyDataset := TClientDataSet.Create(Self);
  CompanyDatasetof_pk := TStringField.Create(Self);
  CompanyDatasetof_naam := TStringField.Create(Self);
  CompanySource := TDataSource.Create(Self);
  TabChangeTimer := TTimer.Create(Self);
  WebConnection := TClientConnection.Create(Self);
  DepartmentDataSet := TClientDataSet.Create(Self);
  DepartmentDataSetda_pk := TStringField.Create(Self);
  DepartmentDataSetda_naam := TStringField.Create(Self);
  DepartmentSource := TDataSource.Create(Self);
  AssetStatusDataSet := TClientDataSet.Create(Self);
  AssetStatusDataSetobs_pk := TStringField.Create(Self);
  AssetStatusDataSetobs_naam := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_checkbox := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_owner_checkbox := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_owner_department := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_owner_department_names := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_tenant_checkbox := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_tenant_department := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_tenant_department_names := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_teamleader_checkbox := TStringField.Create(Self);
  AssetStatusDataSetobs_mail_driver_checkbox := TStringField.Create(Self);
  AssetStatusDataSetobs_text := TStringField.Create(Self);
  AssetStatusDataSetobs_hexcolor := TStringField.Create(Self);
  AssetStatusSource := TDataSource.Create(Self);
  DeviceReasonDeleteDataSet := TClientDataSet.Create(Self);
  DeviceReasonDeleteDataSettrv_pk := TStringField.Create(Self);
  DeviceReasonDeleteDataSettrv_naam := TStringField.Create(Self);
  DeviceReasonDeleteSource := TDataSource.Create(Self);
  AssetGroupDataSet := TClientDataSet.Create(Self);
  AssetGroupDataSetog_pk := TStringField.Create(Self);
  AssetGroupDataSetog_naam := TStringField.Create(Self);
  AssetGroupSource := TDataSource.Create(Self);
  AssetTypeDataSet := TClientDataSet.Create(Self);
  AssetTypeDataSetot_pk := TStringField.Create(Self);
  AssetTypeDataSetot_naam := TStringField.Create(Self);
  AssetTypeSource := TDataSource.Create(Self);
  DeviceModelDataSet := TClientDataSet.Create(Self);
  DeviceModelDataSettm_pk := TStringField.Create(Self);
  DeviceModelDataSettm_id := TStringField.Create(Self);
  DeviceModelSource := TDataSource.Create(Self);
  AssetBrandSource := TDataSource.Create(Self);
  AssetModelSource := TDataSource.Create(Self);
  AssetBrandDataset := TClientDataSet.Create(Self);
  AssetBrandDatasetPKey := TStringField.Create(Self);
  AssetBrandDatasetID := TStringField.Create(Self);
  AssetModelDataset := TClientDataSet.Create(Self);
  AssetModelDatasetPKey := TStringField.Create(Self);
  AssetModelDatasetID := TStringField.Create(Self);
  AssetModelDatasetBrandPKey := TStringField.Create(Self);
  AssetModelDatasetBrandID := TStringField.Create(Self);
  AssetRepairDataSet := TClientDataSet.Create(Self);
  StringField1 := TStringField.Create(Self);
  StringField2 := TStringField.Create(Self);
  AssetRepairDataSetType := TStringField.Create(Self);
  AssetRepairDataSetBrand := TStringField.Create(Self);
  AssetRepairDataSetModel := TStringField.Create(Self);
  AssetRepairSource := TDataSource.Create(Self);
  UserManagerDataset := TClientDataSet.Create(Self);
  UserManagerDatasetga_pk := TStringField.Create(Self);
  UserManagerDatasetga_name := TStringField.Create(Self);
  UserManagerDataSource := TDataSource.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  TMSFNCPageControl.BeforeLoadDFMValues;
  TMSFNCPageControlPage1.BeforeLoadDFMValues;
  DepartmentFilterPanel.BeforeLoadDFMValues;
  DepartmentEdit.BeforeLoadDFMValues;
  DepartmentButtonPanel.BeforeLoadDFMValues;
  DepartmentAddButton.BeforeLoadDFMValues;
  DepartmentEditButton.BeforeLoadDFMValues;
  DepartmentDeleteButton.BeforeLoadDFMValues;
  DepartmentCloseButton.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  DepartmentViewButton.BeforeLoadDFMValues;
  DepartmentGrid.BeforeLoadDFMValues;
  TMSFNCPageControlPage0.BeforeLoadDFMValues;
  CompanyFilterPanel.BeforeLoadDFMValues;
  CompanyEdit.BeforeLoadDFMValues;
  CompanyButtonPanel.BeforeLoadDFMValues;
  CompanyAddButton.BeforeLoadDFMValues;
  CompanyEditButton.BeforeLoadDFMValues;
  CompanyDeleteButton.BeforeLoadDFMValues;
  CompanyCloseButton.BeforeLoadDFMValues;
  CompanyViewButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  CompanyGrid.BeforeLoadDFMValues;
  TMSFNCPageControlPage4.BeforeLoadDFMValues;
  TMSFNCPageControlDevice.BeforeLoadDFMValues;
  TMSFNCPageControlContainer1.BeforeLoadDFMValues;
  TMSFNCPageControlContainer2.BeforeLoadDFMValues;
  TMSFNCPanel4.BeforeLoadDFMValues;
  DeviceReasonDeleteEdit.BeforeLoadDFMValues;
  DeviceReasonDeleteGrid.BeforeLoadDFMValues;
  TMSFNCPanel5.BeforeLoadDFMValues;
  DeviceReasonDeleteAddButton.BeforeLoadDFMValues;
  DeviceReasonDeleteEditButton.BeforeLoadDFMValues;
  DeviceReasonDeleteDeleteButton.BeforeLoadDFMValues;
  WebSpeedButton4.BeforeLoadDFMValues;
  TMSFNCPanel6.BeforeLoadDFMValues;
  DeviceReasonDeleteViewButton.BeforeLoadDFMValues;
  TMSFNCPageControlContainer3.BeforeLoadDFMValues;
  TMSFNCPanel7.BeforeLoadDFMValues;
  DeviceModelEdit.BeforeLoadDFMValues;
  TMSFNCPanel8.BeforeLoadDFMValues;
  DeviceModelEditButton.BeforeLoadDFMValues;
  WebSpeedButton6.BeforeLoadDFMValues;
  TMSFNCPanel10.BeforeLoadDFMValues;
  DeviceModelViewButton.BeforeLoadDFMValues;
  DeviceModelGrid.BeforeLoadDFMValues;
  TMSFNCPageControlPage2.BeforeLoadDFMValues;
  TMSFNCPageControlAsset.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  AssetTypeFilterPanel.BeforeLoadDFMValues;
  AssetTypeEdit.BeforeLoadDFMValues;
  AssetTypeButtonPanel.BeforeLoadDFMValues;
  AssetTypeAddButton.BeforeLoadDFMValues;
  AssetTypeEditButton.BeforeLoadDFMValues;
  AssetTypeDeleteButton.BeforeLoadDFMValues;
  WebSpeedButton10.BeforeLoadDFMValues;
  TMSFNCPanel12.BeforeLoadDFMValues;
  AssetTypeViewButton.BeforeLoadDFMValues;
  AssetTypeGrid.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  AssetGroupFilterPanel.BeforeLoadDFMValues;
  AssetGroupEdit.BeforeLoadDFMValues;
  AssetGroupButtonPanel.BeforeLoadDFMValues;
  AssetGroupAddButton.BeforeLoadDFMValues;
  AssetGroupEditButton.BeforeLoadDFMValues;
  AssetGroupDeleteButton.BeforeLoadDFMValues;
  WebSpeedButton5.BeforeLoadDFMValues;
  TMSFNCPanel9.BeforeLoadDFMValues;
  AssetGroupViewButton.BeforeLoadDFMValues;
  AssetGroupGrid.BeforeLoadDFMValues;
  TMSFNCPageControlAssetPage3.BeforeLoadDFMValues;
  AssetBrandFilterPanel.BeforeLoadDFMValues;
  AssetBrandEdit.BeforeLoadDFMValues;
  AssetBrandGrid.BeforeLoadDFMValues;
  TMSFNCPanel11.BeforeLoadDFMValues;
  AssetBrandAddButton.BeforeLoadDFMValues;
  AssetBrandEditButton.BeforeLoadDFMValues;
  AssetBrandDeleteButton.BeforeLoadDFMValues;
  WebSpeedButton7.BeforeLoadDFMValues;
  TMSFNCPanel13.BeforeLoadDFMValues;
  AssetBrandViewButton.BeforeLoadDFMValues;
  TMSFNCPageControlAssetPage4.BeforeLoadDFMValues;
  AssetModelFilterPanel.BeforeLoadDFMValues;
  AssetModelEdit.BeforeLoadDFMValues;
  AssetModelGrid.BeforeLoadDFMValues;
  TMSFNCPanel14.BeforeLoadDFMValues;
  AssetModelAddButton.BeforeLoadDFMValues;
  AssetModelEditButton.BeforeLoadDFMValues;
  AssetModelDeleteButton.BeforeLoadDFMValues;
  WebSpeedButton13.BeforeLoadDFMValues;
  TMSFNCPanel15.BeforeLoadDFMValues;
  AssetModelViewButton.BeforeLoadDFMValues;
  TMSFNCPageControlAssetPage5.BeforeLoadDFMValues;
  TMSFNCPanel16.BeforeLoadDFMValues;
  AssetRepairEdit.BeforeLoadDFMValues;
  AssetRepairGrid.BeforeLoadDFMValues;
  TMSFNCPanel17.BeforeLoadDFMValues;
  AssetRepairAddButton.BeforeLoadDFMValues;
  AssetRepairEditButton.BeforeLoadDFMValues;
  AssetRepairDeleteButton.BeforeLoadDFMValues;
  WebSpeedButton8.BeforeLoadDFMValues;
  TMSFNCPanel18.BeforeLoadDFMValues;
  AssetRepairViewButton.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  AssetStatusFilterPanel.BeforeLoadDFMValues;
  AssetStatusEdit.BeforeLoadDFMValues;
  AssetStatusButtonPanel.BeforeLoadDFMValues;
  AssetStatusAddButton.BeforeLoadDFMValues;
  AssetStatusEditButton.BeforeLoadDFMValues;
  AssetStatusDeleteButton.BeforeLoadDFMValues;
  AssetStatusCloseButton.BeforeLoadDFMValues;
  TMSFNCPanel3.BeforeLoadDFMValues;
  AssetStatusViewButton.BeforeLoadDFMValues;
  AssetStatusGrid.BeforeLoadDFMValues;
  TMSFNCPageControlPage3.BeforeLoadDFMValues;
  RealTimeMapMaximumDistanceLabel.BeforeLoadDFMValues;
  ReportServerURLLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  RealTimeMapMaximumDistanceEdit.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  SettingsGeneralCloseButton.BeforeLoadDFMValues;
  ReportServerURLEdit.BeforeLoadDFMValues;
  AssetIDIsReadOnlyEdit.BeforeLoadDFMValues;
  MachineMaxEnabledEdit.BeforeLoadDFMValues;
  MachineMaxIntervalEdit.BeforeLoadDFMValues;
  MachineMaxFillColorEdit.BeforeLoadDFMValues;
  RealtimeMapRefreshAssetCountMax.BeforeLoadDFMValues;
  TMSFNCPageControlPage5.BeforeLoadDFMValues;
  TMSFNCPanel19.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  UserManagerYesEdit.BeforeLoadDFMValues;
  UserManagerNoEdit.BeforeLoadDFMValues;
  TMSFNCPanel20.BeforeLoadDFMValues;
  UserManagerAddButton.BeforeLoadDFMValues;
  UserManagerDeleteButton.BeforeLoadDFMValues;
  TMSFNCPanel21.BeforeLoadDFMValues;
  UserManagerGrid.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  CompanyGridAdapter.BeforeLoadDFMValues;
  DepartmentGridAdapter.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  AssetStatusGridAdapter.BeforeLoadDFMValues;
  DeviceReasonDeleteGridAdapter.BeforeLoadDFMValues;
  AssetGroupGridAdapter.BeforeLoadDFMValues;
  AssetTypeGridAdapter.BeforeLoadDFMValues;
  DeviceModelGridAdapter.BeforeLoadDFMValues;
  AssetBrandGridAdapter.BeforeLoadDFMValues;
  AssetModelGridAdapter.BeforeLoadDFMValues;
  AssetRepairGridAdapter.BeforeLoadDFMValues;
  UserManagerGridAdapter.BeforeLoadDFMValues;
  SearchEditTimer.BeforeLoadDFMValues;
  FocusRecordTimer.BeforeLoadDFMValues;
  CompanyDataset.BeforeLoadDFMValues;
  CompanyDatasetof_pk.BeforeLoadDFMValues;
  CompanyDatasetof_naam.BeforeLoadDFMValues;
  CompanySource.BeforeLoadDFMValues;
  TabChangeTimer.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  DepartmentDataSet.BeforeLoadDFMValues;
  DepartmentDataSetda_pk.BeforeLoadDFMValues;
  DepartmentDataSetda_naam.BeforeLoadDFMValues;
  DepartmentSource.BeforeLoadDFMValues;
  AssetStatusDataSet.BeforeLoadDFMValues;
  AssetStatusDataSetobs_pk.BeforeLoadDFMValues;
  AssetStatusDataSetobs_naam.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_checkbox.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_owner_checkbox.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_owner_department.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_owner_department_names.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_tenant_checkbox.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_tenant_department.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_tenant_department_names.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_teamleader_checkbox.BeforeLoadDFMValues;
  AssetStatusDataSetobs_mail_driver_checkbox.BeforeLoadDFMValues;
  AssetStatusDataSetobs_text.BeforeLoadDFMValues;
  AssetStatusDataSetobs_hexcolor.BeforeLoadDFMValues;
  AssetStatusSource.BeforeLoadDFMValues;
  DeviceReasonDeleteDataSet.BeforeLoadDFMValues;
  DeviceReasonDeleteDataSettrv_pk.BeforeLoadDFMValues;
  DeviceReasonDeleteDataSettrv_naam.BeforeLoadDFMValues;
  DeviceReasonDeleteSource.BeforeLoadDFMValues;
  AssetGroupDataSet.BeforeLoadDFMValues;
  AssetGroupDataSetog_pk.BeforeLoadDFMValues;
  AssetGroupDataSetog_naam.BeforeLoadDFMValues;
  AssetGroupSource.BeforeLoadDFMValues;
  AssetTypeDataSet.BeforeLoadDFMValues;
  AssetTypeDataSetot_pk.BeforeLoadDFMValues;
  AssetTypeDataSetot_naam.BeforeLoadDFMValues;
  AssetTypeSource.BeforeLoadDFMValues;
  DeviceModelDataSet.BeforeLoadDFMValues;
  DeviceModelDataSettm_pk.BeforeLoadDFMValues;
  DeviceModelDataSettm_id.BeforeLoadDFMValues;
  DeviceModelSource.BeforeLoadDFMValues;
  AssetBrandSource.BeforeLoadDFMValues;
  AssetModelSource.BeforeLoadDFMValues;
  AssetBrandDataset.BeforeLoadDFMValues;
  AssetBrandDatasetPKey.BeforeLoadDFMValues;
  AssetBrandDatasetID.BeforeLoadDFMValues;
  AssetModelDataset.BeforeLoadDFMValues;
  AssetModelDatasetPKey.BeforeLoadDFMValues;
  AssetModelDatasetID.BeforeLoadDFMValues;
  AssetModelDatasetBrandPKey.BeforeLoadDFMValues;
  AssetModelDatasetBrandID.BeforeLoadDFMValues;
  AssetRepairDataSet.BeforeLoadDFMValues;
  StringField1.BeforeLoadDFMValues;
  StringField2.BeforeLoadDFMValues;
  AssetRepairDataSetType.BeforeLoadDFMValues;
  AssetRepairDataSetBrand.BeforeLoadDFMValues;
  AssetRepairDataSetModel.BeforeLoadDFMValues;
  AssetRepairSource.BeforeLoadDFMValues;
  UserManagerDataset.BeforeLoadDFMValues;
  UserManagerDatasetga_pk.BeforeLoadDFMValues;
  UserManagerDatasetga_name.BeforeLoadDFMValues;
  UserManagerDataSource.BeforeLoadDFMValues;
  try
    Name := 'GeneralSettingsForm';
    Width := 1099;
    Height := 675;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1099;
    FormPanel.Height := 675;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1093;
    ContentPanel.Height := 632;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    TMSFNCPageControl.SetParentComponent(ContentPanel);
    TMSFNCPageControl.Name := 'TMSFNCPageControl';
    TMSFNCPageControl.AlignWithMargins := True;
    TMSFNCPageControl.Left := 10;
    TMSFNCPageControl.Top := 0;
    TMSFNCPageControl.Width := 1073;
    TMSFNCPageControl.Height := 624;
    TMSFNCPageControl.Margins.Left := 10;
    TMSFNCPageControl.Margins.Top := 0;
    TMSFNCPageControl.Margins.Right := 10;
    TMSFNCPageControl.Margins.Bottom := 8;
    TMSFNCPageControl.Align := alClient;
    TMSFNCPageControl.BevelEdges := [];
    TMSFNCPageControl.BevelInner := bvNone;
    TMSFNCPageControl.BevelOuter := bvNone;
    TMSFNCPageControl.ParentDoubleBuffered := False;
    TMSFNCPageControl.Color := clBtnFace;
    TMSFNCPageControl.DoubleBuffered := True;
    TMSFNCPageControl.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl.Font.Color := clWindowText;
    TMSFNCPageControl.Font.Height := -16;
    TMSFNCPageControl.Font.Name := 'Tahoma';
    TMSFNCPageControl.Font.Style := [];
    TMSFNCPageControl.ParentFont := False;
    TMSFNCPageControl.TabOrder := 0;
    TMSFNCPageControl.ActivePageIndex := 5;
    TMSFNCPageControl.Fill.Kind := gfkNone;
    TMSFNCPageControl.Fill.ColorTo := clWhite;
    TMSFNCPageControl.Stroke.Kind := gskNone;
    TMSFNCPageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    TMSFNCPageControl.TabAppearance.Font.Color := clAqua;
    TMSFNCPageControl.TabAppearance.Font.Height := -16;
    TMSFNCPageControl.TabAppearance.Font.Name := 'Segoe UI';
    TMSFNCPageControl.TabAppearance.Font.Style := [];
    TMSFNCPageControl.TabAppearance.Fill.Color := 14408667;
    TMSFNCPageControl.TabAppearance.Stroke.Color := -1;
    TMSFNCPageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    TMSFNCPageControl.TabAppearance.HoverFill.Color := 15658734;
    TMSFNCPageControl.TabAppearance.HoverStroke.Color := -1;
    TMSFNCPageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    TMSFNCPageControl.TabAppearance.Shape := tsRectangle;
    TMSFNCPageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    TMSFNCPageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    TMSFNCPageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    TMSFNCPageControl.TabAppearance.TextColor := clGrayText;
    TMSFNCPageControl.TabAppearance.ActiveTextColor := clGrayText;
    TMSFNCPageControl.TabAppearance.HoverTextColor := clGrayText;
    TMSFNCPageControl.TabAppearance.ShowFocus := False;
    TMSFNCPageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    TMSFNCPageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    TMSFNCPageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    TMSFNCPageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl.TabAppearance.BadgeFont.Color := 139;
    TMSFNCPageControl.TabAppearance.BadgeFont.Height := -11;
    TMSFNCPageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    TMSFNCPageControl.TabAppearance.BadgeFont.Style := [fsBold];
    TMSFNCPageControl.ButtonAppearance.CloseIcon.LoadFromFile('uGeneralSettings.ContentPanel.TMSFNCPageControl.ButtonAppearance.CloseIcon.svg');
    TMSFNCPageControl.ButtonAppearance.InsertIcon.LoadFromFile('uGeneralSettings.ContentPanel.TMSFNCPageControl.ButtonAppearance.InsertIcon.svg');
    TMSFNCPageControl.ButtonAppearance.TabListIcon.LoadFromFile('uGeneralSettings.ContentPanel.TMSFNCPageControl.ButtonAppearance.TabListIcon.svg');
    TMSFNCPageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uGeneralSettings.ContentPanel.TMSFNCPageControl.ButtonAppearance.ScrollNextIcon.svg');
    TMSFNCPageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uGeneralSettings.ContentPanel.TMSFNCPageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    TMSFNCPageControl.TabSize.Height := 45.000000000000000000;
    TMSFNCPageControl.TabSize.Margins.Left := 8.000000000000000000;
    TMSFNCPageControl.TabSize.Margins.Top := 8.000000000000000000;
    TMSFNCPageControl.TabSize.Margins.Right := 8.000000000000000000;
    TMSFNCPageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    TMSFNCPageControl.Pages.Clear;
    with TMSFNCPageControl.Pages.Add do
    begin
      Text := 'Main';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl.Pages.Add do
    begin
      Text := 'Companies';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl.Pages.Add do
    begin
      Text := 'Departments';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl.Pages.Add do
    begin
      Text := 'Assets';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl.Pages.Add do
    begin
      Text := 'Devices';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl.Pages.Add do
    begin
      Text := 'Users';
      UseDefaultAppearance := True;
    end;
    SetEvent(TMSFNCPageControl, Self, 'OnBeforeChangePage', 'TMSFNCPageControlBeforeChangePage');
    SetEvent(TMSFNCPageControl, Self, 'OnChangePage', 'TMSFNCPageControlChangePage');
    TMSFNCPageControlPage1.SetParentComponent(TMSFNCPageControl);
    TMSFNCPageControlPage1.Name := 'TMSFNCPageControlPage1';
    TMSFNCPageControlPage1.AlignWithMargins := True;
    TMSFNCPageControlPage1.Left := 0;
    TMSFNCPageControlPage1.Top := 52;
    TMSFNCPageControlPage1.Width := 1073;
    TMSFNCPageControlPage1.Height := 572;
    TMSFNCPageControlPage1.Margins.Left := 0;
    TMSFNCPageControlPage1.Margins.Top := 52;
    TMSFNCPageControlPage1.Margins.Right := 0;
    TMSFNCPageControlPage1.Margins.Bottom := 0;
    TMSFNCPageControlPage1.Align := alClient;
    TMSFNCPageControlPage1.ParentDoubleBuffered := False;
    TMSFNCPageControlPage1.DoubleBuffered := True;
    TMSFNCPageControlPage1.TabStop := False;
    TMSFNCPageControlPage1.TabOrder := 1;
    TMSFNCPageControlPage1.Visible := False;
    TMSFNCPageControlPage1.PageIndex := 2;
    TMSFNCPageControlPage1.IsActive := False;
    DepartmentFilterPanel.SetParentComponent(TMSFNCPageControlPage1);
    DepartmentFilterPanel.Name := 'DepartmentFilterPanel';
    DepartmentFilterPanel.Tag := 1;
    DepartmentFilterPanel.AlignWithMargins := True;
    DepartmentFilterPanel.Left := 3;
    DepartmentFilterPanel.Top := 3;
    DepartmentFilterPanel.Width := 1067;
    DepartmentFilterPanel.Height := 57;
    DepartmentFilterPanel.Align := alTop;
    DepartmentFilterPanel.BevelInner := bvNone;
    DepartmentFilterPanel.BevelOuter := bvNone;
    DepartmentFilterPanel.Ctl3D := True;
    DepartmentFilterPanel.ParentCtl3D := False;
    DepartmentFilterPanel.ParentDoubleBuffered := False;
    DepartmentFilterPanel.DoubleBuffered := True;
    DepartmentFilterPanel.TabOrder := 0;
    DepartmentFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    DepartmentFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    DepartmentFilterPanel.SectionsAppearance.Font.Height := -11;
    DepartmentFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    DepartmentFilterPanel.SectionsAppearance.Font.Style := [];
    DepartmentFilterPanel.Stroke.Kind := gskNone;
    DepartmentFilterPanel.Header.Text := 'Header';
    DepartmentFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    DepartmentFilterPanel.Header.Font.Color := clWindowText;
    DepartmentFilterPanel.Header.Font.Height := -11;
    DepartmentFilterPanel.Header.Font.Name := 'Tahoma';
    DepartmentFilterPanel.Header.Font.Style := [];
    DepartmentFilterPanel.Header.Visible := False;
    DepartmentFilterPanel.Footer.Text := 'Footer';
    DepartmentFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    DepartmentFilterPanel.Footer.Font.Color := clWindowText;
    DepartmentFilterPanel.Footer.Font.Height := -11;
    DepartmentFilterPanel.Footer.Font.Name := 'Tahoma';
    DepartmentFilterPanel.Footer.Font.Style := [];
    DepartmentFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    DepartmentFilterPanel.GlobalFont.Style := [];
    DepartmentEdit.SetParentComponent(DepartmentFilterPanel);
    DepartmentEdit.Name := 'DepartmentEdit';
    DepartmentEdit.Left := 20;
    DepartmentEdit.Top := 10;
    DepartmentEdit.Width := 345;
    DepartmentEdit.Height := 34;
    DepartmentEdit.AutoFocus := True;
    DepartmentEdit.ChildOrder := 1;
    DepartmentEdit.Font.Charset := ANSI_CHARSET;
    DepartmentEdit.Font.Color := clWindowText;
    DepartmentEdit.Font.Height := -16;
    DepartmentEdit.Font.Name := 'Segoe UI';
    DepartmentEdit.Font.Style := [];
    DepartmentEdit.HeightPercent := 100.000000000000000000;
    DepartmentEdit.ParentFont := False;
    DepartmentEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentEdit, Self, 'OnChange', 'SearchEditChange');
    DepartmentButtonPanel.SetParentComponent(TMSFNCPageControlPage1);
    DepartmentButtonPanel.Name := 'DepartmentButtonPanel';
    DepartmentButtonPanel.Tag := 1;
    DepartmentButtonPanel.AlignWithMargins := True;
    DepartmentButtonPanel.Left := 3;
    DepartmentButtonPanel.Top := 520;
    DepartmentButtonPanel.Width := 1067;
    DepartmentButtonPanel.Height := 49;
    DepartmentButtonPanel.Align := alBottom;
    DepartmentButtonPanel.BevelInner := bvNone;
    DepartmentButtonPanel.BevelOuter := bvNone;
    DepartmentButtonPanel.Ctl3D := True;
    DepartmentButtonPanel.ParentCtl3D := False;
    DepartmentButtonPanel.ParentDoubleBuffered := False;
    DepartmentButtonPanel.DoubleBuffered := True;
    DepartmentButtonPanel.TabOrder := 1;
    DepartmentButtonPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    DepartmentButtonPanel.SectionsAppearance.Font.Color := clWindowText;
    DepartmentButtonPanel.SectionsAppearance.Font.Height := -11;
    DepartmentButtonPanel.SectionsAppearance.Font.Name := 'Tahoma';
    DepartmentButtonPanel.SectionsAppearance.Font.Style := [];
    DepartmentButtonPanel.Stroke.Kind := gskNone;
    DepartmentButtonPanel.Header.Text := 'Header';
    DepartmentButtonPanel.Header.Font.Charset := DEFAULT_CHARSET;
    DepartmentButtonPanel.Header.Font.Color := clWindowText;
    DepartmentButtonPanel.Header.Font.Height := -11;
    DepartmentButtonPanel.Header.Font.Name := 'Tahoma';
    DepartmentButtonPanel.Header.Font.Style := [];
    DepartmentButtonPanel.Header.Visible := False;
    DepartmentButtonPanel.Footer.Text := 'Footer';
    DepartmentButtonPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    DepartmentButtonPanel.Footer.Font.Color := clWindowText;
    DepartmentButtonPanel.Footer.Font.Height := -11;
    DepartmentButtonPanel.Footer.Font.Name := 'Tahoma';
    DepartmentButtonPanel.Footer.Font.Style := [];
    DepartmentButtonPanel.GlobalFont.Scale := 1.000000000000000000;
    DepartmentButtonPanel.GlobalFont.Style := [];
    DepartmentAddButton.SetParentComponent(DepartmentButtonPanel);
    DepartmentAddButton.Name := 'DepartmentAddButton';
    DepartmentAddButton.AlignWithMargins := True;
    DepartmentAddButton.Left := 20;
    DepartmentAddButton.Top := 8;
    DepartmentAddButton.Width := 100;
    DepartmentAddButton.Height := 31;
    DepartmentAddButton.Margins.Left := 0;
    DepartmentAddButton.Margins.Top := 8;
    DepartmentAddButton.Margins.Right := 8;
    DepartmentAddButton.Margins.Bottom := 10;
    DepartmentAddButton.Align := alLeft;
    DepartmentAddButton.Caption := 'Add';
    DepartmentAddButton.Color := clNone;
    DepartmentAddButton.ElementClassName := 'MyWebButton';
    DepartmentAddButton.Glyph.LoadFromFile('uGeneralSettings.DepartmentButtonPanel.DepartmentAddButton.Glyph.png');
    DepartmentAddButton.HeightPercent := 100.000000000000000000;
    DepartmentAddButton.TabOrder := 0;
    DepartmentAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentAddButton, Self, 'OnClick', 'DepartmentAddButtonClick');
    DepartmentEditButton.SetParentComponent(DepartmentButtonPanel);
    DepartmentEditButton.Name := 'DepartmentEditButton';
    DepartmentEditButton.AlignWithMargins := True;
    DepartmentEditButton.Left := 236;
    DepartmentEditButton.Top := 8;
    DepartmentEditButton.Width := 100;
    DepartmentEditButton.Height := 31;
    DepartmentEditButton.Margins.Left := 0;
    DepartmentEditButton.Margins.Top := 8;
    DepartmentEditButton.Margins.Right := 8;
    DepartmentEditButton.Margins.Bottom := 10;
    DepartmentEditButton.Align := alLeft;
    DepartmentEditButton.Caption := 'Edit';
    DepartmentEditButton.Color := clNone;
    DepartmentEditButton.ElementClassName := 'MyWebButton';
    DepartmentEditButton.Glyph.LoadFromFile('uGeneralSettings.DepartmentButtonPanel.DepartmentEditButton.Glyph.png');
    DepartmentEditButton.HeightPercent := 100.000000000000000000;
    DepartmentEditButton.TabOrder := 1;
    DepartmentEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentEditButton, Self, 'OnClick', 'DepartmentEditButtonClick');
    DepartmentDeleteButton.SetParentComponent(DepartmentButtonPanel);
    DepartmentDeleteButton.Name := 'DepartmentDeleteButton';
    DepartmentDeleteButton.AlignWithMargins := True;
    DepartmentDeleteButton.Left := 344;
    DepartmentDeleteButton.Top := 8;
    DepartmentDeleteButton.Width := 100;
    DepartmentDeleteButton.Height := 31;
    DepartmentDeleteButton.Margins.Left := 0;
    DepartmentDeleteButton.Margins.Top := 8;
    DepartmentDeleteButton.Margins.Right := 8;
    DepartmentDeleteButton.Margins.Bottom := 10;
    DepartmentDeleteButton.Align := alLeft;
    DepartmentDeleteButton.Caption := 'Delete';
    DepartmentDeleteButton.Color := clNone;
    DepartmentDeleteButton.ElementClassName := 'MyWebButton';
    DepartmentDeleteButton.Glyph.LoadFromFile('uGeneralSettings.DepartmentButtonPanel.DepartmentDeleteButton.Glyph.png');
    DepartmentDeleteButton.HeightPercent := 100.000000000000000000;
    DepartmentDeleteButton.TabOrder := 2;
    DepartmentDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentDeleteButton, Self, 'OnClick', 'DepartmentDeleteButtonClick');
    DepartmentCloseButton.SetParentComponent(DepartmentButtonPanel);
    DepartmentCloseButton.Name := 'DepartmentCloseButton';
    DepartmentCloseButton.AlignWithMargins := True;
    DepartmentCloseButton.Left := 947;
    DepartmentCloseButton.Top := 8;
    DepartmentCloseButton.Width := 100;
    DepartmentCloseButton.Height := 31;
    DepartmentCloseButton.Margins.Left := 10;
    DepartmentCloseButton.Margins.Top := 8;
    DepartmentCloseButton.Margins.Right := 20;
    DepartmentCloseButton.Margins.Bottom := 10;
    DepartmentCloseButton.Align := alRight;
    DepartmentCloseButton.Caption := 'Close';
    DepartmentCloseButton.Color := clNone;
    DepartmentCloseButton.ElementClassName := 'MyWebButton';
    DepartmentCloseButton.Glyph.LoadFromFile('uGeneralSettings.DepartmentButtonPanel.DepartmentCloseButton.Glyph.png');
    DepartmentCloseButton.HeightPercent := 100.000000000000000000;
    DepartmentCloseButton.TabOrder := 3;
    DepartmentCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentCloseButton, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel2.SetParentComponent(DepartmentButtonPanel);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 2;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 3;
    TMSFNCPanel2.Width := 14;
    TMSFNCPanel2.Height := 41;
    TMSFNCPanel2.Margins.Bottom := 5;
    TMSFNCPanel2.Align := alLeft;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 4;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
    DepartmentViewButton.SetParentComponent(DepartmentButtonPanel);
    DepartmentViewButton.Name := 'DepartmentViewButton';
    DepartmentViewButton.AlignWithMargins := True;
    DepartmentViewButton.Left := 128;
    DepartmentViewButton.Top := 8;
    DepartmentViewButton.Width := 100;
    DepartmentViewButton.Height := 31;
    DepartmentViewButton.Margins.Left := 0;
    DepartmentViewButton.Margins.Top := 8;
    DepartmentViewButton.Margins.Right := 8;
    DepartmentViewButton.Margins.Bottom := 10;
    DepartmentViewButton.Align := alLeft;
    DepartmentViewButton.Caption := 'View';
    DepartmentViewButton.Color := clNone;
    DepartmentViewButton.ElementClassName := 'MyWebButton';
    DepartmentViewButton.Glyph.LoadFromFile('uGeneralSettings.DepartmentButtonPanel.DepartmentViewButton.Glyph.png');
    DepartmentViewButton.HeightPercent := 100.000000000000000000;
    DepartmentViewButton.TabOrder := 5;
    DepartmentViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(DepartmentViewButton, Self, 'OnClick', 'DepartmentEditButtonClick');
    DepartmentGrid.SetParentComponent(TMSFNCPageControlPage1);
    DepartmentGrid.Name := 'DepartmentGrid';
    DepartmentGrid.AlignWithMargins := True;
    DepartmentGrid.Left := 20;
    DepartmentGrid.Top := 63;
    DepartmentGrid.Width := 1033;
    DepartmentGrid.Height := 444;
    DepartmentGrid.Margins.Left := 20;
    DepartmentGrid.Margins.Top := 0;
    DepartmentGrid.Margins.Right := 20;
    DepartmentGrid.Margins.Bottom := 10;
    DepartmentGrid.AdaptToStyle := True;
    DepartmentGrid.Align := alClient;
    DepartmentGrid.BevelInner := bvNone;
    DepartmentGrid.BevelOuter := bvNone;
    DepartmentGrid.BevelWidth := 2;
    DepartmentGrid.Ctl3D := False;
    DepartmentGrid.ParentCtl3D := False;
    DepartmentGrid.ParentDoubleBuffered := False;
    DepartmentGrid.DoubleBuffered := True;
    DepartmentGrid.Font.Charset := DEFAULT_CHARSET;
    DepartmentGrid.Font.Color := clWindowText;
    DepartmentGrid.Font.Height := -11;
    DepartmentGrid.Font.Name := 'Tahoma';
    DepartmentGrid.Font.Style := [];
    DepartmentGrid.ParentFont := False;
    DepartmentGrid.TabOrder := 2;
    SetEvent(DepartmentGrid, Self, 'OnDblClick', 'DepartmentGridDblClick');
    DepartmentGrid.DefaultRowHeight := 34.000000000000000000;
    DepartmentGrid.FixedColumns := 0;
    DepartmentGrid.ColumnCount := 1;
    DepartmentGrid.RowCount := 1;
    DepartmentGrid.Options.Borders.FixedCellBorders := bNone;
    DepartmentGrid.Options.ColumnSize.Stretch := True;
    DepartmentGrid.Options.ColumnSize.StretchColumn := 0;
    DepartmentGrid.Options.Editing.CalcFormat := '%g';
    DepartmentGrid.Options.Editing.Enabled := False;
    DepartmentGrid.Options.Grouping.CalcFormat := '%g';
    DepartmentGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DepartmentGrid.Options.HTMLExport.CellPadding := 10;
    DepartmentGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DepartmentGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DepartmentGrid.Options.Mouse.ClickMargin := 0;
    DepartmentGrid.Options.Mouse.ColumnSizeMargin := 6;
    DepartmentGrid.Options.Mouse.RowSizeMargin := 6;
    DepartmentGrid.Options.URL.Color := clSkyBlue;
    DepartmentGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DepartmentGrid.Options.Selection.Mode := smSingleRow;
    DepartmentGrid.Adapter := DepartmentGridAdapter;
    DepartmentGrid.HorizontalScrollBarVisible := False;
    DepartmentGrid.Columns.Clear;
    with DepartmentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1032.000000000000000000;
    end;
    DepartmentGrid.Fill.ColorTo := clSilver;
    DepartmentGrid.DefaultFont.Charset := ANSI_CHARSET;
    DepartmentGrid.DefaultFont.Color := clWindowText;
    DepartmentGrid.DefaultFont.Height := -16;
    DepartmentGrid.DefaultFont.Name := 'Segoe UI';
    DepartmentGrid.DefaultFont.Style := [];
    DepartmentGrid.Stroke.Color := 15987699;
    DepartmentGrid.Stroke.Width := 2.000000000000000000;
    DepartmentGrid.TopRow := 1;
    DepartmentGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DepartmentGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DepartmentGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DepartmentGrid.Appearance.FixedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.FixedLayout.Font.Style := [];
    DepartmentGrid.Appearance.NormalLayout.Fill.Color := -1;
    DepartmentGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DepartmentGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.NormalLayout.Font.Color := clGray;
    DepartmentGrid.Appearance.NormalLayout.Font.Height := -11;
    DepartmentGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.NormalLayout.Font.Style := [];
    DepartmentGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DepartmentGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DepartmentGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DepartmentGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.GroupLayout.Font.Height := -11;
    DepartmentGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DepartmentGrid.Appearance.GroupLayout.Font.Style := [];
    DepartmentGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DepartmentGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DepartmentGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DepartmentGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.SummaryLayout.Font.Height := -11;
    DepartmentGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DepartmentGrid.Appearance.SummaryLayout.Font.Style := [];
    DepartmentGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DepartmentGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DepartmentGrid.Appearance.SelectedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.SelectedLayout.Font.Style := [];
    DepartmentGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DepartmentGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DepartmentGrid.Appearance.FocusedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.FocusedLayout.Font.Style := [];
    DepartmentGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DepartmentGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DepartmentGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DepartmentGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.BandLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.BandLayout.Font.Height := -16;
    DepartmentGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DepartmentGrid.Appearance.BandLayout.Font.Style := [];
    DepartmentGrid.Appearance.ProgressLayout.Color := 15385233;
    DepartmentGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DepartmentGrid.LeftCol := 0;
    DepartmentGrid.ScrollMode := scmItemScrolling;
    DepartmentGrid.GlobalFont.Scale := 1.000000000000000000;
    DepartmentGrid.GlobalFont.Style := [];
    DepartmentGrid.DesignTimeSampleData := True;
    TMSFNCPageControlPage0.SetParentComponent(TMSFNCPageControl);
    TMSFNCPageControlPage0.Name := 'TMSFNCPageControlPage0';
    TMSFNCPageControlPage0.AlignWithMargins := True;
    TMSFNCPageControlPage0.Left := 0;
    TMSFNCPageControlPage0.Top := 52;
    TMSFNCPageControlPage0.Width := 1073;
    TMSFNCPageControlPage0.Height := 572;
    TMSFNCPageControlPage0.Margins.Left := 0;
    TMSFNCPageControlPage0.Margins.Top := 52;
    TMSFNCPageControlPage0.Margins.Right := 0;
    TMSFNCPageControlPage0.Margins.Bottom := 0;
    TMSFNCPageControlPage0.Align := alClient;
    TMSFNCPageControlPage0.ParentDoubleBuffered := False;
    TMSFNCPageControlPage0.DoubleBuffered := True;
    TMSFNCPageControlPage0.TabStop := False;
    TMSFNCPageControlPage0.TabOrder := 0;
    TMSFNCPageControlPage0.Visible := False;
    TMSFNCPageControlPage0.PageIndex := 1;
    TMSFNCPageControlPage0.IsActive := False;
    CompanyFilterPanel.SetParentComponent(TMSFNCPageControlPage0);
    CompanyFilterPanel.Name := 'CompanyFilterPanel';
    CompanyFilterPanel.Tag := 1;
    CompanyFilterPanel.AlignWithMargins := True;
    CompanyFilterPanel.Left := 3;
    CompanyFilterPanel.Top := 3;
    CompanyFilterPanel.Width := 1067;
    CompanyFilterPanel.Height := 57;
    CompanyFilterPanel.Align := alTop;
    CompanyFilterPanel.BevelInner := bvNone;
    CompanyFilterPanel.BevelOuter := bvNone;
    CompanyFilterPanel.Ctl3D := True;
    CompanyFilterPanel.ParentCtl3D := False;
    CompanyFilterPanel.ParentDoubleBuffered := False;
    CompanyFilterPanel.DoubleBuffered := True;
    CompanyFilterPanel.TabOrder := 0;
    CompanyFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    CompanyFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    CompanyFilterPanel.SectionsAppearance.Font.Height := -11;
    CompanyFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    CompanyFilterPanel.SectionsAppearance.Font.Style := [];
    CompanyFilterPanel.Stroke.Kind := gskNone;
    CompanyFilterPanel.Header.Text := 'Header';
    CompanyFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    CompanyFilterPanel.Header.Font.Color := clWindowText;
    CompanyFilterPanel.Header.Font.Height := -11;
    CompanyFilterPanel.Header.Font.Name := 'Tahoma';
    CompanyFilterPanel.Header.Font.Style := [];
    CompanyFilterPanel.Header.Visible := False;
    CompanyFilterPanel.Footer.Text := 'Footer';
    CompanyFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    CompanyFilterPanel.Footer.Font.Color := clWindowText;
    CompanyFilterPanel.Footer.Font.Height := -11;
    CompanyFilterPanel.Footer.Font.Name := 'Tahoma';
    CompanyFilterPanel.Footer.Font.Style := [];
    CompanyFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    CompanyFilterPanel.GlobalFont.Style := [];
    CompanyEdit.SetParentComponent(CompanyFilterPanel);
    CompanyEdit.Name := 'CompanyEdit';
    CompanyEdit.Left := 20;
    CompanyEdit.Top := 10;
    CompanyEdit.Width := 345;
    CompanyEdit.Height := 34;
    CompanyEdit.AutoFocus := True;
    CompanyEdit.ChildOrder := 1;
    CompanyEdit.Font.Charset := ANSI_CHARSET;
    CompanyEdit.Font.Color := clWindowText;
    CompanyEdit.Font.Height := -16;
    CompanyEdit.Font.Name := 'Segoe UI';
    CompanyEdit.Font.Style := [];
    CompanyEdit.HeightPercent := 100.000000000000000000;
    CompanyEdit.ParentFont := False;
    CompanyEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyEdit, Self, 'OnChange', 'SearchEditChange');
    CompanyButtonPanel.SetParentComponent(TMSFNCPageControlPage0);
    CompanyButtonPanel.Name := 'CompanyButtonPanel';
    CompanyButtonPanel.Tag := 1;
    CompanyButtonPanel.AlignWithMargins := True;
    CompanyButtonPanel.Left := 3;
    CompanyButtonPanel.Top := 520;
    CompanyButtonPanel.Width := 1067;
    CompanyButtonPanel.Height := 49;
    CompanyButtonPanel.Align := alBottom;
    CompanyButtonPanel.BevelInner := bvNone;
    CompanyButtonPanel.BevelOuter := bvNone;
    CompanyButtonPanel.Ctl3D := True;
    CompanyButtonPanel.ParentCtl3D := False;
    CompanyButtonPanel.ParentDoubleBuffered := False;
    CompanyButtonPanel.DoubleBuffered := True;
    CompanyButtonPanel.TabOrder := 1;
    CompanyButtonPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    CompanyButtonPanel.SectionsAppearance.Font.Color := clWindowText;
    CompanyButtonPanel.SectionsAppearance.Font.Height := -11;
    CompanyButtonPanel.SectionsAppearance.Font.Name := 'Tahoma';
    CompanyButtonPanel.SectionsAppearance.Font.Style := [];
    CompanyButtonPanel.Stroke.Kind := gskNone;
    CompanyButtonPanel.Header.Text := 'Header';
    CompanyButtonPanel.Header.Font.Charset := DEFAULT_CHARSET;
    CompanyButtonPanel.Header.Font.Color := clWindowText;
    CompanyButtonPanel.Header.Font.Height := -11;
    CompanyButtonPanel.Header.Font.Name := 'Tahoma';
    CompanyButtonPanel.Header.Font.Style := [];
    CompanyButtonPanel.Header.Visible := False;
    CompanyButtonPanel.Footer.Text := 'Footer';
    CompanyButtonPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    CompanyButtonPanel.Footer.Font.Color := clWindowText;
    CompanyButtonPanel.Footer.Font.Height := -11;
    CompanyButtonPanel.Footer.Font.Name := 'Tahoma';
    CompanyButtonPanel.Footer.Font.Style := [];
    CompanyButtonPanel.GlobalFont.Scale := 1.000000000000000000;
    CompanyButtonPanel.GlobalFont.Style := [];
    CompanyAddButton.SetParentComponent(CompanyButtonPanel);
    CompanyAddButton.Name := 'CompanyAddButton';
    CompanyAddButton.AlignWithMargins := True;
    CompanyAddButton.Left := 20;
    CompanyAddButton.Top := 8;
    CompanyAddButton.Width := 100;
    CompanyAddButton.Height := 31;
    CompanyAddButton.Margins.Left := 0;
    CompanyAddButton.Margins.Top := 8;
    CompanyAddButton.Margins.Right := 8;
    CompanyAddButton.Margins.Bottom := 10;
    CompanyAddButton.Align := alLeft;
    CompanyAddButton.Caption := 'Add';
    CompanyAddButton.Color := clNone;
    CompanyAddButton.ElementClassName := 'MyWebButton';
    CompanyAddButton.Glyph.LoadFromFile('uGeneralSettings.CompanyButtonPanel.CompanyAddButton.Glyph.png');
    CompanyAddButton.HeightPercent := 100.000000000000000000;
    CompanyAddButton.TabOrder := 0;
    CompanyAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyAddButton, Self, 'OnClick', 'CompanyAddButtonClick');
    CompanyEditButton.SetParentComponent(CompanyButtonPanel);
    CompanyEditButton.Name := 'CompanyEditButton';
    CompanyEditButton.AlignWithMargins := True;
    CompanyEditButton.Left := 236;
    CompanyEditButton.Top := 8;
    CompanyEditButton.Width := 100;
    CompanyEditButton.Height := 31;
    CompanyEditButton.Margins.Left := 0;
    CompanyEditButton.Margins.Top := 8;
    CompanyEditButton.Margins.Right := 8;
    CompanyEditButton.Margins.Bottom := 10;
    CompanyEditButton.Align := alLeft;
    CompanyEditButton.Caption := 'Edit';
    CompanyEditButton.Color := clNone;
    CompanyEditButton.ElementClassName := 'MyWebButton';
    CompanyEditButton.Glyph.LoadFromFile('uGeneralSettings.CompanyButtonPanel.CompanyEditButton.Glyph.png');
    CompanyEditButton.HeightPercent := 100.000000000000000000;
    CompanyEditButton.TabOrder := 1;
    CompanyEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyEditButton, Self, 'OnClick', 'CompanyEditButtonClick');
    CompanyDeleteButton.SetParentComponent(CompanyButtonPanel);
    CompanyDeleteButton.Name := 'CompanyDeleteButton';
    CompanyDeleteButton.AlignWithMargins := True;
    CompanyDeleteButton.Left := 344;
    CompanyDeleteButton.Top := 8;
    CompanyDeleteButton.Width := 100;
    CompanyDeleteButton.Height := 31;
    CompanyDeleteButton.Margins.Left := 0;
    CompanyDeleteButton.Margins.Top := 8;
    CompanyDeleteButton.Margins.Right := 8;
    CompanyDeleteButton.Margins.Bottom := 10;
    CompanyDeleteButton.Align := alLeft;
    CompanyDeleteButton.Caption := 'Delete';
    CompanyDeleteButton.Color := clNone;
    CompanyDeleteButton.ElementClassName := 'MyWebButton';
    CompanyDeleteButton.Glyph.LoadFromFile('uGeneralSettings.CompanyButtonPanel.CompanyDeleteButton.Glyph.png');
    CompanyDeleteButton.HeightPercent := 100.000000000000000000;
    CompanyDeleteButton.TabOrder := 2;
    CompanyDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyDeleteButton, Self, 'OnClick', 'CompanyDeleteButtonClick');
    CompanyCloseButton.SetParentComponent(CompanyButtonPanel);
    CompanyCloseButton.Name := 'CompanyCloseButton';
    CompanyCloseButton.AlignWithMargins := True;
    CompanyCloseButton.Left := 947;
    CompanyCloseButton.Top := 8;
    CompanyCloseButton.Width := 100;
    CompanyCloseButton.Height := 31;
    CompanyCloseButton.Margins.Left := 10;
    CompanyCloseButton.Margins.Top := 8;
    CompanyCloseButton.Margins.Right := 20;
    CompanyCloseButton.Margins.Bottom := 10;
    CompanyCloseButton.Align := alRight;
    CompanyCloseButton.Caption := 'Close';
    CompanyCloseButton.Color := clNone;
    CompanyCloseButton.ElementClassName := 'MyWebButton';
    CompanyCloseButton.Glyph.LoadFromFile('uGeneralSettings.CompanyButtonPanel.CompanyCloseButton.Glyph.png');
    CompanyCloseButton.HeightPercent := 100.000000000000000000;
    CompanyCloseButton.TabOrder := 3;
    CompanyCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyCloseButton, Self, 'OnClick', 'CloseButtonClick');
    CompanyViewButton.SetParentComponent(CompanyButtonPanel);
    CompanyViewButton.Name := 'CompanyViewButton';
    CompanyViewButton.AlignWithMargins := True;
    CompanyViewButton.Left := 128;
    CompanyViewButton.Top := 8;
    CompanyViewButton.Width := 100;
    CompanyViewButton.Height := 31;
    CompanyViewButton.Margins.Left := 0;
    CompanyViewButton.Margins.Top := 8;
    CompanyViewButton.Margins.Right := 8;
    CompanyViewButton.Margins.Bottom := 10;
    CompanyViewButton.Align := alLeft;
    CompanyViewButton.Caption := 'View';
    CompanyViewButton.Color := clNone;
    CompanyViewButton.ElementClassName := 'MyWebButton';
    CompanyViewButton.Glyph.LoadFromFile('uGeneralSettings.CompanyButtonPanel.CompanyViewButton.Glyph.png');
    CompanyViewButton.HeightPercent := 100.000000000000000000;
    CompanyViewButton.TabOrder := 4;
    CompanyViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyViewButton, Self, 'OnClick', 'CompanyEditButtonClick');
    ListButtonOnlinePanel.SetParentComponent(CompanyButtonPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 5;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    CompanyGrid.SetParentComponent(TMSFNCPageControlPage0);
    CompanyGrid.Name := 'CompanyGrid';
    CompanyGrid.AlignWithMargins := True;
    CompanyGrid.Left := 20;
    CompanyGrid.Top := 63;
    CompanyGrid.Width := 1033;
    CompanyGrid.Height := 444;
    CompanyGrid.Margins.Left := 20;
    CompanyGrid.Margins.Top := 0;
    CompanyGrid.Margins.Right := 20;
    CompanyGrid.Margins.Bottom := 10;
    CompanyGrid.AdaptToStyle := True;
    CompanyGrid.Align := alClient;
    CompanyGrid.BevelInner := bvNone;
    CompanyGrid.BevelOuter := bvNone;
    CompanyGrid.BevelWidth := 2;
    CompanyGrid.Ctl3D := False;
    CompanyGrid.ParentCtl3D := False;
    CompanyGrid.ParentDoubleBuffered := False;
    CompanyGrid.DoubleBuffered := True;
    CompanyGrid.Font.Charset := DEFAULT_CHARSET;
    CompanyGrid.Font.Color := clWindowText;
    CompanyGrid.Font.Height := -11;
    CompanyGrid.Font.Name := 'Tahoma';
    CompanyGrid.Font.Style := [];
    CompanyGrid.ParentFont := False;
    CompanyGrid.TabOrder := 2;
    SetEvent(CompanyGrid, Self, 'OnDblClick', 'CompanyGridDblClick');
    CompanyGrid.DefaultRowHeight := 34.000000000000000000;
    CompanyGrid.FixedColumns := 0;
    CompanyGrid.ColumnCount := 4;
    CompanyGrid.RowCount := 1;
    CompanyGrid.Options.Borders.FixedCellBorders := bNone;
    CompanyGrid.Options.ColumnSize.Stretch := True;
    CompanyGrid.Options.ColumnSize.StretchColumn := 0;
    CompanyGrid.Options.Editing.CalcFormat := '%g';
    CompanyGrid.Options.Editing.Enabled := False;
    CompanyGrid.Options.Grouping.CalcFormat := '%g';
    CompanyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    CompanyGrid.Options.HTMLExport.CellPadding := 10;
    CompanyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    CompanyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    CompanyGrid.Options.Mouse.ClickMargin := 0;
    CompanyGrid.Options.Mouse.ColumnSizeMargin := 6;
    CompanyGrid.Options.Mouse.RowSizeMargin := 6;
    CompanyGrid.Options.URL.Color := clSkyBlue;
    CompanyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    CompanyGrid.Options.Selection.Mode := smSingleRow;
    CompanyGrid.Adapter := CompanyGridAdapter;
    CompanyGrid.HorizontalScrollBarVisible := False;
    CompanyGrid.Columns.Clear;
    with CompanyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 828.000000000000000000;
    end;
    with CompanyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with CompanyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with CompanyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    CompanyGrid.Fill.ColorTo := clSilver;
    CompanyGrid.DefaultFont.Charset := ANSI_CHARSET;
    CompanyGrid.DefaultFont.Color := clWindowText;
    CompanyGrid.DefaultFont.Height := -16;
    CompanyGrid.DefaultFont.Name := 'Segoe UI';
    CompanyGrid.DefaultFont.Style := [];
    CompanyGrid.Stroke.Color := 15987699;
    CompanyGrid.Stroke.Width := 2.000000000000000000;
    CompanyGrid.TopRow := 1;
    CompanyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    CompanyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    CompanyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    CompanyGrid.Appearance.FixedLayout.Font.Height := -16;
    CompanyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.FixedLayout.Font.Style := [];
    CompanyGrid.Appearance.NormalLayout.Fill.Color := -1;
    CompanyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    CompanyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.NormalLayout.Font.Color := clGray;
    CompanyGrid.Appearance.NormalLayout.Font.Height := -11;
    CompanyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.NormalLayout.Font.Style := [];
    CompanyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    CompanyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    CompanyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    CompanyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.GroupLayout.Font.Height := -11;
    CompanyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    CompanyGrid.Appearance.GroupLayout.Font.Style := [];
    CompanyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    CompanyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    CompanyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    CompanyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.SummaryLayout.Font.Height := -11;
    CompanyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    CompanyGrid.Appearance.SummaryLayout.Font.Style := [];
    CompanyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    CompanyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    CompanyGrid.Appearance.SelectedLayout.Font.Height := -16;
    CompanyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.SelectedLayout.Font.Style := [];
    CompanyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    CompanyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    CompanyGrid.Appearance.FocusedLayout.Font.Height := -16;
    CompanyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.FocusedLayout.Font.Style := [];
    CompanyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    CompanyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    CompanyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    CompanyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.BandLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.BandLayout.Font.Height := -16;
    CompanyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    CompanyGrid.Appearance.BandLayout.Font.Style := [];
    CompanyGrid.Appearance.ProgressLayout.Color := 15385233;
    CompanyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    CompanyGrid.LeftCol := 0;
    CompanyGrid.ScrollMode := scmItemScrolling;
    CompanyGrid.GlobalFont.Scale := 1.000000000000000000;
    CompanyGrid.GlobalFont.Style := [];
    CompanyGrid.DesignTimeSampleData := True;
    TMSFNCPageControlPage4.SetParentComponent(TMSFNCPageControl);
    TMSFNCPageControlPage4.Name := 'TMSFNCPageControlPage4';
    TMSFNCPageControlPage4.AlignWithMargins := True;
    TMSFNCPageControlPage4.Left := 0;
    TMSFNCPageControlPage4.Top := 52;
    TMSFNCPageControlPage4.Width := 1073;
    TMSFNCPageControlPage4.Height := 572;
    TMSFNCPageControlPage4.Margins.Left := 0;
    TMSFNCPageControlPage4.Margins.Top := 52;
    TMSFNCPageControlPage4.Margins.Right := 0;
    TMSFNCPageControlPage4.Margins.Bottom := 0;
    TMSFNCPageControlPage4.Align := alClient;
    TMSFNCPageControlPage4.ParentDoubleBuffered := False;
    TMSFNCPageControlPage4.DoubleBuffered := True;
    TMSFNCPageControlPage4.TabStop := False;
    TMSFNCPageControlPage4.TabOrder := 4;
    TMSFNCPageControlPage4.Visible := False;
    TMSFNCPageControlPage4.PageIndex := 4;
    TMSFNCPageControlPage4.IsActive := False;
    TMSFNCPageControlDevice.SetParentComponent(TMSFNCPageControlPage4);
    TMSFNCPageControlDevice.Name := 'TMSFNCPageControlDevice';
    TMSFNCPageControlDevice.AlignWithMargins := True;
    TMSFNCPageControlDevice.Left := 10;
    TMSFNCPageControlDevice.Top := 0;
    TMSFNCPageControlDevice.Width := 1053;
    TMSFNCPageControlDevice.Height := 564;
    TMSFNCPageControlDevice.Margins.Left := 10;
    TMSFNCPageControlDevice.Margins.Top := 0;
    TMSFNCPageControlDevice.Margins.Right := 10;
    TMSFNCPageControlDevice.Margins.Bottom := 8;
    TMSFNCPageControlDevice.Align := alClient;
    TMSFNCPageControlDevice.BevelEdges := [];
    TMSFNCPageControlDevice.BevelInner := bvNone;
    TMSFNCPageControlDevice.BevelOuter := bvNone;
    TMSFNCPageControlDevice.ParentDoubleBuffered := False;
    TMSFNCPageControlDevice.Color := clBtnFace;
    TMSFNCPageControlDevice.DoubleBuffered := True;
    TMSFNCPageControlDevice.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPageControlDevice.Font.Color := clWindowText;
    TMSFNCPageControlDevice.Font.Height := -16;
    TMSFNCPageControlDevice.Font.Name := 'Tahoma';
    TMSFNCPageControlDevice.Font.Style := [];
    TMSFNCPageControlDevice.ParentFont := False;
    TMSFNCPageControlDevice.TabOrder := 0;
    TMSFNCPageControlDevice.Fill.Kind := gfkNone;
    TMSFNCPageControlDevice.Fill.ColorTo := clWhite;
    TMSFNCPageControlDevice.Stroke.Kind := gskNone;
    TMSFNCPageControlDevice.TabAppearance.Font.Charset := ANSI_CHARSET;
    TMSFNCPageControlDevice.TabAppearance.Font.Color := clAqua;
    TMSFNCPageControlDevice.TabAppearance.Font.Height := -16;
    TMSFNCPageControlDevice.TabAppearance.Font.Name := 'Segoe UI';
    TMSFNCPageControlDevice.TabAppearance.Font.Style := [];
    TMSFNCPageControlDevice.TabAppearance.Fill.Color := 14408667;
    TMSFNCPageControlDevice.TabAppearance.Stroke.Color := -1;
    TMSFNCPageControlDevice.TabAppearance.ActiveFill.ColorTo := clWhite;
    TMSFNCPageControlDevice.TabAppearance.HoverFill.Color := 15658734;
    TMSFNCPageControlDevice.TabAppearance.HoverStroke.Color := -1;
    TMSFNCPageControlDevice.TabAppearance.TextSpacing := 10.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.Shape := tsRectangle;
    TMSFNCPageControlDevice.TabAppearance.ShapeOverlap := 0.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.ShapeSlope := 0.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.ShapeRounding := 0.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.TextColor := clGrayText;
    TMSFNCPageControlDevice.TabAppearance.ActiveTextColor := clGrayText;
    TMSFNCPageControlDevice.TabAppearance.HoverTextColor := clGrayText;
    TMSFNCPageControlDevice.TabAppearance.ShowFocus := False;
    TMSFNCPageControlDevice.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    TMSFNCPageControlDevice.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    TMSFNCPageControlDevice.TabAppearance.BadgeFont.Color := 139;
    TMSFNCPageControlDevice.TabAppearance.BadgeFont.Height := -11;
    TMSFNCPageControlDevice.TabAppearance.BadgeFont.Name := 'Tahoma';
    TMSFNCPageControlDevice.TabAppearance.BadgeFont.Style := [fsBold];
    TMSFNCPageControlDevice.ButtonAppearance.CloseIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage4.TMSFNCPageControlDevice.ButtonAppearance.CloseIcon.svg');
    TMSFNCPageControlDevice.ButtonAppearance.InsertIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage4.TMSFNCPageControlDevice.ButtonAppearance.InsertIcon.svg');
    TMSFNCPageControlDevice.ButtonAppearance.TabListIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage4.TMSFNCPageControlDevice.ButtonAppearance.TabListIcon.svg');
    TMSFNCPageControlDevice.ButtonAppearance.ScrollNextIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage4.TMSFNCPageControlDevice.ButtonAppearance.ScrollNextIcon.svg');
    TMSFNCPageControlDevice.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage4.TMSFNCPageControlDevice.ButtonAppearance.ScrollPreviousIcon.svg');
    TMSFNCPageControlDevice.TabSize.Height := 45.000000000000000000;
    TMSFNCPageControlDevice.TabSize.Margins.Left := 8.000000000000000000;
    TMSFNCPageControlDevice.TabSize.Margins.Top := 8.000000000000000000;
    TMSFNCPageControlDevice.TabSize.Margins.Right := 8.000000000000000000;
    TMSFNCPageControlDevice.TabSize.Margins.Bottom := 8.000000000000000000;
    TMSFNCPageControlDevice.Pages.Clear;
    with TMSFNCPageControlDevice.Pages.Add do
    begin
      Text := 'Model';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlDevice.Pages.Add do
    begin
      Text := 'Reason Deactivation';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlDevice.Pages.Add do
    begin
      Text := 'BMW';
      TabVisible := False;
      Visible := False;
      UseDefaultAppearance := True;
    end;
    SetEvent(TMSFNCPageControlDevice, Self, 'OnBeforeChangePage', 'TMSFNCPageControlDeviceBeforeChangePage');
    SetEvent(TMSFNCPageControlDevice, Self, 'OnChangePage', 'TMSFNCPageControlDeviceChangePage');
    TMSFNCPageControlContainer1.SetParentComponent(TMSFNCPageControlDevice);
    TMSFNCPageControlContainer1.Name := 'TMSFNCPageControlContainer1';
    TMSFNCPageControlContainer1.AlignWithMargins := True;
    TMSFNCPageControlContainer1.Left := 0;
    TMSFNCPageControlContainer1.Top := 52;
    TMSFNCPageControlContainer1.Width := 1053;
    TMSFNCPageControlContainer1.Height := 512;
    TMSFNCPageControlContainer1.Margins.Left := 0;
    TMSFNCPageControlContainer1.Margins.Top := 52;
    TMSFNCPageControlContainer1.Margins.Right := 0;
    TMSFNCPageControlContainer1.Margins.Bottom := 0;
    TMSFNCPageControlContainer1.Align := alClient;
    TMSFNCPageControlContainer1.ParentDoubleBuffered := False;
    TMSFNCPageControlContainer1.DoubleBuffered := True;
    TMSFNCPageControlContainer1.TabStop := False;
    TMSFNCPageControlContainer1.TabOrder := 2;
    TMSFNCPageControlContainer1.Visible := False;
    TMSFNCPageControlContainer1.PageIndex := 2;
    TMSFNCPageControlContainer1.IsActive := False;
    TMSFNCPageControlContainer2.SetParentComponent(TMSFNCPageControlDevice);
    TMSFNCPageControlContainer2.Name := 'TMSFNCPageControlContainer2';
    TMSFNCPageControlContainer2.AlignWithMargins := True;
    TMSFNCPageControlContainer2.Left := 0;
    TMSFNCPageControlContainer2.Top := 52;
    TMSFNCPageControlContainer2.Width := 1053;
    TMSFNCPageControlContainer2.Height := 512;
    TMSFNCPageControlContainer2.Margins.Left := 0;
    TMSFNCPageControlContainer2.Margins.Top := 52;
    TMSFNCPageControlContainer2.Margins.Right := 0;
    TMSFNCPageControlContainer2.Margins.Bottom := 0;
    TMSFNCPageControlContainer2.Align := alClient;
    TMSFNCPageControlContainer2.ParentDoubleBuffered := False;
    TMSFNCPageControlContainer2.DoubleBuffered := True;
    TMSFNCPageControlContainer2.TabStop := False;
    TMSFNCPageControlContainer2.TabOrder := 1;
    TMSFNCPageControlContainer2.Visible := False;
    TMSFNCPageControlContainer2.PageIndex := 1;
    TMSFNCPageControlContainer2.IsActive := False;
    TMSFNCPanel4.SetParentComponent(TMSFNCPageControlContainer2);
    TMSFNCPanel4.Name := 'TMSFNCPanel4';
    TMSFNCPanel4.Tag := 1;
    TMSFNCPanel4.AlignWithMargins := True;
    TMSFNCPanel4.Left := 3;
    TMSFNCPanel4.Top := 3;
    TMSFNCPanel4.Width := 1047;
    TMSFNCPanel4.Height := 57;
    TMSFNCPanel4.Align := alTop;
    TMSFNCPanel4.BevelInner := bvNone;
    TMSFNCPanel4.BevelOuter := bvNone;
    TMSFNCPanel4.Ctl3D := True;
    TMSFNCPanel4.ParentCtl3D := False;
    TMSFNCPanel4.ParentDoubleBuffered := False;
    TMSFNCPanel4.DoubleBuffered := True;
    TMSFNCPanel4.TabOrder := 0;
    TMSFNCPanel4.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel4.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel4.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel4.SectionsAppearance.Font.Style := [];
    TMSFNCPanel4.Stroke.Kind := gskNone;
    TMSFNCPanel4.Header.Text := 'Header';
    TMSFNCPanel4.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.Header.Font.Color := clWindowText;
    TMSFNCPanel4.Header.Font.Height := -11;
    TMSFNCPanel4.Header.Font.Name := 'Tahoma';
    TMSFNCPanel4.Header.Font.Style := [];
    TMSFNCPanel4.Header.Visible := False;
    TMSFNCPanel4.Footer.Text := 'Footer';
    TMSFNCPanel4.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.Footer.Font.Color := clWindowText;
    TMSFNCPanel4.Footer.Font.Height := -11;
    TMSFNCPanel4.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel4.Footer.Font.Style := [];
    TMSFNCPanel4.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel4.GlobalFont.Style := [];
    DeviceReasonDeleteEdit.SetParentComponent(TMSFNCPanel4);
    DeviceReasonDeleteEdit.Name := 'DeviceReasonDeleteEdit';
    DeviceReasonDeleteEdit.Left := 17;
    DeviceReasonDeleteEdit.Top := 10;
    DeviceReasonDeleteEdit.Width := 345;
    DeviceReasonDeleteEdit.Height := 34;
    DeviceReasonDeleteEdit.AutoFocus := True;
    DeviceReasonDeleteEdit.ChildOrder := 1;
    DeviceReasonDeleteEdit.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteEdit.Font.Color := clWindowText;
    DeviceReasonDeleteEdit.Font.Height := -16;
    DeviceReasonDeleteEdit.Font.Name := 'Segoe UI';
    DeviceReasonDeleteEdit.Font.Style := [];
    DeviceReasonDeleteEdit.HeightPercent := 100.000000000000000000;
    DeviceReasonDeleteEdit.ParentFont := False;
    DeviceReasonDeleteEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceReasonDeleteEdit, Self, 'OnChange', 'SearchEditChange');
    DeviceReasonDeleteGrid.SetParentComponent(TMSFNCPageControlContainer2);
    DeviceReasonDeleteGrid.Name := 'DeviceReasonDeleteGrid';
    DeviceReasonDeleteGrid.AlignWithMargins := True;
    DeviceReasonDeleteGrid.Left := 20;
    DeviceReasonDeleteGrid.Top := 63;
    DeviceReasonDeleteGrid.Width := 1013;
    DeviceReasonDeleteGrid.Height := 384;
    DeviceReasonDeleteGrid.Margins.Left := 20;
    DeviceReasonDeleteGrid.Margins.Top := 0;
    DeviceReasonDeleteGrid.Margins.Right := 20;
    DeviceReasonDeleteGrid.Margins.Bottom := 10;
    DeviceReasonDeleteGrid.AdaptToStyle := True;
    DeviceReasonDeleteGrid.Align := alClient;
    DeviceReasonDeleteGrid.BevelInner := bvNone;
    DeviceReasonDeleteGrid.BevelOuter := bvNone;
    DeviceReasonDeleteGrid.BevelWidth := 2;
    DeviceReasonDeleteGrid.Ctl3D := False;
    DeviceReasonDeleteGrid.ParentCtl3D := False;
    DeviceReasonDeleteGrid.ParentDoubleBuffered := False;
    DeviceReasonDeleteGrid.DoubleBuffered := True;
    DeviceReasonDeleteGrid.Font.Charset := DEFAULT_CHARSET;
    DeviceReasonDeleteGrid.Font.Color := clWindowText;
    DeviceReasonDeleteGrid.Font.Height := -11;
    DeviceReasonDeleteGrid.Font.Name := 'Tahoma';
    DeviceReasonDeleteGrid.Font.Style := [];
    DeviceReasonDeleteGrid.ParentFont := False;
    DeviceReasonDeleteGrid.TabOrder := 1;
    SetEvent(DeviceReasonDeleteGrid, Self, 'OnDblClick', 'DeviceReasonDeleteGridDblClick');
    DeviceReasonDeleteGrid.DefaultRowHeight := 34.000000000000000000;
    DeviceReasonDeleteGrid.FixedColumns := 0;
    DeviceReasonDeleteGrid.ColumnCount := 1;
    DeviceReasonDeleteGrid.RowCount := 1;
    DeviceReasonDeleteGrid.Options.Borders.FixedCellBorders := bNone;
    DeviceReasonDeleteGrid.Options.ColumnSize.Stretch := True;
    DeviceReasonDeleteGrid.Options.ColumnSize.StretchColumn := 0;
    DeviceReasonDeleteGrid.Options.Editing.CalcFormat := '%g';
    DeviceReasonDeleteGrid.Options.Editing.Enabled := False;
    DeviceReasonDeleteGrid.Options.Grouping.CalcFormat := '%g';
    DeviceReasonDeleteGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DeviceReasonDeleteGrid.Options.HTMLExport.CellPadding := 10;
    DeviceReasonDeleteGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DeviceReasonDeleteGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DeviceReasonDeleteGrid.Options.Mouse.ClickMargin := 0;
    DeviceReasonDeleteGrid.Options.Mouse.ColumnSizeMargin := 6;
    DeviceReasonDeleteGrid.Options.Mouse.RowSizeMargin := 6;
    DeviceReasonDeleteGrid.Options.URL.Color := clSkyBlue;
    DeviceReasonDeleteGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DeviceReasonDeleteGrid.Options.Selection.Mode := smSingleRow;
    DeviceReasonDeleteGrid.Adapter := DeviceReasonDeleteGridAdapter;
    DeviceReasonDeleteGrid.HorizontalScrollBarVisible := False;
    DeviceReasonDeleteGrid.Columns.Clear;
    with DeviceReasonDeleteGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1012.000000000000000000;
    end;
    DeviceReasonDeleteGrid.Fill.ColorTo := clSilver;
    DeviceReasonDeleteGrid.DefaultFont.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.DefaultFont.Color := clWindowText;
    DeviceReasonDeleteGrid.DefaultFont.Height := -16;
    DeviceReasonDeleteGrid.DefaultFont.Name := 'Segoe UI';
    DeviceReasonDeleteGrid.DefaultFont.Style := [];
    DeviceReasonDeleteGrid.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Stroke.Width := 2.000000000000000000;
    DeviceReasonDeleteGrid.TopRow := 1;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Font.Height := -16;
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DeviceReasonDeleteGrid.Appearance.FixedLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Fill.Color := -1;
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Font.Color := clGray;
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Font.Height := -11;
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DeviceReasonDeleteGrid.Appearance.NormalLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Font.Height := -11;
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DeviceReasonDeleteGrid.Appearance.GroupLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Font.Height := -11;
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DeviceReasonDeleteGrid.Appearance.SummaryLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Font.Height := -16;
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DeviceReasonDeleteGrid.Appearance.SelectedLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Font.Height := -16;
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DeviceReasonDeleteGrid.Appearance.FocusedLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DeviceReasonDeleteGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DeviceReasonDeleteGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DeviceReasonDeleteGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DeviceReasonDeleteGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DeviceReasonDeleteGrid.Appearance.BandLayout.Font.Color := clBlack;
    DeviceReasonDeleteGrid.Appearance.BandLayout.Font.Height := -16;
    DeviceReasonDeleteGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DeviceReasonDeleteGrid.Appearance.BandLayout.Font.Style := [];
    DeviceReasonDeleteGrid.Appearance.ProgressLayout.Color := 15385233;
    DeviceReasonDeleteGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DeviceReasonDeleteGrid.LeftCol := 0;
    DeviceReasonDeleteGrid.ScrollMode := scmItemScrolling;
    DeviceReasonDeleteGrid.GlobalFont.Scale := 1.000000000000000000;
    DeviceReasonDeleteGrid.GlobalFont.Style := [];
    DeviceReasonDeleteGrid.DesignTimeSampleData := True;
    TMSFNCPanel5.SetParentComponent(TMSFNCPageControlContainer2);
    TMSFNCPanel5.Name := 'TMSFNCPanel5';
    TMSFNCPanel5.Tag := 1;
    TMSFNCPanel5.AlignWithMargins := True;
    TMSFNCPanel5.Left := 3;
    TMSFNCPanel5.Top := 460;
    TMSFNCPanel5.Width := 1047;
    TMSFNCPanel5.Height := 49;
    TMSFNCPanel5.Align := alBottom;
    TMSFNCPanel5.BevelInner := bvNone;
    TMSFNCPanel5.BevelOuter := bvNone;
    TMSFNCPanel5.Ctl3D := True;
    TMSFNCPanel5.ParentCtl3D := False;
    TMSFNCPanel5.ParentDoubleBuffered := False;
    TMSFNCPanel5.DoubleBuffered := True;
    TMSFNCPanel5.TabOrder := 2;
    TMSFNCPanel5.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel5.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel5.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel5.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel5.SectionsAppearance.Font.Style := [];
    TMSFNCPanel5.Stroke.Kind := gskNone;
    TMSFNCPanel5.Header.Text := 'Header';
    TMSFNCPanel5.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel5.Header.Font.Color := clWindowText;
    TMSFNCPanel5.Header.Font.Height := -11;
    TMSFNCPanel5.Header.Font.Name := 'Tahoma';
    TMSFNCPanel5.Header.Font.Style := [];
    TMSFNCPanel5.Header.Visible := False;
    TMSFNCPanel5.Footer.Text := 'Footer';
    TMSFNCPanel5.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel5.Footer.Font.Color := clWindowText;
    TMSFNCPanel5.Footer.Font.Height := -11;
    TMSFNCPanel5.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel5.Footer.Font.Style := [];
    TMSFNCPanel5.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel5.GlobalFont.Style := [];
    DeviceReasonDeleteAddButton.SetParentComponent(TMSFNCPanel5);
    DeviceReasonDeleteAddButton.Name := 'DeviceReasonDeleteAddButton';
    DeviceReasonDeleteAddButton.AlignWithMargins := True;
    DeviceReasonDeleteAddButton.Left := 20;
    DeviceReasonDeleteAddButton.Top := 8;
    DeviceReasonDeleteAddButton.Width := 100;
    DeviceReasonDeleteAddButton.Height := 31;
    DeviceReasonDeleteAddButton.Margins.Left := 0;
    DeviceReasonDeleteAddButton.Margins.Top := 8;
    DeviceReasonDeleteAddButton.Margins.Right := 8;
    DeviceReasonDeleteAddButton.Margins.Bottom := 10;
    DeviceReasonDeleteAddButton.Align := alLeft;
    DeviceReasonDeleteAddButton.Caption := 'Add';
    DeviceReasonDeleteAddButton.Color := clNone;
    DeviceReasonDeleteAddButton.ElementClassName := 'MyWebButton';
    DeviceReasonDeleteAddButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel5.DeviceReasonDeleteAddButton.Glyph.png');
    DeviceReasonDeleteAddButton.HeightPercent := 100.000000000000000000;
    DeviceReasonDeleteAddButton.TabOrder := 0;
    DeviceReasonDeleteAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceReasonDeleteAddButton, Self, 'OnClick', 'DeviceReasonDeleteAddButtonClick');
    DeviceReasonDeleteEditButton.SetParentComponent(TMSFNCPanel5);
    DeviceReasonDeleteEditButton.Name := 'DeviceReasonDeleteEditButton';
    DeviceReasonDeleteEditButton.AlignWithMargins := True;
    DeviceReasonDeleteEditButton.Left := 236;
    DeviceReasonDeleteEditButton.Top := 8;
    DeviceReasonDeleteEditButton.Width := 100;
    DeviceReasonDeleteEditButton.Height := 31;
    DeviceReasonDeleteEditButton.Margins.Left := 0;
    DeviceReasonDeleteEditButton.Margins.Top := 8;
    DeviceReasonDeleteEditButton.Margins.Right := 8;
    DeviceReasonDeleteEditButton.Margins.Bottom := 10;
    DeviceReasonDeleteEditButton.Align := alLeft;
    DeviceReasonDeleteEditButton.Caption := 'Edit';
    DeviceReasonDeleteEditButton.Color := clNone;
    DeviceReasonDeleteEditButton.ElementClassName := 'MyWebButton';
    DeviceReasonDeleteEditButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel5.DeviceReasonDeleteEditButton.Glyph.png');
    DeviceReasonDeleteEditButton.HeightPercent := 100.000000000000000000;
    DeviceReasonDeleteEditButton.TabOrder := 1;
    DeviceReasonDeleteEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceReasonDeleteEditButton, Self, 'OnClick', 'DeviceReasonDeleteViewButtonClick');
    DeviceReasonDeleteDeleteButton.SetParentComponent(TMSFNCPanel5);
    DeviceReasonDeleteDeleteButton.Name := 'DeviceReasonDeleteDeleteButton';
    DeviceReasonDeleteDeleteButton.AlignWithMargins := True;
    DeviceReasonDeleteDeleteButton.Left := 344;
    DeviceReasonDeleteDeleteButton.Top := 8;
    DeviceReasonDeleteDeleteButton.Width := 100;
    DeviceReasonDeleteDeleteButton.Height := 31;
    DeviceReasonDeleteDeleteButton.Margins.Left := 0;
    DeviceReasonDeleteDeleteButton.Margins.Top := 8;
    DeviceReasonDeleteDeleteButton.Margins.Right := 8;
    DeviceReasonDeleteDeleteButton.Margins.Bottom := 10;
    DeviceReasonDeleteDeleteButton.Align := alLeft;
    DeviceReasonDeleteDeleteButton.Caption := 'Delete';
    DeviceReasonDeleteDeleteButton.Color := clNone;
    DeviceReasonDeleteDeleteButton.ElementClassName := 'MyWebButton';
    DeviceReasonDeleteDeleteButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel5.DeviceReasonDeleteDeleteButton.Glyph.png');
    DeviceReasonDeleteDeleteButton.HeightPercent := 100.000000000000000000;
    DeviceReasonDeleteDeleteButton.TabOrder := 2;
    DeviceReasonDeleteDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceReasonDeleteDeleteButton, Self, 'OnClick', 'DeviceReasonDeleteDeleteButtonClick');
    WebSpeedButton4.SetParentComponent(TMSFNCPanel5);
    WebSpeedButton4.Name := 'WebSpeedButton4';
    WebSpeedButton4.AlignWithMargins := True;
    WebSpeedButton4.Left := 927;
    WebSpeedButton4.Top := 8;
    WebSpeedButton4.Width := 100;
    WebSpeedButton4.Height := 31;
    WebSpeedButton4.Margins.Left := 10;
    WebSpeedButton4.Margins.Top := 8;
    WebSpeedButton4.Margins.Right := 20;
    WebSpeedButton4.Margins.Bottom := 10;
    WebSpeedButton4.Align := alRight;
    WebSpeedButton4.Caption := 'Close';
    WebSpeedButton4.Color := clNone;
    WebSpeedButton4.ElementClassName := 'MyWebButton';
    WebSpeedButton4.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel5.WebSpeedButton4.Glyph.png');
    WebSpeedButton4.HeightPercent := 100.000000000000000000;
    WebSpeedButton4.TabOrder := 3;
    WebSpeedButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton4, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel6.SetParentComponent(TMSFNCPanel5);
    TMSFNCPanel6.Name := 'TMSFNCPanel6';
    TMSFNCPanel6.Tag := 2;
    TMSFNCPanel6.AlignWithMargins := True;
    TMSFNCPanel6.Left := 3;
    TMSFNCPanel6.Top := 3;
    TMSFNCPanel6.Width := 14;
    TMSFNCPanel6.Height := 41;
    TMSFNCPanel6.Margins.Bottom := 5;
    TMSFNCPanel6.Align := alLeft;
    TMSFNCPanel6.BevelInner := bvNone;
    TMSFNCPanel6.BevelOuter := bvNone;
    TMSFNCPanel6.Ctl3D := True;
    TMSFNCPanel6.ParentCtl3D := False;
    TMSFNCPanel6.ParentDoubleBuffered := False;
    TMSFNCPanel6.DoubleBuffered := True;
    TMSFNCPanel6.TabOrder := 4;
    TMSFNCPanel6.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel6.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel6.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel6.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel6.SectionsAppearance.Font.Style := [];
    TMSFNCPanel6.Stroke.Kind := gskNone;
    TMSFNCPanel6.Header.Text := 'Header';
    TMSFNCPanel6.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel6.Header.Font.Color := clWindowText;
    TMSFNCPanel6.Header.Font.Height := -11;
    TMSFNCPanel6.Header.Font.Name := 'Tahoma';
    TMSFNCPanel6.Header.Font.Style := [];
    TMSFNCPanel6.Header.Visible := False;
    TMSFNCPanel6.Footer.Text := 'Footer';
    TMSFNCPanel6.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel6.Footer.Font.Color := clWindowText;
    TMSFNCPanel6.Footer.Font.Height := -11;
    TMSFNCPanel6.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel6.Footer.Font.Style := [];
    TMSFNCPanel6.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel6.GlobalFont.Style := [];
    DeviceReasonDeleteViewButton.SetParentComponent(TMSFNCPanel5);
    DeviceReasonDeleteViewButton.Name := 'DeviceReasonDeleteViewButton';
    DeviceReasonDeleteViewButton.AlignWithMargins := True;
    DeviceReasonDeleteViewButton.Left := 128;
    DeviceReasonDeleteViewButton.Top := 8;
    DeviceReasonDeleteViewButton.Width := 100;
    DeviceReasonDeleteViewButton.Height := 31;
    DeviceReasonDeleteViewButton.Margins.Left := 0;
    DeviceReasonDeleteViewButton.Margins.Top := 8;
    DeviceReasonDeleteViewButton.Margins.Right := 8;
    DeviceReasonDeleteViewButton.Margins.Bottom := 10;
    DeviceReasonDeleteViewButton.Align := alLeft;
    DeviceReasonDeleteViewButton.Caption := 'View';
    DeviceReasonDeleteViewButton.Color := clNone;
    DeviceReasonDeleteViewButton.ElementClassName := 'MyWebButton';
    DeviceReasonDeleteViewButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel5.DeviceReasonDeleteViewButton.Glyph.png');
    DeviceReasonDeleteViewButton.HeightPercent := 100.000000000000000000;
    DeviceReasonDeleteViewButton.TabOrder := 5;
    DeviceReasonDeleteViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceReasonDeleteViewButton, Self, 'OnClick', 'DeviceReasonDeleteViewButtonClick');
    TMSFNCPageControlContainer3.SetParentComponent(TMSFNCPageControlDevice);
    TMSFNCPageControlContainer3.Name := 'TMSFNCPageControlContainer3';
    TMSFNCPageControlContainer3.AlignWithMargins := True;
    TMSFNCPageControlContainer3.Left := 0;
    TMSFNCPageControlContainer3.Top := 52;
    TMSFNCPageControlContainer3.Width := 1053;
    TMSFNCPageControlContainer3.Height := 512;
    TMSFNCPageControlContainer3.Margins.Left := 0;
    TMSFNCPageControlContainer3.Margins.Top := 52;
    TMSFNCPageControlContainer3.Margins.Right := 0;
    TMSFNCPageControlContainer3.Margins.Bottom := 0;
    TMSFNCPageControlContainer3.Align := alClient;
    TMSFNCPageControlContainer3.ParentDoubleBuffered := False;
    TMSFNCPageControlContainer3.DoubleBuffered := True;
    TMSFNCPageControlContainer3.TabStop := False;
    TMSFNCPageControlContainer3.TabOrder := 0;
    TMSFNCPageControlContainer3.PageIndex := 0;
    TMSFNCPageControlContainer3.IsActive := True;
    TMSFNCPanel7.SetParentComponent(TMSFNCPageControlContainer3);
    TMSFNCPanel7.Name := 'TMSFNCPanel7';
    TMSFNCPanel7.Tag := 1;
    TMSFNCPanel7.AlignWithMargins := True;
    TMSFNCPanel7.Left := 3;
    TMSFNCPanel7.Top := 3;
    TMSFNCPanel7.Width := 1047;
    TMSFNCPanel7.Height := 57;
    TMSFNCPanel7.Align := alTop;
    TMSFNCPanel7.BevelInner := bvNone;
    TMSFNCPanel7.BevelOuter := bvNone;
    TMSFNCPanel7.Ctl3D := True;
    TMSFNCPanel7.ParentCtl3D := False;
    TMSFNCPanel7.ParentDoubleBuffered := False;
    TMSFNCPanel7.DoubleBuffered := True;
    TMSFNCPanel7.TabOrder := 0;
    TMSFNCPanel7.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel7.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel7.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel7.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel7.SectionsAppearance.Font.Style := [];
    TMSFNCPanel7.Stroke.Kind := gskNone;
    TMSFNCPanel7.Header.Text := 'Header';
    TMSFNCPanel7.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel7.Header.Font.Color := clWindowText;
    TMSFNCPanel7.Header.Font.Height := -11;
    TMSFNCPanel7.Header.Font.Name := 'Tahoma';
    TMSFNCPanel7.Header.Font.Style := [];
    TMSFNCPanel7.Header.Visible := False;
    TMSFNCPanel7.Footer.Text := 'Footer';
    TMSFNCPanel7.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel7.Footer.Font.Color := clWindowText;
    TMSFNCPanel7.Footer.Font.Height := -11;
    TMSFNCPanel7.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel7.Footer.Font.Style := [];
    TMSFNCPanel7.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel7.GlobalFont.Style := [];
    DeviceModelEdit.SetParentComponent(TMSFNCPanel7);
    DeviceModelEdit.Name := 'DeviceModelEdit';
    DeviceModelEdit.Left := 17;
    DeviceModelEdit.Top := 10;
    DeviceModelEdit.Width := 345;
    DeviceModelEdit.Height := 34;
    DeviceModelEdit.AutoFocus := True;
    DeviceModelEdit.ChildOrder := 1;
    DeviceModelEdit.Font.Charset := ANSI_CHARSET;
    DeviceModelEdit.Font.Color := clWindowText;
    DeviceModelEdit.Font.Height := -16;
    DeviceModelEdit.Font.Name := 'Segoe UI';
    DeviceModelEdit.Font.Style := [];
    DeviceModelEdit.HeightPercent := 100.000000000000000000;
    DeviceModelEdit.ParentFont := False;
    DeviceModelEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceModelEdit, Self, 'OnChange', 'SearchEditChange');
    TMSFNCPanel8.SetParentComponent(TMSFNCPageControlContainer3);
    TMSFNCPanel8.Name := 'TMSFNCPanel8';
    TMSFNCPanel8.Tag := 1;
    TMSFNCPanel8.AlignWithMargins := True;
    TMSFNCPanel8.Left := 3;
    TMSFNCPanel8.Top := 460;
    TMSFNCPanel8.Width := 1047;
    TMSFNCPanel8.Height := 49;
    TMSFNCPanel8.Align := alBottom;
    TMSFNCPanel8.BevelInner := bvNone;
    TMSFNCPanel8.BevelOuter := bvNone;
    TMSFNCPanel8.Ctl3D := True;
    TMSFNCPanel8.ParentCtl3D := False;
    TMSFNCPanel8.ParentDoubleBuffered := False;
    TMSFNCPanel8.DoubleBuffered := True;
    TMSFNCPanel8.TabOrder := 1;
    TMSFNCPanel8.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel8.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel8.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel8.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel8.SectionsAppearance.Font.Style := [];
    TMSFNCPanel8.Stroke.Kind := gskNone;
    TMSFNCPanel8.Header.Text := 'Header';
    TMSFNCPanel8.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel8.Header.Font.Color := clWindowText;
    TMSFNCPanel8.Header.Font.Height := -11;
    TMSFNCPanel8.Header.Font.Name := 'Tahoma';
    TMSFNCPanel8.Header.Font.Style := [];
    TMSFNCPanel8.Header.Visible := False;
    TMSFNCPanel8.Footer.Text := 'Footer';
    TMSFNCPanel8.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel8.Footer.Font.Color := clWindowText;
    TMSFNCPanel8.Footer.Font.Height := -11;
    TMSFNCPanel8.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel8.Footer.Font.Style := [];
    TMSFNCPanel8.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel8.GlobalFont.Style := [];
    DeviceModelEditButton.SetParentComponent(TMSFNCPanel8);
    DeviceModelEditButton.Name := 'DeviceModelEditButton';
    DeviceModelEditButton.AlignWithMargins := True;
    DeviceModelEditButton.Left := 128;
    DeviceModelEditButton.Top := 8;
    DeviceModelEditButton.Width := 100;
    DeviceModelEditButton.Height := 31;
    DeviceModelEditButton.Margins.Left := 0;
    DeviceModelEditButton.Margins.Top := 8;
    DeviceModelEditButton.Margins.Right := 8;
    DeviceModelEditButton.Margins.Bottom := 10;
    DeviceModelEditButton.Align := alLeft;
    DeviceModelEditButton.Caption := 'Edit';
    DeviceModelEditButton.Color := clNone;
    DeviceModelEditButton.ElementClassName := 'MyWebButton';
    DeviceModelEditButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel8.DeviceModelEditButton.Glyph.png');
    DeviceModelEditButton.HeightPercent := 100.000000000000000000;
    DeviceModelEditButton.TabOrder := 0;
    DeviceModelEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceModelEditButton, Self, 'OnClick', 'DeviceModelEditButtonClick');
    WebSpeedButton6.SetParentComponent(TMSFNCPanel8);
    WebSpeedButton6.Name := 'WebSpeedButton6';
    WebSpeedButton6.AlignWithMargins := True;
    WebSpeedButton6.Left := 927;
    WebSpeedButton6.Top := 8;
    WebSpeedButton6.Width := 100;
    WebSpeedButton6.Height := 31;
    WebSpeedButton6.Margins.Left := 10;
    WebSpeedButton6.Margins.Top := 8;
    WebSpeedButton6.Margins.Right := 20;
    WebSpeedButton6.Margins.Bottom := 10;
    WebSpeedButton6.Align := alRight;
    WebSpeedButton6.Caption := 'Close';
    WebSpeedButton6.Color := clNone;
    WebSpeedButton6.ElementClassName := 'MyWebButton';
    WebSpeedButton6.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel8.WebSpeedButton6.Glyph.png');
    WebSpeedButton6.HeightPercent := 100.000000000000000000;
    WebSpeedButton6.TabOrder := 1;
    WebSpeedButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton6, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel10.SetParentComponent(TMSFNCPanel8);
    TMSFNCPanel10.Name := 'TMSFNCPanel10';
    TMSFNCPanel10.Tag := 2;
    TMSFNCPanel10.AlignWithMargins := True;
    TMSFNCPanel10.Left := 3;
    TMSFNCPanel10.Top := 3;
    TMSFNCPanel10.Width := 14;
    TMSFNCPanel10.Height := 41;
    TMSFNCPanel10.Margins.Bottom := 5;
    TMSFNCPanel10.Align := alLeft;
    TMSFNCPanel10.BevelInner := bvNone;
    TMSFNCPanel10.BevelOuter := bvNone;
    TMSFNCPanel10.Ctl3D := True;
    TMSFNCPanel10.ParentCtl3D := False;
    TMSFNCPanel10.ParentDoubleBuffered := False;
    TMSFNCPanel10.DoubleBuffered := True;
    TMSFNCPanel10.TabOrder := 2;
    TMSFNCPanel10.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel10.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel10.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel10.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel10.SectionsAppearance.Font.Style := [];
    TMSFNCPanel10.Stroke.Kind := gskNone;
    TMSFNCPanel10.Header.Text := 'Header';
    TMSFNCPanel10.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel10.Header.Font.Color := clWindowText;
    TMSFNCPanel10.Header.Font.Height := -11;
    TMSFNCPanel10.Header.Font.Name := 'Tahoma';
    TMSFNCPanel10.Header.Font.Style := [];
    TMSFNCPanel10.Header.Visible := False;
    TMSFNCPanel10.Footer.Text := 'Footer';
    TMSFNCPanel10.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel10.Footer.Font.Color := clWindowText;
    TMSFNCPanel10.Footer.Font.Height := -11;
    TMSFNCPanel10.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel10.Footer.Font.Style := [];
    TMSFNCPanel10.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel10.GlobalFont.Style := [];
    DeviceModelViewButton.SetParentComponent(TMSFNCPanel8);
    DeviceModelViewButton.Name := 'DeviceModelViewButton';
    DeviceModelViewButton.AlignWithMargins := True;
    DeviceModelViewButton.Left := 20;
    DeviceModelViewButton.Top := 8;
    DeviceModelViewButton.Width := 100;
    DeviceModelViewButton.Height := 31;
    DeviceModelViewButton.Margins.Left := 0;
    DeviceModelViewButton.Margins.Top := 8;
    DeviceModelViewButton.Margins.Right := 8;
    DeviceModelViewButton.Margins.Bottom := 10;
    DeviceModelViewButton.Align := alLeft;
    DeviceModelViewButton.Caption := 'View';
    DeviceModelViewButton.Color := clNone;
    DeviceModelViewButton.ElementClassName := 'MyWebButton';
    DeviceModelViewButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel8.DeviceModelViewButton.Glyph.png');
    DeviceModelViewButton.HeightPercent := 100.000000000000000000;
    DeviceModelViewButton.TabOrder := 3;
    DeviceModelViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceModelViewButton, Self, 'OnClick', 'DeviceModelEditButtonClick');
    DeviceModelGrid.SetParentComponent(TMSFNCPageControlContainer3);
    DeviceModelGrid.Name := 'DeviceModelGrid';
    DeviceModelGrid.AlignWithMargins := True;
    DeviceModelGrid.Left := 20;
    DeviceModelGrid.Top := 63;
    DeviceModelGrid.Width := 1013;
    DeviceModelGrid.Height := 384;
    DeviceModelGrid.Margins.Left := 20;
    DeviceModelGrid.Margins.Top := 0;
    DeviceModelGrid.Margins.Right := 20;
    DeviceModelGrid.Margins.Bottom := 10;
    DeviceModelGrid.AdaptToStyle := True;
    DeviceModelGrid.Align := alClient;
    DeviceModelGrid.BevelInner := bvNone;
    DeviceModelGrid.BevelOuter := bvNone;
    DeviceModelGrid.BevelWidth := 2;
    DeviceModelGrid.Ctl3D := False;
    DeviceModelGrid.ParentCtl3D := False;
    DeviceModelGrid.ParentDoubleBuffered := False;
    DeviceModelGrid.DoubleBuffered := True;
    DeviceModelGrid.Font.Charset := DEFAULT_CHARSET;
    DeviceModelGrid.Font.Color := clWindowText;
    DeviceModelGrid.Font.Height := -11;
    DeviceModelGrid.Font.Name := 'Tahoma';
    DeviceModelGrid.Font.Style := [];
    DeviceModelGrid.ParentFont := False;
    DeviceModelGrid.TabOrder := 2;
    SetEvent(DeviceModelGrid, Self, 'OnDblClick', 'DeviceModelGridDblClick');
    DeviceModelGrid.DefaultRowHeight := 34.000000000000000000;
    DeviceModelGrid.FixedColumns := 0;
    DeviceModelGrid.ColumnCount := 2;
    DeviceModelGrid.RowCount := 1;
    DeviceModelGrid.Options.Borders.FixedCellBorders := bNone;
    DeviceModelGrid.Options.ColumnSize.Stretch := True;
    DeviceModelGrid.Options.ColumnSize.StretchColumn := 1;
    DeviceModelGrid.Options.Editing.CalcFormat := '%g';
    DeviceModelGrid.Options.Editing.Enabled := False;
    DeviceModelGrid.Options.Grouping.CalcFormat := '%g';
    DeviceModelGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DeviceModelGrid.Options.HTMLExport.CellPadding := 10;
    DeviceModelGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DeviceModelGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DeviceModelGrid.Options.Mouse.ClickMargin := 0;
    DeviceModelGrid.Options.Mouse.ColumnSizeMargin := 6;
    DeviceModelGrid.Options.Mouse.RowSizeMargin := 6;
    DeviceModelGrid.Options.URL.Color := clSkyBlue;
    DeviceModelGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DeviceModelGrid.Options.Selection.Mode := smSingleRow;
    DeviceModelGrid.Adapter := DeviceModelGridAdapter;
    DeviceModelGrid.HorizontalScrollBarVisible := False;
    DeviceModelGrid.Columns.Clear;
    with DeviceModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1.000000000000000000;
    end;
    with DeviceModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 1011.000000000000000000;
    end;
    DeviceModelGrid.Fill.ColorTo := clSilver;
    DeviceModelGrid.DefaultFont.Charset := ANSI_CHARSET;
    DeviceModelGrid.DefaultFont.Color := clWindowText;
    DeviceModelGrid.DefaultFont.Height := -16;
    DeviceModelGrid.DefaultFont.Name := 'Segoe UI';
    DeviceModelGrid.DefaultFont.Style := [];
    DeviceModelGrid.Stroke.Color := 15987699;
    DeviceModelGrid.Stroke.Width := 2.000000000000000000;
    DeviceModelGrid.TopRow := 1;
    DeviceModelGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DeviceModelGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DeviceModelGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DeviceModelGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DeviceModelGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DeviceModelGrid.Appearance.FixedLayout.Font.Height := -16;
    DeviceModelGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DeviceModelGrid.Appearance.FixedLayout.Font.Style := [];
    DeviceModelGrid.Appearance.NormalLayout.Fill.Color := -1;
    DeviceModelGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DeviceModelGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DeviceModelGrid.Appearance.NormalLayout.Font.Color := clGray;
    DeviceModelGrid.Appearance.NormalLayout.Font.Height := -11;
    DeviceModelGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DeviceModelGrid.Appearance.NormalLayout.Font.Style := [];
    DeviceModelGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DeviceModelGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DeviceModelGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DeviceModelGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DeviceModelGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DeviceModelGrid.Appearance.GroupLayout.Font.Height := -11;
    DeviceModelGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DeviceModelGrid.Appearance.GroupLayout.Font.Style := [];
    DeviceModelGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DeviceModelGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DeviceModelGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DeviceModelGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DeviceModelGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DeviceModelGrid.Appearance.SummaryLayout.Font.Height := -11;
    DeviceModelGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DeviceModelGrid.Appearance.SummaryLayout.Font.Style := [];
    DeviceModelGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DeviceModelGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DeviceModelGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DeviceModelGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DeviceModelGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DeviceModelGrid.Appearance.SelectedLayout.Font.Height := -16;
    DeviceModelGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DeviceModelGrid.Appearance.SelectedLayout.Font.Style := [];
    DeviceModelGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DeviceModelGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DeviceModelGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DeviceModelGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DeviceModelGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DeviceModelGrid.Appearance.FocusedLayout.Font.Height := -16;
    DeviceModelGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DeviceModelGrid.Appearance.FocusedLayout.Font.Style := [];
    DeviceModelGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DeviceModelGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DeviceModelGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DeviceModelGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DeviceModelGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DeviceModelGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DeviceModelGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DeviceModelGrid.Appearance.BandLayout.Font.Color := clBlack;
    DeviceModelGrid.Appearance.BandLayout.Font.Height := -16;
    DeviceModelGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DeviceModelGrid.Appearance.BandLayout.Font.Style := [];
    DeviceModelGrid.Appearance.ProgressLayout.Color := 15385233;
    DeviceModelGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DeviceModelGrid.LeftCol := 0;
    DeviceModelGrid.ScrollMode := scmItemScrolling;
    DeviceModelGrid.GlobalFont.Scale := 1.000000000000000000;
    DeviceModelGrid.GlobalFont.Style := [];
    DeviceModelGrid.DesignTimeSampleData := True;
    TMSFNCPageControlPage2.SetParentComponent(TMSFNCPageControl);
    TMSFNCPageControlPage2.Name := 'TMSFNCPageControlPage2';
    TMSFNCPageControlPage2.AlignWithMargins := True;
    TMSFNCPageControlPage2.Left := 0;
    TMSFNCPageControlPage2.Top := 52;
    TMSFNCPageControlPage2.Width := 1073;
    TMSFNCPageControlPage2.Height := 572;
    TMSFNCPageControlPage2.Margins.Left := 0;
    TMSFNCPageControlPage2.Margins.Top := 52;
    TMSFNCPageControlPage2.Margins.Right := 0;
    TMSFNCPageControlPage2.Margins.Bottom := 0;
    TMSFNCPageControlPage2.Align := alClient;
    TMSFNCPageControlPage2.ParentDoubleBuffered := False;
    TMSFNCPageControlPage2.DoubleBuffered := True;
    TMSFNCPageControlPage2.TabStop := False;
    TMSFNCPageControlPage2.TabOrder := 2;
    TMSFNCPageControlPage2.Visible := False;
    TMSFNCPageControlPage2.PageIndex := 3;
    TMSFNCPageControlPage2.IsActive := False;
    TMSFNCPageControlAsset.SetParentComponent(TMSFNCPageControlPage2);
    TMSFNCPageControlAsset.Name := 'TMSFNCPageControlAsset';
    TMSFNCPageControlAsset.AlignWithMargins := True;
    TMSFNCPageControlAsset.Left := 10;
    TMSFNCPageControlAsset.Top := 0;
    TMSFNCPageControlAsset.Width := 1053;
    TMSFNCPageControlAsset.Height := 564;
    TMSFNCPageControlAsset.Margins.Left := 10;
    TMSFNCPageControlAsset.Margins.Top := 0;
    TMSFNCPageControlAsset.Margins.Right := 10;
    TMSFNCPageControlAsset.Margins.Bottom := 8;
    TMSFNCPageControlAsset.Align := alClient;
    TMSFNCPageControlAsset.BevelEdges := [];
    TMSFNCPageControlAsset.BevelInner := bvNone;
    TMSFNCPageControlAsset.BevelOuter := bvNone;
    TMSFNCPageControlAsset.ParentDoubleBuffered := False;
    TMSFNCPageControlAsset.Color := clBtnFace;
    TMSFNCPageControlAsset.DoubleBuffered := True;
    TMSFNCPageControlAsset.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPageControlAsset.Font.Color := clWindowText;
    TMSFNCPageControlAsset.Font.Height := -16;
    TMSFNCPageControlAsset.Font.Name := 'Tahoma';
    TMSFNCPageControlAsset.Font.Style := [];
    TMSFNCPageControlAsset.ParentFont := False;
    TMSFNCPageControlAsset.TabOrder := 0;
    TMSFNCPageControlAsset.Fill.Kind := gfkNone;
    TMSFNCPageControlAsset.Fill.ColorTo := clWhite;
    TMSFNCPageControlAsset.Stroke.Kind := gskNone;
    TMSFNCPageControlAsset.TabAppearance.Font.Charset := ANSI_CHARSET;
    TMSFNCPageControlAsset.TabAppearance.Font.Color := clAqua;
    TMSFNCPageControlAsset.TabAppearance.Font.Height := -16;
    TMSFNCPageControlAsset.TabAppearance.Font.Name := 'Segoe UI';
    TMSFNCPageControlAsset.TabAppearance.Font.Style := [];
    TMSFNCPageControlAsset.TabAppearance.Fill.Color := 14408667;
    TMSFNCPageControlAsset.TabAppearance.Stroke.Color := -1;
    TMSFNCPageControlAsset.TabAppearance.ActiveFill.ColorTo := clWhite;
    TMSFNCPageControlAsset.TabAppearance.HoverFill.Color := 15658734;
    TMSFNCPageControlAsset.TabAppearance.HoverStroke.Color := -1;
    TMSFNCPageControlAsset.TabAppearance.TextSpacing := 10.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.Shape := tsRectangle;
    TMSFNCPageControlAsset.TabAppearance.ShapeOverlap := 0.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.ShapeSlope := 0.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.ShapeRounding := 0.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.TextColor := clGrayText;
    TMSFNCPageControlAsset.TabAppearance.ActiveTextColor := clGrayText;
    TMSFNCPageControlAsset.TabAppearance.HoverTextColor := clGrayText;
    TMSFNCPageControlAsset.TabAppearance.ShowFocus := False;
    TMSFNCPageControlAsset.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    TMSFNCPageControlAsset.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    TMSFNCPageControlAsset.TabAppearance.BadgeFont.Color := 139;
    TMSFNCPageControlAsset.TabAppearance.BadgeFont.Height := -11;
    TMSFNCPageControlAsset.TabAppearance.BadgeFont.Name := 'Tahoma';
    TMSFNCPageControlAsset.TabAppearance.BadgeFont.Style := [fsBold];
    TMSFNCPageControlAsset.ButtonAppearance.CloseIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage2.TMSFNCPageControlAsset.ButtonAppearance.CloseIcon.svg');
    TMSFNCPageControlAsset.ButtonAppearance.InsertIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage2.TMSFNCPageControlAsset.ButtonAppearance.InsertIcon.svg');
    TMSFNCPageControlAsset.ButtonAppearance.TabListIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage2.TMSFNCPageControlAsset.ButtonAppearance.TabListIcon.svg');
    TMSFNCPageControlAsset.ButtonAppearance.ScrollNextIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage2.TMSFNCPageControlAsset.ButtonAppearance.ScrollNextIcon.svg');
    TMSFNCPageControlAsset.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uGeneralSettings.TMSFNCPageControlPage2.TMSFNCPageControlAsset.ButtonAppearance.ScrollPreviousIcon.svg');
    TMSFNCPageControlAsset.TabSize.Height := 45.000000000000000000;
    TMSFNCPageControlAsset.TabSize.Margins.Left := 8.000000000000000000;
    TMSFNCPageControlAsset.TabSize.Margins.Top := 8.000000000000000000;
    TMSFNCPageControlAsset.TabSize.Margins.Right := 8.000000000000000000;
    TMSFNCPageControlAsset.TabSize.Margins.Bottom := 8.000000000000000000;
    TMSFNCPageControlAsset.Pages.Clear;
    with TMSFNCPageControlAsset.Pages.Add do
    begin
      Text := 'Status';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlAsset.Pages.Add do
    begin
      Text := 'Group';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlAsset.Pages.Add do
    begin
      Text := 'Type';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlAsset.Pages.Add do
    begin
      Text := 'Brand';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlAsset.Pages.Add do
    begin
      Text := 'Model';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControlAsset.Pages.Add do
    begin
      Text := 'Repair';
      UseDefaultAppearance := True;
    end;
    SetEvent(TMSFNCPageControlAsset, Self, 'OnBeforeChangePage', 'TMSFNCPageControlAssetBeforeChangePage');
    SetEvent(TMSFNCPageControlAsset, Self, 'OnChangePage', 'TMSFNCPageControlAssetChangePage');
    TMSFNCPageControl1Page2.SetParentComponent(TMSFNCPageControlAsset);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 1053;
    TMSFNCPageControl1Page2.Height := 512;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    AssetTypeFilterPanel.SetParentComponent(TMSFNCPageControl1Page2);
    AssetTypeFilterPanel.Name := 'AssetTypeFilterPanel';
    AssetTypeFilterPanel.Tag := 1;
    AssetTypeFilterPanel.AlignWithMargins := True;
    AssetTypeFilterPanel.Left := 3;
    AssetTypeFilterPanel.Top := 3;
    AssetTypeFilterPanel.Width := 1047;
    AssetTypeFilterPanel.Height := 57;
    AssetTypeFilterPanel.Align := alTop;
    AssetTypeFilterPanel.BevelInner := bvNone;
    AssetTypeFilterPanel.BevelOuter := bvNone;
    AssetTypeFilterPanel.Ctl3D := True;
    AssetTypeFilterPanel.ParentCtl3D := False;
    AssetTypeFilterPanel.ParentDoubleBuffered := False;
    AssetTypeFilterPanel.DoubleBuffered := True;
    AssetTypeFilterPanel.TabOrder := 0;
    AssetTypeFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetTypeFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetTypeFilterPanel.SectionsAppearance.Font.Height := -11;
    AssetTypeFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetTypeFilterPanel.SectionsAppearance.Font.Style := [];
    AssetTypeFilterPanel.Stroke.Kind := gskNone;
    AssetTypeFilterPanel.Header.Text := 'Header';
    AssetTypeFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetTypeFilterPanel.Header.Font.Color := clWindowText;
    AssetTypeFilterPanel.Header.Font.Height := -11;
    AssetTypeFilterPanel.Header.Font.Name := 'Tahoma';
    AssetTypeFilterPanel.Header.Font.Style := [];
    AssetTypeFilterPanel.Header.Visible := False;
    AssetTypeFilterPanel.Footer.Text := 'Footer';
    AssetTypeFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetTypeFilterPanel.Footer.Font.Color := clWindowText;
    AssetTypeFilterPanel.Footer.Font.Height := -11;
    AssetTypeFilterPanel.Footer.Font.Name := 'Tahoma';
    AssetTypeFilterPanel.Footer.Font.Style := [];
    AssetTypeFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetTypeFilterPanel.GlobalFont.Style := [];
    AssetTypeEdit.SetParentComponent(AssetTypeFilterPanel);
    AssetTypeEdit.Name := 'AssetTypeEdit';
    AssetTypeEdit.Left := 17;
    AssetTypeEdit.Top := 10;
    AssetTypeEdit.Width := 345;
    AssetTypeEdit.Height := 34;
    AssetTypeEdit.AutoFocus := True;
    AssetTypeEdit.ChildOrder := 1;
    AssetTypeEdit.Font.Charset := ANSI_CHARSET;
    AssetTypeEdit.Font.Color := clWindowText;
    AssetTypeEdit.Font.Height := -16;
    AssetTypeEdit.Font.Name := 'Segoe UI';
    AssetTypeEdit.Font.Style := [];
    AssetTypeEdit.HeightPercent := 100.000000000000000000;
    AssetTypeEdit.ParentFont := False;
    AssetTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetTypeEdit, Self, 'OnChange', 'SearchEditChange');
    AssetTypeButtonPanel.SetParentComponent(TMSFNCPageControl1Page2);
    AssetTypeButtonPanel.Name := 'AssetTypeButtonPanel';
    AssetTypeButtonPanel.Tag := 1;
    AssetTypeButtonPanel.AlignWithMargins := True;
    AssetTypeButtonPanel.Left := 3;
    AssetTypeButtonPanel.Top := 460;
    AssetTypeButtonPanel.Width := 1047;
    AssetTypeButtonPanel.Height := 49;
    AssetTypeButtonPanel.Align := alBottom;
    AssetTypeButtonPanel.BevelInner := bvNone;
    AssetTypeButtonPanel.BevelOuter := bvNone;
    AssetTypeButtonPanel.Ctl3D := True;
    AssetTypeButtonPanel.ParentCtl3D := False;
    AssetTypeButtonPanel.ParentDoubleBuffered := False;
    AssetTypeButtonPanel.DoubleBuffered := True;
    AssetTypeButtonPanel.TabOrder := 1;
    AssetTypeButtonPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetTypeButtonPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetTypeButtonPanel.SectionsAppearance.Font.Height := -11;
    AssetTypeButtonPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetTypeButtonPanel.SectionsAppearance.Font.Style := [];
    AssetTypeButtonPanel.Stroke.Kind := gskNone;
    AssetTypeButtonPanel.Header.Text := 'Header';
    AssetTypeButtonPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetTypeButtonPanel.Header.Font.Color := clWindowText;
    AssetTypeButtonPanel.Header.Font.Height := -11;
    AssetTypeButtonPanel.Header.Font.Name := 'Tahoma';
    AssetTypeButtonPanel.Header.Font.Style := [];
    AssetTypeButtonPanel.Header.Visible := False;
    AssetTypeButtonPanel.Footer.Text := 'Footer';
    AssetTypeButtonPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetTypeButtonPanel.Footer.Font.Color := clWindowText;
    AssetTypeButtonPanel.Footer.Font.Height := -11;
    AssetTypeButtonPanel.Footer.Font.Name := 'Tahoma';
    AssetTypeButtonPanel.Footer.Font.Style := [];
    AssetTypeButtonPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetTypeButtonPanel.GlobalFont.Style := [];
    AssetTypeAddButton.SetParentComponent(AssetTypeButtonPanel);
    AssetTypeAddButton.Name := 'AssetTypeAddButton';
    AssetTypeAddButton.AlignWithMargins := True;
    AssetTypeAddButton.Left := 20;
    AssetTypeAddButton.Top := 8;
    AssetTypeAddButton.Width := 100;
    AssetTypeAddButton.Height := 31;
    AssetTypeAddButton.Margins.Left := 0;
    AssetTypeAddButton.Margins.Top := 8;
    AssetTypeAddButton.Margins.Right := 8;
    AssetTypeAddButton.Margins.Bottom := 10;
    AssetTypeAddButton.Align := alLeft;
    AssetTypeAddButton.Caption := 'Add';
    AssetTypeAddButton.Color := clNone;
    AssetTypeAddButton.ElementClassName := 'MyWebButton';
    AssetTypeAddButton.Glyph.LoadFromFile('uGeneralSettings.AssetTypeButtonPanel.AssetTypeAddButton.Glyph.png');
    AssetTypeAddButton.HeightPercent := 100.000000000000000000;
    AssetTypeAddButton.TabOrder := 0;
    AssetTypeAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetTypeAddButton, Self, 'OnClick', 'AssetTypeAddButtonClick');
    AssetTypeEditButton.SetParentComponent(AssetTypeButtonPanel);
    AssetTypeEditButton.Name := 'AssetTypeEditButton';
    AssetTypeEditButton.AlignWithMargins := True;
    AssetTypeEditButton.Left := 236;
    AssetTypeEditButton.Top := 8;
    AssetTypeEditButton.Width := 100;
    AssetTypeEditButton.Height := 31;
    AssetTypeEditButton.Margins.Left := 0;
    AssetTypeEditButton.Margins.Top := 8;
    AssetTypeEditButton.Margins.Right := 8;
    AssetTypeEditButton.Margins.Bottom := 10;
    AssetTypeEditButton.Align := alLeft;
    AssetTypeEditButton.Caption := 'Edit';
    AssetTypeEditButton.Color := clNone;
    AssetTypeEditButton.ElementClassName := 'MyWebButton';
    AssetTypeEditButton.Glyph.LoadFromFile('uGeneralSettings.AssetTypeButtonPanel.AssetTypeEditButton.Glyph.png');
    AssetTypeEditButton.HeightPercent := 100.000000000000000000;
    AssetTypeEditButton.TabOrder := 1;
    AssetTypeEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetTypeEditButton, Self, 'OnClick', 'AssetTypeEditButtonClick');
    AssetTypeDeleteButton.SetParentComponent(AssetTypeButtonPanel);
    AssetTypeDeleteButton.Name := 'AssetTypeDeleteButton';
    AssetTypeDeleteButton.AlignWithMargins := True;
    AssetTypeDeleteButton.Left := 344;
    AssetTypeDeleteButton.Top := 8;
    AssetTypeDeleteButton.Width := 100;
    AssetTypeDeleteButton.Height := 31;
    AssetTypeDeleteButton.Margins.Left := 0;
    AssetTypeDeleteButton.Margins.Top := 8;
    AssetTypeDeleteButton.Margins.Right := 8;
    AssetTypeDeleteButton.Margins.Bottom := 10;
    AssetTypeDeleteButton.Align := alLeft;
    AssetTypeDeleteButton.Caption := 'Delete';
    AssetTypeDeleteButton.Color := clNone;
    AssetTypeDeleteButton.ElementClassName := 'MyWebButton';
    AssetTypeDeleteButton.Glyph.LoadFromFile('uGeneralSettings.AssetTypeButtonPanel.AssetTypeDeleteButton.Glyph.png');
    AssetTypeDeleteButton.HeightPercent := 100.000000000000000000;
    AssetTypeDeleteButton.TabOrder := 2;
    AssetTypeDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetTypeDeleteButton, Self, 'OnClick', 'AssetTypeDeleteButtonClick');
    WebSpeedButton10.SetParentComponent(AssetTypeButtonPanel);
    WebSpeedButton10.Name := 'WebSpeedButton10';
    WebSpeedButton10.AlignWithMargins := True;
    WebSpeedButton10.Left := 927;
    WebSpeedButton10.Top := 8;
    WebSpeedButton10.Width := 100;
    WebSpeedButton10.Height := 31;
    WebSpeedButton10.Margins.Left := 10;
    WebSpeedButton10.Margins.Top := 8;
    WebSpeedButton10.Margins.Right := 20;
    WebSpeedButton10.Margins.Bottom := 10;
    WebSpeedButton10.Align := alRight;
    WebSpeedButton10.Caption := 'Close';
    WebSpeedButton10.Color := clNone;
    WebSpeedButton10.ElementClassName := 'MyWebButton';
    WebSpeedButton10.Glyph.LoadFromFile('uGeneralSettings.AssetTypeButtonPanel.WebSpeedButton10.Glyph.png');
    WebSpeedButton10.HeightPercent := 100.000000000000000000;
    WebSpeedButton10.TabOrder := 3;
    WebSpeedButton10.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton10, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel12.SetParentComponent(AssetTypeButtonPanel);
    TMSFNCPanel12.Name := 'TMSFNCPanel12';
    TMSFNCPanel12.Tag := 2;
    TMSFNCPanel12.AlignWithMargins := True;
    TMSFNCPanel12.Left := 3;
    TMSFNCPanel12.Top := 3;
    TMSFNCPanel12.Width := 14;
    TMSFNCPanel12.Height := 41;
    TMSFNCPanel12.Margins.Bottom := 5;
    TMSFNCPanel12.Align := alLeft;
    TMSFNCPanel12.BevelInner := bvNone;
    TMSFNCPanel12.BevelOuter := bvNone;
    TMSFNCPanel12.Ctl3D := True;
    TMSFNCPanel12.ParentCtl3D := False;
    TMSFNCPanel12.ParentDoubleBuffered := False;
    TMSFNCPanel12.DoubleBuffered := True;
    TMSFNCPanel12.TabOrder := 4;
    TMSFNCPanel12.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel12.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel12.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel12.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel12.SectionsAppearance.Font.Style := [];
    TMSFNCPanel12.Stroke.Kind := gskNone;
    TMSFNCPanel12.Header.Text := 'Header';
    TMSFNCPanel12.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel12.Header.Font.Color := clWindowText;
    TMSFNCPanel12.Header.Font.Height := -11;
    TMSFNCPanel12.Header.Font.Name := 'Tahoma';
    TMSFNCPanel12.Header.Font.Style := [];
    TMSFNCPanel12.Header.Visible := False;
    TMSFNCPanel12.Footer.Text := 'Footer';
    TMSFNCPanel12.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel12.Footer.Font.Color := clWindowText;
    TMSFNCPanel12.Footer.Font.Height := -11;
    TMSFNCPanel12.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel12.Footer.Font.Style := [];
    TMSFNCPanel12.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel12.GlobalFont.Style := [];
    AssetTypeViewButton.SetParentComponent(AssetTypeButtonPanel);
    AssetTypeViewButton.Name := 'AssetTypeViewButton';
    AssetTypeViewButton.AlignWithMargins := True;
    AssetTypeViewButton.Left := 128;
    AssetTypeViewButton.Top := 8;
    AssetTypeViewButton.Width := 100;
    AssetTypeViewButton.Height := 31;
    AssetTypeViewButton.Margins.Left := 0;
    AssetTypeViewButton.Margins.Top := 8;
    AssetTypeViewButton.Margins.Right := 8;
    AssetTypeViewButton.Margins.Bottom := 10;
    AssetTypeViewButton.Align := alLeft;
    AssetTypeViewButton.Caption := 'View';
    AssetTypeViewButton.Color := clNone;
    AssetTypeViewButton.ElementClassName := 'MyWebButton';
    AssetTypeViewButton.Glyph.LoadFromFile('uGeneralSettings.AssetTypeButtonPanel.AssetTypeViewButton.Glyph.png');
    AssetTypeViewButton.HeightPercent := 100.000000000000000000;
    AssetTypeViewButton.TabOrder := 5;
    AssetTypeViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetTypeViewButton, Self, 'OnClick', 'AssetTypeEditButtonClick');
    AssetTypeGrid.SetParentComponent(TMSFNCPageControl1Page2);
    AssetTypeGrid.Name := 'AssetTypeGrid';
    AssetTypeGrid.AlignWithMargins := True;
    AssetTypeGrid.Left := 20;
    AssetTypeGrid.Top := 63;
    AssetTypeGrid.Width := 1013;
    AssetTypeGrid.Height := 384;
    AssetTypeGrid.Margins.Left := 20;
    AssetTypeGrid.Margins.Top := 0;
    AssetTypeGrid.Margins.Right := 20;
    AssetTypeGrid.Margins.Bottom := 10;
    AssetTypeGrid.AdaptToStyle := True;
    AssetTypeGrid.Align := alClient;
    AssetTypeGrid.BevelInner := bvNone;
    AssetTypeGrid.BevelOuter := bvNone;
    AssetTypeGrid.BevelWidth := 2;
    AssetTypeGrid.Ctl3D := False;
    AssetTypeGrid.ParentCtl3D := False;
    AssetTypeGrid.ParentDoubleBuffered := False;
    AssetTypeGrid.DoubleBuffered := True;
    AssetTypeGrid.Font.Charset := DEFAULT_CHARSET;
    AssetTypeGrid.Font.Color := clWindowText;
    AssetTypeGrid.Font.Height := -11;
    AssetTypeGrid.Font.Name := 'Tahoma';
    AssetTypeGrid.Font.Style := [];
    AssetTypeGrid.ParentFont := False;
    AssetTypeGrid.TabOrder := 2;
    SetEvent(AssetTypeGrid, Self, 'OnDblClick', 'AssetTypeGridDblClick');
    AssetTypeGrid.DefaultRowHeight := 34.000000000000000000;
    AssetTypeGrid.FixedColumns := 0;
    AssetTypeGrid.ColumnCount := 1;
    AssetTypeGrid.RowCount := 1;
    AssetTypeGrid.Options.Borders.FixedCellBorders := bNone;
    AssetTypeGrid.Options.ColumnSize.Stretch := True;
    AssetTypeGrid.Options.ColumnSize.StretchColumn := 0;
    AssetTypeGrid.Options.Editing.CalcFormat := '%g';
    AssetTypeGrid.Options.Editing.Enabled := False;
    AssetTypeGrid.Options.Grouping.CalcFormat := '%g';
    AssetTypeGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AssetTypeGrid.Options.HTMLExport.CellPadding := 10;
    AssetTypeGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AssetTypeGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AssetTypeGrid.Options.Mouse.ClickMargin := 0;
    AssetTypeGrid.Options.Mouse.ColumnSizeMargin := 6;
    AssetTypeGrid.Options.Mouse.RowSizeMargin := 6;
    AssetTypeGrid.Options.URL.Color := clSkyBlue;
    AssetTypeGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AssetTypeGrid.Options.Selection.Mode := smSingleRow;
    AssetTypeGrid.Adapter := AssetTypeGridAdapter;
    AssetTypeGrid.HorizontalScrollBarVisible := False;
    AssetTypeGrid.Columns.Clear;
    with AssetTypeGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1012.000000000000000000;
    end;
    AssetTypeGrid.Fill.ColorTo := clSilver;
    AssetTypeGrid.DefaultFont.Charset := ANSI_CHARSET;
    AssetTypeGrid.DefaultFont.Color := clWindowText;
    AssetTypeGrid.DefaultFont.Height := -16;
    AssetTypeGrid.DefaultFont.Name := 'Segoe UI';
    AssetTypeGrid.DefaultFont.Style := [];
    AssetTypeGrid.Stroke.Color := 15987699;
    AssetTypeGrid.Stroke.Width := 2.000000000000000000;
    AssetTypeGrid.TopRow := 1;
    AssetTypeGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AssetTypeGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AssetTypeGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AssetTypeGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AssetTypeGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AssetTypeGrid.Appearance.FixedLayout.Font.Height := -16;
    AssetTypeGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AssetTypeGrid.Appearance.FixedLayout.Font.Style := [];
    AssetTypeGrid.Appearance.NormalLayout.Fill.Color := -1;
    AssetTypeGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AssetTypeGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AssetTypeGrid.Appearance.NormalLayout.Font.Color := clGray;
    AssetTypeGrid.Appearance.NormalLayout.Font.Height := -11;
    AssetTypeGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AssetTypeGrid.Appearance.NormalLayout.Font.Style := [];
    AssetTypeGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AssetTypeGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AssetTypeGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AssetTypeGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AssetTypeGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AssetTypeGrid.Appearance.GroupLayout.Font.Height := -11;
    AssetTypeGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AssetTypeGrid.Appearance.GroupLayout.Font.Style := [];
    AssetTypeGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AssetTypeGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AssetTypeGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AssetTypeGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AssetTypeGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AssetTypeGrid.Appearance.SummaryLayout.Font.Height := -11;
    AssetTypeGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AssetTypeGrid.Appearance.SummaryLayout.Font.Style := [];
    AssetTypeGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AssetTypeGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AssetTypeGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AssetTypeGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetTypeGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AssetTypeGrid.Appearance.SelectedLayout.Font.Height := -16;
    AssetTypeGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AssetTypeGrid.Appearance.SelectedLayout.Font.Style := [];
    AssetTypeGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AssetTypeGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AssetTypeGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AssetTypeGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AssetTypeGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AssetTypeGrid.Appearance.FocusedLayout.Font.Height := -16;
    AssetTypeGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AssetTypeGrid.Appearance.FocusedLayout.Font.Style := [];
    AssetTypeGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AssetTypeGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AssetTypeGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AssetTypeGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AssetTypeGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AssetTypeGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AssetTypeGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AssetTypeGrid.Appearance.BandLayout.Font.Color := clBlack;
    AssetTypeGrid.Appearance.BandLayout.Font.Height := -16;
    AssetTypeGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AssetTypeGrid.Appearance.BandLayout.Font.Style := [];
    AssetTypeGrid.Appearance.ProgressLayout.Color := 15385233;
    AssetTypeGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AssetTypeGrid.LeftCol := 0;
    AssetTypeGrid.ScrollMode := scmItemScrolling;
    AssetTypeGrid.GlobalFont.Scale := 1.000000000000000000;
    AssetTypeGrid.GlobalFont.Style := [];
    AssetTypeGrid.DesignTimeSampleData := True;
    TMSFNCPageControl1Page1.SetParentComponent(TMSFNCPageControlAsset);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 1053;
    TMSFNCPageControl1Page1.Height := 512;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    AssetGroupFilterPanel.SetParentComponent(TMSFNCPageControl1Page1);
    AssetGroupFilterPanel.Name := 'AssetGroupFilterPanel';
    AssetGroupFilterPanel.Tag := 1;
    AssetGroupFilterPanel.AlignWithMargins := True;
    AssetGroupFilterPanel.Left := 3;
    AssetGroupFilterPanel.Top := 3;
    AssetGroupFilterPanel.Width := 1047;
    AssetGroupFilterPanel.Height := 57;
    AssetGroupFilterPanel.Align := alTop;
    AssetGroupFilterPanel.BevelInner := bvNone;
    AssetGroupFilterPanel.BevelOuter := bvNone;
    AssetGroupFilterPanel.Ctl3D := True;
    AssetGroupFilterPanel.ParentCtl3D := False;
    AssetGroupFilterPanel.ParentDoubleBuffered := False;
    AssetGroupFilterPanel.DoubleBuffered := True;
    AssetGroupFilterPanel.TabOrder := 0;
    AssetGroupFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetGroupFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetGroupFilterPanel.SectionsAppearance.Font.Height := -11;
    AssetGroupFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetGroupFilterPanel.SectionsAppearance.Font.Style := [];
    AssetGroupFilterPanel.Stroke.Kind := gskNone;
    AssetGroupFilterPanel.Header.Text := 'Header';
    AssetGroupFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetGroupFilterPanel.Header.Font.Color := clWindowText;
    AssetGroupFilterPanel.Header.Font.Height := -11;
    AssetGroupFilterPanel.Header.Font.Name := 'Tahoma';
    AssetGroupFilterPanel.Header.Font.Style := [];
    AssetGroupFilterPanel.Header.Visible := False;
    AssetGroupFilterPanel.Footer.Text := 'Footer';
    AssetGroupFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetGroupFilterPanel.Footer.Font.Color := clWindowText;
    AssetGroupFilterPanel.Footer.Font.Height := -11;
    AssetGroupFilterPanel.Footer.Font.Name := 'Tahoma';
    AssetGroupFilterPanel.Footer.Font.Style := [];
    AssetGroupFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetGroupFilterPanel.GlobalFont.Style := [];
    AssetGroupEdit.SetParentComponent(AssetGroupFilterPanel);
    AssetGroupEdit.Name := 'AssetGroupEdit';
    AssetGroupEdit.Left := 17;
    AssetGroupEdit.Top := 10;
    AssetGroupEdit.Width := 345;
    AssetGroupEdit.Height := 34;
    AssetGroupEdit.AutoFocus := True;
    AssetGroupEdit.ChildOrder := 1;
    AssetGroupEdit.Font.Charset := ANSI_CHARSET;
    AssetGroupEdit.Font.Color := clWindowText;
    AssetGroupEdit.Font.Height := -16;
    AssetGroupEdit.Font.Name := 'Segoe UI';
    AssetGroupEdit.Font.Style := [];
    AssetGroupEdit.HeightPercent := 100.000000000000000000;
    AssetGroupEdit.ParentFont := False;
    AssetGroupEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupEdit, Self, 'OnChange', 'SearchEditChange');
    AssetGroupButtonPanel.SetParentComponent(TMSFNCPageControl1Page1);
    AssetGroupButtonPanel.Name := 'AssetGroupButtonPanel';
    AssetGroupButtonPanel.Tag := 1;
    AssetGroupButtonPanel.AlignWithMargins := True;
    AssetGroupButtonPanel.Left := 3;
    AssetGroupButtonPanel.Top := 460;
    AssetGroupButtonPanel.Width := 1047;
    AssetGroupButtonPanel.Height := 49;
    AssetGroupButtonPanel.Align := alBottom;
    AssetGroupButtonPanel.BevelInner := bvNone;
    AssetGroupButtonPanel.BevelOuter := bvNone;
    AssetGroupButtonPanel.Ctl3D := True;
    AssetGroupButtonPanel.ParentCtl3D := False;
    AssetGroupButtonPanel.ParentDoubleBuffered := False;
    AssetGroupButtonPanel.DoubleBuffered := True;
    AssetGroupButtonPanel.TabOrder := 1;
    AssetGroupButtonPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetGroupButtonPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetGroupButtonPanel.SectionsAppearance.Font.Height := -11;
    AssetGroupButtonPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetGroupButtonPanel.SectionsAppearance.Font.Style := [];
    AssetGroupButtonPanel.Stroke.Kind := gskNone;
    AssetGroupButtonPanel.Header.Text := 'Header';
    AssetGroupButtonPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetGroupButtonPanel.Header.Font.Color := clWindowText;
    AssetGroupButtonPanel.Header.Font.Height := -11;
    AssetGroupButtonPanel.Header.Font.Name := 'Tahoma';
    AssetGroupButtonPanel.Header.Font.Style := [];
    AssetGroupButtonPanel.Header.Visible := False;
    AssetGroupButtonPanel.Footer.Text := 'Footer';
    AssetGroupButtonPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetGroupButtonPanel.Footer.Font.Color := clWindowText;
    AssetGroupButtonPanel.Footer.Font.Height := -11;
    AssetGroupButtonPanel.Footer.Font.Name := 'Tahoma';
    AssetGroupButtonPanel.Footer.Font.Style := [];
    AssetGroupButtonPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetGroupButtonPanel.GlobalFont.Style := [];
    AssetGroupAddButton.SetParentComponent(AssetGroupButtonPanel);
    AssetGroupAddButton.Name := 'AssetGroupAddButton';
    AssetGroupAddButton.AlignWithMargins := True;
    AssetGroupAddButton.Left := 20;
    AssetGroupAddButton.Top := 8;
    AssetGroupAddButton.Width := 100;
    AssetGroupAddButton.Height := 31;
    AssetGroupAddButton.Margins.Left := 0;
    AssetGroupAddButton.Margins.Top := 8;
    AssetGroupAddButton.Margins.Right := 8;
    AssetGroupAddButton.Margins.Bottom := 10;
    AssetGroupAddButton.Align := alLeft;
    AssetGroupAddButton.Caption := 'Add';
    AssetGroupAddButton.Color := clNone;
    AssetGroupAddButton.ElementClassName := 'MyWebButton';
    AssetGroupAddButton.Glyph.LoadFromFile('uGeneralSettings.AssetGroupButtonPanel.AssetGroupAddButton.Glyph.png');
    AssetGroupAddButton.HeightPercent := 100.000000000000000000;
    AssetGroupAddButton.TabOrder := 0;
    AssetGroupAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupAddButton, Self, 'OnClick', 'AssetGroupAddButtonClick');
    AssetGroupEditButton.SetParentComponent(AssetGroupButtonPanel);
    AssetGroupEditButton.Name := 'AssetGroupEditButton';
    AssetGroupEditButton.AlignWithMargins := True;
    AssetGroupEditButton.Left := 236;
    AssetGroupEditButton.Top := 8;
    AssetGroupEditButton.Width := 100;
    AssetGroupEditButton.Height := 31;
    AssetGroupEditButton.Margins.Left := 0;
    AssetGroupEditButton.Margins.Top := 8;
    AssetGroupEditButton.Margins.Right := 8;
    AssetGroupEditButton.Margins.Bottom := 10;
    AssetGroupEditButton.Align := alLeft;
    AssetGroupEditButton.Caption := 'Edit';
    AssetGroupEditButton.Color := clNone;
    AssetGroupEditButton.ElementClassName := 'MyWebButton';
    AssetGroupEditButton.Glyph.LoadFromFile('uGeneralSettings.AssetGroupButtonPanel.AssetGroupEditButton.Glyph.png');
    AssetGroupEditButton.HeightPercent := 100.000000000000000000;
    AssetGroupEditButton.TabOrder := 1;
    AssetGroupEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupEditButton, Self, 'OnClick', 'AssetGroupEditButtonClick');
    AssetGroupDeleteButton.SetParentComponent(AssetGroupButtonPanel);
    AssetGroupDeleteButton.Name := 'AssetGroupDeleteButton';
    AssetGroupDeleteButton.AlignWithMargins := True;
    AssetGroupDeleteButton.Left := 344;
    AssetGroupDeleteButton.Top := 8;
    AssetGroupDeleteButton.Width := 100;
    AssetGroupDeleteButton.Height := 31;
    AssetGroupDeleteButton.Margins.Left := 0;
    AssetGroupDeleteButton.Margins.Top := 8;
    AssetGroupDeleteButton.Margins.Right := 8;
    AssetGroupDeleteButton.Margins.Bottom := 10;
    AssetGroupDeleteButton.Align := alLeft;
    AssetGroupDeleteButton.Caption := 'Delete';
    AssetGroupDeleteButton.Color := clNone;
    AssetGroupDeleteButton.ElementClassName := 'MyWebButton';
    AssetGroupDeleteButton.Glyph.LoadFromFile('uGeneralSettings.AssetGroupButtonPanel.AssetGroupDeleteButton.Glyph.png');
    AssetGroupDeleteButton.HeightPercent := 100.000000000000000000;
    AssetGroupDeleteButton.TabOrder := 2;
    AssetGroupDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupDeleteButton, Self, 'OnClick', 'AssetGroupDeleteButtonClick');
    WebSpeedButton5.SetParentComponent(AssetGroupButtonPanel);
    WebSpeedButton5.Name := 'WebSpeedButton5';
    WebSpeedButton5.AlignWithMargins := True;
    WebSpeedButton5.Left := 927;
    WebSpeedButton5.Top := 8;
    WebSpeedButton5.Width := 100;
    WebSpeedButton5.Height := 31;
    WebSpeedButton5.Margins.Left := 10;
    WebSpeedButton5.Margins.Top := 8;
    WebSpeedButton5.Margins.Right := 20;
    WebSpeedButton5.Margins.Bottom := 10;
    WebSpeedButton5.Align := alRight;
    WebSpeedButton5.Caption := 'Close';
    WebSpeedButton5.Color := clNone;
    WebSpeedButton5.ElementClassName := 'MyWebButton';
    WebSpeedButton5.Glyph.LoadFromFile('uGeneralSettings.AssetGroupButtonPanel.WebSpeedButton5.Glyph.png');
    WebSpeedButton5.HeightPercent := 100.000000000000000000;
    WebSpeedButton5.TabOrder := 3;
    WebSpeedButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton5, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel9.SetParentComponent(AssetGroupButtonPanel);
    TMSFNCPanel9.Name := 'TMSFNCPanel9';
    TMSFNCPanel9.Tag := 2;
    TMSFNCPanel9.AlignWithMargins := True;
    TMSFNCPanel9.Left := 3;
    TMSFNCPanel9.Top := 3;
    TMSFNCPanel9.Width := 14;
    TMSFNCPanel9.Height := 41;
    TMSFNCPanel9.Margins.Bottom := 5;
    TMSFNCPanel9.Align := alLeft;
    TMSFNCPanel9.BevelInner := bvNone;
    TMSFNCPanel9.BevelOuter := bvNone;
    TMSFNCPanel9.Ctl3D := True;
    TMSFNCPanel9.ParentCtl3D := False;
    TMSFNCPanel9.ParentDoubleBuffered := False;
    TMSFNCPanel9.DoubleBuffered := True;
    TMSFNCPanel9.TabOrder := 4;
    TMSFNCPanel9.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel9.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel9.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel9.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel9.SectionsAppearance.Font.Style := [];
    TMSFNCPanel9.Stroke.Kind := gskNone;
    TMSFNCPanel9.Header.Text := 'Header';
    TMSFNCPanel9.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel9.Header.Font.Color := clWindowText;
    TMSFNCPanel9.Header.Font.Height := -11;
    TMSFNCPanel9.Header.Font.Name := 'Tahoma';
    TMSFNCPanel9.Header.Font.Style := [];
    TMSFNCPanel9.Header.Visible := False;
    TMSFNCPanel9.Footer.Text := 'Footer';
    TMSFNCPanel9.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel9.Footer.Font.Color := clWindowText;
    TMSFNCPanel9.Footer.Font.Height := -11;
    TMSFNCPanel9.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel9.Footer.Font.Style := [];
    TMSFNCPanel9.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel9.GlobalFont.Style := [];
    AssetGroupViewButton.SetParentComponent(AssetGroupButtonPanel);
    AssetGroupViewButton.Name := 'AssetGroupViewButton';
    AssetGroupViewButton.AlignWithMargins := True;
    AssetGroupViewButton.Left := 128;
    AssetGroupViewButton.Top := 8;
    AssetGroupViewButton.Width := 100;
    AssetGroupViewButton.Height := 31;
    AssetGroupViewButton.Margins.Left := 0;
    AssetGroupViewButton.Margins.Top := 8;
    AssetGroupViewButton.Margins.Right := 8;
    AssetGroupViewButton.Margins.Bottom := 10;
    AssetGroupViewButton.Align := alLeft;
    AssetGroupViewButton.Caption := 'View';
    AssetGroupViewButton.Color := clNone;
    AssetGroupViewButton.ElementClassName := 'MyWebButton';
    AssetGroupViewButton.Glyph.LoadFromFile('uGeneralSettings.AssetGroupButtonPanel.AssetGroupViewButton.Glyph.png');
    AssetGroupViewButton.HeightPercent := 100.000000000000000000;
    AssetGroupViewButton.TabOrder := 5;
    AssetGroupViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupViewButton, Self, 'OnClick', 'AssetGroupEditButtonClick');
    AssetGroupGrid.SetParentComponent(TMSFNCPageControl1Page1);
    AssetGroupGrid.Name := 'AssetGroupGrid';
    AssetGroupGrid.AlignWithMargins := True;
    AssetGroupGrid.Left := 20;
    AssetGroupGrid.Top := 63;
    AssetGroupGrid.Width := 1013;
    AssetGroupGrid.Height := 384;
    AssetGroupGrid.Margins.Left := 20;
    AssetGroupGrid.Margins.Top := 0;
    AssetGroupGrid.Margins.Right := 20;
    AssetGroupGrid.Margins.Bottom := 10;
    AssetGroupGrid.AdaptToStyle := True;
    AssetGroupGrid.Align := alClient;
    AssetGroupGrid.BevelInner := bvNone;
    AssetGroupGrid.BevelOuter := bvNone;
    AssetGroupGrid.BevelWidth := 2;
    AssetGroupGrid.Ctl3D := False;
    AssetGroupGrid.ParentCtl3D := False;
    AssetGroupGrid.ParentDoubleBuffered := False;
    AssetGroupGrid.DoubleBuffered := True;
    AssetGroupGrid.Font.Charset := DEFAULT_CHARSET;
    AssetGroupGrid.Font.Color := clWindowText;
    AssetGroupGrid.Font.Height := -11;
    AssetGroupGrid.Font.Name := 'Tahoma';
    AssetGroupGrid.Font.Style := [];
    AssetGroupGrid.ParentFont := False;
    AssetGroupGrid.TabOrder := 2;
    SetEvent(AssetGroupGrid, Self, 'OnDblClick', 'AssetGroupGridDblClick');
    AssetGroupGrid.DefaultRowHeight := 34.000000000000000000;
    AssetGroupGrid.FixedColumns := 0;
    AssetGroupGrid.ColumnCount := 2;
    AssetGroupGrid.RowCount := 1;
    AssetGroupGrid.Options.Borders.FixedCellBorders := bNone;
    AssetGroupGrid.Options.ColumnSize.Stretch := True;
    AssetGroupGrid.Options.ColumnSize.StretchColumn := 1;
    AssetGroupGrid.Options.Editing.CalcFormat := '%g';
    AssetGroupGrid.Options.Editing.Enabled := False;
    AssetGroupGrid.Options.Grouping.CalcFormat := '%g';
    AssetGroupGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AssetGroupGrid.Options.HTMLExport.CellPadding := 10;
    AssetGroupGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AssetGroupGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AssetGroupGrid.Options.Mouse.ClickMargin := 0;
    AssetGroupGrid.Options.Mouse.ColumnSizeMargin := 6;
    AssetGroupGrid.Options.Mouse.RowSizeMargin := 6;
    AssetGroupGrid.Options.URL.Color := clSkyBlue;
    AssetGroupGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AssetGroupGrid.Options.Selection.Mode := smSingleRow;
    AssetGroupGrid.Adapter := AssetGroupGridAdapter;
    AssetGroupGrid.HorizontalScrollBarVisible := False;
    AssetGroupGrid.Columns.Clear;
    with AssetGroupGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with AssetGroupGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 1012.000000000000000000;
    end;
    AssetGroupGrid.Fill.ColorTo := clSilver;
    AssetGroupGrid.DefaultFont.Charset := ANSI_CHARSET;
    AssetGroupGrid.DefaultFont.Color := clWindowText;
    AssetGroupGrid.DefaultFont.Height := -16;
    AssetGroupGrid.DefaultFont.Name := 'Segoe UI';
    AssetGroupGrid.DefaultFont.Style := [];
    AssetGroupGrid.Stroke.Color := 15987699;
    AssetGroupGrid.Stroke.Width := 2.000000000000000000;
    AssetGroupGrid.TopRow := 1;
    AssetGroupGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AssetGroupGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AssetGroupGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AssetGroupGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AssetGroupGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AssetGroupGrid.Appearance.FixedLayout.Font.Height := -16;
    AssetGroupGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AssetGroupGrid.Appearance.FixedLayout.Font.Style := [];
    AssetGroupGrid.Appearance.NormalLayout.Fill.Color := -1;
    AssetGroupGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AssetGroupGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AssetGroupGrid.Appearance.NormalLayout.Font.Color := clGray;
    AssetGroupGrid.Appearance.NormalLayout.Font.Height := -11;
    AssetGroupGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AssetGroupGrid.Appearance.NormalLayout.Font.Style := [];
    AssetGroupGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AssetGroupGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AssetGroupGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AssetGroupGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AssetGroupGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AssetGroupGrid.Appearance.GroupLayout.Font.Height := -11;
    AssetGroupGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AssetGroupGrid.Appearance.GroupLayout.Font.Style := [];
    AssetGroupGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AssetGroupGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AssetGroupGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AssetGroupGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AssetGroupGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AssetGroupGrid.Appearance.SummaryLayout.Font.Height := -11;
    AssetGroupGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AssetGroupGrid.Appearance.SummaryLayout.Font.Style := [];
    AssetGroupGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AssetGroupGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AssetGroupGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AssetGroupGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetGroupGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AssetGroupGrid.Appearance.SelectedLayout.Font.Height := -16;
    AssetGroupGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AssetGroupGrid.Appearance.SelectedLayout.Font.Style := [];
    AssetGroupGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AssetGroupGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AssetGroupGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AssetGroupGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AssetGroupGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AssetGroupGrid.Appearance.FocusedLayout.Font.Height := -16;
    AssetGroupGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AssetGroupGrid.Appearance.FocusedLayout.Font.Style := [];
    AssetGroupGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AssetGroupGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AssetGroupGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AssetGroupGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AssetGroupGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AssetGroupGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AssetGroupGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AssetGroupGrid.Appearance.BandLayout.Font.Color := clBlack;
    AssetGroupGrid.Appearance.BandLayout.Font.Height := -16;
    AssetGroupGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AssetGroupGrid.Appearance.BandLayout.Font.Style := [];
    AssetGroupGrid.Appearance.ProgressLayout.Color := 15385233;
    AssetGroupGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AssetGroupGrid.LeftCol := 1;
    AssetGroupGrid.ScrollMode := scmItemScrolling;
    AssetGroupGrid.ScrollUpdate := scuOnce;
    AssetGroupGrid.GlobalFont.Scale := 1.000000000000000000;
    AssetGroupGrid.GlobalFont.Style := [];
    AssetGroupGrid.DesignTimeSampleData := True;
    TMSFNCPageControlAssetPage3.SetParentComponent(TMSFNCPageControlAsset);
    TMSFNCPageControlAssetPage3.Name := 'TMSFNCPageControlAssetPage3';
    TMSFNCPageControlAssetPage3.AlignWithMargins := True;
    TMSFNCPageControlAssetPage3.Left := 0;
    TMSFNCPageControlAssetPage3.Top := 52;
    TMSFNCPageControlAssetPage3.Width := 1053;
    TMSFNCPageControlAssetPage3.Height := 512;
    TMSFNCPageControlAssetPage3.Margins.Left := 0;
    TMSFNCPageControlAssetPage3.Margins.Top := 52;
    TMSFNCPageControlAssetPage3.Margins.Right := 0;
    TMSFNCPageControlAssetPage3.Margins.Bottom := 0;
    TMSFNCPageControlAssetPage3.Align := alClient;
    TMSFNCPageControlAssetPage3.ParentDoubleBuffered := False;
    TMSFNCPageControlAssetPage3.DoubleBuffered := True;
    TMSFNCPageControlAssetPage3.TabStop := False;
    TMSFNCPageControlAssetPage3.TabOrder := 3;
    TMSFNCPageControlAssetPage3.Visible := False;
    TMSFNCPageControlAssetPage3.PageIndex := 3;
    TMSFNCPageControlAssetPage3.IsActive := False;
    AssetBrandFilterPanel.SetParentComponent(TMSFNCPageControlAssetPage3);
    AssetBrandFilterPanel.Name := 'AssetBrandFilterPanel';
    AssetBrandFilterPanel.Tag := 1;
    AssetBrandFilterPanel.AlignWithMargins := True;
    AssetBrandFilterPanel.Left := 3;
    AssetBrandFilterPanel.Top := 3;
    AssetBrandFilterPanel.Width := 1047;
    AssetBrandFilterPanel.Height := 57;
    AssetBrandFilterPanel.Align := alTop;
    AssetBrandFilterPanel.BevelInner := bvNone;
    AssetBrandFilterPanel.BevelOuter := bvNone;
    AssetBrandFilterPanel.Ctl3D := True;
    AssetBrandFilterPanel.ParentCtl3D := False;
    AssetBrandFilterPanel.ParentDoubleBuffered := False;
    AssetBrandFilterPanel.DoubleBuffered := True;
    AssetBrandFilterPanel.TabOrder := 0;
    AssetBrandFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetBrandFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetBrandFilterPanel.SectionsAppearance.Font.Height := -11;
    AssetBrandFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetBrandFilterPanel.SectionsAppearance.Font.Style := [];
    AssetBrandFilterPanel.Stroke.Kind := gskNone;
    AssetBrandFilterPanel.Header.Text := 'Header';
    AssetBrandFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetBrandFilterPanel.Header.Font.Color := clWindowText;
    AssetBrandFilterPanel.Header.Font.Height := -11;
    AssetBrandFilterPanel.Header.Font.Name := 'Tahoma';
    AssetBrandFilterPanel.Header.Font.Style := [];
    AssetBrandFilterPanel.Header.Visible := False;
    AssetBrandFilterPanel.Footer.Text := 'Footer';
    AssetBrandFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetBrandFilterPanel.Footer.Font.Color := clWindowText;
    AssetBrandFilterPanel.Footer.Font.Height := -11;
    AssetBrandFilterPanel.Footer.Font.Name := 'Tahoma';
    AssetBrandFilterPanel.Footer.Font.Style := [];
    AssetBrandFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetBrandFilterPanel.GlobalFont.Style := [];
    AssetBrandEdit.SetParentComponent(AssetBrandFilterPanel);
    AssetBrandEdit.Name := 'AssetBrandEdit';
    AssetBrandEdit.Left := 17;
    AssetBrandEdit.Top := 10;
    AssetBrandEdit.Width := 345;
    AssetBrandEdit.Height := 34;
    AssetBrandEdit.AutoFocus := True;
    AssetBrandEdit.ChildOrder := 1;
    AssetBrandEdit.Font.Charset := ANSI_CHARSET;
    AssetBrandEdit.Font.Color := clWindowText;
    AssetBrandEdit.Font.Height := -16;
    AssetBrandEdit.Font.Name := 'Segoe UI';
    AssetBrandEdit.Font.Style := [];
    AssetBrandEdit.HeightPercent := 100.000000000000000000;
    AssetBrandEdit.ParentFont := False;
    AssetBrandEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandEdit, Self, 'OnChange', 'SearchEditChange');
    AssetBrandGrid.SetParentComponent(TMSFNCPageControlAssetPage3);
    AssetBrandGrid.Name := 'AssetBrandGrid';
    AssetBrandGrid.AlignWithMargins := True;
    AssetBrandGrid.Left := 20;
    AssetBrandGrid.Top := 63;
    AssetBrandGrid.Width := 1013;
    AssetBrandGrid.Height := 384;
    AssetBrandGrid.Margins.Left := 20;
    AssetBrandGrid.Margins.Top := 0;
    AssetBrandGrid.Margins.Right := 20;
    AssetBrandGrid.Margins.Bottom := 10;
    AssetBrandGrid.AdaptToStyle := True;
    AssetBrandGrid.Align := alClient;
    AssetBrandGrid.BevelInner := bvNone;
    AssetBrandGrid.BevelOuter := bvNone;
    AssetBrandGrid.BevelWidth := 2;
    AssetBrandGrid.Ctl3D := False;
    AssetBrandGrid.ParentCtl3D := False;
    AssetBrandGrid.ParentDoubleBuffered := False;
    AssetBrandGrid.DoubleBuffered := True;
    AssetBrandGrid.Font.Charset := DEFAULT_CHARSET;
    AssetBrandGrid.Font.Color := clWindowText;
    AssetBrandGrid.Font.Height := -11;
    AssetBrandGrid.Font.Name := 'Tahoma';
    AssetBrandGrid.Font.Style := [];
    AssetBrandGrid.ParentFont := False;
    AssetBrandGrid.TabOrder := 1;
    SetEvent(AssetBrandGrid, Self, 'OnDblClick', 'AssetBrandGridDblClick');
    AssetBrandGrid.DefaultRowHeight := 34.000000000000000000;
    AssetBrandGrid.FixedColumns := 0;
    AssetBrandGrid.ColumnCount := 2;
    AssetBrandGrid.RowCount := 1;
    AssetBrandGrid.Options.Borders.FixedCellBorders := bNone;
    AssetBrandGrid.Options.ColumnSize.Stretch := True;
    AssetBrandGrid.Options.ColumnSize.StretchColumn := 1;
    AssetBrandGrid.Options.Editing.CalcFormat := '%g';
    AssetBrandGrid.Options.Editing.Enabled := False;
    AssetBrandGrid.Options.Grouping.CalcFormat := '%g';
    AssetBrandGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AssetBrandGrid.Options.HTMLExport.CellPadding := 10;
    AssetBrandGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AssetBrandGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AssetBrandGrid.Options.Mouse.ClickMargin := 0;
    AssetBrandGrid.Options.Mouse.ColumnSizeMargin := 6;
    AssetBrandGrid.Options.Mouse.RowSizeMargin := 6;
    AssetBrandGrid.Options.URL.Color := clSkyBlue;
    AssetBrandGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AssetBrandGrid.Options.Selection.Mode := smSingleRow;
    AssetBrandGrid.Adapter := AssetBrandGridAdapter;
    AssetBrandGrid.HorizontalScrollBarVisible := False;
    AssetBrandGrid.Columns.Clear;
    with AssetBrandGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with AssetBrandGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 1012.000000000000000000;
    end;
    AssetBrandGrid.Fill.ColorTo := clSilver;
    AssetBrandGrid.DefaultFont.Charset := ANSI_CHARSET;
    AssetBrandGrid.DefaultFont.Color := clWindowText;
    AssetBrandGrid.DefaultFont.Height := -16;
    AssetBrandGrid.DefaultFont.Name := 'Segoe UI';
    AssetBrandGrid.DefaultFont.Style := [];
    AssetBrandGrid.Stroke.Color := 15987699;
    AssetBrandGrid.Stroke.Width := 2.000000000000000000;
    AssetBrandGrid.TopRow := 1;
    AssetBrandGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AssetBrandGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AssetBrandGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AssetBrandGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AssetBrandGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AssetBrandGrid.Appearance.FixedLayout.Font.Height := -16;
    AssetBrandGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AssetBrandGrid.Appearance.FixedLayout.Font.Style := [];
    AssetBrandGrid.Appearance.NormalLayout.Fill.Color := -1;
    AssetBrandGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AssetBrandGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AssetBrandGrid.Appearance.NormalLayout.Font.Color := clGray;
    AssetBrandGrid.Appearance.NormalLayout.Font.Height := -11;
    AssetBrandGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AssetBrandGrid.Appearance.NormalLayout.Font.Style := [];
    AssetBrandGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AssetBrandGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AssetBrandGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AssetBrandGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AssetBrandGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AssetBrandGrid.Appearance.GroupLayout.Font.Height := -11;
    AssetBrandGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AssetBrandGrid.Appearance.GroupLayout.Font.Style := [];
    AssetBrandGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AssetBrandGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AssetBrandGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AssetBrandGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AssetBrandGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AssetBrandGrid.Appearance.SummaryLayout.Font.Height := -11;
    AssetBrandGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AssetBrandGrid.Appearance.SummaryLayout.Font.Style := [];
    AssetBrandGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AssetBrandGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AssetBrandGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AssetBrandGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetBrandGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AssetBrandGrid.Appearance.SelectedLayout.Font.Height := -16;
    AssetBrandGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AssetBrandGrid.Appearance.SelectedLayout.Font.Style := [];
    AssetBrandGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AssetBrandGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AssetBrandGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AssetBrandGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AssetBrandGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AssetBrandGrid.Appearance.FocusedLayout.Font.Height := -16;
    AssetBrandGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AssetBrandGrid.Appearance.FocusedLayout.Font.Style := [];
    AssetBrandGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AssetBrandGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AssetBrandGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AssetBrandGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AssetBrandGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AssetBrandGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AssetBrandGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AssetBrandGrid.Appearance.BandLayout.Font.Color := clBlack;
    AssetBrandGrid.Appearance.BandLayout.Font.Height := -16;
    AssetBrandGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AssetBrandGrid.Appearance.BandLayout.Font.Style := [];
    AssetBrandGrid.Appearance.ProgressLayout.Color := 15385233;
    AssetBrandGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AssetBrandGrid.LeftCol := 1;
    AssetBrandGrid.ScrollMode := scmItemScrolling;
    AssetBrandGrid.GlobalFont.Scale := 1.000000000000000000;
    AssetBrandGrid.GlobalFont.Style := [];
    AssetBrandGrid.DesignTimeSampleData := True;
    TMSFNCPanel11.SetParentComponent(TMSFNCPageControlAssetPage3);
    TMSFNCPanel11.Name := 'TMSFNCPanel11';
    TMSFNCPanel11.Tag := 1;
    TMSFNCPanel11.AlignWithMargins := True;
    TMSFNCPanel11.Left := 3;
    TMSFNCPanel11.Top := 460;
    TMSFNCPanel11.Width := 1047;
    TMSFNCPanel11.Height := 49;
    TMSFNCPanel11.Align := alBottom;
    TMSFNCPanel11.BevelInner := bvNone;
    TMSFNCPanel11.BevelOuter := bvNone;
    TMSFNCPanel11.Ctl3D := True;
    TMSFNCPanel11.ParentCtl3D := False;
    TMSFNCPanel11.ParentDoubleBuffered := False;
    TMSFNCPanel11.DoubleBuffered := True;
    TMSFNCPanel11.TabOrder := 2;
    TMSFNCPanel11.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel11.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel11.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel11.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel11.SectionsAppearance.Font.Style := [];
    TMSFNCPanel11.Stroke.Kind := gskNone;
    TMSFNCPanel11.Header.Text := 'Header';
    TMSFNCPanel11.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel11.Header.Font.Color := clWindowText;
    TMSFNCPanel11.Header.Font.Height := -11;
    TMSFNCPanel11.Header.Font.Name := 'Tahoma';
    TMSFNCPanel11.Header.Font.Style := [];
    TMSFNCPanel11.Header.Visible := False;
    TMSFNCPanel11.Footer.Text := 'Footer';
    TMSFNCPanel11.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel11.Footer.Font.Color := clWindowText;
    TMSFNCPanel11.Footer.Font.Height := -11;
    TMSFNCPanel11.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel11.Footer.Font.Style := [];
    TMSFNCPanel11.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel11.GlobalFont.Style := [];
    AssetBrandAddButton.SetParentComponent(TMSFNCPanel11);
    AssetBrandAddButton.Name := 'AssetBrandAddButton';
    AssetBrandAddButton.AlignWithMargins := True;
    AssetBrandAddButton.Left := 20;
    AssetBrandAddButton.Top := 8;
    AssetBrandAddButton.Width := 100;
    AssetBrandAddButton.Height := 31;
    AssetBrandAddButton.Margins.Left := 0;
    AssetBrandAddButton.Margins.Top := 8;
    AssetBrandAddButton.Margins.Right := 8;
    AssetBrandAddButton.Margins.Bottom := 10;
    AssetBrandAddButton.Align := alLeft;
    AssetBrandAddButton.Caption := 'Add';
    AssetBrandAddButton.Color := clNone;
    AssetBrandAddButton.ElementClassName := 'MyWebButton';
    AssetBrandAddButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel11.AssetBrandAddButton.Glyph.png');
    AssetBrandAddButton.HeightPercent := 100.000000000000000000;
    AssetBrandAddButton.TabOrder := 0;
    AssetBrandAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandAddButton, Self, 'OnClick', 'AssetBrandAddButtonClick');
    AssetBrandEditButton.SetParentComponent(TMSFNCPanel11);
    AssetBrandEditButton.Name := 'AssetBrandEditButton';
    AssetBrandEditButton.AlignWithMargins := True;
    AssetBrandEditButton.Left := 236;
    AssetBrandEditButton.Top := 8;
    AssetBrandEditButton.Width := 100;
    AssetBrandEditButton.Height := 31;
    AssetBrandEditButton.Margins.Left := 0;
    AssetBrandEditButton.Margins.Top := 8;
    AssetBrandEditButton.Margins.Right := 8;
    AssetBrandEditButton.Margins.Bottom := 10;
    AssetBrandEditButton.Align := alLeft;
    AssetBrandEditButton.Caption := 'Edit';
    AssetBrandEditButton.Color := clNone;
    AssetBrandEditButton.ElementClassName := 'MyWebButton';
    AssetBrandEditButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel11.AssetBrandEditButton.Glyph.png');
    AssetBrandEditButton.HeightPercent := 100.000000000000000000;
    AssetBrandEditButton.TabOrder := 1;
    AssetBrandEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandEditButton, Self, 'OnClick', 'AssetBrandEditButtonClick');
    AssetBrandDeleteButton.SetParentComponent(TMSFNCPanel11);
    AssetBrandDeleteButton.Name := 'AssetBrandDeleteButton';
    AssetBrandDeleteButton.AlignWithMargins := True;
    AssetBrandDeleteButton.Left := 344;
    AssetBrandDeleteButton.Top := 8;
    AssetBrandDeleteButton.Width := 100;
    AssetBrandDeleteButton.Height := 31;
    AssetBrandDeleteButton.Margins.Left := 0;
    AssetBrandDeleteButton.Margins.Top := 8;
    AssetBrandDeleteButton.Margins.Right := 8;
    AssetBrandDeleteButton.Margins.Bottom := 10;
    AssetBrandDeleteButton.Align := alLeft;
    AssetBrandDeleteButton.Caption := 'Delete';
    AssetBrandDeleteButton.Color := clNone;
    AssetBrandDeleteButton.ElementClassName := 'MyWebButton';
    AssetBrandDeleteButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel11.AssetBrandDeleteButton.Glyph.png');
    AssetBrandDeleteButton.HeightPercent := 100.000000000000000000;
    AssetBrandDeleteButton.TabOrder := 2;
    AssetBrandDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandDeleteButton, Self, 'OnClick', 'AssetBrandDeleteButtonClick');
    WebSpeedButton7.SetParentComponent(TMSFNCPanel11);
    WebSpeedButton7.Name := 'WebSpeedButton7';
    WebSpeedButton7.AlignWithMargins := True;
    WebSpeedButton7.Left := 927;
    WebSpeedButton7.Top := 8;
    WebSpeedButton7.Width := 100;
    WebSpeedButton7.Height := 31;
    WebSpeedButton7.Margins.Left := 10;
    WebSpeedButton7.Margins.Top := 8;
    WebSpeedButton7.Margins.Right := 20;
    WebSpeedButton7.Margins.Bottom := 10;
    WebSpeedButton7.Align := alRight;
    WebSpeedButton7.Caption := 'Close';
    WebSpeedButton7.Color := clNone;
    WebSpeedButton7.ElementClassName := 'MyWebButton';
    WebSpeedButton7.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel11.WebSpeedButton7.Glyph.png');
    WebSpeedButton7.HeightPercent := 100.000000000000000000;
    WebSpeedButton7.TabOrder := 3;
    WebSpeedButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton7, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel13.SetParentComponent(TMSFNCPanel11);
    TMSFNCPanel13.Name := 'TMSFNCPanel13';
    TMSFNCPanel13.Tag := 2;
    TMSFNCPanel13.AlignWithMargins := True;
    TMSFNCPanel13.Left := 3;
    TMSFNCPanel13.Top := 3;
    TMSFNCPanel13.Width := 14;
    TMSFNCPanel13.Height := 41;
    TMSFNCPanel13.Margins.Bottom := 5;
    TMSFNCPanel13.Align := alLeft;
    TMSFNCPanel13.BevelInner := bvNone;
    TMSFNCPanel13.BevelOuter := bvNone;
    TMSFNCPanel13.Ctl3D := True;
    TMSFNCPanel13.ParentCtl3D := False;
    TMSFNCPanel13.ParentDoubleBuffered := False;
    TMSFNCPanel13.DoubleBuffered := True;
    TMSFNCPanel13.TabOrder := 4;
    TMSFNCPanel13.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel13.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel13.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel13.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel13.SectionsAppearance.Font.Style := [];
    TMSFNCPanel13.Stroke.Kind := gskNone;
    TMSFNCPanel13.Header.Text := 'Header';
    TMSFNCPanel13.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel13.Header.Font.Color := clWindowText;
    TMSFNCPanel13.Header.Font.Height := -11;
    TMSFNCPanel13.Header.Font.Name := 'Tahoma';
    TMSFNCPanel13.Header.Font.Style := [];
    TMSFNCPanel13.Header.Visible := False;
    TMSFNCPanel13.Footer.Text := 'Footer';
    TMSFNCPanel13.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel13.Footer.Font.Color := clWindowText;
    TMSFNCPanel13.Footer.Font.Height := -11;
    TMSFNCPanel13.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel13.Footer.Font.Style := [];
    TMSFNCPanel13.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel13.GlobalFont.Style := [];
    AssetBrandViewButton.SetParentComponent(TMSFNCPanel11);
    AssetBrandViewButton.Name := 'AssetBrandViewButton';
    AssetBrandViewButton.AlignWithMargins := True;
    AssetBrandViewButton.Left := 128;
    AssetBrandViewButton.Top := 8;
    AssetBrandViewButton.Width := 100;
    AssetBrandViewButton.Height := 31;
    AssetBrandViewButton.Margins.Left := 0;
    AssetBrandViewButton.Margins.Top := 8;
    AssetBrandViewButton.Margins.Right := 8;
    AssetBrandViewButton.Margins.Bottom := 10;
    AssetBrandViewButton.Align := alLeft;
    AssetBrandViewButton.Caption := 'View';
    AssetBrandViewButton.Color := clNone;
    AssetBrandViewButton.ElementClassName := 'MyWebButton';
    AssetBrandViewButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel11.AssetBrandViewButton.Glyph.png');
    AssetBrandViewButton.HeightPercent := 100.000000000000000000;
    AssetBrandViewButton.TabOrder := 5;
    AssetBrandViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandViewButton, Self, 'OnClick', 'AssetBrandEditButtonClick');
    TMSFNCPageControlAssetPage4.SetParentComponent(TMSFNCPageControlAsset);
    TMSFNCPageControlAssetPage4.Name := 'TMSFNCPageControlAssetPage4';
    TMSFNCPageControlAssetPage4.AlignWithMargins := True;
    TMSFNCPageControlAssetPage4.Left := 0;
    TMSFNCPageControlAssetPage4.Top := 52;
    TMSFNCPageControlAssetPage4.Width := 1053;
    TMSFNCPageControlAssetPage4.Height := 512;
    TMSFNCPageControlAssetPage4.Margins.Left := 0;
    TMSFNCPageControlAssetPage4.Margins.Top := 52;
    TMSFNCPageControlAssetPage4.Margins.Right := 0;
    TMSFNCPageControlAssetPage4.Margins.Bottom := 0;
    TMSFNCPageControlAssetPage4.Align := alClient;
    TMSFNCPageControlAssetPage4.ParentDoubleBuffered := False;
    TMSFNCPageControlAssetPage4.DoubleBuffered := True;
    TMSFNCPageControlAssetPage4.TabStop := False;
    TMSFNCPageControlAssetPage4.TabOrder := 4;
    TMSFNCPageControlAssetPage4.Visible := False;
    TMSFNCPageControlAssetPage4.PageIndex := 4;
    TMSFNCPageControlAssetPage4.IsActive := False;
    AssetModelFilterPanel.SetParentComponent(TMSFNCPageControlAssetPage4);
    AssetModelFilterPanel.Name := 'AssetModelFilterPanel';
    AssetModelFilterPanel.Tag := 1;
    AssetModelFilterPanel.AlignWithMargins := True;
    AssetModelFilterPanel.Left := 3;
    AssetModelFilterPanel.Top := 3;
    AssetModelFilterPanel.Width := 1047;
    AssetModelFilterPanel.Height := 57;
    AssetModelFilterPanel.Align := alTop;
    AssetModelFilterPanel.BevelInner := bvNone;
    AssetModelFilterPanel.BevelOuter := bvNone;
    AssetModelFilterPanel.Ctl3D := True;
    AssetModelFilterPanel.ParentCtl3D := False;
    AssetModelFilterPanel.ParentDoubleBuffered := False;
    AssetModelFilterPanel.DoubleBuffered := True;
    AssetModelFilterPanel.TabOrder := 0;
    AssetModelFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetModelFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetModelFilterPanel.SectionsAppearance.Font.Height := -11;
    AssetModelFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetModelFilterPanel.SectionsAppearance.Font.Style := [];
    AssetModelFilterPanel.Stroke.Kind := gskNone;
    AssetModelFilterPanel.Header.Text := 'Header';
    AssetModelFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetModelFilterPanel.Header.Font.Color := clWindowText;
    AssetModelFilterPanel.Header.Font.Height := -11;
    AssetModelFilterPanel.Header.Font.Name := 'Tahoma';
    AssetModelFilterPanel.Header.Font.Style := [];
    AssetModelFilterPanel.Header.Visible := False;
    AssetModelFilterPanel.Footer.Text := 'Footer';
    AssetModelFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetModelFilterPanel.Footer.Font.Color := clWindowText;
    AssetModelFilterPanel.Footer.Font.Height := -11;
    AssetModelFilterPanel.Footer.Font.Name := 'Tahoma';
    AssetModelFilterPanel.Footer.Font.Style := [];
    AssetModelFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetModelFilterPanel.GlobalFont.Style := [];
    AssetModelEdit.SetParentComponent(AssetModelFilterPanel);
    AssetModelEdit.Name := 'AssetModelEdit';
    AssetModelEdit.Left := 17;
    AssetModelEdit.Top := 10;
    AssetModelEdit.Width := 345;
    AssetModelEdit.Height := 34;
    AssetModelEdit.AutoFocus := True;
    AssetModelEdit.ChildOrder := 1;
    AssetModelEdit.Font.Charset := ANSI_CHARSET;
    AssetModelEdit.Font.Color := clWindowText;
    AssetModelEdit.Font.Height := -16;
    AssetModelEdit.Font.Name := 'Segoe UI';
    AssetModelEdit.Font.Style := [];
    AssetModelEdit.HeightPercent := 100.000000000000000000;
    AssetModelEdit.ParentFont := False;
    AssetModelEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetModelEdit, Self, 'OnChange', 'SearchEditChange');
    AssetModelGrid.SetParentComponent(TMSFNCPageControlAssetPage4);
    AssetModelGrid.Name := 'AssetModelGrid';
    AssetModelGrid.AlignWithMargins := True;
    AssetModelGrid.Left := 20;
    AssetModelGrid.Top := 63;
    AssetModelGrid.Width := 1013;
    AssetModelGrid.Height := 384;
    AssetModelGrid.Margins.Left := 20;
    AssetModelGrid.Margins.Top := 0;
    AssetModelGrid.Margins.Right := 20;
    AssetModelGrid.Margins.Bottom := 10;
    AssetModelGrid.AdaptToStyle := True;
    AssetModelGrid.Align := alClient;
    AssetModelGrid.BevelInner := bvNone;
    AssetModelGrid.BevelOuter := bvNone;
    AssetModelGrid.BevelWidth := 2;
    AssetModelGrid.Ctl3D := False;
    AssetModelGrid.ParentCtl3D := False;
    AssetModelGrid.ParentDoubleBuffered := False;
    AssetModelGrid.DoubleBuffered := True;
    AssetModelGrid.Font.Charset := DEFAULT_CHARSET;
    AssetModelGrid.Font.Color := clWindowText;
    AssetModelGrid.Font.Height := -11;
    AssetModelGrid.Font.Name := 'Tahoma';
    AssetModelGrid.Font.Style := [];
    AssetModelGrid.ParentFont := False;
    AssetModelGrid.TabOrder := 1;
    SetEvent(AssetModelGrid, Self, 'OnDblClick', 'AssetModelGridDblClick');
    AssetModelGrid.DefaultRowHeight := 34.000000000000000000;
    AssetModelGrid.FixedColumns := 0;
    AssetModelGrid.ColumnCount := 4;
    AssetModelGrid.RowCount := 1;
    AssetModelGrid.Options.Borders.FixedCellBorders := bNone;
    AssetModelGrid.Options.ColumnSize.Stretch := True;
    AssetModelGrid.Options.ColumnSize.StretchColumn := 1;
    AssetModelGrid.Options.Editing.CalcFormat := '%g';
    AssetModelGrid.Options.Editing.Enabled := False;
    AssetModelGrid.Options.Grouping.CalcFormat := '%g';
    AssetModelGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AssetModelGrid.Options.HTMLExport.CellPadding := 10;
    AssetModelGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AssetModelGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AssetModelGrid.Options.Mouse.ClickMargin := 0;
    AssetModelGrid.Options.Mouse.ColumnSizeMargin := 6;
    AssetModelGrid.Options.Mouse.RowSizeMargin := 6;
    AssetModelGrid.Options.URL.Color := clSkyBlue;
    AssetModelGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AssetModelGrid.Options.Selection.Mode := smSingleRow;
    AssetModelGrid.Adapter := AssetModelGridAdapter;
    AssetModelGrid.HorizontalScrollBarVisible := False;
    AssetModelGrid.Columns.Clear;
    with AssetModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1.000000000000000000;
    end;
    with AssetModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 770.000000000000000000;
    end;
    with AssetModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 1.000000000000000000;
    end;
    with AssetModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 240.000000000000000000;
    end;
    AssetModelGrid.Fill.ColorTo := clSilver;
    AssetModelGrid.DefaultFont.Charset := ANSI_CHARSET;
    AssetModelGrid.DefaultFont.Color := clWindowText;
    AssetModelGrid.DefaultFont.Height := -16;
    AssetModelGrid.DefaultFont.Name := 'Segoe UI';
    AssetModelGrid.DefaultFont.Style := [];
    AssetModelGrid.Stroke.Color := 15987699;
    AssetModelGrid.Stroke.Width := 2.000000000000000000;
    AssetModelGrid.TopRow := 1;
    AssetModelGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AssetModelGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AssetModelGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AssetModelGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AssetModelGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AssetModelGrid.Appearance.FixedLayout.Font.Height := -16;
    AssetModelGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AssetModelGrid.Appearance.FixedLayout.Font.Style := [];
    AssetModelGrid.Appearance.NormalLayout.Fill.Color := -1;
    AssetModelGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AssetModelGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AssetModelGrid.Appearance.NormalLayout.Font.Color := clGray;
    AssetModelGrid.Appearance.NormalLayout.Font.Height := -11;
    AssetModelGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AssetModelGrid.Appearance.NormalLayout.Font.Style := [];
    AssetModelGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AssetModelGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AssetModelGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AssetModelGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AssetModelGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AssetModelGrid.Appearance.GroupLayout.Font.Height := -11;
    AssetModelGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AssetModelGrid.Appearance.GroupLayout.Font.Style := [];
    AssetModelGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AssetModelGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AssetModelGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AssetModelGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AssetModelGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AssetModelGrid.Appearance.SummaryLayout.Font.Height := -11;
    AssetModelGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AssetModelGrid.Appearance.SummaryLayout.Font.Style := [];
    AssetModelGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AssetModelGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AssetModelGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AssetModelGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetModelGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AssetModelGrid.Appearance.SelectedLayout.Font.Height := -16;
    AssetModelGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AssetModelGrid.Appearance.SelectedLayout.Font.Style := [];
    AssetModelGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AssetModelGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AssetModelGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AssetModelGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AssetModelGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AssetModelGrid.Appearance.FocusedLayout.Font.Height := -16;
    AssetModelGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AssetModelGrid.Appearance.FocusedLayout.Font.Style := [];
    AssetModelGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AssetModelGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AssetModelGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AssetModelGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetModelGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AssetModelGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AssetModelGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AssetModelGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AssetModelGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AssetModelGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AssetModelGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AssetModelGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AssetModelGrid.Appearance.BandLayout.Font.Color := clBlack;
    AssetModelGrid.Appearance.BandLayout.Font.Height := -16;
    AssetModelGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AssetModelGrid.Appearance.BandLayout.Font.Style := [];
    AssetModelGrid.Appearance.ProgressLayout.Color := 15385233;
    AssetModelGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AssetModelGrid.LeftCol := 0;
    AssetModelGrid.ScrollMode := scmItemScrolling;
    AssetModelGrid.GlobalFont.Scale := 1.000000000000000000;
    AssetModelGrid.GlobalFont.Style := [];
    AssetModelGrid.DesignTimeSampleData := True;
    TMSFNCPanel14.SetParentComponent(TMSFNCPageControlAssetPage4);
    TMSFNCPanel14.Name := 'TMSFNCPanel14';
    TMSFNCPanel14.Tag := 1;
    TMSFNCPanel14.AlignWithMargins := True;
    TMSFNCPanel14.Left := 3;
    TMSFNCPanel14.Top := 460;
    TMSFNCPanel14.Width := 1047;
    TMSFNCPanel14.Height := 49;
    TMSFNCPanel14.Align := alBottom;
    TMSFNCPanel14.BevelInner := bvNone;
    TMSFNCPanel14.BevelOuter := bvNone;
    TMSFNCPanel14.Ctl3D := True;
    TMSFNCPanel14.ParentCtl3D := False;
    TMSFNCPanel14.ParentDoubleBuffered := False;
    TMSFNCPanel14.DoubleBuffered := True;
    TMSFNCPanel14.TabOrder := 2;
    TMSFNCPanel14.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel14.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel14.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel14.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel14.SectionsAppearance.Font.Style := [];
    TMSFNCPanel14.Stroke.Kind := gskNone;
    TMSFNCPanel14.Header.Text := 'Header';
    TMSFNCPanel14.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel14.Header.Font.Color := clWindowText;
    TMSFNCPanel14.Header.Font.Height := -11;
    TMSFNCPanel14.Header.Font.Name := 'Tahoma';
    TMSFNCPanel14.Header.Font.Style := [];
    TMSFNCPanel14.Header.Visible := False;
    TMSFNCPanel14.Footer.Text := 'Footer';
    TMSFNCPanel14.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel14.Footer.Font.Color := clWindowText;
    TMSFNCPanel14.Footer.Font.Height := -11;
    TMSFNCPanel14.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel14.Footer.Font.Style := [];
    TMSFNCPanel14.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel14.GlobalFont.Style := [];
    AssetModelAddButton.SetParentComponent(TMSFNCPanel14);
    AssetModelAddButton.Name := 'AssetModelAddButton';
    AssetModelAddButton.AlignWithMargins := True;
    AssetModelAddButton.Left := 20;
    AssetModelAddButton.Top := 8;
    AssetModelAddButton.Width := 100;
    AssetModelAddButton.Height := 31;
    AssetModelAddButton.Margins.Left := 0;
    AssetModelAddButton.Margins.Top := 8;
    AssetModelAddButton.Margins.Right := 8;
    AssetModelAddButton.Margins.Bottom := 10;
    AssetModelAddButton.Align := alLeft;
    AssetModelAddButton.Caption := 'Add';
    AssetModelAddButton.Color := clNone;
    AssetModelAddButton.ElementClassName := 'MyWebButton';
    AssetModelAddButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel14.AssetModelAddButton.Glyph.png');
    AssetModelAddButton.HeightPercent := 100.000000000000000000;
    AssetModelAddButton.TabOrder := 0;
    AssetModelAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetModelAddButton, Self, 'OnClick', 'AssetModelAddButtonClick');
    AssetModelEditButton.SetParentComponent(TMSFNCPanel14);
    AssetModelEditButton.Name := 'AssetModelEditButton';
    AssetModelEditButton.AlignWithMargins := True;
    AssetModelEditButton.Left := 236;
    AssetModelEditButton.Top := 8;
    AssetModelEditButton.Width := 100;
    AssetModelEditButton.Height := 31;
    AssetModelEditButton.Margins.Left := 0;
    AssetModelEditButton.Margins.Top := 8;
    AssetModelEditButton.Margins.Right := 8;
    AssetModelEditButton.Margins.Bottom := 10;
    AssetModelEditButton.Align := alLeft;
    AssetModelEditButton.Caption := 'Edit';
    AssetModelEditButton.Color := clNone;
    AssetModelEditButton.ElementClassName := 'MyWebButton';
    AssetModelEditButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel14.AssetModelEditButton.Glyph.png');
    AssetModelEditButton.HeightPercent := 100.000000000000000000;
    AssetModelEditButton.TabOrder := 1;
    AssetModelEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetModelEditButton, Self, 'OnClick', 'AssetModelEditButtonClick');
    AssetModelDeleteButton.SetParentComponent(TMSFNCPanel14);
    AssetModelDeleteButton.Name := 'AssetModelDeleteButton';
    AssetModelDeleteButton.AlignWithMargins := True;
    AssetModelDeleteButton.Left := 344;
    AssetModelDeleteButton.Top := 8;
    AssetModelDeleteButton.Width := 100;
    AssetModelDeleteButton.Height := 31;
    AssetModelDeleteButton.Margins.Left := 0;
    AssetModelDeleteButton.Margins.Top := 8;
    AssetModelDeleteButton.Margins.Right := 8;
    AssetModelDeleteButton.Margins.Bottom := 10;
    AssetModelDeleteButton.Align := alLeft;
    AssetModelDeleteButton.Caption := 'Delete';
    AssetModelDeleteButton.Color := clNone;
    AssetModelDeleteButton.ElementClassName := 'MyWebButton';
    AssetModelDeleteButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel14.AssetModelDeleteButton.Glyph.png');
    AssetModelDeleteButton.HeightPercent := 100.000000000000000000;
    AssetModelDeleteButton.TabOrder := 2;
    AssetModelDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetModelDeleteButton, Self, 'OnClick', 'AssetModelDeleteButtonClick');
    WebSpeedButton13.SetParentComponent(TMSFNCPanel14);
    WebSpeedButton13.Name := 'WebSpeedButton13';
    WebSpeedButton13.AlignWithMargins := True;
    WebSpeedButton13.Left := 927;
    WebSpeedButton13.Top := 8;
    WebSpeedButton13.Width := 100;
    WebSpeedButton13.Height := 31;
    WebSpeedButton13.Margins.Left := 10;
    WebSpeedButton13.Margins.Top := 8;
    WebSpeedButton13.Margins.Right := 20;
    WebSpeedButton13.Margins.Bottom := 10;
    WebSpeedButton13.Align := alRight;
    WebSpeedButton13.Caption := 'Close';
    WebSpeedButton13.Color := clNone;
    WebSpeedButton13.ElementClassName := 'MyWebButton';
    WebSpeedButton13.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel14.WebSpeedButton13.Glyph.png');
    WebSpeedButton13.HeightPercent := 100.000000000000000000;
    WebSpeedButton13.TabOrder := 3;
    WebSpeedButton13.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton13, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel15.SetParentComponent(TMSFNCPanel14);
    TMSFNCPanel15.Name := 'TMSFNCPanel15';
    TMSFNCPanel15.Tag := 2;
    TMSFNCPanel15.AlignWithMargins := True;
    TMSFNCPanel15.Left := 3;
    TMSFNCPanel15.Top := 3;
    TMSFNCPanel15.Width := 14;
    TMSFNCPanel15.Height := 41;
    TMSFNCPanel15.Margins.Bottom := 5;
    TMSFNCPanel15.Align := alLeft;
    TMSFNCPanel15.BevelInner := bvNone;
    TMSFNCPanel15.BevelOuter := bvNone;
    TMSFNCPanel15.Ctl3D := True;
    TMSFNCPanel15.ParentCtl3D := False;
    TMSFNCPanel15.ParentDoubleBuffered := False;
    TMSFNCPanel15.DoubleBuffered := True;
    TMSFNCPanel15.TabOrder := 4;
    TMSFNCPanel15.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel15.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel15.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel15.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel15.SectionsAppearance.Font.Style := [];
    TMSFNCPanel15.Stroke.Kind := gskNone;
    TMSFNCPanel15.Header.Text := 'Header';
    TMSFNCPanel15.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel15.Header.Font.Color := clWindowText;
    TMSFNCPanel15.Header.Font.Height := -11;
    TMSFNCPanel15.Header.Font.Name := 'Tahoma';
    TMSFNCPanel15.Header.Font.Style := [];
    TMSFNCPanel15.Header.Visible := False;
    TMSFNCPanel15.Footer.Text := 'Footer';
    TMSFNCPanel15.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel15.Footer.Font.Color := clWindowText;
    TMSFNCPanel15.Footer.Font.Height := -11;
    TMSFNCPanel15.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel15.Footer.Font.Style := [];
    TMSFNCPanel15.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel15.GlobalFont.Style := [];
    AssetModelViewButton.SetParentComponent(TMSFNCPanel14);
    AssetModelViewButton.Name := 'AssetModelViewButton';
    AssetModelViewButton.AlignWithMargins := True;
    AssetModelViewButton.Left := 128;
    AssetModelViewButton.Top := 8;
    AssetModelViewButton.Width := 100;
    AssetModelViewButton.Height := 31;
    AssetModelViewButton.Margins.Left := 0;
    AssetModelViewButton.Margins.Top := 8;
    AssetModelViewButton.Margins.Right := 8;
    AssetModelViewButton.Margins.Bottom := 10;
    AssetModelViewButton.Align := alLeft;
    AssetModelViewButton.Caption := 'View';
    AssetModelViewButton.Color := clNone;
    AssetModelViewButton.ElementClassName := 'MyWebButton';
    AssetModelViewButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel14.AssetModelViewButton.Glyph.png');
    AssetModelViewButton.HeightPercent := 100.000000000000000000;
    AssetModelViewButton.TabOrder := 5;
    AssetModelViewButton.Visible := False;
    AssetModelViewButton.WidthPercent := 100.000000000000000000;
    TMSFNCPageControlAssetPage5.SetParentComponent(TMSFNCPageControlAsset);
    TMSFNCPageControlAssetPage5.Name := 'TMSFNCPageControlAssetPage5';
    TMSFNCPageControlAssetPage5.AlignWithMargins := True;
    TMSFNCPageControlAssetPage5.Left := 0;
    TMSFNCPageControlAssetPage5.Top := 52;
    TMSFNCPageControlAssetPage5.Width := 1053;
    TMSFNCPageControlAssetPage5.Height := 512;
    TMSFNCPageControlAssetPage5.Margins.Left := 0;
    TMSFNCPageControlAssetPage5.Margins.Top := 52;
    TMSFNCPageControlAssetPage5.Margins.Right := 0;
    TMSFNCPageControlAssetPage5.Margins.Bottom := 0;
    TMSFNCPageControlAssetPage5.Align := alClient;
    TMSFNCPageControlAssetPage5.ParentDoubleBuffered := False;
    TMSFNCPageControlAssetPage5.DoubleBuffered := True;
    TMSFNCPageControlAssetPage5.TabStop := False;
    TMSFNCPageControlAssetPage5.TabOrder := 5;
    TMSFNCPageControlAssetPage5.Visible := False;
    TMSFNCPageControlAssetPage5.PageIndex := 5;
    TMSFNCPageControlAssetPage5.IsActive := False;
    TMSFNCPanel16.SetParentComponent(TMSFNCPageControlAssetPage5);
    TMSFNCPanel16.Name := 'TMSFNCPanel16';
    TMSFNCPanel16.Tag := 1;
    TMSFNCPanel16.AlignWithMargins := True;
    TMSFNCPanel16.Left := 3;
    TMSFNCPanel16.Top := 3;
    TMSFNCPanel16.Width := 1047;
    TMSFNCPanel16.Height := 57;
    TMSFNCPanel16.Align := alTop;
    TMSFNCPanel16.BevelInner := bvNone;
    TMSFNCPanel16.BevelOuter := bvNone;
    TMSFNCPanel16.Ctl3D := True;
    TMSFNCPanel16.ParentCtl3D := False;
    TMSFNCPanel16.ParentDoubleBuffered := False;
    TMSFNCPanel16.DoubleBuffered := True;
    TMSFNCPanel16.TabOrder := 0;
    TMSFNCPanel16.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel16.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel16.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel16.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel16.SectionsAppearance.Font.Style := [];
    TMSFNCPanel16.Stroke.Kind := gskNone;
    TMSFNCPanel16.Header.Text := 'Header';
    TMSFNCPanel16.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel16.Header.Font.Color := clWindowText;
    TMSFNCPanel16.Header.Font.Height := -11;
    TMSFNCPanel16.Header.Font.Name := 'Tahoma';
    TMSFNCPanel16.Header.Font.Style := [];
    TMSFNCPanel16.Header.Visible := False;
    TMSFNCPanel16.Header.Fill.Color := 16380654;
    TMSFNCPanel16.Header.Stroke.Kind := gskNone;
    TMSFNCPanel16.Footer.Text := 'Footer';
    TMSFNCPanel16.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel16.Footer.Font.Color := clWindowText;
    TMSFNCPanel16.Footer.Font.Height := -11;
    TMSFNCPanel16.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel16.Footer.Font.Style := [];
    TMSFNCPanel16.Footer.Fill.Color := 16380654;
    TMSFNCPanel16.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel16.GlobalFont.Style := [];
    AssetRepairEdit.SetParentComponent(TMSFNCPanel16);
    AssetRepairEdit.Name := 'AssetRepairEdit';
    AssetRepairEdit.Left := 17;
    AssetRepairEdit.Top := 10;
    AssetRepairEdit.Width := 345;
    AssetRepairEdit.Height := 34;
    AssetRepairEdit.AutoFocus := True;
    AssetRepairEdit.ChildOrder := 1;
    AssetRepairEdit.Font.Charset := ANSI_CHARSET;
    AssetRepairEdit.Font.Color := clWindowText;
    AssetRepairEdit.Font.Height := -16;
    AssetRepairEdit.Font.Name := 'Segoe UI';
    AssetRepairEdit.Font.Style := [];
    AssetRepairEdit.HeightPercent := 100.000000000000000000;
    AssetRepairEdit.ParentFont := False;
    AssetRepairEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetRepairEdit, Self, 'OnChange', 'SearchEditChange');
    AssetRepairGrid.SetParentComponent(TMSFNCPageControlAssetPage5);
    AssetRepairGrid.Name := 'AssetRepairGrid';
    AssetRepairGrid.AlignWithMargins := True;
    AssetRepairGrid.Left := 20;
    AssetRepairGrid.Top := 63;
    AssetRepairGrid.Width := 1013;
    AssetRepairGrid.Height := 384;
    AssetRepairGrid.Margins.Left := 20;
    AssetRepairGrid.Margins.Top := 0;
    AssetRepairGrid.Margins.Right := 20;
    AssetRepairGrid.Margins.Bottom := 10;
    AssetRepairGrid.AdaptToStyle := True;
    AssetRepairGrid.Align := alClient;
    AssetRepairGrid.BevelInner := bvNone;
    AssetRepairGrid.BevelOuter := bvNone;
    AssetRepairGrid.BevelWidth := 2;
    AssetRepairGrid.Ctl3D := False;
    AssetRepairGrid.ParentCtl3D := False;
    AssetRepairGrid.ParentDoubleBuffered := False;
    AssetRepairGrid.DoubleBuffered := True;
    AssetRepairGrid.Font.Charset := DEFAULT_CHARSET;
    AssetRepairGrid.Font.Color := clWindowText;
    AssetRepairGrid.Font.Height := -11;
    AssetRepairGrid.Font.Name := 'Tahoma';
    AssetRepairGrid.Font.Style := [];
    AssetRepairGrid.ParentFont := False;
    AssetRepairGrid.TabOrder := 1;
    SetEvent(AssetRepairGrid, Self, 'OnDblClick', 'AssetModelGridDblClick');
    AssetRepairGrid.DefaultRowHeight := 34.000000000000000000;
    AssetRepairGrid.FixedColumns := 0;
    AssetRepairGrid.RowCount := 1;
    AssetRepairGrid.Options.Bands.Enabled := True;
    AssetRepairGrid.Options.Borders.FixedCellBorders := bNone;
    AssetRepairGrid.Options.ColumnSize.Stretch := True;
    AssetRepairGrid.Options.ColumnSize.StretchColumn := 1;
    AssetRepairGrid.Options.Editing.CalcFormat := '%g';
    AssetRepairGrid.Options.Editing.Enabled := False;
    AssetRepairGrid.Options.Grouping.CalcFormat := '%g';
    AssetRepairGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AssetRepairGrid.Options.HTMLExport.CellPadding := 10;
    AssetRepairGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AssetRepairGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AssetRepairGrid.Options.Mouse.ClickMargin := 0;
    AssetRepairGrid.Options.Mouse.ColumnSizeMargin := 6;
    AssetRepairGrid.Options.Mouse.RowSizeMargin := 6;
    AssetRepairGrid.Options.URL.Color := clSkyBlue;
    AssetRepairGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AssetRepairGrid.Options.Selection.Mode := smSingleRow;
    AssetRepairGrid.Adapter := AssetRepairGridAdapter;
    AssetRepairGrid.HorizontalScrollBarVisible := False;
    AssetRepairGrid.Columns.Clear;
    with AssetRepairGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1.000000000000000000;
    end;
    with AssetRepairGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 531.000000000000000000;
    end;
    with AssetRepairGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with AssetRepairGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with AssetRepairGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    AssetRepairGrid.Fill.ColorTo := clSilver;
    AssetRepairGrid.DefaultFont.Charset := ANSI_CHARSET;
    AssetRepairGrid.DefaultFont.Color := clWindowText;
    AssetRepairGrid.DefaultFont.Height := -16;
    AssetRepairGrid.DefaultFont.Name := 'Segoe UI';
    AssetRepairGrid.DefaultFont.Style := [];
    AssetRepairGrid.Stroke.Color := 15987699;
    AssetRepairGrid.Stroke.Width := 2.000000000000000000;
    AssetRepairGrid.TopRow := 1;
    AssetRepairGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AssetRepairGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AssetRepairGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AssetRepairGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AssetRepairGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AssetRepairGrid.Appearance.FixedLayout.Font.Height := -16;
    AssetRepairGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AssetRepairGrid.Appearance.FixedLayout.Font.Style := [];
    AssetRepairGrid.Appearance.NormalLayout.Fill.Color := -1;
    AssetRepairGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AssetRepairGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AssetRepairGrid.Appearance.NormalLayout.Font.Color := clGray;
    AssetRepairGrid.Appearance.NormalLayout.Font.Height := -11;
    AssetRepairGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AssetRepairGrid.Appearance.NormalLayout.Font.Style := [];
    AssetRepairGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AssetRepairGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AssetRepairGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AssetRepairGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AssetRepairGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AssetRepairGrid.Appearance.GroupLayout.Font.Height := -11;
    AssetRepairGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AssetRepairGrid.Appearance.GroupLayout.Font.Style := [];
    AssetRepairGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AssetRepairGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AssetRepairGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AssetRepairGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AssetRepairGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AssetRepairGrid.Appearance.SummaryLayout.Font.Height := -11;
    AssetRepairGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AssetRepairGrid.Appearance.SummaryLayout.Font.Style := [];
    AssetRepairGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AssetRepairGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AssetRepairGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AssetRepairGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetRepairGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AssetRepairGrid.Appearance.SelectedLayout.Font.Height := -16;
    AssetRepairGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AssetRepairGrid.Appearance.SelectedLayout.Font.Style := [];
    AssetRepairGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AssetRepairGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AssetRepairGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AssetRepairGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AssetRepairGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AssetRepairGrid.Appearance.FocusedLayout.Font.Height := -16;
    AssetRepairGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AssetRepairGrid.Appearance.FocusedLayout.Font.Style := [];
    AssetRepairGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AssetRepairGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AssetRepairGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AssetRepairGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AssetRepairGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AssetRepairGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AssetRepairGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AssetRepairGrid.Appearance.BandLayout.Font.Color := clBlack;
    AssetRepairGrid.Appearance.BandLayout.Font.Height := -16;
    AssetRepairGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AssetRepairGrid.Appearance.BandLayout.Font.Style := [];
    AssetRepairGrid.Appearance.ProgressLayout.Color := 15385233;
    AssetRepairGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AssetRepairGrid.LeftCol := 0;
    AssetRepairGrid.ScrollMode := scmItemScrolling;
    AssetRepairGrid.GlobalFont.Scale := 1.000000000000000000;
    AssetRepairGrid.GlobalFont.Style := [];
    AssetRepairGrid.DesignTimeSampleData := True;
    TMSFNCPanel17.SetParentComponent(TMSFNCPageControlAssetPage5);
    TMSFNCPanel17.Name := 'TMSFNCPanel17';
    TMSFNCPanel17.Tag := 1;
    TMSFNCPanel17.AlignWithMargins := True;
    TMSFNCPanel17.Left := 3;
    TMSFNCPanel17.Top := 460;
    TMSFNCPanel17.Width := 1047;
    TMSFNCPanel17.Height := 49;
    TMSFNCPanel17.Align := alBottom;
    TMSFNCPanel17.BevelInner := bvNone;
    TMSFNCPanel17.BevelOuter := bvNone;
    TMSFNCPanel17.Ctl3D := True;
    TMSFNCPanel17.ParentCtl3D := False;
    TMSFNCPanel17.ParentDoubleBuffered := False;
    TMSFNCPanel17.DoubleBuffered := True;
    TMSFNCPanel17.TabOrder := 2;
    TMSFNCPanel17.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel17.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel17.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel17.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel17.SectionsAppearance.Font.Style := [];
    TMSFNCPanel17.Stroke.Kind := gskNone;
    TMSFNCPanel17.Header.Text := 'Header';
    TMSFNCPanel17.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel17.Header.Font.Color := clWindowText;
    TMSFNCPanel17.Header.Font.Height := -11;
    TMSFNCPanel17.Header.Font.Name := 'Tahoma';
    TMSFNCPanel17.Header.Font.Style := [];
    TMSFNCPanel17.Header.Visible := False;
    TMSFNCPanel17.Header.Fill.Color := 16380654;
    TMSFNCPanel17.Header.Stroke.Kind := gskNone;
    TMSFNCPanel17.Footer.Text := 'Footer';
    TMSFNCPanel17.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel17.Footer.Font.Color := clWindowText;
    TMSFNCPanel17.Footer.Font.Height := -11;
    TMSFNCPanel17.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel17.Footer.Font.Style := [];
    TMSFNCPanel17.Footer.Fill.Color := 16380654;
    TMSFNCPanel17.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel17.GlobalFont.Style := [];
    AssetRepairAddButton.SetParentComponent(TMSFNCPanel17);
    AssetRepairAddButton.Name := 'AssetRepairAddButton';
    AssetRepairAddButton.AlignWithMargins := True;
    AssetRepairAddButton.Left := 20;
    AssetRepairAddButton.Top := 8;
    AssetRepairAddButton.Width := 100;
    AssetRepairAddButton.Height := 31;
    AssetRepairAddButton.Margins.Left := 0;
    AssetRepairAddButton.Margins.Top := 8;
    AssetRepairAddButton.Margins.Right := 8;
    AssetRepairAddButton.Margins.Bottom := 10;
    AssetRepairAddButton.Align := alLeft;
    AssetRepairAddButton.Caption := 'Add';
    AssetRepairAddButton.Color := clNone;
    AssetRepairAddButton.ElementClassName := 'MyWebButton';
    AssetRepairAddButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel17.AssetRepairAddButton.Glyph.png');
    AssetRepairAddButton.HeightPercent := 100.000000000000000000;
    AssetRepairAddButton.TabOrder := 0;
    AssetRepairAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetRepairAddButton, Self, 'OnClick', 'AssetRepairAddButtonClick');
    AssetRepairEditButton.SetParentComponent(TMSFNCPanel17);
    AssetRepairEditButton.Name := 'AssetRepairEditButton';
    AssetRepairEditButton.AlignWithMargins := True;
    AssetRepairEditButton.Left := 236;
    AssetRepairEditButton.Top := 8;
    AssetRepairEditButton.Width := 100;
    AssetRepairEditButton.Height := 31;
    AssetRepairEditButton.Margins.Left := 0;
    AssetRepairEditButton.Margins.Top := 8;
    AssetRepairEditButton.Margins.Right := 8;
    AssetRepairEditButton.Margins.Bottom := 10;
    AssetRepairEditButton.Align := alLeft;
    AssetRepairEditButton.Caption := 'Edit';
    AssetRepairEditButton.Color := clNone;
    AssetRepairEditButton.ElementClassName := 'MyWebButton';
    AssetRepairEditButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel17.AssetRepairEditButton.Glyph.png');
    AssetRepairEditButton.HeightPercent := 100.000000000000000000;
    AssetRepairEditButton.TabOrder := 1;
    AssetRepairEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetRepairEditButton, Self, 'OnClick', 'AssetRepairEditButtonClick');
    AssetRepairDeleteButton.SetParentComponent(TMSFNCPanel17);
    AssetRepairDeleteButton.Name := 'AssetRepairDeleteButton';
    AssetRepairDeleteButton.AlignWithMargins := True;
    AssetRepairDeleteButton.Left := 344;
    AssetRepairDeleteButton.Top := 8;
    AssetRepairDeleteButton.Width := 100;
    AssetRepairDeleteButton.Height := 31;
    AssetRepairDeleteButton.Margins.Left := 0;
    AssetRepairDeleteButton.Margins.Top := 8;
    AssetRepairDeleteButton.Margins.Right := 8;
    AssetRepairDeleteButton.Margins.Bottom := 10;
    AssetRepairDeleteButton.Align := alLeft;
    AssetRepairDeleteButton.Caption := 'Delete';
    AssetRepairDeleteButton.Color := clNone;
    AssetRepairDeleteButton.ElementClassName := 'MyWebButton';
    AssetRepairDeleteButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel17.AssetRepairDeleteButton.Glyph.png');
    AssetRepairDeleteButton.HeightPercent := 100.000000000000000000;
    AssetRepairDeleteButton.TabOrder := 2;
    AssetRepairDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetRepairDeleteButton, Self, 'OnClick', 'AssetRepairDeleteButtonClick');
    WebSpeedButton8.SetParentComponent(TMSFNCPanel17);
    WebSpeedButton8.Name := 'WebSpeedButton8';
    WebSpeedButton8.AlignWithMargins := True;
    WebSpeedButton8.Left := 927;
    WebSpeedButton8.Top := 8;
    WebSpeedButton8.Width := 100;
    WebSpeedButton8.Height := 31;
    WebSpeedButton8.Margins.Left := 10;
    WebSpeedButton8.Margins.Top := 8;
    WebSpeedButton8.Margins.Right := 20;
    WebSpeedButton8.Margins.Bottom := 10;
    WebSpeedButton8.Align := alRight;
    WebSpeedButton8.Caption := 'Close';
    WebSpeedButton8.Color := clNone;
    WebSpeedButton8.ElementClassName := 'MyWebButton';
    WebSpeedButton8.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel17.WebSpeedButton8.Glyph.png');
    WebSpeedButton8.HeightPercent := 100.000000000000000000;
    WebSpeedButton8.TabOrder := 3;
    WebSpeedButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton8, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel18.SetParentComponent(TMSFNCPanel17);
    TMSFNCPanel18.Name := 'TMSFNCPanel18';
    TMSFNCPanel18.Tag := 2;
    TMSFNCPanel18.AlignWithMargins := True;
    TMSFNCPanel18.Left := 3;
    TMSFNCPanel18.Top := 3;
    TMSFNCPanel18.Width := 14;
    TMSFNCPanel18.Height := 41;
    TMSFNCPanel18.Margins.Bottom := 5;
    TMSFNCPanel18.Align := alLeft;
    TMSFNCPanel18.BevelInner := bvNone;
    TMSFNCPanel18.BevelOuter := bvNone;
    TMSFNCPanel18.Ctl3D := True;
    TMSFNCPanel18.ParentCtl3D := False;
    TMSFNCPanel18.ParentDoubleBuffered := False;
    TMSFNCPanel18.DoubleBuffered := True;
    TMSFNCPanel18.TabOrder := 4;
    TMSFNCPanel18.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel18.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel18.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel18.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel18.SectionsAppearance.Font.Style := [];
    TMSFNCPanel18.Stroke.Kind := gskNone;
    TMSFNCPanel18.Header.Text := 'Header';
    TMSFNCPanel18.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel18.Header.Font.Color := clWindowText;
    TMSFNCPanel18.Header.Font.Height := -11;
    TMSFNCPanel18.Header.Font.Name := 'Tahoma';
    TMSFNCPanel18.Header.Font.Style := [];
    TMSFNCPanel18.Header.Visible := False;
    TMSFNCPanel18.Header.Fill.Color := 16380654;
    TMSFNCPanel18.Header.Stroke.Kind := gskNone;
    TMSFNCPanel18.Footer.Text := 'Footer';
    TMSFNCPanel18.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel18.Footer.Font.Color := clWindowText;
    TMSFNCPanel18.Footer.Font.Height := -11;
    TMSFNCPanel18.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel18.Footer.Font.Style := [];
    TMSFNCPanel18.Footer.Fill.Color := 16380654;
    TMSFNCPanel18.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel18.GlobalFont.Style := [];
    AssetRepairViewButton.SetParentComponent(TMSFNCPanel17);
    AssetRepairViewButton.Name := 'AssetRepairViewButton';
    AssetRepairViewButton.AlignWithMargins := True;
    AssetRepairViewButton.Left := 128;
    AssetRepairViewButton.Top := 8;
    AssetRepairViewButton.Width := 100;
    AssetRepairViewButton.Height := 31;
    AssetRepairViewButton.Margins.Left := 0;
    AssetRepairViewButton.Margins.Top := 8;
    AssetRepairViewButton.Margins.Right := 8;
    AssetRepairViewButton.Margins.Bottom := 10;
    AssetRepairViewButton.Align := alLeft;
    AssetRepairViewButton.Caption := 'View';
    AssetRepairViewButton.Color := clNone;
    AssetRepairViewButton.ElementClassName := 'MyWebButton';
    AssetRepairViewButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel17.AssetRepairViewButton.Glyph.png');
    AssetRepairViewButton.HeightPercent := 100.000000000000000000;
    AssetRepairViewButton.TabOrder := 5;
    AssetRepairViewButton.Visible := False;
    AssetRepairViewButton.WidthPercent := 100.000000000000000000;
    TMSFNCPageControl1Page0.SetParentComponent(TMSFNCPageControlAsset);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 1053;
    TMSFNCPageControl1Page0.Height := 512;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    AssetStatusFilterPanel.SetParentComponent(TMSFNCPageControl1Page0);
    AssetStatusFilterPanel.Name := 'AssetStatusFilterPanel';
    AssetStatusFilterPanel.Tag := 1;
    AssetStatusFilterPanel.AlignWithMargins := True;
    AssetStatusFilterPanel.Left := 3;
    AssetStatusFilterPanel.Top := 3;
    AssetStatusFilterPanel.Width := 1047;
    AssetStatusFilterPanel.Height := 57;
    AssetStatusFilterPanel.Align := alTop;
    AssetStatusFilterPanel.BevelInner := bvNone;
    AssetStatusFilterPanel.BevelOuter := bvNone;
    AssetStatusFilterPanel.Ctl3D := True;
    AssetStatusFilterPanel.ParentCtl3D := False;
    AssetStatusFilterPanel.ParentDoubleBuffered := False;
    AssetStatusFilterPanel.DoubleBuffered := True;
    AssetStatusFilterPanel.TabOrder := 0;
    AssetStatusFilterPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetStatusFilterPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetStatusFilterPanel.SectionsAppearance.Font.Height := -11;
    AssetStatusFilterPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetStatusFilterPanel.SectionsAppearance.Font.Style := [];
    AssetStatusFilterPanel.Stroke.Kind := gskNone;
    AssetStatusFilterPanel.Header.Text := 'Header';
    AssetStatusFilterPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetStatusFilterPanel.Header.Font.Color := clWindowText;
    AssetStatusFilterPanel.Header.Font.Height := -11;
    AssetStatusFilterPanel.Header.Font.Name := 'Tahoma';
    AssetStatusFilterPanel.Header.Font.Style := [];
    AssetStatusFilterPanel.Header.Visible := False;
    AssetStatusFilterPanel.Footer.Text := 'Footer';
    AssetStatusFilterPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetStatusFilterPanel.Footer.Font.Color := clWindowText;
    AssetStatusFilterPanel.Footer.Font.Height := -11;
    AssetStatusFilterPanel.Footer.Font.Name := 'Tahoma';
    AssetStatusFilterPanel.Footer.Font.Style := [];
    AssetStatusFilterPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetStatusFilterPanel.GlobalFont.Style := [];
    AssetStatusEdit.SetParentComponent(AssetStatusFilterPanel);
    AssetStatusEdit.Name := 'AssetStatusEdit';
    AssetStatusEdit.Left := 17;
    AssetStatusEdit.Top := 10;
    AssetStatusEdit.Width := 345;
    AssetStatusEdit.Height := 34;
    AssetStatusEdit.AutoFocus := True;
    AssetStatusEdit.ChildOrder := 1;
    AssetStatusEdit.Font.Charset := ANSI_CHARSET;
    AssetStatusEdit.Font.Color := clWindowText;
    AssetStatusEdit.Font.Height := -16;
    AssetStatusEdit.Font.Name := 'Segoe UI';
    AssetStatusEdit.Font.Style := [];
    AssetStatusEdit.HeightPercent := 100.000000000000000000;
    AssetStatusEdit.ParentFont := False;
    AssetStatusEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusEdit, Self, 'OnChange', 'SearchEditChange');
    AssetStatusButtonPanel.SetParentComponent(TMSFNCPageControl1Page0);
    AssetStatusButtonPanel.Name := 'AssetStatusButtonPanel';
    AssetStatusButtonPanel.Tag := 1;
    AssetStatusButtonPanel.AlignWithMargins := True;
    AssetStatusButtonPanel.Left := 3;
    AssetStatusButtonPanel.Top := 460;
    AssetStatusButtonPanel.Width := 1047;
    AssetStatusButtonPanel.Height := 49;
    AssetStatusButtonPanel.Align := alBottom;
    AssetStatusButtonPanel.BevelInner := bvNone;
    AssetStatusButtonPanel.BevelOuter := bvNone;
    AssetStatusButtonPanel.Ctl3D := True;
    AssetStatusButtonPanel.ParentCtl3D := False;
    AssetStatusButtonPanel.ParentDoubleBuffered := False;
    AssetStatusButtonPanel.DoubleBuffered := True;
    AssetStatusButtonPanel.TabOrder := 1;
    AssetStatusButtonPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AssetStatusButtonPanel.SectionsAppearance.Font.Color := clWindowText;
    AssetStatusButtonPanel.SectionsAppearance.Font.Height := -11;
    AssetStatusButtonPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AssetStatusButtonPanel.SectionsAppearance.Font.Style := [];
    AssetStatusButtonPanel.Stroke.Kind := gskNone;
    AssetStatusButtonPanel.Header.Text := 'Header';
    AssetStatusButtonPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AssetStatusButtonPanel.Header.Font.Color := clWindowText;
    AssetStatusButtonPanel.Header.Font.Height := -11;
    AssetStatusButtonPanel.Header.Font.Name := 'Tahoma';
    AssetStatusButtonPanel.Header.Font.Style := [];
    AssetStatusButtonPanel.Header.Visible := False;
    AssetStatusButtonPanel.Footer.Text := 'Footer';
    AssetStatusButtonPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AssetStatusButtonPanel.Footer.Font.Color := clWindowText;
    AssetStatusButtonPanel.Footer.Font.Height := -11;
    AssetStatusButtonPanel.Footer.Font.Name := 'Tahoma';
    AssetStatusButtonPanel.Footer.Font.Style := [];
    AssetStatusButtonPanel.GlobalFont.Scale := 1.000000000000000000;
    AssetStatusButtonPanel.GlobalFont.Style := [];
    AssetStatusAddButton.SetParentComponent(AssetStatusButtonPanel);
    AssetStatusAddButton.Name := 'AssetStatusAddButton';
    AssetStatusAddButton.AlignWithMargins := True;
    AssetStatusAddButton.Left := 20;
    AssetStatusAddButton.Top := 8;
    AssetStatusAddButton.Width := 100;
    AssetStatusAddButton.Height := 31;
    AssetStatusAddButton.Margins.Left := 0;
    AssetStatusAddButton.Margins.Top := 8;
    AssetStatusAddButton.Margins.Right := 8;
    AssetStatusAddButton.Margins.Bottom := 10;
    AssetStatusAddButton.Align := alLeft;
    AssetStatusAddButton.Caption := 'Add';
    AssetStatusAddButton.Color := clNone;
    AssetStatusAddButton.ElementClassName := 'MyWebButton';
    AssetStatusAddButton.Glyph.LoadFromFile('uGeneralSettings.AssetStatusButtonPanel.AssetStatusAddButton.Glyph.png');
    AssetStatusAddButton.HeightPercent := 100.000000000000000000;
    AssetStatusAddButton.TabOrder := 0;
    AssetStatusAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusAddButton, Self, 'OnClick', 'AssetStatusAddButtonClick');
    AssetStatusEditButton.SetParentComponent(AssetStatusButtonPanel);
    AssetStatusEditButton.Name := 'AssetStatusEditButton';
    AssetStatusEditButton.AlignWithMargins := True;
    AssetStatusEditButton.Left := 236;
    AssetStatusEditButton.Top := 8;
    AssetStatusEditButton.Width := 100;
    AssetStatusEditButton.Height := 31;
    AssetStatusEditButton.Margins.Left := 0;
    AssetStatusEditButton.Margins.Top := 8;
    AssetStatusEditButton.Margins.Right := 8;
    AssetStatusEditButton.Margins.Bottom := 10;
    AssetStatusEditButton.Align := alLeft;
    AssetStatusEditButton.Caption := 'Edit';
    AssetStatusEditButton.Color := clNone;
    AssetStatusEditButton.ElementClassName := 'MyWebButton';
    AssetStatusEditButton.Glyph.LoadFromFile('uGeneralSettings.AssetStatusButtonPanel.AssetStatusEditButton.Glyph.png');
    AssetStatusEditButton.HeightPercent := 100.000000000000000000;
    AssetStatusEditButton.TabOrder := 1;
    AssetStatusEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusEditButton, Self, 'OnClick', 'AssetStatusEditButtonClick');
    AssetStatusDeleteButton.SetParentComponent(AssetStatusButtonPanel);
    AssetStatusDeleteButton.Name := 'AssetStatusDeleteButton';
    AssetStatusDeleteButton.AlignWithMargins := True;
    AssetStatusDeleteButton.Left := 344;
    AssetStatusDeleteButton.Top := 8;
    AssetStatusDeleteButton.Width := 100;
    AssetStatusDeleteButton.Height := 31;
    AssetStatusDeleteButton.Margins.Left := 0;
    AssetStatusDeleteButton.Margins.Top := 8;
    AssetStatusDeleteButton.Margins.Right := 8;
    AssetStatusDeleteButton.Margins.Bottom := 10;
    AssetStatusDeleteButton.Align := alLeft;
    AssetStatusDeleteButton.Caption := 'Delete';
    AssetStatusDeleteButton.Color := clNone;
    AssetStatusDeleteButton.ElementClassName := 'MyWebButton';
    AssetStatusDeleteButton.Glyph.LoadFromFile('uGeneralSettings.AssetStatusButtonPanel.AssetStatusDeleteButton.Glyph.png');
    AssetStatusDeleteButton.HeightPercent := 100.000000000000000000;
    AssetStatusDeleteButton.TabOrder := 2;
    AssetStatusDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusDeleteButton, Self, 'OnClick', 'AssetStatusDeleteButtonClick');
    AssetStatusCloseButton.SetParentComponent(AssetStatusButtonPanel);
    AssetStatusCloseButton.Name := 'AssetStatusCloseButton';
    AssetStatusCloseButton.AlignWithMargins := True;
    AssetStatusCloseButton.Left := 927;
    AssetStatusCloseButton.Top := 8;
    AssetStatusCloseButton.Width := 100;
    AssetStatusCloseButton.Height := 31;
    AssetStatusCloseButton.Margins.Left := 10;
    AssetStatusCloseButton.Margins.Top := 8;
    AssetStatusCloseButton.Margins.Right := 20;
    AssetStatusCloseButton.Margins.Bottom := 10;
    AssetStatusCloseButton.Align := alRight;
    AssetStatusCloseButton.Caption := 'Close';
    AssetStatusCloseButton.Color := clNone;
    AssetStatusCloseButton.ElementClassName := 'MyWebButton';
    AssetStatusCloseButton.Glyph.LoadFromFile('uGeneralSettings.AssetStatusButtonPanel.AssetStatusCloseButton.Glyph.png');
    AssetStatusCloseButton.HeightPercent := 100.000000000000000000;
    AssetStatusCloseButton.TabOrder := 3;
    AssetStatusCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusCloseButton, Self, 'OnClick', 'CloseButtonClick');
    TMSFNCPanel3.SetParentComponent(AssetStatusButtonPanel);
    TMSFNCPanel3.Name := 'TMSFNCPanel3';
    TMSFNCPanel3.Tag := 2;
    TMSFNCPanel3.AlignWithMargins := True;
    TMSFNCPanel3.Left := 3;
    TMSFNCPanel3.Top := 3;
    TMSFNCPanel3.Width := 14;
    TMSFNCPanel3.Height := 41;
    TMSFNCPanel3.Margins.Bottom := 5;
    TMSFNCPanel3.Align := alLeft;
    TMSFNCPanel3.BevelInner := bvNone;
    TMSFNCPanel3.BevelOuter := bvNone;
    TMSFNCPanel3.Ctl3D := True;
    TMSFNCPanel3.ParentCtl3D := False;
    TMSFNCPanel3.ParentDoubleBuffered := False;
    TMSFNCPanel3.DoubleBuffered := True;
    TMSFNCPanel3.TabOrder := 4;
    TMSFNCPanel3.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel3.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel3.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel3.SectionsAppearance.Font.Style := [];
    TMSFNCPanel3.Stroke.Kind := gskNone;
    TMSFNCPanel3.Header.Text := 'Header';
    TMSFNCPanel3.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Header.Font.Color := clWindowText;
    TMSFNCPanel3.Header.Font.Height := -11;
    TMSFNCPanel3.Header.Font.Name := 'Tahoma';
    TMSFNCPanel3.Header.Font.Style := [];
    TMSFNCPanel3.Header.Visible := False;
    TMSFNCPanel3.Footer.Text := 'Footer';
    TMSFNCPanel3.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Footer.Font.Color := clWindowText;
    TMSFNCPanel3.Footer.Font.Height := -11;
    TMSFNCPanel3.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel3.Footer.Font.Style := [];
    TMSFNCPanel3.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel3.GlobalFont.Style := [];
    AssetStatusViewButton.SetParentComponent(AssetStatusButtonPanel);
    AssetStatusViewButton.Name := 'AssetStatusViewButton';
    AssetStatusViewButton.AlignWithMargins := True;
    AssetStatusViewButton.Left := 128;
    AssetStatusViewButton.Top := 8;
    AssetStatusViewButton.Width := 100;
    AssetStatusViewButton.Height := 31;
    AssetStatusViewButton.Margins.Left := 0;
    AssetStatusViewButton.Margins.Top := 8;
    AssetStatusViewButton.Margins.Right := 8;
    AssetStatusViewButton.Margins.Bottom := 10;
    AssetStatusViewButton.Align := alLeft;
    AssetStatusViewButton.Caption := 'View';
    AssetStatusViewButton.Color := clNone;
    AssetStatusViewButton.ElementClassName := 'MyWebButton';
    AssetStatusViewButton.Glyph.LoadFromFile('uGeneralSettings.AssetStatusButtonPanel.AssetStatusViewButton.Glyph.png');
    AssetStatusViewButton.HeightPercent := 100.000000000000000000;
    AssetStatusViewButton.TabOrder := 5;
    AssetStatusViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusViewButton, Self, 'OnClick', 'AssetStatusEditButtonClick');
    AssetStatusGrid.SetParentComponent(TMSFNCPageControl1Page0);
    AssetStatusGrid.Name := 'AssetStatusGrid';
    AssetStatusGrid.AlignWithMargins := True;
    AssetStatusGrid.Left := 20;
    AssetStatusGrid.Top := 63;
    AssetStatusGrid.Width := 1013;
    AssetStatusGrid.Height := 384;
    AssetStatusGrid.Margins.Left := 20;
    AssetStatusGrid.Margins.Top := 0;
    AssetStatusGrid.Margins.Right := 20;
    AssetStatusGrid.Margins.Bottom := 10;
    AssetStatusGrid.AdaptToStyle := True;
    AssetStatusGrid.Align := alClient;
    AssetStatusGrid.BevelInner := bvNone;
    AssetStatusGrid.BevelOuter := bvNone;
    AssetStatusGrid.BevelWidth := 2;
    AssetStatusGrid.Ctl3D := False;
    AssetStatusGrid.ParentCtl3D := False;
    AssetStatusGrid.ParentDoubleBuffered := False;
    AssetStatusGrid.DoubleBuffered := True;
    AssetStatusGrid.Font.Charset := DEFAULT_CHARSET;
    AssetStatusGrid.Font.Color := clWindowText;
    AssetStatusGrid.Font.Height := -16;
    AssetStatusGrid.Font.Name := 'Tahoma';
    AssetStatusGrid.Font.Style := [];
    AssetStatusGrid.ParentFont := False;
    AssetStatusGrid.TabOrder := 2;
    SetEvent(AssetStatusGrid, Self, 'OnDblClick', 'AssetStatusGridDblClick');
    AssetStatusGrid.DefaultRowHeight := 34.000000000000000000;
    AssetStatusGrid.FixedColumns := 0;
    AssetStatusGrid.ColumnCount := 1;
    AssetStatusGrid.RowCount := 1;
    AssetStatusGrid.Options.Borders.FixedCellBorders := bNone;
    AssetStatusGrid.Options.ColumnSize.Stretch := True;
    AssetStatusGrid.Options.ColumnSize.StretchColumn := 0;
    AssetStatusGrid.Options.Editing.CalcFormat := '%g';
    AssetStatusGrid.Options.Editing.Enabled := False;
    AssetStatusGrid.Options.Grouping.CalcFormat := '%g';
    AssetStatusGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AssetStatusGrid.Options.HTMLExport.CellPadding := 10;
    AssetStatusGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AssetStatusGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AssetStatusGrid.Options.Mouse.ClickMargin := 0;
    AssetStatusGrid.Options.Mouse.ColumnSizeMargin := 6;
    AssetStatusGrid.Options.Mouse.RowSizeMargin := 6;
    AssetStatusGrid.Options.URL.Color := clSkyBlue;
    AssetStatusGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AssetStatusGrid.Options.Selection.Mode := smSingleRow;
    AssetStatusGrid.Adapter := AssetStatusGridAdapter;
    AssetStatusGrid.HorizontalScrollBarVisible := False;
    AssetStatusGrid.Columns.Clear;
    with AssetStatusGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1012.000000000000000000;
    end;
    AssetStatusGrid.Fill.ColorTo := clSilver;
    AssetStatusGrid.DefaultFont.Charset := ANSI_CHARSET;
    AssetStatusGrid.DefaultFont.Color := clWindowText;
    AssetStatusGrid.DefaultFont.Height := -16;
    AssetStatusGrid.DefaultFont.Name := 'Segoe UI';
    AssetStatusGrid.DefaultFont.Style := [];
    AssetStatusGrid.Stroke.Color := 15987699;
    AssetStatusGrid.Stroke.Width := 2.000000000000000000;
    AssetStatusGrid.TopRow := 1;
    AssetStatusGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AssetStatusGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AssetStatusGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AssetStatusGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AssetStatusGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AssetStatusGrid.Appearance.FixedLayout.Font.Height := -16;
    AssetStatusGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AssetStatusGrid.Appearance.FixedLayout.Font.Style := [];
    AssetStatusGrid.Appearance.NormalLayout.Fill.Color := -1;
    AssetStatusGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AssetStatusGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AssetStatusGrid.Appearance.NormalLayout.Font.Color := clGray;
    AssetStatusGrid.Appearance.NormalLayout.Font.Height := -11;
    AssetStatusGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AssetStatusGrid.Appearance.NormalLayout.Font.Style := [];
    AssetStatusGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AssetStatusGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AssetStatusGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AssetStatusGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AssetStatusGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AssetStatusGrid.Appearance.GroupLayout.Font.Height := -11;
    AssetStatusGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AssetStatusGrid.Appearance.GroupLayout.Font.Style := [];
    AssetStatusGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AssetStatusGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AssetStatusGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AssetStatusGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AssetStatusGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AssetStatusGrid.Appearance.SummaryLayout.Font.Height := -11;
    AssetStatusGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AssetStatusGrid.Appearance.SummaryLayout.Font.Style := [];
    AssetStatusGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AssetStatusGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AssetStatusGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AssetStatusGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetStatusGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AssetStatusGrid.Appearance.SelectedLayout.Font.Height := -16;
    AssetStatusGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AssetStatusGrid.Appearance.SelectedLayout.Font.Style := [];
    AssetStatusGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AssetStatusGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AssetStatusGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AssetStatusGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AssetStatusGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AssetStatusGrid.Appearance.FocusedLayout.Font.Height := -16;
    AssetStatusGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AssetStatusGrid.Appearance.FocusedLayout.Font.Style := [];
    AssetStatusGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AssetStatusGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AssetStatusGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AssetStatusGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AssetStatusGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AssetStatusGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AssetStatusGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AssetStatusGrid.Appearance.BandLayout.Font.Color := clBlack;
    AssetStatusGrid.Appearance.BandLayout.Font.Height := -16;
    AssetStatusGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AssetStatusGrid.Appearance.BandLayout.Font.Style := [];
    AssetStatusGrid.Appearance.ProgressLayout.Color := 15385233;
    AssetStatusGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AssetStatusGrid.LeftCol := 0;
    AssetStatusGrid.ScrollMode := scmItemScrolling;
    AssetStatusGrid.GlobalFont.Scale := 1.000000000000000000;
    AssetStatusGrid.GlobalFont.Style := [];
    AssetStatusGrid.DesignTimeSampleData := True;
    TMSFNCPageControlPage3.SetParentComponent(TMSFNCPageControl);
    TMSFNCPageControlPage3.Name := 'TMSFNCPageControlPage3';
    TMSFNCPageControlPage3.AlignWithMargins := True;
    TMSFNCPageControlPage3.Left := 0;
    TMSFNCPageControlPage3.Top := 52;
    TMSFNCPageControlPage3.Width := 1073;
    TMSFNCPageControlPage3.Height := 572;
    TMSFNCPageControlPage3.Margins.Left := 0;
    TMSFNCPageControlPage3.Margins.Top := 52;
    TMSFNCPageControlPage3.Margins.Right := 0;
    TMSFNCPageControlPage3.Margins.Bottom := 0;
    TMSFNCPageControlPage3.Align := alClient;
    TMSFNCPageControlPage3.BevelInner := bvNone;
    TMSFNCPageControlPage3.ParentDoubleBuffered := False;
    TMSFNCPageControlPage3.DoubleBuffered := True;
    TMSFNCPageControlPage3.TabStop := False;
    TMSFNCPageControlPage3.TabOrder := 3;
    TMSFNCPageControlPage3.Visible := False;
    TMSFNCPageControlPage3.Stroke.Color := clBackground;
    TMSFNCPageControlPage3.Stroke.Width := 2.000000000000000000;
    TMSFNCPageControlPage3.PageIndex := 0;
    TMSFNCPageControlPage3.IsActive := False;
    RealTimeMapMaximumDistanceLabel.SetParentComponent(TMSFNCPageControlPage3);
    RealTimeMapMaximumDistanceLabel.Name := 'RealTimeMapMaximumDistanceLabel';
    RealTimeMapMaximumDistanceLabel.Left := 23;
    RealTimeMapMaximumDistanceLabel.Top := 26;
    RealTimeMapMaximumDistanceLabel.Width := 268;
    RealTimeMapMaximumDistanceLabel.Height := 21;
    RealTimeMapMaximumDistanceLabel.Caption := 'Realtime map maximum distance (Km)';
    RealTimeMapMaximumDistanceLabel.Font.Charset := ANSI_CHARSET;
    RealTimeMapMaximumDistanceLabel.Font.Color := 9010544;
    RealTimeMapMaximumDistanceLabel.Font.Height := -16;
    RealTimeMapMaximumDistanceLabel.Font.Name := 'Segoe UI';
    RealTimeMapMaximumDistanceLabel.Font.Style := [];
    RealTimeMapMaximumDistanceLabel.HeightPercent := 100.000000000000000000;
    RealTimeMapMaximumDistanceLabel.ParentFont := False;
    RealTimeMapMaximumDistanceLabel.WidthPercent := 100.000000000000000000;
    ReportServerURLLabel.SetParentComponent(TMSFNCPageControlPage3);
    ReportServerURLLabel.Name := 'ReportServerURLLabel';
    ReportServerURLLabel.Left := 23;
    ReportServerURLLabel.Top := 186;
    ReportServerURLLabel.Width := 127;
    ReportServerURLLabel.Height := 21;
    ReportServerURLLabel.Caption := 'Report server URL';
    ReportServerURLLabel.Font.Charset := ANSI_CHARSET;
    ReportServerURLLabel.Font.Color := 9010544;
    ReportServerURLLabel.Font.Height := -16;
    ReportServerURLLabel.Font.Name := 'Segoe UI';
    ReportServerURLLabel.Font.Style := [];
    ReportServerURLLabel.HeightPercent := 100.000000000000000000;
    ReportServerURLLabel.ParentFont := False;
    ReportServerURLLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControlPage3);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 322;
    WebLabel1.Width := 72;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Thirdparty';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TMSFNCPageControlPage3);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 239;
    WebLabel2.Top := 322;
    WebLabel2.Width := 124;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Interval (Seconds)';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(TMSFNCPageControlPage3);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 156;
    WebLabel3.Top := 322;
    WebLabel3.Width := 38;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Color';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(TMSFNCPageControlPage3);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 23;
    WebLabel4.Top := 106;
    WebLabel4.Width := 326;
    WebLabel4.Height := 21;
    WebLabel4.Hint := 'When the number of assets in the realtime map is higher than this number a message is displayed before  update';
    WebLabel4.Caption := 'Min. Asset count for refresh message to appear';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.ShowHint := True;
    WebLabel4.WidthPercent := 100.000000000000000000;
    RealTimeMapMaximumDistanceEdit.SetParentComponent(TMSFNCPageControlPage3);
    RealTimeMapMaximumDistanceEdit.Name := 'RealTimeMapMaximumDistanceEdit';
    RealTimeMapMaximumDistanceEdit.Left := 20;
    RealTimeMapMaximumDistanceEdit.Top := 53;
    RealTimeMapMaximumDistanceEdit.Width := 345;
    RealTimeMapMaximumDistanceEdit.Height := 34;
    RealTimeMapMaximumDistanceEdit.ChildOrder := 1;
    RealTimeMapMaximumDistanceEdit.EditType := weNumeric;
    RealTimeMapMaximumDistanceEdit.ElementClassName := 'MyWebEdit';
    RealTimeMapMaximumDistanceEdit.Font.Charset := ANSI_CHARSET;
    RealTimeMapMaximumDistanceEdit.Font.Color := clWindowText;
    RealTimeMapMaximumDistanceEdit.Font.Height := -16;
    RealTimeMapMaximumDistanceEdit.Font.Name := 'Segoe UI';
    RealTimeMapMaximumDistanceEdit.Font.Style := [];
    RealTimeMapMaximumDistanceEdit.HeightPercent := 100.000000000000000000;
    RealTimeMapMaximumDistanceEdit.ParentFont := False;
    RealTimeMapMaximumDistanceEdit.WidthPercent := 100.000000000000000000;
    SetEvent(RealTimeMapMaximumDistanceEdit, Self, 'OnChange', 'RecordChange');
    TMSFNCPanel1.SetParentComponent(TMSFNCPageControlPage3);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 520;
    TMSFNCPanel1.Width := 1067;
    TMSFNCPanel1.Height := 49;
    TMSFNCPanel1.Align := alBottom;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 1;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    SaveButton.SetParentComponent(TMSFNCPanel1);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 20;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alLeft;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel1.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    SettingsGeneralCloseButton.SetParentComponent(TMSFNCPanel1);
    SettingsGeneralCloseButton.Name := 'SettingsGeneralCloseButton';
    SettingsGeneralCloseButton.AlignWithMargins := True;
    SettingsGeneralCloseButton.Left := 947;
    SettingsGeneralCloseButton.Top := 8;
    SettingsGeneralCloseButton.Width := 100;
    SettingsGeneralCloseButton.Height := 31;
    SettingsGeneralCloseButton.Margins.Left := 10;
    SettingsGeneralCloseButton.Margins.Top := 8;
    SettingsGeneralCloseButton.Margins.Right := 20;
    SettingsGeneralCloseButton.Margins.Bottom := 10;
    SettingsGeneralCloseButton.Align := alRight;
    SettingsGeneralCloseButton.Caption := '&Cancel';
    SettingsGeneralCloseButton.Color := clNone;
    SettingsGeneralCloseButton.ElementClassName := 'MyWebButton';
    SettingsGeneralCloseButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel1.SettingsGeneralCloseButton.Glyph.png');
    SettingsGeneralCloseButton.HeightPercent := 100.000000000000000000;
    SettingsGeneralCloseButton.TabOrder := 1;
    SettingsGeneralCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(SettingsGeneralCloseButton, Self, 'OnClick', 'CloseImageClick');
    ReportServerURLEdit.SetParentComponent(TMSFNCPageControlPage3);
    ReportServerURLEdit.Name := 'ReportServerURLEdit';
    ReportServerURLEdit.Left := 20;
    ReportServerURLEdit.Top := 213;
    ReportServerURLEdit.Width := 345;
    ReportServerURLEdit.Height := 34;
    ReportServerURLEdit.ChildOrder := 1;
    ReportServerURLEdit.ElementClassName := 'MyWebEdit';
    ReportServerURLEdit.Font.Charset := ANSI_CHARSET;
    ReportServerURLEdit.Font.Color := clWindowText;
    ReportServerURLEdit.Font.Height := -16;
    ReportServerURLEdit.Font.Name := 'Segoe UI';
    ReportServerURLEdit.Font.Style := [];
    ReportServerURLEdit.HeightPercent := 100.000000000000000000;
    ReportServerURLEdit.ParentFont := False;
    ReportServerURLEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportServerURLEdit, Self, 'OnChange', 'RecordChange');
    AssetIDIsReadOnlyEdit.SetParentComponent(TMSFNCPageControlPage3);
    AssetIDIsReadOnlyEdit.Name := 'AssetIDIsReadOnlyEdit';
    AssetIDIsReadOnlyEdit.Left := 20;
    AssetIDIsReadOnlyEdit.Top := 274;
    AssetIDIsReadOnlyEdit.Width := 345;
    AssetIDIsReadOnlyEdit.Height := 26;
    AssetIDIsReadOnlyEdit.Caption := 'Asset ID is read only';
    AssetIDIsReadOnlyEdit.ChildOrder := 28;
    AssetIDIsReadOnlyEdit.Font.Charset := ANSI_CHARSET;
    AssetIDIsReadOnlyEdit.Font.Color := clWindowText;
    AssetIDIsReadOnlyEdit.Font.Height := -16;
    AssetIDIsReadOnlyEdit.Font.Name := 'Segoe UI';
    AssetIDIsReadOnlyEdit.Font.Style := [];
    AssetIDIsReadOnlyEdit.HeightPercent := 100.000000000000000000;
    AssetIDIsReadOnlyEdit.ParentFont := False;
    AssetIDIsReadOnlyEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetIDIsReadOnlyEdit, Self, 'OnClick', 'RecordChange');
    MachineMaxEnabledEdit.SetParentComponent(TMSFNCPageControlPage3);
    MachineMaxEnabledEdit.Name := 'MachineMaxEnabledEdit';
    MachineMaxEnabledEdit.Left := 20;
    MachineMaxEnabledEdit.Top := 352;
    MachineMaxEnabledEdit.Width := 130;
    MachineMaxEnabledEdit.Height := 26;
    MachineMaxEnabledEdit.Caption := 'MachineMax';
    MachineMaxEnabledEdit.ChildOrder := 28;
    MachineMaxEnabledEdit.Font.Charset := ANSI_CHARSET;
    MachineMaxEnabledEdit.Font.Color := clWindowText;
    MachineMaxEnabledEdit.Font.Height := -16;
    MachineMaxEnabledEdit.Font.Name := 'Segoe UI';
    MachineMaxEnabledEdit.Font.Style := [];
    MachineMaxEnabledEdit.HeightPercent := 100.000000000000000000;
    MachineMaxEnabledEdit.ParentFont := False;
    MachineMaxEnabledEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MachineMaxEnabledEdit, Self, 'OnClick', 'RecordChange');
    MachineMaxIntervalEdit.SetParentComponent(TMSFNCPageControlPage3);
    MachineMaxIntervalEdit.Name := 'MachineMaxIntervalEdit';
    MachineMaxIntervalEdit.Left := 239;
    MachineMaxIntervalEdit.Top := 350;
    MachineMaxIntervalEdit.Width := 126;
    MachineMaxIntervalEdit.Height := 34;
    MachineMaxIntervalEdit.ChildOrder := 1;
    MachineMaxIntervalEdit.EditType := weNumeric;
    MachineMaxIntervalEdit.ElementClassName := 'MyWebEdit';
    MachineMaxIntervalEdit.Font.Charset := ANSI_CHARSET;
    MachineMaxIntervalEdit.Font.Color := clWindowText;
    MachineMaxIntervalEdit.Font.Height := -16;
    MachineMaxIntervalEdit.Font.Name := 'Segoe UI';
    MachineMaxIntervalEdit.Font.Style := [];
    MachineMaxIntervalEdit.HeightPercent := 100.000000000000000000;
    MachineMaxIntervalEdit.ParentFont := False;
    MachineMaxIntervalEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MachineMaxIntervalEdit, Self, 'OnChange', 'RecordChange');
    MachineMaxFillColorEdit.SetParentComponent(TMSFNCPageControlPage3);
    MachineMaxFillColorEdit.Name := 'MachineMaxFillColorEdit';
    MachineMaxFillColorEdit.Left := 158;
    MachineMaxFillColorEdit.Top := 350;
    MachineMaxFillColorEdit.Width := 34;
    MachineMaxFillColorEdit.Height := 34;
    MachineMaxFillColorEdit.ParentDoubleBuffered := False;
    MachineMaxFillColorEdit.DoubleBuffered := True;
    MachineMaxFillColorEdit.TabOrder := 6;
    SetEvent(MachineMaxFillColorEdit, Self, 'OnClick', 'MachineMaxFillColorEditClick');
    MachineMaxFillColorEdit.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    MachineMaxFillColorEdit.SectionsAppearance.Font.Color := clWindowText;
    MachineMaxFillColorEdit.SectionsAppearance.Font.Height := -11;
    MachineMaxFillColorEdit.SectionsAppearance.Font.Name := 'Tahoma';
    MachineMaxFillColorEdit.SectionsAppearance.Font.Style := [];
    MachineMaxFillColorEdit.Header.Text := 'Header';
    MachineMaxFillColorEdit.Header.Font.Charset := DEFAULT_CHARSET;
    MachineMaxFillColorEdit.Header.Font.Color := clWindowText;
    MachineMaxFillColorEdit.Header.Font.Height := -11;
    MachineMaxFillColorEdit.Header.Font.Name := 'Tahoma';
    MachineMaxFillColorEdit.Header.Font.Style := [];
    MachineMaxFillColorEdit.Header.Visible := False;
    MachineMaxFillColorEdit.Header.Stroke.Color := clBlack;
    MachineMaxFillColorEdit.Footer.Text := 'Footer';
    MachineMaxFillColorEdit.Footer.Font.Charset := DEFAULT_CHARSET;
    MachineMaxFillColorEdit.Footer.Font.Color := clWindowText;
    MachineMaxFillColorEdit.Footer.Font.Height := -11;
    MachineMaxFillColorEdit.Footer.Font.Name := 'Tahoma';
    MachineMaxFillColorEdit.Footer.Font.Style := [];
    MachineMaxFillColorEdit.CompactAction := pcNone;
    MachineMaxFillColorEdit.GlobalFont.Scale := 1.000000000000000000;
    MachineMaxFillColorEdit.GlobalFont.Style := [];
    RealtimeMapRefreshAssetCountMax.SetParentComponent(TMSFNCPageControlPage3);
    RealtimeMapRefreshAssetCountMax.Name := 'RealtimeMapRefreshAssetCountMax';
    RealtimeMapRefreshAssetCountMax.Left := 20;
    RealtimeMapRefreshAssetCountMax.Top := 133;
    RealtimeMapRefreshAssetCountMax.Width := 345;
    RealtimeMapRefreshAssetCountMax.Height := 34;
    RealtimeMapRefreshAssetCountMax.ChildOrder := 1;
    RealtimeMapRefreshAssetCountMax.EditType := weNumeric;
    RealtimeMapRefreshAssetCountMax.ElementClassName := 'MyWebEdit';
    RealtimeMapRefreshAssetCountMax.Font.Charset := ANSI_CHARSET;
    RealtimeMapRefreshAssetCountMax.Font.Color := clWindowText;
    RealtimeMapRefreshAssetCountMax.Font.Height := -16;
    RealtimeMapRefreshAssetCountMax.Font.Name := 'Segoe UI';
    RealtimeMapRefreshAssetCountMax.Font.Style := [];
    RealtimeMapRefreshAssetCountMax.HeightPercent := 100.000000000000000000;
    RealtimeMapRefreshAssetCountMax.ParentFont := False;
    RealtimeMapRefreshAssetCountMax.WidthPercent := 100.000000000000000000;
    SetEvent(RealtimeMapRefreshAssetCountMax, Self, 'OnChange', 'RecordChange');
    TMSFNCPageControlPage5.SetParentComponent(TMSFNCPageControl);
    TMSFNCPageControlPage5.Name := 'TMSFNCPageControlPage5';
    TMSFNCPageControlPage5.AlignWithMargins := True;
    TMSFNCPageControlPage5.Left := 0;
    TMSFNCPageControlPage5.Top := 52;
    TMSFNCPageControlPage5.Width := 1073;
    TMSFNCPageControlPage5.Height := 572;
    TMSFNCPageControlPage5.Margins.Left := 0;
    TMSFNCPageControlPage5.Margins.Top := 52;
    TMSFNCPageControlPage5.Margins.Right := 0;
    TMSFNCPageControlPage5.Margins.Bottom := 0;
    TMSFNCPageControlPage5.Align := alClient;
    TMSFNCPageControlPage5.ParentDoubleBuffered := False;
    TMSFNCPageControlPage5.DoubleBuffered := True;
    TMSFNCPageControlPage5.TabStop := False;
    TMSFNCPageControlPage5.TabOrder := 5;
    TMSFNCPageControlPage5.PageIndex := 5;
    TMSFNCPageControlPage5.IsActive := True;
    TMSFNCPanel19.SetParentComponent(TMSFNCPageControlPage5);
    TMSFNCPanel19.Name := 'TMSFNCPanel19';
    TMSFNCPanel19.Tag := 1;
    TMSFNCPanel19.AlignWithMargins := True;
    TMSFNCPanel19.Left := 3;
    TMSFNCPanel19.Top := 3;
    TMSFNCPanel19.Width := 1067;
    TMSFNCPanel19.Height := 57;
    TMSFNCPanel19.Align := alTop;
    TMSFNCPanel19.BevelInner := bvNone;
    TMSFNCPanel19.BevelOuter := bvNone;
    TMSFNCPanel19.Ctl3D := True;
    TMSFNCPanel19.ParentCtl3D := False;
    TMSFNCPanel19.ParentDoubleBuffered := False;
    TMSFNCPanel19.DoubleBuffered := True;
    TMSFNCPanel19.TabOrder := 0;
    TMSFNCPanel19.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel19.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel19.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel19.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel19.SectionsAppearance.Font.Style := [];
    TMSFNCPanel19.Stroke.Kind := gskNone;
    TMSFNCPanel19.Header.Text := 'Header';
    TMSFNCPanel19.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel19.Header.Font.Color := clWindowText;
    TMSFNCPanel19.Header.Font.Height := -11;
    TMSFNCPanel19.Header.Font.Name := 'Tahoma';
    TMSFNCPanel19.Header.Font.Style := [];
    TMSFNCPanel19.Header.Visible := False;
    TMSFNCPanel19.Header.Fill.Color := 16380654;
    TMSFNCPanel19.Header.Stroke.Kind := gskNone;
    TMSFNCPanel19.Footer.Text := 'Footer';
    TMSFNCPanel19.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel19.Footer.Font.Color := clWindowText;
    TMSFNCPanel19.Footer.Font.Height := -11;
    TMSFNCPanel19.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel19.Footer.Font.Style := [];
    TMSFNCPanel19.Footer.Fill.Color := 16380654;
    TMSFNCPanel19.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel19.GlobalFont.Style := [];
    WebLabel5.SetParentComponent(TMSFNCPanel19);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 20;
    WebLabel5.Top := 23;
    WebLabel5.Width := 198;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'Automatically add managers';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    UserManagerYesEdit.SetParentComponent(TMSFNCPanel19);
    UserManagerYesEdit.Name := 'UserManagerYesEdit';
    UserManagerYesEdit.Left := 259;
    UserManagerYesEdit.Top := 24;
    UserManagerYesEdit.Width := 62;
    UserManagerYesEdit.Height := 22;
    UserManagerYesEdit.Caption := 'All';
    UserManagerYesEdit.Checked := True;
    UserManagerYesEdit.ChildOrder := 1;
    UserManagerYesEdit.Color := clNone;
    UserManagerYesEdit.GroupName := 'UserManager';
    UserManagerYesEdit.HeightPercent := 100.000000000000000000;
    UserManagerYesEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UserManagerYesEdit, Self, 'OnClick', 'RecordChange');
    UserManagerNoEdit.SetParentComponent(TMSFNCPanel19);
    UserManagerNoEdit.Name := 'UserManagerNoEdit';
    UserManagerNoEdit.Left := 327;
    UserManagerNoEdit.Top := 24;
    UserManagerNoEdit.Width := 482;
    UserManagerNoEdit.Height := 22;
    UserManagerNoEdit.Caption := 'Only selected';
    UserManagerNoEdit.Checked := False;
    UserManagerNoEdit.ChildOrder := 1;
    UserManagerNoEdit.Color := clNone;
    UserManagerNoEdit.GroupName := 'UserManager';
    UserManagerNoEdit.HeightPercent := 100.000000000000000000;
    UserManagerNoEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UserManagerNoEdit, Self, 'OnClick', 'RecordChange');
    TMSFNCPanel20.SetParentComponent(TMSFNCPageControlPage5);
    TMSFNCPanel20.Name := 'TMSFNCPanel20';
    TMSFNCPanel20.Tag := 1;
    TMSFNCPanel20.AlignWithMargins := True;
    TMSFNCPanel20.Left := 3;
    TMSFNCPanel20.Top := 520;
    TMSFNCPanel20.Width := 1067;
    TMSFNCPanel20.Height := 49;
    TMSFNCPanel20.Align := alBottom;
    TMSFNCPanel20.BevelInner := bvNone;
    TMSFNCPanel20.BevelOuter := bvNone;
    TMSFNCPanel20.Ctl3D := True;
    TMSFNCPanel20.ParentCtl3D := False;
    TMSFNCPanel20.ParentDoubleBuffered := False;
    TMSFNCPanel20.DoubleBuffered := True;
    TMSFNCPanel20.TabOrder := 1;
    TMSFNCPanel20.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel20.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel20.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel20.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel20.SectionsAppearance.Font.Style := [];
    TMSFNCPanel20.Stroke.Kind := gskNone;
    TMSFNCPanel20.Header.Text := 'Header';
    TMSFNCPanel20.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel20.Header.Font.Color := clWindowText;
    TMSFNCPanel20.Header.Font.Height := -11;
    TMSFNCPanel20.Header.Font.Name := 'Tahoma';
    TMSFNCPanel20.Header.Font.Style := [];
    TMSFNCPanel20.Header.Visible := False;
    TMSFNCPanel20.Header.Fill.Color := 16380654;
    TMSFNCPanel20.Header.Stroke.Kind := gskNone;
    TMSFNCPanel20.Footer.Text := 'Footer';
    TMSFNCPanel20.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel20.Footer.Font.Color := clWindowText;
    TMSFNCPanel20.Footer.Font.Height := -11;
    TMSFNCPanel20.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel20.Footer.Font.Style := [];
    TMSFNCPanel20.Footer.Fill.Color := 16380654;
    TMSFNCPanel20.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel20.GlobalFont.Style := [];
    UserManagerAddButton.SetParentComponent(TMSFNCPanel20);
    UserManagerAddButton.Name := 'UserManagerAddButton';
    UserManagerAddButton.AlignWithMargins := True;
    UserManagerAddButton.Left := 20;
    UserManagerAddButton.Top := 8;
    UserManagerAddButton.Width := 100;
    UserManagerAddButton.Height := 31;
    UserManagerAddButton.Margins.Left := 0;
    UserManagerAddButton.Margins.Top := 8;
    UserManagerAddButton.Margins.Right := 8;
    UserManagerAddButton.Margins.Bottom := 10;
    UserManagerAddButton.Align := alLeft;
    UserManagerAddButton.Caption := 'Add';
    UserManagerAddButton.Color := clNone;
    UserManagerAddButton.ElementClassName := 'MyWebButton';
    UserManagerAddButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel20.UserManagerAddButton.Glyph.png');
    UserManagerAddButton.HeightPercent := 100.000000000000000000;
    UserManagerAddButton.TabOrder := 0;
    UserManagerAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserManagerAddButton, Self, 'OnClick', 'UserManagerAddButtonClick');
    UserManagerDeleteButton.SetParentComponent(TMSFNCPanel20);
    UserManagerDeleteButton.Name := 'UserManagerDeleteButton';
    UserManagerDeleteButton.AlignWithMargins := True;
    UserManagerDeleteButton.Left := 128;
    UserManagerDeleteButton.Top := 8;
    UserManagerDeleteButton.Width := 100;
    UserManagerDeleteButton.Height := 31;
    UserManagerDeleteButton.Margins.Left := 0;
    UserManagerDeleteButton.Margins.Top := 8;
    UserManagerDeleteButton.Margins.Right := 8;
    UserManagerDeleteButton.Margins.Bottom := 10;
    UserManagerDeleteButton.Align := alLeft;
    UserManagerDeleteButton.Caption := 'Delete';
    UserManagerDeleteButton.Color := clNone;
    UserManagerDeleteButton.ElementClassName := 'MyWebButton';
    UserManagerDeleteButton.Glyph.LoadFromFile('uGeneralSettings.TMSFNCPanel20.UserManagerDeleteButton.Glyph.png');
    UserManagerDeleteButton.HeightPercent := 100.000000000000000000;
    UserManagerDeleteButton.TabOrder := 1;
    UserManagerDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserManagerDeleteButton, Self, 'OnClick', 'UserManagerDeleteButtonClick');
    TMSFNCPanel21.SetParentComponent(TMSFNCPanel20);
    TMSFNCPanel21.Name := 'TMSFNCPanel21';
    TMSFNCPanel21.Tag := 2;
    TMSFNCPanel21.AlignWithMargins := True;
    TMSFNCPanel21.Left := 3;
    TMSFNCPanel21.Top := 3;
    TMSFNCPanel21.Width := 14;
    TMSFNCPanel21.Height := 41;
    TMSFNCPanel21.Margins.Bottom := 5;
    TMSFNCPanel21.Align := alLeft;
    TMSFNCPanel21.BevelInner := bvNone;
    TMSFNCPanel21.BevelOuter := bvNone;
    TMSFNCPanel21.Ctl3D := True;
    TMSFNCPanel21.ParentCtl3D := False;
    TMSFNCPanel21.ParentDoubleBuffered := False;
    TMSFNCPanel21.DoubleBuffered := True;
    TMSFNCPanel21.TabOrder := 2;
    TMSFNCPanel21.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel21.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel21.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel21.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel21.SectionsAppearance.Font.Style := [];
    TMSFNCPanel21.Stroke.Kind := gskNone;
    TMSFNCPanel21.Header.Text := 'Header';
    TMSFNCPanel21.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel21.Header.Font.Color := clWindowText;
    TMSFNCPanel21.Header.Font.Height := -11;
    TMSFNCPanel21.Header.Font.Name := 'Tahoma';
    TMSFNCPanel21.Header.Font.Style := [];
    TMSFNCPanel21.Header.Visible := False;
    TMSFNCPanel21.Header.Fill.Color := 16380654;
    TMSFNCPanel21.Header.Stroke.Kind := gskNone;
    TMSFNCPanel21.Footer.Text := 'Footer';
    TMSFNCPanel21.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel21.Footer.Font.Color := clWindowText;
    TMSFNCPanel21.Footer.Font.Height := -11;
    TMSFNCPanel21.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel21.Footer.Font.Style := [];
    TMSFNCPanel21.Footer.Fill.Color := 16380654;
    TMSFNCPanel21.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel21.GlobalFont.Style := [];
    UserManagerGrid.SetParentComponent(TMSFNCPageControlPage5);
    UserManagerGrid.Name := 'UserManagerGrid';
    UserManagerGrid.AlignWithMargins := True;
    UserManagerGrid.Left := 20;
    UserManagerGrid.Top := 63;
    UserManagerGrid.Width := 1033;
    UserManagerGrid.Height := 444;
    UserManagerGrid.Margins.Left := 20;
    UserManagerGrid.Margins.Top := 0;
    UserManagerGrid.Margins.Right := 20;
    UserManagerGrid.Margins.Bottom := 10;
    UserManagerGrid.AdaptToStyle := True;
    UserManagerGrid.Align := alClient;
    UserManagerGrid.BevelInner := bvNone;
    UserManagerGrid.BevelOuter := bvNone;
    UserManagerGrid.BevelWidth := 2;
    UserManagerGrid.Ctl3D := False;
    UserManagerGrid.ParentCtl3D := False;
    UserManagerGrid.ParentDoubleBuffered := False;
    UserManagerGrid.DoubleBuffered := True;
    UserManagerGrid.Font.Charset := DEFAULT_CHARSET;
    UserManagerGrid.Font.Color := clWindowText;
    UserManagerGrid.Font.Height := -11;
    UserManagerGrid.Font.Name := 'Tahoma';
    UserManagerGrid.Font.Style := [];
    UserManagerGrid.ParentFont := False;
    UserManagerGrid.TabOrder := 2;
    SetEvent(UserManagerGrid, Self, 'OnDblClick', 'DeviceModelGridDblClick');
    UserManagerGrid.DefaultRowHeight := 34.000000000000000000;
    UserManagerGrid.FixedColumns := 0;
    UserManagerGrid.ColumnCount := 0;
    UserManagerGrid.RowCount := 1;
    UserManagerGrid.Options.Bands.Enabled := True;
    UserManagerGrid.Options.Borders.FixedCellBorders := bNone;
    UserManagerGrid.Options.ColumnSize.Stretch := True;
    UserManagerGrid.Options.ColumnSize.StretchColumn := 1;
    UserManagerGrid.Options.Editing.CalcFormat := '%g';
    UserManagerGrid.Options.Editing.Enabled := False;
    UserManagerGrid.Options.Grouping.CalcFormat := '%g';
    UserManagerGrid.Options.Grouping.GroupCountFormat := '(%d)';
    UserManagerGrid.Options.HTMLExport.CellPadding := 10;
    UserManagerGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    UserManagerGrid.Options.Mouse.WheelScrollKeepSelection := True;
    UserManagerGrid.Options.Mouse.ClickMargin := 0;
    UserManagerGrid.Options.Mouse.ColumnSizeMargin := 6;
    UserManagerGrid.Options.Mouse.RowSizeMargin := 6;
    UserManagerGrid.Options.URL.Color := clSkyBlue;
    UserManagerGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    UserManagerGrid.Options.Selection.Mode := smSingleRow;
    UserManagerGrid.Adapter := UserManagerGridAdapter;
    UserManagerGrid.HorizontalScrollBarVisible := False;
    UserManagerGrid.Columns.Clear;
    with UserManagerGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clBlack;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 1.000000000000000000;
    end;
    with UserManagerGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 1031.000000000000000000;
    end;
    UserManagerGrid.Fill.ColorTo := clSilver;
    UserManagerGrid.DefaultFont.Charset := ANSI_CHARSET;
    UserManagerGrid.DefaultFont.Color := clWindowText;
    UserManagerGrid.DefaultFont.Height := -16;
    UserManagerGrid.DefaultFont.Name := 'Segoe UI';
    UserManagerGrid.DefaultFont.Style := [];
    UserManagerGrid.Stroke.Color := 15987699;
    UserManagerGrid.Stroke.Width := 2.000000000000000000;
    UserManagerGrid.TopRow := 1;
    UserManagerGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    UserManagerGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    UserManagerGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    UserManagerGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    UserManagerGrid.Appearance.FixedLayout.Font.Color := clWhite;
    UserManagerGrid.Appearance.FixedLayout.Font.Height := -16;
    UserManagerGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    UserManagerGrid.Appearance.FixedLayout.Font.Style := [];
    UserManagerGrid.Appearance.NormalLayout.Fill.Color := -1;
    UserManagerGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    UserManagerGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    UserManagerGrid.Appearance.NormalLayout.Font.Color := clGray;
    UserManagerGrid.Appearance.NormalLayout.Font.Height := -11;
    UserManagerGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    UserManagerGrid.Appearance.NormalLayout.Font.Style := [];
    UserManagerGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    UserManagerGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    UserManagerGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    UserManagerGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    UserManagerGrid.Appearance.GroupLayout.Font.Color := clBlack;
    UserManagerGrid.Appearance.GroupLayout.Font.Height := -11;
    UserManagerGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    UserManagerGrid.Appearance.GroupLayout.Font.Style := [];
    UserManagerGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    UserManagerGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    UserManagerGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    UserManagerGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    UserManagerGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    UserManagerGrid.Appearance.SummaryLayout.Font.Height := -11;
    UserManagerGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    UserManagerGrid.Appearance.SummaryLayout.Font.Style := [];
    UserManagerGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    UserManagerGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    UserManagerGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    UserManagerGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    UserManagerGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    UserManagerGrid.Appearance.SelectedLayout.Font.Height := -16;
    UserManagerGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    UserManagerGrid.Appearance.SelectedLayout.Font.Style := [];
    UserManagerGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    UserManagerGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    UserManagerGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    UserManagerGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    UserManagerGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    UserManagerGrid.Appearance.FocusedLayout.Font.Height := -16;
    UserManagerGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    UserManagerGrid.Appearance.FocusedLayout.Font.Style := [];
    UserManagerGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    UserManagerGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    UserManagerGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    UserManagerGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    UserManagerGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    UserManagerGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    UserManagerGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    UserManagerGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    UserManagerGrid.Appearance.BandLayout.Fill.Color := 14062646;
    UserManagerGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    UserManagerGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    UserManagerGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    UserManagerGrid.Appearance.BandLayout.Font.Color := clBlack;
    UserManagerGrid.Appearance.BandLayout.Font.Height := -16;
    UserManagerGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    UserManagerGrid.Appearance.BandLayout.Font.Style := [];
    UserManagerGrid.Appearance.ProgressLayout.Color := 15385233;
    UserManagerGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    UserManagerGrid.LeftCol := 0;
    UserManagerGrid.ScrollMode := scmItemScrolling;
    UserManagerGrid.GlobalFont.Scale := 1.000000000000000000;
    UserManagerGrid.GlobalFont.Style := [];
    UserManagerGrid.DesignTimeSampleData := True;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1099;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 115;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'General Settings';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1070;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uGeneralSettings.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 432;
    WebWaitMessage.Top := 102;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uGeneralSettings.WebWaitMessage.Picture.gif');
    CompanyGridAdapter.SetParentComponent(Self);
    CompanyGridAdapter.Name := 'CompanyGridAdapter';
    CompanyGridAdapter.Left := 119;
    CompanyGridAdapter.Top := 243;
    CompanyGridAdapter.Width := 26;
    CompanyGridAdapter.Height := 26;
    CompanyGridAdapter.Margins.Left := 12;
    CompanyGridAdapter.Visible := True;
    CompanyGridAdapter.Active := True;
    CompanyGridAdapter.Grid := CompanyGrid;
    CompanyGridAdapter.DataSource := CompanySource;
    CompanyGridAdapter.Columns.Clear;
    with CompanyGridAdapter.Columns.Add do
    begin
      FieldName := 'of_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    CompanyGridAdapter.AutoCreateColumns := False;
    CompanyGridAdapter.AutoRemoveColumns := False;
    DepartmentGridAdapter.SetParentComponent(Self);
    DepartmentGridAdapter.Name := 'DepartmentGridAdapter';
    DepartmentGridAdapter.Left := 222;
    DepartmentGridAdapter.Top := 243;
    DepartmentGridAdapter.Width := 26;
    DepartmentGridAdapter.Height := 26;
    DepartmentGridAdapter.Margins.Left := 12;
    DepartmentGridAdapter.Visible := True;
    DepartmentGridAdapter.Active := True;
    DepartmentGridAdapter.Grid := DepartmentGrid;
    DepartmentGridAdapter.DataSource := DepartmentSource;
    DepartmentGridAdapter.Columns.Clear;
    with DepartmentGridAdapter.Columns.Add do
    begin
      FieldName := 'da_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DepartmentGridAdapter.AutoCreateColumns := False;
    DepartmentGridAdapter.AutoRemoveColumns := False;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 920;
    MyMessageDlg.Top := 105;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    AssetStatusGridAdapter.SetParentComponent(Self);
    AssetStatusGridAdapter.Name := 'AssetStatusGridAdapter';
    AssetStatusGridAdapter.Left := 326;
    AssetStatusGridAdapter.Top := 243;
    AssetStatusGridAdapter.Width := 26;
    AssetStatusGridAdapter.Height := 26;
    AssetStatusGridAdapter.Margins.Left := 12;
    AssetStatusGridAdapter.Visible := True;
    AssetStatusGridAdapter.Active := True;
    AssetStatusGridAdapter.Grid := AssetStatusGrid;
    AssetStatusGridAdapter.DataSource := AssetStatusSource;
    AssetStatusGridAdapter.Columns.Clear;
    with AssetStatusGridAdapter.Columns.Add do
    begin
      FieldName := 'obs_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AssetStatusGridAdapter.AutoCreateColumns := False;
    AssetStatusGridAdapter.AutoRemoveColumns := False;
    DeviceReasonDeleteGridAdapter.SetParentComponent(Self);
    DeviceReasonDeleteGridAdapter.Name := 'DeviceReasonDeleteGridAdapter';
    DeviceReasonDeleteGridAdapter.Left := 279;
    DeviceReasonDeleteGridAdapter.Top := 427;
    DeviceReasonDeleteGridAdapter.Width := 26;
    DeviceReasonDeleteGridAdapter.Height := 26;
    DeviceReasonDeleteGridAdapter.Margins.Left := 12;
    DeviceReasonDeleteGridAdapter.Visible := True;
    DeviceReasonDeleteGridAdapter.Active := True;
    DeviceReasonDeleteGridAdapter.Grid := DeviceReasonDeleteGrid;
    DeviceReasonDeleteGridAdapter.DataSource := DeviceReasonDeleteSource;
    DeviceReasonDeleteGridAdapter.Columns.Clear;
    with DeviceReasonDeleteGridAdapter.Columns.Add do
    begin
      FieldName := 'trv_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DeviceReasonDeleteGridAdapter.AutoCreateColumns := False;
    DeviceReasonDeleteGridAdapter.AutoRemoveColumns := False;
    AssetGroupGridAdapter.SetParentComponent(Self);
    AssetGroupGridAdapter.Name := 'AssetGroupGridAdapter';
    AssetGroupGridAdapter.Left := 434;
    AssetGroupGridAdapter.Top := 243;
    AssetGroupGridAdapter.Width := 26;
    AssetGroupGridAdapter.Height := 26;
    AssetGroupGridAdapter.Margins.Left := 12;
    AssetGroupGridAdapter.Visible := True;
    AssetGroupGridAdapter.Active := True;
    AssetGroupGridAdapter.Grid := AssetGroupGrid;
    AssetGroupGridAdapter.DataSource := AssetGroupSource;
    AssetGroupGridAdapter.Columns.Clear;
    with AssetGroupGridAdapter.Columns.Add do
    begin
      FieldName := 'og_pk';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetGroupGridAdapter.Columns.Add do
    begin
      FieldName := 'og_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AssetGroupGridAdapter.AutoCreateColumns := False;
    AssetGroupGridAdapter.AutoRemoveColumns := False;
    AssetTypeGridAdapter.SetParentComponent(Self);
    AssetTypeGridAdapter.Name := 'AssetTypeGridAdapter';
    AssetTypeGridAdapter.Left := 542;
    AssetTypeGridAdapter.Top := 243;
    AssetTypeGridAdapter.Width := 26;
    AssetTypeGridAdapter.Height := 26;
    AssetTypeGridAdapter.Margins.Left := 12;
    AssetTypeGridAdapter.Visible := True;
    AssetTypeGridAdapter.Active := True;
    AssetTypeGridAdapter.Grid := AssetTypeGrid;
    AssetTypeGridAdapter.DataSource := AssetTypeSource;
    AssetTypeGridAdapter.Columns.Clear;
    with AssetTypeGridAdapter.Columns.Add do
    begin
      FieldName := 'ot_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AssetTypeGridAdapter.AutoCreateColumns := False;
    AssetTypeGridAdapter.AutoRemoveColumns := False;
    DeviceModelGridAdapter.SetParentComponent(Self);
    DeviceModelGridAdapter.Name := 'DeviceModelGridAdapter';
    DeviceModelGridAdapter.Left := 119;
    DeviceModelGridAdapter.Top := 427;
    DeviceModelGridAdapter.Width := 26;
    DeviceModelGridAdapter.Height := 26;
    DeviceModelGridAdapter.Margins.Left := 12;
    DeviceModelGridAdapter.Visible := True;
    DeviceModelGridAdapter.Active := True;
    DeviceModelGridAdapter.Grid := DeviceModelGrid;
    DeviceModelGridAdapter.DataSource := DeviceModelSource;
    DeviceModelGridAdapter.Columns.Clear;
    with DeviceModelGridAdapter.Columns.Add do
    begin
      FieldName := 'tm_pk';
      Header := '  PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DeviceModelGridAdapter.Columns.Add do
    begin
      FieldName := 'tm_id';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DeviceModelGridAdapter.AutoCreateColumns := False;
    DeviceModelGridAdapter.AutoRemoveColumns := False;
    AssetBrandGridAdapter.SetParentComponent(Self);
    AssetBrandGridAdapter.Name := 'AssetBrandGridAdapter';
    AssetBrandGridAdapter.Left := 656;
    AssetBrandGridAdapter.Top := 243;
    AssetBrandGridAdapter.Width := 26;
    AssetBrandGridAdapter.Height := 26;
    AssetBrandGridAdapter.Visible := True;
    AssetBrandGridAdapter.Active := True;
    AssetBrandGridAdapter.Grid := AssetBrandGrid;
    AssetBrandGridAdapter.DataSource := AssetBrandSource;
    AssetBrandGridAdapter.Columns.Clear;
    with AssetBrandGridAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetBrandGridAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  BRAND';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AssetModelGridAdapter.SetParentComponent(Self);
    AssetModelGridAdapter.Name := 'AssetModelGridAdapter';
    AssetModelGridAdapter.Left := 768;
    AssetModelGridAdapter.Top := 243;
    AssetModelGridAdapter.Width := 26;
    AssetModelGridAdapter.Height := 26;
    AssetModelGridAdapter.Visible := True;
    AssetModelGridAdapter.Grid := AssetModelGrid;
    AssetModelGridAdapter.DataSource := AssetModelSource;
    AssetModelGridAdapter.Columns.Clear;
    with AssetModelGridAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetModelGridAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  MODEL';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetModelGridAdapter.Columns.Add do
    begin
      FieldName := 'BrandPKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetModelGridAdapter.Columns.Add do
    begin
      FieldName := 'BrandID';
      Header := '  BRAND';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AssetRepairGridAdapter.SetParentComponent(Self);
    AssetRepairGridAdapter.Name := 'AssetRepairGridAdapter';
    AssetRepairGridAdapter.Left := 872;
    AssetRepairGridAdapter.Top := 243;
    AssetRepairGridAdapter.Width := 26;
    AssetRepairGridAdapter.Height := 26;
    AssetRepairGridAdapter.Visible := True;
    AssetRepairGridAdapter.Grid := AssetRepairGrid;
    AssetRepairGridAdapter.DataSource := AssetRepairSource;
    AssetRepairGridAdapter.Columns.Clear;
    with AssetRepairGridAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetRepairGridAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetRepairGridAdapter.Columns.Add do
    begin
      FieldName := 'Type';
      Header := '  TYPE';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetRepairGridAdapter.Columns.Add do
    begin
      FieldName := 'Brand';
      Header := '  BRAND';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AssetRepairGridAdapter.Columns.Add do
    begin
      FieldName := 'Model';
      Header := '  MODEL';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    UserManagerGridAdapter.SetParentComponent(Self);
    UserManagerGridAdapter.Name := 'UserManagerGridAdapter';
    UserManagerGridAdapter.Left := 439;
    UserManagerGridAdapter.Top := 427;
    UserManagerGridAdapter.Width := 26;
    UserManagerGridAdapter.Height := 26;
    UserManagerGridAdapter.Margins.Left := 12;
    UserManagerGridAdapter.Visible := True;
    UserManagerGridAdapter.Active := True;
    UserManagerGridAdapter.Grid := UserManagerGrid;
    UserManagerGridAdapter.DataSource := UserManagerDataSource;
    UserManagerGridAdapter.Columns.Clear;
    with UserManagerGridAdapter.Columns.Add do
    begin
      FieldName := 'ga_pk';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with UserManagerGridAdapter.Columns.Add do
    begin
      FieldName := 'ga_name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    UserManagerGridAdapter.AutoCreateColumns := False;
    UserManagerGridAdapter.AutoRemoveColumns := False;
    SearchEditTimer.SetParentComponent(Self);
    SearchEditTimer.Name := 'SearchEditTimer';
    SearchEditTimer.Enabled := False;
    SearchEditTimer.Interval := 300;
    SetEvent(SearchEditTimer, Self, 'OnTimer', 'SearchEditTimerTimer');
    SearchEditTimer.Left := 515;
    SearchEditTimer.Top := 100;
    FocusRecordTimer.SetParentComponent(Self);
    FocusRecordTimer.Name := 'FocusRecordTimer';
    FocusRecordTimer.Enabled := False;
    FocusRecordTimer.Interval := 100;
    SetEvent(FocusRecordTimer, Self, 'OnTimer', 'FocusRecordTimerTimer');
    FocusRecordTimer.Left := 723;
    FocusRecordTimer.Top := 100;
    CompanyDataset.SetParentComponent(Self);
    CompanyDataset.Name := 'CompanyDataset';
    CompanyDataset.Tag := -1;
    CompanyDataset.AfterOpen := DatasetAfterOpen;
    CompanyDataset.Left := 116;
    CompanyDataset.Top := 296;
    CompanyDatasetof_pk.SetParentComponent(CompanyDataset);
    CompanyDatasetof_pk.Name := 'CompanyDatasetof_pk';
    CompanyDatasetof_pk.FieldName := 'of_pk';
    CompanyDatasetof_naam.SetParentComponent(CompanyDataset);
    CompanyDatasetof_naam.Name := 'CompanyDatasetof_naam';
    CompanyDatasetof_naam.FieldName := 'of_naam';
    CompanyDatasetof_naam.Size := 40;
    CompanySource.SetParentComponent(Self);
    CompanySource.Name := 'CompanySource';
    CompanySource.DataSet := CompanyDataset;
    CompanySource.Left := 116;
    CompanySource.Top := 360;
    TabChangeTimer.SetParentComponent(Self);
    TabChangeTimer.Name := 'TabChangeTimer';
    TabChangeTimer.Enabled := False;
    TabChangeTimer.Interval := 50;
    SetEvent(TabChangeTimer, Self, 'OnTimer', 'TabChangeTimerTimer');
    TabChangeTimer.Left := 619;
    TabChangeTimer.Top := 100;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.URI := 'https://beta.instatrack.eu/php/default_user_manager_list.php?sessionid=bbb4b2ba0e2cc152e715e686d9eeccb6';
    WebConnection.Left := 836;
    WebConnection.Top := 101;
    DepartmentDataSet.SetParentComponent(Self);
    DepartmentDataSet.Name := 'DepartmentDataSet';
    DepartmentDataSet.Tag := -1;
    DepartmentDataSet.AfterOpen := DatasetAfterOpen;
    DepartmentDataSet.Left := 220;
    DepartmentDataSet.Top := 296;
    DepartmentDataSetda_pk.SetParentComponent(DepartmentDataSet);
    DepartmentDataSetda_pk.Name := 'DepartmentDataSetda_pk';
    DepartmentDataSetda_pk.FieldName := 'da_pk';
    DepartmentDataSetda_pk.Size := 256;
    DepartmentDataSetda_naam.SetParentComponent(DepartmentDataSet);
    DepartmentDataSetda_naam.Name := 'DepartmentDataSetda_naam';
    DepartmentDataSetda_naam.FieldName := 'da_naam';
    DepartmentDataSetda_naam.Size := 256;
    DepartmentSource.SetParentComponent(Self);
    DepartmentSource.Name := 'DepartmentSource';
    DepartmentSource.DataSet := DepartmentDataSet;
    DepartmentSource.Left := 220;
    DepartmentSource.Top := 360;
    AssetStatusDataSet.SetParentComponent(Self);
    AssetStatusDataSet.Name := 'AssetStatusDataSet';
    AssetStatusDataSet.Tag := -1;
    AssetStatusDataSet.AfterOpen := DatasetAfterOpen;
    AssetStatusDataSet.Left := 324;
    AssetStatusDataSet.Top := 296;
    AssetStatusDataSetobs_pk.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_pk.Name := 'AssetStatusDataSetobs_pk';
    AssetStatusDataSetobs_pk.FieldName := 'obs_pk';
    AssetStatusDataSetobs_pk.Visible := False;
    AssetStatusDataSetobs_pk.Size := 256;
    AssetStatusDataSetobs_naam.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_naam.Name := 'AssetStatusDataSetobs_naam';
    AssetStatusDataSetobs_naam.FieldName := 'obs_naam';
    AssetStatusDataSetobs_naam.Size := 256;
    AssetStatusDataSetobs_mail_checkbox.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_checkbox.Name := 'AssetStatusDataSetobs_mail_checkbox';
    AssetStatusDataSetobs_mail_checkbox.FieldName := 'obs_mail_checkbox';
    AssetStatusDataSetobs_mail_checkbox.Visible := False;
    AssetStatusDataSetobs_mail_checkbox.Size := 256;
    AssetStatusDataSetobs_mail_owner_checkbox.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_owner_checkbox.Name := 'AssetStatusDataSetobs_mail_owner_checkbox';
    AssetStatusDataSetobs_mail_owner_checkbox.FieldName := 'obs_mail_owner_checkbox';
    AssetStatusDataSetobs_mail_owner_checkbox.Visible := False;
    AssetStatusDataSetobs_mail_owner_checkbox.Size := 256;
    AssetStatusDataSetobs_mail_owner_department.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_owner_department.Name := 'AssetStatusDataSetobs_mail_owner_department';
    AssetStatusDataSetobs_mail_owner_department.FieldName := 'obs_mail_owner_department';
    AssetStatusDataSetobs_mail_owner_department.Visible := False;
    AssetStatusDataSetobs_mail_owner_department.Size := 256;
    AssetStatusDataSetobs_mail_owner_department_names.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_owner_department_names.Name := 'AssetStatusDataSetobs_mail_owner_department_names';
    AssetStatusDataSetobs_mail_owner_department_names.FieldName := 'obs_mail_owner_department_names';
    AssetStatusDataSetobs_mail_owner_department_names.Visible := False;
    AssetStatusDataSetobs_mail_owner_department_names.Size := 256;
    AssetStatusDataSetobs_mail_tenant_checkbox.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_tenant_checkbox.Name := 'AssetStatusDataSetobs_mail_tenant_checkbox';
    AssetStatusDataSetobs_mail_tenant_checkbox.FieldName := 'obs_mail_tenant_checkbox';
    AssetStatusDataSetobs_mail_tenant_checkbox.Visible := False;
    AssetStatusDataSetobs_mail_tenant_checkbox.Size := 256;
    AssetStatusDataSetobs_mail_tenant_department.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_tenant_department.Name := 'AssetStatusDataSetobs_mail_tenant_department';
    AssetStatusDataSetobs_mail_tenant_department.FieldName := 'obs_mail_tenant_department';
    AssetStatusDataSetobs_mail_tenant_department.Visible := False;
    AssetStatusDataSetobs_mail_tenant_department.Size := 256;
    AssetStatusDataSetobs_mail_tenant_department_names.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_tenant_department_names.Name := 'AssetStatusDataSetobs_mail_tenant_department_names';
    AssetStatusDataSetobs_mail_tenant_department_names.FieldName := 'obs_mail_tenant_department_names';
    AssetStatusDataSetobs_mail_tenant_department_names.Visible := False;
    AssetStatusDataSetobs_mail_tenant_department_names.Size := 256;
    AssetStatusDataSetobs_mail_teamleader_checkbox.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_teamleader_checkbox.Name := 'AssetStatusDataSetobs_mail_teamleader_checkbox';
    AssetStatusDataSetobs_mail_teamleader_checkbox.FieldName := 'obs_mail_teamleader_checkbox';
    AssetStatusDataSetobs_mail_teamleader_checkbox.Visible := False;
    AssetStatusDataSetobs_mail_teamleader_checkbox.Size := 256;
    AssetStatusDataSetobs_mail_driver_checkbox.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_mail_driver_checkbox.Name := 'AssetStatusDataSetobs_mail_driver_checkbox';
    AssetStatusDataSetobs_mail_driver_checkbox.FieldName := 'obs_mail_driver_checkbox';
    AssetStatusDataSetobs_mail_driver_checkbox.Visible := False;
    AssetStatusDataSetobs_mail_driver_checkbox.Size := 256;
    AssetStatusDataSetobs_text.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_text.Name := 'AssetStatusDataSetobs_text';
    AssetStatusDataSetobs_text.FieldName := 'obs_text';
    AssetStatusDataSetobs_text.Size := 256;
    AssetStatusDataSetobs_hexcolor.SetParentComponent(AssetStatusDataSet);
    AssetStatusDataSetobs_hexcolor.Name := 'AssetStatusDataSetobs_hexcolor';
    AssetStatusDataSetobs_hexcolor.FieldName := 'obs_hexcolor';
    AssetStatusDataSetobs_hexcolor.Size := 256;
    AssetStatusSource.SetParentComponent(Self);
    AssetStatusSource.Name := 'AssetStatusSource';
    AssetStatusSource.DataSet := AssetStatusDataSet;
    AssetStatusSource.Left := 324;
    AssetStatusSource.Top := 360;
    DeviceReasonDeleteDataSet.SetParentComponent(Self);
    DeviceReasonDeleteDataSet.Name := 'DeviceReasonDeleteDataSet';
    DeviceReasonDeleteDataSet.Tag := -1;
    DeviceReasonDeleteDataSet.AfterOpen := DatasetAfterOpen;
    DeviceReasonDeleteDataSet.Left := 278;
    DeviceReasonDeleteDataSet.Top := 480;
    DeviceReasonDeleteDataSettrv_pk.SetParentComponent(DeviceReasonDeleteDataSet);
    DeviceReasonDeleteDataSettrv_pk.Name := 'DeviceReasonDeleteDataSettrv_pk';
    DeviceReasonDeleteDataSettrv_pk.FieldName := 'trv_pk';
    DeviceReasonDeleteDataSettrv_pk.Visible := False;
    DeviceReasonDeleteDataSettrv_pk.Size := 256;
    DeviceReasonDeleteDataSettrv_naam.SetParentComponent(DeviceReasonDeleteDataSet);
    DeviceReasonDeleteDataSettrv_naam.Name := 'DeviceReasonDeleteDataSettrv_naam';
    DeviceReasonDeleteDataSettrv_naam.FieldName := 'trv_naam';
    DeviceReasonDeleteDataSettrv_naam.Size := 256;
    DeviceReasonDeleteSource.SetParentComponent(Self);
    DeviceReasonDeleteSource.Name := 'DeviceReasonDeleteSource';
    DeviceReasonDeleteSource.DataSet := DeviceReasonDeleteDataSet;
    DeviceReasonDeleteSource.Left := 278;
    DeviceReasonDeleteSource.Top := 544;
    AssetGroupDataSet.SetParentComponent(Self);
    AssetGroupDataSet.Name := 'AssetGroupDataSet';
    AssetGroupDataSet.Tag := -1;
    AssetGroupDataSet.AfterOpen := DatasetAfterOpen;
    AssetGroupDataSet.Left := 436;
    AssetGroupDataSet.Top := 296;
    AssetGroupDataSetog_pk.SetParentComponent(AssetGroupDataSet);
    AssetGroupDataSetog_pk.Name := 'AssetGroupDataSetog_pk';
    AssetGroupDataSetog_pk.FieldName := 'og_pk';
    AssetGroupDataSetog_pk.Visible := False;
    AssetGroupDataSetog_pk.Size := 256;
    AssetGroupDataSetog_naam.SetParentComponent(AssetGroupDataSet);
    AssetGroupDataSetog_naam.Name := 'AssetGroupDataSetog_naam';
    AssetGroupDataSetog_naam.FieldName := 'og_naam';
    AssetGroupDataSetog_naam.Size := 256;
    AssetGroupSource.SetParentComponent(Self);
    AssetGroupSource.Name := 'AssetGroupSource';
    AssetGroupSource.DataSet := AssetGroupDataSet;
    AssetGroupSource.Left := 436;
    AssetGroupSource.Top := 360;
    AssetTypeDataSet.SetParentComponent(Self);
    AssetTypeDataSet.Name := 'AssetTypeDataSet';
    AssetTypeDataSet.Tag := -1;
    AssetTypeDataSet.AfterOpen := DatasetAfterOpen;
    AssetTypeDataSet.Left := 544;
    AssetTypeDataSet.Top := 296;
    AssetTypeDataSetot_pk.SetParentComponent(AssetTypeDataSet);
    AssetTypeDataSetot_pk.Name := 'AssetTypeDataSetot_pk';
    AssetTypeDataSetot_pk.FieldName := 'ot_pk';
    AssetTypeDataSetot_pk.Visible := False;
    AssetTypeDataSetot_pk.Size := 256;
    AssetTypeDataSetot_naam.SetParentComponent(AssetTypeDataSet);
    AssetTypeDataSetot_naam.Name := 'AssetTypeDataSetot_naam';
    AssetTypeDataSetot_naam.FieldName := 'ot_naam';
    AssetTypeDataSetot_naam.Size := 256;
    AssetTypeSource.SetParentComponent(Self);
    AssetTypeSource.Name := 'AssetTypeSource';
    AssetTypeSource.DataSet := AssetTypeDataSet;
    AssetTypeSource.Left := 544;
    AssetTypeSource.Top := 360;
    DeviceModelDataSet.SetParentComponent(Self);
    DeviceModelDataSet.Name := 'DeviceModelDataSet';
    DeviceModelDataSet.Tag := -1;
    DeviceModelDataSet.AfterOpen := DatasetAfterOpen;
    DeviceModelDataSet.Left := 118;
    DeviceModelDataSet.Top := 480;
    DeviceModelDataSettm_pk.SetParentComponent(DeviceModelDataSet);
    DeviceModelDataSettm_pk.Name := 'DeviceModelDataSettm_pk';
    DeviceModelDataSettm_pk.FieldName := 'tm_pk';
    DeviceModelDataSettm_pk.Size := 256;
    DeviceModelDataSettm_id.SetParentComponent(DeviceModelDataSet);
    DeviceModelDataSettm_id.Name := 'DeviceModelDataSettm_id';
    DeviceModelDataSettm_id.FieldName := 'tm_id';
    DeviceModelDataSettm_id.Size := 256;
    DeviceModelSource.SetParentComponent(Self);
    DeviceModelSource.Name := 'DeviceModelSource';
    DeviceModelSource.DataSet := DeviceModelDataSet;
    DeviceModelSource.Left := 118;
    DeviceModelSource.Top := 544;
    AssetBrandSource.SetParentComponent(Self);
    AssetBrandSource.Name := 'AssetBrandSource';
    AssetBrandSource.DataSet := AssetBrandDataset;
    AssetBrandSource.Left := 659;
    AssetBrandSource.Top := 359;
    AssetModelSource.SetParentComponent(Self);
    AssetModelSource.Name := 'AssetModelSource';
    AssetModelSource.DataSet := AssetModelDataset;
    AssetModelSource.Left := 763;
    AssetModelSource.Top := 359;
    AssetBrandDataset.SetParentComponent(Self);
    AssetBrandDataset.Name := 'AssetBrandDataset';
    AssetBrandDataset.AfterOpen := DatasetAfterOpen;
    AssetBrandDataset.Left := 652;
    AssetBrandDataset.Top := 296;
    AssetBrandDatasetPKey.SetParentComponent(AssetBrandDataset);
    AssetBrandDatasetPKey.Name := 'AssetBrandDatasetPKey';
    AssetBrandDatasetPKey.FieldName := 'PKey';
    AssetBrandDatasetPKey.Visible := False;
    AssetBrandDatasetPKey.Size := 256;
    AssetBrandDatasetID.SetParentComponent(AssetBrandDataset);
    AssetBrandDatasetID.Name := 'AssetBrandDatasetID';
    AssetBrandDatasetID.FieldName := 'ID';
    AssetBrandDatasetID.Size := 256;
    AssetModelDataset.SetParentComponent(Self);
    AssetModelDataset.Name := 'AssetModelDataset';
    AssetModelDataset.AfterOpen := DatasetAfterOpen;
    AssetModelDataset.Left := 764;
    AssetModelDataset.Top := 296;
    AssetModelDatasetPKey.SetParentComponent(AssetModelDataset);
    AssetModelDatasetPKey.Name := 'AssetModelDatasetPKey';
    AssetModelDatasetPKey.FieldName := 'PKey';
    AssetModelDatasetPKey.Visible := False;
    AssetModelDatasetPKey.Size := 256;
    AssetModelDatasetID.SetParentComponent(AssetModelDataset);
    AssetModelDatasetID.Name := 'AssetModelDatasetID';
    AssetModelDatasetID.FieldName := 'ID';
    AssetModelDatasetID.Size := 256;
    AssetModelDatasetBrandPKey.SetParentComponent(AssetModelDataset);
    AssetModelDatasetBrandPKey.Name := 'AssetModelDatasetBrandPKey';
    AssetModelDatasetBrandPKey.FieldName := 'BrandPKey';
    AssetModelDatasetBrandPKey.Visible := False;
    AssetModelDatasetBrandPKey.Size := 256;
    AssetModelDatasetBrandID.SetParentComponent(AssetModelDataset);
    AssetModelDatasetBrandID.Name := 'AssetModelDatasetBrandID';
    AssetModelDatasetBrandID.FieldName := 'BrandID';
    AssetModelDatasetBrandID.Size := 256;
    AssetRepairDataSet.SetParentComponent(Self);
    AssetRepairDataSet.Name := 'AssetRepairDataSet';
    AssetRepairDataSet.AfterOpen := DatasetAfterOpen;
    AssetRepairDataSet.Left := 868;
    AssetRepairDataSet.Top := 296;
    StringField1.SetParentComponent(AssetRepairDataSet);
    StringField1.Name := 'StringField1';
    StringField1.FieldName := 'PKey';
    StringField1.Visible := False;
    StringField1.Size := 256;
    StringField2.SetParentComponent(AssetRepairDataSet);
    StringField2.Name := 'StringField2';
    StringField2.FieldName := 'ID';
    StringField2.Size := 256;
    AssetRepairDataSetType.SetParentComponent(AssetRepairDataSet);
    AssetRepairDataSetType.Name := 'AssetRepairDataSetType';
    AssetRepairDataSetType.FieldName := 'Type';
    AssetRepairDataSetType.Size := 256;
    AssetRepairDataSetBrand.SetParentComponent(AssetRepairDataSet);
    AssetRepairDataSetBrand.Name := 'AssetRepairDataSetBrand';
    AssetRepairDataSetBrand.FieldName := 'Brand';
    AssetRepairDataSetBrand.Size := 256;
    AssetRepairDataSetModel.SetParentComponent(AssetRepairDataSet);
    AssetRepairDataSetModel.Name := 'AssetRepairDataSetModel';
    AssetRepairDataSetModel.FieldName := 'Model';
    AssetRepairDataSetModel.Size := 256;
    AssetRepairSource.SetParentComponent(Self);
    AssetRepairSource.Name := 'AssetRepairSource';
    AssetRepairSource.DataSet := AssetRepairDataSet;
    AssetRepairSource.Left := 867;
    AssetRepairSource.Top := 359;
    UserManagerDataset.SetParentComponent(Self);
    UserManagerDataset.Name := 'UserManagerDataset';
    UserManagerDataset.AfterOpen := DatasetAfterOpen;
    UserManagerDataset.Left := 436;
    UserManagerDataset.Top := 482;
    UserManagerDatasetga_pk.SetParentComponent(UserManagerDataset);
    UserManagerDatasetga_pk.Name := 'UserManagerDatasetga_pk';
    UserManagerDatasetga_pk.FieldName := 'ga_pk';
    UserManagerDatasetga_pk.Size := 256;
    UserManagerDatasetga_name.SetParentComponent(UserManagerDataset);
    UserManagerDatasetga_name.Name := 'UserManagerDatasetga_name';
    UserManagerDatasetga_name.FieldName := 'ga_name';
    UserManagerDatasetga_name.Size := 256;
    UserManagerDataSource.SetParentComponent(Self);
    UserManagerDataSource.Name := 'UserManagerDataSource';
    UserManagerDataSource.DataSet := UserManagerDataset;
    UserManagerDataSource.Left := 439;
    UserManagerDataSource.Top := 546;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    TMSFNCPageControl.AfterLoadDFMValues;
    TMSFNCPageControlPage1.AfterLoadDFMValues;
    DepartmentFilterPanel.AfterLoadDFMValues;
    DepartmentEdit.AfterLoadDFMValues;
    DepartmentButtonPanel.AfterLoadDFMValues;
    DepartmentAddButton.AfterLoadDFMValues;
    DepartmentEditButton.AfterLoadDFMValues;
    DepartmentDeleteButton.AfterLoadDFMValues;
    DepartmentCloseButton.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    DepartmentViewButton.AfterLoadDFMValues;
    DepartmentGrid.AfterLoadDFMValues;
    TMSFNCPageControlPage0.AfterLoadDFMValues;
    CompanyFilterPanel.AfterLoadDFMValues;
    CompanyEdit.AfterLoadDFMValues;
    CompanyButtonPanel.AfterLoadDFMValues;
    CompanyAddButton.AfterLoadDFMValues;
    CompanyEditButton.AfterLoadDFMValues;
    CompanyDeleteButton.AfterLoadDFMValues;
    CompanyCloseButton.AfterLoadDFMValues;
    CompanyViewButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    CompanyGrid.AfterLoadDFMValues;
    TMSFNCPageControlPage4.AfterLoadDFMValues;
    TMSFNCPageControlDevice.AfterLoadDFMValues;
    TMSFNCPageControlContainer1.AfterLoadDFMValues;
    TMSFNCPageControlContainer2.AfterLoadDFMValues;
    TMSFNCPanel4.AfterLoadDFMValues;
    DeviceReasonDeleteEdit.AfterLoadDFMValues;
    DeviceReasonDeleteGrid.AfterLoadDFMValues;
    TMSFNCPanel5.AfterLoadDFMValues;
    DeviceReasonDeleteAddButton.AfterLoadDFMValues;
    DeviceReasonDeleteEditButton.AfterLoadDFMValues;
    DeviceReasonDeleteDeleteButton.AfterLoadDFMValues;
    WebSpeedButton4.AfterLoadDFMValues;
    TMSFNCPanel6.AfterLoadDFMValues;
    DeviceReasonDeleteViewButton.AfterLoadDFMValues;
    TMSFNCPageControlContainer3.AfterLoadDFMValues;
    TMSFNCPanel7.AfterLoadDFMValues;
    DeviceModelEdit.AfterLoadDFMValues;
    TMSFNCPanel8.AfterLoadDFMValues;
    DeviceModelEditButton.AfterLoadDFMValues;
    WebSpeedButton6.AfterLoadDFMValues;
    TMSFNCPanel10.AfterLoadDFMValues;
    DeviceModelViewButton.AfterLoadDFMValues;
    DeviceModelGrid.AfterLoadDFMValues;
    TMSFNCPageControlPage2.AfterLoadDFMValues;
    TMSFNCPageControlAsset.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    AssetTypeFilterPanel.AfterLoadDFMValues;
    AssetTypeEdit.AfterLoadDFMValues;
    AssetTypeButtonPanel.AfterLoadDFMValues;
    AssetTypeAddButton.AfterLoadDFMValues;
    AssetTypeEditButton.AfterLoadDFMValues;
    AssetTypeDeleteButton.AfterLoadDFMValues;
    WebSpeedButton10.AfterLoadDFMValues;
    TMSFNCPanel12.AfterLoadDFMValues;
    AssetTypeViewButton.AfterLoadDFMValues;
    AssetTypeGrid.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    AssetGroupFilterPanel.AfterLoadDFMValues;
    AssetGroupEdit.AfterLoadDFMValues;
    AssetGroupButtonPanel.AfterLoadDFMValues;
    AssetGroupAddButton.AfterLoadDFMValues;
    AssetGroupEditButton.AfterLoadDFMValues;
    AssetGroupDeleteButton.AfterLoadDFMValues;
    WebSpeedButton5.AfterLoadDFMValues;
    TMSFNCPanel9.AfterLoadDFMValues;
    AssetGroupViewButton.AfterLoadDFMValues;
    AssetGroupGrid.AfterLoadDFMValues;
    TMSFNCPageControlAssetPage3.AfterLoadDFMValues;
    AssetBrandFilterPanel.AfterLoadDFMValues;
    AssetBrandEdit.AfterLoadDFMValues;
    AssetBrandGrid.AfterLoadDFMValues;
    TMSFNCPanel11.AfterLoadDFMValues;
    AssetBrandAddButton.AfterLoadDFMValues;
    AssetBrandEditButton.AfterLoadDFMValues;
    AssetBrandDeleteButton.AfterLoadDFMValues;
    WebSpeedButton7.AfterLoadDFMValues;
    TMSFNCPanel13.AfterLoadDFMValues;
    AssetBrandViewButton.AfterLoadDFMValues;
    TMSFNCPageControlAssetPage4.AfterLoadDFMValues;
    AssetModelFilterPanel.AfterLoadDFMValues;
    AssetModelEdit.AfterLoadDFMValues;
    AssetModelGrid.AfterLoadDFMValues;
    TMSFNCPanel14.AfterLoadDFMValues;
    AssetModelAddButton.AfterLoadDFMValues;
    AssetModelEditButton.AfterLoadDFMValues;
    AssetModelDeleteButton.AfterLoadDFMValues;
    WebSpeedButton13.AfterLoadDFMValues;
    TMSFNCPanel15.AfterLoadDFMValues;
    AssetModelViewButton.AfterLoadDFMValues;
    TMSFNCPageControlAssetPage5.AfterLoadDFMValues;
    TMSFNCPanel16.AfterLoadDFMValues;
    AssetRepairEdit.AfterLoadDFMValues;
    AssetRepairGrid.AfterLoadDFMValues;
    TMSFNCPanel17.AfterLoadDFMValues;
    AssetRepairAddButton.AfterLoadDFMValues;
    AssetRepairEditButton.AfterLoadDFMValues;
    AssetRepairDeleteButton.AfterLoadDFMValues;
    WebSpeedButton8.AfterLoadDFMValues;
    TMSFNCPanel18.AfterLoadDFMValues;
    AssetRepairViewButton.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    AssetStatusFilterPanel.AfterLoadDFMValues;
    AssetStatusEdit.AfterLoadDFMValues;
    AssetStatusButtonPanel.AfterLoadDFMValues;
    AssetStatusAddButton.AfterLoadDFMValues;
    AssetStatusEditButton.AfterLoadDFMValues;
    AssetStatusDeleteButton.AfterLoadDFMValues;
    AssetStatusCloseButton.AfterLoadDFMValues;
    TMSFNCPanel3.AfterLoadDFMValues;
    AssetStatusViewButton.AfterLoadDFMValues;
    AssetStatusGrid.AfterLoadDFMValues;
    TMSFNCPageControlPage3.AfterLoadDFMValues;
    RealTimeMapMaximumDistanceLabel.AfterLoadDFMValues;
    ReportServerURLLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    RealTimeMapMaximumDistanceEdit.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    SettingsGeneralCloseButton.AfterLoadDFMValues;
    ReportServerURLEdit.AfterLoadDFMValues;
    AssetIDIsReadOnlyEdit.AfterLoadDFMValues;
    MachineMaxEnabledEdit.AfterLoadDFMValues;
    MachineMaxIntervalEdit.AfterLoadDFMValues;
    MachineMaxFillColorEdit.AfterLoadDFMValues;
    RealtimeMapRefreshAssetCountMax.AfterLoadDFMValues;
    TMSFNCPageControlPage5.AfterLoadDFMValues;
    TMSFNCPanel19.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    UserManagerYesEdit.AfterLoadDFMValues;
    UserManagerNoEdit.AfterLoadDFMValues;
    TMSFNCPanel20.AfterLoadDFMValues;
    UserManagerAddButton.AfterLoadDFMValues;
    UserManagerDeleteButton.AfterLoadDFMValues;
    TMSFNCPanel21.AfterLoadDFMValues;
    UserManagerGrid.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    CompanyGridAdapter.AfterLoadDFMValues;
    DepartmentGridAdapter.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    AssetStatusGridAdapter.AfterLoadDFMValues;
    DeviceReasonDeleteGridAdapter.AfterLoadDFMValues;
    AssetGroupGridAdapter.AfterLoadDFMValues;
    AssetTypeGridAdapter.AfterLoadDFMValues;
    DeviceModelGridAdapter.AfterLoadDFMValues;
    AssetBrandGridAdapter.AfterLoadDFMValues;
    AssetModelGridAdapter.AfterLoadDFMValues;
    AssetRepairGridAdapter.AfterLoadDFMValues;
    UserManagerGridAdapter.AfterLoadDFMValues;
    SearchEditTimer.AfterLoadDFMValues;
    FocusRecordTimer.AfterLoadDFMValues;
    CompanyDataset.AfterLoadDFMValues;
    CompanyDatasetof_pk.AfterLoadDFMValues;
    CompanyDatasetof_naam.AfterLoadDFMValues;
    CompanySource.AfterLoadDFMValues;
    TabChangeTimer.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
    DepartmentDataSet.AfterLoadDFMValues;
    DepartmentDataSetda_pk.AfterLoadDFMValues;
    DepartmentDataSetda_naam.AfterLoadDFMValues;
    DepartmentSource.AfterLoadDFMValues;
    AssetStatusDataSet.AfterLoadDFMValues;
    AssetStatusDataSetobs_pk.AfterLoadDFMValues;
    AssetStatusDataSetobs_naam.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_checkbox.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_owner_checkbox.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_owner_department.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_owner_department_names.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_tenant_checkbox.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_tenant_department.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_tenant_department_names.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_teamleader_checkbox.AfterLoadDFMValues;
    AssetStatusDataSetobs_mail_driver_checkbox.AfterLoadDFMValues;
    AssetStatusDataSetobs_text.AfterLoadDFMValues;
    AssetStatusDataSetobs_hexcolor.AfterLoadDFMValues;
    AssetStatusSource.AfterLoadDFMValues;
    DeviceReasonDeleteDataSet.AfterLoadDFMValues;
    DeviceReasonDeleteDataSettrv_pk.AfterLoadDFMValues;
    DeviceReasonDeleteDataSettrv_naam.AfterLoadDFMValues;
    DeviceReasonDeleteSource.AfterLoadDFMValues;
    AssetGroupDataSet.AfterLoadDFMValues;
    AssetGroupDataSetog_pk.AfterLoadDFMValues;
    AssetGroupDataSetog_naam.AfterLoadDFMValues;
    AssetGroupSource.AfterLoadDFMValues;
    AssetTypeDataSet.AfterLoadDFMValues;
    AssetTypeDataSetot_pk.AfterLoadDFMValues;
    AssetTypeDataSetot_naam.AfterLoadDFMValues;
    AssetTypeSource.AfterLoadDFMValues;
    DeviceModelDataSet.AfterLoadDFMValues;
    DeviceModelDataSettm_pk.AfterLoadDFMValues;
    DeviceModelDataSettm_id.AfterLoadDFMValues;
    DeviceModelSource.AfterLoadDFMValues;
    AssetBrandSource.AfterLoadDFMValues;
    AssetModelSource.AfterLoadDFMValues;
    AssetBrandDataset.AfterLoadDFMValues;
    AssetBrandDatasetPKey.AfterLoadDFMValues;
    AssetBrandDatasetID.AfterLoadDFMValues;
    AssetModelDataset.AfterLoadDFMValues;
    AssetModelDatasetPKey.AfterLoadDFMValues;
    AssetModelDatasetID.AfterLoadDFMValues;
    AssetModelDatasetBrandPKey.AfterLoadDFMValues;
    AssetModelDatasetBrandID.AfterLoadDFMValues;
    AssetRepairDataSet.AfterLoadDFMValues;
    StringField1.AfterLoadDFMValues;
    StringField2.AfterLoadDFMValues;
    AssetRepairDataSetType.AfterLoadDFMValues;
    AssetRepairDataSetBrand.AfterLoadDFMValues;
    AssetRepairDataSetModel.AfterLoadDFMValues;
    AssetRepairSource.AfterLoadDFMValues;
    UserManagerDataset.AfterLoadDFMValues;
    UserManagerDatasetga_pk.AfterLoadDFMValues;
    UserManagerDatasetga_name.AfterLoadDFMValues;
    UserManagerDataSource.AfterLoadDFMValues;
  end;
end;

end.
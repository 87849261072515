unit uUserTeamFilterEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, VCL.TMSFNCCustomControl,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter,
  WEBLib.Buttons, Vcl.Imaging.GIFImg;

type
  TUserTeamFilterEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    StatusBarPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    FilterDataset: TClientDataSet;
    FilterSource: TDataSource;
    TopPanel: TPanel;
    LoadTimer: TTimer;
    UsernameLabel: TLabel;
    FilterNameEdit: TEdit;
    FilterDefaultCheckBox: TCheckBox;
    FilterDatasetobject_groep_pk: TStringField;
    FilterDatasetobject_groep_naam: TStringField;
    FilterDatasetobject_groep_checkbox: TStringField;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    FilterGrid: TTMSFNCGrid;
    ShowTimer: TTimer;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    MyMessageDlg: TMessageDlg;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure LoadTimerTimer(Sender: TObject);
    [async]
    procedure FilterDBGridKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState); async;
    procedure FilterDatasetAfterOpen(DataSet: TDataSet);
    procedure ShowTimerTimer(Sender: TObject);
    [async]
    procedure CloseButtonClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure FilterGridCellClick(Sender: TObject; ACol, ARow: Integer);
    procedure FilterGridKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure RecordChange(Sender: TObject);
    procedure FilterDatasetAfterPost(DataSet: TDataSet);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadFilter(); async;
    [async]
    function SaveFilter(): Boolean; async;
    function ValidInput(): Boolean;
  public
    { Public declarations }
    UserPKey: Integer;
    TeamFilterSet: Boolean;
    TeamFilterInt: Integer;
    TeamFilterName: String;
    TeamFilterPublic: Boolean;
    TeamFilterDefault: Boolean;
  protected procedure LoadDFMValues; override; end;

var
  UserTeamFilterEditForm: TUserTeamFilterEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TUserTeamFilterEditForm.FilterDatasetAfterOpen(DataSet: TDataSet);
begin
  GridDatabaseAdapter.Active := True;
  LoadTimer.Enabled := True;
end;

procedure TUserTeamFilterEditForm.FilterDatasetAfterPost(DataSet: TDataSet);
begin
  RecordChanged := True;
end;

procedure TUserTeamFilterEditForm.FilterDBGridKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = VK_Space then begin
    if FilterDataset.Active and not(FilterDataset.IsEmpty) then begin
      FilterDataset.Edit;
      FilterDataset.FieldByName('object_groep_checkbox').AsInteger :=
        Abs(FilterDataset.FieldByName('object_groep_checkbox').AsInteger - 1);
      FilterDataset.Post;
    end;
  end;
end;

procedure TUserTeamFilterEditForm.FilterGridCellClick(Sender: TObject; ACol,
  ARow: Integer);
begin
  if ACol = 0 then begin
    if FilterDataset.Active and not(FilterDataset.IsEmpty) then begin
      FilterDataset.Edit;
      FilterDataset.FieldByName('object_groep_checkbox').AsInteger :=
        Abs(FilterDataset.FieldByName('object_groep_checkbox').AsInteger - 1);
      FilterDataset.Post;
    end;
  end;
end;

procedure TUserTeamFilterEditForm.FilterGridKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = vk_space then begin
    if FilterDataset.Active and not(FilterDataset.IsEmpty) then begin
      FilterDataset.Edit;
      FilterDataset.FieldByName('object_groep_checkbox').AsInteger :=
        Abs(FilterDataset.FieldByName('object_groep_checkbox').AsInteger - 1);
      FilterDataset.Post;
    end;
  end;
end;

procedure TUserTeamFilterEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TUserTeamFilterEditForm.WebFormCreate(Sender: TObject);
begin
  TeamFilterSet := False;
  SetGridStyle(FilterGrid, False);
end;

procedure TUserTeamFilterEditForm.WebFormShow(Sender: TObject);
begin
//WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TUserTeamFilterEditForm.LoadTimerTimer(Sender: TObject);
begin
  LoadTimer.Enabled := False;
  FilterDataset.First;
  WebWaitMessage.Hide;
end;

procedure TUserTeamFilterEditForm.LoadFilter();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_team_filter_edit.php?' +
      'sessionid=' + SessionId +
      '&user=' + IntToStr(UserPKey) +
      '&pkey=' + IntToStr(TeamFilterInt);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  FilterDataset.Close;
  FilterDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
  FilterDataset.Open;
end;

procedure TUserTeamFilterEditForm.CloseButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if TeamFilterInt >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if TeamFilterInt >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TUserTeamFilterEditForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

function TUserTeamFilterEditForm.ValidInput(): Boolean;
begin
  Result := True;
end;

procedure TUserTeamFilterEditForm.SaveButtonClick(Sender: TObject);
begin
  if ValidInput() then begin
    if await(SaveFilter()) then begin
      ModalResult := mrOK;
    end;
  end;
end;

function TUserTeamFilterEditForm.SaveFilter(): Boolean;
var
  RecNo: Integer;
  FilterText: String;
  StringList: TStringList;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  // Get New Filter Value
  StringList := TStringList.Create();
  try
    RecNo := FilterDataset.RecNo;
    FilterDataset.DisableControls;
    try
      FilterDataset.First;
      while not(FilterDataset.EOF) do begin
        if FilterDataset.FieldByName('object_groep_checkbox').AsInteger = 1 then begin
          StringList.Add(FilterDataset.FieldByName('object_groep_pk').AsString);
        end;
        FilterDataset.Next;
      end;
    finally
      FilterDataset.RecNo := RecNo;
      FilterDataset.EnableControls;
    end;
    FilterText := '{' + StringList.DelimitedText + '}';
  finally
    StringList.Free;
  end;
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_team_filter_save.php?sessionid=' + SessionId +
      '&user=' + IntToStr(UserPKey) +
      '&filter=' + IntToStr(TeamFilterInt) +
      '&name=' + Trim(FilterNameEdit.Text) +
      '&value=' + FilterText +
      '&public=' + '0' +
      '&default=' + IntToStr(BooleanToInteger(FilterDefaultCheckBox.Checked));
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    Result := True;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TUserTeamFilterEditForm.ShowTimerTimer(Sender: TObject);
begin
  if TeamFilterSet then begin
    ShowTimer.Enabled := False;
    // Set Focus
    if TeamFilterInt = -1 then begin
      FilterNameEdit.SetFocus;
    end else begin
      FilterGrid.SetFocus;
    end;
    // Filter
    FilterNameEdit.Text := TeamFilterName;
    FilterDefaultCheckBox.Checked := TeamFilterDefault;
    // Load Filter Data
    LoadFilter();
    RecordChanged := False;
  end;
end;

procedure TUserTeamFilterEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  TopPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  FilterNameEdit := TEdit.Create(Self);
  FilterDefaultCheckBox := TCheckBox.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  FilterGrid := TTMSFNCGrid.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  FilterDataset := TClientDataSet.Create(Self);
  FilterDatasetobject_groep_pk := TStringField.Create(Self);
  FilterDatasetobject_groep_naam := TStringField.Create(Self);
  FilterDatasetobject_groep_checkbox := TStringField.Create(Self);
  FilterSource := TDataSource.Create(Self);
  LoadTimer := TTimer.Create(Self);
  ShowTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  FilterNameEdit.BeforeLoadDFMValues;
  FilterDefaultCheckBox.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  FilterGrid.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  FilterDataset.BeforeLoadDFMValues;
  FilterDatasetobject_groep_pk.BeforeLoadDFMValues;
  FilterDatasetobject_groep_naam.BeforeLoadDFMValues;
  FilterDatasetobject_groep_checkbox.BeforeLoadDFMValues;
  FilterSource.BeforeLoadDFMValues;
  LoadTimer.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  try
    Name := 'UserTeamFilterEditForm';
    Width := 640;
    Height := 544;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 640;
    FormPanel.Height := 544;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 634;
    ContentPanel.Height := 501;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    TopPanel.SetParentComponent(ContentPanel);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 0;
    TopPanel.Width := 634;
    TopPanel.Height := 97;
    TopPanel.Align := alTop;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 2;
    TopPanel.Color := clWhite;
    UsernameLabel.SetParentComponent(TopPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 20;
    UsernameLabel.Top := 10;
    UsernameLabel.Width := 42;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'Name';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    FilterNameEdit.SetParentComponent(TopPanel);
    FilterNameEdit.Name := 'FilterNameEdit';
    FilterNameEdit.Left := 20;
    FilterNameEdit.Top := 40;
    FilterNameEdit.Width := 345;
    FilterNameEdit.Height := 34;
    FilterNameEdit.ChildOrder := 1;
    FilterNameEdit.ElementClassName := 'MyWebEdit';
    FilterNameEdit.Font.Charset := ANSI_CHARSET;
    FilterNameEdit.Font.Color := clWindowText;
    FilterNameEdit.Font.Height := -16;
    FilterNameEdit.Font.Name := 'Segoe UI';
    FilterNameEdit.Font.Style := [];
    FilterNameEdit.HeightPercent := 100.000000000000000000;
    FilterNameEdit.ParentFont := False;
    FilterNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterNameEdit, Self, 'OnChange', 'RecordChange');
    FilterDefaultCheckBox.SetParentComponent(TopPanel);
    FilterDefaultCheckBox.Name := 'FilterDefaultCheckBox';
    FilterDefaultCheckBox.Left := 416;
    FilterDefaultCheckBox.Top := 44;
    FilterDefaultCheckBox.Width := 113;
    FilterDefaultCheckBox.Height := 22;
    FilterDefaultCheckBox.Caption := 'Default';
    FilterDefaultCheckBox.ChildOrder := 2;
    FilterDefaultCheckBox.Font.Charset := ANSI_CHARSET;
    FilterDefaultCheckBox.Font.Color := 9010544;
    FilterDefaultCheckBox.Font.Height := -16;
    FilterDefaultCheckBox.Font.Name := 'Segoe UI';
    FilterDefaultCheckBox.Font.Style := [];
    FilterDefaultCheckBox.HeightPercent := 100.000000000000000000;
    FilterDefaultCheckBox.ParentFont := False;
    FilterDefaultCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(FilterDefaultCheckBox, Self, 'OnClick', 'RecordChange');
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 577;
    GridDatabaseAdapter.Top := 263;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := FilterGrid;
    GridDatabaseAdapter.DataSource := FilterSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'object_groep_checkbox';
      Header := 'View';
      CheckBoxField := True;
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'object_groep_naam';
      Header := 'Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    FilterGrid.SetParentComponent(ContentPanel);
    FilterGrid.Name := 'FilterGrid';
    FilterGrid.AlignWithMargins := True;
    FilterGrid.Left := 20;
    FilterGrid.Top := 97;
    FilterGrid.Width := 594;
    FilterGrid.Height := 345;
    FilterGrid.Margins.Left := 20;
    FilterGrid.Margins.Top := 0;
    FilterGrid.Margins.Right := 20;
    FilterGrid.Margins.Bottom := 10;
    FilterGrid.AdaptToStyle := True;
    FilterGrid.Align := alClient;
    FilterGrid.BevelInner := bvNone;
    FilterGrid.BevelOuter := bvNone;
    FilterGrid.BevelWidth := 2;
    FilterGrid.Ctl3D := False;
    FilterGrid.ParentCtl3D := False;
    FilterGrid.ParentDoubleBuffered := False;
    FilterGrid.DoubleBuffered := True;
    FilterGrid.Font.Charset := ANSI_CHARSET;
    FilterGrid.Font.Color := clWindowText;
    FilterGrid.Font.Height := -16;
    FilterGrid.Font.Name := 'Segoe UI';
    FilterGrid.Font.Style := [];
    FilterGrid.ParentFont := False;
    FilterGrid.TabOrder := 2;
    SetEvent(FilterGrid, Self, 'OnKeyDown', 'FilterGridKeyDown');
    FilterGrid.DefaultRowHeight := 34.000000000000000000;
    FilterGrid.FixedColumns := 0;
    FilterGrid.ColumnCount := 3;
    FilterGrid.RowCount := 1;
    FilterGrid.Options.Borders.FixedCellBorders := bNone;
    FilterGrid.Options.ColumnSize.Stretch := True;
    FilterGrid.Options.ColumnSize.StretchColumn := 1;
    FilterGrid.Options.Editing.CalcFormat := '%g';
    FilterGrid.Options.Grouping.CalcFormat := '%g';
    FilterGrid.Options.Grouping.GroupCountFormat := '(%d)';
    FilterGrid.Options.HTMLExport.CellPadding := 10;
    FilterGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    FilterGrid.Options.Mouse.WheelScrollKeepSelection := True;
    FilterGrid.Options.Mouse.ClickMargin := 0;
    FilterGrid.Options.Mouse.ColumnSizeMargin := 6;
    FilterGrid.Options.Mouse.RowSizeMargin := 6;
    FilterGrid.Options.URL.Color := clSkyBlue;
    FilterGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    FilterGrid.Options.Selection.Mode := smSingleRow;
    FilterGrid.Adapter := GridDatabaseAdapter;
    FilterGrid.HorizontalScrollBarVisible := False;
    FilterGrid.Columns.Clear;
    with FilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'View';
      ReadOnly := True;
      Width := 60.000000000000000000;
    end;
    with FilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Name := 'Name';
      ReadOnly := True;
      Width := 76.000000000000000000;
    end;
    FilterGrid.Fill.ColorTo := clSilver;
    FilterGrid.DefaultFont.Charset := ANSI_CHARSET;
    FilterGrid.DefaultFont.Color := clWindowText;
    FilterGrid.DefaultFont.Height := -16;
    FilterGrid.DefaultFont.Name := 'Segoe UI';
    FilterGrid.DefaultFont.Style := [];
    FilterGrid.Stroke.Color := 15987699;
    FilterGrid.Stroke.Width := 2.000000000000000000;
    FilterGrid.TopRow := 1;
    FilterGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    FilterGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    FilterGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.FixedLayout.Font.Color := clWhite;
    FilterGrid.Appearance.FixedLayout.Font.Height := -16;
    FilterGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.FixedLayout.Font.Style := [];
    FilterGrid.Appearance.NormalLayout.Fill.Color := -1;
    FilterGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    FilterGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.NormalLayout.Font.Color := clGray;
    FilterGrid.Appearance.NormalLayout.Font.Height := -16;
    FilterGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.NormalLayout.Font.Style := [];
    FilterGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    FilterGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    FilterGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    FilterGrid.Appearance.GroupLayout.Font.Color := clBlack;
    FilterGrid.Appearance.GroupLayout.Font.Height := -16;
    FilterGrid.Appearance.GroupLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.GroupLayout.Font.Style := [];
    FilterGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    FilterGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    FilterGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    FilterGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    FilterGrid.Appearance.SummaryLayout.Font.Height := -16;
    FilterGrid.Appearance.SummaryLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.SummaryLayout.Font.Style := [];
    FilterGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    FilterGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    FilterGrid.Appearance.SelectedLayout.Font.Height := -16;
    FilterGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.SelectedLayout.Font.Style := [];
    FilterGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    FilterGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    FilterGrid.Appearance.FocusedLayout.Font.Height := -16;
    FilterGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.FocusedLayout.Font.Style := [];
    FilterGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    FilterGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    FilterGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    FilterGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    FilterGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    FilterGrid.Appearance.BandLayout.Fill.Color := 14062646;
    FilterGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    FilterGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    FilterGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    FilterGrid.Appearance.BandLayout.Font.Color := clBlack;
    FilterGrid.Appearance.BandLayout.Font.Height := -16;
    FilterGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    FilterGrid.Appearance.BandLayout.Font.Style := [];
    FilterGrid.Appearance.ProgressLayout.Color := 15385233;
    FilterGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    FilterGrid.LeftCol := 0;
    FilterGrid.ScrollMode := scmItemScrolling;
    FilterGrid.GlobalFont.Scale := 1.000000000000000000;
    FilterGrid.GlobalFont.Style := [];
    FilterGrid.DesignTimeSampleData := True;
    SetEvent(FilterGrid, Self, 'OnCellClick', 'FilterGridCellClick');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 452;
    StatusBarPanel.Width := 634;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 514;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uUserTeamFilterEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 20;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alLeft;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uUserTeamFilterEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 640;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 164;
    CaptionLabel.Height := 21;
    CaptionLabel.Caption := 'Asset Group Filter - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Segoe UI';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 611;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uUserTeamFilterEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 582;
    WebWaitMessage.Top := 64;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uUserTeamFilterEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 582;
    MyMessageDlg.Top := 234;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    FilterDataset.SetParentComponent(Self);
    FilterDataset.Name := 'FilterDataset';
    FilterDataset.AfterOpen := FilterDatasetAfterOpen;
    FilterDataset.AfterPost := FilterDatasetAfterPost;
    FilterDataset.Left := 580;
    FilterDataset.Top := 104;
    FilterDatasetobject_groep_pk.SetParentComponent(FilterDataset);
    FilterDatasetobject_groep_pk.Name := 'FilterDatasetobject_groep_pk';
    FilterDatasetobject_groep_pk.FieldName := 'object_groep_pk';
    FilterDatasetobject_groep_pk.Size := 256;
    FilterDatasetobject_groep_naam.SetParentComponent(FilterDataset);
    FilterDatasetobject_groep_naam.Name := 'FilterDatasetobject_groep_naam';
    FilterDatasetobject_groep_naam.FieldName := 'object_groep_naam';
    FilterDatasetobject_groep_naam.Size := 256;
    FilterDatasetobject_groep_checkbox.SetParentComponent(FilterDataset);
    FilterDatasetobject_groep_checkbox.Name := 'FilterDatasetobject_groep_checkbox';
    FilterDatasetobject_groep_checkbox.FieldName := 'object_groep_checkbox';
    FilterDatasetobject_groep_checkbox.Size := 256;
    FilterSource.SetParentComponent(Self);
    FilterSource.Name := 'FilterSource';
    FilterSource.DataSet := FilterDataset;
    FilterSource.Left := 580;
    FilterSource.Top := 176;
    LoadTimer.SetParentComponent(Self);
    LoadTimer.Name := 'LoadTimer';
    LoadTimer.Enabled := False;
    LoadTimer.Interval := 50;
    SetEvent(LoadTimer, Self, 'OnTimer', 'LoadTimerTimer');
    LoadTimer.Left := 579;
    LoadTimer.Top := 392;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 579;
    ShowTimer.Top := 336;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    FilterNameEdit.AfterLoadDFMValues;
    FilterDefaultCheckBox.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    FilterGrid.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    FilterDataset.AfterLoadDFMValues;
    FilterDatasetobject_groep_pk.AfterLoadDFMValues;
    FilterDatasetobject_groep_naam.AfterLoadDFMValues;
    FilterDatasetobject_groep_checkbox.AfterLoadDFMValues;
    FilterSource.AfterLoadDFMValues;
    LoadTimer.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
  end;
end;

end.

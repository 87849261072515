unit uAssetRepairTextEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, WEBLib.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons;

type
  TAssetRepairTextEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    BrandNameLabel: TLabel;
    NameEdit: TEdit;
    MyMessageDlg: TMessageDlg;

    AssetBrandEdit: TLookupComboBox;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    AssetTypeEdit: TLookupComboBox;
    WebLabel3: TLabel;
    TextEdit: TMemo;
    WebLabel4: TLabel;
    AssetModelEdit: TLookupComboBox;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    procedure AssetBrandEditChange(Sender: TObject);
  private
    { Private declarations }
    ModelListJSON: String;
    RecordChanged: Boolean;
    [async]
    procedure LoadAssetTypeList(); async;
    [async]
    procedure LoadAssetBrandList(); async;
    [async]
    procedure LoadAssetModelList(); async;
    [async]
    procedure ShowAssetModelList(BrandPKey: String); async;
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Integer;
    IsSet: Boolean;
  protected procedure LoadDFMValues; override; end;

var
  AssetRepairTextEditForm: TAssetRepairTextEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetRepairTextEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TAssetRepairTextEditForm.AssetBrandEditChange(Sender: TObject);
begin
  ShowAssetModelList(AssetBrandEdit.Value);
  RecordChange(TObject(Sender));
end;

procedure TAssetRepairTextEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetRepairTextEditForm.WebFormCreate(Sender: TObject);
begin
  //
end;

procedure TAssetRepairTextEditForm.WebFormShow(Sender: TObject);
begin
  await(LoadAssetTypeList());
  await(LoadAssetBrandList());
  await(LoadAssetModelList());
  await(LoadData());
  RecordChanged := False;
end;

procedure TAssetRepairTextEditForm.LoadAssetTypeList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetTypeEdit.LookupValues.Clear;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_type_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONArray := TJSONArray(MyJSON.Parse(string(MyRequest.response)));
        AssetTypeEdit.LookupValues.AddPair('0', 'N/A');
        for i := 0 to MyJSONArray.Count-1 do begin
          MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
          AssetTypeEdit.LookupValues.AddPair(
            MyJSONObject.GetJSONValue('ot_pk'),
            MyJSONObject.GetJSONValue('ot_naam')
          );
        end;
      finally
        MyJSON.Free;
      end;
      // Set Value
      AssetTypeEdit.Value := '0';
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetRepairTextEditForm.LoadAssetBrandList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetBrandEdit.LookupValues.Clear;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_brand_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONArray := TJSONArray(MyJSON.Parse(string(MyRequest.response)));
        AssetBrandEdit.LookupValues.AddPair('0', 'N/A');
        for i := 0 to MyJSONArray.Count-1 do begin
          MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
          AssetBrandEdit.LookupValues.AddPair(
            MyJSONObject.GetJSONValue('PKey'),
            MyJSONObject.GetJSONValue('ID')
          );
        end;
      finally
        MyJSON.Free;
      end;
      // Set Value
      AssetBrandEdit.Value := '0';
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetRepairTextEditForm.LoadAssetModelList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  AssetModelEdit.LookupValues.Clear;
  AssetModelEdit.LookupValues.AddPair('0', 'N/A');
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_model_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      ModelListJSON := string(MyRequest.response);
      // Set Value
      AssetModelEdit.Value := '0';
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetRepairTextEditForm.ShowAssetModelList(BrandPKey: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetModelEdit.LookupValues.Clear;
  AssetModelEdit.LookupValues.AddPair('0', 'N/A');
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(ModelListJSON));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      if MyJSONObject.GetJSONValue('BrandPKey') = BrandPKey then begin
        AssetModelEdit.LookupValues.AddPair(
          MyJSONObject.GetJSONValue('PKey'),
          MyJSONObject.GetJSONValue('ID')
        );
      end;
    end;
    AssetModelEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetRepairTextEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  if PKey >= 0 then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_asset_repair_load.php?sessionid=' + SessionId +
        '&pkey=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      if MyRequest.Status = 200 then begin
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          // Assign Values
          NameEdit.Text := MyJSONObject.GetJSONValue('id');
          AssetTypeEdit.Value := MyJSONObject.GetJSONValue('type_pkey');
          AssetBrandEdit.Value := MyJSONObject.GetJSONValue('brand_pkey');
          await(ShowAssetModelList(AssetBrandEdit.Value));
          AssetModelEdit.Value := MyJSONObject.GetJSONValue('model_pkey');
          TextEdit.Text := Window.atob(MyJSONObject.GetJSONValue('repair_text'));
        finally
          MyJSON.Free;
        end
      end;
    finally
      MyWebRequest.Free;
    end;
  end;
end;

function TAssetRepairTextEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Name
  if Result then begin
    if Trim(NameEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'Name is a required field.';
    end;
  end;
end;

function TAssetRepairTextEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id": "' + NameEdit.Text + '",' +
      '"type": "' + AssetTypeEdit.Value + '",' +
      '"brand": "' + AssetBrandEdit.Value + '",' +
      '"model": "' + AssetModelEdit.Value + '",' +
      '"text": "' + Window.btoa(TextEdit.Text) + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_repair_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetRepairTextEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, mtError, [mbOK]);
  end;
end;

procedure TAssetRepairTextEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  BrandNameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  NameEdit := TEdit.Create(Self);
  AssetBrandEdit := TLookupComboBox.Create(Self);
  AssetTypeEdit := TLookupComboBox.Create(Self);
  TextEdit := TMemo.Create(Self);
  AssetModelEdit := TLookupComboBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  BrandNameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  NameEdit.BeforeLoadDFMValues;
  AssetBrandEdit.BeforeLoadDFMValues;
  AssetTypeEdit.BeforeLoadDFMValues;
  TextEdit.BeforeLoadDFMValues;
  AssetModelEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  try
    Name := 'AssetRepairTextEditForm';
    Width := 445;
    Height := 672;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 445;
    FormPanel.Height := 672;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 439;
    ContentPanel.Height := 629;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    BrandNameLabel.SetParentComponent(ContentPanel);
    BrandNameLabel.Name := 'BrandNameLabel';
    BrandNameLabel.Left := 20;
    BrandNameLabel.Top := 15;
    BrandNameLabel.Width := 42;
    BrandNameLabel.Height := 21;
    BrandNameLabel.Caption := 'Name';
    BrandNameLabel.Font.Charset := ANSI_CHARSET;
    BrandNameLabel.Font.Color := 9010544;
    BrandNameLabel.Font.Height := -16;
    BrandNameLabel.Font.Name := 'Segoe UI';
    BrandNameLabel.Font.Style := [];
    BrandNameLabel.HeightPercent := 100.000000000000000000;
    BrandNameLabel.ParentFont := False;
    BrandNameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 163;
    WebLabel1.Width := 41;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Brand';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 20;
    WebLabel2.Top := 90;
    WebLabel2.Width := 32;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Type';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(ContentPanel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 20;
    WebLabel3.Top := 308;
    WebLabel3.Width := 26;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Text';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ContentPanel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 20;
    WebLabel4.Top := 235;
    WebLabel4.Width := 44;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Model';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 580;
    StatusBarPanel.Width := 439;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 319;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetRepairTextEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 204;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetRepairTextEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    NameEdit.SetParentComponent(ContentPanel);
    NameEdit.Name := 'NameEdit';
    NameEdit.Left := 20;
    NameEdit.Top := 42;
    NameEdit.Width := 397;
    NameEdit.Height := 34;
    NameEdit.ChildOrder := 1;
    NameEdit.ElementClassName := 'MyWebEdit';
    NameEdit.Font.Charset := ANSI_CHARSET;
    NameEdit.Font.Color := clWindowText;
    NameEdit.Font.Height := -16;
    NameEdit.Font.Name := 'Segoe UI';
    NameEdit.Font.Style := [];
    NameEdit.HeightPercent := 100.000000000000000000;
    NameEdit.ParentFont := False;
    NameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameEdit, Self, 'OnChange', 'RecordChange');
    AssetBrandEdit.SetParentComponent(ContentPanel);
    AssetBrandEdit.Name := 'AssetBrandEdit';
    AssetBrandEdit.AlignWithMargins := True;
    AssetBrandEdit.Left := 20;
    AssetBrandEdit.Top := 191;
    AssetBrandEdit.Width := 397;
    AssetBrandEdit.Height := 34;
    AssetBrandEdit.Margins.Left := 20;
    AssetBrandEdit.Margins.Top := 8;
    AssetBrandEdit.Margins.Bottom := 8;
    AssetBrandEdit.Color := clWhite;
    AssetBrandEdit.ElementClassName := 'myselect_white';
    AssetBrandEdit.Font.Charset := DEFAULT_CHARSET;
    AssetBrandEdit.Font.Color := clWindowText;
    AssetBrandEdit.Font.Height := 12;
    AssetBrandEdit.Font.Name := 'Segoe UI';
    AssetBrandEdit.Font.Style := [];
    AssetBrandEdit.HeightPercent := 100.000000000000000000;
    AssetBrandEdit.ParentFont := False;
    AssetBrandEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandEdit, Self, 'OnChange', 'AssetBrandEditChange');
    AssetBrandEdit.ItemIndex := -1;
    AssetTypeEdit.SetParentComponent(ContentPanel);
    AssetTypeEdit.Name := 'AssetTypeEdit';
    AssetTypeEdit.AlignWithMargins := True;
    AssetTypeEdit.Left := 20;
    AssetTypeEdit.Top := 118;
    AssetTypeEdit.Width := 397;
    AssetTypeEdit.Height := 34;
    AssetTypeEdit.Margins.Left := 20;
    AssetTypeEdit.Margins.Top := 8;
    AssetTypeEdit.Margins.Bottom := 8;
    AssetTypeEdit.Color := clWhite;
    AssetTypeEdit.ElementClassName := 'myselect_white';
    AssetTypeEdit.Font.Charset := DEFAULT_CHARSET;
    AssetTypeEdit.Font.Color := clWindowText;
    AssetTypeEdit.Font.Height := 12;
    AssetTypeEdit.Font.Name := 'Segoe UI';
    AssetTypeEdit.Font.Style := [];
    AssetTypeEdit.HeightPercent := 100.000000000000000000;
    AssetTypeEdit.ParentFont := False;
    AssetTypeEdit.WidthPercent := 100.000000000000000000;
    AssetTypeEdit.ItemIndex := -1;
    TextEdit.SetParentComponent(ContentPanel);
    TextEdit.Name := 'TextEdit';
    TextEdit.Left := 20;
    TextEdit.Top := 335;
    TextEdit.Width := 397;
    TextEdit.Height := 239;
    TextEdit.AutoSize := False;
    TextEdit.ElementClassName := 'MyWebEdit';
    TextEdit.HeightPercent := 100.000000000000000000;
    TextEdit.SelLength := 0;
    TextEdit.SelStart := 0;
    TextEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TextEdit, Self, 'OnChange', 'RecordChange');
    AssetModelEdit.SetParentComponent(ContentPanel);
    AssetModelEdit.Name := 'AssetModelEdit';
    AssetModelEdit.AlignWithMargins := True;
    AssetModelEdit.Left := 20;
    AssetModelEdit.Top := 263;
    AssetModelEdit.Width := 397;
    AssetModelEdit.Height := 34;
    AssetModelEdit.Margins.Left := 20;
    AssetModelEdit.Margins.Top := 8;
    AssetModelEdit.Margins.Bottom := 8;
    AssetModelEdit.Color := clWhite;
    AssetModelEdit.ElementClassName := 'myselect_white';
    AssetModelEdit.Font.Charset := DEFAULT_CHARSET;
    AssetModelEdit.Font.Color := clWindowText;
    AssetModelEdit.Font.Height := 12;
    AssetModelEdit.Font.Name := 'Segoe UI';
    AssetModelEdit.Font.Style := [];
    AssetModelEdit.HeightPercent := 100.000000000000000000;
    AssetModelEdit.ParentFont := False;
    AssetModelEdit.WidthPercent := 100.000000000000000000;
    AssetModelEdit.ItemIndex := -1;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 445;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 119;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Repair text - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 416;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetRepairTextEdit.CaptionPanel.CloseImage.Picture.png');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 222;
    MyMessageDlg.Top := 52;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    BrandNameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    NameEdit.AfterLoadDFMValues;
    AssetBrandEdit.AfterLoadDFMValues;
    AssetTypeEdit.AfterLoadDFMValues;
    TextEdit.AfterLoadDFMValues;
    AssetModelEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
  end;
end;

end.
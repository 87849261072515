{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCheckedListBox;

interface

{$I WEBLib.TMSFNCDefines.inc}

uses
  Classes, WEBLib.TMSFNCListBox, WEBLib.TMSFNCTreeView, WEBLib.TMSFNCTreeViewData
  {$IFNDEF LCLLIB}
  ,WEBLib.TMSFNCTypes
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 2; // Build nr.

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.2 : Fixed : Issue with initialization on WEB

type
  TTMSFNCCheckedListBoxItems = class;

  TTMSFNCCheckedListBoxItem = class(TTMSFNCListBoxItem)
  published
    property Checked;
  end;

  TTMSFNCListBoxCheckedItems = array of TTMSFNCCheckedListBoxItem;

  TTMSFNCCheckedListBoxItems = class(TTMSFNCListBoxItems)
  protected
    function GetItemClass: TCollectionItemClass; override;
  public
    function CheckedItems: TTMSFNCListBoxCheckedItems; virtual;
  end;

  TTMSFNCCheckedListBoxItemCheckChanged = procedure(Sender: TObject; AItem: TTMSFNCCheckedListBoxItem) of object;

  TTMSFNCCustomCheckedListBox = class(TTMSFNCListBox)
  private
    FOnItemCheckChanged: TTMSFNCCheckedListBoxItemCheckChanged;
    function GetCheckedItem(AItem: TTMSFNCCheckedListBoxItem): Boolean;
    procedure SetCheckedItem(AItem: TTMSFNCCheckedListBoxItem; const Value: Boolean);
    function GetChecked(AItemIndex: Integer): Boolean;
    procedure SetChecked(AItemIndex: Integer; const Value: Boolean);
  protected
    procedure Loaded; override;
    function CreateItems: TTMSFNCListBoxItems; override;
    function GetVersion: string; override;
    procedure DoItemCheckChanged(Sender: TObject; AItem: TTMSFNCCheckedListBoxItem); virtual;
    procedure CustomizeTreeView(ATreeView: TTMSFNCTreeView); override;
    procedure DoIsNodeChecked(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AIsChecked: Boolean); virtual;
    procedure DoGetNodeCheckType(Sender: TObject; {%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType); virtual;
    procedure DoAfterCheckNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer); virtual;
    procedure DoAfterUnCheckNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer); virtual;
    property OnItemCheckChanged: TTMSFNCCheckedListBoxItemCheckChanged read FOnItemCheckChanged write FOnItemCheckChanged;
  public
    property CheckedItem[AItem: TTMSFNCCheckedListBoxItem]: Boolean read GetCheckedItem write SetCheckedItem;
    property Checked[AItemIndex: Integer]: Boolean read GetChecked write SetChecked;
    function CheckedItems: TTMSFNCListBoxCheckedItems; virtual;
    function AddItem(AText: string = ''): TTMSFNCCheckedListBoxItem; reintroduce; virtual;
    procedure CheckAll;
    procedure UncheckAll;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCheckedListBox = class(TTMSFNCCustomCheckedListBox)
  published
    property OnItemCheckChanged;
    property OnAfterDrawItemCheck;
    property OnBeforeDrawItemCheck;
  end;

implementation

uses
  WEBLib.TMSFNCUtils
  {$IFDEF WEBLIB}
  , web
  {$ENDIF};

{ TTMSFNCCheckedListBox }

function TTMSFNCCustomCheckedListBox.AddItem(
  AText: string): TTMSFNCCheckedListBoxItem;
begin
  Result := TTMSFNCCheckedListBoxItem(inherited AddItem(AText));
end;

procedure TTMSFNCCustomCheckedListBox.CheckAll;
var
  I: Integer;
begin
  BeginUpdate;
  for I := 0 to Items.Count - 1 do
    Checked[I] := True;
  EndUpdate;
end;

function TTMSFNCCustomCheckedListBox.CheckedItems: TTMSFNCListBoxCheckedItems;
begin
  Result := TTMSFNCCheckedListBoxItems(Items).CheckedItems;
end;

function TTMSFNCCustomCheckedListBox.CreateItems: TTMSFNCListBoxItems;
begin
  Result := TTMSFNCCheckedListBoxItems.Create(Self);
end;

procedure TTMSFNCCustomCheckedListBox.CustomizeTreeView(
  ATreeView: TTMSFNCTreeView);
begin
  inherited;
  ATreeView.OnIsNodeChecked := DoIsNodeChecked;
  ATreeView.OnGetNodeCheckType := DoGetNodeCheckType;
  ATreeView.OnAfterUnCheckNode := DoAfterUnCheckNode;
  ATreeView.OnAfterCheckNode := DoAfterCheckNode;
end;

procedure TTMSFNCCustomCheckedListBox.DoAfterCheckNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer);
var
  it: TTMSFNCCheckedListBoxItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
  begin
    it := Items[ANode.Row] as TTMSFNCCheckedListBoxItem;
    it.Checked := True;
    DoItemCheckChanged(Self, it);
  end;
end;

procedure TTMSFNCCustomCheckedListBox.DoAfterUnCheckNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer);
var
  it: TTMSFNCCheckedListBoxItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
  begin
    it := Items[ANode.Row] as TTMSFNCCheckedListBoxItem;
    it.Checked := False;
    DoItemCheckChanged(Self, it);
  end;
end;

procedure TTMSFNCCustomCheckedListBox.DoGetNodeCheckType(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType);
begin
  ACheckType := tvntCheckBox;
end;

procedure TTMSFNCCustomCheckedListBox.DoIsNodeChecked(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AIsChecked: Boolean);
begin
  inherited;
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    AIsChecked := (Items[ANode.Row] as TTMSFNCCheckedListBoxItem).Checked;
end;

procedure TTMSFNCCustomCheckedListBox.DoItemCheckChanged(Sender: TObject;
  AItem: TTMSFNCCheckedListBoxItem);
begin
  if Assigned(OnItemCheckChanged) then
    OnItemCheckChanged(Self, AItem);
end;

function TTMSFNCCustomCheckedListBox.GetChecked(AItemIndex: Integer): Boolean;
begin
  Result := False;
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    Result := (Items[AItemIndex] as TTMSFNCCheckedListBoxItem).Checked;
end;

function TTMSFNCCustomCheckedListBox.GetCheckedItem(
  AItem: TTMSFNCCheckedListBoxItem): Boolean;
begin
  Result := False;
  if Assigned(AItem) then
    Result := AItem.Checked;
end;

function TTMSFNCCustomCheckedListBox.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomCheckedListBox.Loaded;
begin
  inherited;
  {$IFDEF WEBLIB}
  window.setTimeout(@UpdateItems, 100);
  {$ENDIF}
end;

procedure TTMSFNCCustomCheckedListBox.SetChecked(AItemIndex: Integer;
  const Value: Boolean);
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    (Items[AItemIndex] as TTMSFNCCheckedListBoxItem).Checked := Value;
end;

procedure TTMSFNCCustomCheckedListBox.SetCheckedItem(AItem: TTMSFNCCheckedListBoxItem;
  const Value: Boolean);
begin
  if Assigned(AItem) then
    AItem.Checked := Value;
end;

procedure TTMSFNCCustomCheckedListBox.UncheckAll;
var
  I: Integer;
begin
  BeginUpdate;
  for I := 0 to Items.Count - 1 do
    Checked[I] := False;
  EndUpdate;
end;

{ TTMSFNCCheckedListBoxItems }

function TTMSFNCCheckedListBoxItems.CheckedItems: TTMSFNCListBoxCheckedItems;
var
  I: Integer;
  n: TTMSFNCCheckedListBoxItem;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    n := Items[I] as TTMSFNCCheckedListBoxItem;
    if n.Checked then
    begin
      SetLength(Result, Length(Result) + 1);
      Result[Length(Result) - 1] := n;
    end;
  end;
end;

function TTMSFNCCheckedListBoxItems.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCCheckedListBoxItem;
end;

end.


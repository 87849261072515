{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGridCell;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCBitmapContainer,
  WEBLib.TMSFNCTypes, WEBLib.Controls, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,UITypes, Generics.Collections, Types, WEBLib.StdCtrls
  {$ENDIF}
  {$ENDIF}
  ;

type
  TTMSFNCFixedGridCell = class;

  TTMSFNCGridNodeState = (nsClosed, nsOpen);

  TTMSFNCGridSortKind = (skNone, skAscending, skDescending);

  TTMSFNCGridCell = class;

  TTMSFNCGridCellLayout = class(TPersistent)
  private
    FSides: TTMSFNCGraphicsSides;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FFont: TTMSFNCGraphicsFont;
    FTextAlign: TTMSFNCGraphicsTextAlign;
    FWordWrapping: Boolean;
    FOnChange: TNotifyEvent;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSides(const Value: TTMSFNCGraphicsSides);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetWordWrapping(const Value: Boolean);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Sides: TTMSFNCGraphicsSides read FSides write SetSides default [gsLeft, gsTop, gsRight, gsBottom];
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property TextAlign: TTMSFNCGraphicsTextAlign read FTextAlign write SetTextAlign default gtaLeading;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridCellProgressLayout = class(TPersistent)
  private
    FTextColor: TTMSFNCGraphicsColor;
    FColor: TTMSFNCGraphicsColor;
    FFormat: String;
    FOnChange: TNotifyEvent;
    FShowText: Boolean;
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetFormat(const Value: String);
    procedure SetShowText(const Value: Boolean);
  protected
    procedure Changed;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcYellowgreen;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property Format: String read FFormat write SetFormat;
    property ShowText: Boolean read FShowText write SetShowText default True;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCCellBeforeDraw = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; var ARect: TRectF;
    var ATextRect: TRectF; var ADrawText: Boolean; var ADrawBackGround: Boolean; var ADrawBorder: Boolean; var AllowDraw: Boolean) of object;

  TTMSFNCCellAfterDraw = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF) of object;

  TTMSFNCGridCellControlPosition = (gcpLeft, gcpTop, gcpRight, gcpBottom, gcpCenter);

  TTMSFNCGridCell = class(TPersistent)
  private
    FOwner: TComponent;
    FText: String;
    FLayout: TTMSFNCGridCellLayout;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FDisplayHTMLFormatting: Boolean;
    FWidth: Single;
    FHeight: Single;
    FTop: Single;
    FLeft: Single;
    FControlBitmap: TTMSFNCBitmapHelperClass;
    FControlEnabled: Boolean;
    FOnBeforeDraw: TTMSFNCCellBeforeDraw;
    FOnAfterDraw: TTMSFNCCellAfterDraw;
    FOnControlClick: TNotifyEvent;
    FControlStretched: Boolean;
    FControlPosition: TTMSFNCGridCellControlPosition;
    FOptimizedHTMLDrawing: Boolean;
    FScaleFactor: Single;
    FAngle: Integer;
    procedure SetText(const Value: String); virtual;
    procedure SetLayout(const Value: TTMSFNCGridCellLayout); virtual;
    procedure SetDisplayHTMLFormatting(const Value: Boolean); virtual;
    procedure SetHeight(const Value: single); virtual;
    procedure SetWidth(const Value: single); virtual;
    procedure SetLeft(const Value: single); virtual;
    procedure SetTop(const Value: single); virtual;
    procedure SetControlBitmap(const Value: TTMSFNCBitmapHelperClass); virtual;
    function GetControlBitmap: TTMSFNCBitmapHelperClass; virtual;
    procedure SetControlEnabled(const Value: Boolean);
    procedure SetAngle(const Value: Integer);
  protected
    procedure Changed(Sender: TObject);
    procedure DoControlClick(Sender: TObject);
    procedure HandleControlMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); virtual;
    procedure HandleControlMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); virtual;
    procedure HandleControlMouseMove({%H-}Shift: TShiftstate; {%H-}X, {%H-}Y: Single; var {%H-}ACursor: TCursor); virtual;
    procedure DoBeforeDraw(AGraphics: TTMSFNCGraphics; var ARect: TRectF; var ATextRect: TRectF; var ADrawText: Boolean; var ADrawBackGround: Boolean; var ADrawBorder: Boolean; var AllowDraw: Boolean);
    procedure DoAfterDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF);
  public
    function IsHTML: boolean;
    function GetTextWidth: Single; virtual;
    function GetTextHeight: Single; virtual;
    function XYToAnchor(X, Y: Single): string;
    function GetStrippedHTMLText: String;
    function GetControlWidth: Single; virtual;
    function GetControlHeight: Single; virtual;
    function GetRealTextRect(AAbsolute: Boolean = False): TRectF; virtual;
    function GetTextRect(AAbsolute: Boolean = False): TRectF; virtual;
    function GetControlRect(AAbsolute: Boolean = False): TRectF; virtual;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single; var AHandled: Boolean; var ACursor: TCursor); virtual;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single; var AHandled: Boolean); virtual;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single; var AHandled: Boolean); virtual;
    procedure Draw(AGraphics: TTMSFNCGraphics; ADrawText: Boolean; ADrawBackGround: Boolean; ADrawBorder: Boolean); virtual;
    procedure DrawControl(AGraphics: TTMSFNCGraphics); virtual;
    procedure Assign(Source: TPersistent); override;
    procedure AssignLayout(Source: TTMSFNCGridCellLayout);
    property ControlBitmap: TTMSFNCBitmapHelperClass read GetControlBitmap write SetControlBitmap;
    property ControlEnabled: Boolean read FControlEnabled write SetControlEnabled;
    property Text: String read FText write SetText;
    property ScaleFactor: Single read FScaleFactor write FScaleFactor;
    property DisplayHTMLFormatting: Boolean read FDisplayHTMLFormatting write SetDisplayHTMLFormatting default True;
    property Layout: TTMSFNCGridCellLayout read FLayout write SetLayout;
    property BitmapContainer: TTMSFNCBitmapContainer read FBitmapContainer write FBitmapContainer;
    property OptimizedHTMLDrawing: Boolean read FOptimizedHTMLDrawing write FOptimizedHTMLDrawing;
    property Width: single read FWidth write SetWidth;
    property Height: single read FHeight write SetHeight;
    property Angle: Integer read FAngle write SetAngle;
    property Left: single read FLeft write SetLeft;
    property Top: single read FTop write SetTop;
    property ControlStretched: Boolean read FControlStretched write FControlStretched default False;
    property ControlPosition: TTMSFNCGridCellControlPosition read FControlPosition write FControlPosition default gcpLeft;
    property OnBeforeDraw: TTMSFNCCellBeforeDraw read FOnBeforeDraw write FOnBeforeDraw;
    property OnAfterDraw: TTMSFNCCellAfterDraw read FOnAfterDraw write FOnAfterDraw;
    property OnControlClick: TNotifyEvent read FOnControlClick write FOnControlClick;
    constructor Create(AOwner: TComponent); virtual;
    destructor Destroy; override;
  end;

  TTMSFNCGridCellClass = class of TTMSFNCGridCell;

  TTMSFNCBaseCheckGridCell = class(TTMSFNCGridCell)
  private
    FChecked: Boolean;
    FOnCheckChanged: TNotifyEvent;
    procedure SetChecked(const Value: Boolean);
  protected
    procedure HandleControlMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure DoCheckChanged(Sender: TObject); virtual;
  public
    function GetControlWidth: Single; override;
    function GetControlHeight: Single; override;
  published
    property Checked: Boolean read FChecked write SetChecked default False;
    property OnCheckChanged: TNotifyEvent read FOnCheckChanged write FOnCheckChanged;
  end;

  TTMSFNCRadioGridCell = class(TTMSFNCBaseCheckGridCell)
  end;

  TTMSFNCCheckGridCell = class(TTMSFNCBaseCheckGridCell)
  end;

  TTMSFNCButtonGridCell = class(TTMSFNCGridCell)
  private
    FButtonDown: Boolean;
    FButtonWidth: Integer;
    FButtonText: String;
    FButtonHeight: Integer;
    procedure SetButtonText(const Value: String);
    procedure SetButtonWidth(const Value: Integer);
    procedure SetButtonHeight(const Value: Integer);
  protected
    procedure HandleControlMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleControlMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
  public
    function GetControlWidth: Single; override;
    function GetControlHeight: Single; override;
    constructor Create(AOwner: TComponent); override;
    procedure DrawControl(AGraphics: TTMSFNCGraphics); override;
  published
    property ButtonText: String read FButtonText write SetButtonText;
    property ButtonWidth: Integer read FButtonWidth write SetButtonWidth default 24;
    property ButtonHeight: Integer read FButtonHeight write SetButtonHeight default 20;
  end;

  TTMSFNCProgressGridCell = class(TTMSFNCGridCell)
  private
    FValue: Single;
    FColor: TTMSFNCGraphicsColor;
    FShowText: Boolean;
    FTextColor: TTMSFNCGraphicsColor;
    FFormat: string;
    procedure SetValue(const Value: Single);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetShowText(const Value: Boolean);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetFormat(const Value: string);
  public
    procedure Draw(AGraphics: TTMSFNCGraphics; {%H-}ADrawText: Boolean; {%H-}ADrawBackGround: Boolean; {%H-}ADrawBorder: Boolean); override;
    constructor Create(AOwner: TComponent); override;
  published
    property Value: Single read FValue write SetValue;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcYellowgreen;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property ShowText: Boolean read FShowText write SetShowText default True;
    property Format: string read FFormat write SetFormat;
  end;

  TTMSFNCCommentGridCell = class(TTMSFNCGridCell)
  private
    FCommentColor: TTMSFNCGraphicsColor;
    FCommentText: String;
    FCommentTextColor: TTMSFNCGraphicsColor;
  protected
    function PtInComment(X, Y: Single): Boolean; virtual;
  public
    constructor Create(AOwner: TComponent); override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single; var AHandled: Boolean); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single; var AHandled: Boolean; var ACursor: TCursor); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single; var AHandled: Boolean); override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ADrawText: Boolean; ADrawBackGround: Boolean; ADrawBorder: Boolean); override;
  published
    property CommentText: String read FCommentText write FCommentText;
    property CommentColor: TTMSFNCGraphicsColor read FCommentColor write FCommentColor default gcRed;
    property CommentTextColor: TTMSFNCGraphicsColor read FCommentTextColor write FCommentTextColor default gcBlack;
  end;

  TTMSFNCFixedGridCell = class(TTMSFNCGridCell)
  private
    FShowDropDownButton: Boolean;
    FSortIndex: Integer;
    FSortKind: TTMSFNCGridSortKind;
    FSizeMargin: Integer;
  protected
    function PtInDropDown(X, Y: Single): Boolean; virtual;
  public
    constructor Create(AOwner: TComponent); override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single; var AHandled: Boolean); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single; var AHandled: Boolean; var ACursor: TCursor); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single; var AHandled: Boolean); override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ADrawText: Boolean; ADrawBackGround: Boolean; ADrawBorder: Boolean); override;
    procedure DrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
  published
    property ShowDropDownButton: Boolean read FShowDropDownButton write FShowDropDownButton default False;
    property SortKind: TTMSFNCGridSortKind read FSortKind write FSortKind default skDescending;
    property SortIndex: Integer read FSortIndex write FSortIndex default -1;
    property SizeMargin: Integer read FSizeMargin write FSizeMargin default 0;
  end;

  TTMSFNCNodeGridCell = class(TTMSFNCGridCell)
  private
    FState: TTMSFNCGridNodeState;
  protected
    procedure HandleControlMouseMove(Shift: TShiftState; X, Y: Single; var ACursor: TCursor); override;
  published
    property State: TTMSFNCGridNodeState read FState write FState default nsClosed;
  end;

  TTMSFNCBitmapGridCell = class(TTMSFNCGridCell)
  private
    FBitmap: TTMSFNCBitmap;
    FBitmapName: string;
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    procedure SetBitmapName(const Value: string);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function GetControlBitmap: TTMSFNCBitmapHelperClass; override;
  published
    property BitmapName: string read FBitmapName write SetBitmapName;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
  end;

implementation

uses
  Math, SysUtils, WEBLib.TMSFNCUtils;

{ TTMSFNCGridCellLayout }

procedure TTMSFNCGridCellLayout.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGridCellLayout then
  begin
    FFill.Assign((Source as TTMSFNCGridCellLayout).Fill);
    FStroke.Assign((Source as TTMSFNCGridCellLayout).Stroke);
    FSides := (Source as TTMSFNCGridCellLayout).Sides;
    FFont.Assign((Source as TTMSFNCGridCellLayout).Font);
    FTextAlign := (Source as TTMSFNCGridCellLayout).TextAlign;
    FVerticalTextAlign := (Source as TTMSFNCGridCellLayout).VerticalTextAlign;
    FWordWrapping := (Source as TTMSFNCGridCellLayout).WordWrapping;
  end;
end;

procedure TTMSFNCGridCellLayout.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGridCellLayout.Create;
begin
  FFill := TTMSFNCGraphicsFill.Create;
  FFill.Kind := gfkSolid;
  FFill.Color := gcWhite;
  FFill.OnChanged := @FillChanged;

  FStroke := TTMSFNCGraphicsStroke.Create;
  FStroke.Kind := gskSolid;
  FStroke.Color := gcSilver;
  FStroke.OnChanged := @StrokeChanged;

  FSides := [gsLeft, gsTop, gsRight, gsBottom];
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FTextAlign := gtaLeading;
  FVerticalTextAlign := gtaCenter;
  FWordWrapping := False;
end;

destructor TTMSFNCGridCellLayout.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCGridCellLayout.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCGridCellLayout.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCGridCellLayout.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.Assign(Value);
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.SetSides(const Value: TTMSFNCGraphicsSides);
begin
  if FSides <> Value then
  begin
    FSides := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.SetTextAlign(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextAlign <> Value then
  begin
    FTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellLayout.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCGridCell }

procedure TTMSFNCGridCell.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridCell) then
  begin
    FText := (Source as TTMSFNCGridCell).Text;
    FLayout.Assign((Source as TTMSFNCGridCell).Layout);
    FLeft := (Source as TTMSFNCGridCell).Left;
    FTop := (Source as TTMSFNCGridCell).Top;
    FWidth := (Source as TTMSFNCGridCell).Width;
    FHeight := (Source as TTMSFNCGridCell).Height;
    FControlStretched := (Source as TTMSFNCGridCell).ControlStretched;
  end
  else
    inherited;
end;

procedure TTMSFNCGridCell.AssignLayout(Source: TTMSFNCGridCellLayout);
begin
  if (Source is TTMSFNCGridCellLayout) then
    Layout := Source;
end;

procedure TTMSFNCGridCell.Changed(Sender: TObject);
begin
end;

constructor TTMSFNCGridCell.Create(AOwner: TComponent);
begin
  FOwner := AOwner;
  FScaleFactor := 1.0;
  FLayout := TTMSFNCGridCellLayout.Create;
  FLayout.OnChange := @Changed;
  FControlStretched := False;
  FControlEnabled := True;
end;

destructor TTMSFNCGridCell.Destroy;
begin
  FControlBitmap := nil;
  FLayout.Free;
  inherited;
end;

procedure TTMSFNCGridCell.DoAfterDraw(AGraphics: TTMSFNCGraphics; ARect,
  ATextRect: TRectF);
begin
  if Assigned(OnAfterDraw) then
    OnAfterDraw(Self, AGraphics, ARect, ATextRect);
end;

procedure TTMSFNCGridCell.DoBeforeDraw(AGraphics: TTMSFNCGraphics; var ARect,
  ATextRect: TRectF; var ADrawText, ADrawBackGround, ADrawBorder, AllowDraw: Boolean);
begin
  if Assigned(OnBeforeDraw) then
    OnBeforeDraw(Self, AGraphics, ARect, ATextRect, ADrawText, ADrawBackGround, ADrawBorder, AllowDraw);
end;

procedure TTMSFNCGridCell.DoControlClick(Sender: TObject);
begin
  if Assigned(OnControlClick) then
    OnControlClick(Self);
end;

procedure TTMSFNCGridCell.Draw(AGraphics: TTMSFNCGraphics; ADrawText: Boolean; ADrawBackGround: Boolean; ADrawBorder: Boolean);
var
  r, rt: TRectF;
  s: string;
  AllowDraw: Boolean;
  st: TTMSFNCGraphicsSaveState;
begin
  r := RectF(Left, Top, Left + Width, Top + Height);
  rt := GetTextRect(True);

  AllowDraw := True;
  AGraphics.SetFill(Layout.Fill);
  AGraphics.SetStroke(Layout.Stroke);
  AGraphics.SetFont(Layout.Font);

//  if (Layout.Stroke.Kind <> gskNone) then
//  begin
//    r.Right := r.Right - (Layout.Stroke.Width {$IFDEF FMXLIB}- 1{$ENDIF});
//    r.Bottom := r.Bottom - (Layout.Stroke.Width {$IFDEF FMXLIB}- 1{$ENDIF});
//  end;

  DoBeforeDraw(AGraphics, r, rt, ADrawText, ADrawBackGround, ADrawBorder, AllowDraw);
  if AllowDraw then
  begin
    if not ADrawBackGround then
    begin
      AGraphics.Fill.Kind := gfkNone;
      AGraphics.Fill.Color := gcNull;
    end;

    if not ADrawBorder then
    begin
      AGraphics.Stroke.Kind := gskNone;
      AGraphics.Stroke.Color := gcNull;
    end;

    if ADrawBackGround or ADrawBorder then
      AGraphics.DrawRectangle(r, Layout.Sides);

    s := Text;
    if (s <> '') and ADrawText then
    begin
      AGraphics.SetFont(Layout.Font);

      st := nil;
      if Angle <> 0 then
        st := AGraphics.SaveState;

      try
        if Angle <> 0 then
          AGraphics.ClipRect(rt);

        AGraphics.DrawText(rt, s, Layout.WordWrapping, Layout.TextAlign, Layout.VerticalTextAlign, gttNone, Angle, -1, -1, not DisplayHTMLFormatting);
      finally
        if Angle <> 0 then
          AGraphics.RestoreState(st);
      end;
    end;

    DrawControl(AGraphics);

    DoAfterDraw(AGraphics, r, rt);
  end;
end;

procedure TTMSFNCGridCell.DrawControl(AGraphics: TTMSFNCGraphics);
var
  rc: TRectF;
  c: TTMSFNCBitmapHelperClass;
begin
  c := ControlBitmap;
  if Assigned(c) then
  begin
    rc := GetControlRect(True);
    rc := RectF(Round(rc.Left), Round(rc.Top), Round(rc.Right), Round(rc.Bottom));
    AGraphics.DrawBitmap(rc, c);
  end;
end;

function TTMSFNCGridCell.GetControlBitmap: TTMSFNCBitmapHelperClass;
begin
  Result := FControlBitmap;
end;

function TTMSFNCGridCell.GetControlHeight: Single;
var
  c: TTMSFNCBitmapHelperClass;
begin
  Result := 0;
  c := ControlBitmap;
  if Assigned(c) then
    Result := c.Height;
end;

function TTMSFNCGridCell.GetControlRect(AAbsolute: Boolean = False): TRectF;
var
  r: TRectF;
  c: TTMSFNCBitmapHelperClass;
  cw, ch: Single;
begin
  Result := RectF(0, 0, 0, 0);
  c := ControlBitmap;
  if Assigned(c) and not IsBitmapEmpty(c) then
  begin
    if AAbsolute then
      r := RectF(Left, Top, Left + Width, Top + Height)
    else
      r := RectF(0, 0, Width, Height);

    if ControlStretched then
    begin
      Result := r;
      InflateRectEx(Result, -2 * ScaleFactor, -2 * ScaleFactor);
    end
    else
    begin
      cw := GetControlWidth * ScaleFactor;
      ch := GetControlHeight * ScaleFactor;

      case ControlPosition of
        gcpLeft: Result := RectF(r.Left + 2, r.Top + ((r.Bottom - r.Top) - ch) / 2, r.Left + cw + 2, r.Top + ((r.Bottom - r.Top) - ch) / 2 + ch);
        gcpTop: Result := RectF(r.Left + ((r.Right - r.Left) - cw) / 2, r.Top + 2, r.Left + ((r.Right - r.Left) - cw) / 2 + cw, r.Top + 2 + ch);
        gcpRight: Result := RectF(r.Right - cw - 2, r.Top + ((r.Bottom - r.Top) - ch) / 2, r.Right - 2, r.Top + ((r.Bottom - r.Top) - ch) / 2 + ch);
        gcpBottom: Result := RectF(r.Left + ((r.Right - r.Left) - cw) / 2, r.Bottom - 2 - ch, r.Left + ((r.Right - r.Left) - cw) / 2 + cw, r.Bottom - 2);
        gcpCenter: Result := RectF(r.Left + ((r.Right - r.Left) - cw) / 2, r.Top + ((r.Bottom - r.Top) - ch) / 2, r.Left + ((r.Right - r.Left) - cw) / 2 + cw, r.Top + ((r.Bottom - r.Top) - ch) / 2 + ch);
      end;
    end;
  end;
end;

function TTMSFNCGridCell.GetControlWidth: Single;
var
  c: TTMSFNCBitmapHelperClass;
begin
  Result := 0;
  c := ControlBitmap;
  if Assigned(c) then
    Result := c.Width;
end;

function TTMSFNCGridCell.GetRealTextRect(AAbsolute: Boolean): TRectF;
var
  g: TTMSFNCGraphics;
  r, tr: TRectF;
  s: string;
begin
  Result := EmptyRect;
  r := GetTextRect(AAbsolute);
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  try
    g.Context.BeginScene;
    g.SetFont(Layout.Font);
    s := Text;
    if (s = '') then
      s := 'gh';

    tr := g.CalculateText(s, r, Layout.WordWrapping, not DisplayHTMLFormatting);
    tr.Left := r.Left;
    tr.Right := r.Right;

    case Layout.TextAlign of
      gtaCenter:
      begin
        case Layout.VerticalTextAlign of
          gtaCenter: tr := RectF(r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2, r.Top + ((r.Bottom - r.Top) - (tr.Bottom - tr.Top)) / 2, r.Left  + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2 + (tr.Right - tr.Left), r.Top + ((r.Bottom - r.Top) - (tr.Bottom - tr.Top)) / 2 + (tr.Bottom - tr.Top));
          gtaLeading: tr := RectF(r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2, r.Top, r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2 + (tr.Right - tr.Left), r.Top + (tr.Bottom - tr.Top));
          gtaTrailing: tr := RectF(r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2, r.Bottom - (tr.Bottom - tr.Top), r.Left + ((r.Right - r.Left) - (tr.Right - tr.Left)) / 2 + (tr.Right - tr.Left), r.Bottom);
        end;
      end;
      gtaLeading:
      begin
        case Layout.VerticalTextAlign of
          gtaCenter: tr := RectF(r.Left, r.Top + ((r.Bottom - r.Top) - (tr.Bottom - tr.Top)) / 2, r.Left + (tr.Right - tr.Left), r.Top + ((r.Bottom - r.Top) - (tr.Bottom - tr.Top)) / 2 + (tr.Bottom - tr.Top));
          gtaLeading: tr := RectF(r.Left, r.Top, r.Left + (tr.Right - tr.Left), r.Top + (tr.Bottom - tr.Top));
          gtaTrailing: tr := RectF(r.Left, r.Bottom - (tr.Bottom - tr.Top), r.Left + (tr.Right - tr.Left), r.Bottom);
        end;
      end;
      gtaTrailing:
      begin
        case Layout.VerticalTextAlign of
          gtaCenter: tr := RectF(r.Right - (tr.Right - tr.Left), r.Top + ((r.Bottom - r.Top) - (tr.Bottom - tr.Top)) / 2, r.Right, r.Top + ((r.Bottom - r.Top) - (tr.Bottom - tr.Top)) / 2 + (tr.Bottom - tr.Top));
          gtaLeading: tr := RectF(r.Right - (tr.Right - tr.Left), r.Top, r.Right, r.Top + (tr.Bottom - tr.Top));
          gtaTrailing: tr := RectF(r.Right - (tr.Right - tr.Left), r.Bottom - (tr.Bottom - tr.Top), r.Right, r.Bottom);
        end;
      end;
    end;

    Result := tr;

    Result.Top := Max(r.Top, Result.Top);
    Result.Left := Max(r.Left, Result.Left);
    Result.Right := r.Right;
    Result.Bottom := r.Bottom;

    g.Context.EndScene;
  finally
    g.Free;
  end;
end;

function TTMSFNCGridCell.GetStrippedHTMLText: String;
var
  str: string;
begin
  str := Text;
  if IsHTML then
    Result := TTMSFNCUtils.HTMLStrip(str)
  else
    Result := str;
end;

function TTMSFNCGridCell.GetTextHeight: Single;
var
  str: String;
  g: TTMSFNCGraphics;
  r, txtr: TRectF;
begin
  str := Text;
  Result := 0;
  if str <> '' then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.BitmapContainer := BitmapContainer;
    try
      g.SetFont(Layout.Font);
      r := GetTextRect;
      txtr := g.CalculateText(str, r, Layout.WordWrapping);
      if Abs(Angle) = 0 then
        Result := txtr.Bottom - txtr.Top
      else
        Result := txtr.Right - txtr.Left;
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

function TTMSFNCGridCell.GetTextRect(AAbsolute: Boolean = False): TRectF;
var
  r, rct: TRectF;
  c: TTMSFNCBitmapHelperClass;
  res: TRectF;
begin
  if AAbsolute then
    r := RectF(Left, Top, Left + Width, Top + Height)
  else
    r := RectF(0, 0, Width, Height);

  rct := GetControlRect(False);
  c := ControlBitmap;

  if not ControlStretched and Assigned(c) and not IsBitmapEmpty(c) then
  begin
    case ControlPosition of
      gcpLeft: res := RectF(r.Left + (rct.Right - rct.Left) + 2, r.Top, r.Right, r.Bottom);
      gcpTop: res := RectF(r.Left, r.Top + (rct.Bottom - rct.Top) + 2, r.Right, r.Bottom);
      gcpRight: res := RectF(r.Left, r.Top, r.Right - (rct.Right - rct.Left) - 2, r.Bottom);
      gcpBottom: res := RectF(r.Left, r.Top, r.Right, r.Bottom - (rct.Bottom - rct.Top) - 2);
      else
        res := r;
    end;
  end
  else
    res := r;

  {$IFDEF LCLLIB}
  InflateRectEx(res, -4, -2);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  InflateRectEx(res, -3, -3);
  {$ENDIF}

  Result := res;
end;

function TTMSFNCGridCell.GetTextWidth: Single;
var
  str: String;
  g: TTMSFNCGraphics;
  r, txtr: TRectF;
begin
  str := Text;
  Result := 0;
  if str <> '' then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.BitmapContainer := BitmapContainer;
    try
      g.SetFont(Layout.Font);
      r := GetTextRect;
      txtr := g.CalculateText(str, r, Layout.WordWrapping);
      if Abs(Angle) = 90 then
        Result := txtr.Bottom - txtr.Top
      else
        Result := txtr.Right - txtr.Left;
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

procedure TTMSFNCGridCell.HandleControlMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin

end;

procedure TTMSFNCGridCell.HandleControlMouseMove(Shift: TShiftstate; X,
  Y: Single; var ACursor: TCursor);
begin

end;

procedure TTMSFNCGridCell.HandleControlMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  DoControlClick(Self);
end;

procedure TTMSFNCGridCell.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single; var AHandled: Boolean);
var
  ctlr: TRectF;
begin
  if ControlEnabled then
  begin
    ctlr := GetControlRect(True);
    AHandled := PtInRectEx(ctlr, PointF(X, Y));
    if AHandled then
      HandleControlMouseDown(Button, Shift, X, Y);
  end;
end;

procedure TTMSFNCGridCell.HandleMouseMove(Shift: TShiftState; X, Y: Single;
  var AHandled: Boolean; var ACursor: TCursor);
var
  ctlr: TRectF;
begin
  if ControlEnabled then
  begin
    ctlr := GetControlRect(True);
    AHandled := PtInRectEx(ctlr, PointF(X, Y));
    if AHandled then
      HandleControlMouseMove(Shift, X, Y, ACursor);
  end;
end;

procedure TTMSFNCGridCell.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single; var AHandled: Boolean);
var
  ctlr: TRectF;
begin
  if ControlEnabled then
  begin
    ctlr := GetControlRect(True);
    AHandled := PtInRectEx(ctlr, PointF(X, Y));
    if AHandled then
      HandleControlMouseUp(Button, Shift, X, Y);
  end;
end;

function TTMSFNCGridCell.IsHTML: boolean;
begin
  if DisplayHTMLFormatting then
    Result := False
  else
    Result := (Pos('</', Text) > 0) or (Pos('/>', Text)  > 0) or (Pos('<BR>', UpperCase(Text)) > 0);
end;

procedure TTMSFNCGridCell.SetAngle(const Value: Integer);
begin
  if FAngle <> Value then
  begin
    FAngle := Value;
  end;
end;

procedure TTMSFNCGridCell.SetControlBitmap(
  const Value: TTMSFNCBitmapHelperClass);
begin
  if FControlBitmap <> Value then
  begin
    FControlBitmap := Value;
  end;
end;

procedure TTMSFNCGridCell.SetControlEnabled(const Value: Boolean);
begin
  if FControlEnabled <> Value then
  begin
    FControlEnabled := Value;
  end;
end;

procedure TTMSFNCGridCell.SetDisplayHTMLFormatting(const Value: Boolean);
begin
  if FDisplayHTMLFormatting <> Value then
  begin
    FDisplayHTMLFormatting := Value;
  end;
end;

procedure TTMSFNCGridCell.SetHeight(const Value: single);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
  end;
end;

procedure TTMSFNCGridCell.SetLayout(const Value: TTMSFNCGridCellLayout);
begin
  FLayout.Assign(Value);
end;

procedure TTMSFNCGridCell.SetLeft(const Value: single);
begin
  if FLeft <> Value then
  begin
    FLeft := Value;
  end;
end;

procedure TTMSFNCGridCell.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
  end;
end;

procedure TTMSFNCGridCell.SetTop(const Value: single);
begin
  if FTop <> Value then
  begin
    FTop := Value;
  end;
end;

procedure TTMSFNCGridCell.SetWidth(const Value: single);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
  end;
end;

function TTMSFNCGridCell.XYToAnchor(X, Y: Single): string;
var
  g: TTMSFNCGraphics;
  ha, va: TTMSFNCGraphicsTextAlign;
begin
  Result := '';
  if IsHTML then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.BitmapContainer := BitmapContainer;
    try
      g.Font.Assign(FLayout.Font);
      ha := Layout.TextAlign;
      va := Layout.VerticalTextAlign;
      Result := g.DrawText(GetTextRect(True), Text, False, ha, va, gttNone, 0, -1, -1, True, True, X, Y);
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

{ TTMSFNCBaseCheckGridCell }

procedure TTMSFNCBaseCheckGridCell.DoCheckChanged(Sender: TObject);
begin
  if Assigned(OnCheckChanged) then
    OnCheckChanged(Self);
end;

function TTMSFNCBaseCheckGridCell.GetControlHeight: Single;
begin
  {$IFDEF FMXMOBILE}
  Result := 25;
  {$ELSE}
  {$IFDEF CMNWEBLIB}
  {$IFNDEF WEBLIB}
  Result := 17 * TTMSFNCUtils.GetDPIScale;
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := 19;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  Result := 19;
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCBaseCheckGridCell.GetControlWidth: Single;
begin
  {$IFDEF FMXMOBILE}
  Result := 25;
  {$ELSE}
  {$IFDEF CMNWEBLIB}
  {$IFNDEF WEBLIB}
  Result := 17 * TTMSFNCUtils.GetDPIScale;
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := 19;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  Result := 19;
  {$ENDIF}
  {$ENDIF}
end;

procedure TTMSFNCBaseCheckGridCell.HandleControlMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  Checked := not Checked;
end;

procedure TTMSFNCBaseCheckGridCell.SetChecked(const Value: Boolean);
begin
  if FChecked <> Value then
  begin
    FChecked := Value;
    DoCheckChanged(Self);
  end;
end;

{ TTMSFNCBitmapGridCell }

constructor TTMSFNCBitmapGridCell.Create(AOwner: TComponent);
begin
  inherited;
  FBitmap := TTMSFNCBitmap.Create;
  ControlEnabled := False;
end;

destructor TTMSFNCBitmapGridCell.Destroy;
begin
  FBitmap.Free;
  inherited;
end;

function TTMSFNCBitmapGridCell.GetControlBitmap: TTMSFNCBitmapHelperClass;
begin
  Result := nil;
  if Assigned(Bitmap) and not IsBitmapEmpty(Bitmap) then
    Result := Bitmap
  else if Assigned(BitmapContainer) and (BitmapName <> '') then
    Result := BitmapContainer.FindBitmap(BitmapName);
end;

procedure TTMSFNCBitmapGridCell.SetBitmap(const Value: TTMSFNCBitmap);
begin
  if FBitmap <> Value then
  begin
    FBitmap.Assign(Value);
  end;
end;

procedure TTMSFNCBitmapGridCell.SetBitmapName(const Value: string);
begin
  if FBitmapName <> Value then
  begin
    FBitmapName := Value;
  end;
end;

{ TTMSFNCProgressGridCell }

constructor TTMSFNCProgressGridCell.Create(AOwner: TComponent);
begin
  inherited;
  FValue := 0;
  FColor := gcYellowgreen;
  FTextColor := gcBlack;
  FShowText := True;
  FFormat := '%.0f%%';
end;

procedure TTMSFNCProgressGridCell.Draw(AGraphics: TTMSFNCGraphics; ADrawText,
  ADrawBackGround, ADrawBorder: Boolean);
var
  r: TRectF;
begin
  inherited;
  if ADrawBackGround then
  begin
    r := RectF(Left + 4, Top + 4, Left + Width - 4, Top + Height - 4);
    AGraphics.DrawProgressBar(r, Value, Format, 100, Color, TextColor, ShowText);
  end;
end;

procedure TTMSFNCProgressGridCell.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  FColor := Value;
end;

procedure TTMSFNCProgressGridCell.SetFormat(const Value: string);
begin
  FFormat := Value;
end;

procedure TTMSFNCProgressGridCell.SetShowText(const Value: Boolean);
begin
  FShowText := Value;
end;

procedure TTMSFNCProgressGridCell.SetTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  FTextColor := Value;
end;

procedure TTMSFNCProgressGridCell.SetValue(const Value: Single);
begin
  FValue := Value;
end;

{ TTMSFNCCommentGridCell }

constructor TTMSFNCCommentGridCell.Create(AOwner: TComponent);
begin
  inherited;
  FCommentColor := gcRed;
  FCommentTextColor := gcBlack;
end;

procedure TTMSFNCCommentGridCell.Draw(AGraphics: TTMSFNCGraphics; ADrawText,
  ADrawBackGround, ADrawBorder: Boolean);
var
  pth: TTMSFNCGraphicsPath;
  r: TRectF;
begin
  inherited;
  r := RectF(Left, Top, Left + Width, Top + Height);
  InflateRectEx(r, -3, -3);
  AGraphics.Fill.Kind := gfkSolid;
  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.Fill.Color := CommentColor;
  AGraphics.Stroke.Color := CommentColor;
  pth := TTMSFNCGraphicsPath.Create;
  try
    pth.MoveTo(PointF(r.Right - 7, r.Top));
    pth.LineTo(PointF(r.Right, r.Top));
    pth.LineTo(PointF(r.Right, r.Top + 7));
    pth.ClosePath;
    AGraphics.DrawPath(pth);
  finally
    pth.Free;
  end;
end;

procedure TTMSFNCCommentGridCell.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single; var AHandled: Boolean);
begin
  inherited;
  AHandled := PtInComment(X, Y);
end;

procedure TTMSFNCCommentGridCell.HandleMouseMove(Shift: TShiftState; X,
  Y: Single; var AHandled: Boolean; var ACursor: TCursor);
begin
  inherited;
  AHandled := PtInComment(X, Y);
  if AHandled then
    ACursor := crHandPoint;
end;

procedure TTMSFNCCommentGridCell.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single; var AHandled: Boolean);
begin
  inherited;
  AHandled := PtInComment(X, Y);
  if AHandled then
    DoControlClick(Self);
end;

function TTMSFNCCommentGridCell.PtInComment(X, Y: Single): Boolean;
var
  pth: TTMSFNCGraphicsPath;
  r: TRectF;
  g: TTMSFNCGraphics;
begin
  r := RectF(Left, Top, Left + Width, Top + Height);
  InflateRectEx(r, -3, -3);
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
  g.BitmapContainer := BitmapContainer;
  pth := TTMSFNCGraphicsPath.Create;
  try
    pth.MoveTo(PointF(r.Right - 7, r.Top));
    pth.LineTo(PointF(r.Right, r.Top));
    pth.LineTo(PointF(r.Right, r.Top + 7));
    pth.ClosePath;
    Result := g.PointInPath(PointF(X, Y), pth);
  finally
    pth.Free;
    g.EndScene;
    g.Free;
  end;
end;

{ TTMSFNCNodeGridCell }

procedure TTMSFNCNodeGridCell.HandleControlMouseMove(Shift: TShiftState; X,
  Y: Single; var ACursor: TCursor);
begin
  inherited;
  ACursor := crHandPoint;
end;

{ TTMSFNCFixedGridCell }

constructor TTMSFNCFixedGridCell.Create(AOwner: TComponent);
begin
  inherited;
  FSizeMargin := 0;
end;

procedure TTMSFNCFixedGridCell.Draw(AGraphics: TTMSFNCGraphics; ADrawText,
  ADrawBackGround, ADrawBorder: Boolean);
var
  r, dr, sr: TRectF;
  sz, szr: Single;
  mgr: Single;
begin
  inherited;
  r := RectF(Left, Top, Left + Width, Top + Height);
  sr := r;

  mgr := (SizeMargin + 2) * ScaleFactor;

  if ShowDropDownButton then
  begin
    sz := 15 * ScaleFactor;
    dr := RectF(r.Right - sz - mgr, r.Top + ((r.Bottom - r.Top) - sz) / 2, r.Right - mgr, r.Top + ((r.Bottom - r.Top) - sz) / 2 + sz);
    AGraphics.DrawDropDownButton(RectF(Int(dr.Left), Int(dr.Top), Int(dr.Right), Int(dr.Bottom)), False, False, True, True, False);
    sr.Right := dr.Left;
  end;

  if SortKind <> skNone then
  begin
    szr := 12 * ScaleFactor;
    sr := RectF(sr.Right - szr - 3 * ScaleFactor - mgr, sr.Top + ((sr.Bottom - sr.Top) - szr) / 2, sr.Right - 3 * ScaleFactor - mgr, sr.Top + ((sr.Bottom - sr.Top) - szr) / 2 + szr);
    DrawSortIndicator(AGraphics, sr);
  end;
end;

procedure TTMSFNCFixedGridCell.DrawSortIndicator(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  pth: TTMSFNCGraphicsPath;
  vertt: TTMSFNCGraphicsTextAlign;
  c: TTMSFNCGraphicsColor;
  txtr: TRectF;
begin
  inherited;
  if SortIndex = -1 then
    c := gcSteelblue
  else
    c := gcOrangered;

  AGraphics.Fill.Kind := gfkSolid;
  AGraphics.Fill.Color := c;
  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.Stroke.Color := c;

  vertt := gtaCenter;
  txtr := ARect;
  pth := TTMSFNCGraphicsPath.Create;
  try
    case SortKind of
      skAscending:
      begin
        vertt := gtaTrailing;
        pth.MoveTo(PointF(ARect.Left + (ARect.Right - ARect.Left) / 2, ARect.Top));
        pth.LineTo(PointF(ARect.Right, ARect.Bottom));
        pth.LineTo(PointF(ARect.Left, ARect.Bottom));
        txtr := RectF(ARect.Left, ARect.Top + 2, ARect.Right, ARect.Bottom + 2);
      end;
      skDescending:
      begin
        vertt := gtaLeading;
        pth.MoveTo(PointF(ARect.Left, ARect.Top));
        pth.LineTo(PointF(ARect.Right, ARect.Top));
        pth.LineTo(PointF(ARect.Left + (ARect.Right - ARect.Left) / 2, ARect.Bottom));
        txtr := RectF(ARect.Left, ARect.Top - 2, ARect.Right, ARect.Bottom - 2);
      end;
    end;
    pth.ClosePath;
    AGraphics.DrawPath(pth);

    if SortIndex <> -1 then
    begin
      AGraphics.Font.Color := gcWhite;
      TTMSFNCUtils.SetFontSize(AGraphics.Font, 9);
      AGraphics.DrawText(txtr, IntToStr(SortIndex), False, gtaCenter, vertt);
    end;
  finally
    pth.Free;
  end;
end;

procedure TTMSFNCFixedGridCell.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single; var AHandled: Boolean);
begin
  inherited;
  AHandled := PtInDropDown(X, Y);
end;

procedure TTMSFNCFixedGridCell.HandleMouseMove(Shift: TShiftState; X, Y: Single;
  var AHandled: Boolean; var ACursor: TCursor);
begin
  inherited;
  AHandled := PtInDropDown(X, Y);
end;

procedure TTMSFNCFixedGridCell.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single; var AHandled: Boolean);
begin
  inherited;
  AHandled := PtInDropDown(X, Y);
  if AHandled then
    DoControlClick(Self);
end;

function TTMSFNCFixedGridCell.PtInDropDown(X, Y: Single): Boolean;
var
  r: TRectF;
  mgr, sz: Single;
begin
  Result := False;
  if not ShowDropDownButton then
    Exit;

  mgr := (SizeMargin + 2) * ScaleFactor;
  sz := 15 * ScaleFactor;
  r := RectF(Left + Width - sz - mgr, Top + (Height - sz) / 2, Left + Width - mgr, Top + (Height - sz) / 2 + sz);
  Result := PtInRectEx(r, PointF(X, Y));
end;

{ TTMSFNCGridCellProgressLayout }

procedure TTMSFNCGridCellProgressLayout.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridCellProgressLayout) then
  begin
    FColor := (Source as TTMSFNCGridCellProgressLayout).Color;
    FTextColor := (Source as TTMSFNCGridCellProgressLayout).TextColor;
    FFormat := (Source as TTMSFNCGridCellProgressLayout).Format;
    FShowText := (Source as TTMSFNCGridCellProgressLayout).ShowText;
  end;
end;

procedure TTMSFNCGridCellProgressLayout.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGridCellProgressLayout.Create;
begin
  FTextColor := gcBlack;
  FColor := gcYellowgreen;
  FFormat := '%.0f%%';
  FShowText := True;
end;

procedure TTMSFNCGridCellProgressLayout.SetColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellProgressLayout.SetFormat(const Value: String);
begin
  if FFormat <> Value then
  begin
    FFormat := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellProgressLayout.SetShowText(const Value: Boolean);
begin
  if FShowText <> Value then
  begin
    FShowText := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridCellProgressLayout.SetTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FTextColor <> Value then
  begin
    FTextColor := Value;
    Changed;
  end;
end;

{ TTMSFNCButtonGridCell }

constructor TTMSFNCButtonGridCell.Create(AOwner: TComponent);
begin
  inherited;
  FButtonWidth := 24;
  FButtonHeight := 20;
  FButtonDown := False;
end;

procedure TTMSFNCButtonGridCell.DrawControl(AGraphics: TTMSFNCGraphics);
var
  rc: TRectF;
begin
  rc := GetControlRect(True);
  rc := RectF(Round(rc.Left), Round(rc.Top), Round(rc.Right), Round(rc.Bottom));
  AGraphics.DrawButton(rc, False, FButtonDown, ControlEnabled);
  if ButtonText <> '' then
  begin
    InflateRectEx(rc, -2, -2);
    AGraphics.Font.Color := gcBlack;
    AGraphics.DrawText(rc, ButtonText, False, gtaCenter, gtaCenter, gttWord);
  end;
end;

function TTMSFNCButtonGridCell.GetControlHeight: Single;
begin
  Result := ButtonHeight;
end;

function TTMSFNCButtonGridCell.GetControlWidth: Single;
begin
  Result := ButtonWidth;
end;

procedure TTMSFNCButtonGridCell.HandleControlMouseDown(
  Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  inherited;
  FButtonDown := True;
end;

procedure TTMSFNCButtonGridCell.HandleControlMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  FButtonDown := False;
end;

procedure TTMSFNCButtonGridCell.SetButtonHeight(const Value: Integer);
begin
  if FButtonHeight <> Value then
    FButtonHeight := Value;
end;

procedure TTMSFNCButtonGridCell.SetButtonText(const Value: String);
begin
  if FButtonText <> Value then
    FButtonText := Value;
end;

procedure TTMSFNCButtonGridCell.SetButtonWidth(const Value: Integer);
begin
  if FButtonWidth <> Value then
    FButtonWidth := Value;
end;

end.


unit uAssetTypePicture;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCPanel, WEBLib.Buttons, WEBLIB.REST, WEBLIB.JSON, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, Data.DB, WEBLib.DB, WEBLib.CDS,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCCustomScrollControl,
  VCL.TMSFNCGridData, VCL.TMSFNCGrid;

type
  TAssetTypePictureForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SelectButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    MyGrid: TTMSFNCGrid;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    WebClientConnection1: TClientConnection;
    MyDatasetpkey: TStringField;
    MyDatasetpicture_blob: TStringField;
    DatasetTimer: TTimer;
    procedure CloseImageClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure SelectButtonClick(Sender: TObject);
    procedure DatasetTimerTimer(Sender: TObject);
    procedure MyDatasetAfterScroll(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    [async]
    procedure LoadList(); async;
  public
    PKey: Int64;
    Base64: String;
  protected procedure LoadDFMValues; override; end;

var
  AssetTypePictureForm: TAssetTypePictureForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetTypePictureForm.WebFormCreate(Sender: TObject);
begin
  SetGridStyle(MyGrid, False);
end;

procedure TAssetTypePictureForm.WebFormShow(Sender: TObject);
begin
  LoadList();
end;

procedure TAssetTypePictureForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  if not(MyDataset.IsEmpty) then begin
    MyDataset.First;
  end else begin
    MyDatasetAfterScroll(MyDataset);
  end;
end;

procedure TAssetTypePictureForm.LoadList;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_type_picture_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TAssetTypePictureForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  DatasetTimer.Enabled := True;
end;

procedure TAssetTypePictureForm.MyDatasetAfterScroll(DataSet: TDataSet);
begin
  GridDatabaseAdapter.Active := True;
end;

procedure TAssetTypePictureForm.SelectButtonClick(Sender: TObject);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    PKey := MyDataset.FieldByName('pkey').AsLargeInt;
    Base64 := MyDataset.FieldByName('picture_blob').AsString;
    ModalResult := mrOK;
  end;
end;

procedure TAssetTypePictureForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TAssetTypePictureForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetpkey := TStringField.Create(Self);
  MyDatasetpicture_blob := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);
  DatasetTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetpkey.BeforeLoadDFMValues;
  MyDatasetpicture_blob.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  try
    Name := 'AssetTypePictureForm';
    Width := 549;
    Height := 543;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 549;
    FormPanel.Height := 543;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 543;
    ContentPanel.Height := 500;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 451;
    StatusBarPanel.Width := 543;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 423;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetTypePicture.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uAssetTypePicture.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 1;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 128;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 2;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 0;
    MyGrid.Width := 503;
    MyGrid.Height := 441;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 1;
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.RowCount := 1;
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 0;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smSingleRow;
    MyGrid.Adapter := GridDatabaseAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 68.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 0;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 549;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 94;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Select Picture';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 520;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetTypePicture.CaptionPanel.CloseImage.Picture.png');
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 181;
    GridDatabaseAdapter.Top := 195;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := MyGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'picture_blob';
      Header := '  Picture';
      PictureField := True;
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.AfterScroll := MyDatasetAfterScroll;
    MyDataset.Left := 244;
    MyDataset.Top := 192;
    MyDatasetpkey.SetParentComponent(MyDataset);
    MyDatasetpkey.Name := 'MyDatasetpkey';
    MyDatasetpkey.FieldName := 'pkey';
    MyDatasetpkey.Visible := False;
    MyDatasetpkey.Size := 256;
    MyDatasetpicture_blob.SetParentComponent(MyDataset);
    MyDatasetpicture_blob.Name := 'MyDatasetpicture_blob';
    MyDatasetpicture_blob.FieldName := 'picture_blob';
    MyDatasetpicture_blob.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 340;
    MyDataSource.Top := 192;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.URI := 'https://instatrack.eu:44321/php/default_asset_type_picture_list.php?sessionid=9d8ddb27f2c59c2591a4b865e28dace2';
    WebClientConnection1.Left := 343;
    WebClientConnection1.Top := 253;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 243;
    DatasetTimer.Top := 252;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetpkey.AfterLoadDFMValues;
    MyDatasetpicture_blob.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
  end;
end;

end.

unit uSMS;

interface

uses
  System.SysUtils, System.Classes, JS, Web,
  WEBLib.Graphics, WEBLib.Controls, WEBLib.REST, WEBLib.JSON,
  WEBLib.RegularExpressions, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons;

type
  TSMSForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    PhoneNumberLabel: TLabel;
    RecipientsEdit: TEdit;
    WebLabel1: TLabel;
    MessageEdit: TMemo;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    MyMessageDlg: TMessageDlg;
    procedure CloseButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    [async]
    procedure CreateMessage(); async;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SMSForm: TSMSForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TSMSForm.CreateMessage;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  if (RecipientsEdit.Text <> EmptyStr) and TRegEx.IsMatch(RecipientsEdit.Text, RegExNumeric) then begin
    if Trim(MessageEdit.Text) <> EmptyStr then begin
      MyString :=
        '{' +
          '"message_type":"SMS",' +
          '"recipients":"' + window.btoa('{' + RecipientsEdit.Text + '}') + '",' +
          '"body":"' + Window.btoa(MessageEdit.Text) + '"' +
        '}';
      // Execute Request
      MyWebRequest := THTTPRequest.Create(Self);
      try
        // Request
        MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
        MyWebRequest.URL := url_php + 'create_sms_message.php';
        MyWebRequest.Command := THTTPCommand.httpPOST;
        MyWebRequest.PostData := MyString;
        MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        // Response
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            ModalResult := mrOK;
          end else begin
            MyMessageDlg.ShowDialog('Failed to register SMS.', WEBLib.Dialogs.mtError, [mbOK]);
          end;
        finally
          MyJSON.Free;
        end
      finally
        MyWebRequest.Free;
      end;
    end else begin
      MyMessageDlg.ShowDialog('Message is missing.', WEBLib.Dialogs.mtError, [mbOK]);
    end;
  end else begin
    MyMessageDlg.ShowDialog('Invalid phone number.', WEBLib.Dialogs.mtError, [mbOK]);
  end;
end;

procedure TSMSForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TSMSForm.SaveButtonClick(Sender: TObject);
begin
  CreateMessage();
end;

procedure TSMSForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  PhoneNumberLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  RecipientsEdit := TEdit.Create(Self);
  MessageEdit := TMemo.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  PhoneNumberLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  RecipientsEdit.BeforeLoadDFMValues;
  MessageEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  try
    Name := 'SMSForm';
    Width := 502;
    Height := 402;
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 502;
    FormPanel.Height := 402;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 496;
    ContentPanel.Height := 359;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    PhoneNumberLabel.SetParentComponent(ContentPanel);
    PhoneNumberLabel.Name := 'PhoneNumberLabel';
    PhoneNumberLabel.Left := 19;
    PhoneNumberLabel.Top := 21;
    PhoneNumberLabel.Width := 120;
    PhoneNumberLabel.Height := 21;
    PhoneNumberLabel.Caption := 'PHONE NUMBER';
    PhoneNumberLabel.Font.Charset := ANSI_CHARSET;
    PhoneNumberLabel.Font.Color := 9010544;
    PhoneNumberLabel.Font.Height := -16;
    PhoneNumberLabel.Font.Name := 'Segoe UI';
    PhoneNumberLabel.Font.Style := [];
    PhoneNumberLabel.HeightPercent := 100.000000000000000000;
    PhoneNumberLabel.ParentFont := False;
    PhoneNumberLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 19;
    WebLabel1.Top := 101;
    WebLabel1.Width := 69;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'MESSAGE';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    RecipientsEdit.SetParentComponent(ContentPanel);
    RecipientsEdit.Name := 'RecipientsEdit';
    RecipientsEdit.Left := 19;
    RecipientsEdit.Top := 48;
    RecipientsEdit.Width := 457;
    RecipientsEdit.Height := 34;
    RecipientsEdit.ChildOrder := 1;
    RecipientsEdit.EditType := weNumeric;
    RecipientsEdit.ElementClassName := 'MyWebEdit';
    RecipientsEdit.Font.Charset := ANSI_CHARSET;
    RecipientsEdit.Font.Color := clWindowText;
    RecipientsEdit.Font.Height := -16;
    RecipientsEdit.Font.Name := 'Segoe UI';
    RecipientsEdit.Font.Style := [];
    RecipientsEdit.HeightPercent := 100.000000000000000000;
    RecipientsEdit.ParentFont := False;
    RecipientsEdit.WidthPercent := 100.000000000000000000;
    MessageEdit.SetParentComponent(ContentPanel);
    MessageEdit.Name := 'MessageEdit';
    MessageEdit.Left := 19;
    MessageEdit.Top := 128;
    MessageEdit.Width := 457;
    MessageEdit.Height := 169;
    MessageEdit.AutoSize := False;
    MessageEdit.ElementClassName := 'MyWebEdit';
    MessageEdit.HeightPercent := 100.000000000000000000;
    MessageEdit.SelLength := 0;
    MessageEdit.SelStart := 0;
    MessageEdit.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 310;
    StatusBarPanel.Width := 496;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 376;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uSMS.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 261;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uSMS.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    MyMessageDlg.SetParentComponent(ContentPanel);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 48;
    MyMessageDlg.Top := 152;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 502;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 30;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'SMS';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 473;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseButtonClick');
    CloseImage.Picture.LoadFromFile('uSMS.CaptionPanel.CloseImage.Picture.png');
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    PhoneNumberLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    RecipientsEdit.AfterLoadDFMValues;
    MessageEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
  end;
end;

end.
{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2023                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGoogleRoutes;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 23}
{$DEFINE UNREGISTER}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE UNREGISTER}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Forms
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs
  {$ENDIF}
  {$IFDEF CMNLIB}
  {$ENDIF}
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCCloudBase
  ,WEBLib.TMSFNCDirections;

const
  LB = #13;
  TTMSFNCGoogleRoutesDocURL = TTMSFNCBaseDocURL + 'tmsfncmaps/components/ttmsfncmaps/#ttmsfncdirections';
  TTMSFNCGoogleRoutesTipsURL = 'https://www.tmssoftware.com/site/tmsfncmaps.asp?s=faq';

  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release


type
  TTMSFNCGoogleRoutesRoutingMode = (rmTrafficUnaware, rmTrafficAware, rmTrafficAwareOptimal);
  TTMSFNCGoogleRoutesDeparture = (rdIgnore, rdNow, rdDateTime);

  TTMSFNCGoogleRoutes = class;

  TTMSFNCGoogleRoutesOptions = class(TPersistent)
  private
    FRoutingMode: TTMSFNCGoogleRoutesRoutingMode;
    FDeparture: TTMSFNCGoogleRoutesDeparture;
    FDepartureTime: TDateTime;
    FHTMLFormattedInstructions: Boolean;
    FIncludeFuelEfficientRoute: Boolean;
    procedure SetRoutingMode(const Value: TTMSFNCGoogleRoutesRoutingMode);
    procedure SetDeparture(const Value: TTMSFNCGoogleRoutesDeparture);
    procedure SetDepartureTime(const Value: TDateTime);
    procedure SetHTMLFormattedInstructions(const Value: Boolean);
    procedure SetIncludeFuelEfficientRoute(const Value: Boolean);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; virtual;
    destructor Destroy; override;
  published
    property RoutingMode: TTMSFNCGoogleRoutesRoutingMode read FRoutingMode write SetRoutingMode default rmTrafficUnaware;
    property Departure: TTMSFNCGoogleRoutesDeparture read FDeparture write SetDeparture default rdIgnore;
    property DepartureTime: TDateTime read FDepartureTime write SetDepartureTime;
    property HTMLFormattedInstructions: Boolean read FHTMLFormattedInstructions write SetHTMLFormattedInstructions default false;
    property IncludeFuelEfficientRoute: Boolean read FIncludeFuelEfficientRoute write SetIncludeFuelEfficientRoute default False;
  end;

  TTMSFNCSpeedReadingInterval = class(TCollectionItem)
  private
    FOwner: TPersistent;
    FDataBoolean: Boolean;
    FDataString: string;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FStartPolylinePointIndex: Integer;
    FSpeed: string;
    FEndPolylinePointIndex: Integer;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: string read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property StartPolylinePointIndex: Integer read FStartPolylinePointIndex write FStartPolylinePointIndex;
    property EndPolylinePointIndex: Integer read FEndPolylinePointIndex write FEndPolylinePointIndex;
    property Speed: string read FSpeed write FSpeed;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCSpeedReadingIntervals = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCSpeedReadingIntervals = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCSpeedReadingInterval>)
  {$ENDIF}
  private
    FOwner: TPersistent;
    function GetItem(Index: Integer): TTMSFNCSpeedReadingInterval;
    procedure SetItem(Index: Integer; const Value: TTMSFNCSpeedReadingInterval);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TPersistent); virtual;
    property Items[Index: Integer]: TTMSFNCSpeedReadingInterval read GetItem write SetItem; default;
    function Add: TTMSFNCSpeedReadingInterval;
    function Insert(Index: Integer): TTMSFNCSpeedReadingInterval;
  end;

  TTMSFNCGoogleRoutesTravelAdvisory = class(TPersistent)
  private
    FSpeedReadingIntervals: TTMSFNCSpeedReadingIntervals;
    FFuelConsumptionMicroliters: Integer;
    procedure SetSpeedReadingIntervals(
      const Value: TTMSFNCSpeedReadingIntervals);
    procedure SetFuelConsumptionMicroliters(const Value: Integer);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; virtual;
    destructor Destroy; override;
  published
    property SpeedReadingIntervals: TTMSFNCSpeedReadingIntervals read FSpeedReadingIntervals write SetSpeedReadingIntervals;
    property FuelConsumptionMicroliters: Integer read FFuelConsumptionMicroliters write SetFuelConsumptionMicroliters;
  end;

  TTMSFNCGoogleRoutesItem = class(TTMSFNCDirectionsItem)
  private
    FRouteLabels: TStringList;
    FTravelAdvisory: TTMSFNCGoogleRoutesTravelAdvisory;
    procedure SetRouteLabels(const Value: TStringList);
    procedure SetTravelAdivsory(const Value: TTMSFNCGoogleRoutesTravelAdvisory);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property RouteLabels: TStringList read FRouteLabels write SetRouteLabels;
    property TravelAdvisory: TTMSFNCGoogleRoutesTravelAdvisory read FTravelAdvisory write SetTravelAdivsory;
  end;

  TTMSFNCGoogleRoutesItems = class(TTMSFNCDirectionsItems)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleRoutesItem;
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCGoogleRoutesItem read GetItem; default;
  end;

  TTMSFNCGoogleRoutesRequest = class(TTMSFNCDirectionsRequest)
  private
    function GetItems: TTMSFNCGoogleRoutesItems;
  protected
    function GetDirectionsItemsClass: TTMSFNCDirectionsItemsClass; override;
  published
    property Items: TTMSFNCGoogleRoutesItems read GetItems;
  end;

  TTMSFNCGoogleRoutesRequests = class(TTMSFNCDirectionsRequests)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleRoutesRequest;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleRoutesRequest);
  protected
    function CreateItemClass: TCollectionItemClass; override;
    property Items[Index: Integer]: TTMSFNCGoogleRoutesRequest read GetItem write SetItem; default;
  end;

  TTMSFNCCustomGoogleRoutes = class(TTMSFNCCustomDirections)
  private
    FOptions: TTMSFNCGoogleRoutesOptions;
    procedure SetOptions(const Value: TTMSFNCGoogleRoutesOptions);
    function GetDirectionsRequests: TTMSFNCGoogleRoutesRequests;
    procedure SetDirectionsRequests(const Value: TTMSFNCGoogleRoutesRequests);
  protected
    function GetDirectionsRequestsClass: TTMSFNCDirectionsRequestsClass; override;
    function GetGoogleRoutesOptions(var AOptions: string; ATravelMode: TTMSFNCDirectionsTravelMode;
      AWayPoints: TTMSFNCMapsCoordinateRecArray; AWayPointsList: TStringList): string; override;
    property DirectionsRequests: TTMSFNCGoogleRoutesRequests read GetDirectionsRequests write SetDirectionsRequests;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property Options: TTMSFNCGoogleRoutesOptions read FOptions write SetOptions;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCGoogleRoutes = class(TTMSFNCCustomGoogleRoutes)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property DirectionsInstance;
    property DirectionsProperties;
  published
    property OnGetDirections;
    property OnGetDirectionsResult;
    property APIKey;
    property DirectionsRequests;
    property Version;
  end;

implementation

uses
  {$IFDEF MSWINDOWS}
  Windows, Registry,
  {$ENDIF}
  {%H-}Math,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCDirections.Google,
  SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  , DateUtils
  ;

{ TTMSFNCCustomGoogleRoutes }

constructor TTMSFNCCustomGoogleRoutes.Create(AOwner: TComponent);
begin
  inherited;
  IsGoogleRoutes := True;
  InitializeDirections;
  FOptions := TTMSFNCGoogleRoutesOptions.Create;
end;

destructor TTMSFNCCustomGoogleRoutes.Destroy;
begin
  FOptions.Free;
  inherited;
end;

function TTMSFNCCustomGoogleRoutes.GetDirectionsRequests: TTMSFNCGoogleRoutesRequests;
begin
  Result := TTMSFNCGoogleRoutesRequests(inherited DirectionsRequests);
end;

function TTMSFNCCustomGoogleRoutes.GetDirectionsRequestsClass: TTMSFNCDirectionsRequestsClass;
begin
  Result := TTMSFNCGoogleRoutesRequests;
end;

function TTMSFNCCustomGoogleRoutes.GetGoogleRoutesOptions(
  var AOptions: string; ATravelMode: TTMSFNCDirectionsTravelMode;
    AWayPoints: TTMSFNCMapsCoordinateRecArray; AWayPointsList: TStringList): string;
var
  Preference: string;
  Departure: string;
  Extras: TStringList;
  I: Integer;
begin
  Result := AOptions;

  if not (ATravelMode in [tmWalking, tmBicycling]) then
  begin
    case Options.RoutingMode of
      rmTrafficUnaware: Preference := '';
      rmTrafficAware: Preference := 'TRAFFIC_AWARE';
      rmTrafficAwareOptimal:
      begin
        Preference := 'TRAFFIC_AWARE_OPTIMAL';
        if (Options.IncludeFuelEfficientRoute) then
        begin
          if (Length(AWayPoints) <= 0) and ((not Assigned(AWayPointsList)) or (Assigned(AWayPointsList) and (AWayPointsList.Count <= 0))) then
          begin
            Result := Result + '  "requestedReferenceRoutes": ["FUEL_EFFICIENT"],' + LB;
          end;
        end;
      end;
    end;

    if Preference <> '' then
    begin
      Result := Result +
        '  "routingPreference": "' + Preference + '",' + LB;
    end;
  end;

  if Options.RoutingMode <> rmTrafficUnaware then
  begin
    case Options.Departure of
      rdIgnore: Departure := '';
      rdNow: Departure := TTMSFNCUtils.DateTimeToISO(Now, True, True);
      rdDateTime: Departure := TTMSFNCUtils.DateTimeToISO(Options.DepartureTime, True, True);
    end;

    if Departure <> '' then
    begin
      Result := Result +
        '  "departureTime": "' + Departure + '",' + LB;
    end;
  end;

  Extras := TStringList.Create;
//  Extras.Add('TOLLS');
//  Extras.Add('FUEL_CONSUMPTION');
  Extras.Add('TRAFFIC_ON_POLYLINE');

  if Options.HTMLFormattedInstructions then
  begin
    Extras.Add('HTML_FORMATTED_NAVIGATION_INSTRUCTIONS');
  end;

  if Extras.Count > 0 then
  begin
    Result := Result + '  "extraComputations": [';
    for I := 0 to Extras.Count - 1 do
    begin
      Result := Result + '"' + Extras[I] + '",';
    end;
    Result := Result + '],' + LB;
  end;

  Extras.Free;
end;

procedure TTMSFNCCustomGoogleRoutes.SetDirectionsRequests(
  const Value: TTMSFNCGoogleRoutesRequests);
begin
  inherited;
end;

procedure TTMSFNCCustomGoogleRoutes.SetOptions(
  const Value: TTMSFNCGoogleRoutesOptions);
begin
  FOptions.Assign(Value);
end;

{ TTMSFNCGoogleRoutes }

procedure TTMSFNCGoogleRoutes.RegisterRuntimeClasses;
begin
  inherited;
  Classes.RegisterClass(TTMSFNCGoogleRoutes);
end;

{ TTMSFNCGoogleRoutesOptions }

procedure TTMSFNCGoogleRoutesOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGoogleRoutesOptions) then
  begin
    FRoutingMode := (Source as TTMSFNCGoogleRoutesOptions).RoutingMode;
    FDeparture := (Source as TTMSFNCGoogleRoutesOptions).Departure;
    FDepartureTime := (Source as TTMSFNCGoogleRoutesOptions).DepartureTime;
    FHTMLFormattedInstructions := (Source as TTMSFNCGoogleRoutesOptions).HTMLFormattedInstructions;
  end
  else
    inherited;
end;

constructor TTMSFNCGoogleRoutesOptions.Create;
begin
  FRoutingMode := rmTrafficUnaware;
  FDeparture := rdIgnore;
  FDepartureTime := Now;
  FHTMLFormattedInstructions := False;
end;

destructor TTMSFNCGoogleRoutesOptions.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGoogleRoutesOptions.SetDeparture(
  const Value: TTMSFNCGoogleRoutesDeparture);
begin
  if FDeparture <> Value then
  begin
    FDeparture := Value;
  end;
end;

procedure TTMSFNCGoogleRoutesOptions.SetDepartureTime(const Value: TDateTime);
begin
  if FDepartureTime <> Value then
  begin
      FDepartureTime := Value;
  end;
end;

procedure TTMSFNCGoogleRoutesOptions.SetHTMLFormattedInstructions(
  const Value: Boolean);
begin
  if FHTMLFormattedInstructions <> Value then
  begin
    FHTMLFormattedInstructions := Value;
  end;
end;

procedure TTMSFNCGoogleRoutesOptions.SetIncludeFuelEfficientRoute(
  const Value: Boolean);
begin
  if FIncludeFuelEfficientRoute <> Value then
  begin
    FIncludeFuelEfficientRoute := Value;
  end;
end;

procedure TTMSFNCGoogleRoutesOptions.SetRoutingMode(const Value: TTMSFNCGoogleRoutesRoutingMode);
begin
  if FRoutingMode <> Value then
  begin
    FRoutingMode := Value;
  end;
end;

{ TTMSFNCGoogleRoutesTravelAdvisory }

procedure TTMSFNCGoogleRoutesTravelAdvisory.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGoogleRoutesTravelAdvisory) then
  begin
    FSpeedReadingIntervals := (Source as TTMSFNCGoogleRoutesTravelAdvisory).SpeedReadingIntervals;
    FFuelConsumptionMicroliters := (Source as TTMSFNCGoogleRoutesTravelAdvisory).FuelConsumptionMicroliters;
  end
  else
    inherited;
end;

constructor TTMSFNCGoogleRoutesTravelAdvisory.Create;
begin
  FSpeedReadingIntervals := TTMSFNCSpeedReadingIntervals.Create(Self);
  FFuelConsumptionMicroliters := 0;
end;

destructor TTMSFNCGoogleRoutesTravelAdvisory.Destroy;
begin
  FSpeedReadingIntervals.Free;
end;

procedure TTMSFNCGoogleRoutesTravelAdvisory.SetFuelConsumptionMicroliters(
  const Value: Integer);
begin
  FFuelConsumptionMicroliters := Value;
end;

procedure TTMSFNCGoogleRoutesTravelAdvisory.SetSpeedReadingIntervals(
  const Value: TTMSFNCSpeedReadingIntervals);
begin
  FSpeedReadingIntervals.Assign(Value);
end;

{ TTMSFNCGoogleRoutesRequests }

function TTMSFNCGoogleRoutesRequests.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleRoutesRequest;
end;

function TTMSFNCGoogleRoutesRequests.GetItem(
  Index: Integer): TTMSFNCGoogleRoutesRequest;
begin
  Result := TTMSFNCGoogleRoutesRequest(inherited Items[Index]);
end;

procedure TTMSFNCGoogleRoutesRequests.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleRoutesRequest);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleRoutesRequest }

function TTMSFNCGoogleRoutesRequest.GetDirectionsItemsClass: TTMSFNCDirectionsItemsClass;
begin
  Result := TTMSFNCGoogleRoutesItems;
end;

function TTMSFNCGoogleRoutesRequest.GetItems: TTMSFNCGoogleRoutesItems;
begin
  Result := TTMSFNCGoogleRoutesItems(inherited Items);
end;

{ TTMSFNCGoogleRoutesItems }

function TTMSFNCGoogleRoutesItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleRoutesItem;
end;

function TTMSFNCGoogleRoutesItems.GetItem(
  Index: Integer): TTMSFNCGoogleRoutesItem;
begin
  Result := TTMSFNCGoogleRoutesItem(inherited Items[Index]);
end;

{ TTMSFNCGoogleRoutesItem }

procedure TTMSFNCGoogleRoutesItem.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCGoogleRoutesItem then
  begin
    FRouteLabels.Assign(TTMSFNCGoogleRoutesItem(Source).RouteLabels);
    FTravelAdvisory.Assign(TTMSFNCGoogleRoutesItem(Source).TravelAdvisory);
  end;
end;

constructor TTMSFNCGoogleRoutesItem.Create(ACollection: TCollection);
begin
  inherited;
  FRouteLabels := TStringList.Create;
  FTravelAdvisory := TTMSFNCGoogleRoutesTravelAdvisory.Create;
end;

destructor TTMSFNCGoogleRoutesItem.Destroy;
begin
  FRouteLabels.Free;
  FTravelAdvisory.Free;
  inherited;
end;

procedure TTMSFNCGoogleRoutesItem.SetRouteLabels(const Value: TStringList);
begin
  FRouteLabels.Assign(Value);
end;

procedure TTMSFNCGoogleRoutesItem.SetTravelAdivsory(
  const Value: TTMSFNCGoogleRoutesTravelAdvisory);
begin
  FTravelAdvisory := Value;
end;

{ TTMSFNCSpeedReadingIntervals }

function TTMSFNCSpeedReadingIntervals.Add: TTMSFNCSpeedReadingInterval;
begin
  Result := TTMSFNCSpeedReadingInterval(inherited Add);
end;

constructor TTMSFNCSpeedReadingIntervals.Create(AOwner: TPersistent);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCSpeedReadingIntervals.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCSpeedReadingInterval;
end;

function TTMSFNCSpeedReadingIntervals.GetItem(
  Index: Integer): TTMSFNCSpeedReadingInterval;
begin
  Result := TTMSFNCSpeedReadingInterval(inherited Items[Index]);
end;

function TTMSFNCSpeedReadingIntervals.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCSpeedReadingIntervals.Insert(
  Index: Integer): TTMSFNCSpeedReadingInterval;
begin
  Result := TTMSFNCSpeedReadingInterval(inherited Insert(Index));
end;

procedure TTMSFNCSpeedReadingIntervals.SetItem(Index: Integer;
  const Value: TTMSFNCSpeedReadingInterval);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCSpeedReadingInterval }

procedure TTMSFNCSpeedReadingInterval.Assign(Source: TPersistent);
begin
  inherited;

end;

constructor TTMSFNCSpeedReadingInterval.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCSpeedReadingIntervals).FOwner;

  FStartPolylinePointIndex := -1;
  FEndPolylinePointIndex := 0;
  FSpeed := '';
end;

destructor TTMSFNCSpeedReadingInterval.Destroy;
begin
  inherited;
end;

end.

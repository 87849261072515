unit uPOIGroupEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, WEBLib.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.WebCtrls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCImage;

type
  TPOIGroupEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    NameLabel: TLabel;
    NameEdit: TEdit;
    POIGroupImage: TTMSFNCImage;
    MyMessageDlg: TMessageDlg;
    OpenFileButton: TSpeedButton;
    MyOpenFileDialog: TOpenDialog;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    procedure OpenFileButtonClick(Sender: TObject);
    procedure MyOpenFileDialogChange(Sender: TObject);
    procedure MyOpenFileDialogGetFileAsBase64(Sender: TObject;
      AFileIndex: Integer; ABase64: string);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Integer;
    ImageBase64: String;
  protected procedure LoadDFMValues; override; end;

var
  POIGroupEditForm: TPOIGroupEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TPOIGroupEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TPOIGroupEditForm.WebFormShow(Sender: TObject);
begin
  NameEdit.SetFocus();
  LoadData();
  RecordChanged := False;
end;

procedure TPOIGroupEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  if PKey >= 0 then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_poi_group_load.php?sessionid=' + SessionId +
        '&pkey=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    finally
      MyWebRequest.Free;
    end;
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      // Assign Values
      NameEdit.Text := MyJSONObject.GetJSONValue('ID');
      ImageBase64 := MyJSONObject.GetJSONValue('Picture');
      if ImageBase64 <> EmptyStr then begin
        POIGroupImage.Bitmap.LoadFromURL(ImageBase64);
      end;
    finally
      MyJSON.Free;
    end
  end;
end;

procedure TPOIGroupEditForm.MyOpenFileDialogChange(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  MyOpenFileDialog.Files[0].GetFileAsBase64;
end;

procedure TPOIGroupEditForm.MyOpenFileDialogGetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
begin
  ImageBase64 := 'data:image/png;base64,' + ABase64;
  POIGroupImage.Bitmap.LoadFromURL(ImageBase64);
end;

procedure TPOIGroupEditForm.OpenFileButtonClick(Sender: TObject);
begin
  MyOpenFileDialog.Execute;
end;

procedure TPOIGroupEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

function TPOIGroupEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Name
  if Result then begin
    if Trim(NameEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'Name is a required field.';
    end;
  end;
end;

function TPOIGroupEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id": "' + NameEdit.Text + '",' +
      '"picture": "' + ImageBase64 + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_poi_group_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TPOIGroupEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, mtError, [mbOK]);
  end;
end;

procedure TPOIGroupEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  NameLabel := TLabel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  OpenFileButton := TSpeedButton.Create(Self);
  NameEdit := TEdit.Create(Self);
  POIGroupImage := TTMSFNCImage.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  MyOpenFileDialog := TOpenDialog.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  NameLabel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  OpenFileButton.BeforeLoadDFMValues;
  NameEdit.BeforeLoadDFMValues;
  POIGroupImage.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  MyOpenFileDialog.BeforeLoadDFMValues;
  try
    Name := 'POIGroupEditForm';
    Width := 445;
    Height := 279;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 445;
    FormPanel.Height := 279;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 439;
    ContentPanel.Height := 236;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    NameLabel.SetParentComponent(ContentPanel);
    NameLabel.Name := 'NameLabel';
    NameLabel.Left := 20;
    NameLabel.Top := 15;
    NameLabel.Width := 42;
    NameLabel.Height := 21;
    NameLabel.Caption := 'Name';
    NameLabel.Font.Charset := ANSI_CHARSET;
    NameLabel.Font.Color := 9010544;
    NameLabel.Font.Height := -16;
    NameLabel.Font.Name := 'Segoe UI';
    NameLabel.Font.Style := [];
    NameLabel.HeightPercent := 100.000000000000000000;
    NameLabel.ParentFont := False;
    NameLabel.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 187;
    StatusBarPanel.Width := 439;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 319;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uPOIGroupEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 204;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uPOIGroupEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    OpenFileButton.SetParentComponent(StatusBarPanel);
    OpenFileButton.Name := 'OpenFileButton';
    OpenFileButton.AlignWithMargins := True;
    OpenFileButton.Left := 20;
    OpenFileButton.Top := 8;
    OpenFileButton.Width := 127;
    OpenFileButton.Height := 31;
    OpenFileButton.Margins.Left := 20;
    OpenFileButton.Margins.Top := 8;
    OpenFileButton.Margins.Right := 5;
    OpenFileButton.Margins.Bottom := 10;
    OpenFileButton.Caption := 'Choose &File';
    OpenFileButton.Color := clNone;
    OpenFileButton.ElementClassName := 'MyWebButton';
    OpenFileButton.HeightPercent := 100.000000000000000000;
    OpenFileButton.TabOrder := 2;
    OpenFileButton.WidthPercent := 100.000000000000000000;
    SetEvent(OpenFileButton, Self, 'OnClick', 'OpenFileButtonClick');
    NameEdit.SetParentComponent(ContentPanel);
    NameEdit.Name := 'NameEdit';
    NameEdit.Left := 20;
    NameEdit.Top := 42;
    NameEdit.Width := 397;
    NameEdit.Height := 34;
    NameEdit.ChildOrder := 1;
    NameEdit.ElementClassName := 'MyWebEdit';
    NameEdit.Font.Charset := ANSI_CHARSET;
    NameEdit.Font.Color := clWindowText;
    NameEdit.Font.Height := -16;
    NameEdit.Font.Name := 'Segoe UI';
    NameEdit.Font.Style := [];
    NameEdit.HeightPercent := 100.000000000000000000;
    NameEdit.ParentFont := False;
    NameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameEdit, Self, 'OnChange', 'RecordChange');
    POIGroupImage.SetParentComponent(ContentPanel);
    POIGroupImage.Name := 'POIGroupImage';
    POIGroupImage.Left := 20;
    POIGroupImage.Top := 101;
    POIGroupImage.Width := 64;
    POIGroupImage.Height := 64;
    POIGroupImage.ParentDoubleBuffered := False;
    POIGroupImage.Color := clBtnFace;
    POIGroupImage.DoubleBuffered := True;
    POIGroupImage.TabOrder := 2;
    POIGroupImage.Fill.Kind := gfkNone;
    POIGroupImage.Stroke.Kind := gskNone;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 445;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 119;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'POI Group - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 416;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uPOIGroupEdit.CaptionPanel.CloseImage.Picture.png');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 222;
    MyMessageDlg.Top := 46;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    MyOpenFileDialog.SetParentComponent(Self);
    MyOpenFileDialog.Name := 'MyOpenFileDialog';
    MyOpenFileDialog.Accept := '.png';
    SetEvent(MyOpenFileDialog, Self, 'OnChange', 'MyOpenFileDialogChange');
    SetEvent(MyOpenFileDialog, Self, 'OnGetFileAsBase64', 'MyOpenFileDialogGetFileAsBase64');
    MyOpenFileDialog.Left := 145;
    MyOpenFileDialog.Top := 154;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    NameLabel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    OpenFileButton.AfterLoadDFMValues;
    NameEdit.AfterLoadDFMValues;
    POIGroupImage.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    MyOpenFileDialog.AfterLoadDFMValues;
  end;
end;

end.
unit uAssetEdit;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.RegularExpressions,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCPageControl,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTabSet, VCL.TMSFNCPanel, WEBLib.Buttons,
  WEBLib.REST, WEBLib.JSON, VCL.TMSFNCCustomPicker, VCL.TMSFNCColorPicker,
  VCL.TMSFNCImage, VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter,
  Data.DB, WEBLib.DB, WEBLib.CDS;

type
  TAssetEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    AssetTypeLabel: TLabel;
    AssetGroupLabel: TLabel;
    AssetNoLabel: TLabel;
    AssetIDLabel: TLabel;
    AssetIDEdit: TEdit;
    OwnerLabel: TLabel;
    TenantLabel: TLabel;
    AssetStatusLabel: TLabel;
    ListButtonOnlinePanel: TTMSFNCPanel;
    PageControl: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    LicensePlateLabel: TLabel;
    LicensePlateEdit: TEdit;
    ChassisNumberLabel: TLabel;
    ChassisNumberEdit: TEdit;
    AssetGroupEdit: TLookupComboBox;
    AssetTypeEdit: TLookupComboBox;
    OwnerEdit: TLookupComboBox;
    TenantEdit: TLookupComboBox;
    AssetStatusEdit: TLookupComboBox;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    RemarkEdit: TMemo;
    AssetNoEdit: TEdit;
    UserType2Label: TLabel;
    UserType2Edit: TLookupComboBox;
    WebLabel2: TLabel;
    UserType1Label: TLabel;
    UserType1Edit: TLookupComboBox;
    WebLabel4: TLabel;
    AssetBrandEdit: TLookupComboBox;
    AssetModelEdit: TLookupComboBox;
    WebLabel8: TLabel;
    WebLabel15: TLabel;
    DevicePKeyEdit: TEdit;
    WebLabel17: TLabel;
    SelectDeviceButton: TBitBtn;
    ClearDeviceButton: TBitBtn;
    YearManufacturedEdit: TEdit;
    DriverPKeyEdit: TEdit;
    SelectDriverButton: TBitBtn;
    ClearDriverButton: TBitBtn;
    UserType3Label: TLabel;
    UserType3Edit: TLookupComboBox;
    UserType4Label: TLabel;
    UserType4Edit: TLookupComboBox;
    WebLabel11: TLabel;
    FuelTypeEdit: TLookupComboBox;
    WebLabel1: TLabel;
    WebLabel10: TLabel;
    CarbonDioxideEdit: TEdit;
    FuelConsumptionEdit: TEdit;
    ColorLabel: TLabel;
    WebLabel3: TLabel;
    AssetKindEdit: TLookupComboBox;
    KeyNumberEdit: TEdit;
    KeyCommentEdit: TEdit;
    WebLabel5: TLabel;
    WebLabel12: TLabel;
    BatteryLockEdit: TCheckBox;
    AssetBrandLabel: TLabel;
    AssetModelLabel: TLabel;
    ColorEdit: TTMSFNCColorPicker;
    WebPanel1: TPanel;
    AlertGrid: TTMSFNCGrid;
    WebMessageDlg2: TMessageDlg;
    DatasetTimer: TTimer;
    AlertDataset: TClientDataSet;
    AlertDatasetPKey: TStringField;
    AlertDatasetName: TStringField;
    AlertDataSource: TDataSource;
    AlertDBAdapter: TTMSFNCGridDatabaseAdapter;
    AddAlertButton: TSpeedButton;
    DeleteAlertButton: TSpeedButton;
    TMSFNCPanel2: TTMSFNCPanel;
    PageControlPage3: TTMSFNCPageControlContainer;
    DeviceDBAdapter: TTMSFNCGridDatabaseAdapter;
    DeviceDataset: TClientDataSet;
    DeviceDataSource: TDataSource;
    DeviceGrid: TTMSFNCGrid;
    WebMessageDlg3: TMessageDlg;
    WebConnection: TClientConnection;
    DeviceDatasetPKey: TStringField;
    DeviceDatasetID: TStringField;
    DeviceDatasetFrom: TStringField;
    DeviceDatasetTill: TStringField;
    PageControlPage4: TTMSFNCPageControlContainer;
    DriverDBAdapter: TTMSFNCGridDatabaseAdapter;
    DriverDataSet: TClientDataSet;
    DriverDataSource: TDataSource;
    DriverGrid: TTMSFNCGrid;
    WebMessageDlg4: TMessageDlg;
    DriverDataSetPKey: TStringField;
    DriverDataSetName: TStringField;
    DriverDataSetFrom: TStringField;
    DriverDataSetTill: TStringField;
    WebLabel16: TLabel;
    ModelImage: TTMSFNCImage;
    MyMessageDlg: TMessageDlg;
    OdoMeterEdit: TEdit;
    OdoMeterLabel: TLabel;
    DocumentDBAdapter: TTMSFNCGridDatabaseAdapter;
    DocumentDataSet: TClientDataSet;
    DocumentDataSource: TDataSource;
    DocumentGrid: TTMSFNCGrid;
    WebPanel2: TPanel;
    AddDocumentButton: TSpeedButton;
    DeleteDocumentButton: TSpeedButton;
    TMSFNCPanel1: TTMSFNCPanel;
    DocumentDataSetPKey: TStringField;
    DocumentDataSetID: TStringField;
    DocumentDataSetType: TStringField;
    DocumentDataSetExpiryDate: TStringField;
    DocumentDataSetTimestamp: TStringField;
    EditDocumentButton: TSpeedButton;
    ViewDocumentButton: TSpeedButton;
    PageControlPage5: TTMSFNCPageControlContainer;
    OdoMeterDiffEdit: TEdit;
    WebLabel6: TLabel;
    AlertDatasetOrigin: TStringField;
    DocumentDataSetLength: TStringField;
    MOTDateEdit: TEdit;
    MOTDateLabel: TLabel;
    RepairHistoryButton: TSpeedButton;
    StatusHistoryButton: TSpeedButton;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure AssetBrandEditChange(Sender: TObject); async;
    [async]
    procedure SelectDeviceButtonClick(Sender: TObject); async;
    [async]
    procedure ClearDeviceButtonClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    [async]
    procedure SelectDriverButtonClick(Sender: TObject); async;
    [async]
    procedure ClearDriverButtonClick(Sender: TObject); async;
    [async]
    procedure AssetBrandLabelClick(Sender: TObject); async;
    [async]
    procedure AssetModelLabelClick(Sender: TObject); async;
    procedure AssetModelEditChange(Sender: TObject);
    procedure PageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    procedure AlertDatasetAfterOpen(DataSet: TDataSet);
    [async]
    procedure AddAlertButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteAlertButtonClick(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    procedure ColorEditItemSelected(Sender: TObject; AItemIndex: Integer);
    procedure DocumentDataSetAfterOpen(DataSet: TDataSet);
    [async]
    procedure AddDocumentButtonClick(Sender: TObject); async;
    [async]
    procedure EditDocumentButtonClick(Sender: TObject); async;
    [async]
    procedure ViewDocumentButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteDocumentButtonClick(Sender: TObject); async;
    [async]
    procedure AssetStatusLabelClick(Sender: TObject); async;
    procedure LicensePlateEditKeyPress(Sender: TObject; var Key: Char);
    [async]
    procedure MOTDateLabelClick(Sender: TObject); async;
    procedure DocumentGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
    [async]
    procedure StatusHistoryButtonClick(Sender: TObject); async;
    [async]
    procedure RepairHistoryButtonClick(Sender: TObject); async;
  private
    { Private declarations }
    bDocumentsLoaded: Boolean;
    RecordChanged: Boolean;
    MOTDateFirst: Int64;
    MOTDateNext: Int64;
    MOTDateInterval: Int64;
    MOTReminder: Boolean;
    BrandModel: String;
    [async]
    procedure LoadLookupValues(); async;
    [async]
    procedure LoadBrandModelValues(); async;
    procedure LoadAssetStatusList(S: String);
    procedure LoadAssetTypeList(S: String);
    procedure LoadAssetGroupList(S: String);
    procedure LoadAssetBrandList(S: String);
    procedure LoadAssetModelList(S: String);
    procedure LoadOwnerTenantList(S: String);
    procedure LoadUserType1List(S: String);
    procedure LoadUserType2List(S: String);
    procedure LoadUserType3List(S: String);
    procedure LoadUserType4List(S: String);
    [async]
    function LoadAsset(): Boolean; async;
    [async]
    procedure LoadDocumentList(); async;
    [async]
    procedure LoadAlertList(); async;
    [async]
    procedure AddAlert(AlertPKey: String); async;
    [async]
    procedure DeleteAlert(AlertPKey: String); async;
    [async]
    procedure DeleteDocument(DocumentPKey: String); async;
    [async]
    procedure LoadDeviceList(); async;
    [async]
    procedure LoadDriverList(); async;
    [async]
    procedure LoadModelInfo(S: String; Default: Boolean); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveAsset(var ErrorMessage: String): Boolean; async;
    [async]
    procedure ShowAssetDocumentEditDialog(DocumentPKey: Integer); async;
    procedure UpdateDocumentButtons();

  public
    { Public declarations }
    PKey: Int64;
    IsSet: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  AssetEditForm: TAssetEditForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceList, uUserList, uBrandList, uModelList, uAlertList,
  uAssetDocumentEdit, uReportPrint, uAssetStatusChange, uAssetMOTEdit,
  uAssetStatusChangeList, uAssetRepairTicketList;

procedure TAssetEditForm.WebFormCreate(Sender: TObject);
begin
  AssetKindEdit.Value := '1';
  SetGridStyle(DocumentGrid, False);
  SetGridStyle(AlertGrid, False);
  SetGridStyle(DeviceGrid, False);
  SetGridStyle(DriverGrid, False);
end;

procedure TAssetEditForm.SetReadOnly();
begin
  // Disable Main Buttons
  SaveButton.Visible := False;
  SelectDeviceButton.Visible := False;
  ClearDeviceButton.Visible := False;
  SelectDriverButton.Visible := False;
  ClearDriverButton.Visible := False;
  // Disable Tab Buttons
  AddDocumentButton.Visible := False;
  EditDocumentButton.Visible := False;
  DeleteDocumentButton.Visible := False;

end;

procedure TAssetEditForm.AddAlertButtonClick(Sender: TObject);
var
  MyPermission: Integer;
  MyWebForm: TAlertListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'alerts');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TAlertListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TAlertListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Allow Select
      MyWebForm.SelectButton.Visible := True;
//    MyWebForm.MyGrid.Options.Selection.Mode := smDisjunctRow;
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        // Add Alerts
        AddAlert(MyWebForm.PKey);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TAssetEditForm.AddDocumentButtonClick(Sender: TObject);
begin
  await(ShowAssetDocumentEditDialog(-1));
end;

procedure TAssetEditForm.ShowAssetDocumentEditDialog(DocumentPKey: Integer);
var
  MyWebForm: TAssetDocumentEditForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TAssetDocumentEditForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  MyWebForm.PKey := DocumentPKey;
  MyWebForm.AssetPKey := PKey;
  // Load Form
  await(TAssetDocumentEditForm, MyWebForm.Load());
  try
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      LoadDocumentList();
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetEditForm.StatusHistoryButtonClick(Sender: TObject);
var
  MyWebForm: TAssetStatusChangeListForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TAssetStatusChangeListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  MyWebForm.AssetPKey := IntToStr(PKey);
  // Load Form
  await(TAssetStatusChangeListForm, MyWebForm.Load());
  try
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
//    LoadDocumentList();
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetEditForm.AddAlert(AlertPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_alert_add.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + AlertPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAlertList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;


procedure TAssetEditForm.AssetBrandEditChange(Sender: TObject);
begin
  LoadAssetModelList(AssetBrandEdit.Value);
  RecordChange(TObject(Sender));
end;

procedure TAssetEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  Result: Boolean;
  ErrorMessage: String;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      Result := await(ValidInput(ErrorMessage));
      if Result then begin
        Result := await(SaveAsset(ErrorMessage));
        if Result then begin
          ModalResult := mrOK;
        end else begin
          MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
        end;
      end else begin
        MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetEditForm.ColorEditItemSelected(Sender: TObject;
  AItemIndex: Integer);
begin
  RecordChange(TObject(Sender));
end;

procedure TAssetEditForm.DeleteAlertButtonClick(Sender: TObject);
var
  StringList: TStringList;
  I: Integer;
begin
  if AlertGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to AlertGrid.RowSelectionCount-1 do begin
        StringList.Add(AlertGrid.Cells[0, AlertGrid.SelectedRow[I]]);
      end;
      DeleteAlert(StringList.DelimitedText);
    finally
      StringList.Free;
    end;
  end;
end;

procedure TAssetEditForm.DeleteDocumentButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  StringList: TStringList;
  I: Integer;
begin
  if DocumentGrid.RowSelectionCount > 0 then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selected records?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      StringList := TStringList.Create();
      try
        for I := 0 to DocumentGrid.RowSelectionCount-1 do begin
          StringList.Add(DocumentGrid.Cells[0, DocumentGrid.SelectedRow[I]]);
        end;
        DeleteDocument(StringList.DelimitedText);
      finally
        StringList.Free;
      end;
    end;
  end;
end;

procedure TAssetEditForm.DocumentDataSetAfterOpen(DataSet: TDataSet);
begin
  DocumentDBAdapter.Active := True;
  DocumentDBAdapter.LoadAllDataAndDisconnect;
  UpdateDocumentButtons();
  bDocumentsLoaded := True;
end;

procedure TAssetEditForm.DocumentGridSelectedCell(Sender: TObject; ACol,
  ARow: Integer);
begin
  if bDocumentsLoaded then begin
    UpdateDocumentButtons();
  end;
end;

procedure TAssetEditForm.EditDocumentButtonClick(Sender: TObject);
var
  DocumentPKey: Integer;
begin
  if DocumentGrid.RowSelectionCount = 1 then begin
    DocumentPKey := StrToInt(DocumentGrid.Cells[0, DocumentGrid.SelectedRow[0]]);
    await(ShowAssetDocumentEditDialog(DocumentPKey));
  end;
end;

procedure TAssetEditForm.DeleteAlert(AlertPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_alert_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + AlertPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAlertList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetEditForm.DeleteDocument(DocumentPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_document_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + DocumentPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadDocumentList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetEditForm.WebFormShow(Sender: TObject);
var
  I: Integer;
  Result: Boolean;
begin
  // Read Only
  if AssetIDIsReadOnly then AssetIDEdit.Enabled := False;
  // Load Lookup Values
  await(LoadLookupValues());
  // Load Values
  Result := await(LoadAsset());
  if Result then begin
    // Show Pages
    if PKey >= 0 then begin
      for I := 1 to PageControl.Pages.Count-1 do begin
        PageControl.Pages[I].TabVisible := True;
        PageControl.ActivePageIndex := 0;
      end;
    end else begin
      YearManufacturedEdit.Text := '2023';
      OdoMeterEdit.Text := '0';
      OdoMeterDiffEdit.Text := '0';
    end;
  end;
  RecordChanged := False;
end;

procedure TAssetEditForm.MOTDateLabelClick(Sender: TObject);
var
  MyWebForm: TAssetMOTEditForm;
begin
  MyWebForm := TAssetMOTEditForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TAssetMOTEditForm, MyWebForm.Load());
  try
    MyWebForm.SaveButton.Visible := Self.SaveButton.Visible;
    MyWebForm.SetValues(MOTDateFirst, MOTDateInterval, MOTReminder);
    // Show Form
    await(TModalResult, MyWebForm.Execute);
    if MyWebForm.ModalResult = mrOK then begin
      RecordChanged := True;
      MOTDateInterval := StrToInt(MyWebForm.DateIntervalEdit.Text);
      MOTDateFirst := DateTimeToUnix(MyWebForm.DateFirstEdit.Date);
      MOTReminder := MyWebForm.ReminderEdit.Checked;
      MOTDateEdit.Text := GetNextMOTDate(MOTDateFirst, MOTDateInterval);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetEditForm.LoadAssetStatusList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetStatusEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AssetStatusEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    AssetStatusEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
  // Read Only?
  AssetStatusEdit.Enabled := PKey = 0;
end;

procedure TAssetEditForm.LoadAssetTypeList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetTypeEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AssetTypeEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    AssetTypeEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadAssetGroupList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetGroupEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AssetGroupEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    AssetGroupEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadAssetBrandList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetBrandEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AssetBrandEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    AssetBrandEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadAssetModelList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetModelEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(BrandModel));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      if
        (MyJSONObject.GetJSONValue('Parent') = '0')
        or
        (MyJSONObject.GetJSONValue('Parent') = S)
      then begin
        AssetModelEdit.LookupValues.AddPair(
          MyJSONObject.GetJSONValue('PKey'),
          MyJSONObject.GetJSONValue('ID')
        );
      end;
    end;
    // Set Value
    AssetModelEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadOwnerTenantList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  OwnerEdit.LookupValues.Clear;
  TenantEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      OwnerEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
      TenantEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    OwnerEdit.Value := '0';
    TenantEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadUserType1List(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  UserType1Edit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      UserType1Edit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    UserType1Edit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadUserType2List(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  UserType2Edit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      UserType2Edit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    UserType2Edit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadUserType3List(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  UserType3Edit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      UserType3Edit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    UserType3Edit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.LoadUserType4List(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  UserType4Edit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      UserType4Edit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    UserType4Edit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetEditForm.AlertDatasetAfterOpen(DataSet: TDataSet);
begin
  AlertDBAdapter.Active := True;
  AlertDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TAssetEditForm.PageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  case ACurrentPageIndex of
    1: LoadDocumentList();
    2: LoadAlertList();
    3: LoadDeviceList();
    4: LoadDriverList();
  end;
end;

procedure TAssetEditForm.LoadLookupValues();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    BrandModel := TJSONPair(MyJSONObject.Get('object_model')).JsonValue.ToString;
    LoadAssetStatusList(TJSONPair(MyJSONObject.Get('object_status')).JsonValue.ToString);
    LoadAssetTypeList(TJSONPair(MyJSONObject.Get('object_type')).JsonValue.ToString);
    LoadAssetGroupList(TJSONPair(MyJSONObject.Get('object_group')).JsonValue.ToString);
    LoadOwnerTenantList(TJSONPair(MyJSONObject.Get('object_owner_tenant')).JsonValue.ToString);
    LoadAssetBrandList(TJSONPair(MyJSONObject.Get('object_brand')).JsonValue.ToString);
    LoadUserType1List(TJSONPair(MyJSONObject.Get('user_type_1')).JsonValue.ToString);
    LoadUserType2List(TJSONPair(MyJSONObject.Get('user_type_2')).JsonValue.ToString);
    LoadUserType3List(TJSONPair(MyJSONObject.Get('user_type_3')).JsonValue.ToString);
    LoadUserType4List(TJSONPair(MyJSONObject.Get('user_type_4')).JsonValue.ToString);
    LoadAssetModelList('0');
  finally
    MyJSON.Free;
  end
end;

procedure TAssetEditForm.LoadBrandModelValues();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_brand_model_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    BrandModel := TJSONPair(MyJSONObject.Get('object_model')).JsonValue.ToString;
    LoadAssetBrandList(TJSONPair(MyJSONObject.Get('object_brand')).JsonValue.ToString);
    LoadAssetModelList('0');
  finally
    MyJSON.Free;
  end
end;

function TAssetEditForm.LoadAsset(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  if PKey >= 0 then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_asset_load.php?sessionid=' + SessionId +
        '&pkey=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Response
      if MyRequest.Status = 200 then begin
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            // General
            AssetStatusEdit.Value := MyJSONObject.GetJSONValue('AssetStatus');
            AssetTypeEdit.Value := MyJSONObject.GetJSONValue('Type');
            AssetGroupEdit.Value := MyJSONObject.GetJSONValue('Group');
            AssetIDEdit.Text := MyJSONObject.GetJSONValue('ID');
            if MyJSONObject.GetJSONValue('Symbol') <> '0' then begin
              AssetNoEdit.Text := MyJSONObject.GetJSONValue('Symbol');
            end else begin
              AssetNoEdit.Text := EmptyStr;
            end;
            OwnerEdit.Value := MyJSONObject.GetJSONValue('Owner');
            TenantEdit.Value := MyJSONObject.GetJSONValue('Tenant');
            AssetBrandEdit.Value := MyJSONObject.GetJSONValue('Brand');
            LoadAssetModelList(AssetBrandEdit.Value);
            AssetModelEdit.Value := MyJSONObject.GetJSONValue('Model');
            LoadModelInfo(AssetModelEdit.Value, False);
            UserType1Edit.Value := MyJSONObject.GetJSONValue('Type 1');
            UserType2Edit.Value := MyJSONObject.GetJSONValue('Type 2');
            UserType3Edit.Value := MyJSONObject.GetJSONValue('Type 3');
            UserType4Edit.Value := MyJSONObject.GetJSONValue('Type 4');
            OdoMeterEdit.Text := MyJSONObject.GetJSONValue('odometer value');
            OdoMeterLabel.Caption := '   ' + MyJSONObject.GetJSONValue('odometer date');
            OdoMeterDiffEdit.Text := MyJSONObject.GetJSONValue('odometer diff');
            YearManufacturedEdit.Text := MyJSONObject.GetJSONValue('Year');
            KeyNumberEdit.Text := MyJSONObject.GetJSONValue('key_number');
            KeyCommentEdit.Text := MyJSONObject.GetJSONValue('key_comment');
            FuelTypeEdit.Value := MyJSONObject.GetJSONValue('fuel_type');
            FuelConsumptionEdit.Text := MyJSONObject.GetJSONValue('fuel_consumption');
            CarbonDioxideEdit.Text := MyJSONObject.GetJSONValue('carbon_dioxide');
            ColorEdit.SelectedColor := StrToInt(MyJSONObject.GetJSONValue('color'));
            BatteryLockEdit.Checked := MyJSONObject.GetJSONValue('battery_lock') = '1';
            RemarkEdit.Text := MyJSONObject.GetJSONValue('Remark');
            // MOT
            MOTDateInterval := StrToInt(MyJSONObject.GetJSONValue('mot_date_interval'));
            MOTDateFirst := StrToInt(MyJSONObject.GetJSONValue('mot_date_first'));
            MOTDateNext := StrToInt(MyJSONObject.GetJSONValue('mot_date_next'));
            MOTReminder := MyJSONObject.GetJSONValue('mot_reminder') = '1';
            MOTDateEdit.Text := GetNextMOTDate(MOTDateFirst, MOTDateInterval);
            // Device
            DevicePKeyEdit.Text := MyJSONObject.GetJSONValue('DevicePKey');
            // Driver
            DriverPKeyEdit.Tag := StrToInt(MyJSONObject.GetJSONValue('DriverPKey'));
            DriverPKeyEdit.Text := MyJSONObject.GetJSONValue('DriverName');
            // Vehicle
            LicensePlateEdit.Text := MyJSONObject.GetJSONValue('License Plate');
            ChassisNumberEdit.Text := MyJSONObject.GetJSONValue('Chassis Number');
            Result := True;
          end else begin
            MessageDlg(MyJSONObject.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
          end;
        finally
          MyJSON.Free;
        end
      end;
    finally
      MyWebRequest.Free;
    end;
  end else begin
    Result := True;
  end;
end;

function TAssetEditForm.ValidInput(var ErrorMessage: String): Boolean;
var
  MyInteger: Integer;
begin
  Result := True;
  // License Plate
  if Result and (Trim(LicensePlateEdit.Text) = EmptyStr) then begin
    Result := False;
    ErrorMessage := '"Registration plate" is a required field.';
  end else begin
    if not(TRegEx.IsMatch(LicensePlateEdit.Text, RegExAlphaNumeric)) then begin
      Result := False;
      ErrorMessage := '"Registration plate" contains invalid characters.';
    end;
  end;
  // Asset Group
  if Result and (AssetGroupEdit.Value = '0') then begin
    Result := False;
    ErrorMessage := '"Asset Group" is a required field.';
  end;
  // Year
  if Result then begin
    if not(TryStrToInt(YearManufacturedEdit.Text, MyInteger)) then begin
      Result := False;
      ErrorMessage := '"Year manufactured" is a required field.';
    end;
  end;
  // Odometer
  if Result then begin
    if not(TryStrToInt(OdometerEdit.Text, MyInteger)) then begin
      Result := False;
      ErrorMessage := '"Odometer" is a required field.';
    end;
  end;
end;

procedure TAssetEditForm.ViewDocumentButtonClick(Sender: TObject);
var
  DocumentPKey: Integer;
  DocumentFile: Integer;
  MyURL: String;
  MyWebForm: TReportPrintForm;
begin
  if DocumentGrid.RowSelectionCount = 1 then begin
    if TryStrToInt(DocumentGrid.Cells[0, DocumentGrid.SelectedRow[0]], DocumentPKey) then begin
      if TryStrToInt(DocumentGrid.Cells[5, DocumentGrid.SelectedRow[0]], DocumentFile) then begin
        if DocumentFile > 0 then begin
          MyURL :=
            url_php + 'default_asset_document_view.php?sessionid=' + SessionId +
            '&pkey=' + IntToStr(DocumentPKey);
          MyWebForm := TReportPrintForm.Create(Self);
          MyWebForm.Popup := True;
          MyWebForm.Border := fbNone;
          await(TReportPrintForm, MyWebForm.Load());
          try
            // Initialize
            MyWebForm.WebBrowser.URL := MyURL;
            // Show Form
            await(TModalResult, MyWebForm.Execute);
          finally
            MyWebForm.Free;
          end;
        end else begin
          MessageDlg('No file assigned to this document', TMsgDlgType.mtError, [mbOK]);
        end;
      end;
    end;
  end;
end;

function TAssetEditForm.SaveAsset(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
//    '"no": "' + AssetNoEdit.Text + '",' +
      '"id": "' + AssetIDEdit.Text + '",' +
      '"license plate": "' + LicensePlateEdit.Text + '",' +
      '"chassis number": "' + ChassisNumberEdit.Text + '",' +
      '"type": "' + AssetTypeEdit.Value + '",' +
      '"brand": "' + AssetBrandEdit.Value + '",' +
      '"model": "' + AssetModelEdit.Value + '",' +
      '"status": "' + AssetStatusEdit.Value + '",' +
      '"owner": "' + OwnerEdit.Value + '",' +
      '"tenant": "' + TenantEdit.Value + '",' +
      '"group": "' + AssetGroupEdit.Value + '",' +
      '"type1": "' + EmptyStrToZero(UserType1Edit.Value) + '",' +
      '"type2": "' + EmptyStrToZero(UserType2Edit.Value) + '",' +
      '"type3": "' + EmptyStrToZero(UserType3Edit.Value) + '",' +
      '"type4": "' + EmptyStrToZero(UserType4Edit.Value) + '",' +
      '"mot_date_first": "' + IntToStr(MOTDateFirst) + '",' +
      '"mot_date_interval": "' + IntToStr(MOTDateInterval) + '",' +
      '"mot_reminder": "' + BooleanToString(MOTReminder) + '",' +
      '"key_number": "' + KeyNumberEdit.Text + '",' +
      '"key_comment": "' + KeyCommentEdit.Text + '",' +
      '"fuel_type": "' + FuelTypeEdit.Value + '",' +
      '"fuel_consumption": "' + FuelConsumptionEdit.Text + '",' +
      '"carbon_dioxide": "' + CarbonDioxideEdit.Text + '",' +
      '"odometer": "' + OdometerEdit.Text + '",' +
      '"color": "' + IntToStr(ColorEdit.SelectedColor) + '",' +
      '"battery_lock": "' + BooleanToString(BatteryLockEdit.Checked) + '",' +
      '"remark": "' + Window.btoa(RemarkEdit.Text) + '",' +
      '"year": "' + YearManufacturedEdit.Text + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveAsset(ErrorMessage));
    if Result then begin
      RecordChanged := False;
      SelectDeviceButton.Enabled := True;
      ClearDeviceButton.Enabled := True;
      SelectDriverButton.Enabled := True;
      ClearDriverButton.Enabled := True;
      // Refresh Alerts
      if PageControl.ActivePageIndex = 2 then begin
        await(LoadAlertList());
      end;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TAssetEditForm.SelectDeviceButtonClick(Sender: TObject);
var
  MyWebForm: TDeviceListForm;
  MyModalResult: TModalResult;
  DevicePKey: String;
  DeviceNo: String;
  ObjectPKey: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MessageText: String;
begin
  MyWebForm := TDeviceListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TDeviceListForm, MyWebForm.Load());
  try
    MyWebForm.SelectButton.Visible := True;
    await(TModalResult, MyWebForm.Execute);
    if MyWebForm.ModalResult = mrOK then begin
      DevicePKey := MyWebForm.DevicePKey;
      DeviceNo := MyWebForm.DeviceNo;
      ObjectPKey := '-1';
      // Check Object Linked To Selected Device
      MyWebRequest := THTTPRequest.Create(Self);
      try
        // Request
        MyWebRequest.URL :=
          url_php + 'default_device_object_get.php?sessionid=' + SessionId +
          '&pkey=' + DevicePKey;
        MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        // Response
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            ObjectPKey := MyJSONObject.GetJSONValue('PKey');
            if StrToInt(ObjectPKey) > 0 then begin
              MessageText :=
                'Delete link with asset "' + MyJSONObject.GetJSONValue('ID') + '"?';
            end;
          end;
        finally
          MyJSON.Free;
        end;
      finally
        MyWebRequest.Free;
      end;
      if StrToInt(ObjectPKey) > 0 then begin
        MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog(MessageText, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      end else begin
        MyModalResult := mrYes;
      end;
      if MyModalResult = mrYes then begin
        // Update Link
        MyWebRequest := THTTPRequest.Create(Self);
        try
          // Request
          MyWebRequest.URL :=
            url_php + 'default_device_object_set.php?sessionid=' + SessionId +
            '&device=' + DevicePKey + '&object=' + IntToStr(PKey);
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          // Response
          MyJSON := TJSON.Create;
          try
            MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
            if MyJSONObject.GetJSONValue('status') = 'OK' then begin
              DevicePKeyEdit.Text := MyWebForm.DevicePKey;
              AssetNoEdit.Text := DeviceNo;
            end;
          finally
            MyJSON.Free;
          end;
        finally
          MyWebRequest.Free;
        end;
      end;
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetEditForm.SelectDriverButtonClick(Sender: TObject);
var
  MyWebForm: TUserListForm;
  MyModalResult: TModalResult;
  UserPKey: String;
  DriverPKey, DriverName: String;
  ObjectPKey: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MessageText: String;
begin
  MyWebForm := TUserListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TUserListForm, MyWebForm.Load());
  try
    MyWebForm.SelectButton.Visible := True;
    // Filter Driver = 1
    MyWebForm.MasterDataset.Filtered := True;
    // Show Form
    await(TModalResult, MyWebForm.Execute);
    if MyWebForm.ModalResult = mrOK then begin
      UserPKey := MyWebForm.UserPKey;
      // Get Driver / Assets Linked To User
      MyWebRequest := THTTPRequest.Create(Self);
      try
        // Request
        MyWebRequest.URL :=
          url_php + 'default_user_object_get.php?sessionid=' + SessionId +
          '&pkey=' + UserPKey;
        MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        // Response
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            DriverPKey := MyJSONObject.GetJSONValue('driver_pkey');
            DriverName := MyJSONObject.GetJSONValue('driver_name');
            ObjectPKey := MyJSONObject.GetJSONValue('license_plate');
            if ObjectPKey <> EmptyStr then begin
              MessageText :=
                'Driver is already linked with asset(s) "' + ObjectPKey + '". Continue?';
            end;
          end;
        finally
          MyJSON.Free;
        end;
      finally
        MyWebRequest.Free;
      end;
      // Confirmation
      if ObjectPKey <> EmptyStr then begin
        MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog(MessageText, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      end else begin
        MyModalResult := mrYes;
      end;
      if MyModalResult = mrYes then begin
        // Update Link
        MyWebRequest := THTTPRequest.Create(Self);
        try
          // Request
          MyWebRequest.URL :=
            url_php + 'default_object_person_set.php?sessionid=' + SessionId +
            '&person=' + DriverPKey + '&object=' + IntToStr(PKey);
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          // Response
          MyJSON := TJSON.Create;
          try
            MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
            if MyJSONObject.GetJSONValue('status') = 'OK' then begin
              DriverPKeyEdit.Tag := StrToInt(DriverPKey);
              DriverPKeyEdit.Text := DriverName;
            end else begin
              MessageDlg(MyJSONObject.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
            end;
          finally
            MyJSON.Free;
          end;
        finally
          MyWebRequest.Free;
        end;
      end;
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetEditForm.AssetBrandLabelClick(Sender: TObject);
var
  MyWebForm: TBrandListForm;
begin
  if SaveButton.Visible then begin
    MyWebForm := TBrandListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TBrandListForm, MyWebForm.Load());
    try
      MyWebForm.SelectButton.Visible := True;
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        RecordChange(TObject(Sender));
        // Refresh Brand / Model
        await(LoadBrandModelValues());
        // Update Brand / Model
        AssetBrandEdit.Value := MyWebForm.BrandPKey;
        AssetModelEdit.Value := '0';
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TAssetEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TAssetEditForm.RepairHistoryButtonClick(Sender: TObject);
var
  MyWebForm: TAssetRepairTicketListForm;
begin
  // Create Form
  MyWebForm := TAssetRepairTicketListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  MyWebForm.AssetPKey := IntToStr(PKey);
  // Load Form
  await(TAssetRepairTicketListForm, MyWebForm.Load());
  try
    await(TModalResult, MyWebForm.Execute);
  finally
    MyWebForm.Free;
  end;
end;

procedure TAssetEditForm.LoadModelInfo(S: String; Default: Boolean);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  ImageBase64: String;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_model_load.php?sessionid=' + SessionId +
      '&pkey=' + S;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if Default then begin
        FuelTypeEdit.Value := MyJSONObject.GetJSONValue('fuel_type');
        FuelConsumptionEdit.Text := MyJSONObject.GetJSONValue('fuel_consumption');
        CarbonDioxideEdit.Text := MyJSONObject.GetJSONValue('carbon_dioxide');
        BatteryLockEdit.Checked := MyJSONObject.GetJSONValue('battery_lock') = 't';
      end;
      ImageBase64 := MyJSONObject.GetJSONValue('Picture');
      ModelImage.Bitmaps.Clear;
      if ImageBase64 <> EmptyStr then begin
        ModelImage.Bitmap.LoadFromURL(ImageBase64);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetEditForm.AssetModelEditChange(Sender: TObject);
begin
  LoadModelInfo(AssetModelEdit.Value, True);
  RecordChange(TObject(Sender));
end;

procedure TAssetEditForm.AssetModelLabelClick(Sender: TObject);
var
  MyWebForm: TModelListForm;
begin
  if SaveButton.Visible then begin
    MyWebForm := TModelListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TModelListForm, MyWebForm.Load());
    try
      MyWebForm.SelectButton.Visible := True;
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        RecordChange(TObject(Sender));
        // Refresh Brand / Model
        await(LoadBrandModelValues());
        // Update Brand
        AssetBrandEdit.Value := MyWebForm.BrandPKey;
        // Update Model
        LoadAssetModelList(MyWebForm.BrandPKey);
        AssetModelEdit.Value := MyWebForm.ModelPKey;
        LoadModelInfo(AssetModelEdit.Value, True);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TAssetEditForm.AssetStatusLabelClick(Sender: TObject);
var
  MyWebForm: TAssetStatusChangeForm;
begin
  if SaveButton.Visible then begin
    if PKey > 0 then begin
      MyWebForm := TAssetStatusChangeForm.Create(Self);
      MyWebForm.Popup := True;
      MyWebForm.Border := fbNone;
      MyWebForm.AssetPKey := IntToStr(PKey);
      await(TAssetStatusChangeForm, MyWebForm.Load());
      try
        // Update Fields
        MyWebForm.AssetIDEdit.Text := AssetIDEdit.Text;
        // Show Form
        await(TModalResult, MyWebForm.Execute);
        if MyWebForm.ModalResult = mrOK then begin
          // Update Status
          AssetStatusEdit.Value := MyWebForm.StatusPKey;
        end;
      finally
        MyWebForm.Free;
      end;
    end;
  end;
end;

procedure TAssetEditForm.ClearDeviceButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  MessageText: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MessageText := 'Remove link?';
  MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog(MessageText, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
  if MyModalResult = mrYes then begin
    // Update Link
    MyWebRequest := THTTPRequest.Create(Self);
    try
      // Request
      MyWebRequest.URL :=
        url_php + 'default_object_device_clear.php?sessionid=' + SessionId +
        '&object=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Response
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          DevicePKeyEdit.Text := EmptyStr;
          AssetNoEdit.Text := EmptyStr;
        end;
      finally
        MyJSON.Free;
      end;
    finally
      MyWebRequest.Free;
    end;
  end;
end;

procedure TAssetEditForm.ClearDriverButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  MessageText: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MessageText := 'Remove link?';
  MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog(MessageText, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
  if MyModalResult = mrYes then begin
    // Update Link
    MyWebRequest := THTTPRequest.Create(Self);
    try
      // Request
      MyWebRequest.URL :=
        url_php + 'default_object_person_clear.php?sessionid=' + SessionId +
        '&object=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Response
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          DriverPKeyEdit.Text := EmptyStr;
        end;
      finally
        MyJSON.Free;
      end;
    finally
      MyWebRequest.Free;
    end;
  end;
end;

procedure TAssetEditForm.LoadDocumentList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  bDocumentsLoaded := False;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_document_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  DocumentDataset.Close;
  DocumentDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(DocumentDataset.Open);
end;

procedure TAssetEditForm.LicensePlateEditKeyPress(Sender: TObject;
  var Key: Char);
begin
  if not(Key in KeysAlphaNumeric) then Key := Chr(0);
end;

procedure TAssetEditForm.LoadAlertList;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_alert_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  AlertDataset.Close;
  AlertDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(AlertDataset.Open);
end;

procedure TAssetEditForm.LoadDeviceList;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_device_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  DeviceDataset.Close;
  DeviceDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(DeviceDataset.Open);
  DeviceDBAdapter.Active := True;
  DeviceDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TAssetEditForm.LoadDriverList;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_driver_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  DriverDataset.Close;
  DriverDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(DriverDataset.Open);
  DriverDBAdapter.Active := True;
  DriverDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TAssetEditForm.UpdateDocumentButtons();
var
  DocumentPKey: Integer;
begin
  if not(DocumentDataset.IsEmpty) then begin
    DocumentPKey := StrToInt(DocumentGrid.Cells[0, DocumentGrid.SelectedRow[0]]);
    if DocumentDataset.Locate('PKey', DocumentPKey, []) then begin
      if (Trim(DocumentDataset.FieldByName('Type').AsString) = 'OTHER') then begin
        EditDocumentButton.Caption := 'Edit';
      end else begin
        EditDocumentButton.Caption := 'Replace';
      end;
    end;
  end else begin
    EditDocumentButton.Caption := 'Edit';
  end;
end;

procedure TAssetEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  AssetTypeLabel := TLabel.Create(Self);
  AssetGroupLabel := TLabel.Create(Self);
  AssetNoLabel := TLabel.Create(Self);
  AssetIDLabel := TLabel.Create(Self);
  OwnerLabel := TLabel.Create(Self);
  TenantLabel := TLabel.Create(Self);
  AssetStatusLabel := TLabel.Create(Self);
  UserType2Label := TLabel.Create(Self);
  UserType1Label := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  UserType3Label := TLabel.Create(Self);
  UserType4Label := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  AssetBrandLabel := TLabel.Create(Self);
  AssetModelLabel := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  AssetIDEdit := TEdit.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  PageControl := TTMSFNCPageControl.Create(Self);
  PageControlPage5 := TTMSFNCPageControlContainer.Create(Self);
  PageControlPage3 := TTMSFNCPageControlContainer.Create(Self);
  DeviceGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg3 := TMessageDlg.Create(Self);
  PageControlPage4 := TTMSFNCPageControlContainer.Create(Self);
  DriverGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg4 := TMessageDlg.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  DocumentGrid := TTMSFNCGrid.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  AddDocumentButton := TSpeedButton.Create(Self);
  DeleteDocumentButton := TSpeedButton.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  EditDocumentButton := TSpeedButton.Create(Self);
  ViewDocumentButton := TSpeedButton.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  AddAlertButton := TSpeedButton.Create(Self);
  DeleteAlertButton := TSpeedButton.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  AlertGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg2 := TMessageDlg.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  LicensePlateLabel := TLabel.Create(Self);
  ChassisNumberLabel := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  ColorLabel := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  OdoMeterLabel := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  MOTDateLabel := TLabel.Create(Self);
  LicensePlateEdit := TEdit.Create(Self);
  ChassisNumberEdit := TEdit.Create(Self);
  RemarkEdit := TMemo.Create(Self);
  YearManufacturedEdit := TEdit.Create(Self);
  DriverPKeyEdit := TEdit.Create(Self);
  SelectDriverButton := TBitBtn.Create(Self);
  ClearDriverButton := TBitBtn.Create(Self);
  FuelTypeEdit := TLookupComboBox.Create(Self);
  CarbonDioxideEdit := TEdit.Create(Self);
  FuelConsumptionEdit := TEdit.Create(Self);
  KeyNumberEdit := TEdit.Create(Self);
  KeyCommentEdit := TEdit.Create(Self);
  BatteryLockEdit := TCheckBox.Create(Self);
  ColorEdit := TTMSFNCColorPicker.Create(Self);
  OdoMeterEdit := TEdit.Create(Self);
  OdoMeterDiffEdit := TEdit.Create(Self);
  MOTDateEdit := TEdit.Create(Self);
  AssetGroupEdit := TLookupComboBox.Create(Self);
  AssetTypeEdit := TLookupComboBox.Create(Self);
  OwnerEdit := TLookupComboBox.Create(Self);
  TenantEdit := TLookupComboBox.Create(Self);
  AssetStatusEdit := TLookupComboBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  RepairHistoryButton := TSpeedButton.Create(Self);
  StatusHistoryButton := TSpeedButton.Create(Self);
  AssetNoEdit := TEdit.Create(Self);
  UserType2Edit := TLookupComboBox.Create(Self);
  UserType1Edit := TLookupComboBox.Create(Self);
  AssetBrandEdit := TLookupComboBox.Create(Self);
  AssetModelEdit := TLookupComboBox.Create(Self);
  DevicePKeyEdit := TEdit.Create(Self);
  SelectDeviceButton := TBitBtn.Create(Self);
  ClearDeviceButton := TBitBtn.Create(Self);
  UserType3Edit := TLookupComboBox.Create(Self);
  UserType4Edit := TLookupComboBox.Create(Self);
  AssetKindEdit := TLookupComboBox.Create(Self);
  ModelImage := TTMSFNCImage.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  AlertDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DeviceDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DriverDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  DocumentDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  AlertDataset := TClientDataSet.Create(Self);
  AlertDatasetPKey := TStringField.Create(Self);
  AlertDatasetName := TStringField.Create(Self);
  AlertDatasetOrigin := TStringField.Create(Self);
  AlertDataSource := TDataSource.Create(Self);
  DeviceDataset := TClientDataSet.Create(Self);
  DeviceDatasetPKey := TStringField.Create(Self);
  DeviceDatasetID := TStringField.Create(Self);
  DeviceDatasetFrom := TStringField.Create(Self);
  DeviceDatasetTill := TStringField.Create(Self);
  DeviceDataSource := TDataSource.Create(Self);
  WebConnection := TClientConnection.Create(Self);
  DriverDataSet := TClientDataSet.Create(Self);
  DriverDataSetPKey := TStringField.Create(Self);
  DriverDataSetName := TStringField.Create(Self);
  DriverDataSetFrom := TStringField.Create(Self);
  DriverDataSetTill := TStringField.Create(Self);
  DriverDataSource := TDataSource.Create(Self);
  DocumentDataSet := TClientDataSet.Create(Self);
  DocumentDataSetPKey := TStringField.Create(Self);
  DocumentDataSetID := TStringField.Create(Self);
  DocumentDataSetType := TStringField.Create(Self);
  DocumentDataSetExpiryDate := TStringField.Create(Self);
  DocumentDataSetTimestamp := TStringField.Create(Self);
  DocumentDataSetLength := TStringField.Create(Self);
  DocumentDataSource := TDataSource.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  AssetTypeLabel.BeforeLoadDFMValues;
  AssetGroupLabel.BeforeLoadDFMValues;
  AssetNoLabel.BeforeLoadDFMValues;
  AssetIDLabel.BeforeLoadDFMValues;
  OwnerLabel.BeforeLoadDFMValues;
  TenantLabel.BeforeLoadDFMValues;
  AssetStatusLabel.BeforeLoadDFMValues;
  UserType2Label.BeforeLoadDFMValues;
  UserType1Label.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  UserType3Label.BeforeLoadDFMValues;
  UserType4Label.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  AssetBrandLabel.BeforeLoadDFMValues;
  AssetModelLabel.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  AssetIDEdit.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  PageControl.BeforeLoadDFMValues;
  PageControlPage5.BeforeLoadDFMValues;
  PageControlPage3.BeforeLoadDFMValues;
  DeviceGrid.BeforeLoadDFMValues;
  WebMessageDlg3.BeforeLoadDFMValues;
  PageControlPage4.BeforeLoadDFMValues;
  DriverGrid.BeforeLoadDFMValues;
  WebMessageDlg4.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  DocumentGrid.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  AddDocumentButton.BeforeLoadDFMValues;
  DeleteDocumentButton.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  EditDocumentButton.BeforeLoadDFMValues;
  ViewDocumentButton.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  AddAlertButton.BeforeLoadDFMValues;
  DeleteAlertButton.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  AlertGrid.BeforeLoadDFMValues;
  WebMessageDlg2.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  LicensePlateLabel.BeforeLoadDFMValues;
  ChassisNumberLabel.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  ColorLabel.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  OdoMeterLabel.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  MOTDateLabel.BeforeLoadDFMValues;
  LicensePlateEdit.BeforeLoadDFMValues;
  ChassisNumberEdit.BeforeLoadDFMValues;
  RemarkEdit.BeforeLoadDFMValues;
  YearManufacturedEdit.BeforeLoadDFMValues;
  DriverPKeyEdit.BeforeLoadDFMValues;
  SelectDriverButton.BeforeLoadDFMValues;
  ClearDriverButton.BeforeLoadDFMValues;
  FuelTypeEdit.BeforeLoadDFMValues;
  CarbonDioxideEdit.BeforeLoadDFMValues;
  FuelConsumptionEdit.BeforeLoadDFMValues;
  KeyNumberEdit.BeforeLoadDFMValues;
  KeyCommentEdit.BeforeLoadDFMValues;
  BatteryLockEdit.BeforeLoadDFMValues;
  ColorEdit.BeforeLoadDFMValues;
  OdoMeterEdit.BeforeLoadDFMValues;
  OdoMeterDiffEdit.BeforeLoadDFMValues;
  MOTDateEdit.BeforeLoadDFMValues;
  AssetGroupEdit.BeforeLoadDFMValues;
  AssetTypeEdit.BeforeLoadDFMValues;
  OwnerEdit.BeforeLoadDFMValues;
  TenantEdit.BeforeLoadDFMValues;
  AssetStatusEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  RepairHistoryButton.BeforeLoadDFMValues;
  StatusHistoryButton.BeforeLoadDFMValues;
  AssetNoEdit.BeforeLoadDFMValues;
  UserType2Edit.BeforeLoadDFMValues;
  UserType1Edit.BeforeLoadDFMValues;
  AssetBrandEdit.BeforeLoadDFMValues;
  AssetModelEdit.BeforeLoadDFMValues;
  DevicePKeyEdit.BeforeLoadDFMValues;
  SelectDeviceButton.BeforeLoadDFMValues;
  ClearDeviceButton.BeforeLoadDFMValues;
  UserType3Edit.BeforeLoadDFMValues;
  UserType4Edit.BeforeLoadDFMValues;
  AssetKindEdit.BeforeLoadDFMValues;
  ModelImage.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  AlertDBAdapter.BeforeLoadDFMValues;
  DeviceDBAdapter.BeforeLoadDFMValues;
  DriverDBAdapter.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  DocumentDBAdapter.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  AlertDataset.BeforeLoadDFMValues;
  AlertDatasetPKey.BeforeLoadDFMValues;
  AlertDatasetName.BeforeLoadDFMValues;
  AlertDatasetOrigin.BeforeLoadDFMValues;
  AlertDataSource.BeforeLoadDFMValues;
  DeviceDataset.BeforeLoadDFMValues;
  DeviceDatasetPKey.BeforeLoadDFMValues;
  DeviceDatasetID.BeforeLoadDFMValues;
  DeviceDatasetFrom.BeforeLoadDFMValues;
  DeviceDatasetTill.BeforeLoadDFMValues;
  DeviceDataSource.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  DriverDataSet.BeforeLoadDFMValues;
  DriverDataSetPKey.BeforeLoadDFMValues;
  DriverDataSetName.BeforeLoadDFMValues;
  DriverDataSetFrom.BeforeLoadDFMValues;
  DriverDataSetTill.BeforeLoadDFMValues;
  DriverDataSource.BeforeLoadDFMValues;
  DocumentDataSet.BeforeLoadDFMValues;
  DocumentDataSetPKey.BeforeLoadDFMValues;
  DocumentDataSetID.BeforeLoadDFMValues;
  DocumentDataSetType.BeforeLoadDFMValues;
  DocumentDataSetExpiryDate.BeforeLoadDFMValues;
  DocumentDataSetTimestamp.BeforeLoadDFMValues;
  DocumentDataSetLength.BeforeLoadDFMValues;
  DocumentDataSource.BeforeLoadDFMValues;
  try
    Name := 'AssetEditForm';
    Width := 1291;
    Height := 768;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 289;
    WebLabel2.Top := 288;
    WebLabel2.Width := 85;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Asset Group';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1291;
    FormPanel.Height := 768;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1285;
    ContentPanel.Height := 725;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    AssetTypeLabel.SetParentComponent(ContentPanel);
    AssetTypeLabel.Name := 'AssetTypeLabel';
    AssetTypeLabel.Left := 30;
    AssetTypeLabel.Top := 86;
    AssetTypeLabel.Width := 73;
    AssetTypeLabel.Height := 21;
    AssetTypeLabel.Caption := 'Asset Type';
    AssetTypeLabel.Font.Charset := ANSI_CHARSET;
    AssetTypeLabel.Font.Color := 9010544;
    AssetTypeLabel.Font.Height := -16;
    AssetTypeLabel.Font.Name := 'Segoe UI';
    AssetTypeLabel.Font.Style := [];
    AssetTypeLabel.HeightPercent := 100.000000000000000000;
    AssetTypeLabel.ParentFont := False;
    AssetTypeLabel.WidthPercent := 100.000000000000000000;
    AssetGroupLabel.SetParentComponent(ContentPanel);
    AssetGroupLabel.Name := 'AssetGroupLabel';
    AssetGroupLabel.Left := 30;
    AssetGroupLabel.Top := 155;
    AssetGroupLabel.Width := 85;
    AssetGroupLabel.Height := 21;
    AssetGroupLabel.Caption := 'Asset Group';
    AssetGroupLabel.Font.Charset := ANSI_CHARSET;
    AssetGroupLabel.Font.Color := 9010544;
    AssetGroupLabel.Font.Height := -16;
    AssetGroupLabel.Font.Name := 'Segoe UI';
    AssetGroupLabel.Font.Style := [];
    AssetGroupLabel.HeightPercent := 100.000000000000000000;
    AssetGroupLabel.ParentFont := False;
    AssetGroupLabel.WidthPercent := 100.000000000000000000;
    AssetNoLabel.SetParentComponent(ContentPanel);
    AssetNoLabel.Name := 'AssetNoLabel';
    AssetNoLabel.Left := 366;
    AssetNoLabel.Top := 291;
    AssetNoLabel.Width := 108;
    AssetNoLabel.Height := 21;
    AssetNoLabel.Caption := 'Device Number';
    AssetNoLabel.Font.Charset := ANSI_CHARSET;
    AssetNoLabel.Font.Color := 9010544;
    AssetNoLabel.Font.Height := -16;
    AssetNoLabel.Font.Name := 'Segoe UI';
    AssetNoLabel.Font.Style := [];
    AssetNoLabel.HeightPercent := 100.000000000000000000;
    AssetNoLabel.ParentFont := False;
    AssetNoLabel.WidthPercent := 100.000000000000000000;
    AssetIDLabel.SetParentComponent(ContentPanel);
    AssetIDLabel.Name := 'AssetIDLabel';
    AssetIDLabel.Left := 30;
    AssetIDLabel.Top := 220;
    AssetIDLabel.Width := 56;
    AssetIDLabel.Height := 21;
    AssetIDLabel.Caption := 'Asset ID';
    AssetIDLabel.Font.Charset := ANSI_CHARSET;
    AssetIDLabel.Font.Color := 9010544;
    AssetIDLabel.Font.Height := -16;
    AssetIDLabel.Font.Name := 'Segoe UI';
    AssetIDLabel.Font.Style := [];
    AssetIDLabel.HeightPercent := 100.000000000000000000;
    AssetIDLabel.ParentFont := False;
    AssetIDLabel.WidthPercent := 100.000000000000000000;
    OwnerLabel.SetParentComponent(ContentPanel);
    OwnerLabel.Name := 'OwnerLabel';
    OwnerLabel.Left := 28;
    OwnerLabel.Top := 432;
    OwnerLabel.Width := 140;
    OwnerLabel.Height := 21;
    OwnerLabel.Caption := 'Owner (Economical)';
    OwnerLabel.Font.Charset := ANSI_CHARSET;
    OwnerLabel.Font.Color := 9010544;
    OwnerLabel.Font.Height := -16;
    OwnerLabel.Font.Name := 'Segoe UI';
    OwnerLabel.Font.Style := [];
    OwnerLabel.HeightPercent := 100.000000000000000000;
    OwnerLabel.ParentFont := False;
    OwnerLabel.WidthPercent := 100.000000000000000000;
    TenantLabel.SetParentComponent(ContentPanel);
    TenantLabel.Name := 'TenantLabel';
    TenantLabel.Left := 366;
    TenantLabel.Top := 432;
    TenantLabel.Width := 105;
    TenantLabel.Height := 21;
    TenantLabel.Caption := 'Tenant (Lessee)';
    TenantLabel.Font.Charset := ANSI_CHARSET;
    TenantLabel.Font.Color := 9010544;
    TenantLabel.Font.Height := -16;
    TenantLabel.Font.Name := 'Segoe UI';
    TenantLabel.Font.Style := [];
    TenantLabel.HeightPercent := 100.000000000000000000;
    TenantLabel.ParentFont := False;
    TenantLabel.WidthPercent := 100.000000000000000000;
    AssetStatusLabel.SetParentComponent(ContentPanel);
    AssetStatusLabel.Name := 'AssetStatusLabel';
    AssetStatusLabel.Left := 368;
    AssetStatusLabel.Top := 19;
    AssetStatusLabel.Width := 42;
    AssetStatusLabel.Height := 21;
    AssetStatusLabel.Caption := 'Status';
    AssetStatusLabel.Font.Charset := ANSI_CHARSET;
    AssetStatusLabel.Font.Color := 16755027;
    AssetStatusLabel.Font.Height := -16;
    AssetStatusLabel.Font.Name := 'Segoe UI';
    AssetStatusLabel.Font.Style := [fsUnderline];
    AssetStatusLabel.HeightPercent := 100.000000000000000000;
    AssetStatusLabel.ParentFont := False;
    AssetStatusLabel.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusLabel, Self, 'OnClick', 'AssetStatusLabelClick');
    UserType2Label.SetParentComponent(ContentPanel);
    UserType2Label.Name := 'UserType2Label';
    UserType2Label.Left := 368;
    UserType2Label.Top := 501;
    UserType2Label.Width := 92;
    UserType2Label.Height := 21;
    UserType2Label.Caption := 'Site Foreman';
    UserType2Label.Font.Charset := ANSI_CHARSET;
    UserType2Label.Font.Color := 9010544;
    UserType2Label.Font.Height := -16;
    UserType2Label.Font.Name := 'Segoe UI';
    UserType2Label.Font.Style := [];
    UserType2Label.HeightPercent := 100.000000000000000000;
    UserType2Label.ParentFont := False;
    UserType2Label.WidthPercent := 100.000000000000000000;
    UserType1Label.SetParentComponent(ContentPanel);
    UserType1Label.Name := 'UserType1Label';
    UserType1Label.Left := 30;
    UserType1Label.Top := 501;
    UserType1Label.Width := 114;
    UserType1Label.Height := 21;
    UserType1Label.Caption := 'Project Manager';
    UserType1Label.Font.Charset := ANSI_CHARSET;
    UserType1Label.Font.Color := 9010544;
    UserType1Label.Font.Height := -16;
    UserType1Label.Font.Name := 'Segoe UI';
    UserType1Label.Font.Style := [];
    UserType1Label.HeightPercent := 100.000000000000000000;
    UserType1Label.ParentFont := False;
    UserType1Label.WidthPercent := 100.000000000000000000;
    WebLabel17.SetParentComponent(ContentPanel);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 30;
    WebLabel17.Top := 292;
    WebLabel17.Width := 65;
    WebLabel17.Height := 21;
    WebLabel17.Caption := 'Device ID';
    WebLabel17.Font.Charset := ANSI_CHARSET;
    WebLabel17.Font.Color := 9010544;
    WebLabel17.Font.Height := -16;
    WebLabel17.Font.Name := 'Segoe UI';
    WebLabel17.Font.Style := [];
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.ParentFont := False;
    WebLabel17.WidthPercent := 100.000000000000000000;
    UserType3Label.SetParentComponent(ContentPanel);
    UserType3Label.Name := 'UserType3Label';
    UserType3Label.Left := 368;
    UserType3Label.Top := 572;
    UserType3Label.Width := 62;
    UserType3Label.Height := 21;
    UserType3Label.Caption := 'Foreman';
    UserType3Label.Font.Charset := ANSI_CHARSET;
    UserType3Label.Font.Color := 9010544;
    UserType3Label.Font.Height := -16;
    UserType3Label.Font.Name := 'Segoe UI';
    UserType3Label.Font.Style := [];
    UserType3Label.HeightPercent := 100.000000000000000000;
    UserType3Label.ParentFont := False;
    UserType3Label.WidthPercent := 100.000000000000000000;
    UserType4Label.SetParentComponent(ContentPanel);
    UserType4Label.Name := 'UserType4Label';
    UserType4Label.Left := 30;
    UserType4Label.Top := 572;
    UserType4Label.Width := 51;
    UserType4Label.Height := 21;
    UserType4Label.Caption := 'Worker';
    UserType4Label.Font.Charset := ANSI_CHARSET;
    UserType4Label.Font.Color := 9010544;
    UserType4Label.Font.Height := -16;
    UserType4Label.Font.Name := 'Segoe UI';
    UserType4Label.Font.Style := [];
    UserType4Label.HeightPercent := 100.000000000000000000;
    UserType4Label.ParentFont := False;
    UserType4Label.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(ContentPanel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 30;
    WebLabel3.Top := 19;
    WebLabel3.Width := 72;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Asset Kind';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    AssetBrandLabel.SetParentComponent(ContentPanel);
    AssetBrandLabel.Name := 'AssetBrandLabel';
    AssetBrandLabel.Left := 28;
    AssetBrandLabel.Top := 363;
    AssetBrandLabel.Width := 41;
    AssetBrandLabel.Height := 21;
    AssetBrandLabel.Caption := 'Brand';
    AssetBrandLabel.Font.Charset := ANSI_CHARSET;
    AssetBrandLabel.Font.Color := 16755027;
    AssetBrandLabel.Font.Height := -16;
    AssetBrandLabel.Font.Name := 'Segoe UI';
    AssetBrandLabel.Font.Style := [fsUnderline];
    AssetBrandLabel.HeightPercent := 100.000000000000000000;
    AssetBrandLabel.ParentFont := False;
    AssetBrandLabel.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandLabel, Self, 'OnClick', 'AssetBrandLabelClick');
    AssetModelLabel.SetParentComponent(ContentPanel);
    AssetModelLabel.Name := 'AssetModelLabel';
    AssetModelLabel.Left := 369;
    AssetModelLabel.Top := 363;
    AssetModelLabel.Width := 44;
    AssetModelLabel.Height := 21;
    AssetModelLabel.Caption := 'Model';
    AssetModelLabel.Font.Charset := ANSI_CHARSET;
    AssetModelLabel.Font.Color := 16755027;
    AssetModelLabel.Font.Height := -16;
    AssetModelLabel.Font.Name := 'Segoe UI';
    AssetModelLabel.Font.Style := [fsUnderline];
    AssetModelLabel.HeightPercent := 100.000000000000000000;
    AssetModelLabel.ParentFont := False;
    AssetModelLabel.WidthPercent := 100.000000000000000000;
    SetEvent(AssetModelLabel, Self, 'OnClick', 'AssetModelLabelClick');
    WebLabel16.SetParentComponent(ContentPanel);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 369;
    WebLabel16.Top := 83;
    WebLabel16.Width := 91;
    WebLabel16.Height := 21;
    WebLabel16.Caption := 'Model image';
    WebLabel16.Font.Charset := ANSI_CHARSET;
    WebLabel16.Font.Color := 9010544;
    WebLabel16.Font.Height := -16;
    WebLabel16.Font.Name := 'Segoe UI';
    WebLabel16.Font.Style := [];
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WidthPercent := 100.000000000000000000;
    AssetIDEdit.SetParentComponent(ContentPanel);
    AssetIDEdit.Name := 'AssetIDEdit';
    AssetIDEdit.Left := 30;
    AssetIDEdit.Top := 245;
    AssetIDEdit.Width := 282;
    AssetIDEdit.Height := 32;
    AssetIDEdit.CharCase := wecUpperCase;
    AssetIDEdit.ChildOrder := 1;
    AssetIDEdit.ElementClassName := 'MyWebEdit';
    AssetIDEdit.Font.Charset := ANSI_CHARSET;
    AssetIDEdit.Font.Color := clFuchsia;
    AssetIDEdit.Font.Height := -16;
    AssetIDEdit.Font.Name := 'Segoe UI';
    AssetIDEdit.Font.Style := [];
    AssetIDEdit.HeightPercent := 100.000000000000000000;
    AssetIDEdit.ParentFont := False;
    AssetIDEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetIDEdit, Self, 'OnChange', 'RecordChange');
    ListButtonOnlinePanel.SetParentComponent(ContentPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 673;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 602;
    ListButtonOnlinePanel.Height := 670;
    ListButtonOnlinePanel.Margins.Right := 10;
    ListButtonOnlinePanel.Align := alRight;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 1;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    PageControl.SetParentComponent(ListButtonOnlinePanel);
    PageControl.Name := 'PageControl';
    PageControl.AlignWithMargins := True;
    PageControl.Left := 10;
    PageControl.Top := 0;
    PageControl.Width := 582;
    PageControl.Height := 662;
    PageControl.Margins.Left := 10;
    PageControl.Margins.Top := 0;
    PageControl.Margins.Right := 10;
    PageControl.Margins.Bottom := 8;
    PageControl.Align := alClient;
    PageControl.BevelEdges := [];
    PageControl.BevelInner := bvNone;
    PageControl.BevelOuter := bvNone;
    PageControl.ParentDoubleBuffered := False;
    PageControl.Color := clBtnFace;
    PageControl.DoubleBuffered := True;
    PageControl.Font.Charset := DEFAULT_CHARSET;
    PageControl.Font.Color := clWindowText;
    PageControl.Font.Height := -16;
    PageControl.Font.Name := 'Tahoma';
    PageControl.Font.Style := [];
    PageControl.ParentFont := False;
    PageControl.TabOrder := 0;
    PageControl.Fill.Kind := gfkNone;
    PageControl.Fill.ColorTo := clWhite;
    PageControl.Stroke.Kind := gskNone;
    PageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    PageControl.TabAppearance.Font.Color := clAqua;
    PageControl.TabAppearance.Font.Height := -16;
    PageControl.TabAppearance.Font.Name := 'Segoe UI';
    PageControl.TabAppearance.Font.Style := [];
    PageControl.TabAppearance.Fill.Color := 14408667;
    PageControl.TabAppearance.Stroke.Color := -1;
    PageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    PageControl.TabAppearance.HoverFill.Color := 15658734;
    PageControl.TabAppearance.HoverStroke.Color := -1;
    PageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    PageControl.TabAppearance.Shape := tsRectangle;
    PageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    PageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    PageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    PageControl.TabAppearance.TextColor := clGrayText;
    PageControl.TabAppearance.ActiveTextColor := clGrayText;
    PageControl.TabAppearance.HoverTextColor := clGrayText;
    PageControl.TabAppearance.ShowFocus := False;
    PageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    PageControl.TabAppearance.BadgeFont.Color := 139;
    PageControl.TabAppearance.BadgeFont.Height := -11;
    PageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    PageControl.TabAppearance.BadgeFont.Style := [fsBold];
    PageControl.ButtonAppearance.CloseIcon.LoadFromFile('uAssetEdit.ListButtonOnlinePanel.PageControl.ButtonAppearance.CloseIcon.svg');
    PageControl.ButtonAppearance.InsertIcon.LoadFromFile('uAssetEdit.ListButtonOnlinePanel.PageControl.ButtonAppearance.InsertIcon.svg');
    PageControl.ButtonAppearance.TabListIcon.LoadFromFile('uAssetEdit.ListButtonOnlinePanel.PageControl.ButtonAppearance.TabListIcon.svg');
    PageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uAssetEdit.ListButtonOnlinePanel.PageControl.ButtonAppearance.ScrollNextIcon.svg');
    PageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uAssetEdit.ListButtonOnlinePanel.PageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    PageControl.TabSize.Height := 45.000000000000000000;
    PageControl.TabSize.Margins.Left := 8.000000000000000000;
    PageControl.TabSize.Margins.Top := 8.000000000000000000;
    PageControl.TabSize.Margins.Right := 8.000000000000000000;
    PageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    PageControl.Pages.Clear;
    with PageControl.Pages.Add do
    begin
      Text := 'Vehicle';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Documents';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Alerts';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Devices';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Drivers';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'I/O';
      TabVisible := False;
      Visible := False;
    end;
    SetEvent(PageControl, Self, 'OnChangePage', 'PageControlChangePage');
    PageControlPage5.SetParentComponent(PageControl);
    PageControlPage5.Name := 'PageControlPage5';
    PageControlPage5.AlignWithMargins := True;
    PageControlPage5.Left := 0;
    PageControlPage5.Top := 52;
    PageControlPage5.Width := 582;
    PageControlPage5.Height := 610;
    PageControlPage5.Margins.Left := 0;
    PageControlPage5.Margins.Top := 52;
    PageControlPage5.Margins.Right := 0;
    PageControlPage5.Margins.Bottom := 0;
    PageControlPage5.Align := alClient;
    PageControlPage5.ParentDoubleBuffered := False;
    PageControlPage5.DoubleBuffered := True;
    PageControlPage5.TabStop := False;
    PageControlPage5.TabOrder := 5;
    PageControlPage5.Visible := False;
    PageControlPage5.PageIndex := 5;
    PageControlPage5.IsActive := False;
    PageControlPage3.SetParentComponent(PageControl);
    PageControlPage3.Name := 'PageControlPage3';
    PageControlPage3.AlignWithMargins := True;
    PageControlPage3.Left := 0;
    PageControlPage3.Top := 52;
    PageControlPage3.Width := 582;
    PageControlPage3.Height := 610;
    PageControlPage3.Margins.Left := 0;
    PageControlPage3.Margins.Top := 52;
    PageControlPage3.Margins.Right := 0;
    PageControlPage3.Margins.Bottom := 0;
    PageControlPage3.Align := alClient;
    PageControlPage3.ParentDoubleBuffered := False;
    PageControlPage3.DoubleBuffered := True;
    PageControlPage3.TabStop := False;
    PageControlPage3.TabOrder := 3;
    PageControlPage3.Visible := False;
    PageControlPage3.PageIndex := 3;
    PageControlPage3.IsActive := False;
    DeviceGrid.SetParentComponent(PageControlPage3);
    DeviceGrid.Name := 'DeviceGrid';
    DeviceGrid.AlignWithMargins := True;
    DeviceGrid.Left := 20;
    DeviceGrid.Top := 20;
    DeviceGrid.Width := 542;
    DeviceGrid.Height := 570;
    DeviceGrid.Margins.Left := 20;
    DeviceGrid.Margins.Top := 20;
    DeviceGrid.Margins.Right := 20;
    DeviceGrid.Margins.Bottom := 20;
    DeviceGrid.AdaptToStyle := True;
    DeviceGrid.Align := alClient;
    DeviceGrid.BevelInner := bvNone;
    DeviceGrid.BevelOuter := bvNone;
    DeviceGrid.BevelWidth := 2;
    DeviceGrid.Ctl3D := False;
    DeviceGrid.ParentCtl3D := False;
    DeviceGrid.ParentDoubleBuffered := False;
    DeviceGrid.DoubleBuffered := True;
    DeviceGrid.Font.Charset := DEFAULT_CHARSET;
    DeviceGrid.Font.Color := clWindowText;
    DeviceGrid.Font.Height := -11;
    DeviceGrid.Font.Name := 'Tahoma';
    DeviceGrid.Font.Style := [];
    DeviceGrid.ParentFont := False;
    DeviceGrid.TabOrder := 0;
    DeviceGrid.DefaultRowHeight := 34.000000000000000000;
    DeviceGrid.FixedColumns := 0;
    DeviceGrid.ColumnCount := 4;
    DeviceGrid.RowCount := 1;
    DeviceGrid.Options.Borders.FixedCellBorders := bNone;
    DeviceGrid.Options.ColumnSize.Stretch := True;
    DeviceGrid.Options.ColumnSize.StretchColumn := 1;
    DeviceGrid.Options.Editing.CalcFormat := '%g';
    DeviceGrid.Options.Grouping.CalcFormat := '%g';
    DeviceGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DeviceGrid.Options.HTMLExport.CellPadding := 10;
    DeviceGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DeviceGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DeviceGrid.Options.Mouse.ClickMargin := 0;
    DeviceGrid.Options.Mouse.ColumnSizeMargin := 6;
    DeviceGrid.Options.Mouse.RowSizeMargin := 6;
    DeviceGrid.Options.URL.Color := clSkyBlue;
    DeviceGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DeviceGrid.Options.Selection.Mode := smDisjunctRow;
    DeviceGrid.Adapter := DeviceDBAdapter;
    DeviceGrid.HorizontalScrollBarVisible := False;
    DeviceGrid.Columns.Clear;
    with DeviceGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with DeviceGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 301.000000000000000000;
    end;
    with DeviceGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 120.000000000000000000;
    end;
    with DeviceGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 120.000000000000000000;
    end;
    DeviceGrid.Fill.ColorTo := clSilver;
    DeviceGrid.DefaultFont.Charset := ANSI_CHARSET;
    DeviceGrid.DefaultFont.Color := clWindowText;
    DeviceGrid.DefaultFont.Height := -15;
    DeviceGrid.DefaultFont.Name := 'Segoe UI';
    DeviceGrid.DefaultFont.Style := [];
    DeviceGrid.Stroke.Color := 15987699;
    DeviceGrid.Stroke.Width := 2.000000000000000000;
    DeviceGrid.TopRow := 1;
    DeviceGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DeviceGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DeviceGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DeviceGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DeviceGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DeviceGrid.Appearance.FixedLayout.Font.Height := -16;
    DeviceGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DeviceGrid.Appearance.FixedLayout.Font.Style := [];
    DeviceGrid.Appearance.NormalLayout.Fill.Color := -1;
    DeviceGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DeviceGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DeviceGrid.Appearance.NormalLayout.Font.Color := clGray;
    DeviceGrid.Appearance.NormalLayout.Font.Height := -11;
    DeviceGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DeviceGrid.Appearance.NormalLayout.Font.Style := [];
    DeviceGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DeviceGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DeviceGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DeviceGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DeviceGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DeviceGrid.Appearance.GroupLayout.Font.Height := -11;
    DeviceGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DeviceGrid.Appearance.GroupLayout.Font.Style := [];
    DeviceGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DeviceGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DeviceGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DeviceGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DeviceGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DeviceGrid.Appearance.SummaryLayout.Font.Height := -11;
    DeviceGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DeviceGrid.Appearance.SummaryLayout.Font.Style := [];
    DeviceGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DeviceGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DeviceGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DeviceGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DeviceGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DeviceGrid.Appearance.SelectedLayout.Font.Height := -16;
    DeviceGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DeviceGrid.Appearance.SelectedLayout.Font.Style := [];
    DeviceGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DeviceGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DeviceGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DeviceGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DeviceGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DeviceGrid.Appearance.FocusedLayout.Font.Height := -16;
    DeviceGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DeviceGrid.Appearance.FocusedLayout.Font.Style := [];
    DeviceGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DeviceGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DeviceGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DeviceGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DeviceGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DeviceGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DeviceGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DeviceGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DeviceGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DeviceGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DeviceGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DeviceGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DeviceGrid.Appearance.BandLayout.Font.Color := clBlack;
    DeviceGrid.Appearance.BandLayout.Font.Height := -16;
    DeviceGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DeviceGrid.Appearance.BandLayout.Font.Style := [];
    DeviceGrid.Appearance.ProgressLayout.Color := 15385233;
    DeviceGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DeviceGrid.LeftCol := 1;
    DeviceGrid.ScrollMode := scmItemScrolling;
    DeviceGrid.GlobalFont.Scale := 1.000000000000000000;
    DeviceGrid.GlobalFont.Style := [];
    DeviceGrid.DesignTimeSampleData := True;
    WebMessageDlg3.SetParentComponent(DeviceGrid);
    WebMessageDlg3.Name := 'WebMessageDlg3';
    WebMessageDlg3.Left := 275;
    WebMessageDlg3.Top := 90;
    WebMessageDlg3.Width := 24;
    WebMessageDlg3.Height := 24;
    WebMessageDlg3.Buttons := [];
    WebMessageDlg3.Opacity := 0.200000000000000000;
    PageControlPage4.SetParentComponent(PageControl);
    PageControlPage4.Name := 'PageControlPage4';
    PageControlPage4.AlignWithMargins := True;
    PageControlPage4.Left := 0;
    PageControlPage4.Top := 52;
    PageControlPage4.Width := 582;
    PageControlPage4.Height := 610;
    PageControlPage4.Margins.Left := 0;
    PageControlPage4.Margins.Top := 52;
    PageControlPage4.Margins.Right := 0;
    PageControlPage4.Margins.Bottom := 0;
    PageControlPage4.Align := alClient;
    PageControlPage4.ParentDoubleBuffered := False;
    PageControlPage4.DoubleBuffered := True;
    PageControlPage4.TabStop := False;
    PageControlPage4.TabOrder := 4;
    PageControlPage4.Visible := False;
    PageControlPage4.PageIndex := 4;
    PageControlPage4.IsActive := False;
    DriverGrid.SetParentComponent(PageControlPage4);
    DriverGrid.Name := 'DriverGrid';
    DriverGrid.AlignWithMargins := True;
    DriverGrid.Left := 20;
    DriverGrid.Top := 20;
    DriverGrid.Width := 542;
    DriverGrid.Height := 570;
    DriverGrid.Margins.Left := 20;
    DriverGrid.Margins.Top := 20;
    DriverGrid.Margins.Right := 20;
    DriverGrid.Margins.Bottom := 20;
    DriverGrid.AdaptToStyle := True;
    DriverGrid.Align := alClient;
    DriverGrid.BevelInner := bvNone;
    DriverGrid.BevelOuter := bvNone;
    DriverGrid.BevelWidth := 2;
    DriverGrid.Ctl3D := False;
    DriverGrid.ParentCtl3D := False;
    DriverGrid.ParentDoubleBuffered := False;
    DriverGrid.DoubleBuffered := True;
    DriverGrid.Font.Charset := DEFAULT_CHARSET;
    DriverGrid.Font.Color := clWindowText;
    DriverGrid.Font.Height := -11;
    DriverGrid.Font.Name := 'Tahoma';
    DriverGrid.Font.Style := [];
    DriverGrid.ParentFont := False;
    DriverGrid.TabOrder := 0;
    DriverGrid.DefaultRowHeight := 34.000000000000000000;
    DriverGrid.FixedColumns := 0;
    DriverGrid.ColumnCount := 2;
    DriverGrid.RowCount := 1;
    DriverGrid.Options.Borders.FixedCellBorders := bNone;
    DriverGrid.Options.ColumnSize.Stretch := True;
    DriverGrid.Options.ColumnSize.StretchColumn := 1;
    DriverGrid.Options.Editing.CalcFormat := '%g';
    DriverGrid.Options.Grouping.CalcFormat := '%g';
    DriverGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DriverGrid.Options.HTMLExport.CellPadding := 10;
    DriverGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DriverGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DriverGrid.Options.Mouse.ClickMargin := 0;
    DriverGrid.Options.Mouse.ColumnSizeMargin := 6;
    DriverGrid.Options.Mouse.RowSizeMargin := 6;
    DriverGrid.Options.URL.Color := clSkyBlue;
    DriverGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DriverGrid.Options.Selection.Mode := smDisjunctRow;
    DriverGrid.Adapter := DriverDBAdapter;
    DriverGrid.HorizontalScrollBarVisible := False;
    DriverGrid.Columns.Clear;
    with DriverGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with DriverGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 541.000000000000000000;
    end;
    with DriverGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 120.000000000000000000;
    end;
    with DriverGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 120.000000000000000000;
    end;
    DriverGrid.Fill.ColorTo := clSilver;
    DriverGrid.DefaultFont.Charset := ANSI_CHARSET;
    DriverGrid.DefaultFont.Color := clWindowText;
    DriverGrid.DefaultFont.Height := -15;
    DriverGrid.DefaultFont.Name := 'Segoe UI';
    DriverGrid.DefaultFont.Style := [];
    DriverGrid.Stroke.Color := 15987699;
    DriverGrid.Stroke.Width := 2.000000000000000000;
    DriverGrid.TopRow := 1;
    DriverGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DriverGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DriverGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DriverGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DriverGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DriverGrid.Appearance.FixedLayout.Font.Height := -16;
    DriverGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DriverGrid.Appearance.FixedLayout.Font.Style := [];
    DriverGrid.Appearance.NormalLayout.Fill.Color := -1;
    DriverGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DriverGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DriverGrid.Appearance.NormalLayout.Font.Color := clGray;
    DriverGrid.Appearance.NormalLayout.Font.Height := -11;
    DriverGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DriverGrid.Appearance.NormalLayout.Font.Style := [];
    DriverGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DriverGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DriverGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DriverGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DriverGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DriverGrid.Appearance.GroupLayout.Font.Height := -11;
    DriverGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DriverGrid.Appearance.GroupLayout.Font.Style := [];
    DriverGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DriverGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DriverGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DriverGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DriverGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DriverGrid.Appearance.SummaryLayout.Font.Height := -11;
    DriverGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DriverGrid.Appearance.SummaryLayout.Font.Style := [];
    DriverGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DriverGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DriverGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DriverGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DriverGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DriverGrid.Appearance.SelectedLayout.Font.Height := -16;
    DriverGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DriverGrid.Appearance.SelectedLayout.Font.Style := [];
    DriverGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DriverGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DriverGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DriverGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DriverGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DriverGrid.Appearance.FocusedLayout.Font.Height := -16;
    DriverGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DriverGrid.Appearance.FocusedLayout.Font.Style := [];
    DriverGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DriverGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DriverGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DriverGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DriverGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DriverGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DriverGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DriverGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DriverGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DriverGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DriverGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DriverGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DriverGrid.Appearance.BandLayout.Font.Color := clBlack;
    DriverGrid.Appearance.BandLayout.Font.Height := -16;
    DriverGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DriverGrid.Appearance.BandLayout.Font.Style := [];
    DriverGrid.Appearance.ProgressLayout.Color := 15385233;
    DriverGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DriverGrid.LeftCol := 1;
    DriverGrid.ScrollMode := scmItemScrolling;
    DriverGrid.GlobalFont.Scale := 1.000000000000000000;
    DriverGrid.GlobalFont.Style := [];
    DriverGrid.DesignTimeSampleData := True;
    WebMessageDlg4.SetParentComponent(DriverGrid);
    WebMessageDlg4.Name := 'WebMessageDlg4';
    WebMessageDlg4.Left := 275;
    WebMessageDlg4.Top := 90;
    WebMessageDlg4.Width := 24;
    WebMessageDlg4.Height := 24;
    WebMessageDlg4.Buttons := [];
    WebMessageDlg4.Opacity := 0.200000000000000000;
    TMSFNCPageControl1Page1.SetParentComponent(PageControl);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 582;
    TMSFNCPageControl1Page1.Height := 610;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    DocumentGrid.SetParentComponent(TMSFNCPageControl1Page1);
    DocumentGrid.Name := 'DocumentGrid';
    DocumentGrid.AlignWithMargins := True;
    DocumentGrid.Left := 20;
    DocumentGrid.Top := 20;
    DocumentGrid.Width := 542;
    DocumentGrid.Height := 529;
    DocumentGrid.Margins.Left := 20;
    DocumentGrid.Margins.Top := 20;
    DocumentGrid.Margins.Right := 20;
    DocumentGrid.Margins.Bottom := 10;
    DocumentGrid.AdaptToStyle := True;
    DocumentGrid.Align := alClient;
    DocumentGrid.BevelInner := bvNone;
    DocumentGrid.BevelOuter := bvNone;
    DocumentGrid.BevelWidth := 2;
    DocumentGrid.Ctl3D := False;
    DocumentGrid.ParentCtl3D := False;
    DocumentGrid.ParentDoubleBuffered := False;
    DocumentGrid.DoubleBuffered := True;
    DocumentGrid.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Font.Color := clWindowText;
    DocumentGrid.Font.Height := -11;
    DocumentGrid.Font.Name := 'Tahoma';
    DocumentGrid.Font.Style := [];
    DocumentGrid.ParentFont := False;
    DocumentGrid.TabOrder := 0;
    DocumentGrid.DefaultRowHeight := 34.000000000000000000;
    DocumentGrid.FixedColumns := 0;
    DocumentGrid.ColumnCount := 6;
    DocumentGrid.RowCount := 1;
    DocumentGrid.Options.Borders.FixedCellBorders := bNone;
    DocumentGrid.Options.ColumnSize.Stretch := True;
    DocumentGrid.Options.ColumnSize.StretchColumn := 2;
    DocumentGrid.Options.Editing.CalcFormat := '%g';
    DocumentGrid.Options.Grouping.CalcFormat := '%g';
    DocumentGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DocumentGrid.Options.HTMLExport.CellPadding := 10;
    DocumentGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DocumentGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DocumentGrid.Options.Mouse.ClickMargin := 0;
    DocumentGrid.Options.Mouse.ColumnSizeMargin := 6;
    DocumentGrid.Options.Mouse.RowSizeMargin := 6;
    DocumentGrid.Options.URL.Color := clSkyBlue;
    DocumentGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DocumentGrid.Options.Selection.Mode := smDisjunctRow;
    SetEvent(DocumentGrid, Self, 'OnSelectedCell', 'DocumentGridSelectedCell');
    DocumentGrid.Adapter := DocumentDBAdapter;
    DocumentGrid.HorizontalScrollBarVisible := False;
    DocumentGrid.Columns.Clear;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 130.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 210.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 1.000000000000000000;
    end;
    DocumentGrid.Fill.ColorTo := clSilver;
    DocumentGrid.DefaultFont.Charset := ANSI_CHARSET;
    DocumentGrid.DefaultFont.Color := clWindowText;
    DocumentGrid.DefaultFont.Height := -15;
    DocumentGrid.DefaultFont.Name := 'Segoe UI';
    DocumentGrid.DefaultFont.Style := [];
    DocumentGrid.Stroke.Color := 15987699;
    DocumentGrid.Stroke.Width := 2.000000000000000000;
    DocumentGrid.TopRow := 1;
    DocumentGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DocumentGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DocumentGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.FixedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FixedLayout.Font.Style := [];
    DocumentGrid.Appearance.NormalLayout.Fill.Color := -1;
    DocumentGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DocumentGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.NormalLayout.Font.Color := clGray;
    DocumentGrid.Appearance.NormalLayout.Font.Height := -11;
    DocumentGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.NormalLayout.Font.Style := [];
    DocumentGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DocumentGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.GroupLayout.Font.Height := -11;
    DocumentGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DocumentGrid.Appearance.GroupLayout.Font.Style := [];
    DocumentGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DocumentGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.SummaryLayout.Font.Height := -11;
    DocumentGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DocumentGrid.Appearance.SummaryLayout.Font.Style := [];
    DocumentGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DocumentGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.SelectedLayout.Font.Height := -16;
    DocumentGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.SelectedLayout.Font.Style := [];
    DocumentGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.FocusedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FocusedLayout.Font.Style := [];
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DocumentGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DocumentGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DocumentGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.BandLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.BandLayout.Font.Height := -16;
    DocumentGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DocumentGrid.Appearance.BandLayout.Font.Style := [];
    DocumentGrid.Appearance.ProgressLayout.Color := 15385233;
    DocumentGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DocumentGrid.LeftCol := 1;
    DocumentGrid.ScrollMode := scmItemScrolling;
    DocumentGrid.GlobalFont.Scale := 1.000000000000000000;
    DocumentGrid.GlobalFont.Style := [];
    DocumentGrid.DesignTimeSampleData := True;
    WebPanel2.SetParentComponent(TMSFNCPageControl1Page1);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.AlignWithMargins := True;
    WebPanel2.Left := 1;
    WebPanel2.Top := 560;
    WebPanel2.Width := 580;
    WebPanel2.Height := 49;
    WebPanel2.Margins.Left := 1;
    WebPanel2.Margins.Top := 1;
    WebPanel2.Margins.Right := 1;
    WebPanel2.Margins.Bottom := 1;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clNone;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 1;
    WebPanel2.Color := clWhite;
    AddDocumentButton.SetParentComponent(WebPanel2);
    AddDocumentButton.Name := 'AddDocumentButton';
    AddDocumentButton.AlignWithMargins := True;
    AddDocumentButton.Left := 20;
    AddDocumentButton.Top := 8;
    AddDocumentButton.Width := 100;
    AddDocumentButton.Height := 31;
    AddDocumentButton.Margins.Left := 0;
    AddDocumentButton.Margins.Top := 8;
    AddDocumentButton.Margins.Right := 8;
    AddDocumentButton.Margins.Bottom := 10;
    AddDocumentButton.Align := alLeft;
    AddDocumentButton.Caption := 'Add';
    AddDocumentButton.Color := clNone;
    AddDocumentButton.ElementClassName := 'MyWebButton';
    AddDocumentButton.Glyph.LoadFromFile('uAssetEdit.WebPanel2.AddDocumentButton.Glyph.png');
    AddDocumentButton.HeightPercent := 100.000000000000000000;
    AddDocumentButton.TabOrder := 0;
    AddDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddDocumentButton, Self, 'OnClick', 'AddDocumentButtonClick');
    DeleteDocumentButton.SetParentComponent(WebPanel2);
    DeleteDocumentButton.Name := 'DeleteDocumentButton';
    DeleteDocumentButton.AlignWithMargins := True;
    DeleteDocumentButton.Left := 236;
    DeleteDocumentButton.Top := 8;
    DeleteDocumentButton.Width := 100;
    DeleteDocumentButton.Height := 31;
    DeleteDocumentButton.Margins.Left := 0;
    DeleteDocumentButton.Margins.Top := 8;
    DeleteDocumentButton.Margins.Right := 8;
    DeleteDocumentButton.Margins.Bottom := 10;
    DeleteDocumentButton.Align := alLeft;
    DeleteDocumentButton.Caption := 'Delete';
    DeleteDocumentButton.Color := clNone;
    DeleteDocumentButton.ElementClassName := 'MyWebButton';
    DeleteDocumentButton.Glyph.LoadFromFile('uAssetEdit.WebPanel2.DeleteDocumentButton.Glyph.png');
    DeleteDocumentButton.HeightPercent := 100.000000000000000000;
    DeleteDocumentButton.TabOrder := 1;
    DeleteDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteDocumentButton, Self, 'OnClick', 'DeleteDocumentButtonClick');
    TMSFNCPanel1.SetParentComponent(WebPanel2);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 2;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 3;
    TMSFNCPanel1.Width := 14;
    TMSFNCPanel1.Height := 41;
    TMSFNCPanel1.Margins.Bottom := 5;
    TMSFNCPanel1.Align := alLeft;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 2;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    EditDocumentButton.SetParentComponent(WebPanel2);
    EditDocumentButton.Name := 'EditDocumentButton';
    EditDocumentButton.AlignWithMargins := True;
    EditDocumentButton.Left := 128;
    EditDocumentButton.Top := 8;
    EditDocumentButton.Width := 100;
    EditDocumentButton.Height := 31;
    EditDocumentButton.Margins.Left := 0;
    EditDocumentButton.Margins.Top := 8;
    EditDocumentButton.Margins.Right := 8;
    EditDocumentButton.Margins.Bottom := 10;
    EditDocumentButton.Align := alLeft;
    EditDocumentButton.Caption := 'Edit';
    EditDocumentButton.Color := clNone;
    EditDocumentButton.ElementClassName := 'MyWebButton';
    EditDocumentButton.Glyph.LoadFromFile('uAssetEdit.WebPanel2.EditDocumentButton.Glyph.png');
    EditDocumentButton.HeightPercent := 100.000000000000000000;
    EditDocumentButton.TabOrder := 3;
    EditDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditDocumentButton, Self, 'OnClick', 'EditDocumentButtonClick');
    ViewDocumentButton.SetParentComponent(WebPanel2);
    ViewDocumentButton.Name := 'ViewDocumentButton';
    ViewDocumentButton.AlignWithMargins := True;
    ViewDocumentButton.Left := 460;
    ViewDocumentButton.Top := 8;
    ViewDocumentButton.Width := 100;
    ViewDocumentButton.Height := 31;
    ViewDocumentButton.Margins.Left := 0;
    ViewDocumentButton.Margins.Top := 8;
    ViewDocumentButton.Margins.Right := 20;
    ViewDocumentButton.Margins.Bottom := 10;
    ViewDocumentButton.Align := alRight;
    ViewDocumentButton.Caption := 'View';
    ViewDocumentButton.Color := clNone;
    ViewDocumentButton.ElementClassName := 'MyWebButton';
    ViewDocumentButton.Glyph.LoadFromFile('uAssetEdit.WebPanel2.ViewDocumentButton.Glyph.png');
    ViewDocumentButton.HeightPercent := 100.000000000000000000;
    ViewDocumentButton.TabOrder := 4;
    ViewDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewDocumentButton, Self, 'OnClick', 'ViewDocumentButtonClick');
    TMSFNCPageControl1Page2.SetParentComponent(PageControl);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 582;
    TMSFNCPageControl1Page2.Height := 610;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    WebPanel1.SetParentComponent(TMSFNCPageControl1Page2);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.AlignWithMargins := True;
    WebPanel1.Left := 1;
    WebPanel1.Top := 560;
    WebPanel1.Width := 580;
    WebPanel1.Height := 49;
    WebPanel1.Margins.Left := 1;
    WebPanel1.Margins.Top := 1;
    WebPanel1.Margins.Right := 1;
    WebPanel1.Margins.Bottom := 1;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clNone;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.Color := clWhite;
    AddAlertButton.SetParentComponent(WebPanel1);
    AddAlertButton.Name := 'AddAlertButton';
    AddAlertButton.AlignWithMargins := True;
    AddAlertButton.Left := 20;
    AddAlertButton.Top := 8;
    AddAlertButton.Width := 100;
    AddAlertButton.Height := 31;
    AddAlertButton.Margins.Left := 0;
    AddAlertButton.Margins.Top := 8;
    AddAlertButton.Margins.Right := 8;
    AddAlertButton.Margins.Bottom := 10;
    AddAlertButton.Align := alLeft;
    AddAlertButton.Caption := 'Add';
    AddAlertButton.Color := clNone;
    AddAlertButton.ElementClassName := 'MyWebButton';
    AddAlertButton.Glyph.LoadFromFile('uAssetEdit.WebPanel1.AddAlertButton.Glyph.png');
    AddAlertButton.HeightPercent := 100.000000000000000000;
    AddAlertButton.TabOrder := 0;
    AddAlertButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddAlertButton, Self, 'OnClick', 'AddAlertButtonClick');
    DeleteAlertButton.SetParentComponent(WebPanel1);
    DeleteAlertButton.Name := 'DeleteAlertButton';
    DeleteAlertButton.AlignWithMargins := True;
    DeleteAlertButton.Left := 128;
    DeleteAlertButton.Top := 8;
    DeleteAlertButton.Width := 100;
    DeleteAlertButton.Height := 31;
    DeleteAlertButton.Margins.Left := 0;
    DeleteAlertButton.Margins.Top := 8;
    DeleteAlertButton.Margins.Right := 8;
    DeleteAlertButton.Margins.Bottom := 10;
    DeleteAlertButton.Align := alLeft;
    DeleteAlertButton.Caption := 'Delete';
    DeleteAlertButton.Color := clNone;
    DeleteAlertButton.ElementClassName := 'MyWebButton';
    DeleteAlertButton.Glyph.LoadFromFile('uAssetEdit.WebPanel1.DeleteAlertButton.Glyph.png');
    DeleteAlertButton.HeightPercent := 100.000000000000000000;
    DeleteAlertButton.TabOrder := 1;
    DeleteAlertButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteAlertButton, Self, 'OnClick', 'DeleteAlertButtonClick');
    TMSFNCPanel2.SetParentComponent(WebPanel1);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 2;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 3;
    TMSFNCPanel2.Width := 14;
    TMSFNCPanel2.Height := 41;
    TMSFNCPanel2.Margins.Bottom := 5;
    TMSFNCPanel2.Align := alLeft;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 2;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
    AlertGrid.SetParentComponent(TMSFNCPageControl1Page2);
    AlertGrid.Name := 'AlertGrid';
    AlertGrid.AlignWithMargins := True;
    AlertGrid.Left := 20;
    AlertGrid.Top := 20;
    AlertGrid.Width := 542;
    AlertGrid.Height := 529;
    AlertGrid.Margins.Left := 20;
    AlertGrid.Margins.Top := 20;
    AlertGrid.Margins.Right := 20;
    AlertGrid.Margins.Bottom := 10;
    AlertGrid.AdaptToStyle := True;
    AlertGrid.Align := alClient;
    AlertGrid.BevelInner := bvNone;
    AlertGrid.BevelOuter := bvNone;
    AlertGrid.BevelWidth := 2;
    AlertGrid.Ctl3D := False;
    AlertGrid.ParentCtl3D := False;
    AlertGrid.ParentDoubleBuffered := False;
    AlertGrid.DoubleBuffered := True;
    AlertGrid.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Font.Color := clWindowText;
    AlertGrid.Font.Height := -11;
    AlertGrid.Font.Name := 'Tahoma';
    AlertGrid.Font.Style := [];
    AlertGrid.ParentFont := False;
    AlertGrid.TabOrder := 1;
    AlertGrid.DefaultRowHeight := 34.000000000000000000;
    AlertGrid.FixedColumns := 0;
    AlertGrid.ColumnCount := 4;
    AlertGrid.RowCount := 1;
    AlertGrid.Options.Borders.FixedCellBorders := bNone;
    AlertGrid.Options.ColumnSize.Stretch := True;
    AlertGrid.Options.ColumnSize.StretchColumn := 1;
    AlertGrid.Options.Editing.CalcFormat := '%g';
    AlertGrid.Options.Grouping.CalcFormat := '%g';
    AlertGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AlertGrid.Options.HTMLExport.CellPadding := 10;
    AlertGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AlertGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AlertGrid.Options.Mouse.ClickMargin := 0;
    AlertGrid.Options.Mouse.ColumnSizeMargin := 6;
    AlertGrid.Options.Mouse.RowSizeMargin := 6;
    AlertGrid.Options.URL.Color := clSkyBlue;
    AlertGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AlertGrid.Options.Selection.Mode := smDisjunctRow;
    AlertGrid.Adapter := AlertDBAdapter;
    AlertGrid.HorizontalScrollBarVisible := False;
    AlertGrid.Columns.Clear;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 136.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    AlertGrid.Fill.ColorTo := clSilver;
    AlertGrid.DefaultFont.Charset := ANSI_CHARSET;
    AlertGrid.DefaultFont.Color := clWindowText;
    AlertGrid.DefaultFont.Height := -15;
    AlertGrid.DefaultFont.Name := 'Segoe UI';
    AlertGrid.DefaultFont.Style := [];
    AlertGrid.Stroke.Color := 15987699;
    AlertGrid.Stroke.Width := 2.000000000000000000;
    AlertGrid.TopRow := 1;
    AlertGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AlertGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AlertGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.FixedLayout.Font.Height := -16;
    AlertGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FixedLayout.Font.Style := [];
    AlertGrid.Appearance.NormalLayout.Fill.Color := -1;
    AlertGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AlertGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.NormalLayout.Font.Color := clGray;
    AlertGrid.Appearance.NormalLayout.Font.Height := -11;
    AlertGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.NormalLayout.Font.Style := [];
    AlertGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AlertGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AlertGrid.Appearance.GroupLayout.Font.Height := -11;
    AlertGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AlertGrid.Appearance.GroupLayout.Font.Style := [];
    AlertGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AlertGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AlertGrid.Appearance.SummaryLayout.Font.Height := -11;
    AlertGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AlertGrid.Appearance.SummaryLayout.Font.Style := [];
    AlertGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AlertGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.SelectedLayout.Font.Height := -16;
    AlertGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.SelectedLayout.Font.Style := [];
    AlertGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.FocusedLayout.Font.Height := -16;
    AlertGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FocusedLayout.Font.Style := [];
    AlertGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AlertGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AlertGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AlertGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.BandLayout.Font.Color := clBlack;
    AlertGrid.Appearance.BandLayout.Font.Height := -16;
    AlertGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AlertGrid.Appearance.BandLayout.Font.Style := [];
    AlertGrid.Appearance.ProgressLayout.Color := 15385233;
    AlertGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AlertGrid.LeftCol := 1;
    AlertGrid.ScrollMode := scmItemScrolling;
    AlertGrid.GlobalFont.Scale := 1.000000000000000000;
    AlertGrid.GlobalFont.Style := [];
    AlertGrid.DesignTimeSampleData := True;
    WebMessageDlg2.SetParentComponent(AlertGrid);
    WebMessageDlg2.Name := 'WebMessageDlg2';
    WebMessageDlg2.Left := 85;
    WebMessageDlg2.Top := 344;
    WebMessageDlg2.Width := 24;
    WebMessageDlg2.Height := 24;
    WebMessageDlg2.Buttons := [];
    WebMessageDlg2.Opacity := 0.200000000000000000;
    TMSFNCPageControl1Page0.SetParentComponent(PageControl);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 582;
    TMSFNCPageControl1Page0.Height := 610;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    LicensePlateLabel.SetParentComponent(TMSFNCPageControl1Page0);
    LicensePlateLabel.Name := 'LicensePlateLabel';
    LicensePlateLabel.Left := 39;
    LicensePlateLabel.Top := 14;
    LicensePlateLabel.Width := 122;
    LicensePlateLabel.Height := 21;
    LicensePlateLabel.Caption := 'Registration plate';
    LicensePlateLabel.Font.Charset := ANSI_CHARSET;
    LicensePlateLabel.Font.Color := 9010544;
    LicensePlateLabel.Font.Height := -16;
    LicensePlateLabel.Font.Name := 'Segoe UI';
    LicensePlateLabel.Font.Style := [];
    LicensePlateLabel.HeightPercent := 100.000000000000000000;
    LicensePlateLabel.ParentFont := False;
    LicensePlateLabel.WidthPercent := 100.000000000000000000;
    ChassisNumberLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ChassisNumberLabel.Name := 'ChassisNumberLabel';
    ChassisNumberLabel.Left := 39;
    ChassisNumberLabel.Top := 75;
    ChassisNumberLabel.Width := 26;
    ChassisNumberLabel.Height := 21;
    ChassisNumberLabel.Caption := 'VIN';
    ChassisNumberLabel.Font.Charset := ANSI_CHARSET;
    ChassisNumberLabel.Font.Color := 9010544;
    ChassisNumberLabel.Font.Height := -16;
    ChassisNumberLabel.Font.Name := 'Segoe UI';
    ChassisNumberLabel.Font.Style := [];
    ChassisNumberLabel.HeightPercent := 100.000000000000000000;
    ChassisNumberLabel.ParentFont := False;
    ChassisNumberLabel.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 40;
    WebLabel4.Top := 516;
    WebLabel4.Width := 43;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Driver';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 16755027;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 312;
    WebLabel8.Top := 14;
    WebLabel8.Width := 69;
    WebLabel8.Height := 21;
    WebLabel8.Caption := 'Build Year';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := 9010544;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 40;
    WebLabel15.Top := 139;
    WebLabel15.Width := 76;
    WebLabel15.Height := 21;
    WebLabel15.Caption := 'Comments';
    WebLabel15.Font.Charset := ANSI_CHARSET;
    WebLabel15.Font.Color := 9010544;
    WebLabel15.Font.Height := -16;
    WebLabel15.Font.Name := 'Segoe UI';
    WebLabel15.Font.Style := [];
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 36;
    WebLabel11.Top := 372;
    WebLabel11.Width := 63;
    WebLabel11.Height := 21;
    WebLabel11.Caption := 'Fuel type';
    WebLabel11.Font.Charset := ANSI_CHARSET;
    WebLabel11.Font.Color := 9010544;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Segoe UI';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 209;
    WebLabel1.Top := 373;
    WebLabel1.Width := 63;
    WebLabel1.Height := 21;
    WebLabel1.Caption := ' l/100km';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 316;
    WebLabel10.Top := 372;
    WebLabel10.Width := 67;
    WebLabel10.Height := 21;
    WebLabel10.Caption := 'CO2 level';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := 9010544;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    ColorLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ColorLabel.Name := 'ColorLabel';
    ColorLabel.Left := 453;
    ColorLabel.Top := 372;
    ColorLabel.Width := 38;
    ColorLabel.Height := 21;
    ColorLabel.Caption := 'Color';
    ColorLabel.Font.Charset := ANSI_CHARSET;
    ColorLabel.Font.Color := 9010544;
    ColorLabel.Font.Height := -16;
    ColorLabel.Font.Name := 'Segoe UI';
    ColorLabel.Font.Style := [];
    ColorLabel.HeightPercent := 100.000000000000000000;
    ColorLabel.ParentFont := False;
    ColorLabel.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 38;
    WebLabel5.Top := 445;
    WebLabel5.Width := 87;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'Key Number';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 214;
    WebLabel12.Top := 445;
    WebLabel12.Width := 102;
    WebLabel12.Height := 21;
    WebLabel12.Caption := 'Key comments';
    WebLabel12.Font.Charset := ANSI_CHARSET;
    WebLabel12.Font.Color := 9010544;
    WebLabel12.Font.Height := -16;
    WebLabel12.Font.Name := 'Segoe UI';
    WebLabel12.Font.Style := [];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    OdoMeterLabel.SetParentComponent(TMSFNCPageControl1Page0);
    OdoMeterLabel.Name := 'OdoMeterLabel';
    OdoMeterLabel.Left := 439;
    OdoMeterLabel.Top := 75;
    OdoMeterLabel.Width := 43;
    OdoMeterLabel.Height := 21;
    OdoMeterLabel.Caption := 'Preset';
    OdoMeterLabel.Font.Charset := ANSI_CHARSET;
    OdoMeterLabel.Font.Color := 9010544;
    OdoMeterLabel.Font.Height := -16;
    OdoMeterLabel.Font.Name := 'Segoe UI';
    OdoMeterLabel.Font.Style := [];
    OdoMeterLabel.HeightPercent := 100.000000000000000000;
    OdoMeterLabel.ParentFont := False;
    OdoMeterLabel.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 316;
    WebLabel6.Top := 75;
    WebLabel6.Width := 71;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'Odometer';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := 9010544;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    MOTDateLabel.SetParentComponent(TMSFNCPageControl1Page0);
    MOTDateLabel.Name := 'MOTDateLabel';
    MOTDateLabel.Left := 433;
    MOTDateLabel.Top := 14;
    MOTDateLabel.Width := 65;
    MOTDateLabel.Height := 21;
    MOTDateLabel.Caption := 'MOT Due';
    MOTDateLabel.Font.Charset := ANSI_CHARSET;
    MOTDateLabel.Font.Color := 16755027;
    MOTDateLabel.Font.Height := -16;
    MOTDateLabel.Font.Name := 'Segoe UI';
    MOTDateLabel.Font.Style := [fsUnderline];
    MOTDateLabel.HeightPercent := 100.000000000000000000;
    MOTDateLabel.ParentFont := False;
    MOTDateLabel.WidthPercent := 100.000000000000000000;
    SetEvent(MOTDateLabel, Self, 'OnClick', 'MOTDateLabelClick');
    LicensePlateEdit.SetParentComponent(TMSFNCPageControl1Page0);
    LicensePlateEdit.Name := 'LicensePlateEdit';
    LicensePlateEdit.Left := 39;
    LicensePlateEdit.Top := 38;
    LicensePlateEdit.Width := 234;
    LicensePlateEdit.Height := 34;
    LicensePlateEdit.CharCase := wecUpperCase;
    LicensePlateEdit.ChildOrder := 1;
    LicensePlateEdit.ElementClassName := 'MyWebEdit';
    LicensePlateEdit.Font.Charset := ANSI_CHARSET;
    LicensePlateEdit.Font.Color := clWindowText;
    LicensePlateEdit.Font.Height := -16;
    LicensePlateEdit.Font.Name := 'Segoe UI';
    LicensePlateEdit.Font.Style := [];
    LicensePlateEdit.HeightPercent := 100.000000000000000000;
    LicensePlateEdit.ParentFont := False;
    LicensePlateEdit.WidthPercent := 100.000000000000000000;
    SetEvent(LicensePlateEdit, Self, 'OnChange', 'RecordChange');
    SetEvent(LicensePlateEdit, Self, 'OnKeyPress', 'LicensePlateEditKeyPress');
    ChassisNumberEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ChassisNumberEdit.Name := 'ChassisNumberEdit';
    ChassisNumberEdit.Left := 39;
    ChassisNumberEdit.Top := 99;
    ChassisNumberEdit.Width := 237;
    ChassisNumberEdit.Height := 34;
    ChassisNumberEdit.CharCase := wecUpperCase;
    ChassisNumberEdit.ChildOrder := 1;
    ChassisNumberEdit.ElementClassName := 'MyWebEdit';
    ChassisNumberEdit.Font.Charset := ANSI_CHARSET;
    ChassisNumberEdit.Font.Color := clWindowText;
    ChassisNumberEdit.Font.Height := -16;
    ChassisNumberEdit.Font.Name := 'Segoe UI';
    ChassisNumberEdit.Font.Style := [];
    ChassisNumberEdit.HeightPercent := 100.000000000000000000;
    ChassisNumberEdit.ParentFont := False;
    ChassisNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ChassisNumberEdit, Self, 'OnChange', 'RecordChange');
    RemarkEdit.SetParentComponent(TMSFNCPageControl1Page0);
    RemarkEdit.Name := 'RemarkEdit';
    RemarkEdit.Left := 39;
    RemarkEdit.Top := 166;
    RemarkEdit.Width := 510;
    RemarkEdit.Height := 201;
    RemarkEdit.AutoSize := False;
    RemarkEdit.ElementClassName := 'MyWebEdit';
    RemarkEdit.HeightPercent := 100.000000000000000000;
    RemarkEdit.SelLength := 0;
    RemarkEdit.SelStart := 0;
    RemarkEdit.WidthPercent := 100.000000000000000000;
    SetEvent(RemarkEdit, Self, 'OnChange', 'RecordChange');
    YearManufacturedEdit.SetParentComponent(TMSFNCPageControl1Page0);
    YearManufacturedEdit.Name := 'YearManufacturedEdit';
    YearManufacturedEdit.Left := 313;
    YearManufacturedEdit.Top := 38;
    YearManufacturedEdit.Width := 104;
    YearManufacturedEdit.Height := 34;
    YearManufacturedEdit.CharCase := wecUpperCase;
    YearManufacturedEdit.ChildOrder := 1;
    YearManufacturedEdit.EditType := weNumeric;
    YearManufacturedEdit.ElementClassName := 'MyWebEdit';
    YearManufacturedEdit.Font.Charset := ANSI_CHARSET;
    YearManufacturedEdit.Font.Color := clWindowText;
    YearManufacturedEdit.Font.Height := -16;
    YearManufacturedEdit.Font.Name := 'Segoe UI';
    YearManufacturedEdit.Font.Style := [];
    YearManufacturedEdit.HeightPercent := 100.000000000000000000;
    YearManufacturedEdit.ParentFont := False;
    YearManufacturedEdit.WidthPercent := 100.000000000000000000;
    SetEvent(YearManufacturedEdit, Self, 'OnChange', 'RecordChange');
    DriverPKeyEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DriverPKeyEdit.Name := 'DriverPKeyEdit';
    DriverPKeyEdit.Left := 38;
    DriverPKeyEdit.Top := 543;
    DriverPKeyEdit.Width := 243;
    DriverPKeyEdit.Height := 34;
    DriverPKeyEdit.CharCase := wecUpperCase;
    DriverPKeyEdit.ChildOrder := 1;
    DriverPKeyEdit.ElementClassName := 'MyWebEdit';
    DriverPKeyEdit.Font.Charset := ANSI_CHARSET;
    DriverPKeyEdit.Font.Color := clWindowText;
    DriverPKeyEdit.Font.Height := -16;
    DriverPKeyEdit.Font.Name := 'Segoe UI';
    DriverPKeyEdit.Font.Style := [];
    DriverPKeyEdit.HeightPercent := 100.000000000000000000;
    DriverPKeyEdit.ParentFont := False;
    DriverPKeyEdit.ReadOnly := True;
    DriverPKeyEdit.WidthPercent := 100.000000000000000000;
    SelectDriverButton.SetParentComponent(TMSFNCPageControl1Page0);
    SelectDriverButton.Name := 'SelectDriverButton';
    SelectDriverButton.Left := 305;
    SelectDriverButton.Top := 543;
    SelectDriverButton.Width := 34;
    SelectDriverButton.Height := 34;
    SelectDriverButton.Color := clNone;
    SelectDriverButton.ElementClassName := 'MyWebButton';
    SelectDriverButton.Enabled := False;
    SelectDriverButton.HeightPercent := 100.000000000000000000;
    SelectDriverButton.MaterialGlyph := 'search';
    SelectDriverButton.MaterialGlyphColor := clWhite;
    SelectDriverButton.MaterialGlyphSize := 24;
    SelectDriverButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectDriverButton, Self, 'OnClick', 'SelectDriverButtonClick');
    ClearDriverButton.SetParentComponent(TMSFNCPageControl1Page0);
    ClearDriverButton.Name := 'ClearDriverButton';
    ClearDriverButton.Left := 345;
    ClearDriverButton.Top := 543;
    ClearDriverButton.Width := 34;
    ClearDriverButton.Height := 34;
    ClearDriverButton.Color := clNone;
    ClearDriverButton.ElementClassName := 'MyWebButton';
    ClearDriverButton.Enabled := False;
    ClearDriverButton.HeightPercent := 100.000000000000000000;
    ClearDriverButton.MaterialGlyph := 'clear';
    ClearDriverButton.MaterialGlyphColor := clWhite;
    ClearDriverButton.MaterialGlyphSize := 24;
    ClearDriverButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearDriverButton, Self, 'OnClick', 'ClearDriverButtonClick');
    FuelTypeEdit.SetParentComponent(TMSFNCPageControl1Page0);
    FuelTypeEdit.Name := 'FuelTypeEdit';
    FuelTypeEdit.AlignWithMargins := True;
    FuelTypeEdit.Left := 36;
    FuelTypeEdit.Top := 399;
    FuelTypeEdit.Width := 147;
    FuelTypeEdit.Height := 34;
    FuelTypeEdit.Margins.Left := 20;
    FuelTypeEdit.Margins.Top := 8;
    FuelTypeEdit.Margins.Bottom := 8;
    FuelTypeEdit.Color := clWhite;
    FuelTypeEdit.ElementClassName := 'myselect_white';
    FuelTypeEdit.Font.Charset := DEFAULT_CHARSET;
    FuelTypeEdit.Font.Color := clWindowText;
    FuelTypeEdit.Font.Height := 12;
    FuelTypeEdit.Font.Name := 'Segoe UI';
    FuelTypeEdit.Font.Style := [];
    FuelTypeEdit.HeightPercent := 100.000000000000000000;
    FuelTypeEdit.ParentFont := False;
    FuelTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FuelTypeEdit, Self, 'OnChange', 'RecordChange');
    FuelTypeEdit.ItemIndex := -1;
    FuelTypeEdit.LookupValues.Clear;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'GASOLINE';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'DIESEL';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'HYDROGEN';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'LPG';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'ELECTRICITY';
    end;
    CarbonDioxideEdit.SetParentComponent(TMSFNCPageControl1Page0);
    CarbonDioxideEdit.Name := 'CarbonDioxideEdit';
    CarbonDioxideEdit.Left := 316;
    CarbonDioxideEdit.Top := 399;
    CarbonDioxideEdit.Width := 100;
    CarbonDioxideEdit.Height := 34;
    CarbonDioxideEdit.ChildOrder := 1;
    CarbonDioxideEdit.EditType := weNumeric;
    CarbonDioxideEdit.ElementClassName := 'MyWebEdit';
    CarbonDioxideEdit.Font.Charset := ANSI_CHARSET;
    CarbonDioxideEdit.Font.Color := clWindowText;
    CarbonDioxideEdit.Font.Height := -16;
    CarbonDioxideEdit.Font.Name := 'Segoe UI';
    CarbonDioxideEdit.Font.Style := [];
    CarbonDioxideEdit.HeightPercent := 100.000000000000000000;
    CarbonDioxideEdit.ParentFont := False;
    CarbonDioxideEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CarbonDioxideEdit, Self, 'OnChange', 'RecordChange');
    FuelConsumptionEdit.SetParentComponent(TMSFNCPageControl1Page0);
    FuelConsumptionEdit.Name := 'FuelConsumptionEdit';
    FuelConsumptionEdit.Left := 208;
    FuelConsumptionEdit.Top := 399;
    FuelConsumptionEdit.Width := 65;
    FuelConsumptionEdit.Height := 34;
    FuelConsumptionEdit.ChildOrder := 1;
    FuelConsumptionEdit.EditType := weFloat;
    FuelConsumptionEdit.ElementClassName := 'MyWebEdit';
    FuelConsumptionEdit.Font.Charset := ANSI_CHARSET;
    FuelConsumptionEdit.Font.Color := clWindowText;
    FuelConsumptionEdit.Font.Height := -16;
    FuelConsumptionEdit.Font.Name := 'Segoe UI';
    FuelConsumptionEdit.Font.Style := [];
    FuelConsumptionEdit.HeightPercent := 100.000000000000000000;
    FuelConsumptionEdit.ParentFont := False;
    FuelConsumptionEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FuelConsumptionEdit, Self, 'OnChange', 'RecordChange');
    KeyNumberEdit.SetParentComponent(TMSFNCPageControl1Page0);
    KeyNumberEdit.Name := 'KeyNumberEdit';
    KeyNumberEdit.Left := 39;
    KeyNumberEdit.Top := 472;
    KeyNumberEdit.Width := 146;
    KeyNumberEdit.Height := 34;
    KeyNumberEdit.ChildOrder := 1;
    KeyNumberEdit.ElementClassName := 'MyWebEdit';
    KeyNumberEdit.Font.Charset := ANSI_CHARSET;
    KeyNumberEdit.Font.Color := clWindowText;
    KeyNumberEdit.Font.Height := -16;
    KeyNumberEdit.Font.Name := 'Segoe UI';
    KeyNumberEdit.Font.Style := [];
    KeyNumberEdit.HeightPercent := 100.000000000000000000;
    KeyNumberEdit.ParentFont := False;
    KeyNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(KeyNumberEdit, Self, 'OnChange', 'RecordChange');
    KeyCommentEdit.SetParentComponent(TMSFNCPageControl1Page0);
    KeyCommentEdit.Name := 'KeyCommentEdit';
    KeyCommentEdit.Left := 208;
    KeyCommentEdit.Top := 472;
    KeyCommentEdit.Width := 345;
    KeyCommentEdit.Height := 34;
    KeyCommentEdit.ChildOrder := 1;
    KeyCommentEdit.ElementClassName := 'MyWebEdit';
    KeyCommentEdit.Font.Charset := ANSI_CHARSET;
    KeyCommentEdit.Font.Color := clWindowText;
    KeyCommentEdit.Font.Height := -16;
    KeyCommentEdit.Font.Name := 'Segoe UI';
    KeyCommentEdit.Font.Style := [];
    KeyCommentEdit.HeightPercent := 100.000000000000000000;
    KeyCommentEdit.ParentFont := False;
    KeyCommentEdit.WidthPercent := 100.000000000000000000;
    SetEvent(KeyCommentEdit, Self, 'OnChange', 'RecordChange');
    BatteryLockEdit.SetParentComponent(TMSFNCPageControl1Page0);
    BatteryLockEdit.Name := 'BatteryLockEdit';
    BatteryLockEdit.Left := 448;
    BatteryLockEdit.Top := 546;
    BatteryLockEdit.Width := 115;
    BatteryLockEdit.Height := 26;
    BatteryLockEdit.Caption := 'Battery Lock';
    BatteryLockEdit.ChildOrder := 28;
    BatteryLockEdit.Font.Charset := ANSI_CHARSET;
    BatteryLockEdit.Font.Color := clWindowText;
    BatteryLockEdit.Font.Height := -16;
    BatteryLockEdit.Font.Name := 'Segoe UI';
    BatteryLockEdit.Font.Style := [];
    BatteryLockEdit.HeightPercent := 100.000000000000000000;
    BatteryLockEdit.ParentFont := False;
    BatteryLockEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryLockEdit, Self, 'OnClick', 'RecordChange');
    ColorEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ColorEdit.Name := 'ColorEdit';
    ColorEdit.Left := 448;
    ColorEdit.Top := 399;
    ColorEdit.Width := 100;
    ColorEdit.Height := 34;
    ColorEdit.ParentDoubleBuffered := False;
    ColorEdit.DoubleBuffered := True;
    ColorEdit.Font.Charset := DEFAULT_CHARSET;
    ColorEdit.Font.Color := clWindowText;
    ColorEdit.Font.Height := -11;
    ColorEdit.Font.Name := 'Tahoma';
    ColorEdit.Font.Style := [];
    ColorEdit.TabOrder := 13;
    ColorEdit.Appearance.Stroke.Color := 11119017;
    ColorEdit.Appearance.Fill.Color := 15329769;
    ColorEdit.Appearance.StrokeHover.Color := 10061943;
    ColorEdit.Appearance.FillHover.Color := 13419707;
    ColorEdit.Appearance.StrokeDown.Color := 9470064;
    ColorEdit.Appearance.FillDown.Color := 13156536;
    ColorEdit.Appearance.StrokeSelected.Color := 5197615;
    ColorEdit.Appearance.FillSelected.Color := 13156536;
    ColorEdit.Appearance.StrokeDisabled.Color := 11119017;
    ColorEdit.Appearance.FillDisabled.Color := clSilver;
    ColorEdit.Appearance.VerticalSpacing := 0.000000000000000000;
    ColorEdit.Appearance.HorizontalSpacing := 0.000000000000000000;
    ColorEdit.Appearance.SeparatorStroke.Color := 11119017;
    ColorEdit.Appearance.Font.Charset := DEFAULT_CHARSET;
    ColorEdit.Appearance.Font.Color := clWindowText;
    ColorEdit.Appearance.Font.Height := 0;
    ColorEdit.Appearance.Font.Name := 'Tahoma';
    ColorEdit.Appearance.Font.Style := [];
    ColorEdit.Columns := 5;
    ColorEdit.Items.Clear;
    with ColorEdit.Items.Add do
    begin
      Color := clBlack;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clMaroon;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clGreen;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clOlive;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clNavy;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clPurple;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clTeal;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clSilver;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clGray;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clRed;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clLime;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clYellow;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clBlue;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clFuchsia;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clAqua;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clWhite;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := 36095;
    end;
    SetEvent(ColorEdit, Self, 'OnItemSelected', 'ColorEditItemSelected');
    OdoMeterEdit.SetParentComponent(TMSFNCPageControl1Page0);
    OdoMeterEdit.Name := 'OdoMeterEdit';
    OdoMeterEdit.Left := 432;
    OdoMeterEdit.Top := 99;
    OdoMeterEdit.Width := 105;
    OdoMeterEdit.Height := 34;
    OdoMeterEdit.CharCase := wecUpperCase;
    OdoMeterEdit.ChildOrder := 1;
    OdoMeterEdit.EditType := weNumeric;
    OdoMeterEdit.ElementClassName := 'MyWebEdit';
    OdoMeterEdit.Font.Charset := ANSI_CHARSET;
    OdoMeterEdit.Font.Color := clWindowText;
    OdoMeterEdit.Font.Height := -16;
    OdoMeterEdit.Font.Name := 'Segoe UI';
    OdoMeterEdit.Font.Style := [];
    OdoMeterEdit.HeightPercent := 100.000000000000000000;
    OdoMeterEdit.ParentFont := False;
    OdoMeterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(OdoMeterEdit, Self, 'OnChange', 'RecordChange');
    OdoMeterDiffEdit.SetParentComponent(TMSFNCPageControl1Page0);
    OdoMeterDiffEdit.Name := 'OdoMeterDiffEdit';
    OdoMeterDiffEdit.Left := 313;
    OdoMeterDiffEdit.Top := 99;
    OdoMeterDiffEdit.Width := 105;
    OdoMeterDiffEdit.Height := 34;
    OdoMeterDiffEdit.CharCase := wecUpperCase;
    OdoMeterDiffEdit.ChildOrder := 1;
    OdoMeterDiffEdit.EditType := weNumeric;
    OdoMeterDiffEdit.ElementClassName := 'MyWebEdit';
    OdoMeterDiffEdit.Font.Charset := ANSI_CHARSET;
    OdoMeterDiffEdit.Font.Color := clWindowText;
    OdoMeterDiffEdit.Font.Height := -16;
    OdoMeterDiffEdit.Font.Name := 'Segoe UI';
    OdoMeterDiffEdit.Font.Style := [];
    OdoMeterDiffEdit.HeightPercent := 100.000000000000000000;
    OdoMeterDiffEdit.ParentFont := False;
    OdoMeterDiffEdit.ReadOnly := True;
    OdoMeterDiffEdit.WidthPercent := 100.000000000000000000;
    MOTDateEdit.SetParentComponent(TMSFNCPageControl1Page0);
    MOTDateEdit.Name := 'MOTDateEdit';
    MOTDateEdit.Left := 432;
    MOTDateEdit.Top := 38;
    MOTDateEdit.Width := 105;
    MOTDateEdit.Height := 34;
    MOTDateEdit.ChildOrder := 1;
    MOTDateEdit.ElementClassName := 'MyWebEdit';
    MOTDateEdit.Enabled := False;
    MOTDateEdit.Font.Charset := ANSI_CHARSET;
    MOTDateEdit.Font.Color := clWindowText;
    MOTDateEdit.Font.Height := -16;
    MOTDateEdit.Font.Name := 'Segoe UI';
    MOTDateEdit.Font.Style := [];
    MOTDateEdit.HeightPercent := 100.000000000000000000;
    MOTDateEdit.ParentFont := False;
    MOTDateEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MOTDateEdit, Self, 'OnChange', 'RecordChange');
    AssetGroupEdit.SetParentComponent(ContentPanel);
    AssetGroupEdit.Name := 'AssetGroupEdit';
    AssetGroupEdit.AlignWithMargins := True;
    AssetGroupEdit.Left := 30;
    AssetGroupEdit.Top := 179;
    AssetGroupEdit.Width := 280;
    AssetGroupEdit.Height := 34;
    AssetGroupEdit.Margins.Left := 20;
    AssetGroupEdit.Margins.Top := 8;
    AssetGroupEdit.Margins.Bottom := 8;
    AssetGroupEdit.Color := clWhite;
    AssetGroupEdit.ElementClassName := 'myselect_white';
    AssetGroupEdit.Font.Charset := DEFAULT_CHARSET;
    AssetGroupEdit.Font.Color := clWindowText;
    AssetGroupEdit.Font.Height := 12;
    AssetGroupEdit.Font.Name := 'Segoe UI';
    AssetGroupEdit.Font.Style := [];
    AssetGroupEdit.HeightPercent := 100.000000000000000000;
    AssetGroupEdit.ParentFont := False;
    AssetGroupEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupEdit, Self, 'OnChange', 'RecordChange');
    AssetGroupEdit.ItemIndex := -1;
    AssetTypeEdit.SetParentComponent(ContentPanel);
    AssetTypeEdit.Name := 'AssetTypeEdit';
    AssetTypeEdit.AlignWithMargins := True;
    AssetTypeEdit.Left := 30;
    AssetTypeEdit.Top := 110;
    AssetTypeEdit.Width := 280;
    AssetTypeEdit.Height := 34;
    AssetTypeEdit.Margins.Left := 20;
    AssetTypeEdit.Margins.Top := 8;
    AssetTypeEdit.Margins.Bottom := 8;
    AssetTypeEdit.Color := clWhite;
    AssetTypeEdit.ElementClassName := 'myselect_white';
    AssetTypeEdit.Font.Charset := DEFAULT_CHARSET;
    AssetTypeEdit.Font.Color := clWindowText;
    AssetTypeEdit.Font.Height := 12;
    AssetTypeEdit.Font.Name := 'Segoe UI';
    AssetTypeEdit.Font.Style := [];
    AssetTypeEdit.HeightPercent := 100.000000000000000000;
    AssetTypeEdit.ParentFont := False;
    AssetTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetTypeEdit, Self, 'OnChange', 'RecordChange');
    AssetTypeEdit.ItemIndex := -1;
    OwnerEdit.SetParentComponent(ContentPanel);
    OwnerEdit.Name := 'OwnerEdit';
    OwnerEdit.AlignWithMargins := True;
    OwnerEdit.Left := 28;
    OwnerEdit.Top := 456;
    OwnerEdit.Width := 280;
    OwnerEdit.Height := 34;
    OwnerEdit.Margins.Left := 20;
    OwnerEdit.Margins.Top := 8;
    OwnerEdit.Margins.Bottom := 8;
    OwnerEdit.Color := clWhite;
    OwnerEdit.ElementClassName := 'myselect_white';
    OwnerEdit.Font.Charset := DEFAULT_CHARSET;
    OwnerEdit.Font.Color := clWindowText;
    OwnerEdit.Font.Height := 12;
    OwnerEdit.Font.Name := 'Segoe UI';
    OwnerEdit.Font.Style := [];
    OwnerEdit.HeightPercent := 100.000000000000000000;
    OwnerEdit.ParentFont := False;
    OwnerEdit.WidthPercent := 100.000000000000000000;
    SetEvent(OwnerEdit, Self, 'OnChange', 'RecordChange');
    OwnerEdit.ItemIndex := -1;
    TenantEdit.SetParentComponent(ContentPanel);
    TenantEdit.Name := 'TenantEdit';
    TenantEdit.AlignWithMargins := True;
    TenantEdit.Left := 366;
    TenantEdit.Top := 456;
    TenantEdit.Width := 280;
    TenantEdit.Height := 34;
    TenantEdit.Margins.Left := 20;
    TenantEdit.Margins.Top := 8;
    TenantEdit.Margins.Bottom := 8;
    TenantEdit.Color := clWhite;
    TenantEdit.ElementClassName := 'myselect_white';
    TenantEdit.Font.Charset := DEFAULT_CHARSET;
    TenantEdit.Font.Color := clWindowText;
    TenantEdit.Font.Height := 12;
    TenantEdit.Font.Name := 'Segoe UI';
    TenantEdit.Font.Style := [];
    TenantEdit.HeightPercent := 100.000000000000000000;
    TenantEdit.ParentFont := False;
    TenantEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TenantEdit, Self, 'OnChange', 'RecordChange');
    TenantEdit.ItemIndex := -1;
    AssetStatusEdit.SetParentComponent(ContentPanel);
    AssetStatusEdit.Name := 'AssetStatusEdit';
    AssetStatusEdit.AlignWithMargins := True;
    AssetStatusEdit.Left := 368;
    AssetStatusEdit.Top := 43;
    AssetStatusEdit.Width := 280;
    AssetStatusEdit.Height := 34;
    AssetStatusEdit.Margins.Left := 20;
    AssetStatusEdit.Margins.Top := 8;
    AssetStatusEdit.Margins.Bottom := 8;
    AssetStatusEdit.Color := clWhite;
    AssetStatusEdit.ElementClassName := 'myselect_white';
    AssetStatusEdit.Font.Charset := DEFAULT_CHARSET;
    AssetStatusEdit.Font.Color := clWindowText;
    AssetStatusEdit.Font.Height := 12;
    AssetStatusEdit.Font.Name := 'Segoe UI';
    AssetStatusEdit.Font.Style := [];
    AssetStatusEdit.HeightPercent := 100.000000000000000000;
    AssetStatusEdit.ParentFont := False;
    AssetStatusEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusEdit, Self, 'OnChange', 'RecordChange');
    AssetStatusEdit.ItemIndex := -1;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 676;
    StatusBarPanel.Width := 1285;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1165;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 1050;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    RepairHistoryButton.SetParentComponent(StatusBarPanel);
    RepairHistoryButton.Name := 'RepairHistoryButton';
    RepairHistoryButton.AlignWithMargins := True;
    RepairHistoryButton.Left := 20;
    RepairHistoryButton.Top := 8;
    RepairHistoryButton.Width := 120;
    RepairHistoryButton.Height := 31;
    RepairHistoryButton.Margins.Left := 20;
    RepairHistoryButton.Margins.Top := 8;
    RepairHistoryButton.Margins.Right := 5;
    RepairHistoryButton.Margins.Bottom := 10;
    RepairHistoryButton.Align := alLeft;
    RepairHistoryButton.Caption := 'Repair tickets';
    RepairHistoryButton.Color := clNone;
    RepairHistoryButton.ElementClassName := 'MyWebButton';
    RepairHistoryButton.HeightPercent := 100.000000000000000000;
    RepairHistoryButton.TabOrder := 2;
    RepairHistoryButton.WidthPercent := 100.000000000000000000;
    SetEvent(RepairHistoryButton, Self, 'OnClick', 'RepairHistoryButtonClick');
    StatusHistoryButton.SetParentComponent(StatusBarPanel);
    StatusHistoryButton.Name := 'StatusHistoryButton';
    StatusHistoryButton.AlignWithMargins := True;
    StatusHistoryButton.Left := 155;
    StatusHistoryButton.Top := 8;
    StatusHistoryButton.Width := 120;
    StatusHistoryButton.Height := 31;
    StatusHistoryButton.Margins.Left := 10;
    StatusHistoryButton.Margins.Top := 8;
    StatusHistoryButton.Margins.Right := 5;
    StatusHistoryButton.Margins.Bottom := 10;
    StatusHistoryButton.Align := alLeft;
    StatusHistoryButton.Caption := 'Status changes';
    StatusHistoryButton.Color := clNone;
    StatusHistoryButton.ElementClassName := 'MyWebButton';
    StatusHistoryButton.HeightPercent := 100.000000000000000000;
    StatusHistoryButton.TabOrder := 3;
    StatusHistoryButton.WidthPercent := 100.000000000000000000;
    SetEvent(StatusHistoryButton, Self, 'OnClick', 'StatusHistoryButtonClick');
    AssetNoEdit.SetParentComponent(ContentPanel);
    AssetNoEdit.Name := 'AssetNoEdit';
    AssetNoEdit.Left := 366;
    AssetNoEdit.Top := 316;
    AssetNoEdit.Width := 280;
    AssetNoEdit.Height := 32;
    AssetNoEdit.CharCase := wecUpperCase;
    AssetNoEdit.ChildOrder := 1;
    AssetNoEdit.EditType := weSignedNumeric;
    AssetNoEdit.ElementClassName := 'MyWebEdit';
    AssetNoEdit.Enabled := False;
    AssetNoEdit.Font.Charset := ANSI_CHARSET;
    AssetNoEdit.Font.Color := clWindowText;
    AssetNoEdit.Font.Height := -16;
    AssetNoEdit.Font.Name := 'Segoe UI';
    AssetNoEdit.Font.Style := [];
    AssetNoEdit.HeightPercent := 100.000000000000000000;
    AssetNoEdit.ParentFont := False;
    AssetNoEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetNoEdit, Self, 'OnChange', 'RecordChange');
    UserType2Edit.SetParentComponent(ContentPanel);
    UserType2Edit.Name := 'UserType2Edit';
    UserType2Edit.AlignWithMargins := True;
    UserType2Edit.Left := 368;
    UserType2Edit.Top := 527;
    UserType2Edit.Width := 278;
    UserType2Edit.Height := 34;
    UserType2Edit.Margins.Left := 20;
    UserType2Edit.Margins.Top := 8;
    UserType2Edit.Margins.Bottom := 8;
    UserType2Edit.Color := clWhite;
    UserType2Edit.ElementClassName := 'myselect_white';
    UserType2Edit.Font.Charset := DEFAULT_CHARSET;
    UserType2Edit.Font.Color := clWindowText;
    UserType2Edit.Font.Height := 12;
    UserType2Edit.Font.Name := 'Segoe UI';
    UserType2Edit.Font.Style := [];
    UserType2Edit.HeightPercent := 100.000000000000000000;
    UserType2Edit.ParentFont := False;
    UserType2Edit.WidthPercent := 100.000000000000000000;
    SetEvent(UserType2Edit, Self, 'OnChange', 'RecordChange');
    UserType2Edit.ItemIndex := -1;
    UserType1Edit.SetParentComponent(ContentPanel);
    UserType1Edit.Name := 'UserType1Edit';
    UserType1Edit.AlignWithMargins := True;
    UserType1Edit.Left := 30;
    UserType1Edit.Top := 527;
    UserType1Edit.Width := 280;
    UserType1Edit.Height := 34;
    UserType1Edit.Margins.Left := 20;
    UserType1Edit.Margins.Top := 8;
    UserType1Edit.Margins.Bottom := 8;
    UserType1Edit.Color := clWhite;
    UserType1Edit.ElementClassName := 'myselect_white';
    UserType1Edit.Font.Charset := DEFAULT_CHARSET;
    UserType1Edit.Font.Color := clWindowText;
    UserType1Edit.Font.Height := 12;
    UserType1Edit.Font.Name := 'Segoe UI';
    UserType1Edit.Font.Style := [];
    UserType1Edit.HeightPercent := 100.000000000000000000;
    UserType1Edit.ParentFont := False;
    UserType1Edit.WidthPercent := 100.000000000000000000;
    SetEvent(UserType1Edit, Self, 'OnChange', 'RecordChange');
    UserType1Edit.ItemIndex := -1;
    AssetBrandEdit.SetParentComponent(ContentPanel);
    AssetBrandEdit.Name := 'AssetBrandEdit';
    AssetBrandEdit.AlignWithMargins := True;
    AssetBrandEdit.Left := 28;
    AssetBrandEdit.Top := 386;
    AssetBrandEdit.Width := 280;
    AssetBrandEdit.Height := 34;
    AssetBrandEdit.Margins.Left := 20;
    AssetBrandEdit.Margins.Top := 8;
    AssetBrandEdit.Margins.Bottom := 8;
    AssetBrandEdit.Color := clWhite;
    AssetBrandEdit.ElementClassName := 'myselect_white';
    AssetBrandEdit.Font.Charset := DEFAULT_CHARSET;
    AssetBrandEdit.Font.Color := clWindowText;
    AssetBrandEdit.Font.Height := 12;
    AssetBrandEdit.Font.Name := 'Segoe UI';
    AssetBrandEdit.Font.Style := [];
    AssetBrandEdit.HeightPercent := 100.000000000000000000;
    AssetBrandEdit.ParentFont := False;
    AssetBrandEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetBrandEdit, Self, 'OnChange', 'AssetBrandEditChange');
    AssetBrandEdit.ItemIndex := -1;
    AssetModelEdit.SetParentComponent(ContentPanel);
    AssetModelEdit.Name := 'AssetModelEdit';
    AssetModelEdit.AlignWithMargins := True;
    AssetModelEdit.Left := 366;
    AssetModelEdit.Top := 386;
    AssetModelEdit.Width := 280;
    AssetModelEdit.Height := 34;
    AssetModelEdit.Margins.Left := 20;
    AssetModelEdit.Margins.Top := 8;
    AssetModelEdit.Margins.Bottom := 8;
    AssetModelEdit.Color := clWhite;
    AssetModelEdit.ElementClassName := 'myselect_white';
    AssetModelEdit.Font.Charset := DEFAULT_CHARSET;
    AssetModelEdit.Font.Color := clWindowText;
    AssetModelEdit.Font.Height := 12;
    AssetModelEdit.Font.Name := 'Segoe UI';
    AssetModelEdit.Font.Style := [];
    AssetModelEdit.HeightPercent := 100.000000000000000000;
    AssetModelEdit.ParentFont := False;
    AssetModelEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetModelEdit, Self, 'OnChange', 'AssetModelEditChange');
    AssetModelEdit.ItemIndex := -1;
    DevicePKeyEdit.SetParentComponent(ContentPanel);
    DevicePKeyEdit.Name := 'DevicePKeyEdit';
    DevicePKeyEdit.Left := 30;
    DevicePKeyEdit.Top := 316;
    DevicePKeyEdit.Width := 202;
    DevicePKeyEdit.Height := 34;
    DevicePKeyEdit.CharCase := wecUpperCase;
    DevicePKeyEdit.ChildOrder := 1;
    DevicePKeyEdit.ElementClassName := 'MyWebEdit';
    DevicePKeyEdit.Font.Charset := ANSI_CHARSET;
    DevicePKeyEdit.Font.Color := clWindowText;
    DevicePKeyEdit.Font.Height := -16;
    DevicePKeyEdit.Font.Name := 'Segoe UI';
    DevicePKeyEdit.Font.Style := [];
    DevicePKeyEdit.HeightPercent := 100.000000000000000000;
    DevicePKeyEdit.ParentFont := False;
    DevicePKeyEdit.ReadOnly := True;
    DevicePKeyEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DevicePKeyEdit, Self, 'OnChange', 'RecordChange');
    SelectDeviceButton.SetParentComponent(ContentPanel);
    SelectDeviceButton.Name := 'SelectDeviceButton';
    SelectDeviceButton.Left := 238;
    SelectDeviceButton.Top := 316;
    SelectDeviceButton.Width := 34;
    SelectDeviceButton.Height := 34;
    SelectDeviceButton.Color := clNone;
    SelectDeviceButton.ElementClassName := 'MyWebButton';
    SelectDeviceButton.Enabled := False;
    SelectDeviceButton.HeightPercent := 100.000000000000000000;
    SelectDeviceButton.MaterialGlyph := 'search';
    SelectDeviceButton.MaterialGlyphColor := clWhite;
    SelectDeviceButton.MaterialGlyphSize := 24;
    SelectDeviceButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectDeviceButton, Self, 'OnClick', 'SelectDeviceButtonClick');
    ClearDeviceButton.SetParentComponent(ContentPanel);
    ClearDeviceButton.Name := 'ClearDeviceButton';
    ClearDeviceButton.Left := 278;
    ClearDeviceButton.Top := 316;
    ClearDeviceButton.Width := 34;
    ClearDeviceButton.Height := 34;
    ClearDeviceButton.Color := clNone;
    ClearDeviceButton.ElementClassName := 'MyWebButton';
    ClearDeviceButton.Enabled := False;
    ClearDeviceButton.HeightPercent := 100.000000000000000000;
    ClearDeviceButton.MaterialGlyph := 'clear';
    ClearDeviceButton.MaterialGlyphColor := clWhite;
    ClearDeviceButton.MaterialGlyphSize := 24;
    ClearDeviceButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearDeviceButton, Self, 'OnClick', 'ClearDeviceButtonClick');
    UserType3Edit.SetParentComponent(ContentPanel);
    UserType3Edit.Name := 'UserType3Edit';
    UserType3Edit.AlignWithMargins := True;
    UserType3Edit.Left := 368;
    UserType3Edit.Top := 598;
    UserType3Edit.Width := 280;
    UserType3Edit.Height := 34;
    UserType3Edit.Margins.Left := 20;
    UserType3Edit.Margins.Top := 8;
    UserType3Edit.Margins.Bottom := 8;
    UserType3Edit.Color := clWhite;
    UserType3Edit.ElementClassName := 'myselect_white';
    UserType3Edit.Font.Charset := DEFAULT_CHARSET;
    UserType3Edit.Font.Color := clWindowText;
    UserType3Edit.Font.Height := 12;
    UserType3Edit.Font.Name := 'Segoe UI';
    UserType3Edit.Font.Style := [];
    UserType3Edit.HeightPercent := 100.000000000000000000;
    UserType3Edit.ParentFont := False;
    UserType3Edit.WidthPercent := 100.000000000000000000;
    SetEvent(UserType3Edit, Self, 'OnChange', 'RecordChange');
    UserType3Edit.ItemIndex := -1;
    UserType4Edit.SetParentComponent(ContentPanel);
    UserType4Edit.Name := 'UserType4Edit';
    UserType4Edit.AlignWithMargins := True;
    UserType4Edit.Left := 30;
    UserType4Edit.Top := 598;
    UserType4Edit.Width := 278;
    UserType4Edit.Height := 34;
    UserType4Edit.Margins.Left := 20;
    UserType4Edit.Margins.Top := 8;
    UserType4Edit.Margins.Bottom := 8;
    UserType4Edit.Color := clWhite;
    UserType4Edit.ElementClassName := 'myselect_white';
    UserType4Edit.Font.Charset := DEFAULT_CHARSET;
    UserType4Edit.Font.Color := clWindowText;
    UserType4Edit.Font.Height := 12;
    UserType4Edit.Font.Name := 'Segoe UI';
    UserType4Edit.Font.Style := [];
    UserType4Edit.HeightPercent := 100.000000000000000000;
    UserType4Edit.ParentFont := False;
    UserType4Edit.WidthPercent := 100.000000000000000000;
    SetEvent(UserType4Edit, Self, 'OnChange', 'RecordChange');
    UserType4Edit.ItemIndex := -1;
    AssetKindEdit.SetParentComponent(ContentPanel);
    AssetKindEdit.Name := 'AssetKindEdit';
    AssetKindEdit.AlignWithMargins := True;
    AssetKindEdit.Left := 30;
    AssetKindEdit.Top := 43;
    AssetKindEdit.Width := 280;
    AssetKindEdit.Height := 34;
    AssetKindEdit.Margins.Left := 20;
    AssetKindEdit.Margins.Top := 8;
    AssetKindEdit.Margins.Bottom := 8;
    AssetKindEdit.Color := clWhite;
    AssetKindEdit.ElementClassName := 'myselect_white';
    AssetKindEdit.Font.Charset := DEFAULT_CHARSET;
    AssetKindEdit.Font.Color := clWindowText;
    AssetKindEdit.Font.Height := 12;
    AssetKindEdit.Font.Name := 'Segoe UI';
    AssetKindEdit.Font.Style := [];
    AssetKindEdit.HeightPercent := 100.000000000000000000;
    AssetKindEdit.ParentFont := False;
    AssetKindEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetKindEdit, Self, 'OnChange', 'RecordChange');
    AssetKindEdit.ItemIndex := -1;
    AssetKindEdit.LookupValues.Clear;
    with AssetKindEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Vehicle';
    end;
    ModelImage.SetParentComponent(ContentPanel);
    ModelImage.Name := 'ModelImage';
    ModelImage.Left := 368;
    ModelImage.Top := 110;
    ModelImage.Width := 280;
    ModelImage.Height := 167;
    ModelImage.ParentDoubleBuffered := False;
    ModelImage.Color := clBtnFace;
    ModelImage.DoubleBuffered := True;
    ModelImage.TabOrder := 19;
    ModelImage.Fill.Kind := gfkNone;
    ModelImage.Stroke.Kind := gskNone;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1291;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 81;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1262;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetEdit.CaptionPanel.CloseImage.Picture.png');
    AlertDBAdapter.SetParentComponent(Self);
    AlertDBAdapter.Name := 'AlertDBAdapter';
    AlertDBAdapter.Left := 871;
    AlertDBAdapter.Top := 283;
    AlertDBAdapter.Width := 26;
    AlertDBAdapter.Height := 26;
    AlertDBAdapter.Margins.Left := 12;
    AlertDBAdapter.Visible := True;
    AlertDBAdapter.Grid := AlertGrid;
    AlertDBAdapter.DataSource := AlertDataSource;
    AlertDBAdapter.Columns.Clear;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'Name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'Origin';
      Header := '  Origin';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AlertDBAdapter.AutoCreateColumns := False;
    AlertDBAdapter.AutoRemoveColumns := False;
    DeviceDBAdapter.SetParentComponent(Self);
    DeviceDBAdapter.Name := 'DeviceDBAdapter';
    DeviceDBAdapter.Left := 871;
    DeviceDBAdapter.Top := 339;
    DeviceDBAdapter.Width := 26;
    DeviceDBAdapter.Height := 26;
    DeviceDBAdapter.Margins.Left := 12;
    DeviceDBAdapter.Visible := True;
    DeviceDBAdapter.Grid := DeviceGrid;
    DeviceDBAdapter.DataSource := DeviceDataSource;
    DeviceDBAdapter.Columns.Clear;
    with DeviceDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DeviceDBAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  IMEI';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DeviceDBAdapter.Columns.Add do
    begin
      FieldName := 'From';
      Header := '  From';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DeviceDBAdapter.Columns.Add do
    begin
      FieldName := 'Till';
      Header := '  Till';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DeviceDBAdapter.AutoCreateColumns := False;
    DeviceDBAdapter.AutoRemoveColumns := False;
    DriverDBAdapter.SetParentComponent(Self);
    DriverDBAdapter.Name := 'DriverDBAdapter';
    DriverDBAdapter.Left := 871;
    DriverDBAdapter.Top := 403;
    DriverDBAdapter.Width := 26;
    DriverDBAdapter.Height := 26;
    DriverDBAdapter.Margins.Left := 12;
    DriverDBAdapter.Visible := True;
    DriverDBAdapter.Grid := DriverGrid;
    DriverDBAdapter.DataSource := DriverDataSource;
    DriverDBAdapter.Columns.Clear;
    with DriverDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DriverDBAdapter.Columns.Add do
    begin
      FieldName := 'Name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DriverDBAdapter.Columns.Add do
    begin
      FieldName := 'From';
      Header := '  From';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DriverDBAdapter.Columns.Add do
    begin
      FieldName := 'Till';
      Header := '  Till';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DriverDBAdapter.AutoCreateColumns := False;
    DriverDBAdapter.AutoRemoveColumns := False;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 761;
    MyMessageDlg.Top := 405;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    DocumentDBAdapter.SetParentComponent(Self);
    DocumentDBAdapter.Name := 'DocumentDBAdapter';
    DocumentDBAdapter.Left := 871;
    DocumentDBAdapter.Top := 227;
    DocumentDBAdapter.Width := 26;
    DocumentDBAdapter.Height := 26;
    DocumentDBAdapter.Margins.Left := 12;
    DocumentDBAdapter.Visible := True;
    DocumentDBAdapter.Grid := DocumentGrid;
    DocumentDBAdapter.DataSource := DocumentDataSource;
    DocumentDBAdapter.Columns.Clear;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'Type';
      Header := '  Type';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'Expiry Date';
      Header := '  Exp. Date';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'Timestamp';
      Header := '  Date';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'Length';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DocumentDBAdapter.AutoCreateColumns := False;
    DocumentDBAdapter.AutoRemoveColumns := False;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    DatasetTimer.Left := 763;
    DatasetTimer.Top := 226;
    AlertDataset.SetParentComponent(Self);
    AlertDataset.Name := 'AlertDataset';
    AlertDataset.AfterOpen := AlertDatasetAfterOpen;
    AlertDataset.Left := 980;
    AlertDataset.Top := 282;
    AlertDatasetPKey.SetParentComponent(AlertDataset);
    AlertDatasetPKey.Name := 'AlertDatasetPKey';
    AlertDatasetPKey.FieldName := 'PKey';
    AlertDatasetPKey.Size := 256;
    AlertDatasetName.SetParentComponent(AlertDataset);
    AlertDatasetName.Name := 'AlertDatasetName';
    AlertDatasetName.FieldName := 'Name';
    AlertDatasetName.Size := 256;
    AlertDatasetOrigin.SetParentComponent(AlertDataset);
    AlertDatasetOrigin.Name := 'AlertDatasetOrigin';
    AlertDatasetOrigin.FieldName := 'Origin';
    AlertDatasetOrigin.Size := 256;
    AlertDataSource.SetParentComponent(Self);
    AlertDataSource.Name := 'AlertDataSource';
    AlertDataSource.DataSet := AlertDataset;
    AlertDataSource.Left := 1092;
    AlertDataSource.Top := 282;
    DeviceDataset.SetParentComponent(Self);
    DeviceDataset.Name := 'DeviceDataset';
    DeviceDataset.Left := 980;
    DeviceDataset.Top := 338;
    DeviceDatasetPKey.SetParentComponent(DeviceDataset);
    DeviceDatasetPKey.Name := 'DeviceDatasetPKey';
    DeviceDatasetPKey.FieldName := 'PKey';
    DeviceDatasetPKey.Size := 256;
    DeviceDatasetID.SetParentComponent(DeviceDataset);
    DeviceDatasetID.Name := 'DeviceDatasetID';
    DeviceDatasetID.FieldName := 'ID';
    DeviceDatasetID.Size := 256;
    DeviceDatasetFrom.SetParentComponent(DeviceDataset);
    DeviceDatasetFrom.Name := 'DeviceDatasetFrom';
    DeviceDatasetFrom.FieldName := 'From';
    DeviceDatasetFrom.Size := 256;
    DeviceDatasetTill.SetParentComponent(DeviceDataset);
    DeviceDatasetTill.Name := 'DeviceDatasetTill';
    DeviceDatasetTill.FieldName := 'Till';
    DeviceDatasetTill.Size := 256;
    DeviceDataSource.SetParentComponent(Self);
    DeviceDataSource.Name := 'DeviceDataSource';
    DeviceDataSource.DataSet := DeviceDataset;
    DeviceDataSource.Left := 1092;
    DeviceDataSource.Top := 338;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.URI := 'https://instatrack.eu:44321/php/default_asset_document_list.php?sessionid=c8b02d2e72032c6d6342c37ec47184ae&pkey=21';
    WebConnection.Left := 762;
    WebConnection.Top := 285;
    DriverDataSet.SetParentComponent(Self);
    DriverDataSet.Name := 'DriverDataSet';
    DriverDataSet.Left := 980;
    DriverDataSet.Top := 402;
    DriverDataSetPKey.SetParentComponent(DriverDataSet);
    DriverDataSetPKey.Name := 'DriverDataSetPKey';
    DriverDataSetPKey.FieldName := 'PKey';
    DriverDataSetPKey.Size := 256;
    DriverDataSetName.SetParentComponent(DriverDataSet);
    DriverDataSetName.Name := 'DriverDataSetName';
    DriverDataSetName.FieldName := 'Name';
    DriverDataSetName.Size := 256;
    DriverDataSetFrom.SetParentComponent(DriverDataSet);
    DriverDataSetFrom.Name := 'DriverDataSetFrom';
    DriverDataSetFrom.FieldName := 'From';
    DriverDataSetFrom.Size := 256;
    DriverDataSetTill.SetParentComponent(DriverDataSet);
    DriverDataSetTill.Name := 'DriverDataSetTill';
    DriverDataSetTill.FieldName := 'Till';
    DriverDataSetTill.Size := 256;
    DriverDataSource.SetParentComponent(Self);
    DriverDataSource.Name := 'DriverDataSource';
    DriverDataSource.DataSet := DriverDataSet;
    DriverDataSource.Left := 1092;
    DriverDataSource.Top := 402;
    DocumentDataSet.SetParentComponent(Self);
    DocumentDataSet.Name := 'DocumentDataSet';
    DocumentDataSet.AfterOpen := DocumentDataSetAfterOpen;
    DocumentDataSet.Left := 980;
    DocumentDataSet.Top := 226;
    DocumentDataSetPKey.SetParentComponent(DocumentDataSet);
    DocumentDataSetPKey.Name := 'DocumentDataSetPKey';
    DocumentDataSetPKey.FieldName := 'PKey';
    DocumentDataSetPKey.Size := 256;
    DocumentDataSetID.SetParentComponent(DocumentDataSet);
    DocumentDataSetID.Name := 'DocumentDataSetID';
    DocumentDataSetID.FieldName := 'ID';
    DocumentDataSetID.Size := 256;
    DocumentDataSetType.SetParentComponent(DocumentDataSet);
    DocumentDataSetType.Name := 'DocumentDataSetType';
    DocumentDataSetType.FieldName := 'Type';
    DocumentDataSetType.Size := 256;
    DocumentDataSetExpiryDate.SetParentComponent(DocumentDataSet);
    DocumentDataSetExpiryDate.Name := 'DocumentDataSetExpiryDate';
    DocumentDataSetExpiryDate.FieldName := 'Expiry Date';
    DocumentDataSetExpiryDate.Size := 256;
    DocumentDataSetTimestamp.SetParentComponent(DocumentDataSet);
    DocumentDataSetTimestamp.Name := 'DocumentDataSetTimestamp';
    DocumentDataSetTimestamp.FieldName := 'Timestamp';
    DocumentDataSetTimestamp.Size := 256;
    DocumentDataSetLength.SetParentComponent(DocumentDataSet);
    DocumentDataSetLength.Name := 'DocumentDataSetLength';
    DocumentDataSetLength.FieldName := 'Length';
    DocumentDataSetLength.Size := 256;
    DocumentDataSource.SetParentComponent(Self);
    DocumentDataSource.Name := 'DocumentDataSource';
    DocumentDataSource.DataSet := DocumentDataSet;
    DocumentDataSource.Left := 1092;
    DocumentDataSource.Top := 226;
  finally
    WebLabel2.AfterLoadDFMValues;
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    AssetTypeLabel.AfterLoadDFMValues;
    AssetGroupLabel.AfterLoadDFMValues;
    AssetNoLabel.AfterLoadDFMValues;
    AssetIDLabel.AfterLoadDFMValues;
    OwnerLabel.AfterLoadDFMValues;
    TenantLabel.AfterLoadDFMValues;
    AssetStatusLabel.AfterLoadDFMValues;
    UserType2Label.AfterLoadDFMValues;
    UserType1Label.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    UserType3Label.AfterLoadDFMValues;
    UserType4Label.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    AssetBrandLabel.AfterLoadDFMValues;
    AssetModelLabel.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    AssetIDEdit.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    PageControl.AfterLoadDFMValues;
    PageControlPage5.AfterLoadDFMValues;
    PageControlPage3.AfterLoadDFMValues;
    DeviceGrid.AfterLoadDFMValues;
    WebMessageDlg3.AfterLoadDFMValues;
    PageControlPage4.AfterLoadDFMValues;
    DriverGrid.AfterLoadDFMValues;
    WebMessageDlg4.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    DocumentGrid.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    AddDocumentButton.AfterLoadDFMValues;
    DeleteDocumentButton.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    EditDocumentButton.AfterLoadDFMValues;
    ViewDocumentButton.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    AddAlertButton.AfterLoadDFMValues;
    DeleteAlertButton.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    AlertGrid.AfterLoadDFMValues;
    WebMessageDlg2.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    LicensePlateLabel.AfterLoadDFMValues;
    ChassisNumberLabel.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    ColorLabel.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    OdoMeterLabel.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    MOTDateLabel.AfterLoadDFMValues;
    LicensePlateEdit.AfterLoadDFMValues;
    ChassisNumberEdit.AfterLoadDFMValues;
    RemarkEdit.AfterLoadDFMValues;
    YearManufacturedEdit.AfterLoadDFMValues;
    DriverPKeyEdit.AfterLoadDFMValues;
    SelectDriverButton.AfterLoadDFMValues;
    ClearDriverButton.AfterLoadDFMValues;
    FuelTypeEdit.AfterLoadDFMValues;
    CarbonDioxideEdit.AfterLoadDFMValues;
    FuelConsumptionEdit.AfterLoadDFMValues;
    KeyNumberEdit.AfterLoadDFMValues;
    KeyCommentEdit.AfterLoadDFMValues;
    BatteryLockEdit.AfterLoadDFMValues;
    ColorEdit.AfterLoadDFMValues;
    OdoMeterEdit.AfterLoadDFMValues;
    OdoMeterDiffEdit.AfterLoadDFMValues;
    MOTDateEdit.AfterLoadDFMValues;
    AssetGroupEdit.AfterLoadDFMValues;
    AssetTypeEdit.AfterLoadDFMValues;
    OwnerEdit.AfterLoadDFMValues;
    TenantEdit.AfterLoadDFMValues;
    AssetStatusEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    RepairHistoryButton.AfterLoadDFMValues;
    StatusHistoryButton.AfterLoadDFMValues;
    AssetNoEdit.AfterLoadDFMValues;
    UserType2Edit.AfterLoadDFMValues;
    UserType1Edit.AfterLoadDFMValues;
    AssetBrandEdit.AfterLoadDFMValues;
    AssetModelEdit.AfterLoadDFMValues;
    DevicePKeyEdit.AfterLoadDFMValues;
    SelectDeviceButton.AfterLoadDFMValues;
    ClearDeviceButton.AfterLoadDFMValues;
    UserType3Edit.AfterLoadDFMValues;
    UserType4Edit.AfterLoadDFMValues;
    AssetKindEdit.AfterLoadDFMValues;
    ModelImage.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    AlertDBAdapter.AfterLoadDFMValues;
    DeviceDBAdapter.AfterLoadDFMValues;
    DriverDBAdapter.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    DocumentDBAdapter.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    AlertDataset.AfterLoadDFMValues;
    AlertDatasetPKey.AfterLoadDFMValues;
    AlertDatasetName.AfterLoadDFMValues;
    AlertDatasetOrigin.AfterLoadDFMValues;
    AlertDataSource.AfterLoadDFMValues;
    DeviceDataset.AfterLoadDFMValues;
    DeviceDatasetPKey.AfterLoadDFMValues;
    DeviceDatasetID.AfterLoadDFMValues;
    DeviceDatasetFrom.AfterLoadDFMValues;
    DeviceDatasetTill.AfterLoadDFMValues;
    DeviceDataSource.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
    DriverDataSet.AfterLoadDFMValues;
    DriverDataSetPKey.AfterLoadDFMValues;
    DriverDataSetName.AfterLoadDFMValues;
    DriverDataSetFrom.AfterLoadDFMValues;
    DriverDataSetTill.AfterLoadDFMValues;
    DriverDataSource.AfterLoadDFMValues;
    DocumentDataSet.AfterLoadDFMValues;
    DocumentDataSetPKey.AfterLoadDFMValues;
    DocumentDataSetID.AfterLoadDFMValues;
    DocumentDataSetType.AfterLoadDFMValues;
    DocumentDataSetExpiryDate.AfterLoadDFMValues;
    DocumentDataSetTimestamp.AfterLoadDFMValues;
    DocumentDataSetLength.AfterLoadDFMValues;
    DocumentDataSource.AfterLoadDFMValues;
  end;
end;

end.

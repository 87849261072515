{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2023                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDirections.GoogleRoutes;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

procedure RegisterGoogleRoutesDirectionsService;
procedure UnRegisterGoogleRoutesDirectionsService;

implementation

uses
  Classes, Math, DateUtils , Types, SysUtils, WEBLib.TMSFNCDirections, WEBLib.TMSFNCGoogleRoutes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes, WEBLib.TMSFNCMapsCommonTypes, WEBLib.TMSFNCCloudBase
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}

  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections, Generics.Defaults
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  , UITypes
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  LB = #13;

type
  TTMSFNCDirectionsGoogleRoutesService = class;

  TTMSFNCDirectionsGoogleRoutesDirectionsFactoryService = class(TTMSFNCDirectionsFactoryService, ITMSFNCDirectionsServiceGoogleRoutes);

  TTMSFNCDirectionsGoogleRoutesService = class(TTMSFNCDirectionsGoogleRoutesDirectionsFactoryService)
  protected
    function DoCreateDirections: ITMSFNCCustomDirections; override;
  end;

type
  TTMSFNCDirectionsGoogleRoutesDirections = class;

  TTMSFNCDirectionsGoogleRoutesDirections = class(TTMSFNCCustomDirectionsInterfacedObject, ITMSFNCCustomDirections)
  protected
    FTravelMode: TTMSFNCDirectionsTravelMode;
    function GetIdentifier: string;
    function IsValid: Boolean;
    function GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
    function GetHost: string;
    function GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
    function GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
    function GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
    procedure Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
    procedure AddHeaders(AHeaders: TTMSFNCCloudBaseRequestHeaders);
    procedure DestroyDirections;
  public
    constructor Create;
    destructor Destroy; override;
  end;

var
  DirectionsService: ITMSFNCDirectionsServiceGoogleRoutes;

procedure RegisterGoogleRoutesDirectionsService;
begin
  if not TTMSFNCDirectionsPlatformServices.Current.SupportsPlatformService(ITMSFNCDirectionsServiceGoogleRoutes, IInterface(DirectionsService)) then
  begin
    DirectionsService := TTMSFNCDirectionsGoogleRoutesService.Create;
    TTMSFNCDirectionsPlatformServices.Current.AddPlatformService(ITMSFNCDirectionsServiceGoogleRoutes, DirectionsService);
  end;
end;

procedure UnregisterGoogleRoutesDirectionsService;
begin
  TTMSFNCDirectionsPlatformServices.Current.RemovePlatformService(ITMSFNCDirectionsServiceGoogleRoutes);
end;

{ TTMSFNCDirectionsGoogleRoutesService }

function TTMSFNCDirectionsGoogleRoutesService.DoCreateDirections: ITMSFNCCustomDirections;
begin
  Result := TTMSFNCDirectionsGoogleRoutesDirections.Create;
end;

procedure TTMSFNCDirectionsGoogleRoutesDirections.AddHeaders(
  AHeaders: TTMSFNCCloudBaseRequestHeaders);
var
  Fields: TStringList;
begin
  Fields := TStringList.Create;

//  Fields.Add('*');

  Fields.Add('routes.distanceMeters');
  Fields.Add('routes.duration');
//  Fields.Add('routes.staticDuration');
  Fields.Add('routes.polyline.encodedPolyline');
  Fields.Add('routes.description');
//  Fields.Add('routes.warnings');
  Fields.Add('routes.routeLabels');
  Fields.Add('routes.travelAdvisory');
  Fields.Add('routes.optimizedIntermediateWaypointIndex');
  Fields.Add('routes.legs.distanceMeters');
  Fields.Add('routes.legs.duration');
//  Fields.Add('routes.legs.staticDuration');
  Fields.Add('routes.legs.polyline.encodedPolyline');
  Fields.Add('routes.legs.startLocation');
  Fields.Add('routes.legs.endLocation');
  Fields.Add('routes.legs.steps.distanceMeters');
  Fields.Add('routes.legs.steps.staticDuration');
  Fields.Add('routes.legs.steps.polyline.encodedPolyline');
  Fields.Add('routes.legs.steps.startLocation');
  Fields.Add('routes.legs.steps.endLocation');
//  Fields.Add('routes.legs.steps.navigationInstructions.maneuver');
  Fields.Add('routes.legs.steps.navigationInstruction.instructions');
//  Fields.Add('routes.legs.steps.travelmode');
//  Fields.Add('routes.legs.steps.transitDetails');

  AHeaders.Add(TTMSFNCCloudBaseRequestHeader.Create('X-Goog-FieldMask', Fields.CommaText));
  Fields.Free;
end;

constructor TTMSFNCDirectionsGoogleRoutesDirections.Create;
begin
  inherited;
end;

destructor TTMSFNCDirectionsGoogleRoutesDirections.Destroy;
begin
  inherited;
end;

procedure TTMSFNCDirectionsGoogleRoutesDirections.DestroyDirections;
begin
  DirectionsService.DestroyDirections(Self);
end;

function TTMSFNCDirectionsGoogleRoutesDirections.GetHost: string;
begin
  Result := 'https://routes.googleapis.com';
end;

function TTMSFNCDirectionsGoogleRoutesDirections.GetIdentifier: string;
begin
  Result := 'GoogleRoutes';
end;

function TTMSFNCDirectionsGoogleRoutesDirections.GetPath(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil): string;
begin
  Result := '/directions/v2:computeRoutes';
end;

function TTMSFNCDirectionsGoogleRoutesDirections.GetPostData(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
      ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
      ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
      AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAvoidTolls: Boolean = False): string;
var
  TravelMode, WayPoints, Locale: string;
  I: Integer;
begin
  if ATravelMode in [tmTruck] then
    FTravelMode := tmDriving
  else
    FTravelMode := ATravelMode;

  case ATravelMode of
    tmDriving: TravelMode := 'DRIVE';
    tmWalking: TravelMode := 'WALK';
    tmBicycling: TravelMode := 'BICYCLE';
    tmPublicTransport: TravelMode := 'TRANSIT';
  end;

  if ALocale = '' then
  begin
    Locale := 'EN';
  end
  else
  begin
    Locale := ALocale;
  end;

  WayPoints := '';

  if ATravelMode <> tmPublicTransport then
  begin
    if Assigned(AWayPointList) and (AWayPointList.Count > 0) then
    begin
      WayPoints := '  "intermediates": [';
        for I := 0 to (AWayPointList.Count - 1) do
          WayPoints := WayPoints + '{"address": "' + AWayPointList[I] + '"},';
      WayPoints := WayPoints + ']';
    end;

    if Assigned(AWaypoints) and (Length(AWayPoints) > 0) then
    begin
      WayPoints := '  "intermediates": [';
        for I := 0 to (AWayPointList.Count - 1) do
          WayPoints := WayPoints +
      '    "location":{' + LB +
      '      "latLng":{' + LB +
      '        "latitude": ' + FloatToStrDot(AWayPoints[I].Latitude) + ',' + LB +
      '        "longitude": ' + FloatToStrDot(AWayPoints[I].Longitude) + '' + LB +
      '      }' + LB +
      '    }' + LB;
      WayPoints := WayPoints + ']' + LB;
    end;

    if WayPoints <> '' then
    begin
      WayPoints := WayPoints +
      ', "optimizeWaypointOrder": ' + LowerCase(BoolToStr(AOptimizeWayPoints, True)) + '' + LB;
    end;
  end;

  Result := '{' + LB +
    '  "origin":{' + LB;

  if AOrigin <> '' then
  begin
    Result := Result + '    "address": "' + AOrigin + '"' + LB;
  end
  else
  begin
    Result := Result +
    '    "location":{' + LB +
    '      "latLng":{' + LB +
    '        "latitude": ' + FloatToStrDot(AOriginLatitude) + ',' + LB +
    '        "longitude": ' + FloatToStrDot(AOriginLongitude) + '' + LB +
    '      }' + LB +
    '    }' + LB;
  end;

  Result := Result + '  },' + LB +
    '  "destination":{' + LB;

  if ADestination <> '' then
  begin
    Result := Result + '    "address": "' + ADestination + '"' + LB;
  end
  else
  begin
    Result := Result +
    '    "location":{' + LB +
    '      "latLng":{' + LB +
    '        "latitude": ' + FloatToStrDot(ADestinationLatitude) + ',' + LB +
    '        "longitude": ' + FloatToStrDot(ADestinationLongitude) + '' + LB +
    '      }' + LB +
    '    }' + LB;
  end;

  Result := Result + '  },' + LB +
    '  "travelMode": "' + TravelMode + '",' + LB;

  Result := DirectionsProperties.GetGoogleRoutesOptions(Result, ATravelMode, AWayPoints, AWayPointList);

  Result := Result +
    '    "computeAlternativeRoutes": ' + LowerCase(BoolToStr(AAlternatives, True)) + ',' + LB +
    '    "routeModifiers": {' + LB +
    '    "avoidTolls": ' + LowerCase(BoolToStr(AAvoidTolls, True)) + ',' + LB +
    '    "avoidHighways": false,' + LB +
    '    "avoidFerries": false,' + LB +

//  '  "vehicleInfo": {"emissionType": "ELECTRIC"},' + LB +

    '  },' + LB +
    '  "languageCode": "' + Locale + '",' + LB +
    '  "units": "IMPERIAL",' + LB +
    WayPoints +
    '}' + LB;
end;

function TTMSFNCDirectionsGoogleRoutesDirections.GetQuery(AOrigin, ADestination: string; AOriginLatitude, AOriginLongitude,
  ADestinationLatitude, ADestinationLongitude: Double; AAlternatives: Boolean = False;
  ATravelMode: TTMSFNCDirectionsTravelMode = tmDriving; AWayPointList: TStringList = nil; AWayPoints: TTMSFNCMapsCoordinateRecArray = nil;
  AOptimizeWayPoints: Boolean = False; ALocale: string = ''; AAVoidTolls: Boolean = False): string;
begin
  Result := 'key=' + DirectionsProperties.GetAPIKey;
end;

function TTMSFNCDirectionsGoogleRoutesDirections.GetRequestMethod: TTMSFNCCloudBaseRequestMethod;
begin
  Result := rmPOST;
end;

function TTMSFNCDirectionsGoogleRoutesDirections.IsValid: Boolean;
begin
  Result := DirectionsProperties.GetAPIKey <> '';
end;

procedure TTMSFNCDirectionsGoogleRoutesDirections.Parse(ARequest: TTMSFNCDirectionsRequest; ARequestResult: string);
var
  d: TTMSFNCDirectionsItems;
  di: TTMSFNCDirectionsItem;
  step, lstep: TTMSFNCDirectionsStep;
  wp: TTMSFNCDirectionsWayPoint;
  l: TTMSFNCDirectionsLeg;
  spi: TTMSFNCSpeedReadingInterval;
  fcm: string;
  fcmint: Integer;

  o, jo, jol, jos: TJSONValue;
  jar, jal, jas: TJSONArray;
  i, j, k, lm: Integer;
  os, ot: TJSONObject;
  wpIndex: array of integer;
begin
  if Assigned(DirectionsProperties) then
  begin
    ARequest.TravelMode := FTravelMode;

    d := ARequest.Items;
    if Assigned(d) then
    begin
      if ARequestResult <> '' then
      begin
        o := TTMSFNCUtils.ParseJSON(ARequestResult);
        if Assigned(o) then
        begin
          try
            ARequest.Status := TTMSFNCUtils.GetJSONProp(o, 'status');
            ARequest.ErrorMessage := TTMSFNCUtils.GetJSONProp(o, 'error_message');

            jar := TTMSFNCUtils.GetJSONValue(o, 'routes') as TJSONArray;
            if Assigned(jar) then
            begin

              for i := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
              begin
                jo := TTMSFNCUtils.GetJSONArrayItem(jar, i);

                di := d.Add;

                di.Summary := TTMSFNCUtils.GetJSONProp(jo, 'description');
                //di.Warnings := TTMSFNCUtils.GetJSONProp(jo, 'warnings'); //array
                di.Distance := TTMSFNCUtils.GetJSONIntegerValue(jo, 'distanceMeters');
                di.Duration := StrToInt(StringReplace(TTMSFNCUtils.GetJSONProp(jo, 'duration'), 's', '', []));

                os := TTMSFNCUtils.GetJSONValue(jo, 'polyline') as TJSONObject;
                if Assigned(os) then
                begin
                  DecodeValues(TTMSFNCUtils.GetJSONProp(os, 'encodedPolyline'), di.Coordinates);
                end;

                SetLength(wpIndex, 1);
                wpIndex[0] := -1;

                jal := TTMSFNCUtils.GetJSONValue(jo, 'optimizedIntermediateWaypointIndex') as TJSONArray;
                if Assigned(jal) then
                begin
                  SetLength(wpIndex, TTMSFNCUtils.GetJSONArraySize(jal) + 1);

                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);
                    if jol.Value <> '' then
                      wpIndex[j + 1] := StrToInt(jol.Value);
                  end;
                end;

                if di is TTMSFNCGoogleRoutesItem then
                begin
                  jas := jo['routeLabels'].AsArray;
                  if Assigned(jas) then
                  begin
                    (di as TTMSFNCGoogleRoutesItem).RouteLabels.JSON := jas.ToJSON;
                  end;

                  jos := jo['travelAdvisory'];
                  if Assigned(jos) then
                  begin
                    fcm := jos['fuelConsumptionMicroliters'].AsString;
                    TryStrToInt(fcm, fcmint);
                    (di as TTMSFNCGoogleRoutesItem).TravelAdvisory.FuelConsumptionMicroliters := fcmint;
//                    (di as TTMSFNCGoogleRoutesItem).TravelAdvisory.JSON := jos.ToJSON;
                    jas := jos['speedReadingIntervals'].AsArray;
                    if Assigned(jas) then
                    begin
                      for K := 0 to jas.Count - 1 do
                      begin
                        spi := (di as TTMSFNCGoogleRoutesItem).TravelAdvisory.SpeedReadingIntervals.Add;
                        spi.JSON := jas[K].ToJSON;

//                        spi.StartPolylinePointIndex := jol['startPolylinePointIndex'].AsInteger;
//                        spi.EndPolylinePointIndex := jol['endPolylinePointIndex'].AsInteger;
//                        spi.Speed := jol['speed'].AsString;
                      end;
                    end;
                  end;
                end;

                jal := TTMSFNCUtils.GetJSONValue(jo, 'legs') as TJSONArray;
                if Assigned(jal) then
                begin
                  for j := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                  begin
                    jol := TTMSFNCUtils.GetJSONArrayItem(jal, j);

                    l := di.Legs.Add;

                    wp := di.WayPoints.Add;
                    if Length(wpIndex) > j then
                      wp.OptimizedIndex := wpIndex[j];

                    //not available
                    //l.StartAddress := TTMSFNCUtils.GetJSONProp(jol, 'start_address');
                    //l.EndAddress := TTMSFNCUtils.GetJSONProp(jol, 'end_address');

                    l.Distance := TTMSFNCUtils.GetJSONIntegerValue(jol, 'distanceMeters');
                    wp.Distance := l.Distance;

                    l.Duration := StrToInt(StringReplace(TTMSFNCUtils.GetJSONProp(jol, 'duration'), 's', '', []));
                    wp.Duration := l.Duration;

                    os := TTMSFNCUtils.GetJSONValue(jol, 'polyline') as TJSONObject;
                    if Assigned(os) then
                    begin
                      DecodeValues(TTMSFNCUtils.GetJSONProp(os, 'encodedPolyline'), l.Coordinates);
                    end;

                    os := TTMSFNCUtils.GetJSONValue(jol, 'endLocation') as TJSONObject;
                    if Assigned(os) then
                    begin
                      ot := TTMSFNCUtils.GetJSONValue(os, 'latLng') as TJSONObject;
                      if Assigned(ot) then
                      begin
                        l.EndLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'latitude');
                        l.EndLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'longitude');
                      end;
                    end;

                    os := TTMSFNCUtils.GetJSONValue(jol, 'startLocation') as TJSONObject;
                    if Assigned(os) then
                    begin
                      ot := TTMSFNCUtils.GetJSONValue(os, 'latLng') as TJSONObject;
                      if Assigned(ot) then
                      begin
                        l.StartLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'latitude');
                        l.StartLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'longitude');
                      end;
                    end;

                    jas := TTMSFNCUtils.GetJSONValue(jol, 'steps') as TJSONArray;
                    if Assigned(jas) then
                    begin
                      for k := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
                      begin
                        jos := TTMSFNCUtils.GetJSONArrayItem(jas, k);

                        step := di.Steps.Add;

                        step.Distance := TTMSFNCUtils.GetJSONIntegerValue(jos, 'distanceMeters');
                        step.Duration := StrToInt(StringReplace(TTMSFNCUtils.GetJSONProp(jos, 'staticDuration'), 's', '', []));

                        os := TTMSFNCUtils.GetJSONValue(jos, 'navigationInstruction') as TJSONObject;
                        if Assigned(os) then
                        begin
                          step.Instructions := TTMSFNCUtils.GetJSONProp(os, 'instructions');
//                          step.Maneuver := TTMSFNCUtils.GetJSONProp(os, 'maneuver');
                        end;

//                        step.TravelMode := TTMSFNCUtils.GetJSONProp(os, 'travelMode');

                        os := TTMSFNCUtils.GetJSONValue(jos, 'polyline') as TJSONObject;
                        if Assigned(os) then
                          DecodeValues(TTMSFNCUtils.GetJSONProp(os, 'encodedPolyline'), step.Coordinates);

                        for lm := 0 to step.Coordinates.Count - 1 do
                          l.Coordinates.Add.Assign(step.Coordinates[lm]);

                        os := TTMSFNCUtils.GetJSONValue(jos, 'endLocation') as TJSONObject;
                        if Assigned(os) then
                        begin
                          ot := TTMSFNCUtils.GetJSONValue(os, 'latLng') as TJSONObject;
                          if Assigned(ot) then
                          begin
                            step.EndLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'latitude');
                            step.EndLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'longitude');
                          end;
                        end;

                        os := TTMSFNCUtils.GetJSONValue(jos, 'startLocation') as TJSONObject;
                        if Assigned(os) then
                        begin
                          ot := TTMSFNCUtils.GetJSONValue(os, 'latLng') as TJSONObject;
                          if Assigned(ot) then
                          begin
                            step.StartLocation.Latitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'latitude');
                            step.StartLocation.Longitude := TTMSFNCUtils.GetJSONDoubleValue(ot, 'longitude');
                          end;
                        end;

                        lstep := l.Steps.Add;
                        lstep.Assign(step);
                      end;
                    end;
                  end;
                end;
              end;
            end;
          finally
            o.Free;
          end;
        end;
      end;
    end;
  end;
end;

end.


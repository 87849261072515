unit uMain;

interface

uses
  // InstaTrack
  uRealTimeMap, uLogin, uDummy,
  // System
  System.SysUtils, System.DateUtils, System.Classes, JS, Web,
  // VCL
  Vcl.StdCtrls, Vcl.Imaging.pngimage, Vcl.Menus, Vcl.Controls,
  // Web Core
  WEBLib.REST, WEBLib.JSON,
  WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.ComCtrls, WEBLib.Menus;

type
  TMainForm = class(TForm)
    ToolbarPanel: TPanel;
    RealTimeMapButton: TButton;
    WebButton2: TButton;
    ReportButton: TButton;
    ContentPageControl: TPageControl;
    WebSheetRealTimeMap: TTabSheet;
    WebSheetDummy: TTabSheet;
    ToolsButton: TButton;
    LogoutButton: TButton;
    ToolsPopupMenu: TPopupMenu;
    MenuItemAlerts: TMenuItem;
    MenuItemLocations: TMenuItem;
    MenuItemGeofences: TMenuItem;
    MenuItemPoi: TMenuItem;
    MenuItemReports: TMenuItem;
    MenuItemShowAddress: TMenuItem;
    N1: TMenuItem;
    MenuItemAssets: TMenuItem;
    MenuItemDevices: TMenuItem;
    MenuItemGeneralSettings: TMenuItem;
    LogoutPopupMenu: TPopupMenu;
    LogoutMenuItem: TMenuItem;
    PersonalSettingsMenuItem: TMenuItem;
    MenuItemUsers: TMenuItem;
    MenuItemAuthorization: TMenuItem;
    MachineMaxButton: TButton;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    procedure RealTimeMapButtonClick(Sender: TObject);
    procedure ToolsButtonClick(Sender: TObject);
    procedure MenuItemDevicesClick(Sender: TObject);
    procedure MenuItemGeneralSettingsClick(Sender: TObject);
    procedure MenuItemReportsClick(Sender: TObject);
    procedure ReportButtonClick(Sender: TObject);
    procedure LogoutButtonClick(Sender: TObject);
    procedure LogoutMenuItemClick(Sender: TObject);
    procedure PersonalSettingsMenuItemClick(Sender: TObject);
    procedure MenuItemUsersClick(Sender: TObject);
    procedure MenuItemAuthorizationClick(Sender: TObject);
    procedure MenuItemPoiClick(Sender: TObject);
    procedure MenuItemAlertsClick(Sender: TObject);
    procedure MenuItemAssetsClick(Sender: TObject);
    [async]
    procedure MachineMaxButtonClick(Sender: TObject); async;
    procedure MenuItemGeofencesClick(Sender: TObject);
    [async]
  private
    { Private declarations }
    [async]
    procedure ShowLoginDialog(); async;
    [async]
    procedure ShowRealTimeMap(); async;
    [async]
    procedure ShowGeofenceList(); async;
    [async]
    procedure ShowAlertList(); async;
    [async]
    procedure ShowPOIList(); async;
    [async]
    procedure ShowReportList(); async;
    [async]
    procedure ShowReportEdit(); async;
    [async]
    procedure ShowAssetList(); async;
    [async]
    procedure ShowDevicesList(); async;
    [async]
    procedure ShowUserList(); async;
    [async]
    procedure ShowAuthorizationList(); async;
    [async]
    procedure ShowGeneralSettings(); async;
    [async]
    procedure ShowPersonalSettings(); async;
  public
    { Public declarations }
    RealTimeMapForm: TRealtimeMapForm;
    LoginForm: TLoginForm;
    DummyForm: TDummyForm;
  protected procedure LoadDFMValues; override; end;

var
  MainForm: TMainForm;

implementation

{$R *.dfm}

uses
  uCommon, uDeviceEdit, uGeneralSettings, uReportList, uReportEdit,
  uPersonalSettings, uUserList, uSecurityGroupList, uDeviceList, uPOIList,
  uAlertList, uAssetList, uPOIGroupEdit, uGeofenceList;

procedure TMainForm.ToolsButtonClick(Sender: TObject);
var
  x, y: Integer;
begin
  // Enable Menu Items
  MenuItemAlerts.Enabled := GetUserPermissionValue(Permissions, 'alerts') > 0;
  MenuItemLocations.Enabled := GetUserPermissionValue(Permissions, 'locations') > 0;
  MenuItemGeofences.Enabled := GetUserPermissionValue(Permissions, 'geofences') > 0;
  MenuItemPoi.Enabled := GetUserPermissionValue(Permissions, 'poi') > 0;
  MenuItemReports.Enabled := GetUserPermissionValue(Permissions, 'reports') > 0;
  MenuItemShowAddress.Enabled := GetUserPermissionValue(Permissions, 'showaddress') > 0;
  MenuItemAssets.Enabled := GetUserPermissionValue(Permissions, 'assets') > 0;
  MenuItemDevices.Enabled := GetUserPermissionValue(Permissions, 'devices') > 0;
  if StrToInt(SessionType) > 0 then begin
    MenuItemUsers.Enabled := GetUserPermissionValue(Permissions, 'users') > 0;
    MenuItemAuthorization.Enabled := GetUserPermissionValue(Permissions, 'authorization') > 0;
  end else begin
    MenuItemUsers.Enabled := False;
    MenuItemAuthorization.Enabled := False;
  end;
  MenuItemGeneralSettings.Enabled := GetUserPermissionValue(Permissions, 'generalsettings') > 0;
  // Show Menu
  x := TButton(Sender).Left;
  y := TButton(Sender).Top + TButton(Sender).Height + 20;
  ToolsPopupMenu.Popup(x, y);
end;

procedure TMainForm.WebFormCreate(Sender: TObject);
begin
//Tag := 0;
  ClearRealTimeObject();
end;

procedure TMainForm.WebFormShow(Sender: TObject);
begin
  // Login
  Tag := 0;
  await(ShowLoginDialog());
  // Update GUI
  MachineMaxButton.Visible := MachineMaxEnabled;
  await(ShowRealTimeMap());
end;

procedure TMainForm.ShowLoginDialog();
begin
  LoginForm := TLoginForm.Create(Self);
  LoginForm.Caption := 'Login';
  LoginForm.Popup := True;
  LoginForm.Border := fbNone;
  await(TLoginForm, LoginForm.Load());
  try
    // excute form and wait for close
    await(TModalResult, LoginForm.Execute);
    if LoginForm.ModalResult = mrOK then begin
    end;
  finally
    LoginForm.Free;
  end;
end;

procedure ShowToolsMenu();
begin
  //
end;

procedure TMainForm.ShowAlertList();
var
  MyPermission: Integer;
  MyWebForm: TAlertListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'alerts');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TAlertListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TAlertListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowGeofenceList();
var
  MyPermission: Integer;
  MyWebForm: TGeofenceListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'geofences');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TGeofenceListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TGeofenceListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowPOIList();
var
  MyPermission: Integer;
  MyWebForm: TPOIListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'poi');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TPOIListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TPOIListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowReportList();
var
  MyPermission: Integer;
  MyWebForm: TReportListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'reports');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TReportListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TReportListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
        // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowReportEdit();
var
  MyWebForm: TReportEditForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TReportEditForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  // Load Forms
  await(TReportEditForm, MyWebForm.Load());
  try
    // Initialize Form
    MyWebForm.ReportPKey := -1;
    MyWebForm.MailButton.Visible := True;
    MyWebForm.PrintButton.Visible := True;
    MyWebForm.ReportTitleEdit.ReadOnly := True;
    MyWebForm.ReportTypeEdit.Value := '1';
    MyWebForm.ReportFormatEdit.Value := '1';
    MyWebForm.PeriodEdit.Value := '1';
    MyWebForm.DateFromEdit.Date := Date();
    MyWebForm.DateTillEdit.Date := IncDay(Date(), 1);
    if RealTimeObject.IMEI <> EmptyStr then begin
      MyWebForm.SelectObjectFilterType('1', True);
      MyWebForm.ObjectNoEdit.Text := IntToStr(RealTimeObject.Symbol);
    end else begin
      MyWebForm.SelectObjectFilterType(ReportObjectFilterType, True);
    end;
    MyWebForm.TeamFilterEdit.Tag := 0;
    MyWebForm.TypeFilterEdit.Tag := 0;
    MyWebForm.IsSet := True;
    // Hide Save Button
    MyWebForm.SaveButton.Visible := False;
    // Show / Hide Tabs
    MyWebForm.ReportTypePageControl.Pages[4].TabVisible := False;
    MyWebForm.ReportTypePageControl.Pages[5].TabVisible := False;
    MyWebForm.ReportTypePageControl.Pages[0].TabVisible := True;
    MyWebForm.ReportTypePageControl.ActivePageIndex := 0;
    // Distance
    MyWebForm.AssetDistanceEdit.Checked := False;
    MyWebForm.AssetDistanceTypeEdit.Value := '3';
    MyWebForm.AssetDistanceMinEdit.Text := '0';
    MyWebForm.AssetDistanceMaxEdit.Text := '0';
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TMainForm.ShowAssetList();
var
  MyPermission: Integer;
  MyWebForm: TAssetListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'assets');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TAssetListForm.Create(Self);
    MyWebForm.Popup := False;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TAssetListForm, MyWebForm.Load());
    try
      // Set Permissions
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowDevicesList();
var
  MyPermission: Integer;
  MyWebForm: TDeviceListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'devices');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TDeviceListForm.Create(Self);
//  MyWebForm.Popup := True;
    MyWebForm.Popup := False;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TDeviceListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowUserList();
var
  MyPermission: Integer;
  MyWebForm: TUserListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'users');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TUserListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TReportListForm, MyWebForm.Load());

    try
      // Set Permission
      MyWebForm.ManagerButton.Visible := (StrToInt(SessionType) > 0);
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowAuthorizationList();
var
  MyPermission: Integer;
  MyWebForm: TSecurityGroupForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'authorization');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TSecurityGroupForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TSecurityGroupForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowGeneralSettings();
var
  MyPermission: Integer;
  MyWebForm: TGeneralSettingsForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'generalsettings');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TGeneralSettingsForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TGeneralSettingsForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TMainForm.ShowPersonalSettings();
var
  MyWebForm: TPersonalSettingsForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TPersonalSettingsForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  // Load Form
  await(TPersonalSettingsForm, MyWebForm.Load());
  try
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      if Assigned(RealTimeMapForm) then begin
        RealTimeMapForm.ShowObjectsMap(MarkerClustering);
      end;
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TMainForm.MenuItemGeneralSettingsClick(Sender: TObject);
begin
  ShowGeneralSettings();
end;

procedure TMainForm.MenuItemGeofencesClick(Sender: TObject);
begin
  ShowGeofenceList();
end;

procedure TMainForm.MenuItemPoiClick(Sender: TObject);
begin
  ShowPOIList();
end;

procedure TMainForm.MachineMaxButtonClick(Sender: TObject);
begin
  if Assigned(RealTimeMapForm) then begin
    if MachineMaxButton.Tag = 0 then begin
      MachineMaxButton.Tag := 1;
      MachineMaxButton.ElementClassName := 'MyMachineMaxButton';
    end else begin
      MachineMaxButton.Tag := 0;
      MachineMaxButton.ElementClassName :=  'MyWebButton';
    end;
    RealTimeMapForm.MachineMax := (MachineMaxButton.Tag = 1);
    if RealTimeMapForm.ObjectRefreshTimer.Enabled then begin
      RealTimeMapForm.ObjectRefreshTimer.Enabled := False;
      try
        RealTimeMapForm.RefreshThirdPartyMarkers();
      finally
        RealTimeMapForm.ObjectRefreshTimer.Enabled := True;
      end;
    end;
  end;
end;

procedure TMainForm.MenuItemAlertsClick(Sender: TObject);
begin
  ShowAlertList();
end;

procedure TMainForm.MenuItemAssetsClick(Sender: TObject);
begin
  ShowAssetList();
end;

procedure TMainForm.MenuItemAuthorizationClick(Sender: TObject);
begin
  ShowAuthorizationList();
end;

procedure TMainForm.MenuItemDevicesClick(Sender: TObject);
begin
  ShowDevicesList();
end;

procedure TMainForm.LogoutButtonClick(Sender: TObject);
var
  x, y: Integer;
begin
  x := TButton(Sender).Left;
  y := TButton(Sender).Top + TButton(Sender).Height + 20;
  LogoutPopupMenu.Popup(x-75, y);
end;

procedure TMainForm.LogoutMenuItemClick(Sender: TObject);
begin
  Application.Navigate(url_www, TNavigationTarget.ntPage);
end;

procedure TMainForm.ReportButtonClick(Sender: TObject);
begin
  ShowReportEdit();
end;

procedure TMainForm.RealTimeMapButtonClick(Sender: TObject);
begin
  ShowRealTimeMap();
end;

procedure TMainForm.MenuItemReportsClick(Sender: TObject);
begin
  ShowReportList();
end;

procedure TMainForm.MenuItemUsersClick(Sender: TObject);
begin
  ShowUserList();
end;

procedure TMainForm.PersonalSettingsMenuItemClick(Sender: TObject);
begin
  ShowPersonalSettings();
end;

procedure TMainForm.ShowRealTimeMap();

  procedure FormCreated(AForm: TObject);
  begin
    Realign;
  end;

begin
  ContentPageControl.ActivePage := WebSheetRealTimeMap;
  if not(Assigned(RealTimeMapForm)) then begin
    RealTimeMapForm := TRealtimeMapForm.CreateNew(WebSheetRealTimeMap.ElementID, @FormCreated);
  end;
end;

procedure TMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ToolbarPanel := TPanel.Create(Self);
  RealTimeMapButton := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  ReportButton := TButton.Create(Self);
  ToolsButton := TButton.Create(Self);
  LogoutButton := TButton.Create(Self);
  MachineMaxButton := TButton.Create(Self);
  ContentPageControl := TPageControl.Create(Self);
  WebSheetRealTimeMap := TTabSheet.Create(Self);
  WebSheetDummy := TTabSheet.Create(Self);
  ToolsPopupMenu := TPopupMenu.Create(Self);
  MenuItemAlerts := TMenuItem.Create(Self);
  MenuItemLocations := TMenuItem.Create(Self);
  MenuItemGeofences := TMenuItem.Create(Self);
  MenuItemPoi := TMenuItem.Create(Self);
  MenuItemReports := TMenuItem.Create(Self);
  MenuItemShowAddress := TMenuItem.Create(Self);
  N1 := TMenuItem.Create(Self);
  MenuItemAssets := TMenuItem.Create(Self);
  MenuItemDevices := TMenuItem.Create(Self);
  MenuItemUsers := TMenuItem.Create(Self);
  MenuItemAuthorization := TMenuItem.Create(Self);
  MenuItemGeneralSettings := TMenuItem.Create(Self);
  LogoutPopupMenu := TPopupMenu.Create(Self);
  LogoutMenuItem := TMenuItem.Create(Self);
  PersonalSettingsMenuItem := TMenuItem.Create(Self);

  ToolbarPanel.BeforeLoadDFMValues;
  RealTimeMapButton.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  ReportButton.BeforeLoadDFMValues;
  ToolsButton.BeforeLoadDFMValues;
  LogoutButton.BeforeLoadDFMValues;
  MachineMaxButton.BeforeLoadDFMValues;
  ContentPageControl.BeforeLoadDFMValues;
  WebSheetRealTimeMap.BeforeLoadDFMValues;
  WebSheetDummy.BeforeLoadDFMValues;
  ToolsPopupMenu.BeforeLoadDFMValues;
  MenuItemAlerts.BeforeLoadDFMValues;
  MenuItemLocations.BeforeLoadDFMValues;
  MenuItemGeofences.BeforeLoadDFMValues;
  MenuItemPoi.BeforeLoadDFMValues;
  MenuItemReports.BeforeLoadDFMValues;
  MenuItemShowAddress.BeforeLoadDFMValues;
  N1.BeforeLoadDFMValues;
  MenuItemAssets.BeforeLoadDFMValues;
  MenuItemDevices.BeforeLoadDFMValues;
  MenuItemUsers.BeforeLoadDFMValues;
  MenuItemAuthorization.BeforeLoadDFMValues;
  MenuItemGeneralSettings.BeforeLoadDFMValues;
  LogoutPopupMenu.BeforeLoadDFMValues;
  LogoutMenuItem.BeforeLoadDFMValues;
  PersonalSettingsMenuItem.BeforeLoadDFMValues;
  try
    Name := 'MainForm';
    Width := 1343;
    Height := 480;
    Align := alClient;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ToolbarPanel.SetParentComponent(Self);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1343;
    ToolbarPanel.Height := 60;
    ToolbarPanel.ElementClassName := 'MyWebPanel';
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 6;
    RealTimeMapButton.SetParentComponent(ToolbarPanel);
    RealTimeMapButton.Name := 'RealTimeMapButton';
    RealTimeMapButton.AlignWithMargins := True;
    RealTimeMapButton.Left := 140;
    RealTimeMapButton.Top := 10;
    RealTimeMapButton.Width := 120;
    RealTimeMapButton.Height := 40;
    RealTimeMapButton.Margins.Left := 10;
    RealTimeMapButton.Margins.Top := 10;
    RealTimeMapButton.Margins.Right := 0;
    RealTimeMapButton.Margins.Bottom := 10;
    RealTimeMapButton.Align := alLeft;
    RealTimeMapButton.Caption := 'Maps';
    RealTimeMapButton.ElementClassName := 'MyWebButton';
    RealTimeMapButton.HeightPercent := 100.000000000000000000;
    RealTimeMapButton.WidthPercent := 100.000000000000000000;
    SetEvent(RealTimeMapButton, Self, 'OnClick', 'RealTimeMapButtonClick');
    WebButton2.SetParentComponent(ToolbarPanel);
    WebButton2.Name := 'WebButton2';
    WebButton2.AlignWithMargins := True;
    WebButton2.Left := 10;
    WebButton2.Top := 10;
    WebButton2.Width := 120;
    WebButton2.Height := 40;
    WebButton2.Margins.Left := 10;
    WebButton2.Margins.Top := 10;
    WebButton2.Margins.Right := 0;
    WebButton2.Margins.Bottom := 10;
    WebButton2.Align := alLeft;
    WebButton2.Caption := 'Dashboard';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'MyWebButton';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.Visible := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    ReportButton.SetParentComponent(ToolbarPanel);
    ReportButton.Name := 'ReportButton';
    ReportButton.AlignWithMargins := True;
    ReportButton.Left := 270;
    ReportButton.Top := 10;
    ReportButton.Width := 120;
    ReportButton.Height := 40;
    ReportButton.Margins.Left := 10;
    ReportButton.Margins.Top := 10;
    ReportButton.Margins.Right := 0;
    ReportButton.Margins.Bottom := 10;
    ReportButton.Align := alLeft;
    ReportButton.Caption := 'History ';
    ReportButton.ChildOrder := 4;
    ReportButton.ElementClassName := 'MyWebButton';
    ReportButton.ElementFont := efCSS;
    ReportButton.HeightPercent := 100.000000000000000000;
    ReportButton.WidthPercent := 100.000000000000000000;
    SetEvent(ReportButton, Self, 'OnClick', 'ReportButtonClick');
    ToolsButton.SetParentComponent(ToolbarPanel);
    ToolsButton.Name := 'ToolsButton';
    ToolsButton.AlignWithMargins := True;
    ToolsButton.Left := 1083;
    ToolsButton.Top := 10;
    ToolsButton.Width := 120;
    ToolsButton.Height := 40;
    ToolsButton.Margins.Left := 0;
    ToolsButton.Margins.Top := 10;
    ToolsButton.Margins.Right := 10;
    ToolsButton.Margins.Bottom := 10;
    ToolsButton.Align := alRight;
    ToolsButton.Caption := 'Tools';
    ToolsButton.ChildOrder := 3;
    ToolsButton.ElementClassName := 'MyWebButton';
    ToolsButton.HeightPercent := 100.000000000000000000;
    ToolsButton.WidthPercent := 100.000000000000000000;
    SetEvent(ToolsButton, Self, 'OnClick', 'ToolsButtonClick');
    LogoutButton.SetParentComponent(ToolbarPanel);
    LogoutButton.Name := 'LogoutButton';
    LogoutButton.AlignWithMargins := True;
    LogoutButton.Left := 1213;
    LogoutButton.Top := 10;
    LogoutButton.Width := 120;
    LogoutButton.Height := 40;
    LogoutButton.Margins.Left := 0;
    LogoutButton.Margins.Top := 10;
    LogoutButton.Margins.Right := 10;
    LogoutButton.Margins.Bottom := 10;
    LogoutButton.Align := alRight;
    LogoutButton.Caption := 'My Account';
    LogoutButton.ChildOrder := 4;
    LogoutButton.ElementClassName := 'MyWebButton';
    LogoutButton.ElementFont := efCSS;
    LogoutButton.HeightPercent := 100.000000000000000000;
    LogoutButton.WidthPercent := 100.000000000000000000;
    SetEvent(LogoutButton, Self, 'OnClick', 'LogoutButtonClick');
    MachineMaxButton.SetParentComponent(ToolbarPanel);
    MachineMaxButton.Name := 'MachineMaxButton';
    MachineMaxButton.AlignWithMargins := True;
    MachineMaxButton.Left := 400;
    MachineMaxButton.Top := 10;
    MachineMaxButton.Width := 120;
    MachineMaxButton.Height := 40;
    MachineMaxButton.Margins.Left := 10;
    MachineMaxButton.Margins.Top := 10;
    MachineMaxButton.Margins.Right := 0;
    MachineMaxButton.Margins.Bottom := 10;
    MachineMaxButton.Align := alLeft;
    MachineMaxButton.Caption := 'MachineMax';
    MachineMaxButton.ChildOrder := 4;
    MachineMaxButton.ElementClassName := 'MyWebButton';
    MachineMaxButton.ElementFont := efCSS;
    MachineMaxButton.HeightPercent := 100.000000000000000000;
    MachineMaxButton.Visible := False;
    MachineMaxButton.WidthPercent := 100.000000000000000000;
    SetEvent(MachineMaxButton, Self, 'OnClick', 'MachineMaxButtonClick');
    ContentPageControl.SetParentComponent(Self);
    ContentPageControl.Name := 'ContentPageControl';
    ContentPageControl.Left := 0;
    ContentPageControl.Top := 60;
    ContentPageControl.Width := 1343;
    ContentPageControl.Height := 420;
    ContentPageControl.Align := alClient;
    ContentPageControl.ChildOrder := 2;
    ContentPageControl.TabIndex := 1;
    ContentPageControl.ShowTabs := False;
    ContentPageControl.TabOrder := 1;
    WebSheetRealTimeMap.SetParentComponent(ContentPageControl);
    WebSheetRealTimeMap.Name := 'WebSheetRealTimeMap';
    WebSheetRealTimeMap.Left := 0;
    WebSheetRealTimeMap.Top := 20;
    WebSheetRealTimeMap.Width := 1343;
    WebSheetRealTimeMap.Height := 400;
    WebSheetRealTimeMap.ElementClassName := 'MyWebPageControl';
    WebSheetRealTimeMap.Caption := 'Realtime Map';
    WebSheetRealTimeMap.ChildOrder := 3;
    WebSheetDummy.SetParentComponent(ContentPageControl);
    WebSheetDummy.Name := 'WebSheetDummy';
    WebSheetDummy.Left := 0;
    WebSheetDummy.Top := 20;
    WebSheetDummy.Width := 1343;
    WebSheetDummy.Height := 400;
    WebSheetDummy.Caption := 'Dummy';
    WebSheetDummy.ChildOrder := 1;
    ToolsPopupMenu.SetParentComponent(Self);
    ToolsPopupMenu.Name := 'ToolsPopupMenu';
    ToolsPopupMenu.Appearance.BackgroundColor := 12498604;
    ToolsPopupMenu.Appearance.HamburgerMenu.Caption := 'Menu';
    ToolsPopupMenu.Appearance.HoverColor := 13485241;
    ToolsPopupMenu.Appearance.HoverFontColor := clWhite;
    ToolsPopupMenu.Appearance.SubmenuIndicator := '&#9658;';
    ToolsPopupMenu.Font.Charset := DEFAULT_CHARSET;
    ToolsPopupMenu.Font.Color := clWindowText;
    ToolsPopupMenu.Font.Height := -11;
    ToolsPopupMenu.Font.Name := 'Tahoma';
    ToolsPopupMenu.Font.Style := [];
    ToolsPopupMenu.Left := 776;
    ToolsPopupMenu.Top := 160;
    MenuItemAlerts.SetParentComponent(ToolsPopupMenu);
    MenuItemAlerts.Name := 'MenuItemAlerts';
    MenuItemAlerts.Caption := 'Alerts';
    SetEvent(MenuItemAlerts, Self, 'OnClick', 'MenuItemAlertsClick');
    MenuItemLocations.SetParentComponent(ToolsPopupMenu);
    MenuItemLocations.Name := 'MenuItemLocations';
    MenuItemLocations.Caption := 'Locations & Sites';
    MenuItemGeofences.SetParentComponent(ToolsPopupMenu);
    MenuItemGeofences.Name := 'MenuItemGeofences';
    MenuItemGeofences.Caption := 'Geofences';
    SetEvent(MenuItemGeofences, Self, 'OnClick', 'MenuItemGeofencesClick');
    MenuItemPoi.SetParentComponent(ToolsPopupMenu);
    MenuItemPoi.Name := 'MenuItemPoi';
    MenuItemPoi.Caption := 'Point of Interests';
    SetEvent(MenuItemPoi, Self, 'OnClick', 'MenuItemPoiClick');
    MenuItemReports.SetParentComponent(ToolsPopupMenu);
    MenuItemReports.Name := 'MenuItemReports';
    MenuItemReports.Caption := 'Reports';
    SetEvent(MenuItemReports, Self, 'OnClick', 'MenuItemReportsClick');
    MenuItemShowAddress.SetParentComponent(ToolsPopupMenu);
    MenuItemShowAddress.Name := 'MenuItemShowAddress';
    MenuItemShowAddress.Caption := 'Show Address';
    N1.SetParentComponent(ToolsPopupMenu);
    N1.Name := 'N1';
    N1.Caption := '-';
    MenuItemAssets.SetParentComponent(ToolsPopupMenu);
    MenuItemAssets.Name := 'MenuItemAssets';
    MenuItemAssets.Caption := 'Assets';
    SetEvent(MenuItemAssets, Self, 'OnClick', 'MenuItemAssetsClick');
    MenuItemDevices.SetParentComponent(ToolsPopupMenu);
    MenuItemDevices.Name := 'MenuItemDevices';
    MenuItemDevices.Caption := 'Devices';
    SetEvent(MenuItemDevices, Self, 'OnClick', 'MenuItemDevicesClick');
    MenuItemUsers.SetParentComponent(ToolsPopupMenu);
    MenuItemUsers.Name := 'MenuItemUsers';
    MenuItemUsers.Caption := 'Users';
    SetEvent(MenuItemUsers, Self, 'OnClick', 'MenuItemUsersClick');
    MenuItemAuthorization.SetParentComponent(ToolsPopupMenu);
    MenuItemAuthorization.Name := 'MenuItemAuthorization';
    MenuItemAuthorization.Caption := 'Authorization';
    SetEvent(MenuItemAuthorization, Self, 'OnClick', 'MenuItemAuthorizationClick');
    MenuItemGeneralSettings.SetParentComponent(ToolsPopupMenu);
    MenuItemGeneralSettings.Name := 'MenuItemGeneralSettings';
    MenuItemGeneralSettings.Caption := 'General Settings';
    SetEvent(MenuItemGeneralSettings, Self, 'OnClick', 'MenuItemGeneralSettingsClick');
    LogoutPopupMenu.SetParentComponent(Self);
    LogoutPopupMenu.Name := 'LogoutPopupMenu';
    LogoutPopupMenu.Appearance.BackgroundColor := 12498604;
    LogoutPopupMenu.Appearance.HamburgerMenu.Caption := 'Menu';
    LogoutPopupMenu.Appearance.HoverColor := 13485241;
    LogoutPopupMenu.Appearance.HoverFontColor := clWhite;
    LogoutPopupMenu.Appearance.SubmenuIndicator := '&#9658;';
    LogoutPopupMenu.Font.Charset := DEFAULT_CHARSET;
    LogoutPopupMenu.Font.Color := clWindowText;
    LogoutPopupMenu.Font.Height := -11;
    LogoutPopupMenu.Font.Name := 'Tahoma';
    LogoutPopupMenu.Font.Style := [];
    LogoutPopupMenu.Left := 776;
    LogoutPopupMenu.Top := 224;
    LogoutMenuItem.SetParentComponent(LogoutPopupMenu);
    LogoutMenuItem.Name := 'LogoutMenuItem';
    LogoutMenuItem.Caption := 'Logout';
    SetEvent(LogoutMenuItem, Self, 'OnClick', 'LogoutMenuItemClick');
    PersonalSettingsMenuItem.SetParentComponent(LogoutPopupMenu);
    PersonalSettingsMenuItem.Name := 'PersonalSettingsMenuItem';
    PersonalSettingsMenuItem.Caption := 'Personal Settings';
    SetEvent(PersonalSettingsMenuItem, Self, 'OnClick', 'PersonalSettingsMenuItemClick');
  finally
    ToolbarPanel.AfterLoadDFMValues;
    RealTimeMapButton.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    ReportButton.AfterLoadDFMValues;
    ToolsButton.AfterLoadDFMValues;
    LogoutButton.AfterLoadDFMValues;
    MachineMaxButton.AfterLoadDFMValues;
    ContentPageControl.AfterLoadDFMValues;
    WebSheetRealTimeMap.AfterLoadDFMValues;
    WebSheetDummy.AfterLoadDFMValues;
    ToolsPopupMenu.AfterLoadDFMValues;
    MenuItemAlerts.AfterLoadDFMValues;
    MenuItemLocations.AfterLoadDFMValues;
    MenuItemGeofences.AfterLoadDFMValues;
    MenuItemPoi.AfterLoadDFMValues;
    MenuItemReports.AfterLoadDFMValues;
    MenuItemShowAddress.AfterLoadDFMValues;
    N1.AfterLoadDFMValues;
    MenuItemAssets.AfterLoadDFMValues;
    MenuItemDevices.AfterLoadDFMValues;
    MenuItemUsers.AfterLoadDFMValues;
    MenuItemAuthorization.AfterLoadDFMValues;
    MenuItemGeneralSettings.AfterLoadDFMValues;
    LogoutPopupMenu.AfterLoadDFMValues;
    LogoutMenuItem.AfterLoadDFMValues;
    PersonalSettingsMenuItem.AfterLoadDFMValues;
  end;
end;

end.

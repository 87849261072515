{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGridDatabaseAdapter;

{$I WEBLib.TMSFNCDefines.inc}

{$IFNDEF LCLLIB}
//{$DEFINE TMSDEBUG}
{$ENDIF}

{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomGrid, WEBLib.TMSFNCGridCell, WEBLib.TMSFNCGridData,
  {%H-}WEBLib.TMSFNCTypes, WEBLib.Graphics, WEBLib.ExtCtrls
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  {$IFDEF LCLWEBLIB}
  ,DB
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,Data.DB
  {$ENDIF}
  {$IFDEF TMSDEBUG}
  ,TMSLoggingCore
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 18; // Build nr.

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Improved: When OnGetRecordCount is assigned, do not use RowsInDataSet to return number of rows
  // v1.0.0.2 : Fixed: Issue with editing due to alternate cell display build-up code for LCL
  // v1.0.0.3 : Fixed: Issue where dataset type changed from sequenced to non-sequenced during inserting
  // v1.0.0.4 : New: LoadAllDataAndDisconnect to load all data at once and perform filtering, sorting, grouping, ...
  // v1.0.0.5 : Fixed: Alternate build up causing issues when scrolling
  // v1.0.0.6 : Fixed: Issue editing with control has not parent issue
  // v1.0.0.7 : Fixed: Issue with insert and post via row selection
  // v1.0.0.8 : Fixed: Issue inserting row in empty dataset
  // v1.0.0.9 : Fixed: Issue with inserting/appending rows
  // v1.0.0.10: Fixed: Issue with getting SVG from blob field
  // v1.0.0.11: Fixed: Issue with checkbox and selection combination not functioning properly
  // v1.0.0.12: Fixed: Issue with argument out of range when mousewheel scrolling
  // v1.0.0.13: Improved: Loading speed of LoadAllDataAndDisconnect
  // v1.0.0.14: Improved: DataSet Insert/Append instructions
  // v1.0.0.15: Fixed: Issue with Locate not selecting record
  // v1.0.0.16: Fixed: Issue with centering checkboxes/radiobuttons
  // v1.0.0.17: Fixed: Issue with disconnected dataset
  // v1.0.0.18: Fixed: Issue with loading data inside grid related to checkboxes

resourcestring
  sTMSFNCGridDatabaseAdapterUnidirectionalDataSet = 'Unidirectional DataSet.';
  sTMSFNCGridDatabaseAdapterNoGridAssigned = 'No grid assigned.';
  sTMSFNCGridDatabaseAdapterNoFieldsSpecified = 'No fields specified.';
  sTMSFNCGridDatabaseAdapterCannotAddFieldsDataSource = 'Cannot add fields, no datasource assigned.';
  sTMSFNCGridDatabaseAdapterCannotAddFieldsDataSet = 'Cannot add fields, no DataSet specified for datasource.';
  sTMSFNCGridDatabaseAdapterCheckBoxField = 'Cannot assign true when CheckBoxField or ProgressField is also true.';
  sTMSFNCGridDatabaseAdapterPictureField = 'Cannot assign true when PictureField or ProgressField is also true.';
  sTMSFNCGridDatabaseAdapterCheckPictureField = 'Cannot assign true when CheckBoxField or PictureField is also true.';

type
  TTMSFNCGridDatabaseAdapter = class;

  TTMSFNCGridDatabaseAdapterItemDataLink = class(TDataLink)
  private
    FModified: Boolean;
    FAdapter: TTMSFNCGridDatabaseAdapter;
  protected
    procedure ActiveChanged; override;
    procedure DataSetChanged; override;
    procedure DataSetScrolled(Distance: Integer); override;
    procedure RecordChanged(Field: TField); override;
    procedure UpdateData; override;
    function Adapter: TTMSFNCGridAdapter;
  public
    constructor Create(AAdapter: TTMSFNCGridDatabaseAdapter);
    destructor Destroy; override;
    procedure Modified;
    procedure Reset;
  end;

  TTMSFNCGridDatabaseAdapterColumn = class(TCollectionItem)
  private
    FAdapter: TTMSFNCGridDatabaseAdapter;
    FField: TField;
    FFieldName: string;
    FAutoCreated: Boolean;
    FHeader: string;
    FCheckBoxField: Boolean;
    FPictureField: Boolean;
    FProgressField: Boolean;
    FCheckTrue: string;
    FCheckFalse: string;
    FUseLookupEditor: boolean;
    FHTMLTemplate: string;
    FUseColumnEditor: Boolean;
    procedure SetFieldName(const Value: string);
    function GetField: TField;
    procedure SetField(Value: TField);
    procedure SetHeader(const Value: string);
    procedure SetCheckBoxField(const Value: Boolean);
    procedure SetPictureField(const Value: Boolean);
    procedure SetProgressField(const Value: Boolean);
    procedure SetHTMLTemplate(const Value: string);
  protected
    function GetDisplayName: string; override;
    property AutoCreated: Boolean read FAutoCreated write FAutoCreated;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    function Adapter: TTMSFNCGridDatabaseAdapter;
    property Field: TField read GetField write SetField;
  published
    property FieldName: string read FFieldName write SetFieldName;
    property Header: string read FHeader write SetHeader;
    property CheckBoxField: Boolean read FCheckBoxField write SetCheckBoxField default false;
    property ProgressField: Boolean read FProgressField write SetProgressField default false;
    property PictureField: Boolean read FPictureField write SetPictureField default false;
    property CheckFalse: string read FCheckFalse write FCheckFalse;
    property UseLookupEditor: Boolean read FUseLookupEditor write FUseLookupEditor default True;
    property UseColumnEditor: Boolean read FUseColumnEditor write FUseColumnEditor default False;
    property CheckTrue: string read FCheckTrue write FCheckTrue;
    property HTMLTemplate: string read FHTMLTemplate write SetHTMLTemplate;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGridDatabaseAdapterColumns = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGridDatabaseAdapterColumns = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGridDatabaseAdapterColumn>)
  {$ENDIF}
  private
    FAdapter: TTMSFNCGridDatabaseAdapter;
    function GetItem(Index: Integer): TTMSFNCGridDatabaseAdapterColumn;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGridDatabaseAdapterColumn);
  public
    constructor Create(AAdapter: TTMSFNCGridDatabaseAdapter);
    function HasFieldsDefined: boolean;
    function Add: TTMSFNCGridDatabaseAdapterColumn;
    function Insert(index: Integer): TTMSFNCGridDatabaseAdapterColumn;
    function Adapter: TTMSFNCGridDatabaseAdapter;
    property Items[Index: Integer]: TTMSFNCGridDatabaseAdapterColumn read GetItem write SetItem; default;
  end;

  TTMSFNCGridDatabaseAdapterDataSetType = (adsSequenced, adsNonSequenced);
  TTMSFNCGridDatabaseAdapterPostMode = (apmNone, apmCell, apmRow);

  TTMSFNCGridDatabaseAdapterGetRecordCountEvent = procedure(Sender: TObject; var ARecordCount: Integer) of object;
  TTMSFNCGridDatabaseAdapterGetHTMLTemplateDataEvent = procedure(Sender: TObject; ACol, ARow: integer; AFieldname: string; var AData: string) of object;
  TTMSFNCGridDatabaseAdapterGetHTMLTemplateEvent = procedure(Sender: TObject; ACol, ARow: integer; var AHTMLTemplate: string; AFields: TFields) of object;

  TTMSFNCGridDatabaseAdapterLookupItem = class
  private
    FIndex: Integer;
  public
    constructor Create(AIndex: Integer);
    property Index: Integer read FIndex;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCGridDatabaseAdapter = class(TTMSFNCGridAdapter)
  private
    FSkipRetrieveData: Boolean;
    FCellToSelect: TTMSFNCGridCellRec;
    FSelectTimer: TTimer;
    FDoNotCountRow: Boolean;
    FMustEnableControls: Integer;
    FExportStartRow: Integer;
    FOldPosition: TBookMark;
    FSelExport: Boolean;
    FExportRow: Integer;
    FOldIsBOF: Boolean;
    FOldAR: Integer;
    FOldTopRow: Integer;
    FOldIsEOF: Boolean;
    FExporting: Boolean;
    FEditText: string;
    FLookupKeys: TStringList;
    FPrevRecNo: integer;
    FRecordChanged: Boolean;
    FOffset: Integer;
    FOldDatasetType: TTMSFNCGridDatabaseAdapterDataSetType;
    FInternalCall: Integer;
    FOldState: TDataSetState;
    FEmptyDataSet: Boolean;
    FShowDefinedFields: Boolean;
    FNewAppendRecord: Boolean;
    FVisibleFieldCount: Integer;
    FPreviousCell: TTMSFNCGridCellRec;
    FDataLink: TTMSFNCGridDatabaseAdapterItemDataLink;
    FColumns: TTMSFNCGridDatabaseAdapterColumns;
    FAutoRemoveColumns: Boolean;
    FAutoCreateColumns: Boolean;
    FOnGetRecordCount: TTMSFNCGridDatabaseAdapterGetRecordCountEvent;
    FDataSetType: TTMSFNCGridDatabaseAdapterDataSetType;
    FDataSetTypeAuto: Boolean;
    FShowPictureFields: Boolean;
    FShowBooleanFields: Boolean;
    FPostMode: TTMSFNCGridDatabaseAdapterPostMode;
    FShowMemoFields: Boolean;
    FOnGetHTMLTemplateData: TTMSFNCGridDatabaseAdapterGetHTMLTemplateDataEvent;
    FOnGetHTMLTemplate: TTMSFNCGridDatabaseAdapterGetHTMLTemplateEvent;
    function GetDataSource: TDataSource;
    procedure SetDataSource(const Value: TDataSource);
    procedure SetColumns(const Value: TTMSFNCGridDatabaseAdapterColumns);
    function GetFieldAtColumn(ACol: Integer): TField;
    function GetFieldIndexAtColumn(ACol: Integer): Integer;
    procedure SetDataSetType(
      const Value: TTMSFNCGridDatabaseAdapterDataSetType);
  protected
    function GetDocURL: string; override;
    procedure ClearOldPosition;
    procedure RegisterRuntimeClasses; override;
    procedure Notification(AComponent: TComponent; AOperation: TOperation); override;
    procedure RecordChanged({%H-}Field: TField);
    procedure UpdateData;
    procedure ActiveChange;
    procedure DataSetChanged;
    procedure UpdateVisibleFields;
    procedure UpdateGrid;
    procedure Scroll(Distance: Integer);
    procedure UpdateRowCount;
    procedure DoSelectTimer(Sender: TObject);
    procedure BitmapChanged(Sender: TObject);
    procedure UpdateBounds; override;
    procedure Initialize; override;
    procedure ScrollGrid(ADelta: Integer; ABeforeDisplayCells: Boolean = True; AScrollOnly: Boolean = False); override;
    procedure SelectCell(ACell: TTMSFNCGridCellRec); override;
    procedure DoAutoRemoveColumns(ARemoveDefined: Boolean); virtual;
    procedure DoGetRecordCount(var ARecordCount: Integer); virtual;
    procedure CellEditGetData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String); override;
    procedure CellEditValidateData(ACol, {%H-}ARow: Integer; {%H-}CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean); override;
    procedure CellEditSetData(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var CellString: String); override;
    procedure CellEditDone(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor); override;
    procedure GetCellReadOnly(ACol, ARow: Integer; var AReadOnly: Boolean); override;
    procedure GetCellData(ACol, ARow: Integer; var ACellData: String); override;
    procedure GetCellProperties(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure GetCellEditorProperties(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor); override;
    procedure GetCellClass(ACol, ARow: Integer; var ACellClassType: TTMSFNCGridCellClass); override;
    procedure GetCellEditorType(ACol, ARow: Integer; var ACellEditorType: TTMSFNCGridEditorType); override;
    procedure CellCheckBoxClick(ACol, ARow: Integer; ACell: TTMSFNCGridCell); override;
    procedure ExportNotification(AState: TTMSFNCGridExportState; ARow: Integer); override;
    procedure LoadLookupList(AField: TField; AList: TStrings);
    procedure CellBeforeEdit(ACol, ARow: Integer); override;
    procedure CellBeforeEditExit(ACol, ARow: Integer; CellEditor: TTMSFNCGridEditor; var AllowExit: Boolean); override;
    procedure CellEditCancel(ACol, ARow: Integer); override;
    function GetVersion: string; override;
    function CanCancelEdit: Boolean; override;
    function AlternateDisplayBuildUp: Boolean; override;
    function CheckDatasetType: Boolean;
    function GetBufferCount: Integer; virtual;
    function GetRecordCount: Integer; virtual;
    function GetRecordNo: Integer; virtual;
    function GetLookupKey(AIndex: Integer): string;
    function HTMLDBReplace(AValue: string; ADataset: TDataSet; ACol, ARow: Integer): string;
    function GetColumnDisplayName(ACol: Integer): String; override;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function CheckDataSet: Boolean;
    property DataLink: TTMSFNCGridDatabaseAdapterItemDataLink read FDataLink;
    property FieldAtColumn[ACol: Integer]: TField read GetFieldAtColumn;
    property FieldIndexAtColumn[ACol: Integer]: Integer read GetFieldIndexAtColumn;
    procedure SetActiveRecord(ARow: Integer);
    procedure AddAllFields;
    procedure RemoveAllFields;
    procedure RemoveAllColumns;
    procedure LoadAllDataAndDisconnect;
    class procedure BlobFieldToPicture(AField: TBlobField; ABitmap: TTMSFNCBitmap);
  published
    property Version: string read GetVersion;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property Columns: TTMSFNCGridDatabaseAdapterColumns read FColumns write SetColumns;
    property AutoCreateColumns: Boolean read FAutoCreateColumns write FAutoCreateColumns default True;
    property AutoRemoveColumns: Boolean read FAutoRemoveColumns write FAutoRemoveColumns default True;
    property OnGetRecordCount: TTMSFNCGridDatabaseAdapterGetRecordCountEvent read FOnGetRecordCount write FOnGetRecordCount;
    property OnGetHTMLTemplateData: TTMSFNCGridDatabaseAdapterGetHTMLTemplateDataEvent read FOnGetHTMLTemplateData write FOnGetHTMLTemplateData;
    property OnGetHTMLTemplate: TTMSFNCGridDatabaseAdapterGetHTMLTemplateEvent read FOnGetHTMLTemplate write FOnGetHTMLTemplate;
    property DataSetType: TTMSFNCGridDatabaseAdapterDataSetType read FDataSetType write SetDataSetType default adsSequenced;
    property DataSetTypeAuto: Boolean read FDataSetTypeAuto write FDataSetTypeAuto default True;
    property ShowBooleanFields: Boolean read FShowBooleanFields write FShowBooleanFields default True;
    property ShowPictureFields: Boolean read FShowPictureFields write FShowPictureFields default True;
    property ShowMemoFields: Boolean read FShowMemoFields write FShowMemoFields default False;
    property PostMode: TTMSFNCGridDatabaseAdapterPostMode read FPostMode write FPostMode default apmRow;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, SysUtils, Math
  {$IFDEF VCLLIB}
  , JPEG
  {$ENDIF}
  ;

{$R TMSFNCGridDatabaseAdapter.res}

type
  TTMSFNCCustomGridOpen = class(TTMSFNCCustomGrid);

procedure TTMSFNCGridDatabaseAdapter.ActiveChange;
var
  I: Integer;
  sl: TStringList;
  cc: Integer;
  f: TField;
  rc: Integer;
begin
  if FInternalCall > 0 then
    Exit;

  Inc(FInternalCall);
  if not Assigned(Grid) and Active and not (csDesigning in ComponentState) then
    raise Exception.Create(sTMSFNCGridDatabaseAdapterNoGridAssigned);

  if Assigned(FDataLink) and Assigned(Grid) then
  begin
    if Assigned(DataLink.DataSet) then
    begin
      if not DataLink.DataSet.Active then
        FShowDefinedFields := False;

      if DataLink.DataSet.Active and Active then
      begin
        FShowDefinedFields := False;

        for i := 0 to Columns.Count - 1 do
        begin
          if Columns[i].FieldName <> '' then
          begin
            FShowDefinedFields := True;
            Break;
          end;
        end;

        if FShowDefinedFields and FAutoRemoveColumns then
        begin
          i := 0;
          sl := TStringList.Create;
          try
            DataSource.DataSet.GetFieldNames(sl);
            while (i <= Columns.Count - 1) do
            begin
              if Columns[i].FieldName <> '' then
              begin
                if (sl.IndexOf(Columns[i].FieldName) < 0) then
                begin
                  Columns[I].FieldName := '';
                  Columns[I].Header := '';
                  Columns.Delete(I);
                  FShowDefinedFields := False;
                  i := -1;
                end;
              end;
              inc(i);
            end;
          finally
            sl.free;
          end;
        end;

        FVisibleFieldCount := 0;
        for i := 0 to DataLink.DataSet.FieldCount - 1 do
        begin
          if DataLink.DataSet.Fields[i].Visible then
            inc(FVisibleFieldCount);
        end;

        if not FShowDefinedFields and FAutoCreateColumns then
        begin
          cc := FVisibleFieldCount - Grid.HiddenColumnCount;
          if cc >= 0 then
          begin
            for I := 0 to cc - 1 do
              Columns.Add;
          end;
        end;

        for i := 0 to Columns.Count - 1 do
        begin
          f := nil;
          if Columns[i].FieldName <> '' then
            f := DataLink.DataSet.FieldByName(Columns[i].FieldName)
          else if not FShowDefinedFields then
            f := FieldAtColumn[i];

          if Assigned(f) then
          begin
            if Columns[i].FieldName = '' then
            begin
              if FAutoCreateColumns then
              begin
                Columns[i].FieldName := f.FieldName;
                Columns[i].AutoCreated := true;
              end;
            end;
          end;
        end;

        CheckDatasetType;
        DataLink.BufferCount := GetBufferCount;
        rc := GetRecordCount;
        Grid.BeginUpdate;
        Grid.ColumnCount := Columns.Count + Grid.FixedColumns + Grid.FixedRightColumns;
        Grid.RowCount := rc + Grid.FixedRows;
        Grid.FocusedCell := MakeCell(0, DataLink.ActiveRecord + Grid.FixedRows);
        FPreviousCell := Grid.FocusedCell;
        FOffset := 0;
        Grid.EndUpdate;
      end
      else
      begin
        Grid.BeginUpdate;
        Grid.Clear;
        Grid.RowCount := Grid.FixedRows;
        DoAutoRemoveColumns(False);
        Grid.EndUpdate;
      end;
    end
    else
    begin
      Grid.BeginUpdate;
      DoAutoRemoveColumns(True);
      Grid.EndUpdate;
    end;
  end;

  Dec(FInternalCall);
end;

procedure TTMSFNCGridDatabaseAdapter.AddAllFields;
var
  sl: TStringList;
  i, j: Integer;
begin
  if not Assigned(DataSource) then
  begin
    TTMSFNCUtils.Message(sTMSFNCGridDatabaseAdapterCannotAddFieldsDataSource);
    Exit;
  end;

  if not Assigned(DataSource.DataSet) then
  begin
    TTMSFNCUtils.Message(sTMSFNCGridDatabaseAdapterCannotAddFieldsDataSet);
    Exit;
  end;

  sl := TStringList.Create;
  try
    DataSource.DataSet.GetFieldNames(sl);
    j := 0;

    if (sl.Count > 0) and (sl.Count + j < Columns.Count) then
    begin
      while (sl.Count + j < Columns.Count) and (Columns.Count > j) do
      begin
        Columns.Delete(Columns.Count - 1);
      end;
    end;

    for i := 1 to sl.Count do
    begin
      if Columns.Count > i - 1 + j then
      begin
        Columns[i - 1 + j].FieldName := sl[i - 1];
        Columns[i - 1 + j].Header := sl[i - 1];
      end
      else
      begin
        with Columns.Add do
        begin
          FieldName := sl[i - 1];
          Header := sl[i - 1];
        end;
      end;
    end;
  finally
    sl.Free;
  end;
end;

function TTMSFNCGridDatabaseAdapter.AlternateDisplayBuildUp: Boolean;
begin
//  {$IFDEF LCLLIB}
//  Result := True
//  {$ENDIF}
//  {$IFNDEF LCLLIB}
  Result := False;
//  {$ENDIF}
end;

procedure TTMSFNCGridDatabaseAdapter.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGridDatabaseAdapter then
  begin
    FColumns.Assign((Source as TTMSFNCGridDatabaseAdapter).Columns);
    FAutoRemoveColumns := (Source as TTMSFNCGridDatabaseAdapter).AutoRemoveColumns;
    FAutoCreateColumns := (Source as TTMSFNCGridDatabaseAdapter).AutoCreateColumns;
    FDataSetType := (Source as TTMSFNCGridDatabaseAdapter).DataSetType;
    FDataSetTypeAuto := (Source as TTMSFNCGridDatabaseAdapter).DataSetTypeAuto;
    FShowBooleanFields := (Source as TTMSFNCGridDatabaseAdapter).ShowBooleanFields;
    FShowPictureFields := (Source as TTMSFNCGridDatabaseAdapter).ShowPictureFields;
    FShowMemoFields := (Source as TTMSFNCGridDatabaseAdapter).ShowMemoFields;
    FPostMode := (Source as TTMSFNCGridDatabaseAdapter).PostMode;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.BitmapChanged(Sender: TObject);
begin
  Grid.Invalidate;
end;

class procedure TTMSFNCGridDatabaseAdapter.BlobFieldToPicture(AField: TBlobField; ABitmap: TTMSFNCBitmap);
{$IFDEF FMXLIB}
type
  TGraphicHeader = record
    Count: Word;                { Fixed at 1 }
    HType: Word;                { Fixed at $0100 }
    Size: Integer              { Size not including header }
  end;
{$ENDIF}
var
  ms: TMemoryStream;
{$IFDEF FMXLIB}
  Size: Longint;
  Header: TBytes;
  GraphicHeader: TGraphicHeader;
{$ENDIF}
{$IFDEF CMNLIB}
  gcc: TGraphicClass;
  p: TGraphic;
  sig: word;
  i: Integer;
  bmp: TBitmap;
  pic: TPicture;
  oletype: Integer;
  oleoffset: Integer;
  b: Byte;
  ljpg: TJPEGImage;
{$ENDIF}
begin
  {$IFDEF CMNLIB}
  ms := TMemoryStream.Create;
  try
    AField.SaveToStream(ms);
    ms.Position := 0;
    oletype := -1;
    oleoffset := 0;
    if ms.Size > 2 then
    begin
      ms.Position := 0;
      sig := 0;
      ms.Read(sig, 2);
      case sig of
        $1C15: // OLE storage
        begin
          i := 0;
          b := 0;
          while (i < 512) do
          begin
            ms.Read(b, 1);
            inc(i);
            if (b = $FF) then
            begin
              ms.Read(b, 1);
              inc(i);
              if b = $D8 then
              begin
                oletype := 1;
                oleoffset := i;
                break;
              end;
            end;
            if (b = $47) then
            begin
              ms.Read(b, 1);
              inc(i);
              if b = $49 then
              begin
                oletype := 2;
                oleoffset := i;
                break;
              end;
            end;
            if (b = ord('B')) then
            begin
              ms.Read(b, 1);
              inc(i);
              if (b = ord('M')) then
              begin
                oletype := 0;
                oleoffset := i;
                Break;
              end;
            end;
          end;
          ms.Seek(oleoffset, 0);
          case oletype of
            0:
            begin
              bmp := TBitmap.Create;
              bmp.LoadFromStream(ms);
              ABitmap.Assign(bmp);
              bmp.Free;
            end;
            1:
            begin
              LJPg := TJPEGImage.Create;
              try
                ljpg.LoadFromStream(ms);
                pic := TPicture.Create;
                pic.Assign(ljpg);
                ABitmap.Assign(pic);
                pic.Free;
              finally
                FreeAndNil(ljpg);
              end;
            end;
            2:
            begin
              ms := TMemoryStream.Create;
              ms.CopyFrom(ms, ms.Size - ms.Position);
              ABitmap.Graphic.LoadFromStream(ms);
              ms.Free;
            end;
          end;
        end;
        else
        begin
          ms.Position := 0;
          if TTMSFNCUtils.FindGraphicClass(ms.Memory^, ms.Size, gcc) then
          begin
            p := gcc.Create;
            try
              ms.Position := 0;
              p.LoadFromStream(ms);
              ABitmap.Assign(p)
            finally
              p.Free;
            end;
          end;
        end;
      end;
    end;
  finally
    ms.Free;
  end;
  {$ENDIF}
  {$IFDEF FMXLIB}
  ms := TMemoryStream.Create;
  try
    AField.SaveToStream(ms);
    ms.Position := 0;
    Size := ms.Size;
    if Size >= SizeOf(TGraphicHeader) then
    begin
      SetLength(Header, SizeOf(TGraphicHeader));
      ms.Read(Header, 0, Length(Header));
      System.Move(Header[0], GraphicHeader, SizeOf(TGraphicHeader));
      if (GraphicHeader.Count <> 1) or (GraphicHeader.HType <> $0100) or
        (GraphicHeader.Size <> Size - SizeOf(GraphicHeader)) then
        ms.Position := 0;
    end;

    ABitmap.LoadFromStream(ms);
  finally
    ms.Free;
  end;
  {$ENDIF}
end;

procedure TTMSFNCGridDatabaseAdapter.UpdateBounds;
begin
  inherited;
  if CheckDataSet then
  begin
    DataLink.BufferCount := GetBufferCount;
    UpdateGrid;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.UpdateGrid;
begin
  if Assigned(Grid) then
  begin
    Grid.BeginUpdate;
    Grid.EndUpdate;
  end;
end;

function TTMSFNCGridDatabaseAdapter.CanCancelEdit: Boolean;
begin
  Result := False;
end;

procedure TTMSFNCGridDatabaseAdapter.CellBeforeEdit(ACol, ARow: Integer);
begin
  inherited;
  if not CheckDataSet then
    Exit;

  DataLink.DataSet.Edit;
end;

procedure TTMSFNCGridDatabaseAdapter.CellBeforeEditExit(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var AllowExit: Boolean);
begin
  inherited;
  AllowExit := False;
end;

procedure TTMSFNCGridDatabaseAdapter.CellCheckBoxClick(ACol, ARow: Integer;
  ACell: TTMSFNCGridCell);
var
  c: Integer;
  f: TField;
  ae: Boolean;
begin
  inherited;
  if not CheckDataSet then
    Exit;

  c := ACol - Grid.FixedColumns;

  if Grid.FocusedCell.Row <> ARow then
    Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, ARow));

  if (c >= Columns.Count) then
    Exit;

  if CheckDataSet then
  begin
    ae := DataSource.AutoEdit or (dsEdit = FDataLink.DataSet.State);
    if FDataLink.DataSet.CanModify and ae then
    begin
      FDataLink.DataSet.Edit;
      f := FieldAtColumn[c];
      if Assigned(f) then
      begin
        if f.DataType = ftBoolean then
          f.AsBoolean := not f.AsBoolean
        else if Columns[c].CheckBoxField then
        begin
          if UpperCase(Columns[c].CheckTrue) = UpperCase(f.AsString) then
            f.AsString := Columns[c].CheckFalse
          else
            f.AsString := Columns[c].CheckTrue;
        end;

        if PostMode = apmCell then
          FDataLink.DataSet.Post;
      end;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.CellEditCancel(ACol, ARow: Integer);
begin
  inherited;
  if not CheckDataSet then
    Exit;

  DataLink.DataSet.Cancel;
end;

procedure TTMSFNCGridDatabaseAdapter.CellEditDone(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor);
begin
  inherited;
  if not CheckDataSet then
    Exit;
end;

procedure TTMSFNCGridDatabaseAdapter.CellEditGetData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String);
var
  j: integer;
  aField: TField;
  c: Integer;
  r, o: Integer;
begin
  inherited;

  if not CheckDataset then
  	Exit;

  c := ACol - Grid.FixedColumns;
  r := ARow;

  if (r <= Grid.FixedRows - 1) then
    Exit;

  if (Columns.Count > c) then
  begin
    aField := FieldAtColumn[c];
    if (ShowBooleanFields and (aField <> nil) and (aField.DataType = ftBoolean))
      {$IFNDEF WEBLIB}or (ShowPictureFields and (aField <> nil) and (aField.DataType = ftGraphic)){$ENDIF}
      or (Columns[c].PictureField) or Columns[c].CheckBoxField then
        Exit;

    o := DataLink.ActiveRecord;

    SetActiveRecord(r);

    if (DataLink.ActiveRecord < 0) or ((DataLink.ActiveRecord >= DataLink.BufferCount) and not ((DataLink.ActiveRecord = DataLink.BufferCount) and (DataSetType = adsNonSequenced))) then
    begin
      DataLink.ActiveRecord := o;
      Exit;
    end;

    try
      if Columns[c].FieldName <> '' then
      begin
        aField := FDataLink.DataSet.fieldbyname(Columns[c].FieldName);

        if (aField.IsBlob) then
          CellString := AField.AsString
        else
          CellString := AField.Text;
      end
      else if not FShowDefinedFields then
      begin
        j := FieldIndexAtColumn[c];
        if (j <> -1) then
          aField := FDataLink.DataSet.Fields[j]
        else
          aField := nil;

        if Assigned(aField) then
        begin
          if (aField.IsBlob) then
            CellString := aField.AsString
          else
            CellString := aField.Text;
        end;
      end;

    finally
      DataLink.ActiveRecord := o;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.CellEditSetData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String);
begin
  inherited;

end;

procedure TTMSFNCGridDatabaseAdapter.CellEditValidateData(ACol, ARow: Integer;
  CellEditor: TTMSFNCGridEditor; var CellString: String; var Allow: Boolean);
var
  v, i, c: integer;
  f: TField;
  r, o: Integer;
begin
  if not CheckDataset then
  	Exit;

  c := ACol - Grid.FixedColumns;
  r := ARow;

  if (r <= Grid.FixedRows - 1) then
    Exit;

  if Assigned(Columns) and (Columns.Count > c) then
  begin
    if not (DataSource.DataSet.State in [dsInsert, dsEdit]) and (PostMode = apmCell) then
      Exit;

    f := FieldAtColumn[c];
    if (ShowBooleanFields and (f <> nil) and (f.DataType = ftBoolean))
      {$IFNDEF WEBLIB}or (ShowPictureFields and (f <> nil) and (f.DataType = ftGraphic)){$ENDIF}
      or (Columns[c].PictureField) or Columns[c].CheckBoxField then
      Exit;

    o := DataLink.ActiveRecord;

    SetActiveRecord(r);

    if (DataLink.ActiveRecord < 0) or ((DataLink.ActiveRecord >= DataLink.BufferCount) and not ((DataLink.ActiveRecord = DataLink.BufferCount) and (DataSetType = adsNonSequenced))) then
    begin
      DataLink.ActiveRecord := o;
      Exit;
    end;

    try

      if Allow then
      begin
        if (PostMode = apmCell) then
        begin
          try
            if Columns[c].FieldName <> '' then
            begin
              f := FDataLink.DataSet.FieldByName(Columns[c].FieldName);

              if f.FieldKind = fkLookup then
              begin
                if (Grid.CellComboBox.ItemIndex >= 0) then
                begin
                  v := TTMSFNCGridDatabaseAdapterLookupItem(Grid.CellComboBox.Items.Objects[Grid.CellComboBox.ItemIndex]).Index;
                  FDataLink.DataSet.FieldByName(f.KeyFields).AsString := GetLookupKey(v);
                end;
              end
              else
              begin
                if (f.IsBlob) and not ShowMemoFields then
                  f.AsString := '(MEMO)'
                else
                begin
                  if Assigned(f.OnSetText) then
                    f.OnSetText(f, CellString)
                  else
                    f.AsString := CellString;
                end;
              end;
            end
            else
            begin
              if not FShowDefinedFields then
              begin
                i := FieldIndexAtColumn[c];

                if i <> -1 then
                  f := FDataLink.DataSet.Fields[i]
                else
                  f := nil;

                if Assigned(f) then
                begin
                  if f.FieldKind = fkLookup then
                  begin
                    v := TTMSFNCGridDatabaseAdapterLookupItem(Grid.CellComboBox.Items.Objects[Grid.CellComboBox.ItemIndex]).Index;
                    FDataLink.DataSet.FieldByName(f.KeyFields).AsString := GetLookupKey(v);
                  end
                  else
                  begin
                    if (f.IsBlob) and not ShowMemoFields then
                      f.AsString := '(MEMO)'
                    else
                      f.AsString := CellString;
                  end;
                end;
              end;
              if FShowDefinedFields and (Columns[c].FieldName = '') then
                CellString := '';
            end;
          except
            Allow := False;
          end;

          try
            FDataLink.DataSet.Post;
          except
            on E: Exception do
            begin
              FRecordChanged := False;
              FDataLink.DataSet.Cancel;
            end;
          end;
        end
        else
        begin
          if CheckDataSet then
          begin
            if (FEditText <> CellString) and (f.FieldKind <> fkLookup) then
              FEditText := CellString;

            if (f.Text <> CellString) and not FDatalink.FModified and (FDataLink.DataSet.State in [dsEdit, dsInsert]) then
              FDatalink.Modified;

            FDataLink.UpdateData;
          end;
        end;
      end;
    finally
      DataLink.ActiveRecord := o;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.CheckDataSet: Boolean;
begin
  Result := Active and Assigned(FDataLink) and Assigned(DataLink.DataSet) and DataLink.DataSet.Active;
end;

function TTMSFNCGridDatabaseAdapter.CheckDatasetType: Boolean;
begin
  if DataSetTypeAuto then
  begin
    if not FDataLink.DataSet.IsSequenced then
      FDataSetType := adsNonSequenced
    else
      FDataSetType := adsSequenced;
  end;

  Result := (FOldDatasetType <> FDataSetType) and (DataLink.DataSet.State = dsBrowse);
  FOldDatasetType := FDataSetType;

  {$IFDEF TMSDEBUG}
  case DataSetType of
    adsSequenced: TMSLog('Sequenced');
    adsNonSequenced: TMSLog('Non-Sequenced');
  end;
  {$ENDIF}
end;

procedure TTMSFNCGridDatabaseAdapter.ClearOldPosition;
begin
  {$IFDEF WEBLIB}
  FOldPosition.Data := nil;
  FOldPosition.Flag := bfCurrent;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  FOldPosition := nil;
  {$ENDIF}
end;

constructor TTMSFNCGridDatabaseAdapter.Create(AOwner: TComponent);
begin
  inherited;
  FPrevRecNo := 1;
  FSelectTimer := TTimer.Create(Self);
  FSelectTimer.Enabled := False;
  FSelectTimer.OnTimer := DoSelectTimer;
  FSelectTimer.Interval := 1;
  FEditText := '';
  FOldAR := -1;
  FExportRow := -1;
  FExportStartRow := -1;
  ClearOldPosition;
  FOldTopRow := -1;
  FLookupKeys := TStringList.Create;
  FDataSetTypeAuto := True;
  FEmptyDataSet := False;
  FDataSetType := adsSequenced;
  FDataLink := TTMSFNCGridDatabaseAdapterItemDataLink.Create(Self);
  FColumns := TTMSFNCGridDatabaseAdapterColumns.Create(Self);
  FAutoRemoveColumns := True;
  FAutoCreateColumns := True;
  FShowPictureFields := True;
  FShowBooleanFields := True;
  FShowDefinedFields := False;
  FPostMode := apmRow;
end;

procedure TTMSFNCGridDatabaseAdapter.DataSetChanged;
var
  ro, nr, ar: Integer;
begin
  if not CheckDataSet then
    Exit;

  if FInternalCall > 0 then
    Exit;

  if CheckDatasetType then
    Initialize
  else
  begin
    Inc(FInternalCall);
    if Assigned(Grid) then
    begin
      Grid.BeginUpdate;
      UpdateVisibleFields;
      UpdateRowCount;
      FOldState := DataLink.DataSet.State;
      Grid.EndUpdate;

      if FOldState <> dsInsert then
      begin
        case DataSetType of
          adsSequenced:
          begin
            ro := GetRecordNo;
            nr := ro - 1 + Grid.FixedRows;
            ar := nr - DataLink.ActiveRecord;
            TTMSFNCCustomGridOpen(Grid).TopRow := ar;
            Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, nr));
            FPrevRecNo := DataLink.DataSet.RecNo;
          end;
          adsNonSequenced:
          begin
            ro := DataLink.ActiveRecord;
            Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, ro + TTMSFNCCustomGridOpen(Grid).TopRow));
            FPreviousCell.Row := ro + TTMSFNCCustomGridOpen(Grid).TopRow;

            if DataLink.ActiveRecord > TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow then
              FOffset := DataLink.ActiveRecord - (TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow)
            else
              FOffset := 0;
          end;
        end;
      end;
    end;
    Dec(FInternalCall);
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.UpdateData;
var
  f: TField;
  v: integer;
  rci: integer;
  et: TTMSFNCGridEditorType;
  idx: Integer;
  function RemoveThousandSeparator(Value: string):string;
  var
    i: Integer;
  begin
    Result := '';
    {$IFDEF ZEROSTRINGINDEX}
    for i := 0 to Length(Value) - 1 do
    {$ELSE}
    for i := 1 to Length(Value) do
    {$ENDIF}
    begin
      if Value[i] = FormatSettings.DecimalSeparator then
        Result := Result + FormatSettings.DecimalSeparator
        else
          if Value[i] <> FormatSettings.ThousandSeparator then
            Result := Result + Value[i];
    end;
  end;
begin
  rci := Grid.FocusedCell.Col - Grid.FixedColumns;

  f := FieldAtColumn[rci];

  {$IFDEF CMNLIB}
  idx := -1;
  if Grid.CellComboBox.HandleAllocated then
  {$ENDIF}
    idx := Grid.CellComboBox.ItemIndex;

  if Assigned(f) and (FDataLink.DataSet.State in [dsEdit, dsInsert]) then
  begin
    if (f.IsBlob) then
    begin
      {$IFNDEF WEBLIB}
      if (f.DataType <> ftGraphic) then
      {$ENDIF}
        f.AsString := FEditText;
    end
    else if f.FieldKind = fkLookup then
    begin
      if idx >= 0 then
      begin
        v := TTMSFNCGridDatabaseAdapterLookupItem(Grid.CellComboBox.Items.Objects[idx]).Index;
        FDataLink.DataSet.FieldByName(f.KeyFields).AsString := GetLookupKey(v);
      end;
    end
    else
    begin
      et := etEdit;
      GetCellEditorType(Grid.FocusedCell.Col, Grid.FocusedCell.Row, et);

      if (et = etComboBox) and (idx >= 0) and (f.DataType in [ftInteger, ftLargeInt{$IFNDEF WEBLIB}, ftSmallint, ftWord{$ENDIF}]) then
      begin
//        if Columns[rci].UseComboObjectValue then
//        begin
//          v := TTMSFNCGridDatabaseAdapterLookupItem(Grid.CellComboBox.Items.Objects[idx]);
//          f.AsInteger := v;
//        end
//        else
          f.Text := Grid.CellComboBox.Items[idx];
      end
      else
      begin
        if (f.DataType in [ftInteger, ftFloat, ftLargeint{$IFNDEF WEBLIB}, ftCurrency, ftSmallint, ftWord{$ENDIF}]) then
          f.Text := RemoveThousandSeparator(FEditText)
        else
          f.Text := FEditText;
      end;
    end;
  end;
end;

destructor TTMSFNCGridDatabaseAdapter.Destroy;
var
  I: Integer;
  o: TObject;
begin
  FreeAndNil(FSelectTimer);
  for I := 0 to FLookupKeys.Count - 1 do
  begin
    o := FLookupKeys.Objects[I];
    if Assigned(o) then
      o.Free;
  end;
  FreeAndNil(FLookupKeys);
  FreeAndNil(FColumns);
  FreeAndNil(FDataLink);
  inherited;
end;

procedure TTMSFNCGridDatabaseAdapter.DoAutoRemoveColumns(
  ARemoveDefined: Boolean);
var
  DoUpdate,CanRemove: Boolean;
  i: integer;
begin
  if FAutoRemoveColumns then
  begin
    DoUpdate := true;

    CanRemove := (not Columns.HasFieldsDefined or ARemoveDefined);

    if (csDesigning in ComponentState) and not (csLoading in ComponentState) and CanRemove then
    begin
//      DoUpdate := MessageDlg('Automatically remove columns ?',mtConfirmation, [mbYes, mbNo],0) = mrYes;
    end;

    if (csLoading in ComponentState) then
      DoUpdate := false;

    for i := 0 to Columns.Count - 1 do
      Columns[i].FField := nil;

    if CanRemove and DoUpdate then
    begin
      Columns.Clear;
      Grid.ColumnCount := 0;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.DoGetRecordCount(
  var ARecordCount: Integer);
begin
  if Assigned(OnGetRecordCount) then
    OnGetRecordCount(Self, ARecordCount);
end;

procedure TTMSFNCGridDatabaseAdapter.DoSelectTimer(Sender: TObject);
begin
  if Assigned(Grid) and (FCellToSelect.Row <> -1) and (FCellToSelect.Col <> -1) then
  begin
    Grid.SelectCell(FCellToSelect);
    FSelectTimer.Enabled := False;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.ExportNotification(
  AState: TTMSFNCGridExportState; ARow: Integer);
var
  df: integer;
  OldV: Boolean;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  case AState of
    esExportStart:
      begin
        FExporting := True;
        FSelExport := false;
        FOldIsEOF := FDataLink.DataSet.Eof;
        FOldIsBOF := FDataLink.DataSet.Bof;

        FDataLink.DataSet.DisableControls;
        inc(FMustEnableControls);
        //Grid.BeginUpdate;
        FOldAR := FDataLink.ActiveRecord;
        FOldPosition := FDataLink.DataSet.GetBookMark;
        FOldTopRow := TTMSFNCCustomGridOpen(Grid).TopRow;

        if (ARow >= 0) and (DataSetType = adsNonSequenced) then
        begin
          FExportStartRow := ARow;
          FDataLink.DataSet.First;
          if (ARow - Grid.FixedRows) > 0 then
          begin
            FDataLink.DataSet.MoveBy(ARow - Grid.FixedRows);
          end;
        end;
      end;
    esExportNewRow, esExportSelRow, esExportFindRow:
      begin
        if (DataSetType = adsSequenced) or (AState = esExportFindRow) then
        begin
          TTMSFNCCustomGridOpen(Grid).TopRow := ARow;
        end
        else
        begin
          if AState = esExportSelRow then
            FSelExport := true;

          FExportRow := ARow;

          if FSelExport then
          begin
            if FDataLink.DataSet.BookmarkValid(FOldPosition) then
              FDataLink.DataSet.GotoBookMark(FOldPosition);
            if (ARow - TTMSFNCCustomGridOpen(Grid).TopRow) > 0 then
              FDataLink.DataSet.MoveBy(ARow - TTMSFNCCustomGridOpen(Grid).TopRow);
          end
          else
          begin
            FDataLink.DataSet.First;
            if (ARow - Grid.FixedRows) > 0 then
            begin
              FDataLink.DataSet.MoveBy(ARow - Grid.FixedRows);
            end;
          end;
        end;
      end;
    esExportNextRow:
    begin
      if DataSetType = adsSequenced then
      begin
        TTMSFNCCustomGridOpen(Grid).TopRow := ARow;
      end
      else
      begin
        FExportRow := ARow;
        if (ARow <> FExportStartRow) and ((ARow - Grid.FixedRows) > 0) then
        begin
          FDataLink.DataSet.MoveBy(1);
        end;
      end;
    end;
    esExportDone:
      begin
        FExporting := False;
        FExportRow := -1;
        FSelExport := false;
        FExportStartRow := -1;

        if Assigned(FOldPosition) then
        begin
          if (FOldTopRow > -1) then
            TTMSFNCCustomGridOpen(Grid).TopRow := FOldTopRow;

          if FDataLink.DataSet.BookmarkValid(FOldPosition) then
          begin
            FDataLink.DataSet.GotoBookMark(FOldPosition);
            FDataLink.DataSet.FreeBookMark(FOldPosition);
          end;

          ClearOldPosition;
          if (FOldAR <> FDataLink.ActiveRecord) then
          begin
            if (FOldAR < FDataLink.ActiveRecord) then
            begin
              df := (FDataLink.BufferCount - FDataLink.ActiveRecord) + FDataLink.ActiveRecord - FOldAR;
              df := FDataLink.DataSet.MoveBy(df);
              FDataLink.DataSet.MoveBy(-df);
            end
            else
            begin
              df := FDataLink.ActiveRecord + (FOldAR - FDataLink.ActiveRecord);
              df := FDataLink.DataSet.MoveBy(-df);
              FDataLink.DataSet.MoveBy(abs(df));
            end;
          end;

          if FOldIsEOF then
            FDataLink.DataSet.Next;

          if FOldIsBOF then
            FDataLink.DataSet.Prior;

          OldV := FDoNotCountRow;
          FDoNotCountRow := True;
          Dec(FMustEnableControls);
          FDataLink.DataSet.EnableControls;
          FDoNotCountRow := OldV;
        end;

        if (FMustEnableControls > 0) and (FDataLink.DataSet.ControlsDisabled) then
          FDataLink.DataSet.EnableControls;

        //Grid.EndUpdate;
      end;
    esExportFail:
      begin
        FExporting := False;
        FExportRow := -1;
        FSelExport := false;
        FExportStartRow := -1;
        if Assigned(FOldPosition) then
        begin
          FDataLink.DataSet.FreeBookMark(FOldPosition);
          ClearOldPosition;
          FDataLink.DataSet.EnableControls;
          //Grid.EndUpdate;
        end;
      end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetBufferCount: Integer;
begin
  Result := 0;
  if Assigned(Grid) then
  begin
    Result := Grid.VisibleRowCount + 1;
    if Result < ((Round(Grid.Height) div Round(Grid.DefaultRowHeight)) - Grid.FixedRows + 1) then
      Result := ((Round(Grid.Height) div Round(Grid.DefaultRowHeight)) - Grid.FixedRows + 1)
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.GetCellClass(ACol, ARow: Integer;
  var ACellClassType: TTMSFNCGridCellClass);
var
  f: TField;
  c: Integer;
begin
  inherited;

  if not Assigned(Grid) or not CheckDataSet then
    Exit;

  c := ACol - Grid.FixedColumns;

  if (Columns.Count > c) then
  begin
    if ShowBooleanFields and (ACol >= Grid.FixedColumns) and (ARow >= Grid.FixedRows) then
    begin
      f := FieldAtColumn[c];
      if Assigned(f) then
        if f.DataType = ftBoolean then
          ACellClassType := TTMSFNCCheckGridCell;
    end;

    if (ACol >= Grid.FixedColumns) and (ARow >= Grid.FixedRows) and (Columns[c].CheckBoxField) then
      ACellClassType := TTMSFNCCheckGridCell;

    {$IFNDEF WEBLIB}
    if ShowPictureFields and (ACol >= Grid.FixedColumns) and (ARow >= Grid.FixedRows) then
    begin
      f := FieldAtColumn[c];
      if Assigned(f) then
        if f.DataType = ftGraphic then
          ACellClassType := TTMSFNCBitmapGridCell;
    end;
    {$ENDIF}

    if (ACol >= Grid.FixedColumns) and (ARow >= Grid.FixedRows) and (Columns[c].PictureField) then
      ACellClassType := TTMSFNCBitmapGridCell;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.GetCellData(ACol, ARow: Integer;
  var ACellData: String);
var
  o, c, r, i: Integer;
  AField: TField;
  ht: String;
begin
  inherited;

  if not Assigned(Grid) or not CheckDataSet then
    Exit;

  c := ACol - Grid.FixedColumns;
  r := ARow;

  if (c >= 0) and (c <= Columns.Count - 1) then
  begin
    if DataLink.DataSet.Active then
    begin
      if (r = Grid.FixedRows - 1)  then
      begin
        if (Columns[c].Header <> '') then
          ACellData := Columns[c].Header
        else
        begin
          if (Grid.Cells[c,r] <> '') and not FSkipRetrieveData then
            ACellData := Grid.Cells[c,r]
          else
          begin
            if (Columns[c].FieldName <> '') then
            begin
              aField := DataLink.DataSet.FieldByName(Columns[c].FieldName);
              if Assigned(aField) and (aField.DisplayLabel <> '') then
                ACellData := aField.DisplayLabel
              else
                ACellData := Columns[c].FieldName;
            end
            else
            begin
              if AutoCreateColumns then
              begin
                i := FieldIndexAtColumn[c];
                if (i <> -1) then
                begin
                  if (DataLink.DataSet.Fields[i].DisplayLabel <> '') then
                    ACellData := DataLink.DataSet.Fields[i].DisplayLabel
                  else
                    ACellData := DataLink.DataSet.Fields[i].DisplayName;
                end;
              end;
            end;
          end;
        end;
      end
      else
      begin
        if (r <= Grid.FixedRows - 1) then
          Exit;

        AField := FieldAtColumn[c];

        if (ShowBooleanFields and (aField <> nil) and (aField.DataType = ftBoolean))
          {$IFNDEF WEBLIB}or (ShowPictureFields and (aField <> nil) and (aField.DataType = ftGraphic)){$ENDIF}
          or (Columns[c].PictureField) or Columns[c].CheckBoxField then
          Exit;

        o := DataLink.ActiveRecord;

        SetActiveRecord(r);

        if (DataLink.ActiveRecord < 0) or ((DataLink.ActiveRecord >= DataLink.BufferCount) and not ((DataLink.ActiveRecord = DataLink.BufferCount) and (DataSetType = adsNonSequenced))) then
        begin
          if DataSetType = adsNonSequenced then
            ACellData := '';
          DataLink.ActiveRecord := o;
          Exit;
        end;

        try
          if (Columns[c].HTMLTemplate <> '') and (ARow >= Grid.FixedRows) and (ACol >= Grid.FixedColumns) then
          begin
            ht := Columns[c].HTMLTemplate;

            if Assigned(OnGetHTMLTemplate) then
              OnGetHTMLTemplate(Self, c, r, ht, FDataLink.DataSet.Fields);

            ACellData := HTMLDBReplace(ht, FDataLink.DataSet, c, r);
          end
          else
          begin
            AField := FieldAtColumn[c];
            if Assigned(AField) then
            begin
              if AField.IsNull then
                ACellData := ''
              else
              begin
                if AField.IsBlob then
                begin
                  {$IFNDEF WEBLIB}
                  case AField.DataType of
                    ftGraphic:
                    begin
                      if Columns[c].PictureField then
                        ACellData := '(GRAPHIC)';
                    end;
                    else
                  {$ENDIF}
                  {$IFDEF WEBLIB}
                  begin
                  {$ENDIF}
                    begin
                      if Assigned(AField.OnGetText) then
                        ACellData := AField.DisplayText
                      else
                        ACellData := AField.AsString;
                    end;
                  end;
                end
                else
                  ACellData := aField.DisplayText;
              end;
            end
          end;
        finally
          DataLink.ActiveRecord := o;
        end;
      end;
    end
    else
    begin
      if (r = Grid.FixedRows - 1) then
      begin
        if (Columns[c].Header <> '') then
          ACellData := Columns[c].Header
        else
        begin
          if (Columns[c].FieldName <> '') then
            ACellData := Columns[c].FieldName;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.GetCellEditorProperties(ACol,
  ARow: Integer; CellEditor: TTMSFNCGridEditor);
var
  i, c: Integer;
  lookupCell: Boolean;
  fld: TField;
  sl: TStringList;
begin
  inherited;
  if not CheckDataset then
  	Exit;

  lookupCell := false;
  c := ACol - Grid.FixedColumns;

  if (Columns.Count > c) then
  begin
    if (ACol >= Grid.FixedColumns) and (Arow >= Grid.FixedRows) then
    begin
      if FShowDefinedFields and (Columns[c].FieldName = '') then
      begin
      end
      else
      begin
        fld := nil;
        sl := TStringList.Create;
        try
          if Columns[c].FieldName <> '' then
          begin
            fld := FDataLink.DataSet.Fieldbyname(Columns[c].FieldName);
            lookupCell := (fld.FieldKind = fkLookup) and (Columns[c].UseLookupEditor);
            if lookupCell then
              LoadLookupList(fld, sl);
          end
          else if not FShowDefinedFields then
          begin
            i := FieldIndexAtColumn[c];
            if (i <> -1) then
              fld := FDataLink.DataSet.Fields[i]
            else
              fld := nil;

            if Assigned(fld) then
            begin
              lookupCell := (fld.FieldKind = fkLookup) and (Columns[c].UseLookupEditor);
              if lookupCell then
                LoadLookupList(fld, sl);
            end;
          end;

          if Assigned(fld) then
          begin
            if fld.DataType in [ftString{$IFNDEF WEBLIB}, ftWideString, ftWideMemo{$ENDIF}] then
              Grid.CellEdit.MaxLength := fld.Size
            else
              Grid.CellEdit.MaxLength := 0;
          end;

          if lookupCell then
          begin
            Grid.CellComboBox.Parent := Grid;
            Grid.CellComboBox.Items.Assign(sl);
            Grid.CellComboBox.Parent := nil;
          end;
        finally
          sl.Free;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.GetCellEditorType(ACol, ARow: Integer;
  var ACellEditorType: TTMSFNCGridEditorType);
var
  i: integer;
  lookupCell: Boolean;
  fld: TField;
  c: Integer;
begin
  inherited;
  lookupCell := false;

  if not CheckDataSet then
  	Exit;

  c := ACol - Grid.FixedColumns;

  if (c >= 0) and (c <= Columns.Count - 1) then
  begin
    if Columns[C].UseColumnEditor then
      Exit;

    if FShowDefinedFields and (Columns[c].FieldName = '') then
    begin
      ACellEditorType := Grid.Columns[ACol].Editor;
    end
    else
    begin
      fld := nil;

      if Columns[c].FieldName <> '' then
      begin
        fld := FDataLink.DataSet.FieldByName(Columns[c].FieldName);
        lookupCell := (fld.FieldKind = fkLookup) and (Columns[c].UseLookupEditor);
      end
      else if not FShowDefinedFields then
      begin
        i := FieldIndexAtColumn[c];
        if (i <> -1) then
          fld := FDataLink.DataSet.Fields[i]
        else
          fld := nil;

        if Assigned(fld) then
          lookupCell := (fld.FieldKind = fkLookup) and (Columns[c].UseLookupEditor);
      end;

      if Assigned(fld) then
      begin
        case fld.DataType of
          ftInteger, ftLargeint{$IFNDEF WEBLIB}, ftSmallInt, ftWord{$ENDIF}: ACellEditorType := etNumericEdit;
          ftFloat{$IFNDEF WEBLIB}, ftCurrency{$ENDIF}: ACellEditorType := etFloatEdit;
          ftDate, ftTime, ftDateTime: ACellEditorType := etDatePicker;
          else
            ACellEditorType := etEdit;
        end;
      end;

      if lookupCell then
        ACellEditorType := etComboBox;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.GetCellProperties(ACol, ARow: Integer;
  ACell: TTMSFNCGridCell);
var
  f: TField;
  o: Integer;
  c: Integer;
  evnt: TNotifyEvent;
begin
  inherited;

  if not Assigned(Grid) or not CheckDataSet then
  begin
    if Assigned(Grid) then
    begin
      if (ACell is TTMSFNCCheckGridCell) or (ACell is TTMSFNCBitmapGridCell) then
        ACell.Text := '';

      ACell.ControlStretched := False;
      ACell.ControlPosition := gcpCenter;
    end;
    Exit;
  end;

  if ACell is TTMSFNCCheckGridCell then
  begin
    o := DataLink.ActiveRecord;

    SetActiveRecord(ARow);

    if (DataLink.ActiveRecord < 0) or ((DataLink.ActiveRecord >= DataLink.BufferCount) and not ((DataLink.ActiveRecord = DataLink.BufferCount) and (DataSetType = adsNonSequenced))) then
    begin
      DataLink.ActiveRecord := o;
      Exit;
    end;

    try
      c := ACol - Grid.FixedColumns;
      f := FieldAtColumn[c];
      if ShowBooleanFields and (ACol >= Grid.FixedColumns) and (ARow >= Grid.FixedRows) then
      begin
        if Assigned(f) and (f.DataType = ftBoolean) then
        begin
          evnt := (ACell as TTMSFNCCheckGridCell).OnCheckChanged;
          (ACell as TTMSFNCCheckGridCell).OnCheckChanged := nil;
          (ACell as TTMSFNCCheckGridCell).Checked := f.AsBoolean;
          (ACell as TTMSFNCCheckGridCell).OnCheckChanged := evnt;
        end;
      end;

      if (ARow >= Grid.FixedRows) and (ACol >= Grid.FixedColumns) and (Columns[c].CheckBoxField) then
      begin
        evnt := (ACell as TTMSFNCCheckGridCell).OnCheckChanged;
        (ACell as TTMSFNCCheckGridCell).OnCheckChanged := nil;
        (ACell as TTMSFNCCheckGridCell).Checked := UpperCase(f.DisplayText) = UpperCase(Columns[c].CheckTrue);
        (ACell as TTMSFNCCheckGridCell).OnCheckChanged := evnt;
      end;

      ACell.Text := '';

    finally
      DataLink.ActiveRecord := o;
    end;
  end;

  if ACell is TTMSFNCBitmapGridCell then
  begin
    o := DataLink.ActiveRecord;

    SetActiveRecord(ARow);

    if (DataLink.ActiveRecord < 0) or ((DataLink.ActiveRecord >= DataLink.BufferCount) and not ((DataLink.ActiveRecord = DataLink.BufferCount) and (DataSetType = adsNonSequenced))) then
    begin
      DataLink.ActiveRecord := o;
      Exit;
    end;

    try
      c := ACol - Grid.FixedColumns;
      f := FieldAtColumn[c];
      {$IFNDEF WEBLIB}
      if ShowPictureFields and (ACol >= Grid.FixedColumns) and (ARow >= Grid.FixedRows) then
        if Assigned(f) and (f.DataType = ftGraphic) then
          BlobFieldToPicture(TBlobField(f), (ACell as TTMSFNCBitmapGridCell).Bitmap);

      if (ARow >= Grid.FixedRows) and (ACol >= Grid.FixedColumns) and (Columns[c].PictureField) then
        BlobFieldToPicture(TBlobField(f), (ACell as TTMSFNCBitmapGridCell).Bitmap);
      {$ENDIF}
      {$IFDEF WEBLIB}
      if (ARow >= Grid.FixedRows) and (ACol >= Grid.FixedColumns) and (Columns[c].PictureField) then
      begin
        (ACell as TTMSFNCBitmapGridCell).Bitmap.LoadFromURL(f.AsString);
        if (ACell as TTMSFNCBitmapGridCell).Bitmap.Empty then
          (ACell as TTMSFNCBitmapGridCell).Bitmap.OnChange := @BitmapChanged
        else
          (ACell as TTMSFNCBitmapGridCell).Bitmap.OnChange := nil;
      end;
      {$ENDIF}

      ACell.Text := '';

    finally
      DataLink.ActiveRecord := o;
    end;
  end;

  ACell.ControlStretched := False;
  ACell.ControlPosition := gcpCenter;
end;

procedure TTMSFNCGridDatabaseAdapter.GetCellReadOnly(ACol, ARow: Integer;
  var AReadOnly: Boolean);
var
  i, rc, c: integer;
begin
  inherited;
  if not Assigned(Grid) then
    Exit;

  c := ACol - Grid.FixedColumns;

  if Assigned(FDataLink) then
  begin
    if FDataLink.Active and Assigned(FDataLink.DataSet) then
      if FDataLink.DataSet.Active and not FDataLink.DataSet.CanModify then
      begin
        if (c < Columns.Count) and (Columns[c].FieldName <> '') then
        begin
          AReadOnly := True;
          Exit;
        end;
      end;
  end;

  if (c >= Columns.Count) then
  begin
    AReadOnly := false;
    Exit;
  end;

  rc := c;

  if (rc >= 0) and (rc <= Columns.Count - 1) and (Columns[rc].HTMLTemplate <> '') then
  begin
    AReadOnly := True;
    Exit;
  end;

  if (rc >= 0) and (rc <= Columns.Count - 1) and not AReadOnly and CheckDataSet then
  begin
    if Columns[rc].FieldName <> '' then
      AReadOnly := FDataLink.DataSet.FieldByName(Columns[c].FieldName).ReadOnly
    else if not FShowDefinedFields then
    begin
      i := FieldIndexAtColumn[rc];
      if i <> -1 then
        AReadOnly := FDataLink.DataSet.Fields[i].ReadOnly;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetColumnDisplayName(ACol: Integer): String;
var
  c: Integer;
begin
  Result := '';
  if Assigned(Grid) then
  begin
    c := ACol - Grid.FixedColumns;
    if (c >= 0) and (c <= Columns.Count - 1) then
      Result := Columns[c].GetDisplayName;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetDataSource: TDataSource;
begin
  if Assigned(DataLink) then
    Result := DataLink.DataSource
  else
    Result := nil;
end;

function TTMSFNCGridDatabaseAdapter.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/ttmsfncgrid/#databinding';
end;

function TTMSFNCGridDatabaseAdapter.GetFieldAtColumn(ACol: Integer): TField;
var
  j: integer;
begin
  Result := nil;
  if (ACol >= Columns.Count) or not CheckDataSet then
    Exit;

  if (Columns[ACol].HTMLTemplate <> '') then
    Exit;

  if FShowDefinedFields then
  begin
    if Columns[ACol].FieldName <> '' then
      Result := DataLink.DataSet.FieldByName(Columns[ACol].FieldName);
  end
  else
  begin
    if Columns[ACol].FieldName <> '' then
      Result := DataLink.DataSet.FieldByName(Columns[ACol].FieldName)
    else
    begin
      j := GetFieldIndexAtColumn(ACol);
      if (j <> -1) then
        Result := DataLink.DataSet.Fields[j]
      else
        Result := nil;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetFieldIndexAtColumn(
  ACol: Integer): Integer;
var
  j, i, fieldIdx: Integer;
  f: TField;
begin
  Result := -1;
  if not CheckDataSet or (ACol >= Columns.Count) then
    Exit;

  if (Columns[ACol].HTMLTemplate <> '') then
    Exit;

  if FShowDefinedFields then
  begin
    if Columns[ACol].FieldName <> '' then
    begin
      f := DataLink.DataSet.FieldByName(Columns[ACol].FieldName);
      if Assigned(f) then
        Result := f.Index;
    end;
  end
  else
  begin
    if Columns[ACol].FieldName <> '' then
    begin
      f := DataLink.DataSet.FieldByName(Columns[ACol].FieldName);
      if Assigned(f) then
        Result := f.Index;
    end
    else
    begin
      fieldIdx := 0;
      j := 0;
      for i := 0 to DataLink.DataSet.FieldCount - 1 do
      begin
        if DataLink.DataSet.Fields[i].Visible then
        begin
          if fieldIdx >= (ACol - j) then
          begin
            Result := i;
            break;
          end;
          fieldIdx := fieldIdx + 1;
        end;
      end;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetLookupKey(AIndex: Integer): string;
var
  I: Integer;
begin
  Result := '';
  for I := 0 to FLookupKeys.Count - 1 do
  begin
    if (TTMSFNCGridDatabaseAdapterLookupItem(FLookupKeys.Objects[I]).Index = AIndex) then
    begin
      Result := FLookupKeys[I];
      Break;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetRecordCount: Integer;

  function RowsInDataSet: Integer;
  var
    cb: TBookMark;
    iseof, isbof: Boolean;
  begin
    Result := -1;
    if not CheckDataSet then
      Exit;

    iseof := DataLink.DataSet.Eof;
    isbof := DataLink.DataSet.Bof;

    if isbof and iseof then
      Result := 0
    else
      with DataLink.DataSet do
      begin
        cb := GetBookMark;

        if BookmarkValid(cb) then
        begin
          First;
          if (csDesigning in ComponentState) then
            Result := MoveBy(100)
          else
            Result := MoveBy($7FFFFFFF) + 1;

          GotoBookMark(cb);
        end;

        FreeBookMark(cb);
      end;

    if iseof then
      DataLink.DataSet.Next;

    if isbof then
      DataLink.DataSet.Prior;

    if DataLink.DataSet.State = dsInsert then
      Result := Result + 1;
  end;
begin
  Result := 0;
  if Assigned(FDataLink) then
  begin
    if Assigned(DataLink.DataSet) then
    begin
      if DataLink.DataSet.Active then
      begin
        DataLink.DataSet.DisableControls;
        if Assigned(OnGetRecordCount) then
          OnGetRecordCount(Self, Result)
        else
        begin
          Result := RowsInDataSet;
          if Result = -1 then
            Result := DataLink.RecordCount;
        end;
        FEmptyDataSet := Result = 0;
        DataLink.DataSet.EnableControls;
      end;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapter.GetRecordNo: Integer;
begin
  if DataLink.DataSet.State = dsInsert then
    Result := FPrevRecNo
  else
    Result := DataLink.DataSet.RecNo;
end;

function TTMSFNCGridDatabaseAdapter.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCGridDatabaseAdapter.HTMLDBReplace(AValue: string;
  ADataset: TDataSet; ACol, ARow: Integer): string;
var
  beforetag, aftertag, fld, dbfld: string;
  i, j: integer;
  AField: TField;
begin
  beforetag := '';

  while Pos('<#', AValue) > 0 do
  begin
    i := pos('<#', AValue);
    beforetag := beforetag + copy(AValue, 1, i - 1); //part prior to the tag
    aftertag := copy(AValue, i, length(AValue)); //part after the tag
    j := pos('>', aftertag);
    fld := copy(aftertag, 1, j - 1);
    Delete(fld, 1, 2);
    Delete(AValue, 1, i + j - 1);

    dbfld := '';
    if Assigned(ADataSet) then
    begin
      if ADataSet.Active then
      begin
        AField := ADataSet.FindField(fld);
        if Assigned(AField) then
        begin
          if AField.IsBlob then
          begin
            if not ShowMemoFields then
            begin
              dbfld := '(MEMO)'
            end
            else
            begin
              if Assigned(AField.OnGetText) then
                dbfld := AField.DisplayText
              else
                dbfld := AField.AsString;
            end;
          end
          else
           dbfld := AField.DisplayText;
        end;

        if Assigned(FOnGetHTMLTemplateData) then
          FOnGetHTMLTemplateData(Self, ACol, ARow, fld, dbfld);
      end
      else
        dbfld := '(' + fld + ')';
    end
    else
      dbfld := '(' + fld + ')';

    beforetag := beforetag + dbfld;
  end;

  Result := beforetag + AValue;
end;

procedure TTMSFNCGridDatabaseAdapter.Initialize;
begin
  inherited;
  ActiveChange;
end;

procedure TTMSFNCGridDatabaseAdapter.LoadAllDataAndDisconnect;
var
  CurrentGrid: TTMSFNCCustomGridOpen;
  cb: TBookmark;
  I: Integer;
  c, r: Integer;
  s: string;
  f: TField;
  bmp: TTMSFNCBitmap;
begin
  if not CheckDataSet then
    Exit;

  CurrentGrid := TTMSFNCCustomGridOpen(Grid);

  CurrentGrid.BeginUpdate;
  try
    CurrentGrid.ExportNotification(esExportStart,-1);

    DataLink.DataSet.DisableControls;
    cb := DataLink.DataSet.GetBookmark;
    DataLink.DataSet.First;

    FSkipRetrieveData := True;

    for I := 0 to Columns.Count - 1 do
    begin
      f := Columns[I].Field;
      if Assigned(f) then
      begin
        s := '';
        GetCellData(CurrentGrid.FixedColumns + I, 0, s);
        CurrentGrid.Cells[CurrentGrid.FixedColumns + I, 0] := s;
      end;
    end;

    r := CurrentGrid.FixedRows;
    while not DataLink.DataSet.Eof do
    begin
      c := CurrentGrid.FixedColumns;
      for I := 0 to Columns.Count - 1 do
      begin
        f := Columns[I].Field;
        if Assigned(f) then
        begin
          s := '';
          GetCellData(c + I, r, s);
          CurrentGrid.Cells[c + I, r] := s;

          if ShowBooleanFields and (c + I >= Grid.FixedColumns) and (r >= Grid.FixedRows)  then
            if Assigned(f) and (f.DataType = ftBoolean) then
              CurrentGrid.Booleans[C + I, R] := f.AsBoolean;

          if (r >= Grid.FixedRows) and (c + I >= Grid.FixedColumns) and (Columns[I].CheckBoxField) then
            CurrentGrid.Booleans[C + I, R] := UpperCase(f.DisplayText) = UpperCase(Columns[I].CheckTrue);

          {$IFNDEF WEBLIB}
          if ShowPictureFields and (c >= Grid.FixedColumns) and (r >= Grid.FixedRows) then
          begin
            if Assigned(f) and (f.DataType = ftGraphic) then
            begin
              bmp := TTMSFNCBitmap.Create;
              try
                BlobFieldToPicture(TBlobField(f), bmp);
                CurrentGrid.Bitmaps[c + I, R] := bmp;
              finally

              end;
            end;
          end;

          if (r >= Grid.FixedRows) and (c >= Grid.FixedColumns) and (Columns[I].PictureField) then
          begin
            bmp := TTMSFNCBitmap.Create;
            try
              BlobFieldToPicture(TBlobField(f), bmp);
              CurrentGrid.Bitmaps[c + I, R] := bmp;
            finally
              bmp.Free;
            end;
          end;
          {$ENDIF}

        end;
      end;

      CurrentGrid.ExportNotification(esExportNewRow, r);

      DataLink.DataSet.Next;

      Inc(r);
    end;

    FSkipRetrieveData := False;
  finally
    DataLink.DataSet.GotoBookmark(cb);
    DataLink.DataSet.FreeBookmark(cb);
    DataLink.DataSet.EnableControls;
  end;

  CurrentGrid.ExportNotification(esExportDone,-1);
  Inc(FInternalCall);
  Active := False;
  Dec(FInternalCall);
  CurrentGrid.EndUpdate;
end;

procedure TTMSFNCGridDatabaseAdapter.LoadLookupList(AField: TField;
  AList: TStrings);
var
  s, k: string;
  i: Integer;
  o: TObject;
  li: TTMSFNCGridDatabaseAdapterLookupItem;
begin
  if not CheckDataset then
    Exit;

  if Assigned(AField) and Assigned(AField.LookupDataSet) then
  begin
    AField.LookupDataSet.DisableControls;
    AField.LookupDataSet.First;

    for I := 0 to FLookupKeys.Count - 1 do
    begin
      o := FLookupKeys.Objects[I];
      if Assigned(o) then
        o.Free;
    end;

    i := 1;
    FLookupKeys.Clear;
    AList.Clear;

    while not AField.LookupDataSet.Eof do
    begin
      s := AField.LookupDataSet.FieldByName(AField.LookupResultField).AsString;
      k := AField.LookupDataSet.FieldByName(AField.LookupKeyFields).AsString;
      li := TTMSFNCGridDatabaseAdapterLookupItem.Create(i);
      FLookupKeys.AddObject(k, li);
      AList.AddObject(s, li);
      inc(i);
      AField.LookupDataSet.Next;
    end;
    AField.LookupDataSet.EnableControls;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.Notification(AComponent: TComponent;
  AOperation: TOperation);
begin
  inherited;
  if (AOperation = opRemove) and (AComponent = DataSource) then
  begin
    DataSource := nil;
    Grid := nil;
    Initialize;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.RecordChanged(Field: TField);
begin
  FRecordChanged := True;
  UpdateGrid;
end;

procedure TTMSFNCGridDatabaseAdapter.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCGridDatabaseAdapter, TTMSFNCGridDatabaseAdapterColumn]);
end;

procedure TTMSFNCGridDatabaseAdapter.RemoveAllColumns;
var
  i: Integer;
begin
  Grid.BeginUpdate;
  for i := 0 to Columns.Count do
  begin
    Columns[i].FieldName := '';
    Columns[i].Header := '';
  end;

  Columns.Clear;
  Grid.Clear;
  Grid.RowCount := 0;
  Grid.ColumnCount := 0;
  Grid.EndUpdate;
end;

procedure TTMSFNCGridDatabaseAdapter.RemoveAllFields;
var
  i: Integer;
  flds: Boolean;
begin
  flds := Columns.HasFieldsDefined;
  if (csDesigning in ComponentState) and not flds then
    TTMSFNCUtils.Message(sTMSFNCGridDatabaseAdapterNoGridAssigned);

  for i := 0 to Columns.Count - 1 do
  begin
    if Columns[i].Header = Columns[i].FieldName then
      Columns[i].Header := '';

    Columns[i].FieldName := '';
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.Scroll(Distance: Integer);
var
  ro: Integer;
begin
  if not CheckDataSet then
    Exit;

  if FInternalCall > 0 then
    Exit;

  Inc(FInternalCall);
  if Assigned(Grid) then
  begin
    case DataSetType of
      adsSequenced:
      begin
        ro := DataLink.DataSet.RecNo;
        FPrevRecNo := ro;
        Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, ro - 1 + Grid.FixedRows));
      end;
      adsNonSequenced:
      begin
        ro := DataLink.ActiveRecord;
        Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, ro + TTMSFNCCustomGridOpen(Grid).TopRow));
        FPreviousCell.Row := ro + TTMSFNCCustomGridOpen(Grid).TopRow;

        if DataLink.ActiveRecord > TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow then
          FOffset := DataLink.ActiveRecord - (TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow)
        else if DataLink.ActiveRecord < TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow then
          FOffset := Max(FOffset + Distance, 0)
        else
          FOffset := 0;
      end;
    end;
    UpdateGrid;
  end;
  Dec(FInternalCall);
end;

procedure TTMSFNCGridDatabaseAdapter.ScrollGrid(ADelta: Integer; ABeforeDisplayCells: Boolean = True; AScrollOnly: Boolean = False);
var
  ro, off, nr: Integer;
begin
  inherited;
  if FInternalCall > 0 then
    Exit;

  Inc(FInternalCall);
  if Assigned(Grid) and CheckDataSet and (ADelta <> 0) then
  begin
    case DataSetType of
      adsSequenced:
      begin
        if ABeforeDisplayCells then
        begin
//          if TTMSFNCCustomGridOpen(Grid).TopRow = Grid.FixedRows then
//            DataLink.DataSet.First
//          else if TTMSFNCCustomGridOpen(Grid).BottomRow = Grid.RowCount - Grid.FixedRows then
//            DataLink.DataSet.Last
//          else
          begin
            if ADelta > 0 then
              DataLink.DataSet.RecNo := TTMSFNCCustomGridOpen(Grid).TopRow
            else
              DataLink.DataSet.RecNo := TTMSFNCCustomGridOpen(Grid).BottomRow;

            ro := DataLink.DataSet.RecNo;
            off := 0;
            if ADelta > 0 then
              off := TTMSFNCCustomGridOpen(Grid).BottomRow - (ro - 1 + Grid.FixedRows)
            else if ADelta < 0 then
              off := TTMSFNCCustomGridOpen(Grid).TopRow - (ro - 1 + Grid.FixedRows);

            DataLink.MoveBy(off);
            FPrevRecNo := DataLink.DataSet.RecNo;
          end;
        end;
      end;
      adsNonSequenced:
      begin
        if ABeforeDisplayCells then
        begin
          ro := FPreviousCell.Row;
          off := 0;
          if ADelta > 0 then
            off := TTMSFNCCustomGridOpen(Grid).BottomRow - ro
          else if ADelta < 0 then
            off := TTMSFNCCustomGridOpen(Grid).TopRow - ro;

//          if TTMSFNCCustomGridOpen(Grid).TopRow = Grid.FixedRows then
//            DataLink.DataSet.First
//          else if TTMSFNCCustomGridOpen(Grid).BottomRow = Grid.RowCount - Grid.FixedRows then
//            DataLink.DataSet.Last
//          else
            DataLink.MoveBy(off);

          if DataLink.ActiveRecord > TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow then
            FOffset := DataLink.ActiveRecord - (TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow)
          else if DataLink.ActiveRecord < TTMSFNCCustomGridOpen(Grid).BottomRow - TTMSFNCCustomGridOpen(Grid).TopRow then
            FOffset := Max(FOffset + ADelta, 0)
          else
            FOffset := 0;

          FPreviousCell.Row := FPreviousCell.Row + off;
        end;
      end;
    end;
  end;
  Dec(FInternalCall);

  if CheckDataSet and Assigned(DataLink.DataSet)
    and (DataLink.DataSet.State = dsBrowse) and AScrollOnly then
  begin
    ro := GetRecordNo;
    nr := ro - 1 + Grid.FixedRows;
    Grid.SaveScrollPosition;
    Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, nr));
    Grid.RestoreScrollPosition;
  end
end;

procedure TTMSFNCGridDatabaseAdapter.SetActiveRecord(ARow: Integer);
var
  ro, tp, off, rs: Integer;
begin
  case DataSetType of
    adsSequenced:
    begin
      ro := GetRecordNo;
      tp := DataLink.ActiveRecord + TTMSFNCCustomGridOpen(Grid).TopRow - Grid.FixedRows;
      off := tp - (ro - 1);
      rs := ARow - TTMSFNCCustomGridOpen(Grid).TopRow + off;
      DataLink.ActiveRecord := rs
    end;
    adsNonSequenced:
    begin
      rs := ARow - TTMSFNCCustomGridOpen(Grid).TopRow + FOffset;
      if FNewAppendRecord then
        DataLink.ActiveRecord := rs
      else
        DataLink.ActiveRecord := rs
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.SetColumns(
  const Value: TTMSFNCGridDatabaseAdapterColumns);
begin
  FColumns.Assign(Value);
end;

procedure TTMSFNCGridDatabaseAdapter.SelectCell(ACell: TTMSFNCGridCellRec);
var
  ro: Integer;
  a: Boolean;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if FInternalCall > 0 then
    Exit;

  a := False;
  case PostMode of
    apmCell: a := (ACell.Row <> TTMSFNCCustomGridOpen(Grid).PrevFocusedCell.Row) or (ACell.Col <> TTMSFNCCustomGridOpen(Grid).PrevFocusedCell.Col);
    apmRow: a := (ACell.Row <> TTMSFNCCustomGridOpen(Grid).PrevFocusedCell.Row);
  end;

  if a then
  begin
    if DataLink.DataSet.State = dsInsert then
    begin
      DataLink.DataSet.Post;
      FCellToSelect := ACell;
      FSelectTimer.Enabled := true;
    end
    else
    begin
      Inc(FInternalCall);
      case DataSetType of
        adsSequenced:
        begin
          ro := DataLink.DataSet.RecNo;
//          if ACell.Row = Grid.FixedRows then
//            DataLink.DataSet.First
//          else if ACell.Row = Grid.RowCount - Grid.FixedRows then
//            DataLink.DataSet.Last
//          else
            DataLink.MoveBy(ACell.Row - (ro - 1 + Grid.FixedRows));

          FPrevRecNo := DataLink.DataSet.RecNo;
        end;
        adsNonSequenced:
        begin
          ro := FPreviousCell.Row;
//          if ACell.Row = Grid.FixedRows then
//            DataLink.DataSet.First
//          else if ACell.Row = Grid.RowCount - Grid.FixedRows then
//            DataLink.DataSet.Last
//          else
            DataLink.MoveBy(ACell.Row - ro);
          FPreviousCell := ACell;
        end;
      end;
      Dec(FInternalCall);
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.SetDataSetType(
  const Value: TTMSFNCGridDatabaseAdapterDataSetType);
begin
  if Value <> FDataSetType then
  begin
    FDataSetType := Value;
    if CheckDataSet then
    begin
      DataLink.DataSet.Active := false;
      DataLink.DataSet.Active := true;
    end;
  end;
end;

procedure TTMSFNCGridDatabaseAdapter.SetDataSource(const Value: TDataSource);
begin
  if Value = DataLink.Datasource then
    Exit;

  DataLink.DataSource := Value;

  if not Assigned(DataLink.DataSource) then
  begin
    Grid.BeginUpdate;
    Grid.Clear;
    Grid.RowCount := Grid.FixedRows + 1;

    if AutoRemoveColumns then
      Grid.ColumnCount := 0;

    Grid.EndUpdate;
  end;

  if Value <> nil then
    Value.FreeNotification(Self);
end;

procedure TTMSFNCGridDatabaseAdapter.UpdateRowCount;
var
  nrc: Integer;
  rcount: Boolean;
begin
  if not Assigned(Grid) then
    Exit;

  rcount := False;

  Grid.BeginUpdate;
  if Grid.RowCount <= Grid.FixedRows then
    Grid.RowCount := Grid.FixedRows;

  if not DataLink.Active then
    Grid.RowCount := (Grid.FixedRows + 1)
  else
  begin
    if (FDataLink.DataSet.State = dsInsert) and (FOldState <> dsInsert) then
    begin
      if not FEmptyDataSet then
      begin
        Grid.InsertRows(Grid.FixedRows, 1);

        if FDataLink.DataSet.Eof then
        begin
          FPrevRecNo := Grid.RowCount - 1;
          
          case DataSetType of
            adsSequenced: Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, Grid.RowCount - 1));
            adsNonSequenced: Grid.SelectCell(MakeCell(Grid.FocusedCell.Col, Min(Grid.VisibleRowCount + 1, Grid.RowCount - 1)));
          end;
        end;
      end
      else
      begin
        Grid.InsertRows(Grid.FixedRows, 1);

        if FDataLink.DataSet.Eof then
        begin
          FPrevRecNo := Grid.RowCount - 1;
        
          case DataSetType of
            adsSequenced: Grid.SelectCell(MakeCell(Grid.FixedColumns, Grid.RowCount - 1));
            adsNonSequenced: Grid.SelectCell(MakeCell(Grid.FixedColumns, Min(Grid.VisibleRowCount + 1, Grid.RowCount - 1)));
          end;
        end;
      end;
    end
    else if FDataLink.DataSet.State = dsBrowse then
    begin
      if (FOldState = dsInsert) and FRecordChanged and FEmptyDataSet then
      begin
        nrc := GetRecordCount;
        if nrc < Grid.FixedRows then
          Grid.RowCount := Grid.FixedRows
        else
          Grid.RowCount := nrc + Grid.FixedRows;

        rcount := True;
      end;

      if (FOldState = dsInsert) and (((not FRecordChanged) and (PostMode = apmCell)) or (PostMode = apmRow)) then
      begin
        if Grid.RowCount > (Grid.FixedRows + 1) then
        begin
          if (PostMode = apmCell) then
            Grid.DeleteRow(Grid.RowCount - 1);

          nrc := Self.GetRecordCount;

          if nrc < Grid.FixedRows then
            Grid.RowCount := Grid.FixedRows
          else
            Grid.RowCount := nrc + Grid.FixedRows;

          rcount := True;
        end;
      end
      else if (FOldState = dsBrowse) and (FDataLink.DataSet.State = dsBrowse) then
      begin
        nrc := GetRecordCount;

        if nrc < Grid.FixedRows then
          Grid.RowCount := Grid.FixedRows
        else
          Grid.RowCount := nrc + Grid.FixedRows;

        rcount := True;
      end;

      if (FOldState = dsInsert) and (PostMode = apmRow) and FEmptyDataSet then
      begin
        nrc := GetRecordCount;
        if nrc < Grid.FixedRows then
          Grid.RowCount := Grid.FixedRows
        else
          Grid.RowCount := nrc + Grid.FixedRows;

        rcount := True;
      end;

      if not rcount and (FOldState = dsInsert) and (FRecordChanged and (PostMode = apmCell)) then
      begin
        nrc := GetRecordCount;
        if nrc < Grid.FixedRows then
          Grid.RowCount := Grid.FixedRows
        else
          Grid.RowCount := nrc + Grid.FixedRows;
      end;
    end;
  end;

  FRecordChanged := False;
  DataLink.BufferCount := GetBufferCount;
  Grid.EndUpdate;
end;

procedure TTMSFNCGridDatabaseAdapter.UpdateVisibleFields;
var
  i, j, NewVisibleFieldCount, cc: integer;
  f: TField;
begin
  NewVisibleFieldCount := 0;

  for i := 0 to DataLink.DataSet.FieldCount - 1 do
    if DataLink.DataSet.Fields[i].Visible then
      NewVisibleFieldCount := NewVisibleFieldCount + 1;

  if NewVisibleFieldCount <> FVisibleFieldCount then
  begin
    FVisibleFieldCount := NewVisibleFieldCount;

    if not FShowDefinedFields then
    begin
      cc := FVisibleFieldCount - Grid.HiddenColumnCount;
      if cc >= 0 then
      begin
        for I := 0 to cc - 1 do
          Columns.Add;
      end;
    end;

    if not FShowDefinedFields then
    begin
      for i := 0 to Columns.Count - 1 do
        Columns[i].FieldName := '';
    end;

    for i := 0 to Columns.Count - 1 do
    begin
      f := nil;
      if Columns[i].FieldName <> '' then
        f := DataLink.DataSet.FindField(Columns[i].FieldName)
      else
        if not FShowDefinedFields then
        begin
          j := FieldIndexAtColumn[i];
          if j <> -1 then
            f := DataLink.DataSet.Fields[j]
          else
            f := nil;
        end;

      if Assigned(f) then
      begin
        if not FShowDefinedFields and (Columns[i].FieldName = '') then
          Columns[i].FieldName := f.FieldName;
      end;
    end;

    Grid.BeginUpdate;
    Grid.ColumnCount := Columns.Count + Grid.FixedColumns + Grid.FixedRightColumns;
    Grid.EndUpdate;
  end;
end;

{ TTMSFNCGridDatabaseAdapterItemDataLink }

procedure TTMSFNCGridDatabaseAdapterItemDataLink.ActiveChanged;
begin
  if Active and Assigned(DataSource) then
    if Assigned(DataSource.DataSet) then
      if DataSource.DataSet.IsUnidirectional then
        raise Exception.Create(sTMSFNCGridDatabaseAdapterUnidirectionalDataSet);

  FAdapter.ActiveChange;
  FModified := False;
end;

function TTMSFNCGridDatabaseAdapterItemDataLink.Adapter: TTMSFNCGridAdapter;
begin
  Result := FAdapter;
end;

constructor TTMSFNCGridDatabaseAdapterItemDataLink.Create(
  AAdapter: TTMSFNCGridDatabaseAdapter);
begin
  FAdapter := AAdapter;
  VisualControl := True;
end;

procedure TTMSFNCGridDatabaseAdapterItemDataLink.DataSetChanged;
begin
  FAdapter.DataSetChanged;
  FModified := False;
end;

procedure TTMSFNCGridDatabaseAdapterItemDataLink.DataSetScrolled(
  Distance: Integer);
begin
  FAdapter.Scroll(Distance);
end;

destructor TTMSFNCGridDatabaseAdapterItemDataLink.Destroy;
begin

  inherited;
end;

procedure TTMSFNCGridDatabaseAdapterItemDataLink.Modified;
begin
  FModified := True;
end;

procedure TTMSFNCGridDatabaseAdapterItemDataLink.RecordChanged(Field: TField);
begin
  FAdapter.RecordChanged(Field);
end;

procedure TTMSFNCGridDatabaseAdapterItemDataLink.Reset;
begin
  if FModified then
    RecordChanged(nil)
  else
    Dataset.Cancel;
end;

procedure TTMSFNCGridDatabaseAdapterItemDataLink.UpdateData;
begin
  if FModified then
    FAdapter.UpdateData;
  FModified := False;
end;

{ TTMSFNCGridDatabaseAdapterColumn }

function TTMSFNCGridDatabaseAdapterColumn.Adapter: TTMSFNCGridDatabaseAdapter;
begin
  Result := FAdapter;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGridDatabaseAdapterColumn then
  begin
  end;
end;

constructor TTMSFNCGridDatabaseAdapterColumn.Create(ACollection: TCollection);
begin
  inherited;
  FUseLookupEditor := True;
  FCheckTrue := 'True';
  FCheckFalse := 'False';
  FCheckBoxField := False;
  FProgressField := False;
  FPictureField := False;
  FAdapter := nil;
  if Assigned(Collection) and (Collection is TTMSFNCGridDatabaseAdapterColumns) then
    FAdapter := (Collection as TTMSFNCGridDatabaseAdapterColumns).Adapter;
end;

function TTMSFNCGridDatabaseAdapterColumn.GetDisplayName: string;
begin
  if Header = '' then
  begin
    if FieldName = '' then
      Result := 'Column ' + Inttostr(Index)
    else
      Result := FieldName;
  end
  else
    Result := Header;
end;

function TTMSFNCGridDatabaseAdapterColumn.GetField: TField;
var
  ap: TTMSFNCGridDatabaseAdapter;
begin
  ap := Adapter;
  if (FField = nil) and (Length(FFieldName) > 0) and Assigned(ap) and
    Assigned(ap.DataLink.DataSet) then
    with ap.Datalink.DataSet do
      if Active {$IFNDEF LCLWEBLIB}or not (lcAutomatic in Fields.LifeCycles){$ENDIF} then
        SetField(FindField(FieldName));
  Result := FField;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetCheckBoxField(
  const Value: Boolean);
begin
  if Value and (FPictureField or FProgressField) then
    raise Exception.Create(sTMSFNCGridDatabaseAdapterPictureField);

  if FCheckBoxField <> Value then
  begin
    FCheckBoxField := Value;

    if Assigned(FAdapter) then
      FAdapter.UpdateGrid;
  end;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetField(Value: TField);
var
  ap: TTMSFNCGridDatabaseAdapter;
begin
  if FField = Value then
    Exit;

  if Assigned(Value) and (csDestroying in Value.ComponentState) then
    Value := nil;

  ap := Adapter;

  if Assigned(FField) and Assigned(ap) then
    FField.RemoveFreeNotification(ap);

  FField := Value;
  if Assigned(Value) then
  begin
    if Assigned(ap) then
      FField.FreeNotification(ap);
    FFieldName := Value.FieldName;
  end;

  if Assigned(FAdapter) then
    FAdapter.UpdateGrid;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetFieldName(const Value: string);
var
  f: TField;
  ap: TTMSFNCGridDatabaseAdapter;
begin
  f := nil;
  ap := Adapter;
  if Assigned(ap) and Assigned(ap.DataLink.DataSet) and
    not (csLoading in ap.ComponentState) and (Length(Value) > 0) then
      f := ap.DataLink.DataSet.FindField(Value);
  FFieldName := Value;
  if Value <> '' then
    Field := f;

  if Assigned(FAdapter) then
    FAdapter.UpdateGrid;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetHeader(const Value: string);
var
  ap: TTMSFNCGridDatabaseAdapter;
begin
  FHeader := Value;
  ap := Adapter;
  if Assigned(ap) and Assigned(ap.Grid) then
    ap.Grid.Cells[Index + ap.Grid.FixedColumns, 0] := FHeader;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetHTMLTemplate(const Value: string);
begin
  FHTMLTemplate := Value;
  if assigned(FAdapter) then
    FAdapter.UpdateGrid;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetPictureField(
  const Value: Boolean);
begin
  if Value and (FCheckBoxField or FProgressField) then
    raise Exception.Create(sTMSFNCGridDatabaseAdapterCheckBoxField);

  if FPictureField <> Value then
  begin
    FPictureField := Value;
    if Assigned(FAdapter) then
      FAdapter.UpdateGrid;
  end;
end;

procedure TTMSFNCGridDatabaseAdapterColumn.SetProgressField(
  const Value: Boolean);
begin
  if Value and (FCheckBoxField or FPictureField) then
    raise Exception.Create(sTMSFNCGridDatabaseAdapterCheckPictureField);

  if FProgressField <> Value then
  begin
    FProgressField := Value;
    if Assigned(FAdapter) then
      FAdapter.UpdateGrid;
  end;
end;

{ TTMSFNCGridDatabaseAdapterColumns }

function TTMSFNCGridDatabaseAdapterColumns.Adapter: TTMSFNCGridDatabaseAdapter;
begin
  Result := FAdapter;
end;

function TTMSFNCGridDatabaseAdapterColumns.Add: TTMSFNCGridDatabaseAdapterColumn;
begin
  Result := TTMSFNCGridDatabaseAdapterColumn(inherited Add);
end;

constructor TTMSFNCGridDatabaseAdapterColumns.Create(AAdapter: TTMSFNCGridDatabaseAdapter);
begin
  inherited Create(AAdapter, TTMSFNCGridDatabaseAdapterColumn);
  FAdapter := AAdapter;
end;

function TTMSFNCGridDatabaseAdapterColumns.GetItem(Index: Integer): TTMSFNCGridDatabaseAdapterColumn;
begin
  Result := TTMSFNCGridDatabaseAdapterColumn(inherited GetItem(Index));
end;

function TTMSFNCGridDatabaseAdapterColumns.HasFieldsDefined: boolean;
var
  i: Integer;
begin
  Result := False;
  for i := 0 to Count - 1 do
  begin
    if (Items[i].FieldName <> '') and not (Items[i].AutoCreated) then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCGridDatabaseAdapterColumns.Insert(index: Integer): TTMSFNCGridDatabaseAdapterColumn;
begin
  Result := TTMSFNCGridDatabaseAdapterColumn(inherited Insert(Index));
end;

procedure TTMSFNCGridDatabaseAdapterColumns.SetItem(Index: Integer; const Value: TTMSFNCGridDatabaseAdapterColumn);
begin
  inherited SetItem(Index, Value);
end;

{ TTMSFNCGridDatabaseAdapterLookupItem }

constructor TTMSFNCGridDatabaseAdapterLookupItem.Create(AIndex: Integer);
begin
  FIndex := AIndex;
end;

end.

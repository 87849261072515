unit uBrandEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, WEBLib.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons;

type
  TBrandEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    BrandNameLabel: TLabel;
    BrandNameEdit: TEdit;
    MyMessageDlg: TMessageDlg;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Integer;
    IsSet: Boolean;
  protected procedure LoadDFMValues; override; end;

var
  BrandEditForm: TBrandEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TBrandEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TBrandEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TBrandEditForm.WebFormShow(Sender: TObject);
begin
  LoadData();
  RecordChanged := False;
end;

procedure TBrandEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  if PKey >= 0 then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_brand_load.php?sessionid=' + SessionId +
        '&pkey=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    finally
      MyWebRequest.Free;
    end;
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      // Assign Values
      BrandNameEdit.Text := MyJSONObject.GetJSONValue('ID');
    finally
      MyJSON.Free;
    end
  end;
end;

function TBrandEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Name
  if Result then begin
    if Trim(BrandNameEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'Device ID is a required field.';
    end;
  end;
end;

function TBrandEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id": "' + BrandNameEdit.Text + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_brand_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TBrandEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, mtError, [mbOK]);
  end;
end;

procedure TBrandEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  BrandNameLabel := TLabel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  BrandNameEdit := TEdit.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  BrandNameLabel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  BrandNameEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  try
    Name := 'BrandEditForm';
    Width := 445;
    Height := 198;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 445;
    FormPanel.Height := 198;
    FormPanel.HeightPercent := 100.000000000000000000;
    FormPanel.WidthPercent := 100.000000000000000000;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 439;
    ContentPanel.Height := 155;
    ContentPanel.Margins.Top := 0;
    ContentPanel.HeightPercent := 100.000000000000000000;
    ContentPanel.WidthPercent := 100.000000000000000000;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    BrandNameLabel.SetParentComponent(ContentPanel);
    BrandNameLabel.Name := 'BrandNameLabel';
    BrandNameLabel.Left := 20;
    BrandNameLabel.Top := 15;
    BrandNameLabel.Width := 42;
    BrandNameLabel.Height := 21;
    BrandNameLabel.Caption := 'Name';
    BrandNameLabel.Font.Charset := ANSI_CHARSET;
    BrandNameLabel.Font.Color := 9010544;
    BrandNameLabel.Font.Height := -16;
    BrandNameLabel.Font.Name := 'Segoe UI';
    BrandNameLabel.Font.Style := [];
    BrandNameLabel.HeightPercent := 100.000000000000000000;
    BrandNameLabel.ParentFont := False;
    BrandNameLabel.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 106;
    StatusBarPanel.Width := 439;
    StatusBarPanel.Height := 49;
    StatusBarPanel.HeightPercent := 100.000000000000000000;
    StatusBarPanel.WidthPercent := 100.000000000000000000;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderColor := clSilver;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 319;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uBrandEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 204;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uBrandEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    BrandNameEdit.SetParentComponent(ContentPanel);
    BrandNameEdit.Name := 'BrandNameEdit';
    BrandNameEdit.Left := 20;
    BrandNameEdit.Top := 42;
    BrandNameEdit.Width := 397;
    BrandNameEdit.Height := 34;
    BrandNameEdit.ChildOrder := 1;
    BrandNameEdit.ElementClassName := 'MyWebEdit';
    BrandNameEdit.Font.Charset := ANSI_CHARSET;
    BrandNameEdit.Font.Color := clWindowText;
    BrandNameEdit.Font.Height := -16;
    BrandNameEdit.Font.Name := 'Segoe UI';
    BrandNameEdit.Font.Style := [];
    BrandNameEdit.HeightPercent := 100.000000000000000000;
    BrandNameEdit.ParentFont := False;
    BrandNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BrandNameEdit, Self, 'OnChange', 'RecordChange');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 445;
    CaptionPanel.Height := 40;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 84;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Brand - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 416;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uBrandEdit.CaptionPanel.CloseImage.Picture.png');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 222;
    MyMessageDlg.Top := 52;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.HeightPercent := 100.000000000000000000;
    MyMessageDlg.WidthPercent := 100.000000000000000000;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.DialogText.BeginUpdate;
    try
      MyMessageDlg.DialogText.Clear;
      MyMessageDlg.DialogText.Add('Warning');
      MyMessageDlg.DialogText.Add('Error');
      MyMessageDlg.DialogText.Add('Information');
      MyMessageDlg.DialogText.Add('Confirm');
      MyMessageDlg.DialogText.Add('Custom');
      MyMessageDlg.DialogText.Add('OK');
      MyMessageDlg.DialogText.Add('Cancel');
      MyMessageDlg.DialogText.Add('Yes');
      MyMessageDlg.DialogText.Add('No');
      MyMessageDlg.DialogText.Add('Abort');
      MyMessageDlg.DialogText.Add('Retry');
      MyMessageDlg.DialogText.Add('Ignore');
      MyMessageDlg.DialogText.Add('All');
      MyMessageDlg.DialogText.Add('Yes to All');
      MyMessageDlg.DialogText.Add('No to All');
      MyMessageDlg.DialogText.Add('Help');
      MyMessageDlg.DialogText.Add('Close');
    finally
      MyMessageDlg.DialogText.EndUpdate;
    end;
    MyMessageDlg.Opacity := 0.200000000000000000;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    BrandNameLabel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    BrandNameEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
  end;
end;

end.
{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCWXHTMLMemo;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.Forms, SysUtils, WEBLib.Dialogs, StrUtils
  {$IFDEF FMXLIB}
  , System.UITypes
  {$ENDIF}
  {$IFDEF VCLLIB}
  , Vcl.Controls
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  , System.Types, System.JSON, System.Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  , fpjson, Controls
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  , WEBLIB.Controls, Web, Contnrs, WEBLIB.JSON
  {$ENDIF}
  , WEBLib.TMSFNCCommonJSFiles, WEBLib.TMSFNCWebBrowser, WEBLib.TMSFNCCustomWEBControl,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCWXHTMLMemo.Common, WEBLib.TMSFNCWXCommon;

type
  TTMSFNCWXHTMLMemoChangeContent = procedure(Sender: TObject;
    HTMLContent, ContentPlainText: string) of object;
  TTMSFNCWXHTMLMemoStyleTextUpdate = procedure(Sender: TObject; IsBold, IsItalic, IsUnderline,
    IsStrikeThrough: Boolean; FontName: string; FontSize: Integer;
    ForeColor, BackColor: TTMSFNCGraphicsColor) of object;

  TTMSFNCWXHTMLMemoInsertButtons = class(TPersistent)
  private
    FBtVideo: Boolean;
    FBtTable: Boolean;
    FBtPicture: Boolean;
    FBtLink: Boolean;
    FBtHr: Boolean;
    FOnChanged: TNotifyEvent;
    procedure SetBtHr(const Value: Boolean);
    procedure SetBtLink(const Value: Boolean);
    procedure SetBtPicture(const Value: Boolean);
    procedure SetBtTable(const Value: Boolean);
    procedure SetBtVideo(const Value: Boolean);
  protected
    procedure Change;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property BtPicture: Boolean read FBtPicture write SetBtPicture default True;
    property BtLink: Boolean read FBtLink write SetBtLink default True;
    property BtVideo: Boolean read FBtVideo write SetBtVideo default True;
    property BtTable: Boolean read FBtTable write SetBtTable default True;
    property BtHr: Boolean read FBtHr write SetBtHr default True;
  end;

  TTMSFNCWXHTMLMemoFontButtons = class(TPersistent)
  private
    FBtClear: Boolean;
    FBtBold: Boolean;
    FBtStrikeThrough: Boolean;
    FBtFontSize: Boolean;
    FBtUnderline: Boolean;
    FBtColor: Boolean;
    FBtFontName: Boolean;
    FBtItalic: Boolean;
    FOnChanged: TNotifyEvent;
    procedure SetBtBold(const Value: Boolean);
    procedure SetBtClear(const Value: Boolean);
    procedure SetBtColor(const Value: Boolean);
    procedure SetBtFontName(const Value: Boolean);
    procedure SetBtFontSize(const Value: Boolean);
    procedure SetBtItalic(const Value: Boolean);
    procedure SetBtStrikeThrough(const Value: Boolean);
    procedure SetBtUnderline(const Value: Boolean);
  protected
    procedure Change;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property BtFontName: Boolean read FBtFontName write SetBtFontName
      default True;
    property BtFontSize: Boolean read FBtFontSize write SetBtFontSize
      default True;
    property BtColor: Boolean read FBtColor write SetBtColor default True;
    property BtBold: Boolean read FBtBold write SetBtBold default True;
    property BtItalic: Boolean read FBtItalic write SetBtItalic default True;
    property BtUnderline: Boolean read FBtUnderline write SetBtUnderline
      default True;
    property BtStrikeThrough: Boolean read FBtStrikeThrough
      write SetBtStrikeThrough default True;
    property BtClear: Boolean read FBtClear write SetBtClear default True;
  end;

  TTMSFNCWXHTMLMemoParagraphButtons = class(TPersistent)
  private
    FBtOList: Boolean;
    FBtParagraph: Boolean;
    FBtUList: Boolean;
    FBtStyle: Boolean;
    FOnChanged: TNotifyEvent;
    procedure SetBtOList(const Value: Boolean);
    procedure SetBtParagraph(const Value: Boolean);
    procedure SetBtStyle(const Value: Boolean);
    procedure SetBtUList(const Value: Boolean);
  protected
    procedure Change;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property BtStyle: Boolean read FBtStyle write SetBtStyle default True;
    property BtOList: Boolean read FBtOList write SetBtOList default True;
    property BtUList: Boolean read FBtUList write SetBtUList default True;
    property BtParagraph: Boolean read FBtParagraph write SetBtParagraph
      default True;
  end;

  TTMSFNCWXHTMLMemoMiscButtons = class(TPersistent)
  private
    FBtUndo: Boolean;
    FBtRedo: Boolean;
    FOnChanged: TNotifyEvent;
    FBtCodeView: Boolean;
    procedure SetBtRedo(const Value: Boolean);
    procedure SetBtUndo(const Value: Boolean);
    procedure SetBtCodeView(const Value: Boolean);
  protected
    procedure Change;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property BtUndo: Boolean read FBtUndo write SetBtUndo default True;
    property BtRedo: Boolean read FBtRedo write SetBtRedo default True;
    property BtCodeView: Boolean read FBtCodeView write SetBtCodeView default False;
  end;

  TTMSFNCWXHTMLMemoButtons = class(TPersistent)
  private
    FMisc: TTMSFNCWXHTMLMemoMiscButtons;
    FFontStyle: TTMSFNCWXHTMLMemoFontButtons;
    FParagraph: TTMSFNCWXHTMLMemoParagraphButtons;
    FInsert: TTMSFNCWXHTMLMemoInsertButtons;
    FOnChanged: TNotifyEvent;
    procedure SetFontStyle(const Value: TTMSFNCWXHTMLMemoFontButtons);
    procedure SetInsert(const Value: TTMSFNCWXHTMLMemoInsertButtons);
    procedure SetMisc(const Value: TTMSFNCWXHTMLMemoMiscButtons);
    procedure SetParagraph(const Value: TTMSFNCWXHTMLMemoParagraphButtons);
  protected
    procedure Change;
    procedure FontStyleChanged(Sender: TObject);
    procedure InsertChanged(Sender: TObject);
    procedure ParagraphChanged(Sender: TObject);
    procedure MiscChanged(Sender: TObject);
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property FontStyle: TTMSFNCWXHTMLMemoFontButtons read FFontStyle write SetFontStyle;
    property Insert: TTMSFNCWXHTMLMemoInsertButtons read FInsert write SetInsert;
    property Paragraph: TTMSFNCWXHTMLMemoParagraphButtons read FParagraph write SetParagraph;
    property Misc: TTMSFNCWXHTMLMemoMiscButtons read FMisc write SetMisc;
  end;

  TTMSFNCWXHTMLMemoToolbar = class(TPersistent)
  private
    FVisible: Boolean;
    FButtons: TTMSFNCWXHTMLMemoButtons;
    FOnChanged: TNotifyEvent;
    procedure SetButtons(const Value: TTMSFNCWXHTMLMemoButtons);
    procedure SetVisible(const Value: Boolean);
  protected
    procedure Change;
    procedure ButtonsChanged(Sender: TObject);
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  published
    property Buttons: TTMSFNCWXHTMLMemoButtons read FButtons write SetButtons;
    property Visible: Boolean read FVisible write SetVisible default True;
  end;

  TTMSFNCWXHTMLMemoFontSizeUnit = (mfuDefault, mfuPx, mfuPt);

  TTMSFNCWXCustomHTMLMemo = class(TTMSFNCCustomWEBControl)
  private
    FDefaultFontName: string;
    FEditorInitialized: Boolean;
    FUpdateHTMLContent: Boolean;
    FToolbar: TTMSFNCWXHTMLMemoToolbar;
    FOnInit: TNotifyEvent;
    FOnContentChange: TTMSFNCWXHTMLMemoChangeContent;
    FOnFocus: TNotifyEvent;
    FLibraryLocation: TTMSFNCWXLibraryLocation;
    FHTML: TStrings;
    FOnStyleTextUpdate: TTMSFNCWXHTMLMemoStyleTextUpdate;
    FPlainText: string;
    FModified: Boolean;
    FCustomFonts: TStrings;
    FCustomFontSizes: TStrings;
    FUpdateCount: Integer;
    FReadOnly: Boolean;
    FFontSizeUnit: TTMSFNCWXHTMLMemoFontSizeUnit;
    procedure SetToolbar(const Value: TTMSFNCWXHTMLMemoToolbar);
    procedure SetLibraryLocation(const Value: TTMSFNCWXLibraryLocation);
    procedure SetHTML(const Value: TStrings);
    procedure SetModified(const Value: Boolean);
    procedure SetCustomFonts(const Value: TStrings);
    procedure SetCustomFontSizes(const Value: TStrings);
    procedure SetReadOnly(const Value: Boolean);
    function GetAllowDrop: Boolean;
    procedure SetAllowDrop(const Value: Boolean);
    procedure SetFontSizeUnit(const Value: TTMSFNCWXHTMLMemoFontSizeUnit);
  protected
    {$IFDEF WEBLIB}
    procedure SetFocus; override;
    {$ENDIF}
    function GetDocURL: string; override;
    procedure DoControlInitialized; override;
    procedure InitializeHTML; override;
    function GetCustomCSS: string; override;
    function GetCustomFunctions: string; override;
    {$IFDEF WEBLIB}
    function GetWaitInitVariables: string; override;
    {$ENDIF}
    function GetWaitInitCondition: string; override;
    procedure LoadLinks(AList: TTMSFNCCustomWEBControlLinksList); override;
    procedure CallCustomEvent(AEventData: TTMSFNCCustomWEBControlEventData); override;
    function GetCustomFontNames: string;
    function GetToolBarVisibleTag: string;
    function GetFontSizesList: string;
    function GetToolBarButtonsTag: string;
    procedure ExecuteSummernoteJavascript(AJSFunction: string;
      ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent = nil);
    procedure ExecuteSummernoteJavascriptParams(AJSFunction: string;
      ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent = nil);
    procedure ReInitializeEditor;
    procedure DoHandleInit(ACustomData: string); virtual;
    procedure DoHandleChangeEvent(ACustomData: string); virtual;
    procedure DoHandleFocusEvent; virtual;
    procedure DoHandleExitEvent; virtual;
    procedure DoHandleKeyDownEvent(ACustomData: string); virtual;
    procedure DoHandleKeyUpEvent(ACustomData: string); virtual;
    procedure DoHandleMouseDownEvent(ACustomData: string); virtual;
    procedure DoHandleMouseUpEvent(ACustomData: string); virtual;
    procedure DoHandleStyleTextUpdate(ACustomData: string); virtual;
    procedure ToolbarChanged(Sender: TObject);
    procedure TextLinesChanged(Sender: TObject);
    property AllowDrop: Boolean read GetAllowDrop write SetAllowDrop default True;
    property CustomFonts: TStrings read FCustomFonts write SetCustomFonts;
    property CustomFontSizes: TStrings read FCustomFontSizes write SetCustomFontSizes;
    property Toolbar: TTMSFNCWXHTMLMemoToolbar read FToolbar write SetToolbar;
    property LibraryLocation: TTMSFNCWXLibraryLocation read FLibraryLocation write SetLibraryLocation default llOnline;
    property HTML: TStrings read FHTML write SetHTML;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly default False;
    property PlainText: string read FPlainText;
    property FontSizeUnit: TTMSFNCWXHTMLMemoFontSizeUnit read FFontSizeUnit write SetFontSizeUnit default mfuDefault;
    property OnInit: TNotifyEvent read FOnInit write FOnInit;
    property OnFocus: TNotifyEvent read FOnFocus write FOnFocus;
    property OnContentChange: TTMSFNCWXHTMLMemoChangeContent read FOnContentChange write FOnContentChange;
    property OnStyleTextUpdate: TTMSFNCWXHTMLMemoStyleTextUpdate read FOnStyleTextUpdate write FOnStyleTextUpdate;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure ResetContent;
    procedure Undo;
    procedure Redo;
    procedure ToggleCodeview;
    procedure TextBold;
    procedure TextItalic;
    procedure TextUnderline;
    procedure TextStrikeThrough;
    procedure RemoveFormat;
    procedure FormatToParagraphs;
    procedure Indent;
    procedure Outdent;
    procedure InsertList(AOrdered: Boolean);
    procedure InsertParagraph;
    procedure JustifyLeft;
    procedure JustifyRight;
    procedure JustifyCenter;
    procedure JustifyFull;
    procedure SetLineHeight(ALineHeight: Integer);
    procedure SetFontSize(AFontSize: Integer);
    procedure InsertText(AText: string);
    procedure LoadHtmlContent(AHTMLText: string; AReplaceWithHTMLLinebreak: Boolean = true);
    procedure SaveAsHTMLFile(AFileName: string {$IFNDEF LCLWEBLIB}; AEncoding: TEncoding = Nil{$ENDIF});
    procedure SaveAsHTMLStream(AStream: TStream {$IFNDEF LCLWEBLIB}; AEncoding: TEncoding = Nil{$ENDIF});
    {$IFNDEF WEBLIB}
    procedure LoadFromHTMLFile(AFileName: string);
    {$ENDIF}
    procedure LoadFromHTMLStream(AStream: TStream);
    procedure SetFontName(AFontName: string);
    property Modified: Boolean read FModified write SetModified;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  [FNCJSLibReferenceAttribute('https://code.jquery.com/jquery-3.5.1.min.js;' +
    'https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js;' +
    'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js;' +
    'https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.js',
    'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css;'
    + 'https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.css')]
  {$ENDIF}
  TTMSFNCWXHTMLMemo = class(TTMSFNCWXCustomHTMLMemo)
  published
    property AllowDrop;
    property CustomFonts;
    property CustomFontSizes;
    property Toolbar;
    property LibraryLocation;
    property HTML;
    property FontSizeUnit;
    property PlainText;
    property ReadOnly;
    property OnInit;
    property OnFocus;
    property OnContentChange;
    property OnStyleTextUpdate;
  end;

var
  TMSFNCWXHTMLMemo: TTMSFNCWXHTMLMemo;

implementation

{$R TMSFNCWXHTMLMemo.res}

{ TTMSFNCWXHTMLMemo }

function TTMSFNCWXCustomHTMLMemo.GetAllowDrop: Boolean;
begin
  Result := Settings.AllowExternalDrop;
end;

function TTMSFNCWXCustomHTMLMemo.GetCustomCSS: string;
begin
  Result := '';
  {$IFNDEF WEBLIB}
  if not IsDesigning and (FLibraryLocation = llOffline) then
  begin
    Result := LoadResourceFile('BOOTSTRAP_CSS') + LB;
    Result := Result + LoadResourceFile('SUMMERNOTE_CSS') + LB;
  end;
  Result := Result + SUMNTCSSHEIGHTEDITOR;
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := Result + LB + '.note-editable { height: 100% !important; }' + LB;
  Result := Result + LB + '.note-statusbar { display: none; }' + LB;
  {$ENDIF}
  Result := Result + LB + '.note-btn.btn.btn-default.btn-sm.dropdown-toggle { height: 30px; }' + LB;
  Result := Result + LB + '.note-btn-group.note-add { display: inline-block; }' + LB;
  Result := Result + LB + '.note-btn-group.note-delete { display: inline-block; }' + LB;
end;

function TTMSFNCWXCustomHTMLMemo.GetCustomFontNames: string;
var
  I: Integer;
begin
  Result := '';
  if FCustomFonts.Count > 0 then
  begin
    Result := 'fontNames: [';
    Result := Result + '"' + FCustomFonts[0] + '"';
    for I := 1 to FCustomFonts.Count - 1 do
      Result := Result + ', "' + FCustomFonts[I] + '"';
    Result := Result + '],';
  end;
end;

function TTMSFNCWXCustomHTMLMemo.GetCustomFunctions: string;
var
  JSText: string;
begin
  JSText := 'function ' + GetControlID + 'initializeEditor(){' + LB +
    'window.$(''#' + GetControlID + ''').summernote({' + LB +
    ReplaceStr(CALLBACKSEVENTS, DATA_OBJECT, GetDefaultEventDataObject) + LB +
    DISABLERESIZEEDITOR + LB + GetFontSizesList + LB + GetToolBarVisibleTag +
    LB + GetCustomFontNames + LB {$IFNDEF WEBLIB}+ FOCUSTRUE + LB{$ENDIF} + '});}' + LB +
    SUMNTJSSELECTEDTEXT + LB + SUMNTJSDEFAULTFONT + LB + SUMNTJSFIRSTFONT;

  JSText := ReplaceStr(JSText, JSHASHTAGSUMMERNOTEID, GetControlID);
  Result := JSText;
end;

function TTMSFNCWXCustomHTMLMemo.GetDocURL: string;
begin
  Result := 'https://download.tmssoftware.com/doc/tmsfncwxpack/components/ttmsfncwxhtmlmemo/';
end;

function TTMSFNCWXCustomHTMLMemo.GetFontSizesList: string;
var
  I: Integer;
begin
  if FCustomFontSizes.Count > 0 then
  begin
    Result := 'fontSizes: [';
    Result := Result + '"' + FCustomFontSizes[0] + '"';
    for I := 1 to FCustomFontSizes.Count - 1 do
      Result := Result + ', "' + FCustomFontSizes[I] + '"';
    Result := '],';
  end
  else
    Result := 'fontSizes: ["8", "9", "10", "11", "12", "14", "16", "18", "24", "36"],';
end;

function TTMSFNCWXCustomHTMLMemo.GetToolBarButtonsTag: string;
begin
  Result := 'toolbar: [' + LB;
  if (FToolbar.FButtons.FFontStyle.FBtFontName) or
    (FToolbar.FButtons.FFontStyle.FBtFontSize) or
    (FToolbar.FButtons.FFontStyle.FBtColor) or
    (FToolbar.FButtons.FFontStyle.FBtBold) or
    (FToolbar.FButtons.FFontStyle.FBtItalic) or
    (FToolbar.FButtons.FFontStyle.FBtUnderline) or
    (FToolbar.FButtons.FFontStyle.FBtStrikeThrough) or
    (FToolbar.FButtons.FFontStyle.FBtClear) then
  begin
    Result := Result + '[''font'', [';
    if FToolbar.FButtons.FFontStyle.FBtFontName then
      Result := Result + '''fontname'',';
    if FToolbar.FButtons.FFontStyle.FBtFontSize then
      Result := Result + '''fontsize'',';
    if FToolbar.FButtons.FFontStyle.FBtColor then
      Result := Result + '''color'',';
    if FToolbar.FButtons.FFontStyle.FBtBold then
      Result := Result + '''bold'',';
    if FToolbar.FButtons.FFontStyle.FBtItalic then
      Result := Result + '''italic'',';
    if FToolbar.FButtons.FFontStyle.FBtUnderline then
      Result := Result + '''underline'',';
    if FToolbar.FButtons.FFontStyle.FBtStrikeThrough then
      Result := Result + '''strikethrough'',';
    if FToolbar.FButtons.FFontStyle.FBtClear then
      Result := Result + '''clear''';
    if Copy(Result, Length(Result), 1) = ',' then
      Delete(Result, Length(Result), 1);
    Result := Result + ']],' + LB;
  end;
  if (FToolbar.FButtons.FParagraph.FBtStyle) or
    (FToolbar.FButtons.FParagraph.FBtOList) or
    (FToolbar.FButtons.FParagraph.FBtUList) or
    (FToolbar.FButtons.FParagraph.FBtParagraph) then
  begin
    Result := Result + '[''para'', [';
    if FToolbar.FButtons.FParagraph.FBtStyle then
      Result := Result + '''style'',';
    if FToolbar.FButtons.FParagraph.FBtUList then
      Result := Result + '''ul'',';
    if FToolbar.FButtons.FParagraph.FBtOList then
      Result := Result + '''ol'',';
    if FToolbar.FButtons.FParagraph.FBtParagraph then
      Result := Result + '''paragraph''';
    if Copy(Result, Length(Result), 1) = ',' then
      Delete(Result, Length(Result), 1);
    Result := Result + ']],' + LB;
  end;
  if (FToolbar.FButtons.FInsert.FBtPicture) or
    (FToolbar.FButtons.FInsert.FBtLink) or (FToolbar.FButtons.FInsert.FBtVideo)
    or (FToolbar.FButtons.FInsert.FBtTable) or (FToolbar.FButtons.FInsert.FBtHr)
  then
  begin
    Result := Result + '[''insert'', [';
    if FToolbar.FButtons.FInsert.FBtPicture then
      Result := Result + '''picture'',';
    if FToolbar.FButtons.FInsert.FBtLink then
      Result := Result + '''link'',';
    if FToolbar.FButtons.FInsert.FBtVideo then
      Result := Result + '''video'',';
    if FToolbar.FButtons.FInsert.FBtTable then
      Result := Result + '''table'',';
    if FToolbar.FButtons.FInsert.FBtHr then
      Result := Result + '''hr''';
    if Copy(Result, Length(Result), 1) = ',' then
      Delete(Result, Length(Result), 1);
    Result := Result + ']],' + LB;
  end;
  if (FToolbar.FButtons.FMisc.FBtUndo) or (FToolbar.FButtons.FMisc.FBtRedo) then
  begin
    Result := Result + '[''misc'', [';
    if FToolbar.FButtons.FMisc.FBtUndo then
      Result := Result + '''undo'',';
    if FToolbar.FButtons.FMisc.FBtRedo then
      Result := Result + '''redo'',';
    if FToolbar.FButtons.FMisc.FBtCodeView then
      Result := Result + '''codeview''';
    if Copy(Result, Length(Result), 1) = ',' then
      Delete(Result, Length(Result), 1);
    Result := Result + ']],' + LB;
  end;
  Result := Result + '],' + LB;
end;

function TTMSFNCWXCustomHTMLMemo.GetToolBarVisibleTag: string;
begin
  if not(FToolbar.FVisible) then
    Result := TOOLBARVISIBLEFALSE + LB
  else
    Result := GetToolBarButtonsTag;
end;

function TTMSFNCWXCustomHTMLMemo.GetWaitInitCondition: string;
begin
  {$IFDEF WEBLIB}
  Result := '!window.$ || !e || !e2 || !window.$(''#' + GetControlID + ''') || !window.$(''#' + GetControlID + ''').summernote';
  {$ENDIF}
  {$IFNDEF WEBLIB}
  Result := '!window.$ || !window.$(''#' + GetControlID + ''') || !window.$(''#' + GetControlID + ''').summernote';
  {$ENDIF}
end;

{$IFDEF WEBLIB}
function TTMSFNCWXCustomHTMLMemo.GetWaitInitVariables: string;
begin
  Result :=
    '  var e = document.getElementById("' + ElementID + '");' + LB +
    '  var e2 = document.getElementById("' + GetControlID + '");';
end;
{$ENDIF}

procedure TTMSFNCWXCustomHTMLMemo.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCWXCustomHTMLMemo.CallCustomEvent(AEventData: TTMSFNCCustomWEBControlEventData);
begin
  inherited;

  if AEventData.EventName <> '' then
  begin
    case IndexStr(AEventData.EventName, [ONINITEVENT, ONCHANGEEVENT,
      ONFOCUSVENT, ONBLUREVENT, ONKEYDOWNEVENT, ONKEYUPEVENT, ONMOUSEDOWNEVENT,
      ONMOUSEUPEVENT, ONSTYLETEXTUPDATEEVENT]) of
      0: DoHandleInit(AEventData.CustomData);
      1: DoHandleChangeEvent(AEventData.CustomData);
      2: DoHandleFocusEvent;
      3: DoHandleExitEvent;
      4: DoHandleKeyDownEvent(AEventData.CustomData);
      5: DoHandleKeyUpEvent(AEventData.CustomData);
      6: DoHandleMouseDownEvent(AEventData.CustomData);
      7: DoHandleMouseUpEvent(AEventData.CustomData);
      8: DoHandleStyleTextUpdate(AEventData.CustomData);
    end;
  end;
end;

constructor TTMSFNCWXCustomHTMLMemo.Create(AOwner: TComponent);
begin
  FFontSizeUnit := mfuDefault;
  FReadOnly := False;
  FDefaultFontName := '';
  FModified := False;
  FLibraryLocation := llOnline;
  FEditorInitialized := False;
  FUpdateHTMLContent := True;
  FToolbar := TTMSFNCWXHTMLMemoToolbar.Create;
  FToolbar.OnChanged := @ToolbarChanged;

  FCustomFonts := TStringList.Create;
  FCustomFontSizes := TStringList.Create;

  FPlainText := '';
  FHTML := TStringList.Create;
  TStringList(FHTML).OnChange := @TextLinesChanged;
  FHTML.linebreak := '';

  {$IFNDEF LCLLIB}
  {$IF COMPILERVERSION > 31}
  {$IFNDEF WEBLIB}
  FHTML.TrailingLineBreak := False;
  {$ENDIF}
  {$ENDIF}
  {$ENDIF}
  inherited;
end;

procedure TTMSFNCWXCustomHTMLMemo.TextLinesChanged(Sender: TObject);
begin
  if FEditorInitialized then
  begin
    if FUpdateHTMLContent then
      LoadHtmlContent(FHTML.Text)
    else
      FUpdateHTMLContent := True;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.InitializeHTML;
begin
  inherited;
  FEditorInitialized := True;
end;

procedure TTMSFNCWXCustomHTMLMemo.LoadLinks(AList: TTMSFNCCustomWEBControlLinksList);
begin
  inherited;
  {$IFNDEF WEBLIB}
  if not IsDesigning and (FLibraryLocation = llOffline) then
  begin
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '',
      LoadResourceFile('JQUERY_JS'), False, False));
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '',
      LoadResourceFile('POPPER_JS'), False, False));
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '',
      LoadResourceFile('BOOTSTRAP_JS'), False, False));
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '',
      LoadResourceFile('SUMMERNOTE_JS'), False, False));
  end
  else
  begin
  {$ENDIF}
    AList.Add(TTMSFNCCustomWEBControlLink.CreateLink('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css', '', 'stylesheet'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://code.jquery.com/jquery-3.5.1.min.js'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateLink('https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.css', '', 'stylesheet'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.js'));
  {$IFNDEF WEBLIB}
  end;
  {$ENDIF}
end;

procedure TTMSFNCWXCustomHTMLMemo.FormatToParagraphs;
begin
  ExecuteSummernoteJavascript(SUMNTJSFORMATPARA);
end;

procedure TTMSFNCWXCustomHTMLMemo.Indent;
begin
  ExecuteSummernoteJavascript(SUMNTJSINDENT);
end;

procedure TTMSFNCWXCustomHTMLMemo.InsertList(AOrdered: Boolean);
begin
  if AOrdered then
    ExecuteSummernoteJavascript(SUMNTJSINSERTOL)
  else
    ExecuteSummernoteJavascript(SUMNTJSINSERTUOL);
end;

procedure TTMSFNCWXCustomHTMLMemo.InsertParagraph;
begin
  ExecuteSummernoteJavascript(SUMNTJSINSERTPARA);
end;

procedure TTMSFNCWXCustomHTMLMemo.InsertText(AText: string);
var
  SummernoteJSFunction: string;
begin
  SummernoteJSFunction := Format(SUMNTJSINSERTTEXT, [AText]);
  ExecuteSummernoteJavascriptParams(SummernoteJSFunction);
end;

procedure TTMSFNCWXCustomHTMLMemo.JustifyCenter;
begin
  ExecuteSummernoteJavascript(SUMNTJSJUSTIFYCENTER);
end;

procedure TTMSFNCWXCustomHTMLMemo.JustifyFull;
begin
  ExecuteSummernoteJavascript(SUMNTJSJUSTIFYFULL);
end;

procedure TTMSFNCWXCustomHTMLMemo.JustifyLeft;
begin
  ExecuteSummernoteJavascript(SUMNTJSJUSTIFYLEFT);
end;

procedure TTMSFNCWXCustomHTMLMemo.JustifyRight;
begin
  ExecuteSummernoteJavascript(SUMNTJSJUSTIFYRIGHT);
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCWXCustomHTMLMemo.LoadFromHTMLFile(AFileName: string);
var
  sl: TStringList;
  tmp, str: string;
  i: Integer;
begin
  sl := TStringList.Create;
  try
    sl.LoadFromFile(AFileName);
    str := sl.Text;
    tmp := UpperCase(str);
    i := Pos('<HTML>', tmp);
    if i > 0 then
    begin
      i := Pos('<BODY>', tmp);
      Delete(str, 1, i + 7);

      tmp := UpperCase(str);
      i := Pos('</BODY>', tmp);
      Delete(str, i, Length(str) - i + 1);
    end;

    if str.EndsWith(#13#10) then
      Delete(str, Length(str) - 2, 2)
    else if str.EndsWith(#10) then
      Delete(str, Length(str) - 1, 1)
    else if str.EndsWith(#13) then
      Delete(str, Length(str) - 1, 1);

    FHTML.Text := str;
  finally
    sl.Free;
  end;
end;
{$ENDIF}

procedure TTMSFNCWXCustomHTMLMemo.LoadFromHTMLStream(AStream: TStream);
var
  sl: TStringList;
  str, tmp: string;
  i: Integer;
begin
  sl := TStringList.Create;
  try
    sl.LoadFromStream(AStream);

    str := sl.Text;
    tmp := UpperCase(str);
    i := Pos('<HTML>', tmp);
    if i > 0 then
    begin
      i := Pos('<BODY>', tmp);
      Delete(str, 1, i + 7);

      tmp := UpperCase(str);
      i := Pos('</BODY>', tmp);
      Delete(str, i, Length(str) - i);
    end;
    FHTML.Text := str;
  finally
    sl.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.LoadHtmlContent(AHTMLText: string; AReplaceWithHTMLLinebreak: Boolean);
var
  SummernoteJSFunction: string;
  txt: string;
  lb: string;
begin
  if AReplaceWithHTMLLinebreak then
    lb := '<br>'
  else
    lb := '';

  txt := StringReplace(AHTMLText, #13#10, lb, [rfReplaceAll]);
  txt := StringReplace(txt, #13, lb, [rfReplaceAll]);
  txt := StringReplace(txt, #10, lb, [rfReplaceAll]);
  txt := StringReplace(txt, '"', '\"', [rfReplaceAll]);
  SummernoteJSFunction := Format(SUMNTJSLOADHTML, [txt]);
  ExecuteSummernoteJavascriptParams(SummernoteJSFunction);
end;

procedure TTMSFNCWXCustomHTMLMemo.SetFontName(AFontName: string);
var
  SummernoteJSFunction: string;
begin
  SummernoteJSFunction := Format(SUMNTJSSETFONTNAME, [AFontName]);
  ExecuteSummernoteJavascriptParams(SummernoteJSFunction);
end;

procedure TTMSFNCWXCustomHTMLMemo.SetFontSize(AFontSize: Integer);
var
  SummernoteJSFunction: string;
begin
  SummernoteJSFunction := Format(SUMNTJSSETFONTSIZE, [AFontSize]);
  ExecuteSummernoteJavascriptParams(SummernoteJSFunction);
end;

procedure TTMSFNCWXCustomHTMLMemo.SetFontSizeUnit(
  const Value: TTMSFNCWXHTMLMemoFontSizeUnit);
begin
  if FFontSizeUnit <> Value then
  begin
    FFontSizeUnit := Value;
    if FEditorInitialized then
    begin
      case FFontSizeUnit of
        mfuPx: ExecuteSummernoteJavascriptParams('"fontSizeUnit", "px"');
        mfuPt: ExecuteSummernoteJavascriptParams('"fontSizeUnit", "pt"');
      end;
    end;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.Outdent;
begin
  ExecuteSummernoteJavascript(SUMNTJSOUTDENT);
end;

procedure TTMSFNCWXCustomHTMLMemo.Redo;
begin
  ExecuteSummernoteJavascript(SUMNTJSREDO);
end;

procedure TTMSFNCWXCustomHTMLMemo.RemoveFormat;
begin
  ExecuteSummernoteJavascript(SUMNTJSREMOVEFORMAT);
end;

procedure TTMSFNCWXCustomHTMLMemo.ResetContent;
begin
  ExecuteSummernoteJavascript(SUMNTJSRESET);
end;

procedure TTMSFNCWXCustomHTMLMemo.SetlineHeight(ALineHeight: Integer);
var
  SummernoteJSFunction: string;
begin
  SummernoteJSFunction := Format(SUMNTJSLINEHEIGHT, [ALineHeight]);
  ExecuteSummernoteJavascriptParams(SummernoteJSFunction);
end;

procedure TTMSFNCWXCustomHTMLMemo.SetModified(const Value: Boolean);
begin
  if FModified <> Value then
    FModified := Value;
end;

procedure TTMSFNCWXCustomHTMLMemo.SetReadOnly(const Value: Boolean);
begin
  if FReadOnly <> Value then
  begin
    FReadOnly := Value;
    if FEditorInitialized then
    begin
      if Value then
        ExecuteSummernoteJavascript('disable')
      else
        ExecuteSummernoteJavascript('enable');
    end;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.TextBold;
begin
  ExecuteSummernoteJavascript(SUMNTJSBOLD);
end;

procedure TTMSFNCWXCustomHTMLMemo.TextItalic;
begin
  ExecuteSummernoteJavascript(SUMNTJSITALIC);
end;

procedure TTMSFNCWXCustomHTMLMemo.TextStrikeThrough;
begin
  ExecuteSummernoteJavascript(SUMNTJSSTRIKETHROUGH);
end;

procedure TTMSFNCWXCustomHTMLMemo.TextUnderline;
begin
  ExecuteSummernoteJavascript(SUMNTJSUNDERLINE);
end;

procedure TTMSFNCWXCustomHTMLMemo.ToggleCodeview;
begin
  ExecuteSummernoteJavascript(SUMNTJSTGCODEVIEW);
end;

procedure TTMSFNCWXCustomHTMLMemo.ToolbarChanged(Sender: TObject);
begin
  if FEditorInitialized then
  begin
    {$IFNDEF WEBLIB}
    ReInitializeEditor;
    {$ENDIF}
    {$IFDEF WEBLIB}
    RemoveScripts;
    LoadScripts(True);
    LoadScripts(False);
    {$ENDIF}
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.Undo;
begin
  ExecuteSummernoteJavascript(SUMNTJSUNDO);
end;

procedure TTMSFNCWXCustomHTMLMemo.ReInitializeEditor;
begin
  if FUpdateCount > 0 then
    Exit;

  ReInitialize;
end;

destructor TTMSFNCWXCustomHTMLMemo.Destroy;
begin
  FToolbar.Free;
  FHTML.Free;
  FCustomFonts.Free;
  FCustomFontSizes.Free;
  inherited;
end;

procedure TTMSFNCWXCustomHTMLMemo.DoControlInitialized;
begin
  inherited;
  ExecuteJavaScript(GetControlID + 'initializeEditor()');
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleChangeEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    FUpdateHTMLContent := False;
    FHTML.Text := LResult.Items[0].Value;
    FPlainText := LResult.Items[1].Value;
    if Assigned(FOnContentChange) then
    begin
      FModified := True;
      FOnContentChange(Self, LResult.Items[0].Value,
        LResult.Items[1].Value);
    end;
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleExitEvent;
begin
  if Assigned(OnExit) then
    OnExit(Self);
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleFocusEvent;
begin
  if Assigned(FOnFocus) then
    FOnFocus(Self);
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleInit(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  firstFont: string;
  {$IFDEF WEBLIB}
  selections: TJSNodeList;
  Element: TJSHTMLElement;
  I: Integer;
  {$ENDIF}
begin
  {$IFDEF WEBLIB}
  selections := document.querySelectorAll('div.note-editor.note-frame.card');
  for I := 0 to selections.Length - 1 do
  begin
    Element := TJSHTMLElement(selections.item(I));
    Element.style.setproperty('height', '100%');
  end;

  selections := document.querySelectorAll('div.note-editing-area');
  for I := 0 to selections.Length - 1 do
  begin
    Element := TJSHTMLElement(selections.item(I));
    Element.style.setproperty('height', '100%');
  end;
  {$ELSE}
  ExecuteSummernoteJavascript(SUMNTJSTGFULLSCREEN);
  {$ENDIF}
  FDefaultFontName := ACustomData;

  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    FDefaultFontName := LResult.Items[0].Value;
    firstFont := LResult.Items[1].Value;
  finally
    js.Free;
  end;

  ExecuteSummernoteJavascriptParams('"fontName", "' + FDefaultFontName + '"');
  case FFontSizeUnit of
    mfuPx: ExecuteSummernoteJavascriptParams('"fontSizeUnit", "px"');
    mfuPt: ExecuteSummernoteJavascriptParams('"fontSizeUnit", "pt"');
  end;

  if FHTML.Text <> '' then
    LoadHtmlContent(FHTML.Text);
  if FReadOnly then
    ExecuteSummernoteJavascript('disable');
  if Assigned(FOnInit) then
    FOnInit(Self);
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleKeyDownEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Key: Word;
  Shift: TShiftState;
  {$IFDEF FMXLIB}
  KeyChar: Char;
  {$ENDIF}
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Key := StrToInt(LResult.Items[0].Value);
    {$IFDEF FMXLIB}
    KeyChar := LResult.Items[1].Value.Chars[0];
    {$ENDIF}
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[4].Value = 'false' then
      Exclude(Shift, ssCtrl);
    {$IFDEF FMXLIB}
    if Assigned(OnKeyDown) then
      OnKeyDown(Self, Key, KeyChar, Shift);
    {$ELSE}
    if Assigned(OnKeyDown) then
      OnKeyDown(Self, Key, Shift);
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleKeyUpEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Key: Word;
  Shift: TShiftState;
  {$IFDEF FMXLIB}
  KeyChar: Char;
  {$ENDIF}
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Key := StrToInt(LResult.Items[0].Value);
    {$IFDEF FMXLIB}
    KeyChar := LResult.Items[1].Value.Chars[0];
    {$ENDIF}
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[4].Value = 'false' then
      Exclude(Shift, ssCtrl);
    {$IFDEF FMXLIB}
    if Assigned(OnKeyUp) then
      OnKeyUp(Self, Key, KeyChar, Shift);
    {$ELSE}
    if Assigned(OnKeyUp) then
      OnKeyUp(Self, Key, Shift);
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleMouseDownEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Button: TMouseButton;
  Shift: TShiftState;
  X, Y: Single;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Button := TMouseButton.mbLeft;
    case StrToInt(LResult.Items[0].Value) of
      0: Button := TMouseButton.mbLeft;
      1: Button := TMouseButton.mbMiddle;
      2: Button := TMouseButton.mbRight;
    end;
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[1].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssCtrl);
    X := JSStringToFloat(LResult.Items[4].Value);
    Y := JSStringToFloat(LResult.Items[5].Value);
    {$IFDEF FMXLIB}
    if Assigned(OnMouseDown) then
      OnMouseDown(Self, Button, Shift, X, Y);
    {$ELSE}
    if Assigned(OnMouseDown) then
      OnMouseDown(Self, Button, Shift, Trunc(X), Trunc(Y));
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleMouseUpEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Button: TMouseButton;
  Shift: TShiftState;
  X, Y: Single;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Button := TMouseButton.mbLeft;
    case StrToInt(LResult.Items[0].Value) of
      0: Button := TMouseButton.mbLeft;
      1: Button := TMouseButton.mbMiddle;
      2: Button := TMouseButton.mbRight;
    end;
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[1].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssCtrl);
    X := JSStringToFloat(LResult.Items[4].Value);
    Y := JSStringToFloat(LResult.Items[5].Value);
    {$IFDEF FMXLIB}
    if Assigned(OnMouseUp) then
      OnMouseUp(Self, Button, Shift, X, Y);
    {$ELSE}
    if Assigned(OnMouseUp) then
      OnMouseUp(Self, Button, Shift, Trunc(X), Trunc(Y));
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.DoHandleStyleTextUpdate(ACustomData: string);
var
  ForeColor: TTMSFNCGraphicsColor;
  BackColor: TTMSFNCGraphicsColor;
  LResult: TJSONArray;
  FontReceived: string;
  FontArray: {$IFDEF LCLLIB}specialize {$ENDIF}TArray<string>;
  ColorReceived: string;
  ColorArray: {$IFDEF LCLLIB}specialize {$ENDIF}TArray<string>;
  FontName: string;
  FontSize: Integer;
  IsBold: Boolean;
  IsItalic: Boolean;
  IsUnderline: Boolean;
  IsStrike: Boolean;
  js: TJSONValue;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    FontReceived := StringReplace(LResult.Items[4].Value, '"', '', [rfReplaceAll]);
    FontArray := FontReceived.Split([',']);
    if (High(FontArray) > 1) or (Length(FontArray) = 0) then
      FontName := ''
    else
      FontName := FontArray[0];

    FontSize := StrToIntDef(ReplaceStr(LResult.Items[5].Value, 'px', ''), 0);
    IsBold := LResult.Items[0].Value = 'true';
    IsItalic := LResult.Items[1].Value = 'true';
    IsUnderline := LResult.Items[2].Value = 'true';
    IsStrike := LResult.Items[3].Value = 'true';

    ColorReceived := StringReplace(LResult.Items[6].Value, 'rgb(', '', [rfReplaceAll]);
    ColorReceived := StringReplace(ColorReceived, 'rgba(', '', [rfReplaceAll]);
    ColorReceived := StringReplace(ColorReceived, ')', '', [rfReplaceAll]);
    ColorArray := ColorReceived.Split([',']);
    if Length(ColorArray) > 2 then
      ForeColor := MakeGraphicsColor(StrToInt(ColorArray[0]),
        StrToInt(ColorArray[1]), StrToInt(ColorArray[2]))
    else
      ForeColor := MakeGraphicsColor(255, 255, 255);

    ColorReceived := StringReplace(LResult.Items[7].Value, 'rgb(', '', [rfReplaceAll]);
    ColorReceived := StringReplace(ColorReceived, 'rgba(', '', [rfReplaceAll]);
    ColorReceived := StringReplace(ColorReceived, ')', '', [rfReplaceAll]);
    ColorArray := ColorReceived.Split([',']);
    if Length(ColorArray) > 2 then
      BackColor := MakeGraphicsColor(StrToInt(ColorArray[0]),
        StrToInt(ColorArray[1]), StrToInt(ColorArray[2]))
    else
      BackColor := MakeGraphicsColor(255, 255, 255);

    if Assigned(FOnStyleTextUpdate) then
      FOnStyleTextUpdate(Self, IsBold, IsItalic, IsUnderline, IsStrike, FontName,
        FontSize, ForeColor, BackColor);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
end;

procedure TTMSFNCWXCustomHTMLMemo.ExecuteSummernoteJavascript(AJSFunction: string;
  ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent);
var
  SummernoteJSFunction: string;
  JSText: string;
begin
  JSText := ReplaceStr(SUMNTJSCMD, JSHASHTAGSUMMERNOTEID, GetControlID);

  SummernoteJSFunction := Format(JSText, [AJSFunction]);
  if Assigned(ACompleteEvent) then
    ExecuteJavascript(SummernoteJSFunction, ACompleteEvent)
  else
    ExecuteJavascript(SummernoteJSFunction);
end;

procedure TTMSFNCWXCustomHTMLMemo.ExecuteSummernoteJavascriptParams
  (AJSFunction: string;
  ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent);
var
  SummernoteJSFunction: string;
  JSText: string;
begin
  JSText := ReplaceStr(SUMNTJSPARAMSCMD, JSHASHTAGSUMMERNOTEID, GetControlID);

  SummernoteJSFunction := Format(JSText, [AJSFunction]);
  if Assigned(ACompleteEvent) then
    ExecuteJavascript(SummernoteJSFunction, ACompleteEvent)
  else
    ExecuteJavascript(SummernoteJSFunction);
end;


procedure TTMSFNCWXCustomHTMLMemo.SaveAsHTMLFile(AFileName: string {$IFNDEF LCLWEBLIB};
  AEncoding: TEncoding = nil{$ENDIF});
const
  SummernoteBOM = #$FEFF;
var
  sl: TStringList;
  txt: string;
begin
  txt := StringReplace(FHTML.Text, SummernoteBOM, '', [rfReplaceAll]);

  sl := TStringList.Create;
  try
    sl.Text :=
      '<!DOCTYPE html>' + LB +
      '<html>' + LB +
      '<head>' + LB +
      '  <style>' + LB +
      '    body {' + LB +
      '      font-family: "' + FDefaultFontName + '";' + LB +
      '    }' + LB +
      '  </style>' + LB +
      '</head>' + LB +
      '<body>' + LB +
      txt + LB +
      '</body>' + LB +
      '</html>';

    {$IFNDEF LCLWEBLIB}
    if not Assigned(AEncoding) then
      AEncoding := TEncoding.Default;
    {$ENDIF}

    sl.SaveToFile(AFileName{$IFNDEF LCLWEBLIB}, AEncoding{$ENDIF});
  finally
    sl.Free;
  end;
end;

procedure TTMSFNCWXCustomHTMLMemo.SaveAsHTMLStream(AStream: TStream {$IFNDEF LCLWEBLIB};
  AEncoding: TEncoding = nil{$ENDIF});
const
  SummernoteBOM = #$FEFF;
var
  str, txt: string;
  ss: TStringStream;
begin
  txt := StringReplace(FHTML.Text, SummernoteBOM, '', [rfReplaceAll]);

  str := '<!DOCTYPE html>' + LB +
    '<html>' + LB +
    '<head>' + LB +
    '  <style>' + LB +
    '    body {' + LB +
    '      font-family: "' + FDefaultFontName + '";' + LB +
    '    }' + LB +
    '  </style>' + LB +
    '</head>' + LB +
    '<body>' + LB +
    txt + LB +
    '</body>' + LB +
    '</html>';

  {$IFNDEF LCLWEBLIB}
  if not Assigned(AEncoding) then
    AEncoding := TEncoding.Default;
  {$ENDIF}

  ss := TStringStream.Create(str {$IFNDEF LCLWEBLIB}, AEncoding{$ENDIF});
  try
    AStream.CopyFrom(ss, ss.Size);
  finally
    ss.Free;
  end;

end;

procedure TTMSFNCWXCustomHTMLMemo.SetAllowDrop(const Value: Boolean);
begin
  Settings.AllowExternalDrop := Value;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCWXCustomHTMLMemo.SetFocus;
begin
  if FEditorInitialized then
    ExecuteSummernoteJavascript('focus');
end;
{$ENDIF}

procedure TTMSFNCWXCustomHTMLMemo.SetCustomFonts(const Value: TStrings);
begin
  FCustomFonts.Assign(Value);
  if FEditorInitialized then
    ReInitializeEditor;
end;

procedure TTMSFNCWXCustomHTMLMemo.SetCustomFontSizes(const Value: TStrings);
begin
  FCustomFontSizes.Assign(Value);
  if FEditorInitialized then
    ReInitializeEditor;
end;

procedure TTMSFNCWXCustomHTMLMemo.SetHTML(const Value: TStrings);
begin
  Value.linebreak := '';
  FHTML.Assign(Value);
  if FUpdateHTMLContent then
    LoadHtmlContent(FHTML.Text)
  else
    FUpdateHTMLContent := True;
end;

procedure TTMSFNCWXCustomHTMLMemo.SetLibraryLocation(const Value
  : TTMSFNCWXLibraryLocation);
begin
  FLibraryLocation := Value;
end;

procedure TTMSFNCWXCustomHTMLMemo.SetToolbar(const Value: TTMSFNCWXHTMLMemoToolbar);
begin
  FToolbar.Assign(Value);
  if FEditorInitialized then
    ReInitializeEditor;
end;

{ TTMSFNCWXHTMLMemoParagraphButtons }

procedure TTMSFNCWXHTMLMemoParagraphButtons.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCWXHTMLMemoParagraphButtons) then
  begin
    FBtOList := (Source as TTMSFNCWXHTMLMemoParagraphButtons).BtOList;
    FBtParagraph := (Source as TTMSFNCWXHTMLMemoParagraphButtons).BtParagraph;
    FBtUList := (Source as TTMSFNCWXHTMLMemoParagraphButtons).BtUList;
    FBtStyle := (Source as TTMSFNCWXHTMLMemoParagraphButtons).BtStyle;
  end
  else
    inherited Assign(Source);
end;

procedure TTMSFNCWXHTMLMemoParagraphButtons.Change;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCWXHTMLMemoParagraphButtons.Create;
begin
  FBtOList := True;
  FBtParagraph := True;
  FBtUList := True;
  FBtStyle := True;
end;

procedure TTMSFNCWXHTMLMemoParagraphButtons.SetBtOList(const Value: Boolean);
begin
  if FBtOList <> Value then
  begin
    FBtOList := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoParagraphButtons.SetBtParagraph
  (const Value: Boolean);
begin
  if FBtParagraph <> Value then
  begin
    FBtParagraph := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoParagraphButtons.SetBtStyle(const Value: Boolean);
begin
  if FBtStyle <> Value then
  begin
    FBtStyle := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoParagraphButtons.SetBtUList(const Value: Boolean);
begin
  if FBtUList <> Value then
  begin
    FBtUList := Value;
    Change;
  end;
end;

{ TTMSFNCWXHTMLMemoFontButtons }

procedure TTMSFNCWXHTMLMemoFontButtons.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCWXHTMLMemoFontButtons) then
  begin
    FBtClear := (Source as TTMSFNCWXHTMLMemoFontButtons).BtClear;
    FBtBold := (Source as TTMSFNCWXHTMLMemoFontButtons).BtBold;
    FBtStrikeThrough := (Source as TTMSFNCWXHTMLMemoFontButtons).BtStrikeThrough;
    FBtFontSize := (Source as TTMSFNCWXHTMLMemoFontButtons).BtFontSize;
    FBtUnderline := (Source as TTMSFNCWXHTMLMemoFontButtons).BtUnderline;
    FBtColor := (Source as TTMSFNCWXHTMLMemoFontButtons).BtColor;
    FBtFontName := (Source as TTMSFNCWXHTMLMemoFontButtons).BtFontName;
    FBtItalic := (Source as TTMSFNCWXHTMLMemoFontButtons).BtItalic;
  end
  else
    inherited Assign(Source);
end;

procedure TTMSFNCWXHTMLMemoFontButtons.Change;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCWXHTMLMemoFontButtons.Create;
begin
  FBtClear := True;
  FBtBold := True;
  FBtStrikeThrough := True;
  FBtFontSize := True;
  FBtUnderline := True;
  FBtColor := True;
  FBtFontName := True;
  FBtItalic := True;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtBold(const Value: Boolean);
begin
  if FBtBold <> Value then
  begin
    FBtBold := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtClear(const Value: Boolean);
begin
  if FBtClear <> Value then
  begin
    FBtClear := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtColor(const Value: Boolean);
begin
  if FBtColor <> Value then
  begin
    FBtColor := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtFontName(const Value: Boolean);
begin
  if FBtFontName <> Value then
  begin
    FBtFontName := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtFontSize(const Value: Boolean);
begin
  if FBtFontSize <> Value then
  begin
    FBtFontSize := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtItalic(const Value: Boolean);
begin
  if FBtItalic <> Value then
  begin
    FBtItalic := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtStrikeThrough(const Value: Boolean);
begin
  if FBtStrikeThrough <> Value then
  begin
    FBtStrikeThrough := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoFontButtons.SetBtUnderline(const Value: Boolean);
begin
  if FBtUnderline <> Value then
  begin
    FBtUnderline := Value;
    Change;
  end;
end;

{ TTMSFNCWXHTMLMemoInsertButtons }

procedure TTMSFNCWXHTMLMemoInsertButtons.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCWXHTMLMemoInsertButtons) then
  begin
    FBtVideo := (Source as TTMSFNCWXHTMLMemoInsertButtons).BtVideo;
    FBtTable := (Source as TTMSFNCWXHTMLMemoInsertButtons).BtTable;
    FBtPicture := (Source as TTMSFNCWXHTMLMemoInsertButtons).BtPicture;
    FBtLink := (Source as TTMSFNCWXHTMLMemoInsertButtons).BtLink;
    FBtHr := (Source as TTMSFNCWXHTMLMemoInsertButtons).BtHr;
  end
  else
    inherited Assign(Source);
end;

procedure TTMSFNCWXHTMLMemoInsertButtons.Change;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCWXHTMLMemoInsertButtons.Create;
begin
  FBtVideo := True;
  FBtTable := True;
  FBtPicture := True;
  FBtLink := True;
  FBtHr := True;
end;

procedure TTMSFNCWXHTMLMemoInsertButtons.SetBtHr(const Value: Boolean);
begin
  if FBtHr <> Value then
  begin
    FBtHr := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoInsertButtons.SetBtLink(const Value: Boolean);
begin
  if FBtLink <> Value then
  begin
    FBtLink := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoInsertButtons.SetBtPicture(const Value: Boolean);
begin
  if FBtPicture <> Value then
  begin
    FBtPicture := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoInsertButtons.SetBtTable(const Value: Boolean);
begin
  if FBtTable <> Value then
  begin
    FBtTable := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoInsertButtons.SetBtVideo(const Value: Boolean);
begin
  if FBtVideo <> Value then
  begin
    FBtVideo := Value;
    Change;
  end;
end;

{ TTMSFNCWXHTMLMemoMiscButtons }

procedure TTMSFNCWXHTMLMemoMiscButtons.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCWXHTMLMemoMiscButtons) then
  begin
    FBtUndo := (Source as TTMSFNCWXHTMLMemoMiscButtons).BtUndo;
    FBtRedo := (Source as TTMSFNCWXHTMLMemoMiscButtons).BtRedo;
    FBtCodeView := (Source as TTMSFNCWXHTMLMemoMiscButtons).BtCodeView;
  end
  else
    inherited Assign(Source);
end;

procedure TTMSFNCWXHTMLMemoMiscButtons.Change;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCWXHTMLMemoMiscButtons.Create;
begin
  FBtUndo := True;
  FBtRedo := True;
  FBtCodeView := False;
end;

procedure TTMSFNCWXHTMLMemoMiscButtons.SetBtCodeView(const Value: Boolean);
begin
  if FBtCodeView <> Value then
  begin
    FBtCodeView := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoMiscButtons.SetBtRedo(const Value: Boolean);
begin
  if FBtRedo <> Value then
  begin
    FBtRedo := Value;
    Change;
  end;
end;

procedure TTMSFNCWXHTMLMemoMiscButtons.SetBtUndo(const Value: Boolean);
begin
  if FBtUndo <> Value then
  begin
    FBtUndo := Value;
    Change;
  end;
end;

{ TTMSFNCWXHTMLMemoButtons }

procedure TTMSFNCWXHTMLMemoButtons.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCWXHTMLMemoButtons) then
  begin
    FMisc.Assign((Source as TTMSFNCWXHTMLMemoButtons).Misc);
    FFontStyle.Assign((Source as TTMSFNCWXHTMLMemoButtons).FontStyle);
    FParagraph.Assign((Source as TTMSFNCWXHTMLMemoButtons).Paragraph);
    FInsert.Assign((Source as TTMSFNCWXHTMLMemoButtons).Insert);
  end
  else
    inherited Assign(Source);
end;

procedure TTMSFNCWXHTMLMemoButtons.Change;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCWXHTMLMemoButtons.Create;
begin
  inherited;
  FFontStyle := TTMSFNCWXHTMLMemoFontButtons.Create;
  FFontStyle.OnChanged := @FontStyleChanged;
  FInsert := TTMSFNCWXHTMLMemoInsertButtons.Create;
  FInsert.OnChanged := @InsertChanged;
  FParagraph := TTMSFNCWXHTMLMemoParagraphButtons.Create;
  FParagraph.OnChanged := @ParagraphChanged;
  FMisc := TTMSFNCWXHTMLMemoMiscButtons.Create;
  FMisc.OnChanged := @MiscChanged;
end;

destructor TTMSFNCWXHTMLMemoButtons.Destroy;
begin
  FMisc.Free;
  FParagraph.Free;
  FInsert.Free;
  FFontStyle.Free;
  inherited;
end;

procedure TTMSFNCWXHTMLMemoButtons.FontStyleChanged(Sender: TObject);
begin
  Change;
end;

procedure TTMSFNCWXHTMLMemoButtons.InsertChanged(Sender: TObject);
begin
  Change;
end;

procedure TTMSFNCWXHTMLMemoButtons.MiscChanged(Sender: TObject);
begin
  Change;
end;

procedure TTMSFNCWXHTMLMemoButtons.ParagraphChanged(Sender: TObject);
begin
  Change;
end;

procedure TTMSFNCWXHTMLMemoButtons.SetFontStyle(const Value: TTMSFNCWXHTMLMemoFontButtons);
begin
  FFontStyle.Assign(Value);
end;

procedure TTMSFNCWXHTMLMemoButtons.SetInsert(const Value: TTMSFNCWXHTMLMemoInsertButtons);
begin
  FInsert.Assign(Value);
end;

procedure TTMSFNCWXHTMLMemoButtons.SetMisc(const Value: TTMSFNCWXHTMLMemoMiscButtons);
begin
  FMisc.Assign(Value);
end;

procedure TTMSFNCWXHTMLMemoButtons.SetParagraph(const Value: TTMSFNCWXHTMLMemoParagraphButtons);
begin
  FParagraph.Assign(Value);
end;

{ TTMSFNCWXHTMLMemoToolbar }

procedure TTMSFNCWXHTMLMemoToolbar.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCWXHTMLMemoToolbar) then
  begin
    FButtons.Assign((Source as TTMSFNCWXHTMLMemoToolbar).FButtons);
    FVisible := (Source as TTMSFNCWXHTMLMemoToolbar).FVisible;
  end
  else
    inherited Assign(Source);
end;

procedure TTMSFNCWXHTMLMemoToolbar.ButtonsChanged(Sender: TObject);
begin
  Change;
end;

procedure TTMSFNCWXHTMLMemoToolbar.Change;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCWXHTMLMemoToolbar.Create;
begin
  inherited;
  FVisible := True;
  FButtons := TTMSFNCWXHTMLMemoButtons.Create;
  FButtons.OnChanged := @ButtonsChanged;
end;

destructor TTMSFNCWXHTMLMemoToolbar.Destroy;
begin
  FButtons.Free;
  inherited;
end;

procedure TTMSFNCWXHTMLMemoToolbar.SetButtons(const Value
  : TTMSFNCWXHTMLMemoButtons);
begin
  FButtons.Assign(Value);
end;

procedure TTMSFNCWXHTMLMemoToolbar.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Change;
  end;
end;

end.

unit uDeviceHistory;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, WEBLib.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCPanel, WEBLib.Buttons, Data.DB, WEBLib.DB, WEBLib.CDS,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGridDatabaseAdapter, Vcl.Imaging.GIFImg, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCGrid, VCL.TMSFNCMapsCommonTypes;

type
  TDeviceHistoryForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    ToolbarPanel: TPanel;
    DateFromLabel: TLabel;
    DateFromEdit: TDateTimePicker;
    DateTillLabel: TLabel;
    DateTillEdit: TDateTimePicker;
    SearchButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    DatasetTimer: TTimer;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    MyConnection: TClientConnection;
    MyGrid: TTMSFNCGrid;
    MyDatasetPKey: TStringField;
    MyDatasetTimestamp: TStringField;
    MyDatasetMessageCode: TStringField;
    MyDatasetGPS: TStringField;
    MyDatasetSAT: TStringField;
    MyDatasetGSM: TStringField;
    MyDatasetSpeed: TStringField;
    MyDatasetIntBatt: TStringField;
    MyDatasetExtBatt: TStringField;
    MyDatasetIOStatus: TStringField;
    MyDatasetLatitude: TStringField;
    MyDatasetLongitude: TStringField;
    GridClipboardButton: TSpeedButton;
    CellClipboardButton: TSpeedButton;
    MapButton: TSpeedButton;
    procedure SearchButtonClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure DatasetTimerTimer(Sender: TObject);
    procedure GridClipboardButtonClick(Sender: TObject);
    procedure CellClipboardButtonClick(Sender: TObject);
    [async]
    procedure MapButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure LoadList(); async;
  public
    { Public declarations }
    DevicePKey: String;
    DateFrom, DateTill: TDate;
  protected procedure LoadDFMValues; override; end;

var
  DeviceHistoryForm: TDeviceHistoryForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceHistoryMap;

procedure TDeviceHistoryForm.WebFormCreate(Sender: TObject);
begin
  SetGridStyle(MyGrid, False);
end;

procedure TDeviceHistoryForm.WebFormShow(Sender: TObject);
begin
  DateFromEdit.Date := DateFrom;
  DateTillEdit.Date := DateTill;
  LoadList();
end;

procedure TDeviceHistoryForm.SearchButtonClick(Sender: TObject);
begin
  DateFrom := DateFromEdit.Date;
  DateTill := DateTillEdit.Date;
  LoadList();
end;

procedure TDeviceHistoryForm.CellClipboardButtonClick(Sender: TObject);
begin
  CellToClipBoard(MyGrid);
end;

procedure TDeviceHistoryForm.GridClipboardButtonClick(Sender: TObject);
begin
  GridToClipBoard(MyGrid, True);
end;

procedure TDeviceHistoryForm.MapButtonClick(Sender: TObject);
var
  HistoryPKey: Int64;
  MyWebForm: TDeviceHistoryMapForm;
  MyModalResult: TModalResult;
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    if MyGrid.RowSelectionCount = 1 then begin
      HistoryPKey := StrToInt(MyGrid.Cells[0, MyGrid.SelectedRow[0]]);
      if MyDataset.Locate('PKey', HistoryPKey, []) then begin
        // Create Form
        MyWebForm := TDeviceHistoryMapForm.Create(Self);
        MyWebForm.Popup := True;
        MyWebForm.Border := fbNone;
        MyWebForm.Latitude := StrToFloatDot(MyDataset.FieldByName('Latitude').AsString);
        MyWebForm.Longitude := StrToFloatDot(MyDataset.FieldByName('Longitude').AsString);
        // Load Form
        await(TDeviceHistoryMapForm, MyWebForm.Load());
        try
          // Show Form
          MyModalResult := await(TModalResult, MyWebForm.Execute);
          if MyModalResult = mrOK then begin
          end;
        finally
          MyWebForm.Free;
        end;
      end;
    end;
  end;
end;

procedure TDeviceHistoryForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TDeviceHistoryForm.LoadList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
//WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_device_history_list.php?sessionid=' + SessionId +
      '&device_pkey=' + DevicePKey +
      '&date_from=' + IntToStr(DateTimeToUnix(DateFrom)) +
      '&date_till=' + IntToStr(DateTimeToUnix(DateTill));
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TDeviceHistoryForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  // Update Caption
  CaptionLabel.Caption :=
    'Device - History (' + IntToStr(Dataset.RecordCount) + ')';
  // Link Grid
  GridDatabaseAdapter.Active := True;
  GridDatabaseAdapter.LoadAllDataAndDisconnect;
  DatasetTimer.Enabled := True;
end;

procedure TDeviceHistoryForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  WebWaitMessage.Hide;
end;

procedure TDeviceHistoryForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  GridClipboardButton := TSpeedButton.Create(Self);
  CellClipboardButton := TSpeedButton.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  DateFromLabel := TLabel.Create(Self);
  DateTillLabel := TLabel.Create(Self);
  DateFromEdit := TDateTimePicker.Create(Self);
  DateTillEdit := TDateTimePicker.Create(Self);
  SearchButton := TSpeedButton.Create(Self);
  MapButton := TSpeedButton.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetPKey := TStringField.Create(Self);
  MyDatasetTimestamp := TStringField.Create(Self);
  MyDatasetMessageCode := TStringField.Create(Self);
  MyDatasetGPS := TStringField.Create(Self);
  MyDatasetSAT := TStringField.Create(Self);
  MyDatasetGSM := TStringField.Create(Self);
  MyDatasetSpeed := TStringField.Create(Self);
  MyDatasetIntBatt := TStringField.Create(Self);
  MyDatasetExtBatt := TStringField.Create(Self);
  MyDatasetIOStatus := TStringField.Create(Self);
  MyDatasetLatitude := TStringField.Create(Self);
  MyDatasetLongitude := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  MyConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  GridClipboardButton.BeforeLoadDFMValues;
  CellClipboardButton.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  DateFromLabel.BeforeLoadDFMValues;
  DateTillLabel.BeforeLoadDFMValues;
  DateFromEdit.BeforeLoadDFMValues;
  DateTillEdit.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  MapButton.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetPKey.BeforeLoadDFMValues;
  MyDatasetTimestamp.BeforeLoadDFMValues;
  MyDatasetMessageCode.BeforeLoadDFMValues;
  MyDatasetGPS.BeforeLoadDFMValues;
  MyDatasetSAT.BeforeLoadDFMValues;
  MyDatasetGSM.BeforeLoadDFMValues;
  MyDatasetSpeed.BeforeLoadDFMValues;
  MyDatasetIntBatt.BeforeLoadDFMValues;
  MyDatasetExtBatt.BeforeLoadDFMValues;
  MyDatasetIOStatus.BeforeLoadDFMValues;
  MyDatasetLatitude.BeforeLoadDFMValues;
  MyDatasetLongitude.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  MyConnection.BeforeLoadDFMValues;
  try
    Name := 'DeviceHistoryForm';
    Width := 1313;
    Height := 642;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1313;
    FormPanel.Height := 642;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1307;
    ContentPanel.Height := 599;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 550;
    StatusBarPanel.Width := 1307;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1187;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uDeviceHistory.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 1;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    GridClipboardButton.SetParentComponent(StatusBarPanel);
    GridClipboardButton.Name := 'GridClipboardButton';
    GridClipboardButton.AlignWithMargins := True;
    GridClipboardButton.Left := 20;
    GridClipboardButton.Top := 8;
    GridClipboardButton.Width := 125;
    GridClipboardButton.Height := 31;
    GridClipboardButton.Margins.Left := 0;
    GridClipboardButton.Margins.Top := 8;
    GridClipboardButton.Margins.Right := 8;
    GridClipboardButton.Margins.Bottom := 10;
    GridClipboardButton.Align := alLeft;
    GridClipboardButton.Caption := 'Copy Rows';
    GridClipboardButton.Color := clNone;
    GridClipboardButton.ElementClassName := 'MyWebButton';
    GridClipboardButton.Glyph.LoadFromFile('uDeviceHistory.StatusBarPanel.GridClipboardButton.Glyph.png');
    GridClipboardButton.HeightPercent := 100.000000000000000000;
    GridClipboardButton.TabOrder := 2;
    GridClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(GridClipboardButton, Self, 'OnClick', 'GridClipboardButtonClick');
    CellClipboardButton.SetParentComponent(StatusBarPanel);
    CellClipboardButton.Name := 'CellClipboardButton';
    CellClipboardButton.AlignWithMargins := True;
    CellClipboardButton.Left := 153;
    CellClipboardButton.Top := 8;
    CellClipboardButton.Width := 125;
    CellClipboardButton.Height := 31;
    CellClipboardButton.Margins.Left := 0;
    CellClipboardButton.Margins.Top := 8;
    CellClipboardButton.Margins.Right := 8;
    CellClipboardButton.Margins.Bottom := 10;
    CellClipboardButton.Align := alLeft;
    CellClipboardButton.Caption := 'Copy Cell';
    CellClipboardButton.Color := clNone;
    CellClipboardButton.ElementClassName := 'MyWebButton';
    CellClipboardButton.Glyph.LoadFromFile('uDeviceHistory.StatusBarPanel.CellClipboardButton.Glyph.png');
    CellClipboardButton.HeightPercent := 100.000000000000000000;
    CellClipboardButton.TabOrder := 3;
    CellClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CellClipboardButton, Self, 'OnClick', 'CellClipboardButtonClick');
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1307;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    DateFromLabel.SetParentComponent(ToolbarPanel);
    DateFromLabel.Name := 'DateFromLabel';
    DateFromLabel.AlignWithMargins := True;
    DateFromLabel.Left := 16;
    DateFromLabel.Top := 16;
    DateFromLabel.Width := 73;
    DateFromLabel.Height := 22;
    DateFromLabel.Margins.Left := 16;
    DateFromLabel.Margins.Top := 16;
    DateFromLabel.Margins.Bottom := 16;
    DateFromLabel.Align := alLeft;
    DateFromLabel.Caption := 'Date From';
    DateFromLabel.Font.Charset := ANSI_CHARSET;
    DateFromLabel.Font.Color := 9010544;
    DateFromLabel.Font.Height := -16;
    DateFromLabel.Font.Name := 'Segoe UI';
    DateFromLabel.Font.Style := [];
    DateFromLabel.HeightPercent := 100.000000000000000000;
    DateFromLabel.ParentFont := False;
    DateFromLabel.WidthPercent := 100.000000000000000000;
    DateTillLabel.SetParentComponent(ToolbarPanel);
    DateTillLabel.Name := 'DateTillLabel';
    DateTillLabel.AlignWithMargins := True;
    DateTillLabel.Left := 274;
    DateTillLabel.Top := 16;
    DateTillLabel.Width := 56;
    DateTillLabel.Height := 22;
    DateTillLabel.Margins.Left := 16;
    DateTillLabel.Margins.Top := 16;
    DateTillLabel.Margins.Bottom := 16;
    DateTillLabel.Align := alLeft;
    DateTillLabel.Caption := 'Date Till';
    DateTillLabel.Font.Charset := ANSI_CHARSET;
    DateTillLabel.Font.Color := 9010544;
    DateTillLabel.Font.Height := -16;
    DateTillLabel.Font.Name := 'Segoe UI';
    DateTillLabel.Font.Style := [];
    DateTillLabel.HeightPercent := 100.000000000000000000;
    DateTillLabel.ParentFont := False;
    DateTillLabel.WidthPercent := 100.000000000000000000;
    DateFromEdit.SetParentComponent(ToolbarPanel);
    DateFromEdit.Name := 'DateFromEdit';
    DateFromEdit.AlignWithMargins := True;
    DateFromEdit.Left := 112;
    DateFromEdit.Top := 8;
    DateFromEdit.Width := 143;
    DateFromEdit.Height := 38;
    DateFromEdit.Margins.Left := 20;
    DateFromEdit.Margins.Top := 8;
    DateFromEdit.Margins.Bottom := 8;
    DateFromEdit.Align := alLeft;
    DateFromEdit.BorderStyle := bsSingle;
    DateFromEdit.ChildOrder := 2;
    DateFromEdit.Color := clWhite;
    DateFromEdit.Date := 44545.380967847220000000;
    DateFromEdit.Font.Charset := ANSI_CHARSET;
    DateFromEdit.Font.Color := clWindowText;
    DateFromEdit.Font.Height := -16;
    DateFromEdit.Font.Name := 'Segoe UI';
    DateFromEdit.Font.Style := [];
    DateFromEdit.ParentFont := False;
    DateFromEdit.Role := '';
    DateFromEdit.Text := '';
    DateTillEdit.SetParentComponent(ToolbarPanel);
    DateTillEdit.Name := 'DateTillEdit';
    DateTillEdit.AlignWithMargins := True;
    DateTillEdit.Left := 353;
    DateTillEdit.Top := 8;
    DateTillEdit.Width := 143;
    DateTillEdit.Height := 38;
    DateTillEdit.Margins.Left := 20;
    DateTillEdit.Margins.Top := 8;
    DateTillEdit.Margins.Bottom := 8;
    DateTillEdit.Align := alLeft;
    DateTillEdit.BorderStyle := bsSingle;
    DateTillEdit.ChildOrder := 2;
    DateTillEdit.Color := clWhite;
    DateTillEdit.Date := 44545.380967847220000000;
    DateTillEdit.Font.Charset := ANSI_CHARSET;
    DateTillEdit.Font.Color := clWindowText;
    DateTillEdit.Font.Height := -16;
    DateTillEdit.Font.Name := 'Segoe UI';
    DateTillEdit.Font.Style := [];
    DateTillEdit.ParentFont := False;
    DateTillEdit.Role := '';
    DateTillEdit.Text := '';
    SearchButton.SetParentComponent(ToolbarPanel);
    SearchButton.Name := 'SearchButton';
    SearchButton.AlignWithMargins := True;
    SearchButton.Left := 509;
    SearchButton.Top := 8;
    SearchButton.Width := 109;
    SearchButton.Height := 36;
    SearchButton.Margins.Left := 10;
    SearchButton.Margins.Top := 8;
    SearchButton.Margins.Right := 5;
    SearchButton.Margins.Bottom := 10;
    SearchButton.Align := alLeft;
    SearchButton.Caption := 'Search';
    SearchButton.Color := clNone;
    SearchButton.ElementClassName := 'MyWebButton';
    SearchButton.Font.Charset := ANSI_CHARSET;
    SearchButton.Font.Color := clWindowText;
    SearchButton.Font.Height := -13;
    SearchButton.Font.Name := 'Segoe UI';
    SearchButton.Font.Style := [];
    SearchButton.Glyph.LoadFromFile('uDeviceHistory.ToolbarPanel.SearchButton.Glyph.png');
    SearchButton.HeightPercent := 100.000000000000000000;
    SearchButton.ParentFont := False;
    SearchButton.TabOrder := 2;
    SearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(SearchButton, Self, 'OnClick', 'SearchButtonClick');
    MapButton.SetParentComponent(ToolbarPanel);
    MapButton.Name := 'MapButton';
    MapButton.AlignWithMargins := True;
    MapButton.Left := 1187;
    MapButton.Top := 8;
    MapButton.Width := 100;
    MapButton.Height := 36;
    MapButton.Margins.Left := 0;
    MapButton.Margins.Top := 8;
    MapButton.Margins.Right := 20;
    MapButton.Margins.Bottom := 10;
    MapButton.Align := alRight;
    MapButton.Caption := 'Map';
    MapButton.Color := clNone;
    MapButton.ElementClassName := 'MyWebButton';
    MapButton.Glyph.LoadFromFile('uDeviceHistory.ToolbarPanel.MapButton.Glyph.png');
    MapButton.HeightPercent := 100.000000000000000000;
    MapButton.TabOrder := 3;
    MapButton.WidthPercent := 100.000000000000000000;
    SetEvent(MapButton, Self, 'OnClick', 'MapButtonClick');
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 54;
    MyGrid.Width := 1267;
    MyGrid.Height := 486;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 2;
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 12;
    MyGrid.RowCount := 1;
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.StretchColumn := 0;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Editing.Enabled := False;
    MyGrid.Options.Filtering.Rows := frAll;
    MyGrid.Options.Filtering.DropDown := True;
    MyGrid.Options.Filtering.DropDownWidth := 240;
    MyGrid.Options.Filtering.DropDownHeight := 240;
    MyGrid.Options.Filtering.MultiColumn := True;
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ColumnSizing := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.Sorting.IgnoreCase := True;
    MyGrid.Options.Sorting.IgnoreBlanks := True;
    MyGrid.Options.Sorting.Mode := gsmNormal;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    MyGrid.Adapter := GridDatabaseAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      SortFormat := ssNumeric;
      Width := 1.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 180.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -16;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 0;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1313;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 133;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Received Messages';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1284;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uDeviceHistory.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 457;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uDeviceHistory.WebWaitMessage.Picture.gif');
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 453;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := MyGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Timestamp';
      Header := 'Timestamp';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Message Code';
      Header := 'Code';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'GPS';
      Header := 'GPS';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'SAT';
      Header := 'SAT';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'GSM';
      Header := 'GSM';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Speed';
      Header := 'Speed';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Int. Batt.';
      Header := 'Int. Batt.';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Ext. Batt.';
      Header := 'Ext. Batt.';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'I/O Status';
      Header := 'I/O Status';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Latitude';
      Header := 'Latitude';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Longitude';
      Header := 'Longitude';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 283;
    DatasetTimer.Top := 456;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 456;
    MyDatasetPKey.SetParentComponent(MyDataset);
    MyDatasetPKey.Name := 'MyDatasetPKey';
    MyDatasetPKey.FieldName := 'PKey';
    MyDatasetPKey.Size := 256;
    MyDatasetTimestamp.SetParentComponent(MyDataset);
    MyDatasetTimestamp.Name := 'MyDatasetTimestamp';
    MyDatasetTimestamp.FieldName := 'Timestamp';
    MyDatasetTimestamp.Size := 256;
    MyDatasetMessageCode.SetParentComponent(MyDataset);
    MyDatasetMessageCode.Name := 'MyDatasetMessageCode';
    MyDatasetMessageCode.FieldName := 'Message Code';
    MyDatasetMessageCode.Size := 256;
    MyDatasetGPS.SetParentComponent(MyDataset);
    MyDatasetGPS.Name := 'MyDatasetGPS';
    MyDatasetGPS.FieldName := 'GPS';
    MyDatasetGPS.Size := 256;
    MyDatasetSAT.SetParentComponent(MyDataset);
    MyDatasetSAT.Name := 'MyDatasetSAT';
    MyDatasetSAT.FieldName := 'SAT';
    MyDatasetSAT.Size := 256;
    MyDatasetGSM.SetParentComponent(MyDataset);
    MyDatasetGSM.Name := 'MyDatasetGSM';
    MyDatasetGSM.FieldName := 'GSM';
    MyDatasetGSM.Size := 256;
    MyDatasetSpeed.SetParentComponent(MyDataset);
    MyDatasetSpeed.Name := 'MyDatasetSpeed';
    MyDatasetSpeed.FieldName := 'Speed';
    MyDatasetSpeed.Size := 256;
    MyDatasetIntBatt.SetParentComponent(MyDataset);
    MyDatasetIntBatt.Name := 'MyDatasetIntBatt';
    MyDatasetIntBatt.FieldName := 'Int. Batt.';
    MyDatasetIntBatt.Size := 256;
    MyDatasetExtBatt.SetParentComponent(MyDataset);
    MyDatasetExtBatt.Name := 'MyDatasetExtBatt';
    MyDatasetExtBatt.FieldName := 'Ext. Batt.';
    MyDatasetExtBatt.Size := 256;
    MyDatasetIOStatus.SetParentComponent(MyDataset);
    MyDatasetIOStatus.Name := 'MyDatasetIOStatus';
    MyDatasetIOStatus.FieldName := 'I/O Status';
    MyDatasetIOStatus.Size := 256;
    MyDatasetLatitude.SetParentComponent(MyDataset);
    MyDatasetLatitude.Name := 'MyDatasetLatitude';
    MyDatasetLatitude.FieldName := 'Latitude';
    MyDatasetLatitude.Size := 256;
    MyDatasetLongitude.SetParentComponent(MyDataset);
    MyDatasetLongitude.Name := 'MyDatasetLongitude';
    MyDatasetLongitude.FieldName := 'Longitude';
    MyDatasetLongitude.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 484;
    MyDataSource.Top := 456;
    MyConnection.SetParentComponent(Self);
    MyConnection.Name := 'MyConnection';
    MyConnection.Active := False;
    MyConnection.Delimiter := ';';
    MyConnection.URI := 'https://instatrack.eu:44321/php/default_device_history_list.php?sessionid=dccc67fb90a3110a917f9b364b8d5794&device_pkey=  865791030682973&date_from=1669680000&date_till=1669680000';
    MyConnection.Left := 199;
    MyConnection.Top := 454;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    GridClipboardButton.AfterLoadDFMValues;
    CellClipboardButton.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    DateFromLabel.AfterLoadDFMValues;
    DateTillLabel.AfterLoadDFMValues;
    DateFromEdit.AfterLoadDFMValues;
    DateTillEdit.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    MapButton.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetPKey.AfterLoadDFMValues;
    MyDatasetTimestamp.AfterLoadDFMValues;
    MyDatasetMessageCode.AfterLoadDFMValues;
    MyDatasetGPS.AfterLoadDFMValues;
    MyDatasetSAT.AfterLoadDFMValues;
    MyDatasetGSM.AfterLoadDFMValues;
    MyDatasetSpeed.AfterLoadDFMValues;
    MyDatasetIntBatt.AfterLoadDFMValues;
    MyDatasetExtBatt.AfterLoadDFMValues;
    MyDatasetIOStatus.AfterLoadDFMValues;
    MyDatasetLatitude.AfterLoadDFMValues;
    MyDatasetLongitude.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    MyConnection.AfterLoadDFMValues;
  end;
end;

end.
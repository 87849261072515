unit uHistoryMap;

interface

uses
  uHistoryMapPrint,
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  System.DateUtils, WEBLib.JSON,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.ExtCtrls,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, WEBLIB.REST,
  Data.DB, WEBLib.CDS, VCL.TMSFNCWebBrowser, VCL.TMSFNCMaps,
  VCL.TMSFNCGoogleMaps, VCL.TMSFNCMapsCommonTypes, WEBLib.WebCtrls,
  WEBLib.Buttons;

const
  panel_height_trip = 91;
  panel_height_park = 40;

type
  THistoryMapForm = class(TForm)
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebPanel3: TPanel;
    CaptionLabel: TLabel;
    WebImageControl1: TImageControl;
    ToolbarPanel: TPanel;
    ShowTimer: TTimer;
    DateEdit: TDateTimePicker;
    TillEdit: TDateTimePicker;
    FromEdit: TDateTimePicker;
    WebClientConnection1: TClientConnection;
    ParkDriveListDataset: TClientDataSet;
    ParkDriveListDatasetTripId: TStringField;
    ParkDriveListDatasetTripNo: TStringField;
    ParkDriveListDatasetDateTimeFrom: TStringField;
    ParkDriveListDatasetDateTimeTill: TStringField;
    ParkDriveListDatasetDistance: TStringField;
    ParkDriveListDatasetInterval: TStringField;
    ParkDriveListDatasetFromStreet: TStringField;
    ParkDriveListDatasetFromPostalCode: TStringField;
    ParkDriveListDatasetFromMunicipality: TStringField;
    ParkDriveListDatasetFromCountry: TStringField;
    ParkDriveListDatasetTillStreet: TStringField;
    ParkDriveListDatasetTillPostalCode: TStringField;
    ParkDriveListDatasetTillMunicipality: TStringField;
    ParkDriveListDatasetTillCountry: TStringField;
    ParkDriveMapsDataSet: TClientDataSet;
    ParkDriveMapsDataSetTripId: TStringField;
    ParkDriveMapsDataSetTimestamp: TStringField;
    ParkDriveMapsDataSetLatitude: TStringField;
    ParkDriveMapsDataSetLongitude: TStringField;
    ParkDriveMapsDataSetSpeed: TStringField;
    WebBrowserControl1: TBrowserControl;
    CloseButton: TSpeedButton;
    WebSpeedButton1: TSpeedButton;
    PrintDriveParkDButton: TSpeedButton;
    PrintDriveParkButton: TSpeedButton;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure ShowTimerTimer(Sender: TObject);
    [async]
    procedure WebImageControl1Click(Sender: TObject); async;
    procedure SearchButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    [async]
    procedure PrintDriveParkButtonClick(Sender: TObject); async;
    [async]
    procedure PrintDriveParkDButtonClick(Sender: TObject); async;
  private
    { Private declarations }
    procedure ShowHistory(MyDate: TDate; MyFrom, MyTill: TTime);
    [async]
    procedure GetLastTrip(); async;
  public
    { Public declarations }
    Mode: Integer;
    IMEI: Integer;
    AssetPKey: String;
    LicensePlate: String;
  protected procedure LoadDFMValues; override; end;

var
  HistoryMapForm: THistoryMapForm;

implementation

{$R *.dfm}

uses uCommon;


procedure THistoryMapForm.WebFormCreate(Sender: TObject);
begin
  Mode := -1;
  IMEI := -1;
end;

procedure THistoryMapForm.WebFormShow(Sender: TObject);
begin
  ShowTimer.Enabled := True;
end;

procedure THistoryMapForm.WebImageControl1Click(Sender: TObject);
begin
  ModalResult := mrClose;
end;

procedure THistoryMapForm.SearchButtonClick(Sender: TObject);
begin
  ShowHistory(DateEdit.Date, FromEdit.Time, TillEdit.Time);
end;

procedure THistoryMapForm.ShowTimerTimer(Sender: TObject);
var
  MyDate: TDate;
  MyTime: TTime;
  MyFrom: TTime;
  MyTill: TTime;
begin
  if (Mode > 0) and (IMEI > 0) then begin
    ShowTimer.Enabled := False;
    // Default Values
    MyDate := Date();
    MyTime := Time();
    case mode of
      1: begin
        CaptionLabel.Caption := 'History - Last Hour';
        MyFrom := IncHour(MyTime, -1);
        MyTill := MyTime;
        ShowHistory(MyDate, MyFrom, MyTill);
      end;
      2: begin
        CaptionLabel.Caption := 'History - Today';
        MyFrom := StrToTime('00:00:00');
        MyTill := StrToTime('23:59:59');
        ShowHistory(MyDate, MyFrom, MyTill);
      end;
      3: begin
        CaptionLabel.Caption := 'History - Yesterday';
        MyDate := IncDay(MyDate, -1);
        MyFrom := StrToTime('00:00:00');
        MyTill := StrToTime('23:59:59');
        ShowHistory(MyDate, MyFrom, MyTill);
      end;
      4: begin
        GetLastTrip();
      end;
      else begin
        CaptionLabel.Caption := 'History - Custom Date';
        MyFrom := StrToTime('00:00:00');
        MyTill := StrToTime('23:59:59');
      end;
    end;
    CaptionLabel.Caption :=
      CaptionLabel.Caption + ' : ' + LicensePlate;
    DateEdit.Date := MyDate;
    FromEdit.Time := MyFrom;
    TillEdit.Time := MyTill;
  end;
end;

procedure THistoryMapForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrClose;
end;

procedure THistoryMapForm.GetLastTrip();
var
  MyWebRequest: THTTPRequest;
  MyJSRequest: TJSXMLHttpRequest;
  json: String;
  js: TJSON;
  jo: TJSONObject;
  JSDate, JSTime: String;
  MyDate: TDate;
  MyFrom: TTime;
  MyTill: TTime;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_object_last_trip_v2.php?sessionid=' + SessionId +
      '&a=' + AssetPKey +
      '&q=' + IntToStr(IMEI);
    MyJSRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  json := string(MyJSRequest.response);
  js := TJSON.Create;
  try
    jo := TJSONObject(js.Parse(json));
    JSDate := jo.GetJSONValue('Date');
    JSTime := jo.GetJSONValue('Time');
    // Convert Date Time
//  MyDate := StrToDate(JSDate);
    MyDate := DateOf(ConvertDate(JSDate));
    MyFrom := StrToTime(JSTime);
    MyTill := StrToTime('23:59:59');
    // User Interface
    DateEdit.Date := MyDate;
    FromEdit.Time := MyFrom;
    TillEdit.Time := MyTill;
    // Get History
    ShowHistory(MyDate, MyFrom, MyTill);
  finally
    js.Free;
  end;
end;

procedure THistoryMapForm.PrintDriveParkButtonClick(Sender: TObject);
const
  ReportName = 'routes_asset';
var
  MyURL: String;
  MyModalResult: TModalResult;
begin
  HistoryMapPrintForm := THistoryMapPrintForm.Create(Self);
  HistoryMapPrintForm.Popup := True;
  HistoryMapPrintForm.Border := fbNone;
  await(THistoryMapPrintForm, HistoryMapPrintForm.Load());
  try
    // Initialize
    MyURL :=
      url_rpt + '?report=' + ReportName + '.fr3' +
      '&q=' + AssetPKey +
      '&f=' + IntToStr(DateTimeToUnix(DateEdit.Date + FromEdit.Time)) +
      '&t=' + IntToStr(DateTimeToUnix(DateEdit.Date + TillEdit.Time)) +
      '&sessionid=' + SessionId;
    HistoryMapPrintForm.WebBrowser.URL := MyURL;
    // Show Form
    MyModalResult := await(TModalResult, HistoryMapPrintForm.Execute);
    if MyModalResult = mrOK then begin
    end;
  finally
    HistoryMapPrintForm.Free;
  end;
end;

procedure THistoryMapForm.PrintDriveParkDButtonClick(Sender: TObject);
const
  ReportName = 'routes_activities_asset';
var
  MyModalResult: TModalResult;
begin
  HistoryMapPrintForm := THistoryMapPrintForm.Create(Self);
  HistoryMapPrintForm.Popup := True;
  HistoryMapPrintForm.Border := fbNone;
  await(THistoryMapPrintForm, HistoryMapPrintForm.Load());
  try
    // Initialize
    HistoryMapPrintForm.WebBrowser.URL :=
      url_rpt + '?report=' + ReportName + '.fr3' +
      '&q=' + AssetPKey +
      '&f=' + IntToStr(DateTimeToUnix(DateEdit.Date + FromEdit.Time)) +
      '&t=' + IntToStr(DateTimeToUnix(DateEdit.Date + TillEdit.Time)) +
      '&sessionid=' + SessionId;
//    '&timestamp=' + IntToStr(Random(2147483647));
    // Show Form
    MyModalResult := await(TModalResult, HistoryMapPrintForm.Execute);
    if MyModalResult = mrOK then begin
    end;
  finally
    HistoryMapPrintForm.Free;
  end;
end;

procedure THistoryMapForm.ShowHistory(MyDate: TDate; MyFrom, MyTill: TTime);
var
  MyURL: String;
begin
  MyURL :=
    url_www + 'history_map.html?sessionid=' + SessionId +
      '&a=' + AssetPKey +
      '&f=' + IntToStr(DateTimeToUnix(MyDate + MyFrom)) +
      '&t=' + IntToStr(DateTimeToUnix(MyDate + MyTill));
  WebBrowserControl1.URL := MyURL;
end;

procedure THistoryMapForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebBrowserControl1 := TBrowserControl.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  DateEdit := TDateTimePicker.Create(Self);
  TillEdit := TDateTimePicker.Create(Self);
  FromEdit := TDateTimePicker.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  WebSpeedButton1 := TSpeedButton.Create(Self);
  PrintDriveParkDButton := TSpeedButton.Create(Self);
  PrintDriveParkButton := TSpeedButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  ShowTimer := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);
  ParkDriveListDataset := TClientDataSet.Create(Self);
  ParkDriveListDatasetTripId := TStringField.Create(Self);
  ParkDriveListDatasetTripNo := TStringField.Create(Self);
  ParkDriveListDatasetDateTimeFrom := TStringField.Create(Self);
  ParkDriveListDatasetDateTimeTill := TStringField.Create(Self);
  ParkDriveListDatasetDistance := TStringField.Create(Self);
  ParkDriveListDatasetInterval := TStringField.Create(Self);
  ParkDriveListDatasetFromStreet := TStringField.Create(Self);
  ParkDriveListDatasetFromPostalCode := TStringField.Create(Self);
  ParkDriveListDatasetFromMunicipality := TStringField.Create(Self);
  ParkDriveListDatasetFromCountry := TStringField.Create(Self);
  ParkDriveListDatasetTillStreet := TStringField.Create(Self);
  ParkDriveListDatasetTillPostalCode := TStringField.Create(Self);
  ParkDriveListDatasetTillMunicipality := TStringField.Create(Self);
  ParkDriveListDatasetTillCountry := TStringField.Create(Self);
  ParkDriveMapsDataSet := TClientDataSet.Create(Self);
  ParkDriveMapsDataSetTripId := TStringField.Create(Self);
  ParkDriveMapsDataSetTimestamp := TStringField.Create(Self);
  ParkDriveMapsDataSetLatitude := TStringField.Create(Self);
  ParkDriveMapsDataSetLongitude := TStringField.Create(Self);
  ParkDriveMapsDataSetSpeed := TStringField.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebBrowserControl1.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  DateEdit.BeforeLoadDFMValues;
  TillEdit.BeforeLoadDFMValues;
  FromEdit.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  WebSpeedButton1.BeforeLoadDFMValues;
  PrintDriveParkDButton.BeforeLoadDFMValues;
  PrintDriveParkButton.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  ParkDriveListDataset.BeforeLoadDFMValues;
  ParkDriveListDatasetTripId.BeforeLoadDFMValues;
  ParkDriveListDatasetTripNo.BeforeLoadDFMValues;
  ParkDriveListDatasetDateTimeFrom.BeforeLoadDFMValues;
  ParkDriveListDatasetDateTimeTill.BeforeLoadDFMValues;
  ParkDriveListDatasetDistance.BeforeLoadDFMValues;
  ParkDriveListDatasetInterval.BeforeLoadDFMValues;
  ParkDriveListDatasetFromStreet.BeforeLoadDFMValues;
  ParkDriveListDatasetFromPostalCode.BeforeLoadDFMValues;
  ParkDriveListDatasetFromMunicipality.BeforeLoadDFMValues;
  ParkDriveListDatasetFromCountry.BeforeLoadDFMValues;
  ParkDriveListDatasetTillStreet.BeforeLoadDFMValues;
  ParkDriveListDatasetTillPostalCode.BeforeLoadDFMValues;
  ParkDriveListDatasetTillMunicipality.BeforeLoadDFMValues;
  ParkDriveListDatasetTillCountry.BeforeLoadDFMValues;
  ParkDriveMapsDataSet.BeforeLoadDFMValues;
  ParkDriveMapsDataSetTripId.BeforeLoadDFMValues;
  ParkDriveMapsDataSetTimestamp.BeforeLoadDFMValues;
  ParkDriveMapsDataSetLatitude.BeforeLoadDFMValues;
  ParkDriveMapsDataSetLongitude.BeforeLoadDFMValues;
  ParkDriveMapsDataSetSpeed.BeforeLoadDFMValues;
  try
    Name := 'HistoryMapForm';
    Width := 1360;
    Height := 768;
    Align := alClient;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1360;
    WebPanel1.Height := 768;
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := -1;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.Color := 14062646;
    WebPanel2.SetParentComponent(WebPanel1);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.AlignWithMargins := True;
    WebPanel2.Left := 3;
    WebPanel2.Top := 40;
    WebPanel2.Width := 1354;
    WebPanel2.Height := 725;
    WebPanel2.Margins.Top := 0;
    WebPanel2.Align := alClient;
    WebPanel2.BorderColor := -1;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 1;
    WebPanel2.Color := clWhite;
    WebBrowserControl1.SetParentComponent(WebPanel2);
    WebBrowserControl1.Name := 'WebBrowserControl1';
    WebBrowserControl1.Left := 0;
    WebBrowserControl1.Top := 50;
    WebBrowserControl1.Width := 1354;
    WebBrowserControl1.Height := 675;
    WebBrowserControl1.Align := alClient;
    WebBrowserControl1.BorderStyle := bsNone;
    WebBrowserControl1.ChildOrder := 4;
    WebBrowserControl1.Sandbox := [];
    ToolbarPanel.SetParentComponent(WebPanel2);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1354;
    ToolbarPanel.Height := 50;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderColor := clWhite;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.Color := clWhite;
    DateEdit.SetParentComponent(ToolbarPanel);
    DateEdit.Name := 'DateEdit';
    DateEdit.AlignWithMargins := True;
    DateEdit.Left := 10;
    DateEdit.Top := 8;
    DateEdit.Width := 143;
    DateEdit.Height := 34;
    DateEdit.Margins.Left := 10;
    DateEdit.Margins.Top := 8;
    DateEdit.Margins.Bottom := 8;
    DateEdit.Align := alLeft;
    DateEdit.BorderStyle := bsSingle;
    DateEdit.ChildOrder := 2;
    DateEdit.Color := clWhite;
    DateEdit.Date := 44545.380967847220000000;
    DateEdit.Font.Charset := ANSI_CHARSET;
    DateEdit.Font.Color := clWindowText;
    DateEdit.Font.Height := -16;
    DateEdit.Font.Name := 'Segoe UI';
    DateEdit.Font.Style := [];
    DateEdit.ParentFont := False;
    DateEdit.Role := '';
    DateEdit.Text := '';
    TillEdit.SetParentComponent(ToolbarPanel);
    TillEdit.Name := 'TillEdit';
    TillEdit.AlignWithMargins := True;
    TillEdit.Left := 278;
    TillEdit.Top := 8;
    TillEdit.Width := 113;
    TillEdit.Height := 34;
    TillEdit.Margins.Top := 8;
    TillEdit.Margins.Bottom := 8;
    TillEdit.Align := alLeft;
    TillEdit.BorderStyle := bsSingle;
    TillEdit.ChildOrder := 2;
    TillEdit.Color := clWhite;
    TillEdit.Date := 44545.380967847220000000;
    TillEdit.Font.Charset := ANSI_CHARSET;
    TillEdit.Font.Color := clWindowText;
    TillEdit.Font.Height := -16;
    TillEdit.Font.Name := 'Segoe UI';
    TillEdit.Font.Style := [];
    TillEdit.Kind := dtkTime;
    TillEdit.ParentFont := False;
    TillEdit.Role := '';
    TillEdit.ShowSeconds := False;
    TillEdit.Text := '';
    FromEdit.SetParentComponent(ToolbarPanel);
    FromEdit.Name := 'FromEdit';
    FromEdit.AlignWithMargins := True;
    FromEdit.Left := 159;
    FromEdit.Top := 8;
    FromEdit.Width := 113;
    FromEdit.Height := 34;
    FromEdit.Margins.Top := 8;
    FromEdit.Margins.Bottom := 8;
    FromEdit.Align := alLeft;
    FromEdit.BorderStyle := bsSingle;
    FromEdit.ChildOrder := 2;
    FromEdit.Color := clWhite;
    FromEdit.Date := 44545.380967847220000000;
    FromEdit.Font.Charset := ANSI_CHARSET;
    FromEdit.Font.Color := clWindowText;
    FromEdit.Font.Height := -16;
    FromEdit.Font.Name := 'Segoe UI';
    FromEdit.Font.Style := [];
    FromEdit.Kind := dtkTime;
    FromEdit.ParentFont := False;
    FromEdit.Role := '';
    FromEdit.ShowSeconds := False;
    FromEdit.Text := '';
    CloseButton.SetParentComponent(ToolbarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1225;
    CloseButton.Top := 8;
    CloseButton.Width := 109;
    CloseButton.Height := 32;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Font.Charset := ANSI_CHARSET;
    CloseButton.Font.Color := clWindowText;
    CloseButton.Font.Height := -13;
    CloseButton.Font.Name := 'Segoe UI';
    CloseButton.Font.Style := [];
    CloseButton.Glyph.LoadFromFile('uHistoryMap.ToolbarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.ParentFont := False;
    CloseButton.TabOrder := 3;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    WebSpeedButton1.SetParentComponent(ToolbarPanel);
    WebSpeedButton1.Name := 'WebSpeedButton1';
    WebSpeedButton1.AlignWithMargins := True;
    WebSpeedButton1.Left := 404;
    WebSpeedButton1.Top := 8;
    WebSpeedButton1.Width := 109;
    WebSpeedButton1.Height := 32;
    WebSpeedButton1.Margins.Left := 10;
    WebSpeedButton1.Margins.Top := 8;
    WebSpeedButton1.Margins.Right := 5;
    WebSpeedButton1.Margins.Bottom := 10;
    WebSpeedButton1.Align := alLeft;
    WebSpeedButton1.Caption := 'Search';
    WebSpeedButton1.Color := clNone;
    WebSpeedButton1.ElementClassName := 'MyWebButton';
    WebSpeedButton1.Font.Charset := ANSI_CHARSET;
    WebSpeedButton1.Font.Color := clWindowText;
    WebSpeedButton1.Font.Height := -13;
    WebSpeedButton1.Font.Name := 'Segoe UI';
    WebSpeedButton1.Font.Style := [];
    WebSpeedButton1.Glyph.LoadFromFile('uHistoryMap.ToolbarPanel.WebSpeedButton1.Glyph.png');
    WebSpeedButton1.HeightPercent := 100.000000000000000000;
    WebSpeedButton1.ParentFont := False;
    WebSpeedButton1.TabOrder := 4;
    WebSpeedButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton1, Self, 'OnClick', 'SearchButtonClick');
    PrintDriveParkDButton.SetParentComponent(ToolbarPanel);
    PrintDriveParkDButton.Name := 'PrintDriveParkDButton';
    PrintDriveParkDButton.AlignWithMargins := True;
    PrintDriveParkDButton.Left := 981;
    PrintDriveParkDButton.Top := 8;
    PrintDriveParkDButton.Width := 109;
    PrintDriveParkDButton.Height := 32;
    PrintDriveParkDButton.Margins.Left := 10;
    PrintDriveParkDButton.Margins.Top := 8;
    PrintDriveParkDButton.Margins.Right := 5;
    PrintDriveParkDButton.Margins.Bottom := 10;
    PrintDriveParkDButton.Align := alRight;
    PrintDriveParkDButton.Caption := 'Activity';
    PrintDriveParkDButton.Color := clNone;
    PrintDriveParkDButton.ElementClassName := 'MyWebButton';
    PrintDriveParkDButton.Font.Charset := ANSI_CHARSET;
    PrintDriveParkDButton.Font.Color := clWindowText;
    PrintDriveParkDButton.Font.Height := -13;
    PrintDriveParkDButton.Font.Name := 'Segoe UI';
    PrintDriveParkDButton.Font.Style := [];
    PrintDriveParkDButton.Glyph.LoadFromFile('uHistoryMap.ToolbarPanel.PrintDriveParkDButton.Glyph.png');
    PrintDriveParkDButton.HeightPercent := 100.000000000000000000;
    PrintDriveParkDButton.ParentFont := False;
    PrintDriveParkDButton.TabOrder := 5;
    PrintDriveParkDButton.WidthPercent := 100.000000000000000000;
    SetEvent(PrintDriveParkDButton, Self, 'OnClick', 'PrintDriveParkDButtonClick');
    PrintDriveParkButton.SetParentComponent(ToolbarPanel);
    PrintDriveParkButton.Name := 'PrintDriveParkButton';
    PrintDriveParkButton.AlignWithMargins := True;
    PrintDriveParkButton.Left := 1105;
    PrintDriveParkButton.Top := 8;
    PrintDriveParkButton.Width := 105;
    PrintDriveParkButton.Height := 32;
    PrintDriveParkButton.Margins.Left := 10;
    PrintDriveParkButton.Margins.Top := 8;
    PrintDriveParkButton.Margins.Right := 5;
    PrintDriveParkButton.Margins.Bottom := 10;
    PrintDriveParkButton.Align := alRight;
    PrintDriveParkButton.Caption := 'Routes';
    PrintDriveParkButton.Color := clNone;
    PrintDriveParkButton.ElementClassName := 'MyWebButton';
    PrintDriveParkButton.Font.Charset := ANSI_CHARSET;
    PrintDriveParkButton.Font.Color := clWindowText;
    PrintDriveParkButton.Font.Height := -13;
    PrintDriveParkButton.Font.Name := 'Segoe UI';
    PrintDriveParkButton.Font.Style := [];
    PrintDriveParkButton.Glyph.LoadFromFile('uHistoryMap.ToolbarPanel.PrintDriveParkButton.Glyph.png');
    PrintDriveParkButton.HeightPercent := 100.000000000000000000;
    PrintDriveParkButton.ParentFont := False;
    PrintDriveParkButton.TabOrder := 6;
    PrintDriveParkButton.WidthPercent := 100.000000000000000000;
    SetEvent(PrintDriveParkButton, Self, 'OnClick', 'PrintDriveParkButtonClick');
    WebPanel3.SetParentComponent(WebPanel1);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 0;
    WebPanel3.Width := 1360;
    WebPanel3.Height := 40;
    WebPanel3.Align := alTop;
    WebPanel3.BorderColor := -1;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.ChildOrder := 2;
    WebPanel3.Color := 14062646;
    CaptionLabel.SetParentComponent(WebPanel3);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 50;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'History';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    WebImageControl1.SetParentComponent(WebPanel3);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.AlignWithMargins := True;
    WebImageControl1.Left := 1331;
    WebImageControl1.Top := 9;
    WebImageControl1.Width := 23;
    WebImageControl1.Height := 22;
    WebImageControl1.Margins.Top := 9;
    WebImageControl1.Margins.Right := 6;
    WebImageControl1.Margins.Bottom := 9;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alRight;
    WebImageControl1.ChildOrder := 1;
    SetEvent(WebImageControl1, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl1.Picture.LoadFromFile('uHistoryMap.WebPanel3.WebImageControl1.Picture.png');
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 944;
    ShowTimer.Top := 224;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.URI := 'http://instatrack.eu/php/default_drive_park_maps.php?sessionid=a671de8a4de4f6d7e7e01bc6ea629e20&q=862170010166211&f=1639526400&t=1639612799';
    WebClientConnection1.Left := 808;
    WebClientConnection1.Top := 112;
    ParkDriveListDataset.SetParentComponent(Self);
    ParkDriveListDataset.Name := 'ParkDriveListDataset';
    ParkDriveListDataset.Left := 944;
    ParkDriveListDataset.Top := 112;
    ParkDriveListDatasetTripId.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetTripId.Name := 'ParkDriveListDatasetTripId';
    ParkDriveListDatasetTripId.FieldName := 'TripId';
    ParkDriveListDatasetTripId.Size := 256;
    ParkDriveListDatasetTripNo.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetTripNo.Name := 'ParkDriveListDatasetTripNo';
    ParkDriveListDatasetTripNo.FieldName := 'TripNo';
    ParkDriveListDatasetTripNo.Size := 256;
    ParkDriveListDatasetDateTimeFrom.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetDateTimeFrom.Name := 'ParkDriveListDatasetDateTimeFrom';
    ParkDriveListDatasetDateTimeFrom.FieldName := 'DateTimeFrom';
    ParkDriveListDatasetDateTimeFrom.Size := 256;
    ParkDriveListDatasetDateTimeTill.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetDateTimeTill.Name := 'ParkDriveListDatasetDateTimeTill';
    ParkDriveListDatasetDateTimeTill.FieldName := 'DateTimeTill';
    ParkDriveListDatasetDateTimeTill.Size := 256;
    ParkDriveListDatasetDistance.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetDistance.Name := 'ParkDriveListDatasetDistance';
    ParkDriveListDatasetDistance.FieldName := 'Distance';
    ParkDriveListDatasetDistance.Size := 256;
    ParkDriveListDatasetInterval.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetInterval.Name := 'ParkDriveListDatasetInterval';
    ParkDriveListDatasetInterval.FieldName := 'Interval';
    ParkDriveListDatasetInterval.Size := 256;
    ParkDriveListDatasetFromStreet.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetFromStreet.Name := 'ParkDriveListDatasetFromStreet';
    ParkDriveListDatasetFromStreet.FieldName := 'FromStreet';
    ParkDriveListDatasetFromStreet.Size := 256;
    ParkDriveListDatasetFromPostalCode.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetFromPostalCode.Name := 'ParkDriveListDatasetFromPostalCode';
    ParkDriveListDatasetFromPostalCode.FieldName := 'FromPostalCode';
    ParkDriveListDatasetFromPostalCode.Size := 256;
    ParkDriveListDatasetFromMunicipality.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetFromMunicipality.Name := 'ParkDriveListDatasetFromMunicipality';
    ParkDriveListDatasetFromMunicipality.FieldName := 'FromMunicipality';
    ParkDriveListDatasetFromMunicipality.Size := 256;
    ParkDriveListDatasetFromCountry.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetFromCountry.Name := 'ParkDriveListDatasetFromCountry';
    ParkDriveListDatasetFromCountry.FieldName := 'FromCountry';
    ParkDriveListDatasetFromCountry.Size := 256;
    ParkDriveListDatasetTillStreet.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetTillStreet.Name := 'ParkDriveListDatasetTillStreet';
    ParkDriveListDatasetTillStreet.FieldName := 'TillStreet';
    ParkDriveListDatasetTillStreet.Size := 256;
    ParkDriveListDatasetTillPostalCode.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetTillPostalCode.Name := 'ParkDriveListDatasetTillPostalCode';
    ParkDriveListDatasetTillPostalCode.FieldName := 'TillPostalCode';
    ParkDriveListDatasetTillPostalCode.Size := 256;
    ParkDriveListDatasetTillMunicipality.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetTillMunicipality.Name := 'ParkDriveListDatasetTillMunicipality';
    ParkDriveListDatasetTillMunicipality.FieldName := 'TillMunicipality';
    ParkDriveListDatasetTillMunicipality.Size := 256;
    ParkDriveListDatasetTillCountry.SetParentComponent(ParkDriveListDataset);
    ParkDriveListDatasetTillCountry.Name := 'ParkDriveListDatasetTillCountry';
    ParkDriveListDatasetTillCountry.FieldName := 'TillCountry';
    ParkDriveListDatasetTillCountry.Size := 256;
    ParkDriveMapsDataSet.SetParentComponent(Self);
    ParkDriveMapsDataSet.Name := 'ParkDriveMapsDataSet';
    ParkDriveMapsDataSet.Left := 944;
    ParkDriveMapsDataSet.Top := 168;
    ParkDriveMapsDataSetTripId.SetParentComponent(ParkDriveMapsDataSet);
    ParkDriveMapsDataSetTripId.Name := 'ParkDriveMapsDataSetTripId';
    ParkDriveMapsDataSetTripId.FieldName := 'TripId';
    ParkDriveMapsDataSetTripId.Size := 256;
    ParkDriveMapsDataSetTimestamp.SetParentComponent(ParkDriveMapsDataSet);
    ParkDriveMapsDataSetTimestamp.Name := 'ParkDriveMapsDataSetTimestamp';
    ParkDriveMapsDataSetTimestamp.FieldName := 'Timestamp';
    ParkDriveMapsDataSetTimestamp.Size := 256;
    ParkDriveMapsDataSetLatitude.SetParentComponent(ParkDriveMapsDataSet);
    ParkDriveMapsDataSetLatitude.Name := 'ParkDriveMapsDataSetLatitude';
    ParkDriveMapsDataSetLatitude.FieldName := 'Latitude';
    ParkDriveMapsDataSetLatitude.Size := 256;
    ParkDriveMapsDataSetLongitude.SetParentComponent(ParkDriveMapsDataSet);
    ParkDriveMapsDataSetLongitude.Name := 'ParkDriveMapsDataSetLongitude';
    ParkDriveMapsDataSetLongitude.FieldName := 'Longitude';
    ParkDriveMapsDataSetLongitude.Size := 256;
    ParkDriveMapsDataSetSpeed.SetParentComponent(ParkDriveMapsDataSet);
    ParkDriveMapsDataSetSpeed.Name := 'ParkDriveMapsDataSetSpeed';
    ParkDriveMapsDataSetSpeed.FieldName := 'Speed';
    ParkDriveMapsDataSetSpeed.Size := 256;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebBrowserControl1.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    DateEdit.AfterLoadDFMValues;
    TillEdit.AfterLoadDFMValues;
    FromEdit.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    WebSpeedButton1.AfterLoadDFMValues;
    PrintDriveParkDButton.AfterLoadDFMValues;
    PrintDriveParkButton.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
    ParkDriveListDataset.AfterLoadDFMValues;
    ParkDriveListDatasetTripId.AfterLoadDFMValues;
    ParkDriveListDatasetTripNo.AfterLoadDFMValues;
    ParkDriveListDatasetDateTimeFrom.AfterLoadDFMValues;
    ParkDriveListDatasetDateTimeTill.AfterLoadDFMValues;
    ParkDriveListDatasetDistance.AfterLoadDFMValues;
    ParkDriveListDatasetInterval.AfterLoadDFMValues;
    ParkDriveListDatasetFromStreet.AfterLoadDFMValues;
    ParkDriveListDatasetFromPostalCode.AfterLoadDFMValues;
    ParkDriveListDatasetFromMunicipality.AfterLoadDFMValues;
    ParkDriveListDatasetFromCountry.AfterLoadDFMValues;
    ParkDriveListDatasetTillStreet.AfterLoadDFMValues;
    ParkDriveListDatasetTillPostalCode.AfterLoadDFMValues;
    ParkDriveListDatasetTillMunicipality.AfterLoadDFMValues;
    ParkDriveListDatasetTillCountry.AfterLoadDFMValues;
    ParkDriveMapsDataSet.AfterLoadDFMValues;
    ParkDriveMapsDataSetTripId.AfterLoadDFMValues;
    ParkDriveMapsDataSetTimestamp.AfterLoadDFMValues;
    ParkDriveMapsDataSetLatitude.AfterLoadDFMValues;
    ParkDriveMapsDataSetLongitude.AfterLoadDFMValues;
    ParkDriveMapsDataSetSpeed.AfterLoadDFMValues;
  end;
end;

end.

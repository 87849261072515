unit uAssetModelDocumentEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.JSON, WEBLib.Rest,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.WebCtrls, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.Buttons,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, Vcl.Imaging.GIFImg;

type
  TAssetModelDocumentEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    DocumentIDLabel: TLabel;
    DocumentIDEdit: TEdit;
    StatusBarPanel: TTMSFNCPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    FileNameEdit: TEdit;
    WebWaitMessage: TWaitMessage;
    MyMessageDlg: TMessageDlg;
    MyOpenFileDialog: TOpenDialog;
    OpenFileButton: TSpeedButton;
    FileNameLabel: TLabel;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure CloseButtonClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure DocumentIDEditChange(Sender: TObject);
    procedure OpenFileButtonClick(Sender: TObject);
    procedure MyOpenFileDialogChange(Sender: TObject);
    procedure MyOpenFileDialogGetFileAsBase64(Sender: TObject;
      AFileIndex: Integer; ABase64: string);
  private
    { Private declarations }
    RecordChanged: Boolean;
    FileBase64: String;
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    ModelPKey, PKey: Int64;
  protected procedure LoadDFMValues; override; end;

var
  AssetModelDocumentEditForm: TAssetModelDocumentEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetModelDocumentEditForm.WebFormCreate(Sender: TObject);
begin
//
end;

procedure TAssetModelDocumentEditForm.WebFormShow(Sender: TObject);
begin
  if PKey > 0 then begin
    WebWaitMessage.Show;
    await(LoadData());
    WebWaitMessage.Hide;
    RecordChanged := False;
  end else begin
    DocumentIDEdit.SetFocus;
    RecordChanged := False;
  end;
end;

procedure TAssetModelDocumentEditForm.CloseButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetModelDocumentEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_model_instruction_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      DocumentIDEdit.Text := MyJSONObject.GetJSONValue('ID');
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetModelDocumentEditForm.MyOpenFileDialogChange(Sender: TObject);
begin
  RecordChanged := True;
  FileNameEdit.Text := MyOpenFileDialog.Files[0].Name;
  MyOpenFileDialog.Files[0].GetFileAsBase64;
end;

procedure TAssetModelDocumentEditForm.MyOpenFileDialogGetFileAsBase64(
  Sender: TObject; AFileIndex: Integer; ABase64: string);
begin
  FileBase64 := ABase64;
end;

procedure TAssetModelDocumentEditForm.OpenFileButtonClick(Sender: TObject);
begin
  MyOpenFileDialog.Execute;
end;

procedure TAssetModelDocumentEditForm.DocumentIDEditChange(Sender: TObject);
begin
  RecordChanged := True;
end;

function TAssetModelDocumentEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Description
  if Result then begin
    if Trim(DocumentIDEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := '"ID" is a required field.';
    end;
  end;
end;

procedure TAssetModelDocumentEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

function TAssetModelDocumentEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"model": "' + IntToStr(ModelPKey) + '",' +
      '"id": "' + DocumentIDEdit.Text + '",' +
      '"content": "' + FileBase64 + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_model_document_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        Result := True;
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end;
  finally
    MyWebRequest.Free;
  end;
end;


procedure TAssetModelDocumentEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  DocumentIDLabel := TLabel.Create(Self);
  FileNameLabel := TLabel.Create(Self);
  DocumentIDEdit := TEdit.Create(Self);
  StatusBarPanel := TTMSFNCPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  OpenFileButton := TSpeedButton.Create(Self);
  FileNameEdit := TEdit.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  MyOpenFileDialog := TOpenDialog.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  DocumentIDLabel.BeforeLoadDFMValues;
  FileNameLabel.BeforeLoadDFMValues;
  DocumentIDEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  OpenFileButton.BeforeLoadDFMValues;
  FileNameEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  MyOpenFileDialog.BeforeLoadDFMValues;
  try
    Name := 'AssetModelDocumentEditForm';
    Width := 472;
    Height := 290;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 472;
    FormPanel.Height := 290;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 466;
    ContentPanel.Height := 247;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    DocumentIDLabel.SetParentComponent(ContentPanel);
    DocumentIDLabel.Name := 'DocumentIDLabel';
    DocumentIDLabel.Left := 16;
    DocumentIDLabel.Top := 15;
    DocumentIDLabel.Width := 15;
    DocumentIDLabel.Height := 21;
    DocumentIDLabel.Caption := 'ID';
    DocumentIDLabel.Font.Charset := ANSI_CHARSET;
    DocumentIDLabel.Font.Color := 9010544;
    DocumentIDLabel.Font.Height := -16;
    DocumentIDLabel.Font.Name := 'Segoe UI';
    DocumentIDLabel.Font.Style := [];
    DocumentIDLabel.HeightPercent := 100.000000000000000000;
    DocumentIDLabel.ParentFont := False;
    DocumentIDLabel.WidthPercent := 100.000000000000000000;
    FileNameLabel.SetParentComponent(ContentPanel);
    FileNameLabel.Name := 'FileNameLabel';
    FileNameLabel.Left := 16;
    FileNameLabel.Top := 98;
    FileNameLabel.Width := 28;
    FileNameLabel.Height := 21;
    FileNameLabel.Caption := 'FILE';
    FileNameLabel.Font.Charset := ANSI_CHARSET;
    FileNameLabel.Font.Color := 9010544;
    FileNameLabel.Font.Height := -16;
    FileNameLabel.Font.Name := 'Segoe UI';
    FileNameLabel.Font.Style := [];
    FileNameLabel.HeightPercent := 100.000000000000000000;
    FileNameLabel.ParentFont := False;
    FileNameLabel.WidthPercent := 100.000000000000000000;
    DocumentIDEdit.SetParentComponent(ContentPanel);
    DocumentIDEdit.Name := 'DocumentIDEdit';
    DocumentIDEdit.Left := 16;
    DocumentIDEdit.Top := 42;
    DocumentIDEdit.Width := 428;
    DocumentIDEdit.Height := 32;
    DocumentIDEdit.CharCase := wecUpperCase;
    DocumentIDEdit.ChildOrder := 1;
    DocumentIDEdit.ElementClassName := 'MyWebEdit';
    DocumentIDEdit.Font.Charset := ANSI_CHARSET;
    DocumentIDEdit.Font.Color := clFuchsia;
    DocumentIDEdit.Font.Height := -16;
    DocumentIDEdit.Font.Name := 'Segoe UI';
    DocumentIDEdit.Font.Style := [];
    DocumentIDEdit.HeightPercent := 100.000000000000000000;
    DocumentIDEdit.ParentFont := False;
    DocumentIDEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DocumentIDEdit, Self, 'OnChange', 'DocumentIDEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Tag := 1;
    StatusBarPanel.AlignWithMargins := True;
    StatusBarPanel.Left := 2;
    StatusBarPanel.Top := 193;
    StatusBarPanel.Width := 462;
    StatusBarPanel.Height := 52;
    StatusBarPanel.Margins.Left := 2;
    StatusBarPanel.Margins.Top := 2;
    StatusBarPanel.Margins.Right := 2;
    StatusBarPanel.Margins.Bottom := 2;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BevelInner := bvNone;
    StatusBarPanel.BevelOuter := bvNone;
    StatusBarPanel.Ctl3D := True;
    StatusBarPanel.ParentCtl3D := False;
    StatusBarPanel.ParentDoubleBuffered := False;
    StatusBarPanel.DoubleBuffered := True;
    StatusBarPanel.TabOrder := 1;
    StatusBarPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    StatusBarPanel.SectionsAppearance.Font.Color := clWindowText;
    StatusBarPanel.SectionsAppearance.Font.Height := -11;
    StatusBarPanel.SectionsAppearance.Font.Name := 'Tahoma';
    StatusBarPanel.SectionsAppearance.Font.Style := [];
    StatusBarPanel.Stroke.Kind := gskNone;
    StatusBarPanel.Header.Text := 'Header';
    StatusBarPanel.Header.Font.Charset := DEFAULT_CHARSET;
    StatusBarPanel.Header.Font.Color := clWindowText;
    StatusBarPanel.Header.Font.Height := -11;
    StatusBarPanel.Header.Font.Name := 'Tahoma';
    StatusBarPanel.Header.Font.Style := [];
    StatusBarPanel.Header.Visible := False;
    StatusBarPanel.Footer.Text := 'Footer';
    StatusBarPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    StatusBarPanel.Footer.Font.Color := clWindowText;
    StatusBarPanel.Footer.Font.Height := -11;
    StatusBarPanel.Footer.Font.Name := 'Tahoma';
    StatusBarPanel.Footer.Font.Style := [];
    StatusBarPanel.GlobalFont.Scale := 1.000000000000000000;
    StatusBarPanel.GlobalFont.Style := [];
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 227;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 34;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetModelDocumentEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 342;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 34;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetModelDocumentEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    OpenFileButton.SetParentComponent(StatusBarPanel);
    OpenFileButton.Name := 'OpenFileButton';
    OpenFileButton.AlignWithMargins := True;
    OpenFileButton.Left := 14;
    OpenFileButton.Top := 9;
    OpenFileButton.Width := 127;
    OpenFileButton.Height := 31;
    OpenFileButton.Margins.Left := 20;
    OpenFileButton.Margins.Top := 8;
    OpenFileButton.Margins.Right := 5;
    OpenFileButton.Margins.Bottom := 10;
    OpenFileButton.Caption := 'Choose &File';
    OpenFileButton.Color := clNone;
    OpenFileButton.ElementClassName := 'MyWebButton';
    OpenFileButton.HeightPercent := 100.000000000000000000;
    OpenFileButton.TabOrder := 2;
    OpenFileButton.WidthPercent := 100.000000000000000000;
    SetEvent(OpenFileButton, Self, 'OnClick', 'OpenFileButtonClick');
    FileNameEdit.SetParentComponent(ContentPanel);
    FileNameEdit.Name := 'FileNameEdit';
    FileNameEdit.Left := 16;
    FileNameEdit.Top := 125;
    FileNameEdit.Width := 428;
    FileNameEdit.Height := 34;
    FileNameEdit.ChildOrder := 1;
    FileNameEdit.ElementClassName := 'MyWebEdit';
    FileNameEdit.Font.Charset := ANSI_CHARSET;
    FileNameEdit.Font.Color := clWindowText;
    FileNameEdit.Font.Height := -16;
    FileNameEdit.Font.Name := 'Segoe UI';
    FileNameEdit.Font.Style := [];
    FileNameEdit.HeightPercent := 100.000000000000000000;
    FileNameEdit.ParentFont := False;
    FileNameEdit.ReadOnly := True;
    FileNameEdit.WidthPercent := 100.000000000000000000;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 472;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 173;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Model - Document';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 443;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseButtonClick');
    CloseImage.Picture.LoadFromFile('uAssetModelDocumentEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 253;
    WebWaitMessage.Top := 63;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAssetModelDocumentEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 333;
    MyMessageDlg.Top := 63;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    MyOpenFileDialog.SetParentComponent(Self);
    MyOpenFileDialog.Name := 'MyOpenFileDialog';
    MyOpenFileDialog.Accept := '.pdf';
    SetEvent(MyOpenFileDialog, Self, 'OnChange', 'MyOpenFileDialogChange');
    SetEvent(MyOpenFileDialog, Self, 'OnGetFileAsBase64', 'MyOpenFileDialogGetFileAsBase64');
    MyOpenFileDialog.Left := 129;
    MyOpenFileDialog.Top := 58;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    DocumentIDLabel.AfterLoadDFMValues;
    FileNameLabel.AfterLoadDFMValues;
    DocumentIDEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    OpenFileButton.AfterLoadDFMValues;
    FileNameEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    MyOpenFileDialog.AfterLoadDFMValues;
  end;
end;

end.
unit uReportEdit;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.ExtCtrls,
  WEBLib.REST, WEBLIB.JSON, WEBLib.RegularExpressions,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.Buttons,
  VCL.TMSFNCPageControl, VCL.TMSFNCCustomControl, VCL.TMSFNCTabSet,
  VCL.TMSFNCPanel, Data.DB, WEBLib.DB, WEBLib.CDS, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCGrid, Vcl.Imaging.GIFImg;

const
  PageIndexSchedule = 7;
  PageIndexRecipients = 8;

type
  TReportEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    ReportTypeLabel: TLabel;
    ReportTypeEdit: TLookupComboBox;
    ReportFormatLabel: TLabel;
    ReportFormatEdit: TLookupComboBox;
    PeriodLabel: TLabel;
    PeriodEdit: TLookupComboBox;
    DateTillEdit: TDateTimePicker;
    DateFromEdit: TDateTimePicker;
    DateFromLabel: TLabel;
    DateTillLabel: TLabel;
    TeamFilterEdit: TLookupComboBox;
    TypeFilterEdit: TLookupComboBox;
    ReportTitleLabel: TLabel;
    ReportTitleEdit: TEdit;
    ListButtonAllPanel: TTMSFNCPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    PrintButton: TSpeedButton;
    ObjectFilterTypeLabel: TLabel;
    ObjectNoEdit: TEdit;
    ObjectIDEdit: TEdit;
    ObjectFilterTypeEdit: TLookupComboBox;
    ReportTypePageControl: TTMSFNCPageControl;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    WebLabel7: TLabel;
    ReportTypeTripAllEdit: TRadioButton;
    ReportTypeTripFirstEdit: TRadioButton;
    ReportTypeTripLastEdit: TRadioButton;
    ReportTypeTripBothEdit: TRadioButton;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    WebLabel1: TLabel;
    ReportTypeAssetActiveAllEdit: TRadioButton;
    ReportTypeAssetActiveYesEdit: TRadioButton;
    ReportTypeAssetActiveNoEdit: TRadioButton;
    MailButton: TSpeedButton;
    ReportTypePageControlPage3: TTMSFNCPageControlContainer;
    MondayEdit: TCheckBox;
    TuesdayEdit: TCheckBox;
    WednesdayEdit: TCheckBox;
    ThursdayEdit: TCheckBox;
    FridayEdit: TCheckBox;
    SaturdayEdit: TCheckBox;
    SundayEdit: TCheckBox;
    ScheduleDayLabel: TLabel;
    ScheduleMonthLabel: TLabel;
    ScheduleTypeEdit: TLookupComboBox;
    ScheduleDayEdit: TEdit;
    ScheduleMonthEdit: TLookupComboBox;
    ReportTypePageControlPage4: TTMSFNCPageControlContainer;
    WebLabel2: TLabel;
    MOTDays1Edit: TEdit;
    WebLabel3: TLabel;
    MOTDays2Edit: TEdit;
    WebLabel4: TLabel;
    IgnoreDistanceEdit: TCheckBox;
    ReportTypePageControlPage5: TTMSFNCPageControlContainer;
    TMSFNCPanel1: TTMSFNCPanel;
    MailToMyselfEdit: TCheckBox;
    MailToGroupsEdit: TCheckBox;
    TMSFNCPanel2: TTMSFNCPanel;
    MailToLabel: TLabel;
    RecipientListEdit: TEdit;
    SelectButton: TSpeedButton;
    DeselectButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    SendToDBAdapter: TTMSFNCGridDatabaseAdapter;
    SendToDataSet: TClientDataSet;
    SendToDataSetPKey: TStringField;
    SendToDataSetChecked: TStringField;
    SendToDataSetEmail: TStringField;
    SendToDataSetAssetGroup: TStringField;
    SendToDataSource: TDataSource;
    SendToTimer: TTimer;
    SendToGrid: TTMSFNCGrid;
    WebWaitMessage: TWaitMessage;
    MyMessageDlg: TMessageDlg;
    ReportTypePageControlPage6: TTMSFNCPageControlContainer;
    DeviceLowBatteryEdit: TCheckBox;
    ReportTypeDriverIgnoreDistanceEdit: TCheckBox;
    ReportTypePageControlPage7: TTMSFNCPageControlContainer;
    WebLabel9: TLabel;
    AlertListEdit: TLookupComboBox;
    AlertCriticalEdit: TCheckBox;
    ReportTypePageControlPage8: TTMSFNCPageControlContainer;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    InsuranceDays1Edit: TEdit;
    InsuranceDays2Edit: TEdit;
    WebLabel8: TLabel;
    ReportTypeMOTPeriod: TRadioButton;
    ReportTypeMOTDays: TRadioButton;
    ReportTypeInsurancePeriod: TRadioButton;
    ReportTypeInsuranceDays: TRadioButton;
    WebLabel16: TLabel;
    AssetStatusEdit: TLookupComboBox;
    ReportTypeAssetActiveNoTypeEdit: TLookupComboBox;
    AssetDistanceEdit: TCheckBox;
    AssetDistanceTypeEdit: TLookupComboBox;
    AssetDistanceMinEdit: TEdit;
    AssetDistanceMinLabel: TLabel;
    AssetDistanceMaxEdit: TEdit;
    AssetDistanceMaxLabel: TLabel;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    procedure PeriodEditChange(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    [async]
    procedure PrintMailButtonClick(Sender: TObject); async;
    procedure ObjectFilterTypeEditChange(Sender: TObject);
    procedure ReportTypeEditChange(Sender: TObject);
    procedure ScheduleTypeEditChange(Sender: TObject);
    [async]
    procedure ReportTypePageControlBeforeChangePage(Sender: TObject;
      ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean); async;
    procedure ReportTypePageControlChangePage(Sender: TObject;
      APreviousPageIndex, ACurrentPageIndex: Integer);
    procedure SendToDataSetAfterOpen(DataSet: TDataSet);
    procedure SendToGridCellClick(Sender: TObject; ACol, ARow: Integer);
    procedure SendToGridKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure SendToTimerTimer(Sender: TObject);
    procedure TeamFilterEditChange(Sender: TObject);
    procedure ObjectIDEditExit(Sender: TObject);
    procedure ObjectIDEditEnter(Sender: TObject);
    procedure ObjectIDEditChange(Sender: TObject);
    procedure ObjectNoEditEnter(Sender: TObject);
    procedure ObjectNoEditChange(Sender: TObject);
    procedure ObjectNoEditExit(Sender: TObject);
    procedure RecordChange(Sender: TObject);
    procedure SendToDataSetBeforeEdit(DataSet: TDataSet);
    procedure SendToDataSetAfterPost(DataSet: TDataSet);
    procedure MailToGroupsEditClick(Sender: TObject);
    procedure AssetDistanceTypeEditChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadTeamFilterList(); async;
    [async]
    procedure LoadTypeFilterList(); async;
    [async]
    procedure LoadStatusList(); async;
    [async]
    procedure LoadAlertList(); async;
    [async]
    procedure LoadReportMailToList(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function ValidSchedule(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveReport(): Boolean; async;
    function WeekDayToString(): String;
    function ReportMailToString(): String;
    [async]
    function MakeOptionList(): String; async;
  public
    { Public declarations }
    IsSet: Boolean;
    ReportPKey: Int64;
    procedure SelectObjectFilterType(ObjectFilterType: String; UpdateFilterType: Boolean);
    procedure SetSchedule(ScheduleType, WeekDays, Day, Month: String);
    procedure EnableSchedule(Enable: Boolean);
    procedure EnablePeriod(Enable: Boolean);
    function ReportTypeMailtoEnabled(ReportType: Integer): Boolean;
    procedure ParseOptionList(ReportType: Integer; OptionList: String);
  protected procedure LoadDFMValues; override; end;

var
  ReportEditForm: TReportEditForm;

implementation

{$R *.dfm}

uses uCommon, uReportPrint;

procedure TReportEditForm.WebFormCreate(Sender: TObject);
var
  I: Integer;
begin
  Tag := Tag;
  IsSet := False;
  SetGridStyle(SendToGrid, False);
  for I := ReportTypePageControl.Pages.Count-1 downto 0 do begin
    ReportTypePageControl.Pages[I].TabVisible := False;
  end;
  // Initialize Values
  ObjectFilterTypeEdit.Value := '0';
end;

function TReportEditForm.ReportTypeMailtoEnabled(ReportType: Integer): Boolean;
begin
  Result := False;
  case ReportType of
    1: Result := GetUserPermissionValue(Permissions, 'reports_mailto_route') in [1,2,3,5,7];
    2: Result := GetUserPermissionValue(Permissions, 'reports_mailto_asset') in [1,2,3,5,7];
    3: Result := GetUserPermissionValue(Permissions, 'reports_mailto_driver') in [1,2,3,5,7];
    4: Result := GetUserPermissionValue(Permissions, 'reports_mailto_mot') in [1,2,3,5,7];
    5: Result := GetUserPermissionValue(Permissions, 'reports_mailto_device') in [1,2,3,5,7];
    6: Result := GetUserPermissionValue(Permissions, 'reports_mailto_alert') in [1,2,3,5,7];
    7: Result := GetUserPermissionValue(Permissions, 'reports_mailto_insurance') in [1,2,3,5,7];
  end;
end;

procedure TReportEditForm.SelectObjectFilterType(ObjectFilterType: String; UpdateFilterType: Boolean);
begin
  if UpdateFilterType then begin
    ObjectFilterTypeEdit.Value := ObjectFilterType;
  end;
  ObjectNoEdit.Visible := ObjectFilterType = '1';
  ObjectIDEdit.Visible := ObjectFilterType = '2';
  TeamFilterEdit.Visible := ObjectFilterType = '3';
  TypeFilterEdit.Visible := ObjectFilterType = '3';
  LoadReportMailToList();
end;

procedure TReportEditForm.SendToDataSetAfterOpen(DataSet: TDataSet);
begin
  SendToDBAdapter.Active := True;
  SendToTimer.Enabled := True;
end;

procedure TReportEditForm.SendToDataSetAfterPost(DataSet: TDataSet);
begin
  RecordChange(nil);
end;

procedure TReportEditForm.SendToDataSetBeforeEdit(DataSet: TDataSet);
begin
  RecordChange(nil);
end;

procedure TReportEditForm.SendToGridCellClick(Sender: TObject; ACol,
  ARow: Integer);
begin
  if ACol = 0 then begin
    if SendToDataset.Active and not(SendToDataset.IsEmpty) then begin
      SendToDataset.Edit;
      if SendToDataset.FieldByName('Checked').AsString = 't' then begin
        SendToDataset.FieldByName('Checked').AsString := 'f';
      end else begin
        SendToDataset.FieldByName('Checked').AsString := 't';
      end;
      SendToDataset.Post;
    end;
  end;
end;

procedure TReportEditForm.SendToGridKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = VK_Space then begin
    if SendToDataset.Active and not(SendToDataset.IsEmpty) then begin
      SendToDataset.Edit;
      if SendToDataset.FieldByName('Checked').AsString = 't' then begin
        SendToDataset.FieldByName('Checked').AsString := 'f';
      end else begin
        SendToDataset.FieldByName('Checked').AsString := 't';
      end;
      SendToDataset.Post;
    end;
  end;
end;

procedure TReportEditForm.SendToTimerTimer(Sender: TObject);
begin
  SendToTimer.Enabled := False;
  SendToDataset.First;
  WebWaitMessage.Hide;
end;

procedure TReportEditForm.WebFormShow(Sender: TObject);
begin
  // Load Filter Values
  await(LoadTeamFilterList);
  await(LoadTypeFilterList);
  ReportTypePageControlPage6.Tag := 1;
  await(LoadReportMailToList);
//await(LoadAlertList);
  RecordChanged := False;
end;

procedure TReportEditForm.SetSchedule(ScheduleType, WeekDays, Day, Month: String);
var
  StringList: TStringList;
begin
  ScheduleTypeEdit.Value := ScheduleType;
  ScheduleTypeEditChange(ScheduleTypeEdit);
  // WeekDays
  StringList := TStringList.Create();
  try
    StringList.DelimitedText := WeekDays;
    MondayEdit.Checked := StringList.IndexOf('1') >= 0;
    TuesdayEdit.Checked := StringList.IndexOf('2') >= 0;
    WednesdayEdit.Checked := StringList.IndexOf('3') >= 0;
    ThursdayEdit.Checked := StringList.IndexOf('4') >= 0;
    FridayEdit.Checked := StringList.IndexOf('5') >= 0;
    SaturdayEdit.Checked := StringList.IndexOf('6') >= 0;
    SundayEdit.Checked := StringList.IndexOf('0') >= 0;
  finally
    StringList.Free;
  end;
  // Day Of The Month
  ScheduleDayEdit.Text := Day;
  // Month Of The Year
  ScheduleMonthEdit.Value := Month;
end;

procedure TReportEditForm.TeamFilterEditChange(Sender: TObject);
begin
  LoadReportMailToList();
  RecordChange(nil);
end;

procedure TReportEditForm.EnableSchedule(Enable: Boolean);
begin
  ScheduleTypeEdit.Enabled := Enable;
  MondayEdit.Enabled := Enable;
  TuesdayEdit.Enabled := Enable;
  WednesdayEdit.Enabled := Enable;
  ThursdayEdit.Enabled := Enable;
  FridayEdit.Enabled := Enable;
  SaturdayEdit.Enabled := Enable;
  SundayEdit.Enabled := Enable;
  ScheduleDayEdit.Enabled := Enable;
  ScheduleMonthEdit.Enabled := Enable;
end;

procedure TReportEditForm.EnablePeriod(Enable: Boolean);
begin
  PeriodLabel.Visible := Enable;
  PeriodEdit.Visible := Enable;
  DateFromLabel.Visible := Enable;
  DateFromEdit.Visible := Enable;
  DateTillLabel.Visible := Enable;
  DateTillEdit.Visible := Enable;
end;

procedure TReportEditForm.ParseOptionList(ReportType: Integer; OptionList: string);
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyJSONKeys: TStringList;
  I: Integer;
  ReportOption: String;
begin
  // Select TabSheet
  if MailButton.Visible or PrintButton.Visible then begin
    ReportTypePageControl.Pages[PageIndexSchedule].TabVisible := False;
    ReportTypePageControl.Pages[PageIndexRecipients].TabVisible := False;
  end else begin
    ReportTypePageControl.Pages[PageIndexSchedule].TabVisible :=
      not((ReportType = 4) or (ReportType = 7));
//  ReportTypePageControl.Pages[PageIndexRecipients].TabVisible := True;
    ReportTypePageControl.Pages[PageIndexRecipients].TabVisible :=
      ReportTypeMailtoEnabled(ReportType);
  end;
  for I := 0 to ReportTypePageControl.Pages.Count-3 do begin
    ReportTypePageControl.Pages[I].TabVisible := ReportType = I+1;
  end;
  ReportTypePageControl.ActivePageIndex := ReportType-1;
  // Parse JSON
  MyJSON := TJSON.Create;
  MyJSONKeys := TStringList.Create();
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(OptionList));
    MyJSONKeys.DelimitedText := GetJSONObjectKeys(MyJSONObject);
    if ReportType = 1 then begin
      ReportOption := MyJSONObject.GetJSONValue('trips');
      if ReportOption = 'all' then ReportTypeTripAllEdit.Checked := True;
      if ReportOption = 'first' then ReportTypeTripFirstEdit.Checked := True;
      if ReportOption = 'last' then ReportTypeTripLastEdit.Checked := True;
      if ReportOption = 'both' then ReportTypeTripBothEdit.Checked := True;
      ReportOption := MyJSONObject.GetJSONValue('ignore_distance');
      IgnoreDistanceEdit.Checked := ReportOption = '1';
    end;
    if ReportType = 2 then begin
      ReportOption := MyJSONObject.GetJSONValue('active');
      if ReportOption = 'all' then ReportTypeAssetActiveAllEdit.Checked := True;
      if ReportOption = 'yes' then ReportTypeAssetActiveYesEdit.Checked := True;
      if ReportOption = 'no' then ReportTypeAssetActiveNoEdit.Checked := True;
      if MyJSONKeys.IndexOf('asset_status') >= 0 then begin
        AssetStatusEdit.Tag := StrToInt(EmptyStrToZero(MyJSONObject.GetJSONValue('asset_status')));
      end else begin
        AssetStatusEdit.Tag := 0;
      end;
      if MyJSONKeys.IndexOf('error_type') >= 0 then begin
        ReportTypeAssetActiveNoTypeEdit.Tag := StrToInt(EmptyStrToZero(MyJSONObject.GetJSONValue('error_type')));
      end else begin
        ReportTypeAssetActiveNoTypeEdit.Tag := 0;
      end;
      if MyJSONKeys.IndexOf('asset_distance_bool') >= 0 then begin
        AssetDistanceEdit.Checked := MyJSONObject.GetJSONValue('asset_distance_bool') = '1';
        AssetDistanceTypeEdit.Value := MyJSONObject.GetJSONValue('asset_distance_type');
        AssetDistanceMinEdit.Text := MyJSONObject.GetJSONValue('asset_distance_min');
        AssetDistanceMaxEdit.Text := MyJSONObject.GetJSONValue('asset_distance_max');
      end;
    end;
    if ReportType = 3 then begin
      ReportOption := MyJSONObject.GetJSONValue('ignore_distance');
      ReportTypeDriverIgnoreDistanceEdit.Checked := ReportOption = '1';
    end;
    if ReportType = 4 then begin
      if TryStrToInt(MyJSONObject.GetJSONValue('mot_period'), I) then begin
        case I of
          0: ReportTypeMOTDays.Checked := True;
          1: ReportTypeMOTPeriod.Checked := True;
        end;
      end;
      MOTDays1Edit.Text := MyJSONObject.GetJSONValue('mot_days_1');
      MOTDays2Edit.Text := MyJSONObject.GetJSONValue('mot_days_2');
    end;
    if ReportType = 5 then begin
      ReportOption := MyJSONObject.GetJSONValue('low_battery');
      DeviceLowBatteryEdit.Checked := ReportOption = 't';
    end;
    if ReportType = 6 then begin
      ReportOption := MyJSONObject.GetJSONValue('alert_id');
      AlertListEdit.Tag := StrToInt(ReportOption);
      ReportOption := MyJSONObject.GetJSONValue('alert_critical');
      AlertCriticalEdit.Checked := ReportOption = '1';
    end;
    if ReportType = 7 then begin
      InsuranceDays1Edit.Text := MyJSONObject.GetJSONValue('insurance_days_1');
      InsuranceDays2Edit.Text := MyJSONObject.GetJSONValue('insurance_days_2');
    end;
    case ReportType of
      1, 2, 3, 4, 6, 7: EnablePeriod(True);
      else EnablePeriod(False);
    end;
  finally
    MyJSON.Free;
    MyJSONKeys.Free;
  end;
end;

procedure TReportEditForm.AssetDistanceTypeEditChange(Sender: TObject);
var
  AssetDistanceType: Integer;
begin
  if StrToInt(AssetDistanceTypeEdit.Value) > 0 then begin
    AssetDistanceType := StrToInt(AssetDistanceTypeEdit.Value);
    case AssetDistanceType of
      1: begin
        AssetDistanceMinEdit.Visible := False;
        AssetDistanceMinLabel.Caption := 'km';
        AssetDistanceMaxEdit.Left := AssetDistanceMinEdit.Left;
        AssetDistanceMaxEdit.Visible := True;
        AssetDistanceMaxLabel.Visible := False;
      end;
      2: begin
        AssetDistanceMinEdit.Visible := True;
        AssetDistanceMinLabel.Caption := 'and';
        AssetDistanceMaxEdit.Visible := True;
        AssetDistanceMaxEdit.Left := 340;
        AssetDistanceMaxLabel.Visible := True;
      end;
      3: begin
        AssetDistanceMinEdit.Visible := True;
        AssetDistanceMinLabel.Caption := 'km';
        AssetDistanceMaxEdit.Visible := False;
        AssetDistanceMaxLabel.Visible := False;
      end;
    end;
  end;
  RecordChange(nil);
end;

procedure TReportEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  Result: Boolean;
  ErrorMessage: String;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      Result := await(ValidInput(ErrorMessage));
      if Result then begin
        Result := await(SaveReport());
        if Result then begin
          ModalResult := mrOK;
        end else begin
          MessageDlg('Error: Failed to save report', TMsgDlgType.mtError, [mbOK]);
        end;
      end else begin
        MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    end else begin
      if ReportPKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if ReportPKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TReportEditForm.PeriodEditChange(Sender: TObject);
var
  DateFrom, DateTill: TDate;
begin
  if StrToInt(PeriodEdit.Value) > 0 then begin
    PeriodToFromTill(
      StrToInt(PeriodEdit.Value),
      DateFrom, DateTill
    );
    DateFromEdit.Date := DateFrom;
    DateTillEdit.Date := DateTill;
  end;
  RecordChange(nil);
end;

procedure TReportEditForm.PrintMailButtonClick(Sender: TObject);
var
  Result: Boolean;
  MyURL: String;
  MyTitle, MyString: String;
  MyWebForm: TReportPrintForm;
  ErrorMessage: String;
  MyModalResult: TModalResult;
  ReportType: Int64;
  // Mail
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    ReportType := StrToInt(ReportTypeEdit.Value);
    if Trim(ReportTitleEdit.Text) <> EmptyStr then begin
      MyTitle := ReportTitleEdit.Text;
    end else begin
      case ReportType of
        1, 3: begin
          MyTitle :=
            ReportTypeEdit.DisplayText + '     ' +
            'From: ' + FormatDateTime('dd/mm/yyyy', DateFromEdit.Date) + '     ' +
            'Till: ' + FormatDateTime('dd/mm/yyyy', DateTillEdit.Date) + '     ';
        end;
        2: begin
          if AssetDistanceEdit.Checked then begin
            MyTitle :=
              ReportTypeEdit.DisplayText + '     ' +
              'From: ' + FormatDateTime('dd/mm/yyyy', DateFromEdit.Date) + '     ' +
              'Till: ' + FormatDateTime('dd/mm/yyyy', DateTillEdit.Date) + '     ';
          end;
        end;
      end;
      case StrToInt(ObjectFilterTypeEdit.Value) of
        1: begin
          MyTitle := MyTitle + 'Asset ID: ' + ObjectNoEdit.Text;
        end;
        2: begin
          MyTitle := MyTitle + 'Registration plate: ' + ObjectIDEdit.Text;
        end;
        3: begin
          MyTitle := MyTitle +
            'Asset Group: ' + TeamFilterEdit.DisplayText + '     ' +
            'Asset Type: ' + TypeFilterEdit.DisplayText;
        end;
      end;
      case ReportType of
        1: begin
          if ReportTypeTripAllEdit.Checked then MyTitle := MyTitle + '     All routes';
          if ReportTypeTripFirstEdit.Checked then MyTitle := MyTitle + '     First route';
          if ReportTypeTripLastEdit.Checked then MyTitle := MyTitle + '     Last route';
          if ReportTypeTripBothEdit.Checked then MyTitle := MyTitle + '     First and Last route';
        end;
        2: begin
          if ReportTypeAssetActiveAllEdit.Checked then MyTitle := MyTitle + '     Active / Inactive';
          if ReportTypeAssetActiveYesEdit.Checked then MyTitle := MyTitle + '     Active';
          if ReportTypeAssetActiveNoEdit.Checked then MyTitle := MyTitle + '     Inactive';
          if AssetStatusEdit.Value <> '0' then MyTitle := MyTitle + ' - Status = ' + AssetStatusEdit.DisplayText;
          if AssetDistanceEdit.Checked then begin
            case StrToInt(AssetDistanceTypeEdit.Value) of
              1: MyTitle := MyTitle +
                ' - distance less than ' + EmptyStrToZero(AssetDistanceMaxEdit.Text) + ' km';
              2: MyTitle := MyTitle +
                ' - distance between ' + EmptyStrToZero(AssetDistanceMinEdit.Text) + ' and ' +
                EmptyStrToZero(AssetDistanceMaxEdit.Text) + ' km';
              3: MyTitle := MyTitle +
                ' - distance more than ' + EmptyStrToZero(AssetDistanceMinEdit.Text) + ' km';
            end;
          end;
        end;
        4: begin
          if ReportTypeMOTPeriod.Checked then begin
            MyTitle := MyTitle +
              '     MOT between ' + FormatDateTime('dd-mm-yyyy', DateFromEdit.Date) + ' and ' + FormatDateTime('dd-mm-yyyy', DateTillEdit.Date);
          end;
          if ReportTypeMOTDays.Checked then begin
            MyTitle := MyTitle +
              '     MOT in ' + Trim(MOTDays1Edit.Text) + ' or ' + Trim(MOTDays2Edit.Text) + ' days';
          end;
        end;
        5: begin
          if DeviceLowBatteryEdit.Checked then begin
            MyTitle := MyTitle +
            '     Low Battery';
          end;
        end;
        7: begin
          if ReportTypeInsurancePeriod.Checked then begin
            MyTitle := MyTitle +
              '     Insurance expires between ' + FormatDateTime('dd-mm-yyyy', DateFromEdit.Date) + ' and ' + FormatDateTime('dd-mm-yyyy', DateTillEdit.Date);
          end;
          if ReportTypeInsuranceDays.Checked then begin
            MyTitle := MyTitle +
              '     Insurance expires in ' + Trim(MOTDays1Edit.Text) + ' or ' + Trim(MOTDays2Edit.Text) + ' days';
          end;
        end;
      end;
    end;
    MyString :=
      '{' +
        '"date_from":"' + FormatDateTime('yyyy-mm-dd', DateFromEdit.Date) + '",' +
        '"date_till":"' + FormatDateTime('yyyy-mm-dd', DateTillEdit.Date) + '",';
    case StrToInt(ObjectFilterTypeEdit.Value) of
      1: begin
        MyString := MyString +
          '"object_no":"' + Trim(ObjectNoEdit.Text) + '",';
      end;
      2: begin
        MyString := MyString +
          '"object_id":"' + Trim(ObjectIDEdit.Text) + '",'
      end;
      3: begin
        MyString := MyString +
          '"team_filter":"' + TeamFilterEdit.Value + '",';
        MyString := MyString +
          '"type_filter": "' + TypeFilterEdit.Value + '",';
      end;
    end;
    MyString := MyString +
        '"option_list":' + await(MakeOptionList()) +
      '}';
    // URL Parameters
    MyURL :=
      '?sessionid=' + SessionId +
      '&report_name=' + ReportTypeEdit.DisplayText +
      '&report_format=' + ReportFormatEdit.DisplayText +
      '&report_title=' + Trim(MyTitle) +
      '&report_json=' + MyString;
    // Print / Mail
    case TSpeedButton(Sender).Tag of
      1: begin
        // Show Report
        MyWebForm := TReportPrintForm.Create(Self);
        MyWebForm.Popup := True;
        MyWebForm.Border := fbNone;
        await(TReportPrintForm, MyWebForm.Load());
        try
          // Initialize
          MyWebForm.WebBrowser.URL :=
            url_php + 'download_report_live.php' + MyURL;
          // Show Form
          MyModalResult := await(TModalResult, MyWebForm.Execute);
//          if MyModalResult = mrOK then begin
//          end;
        finally
          MyWebForm.Free;
        end;
      end;
      2: begin
        MyWebRequest := THTTPRequest.Create(Self);
        try
          MyWebRequest.URL :=
            url_php + 'download_mail_report_live.php' + MyURL;
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          if MyRequest.Status = 200 then begin
            MyJSON := TJSON.Create;
            try
              MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
              if MyJSONObject.GetJSONValue('status') = 'OK' then Result := True;
            finally
              MyJSON.Free;
            end;
          end;
          if Result then begin
            MessageDlg('Email was sent', TMsgDlgType.mtInformation, [mbOK], nil);
          end else begin
            MessageDlg('Error sending email', TMsgDlgType.mtError, [mbOK], nil);
          end;
        finally
          MyWebRequest.Free;
        end;
      end;
    end;
  end else begin
    MessageDlg(ErrorMessage, TmsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TReportEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TReportEditForm.ReportTypeEditChange(Sender: TObject);
var
  I: Integer;
  NewPageIndex: Integer;
begin
  NewPageIndex := StrToInt(TLookupComboBox(Sender).Value);
  with ReportTypePageControl do begin
    BeginUpdate;
    try
      // Show Pages
      for I := 0 to Pages.Count-1 do Pages[I].TabVisible := True;
      // Update Index
      ActivePageIndex := NewPageIndex-1;
      // Hide Pages
      if not(MailButton.Visible or PrintButton.Visible) then begin
        if ((NewPageIndex = 4) or (NewPageIndex = 7)) then begin
          Pages[PageIndexSchedule].TabVisible := False;
        end else begin
          Pages[PageIndexSchedule].TabVisible := True;
//        Pages[PageIndexRecipients].TabVisible := True;
        end;
        Pages[PageIndexRecipients].TabVisible :=
          ReportTypeMailtoEnabled(NewPageIndex);
      end else begin
        Pages[PageIndexSchedule].TabVisible := False;
        Pages[PageIndexRecipients].TabVisible := False;
      end;
      for I := 0 to Pages.Count-3 do
        if I <> ActivePageIndex then Pages[I].TabVisible := False;
      // MOT
      if ((NewPageIndex = 4) or (NewPageIndex = 7)) then begin
        SetSchedule('1', '0,1,2,3,4,5,6', '1', '1');
        EnableSchedule(False);
      end else begin
        EnableSchedule(True);
      end;
      case NewPageIndex of
        1, 2, 3, 4, 6, 7: EnablePeriod(True);
        else EnablePeriod(False);
      end;
    finally
      EndUpdate;
    end;
  end;
  RecordChange(nil);
end;

procedure TReportEditForm.ReportTypePageControlBeforeChangePage(Sender: TObject;
  ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
var
  MyInteger, MaxDay: Integer;
  ScheduleType: Integer;
  ErrorMessage: String;
begin
  ACanChange := True;
  // Current Page = Schedule
  if ACanChange and (ACurrentPageIndex = PageIndexSchedule) then begin
    ScheduleType := StrToInt(ScheduleTypeEdit.Value);
    case ScheduleType of
      1: begin
        if (WeekDayToString() = EmptyStr) then begin
          ACanChange := False;
          ErrorMessage := 'At least one day of the week is required.';
        end;
      end;
      2,3: begin
        if TryStrToInt(ScheduleDayEdit.Text, MyInteger) then begin
          if (ScheduleType = 2) then MaxDay := 31;
          if (ScheduleType = 3) then begin
            case StrToInt(ScheduleMonthEdit.Value) of
              2: MaxDay := 29;
              4,6,9,11: MaxDay := 30;
              1,3,5,7,8,10,12: MaxDay := 31;
            end;
          end;
          if not((MyInteger > 0) and (MyInteger <= MaxDay)) then begin
            ACanChange := False;
            ErrorMessage := 'DAY requires a value between 1 and ' + IntToStr(MaxDay) + '.';
          end;
        end else begin
          ACanChange := False;
          ErrorMessage := 'DAY is a required field.';
        end;
      end;
    end;
  end;
  // New Page = Recipients
  if ACanChange and (ANewPageIndex = PageIndexRecipients) then begin
    if StrToInt(ScheduleTypeEdit.Value) > 0 then begin
      if ReportPKey <= 0 then begin
        ACanChange := False;
        ErrorMessage := 'Save the report before linking recipients.';
      end;
    end else begin
      ACanChange := False;
      ErrorMessage := 'You need to assign a schedule before you can link recipients.';
    end;
  end;
  // New Page = Assets
  if ACanChange and (ANewPageIndex = 1) then begin
    ReportTypeAssetActiveNoTypeEdit.Value := IntToStr(ReportTypeAssetActiveNoTypeEdit.Tag);
    await(LoadStatusList());
  end;
  // New Page = Alerts
  if ACanChange and (ANewPageIndex = 5) then begin
    await(LoadAlertList());
  end;
  // Show Errors
  if not(ACanChange) then begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TReportEditForm.ReportTypePageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  if (ACurrentPageIndex = PageIndexRecipients) then begin
    SelectButton.Visible := True;
    DeselectButton.Visible := True;
    LoadReportMailToList();
  end else begin
    SelectButton.Visible := False;
    DeselectButton.Visible := False;
  end;
end;

procedure TReportEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := True;
  if Result then Result := await(ValidSchedule(ErrorMessage));
  if Result then Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveReport());
    if Result then begin
      RecordChanged := False;
    end else begin
      MessageDlg('Error: Failed to save report', TMsgDlgType.mtError, [mbOK]);
    end;

//    if Result then begin
//      ModalResult := mrOK;
//    end else begin
//      MessageDlg('Error: Failed to save report', TMsgDlgType.mtError, [mbOK]);
//    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TReportEditForm.LoadTeamFilterList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  // Clear Values
  TeamFilterEdit.LookupValues.Clear;
  // Request JSON
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_team_list_v2.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(String(MyRequest.response)));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      TeamFilterEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('filter_pk'),
        MyJSONObject.GetJSONValue('filter_naam')
      );
    end;
    // Set Value
    TeamFilterEdit.Value := IntToStr(TeamFilterEdit.Tag);
  finally
    MyJSON.Free;
  end;
end;

procedure TReportEditForm.LoadTypeFilterList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  // Clear Values
  TypeFilterEdit.LookupValues.Clear;
  // Request JSON
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_object_type_list_v2.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(String(MyRequest.response)));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      TypeFilterEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('filter_pk'),
        MyJSONObject.GetJSONValue('filter_naam')
      );
    end;
    // Set Value
    TypeFilterEdit.Value := IntToStr(TypeFilterEdit.Tag);
  finally
    MyJSON.Free;
  end;
end;

procedure TReportEditForm.LoadStatusList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  // Clear Values
  AssetStatusEdit.LookupValues.Clear;
  // Request JSON
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_status_list_nul.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Default Value = All
  AssetStatusEdit.LookupValues.AddPair('0', 'ALL');
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(String(MyRequest.response)));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AssetStatusEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('obs_pk'),
        MyJSONObject.GetJSONValue('obs_naam')
      );
    end;
    AssetStatusEdit.Value := IntToStr(AssetStatusEdit.Tag);
  finally
    MyJSON.Free;
  end;
end;

procedure TReportEditForm.LoadAlertList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  // Clear Values
  AlertListEdit.LookupValues.Clear;
  // Request JSON
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_alert_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Default Value = All
  AlertListEdit.LookupValues.AddPair('0', 'ALL');
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(String(MyRequest.response)));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AlertListEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('Name')
      );
    end;
    AlertListEdit.Value := IntToStr(AlertListEdit.Tag);
  finally
    MyJSON.Free;
  end;
end;

procedure TReportEditForm.LoadReportMailToList();
var
  FilterType: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  if
    (ReportTypePageControlPage6.Tag = 1)
  and
    (ReportTypePageControl.ActivePageIndex = PageIndexRecipients)
  then begin
    if MailToGroupsEdit.Checked then begin
      FilterType := StrToInt(ObjectFilterTypeEdit.Value);
      if
        ((FilterType = 1) and (Trim(ObjectNoEdit.Text) <> EmptyStr))
        or
        ((FilterType = 2) and (Trim(ObjectIDEdit.Text) <> EmptyStr))
        or
        (FilterType = 3)
      then begin
        WebWaitMessage.Show;
        MyWebRequest := THTTPRequest.Create(Self);
        try
          MyWebRequest.URL :=
            url_php + 'default_report_mailto_list.php?sessionid=' + SessionId +
            '&report_pkey=' + IntToStr(ReportPKey) +
            '&filter_type=' + ObjectFilterTypeEdit.Value;
          case StrToInt(ObjectFilterTypeEdit.Value) of
            1: MyWebRequest.URL := MyWebRequest.URL + '&filter_value=' + ObjectNoEdit.Text;
            2: MyWebRequest.URL := MyWebRequest.URL + '&filter_value=' + ObjectIDEdit.Text;
            3: MyWebRequest.URL := MyWebRequest.URL + '&filter_value=' + TeamFilterEdit.Value;
          end;
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        finally
          MyWebRequest.Free;
        end;
        // Fill Dataset
        SendToDataset.Close;
        SendToDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
        await(SendToDataset.Open);
      end else begin
        SendToDataset.Close;
        SendToDataset.Open;
      end;
    end else begin
      SendToDataset.Close;
    end;
  end;
end;

function TReportEditForm.ValidSchedule(var ErrorMessage: String): Boolean;
var
  MyInteger, MaxDay: Integer;
  ScheduleType: Integer;
begin
  Result := True;
  ScheduleType := StrToInt(ScheduleTypeEdit.Value);
  case ScheduleType of
    1: begin
      if (WeekDayToString() = EmptyStr) then begin
        Result := False;
        ErrorMessage := 'At least one day of the week is required.';
      end;
    end;
    2,3: begin
      if TryStrToInt(ScheduleDayEdit.Text, MyInteger) then begin
        if (ScheduleType = 2) then MaxDay := 31;
        if (ScheduleType = 3) then begin
          case StrToInt(ScheduleMonthEdit.Value) of
            2: MaxDay := 29;
            4,6,9,11: MaxDay := 30;
            1,3,5,7,8,10,12: MaxDay := 31;
          end;
        end;
        if not((MyInteger > 0) and (MyInteger <= MaxDay)) then begin
          Result := False;
          ErrorMessage := 'DAY requires a value between 1 and ' + IntToStr(MaxDay) + '.';
        end;
      end else begin
        Result := False;
        ErrorMessage := 'DAY is a required field.';
      end;
    end;
  end;
end;

function TReportEditForm.ValidInput(var ErrorMessage: String): Boolean;
var
  MyInteger: Integer;
  StringList: TStringList;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := True;
  // Object Filter Type
  if Result then begin
    case StrToInt(ObjectFilterTypeEdit.Value) of
      1: begin
        if (Trim(ObjectNoEdit.Text) = EmptyStr) then begin
          Result := False;
          ErrorMessage := '"Asset No." is a required field.';
        end else begin
          // Check Device No
          MyWebRequest := THTTPRequest.Create(Self);
          try
            MyWebRequest.URL :=
              url_php + 'default_report_check_device_no.php?sessionid=' + SessionId +
              '&pkey=' + ObjectNoEdit.Text +
              '&date=' + IntToStr(DateTimeToUnix(DateFromEdit.Date));
            MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
            if MyRequest.Status = 200 then begin
              MyJSON := TJSON.Create;
              try
                MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
                if not(MyJSONObject.GetJSONValue('status') = 'OK') then begin
                  Result := False;
                  ErrorMessage := MyJSONObject.GetJSONValue('error_message');
                end;
              finally
                MyJSON.Free;
              end;
            end;
          finally
            MyWebRequest.Free;
          end;
        end;
      end;
      2: begin
        if (Trim(ObjectIDEdit.Text) = EmptyStr) then begin
          Result := False;
          ErrorMessage := '"Registration plate" is a required field.';
        end else begin
          // Check License Plate
          MyWebRequest := THTTPRequest.Create(Self);
          try
            MyWebRequest.URL :=
              url_php + 'default_report_check_license_plate.php?sessionid=' + SessionId +
              '&pkey=' + ObjectIDEdit.Text +
              '&date=' + IntToStr(DateTimeToUnix(DateFromEdit.Date));
            MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
            if MyRequest.Status = 200 then begin
              MyJSON := TJSON.Create;
              try
                MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
                if not(MyJSONObject.GetJSONValue('status') = 'OK') then begin
                  Result := False;
                  ErrorMessage := MyJSONObject.GetJSONValue('error_message');
                end;
              finally
                MyJSON.Free;
              end;
            end;
          finally
            MyWebRequest.Free;
          end;
        end;
      end;
      3: begin
        if (TeamFilterEdit.DisplayText = EmptyStr) or (TypeFilterEdit.DisplayText = EmptyStr) then begin
          Result := False;
          ErrorMessage := '"Asset Group" and "Asset Type" are required fields.';
        end;
      end;
    end;
  end;
  // Report Type
  case StrToInt(ReportTypeEdit.Value) of
    4: begin
      if Result then begin
        if ReportTypeMOTDays.Checked then begin
          if Result and not(TryStrToInt(MOTDays1Edit.Text, MyInteger)) then begin
            Result := False;
            ErrorMessage := '"MOT Days before 1" is a required field.';
          end;
          if Result and not(TryStrToInt(MOTDays2Edit.Text, MyInteger)) then begin
            Result := False;
            ErrorMessage := '"MOT Days before 2" is a required field.';
          end;
        end;
      end;
    end;
    7: begin
      if Result then begin
        if ReportTypeInsuranceDays.Checked then begin
          if Result and not(TryStrToInt(InsuranceDays1Edit.Text, MyInteger)) then begin
            Result := False;
            ErrorMessage := '"Insurance Days before 1" is a required field.';
          end;
          if Result and not(TryStrToInt(InsuranceDays2Edit.Text, MyInteger)) then begin
            Result := False;
            ErrorMessage := '"Insurance Days before 2" is a required field.';
          end;
        end;
      end;
    end;
  end;
  // Email Addresses
  StringList := TStringList.Create();
  StringList.Delimiter := ';';
  StringList.DelimitedText := Trim(RecipientListEdit.Text);
  try
    if StringList.Count > 0 then begin
      for MyInteger := 0 to StringList.Count-1 do begin
        if Result and not(TRegEx.IsMatch(StringList[MyInteger], RegExEmail)) then begin
          Result := False;
          ErrorMessage := '"' + StringList[MyInteger] + '" is not a valid email address.';
        end;
      end;
//    end else begin
//      Result := False;
//      ErrorMessage := 'At least one email address is required.';
    end;
  finally
    StringList.Free;
  end;
end;

function TReportEditForm.WeekDayToString(): String;
var
  StringList: TStringList;
begin
  StringList := TStringList.Create();
  try
    // Sunday (0) to Saturday (6)
    if MondayEdit.Checked then StringList.Add('1');
    if TuesdayEdit.Checked then StringList.Add('2');
    if WednesdayEdit.Checked then StringList.Add('3');
    if ThursdayEdit.Checked then StringList.Add('4');
    if FridayEdit.Checked then StringList.Add('5');
    if SaturdayEdit.Checked then StringList.Add('6');
    if SundayEdit.Checked then StringList.Add('0');
    Result := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
end;

procedure TReportEditForm.MailToGroupsEditClick(Sender: TObject);
begin
  RecordChanged := True;
  LoadReportMailToList();
end;

function TReportEditForm.MakeOptionList(): String;
var
  MyOption: String;
  ReportType: Int64;
begin
  MyOption := EmptyStr;
  ReportType := StrToInt(ReportTypeEdit.Value);
  case ReportType of
    1: begin // Routes
      if ReportTypeTripAllEdit.Checked then MyOption := '{"trips":"all",';
      if ReportTypeTripFirstEdit.Checked then MyOption := '{"trips":"first",';
      if ReportTypeTripLastEdit.Checked then MyOption := '{"trips":"last",';
      if ReportTypeTripBothEdit.Checked then MyOption := '{"trips":"both",';
      MyOption := MyOption + '"ignore_distance":"' + BooleanToString(IgnoreDistanceEdit.Checked) + '"}';
    end;
    2: begin // Assets
      if ReportTypeAssetActiveAllEdit.Checked then MyOption := '{"active":"all",';
      if ReportTypeAssetActiveYesEdit.Checked then MyOption := '{"active":"yes",';
      if ReportTypeAssetActiveNoEdit.Checked then MyOption := '{"active":"no",';
      MyOption := MyOption + '"error_type":"' + EmptyStrToZero(ReportTypeAssetActiveNoTypeEdit.Value) + '",';
      MyOption := MyOption + '"asset_distance_bool":"' + BooleanToString(AssetDistanceEdit.Checked) + '",';
      MyOption := MyOption + '"asset_distance_type":"' + AssetDistanceTypeEdit.Value + '",';
      MyOption := MyOption + '"asset_distance_min":"' + EmptyStrToZero(AssetDistanceMinEdit.Text) + '",';
      MyOption := MyOption + '"asset_distance_max":"' + EmptyStrToZero(AssetDistanceMaxEdit.Text) + '",';
      MyOption := MyOption + '"asset_status":"' + EmptyStrToZero(AssetStatusEdit.Value) + '"}';
    end;
    3: begin // Driver
      MyOption :=
        '{' +
          '"ignore_distance":"' + BooleanToString(ReportTypeDriverIgnoreDistanceEdit.Checked) + '"' +
        '}';
    end;
    4: begin // MOT
      MyOption :=
        '{' +
          '"mot_period":"' + BooleanToString(ReportTypeMOTPeriod.Checked) + '",' +
          '"mot_days_1":"' + EmptyStrToZero(MOTDays1Edit.Text) + '",' +
          '"mot_days_2":"' + EmptyStrToZero(MOTDays2Edit.Text) + '"' +
        '}';
    end;
    5: begin // Devices
      MyOption :=
        '{' +
          '"low_battery":"' + BooleanToPostgreSQL(DeviceLowBatteryEdit.Checked) + '"' +
        '}';
    end;
    6: begin // Alerts
      MyOption :=
        '{' +
          '"alert_id":"' + AlertListEdit.Value + '",' +
          '"alert_critical":"' + BooleanToString(AlertCriticalEdit.Checked) + '"' +
        '}';
    end;
    7: begin // Insurance
      MyOption :=
        '{' +
          '"insurance_period":"' + BooleanToString(ReportTypeInsurancePeriod.Checked) + '",' +
          '"insurance_days_1":"' + EmptyStrToZero(InsuranceDays1Edit.Text) + '",' +
          '"insurance_days_2":"' + EmptyStrToZero(InsuranceDays2Edit.Text) + '"' +
        '}';
    end
    else begin
      MyOption := '{}';
    end;
  end;
  Result := MyOption;
end;

procedure TReportEditForm.ObjectFilterTypeEditChange(Sender: TObject);
begin
  SelectObjectFilterType(TLookupComboBox(Sender).Value, False);
  RecordChange(nil);
end;

procedure TReportEditForm.ObjectIDEditChange(Sender: TObject);
begin
  ObjectIDEdit.Tag := 1;
  RecordChange(nil);
end;

procedure TReportEditForm.ObjectIDEditEnter(Sender: TObject);
begin
  ObjectIDEdit.Tag := 0;
end;

procedure TReportEditForm.ObjectIDEditExit(Sender: TObject);
begin
  if ObjectIDEdit.Tag > 0 then begin
    if Trim(ObjectIDEdit.Text) <> EmptyStr then begin
      LoadReportMailToList();
    end;
  end;
end;

procedure TReportEditForm.ObjectNoEditChange(Sender: TObject);
begin
  ObjectNoEdit.Tag := 1;
  RecordChange(nil);
end;

procedure TReportEditForm.ObjectNoEditEnter(Sender: TObject);
begin
  ObjectNoEdit.Tag := 0;
end;

procedure TReportEditForm.ObjectNoEditExit(Sender: TObject);
begin
  if ObjectNoEdit.Tag > 0 then begin
    if Trim(ObjectNoEdit.Text) <> EmptyStr then begin
      LoadReportMailToList();
    end;
  end;
end;

function TReportEditForm.ReportMailToString(): String;
var
  RecNo: Integer;
  StringListTo, StringListNo: TStringList;
begin
  Result := EmptyStr;
  if SendToDataset.Active and not(SendToDataset.IsEmpty) then begin
    StringListTo := TStringList.Create();
    StringListNo := TStringLIst.Create();
    try
      SendToDataset.BeginUpdate;
      try
        RecNo := SendToDataset.RecNo;
        SendToDataset.First;
        while not(SendToDataset.Eof) do begin
          if SendToDataset.FieldByName('Checked').AsString = 't' then begin
            StringListTo.Add(SendToDataset.FieldByName('PKey').AsString);
          end else begin
            StringListNo.Add(SendToDataset.FieldByName('PKey').AsString);
          end;
          SendToDataset.Next;
        end;
        SendToDataset.RecNo := RecNo;
      finally
        SendToDataset.EndUpdate;
      end;
      Result :=
        '"rl_group_email_to": "' + StringListTo.DelimitedText + '",' +
        '"rl_group_email_no": "' + StringListNo.DelimitedText + '",';
    finally
      StringListNo.Free;
    end;
  end;
end;


function TReportEditForm.SaveReport(): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"rl_pk": "' + IntToStr(ReportPKey) + '",' +
      '"rl_id": "' + ReportTitleEdit.Text + '",' +
      '"rl_type": "' + ReportTypeEdit.Value + '",' +
      '"rl_type_string": "' + ReportTypeEdit.DisplayText + '",' +
      '"rl_format": "' + ReportFormatEdit.Value + '",' +
      '"rl_format_string": "' + ReportFormatEdit.DisplayText + '",' +
      '"rl_period": "' + PeriodEdit.Value + '",' +
      '"rl_period_string": "' + PeriodEdit.DisplayText + '",' +
      '"rl_date_from": "' + FormatDateTime('yyyy-mm-dd', DateFromEdit.Date) + '",' +
      '"rl_date_till": "' + FormatDatetime('yyyy-mm-dd', DateTillEdit.Date) + '",' +
      '"rl_schedule_type": "' + ScheduleTypeEdit.Value + '",' +
      '"rl_schedule_weekday": "' + WeekDayToString() + '",' +
      '"rl_schedule_day": "' + Trim(ScheduleDayEdit.Text) + '",' +
      '"rl_schedule_month": "' + ScheduleMonthEdit.Value + '",' +
      '"rl_mailto_myself": "' + BooleanToString(MailToMyselfEdit.Checked) + '",' +
      // Asset Groups
      '"rl_mailto_groups": "' + BooleanToString(MailToGroupsEdit.Checked) + '",' +
      ReportMailToString() +
      '"rl_recipient_list": "' + RecipientListEdit.Text + '",';
  case StrToInt(ObjectFilterTypeEdit.Value) of
    1: begin
      MyString := MyString + '"rl_object_no":"' + ObjectNoEdit.Text + '",';
    end;
    2: begin
      MyString := MyString + '"rl_object_id":"' + ObjectIDEdit.Text + '",';
    end;
    3: begin
      MyString := MyString +
        '"rl_filter_ogpk": "' + TeamFilterEdit.Value + '",' +
        '"rl_filter_otpk": "' + TypeFilterEdit.Value + '",';
    end;
  end;
  MyString := MyString +
      '"rl_option_list": ' + await(MakeOptionList()) +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_report_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      ReportPKey := StrToInt(MyJSONObject.GetJSONValue('report_pkey'));
      Result := True;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TReportEditForm.ScheduleTypeEditChange(Sender: TObject);
var
  ScheduleType: Integer;
begin
  ScheduleType :=
    StrToInt(TLookupComboBox(Sender).Value);
  // Week Days
  case ScheduleType of
    1: begin
      MondayEdit.Visible := True;
      TuesdayEdit.Visible := True;
      WednesdayEdit.Visible := True;
      ThursdayEdit.Visible := True;
      FridayEdit.Visible := True;
      SaturdayEdit.Visible := True;
      SundayEdit.Visible := True;
    end;
    else begin
      // Hide
      MondayEdit.Visible := False;
      TuesdayEdit.Visible := False;
      WednesdayEdit.Visible := False;
      ThursdayEdit.Visible := False;
      FridayEdit.Visible := False;
      SaturdayEdit.Visible := False;
      SundayEdit.Visible := False;
      // Uncheck
      MondayEdit.Checked := False;
      TuesdayEdit.Checked := False;
      WednesdayEdit.Checked := False;
      ThursdayEdit.Checked := False;
      FridayEdit.Checked := False;
      SaturdayEdit.Checked := False;
      SundayEdit.Checked := False;
    end;
  end;
  // Day of the month
  ScheduleDayLabel.Visible := ScheduleType in [2,3];
  ScheduleDayEdit.Visible := ScheduleType in [2,3];
  ScheduleDayEdit.Text := '1';
  // Month of the year
  ScheduleMonthLabel.Visible := ScheduleType = 3;
  ScheduleMonthEdit.Visible := ScheduleType = 3;
  ScheduleMonthEdit.Value := '1';
  RecordChange(nil);
end;

procedure TReportEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ReportTypeLabel := TLabel.Create(Self);
  ReportFormatLabel := TLabel.Create(Self);
  PeriodLabel := TLabel.Create(Self);
  DateFromLabel := TLabel.Create(Self);
  DateTillLabel := TLabel.Create(Self);
  ReportTitleLabel := TLabel.Create(Self);
  ObjectFilterTypeLabel := TLabel.Create(Self);
  TeamFilterEdit := TLookupComboBox.Create(Self);
  ObjectNoEdit := TEdit.Create(Self);
  ObjectIDEdit := TEdit.Create(Self);
  ReportTypeEdit := TLookupComboBox.Create(Self);
  ReportFormatEdit := TLookupComboBox.Create(Self);
  PeriodEdit := TLookupComboBox.Create(Self);
  DateTillEdit := TDateTimePicker.Create(Self);
  DateFromEdit := TDateTimePicker.Create(Self);
  TypeFilterEdit := TLookupComboBox.Create(Self);
  ReportTitleEdit := TEdit.Create(Self);
  ListButtonAllPanel := TTMSFNCPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  PrintButton := TSpeedButton.Create(Self);
  MailButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  DeselectButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  ObjectFilterTypeEdit := TLookupComboBox.Create(Self);
  ReportTypePageControl := TTMSFNCPageControl.Create(Self);
  ReportTypePageControlPage5 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  MailToMyselfEdit := TCheckBox.Create(Self);
  MailToGroupsEdit := TCheckBox.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  MailToLabel := TLabel.Create(Self);
  RecipientListEdit := TEdit.Create(Self);
  SendToGrid := TTMSFNCGrid.Create(Self);
  ReportTypePageControlPage3 := TTMSFNCPageControlContainer.Create(Self);
  ReportTypeDriverIgnoreDistanceEdit := TCheckBox.Create(Self);
  ReportTypePageControlPage4 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  MOTDays1Edit := TEdit.Create(Self);
  MOTDays2Edit := TEdit.Create(Self);
  ReportTypeMOTPeriod := TRadioButton.Create(Self);
  ReportTypeMOTDays := TRadioButton.Create(Self);
  ReportTypePageControlPage6 := TTMSFNCPageControlContainer.Create(Self);
  DeviceLowBatteryEdit := TCheckBox.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  ScheduleDayLabel := TLabel.Create(Self);
  ScheduleMonthLabel := TLabel.Create(Self);
  MondayEdit := TCheckBox.Create(Self);
  TuesdayEdit := TCheckBox.Create(Self);
  WednesdayEdit := TCheckBox.Create(Self);
  ThursdayEdit := TCheckBox.Create(Self);
  FridayEdit := TCheckBox.Create(Self);
  SaturdayEdit := TCheckBox.Create(Self);
  SundayEdit := TCheckBox.Create(Self);
  ScheduleTypeEdit := TLookupComboBox.Create(Self);
  ScheduleDayEdit := TEdit.Create(Self);
  ScheduleMonthEdit := TLookupComboBox.Create(Self);
  ReportTypePageControlPage8 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  InsuranceDays1Edit := TEdit.Create(Self);
  InsuranceDays2Edit := TEdit.Create(Self);
  ReportTypeInsurancePeriod := TRadioButton.Create(Self);
  ReportTypeInsuranceDays := TRadioButton.Create(Self);
  ReportTypePageControlPage7 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  AlertListEdit := TLookupComboBox.Create(Self);
  AlertCriticalEdit := TCheckBox.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  AssetDistanceMinLabel := TLabel.Create(Self);
  AssetDistanceMaxLabel := TLabel.Create(Self);
  ReportTypeAssetActiveAllEdit := TRadioButton.Create(Self);
  ReportTypeAssetActiveYesEdit := TRadioButton.Create(Self);
  ReportTypeAssetActiveNoEdit := TRadioButton.Create(Self);
  AssetStatusEdit := TLookupComboBox.Create(Self);
  ReportTypeAssetActiveNoTypeEdit := TLookupComboBox.Create(Self);
  AssetDistanceEdit := TCheckBox.Create(Self);
  AssetDistanceTypeEdit := TLookupComboBox.Create(Self);
  AssetDistanceMinEdit := TEdit.Create(Self);
  AssetDistanceMaxEdit := TEdit.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  ReportTypeTripAllEdit := TRadioButton.Create(Self);
  ReportTypeTripFirstEdit := TRadioButton.Create(Self);
  ReportTypeTripLastEdit := TRadioButton.Create(Self);
  ReportTypeTripBothEdit := TRadioButton.Create(Self);
  IgnoreDistanceEdit := TCheckBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  SendToDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  SendToDataSet := TClientDataSet.Create(Self);
  SendToDataSetPKey := TStringField.Create(Self);
  SendToDataSetChecked := TStringField.Create(Self);
  SendToDataSetEmail := TStringField.Create(Self);
  SendToDataSetAssetGroup := TStringField.Create(Self);
  SendToDataSource := TDataSource.Create(Self);
  SendToTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ReportTypeLabel.BeforeLoadDFMValues;
  ReportFormatLabel.BeforeLoadDFMValues;
  PeriodLabel.BeforeLoadDFMValues;
  DateFromLabel.BeforeLoadDFMValues;
  DateTillLabel.BeforeLoadDFMValues;
  ReportTitleLabel.BeforeLoadDFMValues;
  ObjectFilterTypeLabel.BeforeLoadDFMValues;
  TeamFilterEdit.BeforeLoadDFMValues;
  ObjectNoEdit.BeforeLoadDFMValues;
  ObjectIDEdit.BeforeLoadDFMValues;
  ReportTypeEdit.BeforeLoadDFMValues;
  ReportFormatEdit.BeforeLoadDFMValues;
  PeriodEdit.BeforeLoadDFMValues;
  DateTillEdit.BeforeLoadDFMValues;
  DateFromEdit.BeforeLoadDFMValues;
  TypeFilterEdit.BeforeLoadDFMValues;
  ReportTitleEdit.BeforeLoadDFMValues;
  ListButtonAllPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  PrintButton.BeforeLoadDFMValues;
  MailButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  DeselectButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  ObjectFilterTypeEdit.BeforeLoadDFMValues;
  ReportTypePageControl.BeforeLoadDFMValues;
  ReportTypePageControlPage5.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  MailToMyselfEdit.BeforeLoadDFMValues;
  MailToGroupsEdit.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  MailToLabel.BeforeLoadDFMValues;
  RecipientListEdit.BeforeLoadDFMValues;
  SendToGrid.BeforeLoadDFMValues;
  ReportTypePageControlPage3.BeforeLoadDFMValues;
  ReportTypeDriverIgnoreDistanceEdit.BeforeLoadDFMValues;
  ReportTypePageControlPage4.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  MOTDays1Edit.BeforeLoadDFMValues;
  MOTDays2Edit.BeforeLoadDFMValues;
  ReportTypeMOTPeriod.BeforeLoadDFMValues;
  ReportTypeMOTDays.BeforeLoadDFMValues;
  ReportTypePageControlPage6.BeforeLoadDFMValues;
  DeviceLowBatteryEdit.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  ScheduleDayLabel.BeforeLoadDFMValues;
  ScheduleMonthLabel.BeforeLoadDFMValues;
  MondayEdit.BeforeLoadDFMValues;
  TuesdayEdit.BeforeLoadDFMValues;
  WednesdayEdit.BeforeLoadDFMValues;
  ThursdayEdit.BeforeLoadDFMValues;
  FridayEdit.BeforeLoadDFMValues;
  SaturdayEdit.BeforeLoadDFMValues;
  SundayEdit.BeforeLoadDFMValues;
  ScheduleTypeEdit.BeforeLoadDFMValues;
  ScheduleDayEdit.BeforeLoadDFMValues;
  ScheduleMonthEdit.BeforeLoadDFMValues;
  ReportTypePageControlPage8.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  InsuranceDays1Edit.BeforeLoadDFMValues;
  InsuranceDays2Edit.BeforeLoadDFMValues;
  ReportTypeInsurancePeriod.BeforeLoadDFMValues;
  ReportTypeInsuranceDays.BeforeLoadDFMValues;
  ReportTypePageControlPage7.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  AlertListEdit.BeforeLoadDFMValues;
  AlertCriticalEdit.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  AssetDistanceMinLabel.BeforeLoadDFMValues;
  AssetDistanceMaxLabel.BeforeLoadDFMValues;
  ReportTypeAssetActiveAllEdit.BeforeLoadDFMValues;
  ReportTypeAssetActiveYesEdit.BeforeLoadDFMValues;
  ReportTypeAssetActiveNoEdit.BeforeLoadDFMValues;
  AssetStatusEdit.BeforeLoadDFMValues;
  ReportTypeAssetActiveNoTypeEdit.BeforeLoadDFMValues;
  AssetDistanceEdit.BeforeLoadDFMValues;
  AssetDistanceTypeEdit.BeforeLoadDFMValues;
  AssetDistanceMinEdit.BeforeLoadDFMValues;
  AssetDistanceMaxEdit.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  ReportTypeTripAllEdit.BeforeLoadDFMValues;
  ReportTypeTripFirstEdit.BeforeLoadDFMValues;
  ReportTypeTripLastEdit.BeforeLoadDFMValues;
  ReportTypeTripBothEdit.BeforeLoadDFMValues;
  IgnoreDistanceEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  SendToDBAdapter.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  SendToDataSet.BeforeLoadDFMValues;
  SendToDataSetPKey.BeforeLoadDFMValues;
  SendToDataSetChecked.BeforeLoadDFMValues;
  SendToDataSetEmail.BeforeLoadDFMValues;
  SendToDataSetAssetGroup.BeforeLoadDFMValues;
  SendToDataSource.BeforeLoadDFMValues;
  SendToTimer.BeforeLoadDFMValues;
  try
    Name := 'ReportEditForm';
    Width := 874;
    Height := 740;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 874;
    FormPanel.Height := 740;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 868;
    ContentPanel.Height := 697;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ReportTypeLabel.SetParentComponent(ContentPanel);
    ReportTypeLabel.Name := 'ReportTypeLabel';
    ReportTypeLabel.Left := 20;
    ReportTypeLabel.Top := 90;
    ReportTypeLabel.Width := 32;
    ReportTypeLabel.Height := 21;
    ReportTypeLabel.Caption := 'Type';
    ReportTypeLabel.Font.Charset := ANSI_CHARSET;
    ReportTypeLabel.Font.Color := 9010544;
    ReportTypeLabel.Font.Height := -16;
    ReportTypeLabel.Font.Name := 'Segoe UI';
    ReportTypeLabel.Font.Style := [];
    ReportTypeLabel.HeightPercent := 100.000000000000000000;
    ReportTypeLabel.ParentFont := False;
    ReportTypeLabel.WidthPercent := 100.000000000000000000;
    ReportFormatLabel.SetParentComponent(ContentPanel);
    ReportFormatLabel.Name := 'ReportFormatLabel';
    ReportFormatLabel.Left := 496;
    ReportFormatLabel.Top := 90;
    ReportFormatLabel.Width := 50;
    ReportFormatLabel.Height := 21;
    ReportFormatLabel.Caption := 'Format';
    ReportFormatLabel.Font.Charset := ANSI_CHARSET;
    ReportFormatLabel.Font.Color := 9010544;
    ReportFormatLabel.Font.Height := -16;
    ReportFormatLabel.Font.Name := 'Segoe UI';
    ReportFormatLabel.Font.Style := [];
    ReportFormatLabel.HeightPercent := 100.000000000000000000;
    ReportFormatLabel.ParentFont := False;
    ReportFormatLabel.WidthPercent := 100.000000000000000000;
    PeriodLabel.SetParentComponent(ContentPanel);
    PeriodLabel.Name := 'PeriodLabel';
    PeriodLabel.Left := 20;
    PeriodLabel.Top := 196;
    PeriodLabel.Width := 44;
    PeriodLabel.Height := 21;
    PeriodLabel.Caption := 'Period';
    PeriodLabel.Font.Charset := ANSI_CHARSET;
    PeriodLabel.Font.Color := 9010544;
    PeriodLabel.Font.Height := -16;
    PeriodLabel.Font.Name := 'Segoe UI';
    PeriodLabel.Font.Style := [];
    PeriodLabel.HeightPercent := 100.000000000000000000;
    PeriodLabel.ParentFont := False;
    PeriodLabel.WidthPercent := 100.000000000000000000;
    DateFromLabel.SetParentComponent(ContentPanel);
    DateFromLabel.Name := 'DateFromLabel';
    DateFromLabel.Left := 20;
    DateFromLabel.Top := 238;
    DateFromLabel.Width := 73;
    DateFromLabel.Height := 21;
    DateFromLabel.Caption := 'Date From';
    DateFromLabel.Font.Charset := ANSI_CHARSET;
    DateFromLabel.Font.Color := 9010544;
    DateFromLabel.Font.Height := -16;
    DateFromLabel.Font.Name := 'Segoe UI';
    DateFromLabel.Font.Style := [];
    DateFromLabel.HeightPercent := 100.000000000000000000;
    DateFromLabel.ParentFont := False;
    DateFromLabel.WidthPercent := 100.000000000000000000;
    DateTillLabel.SetParentComponent(ContentPanel);
    DateTillLabel.Name := 'DateTillLabel';
    DateTillLabel.Left := 20;
    DateTillLabel.Top := 277;
    DateTillLabel.Width := 56;
    DateTillLabel.Height := 21;
    DateTillLabel.Caption := 'Date Till';
    DateTillLabel.Font.Charset := ANSI_CHARSET;
    DateTillLabel.Font.Color := 9010544;
    DateTillLabel.Font.Height := -16;
    DateTillLabel.Font.Name := 'Segoe UI';
    DateTillLabel.Font.Style := [];
    DateTillLabel.HeightPercent := 100.000000000000000000;
    DateTillLabel.ParentFont := False;
    DateTillLabel.WidthPercent := 100.000000000000000000;
    ReportTitleLabel.SetParentComponent(ContentPanel);
    ReportTitleLabel.Name := 'ReportTitleLabel';
    ReportTitleLabel.Left := 20;
    ReportTitleLabel.Top := 16;
    ReportTitleLabel.Width := 29;
    ReportTitleLabel.Height := 21;
    ReportTitleLabel.Caption := 'Title';
    ReportTitleLabel.Font.Charset := ANSI_CHARSET;
    ReportTitleLabel.Font.Color := 9010544;
    ReportTitleLabel.Font.Height := -16;
    ReportTitleLabel.Font.Name := 'Segoe UI';
    ReportTitleLabel.Font.Style := [];
    ReportTitleLabel.HeightPercent := 100.000000000000000000;
    ReportTitleLabel.ParentFont := False;
    ReportTitleLabel.WidthPercent := 100.000000000000000000;
    ObjectFilterTypeLabel.SetParentComponent(ContentPanel);
    ObjectFilterTypeLabel.Name := 'ObjectFilterTypeLabel';
    ObjectFilterTypeLabel.Left := 494;
    ObjectFilterTypeLabel.Top := 196;
    ObjectFilterTypeLabel.Width := 76;
    ObjectFilterTypeLabel.Height := 21;
    ObjectFilterTypeLabel.Caption := 'Asset Filter';
    ObjectFilterTypeLabel.Font.Charset := ANSI_CHARSET;
    ObjectFilterTypeLabel.Font.Color := 9010544;
    ObjectFilterTypeLabel.Font.Height := -16;
    ObjectFilterTypeLabel.Font.Name := 'Segoe UI';
    ObjectFilterTypeLabel.Font.Style := [];
    ObjectFilterTypeLabel.HeightPercent := 100.000000000000000000;
    ObjectFilterTypeLabel.ParentFont := False;
    ObjectFilterTypeLabel.WidthPercent := 100.000000000000000000;
    TeamFilterEdit.SetParentComponent(ContentPanel);
    TeamFilterEdit.Name := 'TeamFilterEdit';
    TeamFilterEdit.AlignWithMargins := True;
    TeamFilterEdit.Left := 496;
    TeamFilterEdit.Top := 234;
    TeamFilterEdit.Width := 345;
    TeamFilterEdit.Height := 34;
    TeamFilterEdit.Margins.Left := 20;
    TeamFilterEdit.Margins.Top := 8;
    TeamFilterEdit.Margins.Bottom := 8;
    TeamFilterEdit.Color := clWhite;
    TeamFilterEdit.ElementClassName := 'myselect';
    TeamFilterEdit.Font.Charset := DEFAULT_CHARSET;
    TeamFilterEdit.Font.Color := clWindowText;
    TeamFilterEdit.Font.Height := 12;
    TeamFilterEdit.Font.Name := 'Segoe UI';
    TeamFilterEdit.Font.Style := [];
    TeamFilterEdit.HeightPercent := 100.000000000000000000;
    TeamFilterEdit.ParentFont := False;
    TeamFilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TeamFilterEdit, Self, 'OnChange', 'TeamFilterEditChange');
    TeamFilterEdit.ItemIndex := -1;
    TeamFilterEdit.LookupValues.Clear;
    with TeamFilterEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'A';
    end;
    with TeamFilterEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'B';
    end;
    with TeamFilterEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'C';
    end;
    ObjectNoEdit.SetParentComponent(ContentPanel);
    ObjectNoEdit.Name := 'ObjectNoEdit';
    ObjectNoEdit.Left := 496;
    ObjectNoEdit.Top := 234;
    ObjectNoEdit.Width := 346;
    ObjectNoEdit.Height := 34;
    ObjectNoEdit.ChildOrder := 1;
    ObjectNoEdit.EditType := weNumeric;
    ObjectNoEdit.ElementClassName := 'MyWebEdit';
    ObjectNoEdit.Font.Charset := ANSI_CHARSET;
    ObjectNoEdit.Font.Color := clWindowText;
    ObjectNoEdit.Font.Height := -16;
    ObjectNoEdit.Font.Name := 'Segoe UI';
    ObjectNoEdit.Font.Style := [];
    ObjectNoEdit.HeightPercent := 100.000000000000000000;
    ObjectNoEdit.ParentFont := False;
    ObjectNoEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ObjectNoEdit, Self, 'OnChange', 'ObjectNoEditChange');
    SetEvent(ObjectNoEdit, Self, 'OnEnter', 'ObjectNoEditEnter');
    SetEvent(ObjectNoEdit, Self, 'OnExit', 'ObjectNoEditExit');
    ObjectIDEdit.SetParentComponent(ContentPanel);
    ObjectIDEdit.Name := 'ObjectIDEdit';
    ObjectIDEdit.Left := 496;
    ObjectIDEdit.Top := 234;
    ObjectIDEdit.Width := 345;
    ObjectIDEdit.Height := 34;
    ObjectIDEdit.CharCase := wecUpperCase;
    ObjectIDEdit.ChildOrder := 1;
    ObjectIDEdit.ElementClassName := 'MyWebEdit';
    ObjectIDEdit.Font.Charset := ANSI_CHARSET;
    ObjectIDEdit.Font.Color := clWindowText;
    ObjectIDEdit.Font.Height := -16;
    ObjectIDEdit.Font.Name := 'Segoe UI';
    ObjectIDEdit.Font.Style := [];
    ObjectIDEdit.HeightPercent := 100.000000000000000000;
    ObjectIDEdit.ParentFont := False;
    ObjectIDEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ObjectIDEdit, Self, 'OnChange', 'ObjectIDEditChange');
    SetEvent(ObjectIDEdit, Self, 'OnEnter', 'ObjectIDEditEnter');
    SetEvent(ObjectIDEdit, Self, 'OnExit', 'ObjectIDEditExit');
    ReportTypeEdit.SetParentComponent(ContentPanel);
    ReportTypeEdit.Name := 'ReportTypeEdit';
    ReportTypeEdit.AlignWithMargins := True;
    ReportTypeEdit.Left := 20;
    ReportTypeEdit.Top := 122;
    ReportTypeEdit.Width := 345;
    ReportTypeEdit.Height := 34;
    ReportTypeEdit.Margins.Left := 20;
    ReportTypeEdit.Margins.Top := 8;
    ReportTypeEdit.Margins.Bottom := 8;
    ReportTypeEdit.Color := clWhite;
    ReportTypeEdit.ElementClassName := 'myselect';
    ReportTypeEdit.Font.Charset := DEFAULT_CHARSET;
    ReportTypeEdit.Font.Color := clWindowText;
    ReportTypeEdit.Font.Height := 12;
    ReportTypeEdit.Font.Name := 'Segoe UI';
    ReportTypeEdit.Font.Style := [];
    ReportTypeEdit.HeightPercent := 100.000000000000000000;
    ReportTypeEdit.ParentFont := False;
    ReportTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeEdit, Self, 'OnChange', 'ReportTypeEditChange');
    ReportTypeEdit.ItemIndex := -1;
    ReportTypeEdit.LookupValues.Clear;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'ROUTES';
    end;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'ASSETS';
    end;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'DRIVER';
    end;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'MOT';
    end;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'DEVICES';
    end;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '6';
      DisplayText := 'ALERTS';
    end;
    with ReportTypeEdit.LookupValues.Add do
    begin
      Value := '7';
      DisplayText := 'INSURANCE';
    end;
    ReportFormatEdit.SetParentComponent(ContentPanel);
    ReportFormatEdit.Name := 'ReportFormatEdit';
    ReportFormatEdit.AlignWithMargins := True;
    ReportFormatEdit.Left := 496;
    ReportFormatEdit.Top := 122;
    ReportFormatEdit.Width := 345;
    ReportFormatEdit.Height := 34;
    ReportFormatEdit.Margins.Left := 20;
    ReportFormatEdit.Margins.Top := 8;
    ReportFormatEdit.Margins.Bottom := 8;
    ReportFormatEdit.Color := clWhite;
    ReportFormatEdit.ElementClassName := 'myselect';
    ReportFormatEdit.Font.Charset := DEFAULT_CHARSET;
    ReportFormatEdit.Font.Color := clWindowText;
    ReportFormatEdit.Font.Height := 12;
    ReportFormatEdit.Font.Name := 'Segoe UI';
    ReportFormatEdit.Font.Style := [];
    ReportFormatEdit.HeightPercent := 100.000000000000000000;
    ReportFormatEdit.ParentFont := False;
    ReportFormatEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportFormatEdit, Self, 'OnChange', 'RecordChange');
    ReportFormatEdit.ItemIndex := -1;
    ReportFormatEdit.LookupValues.Clear;
    with ReportFormatEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'PDF';
    end;
    with ReportFormatEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'XLS';
    end;
    PeriodEdit.SetParentComponent(ContentPanel);
    PeriodEdit.Name := 'PeriodEdit';
    PeriodEdit.AlignWithMargins := True;
    PeriodEdit.Left := 107;
    PeriodEdit.Top := 190;
    PeriodEdit.Width := 262;
    PeriodEdit.Height := 34;
    PeriodEdit.Margins.Left := 20;
    PeriodEdit.Margins.Top := 8;
    PeriodEdit.Margins.Bottom := 8;
    PeriodEdit.Color := clWhite;
    PeriodEdit.ElementClassName := 'myselect';
    PeriodEdit.Font.Charset := DEFAULT_CHARSET;
    PeriodEdit.Font.Color := clWindowText;
    PeriodEdit.Font.Height := 12;
    PeriodEdit.Font.Name := 'Segoe UI';
    PeriodEdit.Font.Style := [];
    PeriodEdit.HeightPercent := 100.000000000000000000;
    PeriodEdit.ParentFont := False;
    PeriodEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PeriodEdit, Self, 'OnChange', 'PeriodEditChange');
    PeriodEdit.ItemIndex := -1;
    PeriodEdit.LookupValues.Clear;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Today';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Yesterday';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'This week';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '10';
      DisplayText := 'Next Week';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'Last Week';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'Last Two Weeks';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '6';
      DisplayText := 'This Month';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '11';
      DisplayText := 'Next Month';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '7';
      DisplayText := 'Last Month';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '12';
      DisplayText := 'Last Three Months';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '8';
      DisplayText := 'This Year';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '9';
      DisplayText := 'Last Year';
    end;
    with PeriodEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'Custom Period';
    end;
    DateTillEdit.SetParentComponent(ContentPanel);
    DateTillEdit.Name := 'DateTillEdit';
    DateTillEdit.AlignWithMargins := True;
    DateTillEdit.Left := 107;
    DateTillEdit.Top := 274;
    DateTillEdit.Width := 143;
    DateTillEdit.Height := 34;
    DateTillEdit.Margins.Left := 20;
    DateTillEdit.Margins.Top := 8;
    DateTillEdit.Margins.Bottom := 8;
    DateTillEdit.BorderStyle := bsSingle;
    DateTillEdit.ChildOrder := 2;
    DateTillEdit.Color := clWhite;
    DateTillEdit.Date := 44545.380967847220000000;
    DateTillEdit.Font.Charset := ANSI_CHARSET;
    DateTillEdit.Font.Color := clWindowText;
    DateTillEdit.Font.Height := -16;
    DateTillEdit.Font.Name := 'Segoe UI';
    DateTillEdit.Font.Style := [];
    DateTillEdit.ParentFont := False;
    DateTillEdit.Role := '';
    DateTillEdit.Text := '';
    SetEvent(DateTillEdit, Self, 'OnChange', 'RecordChange');
    DateFromEdit.SetParentComponent(ContentPanel);
    DateFromEdit.Name := 'DateFromEdit';
    DateFromEdit.AlignWithMargins := True;
    DateFromEdit.Left := 107;
    DateFromEdit.Top := 233;
    DateFromEdit.Width := 143;
    DateFromEdit.Height := 34;
    DateFromEdit.Margins.Left := 20;
    DateFromEdit.Margins.Top := 8;
    DateFromEdit.Margins.Bottom := 8;
    DateFromEdit.BorderStyle := bsSingle;
    DateFromEdit.ChildOrder := 2;
    DateFromEdit.Color := clWhite;
    DateFromEdit.Date := 44545.380967847220000000;
    DateFromEdit.Font.Charset := ANSI_CHARSET;
    DateFromEdit.Font.Color := clWindowText;
    DateFromEdit.Font.Height := -16;
    DateFromEdit.Font.Name := 'Segoe UI';
    DateFromEdit.Font.Style := [];
    DateFromEdit.ParentFont := False;
    DateFromEdit.Role := '';
    DateFromEdit.Text := '';
    SetEvent(DateFromEdit, Self, 'OnChange', 'RecordChange');
    TypeFilterEdit.SetParentComponent(ContentPanel);
    TypeFilterEdit.Name := 'TypeFilterEdit';
    TypeFilterEdit.AlignWithMargins := True;
    TypeFilterEdit.Left := 496;
    TypeFilterEdit.Top := 279;
    TypeFilterEdit.Width := 346;
    TypeFilterEdit.Height := 34;
    TypeFilterEdit.Margins.Left := 20;
    TypeFilterEdit.Margins.Top := 8;
    TypeFilterEdit.Margins.Bottom := 8;
    TypeFilterEdit.Color := clWhite;
    TypeFilterEdit.ElementClassName := 'myselect';
    TypeFilterEdit.Font.Charset := DEFAULT_CHARSET;
    TypeFilterEdit.Font.Color := clWindowText;
    TypeFilterEdit.Font.Height := 12;
    TypeFilterEdit.Font.Name := 'Segoe UI';
    TypeFilterEdit.Font.Style := [];
    TypeFilterEdit.HeightPercent := 100.000000000000000000;
    TypeFilterEdit.ParentFont := False;
    TypeFilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TypeFilterEdit, Self, 'OnChange', 'RecordChange');
    TypeFilterEdit.ItemIndex := -1;
    TypeFilterEdit.LookupValues.Clear;
    with TypeFilterEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'A';
    end;
    with TypeFilterEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'B';
    end;
    with TypeFilterEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'C';
    end;
    ReportTitleEdit.SetParentComponent(ContentPanel);
    ReportTitleEdit.Name := 'ReportTitleEdit';
    ReportTitleEdit.Left := 20;
    ReportTitleEdit.Top := 43;
    ReportTitleEdit.Width := 821;
    ReportTitleEdit.Height := 34;
    ReportTitleEdit.ChildOrder := 1;
    ReportTitleEdit.ElementClassName := 'MyWebEdit';
    ReportTitleEdit.Font.Charset := ANSI_CHARSET;
    ReportTitleEdit.Font.Color := clWindowText;
    ReportTitleEdit.Font.Height := -16;
    ReportTitleEdit.Font.Name := 'Segoe UI';
    ReportTitleEdit.Font.Style := [];
    ReportTitleEdit.HeightPercent := 100.000000000000000000;
    ReportTitleEdit.ParentFont := False;
    ReportTitleEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTitleEdit, Self, 'OnChange', 'RecordChange');
    ListButtonAllPanel.SetParentComponent(ContentPanel);
    ListButtonAllPanel.Name := 'ListButtonAllPanel';
    ListButtonAllPanel.Tag := 1;
    ListButtonAllPanel.Left := 0;
    ListButtonAllPanel.Top := 648;
    ListButtonAllPanel.Width := 868;
    ListButtonAllPanel.Height := 49;
    ListButtonAllPanel.Align := alBottom;
    ListButtonAllPanel.BevelInner := bvNone;
    ListButtonAllPanel.BevelOuter := bvNone;
    ListButtonAllPanel.Ctl3D := True;
    ListButtonAllPanel.ParentCtl3D := False;
    ListButtonAllPanel.ParentDoubleBuffered := False;
    ListButtonAllPanel.DoubleBuffered := True;
    ListButtonAllPanel.TabOrder := 8;
    ListButtonAllPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonAllPanel.SectionsAppearance.Font.Height := -11;
    ListButtonAllPanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonAllPanel.SectionsAppearance.Font.Style := [];
    ListButtonAllPanel.Stroke.Kind := gskNone;
    ListButtonAllPanel.Header.Text := 'Header';
    ListButtonAllPanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.Header.Font.Color := clWindowText;
    ListButtonAllPanel.Header.Font.Height := -11;
    ListButtonAllPanel.Header.Font.Name := 'Tahoma';
    ListButtonAllPanel.Header.Font.Style := [];
    ListButtonAllPanel.Header.Visible := False;
    ListButtonAllPanel.Footer.Text := 'Footer';
    ListButtonAllPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.Footer.Font.Color := clWindowText;
    ListButtonAllPanel.Footer.Font.Height := -11;
    ListButtonAllPanel.Footer.Font.Name := 'Tahoma';
    ListButtonAllPanel.Footer.Font.Style := [];
    SaveButton.SetParentComponent(ListButtonAllPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 638;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 0;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uReportEdit.ListButtonAllPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(ListButtonAllPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 748;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uReportEdit.ListButtonAllPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    PrintButton.SetParentComponent(ListButtonAllPanel);
    PrintButton.Name := 'PrintButton';
    PrintButton.Tag := 1;
    PrintButton.AlignWithMargins := True;
    PrintButton.Left := 137;
    PrintButton.Top := 8;
    PrintButton.Width := 109;
    PrintButton.Height := 31;
    PrintButton.Margins.Left := 0;
    PrintButton.Margins.Top := 8;
    PrintButton.Margins.Right := 8;
    PrintButton.Margins.Bottom := 10;
    PrintButton.Align := alLeft;
    PrintButton.Caption := 'Print';
    PrintButton.Color := clNone;
    PrintButton.ElementClassName := 'MyWebButton';
    PrintButton.Font.Charset := ANSI_CHARSET;
    PrintButton.Font.Color := clWindowText;
    PrintButton.Font.Height := -16;
    PrintButton.Font.Name := 'Segoe UI';
    PrintButton.Font.Style := [];
    PrintButton.Glyph.LoadFromFile('uReportEdit.ListButtonAllPanel.PrintButton.Glyph.png');
    PrintButton.HeightPercent := 100.000000000000000000;
    PrintButton.ParentFont := False;
    PrintButton.TabOrder := 2;
    PrintButton.Visible := False;
    PrintButton.WidthPercent := 100.000000000000000000;
    SetEvent(PrintButton, Self, 'OnClick', 'PrintMailButtonClick');
    MailButton.SetParentComponent(ListButtonAllPanel);
    MailButton.Name := 'MailButton';
    MailButton.Tag := 2;
    MailButton.AlignWithMargins := True;
    MailButton.Left := 20;
    MailButton.Top := 8;
    MailButton.Width := 109;
    MailButton.Height := 31;
    MailButton.Margins.Left := 0;
    MailButton.Margins.Top := 8;
    MailButton.Margins.Right := 8;
    MailButton.Margins.Bottom := 10;
    MailButton.Align := alLeft;
    MailButton.Caption := 'Mail';
    MailButton.Color := clNone;
    MailButton.ElementClassName := 'MyWebButton';
    MailButton.Font.Charset := ANSI_CHARSET;
    MailButton.Font.Color := clWindowText;
    MailButton.Font.Height := -16;
    MailButton.Font.Name := 'Segoe UI';
    MailButton.Font.Style := [];
    MailButton.Glyph.LoadFromFile('uReportEdit.ListButtonAllPanel.MailButton.Glyph.png');
    MailButton.HeightPercent := 100.000000000000000000;
    MailButton.ParentFont := False;
    MailButton.TabOrder := 3;
    MailButton.Visible := False;
    MailButton.WidthPercent := 100.000000000000000000;
    SetEvent(MailButton, Self, 'OnClick', 'PrintMailButtonClick');
    SelectButton.SetParentComponent(ListButtonAllPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 254;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 0;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 8;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uReportEdit.ListButtonAllPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 4;
    SelectButton.WidthPercent := 100.000000000000000000;
    DeselectButton.SetParentComponent(ListButtonAllPanel);
    DeselectButton.Name := 'DeselectButton';
    DeselectButton.AlignWithMargins := True;
    DeselectButton.Left := 362;
    DeselectButton.Top := 8;
    DeselectButton.Width := 100;
    DeselectButton.Height := 31;
    DeselectButton.Margins.Left := 0;
    DeselectButton.Margins.Top := 8;
    DeselectButton.Margins.Right := 8;
    DeselectButton.Margins.Bottom := 10;
    DeselectButton.Align := alLeft;
    DeselectButton.Caption := 'Deselect';
    DeselectButton.Color := clNone;
    DeselectButton.ElementClassName := 'MyWebButton';
    DeselectButton.Glyph.LoadFromFile('uReportEdit.ListButtonAllPanel.DeselectButton.Glyph.png');
    DeselectButton.HeightPercent := 100.000000000000000000;
    DeselectButton.TabOrder := 5;
    DeselectButton.WidthPercent := 100.000000000000000000;
    ListButtonOnlinePanel.SetParentComponent(ListButtonAllPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 6;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ObjectFilterTypeEdit.SetParentComponent(ContentPanel);
    ObjectFilterTypeEdit.Name := 'ObjectFilterTypeEdit';
    ObjectFilterTypeEdit.AlignWithMargins := True;
    ObjectFilterTypeEdit.Left := 593;
    ObjectFilterTypeEdit.Top := 190;
    ObjectFilterTypeEdit.Width := 248;
    ObjectFilterTypeEdit.Height := 34;
    ObjectFilterTypeEdit.Margins.Left := 20;
    ObjectFilterTypeEdit.Margins.Top := 8;
    ObjectFilterTypeEdit.Margins.Bottom := 8;
    ObjectFilterTypeEdit.Color := clWhite;
    ObjectFilterTypeEdit.ElementClassName := 'myselect';
    ObjectFilterTypeEdit.Font.Charset := DEFAULT_CHARSET;
    ObjectFilterTypeEdit.Font.Color := clWindowText;
    ObjectFilterTypeEdit.Font.Height := 12;
    ObjectFilterTypeEdit.Font.Name := 'Segoe UI';
    ObjectFilterTypeEdit.Font.Style := [];
    ObjectFilterTypeEdit.HeightPercent := 100.000000000000000000;
    ObjectFilterTypeEdit.ParentFont := False;
    ObjectFilterTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ObjectFilterTypeEdit, Self, 'OnChange', 'ObjectFilterTypeEditChange');
    ObjectFilterTypeEdit.ItemIndex := -1;
    ObjectFilterTypeEdit.LookupValues.Clear;
    with ObjectFilterTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'ASSET No.';
    end;
    with ObjectFilterTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'REGISTRATION PLATE';
    end;
    with ObjectFilterTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'GROUP / TYPE FILTER';
    end;
    ReportTypePageControl.SetParentComponent(ContentPanel);
    ReportTypePageControl.Name := 'ReportTypePageControl';
    ReportTypePageControl.AlignWithMargins := True;
    ReportTypePageControl.Left := 10;
    ReportTypePageControl.Top := 321;
    ReportTypePageControl.Width := 848;
    ReportTypePageControl.Height := 319;
    ReportTypePageControl.Margins.Left := 10;
    ReportTypePageControl.Margins.Top := 0;
    ReportTypePageControl.Margins.Right := 10;
    ReportTypePageControl.Margins.Bottom := 8;
    ReportTypePageControl.Align := alBottom;
    ReportTypePageControl.BevelEdges := [];
    ReportTypePageControl.BevelInner := bvNone;
    ReportTypePageControl.BevelOuter := bvNone;
    ReportTypePageControl.ParentDoubleBuffered := False;
    ReportTypePageControl.Color := clBtnFace;
    ReportTypePageControl.DoubleBuffered := True;
    ReportTypePageControl.Font.Charset := DEFAULT_CHARSET;
    ReportTypePageControl.Font.Color := clWindowText;
    ReportTypePageControl.Font.Height := -16;
    ReportTypePageControl.Font.Name := 'Tahoma';
    ReportTypePageControl.Font.Style := [];
    ReportTypePageControl.ParentFont := False;
    ReportTypePageControl.TabOrder := 12;
    ReportTypePageControl.Fill.Kind := gfkNone;
    ReportTypePageControl.Fill.ColorTo := clWhite;
    ReportTypePageControl.Stroke.Kind := gskNone;
    ReportTypePageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    ReportTypePageControl.TabAppearance.Font.Color := clAqua;
    ReportTypePageControl.TabAppearance.Font.Height := -16;
    ReportTypePageControl.TabAppearance.Font.Name := 'Segoe UI';
    ReportTypePageControl.TabAppearance.Font.Style := [];
    ReportTypePageControl.TabAppearance.Fill.Color := 14408667;
    ReportTypePageControl.TabAppearance.Stroke.Color := -1;
    ReportTypePageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    ReportTypePageControl.TabAppearance.HoverFill.Color := 15658734;
    ReportTypePageControl.TabAppearance.HoverStroke.Color := -1;
    ReportTypePageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    ReportTypePageControl.TabAppearance.Shape := tsRectangle;
    ReportTypePageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    ReportTypePageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    ReportTypePageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    ReportTypePageControl.TabAppearance.TextColor := clGrayText;
    ReportTypePageControl.TabAppearance.ActiveTextColor := clGrayText;
    ReportTypePageControl.TabAppearance.HoverTextColor := clGrayText;
    ReportTypePageControl.TabAppearance.ShowFocus := False;
    ReportTypePageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    ReportTypePageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    ReportTypePageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    ReportTypePageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    ReportTypePageControl.TabAppearance.BadgeFont.Color := 139;
    ReportTypePageControl.TabAppearance.BadgeFont.Height := -11;
    ReportTypePageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    ReportTypePageControl.TabAppearance.BadgeFont.Style := [fsBold];
    ReportTypePageControl.ButtonAppearance.CloseIcon.LoadFromFile('uReportEdit.ContentPanel.ReportTypePageControl.ButtonAppearance.CloseIcon.svg');
    ReportTypePageControl.ButtonAppearance.InsertIcon.LoadFromFile('uReportEdit.ContentPanel.ReportTypePageControl.ButtonAppearance.InsertIcon.svg');
    ReportTypePageControl.ButtonAppearance.TabListIcon.LoadFromFile('uReportEdit.ContentPanel.ReportTypePageControl.ButtonAppearance.TabListIcon.svg');
    ReportTypePageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uReportEdit.ContentPanel.ReportTypePageControl.ButtonAppearance.ScrollNextIcon.svg');
    ReportTypePageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uReportEdit.ContentPanel.ReportTypePageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    ReportTypePageControl.TabSize.Height := 45.000000000000000000;
    ReportTypePageControl.TabSize.Margins.Left := 8.000000000000000000;
    ReportTypePageControl.TabSize.Margins.Top := 8.000000000000000000;
    ReportTypePageControl.TabSize.Margins.Right := 8.000000000000000000;
    ReportTypePageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    ReportTypePageControl.Pages.Clear;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'ROUTES';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'ASSETS';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'DRIVER';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'MOT';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'DEVICES';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'ALERTS';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'INSURANCE';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'SCHEDULE';
      UseDefaultAppearance := True;
    end;
    with ReportTypePageControl.Pages.Add do
    begin
      Text := 'SCHEDULE MAIL TO';
      UseDefaultAppearance := True;
    end;
    SetEvent(ReportTypePageControl, Self, 'OnBeforeChangePage', 'ReportTypePageControlBeforeChangePage');
    SetEvent(ReportTypePageControl, Self, 'OnChangePage', 'ReportTypePageControlChangePage');
    ReportTypePageControlPage5.SetParentComponent(ReportTypePageControl);
    ReportTypePageControlPage5.Name := 'ReportTypePageControlPage5';
    ReportTypePageControlPage5.Tag := 1;
    ReportTypePageControlPage5.AlignWithMargins := True;
    ReportTypePageControlPage5.Left := 0;
    ReportTypePageControlPage5.Top := 52;
    ReportTypePageControlPage5.Width := 848;
    ReportTypePageControlPage5.Height := 267;
    ReportTypePageControlPage5.Margins.Left := 0;
    ReportTypePageControlPage5.Margins.Top := 52;
    ReportTypePageControlPage5.Margins.Right := 0;
    ReportTypePageControlPage5.Margins.Bottom := 0;
    ReportTypePageControlPage5.Align := alClient;
    ReportTypePageControlPage5.ParentDoubleBuffered := False;
    ReportTypePageControlPage5.DoubleBuffered := True;
    ReportTypePageControlPage5.TabStop := False;
    ReportTypePageControlPage5.TabOrder := 5;
    ReportTypePageControlPage5.Visible := False;
    ReportTypePageControlPage5.PageIndex := 8;
    ReportTypePageControlPage5.IsActive := False;
    TMSFNCPanel1.SetParentComponent(ReportTypePageControlPage5);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 3;
    TMSFNCPanel1.Width := 842;
    TMSFNCPanel1.Height := 42;
    TMSFNCPanel1.Align := alTop;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    MailToMyselfEdit.SetParentComponent(TMSFNCPanel1);
    MailToMyselfEdit.Name := 'MailToMyselfEdit';
    MailToMyselfEdit.Left := 7;
    MailToMyselfEdit.Top := 10;
    MailToMyselfEdit.Width := 78;
    MailToMyselfEdit.Height := 26;
    MailToMyselfEdit.Caption := 'Myself';
    MailToMyselfEdit.ChildOrder := 28;
    MailToMyselfEdit.Font.Charset := ANSI_CHARSET;
    MailToMyselfEdit.Font.Color := clWindowText;
    MailToMyselfEdit.Font.Height := -16;
    MailToMyselfEdit.Font.Name := 'Segoe UI';
    MailToMyselfEdit.Font.Style := [];
    MailToMyselfEdit.HeightPercent := 100.000000000000000000;
    MailToMyselfEdit.ParentFont := False;
    MailToMyselfEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MailToMyselfEdit, Self, 'OnClick', 'RecordChange');
    MailToGroupsEdit.SetParentComponent(TMSFNCPanel1);
    MailToGroupsEdit.Name := 'MailToGroupsEdit';
    MailToGroupsEdit.Left := 96;
    MailToGroupsEdit.Top := 10;
    MailToGroupsEdit.Width := 119;
    MailToGroupsEdit.Height := 26;
    MailToGroupsEdit.Caption := 'Asset groups';
    MailToGroupsEdit.ChildOrder := 28;
    MailToGroupsEdit.Font.Charset := ANSI_CHARSET;
    MailToGroupsEdit.Font.Color := clWindowText;
    MailToGroupsEdit.Font.Height := -16;
    MailToGroupsEdit.Font.Name := 'Segoe UI';
    MailToGroupsEdit.Font.Style := [];
    MailToGroupsEdit.HeightPercent := 100.000000000000000000;
    MailToGroupsEdit.ParentFont := False;
    MailToGroupsEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MailToGroupsEdit, Self, 'OnClick', 'MailToGroupsEditClick');
    TMSFNCPanel2.SetParentComponent(ReportTypePageControlPage5);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 1;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 219;
    TMSFNCPanel2.Width := 842;
    TMSFNCPanel2.Height := 45;
    TMSFNCPanel2.Align := alBottom;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 1;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    MailToLabel.SetParentComponent(TMSFNCPanel2);
    MailToLabel.Name := 'MailToLabel';
    MailToLabel.Left := 7;
    MailToLabel.Top := 13;
    MailToLabel.Width := 76;
    MailToLabel.Height := 21;
    MailToLabel.Caption := 'Extra Email';
    MailToLabel.Font.Charset := ANSI_CHARSET;
    MailToLabel.Font.Color := 9010544;
    MailToLabel.Font.Height := -16;
    MailToLabel.Font.Name := 'Segoe UI';
    MailToLabel.Font.Style := [];
    MailToLabel.HeightPercent := 100.000000000000000000;
    MailToLabel.ParentFont := False;
    MailToLabel.WidthPercent := 100.000000000000000000;
    RecipientListEdit.SetParentComponent(TMSFNCPanel2);
    RecipientListEdit.Name := 'RecipientListEdit';
    RecipientListEdit.Left := 96;
    RecipientListEdit.Top := 7;
    RecipientListEdit.Width := 741;
    RecipientListEdit.Height := 34;
    RecipientListEdit.Hint := 'Add email addresses seperated by semicolon ( ; )';
    RecipientListEdit.ChildOrder := 1;
    RecipientListEdit.ElementClassName := 'MyWebEdit';
    RecipientListEdit.Font.Charset := ANSI_CHARSET;
    RecipientListEdit.Font.Color := clWindowText;
    RecipientListEdit.Font.Height := -16;
    RecipientListEdit.Font.Name := 'Segoe UI';
    RecipientListEdit.Font.Style := [];
    RecipientListEdit.HeightPercent := 100.000000000000000000;
    RecipientListEdit.ParentFont := False;
    RecipientListEdit.ShowHint := True;
    RecipientListEdit.WidthPercent := 100.000000000000000000;
    SetEvent(RecipientListEdit, Self, 'OnChange', 'RecordChange');
    SendToGrid.SetParentComponent(ReportTypePageControlPage5);
    SendToGrid.Name := 'SendToGrid';
    SendToGrid.AlignWithMargins := True;
    SendToGrid.Left := 8;
    SendToGrid.Top := 48;
    SendToGrid.Width := 832;
    SendToGrid.Height := 168;
    SendToGrid.Margins.Left := 8;
    SendToGrid.Margins.Top := 0;
    SendToGrid.Margins.Right := 8;
    SendToGrid.Margins.Bottom := 0;
    SendToGrid.AdaptToStyle := True;
    SendToGrid.Align := alClient;
    SendToGrid.BevelInner := bvNone;
    SendToGrid.BevelOuter := bvNone;
    SendToGrid.BevelWidth := 2;
    SendToGrid.Ctl3D := False;
    SendToGrid.ParentCtl3D := False;
    SendToGrid.ParentDoubleBuffered := False;
    SendToGrid.DoubleBuffered := True;
    SendToGrid.Font.Charset := ANSI_CHARSET;
    SendToGrid.Font.Color := clWindowText;
    SendToGrid.Font.Height := -16;
    SendToGrid.Font.Name := 'Segoe UI';
    SendToGrid.Font.Style := [];
    SendToGrid.ParentFont := False;
    SendToGrid.TabOrder := 2;
    SetEvent(SendToGrid, Self, 'OnKeyDown', 'SendToGridKeyDown');
    SendToGrid.DefaultRowHeight := 34.000000000000000000;
    SendToGrid.FixedColumns := 0;
    SendToGrid.ColumnCount := 4;
    SendToGrid.RowCount := 1;
    SendToGrid.Options.Borders.FixedCellBorders := bNone;
    SendToGrid.Options.ColumnSize.Stretch := True;
    SendToGrid.Options.ColumnSize.StretchColumn := 2;
    SendToGrid.Options.Editing.CalcFormat := '%g';
    SendToGrid.Options.Grouping.CalcFormat := '%g';
    SendToGrid.Options.Grouping.GroupCountFormat := '(%d)';
    SendToGrid.Options.HTMLExport.CellPadding := 10;
    SendToGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    SendToGrid.Options.Mouse.WheelScrollKeepSelection := True;
    SendToGrid.Options.Mouse.ClickMargin := 0;
    SendToGrid.Options.Mouse.ColumnSizeMargin := 6;
    SendToGrid.Options.Mouse.RowSizeMargin := 6;
    SendToGrid.Options.URL.Color := clSkyBlue;
    SendToGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    SendToGrid.Options.Selection.Mode := smDisjunctRow;
    SendToGrid.Adapter := SendToDBAdapter;
    SendToGrid.HorizontalScrollBarVisible := False;
    SendToGrid.Columns.Clear;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'View';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Name := 'Name';
      ReadOnly := True;
      Width := 76.000000000000000000;
    end;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 515.000000000000000000;
    end;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 240.000000000000000000;
    end;
    SendToGrid.Fill.ColorTo := clSilver;
    SendToGrid.DefaultFont.Charset := ANSI_CHARSET;
    SendToGrid.DefaultFont.Color := clWindowText;
    SendToGrid.DefaultFont.Height := -16;
    SendToGrid.DefaultFont.Name := 'Segoe UI';
    SendToGrid.DefaultFont.Style := [];
    SendToGrid.Stroke.Color := 15987699;
    SendToGrid.Stroke.Width := 2.000000000000000000;
    SendToGrid.TopRow := 1;
    SendToGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    SendToGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    SendToGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.FixedLayout.Font.Color := clWhite;
    SendToGrid.Appearance.FixedLayout.Font.Height := -16;
    SendToGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.FixedLayout.Font.Style := [];
    SendToGrid.Appearance.NormalLayout.Fill.Color := -1;
    SendToGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    SendToGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.NormalLayout.Font.Color := clGray;
    SendToGrid.Appearance.NormalLayout.Font.Height := -16;
    SendToGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.NormalLayout.Font.Style := [];
    SendToGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    SendToGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    SendToGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    SendToGrid.Appearance.GroupLayout.Font.Color := clBlack;
    SendToGrid.Appearance.GroupLayout.Font.Height := -16;
    SendToGrid.Appearance.GroupLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.GroupLayout.Font.Style := [];
    SendToGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    SendToGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    SendToGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    SendToGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    SendToGrid.Appearance.SummaryLayout.Font.Height := -16;
    SendToGrid.Appearance.SummaryLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.SummaryLayout.Font.Style := [];
    SendToGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    SendToGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    SendToGrid.Appearance.SelectedLayout.Font.Height := -16;
    SendToGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.SelectedLayout.Font.Style := [];
    SendToGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    SendToGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    SendToGrid.Appearance.FocusedLayout.Font.Height := -16;
    SendToGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.FocusedLayout.Font.Style := [];
    SendToGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    SendToGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    SendToGrid.Appearance.BandLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    SendToGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.BandLayout.Font.Color := clBlack;
    SendToGrid.Appearance.BandLayout.Font.Height := -16;
    SendToGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    SendToGrid.Appearance.BandLayout.Font.Style := [];
    SendToGrid.Appearance.ProgressLayout.Color := 15385233;
    SendToGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    SendToGrid.LeftCol := 1;
    SendToGrid.ScrollMode := scmItemScrolling;
    SendToGrid.DesignTimeSampleData := True;
    SetEvent(SendToGrid, Self, 'OnCellClick', 'SendToGridCellClick');
    ReportTypePageControlPage3.SetParentComponent(ReportTypePageControl);
    ReportTypePageControlPage3.Name := 'ReportTypePageControlPage3';
    ReportTypePageControlPage3.AlignWithMargins := True;
    ReportTypePageControlPage3.Left := 0;
    ReportTypePageControlPage3.Top := 52;
    ReportTypePageControlPage3.Width := 848;
    ReportTypePageControlPage3.Height := 267;
    ReportTypePageControlPage3.Margins.Left := 0;
    ReportTypePageControlPage3.Margins.Top := 52;
    ReportTypePageControlPage3.Margins.Right := 0;
    ReportTypePageControlPage3.Margins.Bottom := 0;
    ReportTypePageControlPage3.Align := alClient;
    ReportTypePageControlPage3.ParentDoubleBuffered := False;
    ReportTypePageControlPage3.DoubleBuffered := True;
    ReportTypePageControlPage3.TabStop := False;
    ReportTypePageControlPage3.TabOrder := 3;
    ReportTypePageControlPage3.Visible := False;
    ReportTypePageControlPage3.PageIndex := 2;
    ReportTypePageControlPage3.IsActive := False;
    ReportTypeDriverIgnoreDistanceEdit.SetParentComponent(ReportTypePageControlPage3);
    ReportTypeDriverIgnoreDistanceEdit.Name := 'ReportTypeDriverIgnoreDistanceEdit';
    ReportTypeDriverIgnoreDistanceEdit.AlignWithMargins := True;
    ReportTypeDriverIgnoreDistanceEdit.Left := 21;
    ReportTypeDriverIgnoreDistanceEdit.Top := 15;
    ReportTypeDriverIgnoreDistanceEdit.Width := 284;
    ReportTypeDriverIgnoreDistanceEdit.Height := 35;
    ReportTypeDriverIgnoreDistanceEdit.Margins.Left := 40;
    ReportTypeDriverIgnoreDistanceEdit.Margins.Top := 10;
    ReportTypeDriverIgnoreDistanceEdit.Margins.Right := 40;
    ReportTypeDriverIgnoreDistanceEdit.Caption := 'Include routes with distance < 100m';
    ReportTypeDriverIgnoreDistanceEdit.ChildOrder := 2;
    ReportTypeDriverIgnoreDistanceEdit.Color := clWhite;
    ReportTypeDriverIgnoreDistanceEdit.Font.Charset := ANSI_CHARSET;
    ReportTypeDriverIgnoreDistanceEdit.Font.Color := 9010544;
    ReportTypeDriverIgnoreDistanceEdit.Font.Height := -16;
    ReportTypeDriverIgnoreDistanceEdit.Font.Name := 'Segoe UI';
    ReportTypeDriverIgnoreDistanceEdit.Font.Style := [];
    ReportTypeDriverIgnoreDistanceEdit.HeightPercent := 100.000000000000000000;
    ReportTypeDriverIgnoreDistanceEdit.ParentFont := False;
    ReportTypeDriverIgnoreDistanceEdit.TabOrder := 2;
    ReportTypeDriverIgnoreDistanceEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeDriverIgnoreDistanceEdit, Self, 'OnClick', 'RecordChange');
    ReportTypePageControlPage4.SetParentComponent(ReportTypePageControl);
    ReportTypePageControlPage4.Name := 'ReportTypePageControlPage4';
    ReportTypePageControlPage4.AlignWithMargins := True;
    ReportTypePageControlPage4.Left := 0;
    ReportTypePageControlPage4.Top := 52;
    ReportTypePageControlPage4.Width := 848;
    ReportTypePageControlPage4.Height := 267;
    ReportTypePageControlPage4.Margins.Left := 0;
    ReportTypePageControlPage4.Margins.Top := 52;
    ReportTypePageControlPage4.Margins.Right := 0;
    ReportTypePageControlPage4.Margins.Bottom := 0;
    ReportTypePageControlPage4.Align := alClient;
    ReportTypePageControlPage4.ParentDoubleBuffered := False;
    ReportTypePageControlPage4.DoubleBuffered := True;
    ReportTypePageControlPage4.TabStop := False;
    ReportTypePageControlPage4.TabOrder := 4;
    ReportTypePageControlPage4.Visible := False;
    ReportTypePageControlPage4.PageIndex := 3;
    ReportTypePageControlPage4.IsActive := False;
    WebLabel2.SetParentComponent(ReportTypePageControlPage4);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 27;
    WebLabel2.Top := 122;
    WebLabel2.Width := 96;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Days before 1';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(ReportTypePageControlPage4);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 27;
    WebLabel3.Top := 166;
    WebLabel3.Width := 96;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Days before 2';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ReportTypePageControlPage4);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 27;
    WebLabel4.Top := 21;
    WebLabel4.Width := 136;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Reminders for MOT';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    MOTDays1Edit.SetParentComponent(ReportTypePageControlPage4);
    MOTDays1Edit.Name := 'MOTDays1Edit';
    MOTDays1Edit.Left := 141;
    MOTDays1Edit.Top := 118;
    MOTDays1Edit.Width := 183;
    MOTDays1Edit.Height := 34;
    MOTDays1Edit.ChildOrder := 1;
    MOTDays1Edit.EditType := weNumeric;
    MOTDays1Edit.ElementClassName := 'myselect_white';
    MOTDays1Edit.Font.Charset := ANSI_CHARSET;
    MOTDays1Edit.Font.Color := clWindowText;
    MOTDays1Edit.Font.Height := -16;
    MOTDays1Edit.Font.Name := 'Segoe UI';
    MOTDays1Edit.Font.Style := [];
    MOTDays1Edit.HeightPercent := 100.000000000000000000;
    MOTDays1Edit.ParentFont := False;
    MOTDays1Edit.WidthPercent := 100.000000000000000000;
    SetEvent(MOTDays1Edit, Self, 'OnChange', 'RecordChange');
    MOTDays2Edit.SetParentComponent(ReportTypePageControlPage4);
    MOTDays2Edit.Name := 'MOTDays2Edit';
    MOTDays2Edit.Left := 141;
    MOTDays2Edit.Top := 164;
    MOTDays2Edit.Width := 183;
    MOTDays2Edit.Height := 34;
    MOTDays2Edit.ChildOrder := 1;
    MOTDays2Edit.EditType := weNumeric;
    MOTDays2Edit.ElementClassName := 'myselect_white';
    MOTDays2Edit.Font.Charset := ANSI_CHARSET;
    MOTDays2Edit.Font.Color := clWindowText;
    MOTDays2Edit.Font.Height := -16;
    MOTDays2Edit.Font.Name := 'Segoe UI';
    MOTDays2Edit.Font.Style := [];
    MOTDays2Edit.HeightPercent := 100.000000000000000000;
    MOTDays2Edit.ParentFont := False;
    MOTDays2Edit.WidthPercent := 100.000000000000000000;
    SetEvent(MOTDays2Edit, Self, 'OnChange', 'RecordChange');
    ReportTypeMOTPeriod.SetParentComponent(ReportTypePageControlPage4);
    ReportTypeMOTPeriod.Name := 'ReportTypeMOTPeriod';
    ReportTypeMOTPeriod.Tag := 1;
    ReportTypeMOTPeriod.Left := 28;
    ReportTypeMOTPeriod.Top := 56;
    ReportTypeMOTPeriod.Width := 113;
    ReportTypeMOTPeriod.Height := 22;
    ReportTypeMOTPeriod.Caption := 'Period';
    ReportTypeMOTPeriod.Checked := False;
    ReportTypeMOTPeriod.ChildOrder := 1;
    ReportTypeMOTPeriod.Color := clNone;
    ReportTypeMOTPeriod.Font.Charset := ANSI_CHARSET;
    ReportTypeMOTPeriod.Font.Color := clWindowText;
    ReportTypeMOTPeriod.Font.Height := -16;
    ReportTypeMOTPeriod.Font.Name := 'Segoe UI';
    ReportTypeMOTPeriod.Font.Style := [];
    ReportTypeMOTPeriod.GroupName := 'MOT';
    ReportTypeMOTPeriod.HeightPercent := 100.000000000000000000;
    ReportTypeMOTPeriod.ParentFont := False;
    ReportTypeMOTPeriod.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeMOTPeriod, Self, 'OnClick', 'RecordChange');
    ReportTypeMOTDays.SetParentComponent(ReportTypePageControlPage4);
    ReportTypeMOTDays.Name := 'ReportTypeMOTDays';
    ReportTypeMOTDays.Tag := 2;
    ReportTypeMOTDays.Left := 28;
    ReportTypeMOTDays.Top := 84;
    ReportTypeMOTDays.Width := 113;
    ReportTypeMOTDays.Height := 22;
    ReportTypeMOTDays.Caption := 'Days before';
    ReportTypeMOTDays.Checked := True;
    ReportTypeMOTDays.ChildOrder := 1;
    ReportTypeMOTDays.Color := clNone;
    ReportTypeMOTDays.Font.Charset := ANSI_CHARSET;
    ReportTypeMOTDays.Font.Color := clWindowText;
    ReportTypeMOTDays.Font.Height := -16;
    ReportTypeMOTDays.Font.Name := 'Segoe UI';
    ReportTypeMOTDays.Font.Style := [];
    ReportTypeMOTDays.GroupName := 'MOT';
    ReportTypeMOTDays.HeightPercent := 100.000000000000000000;
    ReportTypeMOTDays.ParentFont := False;
    ReportTypeMOTDays.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeMOTDays, Self, 'OnClick', 'RecordChange');
    ReportTypePageControlPage6.SetParentComponent(ReportTypePageControl);
    ReportTypePageControlPage6.Name := 'ReportTypePageControlPage6';
    ReportTypePageControlPage6.AlignWithMargins := True;
    ReportTypePageControlPage6.Left := 0;
    ReportTypePageControlPage6.Top := 52;
    ReportTypePageControlPage6.Width := 848;
    ReportTypePageControlPage6.Height := 267;
    ReportTypePageControlPage6.Margins.Left := 0;
    ReportTypePageControlPage6.Margins.Top := 52;
    ReportTypePageControlPage6.Margins.Right := 0;
    ReportTypePageControlPage6.Margins.Bottom := 0;
    ReportTypePageControlPage6.Align := alClient;
    ReportTypePageControlPage6.ParentDoubleBuffered := False;
    ReportTypePageControlPage6.DoubleBuffered := True;
    ReportTypePageControlPage6.TabStop := False;
    ReportTypePageControlPage6.TabOrder := 6;
    ReportTypePageControlPage6.Visible := False;
    ReportTypePageControlPage6.PageIndex := 4;
    ReportTypePageControlPage6.IsActive := False;
    DeviceLowBatteryEdit.SetParentComponent(ReportTypePageControlPage6);
    DeviceLowBatteryEdit.Name := 'DeviceLowBatteryEdit';
    DeviceLowBatteryEdit.Left := 25;
    DeviceLowBatteryEdit.Top := 24;
    DeviceLowBatteryEdit.Width := 552;
    DeviceLowBatteryEdit.Height := 26;
    DeviceLowBatteryEdit.Caption := 'Only Device Models with option "Show low battery in reports"';
    DeviceLowBatteryEdit.ChildOrder := 28;
    DeviceLowBatteryEdit.Font.Charset := ANSI_CHARSET;
    DeviceLowBatteryEdit.Font.Color := clWindowText;
    DeviceLowBatteryEdit.Font.Height := -16;
    DeviceLowBatteryEdit.Font.Name := 'Segoe UI';
    DeviceLowBatteryEdit.Font.Style := [];
    DeviceLowBatteryEdit.HeightPercent := 100.000000000000000000;
    DeviceLowBatteryEdit.ParentFont := False;
    DeviceLowBatteryEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceLowBatteryEdit, Self, 'OnClick', 'RecordChange');
    TMSFNCPageControl1Page0.SetParentComponent(ReportTypePageControl);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 848;
    TMSFNCPageControl1Page0.Height := 267;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.Visible := False;
    TMSFNCPageControl1Page0.PageIndex := 7;
    TMSFNCPageControl1Page0.IsActive := False;
    ScheduleDayLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ScheduleDayLabel.Name := 'ScheduleDayLabel';
    ScheduleDayLabel.Left := 25;
    ScheduleDayLabel.Top := 79;
    ScheduleDayLabel.Width := 27;
    ScheduleDayLabel.Height := 21;
    ScheduleDayLabel.Caption := 'Day';
    ScheduleDayLabel.Font.Charset := ANSI_CHARSET;
    ScheduleDayLabel.Font.Color := 9010544;
    ScheduleDayLabel.Font.Height := -16;
    ScheduleDayLabel.Font.Name := 'Segoe UI';
    ScheduleDayLabel.Font.Style := [];
    ScheduleDayLabel.HeightPercent := 100.000000000000000000;
    ScheduleDayLabel.ParentFont := False;
    ScheduleDayLabel.WidthPercent := 100.000000000000000000;
    ScheduleMonthLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ScheduleMonthLabel.Name := 'ScheduleMonthLabel';
    ScheduleMonthLabel.Left := 25;
    ScheduleMonthLabel.Top := 126;
    ScheduleMonthLabel.Width := 46;
    ScheduleMonthLabel.Height := 21;
    ScheduleMonthLabel.Caption := 'Month';
    ScheduleMonthLabel.Font.Charset := ANSI_CHARSET;
    ScheduleMonthLabel.Font.Color := 9010544;
    ScheduleMonthLabel.Font.Height := -16;
    ScheduleMonthLabel.Font.Name := 'Segoe UI';
    ScheduleMonthLabel.Font.Style := [];
    ScheduleMonthLabel.HeightPercent := 100.000000000000000000;
    ScheduleMonthLabel.ParentFont := False;
    ScheduleMonthLabel.WidthPercent := 100.000000000000000000;
    MondayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    MondayEdit.Name := 'MondayEdit';
    MondayEdit.AlignWithMargins := True;
    MondayEdit.Left := 339;
    MondayEdit.Top := 26;
    MondayEdit.Width := 129;
    MondayEdit.Height := 35;
    MondayEdit.Margins.Left := 40;
    MondayEdit.Margins.Top := 10;
    MondayEdit.Margins.Right := 40;
    MondayEdit.Caption := 'MONDAY';
    MondayEdit.ChildOrder := 2;
    MondayEdit.Color := clWhite;
    MondayEdit.Font.Charset := ANSI_CHARSET;
    MondayEdit.Font.Color := 9010544;
    MondayEdit.Font.Height := -16;
    MondayEdit.Font.Name := 'Segoe UI';
    MondayEdit.Font.Style := [];
    MondayEdit.HeightPercent := 100.000000000000000000;
    MondayEdit.ParentFont := False;
    MondayEdit.TabOrder := 2;
    MondayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MondayEdit, Self, 'OnClick', 'RecordChange');
    TuesdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TuesdayEdit.Name := 'TuesdayEdit';
    TuesdayEdit.AlignWithMargins := True;
    TuesdayEdit.Left := 483;
    TuesdayEdit.Top := 26;
    TuesdayEdit.Width := 129;
    TuesdayEdit.Height := 35;
    TuesdayEdit.Margins.Left := 40;
    TuesdayEdit.Margins.Top := 10;
    TuesdayEdit.Margins.Right := 40;
    TuesdayEdit.Caption := 'TUESDAY';
    TuesdayEdit.ChildOrder := 2;
    TuesdayEdit.Color := clWhite;
    TuesdayEdit.Font.Charset := ANSI_CHARSET;
    TuesdayEdit.Font.Color := 9010544;
    TuesdayEdit.Font.Height := -16;
    TuesdayEdit.Font.Name := 'Segoe UI';
    TuesdayEdit.Font.Style := [];
    TuesdayEdit.HeightPercent := 100.000000000000000000;
    TuesdayEdit.ParentFont := False;
    TuesdayEdit.TabOrder := 2;
    TuesdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TuesdayEdit, Self, 'OnClick', 'RecordChange');
    WednesdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    WednesdayEdit.Name := 'WednesdayEdit';
    WednesdayEdit.AlignWithMargins := True;
    WednesdayEdit.Left := 339;
    WednesdayEdit.Top := 58;
    WednesdayEdit.Width := 129;
    WednesdayEdit.Height := 35;
    WednesdayEdit.Margins.Left := 40;
    WednesdayEdit.Margins.Top := 10;
    WednesdayEdit.Margins.Right := 40;
    WednesdayEdit.Caption := 'WEDNESDAY';
    WednesdayEdit.ChildOrder := 2;
    WednesdayEdit.Color := clWhite;
    WednesdayEdit.Font.Charset := ANSI_CHARSET;
    WednesdayEdit.Font.Color := 9010544;
    WednesdayEdit.Font.Height := -16;
    WednesdayEdit.Font.Name := 'Segoe UI';
    WednesdayEdit.Font.Style := [];
    WednesdayEdit.HeightPercent := 100.000000000000000000;
    WednesdayEdit.ParentFont := False;
    WednesdayEdit.TabOrder := 2;
    WednesdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(WednesdayEdit, Self, 'OnClick', 'RecordChange');
    ThursdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ThursdayEdit.Name := 'ThursdayEdit';
    ThursdayEdit.AlignWithMargins := True;
    ThursdayEdit.Left := 483;
    ThursdayEdit.Top := 58;
    ThursdayEdit.Width := 129;
    ThursdayEdit.Height := 35;
    ThursdayEdit.Margins.Left := 40;
    ThursdayEdit.Margins.Top := 10;
    ThursdayEdit.Margins.Right := 40;
    ThursdayEdit.Caption := 'THURSDAY';
    ThursdayEdit.ChildOrder := 2;
    ThursdayEdit.Color := clWhite;
    ThursdayEdit.Font.Charset := ANSI_CHARSET;
    ThursdayEdit.Font.Color := 9010544;
    ThursdayEdit.Font.Height := -16;
    ThursdayEdit.Font.Name := 'Segoe UI';
    ThursdayEdit.Font.Style := [];
    ThursdayEdit.HeightPercent := 100.000000000000000000;
    ThursdayEdit.ParentFont := False;
    ThursdayEdit.TabOrder := 2;
    ThursdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ThursdayEdit, Self, 'OnClick', 'RecordChange');
    FridayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    FridayEdit.Name := 'FridayEdit';
    FridayEdit.AlignWithMargins := True;
    FridayEdit.Left := 339;
    FridayEdit.Top := 90;
    FridayEdit.Width := 129;
    FridayEdit.Height := 35;
    FridayEdit.Margins.Left := 40;
    FridayEdit.Margins.Top := 10;
    FridayEdit.Margins.Right := 40;
    FridayEdit.Caption := 'FRIDAY';
    FridayEdit.ChildOrder := 2;
    FridayEdit.Color := clWhite;
    FridayEdit.Font.Charset := ANSI_CHARSET;
    FridayEdit.Font.Color := 9010544;
    FridayEdit.Font.Height := -16;
    FridayEdit.Font.Name := 'Segoe UI';
    FridayEdit.Font.Style := [];
    FridayEdit.HeightPercent := 100.000000000000000000;
    FridayEdit.ParentFont := False;
    FridayEdit.TabOrder := 2;
    FridayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FridayEdit, Self, 'OnClick', 'RecordChange');
    SaturdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    SaturdayEdit.Name := 'SaturdayEdit';
    SaturdayEdit.AlignWithMargins := True;
    SaturdayEdit.Left := 339;
    SaturdayEdit.Top := 122;
    SaturdayEdit.Width := 129;
    SaturdayEdit.Height := 35;
    SaturdayEdit.Margins.Left := 40;
    SaturdayEdit.Margins.Top := 10;
    SaturdayEdit.Margins.Right := 40;
    SaturdayEdit.Caption := 'SATURDAY';
    SaturdayEdit.ChildOrder := 2;
    SaturdayEdit.Color := clWhite;
    SaturdayEdit.Font.Charset := ANSI_CHARSET;
    SaturdayEdit.Font.Color := 9010544;
    SaturdayEdit.Font.Height := -16;
    SaturdayEdit.Font.Name := 'Segoe UI';
    SaturdayEdit.Font.Style := [];
    SaturdayEdit.HeightPercent := 100.000000000000000000;
    SaturdayEdit.ParentFont := False;
    SaturdayEdit.TabOrder := 2;
    SaturdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SaturdayEdit, Self, 'OnClick', 'RecordChange');
    SundayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    SundayEdit.Name := 'SundayEdit';
    SundayEdit.AlignWithMargins := True;
    SundayEdit.Left := 480;
    SundayEdit.Top := 122;
    SundayEdit.Width := 129;
    SundayEdit.Height := 35;
    SundayEdit.Margins.Left := 40;
    SundayEdit.Margins.Top := 10;
    SundayEdit.Margins.Right := 40;
    SundayEdit.Caption := 'SUNDAY';
    SundayEdit.ChildOrder := 2;
    SundayEdit.Color := clWhite;
    SundayEdit.Font.Charset := ANSI_CHARSET;
    SundayEdit.Font.Color := 9010544;
    SundayEdit.Font.Height := -16;
    SundayEdit.Font.Name := 'Segoe UI';
    SundayEdit.Font.Style := [];
    SundayEdit.HeightPercent := 100.000000000000000000;
    SundayEdit.ParentFont := False;
    SundayEdit.TabOrder := 2;
    SundayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SundayEdit, Self, 'OnClick', 'RecordChange');
    ScheduleTypeEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ScheduleTypeEdit.Name := 'ScheduleTypeEdit';
    ScheduleTypeEdit.AlignWithMargins := True;
    ScheduleTypeEdit.Left := 20;
    ScheduleTypeEdit.Top := 31;
    ScheduleTypeEdit.Width := 262;
    ScheduleTypeEdit.Height := 34;
    ScheduleTypeEdit.Margins.Left := 20;
    ScheduleTypeEdit.Margins.Top := 8;
    ScheduleTypeEdit.Margins.Bottom := 8;
    ScheduleTypeEdit.Color := clWhite;
    ScheduleTypeEdit.ElementClassName := 'myselect_white';
    ScheduleTypeEdit.Font.Charset := DEFAULT_CHARSET;
    ScheduleTypeEdit.Font.Color := clWindowText;
    ScheduleTypeEdit.Font.Height := 12;
    ScheduleTypeEdit.Font.Name := 'Segoe UI';
    ScheduleTypeEdit.Font.Style := [];
    ScheduleTypeEdit.HeightPercent := 100.000000000000000000;
    ScheduleTypeEdit.ParentFont := False;
    ScheduleTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ScheduleTypeEdit, Self, 'OnChange', 'ScheduleTypeEditChange');
    ScheduleTypeEdit.ItemIndex := -1;
    ScheduleTypeEdit.LookupValues.Clear;
    with ScheduleTypeEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'N/A';
    end;
    with ScheduleTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Weekly';
    end;
    with ScheduleTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Monthly';
    end;
    with ScheduleTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'Yearly';
    end;
    ScheduleDayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ScheduleDayEdit.Name := 'ScheduleDayEdit';
    ScheduleDayEdit.Left := 99;
    ScheduleDayEdit.Top := 76;
    ScheduleDayEdit.Width := 183;
    ScheduleDayEdit.Height := 34;
    ScheduleDayEdit.ChildOrder := 1;
    ScheduleDayEdit.EditType := weNumeric;
    ScheduleDayEdit.ElementClassName := 'myselect_white';
    ScheduleDayEdit.Font.Charset := ANSI_CHARSET;
    ScheduleDayEdit.Font.Color := clWindowText;
    ScheduleDayEdit.Font.Height := -16;
    ScheduleDayEdit.Font.Name := 'Segoe UI';
    ScheduleDayEdit.Font.Style := [];
    ScheduleDayEdit.HeightPercent := 100.000000000000000000;
    ScheduleDayEdit.ParentFont := False;
    ScheduleDayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ScheduleDayEdit, Self, 'OnChange', 'RecordChange');
    ScheduleMonthEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ScheduleMonthEdit.Name := 'ScheduleMonthEdit';
    ScheduleMonthEdit.AlignWithMargins := True;
    ScheduleMonthEdit.Left := 99;
    ScheduleMonthEdit.Top := 121;
    ScheduleMonthEdit.Width := 183;
    ScheduleMonthEdit.Height := 34;
    ScheduleMonthEdit.Margins.Left := 20;
    ScheduleMonthEdit.Margins.Top := 8;
    ScheduleMonthEdit.Margins.Bottom := 8;
    ScheduleMonthEdit.Color := clWhite;
    ScheduleMonthEdit.ElementClassName := 'myselect_white';
    ScheduleMonthEdit.Font.Charset := DEFAULT_CHARSET;
    ScheduleMonthEdit.Font.Color := clWindowText;
    ScheduleMonthEdit.Font.Height := 12;
    ScheduleMonthEdit.Font.Name := 'Segoe UI';
    ScheduleMonthEdit.Font.Style := [];
    ScheduleMonthEdit.HeightPercent := 100.000000000000000000;
    ScheduleMonthEdit.ParentFont := False;
    ScheduleMonthEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ScheduleMonthEdit, Self, 'OnChange', 'RecordChange');
    ScheduleMonthEdit.ItemIndex := -1;
    ScheduleMonthEdit.LookupValues.Clear;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Januari';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'February';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'March';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'April';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'May';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '6';
      DisplayText := 'June';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '7';
      DisplayText := 'Juli';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '8';
      DisplayText := 'August';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '9';
      DisplayText := 'September';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '10';
      DisplayText := 'October';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '11';
      DisplayText := 'November';
    end;
    with ScheduleMonthEdit.LookupValues.Add do
    begin
      Value := '12';
      DisplayText := 'December';
    end;
    ReportTypePageControlPage8.SetParentComponent(ReportTypePageControl);
    ReportTypePageControlPage8.Name := 'ReportTypePageControlPage8';
    ReportTypePageControlPage8.AlignWithMargins := True;
    ReportTypePageControlPage8.Left := 0;
    ReportTypePageControlPage8.Top := 52;
    ReportTypePageControlPage8.Width := 848;
    ReportTypePageControlPage8.Height := 267;
    ReportTypePageControlPage8.Margins.Left := 0;
    ReportTypePageControlPage8.Margins.Top := 52;
    ReportTypePageControlPage8.Margins.Right := 0;
    ReportTypePageControlPage8.Margins.Bottom := 0;
    ReportTypePageControlPage8.Align := alClient;
    ReportTypePageControlPage8.ParentDoubleBuffered := False;
    ReportTypePageControlPage8.DoubleBuffered := True;
    ReportTypePageControlPage8.TabStop := False;
    ReportTypePageControlPage8.TabOrder := 8;
    ReportTypePageControlPage8.Visible := False;
    ReportTypePageControlPage8.PageIndex := 6;
    ReportTypePageControlPage8.IsActive := False;
    WebLabel5.SetParentComponent(ReportTypePageControlPage8);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 27;
    WebLabel5.Top := 21;
    WebLabel5.Width := 170;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'Reminders for insurance';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(ReportTypePageControlPage8);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 27;
    WebLabel6.Top := 121;
    WebLabel6.Width := 96;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'Days before 1';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := 9010544;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(ReportTypePageControlPage8);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 27;
    WebLabel8.Top := 165;
    WebLabel8.Width := 96;
    WebLabel8.Height := 21;
    WebLabel8.Caption := 'Days before 2';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := 9010544;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    InsuranceDays1Edit.SetParentComponent(ReportTypePageControlPage8);
    InsuranceDays1Edit.Name := 'InsuranceDays1Edit';
    InsuranceDays1Edit.Left := 141;
    InsuranceDays1Edit.Top := 117;
    InsuranceDays1Edit.Width := 183;
    InsuranceDays1Edit.Height := 34;
    InsuranceDays1Edit.ChildOrder := 1;
    InsuranceDays1Edit.EditType := weNumeric;
    InsuranceDays1Edit.ElementClassName := 'myselect_white';
    InsuranceDays1Edit.Font.Charset := ANSI_CHARSET;
    InsuranceDays1Edit.Font.Color := clWindowText;
    InsuranceDays1Edit.Font.Height := -16;
    InsuranceDays1Edit.Font.Name := 'Segoe UI';
    InsuranceDays1Edit.Font.Style := [];
    InsuranceDays1Edit.HeightPercent := 100.000000000000000000;
    InsuranceDays1Edit.ParentFont := False;
    InsuranceDays1Edit.WidthPercent := 100.000000000000000000;
    SetEvent(InsuranceDays1Edit, Self, 'OnChange', 'RecordChange');
    InsuranceDays2Edit.SetParentComponent(ReportTypePageControlPage8);
    InsuranceDays2Edit.Name := 'InsuranceDays2Edit';
    InsuranceDays2Edit.Left := 141;
    InsuranceDays2Edit.Top := 163;
    InsuranceDays2Edit.Width := 183;
    InsuranceDays2Edit.Height := 34;
    InsuranceDays2Edit.ChildOrder := 1;
    InsuranceDays2Edit.EditType := weNumeric;
    InsuranceDays2Edit.ElementClassName := 'myselect_white';
    InsuranceDays2Edit.Font.Charset := ANSI_CHARSET;
    InsuranceDays2Edit.Font.Color := clWindowText;
    InsuranceDays2Edit.Font.Height := -16;
    InsuranceDays2Edit.Font.Name := 'Segoe UI';
    InsuranceDays2Edit.Font.Style := [];
    InsuranceDays2Edit.HeightPercent := 100.000000000000000000;
    InsuranceDays2Edit.ParentFont := False;
    InsuranceDays2Edit.WidthPercent := 100.000000000000000000;
    SetEvent(InsuranceDays2Edit, Self, 'OnChange', 'RecordChange');
    ReportTypeInsurancePeriod.SetParentComponent(ReportTypePageControlPage8);
    ReportTypeInsurancePeriod.Name := 'ReportTypeInsurancePeriod';
    ReportTypeInsurancePeriod.Tag := 1;
    ReportTypeInsurancePeriod.Left := 28;
    ReportTypeInsurancePeriod.Top := 56;
    ReportTypeInsurancePeriod.Width := 113;
    ReportTypeInsurancePeriod.Height := 22;
    ReportTypeInsurancePeriod.Caption := 'Period';
    ReportTypeInsurancePeriod.Checked := False;
    ReportTypeInsurancePeriod.ChildOrder := 1;
    ReportTypeInsurancePeriod.Color := clNone;
    ReportTypeInsurancePeriod.Font.Charset := ANSI_CHARSET;
    ReportTypeInsurancePeriod.Font.Color := clWindowText;
    ReportTypeInsurancePeriod.Font.Height := -16;
    ReportTypeInsurancePeriod.Font.Name := 'Segoe UI';
    ReportTypeInsurancePeriod.Font.Style := [];
    ReportTypeInsurancePeriod.GroupName := 'Insurance';
    ReportTypeInsurancePeriod.HeightPercent := 100.000000000000000000;
    ReportTypeInsurancePeriod.ParentFont := False;
    ReportTypeInsurancePeriod.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeInsurancePeriod, Self, 'OnClick', 'RecordChange');
    ReportTypeInsuranceDays.SetParentComponent(ReportTypePageControlPage8);
    ReportTypeInsuranceDays.Name := 'ReportTypeInsuranceDays';
    ReportTypeInsuranceDays.Tag := 2;
    ReportTypeInsuranceDays.Left := 28;
    ReportTypeInsuranceDays.Top := 84;
    ReportTypeInsuranceDays.Width := 113;
    ReportTypeInsuranceDays.Height := 22;
    ReportTypeInsuranceDays.Caption := 'Days before';
    ReportTypeInsuranceDays.Checked := True;
    ReportTypeInsuranceDays.ChildOrder := 1;
    ReportTypeInsuranceDays.Color := clNone;
    ReportTypeInsuranceDays.Font.Charset := ANSI_CHARSET;
    ReportTypeInsuranceDays.Font.Color := clWindowText;
    ReportTypeInsuranceDays.Font.Height := -16;
    ReportTypeInsuranceDays.Font.Name := 'Segoe UI';
    ReportTypeInsuranceDays.Font.Style := [];
    ReportTypeInsuranceDays.GroupName := 'Insurance';
    ReportTypeInsuranceDays.HeightPercent := 100.000000000000000000;
    ReportTypeInsuranceDays.ParentFont := False;
    ReportTypeInsuranceDays.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeInsuranceDays, Self, 'OnClick', 'RecordChange');
    ReportTypePageControlPage7.SetParentComponent(ReportTypePageControl);
    ReportTypePageControlPage7.Name := 'ReportTypePageControlPage7';
    ReportTypePageControlPage7.AlignWithMargins := True;
    ReportTypePageControlPage7.Left := 0;
    ReportTypePageControlPage7.Top := 52;
    ReportTypePageControlPage7.Width := 848;
    ReportTypePageControlPage7.Height := 267;
    ReportTypePageControlPage7.Margins.Left := 0;
    ReportTypePageControlPage7.Margins.Top := 52;
    ReportTypePageControlPage7.Margins.Right := 0;
    ReportTypePageControlPage7.Margins.Bottom := 0;
    ReportTypePageControlPage7.Align := alClient;
    ReportTypePageControlPage7.ParentDoubleBuffered := False;
    ReportTypePageControlPage7.DoubleBuffered := True;
    ReportTypePageControlPage7.TabStop := False;
    ReportTypePageControlPage7.TabOrder := 7;
    ReportTypePageControlPage7.Visible := False;
    ReportTypePageControlPage7.PageIndex := 5;
    ReportTypePageControlPage7.IsActive := False;
    WebLabel9.SetParentComponent(ReportTypePageControlPage7);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 15;
    WebLabel9.Top := 18;
    WebLabel9.Width := 33;
    WebLabel9.Height := 21;
    WebLabel9.Caption := 'Alert';
    WebLabel9.Font.Charset := ANSI_CHARSET;
    WebLabel9.Font.Color := 9010544;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Segoe UI';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    AlertListEdit.SetParentComponent(ReportTypePageControlPage7);
    AlertListEdit.Name := 'AlertListEdit';
    AlertListEdit.AlignWithMargins := True;
    AlertListEdit.Left := 16;
    AlertListEdit.Top := 45;
    AlertListEdit.Width := 816;
    AlertListEdit.Height := 34;
    AlertListEdit.Margins.Left := 20;
    AlertListEdit.Margins.Top := 8;
    AlertListEdit.Margins.Bottom := 8;
    AlertListEdit.Color := clWhite;
    AlertListEdit.ElementClassName := 'myselect_white';
    AlertListEdit.Font.Charset := DEFAULT_CHARSET;
    AlertListEdit.Font.Color := clWindowText;
    AlertListEdit.Font.Height := 12;
    AlertListEdit.Font.Name := 'Segoe UI';
    AlertListEdit.Font.Style := [];
    AlertListEdit.HeightPercent := 100.000000000000000000;
    AlertListEdit.ParentFont := False;
    AlertListEdit.WidthPercent := 100.000000000000000000;
    AlertListEdit.ItemIndex := -1;
    AlertCriticalEdit.SetParentComponent(ReportTypePageControlPage7);
    AlertCriticalEdit.Name := 'AlertCriticalEdit';
    AlertCriticalEdit.AlignWithMargins := True;
    AlertCriticalEdit.Left := 16;
    AlertCriticalEdit.Top := 91;
    AlertCriticalEdit.Width := 323;
    AlertCriticalEdit.Height := 35;
    AlertCriticalEdit.Margins.Left := 40;
    AlertCriticalEdit.Margins.Top := 10;
    AlertCriticalEdit.Margins.Right := 40;
    AlertCriticalEdit.Caption := 'Show only critical';
    AlertCriticalEdit.ChildOrder := 2;
    AlertCriticalEdit.Color := clWhite;
    AlertCriticalEdit.Font.Charset := ANSI_CHARSET;
    AlertCriticalEdit.Font.Color := 9010544;
    AlertCriticalEdit.Font.Height := -16;
    AlertCriticalEdit.Font.Name := 'Segoe UI';
    AlertCriticalEdit.Font.Style := [];
    AlertCriticalEdit.HeightPercent := 100.000000000000000000;
    AlertCriticalEdit.ParentFont := False;
    AlertCriticalEdit.TabOrder := 2;
    AlertCriticalEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AlertCriticalEdit, Self, 'OnClick', 'RecordChange');
    TMSFNCPageControl1Page2.SetParentComponent(ReportTypePageControl);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 848;
    TMSFNCPageControl1Page2.Height := 267;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 1;
    TMSFNCPageControl1Page2.IsActive := False;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page2);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 28;
    WebLabel1.Top := 26;
    WebLabel1.Width := 109;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Active / Inactive';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(TMSFNCPageControl1Page2);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 520;
    WebLabel16.Top := 24;
    WebLabel16.Width := 42;
    WebLabel16.Height := 21;
    WebLabel16.Caption := 'Status';
    WebLabel16.Font.Charset := ANSI_CHARSET;
    WebLabel16.Font.Color := 9010544;
    WebLabel16.Font.Height := -16;
    WebLabel16.Font.Name := 'Segoe UI';
    WebLabel16.Font.Style := [];
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WidthPercent := 100.000000000000000000;
    AssetDistanceMinLabel.SetParentComponent(TMSFNCPageControl1Page2);
    AssetDistanceMinLabel.Name := 'AssetDistanceMinLabel';
    AssetDistanceMinLabel.Left := 297;
    AssetDistanceMinLabel.Top := 186;
    AssetDistanceMinLabel.Width := 38;
    AssetDistanceMinLabel.Height := 21;
    AssetDistanceMinLabel.Alignment := taCenter;
    AssetDistanceMinLabel.AutoSize := False;
    AssetDistanceMinLabel.Caption := 'km';
    AssetDistanceMinLabel.Font.Charset := ANSI_CHARSET;
    AssetDistanceMinLabel.Font.Color := 9010544;
    AssetDistanceMinLabel.Font.Height := -16;
    AssetDistanceMinLabel.Font.Name := 'Segoe UI';
    AssetDistanceMinLabel.Font.Style := [];
    AssetDistanceMinLabel.HeightPercent := 100.000000000000000000;
    AssetDistanceMinLabel.ParentFont := False;
    AssetDistanceMinLabel.WidthPercent := 100.000000000000000000;
    AssetDistanceMaxLabel.SetParentComponent(TMSFNCPageControl1Page2);
    AssetDistanceMaxLabel.Name := 'AssetDistanceMaxLabel';
    AssetDistanceMaxLabel.Left := 456;
    AssetDistanceMaxLabel.Top := 186;
    AssetDistanceMaxLabel.Width := 22;
    AssetDistanceMaxLabel.Height := 21;
    AssetDistanceMaxLabel.Caption := 'km';
    AssetDistanceMaxLabel.Font.Charset := ANSI_CHARSET;
    AssetDistanceMaxLabel.Font.Color := 9010544;
    AssetDistanceMaxLabel.Font.Height := -16;
    AssetDistanceMaxLabel.Font.Name := 'Segoe UI';
    AssetDistanceMaxLabel.Font.Style := [];
    AssetDistanceMaxLabel.HeightPercent := 100.000000000000000000;
    AssetDistanceMaxLabel.ParentFont := False;
    AssetDistanceMaxLabel.Visible := False;
    AssetDistanceMaxLabel.WidthPercent := 100.000000000000000000;
    ReportTypeAssetActiveAllEdit.SetParentComponent(TMSFNCPageControl1Page2);
    ReportTypeAssetActiveAllEdit.Name := 'ReportTypeAssetActiveAllEdit';
    ReportTypeAssetActiveAllEdit.Left := 28;
    ReportTypeAssetActiveAllEdit.Top := 56;
    ReportTypeAssetActiveAllEdit.Width := 165;
    ReportTypeAssetActiveAllEdit.Height := 22;
    ReportTypeAssetActiveAllEdit.Caption := 'All';
    ReportTypeAssetActiveAllEdit.Checked := True;
    ReportTypeAssetActiveAllEdit.ChildOrder := 1;
    ReportTypeAssetActiveAllEdit.Color := clNone;
    ReportTypeAssetActiveAllEdit.GroupName := 'ActiveInactive';
    ReportTypeAssetActiveAllEdit.HeightPercent := 100.000000000000000000;
    ReportTypeAssetActiveAllEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeAssetActiveAllEdit, Self, 'OnClick', 'RecordChange');
    ReportTypeAssetActiveYesEdit.SetParentComponent(TMSFNCPageControl1Page2);
    ReportTypeAssetActiveYesEdit.Name := 'ReportTypeAssetActiveYesEdit';
    ReportTypeAssetActiveYesEdit.Left := 28;
    ReportTypeAssetActiveYesEdit.Top := 84;
    ReportTypeAssetActiveYesEdit.Width := 165;
    ReportTypeAssetActiveYesEdit.Height := 22;
    ReportTypeAssetActiveYesEdit.Caption := 'Active';
    ReportTypeAssetActiveYesEdit.Checked := False;
    ReportTypeAssetActiveYesEdit.ChildOrder := 2;
    ReportTypeAssetActiveYesEdit.Color := clNone;
    ReportTypeAssetActiveYesEdit.GroupName := 'ActiveInactive';
    ReportTypeAssetActiveYesEdit.HeightPercent := 100.000000000000000000;
    ReportTypeAssetActiveYesEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeAssetActiveYesEdit, Self, 'OnClick', 'RecordChange');
    ReportTypeAssetActiveNoEdit.SetParentComponent(TMSFNCPageControl1Page2);
    ReportTypeAssetActiveNoEdit.Name := 'ReportTypeAssetActiveNoEdit';
    ReportTypeAssetActiveNoEdit.Left := 28;
    ReportTypeAssetActiveNoEdit.Top := 112;
    ReportTypeAssetActiveNoEdit.Width := 91;
    ReportTypeAssetActiveNoEdit.Height := 22;
    ReportTypeAssetActiveNoEdit.Caption := 'Inactive';
    ReportTypeAssetActiveNoEdit.Checked := False;
    ReportTypeAssetActiveNoEdit.ChildOrder := 2;
    ReportTypeAssetActiveNoEdit.Color := clNone;
    ReportTypeAssetActiveNoEdit.GroupName := 'ActiveInactive';
    ReportTypeAssetActiveNoEdit.HeightPercent := 100.000000000000000000;
    ReportTypeAssetActiveNoEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeAssetActiveNoEdit, Self, 'OnClick', 'RecordChange');
    AssetStatusEdit.SetParentComponent(TMSFNCPageControl1Page2);
    AssetStatusEdit.Name := 'AssetStatusEdit';
    AssetStatusEdit.AlignWithMargins := True;
    AssetStatusEdit.Left := 520;
    AssetStatusEdit.Top := 56;
    AssetStatusEdit.Width := 312;
    AssetStatusEdit.Height := 34;
    AssetStatusEdit.Margins.Left := 20;
    AssetStatusEdit.Margins.Top := 8;
    AssetStatusEdit.Margins.Bottom := 8;
    AssetStatusEdit.Color := clWhite;
    AssetStatusEdit.ElementClassName := 'myselect_white';
    AssetStatusEdit.Font.Charset := DEFAULT_CHARSET;
    AssetStatusEdit.Font.Color := clWindowText;
    AssetStatusEdit.Font.Height := 12;
    AssetStatusEdit.Font.Name := 'Segoe UI';
    AssetStatusEdit.Font.Style := [];
    AssetStatusEdit.HeightPercent := 100.000000000000000000;
    AssetStatusEdit.ParentFont := False;
    AssetStatusEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusEdit, Self, 'OnChange', 'RecordChange');
    AssetStatusEdit.ItemIndex := -1;
    ReportTypeAssetActiveNoTypeEdit.SetParentComponent(TMSFNCPageControl1Page2);
    ReportTypeAssetActiveNoTypeEdit.Name := 'ReportTypeAssetActiveNoTypeEdit';
    ReportTypeAssetActiveNoTypeEdit.AlignWithMargins := True;
    ReportTypeAssetActiveNoTypeEdit.Left := 122;
    ReportTypeAssetActiveNoTypeEdit.Top := 106;
    ReportTypeAssetActiveNoTypeEdit.Width := 346;
    ReportTypeAssetActiveNoTypeEdit.Height := 34;
    ReportTypeAssetActiveNoTypeEdit.Margins.Left := 20;
    ReportTypeAssetActiveNoTypeEdit.Margins.Top := 8;
    ReportTypeAssetActiveNoTypeEdit.Margins.Bottom := 8;
    ReportTypeAssetActiveNoTypeEdit.Color := clWhite;
    ReportTypeAssetActiveNoTypeEdit.ElementClassName := 'myselect_white';
    ReportTypeAssetActiveNoTypeEdit.Font.Charset := DEFAULT_CHARSET;
    ReportTypeAssetActiveNoTypeEdit.Font.Color := clWindowText;
    ReportTypeAssetActiveNoTypeEdit.Font.Height := 12;
    ReportTypeAssetActiveNoTypeEdit.Font.Name := 'Segoe UI';
    ReportTypeAssetActiveNoTypeEdit.Font.Style := [];
    ReportTypeAssetActiveNoTypeEdit.HeightPercent := 100.000000000000000000;
    ReportTypeAssetActiveNoTypeEdit.ParentFont := False;
    ReportTypeAssetActiveNoTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeAssetActiveNoTypeEdit, Self, 'OnChange', 'RecordChange');
    ReportTypeAssetActiveNoTypeEdit.ItemIndex := -1;
    ReportTypeAssetActiveNoTypeEdit.LookupValues.Clear;
    with ReportTypeAssetActiveNoTypeEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'ALL';
    end;
    with ReportTypeAssetActiveNoTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'no heartbeats received';
    end;
    with ReportTypeAssetActiveNoTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'no valid GPS data received or no movement';
    end;
    with ReportTypeAssetActiveNoTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'no heartbeats nor valid data received';
    end;
    AssetDistanceEdit.SetParentComponent(TMSFNCPageControl1Page2);
    AssetDistanceEdit.Name := 'AssetDistanceEdit';
    AssetDistanceEdit.AlignWithMargins := True;
    AssetDistanceEdit.Left := 28;
    AssetDistanceEdit.Top := 147;
    AssetDistanceEdit.Width := 323;
    AssetDistanceEdit.Height := 35;
    AssetDistanceEdit.Margins.Left := 40;
    AssetDistanceEdit.Margins.Top := 10;
    AssetDistanceEdit.Margins.Right := 40;
    AssetDistanceEdit.Caption := 'Distance travelled';
    AssetDistanceEdit.ChildOrder := 2;
    AssetDistanceEdit.Color := clWhite;
    AssetDistanceEdit.Font.Charset := ANSI_CHARSET;
    AssetDistanceEdit.Font.Color := 9010544;
    AssetDistanceEdit.Font.Height := -16;
    AssetDistanceEdit.Font.Name := 'Segoe UI';
    AssetDistanceEdit.Font.Style := [];
    AssetDistanceEdit.HeightPercent := 100.000000000000000000;
    AssetDistanceEdit.ParentFont := False;
    AssetDistanceEdit.TabOrder := 2;
    AssetDistanceEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetDistanceEdit, Self, 'OnClick', 'RecordChange');
    AssetDistanceTypeEdit.SetParentComponent(TMSFNCPageControl1Page2);
    AssetDistanceTypeEdit.Name := 'AssetDistanceTypeEdit';
    AssetDistanceTypeEdit.AlignWithMargins := True;
    AssetDistanceTypeEdit.Left := 28;
    AssetDistanceTypeEdit.Top := 181;
    AssetDistanceTypeEdit.Width := 147;
    AssetDistanceTypeEdit.Height := 34;
    AssetDistanceTypeEdit.Margins.Left := 20;
    AssetDistanceTypeEdit.Margins.Top := 8;
    AssetDistanceTypeEdit.Margins.Bottom := 8;
    AssetDistanceTypeEdit.Color := clWhite;
    AssetDistanceTypeEdit.ElementClassName := 'myselect_white';
    AssetDistanceTypeEdit.Font.Charset := DEFAULT_CHARSET;
    AssetDistanceTypeEdit.Font.Color := clWindowText;
    AssetDistanceTypeEdit.Font.Height := 12;
    AssetDistanceTypeEdit.Font.Name := 'Segoe UI';
    AssetDistanceTypeEdit.Font.Style := [];
    AssetDistanceTypeEdit.HeightPercent := 100.000000000000000000;
    AssetDistanceTypeEdit.ParentFont := False;
    AssetDistanceTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetDistanceTypeEdit, Self, 'OnChange', 'AssetDistanceTypeEditChange');
    AssetDistanceTypeEdit.ItemIndex := -1;
    AssetDistanceTypeEdit.LookupValues.Clear;
    with AssetDistanceTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Less than';
    end;
    with AssetDistanceTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Between';
    end;
    with AssetDistanceTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'More than';
    end;
    AssetDistanceMinEdit.SetParentComponent(TMSFNCPageControl1Page2);
    AssetDistanceMinEdit.Name := 'AssetDistanceMinEdit';
    AssetDistanceMinEdit.Left := 189;
    AssetDistanceMinEdit.Top := 181;
    AssetDistanceMinEdit.Width := 101;
    AssetDistanceMinEdit.Height := 34;
    AssetDistanceMinEdit.ChildOrder := 1;
    AssetDistanceMinEdit.EditType := weNumeric;
    AssetDistanceMinEdit.ElementClassName := 'MyWebEdit';
    AssetDistanceMinEdit.Font.Charset := ANSI_CHARSET;
    AssetDistanceMinEdit.Font.Color := clWindowText;
    AssetDistanceMinEdit.Font.Height := -16;
    AssetDistanceMinEdit.Font.Name := 'Segoe UI';
    AssetDistanceMinEdit.Font.Style := [];
    AssetDistanceMinEdit.HeightPercent := 100.000000000000000000;
    AssetDistanceMinEdit.ParentFont := False;
    AssetDistanceMinEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetDistanceMinEdit, Self, 'OnChange', 'RecordChange');
    AssetDistanceMaxEdit.SetParentComponent(TMSFNCPageControl1Page2);
    AssetDistanceMaxEdit.Name := 'AssetDistanceMaxEdit';
    AssetDistanceMaxEdit.Left := 340;
    AssetDistanceMaxEdit.Top := 181;
    AssetDistanceMaxEdit.Width := 101;
    AssetDistanceMaxEdit.Height := 34;
    AssetDistanceMaxEdit.ChildOrder := 1;
    AssetDistanceMaxEdit.EditType := weNumeric;
    AssetDistanceMaxEdit.ElementClassName := 'MyWebEdit';
    AssetDistanceMaxEdit.Font.Charset := ANSI_CHARSET;
    AssetDistanceMaxEdit.Font.Color := clWindowText;
    AssetDistanceMaxEdit.Font.Height := -16;
    AssetDistanceMaxEdit.Font.Name := 'Segoe UI';
    AssetDistanceMaxEdit.Font.Style := [];
    AssetDistanceMaxEdit.HeightPercent := 100.000000000000000000;
    AssetDistanceMaxEdit.ParentFont := False;
    AssetDistanceMaxEdit.Visible := False;
    AssetDistanceMaxEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetDistanceMaxEdit, Self, 'OnChange', 'RecordChange');
    TMSFNCPageControl1Page1.SetParentComponent(ReportTypePageControl);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 848;
    TMSFNCPageControl1Page1.Height := 267;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.PageIndex := 0;
    TMSFNCPageControl1Page1.IsActive := True;
    WebLabel7.SetParentComponent(TMSFNCPageControl1Page1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 20;
    WebLabel7.Top := 18;
    WebLabel7.Width := 48;
    WebLabel7.Height := 21;
    WebLabel7.Caption := 'Routes';
    WebLabel7.Font.Charset := ANSI_CHARSET;
    WebLabel7.Font.Color := 9010544;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Segoe UI';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    ReportTypeTripAllEdit.SetParentComponent(TMSFNCPageControl1Page1);
    ReportTypeTripAllEdit.Name := 'ReportTypeTripAllEdit';
    ReportTypeTripAllEdit.Tag := 1;
    ReportTypeTripAllEdit.Left := 20;
    ReportTypeTripAllEdit.Top := 48;
    ReportTypeTripAllEdit.Width := 113;
    ReportTypeTripAllEdit.Height := 22;
    ReportTypeTripAllEdit.Caption := 'All';
    ReportTypeTripAllEdit.Checked := True;
    ReportTypeTripAllEdit.ChildOrder := 1;
    ReportTypeTripAllEdit.Color := clNone;
    ReportTypeTripAllEdit.Font.Charset := ANSI_CHARSET;
    ReportTypeTripAllEdit.Font.Color := clWindowText;
    ReportTypeTripAllEdit.Font.Height := -16;
    ReportTypeTripAllEdit.Font.Name := 'Segoe UI';
    ReportTypeTripAllEdit.Font.Style := [];
    ReportTypeTripAllEdit.GroupName := 'Legs';
    ReportTypeTripAllEdit.HeightPercent := 100.000000000000000000;
    ReportTypeTripAllEdit.ParentFont := False;
    ReportTypeTripAllEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeTripAllEdit, Self, 'OnClick', 'RecordChange');
    ReportTypeTripFirstEdit.SetParentComponent(TMSFNCPageControl1Page1);
    ReportTypeTripFirstEdit.Name := 'ReportTypeTripFirstEdit';
    ReportTypeTripFirstEdit.Tag := 2;
    ReportTypeTripFirstEdit.Left := 20;
    ReportTypeTripFirstEdit.Top := 76;
    ReportTypeTripFirstEdit.Width := 113;
    ReportTypeTripFirstEdit.Height := 22;
    ReportTypeTripFirstEdit.Caption := 'Only First';
    ReportTypeTripFirstEdit.Checked := False;
    ReportTypeTripFirstEdit.ChildOrder := 1;
    ReportTypeTripFirstEdit.Color := clNone;
    ReportTypeTripFirstEdit.Font.Charset := ANSI_CHARSET;
    ReportTypeTripFirstEdit.Font.Color := clWindowText;
    ReportTypeTripFirstEdit.Font.Height := -16;
    ReportTypeTripFirstEdit.Font.Name := 'Segoe UI';
    ReportTypeTripFirstEdit.Font.Style := [];
    ReportTypeTripFirstEdit.GroupName := 'Legs';
    ReportTypeTripFirstEdit.HeightPercent := 100.000000000000000000;
    ReportTypeTripFirstEdit.ParentFont := False;
    ReportTypeTripFirstEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeTripFirstEdit, Self, 'OnClick', 'RecordChange');
    ReportTypeTripLastEdit.SetParentComponent(TMSFNCPageControl1Page1);
    ReportTypeTripLastEdit.Name := 'ReportTypeTripLastEdit';
    ReportTypeTripLastEdit.Tag := 3;
    ReportTypeTripLastEdit.Left := 20;
    ReportTypeTripLastEdit.Top := 104;
    ReportTypeTripLastEdit.Width := 113;
    ReportTypeTripLastEdit.Height := 22;
    ReportTypeTripLastEdit.Caption := 'Only Last';
    ReportTypeTripLastEdit.Checked := False;
    ReportTypeTripLastEdit.ChildOrder := 1;
    ReportTypeTripLastEdit.Color := clNone;
    ReportTypeTripLastEdit.Font.Charset := ANSI_CHARSET;
    ReportTypeTripLastEdit.Font.Color := clWindowText;
    ReportTypeTripLastEdit.Font.Height := -16;
    ReportTypeTripLastEdit.Font.Name := 'Segoe UI';
    ReportTypeTripLastEdit.Font.Style := [];
    ReportTypeTripLastEdit.GroupName := 'Legs';
    ReportTypeTripLastEdit.HeightPercent := 100.000000000000000000;
    ReportTypeTripLastEdit.ParentFont := False;
    ReportTypeTripLastEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeTripLastEdit, Self, 'OnClick', 'RecordChange');
    ReportTypeTripBothEdit.SetParentComponent(TMSFNCPageControl1Page1);
    ReportTypeTripBothEdit.Name := 'ReportTypeTripBothEdit';
    ReportTypeTripBothEdit.Tag := 4;
    ReportTypeTripBothEdit.Left := 20;
    ReportTypeTripBothEdit.Top := 132;
    ReportTypeTripBothEdit.Width := 155;
    ReportTypeTripBothEdit.Height := 22;
    ReportTypeTripBothEdit.Caption := 'First & Last';
    ReportTypeTripBothEdit.Checked := False;
    ReportTypeTripBothEdit.ChildOrder := 1;
    ReportTypeTripBothEdit.Color := clNone;
    ReportTypeTripBothEdit.Font.Charset := ANSI_CHARSET;
    ReportTypeTripBothEdit.Font.Color := clWindowText;
    ReportTypeTripBothEdit.Font.Height := -16;
    ReportTypeTripBothEdit.Font.Name := 'Segoe UI';
    ReportTypeTripBothEdit.Font.Style := [];
    ReportTypeTripBothEdit.GroupName := 'Legs';
    ReportTypeTripBothEdit.HeightPercent := 100.000000000000000000;
    ReportTypeTripBothEdit.ParentFont := False;
    ReportTypeTripBothEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportTypeTripBothEdit, Self, 'OnClick', 'RecordChange');
    IgnoreDistanceEdit.SetParentComponent(TMSFNCPageControl1Page1);
    IgnoreDistanceEdit.Name := 'IgnoreDistanceEdit';
    IgnoreDistanceEdit.AlignWithMargins := True;
    IgnoreDistanceEdit.Left := 20;
    IgnoreDistanceEdit.Top := 157;
    IgnoreDistanceEdit.Width := 323;
    IgnoreDistanceEdit.Height := 35;
    IgnoreDistanceEdit.Margins.Left := 40;
    IgnoreDistanceEdit.Margins.Top := 10;
    IgnoreDistanceEdit.Margins.Right := 40;
    IgnoreDistanceEdit.Caption := 'Show routes with distance < 100m';
    IgnoreDistanceEdit.ChildOrder := 2;
    IgnoreDistanceEdit.Color := clWhite;
    IgnoreDistanceEdit.Font.Charset := ANSI_CHARSET;
    IgnoreDistanceEdit.Font.Color := 9010544;
    IgnoreDistanceEdit.Font.Height := -16;
    IgnoreDistanceEdit.Font.Name := 'Segoe UI';
    IgnoreDistanceEdit.Font.Style := [];
    IgnoreDistanceEdit.HeightPercent := 100.000000000000000000;
    IgnoreDistanceEdit.ParentFont := False;
    IgnoreDistanceEdit.TabOrder := 2;
    IgnoreDistanceEdit.WidthPercent := 100.000000000000000000;
    SetEvent(IgnoreDistanceEdit, Self, 'OnClick', 'RecordChange');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 874;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 90;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Report - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 845;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uReportEdit.CaptionPanel.CloseImage.Picture.png');
    SendToDBAdapter.SetParentComponent(Self);
    SendToDBAdapter.Name := 'SendToDBAdapter';
    SendToDBAdapter.Left := 53;
    SendToDBAdapter.Top := 542;
    SendToDBAdapter.Width := 26;
    SendToDBAdapter.Height := 26;
    SendToDBAdapter.Margins.Left := 12;
    SendToDBAdapter.Visible := True;
    SendToDBAdapter.Grid := SendToGrid;
    SendToDBAdapter.DataSource := SendToDataSource;
    SendToDBAdapter.Columns.Clear;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'Checked';
      Header := '  Send To';
      CheckBoxField := True;
      CheckFalse := 'f';
      CheckTrue := 't';
    end;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'Email';
      Header := '  Email';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'Asset Group';
      Header := '   Asset Group';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    SendToDBAdapter.AutoCreateColumns := False;
    SendToDBAdapter.AutoRemoveColumns := False;
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 807;
    WebWaitMessage.Top := 8;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uReportEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 770;
    MyMessageDlg.Top := 8;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    SendToDataSet.SetParentComponent(Self);
    SendToDataSet.Name := 'SendToDataSet';
    SendToDataSet.AfterOpen := SendToDataSetAfterOpen;
    SendToDataSet.BeforeEdit := SendToDataSetBeforeEdit;
    SendToDataSet.AfterPost := SendToDataSetAfterPost;
    SendToDataSet.Left := 532;
    SendToDataSet.Top := 560;
    SendToDataSetPKey.SetParentComponent(SendToDataSet);
    SendToDataSetPKey.Name := 'SendToDataSetPKey';
    SendToDataSetPKey.FieldName := 'PKey';
    SendToDataSetPKey.Size := 0;
    SendToDataSetChecked.SetParentComponent(SendToDataSet);
    SendToDataSetChecked.Name := 'SendToDataSetChecked';
    SendToDataSetChecked.FieldName := 'Checked';
    SendToDataSetChecked.Size := 40;
    SendToDataSetEmail.SetParentComponent(SendToDataSet);
    SendToDataSetEmail.Name := 'SendToDataSetEmail';
    SendToDataSetEmail.FieldName := 'Email';
    SendToDataSetEmail.ReadOnly := True;
    SendToDataSetEmail.Size := 256;
    SendToDataSetAssetGroup.SetParentComponent(SendToDataSet);
    SendToDataSetAssetGroup.Name := 'SendToDataSetAssetGroup';
    SendToDataSetAssetGroup.FieldName := 'Asset Group';
    SendToDataSetAssetGroup.ReadOnly := True;
    SendToDataSetAssetGroup.Size := 256;
    SendToDataSource.SetParentComponent(Self);
    SendToDataSource.Name := 'SendToDataSource';
    SendToDataSource.DataSet := SendToDataSet;
    SendToDataSource.Left := 628;
    SendToDataSource.Top := 560;
    SendToTimer.SetParentComponent(Self);
    SendToTimer.Name := 'SendToTimer';
    SendToTimer.Enabled := False;
    SendToTimer.Interval := 100;
    SetEvent(SendToTimer, Self, 'OnTimer', 'SendToTimerTimer');
    SendToTimer.Left := 723;
    SendToTimer.Top := 560;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ReportTypeLabel.AfterLoadDFMValues;
    ReportFormatLabel.AfterLoadDFMValues;
    PeriodLabel.AfterLoadDFMValues;
    DateFromLabel.AfterLoadDFMValues;
    DateTillLabel.AfterLoadDFMValues;
    ReportTitleLabel.AfterLoadDFMValues;
    ObjectFilterTypeLabel.AfterLoadDFMValues;
    TeamFilterEdit.AfterLoadDFMValues;
    ObjectNoEdit.AfterLoadDFMValues;
    ObjectIDEdit.AfterLoadDFMValues;
    ReportTypeEdit.AfterLoadDFMValues;
    ReportFormatEdit.AfterLoadDFMValues;
    PeriodEdit.AfterLoadDFMValues;
    DateTillEdit.AfterLoadDFMValues;
    DateFromEdit.AfterLoadDFMValues;
    TypeFilterEdit.AfterLoadDFMValues;
    ReportTitleEdit.AfterLoadDFMValues;
    ListButtonAllPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    PrintButton.AfterLoadDFMValues;
    MailButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    DeselectButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    ObjectFilterTypeEdit.AfterLoadDFMValues;
    ReportTypePageControl.AfterLoadDFMValues;
    ReportTypePageControlPage5.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    MailToMyselfEdit.AfterLoadDFMValues;
    MailToGroupsEdit.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    MailToLabel.AfterLoadDFMValues;
    RecipientListEdit.AfterLoadDFMValues;
    SendToGrid.AfterLoadDFMValues;
    ReportTypePageControlPage3.AfterLoadDFMValues;
    ReportTypeDriverIgnoreDistanceEdit.AfterLoadDFMValues;
    ReportTypePageControlPage4.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    MOTDays1Edit.AfterLoadDFMValues;
    MOTDays2Edit.AfterLoadDFMValues;
    ReportTypeMOTPeriod.AfterLoadDFMValues;
    ReportTypeMOTDays.AfterLoadDFMValues;
    ReportTypePageControlPage6.AfterLoadDFMValues;
    DeviceLowBatteryEdit.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    ScheduleDayLabel.AfterLoadDFMValues;
    ScheduleMonthLabel.AfterLoadDFMValues;
    MondayEdit.AfterLoadDFMValues;
    TuesdayEdit.AfterLoadDFMValues;
    WednesdayEdit.AfterLoadDFMValues;
    ThursdayEdit.AfterLoadDFMValues;
    FridayEdit.AfterLoadDFMValues;
    SaturdayEdit.AfterLoadDFMValues;
    SundayEdit.AfterLoadDFMValues;
    ScheduleTypeEdit.AfterLoadDFMValues;
    ScheduleDayEdit.AfterLoadDFMValues;
    ScheduleMonthEdit.AfterLoadDFMValues;
    ReportTypePageControlPage8.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    InsuranceDays1Edit.AfterLoadDFMValues;
    InsuranceDays2Edit.AfterLoadDFMValues;
    ReportTypeInsurancePeriod.AfterLoadDFMValues;
    ReportTypeInsuranceDays.AfterLoadDFMValues;
    ReportTypePageControlPage7.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    AlertListEdit.AfterLoadDFMValues;
    AlertCriticalEdit.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    AssetDistanceMinLabel.AfterLoadDFMValues;
    AssetDistanceMaxLabel.AfterLoadDFMValues;
    ReportTypeAssetActiveAllEdit.AfterLoadDFMValues;
    ReportTypeAssetActiveYesEdit.AfterLoadDFMValues;
    ReportTypeAssetActiveNoEdit.AfterLoadDFMValues;
    AssetStatusEdit.AfterLoadDFMValues;
    ReportTypeAssetActiveNoTypeEdit.AfterLoadDFMValues;
    AssetDistanceEdit.AfterLoadDFMValues;
    AssetDistanceTypeEdit.AfterLoadDFMValues;
    AssetDistanceMinEdit.AfterLoadDFMValues;
    AssetDistanceMaxEdit.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    ReportTypeTripAllEdit.AfterLoadDFMValues;
    ReportTypeTripFirstEdit.AfterLoadDFMValues;
    ReportTypeTripLastEdit.AfterLoadDFMValues;
    ReportTypeTripBothEdit.AfterLoadDFMValues;
    IgnoreDistanceEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    SendToDBAdapter.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    SendToDataSet.AfterLoadDFMValues;
    SendToDataSetPKey.AfterLoadDFMValues;
    SendToDataSetChecked.AfterLoadDFMValues;
    SendToDataSetEmail.AfterLoadDFMValues;
    SendToDataSetAssetGroup.AfterLoadDFMValues;
    SendToDataSource.AfterLoadDFMValues;
    SendToTimer.AfterLoadDFMValues;
  end;
end;

end.

unit uReportFilter;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCPageControl,
  Vcl.Controls, VCL.TMSFNCCustomControl, VCL.TMSFNCTabSet, Vcl.StdCtrls,
  WEBLib.ExtCtrls, VCL.TMSFNCPanel, WEBLib.StdCtrls, Vcl.Imaging.pngimage,
  WEBLib.Buttons;

type
  TReportFilterForm = class(TForm)
    TMSFNCPageControl1: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    UsernameLabel: TLabel;
    WebLookupComboBox2: TLookupComboBox;
    WebLabel1: TLabel;
    WebLookupComboBox5: TLookupComboBox;
    WebLabel2: TLabel;
    WebLookupComboBox3: TLookupComboBox;
    DateEdit: TDateTimePicker;
    WebDateTimePicker1: TDateTimePicker;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    FromEdit: TDateTimePicker;
    WebDateTimePicker2: TDateTimePicker;
    WebLookupComboBox6: TLookupComboBox;
    WebLookupComboBox7: TLookupComboBox;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebRadioButton1: TRadioButton;
    WebRadioButton2: TRadioButton;
    WebRadioButton3: TRadioButton;
    WebRadioButton4: TRadioButton;
    WebLabel7: TLabel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    WebLabel8: TLabel;
    UsernameEdit: TEdit;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ReportFilterForm: TReportFilterForm;

implementation

{$R *.dfm}

procedure TReportFilterForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UsernameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  TMSFNCPageControl1 := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebRadioButton1 := TRadioButton.Create(Self);
  WebRadioButton2 := TRadioButton.Create(Self);
  WebRadioButton3 := TRadioButton.Create(Self);
  WebRadioButton4 := TRadioButton.Create(Self);
  WebLookupComboBox2 := TLookupComboBox.Create(Self);
  WebLookupComboBox5 := TLookupComboBox.Create(Self);
  WebLookupComboBox3 := TLookupComboBox.Create(Self);
  DateEdit := TDateTimePicker.Create(Self);
  WebDateTimePicker1 := TDateTimePicker.Create(Self);
  FromEdit := TDateTimePicker.Create(Self);
  WebDateTimePicker2 := TDateTimePicker.Create(Self);
  WebLookupComboBox6 := TLookupComboBox.Create(Self);
  WebLookupComboBox7 := TLookupComboBox.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  UsernameEdit := TEdit.Create(Self);

  UsernameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  TMSFNCPageControl1.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebRadioButton1.BeforeLoadDFMValues;
  WebRadioButton2.BeforeLoadDFMValues;
  WebRadioButton3.BeforeLoadDFMValues;
  WebRadioButton4.BeforeLoadDFMValues;
  WebLookupComboBox2.BeforeLoadDFMValues;
  WebLookupComboBox5.BeforeLoadDFMValues;
  WebLookupComboBox3.BeforeLoadDFMValues;
  DateEdit.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  FromEdit.BeforeLoadDFMValues;
  WebDateTimePicker2.BeforeLoadDFMValues;
  WebLookupComboBox6.BeforeLoadDFMValues;
  WebLookupComboBox7.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  try
    Name := 'ReportFilterForm';
    Width := 813;
    Height := 622;
    UsernameLabel.SetParentComponent(Self);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 40;
    UsernameLabel.Top := 82;
    UsernameLabel.Width := 32;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'Type';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 424;
    WebLabel1.Top := 82;
    WebLabel1.Width := 50;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Format';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 40;
    WebLabel2.Top := 188;
    WebLabel2.Width := 44;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Period';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 40;
    WebLabel3.Top := 230;
    WebLabel3.Width := 73;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Date From';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 40;
    WebLabel4.Top := 269;
    WebLabel4.Width := 56;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Date Till';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 424;
    WebLabel5.Top := 188;
    WebLabel5.Width := 75;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'Team Filter';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 422;
    WebLabel6.Top := 230;
    WebLabel6.Width := 71;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'Type Filter';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := 9010544;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 40;
    WebLabel8.Top := 8;
    WebLabel8.Width := 29;
    WebLabel8.Height := 21;
    WebLabel8.Caption := 'Title';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := 9010544;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    TMSFNCPageControl1.SetParentComponent(Self);
    TMSFNCPageControl1.Name := 'TMSFNCPageControl1';
    TMSFNCPageControl1.AlignWithMargins := True;
    TMSFNCPageControl1.Left := 40;
    TMSFNCPageControl1.Top := 328;
    TMSFNCPageControl1.Width := 729;
    TMSFNCPageControl1.Height := 225;
    TMSFNCPageControl1.Margins.Left := 10;
    TMSFNCPageControl1.Margins.Top := 0;
    TMSFNCPageControl1.Margins.Right := 10;
    TMSFNCPageControl1.Margins.Bottom := 8;
    TMSFNCPageControl1.BevelEdges := [];
    TMSFNCPageControl1.BevelInner := bvNone;
    TMSFNCPageControl1.BevelOuter := bvNone;
    TMSFNCPageControl1.ParentDoubleBuffered := False;
    TMSFNCPageControl1.Color := clBtnFace;
    TMSFNCPageControl1.DoubleBuffered := True;
    TMSFNCPageControl1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl1.Font.Color := clWindowText;
    TMSFNCPageControl1.Font.Height := -16;
    TMSFNCPageControl1.Font.Name := 'Tahoma';
    TMSFNCPageControl1.Font.Style := [];
    TMSFNCPageControl1.ParentFont := False;
    TMSFNCPageControl1.TabOrder := 0;
    TMSFNCPageControl1.Fill.Kind := gfkNone;
    TMSFNCPageControl1.Fill.ColorTo := clWhite;
    TMSFNCPageControl1.Stroke.Kind := gskNone;
    TMSFNCPageControl1.TabAppearance.Font.Charset := ANSI_CHARSET;
    TMSFNCPageControl1.TabAppearance.Font.Color := clAqua;
    TMSFNCPageControl1.TabAppearance.Font.Height := -16;
    TMSFNCPageControl1.TabAppearance.Font.Name := 'Segoe UI';
    TMSFNCPageControl1.TabAppearance.Font.Style := [];
    TMSFNCPageControl1.TabAppearance.ProgressFill.Color := 16297243;
    TMSFNCPageControl1.TabAppearance.ProgressStroke.Color := 16297243;
    TMSFNCPageControl1.TabAppearance.Fill.Color := 14408667;
    TMSFNCPageControl1.TabAppearance.Stroke.Color := -1;
    TMSFNCPageControl1.TabAppearance.ActiveFill.ColorTo := clWhite;
    TMSFNCPageControl1.TabAppearance.ActiveStroke.Color := 11119017;
    TMSFNCPageControl1.TabAppearance.DisabledFill.Color := 13882323;
    TMSFNCPageControl1.TabAppearance.DisabledStroke.Color := 11119017;
    TMSFNCPageControl1.TabAppearance.DownFill.Color := 15655897;
    TMSFNCPageControl1.TabAppearance.DownStroke.Color := 11119017;
    TMSFNCPageControl1.TabAppearance.HoverFill.Color := 15658734;
    TMSFNCPageControl1.TabAppearance.HoverStroke.Color := -1;
    TMSFNCPageControl1.TabAppearance.TextSpacing := 10.000000000000000000;
    TMSFNCPageControl1.TabAppearance.Shape := tsRectangle;
    TMSFNCPageControl1.TabAppearance.ShapeOverlap := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.ShapeSlope := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.ShapeRounding := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.TextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.ActiveTextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.HoverTextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.ShowFocus := False;
    TMSFNCPageControl1.TabAppearance.CloseFill.Kind := gfkNone;
    TMSFNCPageControl1.TabAppearance.CloseFill.Color := 16316397;
    TMSFNCPageControl1.TabAppearance.CloseStroke.Color := 11119017;
    TMSFNCPageControl1.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.CloseDownFill.Color := 3937500;
    TMSFNCPageControl1.TabAppearance.CloseDownStroke.Color := clWhite;
    TMSFNCPageControl1.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.CloseHoverFill.Color := clRed;
    TMSFNCPageControl1.TabAppearance.CloseHoverStroke.Color := clWhite;
    TMSFNCPageControl1.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.BadgeFill.Color := 42495;
    TMSFNCPageControl1.TabAppearance.BadgeStroke.Color := 139;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Color := 139;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Height := -11;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Name := 'Tahoma';
    TMSFNCPageControl1.TabAppearance.BadgeFont.Style := [fsBold];
    TMSFNCPageControl1.ButtonAppearance.Fill.Color := 16316397;
    TMSFNCPageControl1.ButtonAppearance.Stroke.Color := 11119017;
    TMSFNCPageControl1.ButtonAppearance.DownFill.Color := 15655897;
    TMSFNCPageControl1.ButtonAppearance.DownStroke.Color := 11119017;
    TMSFNCPageControl1.ButtonAppearance.DisabledFill.Color := 13882323;
    TMSFNCPageControl1.ButtonAppearance.DisabledStroke.Color := 11119017;
    TMSFNCPageControl1.ButtonAppearance.HoverFill.Color := 16313827;
    TMSFNCPageControl1.ButtonAppearance.HoverStroke.Color := 11119017;
    TMSFNCPageControl1.ButtonAppearance.CloseIcon.LoadFromFile('uReportFilter.TMSFNCPageControl1.ButtonAppearance.CloseIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.InsertIcon.LoadFromFile('uReportFilter.TMSFNCPageControl1.ButtonAppearance.InsertIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.TabListIcon.LoadFromFile('uReportFilter.TMSFNCPageControl1.ButtonAppearance.TabListIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.ScrollNextIcon.LoadFromFile('uReportFilter.TMSFNCPageControl1.ButtonAppearance.ScrollNextIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uReportFilter.TMSFNCPageControl1.ButtonAppearance.ScrollPreviousIcon.svg');
    TMSFNCPageControl1.TabSize.Height := 45.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Left := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Top := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Right := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Bottom := 8.000000000000000000;
    TMSFNCPageControl1.Pages.Clear;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'TRIPS';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'Reports';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'BMW';
      UseDefaultAppearance := True;
    end;
    TMSFNCPageControl1Page2.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 729;
    TMSFNCPageControl1Page2.Height := 173;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    TMSFNCPageControl1Page1.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 729;
    TMSFNCPageControl1Page1.Height := 173;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    TMSFNCPageControl1Page0.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 729;
    TMSFNCPageControl1Page0.Height := 173;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    WebLabel7.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 32;
    WebLabel7.Top := 18;
    WebLabel7.Width := 33;
    WebLabel7.Height := 21;
    WebLabel7.Caption := 'Trips';
    WebLabel7.Font.Charset := ANSI_CHARSET;
    WebLabel7.Font.Color := 9010544;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Segoe UI';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebRadioButton1.SetParentComponent(TMSFNCPageControl1Page0);
    WebRadioButton1.Name := 'WebRadioButton1';
    WebRadioButton1.Left := 32;
    WebRadioButton1.Top := 48;
    WebRadioButton1.Width := 113;
    WebRadioButton1.Height := 22;
    WebRadioButton1.Caption := 'All Trips';
    WebRadioButton1.Checked := False;
    WebRadioButton1.Color := clNone;
    WebRadioButton1.HeightPercent := 100.000000000000000000;
    WebRadioButton1.WidthPercent := 100.000000000000000000;
    WebRadioButton2.SetParentComponent(TMSFNCPageControl1Page0);
    WebRadioButton2.Name := 'WebRadioButton2';
    WebRadioButton2.Left := 151;
    WebRadioButton2.Top := 48;
    WebRadioButton2.Width := 113;
    WebRadioButton2.Height := 22;
    WebRadioButton2.Caption := 'First Trip';
    WebRadioButton2.Checked := False;
    WebRadioButton2.ChildOrder := 1;
    WebRadioButton2.Color := clNone;
    WebRadioButton2.HeightPercent := 100.000000000000000000;
    WebRadioButton2.WidthPercent := 100.000000000000000000;
    WebRadioButton3.SetParentComponent(TMSFNCPageControl1Page0);
    WebRadioButton3.Name := 'WebRadioButton3';
    WebRadioButton3.Left := 270;
    WebRadioButton3.Top := 48;
    WebRadioButton3.Width := 113;
    WebRadioButton3.Height := 22;
    WebRadioButton3.Caption := 'Last Trip';
    WebRadioButton3.Checked := False;
    WebRadioButton3.ChildOrder := 1;
    WebRadioButton3.Color := clNone;
    WebRadioButton3.HeightPercent := 100.000000000000000000;
    WebRadioButton3.WidthPercent := 100.000000000000000000;
    WebRadioButton4.SetParentComponent(TMSFNCPageControl1Page0);
    WebRadioButton4.Name := 'WebRadioButton4';
    WebRadioButton4.Left := 382;
    WebRadioButton4.Top := 48;
    WebRadioButton4.Width := 155;
    WebRadioButton4.Height := 22;
    WebRadioButton4.Caption := 'First && Last Trip';
    WebRadioButton4.Checked := False;
    WebRadioButton4.ChildOrder := 1;
    WebRadioButton4.Color := clNone;
    WebRadioButton4.HeightPercent := 100.000000000000000000;
    WebRadioButton4.WidthPercent := 100.000000000000000000;
    WebLookupComboBox2.SetParentComponent(Self);
    WebLookupComboBox2.Name := 'WebLookupComboBox2';
    WebLookupComboBox2.AlignWithMargins := True;
    WebLookupComboBox2.Left := 40;
    WebLookupComboBox2.Top := 114;
    WebLookupComboBox2.Width := 345;
    WebLookupComboBox2.Height := 34;
    WebLookupComboBox2.Margins.Left := 20;
    WebLookupComboBox2.Margins.Top := 8;
    WebLookupComboBox2.Margins.Bottom := 8;
    WebLookupComboBox2.Color := clWhite;
    WebLookupComboBox2.ElementClassName := 'myselect';
    WebLookupComboBox2.Font.Charset := DEFAULT_CHARSET;
    WebLookupComboBox2.Font.Color := clWindowText;
    WebLookupComboBox2.Font.Height := 12;
    WebLookupComboBox2.Font.Name := 'Segoe UI';
    WebLookupComboBox2.Font.Style := [];
    WebLookupComboBox2.HeightPercent := 100.000000000000000000;
    WebLookupComboBox2.ParentFont := False;
    WebLookupComboBox2.WidthPercent := 100.000000000000000000;
    WebLookupComboBox2.ItemIndex := -1;
    WebLookupComboBox2.LookupValues.Clear;
    with WebLookupComboBox2.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'TRIPS';
    end;
    with WebLookupComboBox2.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'B';
    end;
    with WebLookupComboBox2.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'C';
    end;
    WebLookupComboBox5.SetParentComponent(Self);
    WebLookupComboBox5.Name := 'WebLookupComboBox5';
    WebLookupComboBox5.AlignWithMargins := True;
    WebLookupComboBox5.Left := 424;
    WebLookupComboBox5.Top := 114;
    WebLookupComboBox5.Width := 345;
    WebLookupComboBox5.Height := 34;
    WebLookupComboBox5.Margins.Left := 20;
    WebLookupComboBox5.Margins.Top := 8;
    WebLookupComboBox5.Margins.Bottom := 8;
    WebLookupComboBox5.Color := clWhite;
    WebLookupComboBox5.ElementClassName := 'myselect';
    WebLookupComboBox5.Font.Charset := DEFAULT_CHARSET;
    WebLookupComboBox5.Font.Color := clWindowText;
    WebLookupComboBox5.Font.Height := 12;
    WebLookupComboBox5.Font.Name := 'Segoe UI';
    WebLookupComboBox5.Font.Style := [];
    WebLookupComboBox5.HeightPercent := 100.000000000000000000;
    WebLookupComboBox5.ParentFont := False;
    WebLookupComboBox5.WidthPercent := 100.000000000000000000;
    WebLookupComboBox5.ItemIndex := -1;
    WebLookupComboBox5.LookupValues.Clear;
    with WebLookupComboBox5.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'PDF';
    end;
    with WebLookupComboBox5.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'HTML';
    end;
    with WebLookupComboBox5.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'XLS';
    end;
    WebLookupComboBox3.SetParentComponent(Self);
    WebLookupComboBox3.Name := 'WebLookupComboBox3';
    WebLookupComboBox3.AlignWithMargins := True;
    WebLookupComboBox3.Left := 123;
    WebLookupComboBox3.Top := 180;
    WebLookupComboBox3.Width := 262;
    WebLookupComboBox3.Height := 34;
    WebLookupComboBox3.Margins.Left := 20;
    WebLookupComboBox3.Margins.Top := 8;
    WebLookupComboBox3.Margins.Bottom := 8;
    WebLookupComboBox3.Color := clWhite;
    WebLookupComboBox3.ElementClassName := 'myselect';
    WebLookupComboBox3.Font.Charset := DEFAULT_CHARSET;
    WebLookupComboBox3.Font.Color := clWindowText;
    WebLookupComboBox3.Font.Height := 12;
    WebLookupComboBox3.Font.Name := 'Segoe UI';
    WebLookupComboBox3.Font.Style := [];
    WebLookupComboBox3.HeightPercent := 100.000000000000000000;
    WebLookupComboBox3.ParentFont := False;
    WebLookupComboBox3.WidthPercent := 100.000000000000000000;
    WebLookupComboBox3.ItemIndex := -1;
    WebLookupComboBox3.LookupValues.Clear;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Today';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Yesterday';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'This week';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'Last Week';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'This Month';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '6';
      DisplayText := 'Last Month';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '7';
      DisplayText := 'This Year';
    end;
    with WebLookupComboBox3.LookupValues.Add do
    begin
      Value := '8';
      DisplayText := 'Last Year';
    end;
    DateEdit.SetParentComponent(Self);
    DateEdit.Name := 'DateEdit';
    DateEdit.AlignWithMargins := True;
    DateEdit.Left := 123;
    DateEdit.Top := 265;
    DateEdit.Width := 143;
    DateEdit.Height := 33;
    DateEdit.Margins.Left := 20;
    DateEdit.Margins.Top := 8;
    DateEdit.Margins.Bottom := 8;
    DateEdit.HeightPercent := 100.000000000000000000;
    DateEdit.WidthPercent := 100.000000000000000000;
    DateEdit.BorderStyle := bsSingle;
    DateEdit.ChildOrder := 2;
    DateEdit.Color := clWhite;
    DateEdit.Date := 44545.380967847220000000;
    DateEdit.Font.Charset := ANSI_CHARSET;
    DateEdit.Font.Color := clWindowText;
    DateEdit.Font.Height := -16;
    DateEdit.Font.Name := 'Segoe UI';
    DateEdit.Font.Style := [];
    DateEdit.ParentFont := False;
    DateEdit.Role := '';
    DateEdit.Text := '';
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.AlignWithMargins := True;
    WebDateTimePicker1.Left := 123;
    WebDateTimePicker1.Top := 225;
    WebDateTimePicker1.Width := 143;
    WebDateTimePicker1.Height := 33;
    WebDateTimePicker1.Margins.Left := 20;
    WebDateTimePicker1.Margins.Top := 8;
    WebDateTimePicker1.Margins.Bottom := 8;
    WebDateTimePicker1.HeightPercent := 100.000000000000000000;
    WebDateTimePicker1.WidthPercent := 100.000000000000000000;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 2;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 44545.380967847220000000;
    WebDateTimePicker1.Font.Charset := ANSI_CHARSET;
    WebDateTimePicker1.Font.Color := clWindowText;
    WebDateTimePicker1.Font.Height := -16;
    WebDateTimePicker1.Font.Name := 'Segoe UI';
    WebDateTimePicker1.Font.Style := [];
    WebDateTimePicker1.ParentFont := False;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
    FromEdit.SetParentComponent(Self);
    FromEdit.Name := 'FromEdit';
    FromEdit.AlignWithMargins := True;
    FromEdit.Left := 272;
    FromEdit.Top := 225;
    FromEdit.Width := 113;
    FromEdit.Height := 34;
    FromEdit.Margins.Top := 8;
    FromEdit.Margins.Bottom := 8;
    FromEdit.HeightPercent := 100.000000000000000000;
    FromEdit.WidthPercent := 100.000000000000000000;
    FromEdit.BorderStyle := bsSingle;
    FromEdit.ChildOrder := 2;
    FromEdit.Color := clWhite;
    FromEdit.Date := 44545.380967847220000000;
    FromEdit.Font.Charset := ANSI_CHARSET;
    FromEdit.Font.Color := clWindowText;
    FromEdit.Font.Height := -16;
    FromEdit.Font.Name := 'Segoe UI';
    FromEdit.Font.Style := [];
    FromEdit.Kind := dtkTime;
    FromEdit.ParentFont := False;
    FromEdit.Role := '';
    FromEdit.ShowSeconds := False;
    FromEdit.Text := '';
    WebDateTimePicker2.SetParentComponent(Self);
    WebDateTimePicker2.Name := 'WebDateTimePicker2';
    WebDateTimePicker2.AlignWithMargins := True;
    WebDateTimePicker2.Left := 272;
    WebDateTimePicker2.Top := 264;
    WebDateTimePicker2.Width := 113;
    WebDateTimePicker2.Height := 34;
    WebDateTimePicker2.Margins.Top := 8;
    WebDateTimePicker2.Margins.Bottom := 8;
    WebDateTimePicker2.HeightPercent := 100.000000000000000000;
    WebDateTimePicker2.WidthPercent := 100.000000000000000000;
    WebDateTimePicker2.BorderStyle := bsSingle;
    WebDateTimePicker2.ChildOrder := 2;
    WebDateTimePicker2.Color := clWhite;
    WebDateTimePicker2.Date := 44545.380967847220000000;
    WebDateTimePicker2.Font.Charset := ANSI_CHARSET;
    WebDateTimePicker2.Font.Color := clWindowText;
    WebDateTimePicker2.Font.Height := -16;
    WebDateTimePicker2.Font.Name := 'Segoe UI';
    WebDateTimePicker2.Font.Style := [];
    WebDateTimePicker2.Kind := dtkTime;
    WebDateTimePicker2.ParentFont := False;
    WebDateTimePicker2.Role := '';
    WebDateTimePicker2.ShowSeconds := False;
    WebDateTimePicker2.Text := '';
    WebLookupComboBox6.SetParentComponent(Self);
    WebLookupComboBox6.Name := 'WebLookupComboBox6';
    WebLookupComboBox6.AlignWithMargins := True;
    WebLookupComboBox6.Left := 520;
    WebLookupComboBox6.Top := 180;
    WebLookupComboBox6.Width := 249;
    WebLookupComboBox6.Height := 34;
    WebLookupComboBox6.Margins.Left := 20;
    WebLookupComboBox6.Margins.Top := 8;
    WebLookupComboBox6.Margins.Bottom := 8;
    WebLookupComboBox6.Color := clWhite;
    WebLookupComboBox6.ElementClassName := 'myselect';
    WebLookupComboBox6.Font.Charset := DEFAULT_CHARSET;
    WebLookupComboBox6.Font.Color := clWindowText;
    WebLookupComboBox6.Font.Height := 12;
    WebLookupComboBox6.Font.Name := 'Segoe UI';
    WebLookupComboBox6.Font.Style := [];
    WebLookupComboBox6.HeightPercent := 100.000000000000000000;
    WebLookupComboBox6.ParentFont := False;
    WebLookupComboBox6.WidthPercent := 100.000000000000000000;
    WebLookupComboBox6.ItemIndex := -1;
    WebLookupComboBox6.LookupValues.Clear;
    with WebLookupComboBox6.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'A';
    end;
    with WebLookupComboBox6.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'B';
    end;
    with WebLookupComboBox6.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'C';
    end;
    WebLookupComboBox7.SetParentComponent(Self);
    WebLookupComboBox7.Name := 'WebLookupComboBox7';
    WebLookupComboBox7.AlignWithMargins := True;
    WebLookupComboBox7.Left := 520;
    WebLookupComboBox7.Top := 225;
    WebLookupComboBox7.Width := 249;
    WebLookupComboBox7.Height := 34;
    WebLookupComboBox7.Margins.Left := 20;
    WebLookupComboBox7.Margins.Top := 8;
    WebLookupComboBox7.Margins.Bottom := 8;
    WebLookupComboBox7.Color := clWhite;
    WebLookupComboBox7.ElementClassName := 'myselect';
    WebLookupComboBox7.Font.Charset := DEFAULT_CHARSET;
    WebLookupComboBox7.Font.Color := clWindowText;
    WebLookupComboBox7.Font.Height := 12;
    WebLookupComboBox7.Font.Name := 'Segoe UI';
    WebLookupComboBox7.Font.Style := [];
    WebLookupComboBox7.HeightPercent := 100.000000000000000000;
    WebLookupComboBox7.ParentFont := False;
    WebLookupComboBox7.WidthPercent := 100.000000000000000000;
    WebLookupComboBox7.ItemIndex := -1;
    WebLookupComboBox7.LookupValues.Clear;
    with WebLookupComboBox7.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'A';
    end;
    with WebLookupComboBox7.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'B';
    end;
    with WebLookupComboBox7.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'C';
    end;
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 40;
    SaveButton.Top := 569;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uReportFilter.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 669;
    CloseButton.Top := 569;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uReportFilter.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    UsernameEdit.SetParentComponent(Self);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 40;
    UsernameEdit.Top := 35;
    UsernameEdit.Width := 729;
    UsernameEdit.Height := 34;
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := 'MyWebEdit';
    UsernameEdit.Font.Charset := ANSI_CHARSET;
    UsernameEdit.Font.Color := clWindowText;
    UsernameEdit.Font.Height := -16;
    UsernameEdit.Font.Name := 'Segoe UI';
    UsernameEdit.Font.Style := [];
    UsernameEdit.HeightPercent := 100.000000000000000000;
    UsernameEdit.ParentFont := False;
    UsernameEdit.WidthPercent := 100.000000000000000000;
  finally
    UsernameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    TMSFNCPageControl1.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebRadioButton1.AfterLoadDFMValues;
    WebRadioButton2.AfterLoadDFMValues;
    WebRadioButton3.AfterLoadDFMValues;
    WebRadioButton4.AfterLoadDFMValues;
    WebLookupComboBox2.AfterLoadDFMValues;
    WebLookupComboBox5.AfterLoadDFMValues;
    WebLookupComboBox3.AfterLoadDFMValues;
    DateEdit.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
    FromEdit.AfterLoadDFMValues;
    WebDateTimePicker2.AfterLoadDFMValues;
    WebLookupComboBox6.AfterLoadDFMValues;
    WebLookupComboBox7.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
  end;
end;

end.
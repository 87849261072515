unit uAssetTypeEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, WEBLib.JSON, DateUtils, WEBLib.RegularExpressions,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  WEBLib.WebCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCImage,
  VCL.TMSFNCPageControl, VCL.TMSFNCTabSet, Data.DB, WEBLib.DB, WEBLib.CDS,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid, VCL.TMSFNCGridDatabaseAdapter,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl,
  VCL.TMSFNCGridData, VCL.TMSFNCGrid, VCL.TMSFNCPanel;

type
  TAssetTypeEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    MyMessageDlg: TMessageDlg;
    [async]
    PageControl: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    ModelNameLabel: TLabel;
    WebLabel16: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel1: TLabel;
    NameNLEdit: TEdit;
    ImageEdit: TTMSFNCImage;
    NameFREdit: TEdit;
    NameENEdit: TEdit;
    IgnitionOnEdit: TRadioButton;
    IgnitionOffEdit: TRadioButton;
    AssetEdit: TCheckBox;
    TimeFromBEdit: TCheckBox;
    TimeTillBEdit: TCheckBox;
    TimeFromEdit: TEdit;
    TimeTillEdit: TEdit;
    SelectButton: TSpeedButton;
    AlertDBAdapter: TTMSFNCGridDatabaseAdapter;
    AlertDataset: TClientDataSet;
    AlertDatasetPKey: TStringField;
    AlertDatasetName: TStringField;
    AlertDataSource: TDataSource;
    TMSFNCPanel1: TTMSFNCPanel;
    AddAlertButton: TSpeedButton;
    TMSFNCPanel2: TTMSFNCPanel;
    DeleteAlertButton: TSpeedButton;
    AlertGrid: TTMSFNCGrid;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    [async]
    procedure SelectButtonClick(Sender: TObject); async;
    procedure CheckBoxClick(Sender: TObject);
    procedure RecordChange(Sender: TObject);
    procedure PageControlBeforeChangePage(Sender: TObject;
      ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure PageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    procedure AlertDatasetAfterOpen(DataSet: TDataSet);
    [async]
    procedure AddAlertButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteAlertButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    ImageBase64: String;
    [async]
    procedure LoadData(); async;
    [async]
    procedure LoadAlertList(); async;
    [async]
    procedure AddAlert(AlertPKey: String); async;
    [async]
    procedure DeleteAlert(AlertPKey: String); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
    procedure UpdateUserInterface();
  public
    { Public declarations }
    PKey: Integer;
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  AssetTypeEditForm: TAssetTypeEditForm;

implementation

{$R *.dfm}

uses uCommon, uAssetTypePicture, uAlertList;

procedure TAssetTypeEditForm.SetPermission(MyPermission: Integer);
begin
  SaveButton.Visible := MyPermission in [2,3,6,7];
end;

procedure TAssetTypeEditForm.AddAlertButtonClick(Sender: TObject);
var
  MyPermission: Integer;
  MyWebForm: TAlertListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'alerts');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TAlertListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TAlertListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Allow Select
      MyWebForm.SelectButton.Visible := True;
//    MyWebForm.MyGrid.Options.Selection.Mode := smDisjunctRow;
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        // Add Alerts
        AddAlert(MyWebForm.PKey);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TAssetTypeEditForm.AlertDatasetAfterOpen(DataSet: TDataSet);
begin
  AlertDBAdapter.Active := True;
  AlertDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TAssetTypeEditForm.CheckBoxClick(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  UpdateUserInterface();
end;

procedure TAssetTypeEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  Result: Boolean;
  ErrorMessage: String;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', TMsgDlgType.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      Result := await(ValidInput(ErrorMessage));
      if Result then begin
        Result := await(SaveData(ErrorMessage));
        if Result then begin
          ModalResult := mrOK;
        end else begin
          MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
        end;
      end else begin
        MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetTypeEditForm.DeleteAlertButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  StringList: TStringList;
  I: Integer;
begin
  if AlertGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to AlertGrid.RowSelectionCount-1 do begin
        StringList.Add(AlertGrid.Cells[0, AlertGrid.SelectedRow[I]]);
      end;
      if StringList.Count > 0 then begin
        MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selection?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
        if MyModalResult = mrYes then begin
          DeleteAlert(StringList.DelimitedText);
        end;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TAssetTypeEditForm.WebFormCreate(Sender: TObject);
begin
  SetGridStyle(AlertGrid, False);
end;

procedure TAssetTypeEditForm.WebFormShow(Sender: TObject);
begin
//WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TAssetTypeEditForm.UpdateUserInterface();
begin
  TimeFromBEdit.Enabled := AssetEdit.Checked;
  TimeTillBEdit.Enabled := AssetEdit.Checked;
  if not(AssetEdit.Checked) then begin
    TimeFromBEdit.Checked := False;
    TimeTillBEdit.Checked := False;
  end;
  TimeFromEdit.Enabled := TimeFromBEdit.Checked;
  TimeTillEdit.Enabled := TimeTillBEdit.Checked;
  if not(TimeFromBEdit.Checked) then TimeFromEdit.Text := '00:00:00';
  if not(TimeTillBEdit.Checked) then TimeTillEdit.Text := '23:59:59';
end;

procedure TAssetTypeEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
//    ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TAssetTypeEditForm.ShowTimerTimer(Sender: TObject);
begin
  ShowTimer.Enabled := False;
  // Load Data
  if PKey >= 0 then begin
    await(LoadData());
  end;
  UpdateUserInterface();
  WebWaitMessage.Hide;
  // Set Focus
  NameNLEdit.SetFocus;
end;

procedure TAssetTypeEditForm.PageControlBeforeChangePage(Sender: TObject;
  ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
begin
  if not(PKey > 0) then begin
    ACanChange := False;
    MessageDlg('Save asset type before linking alerts.', TMsgDlgType.mtError, [mbOK]);
  end;

end;

procedure TAssetTypeEditForm.PageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  // Alerts
  if ACurrentPageIndex = 1 then begin
    LoadAlertList();
  end;
end;

procedure TAssetTypeEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_type_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      NameNLEdit.Text := MyJSONObject.GetJSONValue('id_nl');
      NameFREdit.Text := MyJSONObject.GetJSONValue('id_fr');
      NameENEdit.Text := MyJSONObject.GetJSONValue('id_en');
      // Picture
      ImageEdit.Tag := StrToInt(MyJSONObject.GetJSONValue('picture_pkey'));
      ImageBase64 := MyJSONObject.GetJSONValue('picture_blob');
      if ImageBase64 <> EmptyStr then begin
        ImageEdit.Bitmap.LoadFromURL(ImageBase64);
      end;
      // Calculation Method
      if MyJSONObject.GetJSONValue('track_by_engine') = '1' then begin
        IgnitionOnEdit.Checked := True;
      end else begin
        IgnitionOffEdit.Checked := True;
      end;
      // KPI
      AssetEdit.Checked := MyJSONObject.GetJSONValue('kpi') = 't';
      TimeFromBEdit.Checked := MyJSONObject.GetJSONValue('use_time_from') = 't';
      TimeFromEdit.Text := MyJSONObject.GetJSONValue('time_from');
      TimeTillBEdit.Checked := MyJSONObject.GetJSONValue('use_time_till') = 't';
      TimeTillEdit.Text := MyJSONObject.GetJSONValue('time_till');
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetTypeEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

function TAssetTypeEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Name
  if Result then begin
    if Trim(NameNLEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'DUTCH is a required field.';
    end;
  end;
  // Picture
  if Result then begin
    if ImageEdit.Tag <= 0 then begin
      Result := False;
      ErrorMessage := 'PICTURE is a required field.';
    end;
  end;
  // Time From
  if Result then begin
    if not(TRegEx.IsMatch(Trim(TimeFromEdit.Text), RegExTime)) then begin
      Result := False;
      ErrorMessage := 'TIME FROM containts an invalid value.';
    end;
  end;
  // Time Till
  if Result then begin
//  if not(TryStrToTime(TimeTillEdit.Text, MyTime)) then begin
    if not(TRegEx.IsMatch(Trim(TimeTillEdit.Text), RegExTime)) then begin
      Result := False;
      ErrorMessage := 'TIME TILL containts an invalid value.';
    end;
  end;
end;

function TAssetTypeEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id_nl": "' + Trim(NameNLEdit.Text) + '",' +
      '"id_fr": "' + Trim(NameFREdit.Text) + '",' +
      '"id_en": "' + Trim(NameENEdit.Text) + '",' +
      '"track_by_engine": "' + BooleanToString(IgnitionOnEdit.Checked) + '",' +
      '"kpi": "' + BooleanToString(AssetEdit.Checked) + '",' +
      '"use_time_from": "' + BooleanToString(TimeFromBEdit.Checked) + '",' +
      '"time_from": "' + Trim(TimeFromEdit.Text) + '",' +
      '"use_time_till": "' + BooleanToString(TimeTillBEdit.Checked) + '",' +
      '"time_till": "' + Trim(TimeTillEdit.Text) + '",' +
      '"picture_pkey": "' + IntToStr(ImageEdit.Tag) + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_type_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        Result := True;
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetTypeEditForm.SelectButtonClick(Sender: TObject);
var
  MyForm: TAssetTypePictureForm;
  MyModalResult: TModalResult;
begin
  MyForm := TAssetTypePictureForm.Create(Self);
  MyForm.Popup := True;
  MyForm.Border := fbNone;
  await(TAssetTypePictureForm, MyForm.Load());
  try
    MyModalResult := await(TModalResult, MyForm.Execute);
    if MyModalResult = mrOK then begin
      ImageEdit.Tag := MyForm.PKey;
      ImageEdit.Bitmap.LoadFromURL(MyForm.Base64);
      RecordChange(TObject(Sender));
    end;
  finally
    MyForm.Free;
  end;
end;

procedure TAssetTypeEditForm.LoadAlertList;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_type_alert_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  AlertDataset.Close;
  AlertDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(AlertDataset.Open);
end;

procedure TAssetTypeEditForm.AddAlert(AlertPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_type_alert_add.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + AlertPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAlertList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetTypeEditForm.DeleteAlert(AlertPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_type_alert_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + AlertPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAlertList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;


procedure TAssetTypeEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  PageControl := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  AddAlertButton := TSpeedButton.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  DeleteAlertButton := TSpeedButton.Create(Self);
  AlertGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  ModelNameLabel := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  NameNLEdit := TEdit.Create(Self);
  ImageEdit := TTMSFNCImage.Create(Self);
  NameFREdit := TEdit.Create(Self);
  NameENEdit := TEdit.Create(Self);
  IgnitionOnEdit := TRadioButton.Create(Self);
  IgnitionOffEdit := TRadioButton.Create(Self);
  AssetEdit := TCheckBox.Create(Self);
  TimeFromBEdit := TCheckBox.Create(Self);
  TimeTillBEdit := TCheckBox.Create(Self);
  TimeFromEdit := TEdit.Create(Self);
  TimeTillEdit := TEdit.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  AlertDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ShowTimer := TTimer.Create(Self);
  AlertDataset := TClientDataSet.Create(Self);
  AlertDatasetPKey := TStringField.Create(Self);
  AlertDatasetName := TStringField.Create(Self);
  AlertDataSource := TDataSource.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  PageControl.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  AddAlertButton.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  DeleteAlertButton.BeforeLoadDFMValues;
  AlertGrid.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  ModelNameLabel.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  NameNLEdit.BeforeLoadDFMValues;
  ImageEdit.BeforeLoadDFMValues;
  NameFREdit.BeforeLoadDFMValues;
  NameENEdit.BeforeLoadDFMValues;
  IgnitionOnEdit.BeforeLoadDFMValues;
  IgnitionOffEdit.BeforeLoadDFMValues;
  AssetEdit.BeforeLoadDFMValues;
  TimeFromBEdit.BeforeLoadDFMValues;
  TimeTillBEdit.BeforeLoadDFMValues;
  TimeFromEdit.BeforeLoadDFMValues;
  TimeTillEdit.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  AlertDBAdapter.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  AlertDataset.BeforeLoadDFMValues;
  AlertDatasetPKey.BeforeLoadDFMValues;
  AlertDatasetName.BeforeLoadDFMValues;
  AlertDataSource.BeforeLoadDFMValues;
  try
    Name := 'AssetTypeEditForm';
    Width := 469;
    Height := 727;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 469;
    FormPanel.Height := 727;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 463;
    ContentPanel.Height := 684;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 635;
    StatusBarPanel.Width := 463;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 343;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetTypeEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 228;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetTypeEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    PageControl.SetParentComponent(ContentPanel);
    PageControl.Name := 'PageControl';
    PageControl.AlignWithMargins := True;
    PageControl.Left := 10;
    PageControl.Top := 0;
    PageControl.Width := 443;
    PageControl.Height := 627;
    PageControl.Margins.Left := 10;
    PageControl.Margins.Top := 0;
    PageControl.Margins.Right := 10;
    PageControl.Margins.Bottom := 8;
    PageControl.Align := alClient;
    PageControl.BevelEdges := [];
    PageControl.BevelInner := bvNone;
    PageControl.BevelOuter := bvNone;
    PageControl.ParentDoubleBuffered := False;
    PageControl.Color := clBtnFace;
    PageControl.DoubleBuffered := True;
    PageControl.Font.Charset := DEFAULT_CHARSET;
    PageControl.Font.Color := clWindowText;
    PageControl.Font.Height := -16;
    PageControl.Font.Name := 'Tahoma';
    PageControl.Font.Style := [];
    PageControl.ParentFont := False;
    PageControl.TabOrder := 1;
    PageControl.Fill.Kind := gfkNone;
    PageControl.Fill.ColorTo := clWhite;
    PageControl.Stroke.Kind := gskNone;
    PageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    PageControl.TabAppearance.Font.Color := clAqua;
    PageControl.TabAppearance.Font.Height := -16;
    PageControl.TabAppearance.Font.Name := 'Segoe UI';
    PageControl.TabAppearance.Font.Style := [];
    PageControl.TabAppearance.Fill.Color := 14408667;
    PageControl.TabAppearance.Stroke.Color := -1;
    PageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    PageControl.TabAppearance.HoverFill.Color := 15658734;
    PageControl.TabAppearance.HoverStroke.Color := -1;
    PageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    PageControl.TabAppearance.Shape := tsRectangle;
    PageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    PageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    PageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    PageControl.TabAppearance.TextColor := clGrayText;
    PageControl.TabAppearance.ActiveTextColor := clGrayText;
    PageControl.TabAppearance.HoverTextColor := clGrayText;
    PageControl.TabAppearance.ShowFocus := False;
    PageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    PageControl.TabAppearance.BadgeFont.Color := 139;
    PageControl.TabAppearance.BadgeFont.Height := -11;
    PageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    PageControl.TabAppearance.BadgeFont.Style := [fsBold];
    PageControl.ButtonAppearance.CloseIcon.LoadFromFile('uAssetTypeEdit.ContentPanel.PageControl.ButtonAppearance.CloseIcon.svg');
    PageControl.ButtonAppearance.InsertIcon.LoadFromFile('uAssetTypeEdit.ContentPanel.PageControl.ButtonAppearance.InsertIcon.svg');
    PageControl.ButtonAppearance.TabListIcon.LoadFromFile('uAssetTypeEdit.ContentPanel.PageControl.ButtonAppearance.TabListIcon.svg');
    PageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uAssetTypeEdit.ContentPanel.PageControl.ButtonAppearance.ScrollNextIcon.svg');
    PageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uAssetTypeEdit.ContentPanel.PageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    PageControl.TabSize.Height := 45.000000000000000000;
    PageControl.TabSize.Margins.Left := 8.000000000000000000;
    PageControl.TabSize.Margins.Top := 8.000000000000000000;
    PageControl.TabSize.Margins.Right := 8.000000000000000000;
    PageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    PageControl.Pages.Clear;
    with PageControl.Pages.Add do
    begin
      Text := 'GENERAL';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'ALERTS';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'BMW';
      TabVisible := False;
      Visible := False;
      UseDefaultAppearance := True;
    end;
    SetEvent(PageControl, Self, 'OnBeforeChangePage', 'PageControlBeforeChangePage');
    SetEvent(PageControl, Self, 'OnChangePage', 'PageControlChangePage');
    TMSFNCPageControl1Page2.SetParentComponent(PageControl);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 443;
    TMSFNCPageControl1Page2.Height := 575;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    TMSFNCPageControl1Page1.SetParentComponent(PageControl);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 443;
    TMSFNCPageControl1Page1.Height := 575;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    TMSFNCPanel1.SetParentComponent(TMSFNCPageControl1Page1);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 519;
    TMSFNCPanel1.Width := 437;
    TMSFNCPanel1.Height := 53;
    TMSFNCPanel1.Align := alBottom;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    AddAlertButton.SetParentComponent(TMSFNCPanel1);
    AddAlertButton.Name := 'AddAlertButton';
    AddAlertButton.AlignWithMargins := True;
    AddAlertButton.Left := 20;
    AddAlertButton.Top := 8;
    AddAlertButton.Width := 100;
    AddAlertButton.Height := 35;
    AddAlertButton.Margins.Left := 0;
    AddAlertButton.Margins.Top := 8;
    AddAlertButton.Margins.Right := 8;
    AddAlertButton.Margins.Bottom := 10;
    AddAlertButton.Align := alLeft;
    AddAlertButton.Caption := 'Add';
    AddAlertButton.Color := clNone;
    AddAlertButton.ElementClassName := 'MyWebButton';
    AddAlertButton.Glyph.LoadFromFile('uAssetTypeEdit.TMSFNCPanel1.AddAlertButton.Glyph.png');
    AddAlertButton.HeightPercent := 100.000000000000000000;
    AddAlertButton.TabOrder := 0;
    AddAlertButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddAlertButton, Self, 'OnClick', 'AddAlertButtonClick');
    TMSFNCPanel2.SetParentComponent(TMSFNCPanel1);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 2;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 3;
    TMSFNCPanel2.Width := 14;
    TMSFNCPanel2.Height := 45;
    TMSFNCPanel2.Margins.Bottom := 5;
    TMSFNCPanel2.Align := alLeft;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 1;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
    DeleteAlertButton.SetParentComponent(TMSFNCPanel1);
    DeleteAlertButton.Name := 'DeleteAlertButton';
    DeleteAlertButton.AlignWithMargins := True;
    DeleteAlertButton.Left := 128;
    DeleteAlertButton.Top := 8;
    DeleteAlertButton.Width := 100;
    DeleteAlertButton.Height := 35;
    DeleteAlertButton.Margins.Left := 0;
    DeleteAlertButton.Margins.Top := 8;
    DeleteAlertButton.Margins.Right := 8;
    DeleteAlertButton.Margins.Bottom := 10;
    DeleteAlertButton.Align := alLeft;
    DeleteAlertButton.Caption := 'Delete';
    DeleteAlertButton.Color := clNone;
    DeleteAlertButton.ElementClassName := 'MyWebButton';
    DeleteAlertButton.Glyph.LoadFromFile('uAssetTypeEdit.TMSFNCPanel1.DeleteAlertButton.Glyph.png');
    DeleteAlertButton.HeightPercent := 100.000000000000000000;
    DeleteAlertButton.TabOrder := 2;
    DeleteAlertButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteAlertButton, Self, 'OnClick', 'DeleteAlertButtonClick');
    AlertGrid.SetParentComponent(TMSFNCPageControl1Page1);
    AlertGrid.Name := 'AlertGrid';
    AlertGrid.AlignWithMargins := True;
    AlertGrid.Left := 20;
    AlertGrid.Top := 20;
    AlertGrid.Width := 403;
    AlertGrid.Height := 486;
    AlertGrid.Margins.Left := 20;
    AlertGrid.Margins.Top := 20;
    AlertGrid.Margins.Right := 20;
    AlertGrid.Margins.Bottom := 10;
    AlertGrid.AdaptToStyle := True;
    AlertGrid.Align := alClient;
    AlertGrid.BevelInner := bvNone;
    AlertGrid.BevelOuter := bvNone;
    AlertGrid.BevelWidth := 2;
    AlertGrid.Ctl3D := False;
    AlertGrid.ParentCtl3D := False;
    AlertGrid.ParentDoubleBuffered := False;
    AlertGrid.DoubleBuffered := True;
    AlertGrid.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Font.Color := clWindowText;
    AlertGrid.Font.Height := -11;
    AlertGrid.Font.Name := 'Tahoma';
    AlertGrid.Font.Style := [];
    AlertGrid.ParentFont := False;
    AlertGrid.TabOrder := 1;
    AlertGrid.DefaultRowHeight := 34.000000000000000000;
    AlertGrid.FixedColumns := 0;
    AlertGrid.ColumnCount := 4;
    AlertGrid.RowCount := 1;
    AlertGrid.Options.Borders.FixedCellBorders := bNone;
    AlertGrid.Options.ColumnSize.Stretch := True;
    AlertGrid.Options.ColumnSize.StretchColumn := 1;
    AlertGrid.Options.Editing.CalcFormat := '%g';
    AlertGrid.Options.Grouping.CalcFormat := '%g';
    AlertGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AlertGrid.Options.HTMLExport.CellPadding := 10;
    AlertGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AlertGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AlertGrid.Options.Mouse.ClickMargin := 0;
    AlertGrid.Options.Mouse.ColumnSizeMargin := 6;
    AlertGrid.Options.Mouse.RowSizeMargin := 6;
    AlertGrid.Options.URL.Color := clSkyBlue;
    AlertGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AlertGrid.Options.Selection.Mode := smDisjunctRow;
    AlertGrid.Adapter := AlertDBAdapter;
    AlertGrid.HorizontalScrollBarVisible := False;
    AlertGrid.Columns.Clear;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 266.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    AlertGrid.Fill.ColorTo := clSilver;
    AlertGrid.DefaultFont.Charset := ANSI_CHARSET;
    AlertGrid.DefaultFont.Color := clWindowText;
    AlertGrid.DefaultFont.Height := -15;
    AlertGrid.DefaultFont.Name := 'Segoe UI';
    AlertGrid.DefaultFont.Style := [];
    AlertGrid.Stroke.Color := 15987699;
    AlertGrid.Stroke.Width := 2.000000000000000000;
    AlertGrid.TopRow := 1;
    AlertGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AlertGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AlertGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.FixedLayout.Font.Height := -16;
    AlertGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FixedLayout.Font.Style := [];
    AlertGrid.Appearance.NormalLayout.Fill.Color := -1;
    AlertGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AlertGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.NormalLayout.Font.Color := clGray;
    AlertGrid.Appearance.NormalLayout.Font.Height := -11;
    AlertGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.NormalLayout.Font.Style := [];
    AlertGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AlertGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AlertGrid.Appearance.GroupLayout.Font.Height := -11;
    AlertGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AlertGrid.Appearance.GroupLayout.Font.Style := [];
    AlertGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AlertGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AlertGrid.Appearance.SummaryLayout.Font.Height := -11;
    AlertGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AlertGrid.Appearance.SummaryLayout.Font.Style := [];
    AlertGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AlertGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.SelectedLayout.Font.Height := -16;
    AlertGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.SelectedLayout.Font.Style := [];
    AlertGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.FocusedLayout.Font.Height := -16;
    AlertGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FocusedLayout.Font.Style := [];
    AlertGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AlertGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AlertGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AlertGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.BandLayout.Font.Color := clBlack;
    AlertGrid.Appearance.BandLayout.Font.Height := -16;
    AlertGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AlertGrid.Appearance.BandLayout.Font.Style := [];
    AlertGrid.Appearance.ProgressLayout.Color := 15385233;
    AlertGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AlertGrid.LeftCol := 1;
    AlertGrid.ScrollMode := scmItemScrolling;
    AlertGrid.GlobalFont.Scale := 1.000000000000000000;
    AlertGrid.GlobalFont.Style := [];
    AlertGrid.DesignTimeSampleData := True;
    TMSFNCPageControl1Page0.SetParentComponent(PageControl);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 443;
    TMSFNCPageControl1Page0.Height := 575;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    ModelNameLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ModelNameLabel.Name := 'ModelNameLabel';
    ModelNameLabel.Left := 21;
    ModelNameLabel.Top := 21;
    ModelNameLabel.Width := 50;
    ModelNameLabel.Height := 21;
    ModelNameLabel.Caption := 'DUTCH';
    ModelNameLabel.Font.Charset := ANSI_CHARSET;
    ModelNameLabel.Font.Color := 9010544;
    ModelNameLabel.Font.Height := -16;
    ModelNameLabel.Font.Name := 'Segoe UI';
    ModelNameLabel.Font.Style := [];
    ModelNameLabel.HeightPercent := 100.000000000000000000;
    ModelNameLabel.ParentFont := False;
    ModelNameLabel.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 21;
    WebLabel16.Top := 266;
    WebLabel16.Width := 60;
    WebLabel16.Height := 21;
    WebLabel16.Caption := 'PICTURE';
    WebLabel16.Font.Charset := ANSI_CHARSET;
    WebLabel16.Font.Color := 9010544;
    WebLabel16.Font.Height := -16;
    WebLabel16.Font.Name := 'Segoe UI';
    WebLabel16.Font.Style := [];
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 20;
    WebLabel2.Top := 101;
    WebLabel2.Width := 59;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'FRENCH';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 21;
    WebLabel3.Top := 181;
    WebLabel3.Width := 63;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'ENGLISH';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 21;
    WebLabel4.Top := 351;
    WebLabel4.Width := 180;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Route Calculation Method';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 323;
    WebLabel1.Top := 453;
    WebLabel1.Width := 74;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'HH:MM:SS';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    NameNLEdit.SetParentComponent(TMSFNCPageControl1Page0);
    NameNLEdit.Name := 'NameNLEdit';
    NameNLEdit.Left := 21;
    NameNLEdit.Top := 48;
    NameNLEdit.Width := 397;
    NameNLEdit.Height := 34;
    NameNLEdit.ChildOrder := 2;
    NameNLEdit.ElementClassName := 'myselect_white';
    NameNLEdit.Font.Charset := ANSI_CHARSET;
    NameNLEdit.Font.Color := clWindowText;
    NameNLEdit.Font.Height := -16;
    NameNLEdit.Font.Name := 'Segoe UI';
    NameNLEdit.Font.Style := [];
    NameNLEdit.HeightPercent := 100.000000000000000000;
    NameNLEdit.ParentFont := False;
    NameNLEdit.TabOrder := 1001;
    NameNLEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameNLEdit, Self, 'OnChange', 'RecordChange');
    ImageEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ImageEdit.Name := 'ImageEdit';
    ImageEdit.Left := 113;
    ImageEdit.Top := 262;
    ImageEdit.Width := 32;
    ImageEdit.Height := 32;
    ImageEdit.ParentDoubleBuffered := False;
    ImageEdit.Color := clBtnFace;
    ImageEdit.DoubleBuffered := True;
    ImageEdit.TabOrder := 1;
    ImageEdit.Fill.Kind := gfkNone;
    ImageEdit.Stroke.Kind := gskNone;
    NameFREdit.SetParentComponent(TMSFNCPageControl1Page0);
    NameFREdit.Name := 'NameFREdit';
    NameFREdit.Left := 20;
    NameFREdit.Top := 128;
    NameFREdit.Width := 397;
    NameFREdit.Height := 34;
    NameFREdit.ChildOrder := 2;
    NameFREdit.ElementClassName := 'myselect_white';
    NameFREdit.Font.Charset := ANSI_CHARSET;
    NameFREdit.Font.Color := clWindowText;
    NameFREdit.Font.Height := -16;
    NameFREdit.Font.Name := 'Segoe UI';
    NameFREdit.Font.Style := [];
    NameFREdit.HeightPercent := 100.000000000000000000;
    NameFREdit.ParentFont := False;
    NameFREdit.TabOrder := 1001;
    NameFREdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameFREdit, Self, 'OnChange', 'RecordChange');
    NameENEdit.SetParentComponent(TMSFNCPageControl1Page0);
    NameENEdit.Name := 'NameENEdit';
    NameENEdit.Left := 21;
    NameENEdit.Top := 208;
    NameENEdit.Width := 397;
    NameENEdit.Height := 34;
    NameENEdit.ChildOrder := 2;
    NameENEdit.ElementClassName := 'myselect_white';
    NameENEdit.Font.Charset := ANSI_CHARSET;
    NameENEdit.Font.Color := clWindowText;
    NameENEdit.Font.Height := -16;
    NameENEdit.Font.Name := 'Segoe UI';
    NameENEdit.Font.Style := [];
    NameENEdit.HeightPercent := 100.000000000000000000;
    NameENEdit.ParentFont := False;
    NameENEdit.TabOrder := 1001;
    NameENEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameENEdit, Self, 'OnChange', 'RecordChange');
    IgnitionOnEdit.SetParentComponent(TMSFNCPageControl1Page0);
    IgnitionOnEdit.Name := 'IgnitionOnEdit';
    IgnitionOnEdit.Left := 21;
    IgnitionOnEdit.Top := 378;
    IgnitionOnEdit.Width := 124;
    IgnitionOnEdit.Height := 22;
    IgnitionOnEdit.Caption := 'Ignition';
    IgnitionOnEdit.Checked := True;
    IgnitionOnEdit.ChildOrder := 19;
    IgnitionOnEdit.Color := clNone;
    IgnitionOnEdit.GroupName := 'Ignition';
    IgnitionOnEdit.HeightPercent := 100.000000000000000000;
    IgnitionOnEdit.WidthPercent := 100.000000000000000000;
    SetEvent(IgnitionOnEdit, Self, 'OnClick', 'RecordChange');
    IgnitionOffEdit.SetParentComponent(TMSFNCPageControl1Page0);
    IgnitionOffEdit.Name := 'IgnitionOffEdit';
    IgnitionOffEdit.Left := 21;
    IgnitionOffEdit.Top := 402;
    IgnitionOffEdit.Width := 124;
    IgnitionOffEdit.Height := 22;
    IgnitionOffEdit.Caption := 'Movement';
    IgnitionOffEdit.Checked := False;
    IgnitionOffEdit.ChildOrder := 19;
    IgnitionOffEdit.Color := clNone;
    IgnitionOffEdit.GroupName := 'Ignition';
    IgnitionOffEdit.HeightPercent := 100.000000000000000000;
    IgnitionOffEdit.WidthPercent := 100.000000000000000000;
    SetEvent(IgnitionOffEdit, Self, 'OnClick', 'RecordChange');
    AssetEdit.SetParentComponent(TMSFNCPageControl1Page0);
    AssetEdit.Name := 'AssetEdit';
    AssetEdit.AlignWithMargins := True;
    AssetEdit.Left := 21;
    AssetEdit.Top := 437;
    AssetEdit.Width := 164;
    AssetEdit.Height := 35;
    AssetEdit.Margins.Left := 40;
    AssetEdit.Margins.Top := 10;
    AssetEdit.Margins.Right := 40;
    AssetEdit.Caption := 'Availability (for KPI)';
    AssetEdit.ChildOrder := 2;
    AssetEdit.Color := clWhite;
    AssetEdit.Font.Charset := ANSI_CHARSET;
    AssetEdit.Font.Color := 9010544;
    AssetEdit.Font.Height := -16;
    AssetEdit.Font.Name := 'Segoe UI';
    AssetEdit.Font.Style := [];
    AssetEdit.HeightPercent := 100.000000000000000000;
    AssetEdit.ParentFont := False;
    AssetEdit.TabOrder := 2;
    AssetEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetEdit, Self, 'OnClick', 'CheckBoxClick');
    TimeFromBEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeFromBEdit.Name := 'TimeFromBEdit';
    TimeFromBEdit.AlignWithMargins := True;
    TimeFromBEdit.Left := 37;
    TimeFromBEdit.Top := 477;
    TimeFromBEdit.Width := 108;
    TimeFromBEdit.Height := 35;
    TimeFromBEdit.Margins.Left := 40;
    TimeFromBEdit.Margins.Top := 10;
    TimeFromBEdit.Margins.Right := 40;
    TimeFromBEdit.Caption := 'Time From';
    TimeFromBEdit.ChildOrder := 2;
    TimeFromBEdit.Color := clWhite;
    TimeFromBEdit.Font.Charset := ANSI_CHARSET;
    TimeFromBEdit.Font.Color := 9010544;
    TimeFromBEdit.Font.Height := -16;
    TimeFromBEdit.Font.Name := 'Segoe UI';
    TimeFromBEdit.Font.Style := [];
    TimeFromBEdit.HeightPercent := 100.000000000000000000;
    TimeFromBEdit.ParentFont := False;
    TimeFromBEdit.TabOrder := 2;
    TimeFromBEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeFromBEdit, Self, 'OnClick', 'CheckBoxClick');
    TimeTillBEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeTillBEdit.Name := 'TimeTillBEdit';
    TimeTillBEdit.AlignWithMargins := True;
    TimeTillBEdit.Left := 37;
    TimeTillBEdit.Top := 519;
    TimeTillBEdit.Width := 108;
    TimeTillBEdit.Height := 35;
    TimeTillBEdit.Margins.Left := 40;
    TimeTillBEdit.Margins.Top := 10;
    TimeTillBEdit.Margins.Right := 40;
    TimeTillBEdit.Caption := 'Time Till';
    TimeTillBEdit.ChildOrder := 2;
    TimeTillBEdit.Color := clWhite;
    TimeTillBEdit.Font.Charset := ANSI_CHARSET;
    TimeTillBEdit.Font.Color := 9010544;
    TimeTillBEdit.Font.Height := -16;
    TimeTillBEdit.Font.Name := 'Segoe UI';
    TimeTillBEdit.Font.Style := [];
    TimeTillBEdit.HeightPercent := 100.000000000000000000;
    TimeTillBEdit.ParentFont := False;
    TimeTillBEdit.TabOrder := 2;
    TimeTillBEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeTillBEdit, Self, 'OnClick', 'CheckBoxClick');
    TimeFromEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeFromEdit.Name := 'TimeFromEdit';
    TimeFromEdit.Left := 146;
    TimeFromEdit.Top := 480;
    TimeFromEdit.Width := 251;
    TimeFromEdit.Height := 34;
    TimeFromEdit.ChildOrder := 1;
    TimeFromEdit.ElementClassName := 'MyWebEdit';
    TimeFromEdit.Font.Charset := ANSI_CHARSET;
    TimeFromEdit.Font.Color := clWindowText;
    TimeFromEdit.Font.Height := -16;
    TimeFromEdit.Font.Name := 'Segoe UI';
    TimeFromEdit.Font.Style := [];
    TimeFromEdit.HeightPercent := 100.000000000000000000;
    TimeFromEdit.ParentFont := False;
    TimeFromEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeFromEdit, Self, 'OnChange', 'RecordChange');
    TimeTillEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeTillEdit.Name := 'TimeTillEdit';
    TimeTillEdit.Left := 146;
    TimeTillEdit.Top := 522;
    TimeTillEdit.Width := 251;
    TimeTillEdit.Height := 34;
    TimeTillEdit.ChildOrder := 1;
    TimeTillEdit.ElementClassName := 'MyWebEdit';
    TimeTillEdit.Font.Charset := ANSI_CHARSET;
    TimeTillEdit.Font.Color := clWindowText;
    TimeTillEdit.Font.Height := -16;
    TimeTillEdit.Font.Name := 'Segoe UI';
    TimeTillEdit.Font.Style := [];
    TimeTillEdit.HeightPercent := 100.000000000000000000;
    TimeTillEdit.ParentFont := False;
    TimeTillEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeTillEdit, Self, 'OnChange', 'RecordChange');
    SelectButton.SetParentComponent(TMSFNCPageControl1Page0);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 176;
    SelectButton.Top := 263;
    SelectButton.Width := 135;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uAssetTypeEdit.TMSFNCPageControl1Page0.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 11;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 469;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 121;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Type - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 440;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetTypeEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 83;
    WebWaitMessage.Top := 170;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAssetTypeEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 174;
    MyMessageDlg.Top := 170;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    AlertDBAdapter.SetParentComponent(Self);
    AlertDBAdapter.Name := 'AlertDBAdapter';
    AlertDBAdapter.Left := 83;
    AlertDBAdapter.Top := 235;
    AlertDBAdapter.Width := 26;
    AlertDBAdapter.Height := 26;
    AlertDBAdapter.Margins.Left := 12;
    AlertDBAdapter.Visible := True;
    AlertDBAdapter.Grid := AlertGrid;
    AlertDBAdapter.DataSource := AlertDataSource;
    AlertDBAdapter.Columns.Clear;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'Name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AlertDBAdapter.AutoCreateColumns := False;
    AlertDBAdapter.AutoRemoveColumns := False;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 281;
    ShowTimer.Top := 170;
    AlertDataset.SetParentComponent(Self);
    AlertDataset.Name := 'AlertDataset';
    AlertDataset.AfterOpen := AlertDatasetAfterOpen;
    AlertDataset.Left := 173;
    AlertDataset.Top := 234;
    AlertDatasetPKey.SetParentComponent(AlertDataset);
    AlertDatasetPKey.Name := 'AlertDatasetPKey';
    AlertDatasetPKey.FieldName := 'PKey';
    AlertDatasetPKey.Size := 256;
    AlertDatasetName.SetParentComponent(AlertDataset);
    AlertDatasetName.Name := 'AlertDatasetName';
    AlertDatasetName.FieldName := 'Name';
    AlertDatasetName.Size := 256;
    AlertDataSource.SetParentComponent(Self);
    AlertDataSource.Name := 'AlertDataSource';
    AlertDataSource.DataSet := AlertDataset;
    AlertDataSource.Left := 281;
    AlertDataSource.Top := 234;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    PageControl.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    AddAlertButton.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    DeleteAlertButton.AfterLoadDFMValues;
    AlertGrid.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    ModelNameLabel.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    NameNLEdit.AfterLoadDFMValues;
    ImageEdit.AfterLoadDFMValues;
    NameFREdit.AfterLoadDFMValues;
    NameENEdit.AfterLoadDFMValues;
    IgnitionOnEdit.AfterLoadDFMValues;
    IgnitionOffEdit.AfterLoadDFMValues;
    AssetEdit.AfterLoadDFMValues;
    TimeFromBEdit.AfterLoadDFMValues;
    TimeTillBEdit.AfterLoadDFMValues;
    TimeFromEdit.AfterLoadDFMValues;
    TimeTillEdit.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    AlertDBAdapter.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    AlertDataset.AfterLoadDFMValues;
    AlertDatasetPKey.AfterLoadDFMValues;
    AlertDatasetName.AfterLoadDFMValues;
    AlertDataSource.AfterLoadDFMValues;
  end;
end;

end.

unit uLogin;

interface

uses
  System.SysUtils, System.Classes, JS, Web,
  Vcl.Controls, Vcl.StdCtrls, Vcl.Imaging.pngimage,
  WEBLib.Graphics, WEBLib.Controls, WEBLib.REST, WEBLib.JSON,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Login, WEBLib.ExtCtrls,
  WEBLib.StdCtrls, WEBLib.Storage;

type
  TLoginForm = class(TForm)
    BackgroundPanel: TPanel;
    ContentPanel: TPanel;
    HeaderPanel: TPanel;
    CaptionLabel: TLabel;
    CloseFormImage: TImageControl;
    UsernameLabel: TLabel;
    UsernameEdit: TEdit;
    PasswordLabel: TLabel;
    PasswordEdit: TEdit;
    LoginButton: TButton;
    WebHttpRequest: THttpRequest;
    WebLocalStorage: TLocalStorage;
    RememberMeCheckBox: TCheckBox;
    procedure CloseFormImageClick(Sender: TObject);
    [async]
    procedure LoginButtonClick(Sender: TObject); async;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure WebFormKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState); async;
  private
    { Private declarations }
  public
    { Public declarations }
    [async]
    procedure LogonUser(); async;
  protected procedure LoadDFMValues; override; end;

var
  LoginForm: TLoginForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
  UserNameEdit.Text := WebLocalStorage.Values['username'];
  PasswordEdit.Text := WebLocalStorage.Values['password'];
  RememberMeCheckBox.Checked := not(UsernameEdit.Text.IsEmpty);
end;

procedure TLoginForm.WebFormKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = VK_RETURN then begin
    LogonUser;
  end;
end;

procedure TLoginForm.WebFormShow(Sender: TObject);
begin
  UsernameEdit.SetFocus;
end;

procedure TLoginForm.CloseFormImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TLoginForm.LoginButtonClick(Sender: TObject);
begin
  await(LogonUser());
end;

procedure TLoginForm.LogonUser;
var
  UserName: String;
  Password: String;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  // Trim Input
  Username := Trim(UserNameEdit.Text);
  Password := Trim(PasswordEdit.Text);
  // Validate Input
  if (UserName.IsEmpty) or (Password.IsEmpty) then begin
    // Invalid Input
    if UserName.IsEmpty then begin
      UserNameEdit.SetFocus;
    end else begin
      PasswordEdit.SetFocus;
    end;
    ShowMessage('Username and password are required fields!');
  end else begin
    // Valid Input
    WebHTTPRequest.URL :=
      url_php + 'userlogin_v3.php?' +
      'username=' + UserName +
      '&password=' + Password;
    MyRequest := await(TJSXMLHttpRequest, WebHttpRequest.Perform());
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        SessionID := MyJSONObject.GetJSONValue('session_id');
        SessionType := MyJSONObject.GetJSONValue('session_type');
        Permissions := TJSONPair(MyJSONObject.Get('permissions')).JsonValue.ToString;
        await(UpdateGeneralSettings(TJSONPair(MyJSONObject.Get('general_settings')).JsonValue.ToString));
        await(UpdatePersonalSettings(TJSONPair(MyJSONObject.Get('personal_settings')).JsonValue.ToString));
        if RememberMeCheckBox.Checked then begin
           WebLocalStorage.Values['username'] := Username;
           WebLocalStorage.Values['password'] := Password;
        end else begin
           WebLocalStorage.Values['username'] := EmptyStr;
           WebLocalStorage.Values['password'] := EmptyStr;
        end;
        Application.RunScript('document.title = "Instatrack - ' + UserName + '";');
        ModalResult := mrOK;
      end else begin
        MessageDlg(MyJSONObject.GetJSONValue('error_message'), mtError, [mbOK], nil);
      end;
    finally
      MyJSON.Free;
    end
  end;
end;

procedure TLoginForm.WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
begin
  CanClose := ModalResult = mrOK;
end;

procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BackgroundPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  PasswordLabel := TLabel.Create(Self);
  UsernameEdit := TEdit.Create(Self);
  PasswordEdit := TEdit.Create(Self);
  LoginButton := TButton.Create(Self);
  RememberMeCheckBox := TCheckBox.Create(Self);
  HeaderPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseFormImage := TImageControl.Create(Self);
  WebHttpRequest := THttpRequest.Create(Self);
  WebLocalStorage := TLocalStorage.Create(Self);

  BackgroundPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  PasswordLabel.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  PasswordEdit.BeforeLoadDFMValues;
  LoginButton.BeforeLoadDFMValues;
  RememberMeCheckBox.BeforeLoadDFMValues;
  HeaderPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseFormImage.BeforeLoadDFMValues;
  WebHttpRequest.BeforeLoadDFMValues;
  WebLocalStorage.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Width := 450;
    Height := 426;
    ElementClassName := 'MyForm';
    Shadow := False;
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'WebFormKeyDown');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BackgroundPanel.SetParentComponent(Self);
    BackgroundPanel.Name := 'BackgroundPanel';
    BackgroundPanel.Left := 0;
    BackgroundPanel.Top := 0;
    BackgroundPanel.Width := 450;
    BackgroundPanel.Height := 426;
    BackgroundPanel.Align := alClient;
    BackgroundPanel.BorderColor := -1;
    BackgroundPanel.BorderStyle := bsNone;
    BackgroundPanel.Color := 14062646;
    ContentPanel.SetParentComponent(BackgroundPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 444;
    ContentPanel.Height := 383;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    UsernameLabel.SetParentComponent(ContentPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 40;
    UsernameLabel.Top := 26;
    UsernameLabel.Width := 71;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'Username';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    PasswordLabel.SetParentComponent(ContentPanel);
    PasswordLabel.Name := 'PasswordLabel';
    PasswordLabel.Left := 40;
    PasswordLabel.Top := 116;
    PasswordLabel.Width := 66;
    PasswordLabel.Height := 21;
    PasswordLabel.Caption := 'Password';
    PasswordLabel.Font.Charset := ANSI_CHARSET;
    PasswordLabel.Font.Color := 9010544;
    PasswordLabel.Font.Height := -16;
    PasswordLabel.Font.Name := 'Segoe UI';
    PasswordLabel.Font.Style := [];
    PasswordLabel.HeightPercent := 100.000000000000000000;
    PasswordLabel.ParentFont := False;
    PasswordLabel.WidthPercent := 100.000000000000000000;
    UsernameEdit.SetParentComponent(ContentPanel);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 40;
    UsernameEdit.Top := 56;
    UsernameEdit.Width := 345;
    UsernameEdit.Height := 34;
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := 'MyWebEdit';
    UsernameEdit.Font.Charset := ANSI_CHARSET;
    UsernameEdit.Font.Color := clWindowText;
    UsernameEdit.Font.Height := -16;
    UsernameEdit.Font.Name := 'Segoe UI';
    UsernameEdit.Font.Style := [];
    UsernameEdit.HeightPercent := 100.000000000000000000;
    UsernameEdit.ParentFont := False;
    UsernameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UsernameEdit, Self, 'OnKeyDown', 'WebFormKeyDown');
    PasswordEdit.SetParentComponent(ContentPanel);
    PasswordEdit.Name := 'PasswordEdit';
    PasswordEdit.Left := 40;
    PasswordEdit.Top := 146;
    PasswordEdit.Width := 345;
    PasswordEdit.Height := 34;
    PasswordEdit.ChildOrder := 1;
    PasswordEdit.ElementClassName := 'MyWebEdit';
    PasswordEdit.Font.Charset := ANSI_CHARSET;
    PasswordEdit.Font.Color := clWindowText;
    PasswordEdit.Font.Height := -16;
    PasswordEdit.Font.Name := 'Segoe UI';
    PasswordEdit.Font.Style := [];
    PasswordEdit.HeightPercent := 100.000000000000000000;
    PasswordEdit.ParentFont := False;
    PasswordEdit.PasswordChar := '*';
    PasswordEdit.TabOrder := 1;
    PasswordEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PasswordEdit, Self, 'OnKeyDown', 'WebFormKeyDown');
    LoginButton.SetParentComponent(ContentPanel);
    LoginButton.Name := 'LoginButton';
    LoginButton.AlignWithMargins := True;
    LoginButton.Left := 265;
    LoginButton.Top := 290;
    LoginButton.Width := 120;
    LoginButton.Height := 40;
    LoginButton.Margins.Left := 10;
    LoginButton.Margins.Top := 10;
    LoginButton.Margins.Right := 0;
    LoginButton.Margins.Bottom := 10;
    LoginButton.Caption := 'Login';
    LoginButton.ChildOrder := 1;
    LoginButton.Default := True;
    LoginButton.ElementClassName := 'MyWebButton';
    LoginButton.Font.Charset := ANSI_CHARSET;
    LoginButton.Font.Color := clWhite;
    LoginButton.Font.Height := -16;
    LoginButton.Font.Name := 'Segoe UI';
    LoginButton.Font.Style := [];
    LoginButton.HeightPercent := 100.000000000000000000;
    LoginButton.ParentFont := False;
    LoginButton.TabOrder := 3;
    LoginButton.WidthPercent := 100.000000000000000000;
    SetEvent(LoginButton, Self, 'OnClick', 'LoginButtonClick');
    RememberMeCheckBox.SetParentComponent(ContentPanel);
    RememberMeCheckBox.Name := 'RememberMeCheckBox';
    RememberMeCheckBox.AlignWithMargins := True;
    RememberMeCheckBox.Left := 40;
    RememberMeCheckBox.Top := 209;
    RememberMeCheckBox.Width := 323;
    RememberMeCheckBox.Height := 35;
    RememberMeCheckBox.Margins.Left := 40;
    RememberMeCheckBox.Margins.Top := 10;
    RememberMeCheckBox.Margins.Right := 40;
    RememberMeCheckBox.Caption := 'Remember me';
    RememberMeCheckBox.ChildOrder := 2;
    RememberMeCheckBox.Color := clWhite;
    RememberMeCheckBox.Font.Charset := ANSI_CHARSET;
    RememberMeCheckBox.Font.Color := 9010544;
    RememberMeCheckBox.Font.Height := -16;
    RememberMeCheckBox.Font.Name := 'Segoe UI';
    RememberMeCheckBox.Font.Style := [];
    RememberMeCheckBox.HeightPercent := 100.000000000000000000;
    RememberMeCheckBox.ParentFont := False;
    RememberMeCheckBox.TabOrder := 2;
    RememberMeCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(RememberMeCheckBox, Self, 'OnKeyDown', 'WebFormKeyDown');
    HeaderPanel.SetParentComponent(BackgroundPanel);
    HeaderPanel.Name := 'HeaderPanel';
    HeaderPanel.Left := 0;
    HeaderPanel.Top := 0;
    HeaderPanel.Width := 450;
    HeaderPanel.Height := 40;
    HeaderPanel.Align := alTop;
    HeaderPanel.BorderColor := -1;
    HeaderPanel.BorderStyle := bsNone;
    HeaderPanel.ChildOrder := 2;
    HeaderPanel.Color := 14062646;
    CaptionLabel.SetParentComponent(HeaderPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 39;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Login';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseFormImage.SetParentComponent(HeaderPanel);
    CloseFormImage.Name := 'CloseFormImage';
    CloseFormImage.AlignWithMargins := True;
    CloseFormImage.Left := 421;
    CloseFormImage.Top := 9;
    CloseFormImage.Width := 23;
    CloseFormImage.Height := 22;
    CloseFormImage.Margins.Top := 9;
    CloseFormImage.Margins.Right := 6;
    CloseFormImage.Margins.Bottom := 9;
    CloseFormImage.HeightPercent := 100.000000000000000000;
    CloseFormImage.WidthPercent := 100.000000000000000000;
    CloseFormImage.Align := alRight;
    CloseFormImage.ChildOrder := 1;
    SetEvent(CloseFormImage, Self, 'OnClick', 'CloseFormImageClick');
    CloseFormImage.Picture.LoadFromFile('uLogin.HeaderPanel.CloseFormImage.Picture.png');
    WebHttpRequest.SetParentComponent(Self);
    WebHttpRequest.Name := 'WebHttpRequest';
    WebHttpRequest.Headers.BeginUpdate;
    try
      WebHttpRequest.Headers.Clear;
      WebHttpRequest.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest.Headers.EndUpdate;
    end;
    WebHttpRequest.Left := 176;
    WebHttpRequest.Top := 328;
    WebLocalStorage.SetParentComponent(Self);
    WebLocalStorage.Name := 'WebLocalStorage';
    WebLocalStorage.Left := 72;
    WebLocalStorage.Top := 328;
  finally
    BackgroundPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    PasswordLabel.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
    PasswordEdit.AfterLoadDFMValues;
    LoginButton.AfterLoadDFMValues;
    RememberMeCheckBox.AfterLoadDFMValues;
    HeaderPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseFormImage.AfterLoadDFMValues;
    WebHttpRequest.AfterLoadDFMValues;
    WebLocalStorage.AfterLoadDFMValues;
  end;
end;

end.

unit uAlertEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLIB.Rest, WEBLIB.JSON, WEBLib.RegularExpressions,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCDigitalTimePicker, VCL.TMSFNCCustomControl, VCL.TMSFNCCustomPicker,
  VCL.TMSFNCAnalogTimePicker, VCL.TMSFNCPageControl, VCL.TMSFNCTabSet,
  VCL.TMSFNCPanel, VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter,
  Data.DB, WEBLib.DB, WEBLib.CDS, WEBLib.WebCtrls,
  VCL.TMSFNCImage;

type
  TAlertEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    PageControl: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    UsernameLabel: TLabel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel8: TLabel;
    AlertTypeEdit: TLookupComboBox;
    AlertIDNLEdit: TEdit;
    AlertIDFREdit: TEdit;
    AlertIDENEdit: TEdit;
    TimeBoundEdit: TCheckBox;
    TimeFromEdit: TEdit;
    TimeTillEdit: TEdit;
    MondayEdit: TCheckBox;
    TuesdayEdit: TCheckBox;
    WednesdayEdit: TCheckBox;
    ThursdayEdit: TCheckBox;
    FridayEdit: TCheckBox;
    SaturdayEdit: TCheckBox;
    SundayEdit: TCheckBox;
    SMSEdit: TCheckBox;
    EmailEdit: TCheckBox;
    IntervalEdit: TEdit;
    ReportEdit: TCheckBox;
    DeleteButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    DatasetTimer: TTimer;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MyGrid: TTMSFNCGrid;
    WebConnection: TClientConnection;
    MyDatasetPKey: TStringField;
    MyDatasetSymbol: TStringField;
    MyDatasetID: TStringField;
    MyDatasetBrand: TStringField;
    MyDatasetModel: TStringField;
    MyDatasetGroup: TStringField;
    MyMessageDlg: TMessageDlg;
    SendToGrid: TTMSFNCGrid;
    SendToDataSet: TClientDataSet;
    SendToDataSource: TDataSource;
    SendToDBAdapter: TTMSFNCGridDatabaseAdapter;
    SendToDataSetPKey: TStringField;
    SendToDataSetChecked: TStringField;
    SendToDataSetEmail: TStringField;
    SendToDataSetAssetGroup: TStringField;
    SendToTimer: TTimer;
    MailToExtraEdit: TEdit;
    WebLabel11: TLabel;
    AddButton: TSpeedButton;
    SelectButton: TSpeedButton;
    DeselectButton: TSpeedButton;
    MyDatasetOrigin: TStringField;
    MyDatasetType: TStringField;
    AlertTypePanel: TTMSFNCPanel;
    AlertTypeSpeedPanel: TTMSFNCPanel;
    WebLabel4: TLabel;
    SpeedEdit: TEdit;
    WebLabel7: TLabel;
    DistanceEdit: TEdit;
    AlertTypeGeofencePanel: TTMSFNCPanel;
    WebLabel10: TLabel;
    GeofenceTypeEdit: TLookupComboBox;
    WebLabel9: TLabel;
    GeofenceListEdit: TLookupComboBox;
    CriticalEdit: TCheckBox;
    TMSFNCPanel3: TTMSFNCPanel;
    ApplyToAllAssetsEdit: TCheckBox;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure AlertTypeEditChange(Sender: TObject);
    procedure TimeBoundEditClick(Sender: TObject);
    procedure PageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure MyGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
    procedure MyGridColumnSorted(Sender: TObject; ACol: Integer;
      Direction: TTMSFNCGridSortDirection);
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    procedure PageControlBeforeChangePage(Sender: TObject; ACurrentPageIndex,
      ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure DatasetTimerTimer(Sender: TObject);
    procedure RecordChange(Sender: TObject);
    procedure SendToTimerTimer(Sender: TObject);
    procedure SendToDataSetAfterOpen(DataSet: TDataSet);
    procedure SendToGridKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure SendToGridCellClick(Sender: TObject; ACol, ARow: Integer);
    procedure SendToDataSetBeforeEdit(DataSet: TDataSet);
    procedure SendToDataSetAfterPost(DataSet: TDataSet);
    procedure SelectButtonClick(Sender: TObject);
    procedure DeselectButtonClick(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    FocusPKey: Int64;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadAlertTypeList(S: String);
    procedure LoadGeofenceList(S: String);
    [async]
    procedure LoadData(); async;
    [async]
    procedure LoadAssetList(); async;
    [async]
    procedure LoadSendToList(); async;
    [async]
    procedure DeleteAsset(PKey: String); async;
    [async]
    procedure DeleteAssetPost(PKey: String); async;
    [async]
    procedure AddAsset(List: String); async;
    [async]
    procedure AddAssetPost(List: String); async;
    [async]
    procedure UpdateUserInterface(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    function SendMailToString(): String;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    IsSet: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  AlertEditForm: TAlertEditForm;

implementation

{$R *.dfm}

uses uCommon, uAssetList;

procedure TAlertEditForm.WebFormCreate(Sender: TObject);
begin
  Tag := 0;
  FocusPKey := -1;
  SetGridStyle(MyGrid, False);
  SetGridStyle(SendToGrid, False);
end;

procedure TAlertEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      RecordChanged := False;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TAlertEditForm.SendToDataSetAfterOpen(DataSet: TDataSet);
begin
  SendToDBAdapter.Active := True;
  SendToTimer.Enabled := True;
end;

procedure TAlertEditForm.SendToDataSetAfterPost(DataSet: TDataSet);
begin
  RecordChange(nil);
end;

procedure TAlertEditForm.SendToDataSetBeforeEdit(DataSet: TDataSet);
begin
  RecordChange(nil);
end;

procedure TAlertEditForm.SendToGridCellClick(Sender: TObject; ACol,
  ARow: Integer);
begin
  if ACol = 0 then begin
    if SendToDataset.Active and not(SendToDataset.IsEmpty) then begin
      SendToDataset.Edit;
      if SendToDataset.FieldByName('Checked').AsString = 't' then begin
        SendToDataset.FieldByName('Checked').AsString := 'f';
      end else begin
        SendToDataset.FieldByName('Checked').AsString := 't';
      end;
      SendToDataset.Post;
    end;
  end;
end;

procedure TAlertEditForm.SendToGridKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = VK_Space then begin
    if SendToDataset.Active and not(SendToDataset.IsEmpty) then begin
      SendToDataset.Edit;
      if SendToDataset.FieldByName('Checked').AsString = 't' then begin
        SendToDataset.FieldByName('Checked').AsString := 'f';
      end else begin
        SendToDataset.FieldByName('Checked').AsString := 't';
      end;
      SendToDataset.Post;
    end;
  end;
end;

procedure TAlertEditForm.SendToTimerTimer(Sender: TObject);
begin
  SendToTimer.Enabled := False;
  SendToDataset.First;
  WebWaitMessage.Hide;
end;

procedure TAlertEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Visible := False;
end;

procedure TAlertEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TAlertEditForm.ShowTimerTimer(Sender: TObject);
begin
  ShowTimer.Enabled := False;
  // Load Lookup Values
  await(LoadLookupValues());
  // Load Data
  if PKey >= 0 then begin
    AlertTypeEdit.Enabled := False;
    await(LoadData());
  end else begin
    IntervalEdit.Text := '00:15:00';
    AlertTypeEdit.OnChange := AlertTypeEditChange;
    TimeBoundEdit.OnClick := TimeBoundEditClick;
    GeofenceTypeEdit.Value := '0';
    UpdateUserInterface();
  end;
  WebWaitMessage.Hide;
  RecordChanged := False;
end;

procedure TAlertEditForm.TimeBoundEditClick(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  UpdateUserInterface();
end;

procedure TAlertEditForm.LoadLookupValues();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_alert_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadAlertTypeList(TJSONPair(MyJSONObject.Get('alert_type')).JsonValue.ToString);
    LoadGeofenceList(TJSONPair(MyJSONObject.Get('geofence_list')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TAlertEditForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  // Link Grid
  MyGrid.OnSelectedCell := nil;
  GridDatabaseAdapter.Active := True;
  GridDatabaseAdapter.LoadAllDataAndDisconnect;
  MyGrid.OnSelectedCell := MyGridSelectedCell;
  DatasetTimer.Enabled := True;
end;

procedure TAlertEditForm.MyGridColumnSorted(Sender: TObject; ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
  GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
end;

procedure TAlertEditForm.MyGridSelectedCell(Sender: TObject; ACol,
  ARow: Integer);
begin
  FocusPKey := StrToInt(Trim(MyGrid.Cells[0, ARow]));
end;

procedure TAlertEditForm.PageControlBeforeChangePage(Sender: TObject;
  ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
begin
  if ANewPageIndex > 0 then begin
    if PKey < 0 then begin
      ACanChange := False;
      MessageDlg('Save alert before linking assets.', TMsgDlgType.mtError, [mbOK]);
    end;
  end;
end;

procedure TAlertEditForm.PageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  case ACurrentPageIndex of
    0: begin
      AddButton.Visible := False;
      DeleteButton.Visible := False;
      SelectButton.Visible := False;
      DeselectButton.Visible := False;
    end;
    1: begin
      AddButton.Visible := True;
      DeleteButton.Visible := True;
      SelectButton.Visible := False;
      DeselectButton.Visible := False;
      LoadAssetList();
    end;
    2: begin
      AddButton.Visible := False;
      DeleteButton.Visible := False;
      SelectButton.Visible := True;
      DeselectButton.Visible := True;
      LoadSendToList();
    end;
  end;
end;

procedure TAlertEditForm.AddButtonClick(Sender: TObject);
var
  MyWebForm: TAssetListForm;
  MyModalResult: TModalResult;
begin
  MyWebForm := TAssetListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TAssetListForm, MyWebForm.Load());
  try
    MyWebForm.SelectButton.Visible := True;
    MyWebForm.AddButton.Visible := False;
    MyWebForm.ViewButton.Visible := False;
    MyWebForm.EditButton.Visible := False;
    MyWebForm.DeleteButton.Visible := False;
    // excute form and wait for close
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      // Add Assets
//    AddAsset(MyWebForm.Selection);
      AddAssetPost(MyWebForm.Selection);
//    LoadAssetList();
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TAlertEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TAlertEditForm.AlertTypeEditChange(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  UpdateUserInterface();
end;

procedure TAlertEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  Result: Boolean;
  ErrorMessage: String;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      Result := await(ValidInput(ErrorMessage));
      if Result then begin
        Result := await(SaveData(ErrorMessage));
        if Result then begin
          ModalResult := mrOK;
        end else begin
          MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
        end;
      end else begin
        MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAlertEditForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  MyMessage: String;
  StringList: TStringList;
  I, RowIdx: Integer;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    MyMessage := 'Delete selected records?';
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog(MyMessage, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      StringList := TStringList.Create();
      try
        for I := 0 to MyGrid.RowSelectionCount-1 do begin
          RowIdx := MyGrid.SelectedRow[I];
          StringList.Add(MyGrid.Cells[0, RowIdx]);
        end;
//      DeleteAsset(StringList.DelimitedText);
        DeleteAssetPost(StringList.DelimitedText);
      finally
        StringList.Free;
      end;
    end;
  end;
end;

procedure TAlertEditForm.DeselectButtonClick(Sender: TObject);
var
  I: Integer;
  StringList: TStringList;
begin
  StringList := TStringList.Create();
  try
    // Get Rows Numbers
    for I := 0 to SendToGrid.RowSelectionCount-1 do begin
      StringList.Add(IntToStr(SendToGrid.SelectedRow[I]));
    end;
    // Update Records
    for I := 0 to StringList.Count-1 do begin
      SendToDataset.RecNo := StrToInt(StringList[I]);
      SendToDataset.Edit;
      SendToDataset.FieldByName('Checked').AsString := 'f';
      SendToDataset.Post;
    end;
  finally
    StringList.Free;
  end;
end;

procedure TAlertEditForm.AddAsset(List: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_alert_asset_add.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + List;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAssetList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAlertEditForm.AddAssetPost(List: String);
var
  MyString, ErrorMessage: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyString :=
    '{' +
      '"sessionid":"' + SessionId + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"list": "' + List + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_alert_asset_add_post.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          LoadAssetList();
        end else begin
          WebWaitMessage.Hide;
          ErrorMessage := MyJSONObject.GetJSONValue('error_message');
          MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end
    end else begin
      WebWaitMessage.Hide;
      ErrorMessage := 'HTTP Error ' + IntToStr(MyRequest.Status);
      MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAlertEditForm.DatasetTimerTimer(Sender: TObject);
begin
  WebWaitMessage.Hide;
end;

procedure TAlertEditForm.DeleteAsset(PKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_alert_asset_delete.php?sessionid=' + SessionId +
      '&pkey=' + PKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAssetList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAlertEditForm.DeleteAssetPost(PKey: String);
var
  MyString, ErrorMessage: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyString :=
    '{' +
      '"sessionid":"' + SessionId + '",' +
      '"pkey": "' + PKey + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_alert_asset_delete_post.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          LoadAssetList();
        end else begin
          WebWaitMessage.Hide;
          ErrorMessage := MyJSONObject.GetJSONValue('error_message');
          MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end
    end else begin
      WebWaitMessage.Hide;
      ErrorMessage := 'HTTP Error ' + IntToStr(MyRequest.Status);
      MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAlertEditForm.LoadAlertTypeList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AlertTypeEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AlertTypeEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('pkey'),
        MyJSONObject.GetJSONValue('id_en')
      );
    end;
    // Set Value
    AlertTypeEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAlertEditForm.LoadGeofenceList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  GeofenceListEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      GeofenceListEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('pkey'),
        MyJSONObject.GetJSONValue('id')
      );
    end;
  finally
    MyJSON.Free;
  end;
end;

procedure TAlertEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_alert_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      // ID
      AlertIDNLEdit.Text := MyJSONObject.GetJSONValue('alert_id_nl');
      AlertIDFREdit.Text := MyJSONObject.GetJSONValue('alert_id_fr');
      AlertIDENEdit.Text := MyJSONObject.GetJSONValue('alert_id_en');
      // Type
      AlertTypeEdit.Value := MyJSONObject.GetJSONValue('alert_type_pkey');
      TimeBoundEdit.Checked := MyJSONObject.GetJSONValue('time_bound') = '1';
      // Time Bound
      if TimeBoundEdit.Checked then begin
        TimeFromEdit.Text := MyJSONObject.GetJSONValue('time_from');
        TimeTillEdit.Text := MyJSONObject.GetJSONValue('time_till');
        MondayEdit.Checked := MyJSONObject.GetJSONValue('day_monday') = '1';
        TuesdayEdit.Checked := MyJSONObject.GetJSONValue('day_tuesday') = '1';
        WednesdayEdit.Checked := MyJSONObject.GetJSONValue('day_wednesday') = '1';
        ThursdayEdit.Checked := MyJSONObject.GetJSONValue('day_thursday') = '1';
        FridayEdit.Checked := MyJSONObject.GetJSONValue('day_friday') = '1';
        SaturdayEdit.Checked := MyJSONObject.GetJSONValue('day_saturday') = '1';
        SundayEdit.Checked := MyJSONObject.GetJSONValue('day_sunday') = '1';
      end else begin
        TimeFromEdit.Text := '00:00:00';
        TimeTillEdit.Text := '23:59:59';
        MondayEdit.Checked := False;
        TuesdayEdit.Checked := False;
        WednesdayEdit.Checked := False;
        ThursdayEdit.Checked := False;
        FridayEdit.Checked := False;
        SaturdayEdit.Checked := False;
        SundayEdit.Checked := False;
      end;
      // Tresholds
      SpeedEdit.Text := MyJSONObject.GetJSONValue('treshold_speed');
      GeofenceTypeEdit.Value := MyJSONObject.GetJSONValue('geofence_type');
      GeofenceListEdit.Value := MyJSONObject.GetJSONValue('geofence_pkey');
      DistanceEdit.Text := MyJSONObject.GetJSONValue('treshold_distance');
      IntervalEdit.Text := MyJSONObject.GetJSONValue('treshold_interval');
      // Assets
      ApplyToAllAssetsEdit.Checked := MyJSONObject.GetJSONValue('apply_to_all_assets') = '1';
      // Notification / Reporting
      SMSEdit.Checked := MyJSONObject.GetJSONValue('send_sms') = '1';
      EMailEdit.Checked := MyJSONObject.GetJSONValue('send_email') = '1';
      MailToExtraEdit.Text := MyJSONObject.GetJSONValue('send_email_extra');
      ReportEdit.Checked := MyJSONObject.GetJSONValue('show_in_report') = '1';
      CriticalEdit.Checked := MyJSONObject.GetJSONValue('critical') = '1';
      // Update User Interface
      AlertTypeEdit.OnChange := AlertTypeEditChange;
      TimeBoundEdit.OnClick := TimeBoundEditClick;
      UpdateUserInterface();
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAlertEditForm.UpdateUserInterface();
var
  AlertType: Int64;
begin
  // Get Alert Type
  AlertType := StrToInt(AlertTypeEdit.Value);
  // Speed
  SpeedEdit.Enabled := (AlertType = 19);
  if not(SpeedEdit.Enabled) then SpeedEdit.Text := '0';
  // Distance
  DistanceEdit.Enabled := (AlertType = 19) or (AlertType = 100);
  if not(DistanceEdit.Enabled) then DistanceEdit.Text := '0';
  // Distance / Speed
  if SpeedEdit.Enabled or DistanceEdit.Enabled then begin
    AlertTypeSpeedPanel.Visible := True;
    AlertTypeSpeedPanel.BringToFront;
  end else begin
    AlertTypeSpeedPanel.Visible := False;
  end;
  // POI Geofence
  GeofenceTypeEdit.Enabled := (AlertType = 102);
  if not(GeofenceTypeEdit.Enabled) then GeofenceTypeEdit.Value := '0';
  if GeofenceTypeEdit.Enabled then begin
    AlertTypeGeofencePanel.Visible := True;
    AlertTypeGeofencePanel.BringToFront;
  end else begin
    AlertTypeGeofencePanel.Visible := False;
  end;
  // Time Bound
  TimeFromEdit.Enabled := TimeBoundEdit.Checked;
  TimeTillEdit.Enabled := TimeBoundEdit.Checked;
  if not(TimeBoundEdit.Checked) then begin
    TimeFromEdit.Text := '00:00:00';
    TimeTillEdit.Text := '23:59:59';
  end;
  MondayEdit.Enabled := TimeBoundEdit.Checked;
  TuesdayEdit.Enabled := TimeBoundEdit.Checked;
  WednesdayEdit.Enabled := TimeBoundEdit.Checked;
  ThursdayEdit.Enabled := TimeBoundEdit.Checked;
  FridayEdit.Enabled := TimeBoundEdit.Checked;
  SaturdayEdit.Enabled := TimeBoundEdit.Checked;
  SundayEdit.Enabled := TimeBoundEdit.Checked;
end;

procedure TAlertEditForm.LoadAssetList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_alert_asset_list.php?sessionid=' + SessionId;
    MyWebRequest.URL :=
      MyWebRequest.URL + '&pkey=' + IntToStr(PKey);
{
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
}
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TAlertEditForm.LoadSendToList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_alert_mailto_list.php?sessionid=' + SessionId +
      '&alert_pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  SendToDataset.Close;
  SendToDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(SendToDataset.Open);
end;


function TAlertEditForm.ValidInput(var ErrorMessage: String): Boolean;
var
  MyInteger: Integer;
  MyFloat: Double;
  MyFrom, MyTill: TDateTime;
  MyStringList: TStringList;
begin
  Result := True;
  // ID
  if Result then begin
    if Trim(AlertIDNLEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'DUTCH is a required field.';
    end;
  end;
  // Alert Type
  if Result then begin
    if TryStrToInt(AlertTypeEdit.Value, MyInteger) then begin
    end else begin
      Result := False;
      ErrorMessage := 'ALERT TYPE is a required field.';
    end;
  end;
  // Speed
  if Result then begin
    if MyInteger = 19 then begin
      if TryStrToInt(SpeedEdit.Text, MyInteger) then begin
        if MyInteger <= 0 then begin
          Result := False;
          ErrorMessage := 'SPEED needs to a positive number.';
        end;
      end else begin
        Result := False;
        ErrorMessage := 'SPEED is a required field.';
      end;
    end;
  end;
  // Geofence Type
  if Result then begin
    if MyInteger = 102 then begin
      if Result then begin
        if GeofenceListEdit.ItemIndex = -1 then begin
          Result := False;
          ErrorMessage := 'GEOFENCE is a required field.';
        end;
      end;
      if Result then begin
        if GeofenceTypeEdit.Value = '0' then begin
          Result := False;
          ErrorMessage := 'ENTER / EXIT / BOTH is a required field.';
        end;
      end;
    end;
  end;
  // Distance / Beweging Voertuig
  if Result then begin
    if MyInteger = 100 then begin
      if TryStrToFloat(DistanceEdit.Text, MyFloat) then begin
        if MyFloat <= 0 then begin
          Result := False;
          ErrorMessage := 'Distance needs to a positive number.';
        end;
      end else begin
        Result := False;
        ErrorMessage := 'Distance is a required field.';
      end;
    end;
  end;
  // Interval
  if Result then begin
    if not(TryStrToTime(InterValEdit.Text, MyFrom)) then begin
      Result := False;
      ErrorMessage := 'INTERVAL contains an invalid value.';
    end;
  end;
  // Time Bound
  if TimeBoundEdit.Checked then begin
    // Time From
    if Result then begin
      if not(TryStrToTime(TimeFromEdit.Text, MyFrom)) then begin
        Result := False;
        ErrorMessage := 'TIME FROM contains an invalid value.';
      end;
    end;
    // Time Till
    if Result then begin
      if not(TryStrToTime(TimeTillEdit.Text, MyTill)) then begin
        Result := False;
        ErrorMessage := 'TIME TILL contains an invalid value.';
      end;
    end;
    // From vs Till
    if Result then begin
      if MyFrom >= MyTill then begin
        Result := False;
        ErrorMessage := 'TIME TILL must be greater than TIME FROM.';
      end;
    end;
    // Days In Week
    if Result then begin
      if not(
        MondayEdit.Checked or TuesdayEdit.Checked or WednesdayEdit.Checked or
        ThursdayEdit.Checked or FridayEdit.Checked or SaturdayEdit.Checked or
        SundayEdit.Checked
      ) then begin
        Result := False;
        ErrorMessage := 'At least one day is required.';
      end;
    end;
    // Extra Email
    if Result then begin
      if (Trim(MailToExtraEdit.Text) <> EmptyStr) then begin
        MyStringList := TStringList.Create;
        MyStringList.Delimiter := ';';
        try
          MyStringList.DelimitedText := Trim(MailToExtraEdit.Text);
          for MyInteger := 0 to MyStringList.Count-1 do begin
            if not(TRegEx.IsMatch(Trim(MyStringList[MyInteger]), RegExEmail)) then begin
              Result := False;
              ErrorMessage := 'Extra Email does not contain a valid email address.';
            end;
          end;
        finally
          MyStringList.Free;
        end;
      end;
    end;
  end;
end;

procedure TAlertEditForm.SelectButtonClick(Sender: TObject);
var
  I: Integer;
  StringList: TStringList;
begin
  StringList := TStringList.Create();
  try
    // Get Rows Numbers
    for I := 0 to SendToGrid.RowSelectionCount-1 do begin
      StringList.Add(IntToStr(SendToGrid.SelectedRow[I]));
    end;
    // Update Records
    for I := 0 to StringList.Count-1 do begin
      SendToDataset.RecNo := StrToInt(StringList[I]);
      SendToDataset.Edit;
      SendToDataset.FieldByName('Checked').AsString := 't';
      SendToDataset.Post;
    end;
  finally
    StringList.Free;
  end;
end;

function TAlertEditForm.SendMailToString(): String;
var
  RecNo: Integer;
  StringListTo, StringListNo: TStringList;
begin
  Result := EmptyStr;
  if SendToDataset.Active and not(SendToDataset.IsEmpty) then begin
    StringListTo := TStringList.Create();
    StringListNo := TStringLIst.Create();
    try
      SendToDataset.BeginUpdate;
      try
        RecNo := SendToDataset.RecNo;
        SendToDataset.First;
        while not(SendToDataset.Eof) do begin
          if SendToDataset.FieldByName('Checked').AsString = 't' then begin
            StringListTo.Add(SendToDataset.FieldByName('PKey').AsString);
          end else begin
            StringListNo.Add(SendToDataset.FieldByName('PKey').AsString);
          end;
          SendToDataset.Next;
        end;
        SendToDataset.RecNo := RecNo;
      finally
        SendToDataset.EndUpdate;
      end;
      Result :=
        '"send_email_to": "' + StringListTo.DelimitedText + '",' +
        '"send_email_no": "' + StringListNo.DelimitedText + '",';
    finally
      StringListNo.Free;
    end;
  end else begin

  end;
end;

function TAlertEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"alert_id_nl": "' + AlertIDNLEdit.Text + '",' +
      '"alert_id_fr": "' + AlertIDFREdit.Text + '",' +
      '"alert_id_en": "' + AlertIDENEdit.Text + '",' +
      '"alert_type_pkey": "' + AlertTypeEdit.Value + '",' +
      '"time_bound": "' + BooleanToString(TimeBoundEdit.Checked) + '",' +
      '"time_from": "' + TimeFromEdit.Text + '",' +
      '"time_till": "' + TimeTillEdit.Text + '",' +
      '"day_monday": "' + BooleanToString(MondayEdit.Checked) + '",' +
      '"day_tuesday": "' + BooleanToString(TuesdayEdit.Checked) + '",' +
      '"day_wednesday": "' + BooleanToString(WednesdayEdit.Checked) + '",' +
      '"day_thursday": "' + BooleanToString(ThursdayEdit.Checked) + '",' +
      '"day_friday": "' + BooleanToString(FridayEdit.Checked) + '",' +
      '"day_saturday": "' + BooleanToString(SaturdayEdit.Checked) + '",' +
      '"day_sunday": "' + BooleanToString(SundayEdit.Checked) + '",' +
      '"apply_to_all_assets": "' + BooleanToString(ApplyToAllAssetsEdit.Checked) + '",' +
      '"treshold_speed": "' + SpeedEdit.Text + '",' +
      '"geofence_type": "' + EmptyStrToZero(GeofenceTypeEdit.Value) + '",' +
      '"geofence_pkey": "' + EmptyStrToZero(GeofenceListEdit.Value) + '",' +
      '"treshold_speed": "' + SpeedEdit.Text + '",' +
      '"treshold_distance": "' + DistanceEdit.Text + '",' +
      '"treshold_interval": "' + IntervalEdit.Text + '",' +
      '"send_sms": "' + BooleanToString(SMSEdit.Checked) + '",' +
      '"send_email": "' + BooleanToString(EmailEdit.Checked) + '",';
  // Email To
  MyString := MyString + SendMailToString();
  // Email All To
  MyString := MyString +
      '"send_email_extra": "' + Trim(MailToExtraEdit.Text) + '",';
  // Show In Report
  MyString := MyString +
      '"show_in_report": "' + BooleanToString(ReportEdit.Checked) + '",' +
      '"critical": "' + BooleanToString(CriticalEdit.Checked) + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_alert_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;


procedure TAlertEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  DeselectButton := TSpeedButton.Create(Self);
  PageControl := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  SendToGrid := TTMSFNCGrid.Create(Self);
  MailToExtraEdit := TEdit.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel3 := TTMSFNCPanel.Create(Self);
  ApplyToAllAssetsEdit := TCheckBox.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  AlertTypeEdit := TLookupComboBox.Create(Self);
  AlertIDNLEdit := TEdit.Create(Self);
  AlertIDFREdit := TEdit.Create(Self);
  AlertIDENEdit := TEdit.Create(Self);
  TimeBoundEdit := TCheckBox.Create(Self);
  TimeFromEdit := TEdit.Create(Self);
  TimeTillEdit := TEdit.Create(Self);
  MondayEdit := TCheckBox.Create(Self);
  TuesdayEdit := TCheckBox.Create(Self);
  WednesdayEdit := TCheckBox.Create(Self);
  ThursdayEdit := TCheckBox.Create(Self);
  FridayEdit := TCheckBox.Create(Self);
  SaturdayEdit := TCheckBox.Create(Self);
  SundayEdit := TCheckBox.Create(Self);
  SMSEdit := TCheckBox.Create(Self);
  EmailEdit := TCheckBox.Create(Self);
  IntervalEdit := TEdit.Create(Self);
  ReportEdit := TCheckBox.Create(Self);
  AlertTypePanel := TTMSFNCPanel.Create(Self);
  AlertTypeSpeedPanel := TTMSFNCPanel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  SpeedEdit := TEdit.Create(Self);
  DistanceEdit := TEdit.Create(Self);
  AlertTypeGeofencePanel := TTMSFNCPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  GeofenceTypeEdit := TLookupComboBox.Create(Self);
  GeofenceListEdit := TLookupComboBox.Create(Self);
  CriticalEdit := TCheckBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  SendToDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ShowTimer := TTimer.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetPKey := TStringField.Create(Self);
  MyDatasetSymbol := TStringField.Create(Self);
  MyDatasetID := TStringField.Create(Self);
  MyDatasetBrand := TStringField.Create(Self);
  MyDatasetModel := TStringField.Create(Self);
  MyDatasetGroup := TStringField.Create(Self);
  MyDatasetType := TStringField.Create(Self);
  MyDatasetOrigin := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  WebConnection := TClientConnection.Create(Self);
  SendToDataSet := TClientDataSet.Create(Self);
  SendToDataSetPKey := TStringField.Create(Self);
  SendToDataSetChecked := TStringField.Create(Self);
  SendToDataSetEmail := TStringField.Create(Self);
  SendToDataSetAssetGroup := TStringField.Create(Self);
  SendToDataSource := TDataSource.Create(Self);
  SendToTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  DeselectButton.BeforeLoadDFMValues;
  PageControl.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  SendToGrid.BeforeLoadDFMValues;
  MailToExtraEdit.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  TMSFNCPanel3.BeforeLoadDFMValues;
  ApplyToAllAssetsEdit.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  AlertTypeEdit.BeforeLoadDFMValues;
  AlertIDNLEdit.BeforeLoadDFMValues;
  AlertIDFREdit.BeforeLoadDFMValues;
  AlertIDENEdit.BeforeLoadDFMValues;
  TimeBoundEdit.BeforeLoadDFMValues;
  TimeFromEdit.BeforeLoadDFMValues;
  TimeTillEdit.BeforeLoadDFMValues;
  MondayEdit.BeforeLoadDFMValues;
  TuesdayEdit.BeforeLoadDFMValues;
  WednesdayEdit.BeforeLoadDFMValues;
  ThursdayEdit.BeforeLoadDFMValues;
  FridayEdit.BeforeLoadDFMValues;
  SaturdayEdit.BeforeLoadDFMValues;
  SundayEdit.BeforeLoadDFMValues;
  SMSEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  IntervalEdit.BeforeLoadDFMValues;
  ReportEdit.BeforeLoadDFMValues;
  AlertTypePanel.BeforeLoadDFMValues;
  AlertTypeSpeedPanel.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  SpeedEdit.BeforeLoadDFMValues;
  DistanceEdit.BeforeLoadDFMValues;
  AlertTypeGeofencePanel.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  GeofenceTypeEdit.BeforeLoadDFMValues;
  GeofenceListEdit.BeforeLoadDFMValues;
  CriticalEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  SendToDBAdapter.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetPKey.BeforeLoadDFMValues;
  MyDatasetSymbol.BeforeLoadDFMValues;
  MyDatasetID.BeforeLoadDFMValues;
  MyDatasetBrand.BeforeLoadDFMValues;
  MyDatasetModel.BeforeLoadDFMValues;
  MyDatasetGroup.BeforeLoadDFMValues;
  MyDatasetType.BeforeLoadDFMValues;
  MyDatasetOrigin.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  SendToDataSet.BeforeLoadDFMValues;
  SendToDataSetPKey.BeforeLoadDFMValues;
  SendToDataSetChecked.BeforeLoadDFMValues;
  SendToDataSetEmail.BeforeLoadDFMValues;
  SendToDataSetAssetGroup.BeforeLoadDFMValues;
  SendToDataSource.BeforeLoadDFMValues;
  SendToTimer.BeforeLoadDFMValues;
  try
    Name := 'AlertEditForm';
    Width := 1200;
    Height := 684;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1200;
    FormPanel.Height := 684;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1194;
    ContentPanel.Height := 641;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 592;
    StatusBarPanel.Width := 1194;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1074;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAlertEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 959;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAlertEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 128;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uAlertEdit.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 2;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 3;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 20;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uAlertEdit.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 4;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 236;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 0;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 8;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uAlertEdit.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 5;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    DeselectButton.SetParentComponent(StatusBarPanel);
    DeselectButton.Name := 'DeselectButton';
    DeselectButton.AlignWithMargins := True;
    DeselectButton.Left := 344;
    DeselectButton.Top := 8;
    DeselectButton.Width := 100;
    DeselectButton.Height := 31;
    DeselectButton.Margins.Left := 0;
    DeselectButton.Margins.Top := 8;
    DeselectButton.Margins.Right := 8;
    DeselectButton.Margins.Bottom := 10;
    DeselectButton.Align := alLeft;
    DeselectButton.Caption := 'Deselect';
    DeselectButton.Color := clNone;
    DeselectButton.ElementClassName := 'MyWebButton';
    DeselectButton.Glyph.LoadFromFile('uAlertEdit.StatusBarPanel.DeselectButton.Glyph.png');
    DeselectButton.HeightPercent := 100.000000000000000000;
    DeselectButton.TabOrder := 6;
    DeselectButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeselectButton, Self, 'OnClick', 'DeselectButtonClick');
    PageControl.SetParentComponent(ContentPanel);
    PageControl.Name := 'PageControl';
    PageControl.AlignWithMargins := True;
    PageControl.Left := 10;
    PageControl.Top := 0;
    PageControl.Width := 1174;
    PageControl.Height := 584;
    PageControl.Margins.Left := 10;
    PageControl.Margins.Top := 0;
    PageControl.Margins.Right := 10;
    PageControl.Margins.Bottom := 8;
    PageControl.Align := alClient;
    PageControl.BevelEdges := [];
    PageControl.BevelInner := bvNone;
    PageControl.BevelOuter := bvNone;
    PageControl.ParentDoubleBuffered := False;
    PageControl.Color := clBtnFace;
    PageControl.DoubleBuffered := True;
    PageControl.Font.Charset := DEFAULT_CHARSET;
    PageControl.Font.Color := clWindowText;
    PageControl.Font.Height := -16;
    PageControl.Font.Name := 'Tahoma';
    PageControl.Font.Style := [];
    PageControl.ParentFont := False;
    PageControl.TabOrder := 1;
    PageControl.Fill.Kind := gfkNone;
    PageControl.Fill.ColorTo := clWhite;
    PageControl.Stroke.Kind := gskNone;
    PageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    PageControl.TabAppearance.Font.Color := clAqua;
    PageControl.TabAppearance.Font.Height := -16;
    PageControl.TabAppearance.Font.Name := 'Segoe UI';
    PageControl.TabAppearance.Font.Style := [];
    PageControl.TabAppearance.Fill.Color := 14408667;
    PageControl.TabAppearance.Stroke.Color := -1;
    PageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    PageControl.TabAppearance.HoverFill.Color := 15658734;
    PageControl.TabAppearance.HoverStroke.Color := -1;
    PageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    PageControl.TabAppearance.Shape := tsRectangle;
    PageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    PageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    PageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    PageControl.TabAppearance.TextColor := clGrayText;
    PageControl.TabAppearance.ActiveTextColor := clGrayText;
    PageControl.TabAppearance.HoverTextColor := clGrayText;
    PageControl.TabAppearance.ShowFocus := False;
    PageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    PageControl.TabAppearance.BadgeFont.Color := 139;
    PageControl.TabAppearance.BadgeFont.Height := -11;
    PageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    PageControl.TabAppearance.BadgeFont.Style := [fsBold];
    PageControl.ButtonAppearance.CloseIcon.LoadFromFile('uAlertEdit.ContentPanel.PageControl.ButtonAppearance.CloseIcon.svg');
    PageControl.ButtonAppearance.InsertIcon.LoadFromFile('uAlertEdit.ContentPanel.PageControl.ButtonAppearance.InsertIcon.svg');
    PageControl.ButtonAppearance.TabListIcon.LoadFromFile('uAlertEdit.ContentPanel.PageControl.ButtonAppearance.TabListIcon.svg');
    PageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uAlertEdit.ContentPanel.PageControl.ButtonAppearance.ScrollNextIcon.svg');
    PageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uAlertEdit.ContentPanel.PageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    PageControl.TabSize.Height := 45.000000000000000000;
    PageControl.TabSize.Margins.Left := 8.000000000000000000;
    PageControl.TabSize.Margins.Top := 8.000000000000000000;
    PageControl.TabSize.Margins.Right := 8.000000000000000000;
    PageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    PageControl.Pages.Clear;
    with PageControl.Pages.Add do
    begin
      Text := 'GENERAL';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'APPLY TO';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'MAIL TO';
      UseDefaultAppearance := True;
    end;
    SetEvent(PageControl, Self, 'OnBeforeChangePage', 'PageControlBeforeChangePage');
    SetEvent(PageControl, Self, 'OnChangePage', 'PageControlChangePage');
    TMSFNCPageControl1Page2.SetParentComponent(PageControl);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 1174;
    TMSFNCPageControl1Page2.Height := 532;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    WebLabel11.SetParentComponent(TMSFNCPageControl1Page2);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 10;
    WebLabel11.Top := 496;
    WebLabel11.Width := 79;
    WebLabel11.Height := 21;
    WebLabel11.Caption := 'Extra Email:';
    WebLabel11.Font.Charset := ANSI_CHARSET;
    WebLabel11.Font.Color := 9010544;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Segoe UI';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    SendToGrid.SetParentComponent(TMSFNCPageControl1Page2);
    SendToGrid.Name := 'SendToGrid';
    SendToGrid.AlignWithMargins := True;
    SendToGrid.Left := 0;
    SendToGrid.Top := 0;
    SendToGrid.Width := 1174;
    SendToGrid.Height := 482;
    SendToGrid.Margins.Left := 0;
    SendToGrid.Margins.Top := 0;
    SendToGrid.Margins.Right := 0;
    SendToGrid.Margins.Bottom := 50;
    SendToGrid.AdaptToStyle := True;
    SendToGrid.Align := alClient;
    SendToGrid.BevelInner := bvNone;
    SendToGrid.BevelOuter := bvNone;
    SendToGrid.BevelWidth := 2;
    SendToGrid.Ctl3D := False;
    SendToGrid.ParentCtl3D := False;
    SendToGrid.ParentDoubleBuffered := False;
    SendToGrid.DoubleBuffered := True;
    SendToGrid.Font.Charset := ANSI_CHARSET;
    SendToGrid.Font.Color := clWindowText;
    SendToGrid.Font.Height := -16;
    SendToGrid.Font.Name := 'Segoe UI';
    SendToGrid.Font.Style := [];
    SendToGrid.ParentFont := False;
    SendToGrid.TabOrder := 0;
    SetEvent(SendToGrid, Self, 'OnKeyDown', 'SendToGridKeyDown');
    SendToGrid.DefaultRowHeight := 34.000000000000000000;
    SendToGrid.FixedColumns := 0;
    SendToGrid.ColumnCount := 4;
    SendToGrid.RowCount := 1;
    SendToGrid.Options.Borders.FixedCellBorders := bNone;
    SendToGrid.Options.ColumnSize.Stretch := True;
    SendToGrid.Options.ColumnSize.StretchColumn := 3;
    SendToGrid.Options.Editing.CalcFormat := '%g';
    SendToGrid.Options.Grouping.CalcFormat := '%g';
    SendToGrid.Options.Grouping.GroupCountFormat := '(%d)';
    SendToGrid.Options.HTMLExport.CellPadding := 10;
    SendToGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    SendToGrid.Options.Mouse.WheelScrollKeepSelection := True;
    SendToGrid.Options.Mouse.ClickMargin := 0;
    SendToGrid.Options.Mouse.ColumnSizeMargin := 6;
    SendToGrid.Options.Mouse.RowSizeMargin := 6;
    SendToGrid.Options.URL.Color := clSkyBlue;
    SendToGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    SendToGrid.Options.Selection.Mode := smDisjunctRow;
    SendToGrid.Adapter := SendToDBAdapter;
    SendToGrid.HorizontalScrollBarVisible := False;
    SendToGrid.Columns.Clear;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'View';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Name := 'Name';
      ReadOnly := True;
      Width := 76.000000000000000000;
    end;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 240.000000000000000000;
    end;
    with SendToGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 857.000000000000000000;
    end;
    SendToGrid.Fill.ColorTo := clSilver;
    SendToGrid.DefaultFont.Charset := ANSI_CHARSET;
    SendToGrid.DefaultFont.Color := clWindowText;
    SendToGrid.DefaultFont.Height := -16;
    SendToGrid.DefaultFont.Name := 'Segoe UI';
    SendToGrid.DefaultFont.Style := [];
    SendToGrid.Stroke.Color := 15987699;
    SendToGrid.Stroke.Width := 2.000000000000000000;
    SendToGrid.TopRow := 1;
    SendToGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    SendToGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    SendToGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.FixedLayout.Font.Color := clWhite;
    SendToGrid.Appearance.FixedLayout.Font.Height := -16;
    SendToGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.FixedLayout.Font.Style := [];
    SendToGrid.Appearance.NormalLayout.Fill.Color := -1;
    SendToGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    SendToGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.NormalLayout.Font.Color := clGray;
    SendToGrid.Appearance.NormalLayout.Font.Height := -16;
    SendToGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.NormalLayout.Font.Style := [];
    SendToGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    SendToGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    SendToGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    SendToGrid.Appearance.GroupLayout.Font.Color := clBlack;
    SendToGrid.Appearance.GroupLayout.Font.Height := -16;
    SendToGrid.Appearance.GroupLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.GroupLayout.Font.Style := [];
    SendToGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    SendToGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    SendToGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    SendToGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    SendToGrid.Appearance.SummaryLayout.Font.Height := -16;
    SendToGrid.Appearance.SummaryLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.SummaryLayout.Font.Style := [];
    SendToGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    SendToGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    SendToGrid.Appearance.SelectedLayout.Font.Height := -16;
    SendToGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.SelectedLayout.Font.Style := [];
    SendToGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    SendToGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    SendToGrid.Appearance.FocusedLayout.Font.Height := -16;
    SendToGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.FocusedLayout.Font.Style := [];
    SendToGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    SendToGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    SendToGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    SendToGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    SendToGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    SendToGrid.Appearance.BandLayout.Fill.Color := 14062646;
    SendToGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    SendToGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    SendToGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    SendToGrid.Appearance.BandLayout.Font.Color := clBlack;
    SendToGrid.Appearance.BandLayout.Font.Height := -16;
    SendToGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    SendToGrid.Appearance.BandLayout.Font.Style := [];
    SendToGrid.Appearance.ProgressLayout.Color := 15385233;
    SendToGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    SendToGrid.LeftCol := 1;
    SendToGrid.ScrollMode := scmItemScrolling;
    SendToGrid.GlobalFont.Scale := 1.000000000000000000;
    SendToGrid.GlobalFont.Style := [];
    SendToGrid.DesignTimeSampleData := True;
    SetEvent(SendToGrid, Self, 'OnCellClick', 'SendToGridCellClick');
    MailToExtraEdit.SetParentComponent(TMSFNCPageControl1Page2);
    MailToExtraEdit.Name := 'MailToExtraEdit';
    MailToExtraEdit.Left := 104;
    MailToExtraEdit.Top := 490;
    MailToExtraEdit.Width := 885;
    MailToExtraEdit.Height := 34;
    MailToExtraEdit.ChildOrder := 1;
    MailToExtraEdit.ElementClassName := 'MyWebEdit';
    MailToExtraEdit.Font.Charset := ANSI_CHARSET;
    MailToExtraEdit.Font.Color := clWindowText;
    MailToExtraEdit.Font.Height := -16;
    MailToExtraEdit.Font.Name := 'Segoe UI';
    MailToExtraEdit.Font.Style := [];
    MailToExtraEdit.HeightPercent := 100.000000000000000000;
    MailToExtraEdit.ParentFont := False;
    MailToExtraEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MailToExtraEdit, Self, 'OnChange', 'RecordChange');
    TMSFNCPageControl1Page1.SetParentComponent(PageControl);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 1174;
    TMSFNCPageControl1Page1.Height := 532;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    MyGrid.SetParentComponent(TMSFNCPageControl1Page1);
    MyGrid.Name := 'MyGrid';
    MyGrid.Left := 0;
    MyGrid.Top := 49;
    MyGrid.Width := 1174;
    MyGrid.Height := 483;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 0;
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 8;
    MyGrid.RowCount := 1;
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 5;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Editing.Enabled := False;
    MyGrid.Options.Filtering.Rows := frAll;
    MyGrid.Options.Filtering.DropDown := True;
    MyGrid.Options.Filtering.DropDownWidth := 240;
    MyGrid.Options.Filtering.DropDownHeight := 240;
    MyGrid.Options.Filtering.MultiColumn := True;
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ColumnSizing := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.Sorting.IgnoreCase := True;
    MyGrid.Options.Sorting.IgnoreBlanks := True;
    MyGrid.Options.Sorting.Mode := gsmNormal;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    SetEvent(MyGrid, Self, 'OnSelectedCell', 'MyGridSelectedCell');
    MyGrid.Adapter := GridDatabaseAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      SortFormat := ssNumeric;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 150.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 333.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 150.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 1;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    SetEvent(MyGrid, Self, 'OnColumnSorted', 'MyGridColumnSorted');
    TMSFNCPanel3.SetParentComponent(TMSFNCPageControl1Page1);
    TMSFNCPanel3.Name := 'TMSFNCPanel3';
    TMSFNCPanel3.Tag := 1;
    TMSFNCPanel3.AlignWithMargins := True;
    TMSFNCPanel3.Left := 3;
    TMSFNCPanel3.Top := 1;
    TMSFNCPanel3.Width := 1168;
    TMSFNCPanel3.Height := 48;
    TMSFNCPanel3.Margins.Top := 1;
    TMSFNCPanel3.Margins.Bottom := 0;
    TMSFNCPanel3.Align := alTop;
    TMSFNCPanel3.BevelInner := bvNone;
    TMSFNCPanel3.BevelOuter := bvNone;
    TMSFNCPanel3.Ctl3D := True;
    TMSFNCPanel3.ParentCtl3D := False;
    TMSFNCPanel3.ParentDoubleBuffered := False;
    TMSFNCPanel3.DoubleBuffered := True;
    TMSFNCPanel3.TabOrder := 1;
    TMSFNCPanel3.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel3.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel3.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel3.SectionsAppearance.Font.Style := [];
    TMSFNCPanel3.Stroke.Kind := gskNone;
    TMSFNCPanel3.Header.Text := 'Header';
    TMSFNCPanel3.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Header.Font.Color := clWindowText;
    TMSFNCPanel3.Header.Font.Height := -11;
    TMSFNCPanel3.Header.Font.Name := 'Tahoma';
    TMSFNCPanel3.Header.Font.Style := [];
    TMSFNCPanel3.Header.Visible := False;
    TMSFNCPanel3.Header.Fill.Color := 16380654;
    TMSFNCPanel3.Header.Stroke.Kind := gskNone;
    TMSFNCPanel3.Footer.Text := 'Footer';
    TMSFNCPanel3.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Footer.Font.Color := clWindowText;
    TMSFNCPanel3.Footer.Font.Height := -11;
    TMSFNCPanel3.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel3.Footer.Font.Style := [];
    TMSFNCPanel3.Footer.Fill.Color := 16380654;
    TMSFNCPanel3.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel3.GlobalFont.Style := [];
    ApplyToAllAssetsEdit.SetParentComponent(TMSFNCPanel3);
    ApplyToAllAssetsEdit.Name := 'ApplyToAllAssetsEdit';
    ApplyToAllAssetsEdit.AlignWithMargins := True;
    ApplyToAllAssetsEdit.Left := 20;
    ApplyToAllAssetsEdit.Top := 15;
    ApplyToAllAssetsEdit.Width := 1128;
    ApplyToAllAssetsEdit.Height := 23;
    ApplyToAllAssetsEdit.Margins.Left := 20;
    ApplyToAllAssetsEdit.Margins.Top := 15;
    ApplyToAllAssetsEdit.Margins.Right := 20;
    ApplyToAllAssetsEdit.Margins.Bottom := 10;
    ApplyToAllAssetsEdit.Align := alClient;
    ApplyToAllAssetsEdit.Caption := 'Apply to all assets';
    ApplyToAllAssetsEdit.ChildOrder := 2;
    ApplyToAllAssetsEdit.Font.Charset := ANSI_CHARSET;
    ApplyToAllAssetsEdit.Font.Color := 9010544;
    ApplyToAllAssetsEdit.Font.Height := -16;
    ApplyToAllAssetsEdit.Font.Name := 'Segoe UI';
    ApplyToAllAssetsEdit.Font.Style := [];
    ApplyToAllAssetsEdit.HeightPercent := 100.000000000000000000;
    ApplyToAllAssetsEdit.ParentFont := False;
    ApplyToAllAssetsEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ApplyToAllAssetsEdit, Self, 'OnClick', 'RecordChange');
    TMSFNCPageControl1Page0.SetParentComponent(PageControl);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 1174;
    TMSFNCPageControl1Page0.Height := 532;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    UsernameLabel.SetParentComponent(TMSFNCPageControl1Page0);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 36;
    UsernameLabel.Top := 234;
    UsernameLabel.Width := 81;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'ALERT TYPE';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 36;
    WebLabel1.Top := 21;
    WebLabel1.Width := 50;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'DUTCH';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 36;
    WebLabel2.Top := 93;
    WebLabel2.Width := 59;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'FRENCH';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 36;
    WebLabel3.Top := 159;
    WebLabel3.Width := 63;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'ENGLISH';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 556;
    WebLabel5.Top := 93;
    WebLabel5.Width := 82;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'TIME FROM';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 744;
    WebLabel6.Top := 93;
    WebLabel6.Width := 66;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'TIME TILL';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := 9010544;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 344;
    WebLabel8.Top := 234;
    WebLabel8.Width := 117;
    WebLabel8.Height := 21;
    WebLabel8.Caption := 'INTERVAL (TIME)';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := 9010544;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    AlertTypeEdit.SetParentComponent(TMSFNCPageControl1Page0);
    AlertTypeEdit.Name := 'AlertTypeEdit';
    AlertTypeEdit.AlignWithMargins := True;
    AlertTypeEdit.Left := 36;
    AlertTypeEdit.Top := 266;
    AlertTypeEdit.Width := 290;
    AlertTypeEdit.Height := 34;
    AlertTypeEdit.Margins.Left := 20;
    AlertTypeEdit.Margins.Top := 8;
    AlertTypeEdit.Margins.Bottom := 8;
    AlertTypeEdit.Color := clWhite;
    AlertTypeEdit.ElementClassName := 'myselect_white';
    AlertTypeEdit.Font.Charset := DEFAULT_CHARSET;
    AlertTypeEdit.Font.Color := clWindowText;
    AlertTypeEdit.Font.Height := 12;
    AlertTypeEdit.Font.Name := 'Segoe UI';
    AlertTypeEdit.Font.Style := [];
    AlertTypeEdit.HeightPercent := 100.000000000000000000;
    AlertTypeEdit.ParentFont := False;
    AlertTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AlertTypeEdit, Self, 'OnChange', 'AlertTypeEditChange');
    AlertTypeEdit.ItemIndex := -1;
    AlertIDNLEdit.SetParentComponent(TMSFNCPageControl1Page0);
    AlertIDNLEdit.Name := 'AlertIDNLEdit';
    AlertIDNLEdit.Left := 36;
    AlertIDNLEdit.Top := 48;
    AlertIDNLEdit.Width := 425;
    AlertIDNLEdit.Height := 34;
    AlertIDNLEdit.ChildOrder := 1;
    AlertIDNLEdit.ElementClassName := 'MyWebEdit';
    AlertIDNLEdit.Font.Charset := ANSI_CHARSET;
    AlertIDNLEdit.Font.Color := clWindowText;
    AlertIDNLEdit.Font.Height := -16;
    AlertIDNLEdit.Font.Name := 'Segoe UI';
    AlertIDNLEdit.Font.Style := [];
    AlertIDNLEdit.HeightPercent := 100.000000000000000000;
    AlertIDNLEdit.MaxLength := 60;
    AlertIDNLEdit.ParentFont := False;
    AlertIDNLEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AlertIDNLEdit, Self, 'OnChange', 'RecordChange');
    AlertIDFREdit.SetParentComponent(TMSFNCPageControl1Page0);
    AlertIDFREdit.Name := 'AlertIDFREdit';
    AlertIDFREdit.Left := 36;
    AlertIDFREdit.Top := 120;
    AlertIDFREdit.Width := 425;
    AlertIDFREdit.Height := 34;
    AlertIDFREdit.ChildOrder := 1;
    AlertIDFREdit.ElementClassName := 'MyWebEdit';
    AlertIDFREdit.Font.Charset := ANSI_CHARSET;
    AlertIDFREdit.Font.Color := clWindowText;
    AlertIDFREdit.Font.Height := -16;
    AlertIDFREdit.Font.Name := 'Segoe UI';
    AlertIDFREdit.Font.Style := [];
    AlertIDFREdit.HeightPercent := 100.000000000000000000;
    AlertIDFREdit.MaxLength := 60;
    AlertIDFREdit.ParentFont := False;
    AlertIDFREdit.WidthPercent := 100.000000000000000000;
    SetEvent(AlertIDFREdit, Self, 'OnChange', 'RecordChange');
    AlertIDENEdit.SetParentComponent(TMSFNCPageControl1Page0);
    AlertIDENEdit.Name := 'AlertIDENEdit';
    AlertIDENEdit.Left := 36;
    AlertIDENEdit.Top := 186;
    AlertIDENEdit.Width := 425;
    AlertIDENEdit.Height := 34;
    AlertIDENEdit.ChildOrder := 1;
    AlertIDENEdit.ElementClassName := 'MyWebEdit';
    AlertIDENEdit.Font.Charset := ANSI_CHARSET;
    AlertIDENEdit.Font.Color := clWindowText;
    AlertIDENEdit.Font.Height := -16;
    AlertIDENEdit.Font.Name := 'Segoe UI';
    AlertIDENEdit.Font.Style := [];
    AlertIDENEdit.HeightPercent := 100.000000000000000000;
    AlertIDENEdit.MaxLength := 60;
    AlertIDENEdit.ParentFont := False;
    AlertIDENEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AlertIDENEdit, Self, 'OnChange', 'RecordChange');
    TimeBoundEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeBoundEdit.Name := 'TimeBoundEdit';
    TimeBoundEdit.AlignWithMargins := True;
    TimeBoundEdit.Left := 540;
    TimeBoundEdit.Top := 50;
    TimeBoundEdit.Width := 323;
    TimeBoundEdit.Height := 35;
    TimeBoundEdit.Margins.Left := 40;
    TimeBoundEdit.Margins.Top := 10;
    TimeBoundEdit.Margins.Right := 40;
    TimeBoundEdit.Caption := 'TIME - BOUND';
    TimeBoundEdit.ChildOrder := 2;
    TimeBoundEdit.Color := clWhite;
    TimeBoundEdit.Font.Charset := ANSI_CHARSET;
    TimeBoundEdit.Font.Color := 9010544;
    TimeBoundEdit.Font.Height := -16;
    TimeBoundEdit.Font.Name := 'Segoe UI';
    TimeBoundEdit.Font.Style := [];
    TimeBoundEdit.HeightPercent := 100.000000000000000000;
    TimeBoundEdit.ParentFont := False;
    TimeBoundEdit.TabOrder := 2;
    TimeBoundEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeBoundEdit, Self, 'OnClick', 'TimeBoundEditClick');
    TimeFromEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeFromEdit.Name := 'TimeFromEdit';
    TimeFromEdit.Left := 556;
    TimeFromEdit.Top := 120;
    TimeFromEdit.Width := 165;
    TimeFromEdit.Height := 34;
    TimeFromEdit.ChildOrder := 1;
    TimeFromEdit.ElementClassName := 'MyWebEdit';
    TimeFromEdit.Font.Charset := ANSI_CHARSET;
    TimeFromEdit.Font.Color := clWindowText;
    TimeFromEdit.Font.Height := -16;
    TimeFromEdit.Font.Name := 'Segoe UI';
    TimeFromEdit.Font.Style := [];
    TimeFromEdit.HeightPercent := 100.000000000000000000;
    TimeFromEdit.ParentFont := False;
    TimeFromEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeFromEdit, Self, 'OnChange', 'RecordChange');
    TimeTillEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TimeTillEdit.Name := 'TimeTillEdit';
    TimeTillEdit.Left := 744;
    TimeTillEdit.Top := 120;
    TimeTillEdit.Width := 169;
    TimeTillEdit.Height := 34;
    TimeTillEdit.ChildOrder := 1;
    TimeTillEdit.ElementClassName := 'MyWebEdit';
    TimeTillEdit.Font.Charset := ANSI_CHARSET;
    TimeTillEdit.Font.Color := clWindowText;
    TimeTillEdit.Font.Height := -16;
    TimeTillEdit.Font.Name := 'Segoe UI';
    TimeTillEdit.Font.Style := [];
    TimeTillEdit.HeightPercent := 100.000000000000000000;
    TimeTillEdit.ParentFont := False;
    TimeTillEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TimeTillEdit, Self, 'OnChange', 'RecordChange');
    MondayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    MondayEdit.Name := 'MondayEdit';
    MondayEdit.AlignWithMargins := True;
    MondayEdit.Left := 556;
    MondayEdit.Top := 167;
    MondayEdit.Width := 129;
    MondayEdit.Height := 35;
    MondayEdit.Margins.Left := 40;
    MondayEdit.Margins.Top := 10;
    MondayEdit.Margins.Right := 40;
    MondayEdit.Caption := 'MONDAY';
    MondayEdit.ChildOrder := 2;
    MondayEdit.Color := clWhite;
    MondayEdit.Font.Charset := ANSI_CHARSET;
    MondayEdit.Font.Color := 9010544;
    MondayEdit.Font.Height := -16;
    MondayEdit.Font.Name := 'Segoe UI';
    MondayEdit.Font.Style := [];
    MondayEdit.HeightPercent := 100.000000000000000000;
    MondayEdit.ParentFont := False;
    MondayEdit.TabOrder := 2;
    MondayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MondayEdit, Self, 'OnClick', 'RecordChange');
    TuesdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TuesdayEdit.Name := 'TuesdayEdit';
    TuesdayEdit.AlignWithMargins := True;
    TuesdayEdit.Left := 556;
    TuesdayEdit.Top := 198;
    TuesdayEdit.Width := 129;
    TuesdayEdit.Height := 35;
    TuesdayEdit.Margins.Left := 40;
    TuesdayEdit.Margins.Top := 10;
    TuesdayEdit.Margins.Right := 40;
    TuesdayEdit.Caption := 'TUESDAY';
    TuesdayEdit.ChildOrder := 2;
    TuesdayEdit.Color := clWhite;
    TuesdayEdit.Font.Charset := ANSI_CHARSET;
    TuesdayEdit.Font.Color := 9010544;
    TuesdayEdit.Font.Height := -16;
    TuesdayEdit.Font.Name := 'Segoe UI';
    TuesdayEdit.Font.Style := [];
    TuesdayEdit.HeightPercent := 100.000000000000000000;
    TuesdayEdit.ParentFont := False;
    TuesdayEdit.TabOrder := 2;
    TuesdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TuesdayEdit, Self, 'OnClick', 'RecordChange');
    WednesdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    WednesdayEdit.Name := 'WednesdayEdit';
    WednesdayEdit.AlignWithMargins := True;
    WednesdayEdit.Left := 556;
    WednesdayEdit.Top := 228;
    WednesdayEdit.Width := 129;
    WednesdayEdit.Height := 35;
    WednesdayEdit.Margins.Left := 40;
    WednesdayEdit.Margins.Top := 10;
    WednesdayEdit.Margins.Right := 40;
    WednesdayEdit.Caption := 'WEDNESDAY';
    WednesdayEdit.ChildOrder := 2;
    WednesdayEdit.Color := clWhite;
    WednesdayEdit.Font.Charset := ANSI_CHARSET;
    WednesdayEdit.Font.Color := 9010544;
    WednesdayEdit.Font.Height := -16;
    WednesdayEdit.Font.Name := 'Segoe UI';
    WednesdayEdit.Font.Style := [];
    WednesdayEdit.HeightPercent := 100.000000000000000000;
    WednesdayEdit.ParentFont := False;
    WednesdayEdit.TabOrder := 2;
    WednesdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(WednesdayEdit, Self, 'OnClick', 'RecordChange');
    ThursdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ThursdayEdit.Name := 'ThursdayEdit';
    ThursdayEdit.AlignWithMargins := True;
    ThursdayEdit.Left := 556;
    ThursdayEdit.Top := 256;
    ThursdayEdit.Width := 129;
    ThursdayEdit.Height := 35;
    ThursdayEdit.Margins.Left := 40;
    ThursdayEdit.Margins.Top := 10;
    ThursdayEdit.Margins.Right := 40;
    ThursdayEdit.Caption := 'THURSDAY';
    ThursdayEdit.ChildOrder := 2;
    ThursdayEdit.Color := clWhite;
    ThursdayEdit.Font.Charset := ANSI_CHARSET;
    ThursdayEdit.Font.Color := 9010544;
    ThursdayEdit.Font.Height := -16;
    ThursdayEdit.Font.Name := 'Segoe UI';
    ThursdayEdit.Font.Style := [];
    ThursdayEdit.HeightPercent := 100.000000000000000000;
    ThursdayEdit.ParentFont := False;
    ThursdayEdit.TabOrder := 2;
    ThursdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ThursdayEdit, Self, 'OnClick', 'RecordChange');
    FridayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    FridayEdit.Name := 'FridayEdit';
    FridayEdit.AlignWithMargins := True;
    FridayEdit.Left := 744;
    FridayEdit.Top := 167;
    FridayEdit.Width := 129;
    FridayEdit.Height := 35;
    FridayEdit.Margins.Left := 40;
    FridayEdit.Margins.Top := 10;
    FridayEdit.Margins.Right := 40;
    FridayEdit.Caption := 'FRIDAY';
    FridayEdit.ChildOrder := 2;
    FridayEdit.Color := clWhite;
    FridayEdit.Font.Charset := ANSI_CHARSET;
    FridayEdit.Font.Color := 9010544;
    FridayEdit.Font.Height := -16;
    FridayEdit.Font.Name := 'Segoe UI';
    FridayEdit.Font.Style := [];
    FridayEdit.HeightPercent := 100.000000000000000000;
    FridayEdit.ParentFont := False;
    FridayEdit.TabOrder := 2;
    FridayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FridayEdit, Self, 'OnClick', 'RecordChange');
    SaturdayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    SaturdayEdit.Name := 'SaturdayEdit';
    SaturdayEdit.AlignWithMargins := True;
    SaturdayEdit.Left := 745;
    SaturdayEdit.Top := 198;
    SaturdayEdit.Width := 129;
    SaturdayEdit.Height := 35;
    SaturdayEdit.Margins.Left := 40;
    SaturdayEdit.Margins.Top := 10;
    SaturdayEdit.Margins.Right := 40;
    SaturdayEdit.Caption := 'SATURDAY';
    SaturdayEdit.ChildOrder := 2;
    SaturdayEdit.Color := clWhite;
    SaturdayEdit.Font.Charset := ANSI_CHARSET;
    SaturdayEdit.Font.Color := 9010544;
    SaturdayEdit.Font.Height := -16;
    SaturdayEdit.Font.Name := 'Segoe UI';
    SaturdayEdit.Font.Style := [];
    SaturdayEdit.HeightPercent := 100.000000000000000000;
    SaturdayEdit.ParentFont := False;
    SaturdayEdit.TabOrder := 2;
    SaturdayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SaturdayEdit, Self, 'OnClick', 'RecordChange');
    SundayEdit.SetParentComponent(TMSFNCPageControl1Page0);
    SundayEdit.Name := 'SundayEdit';
    SundayEdit.AlignWithMargins := True;
    SundayEdit.Left := 745;
    SundayEdit.Top := 228;
    SundayEdit.Width := 129;
    SundayEdit.Height := 35;
    SundayEdit.Margins.Left := 40;
    SundayEdit.Margins.Top := 10;
    SundayEdit.Margins.Right := 40;
    SundayEdit.Caption := 'SUNDAY';
    SundayEdit.ChildOrder := 2;
    SundayEdit.Color := clWhite;
    SundayEdit.Font.Charset := ANSI_CHARSET;
    SundayEdit.Font.Color := 9010544;
    SundayEdit.Font.Height := -16;
    SundayEdit.Font.Name := 'Segoe UI';
    SundayEdit.Font.Style := [];
    SundayEdit.HeightPercent := 100.000000000000000000;
    SundayEdit.ParentFont := False;
    SundayEdit.TabOrder := 2;
    SundayEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SundayEdit, Self, 'OnClick', 'RecordChange');
    SMSEdit.SetParentComponent(TMSFNCPageControl1Page0);
    SMSEdit.Name := 'SMSEdit';
    SMSEdit.AlignWithMargins := True;
    SMSEdit.Left := 540;
    SMSEdit.Top := 322;
    SMSEdit.Width := 81;
    SMSEdit.Height := 31;
    SMSEdit.Margins.Left := 40;
    SMSEdit.Margins.Top := 10;
    SMSEdit.Margins.Right := 40;
    SMSEdit.Caption := 'SMS';
    SMSEdit.ChildOrder := 2;
    SMSEdit.Color := clWhite;
    SMSEdit.Font.Charset := ANSI_CHARSET;
    SMSEdit.Font.Color := 9010544;
    SMSEdit.Font.Height := -16;
    SMSEdit.Font.Name := 'Segoe UI';
    SMSEdit.Font.Style := [];
    SMSEdit.HeightPercent := 100.000000000000000000;
    SMSEdit.ParentFont := False;
    SMSEdit.TabOrder := 2;
    SMSEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SMSEdit, Self, 'OnClick', 'RecordChange');
    EmailEdit.SetParentComponent(TMSFNCPageControl1Page0);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.AlignWithMargins := True;
    EmailEdit.Left := 540;
    EmailEdit.Top := 351;
    EmailEdit.Width := 81;
    EmailEdit.Height := 31;
    EmailEdit.Margins.Left := 40;
    EmailEdit.Margins.Top := 10;
    EmailEdit.Margins.Right := 40;
    EmailEdit.Caption := 'E-MAIL';
    EmailEdit.ChildOrder := 2;
    EmailEdit.Color := clWhite;
    EmailEdit.Font.Charset := ANSI_CHARSET;
    EmailEdit.Font.Color := 9010544;
    EmailEdit.Font.Height := -16;
    EmailEdit.Font.Name := 'Segoe UI';
    EmailEdit.Font.Style := [];
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.ParentFont := False;
    EmailEdit.TabOrder := 2;
    EmailEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailEdit, Self, 'OnClick', 'RecordChange');
    IntervalEdit.SetParentComponent(TMSFNCPageControl1Page0);
    IntervalEdit.Name := 'IntervalEdit';
    IntervalEdit.Left := 344;
    IntervalEdit.Top := 266;
    IntervalEdit.Width := 117;
    IntervalEdit.Height := 34;
    IntervalEdit.ChildOrder := 1;
    IntervalEdit.ElementClassName := 'myselect_white';
    IntervalEdit.Font.Charset := ANSI_CHARSET;
    IntervalEdit.Font.Color := clWindowText;
    IntervalEdit.Font.Height := -16;
    IntervalEdit.Font.Name := 'Segoe UI';
    IntervalEdit.Font.Style := [];
    IntervalEdit.HeightPercent := 100.000000000000000000;
    IntervalEdit.ParentFont := False;
    IntervalEdit.WidthPercent := 100.000000000000000000;
    SetEvent(IntervalEdit, Self, 'OnChange', 'RecordChange');
    ReportEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ReportEdit.Name := 'ReportEdit';
    ReportEdit.AlignWithMargins := True;
    ReportEdit.Left := 540;
    ReportEdit.Top := 379;
    ReportEdit.Width := 373;
    ReportEdit.Height := 31;
    ReportEdit.Margins.Left := 40;
    ReportEdit.Margins.Top := 10;
    ReportEdit.Margins.Right := 40;
    ReportEdit.Caption := 'SHOW IN REPORTS';
    ReportEdit.ChildOrder := 2;
    ReportEdit.Color := clWhite;
    ReportEdit.Font.Charset := ANSI_CHARSET;
    ReportEdit.Font.Color := 9010544;
    ReportEdit.Font.Height := -16;
    ReportEdit.Font.Name := 'Segoe UI';
    ReportEdit.Font.Style := [];
    ReportEdit.HeightPercent := 100.000000000000000000;
    ReportEdit.ParentFont := False;
    ReportEdit.TabOrder := 2;
    ReportEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ReportEdit, Self, 'OnClick', 'RecordChange');
    AlertTypePanel.SetParentComponent(TMSFNCPageControl1Page0);
    AlertTypePanel.Name := 'AlertTypePanel';
    AlertTypePanel.Tag := 1;
    AlertTypePanel.Left := 30;
    AlertTypePanel.Top := 311;
    AlertTypePanel.Width := 443;
    AlertTypePanel.Height := 194;
    AlertTypePanel.BevelInner := bvNone;
    AlertTypePanel.BevelOuter := bvNone;
    AlertTypePanel.Ctl3D := True;
    AlertTypePanel.ParentCtl3D := False;
    AlertTypePanel.ParentDoubleBuffered := False;
    AlertTypePanel.DoubleBuffered := True;
    AlertTypePanel.TabOrder := 18;
    AlertTypePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AlertTypePanel.SectionsAppearance.Font.Color := clWindowText;
    AlertTypePanel.SectionsAppearance.Font.Height := -11;
    AlertTypePanel.SectionsAppearance.Font.Name := 'Tahoma';
    AlertTypePanel.SectionsAppearance.Font.Style := [];
    AlertTypePanel.Stroke.Kind := gskNone;
    AlertTypePanel.Header.Text := 'Header';
    AlertTypePanel.Header.Font.Charset := DEFAULT_CHARSET;
    AlertTypePanel.Header.Font.Color := clWindowText;
    AlertTypePanel.Header.Font.Height := -11;
    AlertTypePanel.Header.Font.Name := 'Tahoma';
    AlertTypePanel.Header.Font.Style := [];
    AlertTypePanel.Header.Visible := False;
    AlertTypePanel.Header.Fill.Color := 16380654;
    AlertTypePanel.Header.Stroke.Kind := gskNone;
    AlertTypePanel.Footer.Text := 'Footer';
    AlertTypePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AlertTypePanel.Footer.Font.Color := clWindowText;
    AlertTypePanel.Footer.Font.Height := -11;
    AlertTypePanel.Footer.Font.Name := 'Tahoma';
    AlertTypePanel.Footer.Font.Style := [];
    AlertTypePanel.Footer.Fill.Color := 16380654;
    AlertTypePanel.GlobalFont.Scale := 1.000000000000000000;
    AlertTypePanel.GlobalFont.Style := [];
    AlertTypeSpeedPanel.SetParentComponent(AlertTypePanel);
    AlertTypeSpeedPanel.Name := 'AlertTypeSpeedPanel';
    AlertTypeSpeedPanel.Tag := 1;
    AlertTypeSpeedPanel.AlignWithMargins := True;
    AlertTypeSpeedPanel.Left := 3;
    AlertTypeSpeedPanel.Top := 3;
    AlertTypeSpeedPanel.Width := 437;
    AlertTypeSpeedPanel.Height := 188;
    AlertTypeSpeedPanel.Align := alClient;
    AlertTypeSpeedPanel.BevelInner := bvNone;
    AlertTypeSpeedPanel.BevelOuter := bvNone;
    AlertTypeSpeedPanel.Ctl3D := True;
    AlertTypeSpeedPanel.ParentCtl3D := False;
    AlertTypeSpeedPanel.ParentDoubleBuffered := False;
    AlertTypeSpeedPanel.DoubleBuffered := True;
    AlertTypeSpeedPanel.TabOrder := 0;
    AlertTypeSpeedPanel.Visible := False;
    AlertTypeSpeedPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AlertTypeSpeedPanel.SectionsAppearance.Font.Color := clWindowText;
    AlertTypeSpeedPanel.SectionsAppearance.Font.Height := -11;
    AlertTypeSpeedPanel.SectionsAppearance.Font.Name := 'Tahoma';
    AlertTypeSpeedPanel.SectionsAppearance.Font.Style := [];
    AlertTypeSpeedPanel.Stroke.Kind := gskNone;
    AlertTypeSpeedPanel.Header.Text := 'Header';
    AlertTypeSpeedPanel.Header.Font.Charset := DEFAULT_CHARSET;
    AlertTypeSpeedPanel.Header.Font.Color := clWindowText;
    AlertTypeSpeedPanel.Header.Font.Height := -11;
    AlertTypeSpeedPanel.Header.Font.Name := 'Tahoma';
    AlertTypeSpeedPanel.Header.Font.Style := [];
    AlertTypeSpeedPanel.Header.Visible := False;
    AlertTypeSpeedPanel.Header.Fill.Color := 16380654;
    AlertTypeSpeedPanel.Header.Stroke.Kind := gskNone;
    AlertTypeSpeedPanel.Footer.Text := 'Footer';
    AlertTypeSpeedPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AlertTypeSpeedPanel.Footer.Font.Color := clWindowText;
    AlertTypeSpeedPanel.Footer.Font.Height := -11;
    AlertTypeSpeedPanel.Footer.Font.Name := 'Tahoma';
    AlertTypeSpeedPanel.Footer.Font.Style := [];
    AlertTypeSpeedPanel.Footer.Fill.Color := 16380654;
    AlertTypeSpeedPanel.GlobalFont.Scale := 1.000000000000000000;
    AlertTypeSpeedPanel.GlobalFont.Style := [];
    WebLabel4.SetParentComponent(AlertTypeSpeedPanel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 3;
    WebLabel4.Top := 0;
    WebLabel4.Width := 99;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'SPEED (KM/H)';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(AlertTypeSpeedPanel);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 120;
    WebLabel7.Top := 0;
    WebLabel7.Width := 202;
    WebLabel7.Height := 21;
    WebLabel7.Caption := 'MIN.  ROUTE DISTANCE (KM)';
    WebLabel7.Font.Charset := ANSI_CHARSET;
    WebLabel7.Font.Color := 9010544;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Segoe UI';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    SpeedEdit.SetParentComponent(AlertTypeSpeedPanel);
    SpeedEdit.Name := 'SpeedEdit';
    SpeedEdit.Left := 3;
    SpeedEdit.Top := 31;
    SpeedEdit.Width := 99;
    SpeedEdit.Height := 34;
    SpeedEdit.ChildOrder := 1;
    SpeedEdit.EditType := weNumeric;
    SpeedEdit.ElementClassName := 'myselect_white';
    SpeedEdit.Font.Charset := ANSI_CHARSET;
    SpeedEdit.Font.Color := clWindowText;
    SpeedEdit.Font.Height := -16;
    SpeedEdit.Font.Name := 'Segoe UI';
    SpeedEdit.Font.Style := [];
    SpeedEdit.HeightPercent := 100.000000000000000000;
    SpeedEdit.ParentFont := False;
    SpeedEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SpeedEdit, Self, 'OnChange', 'RecordChange');
    DistanceEdit.SetParentComponent(AlertTypeSpeedPanel);
    DistanceEdit.Name := 'DistanceEdit';
    DistanceEdit.Left := 120;
    DistanceEdit.Top := 31;
    DistanceEdit.Width := 112;
    DistanceEdit.Height := 34;
    DistanceEdit.ChildOrder := 1;
    DistanceEdit.EditType := weFloat;
    DistanceEdit.ElementClassName := 'myselect_white';
    DistanceEdit.Font.Charset := ANSI_CHARSET;
    DistanceEdit.Font.Color := clWindowText;
    DistanceEdit.Font.Height := -16;
    DistanceEdit.Font.Name := 'Segoe UI';
    DistanceEdit.Font.Style := [];
    DistanceEdit.HeightPercent := 100.000000000000000000;
    DistanceEdit.ParentFont := False;
    DistanceEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DistanceEdit, Self, 'OnChange', 'RecordChange');
    AlertTypeGeofencePanel.SetParentComponent(AlertTypePanel);
    AlertTypeGeofencePanel.Name := 'AlertTypeGeofencePanel';
    AlertTypeGeofencePanel.Tag := 1;
    AlertTypeGeofencePanel.AlignWithMargins := True;
    AlertTypeGeofencePanel.Left := 3;
    AlertTypeGeofencePanel.Top := 3;
    AlertTypeGeofencePanel.Width := 437;
    AlertTypeGeofencePanel.Height := 188;
    AlertTypeGeofencePanel.Align := alClient;
    AlertTypeGeofencePanel.BevelInner := bvNone;
    AlertTypeGeofencePanel.BevelOuter := bvNone;
    AlertTypeGeofencePanel.Ctl3D := True;
    AlertTypeGeofencePanel.ParentCtl3D := False;
    AlertTypeGeofencePanel.ParentDoubleBuffered := False;
    AlertTypeGeofencePanel.DoubleBuffered := True;
    AlertTypeGeofencePanel.TabOrder := 1;
    AlertTypeGeofencePanel.Visible := False;
    AlertTypeGeofencePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    AlertTypeGeofencePanel.SectionsAppearance.Font.Color := clWindowText;
    AlertTypeGeofencePanel.SectionsAppearance.Font.Height := -11;
    AlertTypeGeofencePanel.SectionsAppearance.Font.Name := 'Tahoma';
    AlertTypeGeofencePanel.SectionsAppearance.Font.Style := [];
    AlertTypeGeofencePanel.Stroke.Kind := gskNone;
    AlertTypeGeofencePanel.Header.Text := 'Header';
    AlertTypeGeofencePanel.Header.Font.Charset := DEFAULT_CHARSET;
    AlertTypeGeofencePanel.Header.Font.Color := clWindowText;
    AlertTypeGeofencePanel.Header.Font.Height := -11;
    AlertTypeGeofencePanel.Header.Font.Name := 'Tahoma';
    AlertTypeGeofencePanel.Header.Font.Style := [];
    AlertTypeGeofencePanel.Header.Visible := False;
    AlertTypeGeofencePanel.Header.Fill.Color := 16380654;
    AlertTypeGeofencePanel.Header.Stroke.Kind := gskNone;
    AlertTypeGeofencePanel.Footer.Text := 'Footer';
    AlertTypeGeofencePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    AlertTypeGeofencePanel.Footer.Font.Color := clWindowText;
    AlertTypeGeofencePanel.Footer.Font.Height := -11;
    AlertTypeGeofencePanel.Footer.Font.Name := 'Tahoma';
    AlertTypeGeofencePanel.Footer.Font.Style := [];
    AlertTypeGeofencePanel.Footer.Fill.Color := 16380654;
    AlertTypeGeofencePanel.GlobalFont.Scale := 1.000000000000000000;
    AlertTypeGeofencePanel.GlobalFont.Style := [];
    WebLabel10.SetParentComponent(AlertTypeGeofencePanel);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 3;
    WebLabel10.Top := 77;
    WebLabel10.Width := 142;
    WebLabel10.Height := 21;
    WebLabel10.Caption := 'ENTER / EXIT / BOTH';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := 9010544;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(AlertTypeGeofencePanel);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 3;
    WebLabel9.Top := 0;
    WebLabel9.Width := 77;
    WebLabel9.Height := 21;
    WebLabel9.Caption := 'GEOFENCE';
    WebLabel9.Font.Charset := ANSI_CHARSET;
    WebLabel9.Font.Color := 9010544;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Segoe UI';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    GeofenceTypeEdit.SetParentComponent(AlertTypeGeofencePanel);
    GeofenceTypeEdit.Name := 'GeofenceTypeEdit';
    GeofenceTypeEdit.AlignWithMargins := True;
    GeofenceTypeEdit.Left := 3;
    GeofenceTypeEdit.Top := 109;
    GeofenceTypeEdit.Width := 217;
    GeofenceTypeEdit.Height := 34;
    GeofenceTypeEdit.Margins.Left := 20;
    GeofenceTypeEdit.Margins.Top := 8;
    GeofenceTypeEdit.Margins.Bottom := 8;
    GeofenceTypeEdit.Color := clWhite;
    GeofenceTypeEdit.ElementClassName := 'myselect_white';
    GeofenceTypeEdit.Font.Charset := DEFAULT_CHARSET;
    GeofenceTypeEdit.Font.Color := clWindowText;
    GeofenceTypeEdit.Font.Height := 12;
    GeofenceTypeEdit.Font.Name := 'Segoe UI';
    GeofenceTypeEdit.Font.Style := [];
    GeofenceTypeEdit.HeightPercent := 100.000000000000000000;
    GeofenceTypeEdit.ParentFont := False;
    GeofenceTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(GeofenceTypeEdit, Self, 'OnChange', 'RecordChange');
    GeofenceTypeEdit.ItemIndex := -1;
    GeofenceTypeEdit.LookupValues.Clear;
    with GeofenceTypeEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'N/A';
    end;
    with GeofenceTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'ENTER';
    end;
    with GeofenceTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'EXIT';
    end;
    with GeofenceTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'BOTH';
    end;
    GeofenceListEdit.SetParentComponent(AlertTypeGeofencePanel);
    GeofenceListEdit.Name := 'GeofenceListEdit';
    GeofenceListEdit.AlignWithMargins := True;
    GeofenceListEdit.Left := 3;
    GeofenceListEdit.Top := 32;
    GeofenceListEdit.Width := 425;
    GeofenceListEdit.Height := 34;
    GeofenceListEdit.Margins.Left := 20;
    GeofenceListEdit.Margins.Top := 8;
    GeofenceListEdit.Margins.Bottom := 8;
    GeofenceListEdit.Color := clWhite;
    GeofenceListEdit.ElementClassName := 'myselect_white';
    GeofenceListEdit.Font.Charset := DEFAULT_CHARSET;
    GeofenceListEdit.Font.Color := clWindowText;
    GeofenceListEdit.Font.Height := 12;
    GeofenceListEdit.Font.Name := 'Segoe UI';
    GeofenceListEdit.Font.Style := [];
    GeofenceListEdit.HeightPercent := 100.000000000000000000;
    GeofenceListEdit.ParentFont := False;
    GeofenceListEdit.WidthPercent := 100.000000000000000000;
    SetEvent(GeofenceListEdit, Self, 'OnChange', 'AlertTypeEditChange');
    GeofenceListEdit.ItemIndex := -1;
    CriticalEdit.SetParentComponent(TMSFNCPageControl1Page0);
    CriticalEdit.Name := 'CriticalEdit';
    CriticalEdit.AlignWithMargins := True;
    CriticalEdit.Left := 540;
    CriticalEdit.Top := 423;
    CriticalEdit.Width := 323;
    CriticalEdit.Height := 35;
    CriticalEdit.Margins.Left := 40;
    CriticalEdit.Margins.Top := 10;
    CriticalEdit.Margins.Right := 40;
    CriticalEdit.Caption := 'CRITICAL';
    CriticalEdit.ChildOrder := 2;
    CriticalEdit.Color := clWhite;
    CriticalEdit.Font.Charset := ANSI_CHARSET;
    CriticalEdit.Font.Color := 9010544;
    CriticalEdit.Font.Height := -16;
    CriticalEdit.Font.Name := 'Segoe UI';
    CriticalEdit.Font.Style := [];
    CriticalEdit.HeightPercent := 100.000000000000000000;
    CriticalEdit.ParentFont := False;
    CriticalEdit.TabOrder := 2;
    CriticalEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CriticalEdit, Self, 'OnClick', 'TimeBoundEditClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1200;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 77;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Alert - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1171;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAlertEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 513;
    WebWaitMessage.Top := 58;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAlertEdit.WebWaitMessage.Picture.gif');
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 117;
    GridDatabaseAdapter.Top := 246;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := MyGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Symbol';
      Header := '  No.';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  Asset ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Group';
      Header := '  Asset Group';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Type';
      Header := '  Type';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Brand';
      Header := '  Brand';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Model';
      Header := '  Model';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Origin';
      Header := '  Origin';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 442;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    SendToDBAdapter.SetParentComponent(Self);
    SendToDBAdapter.Name := 'SendToDBAdapter';
    SendToDBAdapter.Left := 117;
    SendToDBAdapter.Top := 302;
    SendToDBAdapter.Width := 26;
    SendToDBAdapter.Height := 26;
    SendToDBAdapter.Margins.Left := 12;
    SendToDBAdapter.Visible := True;
    SendToDBAdapter.Grid := SendToGrid;
    SendToDBAdapter.DataSource := SendToDataSource;
    SendToDBAdapter.Columns.Clear;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'Checked';
      Header := '  Send To';
      CheckBoxField := True;
      CheckFalse := 'f';
      CheckTrue := 't';
    end;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'Asset Group';
      Header := '  Asset Group';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with SendToDBAdapter.Columns.Add do
    begin
      FieldName := 'Email';
      Header := '  Email';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    SendToDBAdapter.AutoCreateColumns := False;
    SendToDBAdapter.AutoRemoveColumns := False;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 593;
    ShowTimer.Top := 58;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 211;
    DatasetTimer.Top := 184;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 212;
    MyDataset.Top := 248;
    MyDatasetPKey.SetParentComponent(MyDataset);
    MyDatasetPKey.Name := 'MyDatasetPKey';
    MyDatasetPKey.FieldName := 'PKey';
    MyDatasetPKey.Size := 256;
    MyDatasetSymbol.SetParentComponent(MyDataset);
    MyDatasetSymbol.Name := 'MyDatasetSymbol';
    MyDatasetSymbol.FieldName := 'Symbol';
    MyDatasetSymbol.Size := 256;
    MyDatasetID.SetParentComponent(MyDataset);
    MyDatasetID.Name := 'MyDatasetID';
    MyDatasetID.FieldName := 'ID';
    MyDatasetID.Size := 256;
    MyDatasetBrand.SetParentComponent(MyDataset);
    MyDatasetBrand.Name := 'MyDatasetBrand';
    MyDatasetBrand.FieldName := 'Brand';
    MyDatasetBrand.Size := 256;
    MyDatasetModel.SetParentComponent(MyDataset);
    MyDatasetModel.Name := 'MyDatasetModel';
    MyDatasetModel.FieldName := 'Model';
    MyDatasetModel.Size := 256;
    MyDatasetGroup.SetParentComponent(MyDataset);
    MyDatasetGroup.Name := 'MyDatasetGroup';
    MyDatasetGroup.FieldName := 'Group';
    MyDatasetGroup.Size := 256;
    MyDatasetType.SetParentComponent(MyDataset);
    MyDatasetType.Name := 'MyDatasetType';
    MyDatasetType.FieldName := 'Type';
    MyDatasetType.Size := 256;
    MyDatasetOrigin.SetParentComponent(MyDataset);
    MyDatasetOrigin.Name := 'MyDatasetOrigin';
    MyDatasetOrigin.FieldName := 'Origin';
    MyDatasetOrigin.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 308;
    MyDataSource.Top := 248;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.URI := 'https://instatrack.eu:44321/php/default_alert_asset_list.php?sessionid=72991a966696c1274a6d1b30a6dcb752&pkey=19';
    WebConnection.Left := 114;
    WebConnection.Top := 181;
    SendToDataSet.SetParentComponent(Self);
    SendToDataSet.Name := 'SendToDataSet';
    SendToDataSet.AfterOpen := SendToDataSetAfterOpen;
    SendToDataSet.BeforeEdit := SendToDataSetBeforeEdit;
    SendToDataSet.AfterPost := SendToDataSetAfterPost;
    SendToDataSet.Left := 212;
    SendToDataSet.Top := 304;
    SendToDataSetPKey.SetParentComponent(SendToDataSet);
    SendToDataSetPKey.Name := 'SendToDataSetPKey';
    SendToDataSetPKey.FieldName := 'PKey';
    SendToDataSetPKey.Size := 0;
    SendToDataSetChecked.SetParentComponent(SendToDataSet);
    SendToDataSetChecked.Name := 'SendToDataSetChecked';
    SendToDataSetChecked.FieldName := 'Checked';
    SendToDataSetChecked.Size := 40;
    SendToDataSetEmail.SetParentComponent(SendToDataSet);
    SendToDataSetEmail.Name := 'SendToDataSetEmail';
    SendToDataSetEmail.FieldName := 'Email';
    SendToDataSetEmail.ReadOnly := True;
    SendToDataSetEmail.Size := 256;
    SendToDataSetAssetGroup.SetParentComponent(SendToDataSet);
    SendToDataSetAssetGroup.Name := 'SendToDataSetAssetGroup';
    SendToDataSetAssetGroup.FieldName := 'Asset Group';
    SendToDataSetAssetGroup.ReadOnly := True;
    SendToDataSetAssetGroup.Size := 256;
    SendToDataSource.SetParentComponent(Self);
    SendToDataSource.Name := 'SendToDataSource';
    SendToDataSource.DataSet := SendToDataSet;
    SendToDataSource.Left := 308;
    SendToDataSource.Top := 304;
    SendToTimer.SetParentComponent(Self);
    SendToTimer.Name := 'SendToTimer';
    SendToTimer.Enabled := False;
    SendToTimer.Interval := 100;
    SetEvent(SendToTimer, Self, 'OnTimer', 'SendToTimerTimer');
    SendToTimer.Left := 403;
    SendToTimer.Top := 304;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    DeselectButton.AfterLoadDFMValues;
    PageControl.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    SendToGrid.AfterLoadDFMValues;
    MailToExtraEdit.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    TMSFNCPanel3.AfterLoadDFMValues;
    ApplyToAllAssetsEdit.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    AlertTypeEdit.AfterLoadDFMValues;
    AlertIDNLEdit.AfterLoadDFMValues;
    AlertIDFREdit.AfterLoadDFMValues;
    AlertIDENEdit.AfterLoadDFMValues;
    TimeBoundEdit.AfterLoadDFMValues;
    TimeFromEdit.AfterLoadDFMValues;
    TimeTillEdit.AfterLoadDFMValues;
    MondayEdit.AfterLoadDFMValues;
    TuesdayEdit.AfterLoadDFMValues;
    WednesdayEdit.AfterLoadDFMValues;
    ThursdayEdit.AfterLoadDFMValues;
    FridayEdit.AfterLoadDFMValues;
    SaturdayEdit.AfterLoadDFMValues;
    SundayEdit.AfterLoadDFMValues;
    SMSEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    IntervalEdit.AfterLoadDFMValues;
    ReportEdit.AfterLoadDFMValues;
    AlertTypePanel.AfterLoadDFMValues;
    AlertTypeSpeedPanel.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    SpeedEdit.AfterLoadDFMValues;
    DistanceEdit.AfterLoadDFMValues;
    AlertTypeGeofencePanel.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    GeofenceTypeEdit.AfterLoadDFMValues;
    GeofenceListEdit.AfterLoadDFMValues;
    CriticalEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    SendToDBAdapter.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetPKey.AfterLoadDFMValues;
    MyDatasetSymbol.AfterLoadDFMValues;
    MyDatasetID.AfterLoadDFMValues;
    MyDatasetBrand.AfterLoadDFMValues;
    MyDatasetModel.AfterLoadDFMValues;
    MyDatasetGroup.AfterLoadDFMValues;
    MyDatasetType.AfterLoadDFMValues;
    MyDatasetOrigin.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
    SendToDataSet.AfterLoadDFMValues;
    SendToDataSetPKey.AfterLoadDFMValues;
    SendToDataSetChecked.AfterLoadDFMValues;
    SendToDataSetEmail.AfterLoadDFMValues;
    SendToDataSetAssetGroup.AfterLoadDFMValues;
    SendToDataSource.AfterLoadDFMValues;
    SendToTimer.AfterLoadDFMValues;
  end;
end;

end.

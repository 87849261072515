unit uPOIImport;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.WebCtrls, VCL.TMSFNCTypes,
  WEBLIB.REST, WEBLIB.JSON,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, WEBLib.Buttons;

type
  TPOIImportForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    UsernameLabel: TLabel;
    POITypeEdit: TLookupComboBox;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    WebLabel1: TLabel;
    POIGroupEdit: TLookupComboBox;
    FileNameEdit: TEdit;
    OpenFileButton: TSpeedButton;
    MyOpenFileDialog: TOpenDialog;
    WebLabel2: TLabel;
    procedure SaveButtonClick(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    procedure MyOpenFileDialogChange(Sender: TObject);
    procedure MyOpenFileDialogGetFileAsBase64(Sender: TObject;
      AFileIndex: Integer; ABase64: string);
    procedure OpenFileButtonClick(Sender: TObject);
  private
    { Private declarations }
    FileBase64: String;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadPOITypeList(S: String);
    procedure LoadPOIGroupList(S: String);
    [async]
    procedure UploadFile(); async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  POIImportForm: TPOIImportForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TPOIImportForm.WebFormCreate(Sender: TObject);
begin
  FileBase64 := EmptyStr;
end;

procedure TPOIImportForm.WebFormShow(Sender: TObject);
begin
  // Load Lookup Values
  await(LoadLookupValues());
end;

procedure TPOIImportForm.LoadLookupValues;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_poi_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadPOITypeList(TJSONPair(MyJSONObject.Get('poi_type')).JsonValue.ToString);
    LoadPOIGroupList(TJSONPair(MyJSONObject.Get('poi_group')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TPOIImportForm.LoadPOITypeList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  POITypeEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      POITypeEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('pkey'),
        MyJSONObject.GetJSONValue('id')
      );
    end;
    // Set Value
    POITypeEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TPOIImportForm.MyOpenFileDialogChange(Sender: TObject);
begin
  FileNameEdit.Text := MyOpenFileDialog.Files[0].Name;
  MyOpenFileDialog.Files[0].GetFileAsBase64;
end;

procedure TPOIImportForm.MyOpenFileDialogGetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
begin
  FileBase64 := ABase64;
end;

procedure TPOIImportForm.OpenFileButtonClick(Sender: TObject);
begin
  MyOpenFileDialog.Execute;
end;

procedure TPOIImportForm.LoadPOIGroupList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  POIGroupEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      POIGroupEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('pkey'),
        MyJSONObject.GetJSONValue('id')
      );
    end;
    // Set Value
    POIGroupEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TPOIImportForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TPOIImportForm.SaveButtonClick(Sender: TObject);
begin
  if FileBase64 <> EmptyStr then begin
    UploadFile();
  end else begin
    MessageDlg('No file to import', TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TPOIImportForm.UploadFile();
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"type": "' + POITypeEdit.Value + '",' +
      '"group": "' + POIGroupEdit.Value + '",' +
      '"file": "' + FileBase64 + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_poi_import.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        ModalResult := mrOK;
      end else begin
        MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TPOIImportForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  POITypeEdit := TLookupComboBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  OpenFileButton := TSpeedButton.Create(Self);
  POIGroupEdit := TLookupComboBox.Create(Self);
  FileNameEdit := TEdit.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyOpenFileDialog := TOpenDialog.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  POITypeEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  OpenFileButton.BeforeLoadDFMValues;
  POIGroupEdit.BeforeLoadDFMValues;
  FileNameEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyOpenFileDialog.BeforeLoadDFMValues;
  try
    Name := 'POIImportForm';
    Width := 467;
    Height := 387;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 467;
    FormPanel.Height := 387;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 461;
    ContentPanel.Height := 344;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    UsernameLabel.SetParentComponent(ContentPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 32;
    UsernameLabel.Top := 16;
    UsernameLabel.Width := 34;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'TYPE';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 32;
    WebLabel1.Top := 93;
    WebLabel1.Width := 53;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'GROUP';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 32;
    WebLabel2.Top := 181;
    WebLabel2.Width := 28;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'FILE';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    POITypeEdit.SetParentComponent(ContentPanel);
    POITypeEdit.Name := 'POITypeEdit';
    POITypeEdit.AlignWithMargins := True;
    POITypeEdit.Left := 32;
    POITypeEdit.Top := 48;
    POITypeEdit.Width := 397;
    POITypeEdit.Height := 34;
    POITypeEdit.Margins.Left := 20;
    POITypeEdit.Margins.Top := 8;
    POITypeEdit.Margins.Bottom := 8;
    POITypeEdit.Color := clWhite;
    POITypeEdit.ElementClassName := 'myselect_white';
    POITypeEdit.Font.Charset := DEFAULT_CHARSET;
    POITypeEdit.Font.Color := clWindowText;
    POITypeEdit.Font.Height := 12;
    POITypeEdit.Font.Name := 'Segoe UI';
    POITypeEdit.Font.Style := [];
    POITypeEdit.HeightPercent := 100.000000000000000000;
    POITypeEdit.ParentFont := False;
    POITypeEdit.WidthPercent := 100.000000000000000000;
    POITypeEdit.ItemIndex := -1;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 295;
    StatusBarPanel.Width := 461;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 341;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uPOIImport.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 226;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Upload';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uPOIImport.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 2;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    OpenFileButton.SetParentComponent(StatusBarPanel);
    OpenFileButton.Name := 'OpenFileButton';
    OpenFileButton.AlignWithMargins := True;
    OpenFileButton.Left := 32;
    OpenFileButton.Top := 8;
    OpenFileButton.Width := 127;
    OpenFileButton.Height := 31;
    OpenFileButton.Margins.Left := 20;
    OpenFileButton.Margins.Top := 8;
    OpenFileButton.Margins.Right := 5;
    OpenFileButton.Margins.Bottom := 10;
    OpenFileButton.Caption := 'Choose &File';
    OpenFileButton.Color := clNone;
    OpenFileButton.ElementClassName := 'MyWebButton';
    OpenFileButton.HeightPercent := 100.000000000000000000;
    OpenFileButton.TabOrder := 3;
    OpenFileButton.WidthPercent := 100.000000000000000000;
    SetEvent(OpenFileButton, Self, 'OnClick', 'OpenFileButtonClick');
    POIGroupEdit.SetParentComponent(ContentPanel);
    POIGroupEdit.Name := 'POIGroupEdit';
    POIGroupEdit.AlignWithMargins := True;
    POIGroupEdit.Left := 32;
    POIGroupEdit.Top := 125;
    POIGroupEdit.Width := 397;
    POIGroupEdit.Height := 34;
    POIGroupEdit.Margins.Left := 20;
    POIGroupEdit.Margins.Top := 8;
    POIGroupEdit.Margins.Bottom := 8;
    POIGroupEdit.Color := clWhite;
    POIGroupEdit.ElementClassName := 'myselect_white';
    POIGroupEdit.Font.Charset := DEFAULT_CHARSET;
    POIGroupEdit.Font.Color := clWindowText;
    POIGroupEdit.Font.Height := 12;
    POIGroupEdit.Font.Name := 'Segoe UI';
    POIGroupEdit.Font.Style := [];
    POIGroupEdit.HeightPercent := 100.000000000000000000;
    POIGroupEdit.ParentFont := False;
    POIGroupEdit.WidthPercent := 100.000000000000000000;
    POIGroupEdit.ItemIndex := -1;
    FileNameEdit.SetParentComponent(ContentPanel);
    FileNameEdit.Name := 'FileNameEdit';
    FileNameEdit.Left := 32;
    FileNameEdit.Top := 215;
    FileNameEdit.Width := 396;
    FileNameEdit.Height := 34;
    FileNameEdit.ChildOrder := 1;
    FileNameEdit.ElementClassName := 'MyWebEdit';
    FileNameEdit.Font.Charset := ANSI_CHARSET;
    FileNameEdit.Font.Color := clWindowText;
    FileNameEdit.Font.Height := -16;
    FileNameEdit.Font.Name := 'Segoe UI';
    FileNameEdit.Font.Style := [];
    FileNameEdit.HeightPercent := 100.000000000000000000;
    FileNameEdit.ParentFont := False;
    FileNameEdit.ReadOnly := True;
    FileNameEdit.WidthPercent := 100.000000000000000000;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 467;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 182;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Point Of Interest - Import';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 438;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uPOIImport.CaptionPanel.CloseImage.Picture.png');
    MyOpenFileDialog.SetParentComponent(Self);
    MyOpenFileDialog.Name := 'MyOpenFileDialog';
    MyOpenFileDialog.Accept := '.csv';
    SetEvent(MyOpenFileDialog, Self, 'OnChange', 'MyOpenFileDialogChange');
    SetEvent(MyOpenFileDialog, Self, 'OnGetFileAsBase64', 'MyOpenFileDialogGetFileAsBase64');
    MyOpenFileDialog.Left := 129;
    MyOpenFileDialog.Top := 58;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    POITypeEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    OpenFileButton.AfterLoadDFMValues;
    POIGroupEdit.AfterLoadDFMValues;
    FileNameEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyOpenFileDialog.AfterLoadDFMValues;
  end;
end;

end.

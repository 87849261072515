{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2020 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGoogleMaps;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Forms
  {$IFNDEF LCLWEBLIB}
  ,Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Contnrs
  {$ENDIF}
  ,WEBLib.TMSFNCMapsCommonTypes
  ,WEBLib.TMSFNCWebBrowser
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCGraphics
  ,WEBLib.TMSFNCGraphicsTypes
  ,WEBLib.TMSFNCJSONReader
  ,WEBLib.TMSFNCMaps;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 7; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0: First release
  //v1.0.0.1: Improved : property LocalFileAccess to provide access to local files such as images for markers (HERE Maps not supported)
  //v1.0.1.0: New : GetZoomLevel, GetCenterCoordinate with OnGetZoomLevel and OnGetCenterCoordinate asynchronous events
  //v1.0.2.0: New : OnClusterClick, OnClusterMouseEnter, OnClusterMouseLeave events
  //        : New : Clusters.ImagePath property
  //v1.0.3.0: New : Clusters.Text property
  //v1.0.4.0: New : ZIndex property on markers and poly elements
  //        : New : Options.BackgroundColor
  //        : New : Anchor property to control positioning of custom marker image relative to the coordinate
  //        : Improved : Cluster updating
  //v1.0.4.1: Fixed : Issue introducing Anchor property did generate the wrong default offset, now DefaultAnchor (true by default), can be used to switch to custom anchor position
  //v1.1.0.0: New : Options.StreetView property
  //        : New : OnStreetViewChange event
  //v1.2.0.0: New : AddDirections, ClearDirections added
  //        : New : OnRetrievedDirectionsData event
  //        : Improved : OnMapTypeChange now returns the selected MapType
  //        : Improved : When the MapTypeID is changed on the map, the Options.MapTypeID property is updated
  //v1.2.1.0: New : Polyline Geodesic support added
  //v1.3.0.0: New : Overlayview support added
  //v1.3.0.1: Fixed : Fixed issue with removing multiple OverlayViews
  //v1.3.0.2: Improved : OverlayView performance
  //v1.4.0.0: New : Map Options: Tilt and Heading (map rotation) support
  //        : New : Map Options: MapID
  //        : Fixed : Default Cluster ImagePath updated
  //v1.5.0.0: New : OnMapRightClick, OnMarkerRightClick, OnPolyElementRightClick events
  //        : New : DefaultIconSize, IconWidth, IconHeight properties in TTMSFNCGoogleMapsMarker
  //        : Fixed : Issue with offset left & top in TTMSFNCGoogleMaps in combination with OverlayViews
  //v1.6.0.0: New : Polyline Symbols support
  //v1.6.1.0: Improved : Marker OverlayView is automatically hidden when Marker is inside a Marker Cluster
  //v1.6.1.1: Fixed : OnRetrievedDirectionsData now includes ADirectionsData.Routes.Legs.OriginCoordinate & DestinationCoordinate
  //v1.6.2.0: New : Options.ShowScaleControl property added
  //        : New : Options.DisablePOI property added
  //v1.6.3.0: New : Options.Version property added to select the Google Maps JS API version
  //        : Fixed : Issue with Tilt & Heading functions
  //v1.6.4.0: New : Options.ShowRotateControl property added
  //v1.6.4.1: Fixed : Issue with OnRetrievedDirectionsData ADirectionsData.Routes[].Path value
  //v1.6.5.0: New : Options.ShowKeyboardShortcuts property added
  //v1.6.5.1: Fixed : Issue with designtime OverlayViews not picked up
  //        : Fixed : Issue with OverlayViews consuming events on Android
  //v1.7.0.0: New : Complex Polygons support added
  //v1.7.1.0: New : OnKMLLayerClick event added
  //        : New : OnStreetViewEnabledChange event added

  MAPSTYLEDEFAULT = '[]';

  MAPSTYLEDISABLEPOI =
    ' [' + LB +
    '     {' + LB +
    '         featureType: "poi",' + LB +
    '         elementType: "labels",' + LB +
    '         stylers: [' + LB +
    '               { visibility: "off" }' + LB +
    '         ]' + LB +
    '     }' + LB +
    ' ]';

  MAPSTYLENIGHT = '[' + LB +
    '{' + LB +
    '  "elementType": "geometry",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#242f3e"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#746855"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "elementType": "labels.text.stroke",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#242f3e"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "administrative.locality",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#d59563"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "poi",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#d59563"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "poi.park",' + LB +
    '  "elementType": "geometry",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#263c3f"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "poi.park",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#6b9a76"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "road",' + LB +
    '  "elementType": "geometry",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#38414e"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "road",' + LB +
    '  "elementType": "geometry.stroke",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#212a37"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "road",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#9ca5b3"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "road.highway",' + LB +
    '  "elementType": "geometry",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#746855"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "road.highway",' + LB +
    '  "elementType": "geometry.stroke",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#1f2835"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "road.highway",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#f3d19c"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "transit",' + LB +
    '  "elementType": "geometry",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#2f3948"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "transit.station",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#d59563"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "water",' + LB +
    '  "elementType": "geometry",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#17263c"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "water",' + LB +
    '  "elementType": "labels.text.fill",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#515c6d"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '},' + LB +
    '{' + LB +
    '  "featureType": "water",' + LB +
    '  "elementType": "labels.text.stroke",' + LB +
    '  "stylers": [' + LB +
    '    {' + LB +
    '      "color": "#17263c"' + LB +
    '    }' + LB +
    '  ]' + LB +
    '}' + LB +
    ']';

type
  TTMSFNCGoogleMapsMapTypeID = (gmtDefault, gmtSatellite, gmtHybrid, gmtTerrain);
  TTMSFNCGoogleMapsDirectionsTravelMode = (dtmDriving, dtmWalking, dtmBicycling, dtmPublicTransport);
  TTMSFNCGoogleMapsCoordinatePosition = (cpTopLeft, cpTopCenter, cpTopRight, cpBottomLeft, cpBottomCenter, cpBottomRight, cpCenterLeft, cpCenterCenter, cpCenterRight, cpCustom);
  TTMSFNCGoogleMapsOverlayViewMode = (omBounds, omCoordinate);
  TTMSFNCGoogleMapsPolylineSymbolPath = (spBackwardClosedArrow, spBackwardOpenArrow, spCircle, spForwardClosedArrow, spForwardOpenArrow, spCustom);
  TTMSFNCGoogleMapsPolylineSymbolUnits = (unPercentage, unPixels);

  TTMSFNCCustomGoogleMaps = class;
  TTMSFNCGoogleMapsPolyline = class;
  TTMSFNCGoogleMapsCluster = class;
  TTMSFNCGoogleMapsOverlayView = class;
  TTMSFNCGoogleMapsStreetViewClass = class of TTMSFNCGoogleMapsStreetView;

  TTMSFNCGoogleMapsStepRec = record
    Instructions: string;
    Distance: Integer;
    Duration: Integer;
    TravelMode: TTMSFNCGoogleMapsDirectionsTravelMode;
    Path: TTMSFNCMapsCoordinateRecArray;
  end;

  TTMSFNCGoogleMapsLegRec = record
    Distance: Integer;
    Duration: Integer;
    Origin: string;
    Destination: string;
    OriginCoordinate: TTMSFNCMapsCoordinateRec;
    DestinationCoordinate: TTMSFNCMapsCoordinateRec;
    Steps: array of TTMSFNCGoogleMapsStepRec;
  end;

  TTMSFNCGoogleMapsRouteRec = record
    Name: string;
//    Bounds: TTMSFNCMapsBoundsRec;
    Legs: array of TTMSFNCGoogleMapsLegRec;
    Path: TTMSFNCMapsCoordinateRecArray;
  end;

  TTMSFNCGoogleMapsStreetViewData = record
    Enabled: boolean;
  end;

  TTMSFNCGoogleMapsDirectionsData = record
    Routes: array of TTMSFNCGoogleMapsRouteRec;
  end;

  TTMSFNCGoogleMapsDirectionsEvent = procedure(Sender: TObject; AEventData: TTMSFNCMapsEventData; ADirectionsData: TTMSFNCGoogleMapsDirectionsData) of object;
  TTMSFNCGoogleMapsStreetViewEvent = procedure(Sender: TObject; AEventData: TTMSFNCMapsEventData; AStreetViewData: TTMSFNCGoogleMapsStreetViewData) of object;

  TTMSFNCGoogleMapsCircle = class(TTMSFNCMapsCircle)
  private
    FDraggable: Boolean;
    FZIndex: Integer;
    procedure SetDraggable(const Value: Boolean);
    procedure SetZIndex(const Value: Integer);
  protected
    FClickable: Boolean;
    FEditable: Boolean;
    procedure SetClickable(const Value: Boolean);
    procedure SetEditable(const Value: Boolean);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
  published
    property ZIndex: Integer read FZIndex write SetZIndex default 0;
    property Clickable: Boolean read FClickable write SetClickable default True;
    property Draggable: Boolean read FDraggable write SetDraggable default False;
    property Editable: Boolean read FEditable write SetEditable default False;
  end;

  TTMSFNCGoogleMapsCircles = class(TTMSFNCMapsCircles)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleMapsCircle;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsCircle);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCGoogleMapsCircle read GetItem write SetItem; default;
    function Add: TTMSFNCGoogleMapsCircle;
    function Insert(Index: Integer): TTMSFNCGoogleMapsCircle;
  end;

  TTMSFNCGoogleMapsRectangle = class(TTMSFNCMapsRectangle)
  private
    FClickable: Boolean;
    FEditable: Boolean;
    FDraggable: Boolean;
    FZIndex: Integer;
    procedure SetClickable(const Value: Boolean);
    procedure SetEditable(const Value: Boolean);
    procedure SetDraggable(const Value: Boolean);
    procedure SetZIndex(const Value: Integer);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
  published
    property ZIndex: Integer read FZIndex write SetZIndex default 0;
    property Clickable: Boolean read FClickable write SetClickable default True;
    property Draggable: Boolean read FDraggable write SetDraggable default False;
    property Editable: Boolean read FEditable write SetEditable default False;
  end;

  TTMSFNCGoogleMapsRectangles = class(TTMSFNCMapsRectangles)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleMapsRectangle;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsRectangle);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCGoogleMapsRectangle read GetItem write SetItem; default;
    function Add: TTMSFNCGoogleMapsRectangle;
    function Insert(Index: Integer): TTMSFNCGoogleMapsRectangle;
  end;

  TTMSFNCGoogleMapsPolylineSymbol = class(TCollectionItem)
  private
    FOwner: TTMSFNCGoogleMapsPolyline;
    FPath: TTMSFNCGoogleMapsPolylineSymbolPath;
    FCustomPath: string;
    FRepeatSymbolUnits: TTMSFNCGoogleMapsPolylineSymbolUnits;
    FRepeatSymbol: Integer;
    FOffsetUnits: TTMSFNCGoogleMapsPolylineSymbolUnits;
    FOffset: Integer;
    FScale: Single;
    FRotation: Integer;
    FStrokeColor: TTMSFNCGraphicsColor;
    FStrokeOpacity: Single;
    FStrokeWidth: Integer;
    FFillColor: TTMSFNCGraphicsColor;
    FFillOpacity: Single;
    function IsPathStored: Boolean;
    function IsCustomPathStored: Boolean;
    function IsOffsetStored: Boolean;
    function IsRepeatOffsetUnitsStored: Boolean;
    function IsRepeatSymbolStored: Boolean;
    function IsRepeatSymbolUnitsStored: Boolean;
    function IsScaleStored: Boolean;
    function IsRotationStored: Boolean;
    function IsStrokeOpacityStored: Boolean;
    function IsFillOpacityStored: Boolean;
    procedure SetScale(const Value: Single);
    procedure SetCustomPath(const Value: string);
    procedure SetFillColor(const Value: TTMSFNCGraphicsColor);
    procedure SetFillOpacity(const Value: Single);
    procedure SetOffset(const Value: Integer);
    procedure SetOffsetUnits(const Value: TTMSFNCGoogleMapsPolylineSymbolUnits);
    procedure SetPath(const Value: TTMSFNCGoogleMapsPolylineSymbolPath);
    procedure SetRepeatSymbol(const Value: Integer);
    procedure SetRepeatSymbolUnits(
      const Value: TTMSFNCGoogleMapsPolylineSymbolUnits);
    procedure SetRotation(const Value: Integer);
    procedure SetStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetStrokeOpacity(const Value: Single);
    procedure SetStrokeWidth(const Value: Integer);
  protected
    procedure UpdateSymbol;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property StrokeColor: TTMSFNCGraphicsColor read FStrokeColor write SetStrokeColor default gcNull;
    property StrokeOpacity: Single read FStrokeOpacity write SetStrokeOpacity stored IsStrokeOpacityStored;
    property StrokeWidth: Integer read FStrokeWidth write SetStrokeWidth default 0;
    property FillColor: TTMSFNCGraphicsColor read FFillColor write SetFillColor default gcNull;
    property FillOpacity: Single read FFillOpacity write SetFillOpacity stored IsFillOpacityStored;
    property Path: TTMSFNCGoogleMapsPolylineSymbolPath read FPath write SetPath stored IsPathStored default spForwardOpenArrow;
    property CustomPath: string read FCustomPath write SetCustomPath stored IsCustomPathStored;
    property RepeatSymbol: Integer read FRepeatSymbol write SetRepeatSymbol stored IsRepeatSymbolStored default 0;
    property RepeatSymbolUnits: TTMSFNCGoogleMapsPolylineSymbolUnits read FRepeatSymbolUnits write SetRepeatSymbolUnits stored IsRepeatSymbolUnitsStored default unPixels;
    property Offset: Integer read FOffset write SetOffset stored IsOffsetStored default 100;
    property OffsetUnits: TTMSFNCGoogleMapsPolylineSymbolUnits read FOffsetUnits write SetOffsetUnits stored IsRepeatOffsetUnitsStored default unPercentage;
    property Scale: Single read FScale write SetScale stored IsScaleStored;
    property Rotation: Integer read FRotation write SetRotation stored IsRotationStored default 0;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGoogleMapsPolylineSymbols = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGoogleMapsPolylineSymbols = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGoogleMapsPolylineSymbol>)
  {$ENDIF}
  private
    FOwner: TTMSFNCGoogleMapsPolyline;
    function GetItem(Index: Integer): TTMSFNCGoogleMapsPolylineSymbol;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsPolylineSymbol);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCGoogleMapsPolyline); virtual;
    property Items[Index: Integer]: TTMSFNCGoogleMapsPolylineSymbol read GetItem write SetItem; default;
    function Add: TTMSFNCGoogleMapsPolylineSymbol;
    function Insert(Index: Integer): TTMSFNCGoogleMapsPolylineSymbol;
  end;

  TTMSFNCGoogleMapsPolygon = class(TTMSFNCMapsPolygon)
  private
    FClickable: Boolean;
    FEditable: Boolean;
    FDraggable: Boolean;
    FZIndex: Integer;
    procedure SetClickable(const Value: Boolean);
    procedure SetEditable(const Value: Boolean);
    procedure SetDraggable(const Value: Boolean);
    procedure SetZIndex(const Value: Integer);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    function AddHole(ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsPolyElementHole; override;
  published
    property ZIndex: Integer read FZIndex write SetZIndex default 0;
    property Clickable: Boolean read FClickable write SetClickable default True;
    property Draggable: Boolean read FDraggable write SetDraggable default False;
    property Editable: Boolean read FEditable write SetEditable default False;
    property Holes;
  end;

  TTMSFNCGoogleMapsPolygons = class(TTMSFNCMapsPolygons)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleMapsPolygon;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsPolygon);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCGoogleMapsPolygon read GetItem write SetItem; default;
    function Add: TTMSFNCGoogleMapsPolygon;
    function Insert(Index: Integer): TTMSFNCGoogleMapsPolygon;
  end;

  TTMSFNCGoogleMapsPolyline = class(TTMSFNCMapsPolyline)
  private
    FClickable: Boolean;
    FEditable: Boolean;
    FDraggable: Boolean;
    FZIndex: Integer;
    FGeodesic: Boolean;
    FSymbols: TTMSFNCGoogleMapsPolylineSymbols;
    procedure SetClickable(const Value: Boolean);
    procedure SetEditable(const Value: Boolean);
    procedure SetDraggable(const Value: Boolean);
    procedure SetZIndex(const Value: Integer);
    procedure SetGeodesic(const Value: Boolean);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
  published
    property ZIndex: Integer read FZIndex write SetZIndex default 0;
    property Clickable: Boolean read FClickable write SetClickable default True;
    property Draggable: Boolean read FDraggable write SetDraggable default False;
    property Editable: Boolean read FEditable write SetEditable default False;
    property Geodesic: Boolean read FGeodesic write SetGeodesic default False;
    property Symbols: TTMSFNCGoogleMapsPolylineSymbols read FSymbols write FSymbols;
  end;

  TTMSFNCGoogleMapsPolylines = class(TTMSFNCMapsPolylines)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleMapsPolyline;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsPolyline);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCGoogleMapsPolyline read GetItem write SetItem; default;
    function Add: TTMSFNCGoogleMapsPolyline;
    function Insert(Index: Integer): TTMSFNCGoogleMapsPolyline;
  end;

  TTMSFNCGoogleMapsMarker = class(TTMSFNCMapsMarker)
  private
    FCluster: TTMSFNCGoogleMapsCluster;
    FAnimation: Boolean;
    FClickable: Boolean;
    FDraggable: Boolean;
    FZIndex: Integer;
    FAnchor: TTMSFNCMapsAnchorPoint;
    FDefaultAnchor: Boolean;
    FOverlayView: TTMSFNCGoogleMapsOverlayView;
    FIconHeight: Single;
    FDefaultIconSize: Boolean;
    FIconWidth: Single;
    procedure SetAnimation(const Value: Boolean);
    procedure SetClickable(const Value: Boolean);
    procedure SetDraggable(const Value: Boolean);
    procedure SetZIndex(const Value: Integer);
    procedure SetAnchor(const Value: TTMSFNCMapsAnchorPoint);
    procedure SetDefaultAnchor(const Value: Boolean);
    function IsIconHeightStored: Boolean;
    function IsIconWidthStored: Boolean;
    procedure SetDefaultIconSize(const Value: Boolean);
    procedure SetIconHeight(const Value: Single);
    procedure SetIconWidth(const Value: Single);
  protected
    procedure DoAnchorChanged(Sender: TObject);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    property Cluster: TTMSFNCGoogleMapsCluster read FCluster write FCluster;
    property OverlayView: TTMSFNCGoogleMapsOverlayView read FOverlayView write FOverlayView;
    procedure AddOverlayView(AText: string);
  published
    property ZIndex: Integer read FZIndex write SetZIndex default 0;
    property Animation: Boolean read FAnimation write SetAnimation default False;
    property Clickable: Boolean read FClickable write SetClickable default True;
    property Draggable: Boolean read FDraggable write SetDraggable default False;
    property Anchor: TTMSFNCMapsAnchorPoint read FAnchor write SetAnchor;
    property DefaultIconSize: Boolean read FDefaultIconSize write SetDefaultIconSize default True;
    property IconWidth: Single read FIconWidth write SetIconWidth stored IsIconWidthStored nodefault;
    property IconHeight: Single read FIconHeight write SetIconHeight stored IsIconHeightStored nodefault;
    property DefaultAnchor: Boolean read FDefaultAnchor write SetDefaultAnchor default True;
  end;

  TTMSFNCGoogleMapsMarkers = class(TTMSFNCMapsMarkers)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleMapsMarker;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsMarker);
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    property Items[Index: Integer]: TTMSFNCGoogleMapsMarker read GetItem write SetItem; default;
    function Add: TTMSFNCGoogleMapsMarker;
    function Insert(Index: Integer): TTMSFNCGoogleMapsMarker;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGoogleMapsMarkersList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCGoogleMapsMarker;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsMarker);
  public
    property Items[Index: Integer]: TTMSFNCGoogleMapsMarker read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCGoogleMapsMarkersList = class(TList<TTMSFNCGoogleMapsMarker>);
  {$ENDIF}

  TTMSFNCGoogleMapsCluster = class(TCollectionItem)
  private
    FReload: Boolean;
    FMarkers: TTMSFNCGoogleMapsMarkersList;
    FID: string;
    FOwner: TTMSFNCCustomMaps;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FTitle: string;
    FAverageCenter: Boolean;
    FIgnoreHiddenMarkers: Boolean;
    FZoomOnClick: Boolean;
    FMinimumNumberOfMarkers: Integer;
    FMaxZoom: Integer;
    FRecreate: Boolean;
    FImagePath: string;
    FText: string;
    function GetID: string;
    function IsTitleStored: Boolean;
    function GetMarkers: TTMSFNCGoogleMapsMarkersList;
    function IsAverageCenterStored: Boolean;
    function IsIgnoreHiddenMarkers: Boolean;
    function IsMaxZoomStored: Boolean;
    function IsMinimumNumberOfMarkers: Boolean;
    function IsZoomOnClickStored: Boolean;
    procedure SetAverageCenter(const Value: Boolean);
    procedure SetIgnoreHiddenMarkers(const Value: Boolean);
    procedure SetMaxZoom(const Value: Integer);
    procedure SetMinimumNumberOfMarkers(const Value: Integer);
    procedure SetTitle(const Value: string);
    procedure SetZoomOnClick(const Value: Boolean);
    function IsImagePathStored: Boolean;
    procedure SetImagePath(const Value: string);
    procedure SetText(const Value: string);
  protected
    procedure UpdateCluster; virtual;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Markers: TTMSFNCGoogleMapsMarkersList read GetMarkers;
    property IgnoreHiddenMarkers: Boolean read FIgnoreHiddenMarkers write SetIgnoreHiddenMarkers stored IsIgnoreHiddenMarkers nodefault;
    property Recreate: Boolean read FRecreate write FRecreate;
  published
    property ID: string read GetID;
    property Title: string read FTitle write SetTitle stored IsTitleStored nodefault;
    property ZoomOnClick: Boolean read FZoomOnClick write SetZoomOnClick stored IsZoomOnClickStored nodefault;
    property Averagecenter: Boolean read FAverageCenter write SetAverageCenter stored IsAverageCenterStored nodefault;
    property MaxZoom: Integer read FMaxZoom write SetMaxZoom stored IsMaxZoomStored nodefault;
    property MinimumNumberOfMarkers: Integer read FMinimumNumberOfMarkers write SetMinimumNumberOfMarkers stored IsMinimumNumberOfMarkers nodefault;
    property ImagePath: string read FImagePath write SetImagePath stored IsImagePathStored nodefault;
    property Text: string read FText write SetText nodefault;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGoogleMapsClusters = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGoogleMapsClusters = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGoogleMapsCluster>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCGoogleMapsCluster;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsCluster);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCGoogleMapsCluster read GetItem write SetItem; default;
    procedure Clear; virtual;
    procedure Recreate; virtual;
    function Add: TTMSFNCGoogleMapsCluster;
    function Insert(Index: Integer): TTMSFNCGoogleMapsCluster;
  end;

  TTMSFNCGoogleMapsDirectionsItem = class(TCollectionItem)
  private
    FID: string;
    FOwner: TTMSFNCCustomMaps;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FRecreate: Boolean;
    FDestination: string;
    FOrigin: string;
    FStrokeWidth: Integer;
    FStrokeColor: TTMSFNCGraphicsColor;
    FShowMarkers: Boolean;
    FStrokeOpacity: Single;
    FDestinationCoordinate: TTMSFNCMapsCoordinate;
    FOriginCoordinate: TTMSFNCMapsCoordinate;
    FTravelMode: TTMSFNCGoogleMapsDirectionsTravelMode;
    FWayPoints: TStringList;
    FAvoidTolls: Boolean;
    FShowPolyline: Boolean;
    FOptimizeWayPoints: Boolean;
    function GetID: string;
    function IsDestinationStored: Boolean;
    function IsOriginStored: Boolean;
    function IsShowMarkersStored: Boolean;
    function IsStrokeOpacityStored: Boolean;
    function IsStrokeWidthStored: Boolean;
    function IsTravelModeStored: Boolean;
    function IsAvoidTolls: Boolean;
    function IsShowPolylineStored: Boolean;
    function IsOptimizeWayPointsStored: Boolean;
  protected
    procedure UpdateDirectionsItem; virtual;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Recreate: Boolean read FRecreate write FRecreate;
  published
    property ID: string read GetID;
    property Origin: string read FOrigin stored IsOriginStored nodefault;
    property Destination: string read FDestination stored IsDestinationStored nodefault;
    property OriginCoordinate: TTMSFNCMapsCoordinate read FOriginCoordinate nodefault;
    property DestinationCoordinate: TTMSFNCMapsCoordinate read FDestinationCoordinate nodefault;
    property ShowMarkers: Boolean read FShowMarkers stored IsShowMarkersStored default True;
    property ShowPolyline: Boolean read FShowPolyline stored IsShowPolylineStored default True;
    property StrokeColor: TTMSFNCGraphicsColor read FStrokeColor default gcBlue;
    property StrokeOpacity: Single read FStrokeOpacity stored IsStrokeOpacityStored nodefault;
    property StrokeWidth: Integer read FStrokeWidth stored IsStrokeWidthStored default 2;
    property TravelMode: TTMSFNCGoogleMapsDirectionsTravelMode read FTravelMode stored IsTravelModeStored default dtmDriving;
    property WayPoints: TStringList read FWayPoints nodefault;
    property OptimizeWayPoints: Boolean read FOptimizeWayPoints stored IsOptimizeWayPointsStored default False;
    property AvoidTolls: Boolean read FAvoidTolls stored IsAvoidTolls default False;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGoogleMapsDirections = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGoogleMapsDirections = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGoogleMapsDirectionsItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCGoogleMapsDirectionsItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsDirectionsItem);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCGoogleMapsDirectionsItem read GetItem write SetItem; default;
    procedure Clear; virtual;
    function Add: TTMSFNCGoogleMapsDirectionsItem;
    function Insert(Index: Integer): TTMSFNCGoogleMapsDirectionsItem;
  end;

  TTMSFNCGoogleMapsKMLLayer = class(TCollectionItem)
  private
    FID: string;
    FOwner: TTMSFNCCustomMaps;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FURL: string;
    FZoomToBounds: Boolean;
    FRecreate: Boolean;
    function GetID: string;
    function IsURLStored: Boolean;
    function IsZoomToBoundsStored: Boolean;
  protected
    procedure UpdateKMLLayer; virtual;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Recreate: Boolean read FRecreate write FRecreate;
  published
    property ID: string read GetID;
    property URL: string read FURL stored IsURLStored nodefault;
    property ZoomToBounds: Boolean read FZoomToBounds stored IsZoomToBoundsStored nodefault;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCGoogleMapsKMLLayers = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGoogleMapsKMLLayers = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGoogleMapsKMLLayer>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCGoogleMapsKMLLayer;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsKMLLayer);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCGoogleMapsKMLLayer read GetItem write SetItem; default;
    procedure Clear; virtual;
    function Add: TTMSFNCGoogleMapsKMLLayer;
    function Insert(Index: Integer): TTMSFNCGoogleMapsKMLLayer;
  end;

  TTMSFNCGoogleMapsOverlayView = class(TCollectionItem)
  private
    FID: string;
    FOwner: TTMSFNCCustomMaps;
    FMarkers: TTMSFNCGoogleMapsMarkersList;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    FRecreate: Boolean;
    FText: string;
    FBounds: TTMSFNCMapsBounds;
    FBackgroundColor: TTMSFNCGraphicsColor;
    FBorderColor: TTMSFNCGraphicsColor;
    FMode: TTMSFNCGoogleMapsOverlayViewMode;
    FCoordinateOffsetTop: Integer;
    FCoordinateOffsetLeft: Integer;
    FCoordinatePosition: TTMSFNCGoogleMapsCoordinatePosition;
    FPadding: Integer;
    FCoordinate: TTMSFNCMapsCoordinate;
    FFont: TTMSFNCGraphicsFont;
    FVisible: Boolean;
    FClickable: Boolean;
    FWidth: Integer;
    FHeight: Integer;
    function GetID: string;
    procedure SetText(const Value: string);
    procedure SetBounds(const Value: TTMSFNCMapsBounds);
    function GetMarkers: TTMSFNCGoogleMapsMarkersList;
    procedure SetBackgroundColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBorderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetCoordinate(const Value: TTMSFNCMapsCoordinate);
    procedure SetCoordinatePosition(
      const Value: TTMSFNCGoogleMapsCoordinatePosition);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetMode(const Value: TTMSFNCGoogleMapsOverlayViewMode);
    procedure SetoordinatePositionOffsetLeft(const Value: Integer);
    procedure SetoordinatePositionOffsetTop(const Value: Integer);
    procedure SetPadding(const Value: Integer);
    procedure SetVisible(const Value: Boolean);
    procedure SetClickable(const Value: Boolean);
    procedure SetHeight(const Value: Integer);
    procedure SetWidth(const Value: Integer);
  protected
    procedure UpdateOverlayView; virtual;
    procedure Changed(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property Recreate: Boolean read FRecreate write FRecreate;
    property Markers: TTMSFNCGoogleMapsMarkersList read GetMarkers;
  published
    property ID: string read GetID;
    property Text: string read FText write SetText;
    property Bounds: TTMSFNCMapsBounds read FBounds write SetBounds;
    property BorderColor: TTMSFNCGraphicsColor read FBorderColor write SetBorderColor default gcBlack;
    property BackgroundColor: TTMSFNCGraphicsColor read FBackgroundColor write SetBackgroundColor default gcWhite;
    property Clickable: Boolean read FClickable write SetClickable default True;
    property Coordinate: TTMSFNCMapsCoordinate read FCoordinate write SetCoordinate;
    property CoordinatePosition: TTMSFNCGoogleMapsCoordinatePosition read FCoordinatePosition write SetCoordinatePosition default cpTopCenter;
    property CoordinateOffsetTop: Integer read FCoordinateOffsetTop write SetoordinatePositionOffsetTop default 0;
    property CoordinateOffsetLeft: Integer read FCoordinateOffsetLeft write SetoordinatePositionOffsetLeft default 0;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Height: Integer read FHeight write SetHeight default -1;
    property Mode: TTMSFNCGoogleMapsOverlayViewMode read FMode write SetMode default omCoordinate;
    property Padding: Integer read FPadding write SetPadding default 3;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Width: Integer read FWidth write SetWidth default -1;

  end;

  {$IFDEF WEBLIB}
  TTMSFNCGoogleMapsOverlayViews = class(TTMSFNCOwnedCollection)
  {$ELSE}
  TTMSFNCGoogleMapsOverlayViews = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCGoogleMapsOverlayView>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomMaps;
    function GetItem(Index: Integer): TTMSFNCGoogleMapsOverlayView;
    procedure SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsOverlayView);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomMaps); virtual;
    property Items[Index: Integer]: TTMSFNCGoogleMapsOverlayView read GetItem write SetItem; default;
    procedure Clear; virtual;
    function Add: TTMSFNCGoogleMapsOverlayView;
    function Insert(Index: Integer): TTMSFNCGoogleMapsOverlayView;
  end;

  TTMSFNCGoogleMapsStreetView = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FEnabled: Boolean;
    FPitch: integer;
    FHeading: integer;
    FZoom: integer;
    procedure SetEnabled(const Value: Boolean);
    procedure SetHeading(const Value: integer);
    procedure SetPitch(const Value: integer);
    procedure SetZoom(const Value: integer);
  protected
    procedure Changed;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; virtual;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property Heading: integer read FHeading write SetHeading default 0;
    property Pitch: integer read FPitch write SetPitch default 0;
    property Zoom: integer read FZoom write SetZoom default 0;
  end;

  TTMSFNCGoogleMapsOptions = class(TTMSFNCMapsOptions)
  private
    FMapTypeID: TTMSFNCGoogleMapsMapTypeID;
    FShowTraffic: Boolean;
    FMapStyle: string;
    FShowBicycling: Boolean;
    FShowStreetViewControl: Boolean;
    FStreetView: TTMSFNCGoogleMapsStreetView;
    FMapID: string;
    FTilt: Double;
    FHeading: Double;
    FShowScaleControl: Boolean;
    FShowKeyboardShortcuts: Boolean;
    FDisablePOI: Boolean;
    FVersion: string;
    FShowRotateControl: Boolean;
    procedure SetMapTypeID(const Value: TTMSFNCGoogleMapsMapTypeID);
    procedure SetShowTraffic(const Value: Boolean);
    procedure SetMapStyle(const Value: string);
    procedure SetShowBicycling(const Value: Boolean);
    procedure SetSetShowStreetViewControl(const Value: Boolean);
    procedure SetStreetView(const Value: TTMSFNCGoogleMapsStreetView);
    procedure SetMapID(const Value: string);
    procedure SetHeading(const Value: Double);
    procedure SetTilt(const Value: Double);
    procedure SetShowScaleControl(const Value: Boolean);
    procedure SetDisabelPOI(const Value: Boolean);
    procedure SetVersion(const Value: string);
    procedure SetShowRotateControl(const Value: Boolean);
    procedure SetShowKeyboardShortcuts(const Value: Boolean);
  protected
  public
    constructor Create; override;
    destructor Destroy; override;
  published
    property MapID: string read FMapID write SetMapID;
    property MapTypeID: TTMSFNCGoogleMapsMapTypeID read FMapTypeID write SetMapTypeID default gmtDefault;
    property ShowTraffic: Boolean read FShowTraffic write SetShowTraffic default False;
    property ShowBicycling: Boolean read FShowBicycling write SetShowBicycling default False;
    property ShowStreetViewControl: Boolean read FShowStreetViewControl write SetSetShowStreetViewControl default True;
    property ShowScaleControl: Boolean read FShowScaleControl write SetShowScaleControl default False;
    property ShowKeyboardShortcuts: Boolean read FShowKeyboardShortcuts write SetShowKeyboardShortcuts default True;
    property ShowRotateControl: Boolean read FShowRotateControl write SetShowRotateControl default True;
    property MapStyle: string read FMapStyle write SetMapStyle;
    property BackgroundColor;
    property StreetView: TTMSFNCGoogleMapsStreetView read FStreetView write SetStreetView;
    property Tilt: Double read FTilt write SetTilt;
    property Heading: Double read FHeading write SetHeading;
    property DisablePOI: Boolean read FDisablePOI write SetDisabelPOI;
    property Version: string read FVersion write SetVersion;
  end;

  TTMSFNCCustomGoogleMaps = class(TTMSFNCCustomMaps)
  private
    FKMLLayers: TTMSFNCGoogleMapsKMLLayers;
    FOverlayViews: TTMSFNCGoogleMapsOverlayViews;
    FDirections: TTMSFNCGoogleMapsDirections;
    FClusters: TTMSFNCGoogleMapsClusters;
    FOnMarkerDragEnd: TTMSFNCMapsBaseEvent;
    FOnPolyElementDragEnd: TTMSFNCMapsBaseEvent;
    FOnPolyElementEditEnd: TTMSFNCMapsBaseEvent;
    FOnClusterClick: TTMSFNCMapsBaseEvent;
    FOnOverlayViewClick: TTMSFNCMapsBaseEvent;
    FOnKMLLayerClick: TTMSFNCMapsBaseEvent;
    FOnClusterMouseLeave: TTMSFNCMapsBaseEvent;
    FOnClusterMouseEnter: TTMSFNCMapsBaseEvent;
    FOnStreetViewChange: TTMSFNCMapsBaseEvent;
    FOnStreetViewEnabledChange: TTMSFNCGoogleMapsStreetViewEvent;
    FOnRetrievedDirectionsData: TTMSFNCGoogleMapsDirectionsEvent;
    function GetOptions: TTMSFNCGoogleMapsOptions;
    procedure SetKMLLayers(const Value: TTMSFNCGoogleMapsKMLLayers);
    procedure SetOverlayViews(const Value: TTMSFNCGoogleMapsOverlayViews);
    procedure SetDirections(const Value: TTMSFNCGoogleMapsDirections);
    function GetMarkers: TTMSFNCGoogleMapsMarkers;
    function GetPolylines: TTMSFNCGoogleMapsPolylines;
    procedure SetClusters(const Value: TTMSFNCGoogleMapsClusters);
    function GetPolygons: TTMSFNCGoogleMapsPolygons;
    function GetRectangles: TTMSFNCGoogleMapsRectangles;
    function GetCircles: TTMSFNCGoogleMapsCircles;
    procedure SetCircles(const Value: TTMSFNCGoogleMapsCircles);
    procedure SetMarkers(const Value: TTMSFNCGoogleMapsMarkers);
    procedure SetOptions(const Value: TTMSFNCGoogleMapsOptions);
    procedure SetPolygons(const Value: TTMSFNCGoogleMapsPolygons);
    procedure SetPolylines(const Value: TTMSFNCGoogleMapsPolylines);
    procedure SetRectangles(const Value: TTMSFNCGoogleMapsRectangles);
  protected
    function GetDocURL: string; override;
    function GetMapID: string; override;
    function GetAPIVersion: string; override;
    function GetHeading: Double; override;
    function GetTilt: Double; override;
    function GetVersionNr: Integer; override;
    function GetAddOrUpdateKMLLayerFunction: string; virtual;
    function GetDeleteKMLLayerFunction: string; virtual;
    function GetAddOrUpdateDirectionsFunction: string; virtual;
    function GetDeleteDirectionsFunction: string; virtual;
    function GetAddOrUpdateClusterFunction: string; virtual;
    function GetDeleteClusterFunction: string; virtual;
    function GetMarkersClass: TTMSFNCMapsMarkersClass; override;
    function GetPolylinesClass: TTMSFNCMapsPolylinesClass; override;
    function GetPolygonsClass: TTMSFNCMapsPolygonsClass; override;
    function GetRectanglesClass: TTMSFNCMapsRectanglesClass; override;
    function GetCirclesClass: TTMSFNCMapsCirclesClass; override;
    function GetOptionsClass: TTMSFNCMapsOptionsClass; override;
    function GetCustomFunctions: string; override;
    function GetCustomGlobalVariables: string; override;
    function GetCustomOptions: string; override;
    function GetAddCustomObjects: string; override;
    function GetCustomMap: string; override;
    function GetStreetViewClass: TTMSFNCGoogleMapsStreetViewClass; virtual;
    function AddDirectionsInt(AOrigin, ADestination: string;
      AOriginCoordinate, ADestinationCoordinate: TTMSFNCMapsCoordinateRec;
      AShowMarkers: Boolean = True; AShowPolyline: Boolean = True; AStrokeColor: TTMSFNCGraphicsColor = gcBlue;
      AStrokeWidth: Integer = 2; AStrokeOpacity: Single = 1;
      ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode = dtmDriving;
      AAVoidTolls: Boolean = False;
      AWayPoints: TStringList = nil; AOptimizeWayPoints: Boolean = False): TTMSFNCGoogleMapsDirectionsItem; overload;
    function ParseStreetViewDataInt(AID: string; AJSON: string): TTMSFNCGoogleMapsStreetViewData;
    function ParseDirectionsDataInt(AID: string; AJSON: string): TTMSFNCGoogleMapsDirectionsData;
    procedure CallCustomEvent(AEventData: TTMSFNCMapsEventData); override;
    procedure DoMarkerDragEnd(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoClusterClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoOverlayViewClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoKMLLayerClick(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoClusterMouseEnter(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoClusterMouseLeave(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementDragEnd(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoPolyElementEditEnd(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoStreetViewChange(AEventData: TTMSFNCMapsEventData); virtual;
    procedure DoStreetViewEnabledChange(AEventData: TTMSFNCMapsEventData; AStreetViewData: TTMSFNCGoogleMapsStreetViewData); virtual;
    procedure DoRetrievedDirectionsData(AEventData: TTMSFNCMapsEventData; ADirectionsData: TTMSFNCGoogleMapsDirectionsData); virtual;
    procedure DoAddDirections(const AValue: string); virtual;
    procedure GetLinks(AList: TTMSFNCMapsLinksList; AIncludeContent: Boolean = True; ACheckReady: Boolean = True); override;
    procedure UpdateKMLLayers; virtual;
    procedure UpdateOverlayViews; virtual;
    procedure DoUpdateKMLLayers(const AValue: string); virtual;
    procedure DoUpdateOverlayViews(const AValue: string); virtual;
    procedure UpdateDirections; virtual;
    procedure DoUpdateDirections(const AValue: string); virtual;
    procedure UpdateClusters; virtual;
    procedure DoUpdateClusters(const AValue: string); virtual;
    procedure CreateClasses; override;
    procedure StreetViewChanged(Sender: TObject);
    property Options: TTMSFNCGoogleMapsOptions read GetOptions write SetOptions;
    property Clusters: TTMSFNCGoogleMapsClusters read FClusters write SetClusters;
    property KMLLayers: TTMSFNCGoogleMapsKMLLayers read FKMLLayers write SetKMLLayers;
    property Directions: TTMSFNCGoogleMapsDirections read FDirections write SetDirections;
    property Markers: TTMSFNCGoogleMapsMarkers read GetMarkers write SetMarkers;
    property Polylines: TTMSFNCGoogleMapsPolylines read GetPolylines write SetPolylines;
    property Polygons: TTMSFNCGoogleMapsPolygons read GetPolygons write SetPolygons;
    property Rectangles: TTMSFNCGoogleMapsRectangles read GetRectangles write SetRectangles;
    property Circles: TTMSFNCGoogleMapsCircles read GetCircles write SetCircles;
    property OnMarkerDragEnd: TTMSFNCMapsBaseEvent read FOnMarkerDragEnd write FOnMarkerDragEnd;
    property OnClusterClick: TTMSFNCMapsBaseEvent read FOnClusterClick write FOnClusterClick;
    property OnClusterMouseEnter: TTMSFNCMapsBaseEvent read FOnClusterMouseEnter write FOnClusterMouseEnter;
    property OnClusterMouseLeave: TTMSFNCMapsBaseEvent read FOnClusterMouseLeave write FOnClusterMouseLeave;
    property OnOverlayViewClick: TTMSFNCMapsBaseEvent read FOnOverlayViewClick write FOnOverlayViewClick;
    property OnKMLLayerClick: TTMSFNCMapsBaseEvent read FOnKMLLayerClick write FOnKMLLayerClick;
    property OnPolyElementDragEnd: TTMSFNCMapsBaseEvent read FOnPolyElementDragEnd write FOnPolyElementDragEnd;
    property OnPolyElementEditEnd: TTMSFNCMapsBaseEvent read FOnPolyElementEditEnd write FOnPolyElementEditEnd;
    property OnStreetViewChange: TTMSFNCMapsBaseEvent read FOnStreetViewChange write FOnStreetViewChange;
    property OnStreetViewEnabledChange: TTMSFNCGoogleMapsStreetViewEvent read FOnStreetViewEnabledChange write FOnStreetViewEnabledChange;
    property OnRetrievedDirectionsData: TTMSFNCGoogleMapsDirectionsEvent read FOnRetrievedDirectionsData write FOnRetrievedDirectionsData;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure EndUpdate; override;

    function AddMarker(ACoordinate: TTMSFNCMapsCoordinateRec; ATitle: string = ''; AIconURL: string = ''): TTMSFNCGoogleMapsMarker; reintroduce; overload; virtual;
    function AddMarker(ALatitude: Double; ALongitude: Double; ATitle: string = ''; AIconURL: string = ''): TTMSFNCGoogleMapsMarker; reintroduce; overload; virtual;
    function AddPolyline(ACoordinates: TTMSFNCMapsCoordinateRecArray; AClose: Boolean = False): TTMSFNCGoogleMapsPolyline; reintroduce; virtual;
    function AddPolygon(ACoordinates: TTMSFNCMapsCoordinateRecArray; AClose: Boolean = False): TTMSFNCGoogleMapsPolygon; reintroduce; virtual;
    function AddCircle(ACenter: TTMSFNCMapsCoordinateRec; ARadius: Double = 10000): TTMSFNCGoogleMapsCircle; reintroduce; virtual;
    function AddRectangle(ABounds: TTMSFNCMapsBoundsRec): TTMSFNCGoogleMapsRectangle; reintroduce; virtual;

    function AddKMLLayer(AURL: string; AZoomToBounds: Boolean = True): TTMSFNCGoogleMapsKMLLayer;
    function AddDirections(AOrigin, ADestination: string;
      AShowMarkers: Boolean = True; AShowPolyline: Boolean = True; AStrokeColor: TTMSFNCGraphicsColor = gcBlue;
      AStrokeWidth: Integer = 2; AStrokeOpacity: Single = 1;
      ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode = dtmDriving;
      AAvoidTolls: Boolean = False; AWayPoints: TStringList = nil; AOptimizeWayPoints: Boolean = False): TTMSFNCGoogleMapsDirectionsItem; overload;
    function AddDirections(AOriginCoordinate, ADestinationCoordinate: TTMSFNCMapsCoordinateRec;
      AShowMarkers: Boolean = True; AShowPolyline: Boolean = True; AStrokeColor: TTMSFNCGraphicsColor = gcBlue;
      AStrokeWidth: Integer = 2; AStrokeOpacity: Single = 1;
      ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode = dtmDriving): TTMSFNCGoogleMapsDirectionsItem; overload;
    function AddDirections(AOriginLatitude, AOriginLongitude, ADestinationLatitude, ADestinationLongitude: Double;
      AShowMarkers: Boolean = True; AShowPolyline: Boolean = True; AStrokeColor: TTMSFNCGraphicsColor = gcBlue;
      AStrokeWidth: Integer = 2; AStrokeOpacity: Single = 1;
      ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode = dtmDriving): TTMSFNCGoogleMapsDirectionsItem; overload;
    property OverlayViews: TTMSFNCGoogleMapsOverlayViews read FOverlayViews write SetOverlayViews;
    function AddOverlayView: TTMSFNCGoogleMapsOverlayView;
    function GetAddOrUpdateOverlayViewFunction: string; virtual;
    function GetDeleteOverlayViewFunction: string; virtual;
    procedure Clear; override;
    procedure ClearKMLLayers;
    procedure ClearDirections;
    procedure ClearClusters;
    procedure ClearOverlayViews;
  end;

  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  TTMSFNCGoogleMaps = class(TTMSFNCCustomGoogleMaps)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    procedure Navigate; overload; override;
    procedure Navigate(const AURL: string); overload; override;
    procedure ExecuteJavaScript(AScript: String; ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent = nil; AImmediate: Boolean = False); override;
    function ExecuteJavaScriptSync(AScript: String): string; override;
    procedure LoadHTML(AHTML: String); override;
    procedure LoadFile(AFile: String); override;
    procedure Initialize; override;
    procedure DeInitialize; override;
    procedure GoForward; override;
    procedure GoBack; override;
    procedure Reload; override;
    procedure StopLoading; override;
    procedure AddBridge(ABridgeName: string; ABridgeObject: TObject); override;
    procedure RemoveBridge(ABridgeName: string); override;
    procedure CaptureScreenShot; override;
    function GetBridgeCommunicationLayer(ABridgeName: string): string; override;
    function NativeEnvironment: Pointer; override;
    function NativeBrowser: Pointer; override;
    function IsFMXBrowser: Boolean; override;
    function CanGoBack: Boolean; override;
    function CanGoForward: Boolean; override;
    {$IFDEF ANDROID}
    function NativeDialog: Pointer; override;
    {$ENDIF}
    {$IFDEF MSWINDOWS}
    function GetWebBrowserInstance: IInterface; override;
    {$ENDIF}
    property OnCloseForm;
    property EnableContextMenu;
    property EnableShowDebugConsole;
    property EnableAcceleratorKeys;
    property CacheFolder;
    property CacheFolderName;
    property AutoClearCache;
    procedure ClearCache; override;
    procedure ShowDebugConsole; override;

    property MapsInstance;
    property MapsProperties;
  published
    property OnCustomizeLocalAccessFileName;
    property OnCaptureScreenShot;
    property OnCustomizeCSS;
    property OnCustomizeHeadLinks;
    property OnCustomizeJavaScript;
    property OnCustomizeMap;
    property OnCustomizeGlobalVariables;
    property OnCustomizeMarker;
    property OnCustomizePopup;
    property OnCustomizeOptions;
    property OnCustomizePolyElement;
    property OnGetDefaultHTMLMessage;
    property OnZoomChanged;
    property OnMapTypeChanged;
    property OnMapMoveStart;
    property OnMapMoveEnd;
    property OnMapClick;
    property OnMapRightClick;
    property OnMapDblClick;
    property OnMapMouseUp;
    property OnMapMouseDown;
    property OnMapMouseMove;
    property OnMapMouseEnter;
    property OnMapMouseLeave;
    property OnMarkerClick;
    property OnMarkerRightClick;
    property OnMarkerDblClick;
    property OnMarkerMouseUp;
    property OnMarkerMouseDown;
    property OnMarkerMouseEnter;
    property OnMarkerMouseLeave;
    property OnPolyElementClick;
    property OnPolyElementRightClick;
    property OnPolyElementDblClick;
    property OnPolyElementMouseUp;
    property OnPolyElementMouseDown;
    property OnPolyElementMouseEnter;
    property OnPolyElementMouseLeave;
    property OnCustomEvent;
    property OnMapInitialized;
    property OnGetCenterCoordinate;
    property OnGetZoomLevel;
    property OnGetBounds;
    property OnCreateGPXTrack;
    property OnCreateGPXSegment;
    property OnCreateGeoJSONObject;

    property APIKey;
    property Polylines;
    property Polygons;
    property Circles;
    property Rectangles;
    property Markers;
    property Options;
    property Version;
    property LocalFileAccess;
    property ElementContainers;
    property HeadLinks;

    property OnMarkerDragEnd;
    property OnClusterClick;
    property OnClusterMouseEnter;
    property OnClusterMouseLeave;
    property OnOverlayViewClick;
    property OnKMLLayerClick;
    property OnPolyElementDragEnd;
    property OnPolyElementEditEnd;
    property OnStreetViewChange;
    property OnStreetViewEnabledChange;
    property OnRetrievedDirectionsData;

    property KMLLayers;
    property Directions;
    property Clusters;
    property RouteCalculator;
    property OverlayViews;

    property DesigntimeEnabled;
  end;

implementation

uses
  {%H-}Math,
  WEBLib.TMSFNCPersistence,
  WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCMaps.GoogleMaps,
  SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}

  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 26}
  ,JSON
  {$ELSE}
  ,DBXJSON
  {$IFEND}
  {$HINTS ON}

  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,WEBLIB.JSON
  {$ENDIF}
  ;

const
  MAP_TYPE_PREFIX = 'google.maps.MapTypeId.';
  MAP_DEFAULT = 'ROADMAP';
  MAP_SATELLITE = 'SATELLITE';
  MAP_HYBRID = 'HYBRID';
  MAP_TERRAIN = 'TERRAIN';

  MAPTRAFFICLAYER = 'trafficLayer';
  MAPBICYCLINGLAYER = 'bicyclingLayer';

  KMLLAYERVAR = 'kmlLayer';
  KMLLAYERARRAYVAR = 'kmlLayerarray';
  GETKMLLAYERARRAYVAR = 'get' + KMLLAYERARRAYVAR + '()';
  ADDORUPDATEKMLLAYERFUNCTION = 'addOrUpdateKMLLayer';
  DELETEKMLLAYERFUNCTION = 'deleteKMLLayer';

  OVERLAYVIEWVAR = 'overlayView';
  OVERLAYVIEWARRAYVAR = 'overlayViewarray';
  GETOVERLAYVIEWARRAYVAR = 'get' + OVERLAYVIEWARRAYVAR + '()';
  ADDORUPDATEOVERLAYVIEWFUNCTION = 'addOrUpdateOverlayView';
  DELETEOVERLAYVIEWFUNCTION = 'deleteOverlayView';

  DIRECTIONSVAR = 'directions';
  DIRECTIONSARRAYVAR = 'directionsarray';
  GETDIRECTIONSARRAYVAR = 'get' + DIRECTIONSARRAYVAR + '()';
  ADDORUPDATEDIRECTIONSFUNCTION = 'addOrUpdateDirections';
  DELETEDIRECTIONSFUNCTION = 'deleteDirections';

  MARKERCLUSTERERSCRIPT = 'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js';
  CLUSTERVAR = 'cluster';
  CLUSTERARRAYVAR = 'clusterarray';
  GETCLUSTERARRAYVAR = 'get' + CLUSTERARRAYVAR + '()';
  ADDORUPDATECLUSTERFUNCTION = 'addOrUpdateCluster';
  DELETECLUSTERFUNCTION = 'deleteCluster';

  STREETVIEWCHANGE = 'sv';

{ TTMSFNCCustomGoogleMaps }

constructor TTMSFNCCustomGoogleMaps.Create(AOwner: TComponent);
begin
  inherited;
  FKMLLayers := TTMSFNCGoogleMapsKMLLayers.Create(Self);
  FOverlayViews := TTMSFNCGoogleMapsOverlayViews.Create(Self);
  FDirections := TTMSFNCGoogleMapsDirections.Create(Self);
  FClusters := TTMSFNCGoogleMapsClusters.Create(Self);
  Service := msGoogleMaps;
end;

procedure TTMSFNCCustomGoogleMaps.CreateClasses;
begin
  inherited;

  Options.FStreetView := GetStreetViewClass.Create;
  Options.FStreetView.OnChange := @StreetViewChanged;
end;

function TTMSFNCCustomGoogleMaps.GetOptionsClass: TTMSFNCMapsOptionsClass;
begin
  Result := TTMSFNCGoogleMapsOptions;
end;

function TTMSFNCCustomGoogleMaps.GetPolygons: TTMSFNCGoogleMapsPolygons;
begin
  Result := TTMSFNCGoogleMapsPolygons(inherited Polygons);
end;

function TTMSFNCCustomGoogleMaps.GetPolygonsClass: TTMSFNCMapsPolygonsClass;
begin
  Result := TTMSFNCGoogleMapsPolygons;
end;

function TTMSFNCCustomGoogleMaps.GetPolylines: TTMSFNCGoogleMapsPolylines;
begin
  Result := TTMSFNCGoogleMapsPolylines(inherited Polylines);
end;

function TTMSFNCCustomGoogleMaps.GetPolylinesClass: TTMSFNCMapsPolylinesClass;
begin
  Result := TTMSFNCGoogleMapsPolylines;
end;

function TTMSFNCCustomGoogleMaps.GetRectangles: TTMSFNCGoogleMapsRectangles;
begin
  Result := TTMSFNCGoogleMapsRectangles(inherited Rectangles);
end;

function TTMSFNCCustomGoogleMaps.GetRectanglesClass: TTMSFNCMapsRectanglesClass;
begin
  Result := TTMSFNCGoogleMapsRectangles;
end;

function TTMSFNCCustomGoogleMaps.GetStreetViewClass: TTMSFNCGoogleMapsStreetViewClass;
begin
  Result := TTMSFNCGoogleMapsStreetView;
end;

function TTMSFNCCustomGoogleMaps.GetTilt: Double;
begin
  Result := Options.Tilt;
end;

destructor TTMSFNCCustomGoogleMaps.Destroy;
begin
  FKMLLayers.Free;
  FOverlayViews.Free;
  FDirections.Free;
  FClusters.Free;
  inherited;
end;

procedure TTMSFNCCustomGoogleMaps.DoClusterClick(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnClusterClick) then
    OnClusterClick(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoClusterMouseLeave(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnClusterMouseLeave) then
    OnClusterMouseLeave(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoClusterMouseEnter(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnClusterMouseEnter) then
    OnClusterMouseEnter(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoOverlayViewClick(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnOverlayViewClick) then
    OnOverlayViewClick(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoKMLLayerClick(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnKMLLayerClick) then
    OnKMLLayerClick(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoMarkerDragEnd(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnMarkerDragEnd) then
    OnMarkerDragEnd(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoPolyElementDragEnd(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnPolyElementDragEnd) then
    OnPolyElementDragEnd(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoPolyElementEditEnd(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnPolyElementEditEnd) then
    OnPolyElementEditEnd(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoRetrievedDirectionsData(
  AEventData: TTMSFNCMapsEventData; ADirectionsData: TTMSFNCGoogleMapsDirectionsData);
begin
  if Assigned(OnRetrievedDirectionsData) then
    OnRetrievedDirectionsData(Self, AEventData, ADirectionsData);
end;

procedure TTMSFNCCustomGoogleMaps.DoAddDirections(const AValue: string);
begin
  if AValue = 'null' then
    Exit;
end;

procedure TTMSFNCCustomGoogleMaps.DoStreetViewChange(
  AEventData: TTMSFNCMapsEventData);
begin
  if Assigned(OnStreetViewChange) then
    OnStreetViewChange(Self, AEventData);
end;

procedure TTMSFNCCustomGoogleMaps.DoStreetViewEnabledChange(
  AEventData: TTMSFNCMapsEventData;
  AStreetViewData: TTMSFNCGoogleMapsStreetViewData);
begin
  if Assigned(OnStreetViewEnabledChange) then
    OnStreetViewEnabledChange(Self, AEventData, AStreetViewData);
end;

procedure TTMSFNCCustomGoogleMaps.DoUpdateClusters(const AValue: string);
var
  I, j: Integer;
  ma: string;
  sl: TStringList;
  m: TTMSFNCGoogleMapsMarkersList;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);
    for I := 0 to Clusters.Count - 1 do
    begin
      j := sl.IndexOf(Clusters[I].ID);
      if (j = -1) or (Clusters[I].FReload) then
      begin
        Clusters[I].FReload := False;
        m := Clusters[I].Markers;
        ExecuteJavaScript(ADDORUPDATECLUSTERFUNCTION + '([' + Clusters[I].ToJSON + ', ' + m.ToJSON + ']);');
      end;
      if j <> -1 then
        sl.Delete(j);
    end;

    for I := 0 to sl.Count - 1 do
      ExecuteJavaScript(DELETECLUSTERFUNCTION + '({"ID": "' + sl[I] + '"})');

  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomGoogleMaps.DoUpdateDirections(const AValue: string);
var
  I, j: Integer;
  ma: string;
  sl: TStringList;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);
    for I := 0 to Directions.Count - 1 do
    begin
      j := sl.IndexOf(Directions[I].ID);
      ExecuteJavaScript(ADDORUPDATEDIRECTIONSFUNCTION + '(' + Directions[I].ToJSON + ');');
      if j <> -1 then
        sl.Delete(j);
    end;

    for I := 0 to sl.Count - 1 do
      ExecuteJavaScript(DELETEDIRECTIONSFUNCTION + '({"ID": "' + sl[I] + '"})');

  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomGoogleMaps.DoUpdateKMLLayers(const AValue: string);
var
  I, j: Integer;
  ma: string;
  sl: TStringList;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);
    for I := 0 to KMLLayers.Count - 1 do
    begin
      j := sl.IndexOf(KMLLayers[I].ID);
      ExecuteJavaScript(ADDORUPDATEKMLLAYERFUNCTION + '(' + KMLLayers[I].ToJSON + ');');
      if j <> -1 then
        sl.Delete(j);
    end;

    for I := 0 to sl.Count - 1 do
      ExecuteJavaScript(DELETEKMLLAYERFUNCTION + '({"ID": "' + sl[I] + '"})');

  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomGoogleMaps.DoUpdateOverlayViews(const AValue: string);
var
  I, j: Integer;
  ma: string;
  sl: TStringList;
  m: TTMSFNCGoogleMapsMarkersList;
  s: string;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ma := ParseScript(AValue);

  sl := TStringList.Create;
  try
    TTMSFNCUtils.Split(',', ma, sl);

    s := 'function updateAllOverlayViews(){' + LB;

    for I := 0 to OverlayViews.Count - 1 do
    begin
      j := sl.IndexOf(OverlayViews[I].ID);
      m := OverlayViews[I].Markers;
      s := s + ADDORUPDATEOVERLAYVIEWFUNCTION + '([' + OverlayViews[I].ToJSON + ', ' + m.ToJSON + ']);' + LB;
      if j <> -1 then
        sl.Delete(j);
    end;

    s := s + '}updateAllOverlayViews();';
    ExecuteJavaScript(s);

    s := 'function deleteAllOverlayViews(){' + LB;
    for I := 0 to sl.Count - 1 do
      s := s + DELETEOVERLAYVIEWFUNCTION + '({"ID": "' + sl[I] + '"});' + LB;

    s := s + '}deleteAllOverlayViews();';
    ExecuteJavaScript(s);

  finally
    sl.Free;
  end;
end;

procedure TTMSFNCCustomGoogleMaps.EndUpdate;
begin
  inherited;
  if UpdateCount = 0 then
  begin
    UpdateOverlayViews;
    UpdateKMLLayers;
    UpdateDirections;
    UpdateClusters;
  end;
end;

function TTMSFNCCustomGoogleMaps.GetCustomMap: string;
var
  MapTypeID: string;
begin
  Result :=
  '  ' + MAPTRAFFICLAYER + ' = new ' + MAPSERVICEVAR + '.TrafficLayer();' + LB +
  '  ' + MAPBICYCLINGLAYER + ' = new ' + MAPSERVICEVAR + '.BicyclingLayer();' + LB;

  if Options.ShowTraffic then
    Result := Result
      + '  ' + MAPTRAFFICLAYER + '.setMap(' + MAPVAR + ');' + LB;

  if Options.ShowBicycling then
    Result := Result
      + '  ' + MAPBICYCLINGLAYER + '.setMap(' + MAPVAR + ');' + LB;

  if Options.MapTypeID <> gmtDefault then
  begin
    case Options.MapTypeID of
      gmtSatellite: MapTypeID := MAP_SATELLITE;
      gmtHybrid: MapTypeID := MAP_HYBRID;
      gmtTerrain: MapTypeID := MAP_TERRAIN;
    end;
    Result := Result +
      MAPVAR + '.setMapTypeId(' + MAP_TYPE_PREFIX + MapTypeID + ');' + LB;
  end;

  if Options.MapStyle <> '' then
  begin
    Result := Result + MAPVAR + '.setOptions({ styles: ' + Options.MapStyle + '});' + LB;
  end
  else if Options.DisablePOI then
  begin
    Result := Result + MAPVAR + '.setOptions({ styles: ' + MAPSTYLEDISABLEPOI + '});' + LB;
  end
  else
  begin
    Result := Result + MAPVAR + '.setOptions({ styles: ' + MAPSTYLEDEFAULT + '});' + LB;
  end;

  Result := Result + MAPVAR + '.setOptions({ streetViewControl: ' + LowerCase(BoolToStr(Options.ShowStreetViewControl, True)) + '});';
  Result := Result + MAPVAR + '.setOptions({ scaleControl: ' + LowerCase(BoolToStr(Options.ShowScaleControl, True)) + '});';
  Result := Result + MAPVAR + '.setOptions({ keyboardShortcuts: ' + LowerCase(BoolToStr(Options.ShowKeyboardShortcuts, True)) + '});';
  Result := Result + MAPVAR + '.setOptions({ rotateControl: ' + LowerCase(BoolToStr(Options.ShowRotateControl, True)) + '});';

  Result := Result +
    '  sv = ' + MAPVAR + '.getStreetView();' + LB +
    '  addStreetViewEvent();' + LB;

  if Options.StreetView.Enabled then
  begin
    Result := Result +
      '  var svs = new ' + MAPSERVICEVAR + '.StreetViewService();' + LB +
      '  var loc = ' + MAPVAR + '.getCenter();' + LB +
      '  var point = new ' + MAPSERVICEVAR + '.LatLng(parseFloat(loc.lat()), parseFloat(loc.lng()));' + #13 +
      '  svs.getPanoramaByLocation(point, 50, function(data, status) { ' + LB +
      '    if (status == ' + MAPSERVICEVAR + '.StreetViewStatus.OK) {' + LB +
      '      sv.setPano(data.location.pano);' + LB +
      '      sv.setPov({' +
      '        heading: ' + IntToStr(Options.StreetView.Heading) + ',' +
      '        pitch: ' + IntToStr(Options.StreetView.Pitch) + ',' +
      '        zoom: ' + IntToStr(Options.StreetView.Zoom) + '' +
      '    });' +
      '    sv.setVisible(true);' + LB +
      '    ' + MAPVAR + '.setStreetView(sv);' + LB +
      '   } else {' + #13 +
      '     sv.setVisible(false);' + LB +
      '   }' + LB +
      '  });';
  end;
end;

function TTMSFNCCustomGoogleMaps.GetCustomOptions: string;
begin
  Result := Result +
  '  if (' + PARAMSNAME + '["ShowTraffic"]){' + LB +
  '    ' + MAPTRAFFICLAYER + '.setMap(' + MAPVAR + ');' + LB +
  '  }else{' + LB +
  '    ' + MAPTRAFFICLAYER + '.setMap(null);' + LB +
  '  }';

  Result := Result +
  '  if (' + PARAMSNAME + '["ShowBicycling"]){' + LB +
  '    ' + MAPBICYCLINGLAYER + '.setMap(' + MAPVAR + ');' + LB +
  '  }else{' + LB +
  '    ' + MAPBICYCLINGLAYER + '.setMap(null);' + LB +
  '  }';

  Result := Result +
  'switch (' + PARAMSNAME + '["MapTypeID"]){' + LB +
  '  case 0: ' + MAPVAR + '.setMapTypeId(' + MAP_TYPE_PREFIX + MAP_DEFAULT + '); break;' + LB +
  '  case 1: ' + MAPVAR + '.setMapTypeId(' + MAP_TYPE_PREFIX + MAP_SATELLITE + '); break;' + LB +
  '  case 2: ' + MAPVAR + '.setMapTypeId(' + MAP_TYPE_PREFIX + MAP_HYBRID + '); break;' + LB +
  '  case 3: ' + MAPVAR + '.setMapTypeId(' + MAP_TYPE_PREFIX + MAP_TERRAIN + '); break;' + LB +
  '}' + LB;

  Result := Result +
  '  if (' + PARAMSNAME + '["MapStyle"] != ""){' + LB +
  '   ' + MAPVAR + '.setOptions({ styles: JSON.parse(' + PARAMSNAME + '["MapStyle"])});' + LB +
  '  } else { ' + LB +
  '   ' + MAPVAR + '.setOptions({ styles: []});' + LB +
  '  }' + LB;

  Result := Result + MAPVAR + '.setOptions({ streetViewControl: ' + PARAMSNAME + '["ShowStreetViewControl"]});';
  Result := Result + MAPVAR + '.setOptions({ scaleControl: ' + PARAMSNAME + '["ShowScaleControl"]});';
  Result := Result + MAPVAR + '.setOptions({ keyboardShortcuts: ' + PARAMSNAME + '["ShowKeyboardShortcuts"]});';
  Result := Result + MAPVAR + '.setOptions({ rotateControl: ' + PARAMSNAME + '["ShowRotateControl"]});';

  Result := Result +
  '  if (' + PARAMSNAME + '["StreetView"]["Enabled"]) {' + LB +
  '    var svs = new ' + MAPSERVICEVAR + '.StreetViewService();' + LB +
  '    var loc = ' + MAPVAR + '.getCenter();' + LB +
  '    var point = new ' + MAPSERVICEVAR + '.LatLng(parseFloat(loc.lat()), parseFloat(loc.lng()));' + LB +
  '    svs.getPanoramaByLocation(point, 50, function(data, status) { ' + LB +
  '      if (status == ' + MAPSERVICEVAR + '.StreetViewStatus.OK) {' + LB +
  '        sv.setPano(data.location.pano);' + LB +
  '        sv.setPov({' +
  '          heading: ' + PARAMSNAME + '["StreetView"]["Heading"],' +
  '          pitch: ' + PARAMSNAME + '["StreetView"]["Pitch"],' +
  '          zoom: ' + PARAMSNAME + '["StreetView"]["Zoom"]' +
  '      });' +
  '      sv.setVisible(true);' + LB +
  '     } else {' + #13 +
  '       sv.setVisible(false);' + LB +
  '     }' + LB +
  '    });' + LB +
  '  } else {' + LB +
  '    sv.setVisible(false);' + LB +
  '  }';
end;

function TTMSFNCCustomGoogleMaps.GetAddCustomObjects: string;
var
  I: Integer;
  m: TTMSFNCGoogleMapsMarkersList;
begin
  Result := '';
  for I := 0 to KMLLayers.Count - 1 do
    Result := Result + '  ' + ADDORUPDATEKMLLAYERFUNCTION + '(' + KMLLayers[I].ToJSON + ');' + LB;

  for I := 0 to OverlayViews.Count - 1 do
  begin
    m := OverlayViews[I].Markers;
    Result := Result + '  ' + ADDORUPDATEOVERLAYVIEWFUNCTION + '([' + OverlayViews[I].ToJSON + ',' + m.ToJSON + ']);' + LB;
  end;

  for I := 0 to Clusters.Count - 1 do
    Result := Result + '  ' + ADDORUPDATECLUSTERFUNCTION + '([' + Clusters[I].ToJSON + ', []]);' + LB;
end;

function TTMSFNCCustomGoogleMaps.GetAddOrUpdateClusterFunction: string;
var
  m: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  m := '';

  Result :=
    'function ' + ADDORUPDATECLUSTERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + CLUSTERVAR + ' = ' + CLUSTERARRAYVAR + '[' + PARAMSNAME + '[0]["ID"]];' + LB +

    '  var m = [];' + LB +
    '  for (var k in ' + PARAMSNAME + '[1]){' + LB +
    '    var mi = ' + PARAMSNAME + '[1][k];' + LB +
    '    m.push(' + MARKERARRAYVAR + '[mi["ID"]]);' + LB +
    '  }' + LB +

    '  if (m.length != 0){' + LB +
    '    var imgPath = ' + PARAMSNAME + '[0]["ImagePath"];' + LB +
    '    if (imgPath == "") { imgPath = "https://raw.githubusercontent.com/googlearchive/js-marker-clusterer/gh-pages/images/m"}' + LB +

    '  if (!' + CLUSTERVAR + '){' + LB +
    '    ' + CLUSTERVAR + ' = new window.MarkerClusterer(' + MAPVAR + ', m, {' + LB +
    '      averageCenter: ' + PARAMSNAME + '[0]["AverageCenter"],' + LB +
    '      zoomOnClick: ' + PARAMSNAME + '[0]["ZoomOnClick"],' + LB +
    '      maxZoom: ' + PARAMSNAME + '[0]["MaxZoom"],' + LB +
    '      minimumClusterSize: ' + PARAMSNAME + '[0]["MinimumNumberOfMarkers"],' + LB +
    '      ignoreHidden: ' + PARAMSNAME + '[0]["IgnoreHiddenMarkers"],' + LB +
    '      imagePath: imgPath' + LB +
    '    });' + LB +
    '  } else {' + LB +
    '    ' + CLUSTERVAR + '.clearMarkers();' + LB +
    '    ' + CLUSTERVAR + '.addMarkers(m);' + LB +
    '  };' + LB +

    '    ' + CLUSTERVAR + '.setCalculator(function(markers, numStyles) {' + LB +
    '    var index = 0;' + LB +
    '    var count = markers.length;' + LB +
    '    var dv = count;' + LB +
    '    while (dv !== 0) {' + LB +
    '      dv = parseInt(dv / 10, 10);' + LB +
    '      index++;' + LB +
    '    }' + LB +
    '    var clusterText = ' + PARAMSNAME + '[0]["Text"];' + LB +
    '    if (clusterText != "") { count = clusterText.replace("%d", count)}' + LB +

    '    index = Math.min(index, numStyles);' + LB +
    '    return {' + LB +
    '      text: count,' + LB +
    '      index: index' + LB +
    '    };' + LB +
    '  });' + LB +

    '  }';

  if m <> '' then
    Result := Result + m + LB + LB;

  Result := Result +
    '  if (' + CLUSTERVAR + '){' + LB +
    '    ' + CLUSTERARRAYVAR + '[' + PARAMSNAME + '[0]["ID"]] = ' + CLUSTERVAR + ';' + LB +

    '    ' + MAPSERVICEVAR + '.event.addListener(' + CLUSTERVAR + ', "click", function(c) {' + LB +
    '      var pt = c.getCenter()' + LB +
    '      var lat = parseFloat(pt.lat());' + LB +
    '      var lng = parseFloat(pt.lng());' + LB +
    '      var jsonObj = getDefaultCoordinateObject();' + LB +
    '      jsonObj["Latitude"] = lat;' + LB +
    '      jsonObj["Longitude"] = lng;' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "ClusterClick", ' + PARAMSNAME + '[0]["ID"]), jsonObj);' + LB +
    '    })' + LB +

    '    ' + MAPSERVICEVAR + '.event.addListener(' + CLUSTERVAR + ', "mouseover", function(c) {' + LB +
    '      var pt = c.getCenter()' + LB +
    '      var lat = parseFloat(pt.lat());' + LB +
    '      var lng = parseFloat(pt.lng());' + LB +
    '      var jsonObj = getDefaultCoordinateObject();' + LB +
    '      jsonObj["Latitude"] = lat;' + LB +
    '      jsonObj["Longitude"] = lng;' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "ClusterMouseEnter", ' + PARAMSNAME + '[0]["ID"]), jsonObj);' + LB +
    '    })' + LB +

    '    ' + MAPSERVICEVAR + '.event.addListener(' + CLUSTERVAR + ', "mouseout", function(c) {' + LB +
    '      var pt = c.getCenter()' + LB +
    '      var lat = parseFloat(pt.lat());' + LB +
    '      var lng = parseFloat(pt.lng());' + LB +
    '      var jsonObj = getDefaultCoordinateObject();' + LB +
    '      jsonObj["Latitude"] = lat;' + LB +
    '      jsonObj["Longitude"] = lng;' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "ClusterMouseLeave", ' + PARAMSNAME + '[0]["ID"]), jsonObj);' + LB +
    '    })' + LB +

    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetAddOrUpdateDirectionsFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + ADDORUPDATEDIRECTIONSFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + DIRECTIONSVAR + ' = ' + DIRECTIONSARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +

    '  if (!' + DIRECTIONSVAR + '){ ' + LB;

  Result := Result +
    '  var directionsService;' + LB +
    '  var directionsRenderer;' + LB +

    '  if (! directionsService)' +  LB +
    '    directionsService = new ' + MAPSERVICEVAR + '.DirectionsService();' + LB +
    '  directionsRenderer = new ' + MAPSERVICEVAR + '.DirectionsRenderer({' + LB +
    '    suppressMarkers: !' + PARAMSNAME + '["ShowMarkers"], ' + LB +
    '    suppressPolylines: !' + PARAMSNAME + '["ShowPolyline"], ' + LB +
    '    polylineOptions: {' + LB +
    '      strokeColor: ' + PARAMSNAME + '["StrokeColor"],' + LB +
    '      strokeWeight: ' + PARAMSNAME + '["StrokeWidth"],' + LB +
    '      strokeOpacity: ' + PARAMSNAME + '["StrokeOpacity"]' + LB +
    '    }' + LB +
    '  });' + LB +
    '  directionsRenderer.setMap(' + MAPVAR + ');' + LB +
    '  var point;' + LB +
    '  var point2;' + LB +
    '  if ((' + PARAMSNAME + '["Origin"] != "") && (' + PARAMSNAME + '["Destination"] != "")) {' + LB +
    '    point = {query: ' + PARAMSNAME + '["Origin"]};' + LB +
    '    point2 = {query: ' + PARAMSNAME + '["Destination"]};' + LB +
    '  } else { ' + LB +
    '    point = new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["OriginCoordinate"]["Latitude"], ' + PARAMSNAME + '["OriginCoordinate"]["Longitude"]);' + LB +
    '    point2 = new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '["DestinationCoordinate"]["Latitude"], ' + PARAMSNAME + '["DestinationCoordinate"]["Longitude"]);' + LB +
    '  }' + LB +
    '  var sWayPoints = "";' + LB +
    '  var wpList = ' + PARAMSNAME + '["WayPoints"];' + LB +
    '  if (wpList != ""){' + LB +
    '    for (i = 0; i < wpList.length; i++) {' + LB +
    '      if (i > 0)' + LB +
    '        sWayPoints = sWayPoints + ", ";' + LB +
    '      sWayPoints = sWayPoints + "{\"location\": \"" + wpList[i] + "\"}";' + LB +
    '    }' + LB +
    '  }' + LB +
    '  sWayPoints = JSON.parse("[" + sWayPoints + "]");' + LB +
    '  var sTravelMode = ' + MAPSERVICEVAR + '.TravelMode.DRIVING;' + LB +
    '  if (' + PARAMSNAME + '["TravelMode"] == 1)' + LB +
    '    sTravelMode = ' + MAPSERVICEVAR + '.TravelMode.WALKING;' + LB +
    '  else if (' + PARAMSNAME + '["TravelMode"] == 2)' + LB +
    '    sTravelMode = ' + MAPSERVICEVAR + '.TravelMode.BICYCLING;' + LB +
    '  else if (' + PARAMSNAME + '["TravelMode"] == 3)' + LB +
    '    sTravelMode = ' + MAPSERVICEVAR + '.TravelMode.TRANSIT;' + LB +
    '  var request = {' + LB +
    '    origin: point,' + LB +
    '    destination: point2,' + LB +
    '    travelMode: sTravelMode,' + LB +
    '    waypoints: sWayPoints,' + LB +
    '    optimizeWaypoints: ' + PARAMSNAME + '["OptimizeWayPoints"],' + LB +
    '    avoidTolls: ' + PARAMSNAME + '["AvoidTolls"]' + LB +
    '  };' + LB +
    '  directionsService.route(request, function(result, status) {' + LB +
    '    var eventObj = {''Coordinate'': {}, ''X'': 0, ''Y'': 0, ''ID'': ' + PARAMSNAME + '["ID"], ''EventName'': "RetrievedDirectionsData"};' + LB +
    '    if (status === "OK") { ' + LB +
    '      directionsRenderer.setDirections(result);' + LB +
    '      directionsObj = directionsRenderer.getDirections();' + LB +
    '      ' + GETSENDEVENT + '(eventObj, directionsObj);' + LB +
    '    } else { ' + LB +
    '      ' + GETSENDEVENT + '(eventObj, []);' + LB +
    '    }' + LB +
    '  });' + LB +
    '  ' + DIRECTIONSVAR + ' = directionsRenderer;' + LB +
    '}' + LB +

    '  if (' + DIRECTIONSVAR + '){' + LB +
    '    ' + DIRECTIONSARRAYVAR + '[' + PARAMSNAME + '["ID"]] = ' + DIRECTIONSVAR + ';' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetDeleteDirectionsFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + DELETEDIRECTIONSFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + DIRECTIONSVAR + ' = ' + DIRECTIONSARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '  if (' + DIRECTIONSVAR + '){' + LB +
    '    ' + DIRECTIONSVAR + '.setMap(null);' + LB +
    '    delete ' + DIRECTIONSARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + DIRECTIONSVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetAddOrUpdateKMLLayerFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + ADDORUPDATEKMLLAYERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + KMLLAYERVAR + ' = ' + KMLLAYERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +

    '  var kmlOptions = {' + LB +
    '    clickable: true,' + LB +
    '    suppressInfoWindows: false,' + LB +
    '    preserveViewport: (' + PARAMSNAME + '["ZoomToBounds"] == false),' + LB +
    '    map: ' + MAPVAR + LB +
    '  }' + LB + LB +
    '  if (!' + KMLLAYERVAR + ') ' + LB +
    '  ' + KMLLAYERVAR + ' = new ' + MAPSERVICEVAR + '.KmlLayer(' + PARAMSNAME + '["URL"], kmlOptions);' + LB +

    '    ' + KMLLAYERVAR + '.addListener(''click'', function(event){' + LB +
    '      ' + GETSENDEVENT + '(parseEvent(event, "KMLLayerClick", ' + PARAMSNAME + '["ID"]));' + LB +
    '    })' + LB + LB +

    '  if (' + KMLLAYERVAR + '){' + LB +
    '    ' + KMLLAYERARRAYVAR + '[' + PARAMSNAME + '["ID"]] = ' + KMLLAYERVAR + ';' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetAddOrUpdateOverlayViewFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + ADDORUPDATEOVERLAYVIEWFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var m = [];' + LB +
    '  var mid = "";' + LB +
    '  for (var k in ' + PARAMSNAME + '[1]){' + LB +
    '    var mi = ' + PARAMSNAME + '[1][k];' + LB +
    '    mid = mi["ID"];' + LB +
    '    m.push(' + MARKERARRAYVAR + '[mi["ID"]]);' + LB +
    '  }' + LB +

    'class FNCGoogleMapsOverlayView extends ' + MAPSERVICEVAR + '.OverlayView {' + LB +
    ' overlayviewid;' + LB +
    ' bounds;' + LB +
    ' text;' + LB +
    ' marker;' + LB +
    ' visible;' + LB +
    ' mode;' + LB +
    ' padding;' + LB +
    ' backgroundcolor;' + LB +
    ' bordercolor;' + LB +
    ' offsettop;' + LB +
    ' color;' + LB +
    ' fontsize;' + LB +
    ' fontname;' + LB +
    ' coordinateposition;' + LB +
    ' centerlatitude;' + LB +
    ' centerlongitude;' + LB +
    ' clickable;' + LB +
    ' divheight;' + LB +
    ' divwidth;' + LB +
    ' fontstyle;' + LB +
    ' div;' + LB +
    ' span;' + LB +
    ' constructor(id, bounds, marker, text, mode) {' + LB +
    '   super();' + LB +
    '   this.overlayviewid = id;' + LB +
    '   this.bounds = bounds;' + LB +
    '   this.text = text;' + LB +
    '   this.marker = marker;' + LB +
    '   this.visible = true;' + LB +
    '   this.mode = 1;' + LB +
    '   this.padding = 3;' + LB +
    '   this.backgroundcolor = "white";' + LB +
    '   this.bordercolor = "black";' + LB +
    '   this.offsettop = 0;' + LB +
    '   this.offsetleft = 0;' + LB +
    '   this.color = "black";' + LB +
    '   this.fontsize = 12;' + LB +
    '   this.coordinateposition = 1;' + LB +
    '   this.centerlatitude = 0;' + LB +
    '   this.centerlongitude = 0;' + LB +
    '   this.centercoordinate = new ' + MAPSERVICEVAR + '.LatLng(parseFloat(this.centerlatitude),parseFloat(this.centerlongitude));' + #13 +
    '   this.clickable = true;' + LB +
    '   this.divwidth = -1;' + LB +
    '   this.divheight = -1;' + LB +
    '   this.fontstyle = 0;' + LB +
    '   this.mode = mode;' + LB +
    ' }' + LB +
    ' onAdd() {' + LB +
    '   this.div = document.createElement("div");' + LB +
    '   this.div.style.position = "absolute";' + LB +
    '   this.div.style.top = this.offsettop;' + LB +
    '   this.div.style.left = this.offsetleft;' + LB +

    '   this.span = document.createElement("div");' + #13 +
    '   this.span.style.position = "relative";' + LB +
    '   this.span.style.whiteSpace = "nowrap";' + LB +
    '   this.span.style.borderStyle = "solid";' + LB +
    '   this.span.style.borderWidth = "1px";' + LB +
    '   this.span.innerHTML = this.text;' + LB +
    '   this.div.appendChild(this.span);' + #13 +

    '     this.calculatePosition();' + LB +

    '   const panes = this.getPanes();' + LB +
    {$IFDEF FMXMOBILE}
    '   panes.overlayLayer.appendChild(this.div);' + LB +
    {$ELSE}
    '   panes.overlayMouseTarget.appendChild(this.div);' + LB +
    {$ENDIF}

    '   var me = this;' + LB +
    '   ' + 'this.span.addEventListener("click", function(event){' + LB +
    '     if (me.clickable) {' + LB +
    '       ' + GETSENDEVENT + '(parseEvent(event, "OverlayViewClick", me.overlayviewid));' + LB +
    '     }' + LB + LB +
    '   })' + LB + LB +

    ' }' + LB +
    ' calculatePosition(){' + LB +
//0 ppTopLeft
//1 ppTopCenter
//2 ppTopRight
//3 ppBottomLeft
//4 ppBottomCenter
//5 ppBottomRight
//6 ppCenterLeft
//7 ppCenterCenter
//8 ppCenterRight
//9 ppCustom

    '   if (this.mode == 1) {' + LB +
    '     var ohv;' + LB +
    '     var oh;' + LB +
    '     var hoh;' + LB +
    '     if ((this.coordinateposition >= 3) && (this.coordinateposition <= 8)) {' + LB +
    '       ohv = this.span.clientHeight;' + LB +
    '       oh = (ohv * -1) + "px";' + LB +
    '       hoh = ((ohv * -1) / 2) + "px";' + LB +
    '     }' + LB +
    '     switch(this.coordinateposition) {' + LB +
    '       case 0:' + LB +
    '         this.span.style.left = "0";' + LB +
    '         this.span.style.top = "0";' + LB +
    '         break;' + LB +
    '       case 1:' + LB +
    '         this.span.style.left = "-50%";' + LB +
    '         this.span.style.top = "0";' + LB +
    '         break;' + LB +
    '       case 2:' + LB +
    '         this.span.style.left = "-100%";' + LB +
    '         this.span.style.top = "0";' + LB +
    '         break;' + LB +
    '       case 3:' + LB +
    '         this.span.style.left = "0";' + LB +
    '         this.span.style.top = oh;' + LB +
    '         break;' + LB +
    '       case 4:' + LB +
    '         this.span.style.left = "-50%";' + LB +
    '         this.span.style.top = oh;' + LB +
    '         break;' + LB +
    '       case 5:' + LB +
    '         this.span.style.left = "-100%";' + LB +
    '         this.span.style.top = oh;' + LB +
    '         break;' + LB +
    '       case 6:' + LB +
    '         this.span.style.left = "0";' + LB +
    '         this.span.style.top = hoh;' + LB +
    '         break;' + LB +
    '       case 7:' + LB +
    '         this.span.style.left = "-50%";' + LB +
    '         this.span.style.top = hoh;' + LB +
    '         break;' + LB +
    '       case 8:' + LB +
    '         this.span.style.left = "-100%";' + LB +
    '         this.span.style.top = hoh;' + LB +
    '         break;' + LB +
    '     }' + LB +
    '   }' + LB +
    '}' + LB +
    ' draw() {' + LB +
    '   const overlayProjection = this.getProjection();' + LB +
    '   if (overlayProjection) {' + LB +
    '     const sw = overlayProjection.fromLatLngToDivPixel(' + LB +
    '       this.bounds.getSouthWest()' + LB +
    '     );' + LB +
    '     const ne = overlayProjection.fromLatLngToDivPixel(' + LB +
    '       this.bounds.getNorthEast()' + LB +
    '     );' + LB +

    '     if ((this.div) && (this.span)) {' + LB +
    '       if (this.mode == 1) {' + LB +
    '         var position;' + LB +
    '         if (this.marker) {' + LB +
    '           position = overlayProjection.fromLatLngToDivPixel(this.marker.get("position"));' + #13 +
    '         } else {' + LB +
    '           this.centercoordinate = new ' + MAPSERVICEVAR + '.LatLng(parseFloat(this.centerlatitude),parseFloat(this.centerlongitude));' + #13 +
    '           position = overlayProjection.fromLatLngToDivPixel(this.centercoordinate);' + #13 +
    '         }' + LB +
    '         this.div.style.left = this.offsetleft + position.x + "px";' + LB +
    '         this.div.style.top = this.offsettop + position.y + "px";' + LB +
    '         this.span.style.whiteSpace = "nowrap";' + LB +
    '         if (parseInt(this.divwidth) > -1) {' + LB +
    '           this.div.style.width = this.divwidth + "px";' + LB +
    '           this.span.style.whiteSpace = "normal";' + LB +
    '         } else' + LB +
    '           this.div.style.width = "auto";' + LB +
    '         if (parseInt(this.divheight) > -1)' + LB +
    '           this.div.style.height = this.divheight + "px";' + LB +
    '         else' + LB +
    '           this.div.style.height = "auto";' + LB +
    '         this.span.style.width = "auto";' + LB +
    '         this.span.style.height = "auto";' + LB +
    '       } else {' + LB +
    '         this.div.style.left = sw.x + "px";' + LB +
    '         this.div.style.top = ne.y + "px";' + LB +
    '         this.div.style.width = (ne.x - sw.x) + "px";' + LB +
    '         this.div.style.height = (sw.y - ne.y) + "px";' + LB +
    '         this.span.style.width = "100%";' + LB +
    '         this.span.style.height = "100%";' + LB +
    '         this.span.style.whiteSpace = "normal";' + LB +
    '       }' + LB +
    '       if (this.visible)' + LB +
    '         this.div.style.display = "block";' + LB +
    '       else' + LB +
    '         this.div.style.display = "none";' + LB +

    //hide label if connected to a marker not displayed on the map
    // (inside a marker cluster)
    '       if (this.marker) {' + LB +
    '         if (! this.marker.getMap())' + LB +
    '           this.div.style.display = "none";' + LB +
    '       }' + LB +

    '     this.span.innerHTML = this.text;' + LB +
    '     this.span.style.padding = this.padding + "px";' + LB +
    '     if (this.backgroundcolor == "gcNull")' + LB +
    '       this.span.style.backgroundColor = "transparent";' + LB +
    '     else' + LB +
    '       this.span.style.backgroundColor = this.backgroundcolor;' + LB +
    '     if (this.bordercolor == "gcNull")' + LB +
    '       this.span.style.borderColor = "transparent";' + LB +
    '     else' + LB +
    '       this.span.style.borderColor = this.bordercolor;' + LB +
    '     this.span.style.color = this.color;' + LB +
    '     this.span.style.fontSize = this.fontsize + "px";' + LB +
    '     this.span.style.fontFamily = this.fontname;' + LB +
    '     this.span.style.fontWeight = "normal";' + LB +
    '     this.span.style.fontStyle = "normal";' + LB +
    '     this.span.style.textDecoration = "none";' + LB +
    '     if ((this.fontstyle == 1) || (this.fontstyle == 3) || (this.fontstyle == 7) || (this.fontstyle == 15))' + LB +
    '       this.span.style.fontWeight = "bold";' + LB +
    '     if ((this.fontstyle == 2) || (this.fontstyle == 3) || (this.fontstyle == 7) || (this.fontstyle == 15))' + LB +
    '       this.span.style.fontStyle = "italic";' + LB +
    '     if ((this.fontstyle == 4) || (this.fontstyle == 5) || (this.fontstyle == 7) || (this.fontstyle == 15))' + LB +
    '       this.span.style.textDecoration = "underline";' + LB +
    '     if ((this.fontstyle == 8) || (this.fontstyle == 9) || (this.fontstyle == 11))' + LB +
    '       this.span.style.textDecoration = "line-through";' + LB +
    '     if ((this.fontstyle == 12) || (this.fontstyle == 13) || (this.fontstyle == 14) || (this.fontstyle == 15))' + LB +
    '       this.span.style.textDecoration = "underline line-through";' + LB +
    '     this.calculatePosition();' + LB +
    '   }' + LB +
    '  }' + LB +
    ' }' + LB +
    ' onRemove() {' + LB +
    '   if (this.div) {' + LB +
    '     this.div.parentNode.removeChild(this.div);' + LB +
    '     delete this.div;' + LB +
    '   }' + LB +
    ' }' + LB +
    ' hide() {' + LB +
    '   if (this.div) {' + LB +
    '     this.div.style.visibility = "hidden";' + LB +
    '   }' + LB +
    ' }' + LB +
    ' show() {' + LB +
    '   if (this.div) {' + LB +
    '     this.div.style.visibility = "visible";' + LB +
    '   }' + LB +
    ' }' + LB +
    ' toggle() {' + LB +
    '   if (this.div) {' + LB +
    '     if (this.div.style.visibility === "hidden") {' + LB +
    '       this.show();' + LB +
    '     } else {' + LB +
    '       this.hide();' + LB +
    '     }' + LB +
    '   }' + LB +
    ' }' + LB +
    '}' + LB + LB +

    ' if (' + PARAMSNAME + '[0]) { ' + LB +
    '  const bounds = new ' + MAPSERVICEVAR + '.LatLngBounds(' + LB +
    '    new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '[0]["Bounds"]["SouthWest"]["Latitude"], ' + PARAMSNAME + '[0]["Bounds"]["SouthWest"]["Longitude"]),' + LB +
    '    new ' + MAPSERVICEVAR + '.LatLng(' + PARAMSNAME + '[0]["Bounds"]["NorthEast"]["Latitude"], ' + PARAMSNAME + '[0]["Bounds"]["NorthEast"]["Longitude"])' + LB +
    '  );' + LB +

    '  var ' + OVERLAYVIEWVAR + ' = ' + OVERLAYVIEWARRAYVAR + '[' + PARAMSNAME + '[0]["ID"]];' + LB +
    '  var labelmarker = null;' + LB +
    '  if (m.length > 0) ' + LB +
    '    labelmarker = m[0];' + LB +

    '  if (!' + OVERLAYVIEWVAR + ') {' + LB +
    '    ' + OVERLAYVIEWVAR + ' = new FNCGoogleMapsOverlayView(' + PARAMSNAME + '[0]["ID"], bounds, labelmarker, ' + PARAMSNAME + '[0]["Text"], ' + PARAMSNAME + '[0]["Mode"]);' + LB +
    '    ' + OVERLAYVIEWVAR + '.setMap(' + MAPVAR + ');' + LB +
    '  }' + LB +

    '  if (' + OVERLAYVIEWVAR + '){' + LB +
    '    ' + OVERLAYVIEWARRAYVAR + '[' + PARAMSNAME + '[0]["ID"]] = ' + OVERLAYVIEWVAR + ';' + LB +
    '    ' + OVERLAYVIEWVAR + '.text = ' + PARAMSNAME + '[0]["Text"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.mode = ' + PARAMSNAME + '[0]["Mode"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.visible = ' + PARAMSNAME + '[0]["Visible"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.padding = ' + PARAMSNAME + '[0]["Padding"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.backgroundcolor = ' + PARAMSNAME + '[0]["BackgroundColor"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.bordercolor = ' + PARAMSNAME + '[0]["BorderColor"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.offsettop = ' + PARAMSNAME + '[0]["CoordinateOffsetTop"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.offsetleft = ' + PARAMSNAME + '[0]["CoordinateOffsetLeft"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.color = ' + PARAMSNAME + '[0]["Font"]["Color"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.fontsize = ' + PARAMSNAME + '[0]["Font"]["Size"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.fontname = ' + PARAMSNAME + '[0]["Font"]["Name"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.coordinateposition = ' + PARAMSNAME + '[0]["CoordinatePosition"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.centerlatitude = ' + PARAMSNAME + '[0]["Coordinate"]["Latitude"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.centerlongitude = ' + PARAMSNAME + '[0]["Coordinate"]["Longitude"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.clickable = ' + PARAMSNAME + '[0]["Clickable"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.divwidth = ' + PARAMSNAME + '[0]["Width"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.divheight = ' + PARAMSNAME + '[0]["Height"];' + #13 +
    '    ' + OVERLAYVIEWVAR + '.fontstyle = ' + PARAMSNAME + '[0]["Font"]["Style"];' + #13 +
    '    if (labelmarker) ' + LB +
    '      ' + OVERLAYVIEWVAR + '.marker = labelmarker;' + #13 +
    '    ' + OVERLAYVIEWVAR + '.draw();' + LB +
    '  }' + LB +
    ' }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetDeleteClusterFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + DELETECLUSTERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + CLUSTERVAR + ' = ' + CLUSTERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '  if (' + CLUSTERVAR + '){' + LB +
    '    ' + CLUSTERVAR + '.clearMarkers();' + LB +
    '    ' + CLUSTERVAR + '.setMap(null);' + LB +
    '    delete ' + CLUSTERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + CLUSTERVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetDeleteKMLLayerFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + DELETEKMLLAYERFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + KMLLAYERVAR + ' = ' + KMLLAYERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '  if (' + KMLLAYERVAR + '){' + LB +
    '    ' + KMLLAYERVAR + '.setMap(null);' + LB +
    '    delete ' + KMLLAYERARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + KMLLAYERVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetDeleteOverlayViewFunction: string;
begin
  Result := '';
  if not Assigned(MapsInstance) then
    Exit;

  Result :=
    'function ' + DELETEOVERLAYVIEWFUNCTION + '(' + PARAMSNAME + '){' + LB +
    '  var ' + OVERLAYVIEWVAR + ' = ' + OVERLAYVIEWARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '  if (' + OVERLAYVIEWVAR + '){' + LB +
    '    ' + OVERLAYVIEWVAR + '.setMap(null);' + LB +
    '    delete ' + OVERLAYVIEWARRAYVAR + '[' + PARAMSNAME + '["ID"]];' + LB +
    '    ' + OVERLAYVIEWVAR + ' = null;' + LB +
    '  }' + LB +
    '}';
end;

function TTMSFNCCustomGoogleMaps.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncmaps/components/ttmsfncmaps/#ttmsfncgooglemaps';
end;

function TTMSFNCCustomGoogleMaps.GetHeading: Double;
begin
  Result := Options.Heading;
end;

procedure TTMSFNCCustomGoogleMaps.GetLinks(AList: TTMSFNCMapsLinksList; AIncludeContent: Boolean = True; ACheckReady: Boolean = True);
begin
  inherited;
  AList.Insert(0, TTMSFNCMapsLink.CreateScript(MARKERCLUSTERERSCRIPT, 'text/JavaScript', '', ''));
end;

function TTMSFNCCustomGoogleMaps.GetMapID: string;
begin
  Result := Options.MapID;
end;

function TTMSFNCCustomGoogleMaps.GetMarkers: TTMSFNCGoogleMapsMarkers;
begin
  Result := TTMSFNCGoogleMapsMarkers(inherited Markers);
end;

function TTMSFNCCustomGoogleMaps.GetMarkersClass: TTMSFNCMapsMarkersClass;
begin
  Result := TTMSFNCGoogleMapsMarkers;
end;

function TTMSFNCCustomGoogleMaps.GetCustomGlobalVariables: string;
begin
  Result :=
    'var ' + STREETVIEWCHANGE + ';' + LB +
    'var ' + MAPTRAFFICLAYER + ';' + LB +
    'var ' + MAPBICYCLINGLAYER + ';' + LB +
    'var ' + KMLLAYERVAR + ';' + LB +
    'var ' + KMLLAYERARRAYVAR + ' = {};' + LB +
    'var ' + OVERLAYVIEWARRAYVAR + ' = {};' + LB +
    'function ' + GETKMLLAYERARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + KMLLAYERARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'function ' + GETOVERLAYVIEWARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + OVERLAYVIEWARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'var ' + DIRECTIONSVAR + ';' + LB +
    'var ' + DIRECTIONSARRAYVAR + ' = {};' + LB +
    'function ' + GETDIRECTIONSARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + DIRECTIONSARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB + LB +
    'var ' + CLUSTERVAR + ';' + LB +
    'var ' + CLUSTERARRAYVAR + ' = {};' + LB +
    'function ' + GETCLUSTERARRAYVAR + '{' + LB +
    '  var arr = [];' + LB +
    '  for (var key in ' + CLUSTERARRAYVAR + '){' + LB +
    '    var v = key;' + LB +
    '    arr.push(v);' + LB +
    '  }' + LB +
    '  return arr.toString();' + LB +
    '}' + LB;

  Result := Result +
    'function addStreetViewEvent() {' + LB +
    '  sv.addListener(''pov_changed'', function streetviewEventHandler() { ' + LB +
    '    var pov = this.getPov();' + LB +
    '    var heading = Math.round(pov.heading); ' + LB +
    '    var pitch = Math.round(pov.pitch); ' + LB +
    '    var zoom = Math.round(pov.zoom); ' + LB +
    '    var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '    var pos = this.getPosition();' + LB +
    '    if (pos) ' + LB +
    '       loc = {''Latitude'': pos.lat(), ''Longitude'': pos.lng()};' + LB +
    '    var eventObj = {''Coordinate'': loc, ''X'': 0, ''Y'': 0, ''ID'': '''', ''EventName'': "StreetViewChange"};' + LB +
    '    var jsonObj = {''Heading'': heading, ''Pitch'': pitch, ''Zoom'': zoom};' + LB +
    '    ' + GETSENDEVENT + '(eventObj, jsonObj);' + LB +
    '  });' + LB +

    '  sv.addListener(''visible_changed'', function streetviewVisibilityHandler() { ' + LB +
    '    var visible = this.getVisible();' + LB +
    '    var loc = {''Latitude'': 0, ''Longitude'': 0};' + LB +
    '    var pos = this.getPosition();' + LB +
    '    if (pos) ' + LB +
    '       loc = {''Latitude'': pos.lat(), ''Longitude'': pos.lng()};' + LB +
    '    var eventObj = {''Coordinate'': loc, ''X'': 0, ''Y'': 0, ''ID'': '''', ''EventName'': "StreetViewEnabledChange"};' + LB +
    '    var jsonObj = {''Visible'': visible};' + LB +
    '    ' + GETSENDEVENT + '(eventObj, jsonObj);' + LB +
    '  });' + LB +
    '}' + LB;
end;

function TTMSFNCCustomGoogleMaps.GetCircles: TTMSFNCGoogleMapsCircles;
begin
  Result := TTMSFNCGoogleMapsCircles(inherited Circles);
end;

function TTMSFNCCustomGoogleMaps.GetCirclesClass: TTMSFNCMapsCirclesClass;
begin
  Result := TTMSFNCGoogleMapsCircles;
end;

function TTMSFNCCustomGoogleMaps.GetCustomFunctions: string;
begin
  Result :=
  GetAddOrUpdateOverlayViewFunction + LB + LB +
  GetDeleteOverlayViewFunction + LB + LB +
  GetAddOrUpdateKMLLayerFunction + LB + LB +
  GetDeleteKMLLayerFunction + LB + LB +
  GetAddOrUpdateDirectionsFunction + LB + LB +
  GetDeleteDirectionsFunction + LB + LB +
  GetAddOrUpdateClusterFunction + LB + LB +
  GetDeleteClusterFunction;
end;

function TTMSFNCCustomGoogleMaps.GetOptions: TTMSFNCGoogleMapsOptions;
begin
  Result := inherited Options as TTMSFNCGoogleMapsOptions;
end;

function TTMSFNCCustomGoogleMaps.GetAPIVersion: string;
begin
  Result := Options.Version;
end;

function TTMSFNCCustomGoogleMaps.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
end;

function TTMSFNCCustomGoogleMaps.ParseDirectionsDataInt(AID,
  AJSON: string): TTMSFNCGoogleMapsDirectionsData;
var
  jv, jr, ja, js, jc, jd, jp, jb, jf, jg, jl: TJSONValue;
  jar, jas, jal, jak: TJSONArray;
  I, J, K, L, M, TotalLength: Integer;
begin
  TotalLength := 0;
  M := 0;
  jv := TTMSFNCUtils.ParseJSON(AJSON);

  if Assigned(jv) then
  begin
    try
      jr:= TTMSFNCUtils.GetJSONValue(jv, 'routes');

      if Assigned(jr) and (jr is TJSONArray) then
      begin
        jar := jr as TJSONArray;

        SetLength(Result.Routes, TTMSFNCUtils.GetJSONArraySize(jar));
        for I := 0 to TTMSFNCUtils.GetJSONArraySize(jar) - 1 do
        begin
          ja := TTMSFNCUtils.GetJSONArrayItem(jar, I);

          Result.Routes[I].Name := TTMSFNCUtils.GetJSONProp(ja, 'summary');
//          Result.Routes[I].Bounds := TTMSFNCUtils.GetJSONIntegerValue(ja, 'bounds');

          js := TTMSFNCUtils.GetJSONValue(ja, 'legs');

          if Assigned(js) and (js is TJSONArray) then
          begin
            jas := js as TJSONArray;

            SetLength(Result.Routes[I].Legs, TTMSFNCUtils.GetJSONArraySize(jas));
            for J := 0 to TTMSFNCUtils.GetJSONArraySize(jas) - 1 do
            begin
              jc := TTMSFNCUtils.GetJSONArrayItem(jas, J);

              Result.Routes[I].Legs[J].Origin := TTMSFNCUtils.GetJSONProp(jc, 'start_address');
              Result.Routes[I].Legs[J].Destination := TTMSFNCUtils.GetJSONProp(jc, 'end_address');

              jl := TTMSFNCUtils.GetJSONValue(jc, 'start_location');
              if Assigned(jl) then
              begin
                Result.Routes[I].Legs[J].OriginCoordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(jl, 'lat');
                Result.Routes[I].Legs[J].OriginCoordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(jl, 'lng');
              end;

              jl := TTMSFNCUtils.GetJSONValue(jc, 'end_location');
              if Assigned(jl) then
              begin
                Result.Routes[I].Legs[J].DestinationCoordinate.Latitude := TTMSFNCUtils.GetJSONDoubleValue(jl, 'lat');
                Result.Routes[I].Legs[J].DestinationCoordinate.Longitude := TTMSFNCUtils.GetJSONDoubleValue(jl, 'lng');
              end;

              jd := TTMSFNCUtils.GetJSONValue(jc, 'distance');
              if Assigned(jd) then
                Result.Routes[I].Legs[J].Distance := TTMSFNCUtils.GetJSONIntegerValue(jd, 'value');
              jd := TTMSFNCUtils.GetJSONValue(jc, 'duration');
              if Assigned(jd) then
                Result.Routes[I].Legs[J].Duration := TTMSFNCUtils.GetJSONIntegerValue(jd, 'value');

              jp := TTMSFNCUtils.GetJSONValue(jc, 'steps');

              if Assigned(jp) and (jp is TJSONArray) then
              begin
                jal := jp as TJSONArray;

                SetLength(Result.Routes[I].Legs[J].Steps, TTMSFNCUtils.GetJSONArraySize(jal));
                for K := 0 to TTMSFNCUtils.GetJSONArraySize(jal) - 1 do
                begin
                  jb := TTMSFNCUtils.GetJSONArrayItem(jal, K);

                  Result.Routes[I].Legs[J].Steps[K].Instructions := TTMSFNCUtils.GetJSONProp(jb, 'instructions');

                  jd := TTMSFNCUtils.GetJSONValue(jb, 'distance');
                  if Assigned(jd) then
                    Result.Routes[I].Legs[J].Steps[K].Distance := TTMSFNCUtils.GetJSONIntegerValue(jd, 'value');
                  jd := TTMSFNCUtils.GetJSONValue(jb, 'duration');
                  if Assigned(jd) then
                    Result.Routes[I].Legs[J].Steps[K].Duration := TTMSFNCUtils.GetJSONIntegerValue(jd, 'value');

                  if TTMSFNCUtils.GetJSONProp(jb, 'travel_mode') = 'DRIVING' then
                    Result.Routes[I].Legs[J].Steps[K].TravelMode := dtmDriving
                  else
                    Result.Routes[I].Legs[J].Steps[K].TravelMode := dtmWalking;

                  jf := TTMSFNCUtils.GetJSONValue(jb, 'path');

                  if Assigned(jf) and (jf is TJSONArray) then
                  begin
                    jak := jf as TJSONArray;

                    TotalLength := TotalLength + TTMSFNCUtils.GetJSONArraySize(jak);
                    SetLength(Result.Routes[I].Legs[J].Steps[K].Path, TTMSFNCUtils.GetJSONArraySize(jak));
                    for L := 0 to TTMSFNCUtils.GetJSONArraySize(jak) - 1 do
                    begin
                      jg := TTMSFNCUtils.GetJSONArrayItem(jak, L);

                      Result.Routes[I].Legs[J].Steps[K].Path[L].Latitude := TTMSFNCUtils.GetJSONDoubleValue(jg, 'lat');
                      Result.Routes[I].Legs[J].Steps[K].Path[L].Longitude := TTMSFNCUtils.GetJSONDoubleValue(jg, 'lng');
                    end;
                  end;
                end;
              end;
            end;
          end;

          SetLength(Result.Routes[I].Path, TotalLength);
          for J := 0 to Length(Result.Routes[I].Legs) - 1 do
          begin
            for K := 0 to Length(Result.Routes[I].Legs[J].Steps) - 1 do
            begin
              for L := 0 to Length(Result.Routes[I].Legs[J].Steps[K].Path) - 1 do
              begin
                Result.Routes[I].Path[M].Latitude := Result.Routes[I].Legs[J].Steps[K].Path[L].Latitude;
                Result.Routes[I].Path[M].Longitude := Result.Routes[I].Legs[J].Steps[K].Path[L].Longitude;
                M := M + 1;
              end;
            end;
          end;
        end;
      end;
    finally
      jv.Free;
    end;
  end;
end;

function TTMSFNCCustomGoogleMaps.ParseStreetViewDataInt(AID,
  AJSON: string): TTMSFNCGoogleMapsStreetViewData;
var
  jv, jve: TJSONValue;
begin
  jv := TTMSFNCUtils.ParseJSON(AJSON);

  if Assigned(jv) then
  begin
    try
      jve := TTMSFNCUtils.GetJSONValue(jv, 'Visible');
      if Assigned(jve) then
        Result.Enabled := TTMSFNCUtils.GetJSONValueAsBoolean(jve);
    finally
      jv.Free;
    end;
  end;
end;

procedure TTMSFNCCustomGoogleMaps.ClearDirections;
begin
  BeginUpdate;
  Directions.Clear;
  EndUpdate;
end;

function TTMSFNCCustomGoogleMaps.AddDirectionsInt(AOrigin, ADestination: string;
  AOriginCoordinate, ADestinationCoordinate: TTMSFNCMapsCoordinateRec;
  AShowMarkers: Boolean; AShowPolyline: Boolean; AStrokeColor: TTMSFNCGraphicsColor;
  AStrokeWidth: Integer;
  AStrokeOpacity: Single;
  ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode; AAVoidTolls: Boolean;
  AWayPoints: TStringList; AOptimizeWayPoints: Boolean): TTMSFNCGoogleMapsDirectionsItem;
begin
  BeginUpdate;
  Result := Directions.Add;
  Result.FOrigin := AOrigin;
  Result.FDestination := ADestination;
  Result.FOriginCoordinate.Latitude := AOriginCoordinate.Latitude;
  Result.FOriginCoordinate.Longitude := AOriginCoordinate.Longitude;
  Result.FDestinationCoordinate.Latitude := ADestinationCoordinate.Latitude;
  Result.FDestinationCoordinate.Longitude := ADestinationCoordinate.Longitude;
  Result.FShowMarkers := AShowMarkers;
  Result.FShowPolyline := AShowPolyline;
  Result.FStrokeColor := AStrokeColor;
  Result.FStrokeWidth := AStrokeWidth;
  Result.FStrokeOpacity := AStrokeOpacity;
  Result.FTravelMode := ATravelMode;
  Result.FAvoidTolls := AAvoidTolls;
  if Assigned(AWayPoints) then
    Result.FWayPoints.Assign(AWayPoints);
  Result.FOptimizeWayPoints := AOptimizeWayPoints;
  EndUpdate;
end;

function TTMSFNCCustomGoogleMaps.AddDirections(AOrigin,
  ADestination: string; AShowMarkers: Boolean; AShowPolyline: Boolean; AStrokeColor: TTMSFNCGraphicsColor; AStrokeWidth: Integer; AStrokeOpacity: Single;
  ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode; AAvoidTolls: Boolean; AWayPoints: TStringList; AOptimizeWayPoints: Boolean): TTMSFNCGoogleMapsDirectionsItem;
begin
  Result := AddDirectionsInt(AOrigin, ADestination, DefaultCoordinate, DefaultCoordinate, AShowMarkers, AShowPolyline, AStrokeColor, AStrokeWidth, AStrokeOpacity, ATravelMode, AAVoidTolls, AWayPoints, AOptimizeWayPoints);
end;

function TTMSFNCCustomGoogleMaps.AddCircle(ACenter: TTMSFNCMapsCoordinateRec;
  ARadius: Double): TTMSFNCGoogleMapsCircle;
begin
  Result := TTMSFNCGoogleMapsCircle(inherited AddCircle(ACenter, ARadius));
end;

function TTMSFNCCustomGoogleMaps.AddDirections(AOriginLatitude,
  AOriginLongitude, ADestinationLatitude, ADestinationLongitude: Double;
  AShowMarkers: Boolean; AShowPolyline: Boolean; AStrokeColor: TTMSFNCGraphicsColor;
  AStrokeWidth: Integer; AStrokeOpacity: Single;
  ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode): TTMSFNCGoogleMapsDirectionsItem;
begin
  Result := AddDirections(CreateCoordinate(AOriginLatitude, AOriginLongitude), CreateCoordinate(ADestinationLatitude, ADestinationLongitude),
    AShowMarkers, AShowPolyline, AStrokeColor, AStrokeWidth, AStrokeOpacity, ATravelMode);
end;

function TTMSFNCCustomGoogleMaps.AddDirections(AOriginCoordinate,
  ADestinationCoordinate: TTMSFNCMapsCoordinateRec; AShowMarkers: Boolean; AShowPolyline: Boolean; AStrokeColor: TTMSFNCGraphicsColor;
  AStrokeWidth: Integer; AStrokeOpacity: Single;
  ATravelMode: TTMSFNCGoogleMapsDirectionsTravelMode): TTMSFNCGoogleMapsDirectionsItem;
begin
  Result := AddDirectionsInt('', '', AOriginCoordinate, ADestinationCoordinate, AShowMarkers, AShowPolyline, AStrokeColor, AStrokeWidth, AStrokeOpacity, ATravelMode);
end;

procedure TTMSFNCCustomGoogleMaps.SetCircles(
  const Value: TTMSFNCGoogleMapsCircles);
begin
  Circles.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetClusters(
  const Value: TTMSFNCGoogleMapsClusters);
begin
  FClusters.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetDirections(
  const Value: TTMSFNCGoogleMapsDirections);
begin
  FDirections.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetKMLLayers(
  const Value: TTMSFNCGoogleMapsKMLLayers);
begin
  FKMLLayers.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetOverlayViews(
  const Value: TTMSFNCGoogleMapsOverlayViews);
begin
  FOverlayViews.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetMarkers(
  const Value: TTMSFNCGoogleMapsMarkers);
begin
  Markers.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetOptions(
  const Value: TTMSFNCGoogleMapsOptions);
begin
  Options.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetPolygons(
  const Value: TTMSFNCGoogleMapsPolygons);
begin
  Polygons.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetPolylines(
  const Value: TTMSFNCGoogleMapsPolylines);
begin
  Polylines.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.SetRectangles(
  const Value: TTMSFNCGoogleMapsRectangles);
begin
  Rectangles.Assign(Value);
end;

procedure TTMSFNCCustomGoogleMaps.StreetViewChanged(Sender: TObject);
begin
  Options.Changed;
end;

procedure TTMSFNCCustomGoogleMaps.UpdateClusters;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETCLUSTERARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateClusters);
end;

procedure TTMSFNCCustomGoogleMaps.UpdateDirections;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETDIRECTIONSARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateDirections);
end;

procedure TTMSFNCCustomGoogleMaps.UpdateKMLLayers;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETKMLLAYERARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateKMLLayers);
end;

procedure TTMSFNCCustomGoogleMaps.UpdateOverlayViews;
begin
  if not MapInitialized or IsDestroying or (UpdateCount > 0) then
    Exit;

  ExecuteJavaScript(GETOVERLAYVIEWARRAYVAR, {$IFDEF LCLWEBLIB}@{$ENDIF}DoUpdateOverlayViews);
end;

procedure TTMSFNCCustomGoogleMaps.CallCustomEvent(
  AEventData: TTMSFNCMapsEventData);
begin
  inherited;
  if AEventData.EventName = 'MarkerDragEnd' then
    DoMarkerDragEnd(AEventData);

  if AEventData.EventName = 'ClusterClick' then
    DoClusterClick(AEventData);

  if AEventData.EventName = 'ClusterMouseLeave' then
    DoClusterMouseLeave(AEventData);

  if AEventData.EventName = 'ClusterMouseEnter' then
    DoClusterMouseEnter(AEventData);

  if AEventData.EventName = 'OverlayViewClick' then
    DoOverlayViewClick(AEventData);

  if AEventData.EventName = 'KMLLayerClick' then
    DoKMLLayerClick(AEventData);

  if AEventData.EventName = 'PolyElementDragEnd' then
    DoPolyElementDragEnd(AEventData);

  if AEventData.EventName = 'PolyElementEditEnd' then
    DoPolyElementEditEnd(AEventData);

  if AEventData.EventName = 'StreetViewChange' then
    DoStreetViewChange(AEventData);

  if AEventData.EventName = 'StreetViewEnabledChange' then
    DoStreetViewEnabledChange(AEventData, ParseStreetViewDataInt(AEventData.ID, AEventData.CustomData));

  if AEventData.EventName = 'RetrievedDirectionsData' then
    DoRetrievedDirectionsData(AEventData, ParseDirectionsDataInt(AEventData.ID, AEventData.CustomData));

  if AEventData.EventName = 'UpdateMapType' then
  begin
    if AEventData.CustomData = '"roadmap"' then
      Options.MapTypeID := gmtDefault
    else if AEventData.CustomData = '"hybrid"' then
      Options.MapTypeID := gmtHybrid
    else if AEventData.CustomData = '"satellite"' then
      Options.MapTypeID := gmtSatellite
    else if AEventData.CustomData = '"terrain"' then
      Options.MapTypeID := gmtTerrain;
  end
end;

procedure TTMSFNCCustomGoogleMaps.Clear;
begin
  inherited;
  BeginUpdate;
  ClearClusters;
  ClearDirections;
  ClearKMLLayers;
  ClearOverlayViews;
  EndUpdate;
end;

procedure TTMSFNCCustomGoogleMaps.ClearClusters;
begin
  BeginUpdate;
  Clusters.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomGoogleMaps.ClearKMLLayers;
begin
  BeginUpdate;
  KMLLayers.Clear;
  EndUpdate;
end;

procedure TTMSFNCCustomGoogleMaps.ClearOverlayViews;
begin
  BeginUpdate;
  OverlayViews.Clear;
  EndUpdate;
end;

function TTMSFNCCustomGoogleMaps.AddKMLLayer(AURL: string;
  AZoomToBounds: Boolean): TTMSFNCGoogleMapsKMLLayer;
begin
  BeginUpdate;
  Result := KMLLayers.Add;
  Result.FURL := AURL;
  Result.FZoomToBounds := AZoomToBounds;
  EndUpdate;
end;

function TTMSFNCCustomGoogleMaps.AddMarker(
  ACoordinate: TTMSFNCMapsCoordinateRec; ATitle,
  AIconURL: string): TTMSFNCGoogleMapsMarker;
begin
  Result := TTMSFNCGoogleMapsMarker(inherited AddMarker(ACoordinate, ATitle, AIconURL));
end;

function TTMSFNCCustomGoogleMaps.AddMarker(ALatitude, ALongitude: Double;
  ATitle, AIconURL: string): TTMSFNCGoogleMapsMarker;
begin
  Result := TTMSFNCGoogleMapsMarker(inherited AddMarker(ALatitude, ALongitude, ATitle, AIconURL));
end;

function TTMSFNCCustomGoogleMaps.AddOverlayView(): TTMSFNCGoogleMapsOverlayView;
begin
  BeginUpdate;
  Result := OverlayViews.Add;
  EndUpdate;
end;

function TTMSFNCCustomGoogleMaps.AddPolygon(
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
  AClose: Boolean): TTMSFNCGoogleMapsPolygon;
begin
  Result := TTMSFNCGoogleMapsPolygon(inherited AddPolygon(ACoordinates, AClose));
end;

function TTMSFNCCustomGoogleMaps.AddPolyline(
  ACoordinates: TTMSFNCMapsCoordinateRecArray;
  AClose: Boolean): TTMSFNCGoogleMapsPolyline;
begin
  Result := TTMSFNCGoogleMapsPolyline(inherited AddPolyline(ACoordinates, AClose));
end;

function TTMSFNCCustomGoogleMaps.AddRectangle(
  ABounds: TTMSFNCMapsBoundsRec): TTMSFNCGoogleMapsRectangle;
begin
  Result := TTMSFNCGoogleMapsRectangle(inherited AddRectangle(ABounds));
end;

constructor TTMSFNCGoogleMapsOptions.Create;
begin
  inherited;
  MapTypeID := TTMSFNCGoogleMapsMapTypeID.gmtDefault;
  ShowBicycling := False;
  ShowStreetViewControl := True;
  ShowRotateControl := True;
  ShowScaleControl := False;
  ShowKeyboardShortcuts := True;
  ShowTraffic := False;
  MapStyle := '';
  DisablePOI := False;
  Version := 'weekly'
end;

destructor TTMSFNCGoogleMapsOptions.Destroy;
begin
  FStreetView.Free;
  inherited;
end;

procedure TTMSFNCGoogleMapsOptions.SetDisabelPOI(const Value: Boolean);
begin
  if FDIsablePOI <> Value then
  begin
    FDisablePOI := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetHeading(const Value: Double);
begin
  if FHeading <> Value then
  begin
    FHeading := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetMapID(const Value: string);
begin
  if FMapID <> Value then
  begin
    FMapID := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetMapStyle(const Value: string);
begin
  if FMapStyle <> Value then
  begin
    FMapStyle := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetMapTypeID(
  const Value: TTMSFNCGoogleMapsMapTypeID);
begin
  if FMapTypeID <> Value then
  begin
    FMapTypeID := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetSetShowStreetViewControl(
  const Value: Boolean);
begin
  if FShowStreetViewControl <> Value then
  begin
    FShowStreetViewControl := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetShowBicycling(const Value: Boolean);
begin
  if FShowBicycling <> Value then
  begin
    FShowBicycling := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetShowKeyboardShortcuts(
  const Value: Boolean);
begin
  if FShowKeyboardShortcuts <> Value then
  begin
    FShowKeyboardShortcuts := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetShowRotateControl(const Value: Boolean);
begin
  if FShowRotateControl <> Value then
  begin
    FShowRotateControl := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetShowScaleControl(const Value: Boolean);
begin
  if FShowScaleControl <> Value then
  begin
    FShowScaleControl := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetShowTraffic(const Value: Boolean);
begin
  if FShowTraffic <> Value then
  begin
    FShowTraffic := Value;
    Changed;
  end;
end;


procedure TTMSFNCGoogleMapsOptions.SetStreetView(
  const Value: TTMSFNCGoogleMapsStreetView);
begin
  FStreetView.Assign(Value);
end;

procedure TTMSFNCGoogleMapsOptions.SetTilt(const Value: Double);
begin
  if FTilt <> Value then
  begin
    FTilt := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsOptions.SetVersion(const Value: string);
begin
  if FVersion <> Value then
  begin
    FVersion := Value;
    Changed;
  end;
end;

{ TTMSFNCGoogleMapsDirectionsItem }

procedure TTMSFNCGoogleMapsDirectionsItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGoogleMapsDirections then
  begin
    FOrigin := (Source as TTMSFNCGoogleMapsDirectionsItem).Origin;
    FDestination := (Source as TTMSFNCGoogleMapsDirectionsItem).Destination;
    FOriginCoordinate := (Source as TTMSFNCGoogleMapsDirectionsItem).OriginCoordinate;
    FDestinationCoordinate := (Source as TTMSFNCGoogleMapsDirectionsItem).DestinationCoordinate;
    FShowMarkers := (Source as TTMSFNCGoogleMapsDirectionsItem).ShowMarkers;
    FShowPolyline := (Source as TTMSFNCGoogleMapsDirectionsItem).ShowPolyline;
    FStrokeColor := (Source as TTMSFNCGoogleMapsDirectionsItem).StrokeColor;
    FStrokeWidth := (Source as TTMSFNCGoogleMapsDirectionsItem).StrokeWidth;
    FStrokeOpacity := (Source as TTMSFNCGoogleMapsDirectionsItem).StrokeOpacity;
    FTravelMode := (Source as TTMSFNCGoogleMapsDirectionsItem).TravelMode;
    FWayPoints.Assign((Source as TTMSFNCGoogleMapsDirectionsItem).WayPoints);
    FOptimizeWayPoints := (Source as TTMSFNCGoogleMapsDirectionsItem).OptimizeWayPoints;
  end;
end;

constructor TTMSFNCGoogleMapsDirectionsItem.Create(ACollection: TCollection);
begin
  inherited;

  FOriginCoordinate := TTMSFNCMapsCoordinate.Create;
  FDestinationCoordinate := TTMSFNCMapsCoordinate.Create;
  FWayPoints := TStringList.Create;

  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGoogleMapsDirections).FOwner;

  UpdateDirectionsItem;
end;

destructor TTMSFNCGoogleMapsDirectionsItem.Destroy;
begin
  inherited;
  FOriginCoordinate.Free;
  FDestinationCoordinate.Free;
  FWayPoints.Free;
  UpdateDirectionsItem;
end;

function TTMSFNCGoogleMapsDirectionsItem.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsAvoidTolls: Boolean;
begin
  Result := AVoidTolls <> False;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsDestinationStored: Boolean;
begin
  Result := Destination <> '';
end;

function TTMSFNCGoogleMapsDirectionsItem.IsOptimizeWayPointsStored: Boolean;
begin
  Result := OptimizeWayPoints <> False;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsOriginStored: Boolean;
begin
  Result := Origin <> '';
end;

function TTMSFNCGoogleMapsDirectionsItem.IsShowMarkersStored: Boolean;
begin
  Result := ShowMarkers <> True;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsShowPolylineStored: Boolean;
begin
  Result := ShowPolyline <> True;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsStrokeOpacityStored: Boolean;
begin
  Result := StrokeOpacity <> 1;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsStrokeWidthStored: Boolean;
begin
  Result := StrokeWidth <> 1;
end;

function TTMSFNCGoogleMapsDirectionsItem.IsTravelModeStored: Boolean;
begin
  Result := TravelMode <> dtmDriving;
end;


procedure TTMSFNCGoogleMapsDirectionsItem.UpdateDirectionsItem;
begin
  if Assigned(FOwner) then
    (FOwner as TTMSFNCCustomGoogleMaps).UpdateDirections;
end;

{ TTMSFNCGoogleMapsDirections }

function TTMSFNCGoogleMapsDirections.Add: TTMSFNCGoogleMapsDirectionsItem;
begin
  Result := TTMSFNCGoogleMapsDirectionsItem(inherited Add);
end;

procedure TTMSFNCGoogleMapsDirections.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCGoogleMapsDirections.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGoogleMapsDirections.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsDirectionsItem;
end;

function TTMSFNCGoogleMapsDirections.GetItem(
  Index: Integer): TTMSFNCGoogleMapsDirectionsItem;
begin
  Result := TTMSFNCGoogleMapsDirectionsItem(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsDirections.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGoogleMapsDirections.Insert(
  Index: Integer): TTMSFNCGoogleMapsDirectionsItem;
begin
  Result := TTMSFNCGoogleMapsDirectionsItem(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsDirections.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsDirectionsItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsKMLLayer }

procedure TTMSFNCGoogleMapsKMLLayer.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGoogleMapsKMLLayer then
  begin
    FURL := (Source as TTMSFNCGoogleMapsKMLLayer).URL;
  end;
end;

constructor TTMSFNCGoogleMapsKMLLayer.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGoogleMapsKMLLayers).FOwner;

  UpdateKMLLayer;
end;

destructor TTMSFNCGoogleMapsKMLLayer.Destroy;
begin
  inherited;
  UpdateKMLLayer;
end;

function TTMSFNCGoogleMapsKMLLayer.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

function TTMSFNCGoogleMapsKMLLayer.IsURLStored: Boolean;
begin
  Result := URL <> '';
end;

function TTMSFNCGoogleMapsKMLLayer.IsZoomToBoundsStored: Boolean;
begin
  Result := ZoomToBounds;
end;

procedure TTMSFNCGoogleMapsKMLLayer.UpdateKMLLayer;
begin
  if Assigned(FOwner) then
    (FOwner as TTMSFNCCustomGoogleMaps).UpdateKMLLayers;
end;

{ TTMSFNCGoogleMapsKMLLayers }

function TTMSFNCGoogleMapsKMLLayers.Add: TTMSFNCGoogleMapsKMLLayer;
begin
  Result := TTMSFNCGoogleMapsKMLLayer(inherited Add);
end;

procedure TTMSFNCGoogleMapsKMLLayers.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCGoogleMapsKMLLayers.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGoogleMapsKMLLayers.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsKMLLayer;
end;

function TTMSFNCGoogleMapsKMLLayers.GetItem(
  Index: Integer): TTMSFNCGoogleMapsKMLLayer;
begin
  Result := TTMSFNCGoogleMapsKMLLayer(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsKMLLayers.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGoogleMapsKMLLayers.Insert(
  Index: Integer): TTMSFNCGoogleMapsKMLLayer;
begin
  Result := TTMSFNCGoogleMapsKMLLayer(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsKMLLayers.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsKMLLayer);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsOverlayView }

procedure TTMSFNCGoogleMapsOverlayView.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGoogleMapsOverlayView then
  begin
    FText := (Source as TTMSFNCGoogleMapsOverlayView).Text;
    FBounds.Assign((Source as TTMSFNCGoogleMapsOverlayView).Bounds);
    FBorderColor := (Source as TTMSFNCGoogleMapsOverlayView).BorderColor;
    FBackgroundColor := (Source as TTMSFNCGoogleMapsOverlayView).BackgroundColor;
    FClickable := (Source as TTMSFNCGoogleMapsOverlayView).Clickable;
    FCoordinate := (Source as TTMSFNCGoogleMapsOverlayView).Coordinate;
    FCoordinatePosition := (Source as TTMSFNCGoogleMapsOverlayView).CoordinatePosition;
    FCoordinateOffsetTop := (Source as TTMSFNCGoogleMapsOverlayView).CoordinateOffsetTop;
    FCoordinateOffsetLeft := (Source as TTMSFNCGoogleMapsOverlayView).CoordinateOffsetLeft;
    FFont.Assign((Source as TTMSFNCGoogleMapsOverlayView).Font);
    FHeight := (Source as TTMSFNCGoogleMapsOverlayView).Height;
    FMode := (Source as TTMSFNCGoogleMapsOverlayView).Mode;
    FPadding := (Source as TTMSFNCGoogleMapsOverlayView).Padding;
    FVisible := (Source as TTMSFNCGoogleMapsOverlayView).Visible;
    FWidth := (Source as TTMSFNCGoogleMapsOverlayView).Width;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.Changed(Sender: TObject);
begin
  UpdateOverlayView;
end;

constructor TTMSFNCGoogleMapsOverlayView.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGoogleMapsOverlayViews).FOwner;

  FText := '';
  FBounds := TTMSFNCMapsBounds.Create;
  FBounds.OnChange := @Changed;
  FBorderColor := gcBlack;
  FBackgroundColor := gcWhite;
  FClickable := True;
  FCoordinate :=  TTMSFNCMapsCoordinate.Create;
  FCoordinate.OnChange := @Changed;
  FCoordinatePosition := cpTopCenter;
  FCoordinateOffsetTop := 0;
  FCoordinateOffsetLeft := 0;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.Size := 12;
  FFont.OnChanged := @Changed;
  FHeight := -1;
  FMode := omCoordinate;
  FPadding := 3;
  FVisible := True;
  FWidth := -1;

  UpdateOverlayView;
end;

destructor TTMSFNCGoogleMapsOverlayView.Destroy;
begin
  if Assigned(FMarkers) then
    FMarkers.Free;
  FBounds.Free;
  FCoordinate.Free;
  FFont.Free;
  inherited;
  UpdateOverlayView;
end;

function TTMSFNCGoogleMapsOverlayView.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

function TTMSFNCGoogleMapsOverlayView.GetMarkers: TTMSFNCGoogleMapsMarkersList;
var
  I: Integer;
  m: TTMSFNCGoogleMapsMarker;
begin
  if not Assigned(FMarkers) then
    FMarkers := TTMSFNCGoogleMapsMarkersList.Create;

  FMarkers.Clear;

  if Assigned(FOwner) then
  begin
    for I := 0 to TTMSFNCCustomGoogleMaps(FOwner).Markers.Count - 1 do
    begin
      m := TTMSFNCCustomGoogleMaps(FOwner).Markers[I];
      if m.OverlayView = Self then
        FMarkers.Add(m);
    end;
  end;

  Result := FMarkers;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetBackgroundColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FBackgroundColor <> Value then
  begin
    FBackgroundColor := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetBorderColor(
  const Value: TTMSFNCGraphicsColor);
begin
  FBorderColor := Value;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetBounds(
  const Value: TTMSFNCMapsBounds);
begin
  FBounds.Assign(Value);
  UpdateOverlayView;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetClickable(const Value: Boolean);
begin
  if FClickable <> Value then
  begin
    FClickable := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetCoordinate(
  const Value: TTMSFNCMapsCoordinate);
begin
  FCoordinate.Assign(Value);
  UpdateOverlayView;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetCoordinatePosition(
  const Value: TTMSFNCGoogleMapsCoordinatePosition);
begin
  if FCoordinatePosition <> Value then
  begin
    FCoordinatePosition := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
  UpdateOverlayView;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetHeight(const Value: Integer);
begin
  if (FHeight <> Value) then
  begin
    FHeight := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetMode(
  const Value: TTMSFNCGoogleMapsOverlayViewMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetoordinatePositionOffsetLeft(
  const Value: Integer);
begin
  if FCoordinateOffsetLeft <> Value then
  begin
    FCoordinateOffsetLeft := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetoordinatePositionOffsetTop(
  const Value: Integer);
begin
  if FCoordinateOffsetTop <> Value then
  begin
    FCoordinateOffsetTop := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetPadding(const Value: Integer);
begin
  if FPadding <> Value then
  begin
    FPadding := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.SetWidth(const Value: Integer);
begin
  if (FWidth <> Value) then
  begin
    FWidth := Value;
    UpdateOverlayView;
  end;
end;

procedure TTMSFNCGoogleMapsOverlayView.UpdateOverlayView;
begin
  if Assigned(FOwner) then
    (FOwner as TTMSFNCCustomGoogleMaps).UpdateOverlayViews;
end;

{ TTMSFNCGoogleMapsOverlayViews }

function TTMSFNCGoogleMapsOverlayViews.Add: TTMSFNCGoogleMapsOverlayView;
begin
  Result := TTMSFNCGoogleMapsOverlayView(inherited Add);
end;

procedure TTMSFNCGoogleMapsOverlayViews.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCGoogleMapsOverlayViews.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGoogleMapsOverlayViews.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsOverlayView;
end;

function TTMSFNCGoogleMapsOverlayViews.GetItem(
  Index: Integer): TTMSFNCGoogleMapsOverlayView;
begin
  Result := TTMSFNCGoogleMapsOverlayView(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsOverlayViews.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGoogleMapsOverlayViews.Insert(
  Index: Integer): TTMSFNCGoogleMapsOverlayView;
begin
  Result := TTMSFNCGoogleMapsOverlayView(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsOverlayViews.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsOverlayView);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsCluster }

procedure TTMSFNCGoogleMapsCluster.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGoogleMapsCluster then
  begin
    FTitle := (Source as TTMSFNCGoogleMapsCluster).Title;
    FAverageCenter := (Source as TTMSFNCGoogleMapsCluster).Averagecenter;
    FIgnoreHiddenMarkers := (Source as TTMSFNCGoogleMapsCluster).IgnoreHiddenMarkers;
    FZoomOnClick := (Source as TTMSFNCGoogleMapsCluster).ZoomOnClick;
    FMinimumNumberOfMarkers := (Source as TTMSFNCGoogleMapsCluster).MinimumNumberOfMarkers;
    FMaxZoom := (Source as TTMSFNCGoogleMapsCluster).MaxZoom;
    FImagePath := (Source as TTMSFNCGoogleMapsCluster).ImagePath;
  end;
end;

constructor TTMSFNCGoogleMapsCluster.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGoogleMapsClusters).FOwner;

  FTitle := '';
  FAverageCenter := True;
  FIgnoreHiddenMarkers := True;
  FZoomOnClick := True;
  FMinimumNumberOfMarkers := 2;
  FMaxZoom := 0;
  FImagePath := '';

  UpdateCluster;
end;

destructor TTMSFNCGoogleMapsCluster.Destroy;
begin
  inherited;
  if Assigned(FMarkers) then
    FMarkers.Free;
  UpdateCluster;
end;

function TTMSFNCGoogleMapsCluster.GetID: string;
begin
  if (FID = '') or FRecreate then
    FID := CreateNewGUID;

  FRecreate := False;
  Result := FID;
end;

function TTMSFNCGoogleMapsCluster.GetMarkers: TTMSFNCGoogleMapsMarkersList;
var
  I: Integer;
  m: TTMSFNCGoogleMapsMarker;
begin
  if not Assigned(FMarkers) then
    FMarkers := TTMSFNCGoogleMapsMarkersList.Create;

  FMarkers.Clear;

  if Assigned(FOwner) then
  begin
    for I := 0 to TTMSFNCCustomGoogleMaps(FOwner).Markers.Count - 1 do
    begin
      m := TTMSFNCCustomGoogleMaps(FOwner).Markers[I];
      if m.Cluster = Self then
        FMarkers.Add(m);
    end;
  end;

  Result := FMarkers;
end;

function TTMSFNCGoogleMapsCluster.IsAverageCenterStored: Boolean;
begin
  Result := AverageCenter;
end;

function TTMSFNCGoogleMapsCluster.IsIgnoreHiddenMarkers: Boolean;
begin
  Result := IgnoreHiddenMarkers;
end;

function TTMSFNCGoogleMapsCluster.IsImagePathStored: Boolean;
begin
  Result := ImagePath <> '';
end;

function TTMSFNCGoogleMapsCluster.IsMaxZoomStored: Boolean;
begin
  Result := MaxZoom <> 0;
end;

function TTMSFNCGoogleMapsCluster.IsMinimumNumberOfMarkers: Boolean;
begin
  Result := MinimumNumberOfMarkers <> 2;
end;

function TTMSFNCGoogleMapsCluster.IsTitleStored: Boolean;
begin
  Result := Title <> '';
end;

function TTMSFNCGoogleMapsCluster.IsZoomOnClickStored: Boolean;
begin
  Result := ZoomOnClick;
end;

procedure TTMSFNCGoogleMapsCluster.SetAverageCenter(const Value: Boolean);
begin
  if FAverageCenter <> Value then
  begin
    FAverageCenter := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetIgnoreHiddenMarkers(const Value: Boolean);
begin
  if FIgnoreHiddenMarkers <> Value then
  begin
    FIgnoreHiddenMarkers := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetImagePath(const Value: string);
begin
  if FImagePath <> Value then
  begin
    FImagePath := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetMaxZoom(const Value: Integer);
begin
  if FMaxZoom <> Value then
  begin
    FMaxZoom := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetMinimumNumberOfMarkers(
  const Value: Integer);
begin
  if FMinimumNumberOfMarkers <> Value then
  begin
    FMinimumNumberOfMarkers := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetTitle(const Value: string);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.SetZoomOnClick(const Value: Boolean);
begin
  if FZoomOnClick <> Value then
  begin
    FZoomOnClick := Value;
    UpdateCluster;
  end;
end;

procedure TTMSFNCGoogleMapsCluster.UpdateCluster;
begin
  FReload := True;
  if Assigned(FOwner) then
    (FOwner as TTMSFNCCustomGoogleMaps).UpdateClusters;
end;

{ TTMSFNCGoogleMapsClusters }

function TTMSFNCGoogleMapsClusters.Add: TTMSFNCGoogleMapsCluster;
begin
  Result := TTMSFNCGoogleMapsCluster(inherited Add);
end;

procedure TTMSFNCGoogleMapsClusters.Clear;
var
  l: TTMSFNCCustomMaps;
  ci: TCollectionItem;
begin
  l := FOwner;
  if Assigned(l) then
    l.BeginUpdate;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

constructor TTMSFNCGoogleMapsClusters.Create(AOwner: TTMSFNCCustomMaps);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGoogleMapsClusters.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsCluster;
end;

function TTMSFNCGoogleMapsClusters.GetItem(
  Index: Integer): TTMSFNCGoogleMapsCluster;
begin
  Result := TTMSFNCGoogleMapsCluster(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsClusters.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGoogleMapsClusters.Insert(
  Index: Integer): TTMSFNCGoogleMapsCluster;
begin
  Result := TTMSFNCGoogleMapsCluster(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsClusters.Recreate;
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    Items[I].Recreate := True;
end;

procedure TTMSFNCGoogleMapsClusters.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsCluster);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMaps }

procedure TTMSFNCGoogleMaps.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCGoogleMaps);
end;

{$IFDEF WEBLIB}
function TTMSFNCGoogleMapsMarkersList.GetItem(Index: Integer): TTMSFNCGoogleMapsMarker;
begin
  Result := TTMSFNCGoogleMapsMarker(inherited Items[Index]);
end;

procedure TTMSFNCGoogleMapsMarkersList.SetItem(Index: Integer; const Value: TTMSFNCGoogleMapsMarker);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCGoogleMapsMarker }

procedure TTMSFNCGoogleMapsMarker.AddOverlayView(AText: string);
var
  ov: TTMSFNCGoogleMapsOverlayView;
begin
  ov := TTMSFNCGoogleMaps(FOwner).OverlayViews.Add;
  ov.Text := AText;
  OverlayView := ov;
end;

procedure TTMSFNCGoogleMapsMarker.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCGoogleMapsMarker then
  begin
    FDefaultIconSize := (Source as TTMSFNCGoogleMapsMarker).DefaultIconSize;
    FIconHeight := (Source as TTMSFNCGoogleMapsMarker).IconHeight;
    FIconWidth := (Source as TTMSFNCGoogleMapsMarker).IconWidth;
    FZIndex := (Source as TTMSFNCGoogleMapsMarker).ZIndex;
    FAnimation := (Source as TTMSFNCGoogleMapsMarker).Animation;
    FClickable := (Source as TTMSFNCGoogleMapsMarker).Clickable;
    FDraggable := (Source as TTMSFNCGoogleMapsMarker).Draggable;
    FDefaultAnchor := (Source as TTMSFNCGoogleMapsMarker).DefaultAnchor;
    FAnchor.Assign((Source as TTMSFNCGoogleMapsMarker).Anchor);
  end;
end;

constructor TTMSFNCGoogleMapsMarker.Create(ACollection: TCollection);
begin
  inherited;
  FDefaultIconSize := True;
  FIconHeight := 48;
  FIconWidth := 48;
  FZIndex := 0;
  FAnimation := False;
  FClickable := True;
  FDraggable := False;
  FDefaultAnchor := True;
  FAnchor := TTMSFNCMapsAnchorPoint.Create;
  FAnchor.OnChange := @DoAnchorChanged;
end;

destructor TTMSFNCGoogleMapsMarker.Destroy;
begin
  FAnchor.Free;
  FCluster := nil;
  FOverlayView := nil;
  inherited;
end;

procedure TTMSFNCGoogleMapsMarker.DoAnchorChanged(Sender: TObject);
begin
  UpdateMarker;
end;

function TTMSFNCGoogleMapsMarker.IsIconHeightStored: Boolean;
begin
  Result := IconHeight <> 48;
end;

function TTMSFNCGoogleMapsMarker.IsIconWidthStored: Boolean;
begin
  Result := IconWidth <> 48;
end;

procedure TTMSFNCGoogleMapsMarker.SetAnchor(
  const Value: TTMSFNCMapsAnchorPoint);
begin
  if FAnchor <> Value then
    FAnchor.Assign(Value);
end;

procedure TTMSFNCGoogleMapsMarker.SetAnimation(const Value: Boolean);
begin
  if (FAnimation <> Value) then
  begin
    FAnimation := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCGoogleMapsMarker.SetClickable(const Value: Boolean);
begin
  if (FClickable <> Value) then
  begin
    FClickable := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCGoogleMapsMarker.SetDraggable(const Value: Boolean);
begin
  if (Draggable <> Value) then
  begin
    FDraggable := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCGoogleMapsMarker.SetIconHeight(const Value: Single);
begin
  FIconHeight := Value;
end;

procedure TTMSFNCGoogleMapsMarker.SetIconWidth(const Value: Single);
begin
  FIconWidth := Value;
end;

procedure TTMSFNCGoogleMapsMarker.SetDefaultAnchor(const Value: Boolean);
begin
  if FDefaultAnchor <> Value then
  begin
    FDefaultAnchor := Value;
    UpdateMarker;
  end;
end;

procedure TTMSFNCGoogleMapsMarker.SetDefaultIconSize(const Value: Boolean);
begin
  FDefaultIconSize := Value;
end;

procedure TTMSFNCGoogleMapsMarker.SetZIndex(const Value: Integer);
begin
  if FZIndex <> Value then
  begin
    FZIndex := Value;
    UpdateMarker;
  end;
end;

{ TTMSFNCGoogleMapsMarkers }

function TTMSFNCGoogleMapsMarkers.Add: TTMSFNCGoogleMapsMarker;
begin
  Result := TTMSFNCGoogleMapsMarker(inherited Add);
end;

function TTMSFNCGoogleMapsMarkers.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsMarker;
end;

function TTMSFNCGoogleMapsMarkers.GetItem(Index: Integer): TTMSFNCGoogleMapsMarker;
begin
  Result := TTMSFNCGoogleMapsMarker(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsMarkers.Insert(Index: Integer): TTMSFNCGoogleMapsMarker;
begin
  Result := TTMSFNCGoogleMapsMarker(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsMarkers.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsMarker);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsCircle }

constructor TTMSFNCGoogleMapsCircle.Create(ACollection: TCollection);
begin
  inherited;
  FZIndex := 0;
  FClickable := True;
  FDraggable := False;
  FEditable := False;
end;

destructor TTMSFNCGoogleMapsCircle.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGoogleMapsCircle.SetClickable(const Value: Boolean);
begin
  if (FClickable <> Value) then
  begin
    FClickable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsCircle.SetDraggable(const Value: Boolean);
begin
  if (FDraggable <> Value) then
  begin
    FDraggable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsCircle.SetEditable(const Value: Boolean);
begin
  if (FEditable <> Value) then
  begin
    FEditable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsCircle.SetZIndex(const Value: Integer);
begin
  if FZIndex <> Value then
  begin
    FZIndex := Value;
    UpdatePolyElement;
  end;
end;

{ TTMSFNCGoogleMapsCircles }

function TTMSFNCGoogleMapsCircles.Add: TTMSFNCGoogleMapsCircle;
begin
  Result := TTMSFNCGoogleMapsCircle(inherited Add);
end;

function TTMSFNCGoogleMapsCircles.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsCircle;
end;

function TTMSFNCGoogleMapsCircles.GetItem(
  Index: Integer): TTMSFNCGoogleMapsCircle;
begin
  Result := TTMSFNCGoogleMapsCircle(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsCircles.Insert(
  Index: Integer): TTMSFNCGoogleMapsCircle;
begin
  Result := TTMSFNCGoogleMapsCircle(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsCircles.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsCircle);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsRectangle }

constructor TTMSFNCGoogleMapsRectangle.Create(ACollection: TCollection);
begin
  inherited;
  FZIndex := 0;
  FClickable := True;
  FDraggable := False;
  FEditable := False;
end;

destructor TTMSFNCGoogleMapsRectangle.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGoogleMapsRectangle.SetClickable(const Value: Boolean);
begin
  if (FClickable <> Value) then
  begin
    FClickable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsRectangle.SetDraggable(const Value: Boolean);
begin
  if (FDraggable <> Value) then
  begin
    FDraggable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsRectangle.SetEditable(const Value: Boolean);
begin
  if (FEditable <> Value) then
  begin
    FEditable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsRectangle.SetZIndex(const Value: Integer);
begin
  if FZIndex <> Value then
  begin
    FZIndex := Value;
    UpdatePolyElement;
  end;
end;

{ TTMSFNCGoogleMapsRectangles }

function TTMSFNCGoogleMapsRectangles.Add: TTMSFNCGoogleMapsRectangle;
begin
  Result := TTMSFNCGoogleMapsRectangle(inherited Add);
end;

function TTMSFNCGoogleMapsRectangles.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsRectangle;
end;

function TTMSFNCGoogleMapsRectangles.GetItem(
  Index: Integer): TTMSFNCGoogleMapsRectangle;
begin
  Result := TTMSFNCGoogleMapsRectangle(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsRectangles.Insert(
  Index: Integer): TTMSFNCGoogleMapsRectangle;
begin
  Result := TTMSFNCGoogleMapsRectangle(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsRectangles.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsRectangle);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsPolygon }

function TTMSFNCGoogleMapsPolygon.AddHole(
  ACoordinates: TTMSFNCMapsCoordinateRecArray): TTMSFNCMapsPolyElementHole;
begin
  Result := inherited AddHole(ACoordinates);
end;

procedure TTMSFNCGoogleMapsPolygon.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCGoogleMapsPolygon then
  begin
    FZIndex := (Source as TTMSFNCGoogleMapsPolygon).ZIndex;
    FClickable := (Source as TTMSFNCGoogleMapsPolygon).Clickable;
    FDraggable := (Source as TTMSFNCGoogleMapsPolygon).Draggable;
    FEditable := (Source as TTMSFNCGoogleMapsPolygon).Editable;
  end;
end;

constructor TTMSFNCGoogleMapsPolygon.Create(ACollection: TCollection);
begin
  inherited;
  FZIndex := 0;
  FClickable := True;
  FDraggable := False;
  FEditable := False;
end;

destructor TTMSFNCGoogleMapsPolygon.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGoogleMapsPolygon.SetClickable(const Value: Boolean);
begin
  if (FClickable <> Value) then
  begin
    FClickable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolygon.SetDraggable(const Value: Boolean);
begin
  if (FDraggable <> Value) then
  begin
    FDraggable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolygon.SetEditable(const Value: Boolean);
begin
  if (FEditable <> Value) then
  begin
    FEditable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolygon.SetZIndex(const Value: Integer);
begin
  if FZIndex <> Value then
  begin
    FZIndex := Value;
    UpdatePolyElement;
  end;
end;

{ TTMSFNCGoogleMapsPolygons }

function TTMSFNCGoogleMapsPolygons.Add: TTMSFNCGoogleMapsPolygon;
begin
  Result := TTMSFNCGoogleMapsPolygon(inherited Add);
end;

function TTMSFNCGoogleMapsPolygons.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsPolygon;
end;

function TTMSFNCGoogleMapsPolygons.GetItem(
  Index: Integer): TTMSFNCGoogleMapsPolygon;
begin
  Result := TTMSFNCGoogleMapsPolygon(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsPolygons.Insert(
  Index: Integer): TTMSFNCGoogleMapsPolygon;
begin
  Result := TTMSFNCGoogleMapsPolygon(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsPolygons.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsPolygon);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsPolyline }

procedure TTMSFNCGoogleMapsPolyline.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCGoogleMapsPolyline then
  begin
    FZIndex := (Source as TTMSFNCGoogleMapsPolyline).ZIndex;
    FClickable := (Source as TTMSFNCGoogleMapsPolyline).Clickable;
    FDraggable := (Source as TTMSFNCGoogleMapsPolyline).Draggable;
    FEditable := (Source as TTMSFNCGoogleMapsPolyline).Editable;
    FGeodesic := (Source as TTMSFNCGoogleMapsPolyline).Geodesic;
    FSymbols.Assign((Source as TTMSFNCGoogleMapsPolyline).Symbols);
  end;
end;

constructor TTMSFNCGoogleMapsPolyline.Create(ACollection: TCollection);
begin
  inherited;
  FZIndex := 0;
  FClickable := True;
  FDraggable := False;
  FEditable := False;
  FGeodesic := False;
  FSymbols := TTMSFNCGoogleMapsPolylineSymbols.Create(Self);
end;

destructor TTMSFNCGoogleMapsPolyline.Destroy;
begin
  FSymbols.Free;
  inherited;
end;

procedure TTMSFNCGoogleMapsPolyline.SetClickable(const Value: Boolean);
begin
  if (FClickable <> Value) then
  begin
    FClickable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolyline.SetDraggable(const Value: Boolean);
begin
  if (FDraggable <> Value) then
  begin
    FDraggable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolyline.SetEditable(const Value: Boolean);
begin
  if (FEditable <> Value) then
  begin
    FEditable := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolyline.SetGeodesic(const Value: Boolean);
begin
  if (FGeodesic <> Value) then
  begin
    FGeodesic := Value;
    UpdatePolyElement;
  end;
end;

procedure TTMSFNCGoogleMapsPolyline.SetZIndex(const Value: Integer);
begin
  if FZIndex <> Value then
  begin
    FZIndex := Value;
    UpdatePolyElement;
  end;
end;

{ TTMSFNCGoogleMapsPolylines }

function TTMSFNCGoogleMapsPolylines.Add: TTMSFNCGoogleMapsPolyline;
begin
  Result := TTMSFNCGoogleMapsPolyline(inherited Add);
end;

function TTMSFNCGoogleMapsPolylines.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsPolyline;
end;

function TTMSFNCGoogleMapsPolylines.GetItem(
  Index: Integer): TTMSFNCGoogleMapsPolyline;
begin
  Result := TTMSFNCGoogleMapsPolyline(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsPolylines.Insert(
  Index: Integer): TTMSFNCGoogleMapsPolyline;
begin
  Result := TTMSFNCGoogleMapsPolyline(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsPolylines.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsPolyline);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCGoogleMapsStreetView }

procedure TTMSFNCGoogleMapsStreetView.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGoogleMapsStreetView) then
  begin
    FEnabled := (Source as TTMSFNCGoogleMapsStreetView).Enabled;
    FHeading := (Source as TTMSFNCGoogleMapsStreetView).Heading;
    FPitch := (Source as TTMSFNCGoogleMapsStreetView).Pitch;
    FZoom := (Source as TTMSFNCGoogleMapsStreetView).Zoom;
  end
  else
    inherited;
end;

procedure TTMSFNCGoogleMapsStreetView.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGoogleMapsStreetView.Create;
begin
  FEnabled := False;
  FHeading := 0;
  FPitch := 0;
  FZoom := 0;
end;

procedure TTMSFNCGoogleMapsStreetView.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsStreetView.SetHeading(const Value: integer);
begin
  if Heading <> Value then
  begin
    FHeading := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsStreetView.SetPitch(const Value: integer);
begin
  if Pitch <> Value then
  begin
    FPitch := Value;
    Changed;
  end;
end;

procedure TTMSFNCGoogleMapsStreetView.SetZoom(const Value: integer);
begin
  if Zoom <> Value then
  begin
    FZoom := Value;
    Changed;
  end;
end;

{ TTMSFNCGoogleMaps }

procedure TTMSFNCGoogleMaps.AddBridge(ABridgeName: string; ABridgeObject: TObject);
begin
  inherited AddBridge(ABridgeName, ABridgeObject);
end;

function TTMSFNCGoogleMaps.CanGoBack: Boolean;
begin
  Result := inherited CanGoBack;
end;

function TTMSFNCGoogleMaps.CanGoForward: Boolean;
begin
  Result := inherited CanGoForward;
end;

procedure TTMSFNCGoogleMaps.CaptureScreenShot;
begin
  inherited CaptureScreenShot;
end;

procedure TTMSFNCGoogleMaps.ClearCache;
begin
  inherited ClearCache;
end;

procedure TTMSFNCGoogleMaps.DeInitialize;
begin
  inherited DeInitialize;
end;

procedure TTMSFNCGoogleMaps.ExecuteJavaScript(AScript: String;
  ACompleteEvent: TTMSFNCWebBrowserJavaScriptCompleteEvent = nil; AImmediate: Boolean = False);
begin
  inherited ExecuteJavaScript(AScript, ACompleteEvent, AImmediate);
end;

function TTMSFNCGoogleMaps.ExecuteJavaScriptSync(AScript: String): string;
begin
  Result := inherited ExecuteJavaScriptSync(AScript);
end;

function TTMSFNCGoogleMaps.GetBridgeCommunicationLayer(ABridgeName: string): string;
begin
  Result := inherited GetBridgeCommunicationLayer(ABridgeName);
end;

{$IFDEF ANDROID}
function TTMSFNCGoogleMaps.NativeDialog: Pointer;
begin
  Result := inherited NativeDialog;
end;
{$ENDIF}

{$IFDEF MSWINDOWS}
function TTMSFNCGoogleMaps.GetWebBrowserInstance: IInterface;
begin
  Result := inherited GetWebBrowserInstance;
end;
{$ENDIF}

procedure TTMSFNCGoogleMaps.GoBack;
begin
  inherited GoBack;
end;

procedure TTMSFNCGoogleMaps.GoForward;
begin
  inherited GoForward;
end;

procedure TTMSFNCGoogleMaps.Initialize;
begin
  inherited Initialize;
end;

function TTMSFNCGoogleMaps.IsFMXBrowser: Boolean;
begin
  Result := inherited IsFMXBrowser;
end;

procedure TTMSFNCGoogleMaps.LoadFile(AFile: String);
begin
  inherited LoadFile(AFile);
end;

procedure TTMSFNCGoogleMaps.LoadHTML(AHTML: String);
begin
  inherited LoadHTML(AHTML);
end;

function TTMSFNCGoogleMaps.NativeBrowser: Pointer;
begin
  Result := inherited NativeBrowser;
end;

function TTMSFNCGoogleMaps.NativeEnvironment: Pointer;
begin
  Result := inherited NativeEnvironment;
end;

procedure TTMSFNCGoogleMaps.Navigate;
begin
  inherited Navigate;
end;

procedure TTMSFNCGoogleMaps.Navigate(const AURL: string);
begin
  inherited Navigate(AURL);
end;

procedure TTMSFNCGoogleMaps.Reload;
begin
  inherited Reload;
end;

procedure TTMSFNCGoogleMaps.RemoveBridge(ABridgeName: string);
begin
  inherited RemoveBridge(ABridgeName);
end;

procedure TTMSFNCGoogleMaps.ShowDebugConsole;
begin
  inherited ShowDebugConsole;
end;

procedure TTMSFNCGoogleMaps.StopLoading;
begin
  inherited StopLoading;
end;

{ TTMSFNCGoogleMapsPolylineSymbol }

procedure TTMSFNCGoogleMapsPolylineSymbol.Assign(Source: TPersistent);
begin
  inherited;
  FPath := (Source as TTMSFNCGoogleMapsPolylineSymbol).Path;
  FCustomPath := (Source as TTMSFNCGoogleMapsPolylineSymbol).CustomPath;
  FOffset := (Source as TTMSFNCGoogleMapsPolylineSymbol).Offset;
  FRepeatSymbol := (Source as TTMSFNCGoogleMapsPolylineSymbol).RepeatSymbol;
  FOffsetUnits := (Source as TTMSFNCGoogleMapsPolylineSymbol).OffsetUnits;
  FRepeatSymbolUnits := (Source as TTMSFNCGoogleMapsPolylineSymbol).RepeatSymbolUnits;
  FScale := (Source as TTMSFNCGoogleMapsPolylineSymbol).Scale;
  FRotation := (Source as TTMSFNCGoogleMapsPolylineSymbol).Rotation;
  FStrokeColor := (Source as TTMSFNCGoogleMapsPolylineSymbol).StrokeColor;
  FStrokeOpacity := (Source as TTMSFNCGoogleMapsPolylineSymbol).StrokeOpacity;
  FStrokeWidth := (Source as TTMSFNCGoogleMapsPolylineSymbol).StrokeWidth;
  FFillColor := (Source as TTMSFNCGoogleMapsPolylineSymbol).FillColor;
  FFillOpacity := (Source as TTMSFNCGoogleMapsPolylineSymbol).FillOpacity;
end;

constructor TTMSFNCGoogleMapsPolylineSymbol.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (Collection as TTMSFNCGoogleMapsPolylineSymbols).FOwner;

  FPath := spForwardOpenArrow;
  FCustomPath := '';
  FOffset := 100;
  FOffsetUnits := unPercentage;
  FRepeatSymbol := 0;
  FRepeatSymbolUnits := unPixels;
  FScale := 0;
  FRotation := 0;
  FStrokeColor := gcNull;
  FStrokeOpacity := 0;
  FStrokeWidth := 0;
  FFillColor := gcNull;
  FFillOpacity := 0;
end;

destructor TTMSFNCGoogleMapsPolylineSymbol.Destroy;
begin
  inherited;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsCustomPathStored: Boolean;
begin
  Result := CustomPath <> '';
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsFillOpacityStored: Boolean;
begin
  Result := FillOpacity <> 0;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsOffsetStored: Boolean;
begin
  Result := Offset <> 100;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsPathStored: Boolean;
begin
  Result := Path <> spForwardOpenArrow;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsRepeatOffsetUnitsStored: Boolean;
begin
  Result := OffsetUnits <> unPercentage;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsRepeatSymbolStored: Boolean;
begin
  Result := Offset <> 0;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsRepeatSymbolUnitsStored: Boolean;
begin
  Result := OffsetUnits <> unPixels;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsRotationStored: Boolean;
begin
  Result := Rotation <> 0;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsScaleStored: Boolean;
begin
  Result := Scale <> 0;
end;

function TTMSFNCGoogleMapsPolylineSymbol.IsStrokeOpacityStored: Boolean;
begin
  Result := StrokeOpacity <> 0;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetCustomPath(const Value: string);
begin
  if FCustomPath <> Value then
  begin
    FCustomPath := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetFillColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FFillColor <> Value then
  begin
    FFillColor := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetFillOpacity(const Value: Single);
begin
  if FFillOpacity <> Value then
  begin
    FFillOpacity := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetOffset(const Value: Integer);
begin
  if FOffset <> Value then
  begin
    FOffset := Value;
    UpdateSymbol
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetOffsetUnits(const Value: TTMSFNCGoogleMapsPolylineSymbolUnits);
begin
  if FOffsetUnits <> Value then
  begin
    FOffsetUnits := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetPath(
  const Value: TTMSFNCGoogleMapsPolylineSymbolPath);
begin
  if FPath <> Value then
  begin
    FPath := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetRepeatSymbol(const Value: Integer);
begin
  if FRepeatSymbol <> Value then
  begin
    FRepeatSymbol := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetRepeatSymbolUnits(
  const Value: TTMSFNCGoogleMapsPolylineSymbolUnits);
begin
  if FRepeatSymbolUnits <> Value then
  begin
    FRepeatSymbolUnits := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetRotation(const Value: Integer);
begin
  if FRotation <> Value then
  begin
    FRotation := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetScale(const Value: Single);
begin
  if FScale <> Value then
  begin
    FScale := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetStrokeColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FStrokeColor <> Value then
  begin
    FStrokeColor := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetStrokeOpacity(const Value: Single);
begin
  if FStrokeOpacity <> Value then
  begin
    FStrokeOpacity := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.SetStrokeWidth(const Value: Integer);
begin
  if FStrokeWidth <> Value then
  begin
    FStrokeWidth := Value;
    UpdateSymbol;
  end;
end;

procedure TTMSFNCGoogleMapsPolylineSymbol.UpdateSymbol;
begin
  if Assigned(FOwner) then
  begin
    FOwner.UpdatePolyElement;
  end;
end;

{ TTMSFNCGoogleMapsPolylineSymbols }

function TTMSFNCGoogleMapsPolylineSymbols.Add: TTMSFNCGoogleMapsPolylineSymbol;
begin
  Result := TTMSFNCGoogleMapsPolylineSymbol(inherited Add);
end;

constructor TTMSFNCGoogleMapsPolylineSymbols.Create(AOwner: TTMSFNCGoogleMapsPolyline);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCGoogleMapsPolylineSymbols.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCGoogleMapsPolylineSymbol;
end;

function TTMSFNCGoogleMapsPolylineSymbols.GetItem(
  Index: Integer): TTMSFNCGoogleMapsPolylineSymbol;
begin
  Result := TTMSFNCGoogleMapsPolylineSymbol(inherited Items[Index]);
end;

function TTMSFNCGoogleMapsPolylineSymbols.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCGoogleMapsPolylineSymbols.Insert(
  Index: Integer): TTMSFNCGoogleMapsPolylineSymbol;
begin
  Result := TTMSFNCGoogleMapsPolylineSymbol(inherited Insert(Index));
end;

procedure TTMSFNCGoogleMapsPolylineSymbols.SetItem(Index: Integer;
  const Value: TTMSFNCGoogleMapsPolylineSymbol);
begin
  inherited Items[Index] := Value;
end;

end.

unit uPOIList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel, VCL.TMSFNCCustomPicker, VCL.TMSFNCCheckGroupPicker,
  VCL.TMSFNCListBox, VCL.TMSFNCCheckedListBox, VCL.TMSFNCPopup;

type
  TPOIListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MyGrid: TTMSFNCGrid;
    DatasetTimer: TTimer;
    ToolbarPanel: TPanel;
    FilterEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebClientConnection1: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    MyDatasetPKey: TStringField;
    MyDatasetLocation: TStringField;
    MyDatasetLatitude: TStringField;
    MyDatasetLongitude: TStringField;
    MyDatasetType: TStringField;
    ListButtonOnlinePanel: TTMSFNCPanel;
    AddButton: TSpeedButton;
    ViewButton: TSpeedButton;
    EditButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    ImportButton: TSpeedButton;
    MyDatasetGroup: TStringField;
    POIGroupList: TTMSFNCCheckedListBox;
    POIGroupEdit: TEdit;
    POIGroupPopup: TTMSFNCPopup;
    POIGroupClearButton: TSpeedButton;
    SearchClearButton: TSpeedButton;
    POIGroupSearchButton: TSpeedButton;
    POIGroupButton: TSpeedButton;
    SelectButton: TSpeedButton;
    MyDatasetID: TStringField;
    MyDatasetCountry: TStringField;
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure DatasetTimerTimer(Sender: TObject); async;
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure FilterEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    procedure WebFormCreate(Sender: TObject); async;
    procedure MyGridDblClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure ViewButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    [async]
    procedure ImportButtonClick(Sender: TObject); async;
    procedure POIGroupEditClick(Sender: TObject);
    procedure POIGroupPopupClosePopup(Sender: TObject);
    procedure POIGroupPopupPopup(Sender: TObject);
    procedure POIGroupListItemCheckChanged(Sender: TObject;
      AItem: TTMSFNCCheckedListBoxItem);
    procedure SearchClearButtonClick(Sender: TObject);
    procedure POIGroupClearButtonClick(Sender: TObject);
    procedure POIGroupSearchButtonClick(Sender: TObject);
    [async]
    procedure POIGroupButtonClick(Sender: TObject); async;
    procedure SelectButtonClick(Sender: TObject);
    procedure MyGridColumnSorted(Sender: TObject; ACol: Integer;
      Direction: TTMSFNCGridSortDirection);
    procedure MyGridCanSortColumn(Sender: TObject; ACol: Integer;
      var Allow: Boolean);
    procedure MyGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
  private
    FocusPKey: Int64;
    Permission: Integer;
    { Private declarations }
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadPOIGroupList(S: String);
    [async]
    procedure LoadList(SearchValue: String); async;
    [async]
    procedure ShowEditDialog(PKey: Integer); async;
    [async]
    procedure DeleteRecord(PKeyList: String); async;
  public
    PKey: Int64;
    Text: String;
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  POIListForm: TPOIListForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceEdit, uPOIEdit, uPOIImport, uPOIGroupList;

procedure TPOIListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  SetGridStyle(MyGrid, False);
end;

procedure TPOIListForm.SetPermission(MyPermission: Integer);
begin
  Permission := MyPermission;
  AddButton.Visible := MyPermission in [2,3,6,7];
  ViewButton.Visible := MyPermission in [1,5];
  EditButton.Visible := MyPermission in [2,3,7];
  DeleteButton.Visible := MyPermission in [4,5,6,7];
end;

procedure TPOIListForm.WebFormShow(Sender: TObject);
begin
  FilterEdit.SetFocus();
  await(LoadLookupValues());
  LoadList(EmptyStr);
end;

procedure TPOIListForm.POIGroupButtonClick(Sender: TObject);
var
  MyWebForm: TPOIGroupListForm;
begin
  MyWebForm := TPOIGroupListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TPOIGroupListForm, MyWebForm.Load());
  try
    await(TModalResult, MyWebForm.Execute);
    if MyWebForm.ModalResult = mrOK then begin
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TPOIListForm.LoadLookupValues;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_poi_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadPOIGroupList(TJSONPair(MyJSONObject.Get('poi_group')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TPOIListForm.LoadPOIGroupList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  Item: TTMSFNCCheckedListBoxItem;
begin
  POIGroupList.BeginUpdate;
  try
    POIGroupList.Items.Clear;
    // Parse JSON
    MyJSON := TJSON.Create;
    try
      MyJSONArray := TJSONArray(MyJSON.Parse(S));
      for i := 0 to MyJSONArray.Count-1 do begin
        MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
        Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items.Add);
        Item.Tag := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        Item.Text := MyJSONObject.GetJSONValue('id');
        Item.SelectedTextColor := clBlack;
      end;
    finally
      MyJSON.Free;
    end;
  finally
    POIGroupList.EndUpdate;
  end;
end;

procedure TPOIListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowEditDialog(-1));
end;

procedure TPOIListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TPOIListForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  if (FocusPKey = -1) then begin
    MyDataset.First;
  end else begin
    if not(MyDataset.Locate('PKey', FocusPKey, [])) then FocusPKey := -1;
  end;
  MyGrid.OnSelectedCell := nil;
  GridDatabaseAdapter.Active := True;
  GridDatabaseAdapter.LoadAllDataAndDisconnect;
  if MyGrid.SortColumn >= 0 then begin
    MyGrid.SortData(MyGrid.SortColumn, MyGrid.SortDirection);
  end;
  if FocusPKey > 0 then GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  MyGrid.OnSelectedCell := MyGridSelectedCell;
  DatasetTimer.Enabled := True;
end;

procedure TPOIListForm.POIGroupClearButtonClick(Sender: TObject);
var
  I: Integer;
begin
  POIGroupEdit.Text := EmptyStr;
  POIGroupEdit.Tag := 0;
  for I := 0 to POIGroupList.Items.Count-1 do begin
    POIGroupList.Items[I].Checked := False;
  end;
  FilterEditChange(FilterEdit);
end;

procedure TPOIListForm.POIGroupEditClick(Sender: TObject);
begin
  POIGroupList.BeginUpdate;
  POIGroupPopup.Popup;
  POIGroupList.EndUpdate;
end;

procedure TPOIListForm.POIGroupListItemCheckChanged(Sender: TObject;
  AItem: TTMSFNCCheckedListBoxItem);
var
  I: Integer;
  StringList: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  StringList := TStringList.Create();
  try
    for I := 0 to POIGroupList.Items.Count-1 do begin
      Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]);
      if Item.Checked then StringList.Add(Item.Text);
    end;
    POIGroupEdit.Text := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
end;

procedure TPOIListForm.MyGridCanSortColumn(Sender: TObject; ACol: Integer;
  var Allow: Boolean);
begin
  Allow := True;
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    FocusPKey := StrToInt(Trim(MyGrid.Cells[0, MyGrid.SelectedRow[0]]));
  end;
end;

procedure TPOIListForm.MyGridColumnSorted(Sender: TObject; ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  end;
end;

procedure TPOIListForm.MyGridDblClick(Sender: TObject);
begin
  if ViewButton.Visible or EditButton.Visible then begin
    EditButton.Click();
  end;
end;

procedure TPOIListForm.MyGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    FocusPKey := StrToInt(Trim(MyGrid.Cells[0, ARow]));
  end;
end;

procedure TPOIListForm.FilterEditChange(Sender: TObject);
begin
  POIGroupEdit.Tag := 0;
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TPOIListForm.ImportButtonClick(Sender: TObject);
var
  MyWebForm: TPOIImportForm;
  MyModalResult: TModalResult;
begin
  MyWebForm := TPOIImportForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TPOIImportForm, MyWebForm.Load());
  try
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      LoadList(FilterEdit.Text);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TPOIListForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  WebWaitMessage.Hide;
end;

procedure TPOIListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  StringList: TStringList;
  I: Integer;
  Item: TTMSFNCCheckedListBoxItem;
begin
//  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_poi_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    if POIGroupEdit.Tag = 1 then begin
      StringList := TStringList.Create();
      try
        for I := 0 to POIGroupList.Items.Count-1 do begin
          Item := TTMSFNCCheckedListBoxItem(POIGroupList.Items[I]);
          if Item.Checked then StringList.Add(IntToStr(Item.Tag));
        end;
        if StringList.Count > 0 then begin
          MyWebRequest.URL := MyWebRequest.URL + '&g=' + StringList.DelimitedText;
        end;
      finally
        StringList.Free;
      end;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TPOIListForm.SearchClearButtonClick(Sender: TObject);
begin
  if FilterEdit.Text <> EmptyStr then begin
    FilterEdit.Text := EmptyStr;
    FilterEditChange(FilterEdit);
  end;
end;

procedure TPOIListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadList(FilterEdit.Text);
  end;
end;

procedure TPOIListForm.SelectButtonClick(Sender: TObject);
begin
  if MyGrid.RowSelectionCount = 1 then begin
    PKey := StrToInt(MyGrid.Cells[0, MyGrid.SelectedRow[0]]);
    Text := MyGrid.Cells[1, MyGrid.SelectedRow[0]];
    ModalResult := mrOK;
  end;
{
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    PKey := MyDataset.FieldByName('PKey').AsLargeInt;
    Text := MyDataset.FieldByName('ID').AsString;
    ModalResult := mrOK;
  end;
}
end;

procedure TPOIListForm.ShowEditDialog(PKey: Integer);
var
  MyWebForm: TPOIEditForm;
  MyModalResult: TModalResult;
begin
  MyWebForm := TPOIEditForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  MyWebForm.PKey := PKey;
  await(TPOIEditForm, MyWebForm.Load());
  try
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      FocusPKey := MyWebForm.PKey;
      LoadList(FilterEdit.Text);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TPOIListForm.POIGroupPopupClosePopup(Sender: TObject);
begin
  //
end;

procedure TPOIListForm.POIGroupPopupPopup(Sender: TObject);
begin
  POIGroupPopup.DropDownHeight := POIGroupList.Height;
  POIGroupPopup.DropDownWidth := POIGroupEdit.Width;
end;

procedure TPOIListForm.POIGroupSearchButtonClick(Sender: TObject);
begin
  POIGroupEdit.Tag := 1;
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TPOIListForm.ViewButtonClick(Sender: TObject);
begin
//  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
//    await(ShowEditDialog(MyDataset.FieldByName('PKey').AsInteger));
//  end;
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    if MyGrid.RowSelectionCount = 1 then begin
      FocusPKey := StrToInt(MyGrid.Cells[0, MyGrid.SelectedRow[0]]);
      await(ShowEditDialog(FocusPKey));
    end;
  end;
end;

procedure TPOIListForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  StringList: TStringList;
  I: Integer;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to MyGrid.RowSelectionCount-1 do begin
        StringList.Add(MyGrid.Cells[0, MyGrid.SelectedRow[I]]);
      end;
      MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog('Delete selected records?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        DeleteRecord(StringList.DelimitedText);
      end;
    finally
      StringList.Free;
    end;
  end;
//  if MyDataSet.Active and not(MyDataSet.IsEmpty) then begin
//    MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog('Delete alert?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
//    if MyModalResult = mrYes then begin
//      DeleteRecord(MyDataset.FieldByName('PKey').AsInteger);
//    end;
//  end;
end;

procedure TPOIListForm.DeleteRecord(PKeyList: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_poi_delete.php?sessionid=' + SessionId +
      '&pkey=' + PKeyList;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        FocusPKey := -1;
        SearchTimer.Enabled := True;
      end else begin
        MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TPOIListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  FilterEdit := TEdit.Create(Self);
  POIGroupEdit := TEdit.Create(Self);
  POIGroupClearButton := TSpeedButton.Create(Self);
  SearchClearButton := TSpeedButton.Create(Self);
  POIGroupSearchButton := TSpeedButton.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  ViewButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  ImportButton := TSpeedButton.Create(Self);
  POIGroupButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  POIGroupList := TTMSFNCCheckedListBox.Create(Self);
  POIGroupPopup := TTMSFNCPopup.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetPKey := TStringField.Create(Self);
  MyDatasetID := TStringField.Create(Self);
  MyDatasetLocation := TStringField.Create(Self);
  MyDatasetLatitude := TStringField.Create(Self);
  MyDatasetLongitude := TStringField.Create(Self);
  MyDatasetType := TStringField.Create(Self);
  MyDatasetGroup := TStringField.Create(Self);
  MyDatasetCountry := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  FilterEdit.BeforeLoadDFMValues;
  POIGroupEdit.BeforeLoadDFMValues;
  POIGroupClearButton.BeforeLoadDFMValues;
  SearchClearButton.BeforeLoadDFMValues;
  POIGroupSearchButton.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  ViewButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  ImportButton.BeforeLoadDFMValues;
  POIGroupButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  POIGroupList.BeforeLoadDFMValues;
  POIGroupPopup.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetPKey.BeforeLoadDFMValues;
  MyDatasetID.BeforeLoadDFMValues;
  MyDatasetLocation.BeforeLoadDFMValues;
  MyDatasetLatitude.BeforeLoadDFMValues;
  MyDatasetLongitude.BeforeLoadDFMValues;
  MyDatasetType.BeforeLoadDFMValues;
  MyDatasetGroup.BeforeLoadDFMValues;
  MyDatasetCountry.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  try
    Name := 'POIListForm';
    Width := 1409;
    Height := 628;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1409;
    FormPanel.Height := 628;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1403;
    ContentPanel.Height := 585;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 54;
    MyGrid.Width := 1363;
    MyGrid.Height := 472;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 1;
    SetEvent(MyGrid, Self, 'OnDblClick', 'MyGridDblClick');
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 8;
    MyGrid.RowCount := 1;
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 2;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.Sorting.MultiColumn := False;
    MyGrid.Options.Sorting.Mode := gsmNormal;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    SetEvent(MyGrid, Self, 'OnSelectedCell', 'MyGridSelectedCell');
    MyGrid.Adapter := GridDatabaseAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 522.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 120.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.Appearance.ShowFocus := False;
    MyGrid.LeftCol := 2;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    SetEvent(MyGrid, Self, 'OnColumnSorted', 'MyGridColumnSorted');
    SetEvent(MyGrid, Self, 'OnCanSortColumn', 'MyGridCanSortColumn');
    WebMessageDlg1.SetParentComponent(MyGrid);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 51;
    WebMessageDlg1.Top := 392;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 447;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := MyGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := '  PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Location';
      Header := '  Address';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Latitude';
      Header := '  Latitude';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Longitude';
      Header := '  Longitude';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Type';
      Header := '  Type';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Group';
      Header := '  Group';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Country';
      Header := '  Country';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1403;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    FilterEdit.SetParentComponent(ToolbarPanel);
    FilterEdit.Name := 'FilterEdit';
    FilterEdit.Left := 20;
    FilterEdit.Top := 10;
    FilterEdit.Width := 345;
    FilterEdit.Height := 34;
    FilterEdit.AutoFocus := True;
    FilterEdit.ChildOrder := 1;
    FilterEdit.Font.Charset := ANSI_CHARSET;
    FilterEdit.Font.Color := clWindowText;
    FilterEdit.Font.Height := -16;
    FilterEdit.Font.Name := 'Segoe UI';
    FilterEdit.Font.Style := [];
    FilterEdit.HeightPercent := 100.000000000000000000;
    FilterEdit.ParentFont := False;
    FilterEdit.TextHint := 'Address';
    FilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterEdit, Self, 'OnChange', 'FilterEditChange');
    POIGroupEdit.SetParentComponent(ToolbarPanel);
    POIGroupEdit.Name := 'POIGroupEdit';
    POIGroupEdit.Left := 378;
    POIGroupEdit.Top := 10;
    POIGroupEdit.Width := 345;
    POIGroupEdit.Height := 34;
    POIGroupEdit.ChildOrder := 1;
    POIGroupEdit.ElementClassName := 'MyWebEdit';
    POIGroupEdit.Font.Charset := ANSI_CHARSET;
    POIGroupEdit.Font.Color := clWindowText;
    POIGroupEdit.Font.Height := -16;
    POIGroupEdit.Font.Name := 'Segoe UI';
    POIGroupEdit.Font.Style := [];
    POIGroupEdit.HeightPercent := 100.000000000000000000;
    POIGroupEdit.ParentFont := False;
    POIGroupEdit.ReadOnly := True;
    POIGroupEdit.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupEdit, Self, 'OnClick', 'POIGroupEditClick');
    POIGroupClearButton.SetParentComponent(ToolbarPanel);
    POIGroupClearButton.Name := 'POIGroupClearButton';
    POIGroupClearButton.AlignWithMargins := True;
    POIGroupClearButton.Left := 690;
    POIGroupClearButton.Top := 12;
    POIGroupClearButton.Width := 30;
    POIGroupClearButton.Height := 30;
    POIGroupClearButton.Margins.Left := 10;
    POIGroupClearButton.Margins.Top := 8;
    POIGroupClearButton.Margins.Right := 0;
    POIGroupClearButton.Margins.Bottom := 8;
    POIGroupClearButton.Color := clNone;
    POIGroupClearButton.ElementClassName := 'MyClearButton';
    POIGroupClearButton.Glyph.LoadFromFile('uPOIList.ToolbarPanel.POIGroupClearButton.Glyph.png');
    POIGroupClearButton.HeightPercent := 100.000000000000000000;
    POIGroupClearButton.TabOrder := 2;
    POIGroupClearButton.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupClearButton, Self, 'OnClick', 'POIGroupClearButtonClick');
    SearchClearButton.SetParentComponent(ToolbarPanel);
    SearchClearButton.Name := 'SearchClearButton';
    SearchClearButton.AlignWithMargins := True;
    SearchClearButton.Left := 333;
    SearchClearButton.Top := 12;
    SearchClearButton.Width := 30;
    SearchClearButton.Height := 30;
    SearchClearButton.Margins.Left := 10;
    SearchClearButton.Margins.Top := 8;
    SearchClearButton.Margins.Right := 0;
    SearchClearButton.Margins.Bottom := 8;
    SearchClearButton.Color := clNone;
    SearchClearButton.ElementClassName := 'MyClearButton';
    SearchClearButton.Glyph.LoadFromFile('uPOIList.ToolbarPanel.SearchClearButton.Glyph.png');
    SearchClearButton.HeightPercent := 100.000000000000000000;
    SearchClearButton.TabOrder := 3;
    SearchClearButton.WidthPercent := 100.000000000000000000;
    SetEvent(SearchClearButton, Self, 'OnClick', 'SearchClearButtonClick');
    POIGroupSearchButton.SetParentComponent(ToolbarPanel);
    POIGroupSearchButton.Name := 'POIGroupSearchButton';
    POIGroupSearchButton.AlignWithMargins := True;
    POIGroupSearchButton.Left := 659;
    POIGroupSearchButton.Top := 12;
    POIGroupSearchButton.Width := 30;
    POIGroupSearchButton.Height := 30;
    POIGroupSearchButton.Margins.Left := 10;
    POIGroupSearchButton.Margins.Top := 8;
    POIGroupSearchButton.Margins.Right := 0;
    POIGroupSearchButton.Margins.Bottom := 8;
    POIGroupSearchButton.Color := clNone;
    POIGroupSearchButton.ElementClassName := 'MyClearButton';
    POIGroupSearchButton.Glyph.LoadFromFile('uPOIList.ToolbarPanel.POIGroupSearchButton.Glyph.png');
    POIGroupSearchButton.HeightPercent := 100.000000000000000000;
    POIGroupSearchButton.TabOrder := 4;
    POIGroupSearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupSearchButton, Self, 'OnClick', 'POIGroupSearchButtonClick');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 536;
    StatusBarPanel.Width := 1403;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1283;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 128;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 1;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 145;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    ViewButton.SetParentComponent(StatusBarPanel);
    ViewButton.Name := 'ViewButton';
    ViewButton.AlignWithMargins := True;
    ViewButton.Left := 253;
    ViewButton.Top := 8;
    ViewButton.Width := 100;
    ViewButton.Height := 31;
    ViewButton.Margins.Left := 0;
    ViewButton.Margins.Top := 8;
    ViewButton.Margins.Right := 8;
    ViewButton.Margins.Bottom := 10;
    ViewButton.Align := alLeft;
    ViewButton.Caption := 'View';
    ViewButton.Color := clNone;
    ViewButton.ElementClassName := 'MyWebButton';
    ViewButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.ViewButton.Glyph.png');
    ViewButton.HeightPercent := 100.000000000000000000;
    ViewButton.TabOrder := 3;
    ViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewButton, Self, 'OnClick', 'ViewButtonClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 361;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 4;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'ViewButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 469;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 5;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    ImportButton.SetParentComponent(StatusBarPanel);
    ImportButton.Name := 'ImportButton';
    ImportButton.AlignWithMargins := True;
    ImportButton.Left := 577;
    ImportButton.Top := 8;
    ImportButton.Width := 100;
    ImportButton.Height := 31;
    ImportButton.Margins.Left := 0;
    ImportButton.Margins.Top := 8;
    ImportButton.Margins.Right := 8;
    ImportButton.Margins.Bottom := 10;
    ImportButton.Align := alLeft;
    ImportButton.Caption := 'Import';
    ImportButton.Color := clNone;
    ImportButton.ElementClassName := 'MyWebButton';
    ImportButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.ImportButton.Glyph.png');
    ImportButton.HeightPercent := 100.000000000000000000;
    ImportButton.TabOrder := 6;
    ImportButton.WidthPercent := 100.000000000000000000;
    SetEvent(ImportButton, Self, 'OnClick', 'ImportButtonClick');
    POIGroupButton.SetParentComponent(StatusBarPanel);
    POIGroupButton.Name := 'POIGroupButton';
    POIGroupButton.AlignWithMargins := True;
    POIGroupButton.Left := 685;
    POIGroupButton.Top := 8;
    POIGroupButton.Width := 100;
    POIGroupButton.Height := 31;
    POIGroupButton.Margins.Left := 0;
    POIGroupButton.Margins.Top := 8;
    POIGroupButton.Margins.Right := 8;
    POIGroupButton.Margins.Bottom := 10;
    POIGroupButton.Align := alLeft;
    POIGroupButton.Caption := 'Groups';
    POIGroupButton.Color := clNone;
    POIGroupButton.ElementClassName := 'MyWebButton';
    POIGroupButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.POIGroupButton.Glyph.png');
    POIGroupButton.HeightPercent := 100.000000000000000000;
    POIGroupButton.TabOrder := 7;
    POIGroupButton.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupButton, Self, 'OnClick', 'POIGroupButtonClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uPOIList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 8;
    SelectButton.Visible := False;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1409;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 159;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Points of interest - List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1380;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uPOIList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uPOIList.WebWaitMessage.Picture.gif');
    POIGroupList.SetParentComponent(Self);
    POIGroupList.Name := 'POIGroupList';
    POIGroupList.Left := 381;
    POIGroupList.Top := 90;
    POIGroupList.Width := 345;
    POIGroupList.Height := 351;
    POIGroupList.BevelInner := bvNone;
    POIGroupList.BevelOuter := bvNone;
    POIGroupList.ParentDoubleBuffered := False;
    POIGroupList.DoubleBuffered := True;
    POIGroupList.Font.Charset := ANSI_CHARSET;
    POIGroupList.Font.Color := clWindowText;
    POIGroupList.Font.Height := -16;
    POIGroupList.Font.Name := 'Segoe UI';
    POIGroupList.Font.Style := [];
    POIGroupList.ParentFont := False;
    POIGroupList.TabOrder := 2;
    POIGroupList.Visible := False;
    POIGroupList.Stroke.Color := 11119017;
    POIGroupList.ItemsAppearance.Font.Charset := ANSI_CHARSET;
    POIGroupList.ItemsAppearance.Font.Color := clGray;
    POIGroupList.ItemsAppearance.Font.Height := -16;
    POIGroupList.ItemsAppearance.Font.Name := 'Segoe UI';
    POIGroupList.ItemsAppearance.Font.Style := [];
    POIGroupList.ItemsAppearance.SelectedFill.Color := 16578806;
    POIGroupList.ItemsAppearance.SelectedStroke.Color := 15702829;
    POIGroupList.ItemsAppearance.SelectedStroke.Width := 2.000000000000000000;
    POIGroupList.ItemsAppearance.FixedHeight := 30.000000000000000000;
    POIGroupList.ItemsAppearance.ShowFocus := False;
    POIGroupList.DefaultItem.TextColor := clGray;
    POIGroupList.Items.Clear;
    with POIGroupList.Items.Add do
    begin
      Text := 'Mercedes';
      TextColor := clGray;
      Tag := 1;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'Audi';
      TextColor := clGray;
      Tag := 2;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'BMW';
      TextColor := clGray;
      Tag := 3;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'Land Rover';
      TextColor := clGray;
      Tag := 4;
    end;
    with POIGroupList.Items.Add do
    begin
      Text := 'Bugatti';
      TextColor := clGray;
      Tag := 5;
    end;
    POIGroupList.Header.Text := 'Header';
    POIGroupList.Header.Fill.Color := 16380654;
    POIGroupList.Header.Font.Charset := DEFAULT_CHARSET;
    POIGroupList.Header.Font.Color := clBlack;
    POIGroupList.Header.Font.Height := -11;
    POIGroupList.Header.Font.Name := 'Tahoma';
    POIGroupList.Header.Font.Style := [];
    POIGroupList.Header.Stroke.Kind := gskNone;
    POIGroupList.Header.Size := 25.000000000000000000;
    POIGroupList.Header.SortIndicatorColor := 11829830;
    POIGroupList.Interaction.Filtering.Enabled := True;
    POIGroupList.GlobalFont.Scale := 1.000000000000000000;
    POIGroupList.GlobalFont.Style := [];
    SetEvent(POIGroupList, Self, 'OnItemCheckChanged', 'POIGroupListItemCheckChanged');
    POIGroupPopup.SetParentComponent(Self);
    POIGroupPopup.Name := 'POIGroupPopup';
    POIGroupPopup.Left := 385;
    POIGroupPopup.Top := 53;
    POIGroupPopup.Width := 26;
    POIGroupPopup.Height := 26;
    POIGroupPopup.Visible := True;
    POIGroupPopup.DropDownHeight := 351.000000000000000000;
    POIGroupPopup.DropDownWidth := 345.000000000000000000;
    POIGroupPopup.PlacementControl := POIGroupEdit;
    POIGroupPopup.ContentControl := POIGroupList;
    SetEvent(POIGroupPopup, Self, 'OnClosePopup', 'POIGroupPopupClosePopup');
    SetEvent(POIGroupPopup, Self, 'OnPopup', 'POIGroupPopupPopup');
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 488;
    MyDatasetPKey.SetParentComponent(MyDataset);
    MyDatasetPKey.Name := 'MyDatasetPKey';
    MyDatasetPKey.FieldName := 'PKey';
    MyDatasetPKey.Size := 256;
    MyDatasetID.SetParentComponent(MyDataset);
    MyDatasetID.Name := 'MyDatasetID';
    MyDatasetID.FieldName := 'ID';
    MyDatasetID.Size := 256;
    MyDatasetLocation.SetParentComponent(MyDataset);
    MyDatasetLocation.Name := 'MyDatasetLocation';
    MyDatasetLocation.FieldName := 'Location';
    MyDatasetLocation.Size := 256;
    MyDatasetLatitude.SetParentComponent(MyDataset);
    MyDatasetLatitude.Name := 'MyDatasetLatitude';
    MyDatasetLatitude.FieldName := 'Latitude';
    MyDatasetLatitude.Size := 256;
    MyDatasetLongitude.SetParentComponent(MyDataset);
    MyDatasetLongitude.Name := 'MyDatasetLongitude';
    MyDatasetLongitude.FieldName := 'Longitude';
    MyDatasetLongitude.Size := 256;
    MyDatasetType.SetParentComponent(MyDataset);
    MyDatasetType.Name := 'MyDatasetType';
    MyDatasetType.FieldName := 'Type';
    MyDatasetType.Size := 256;
    MyDatasetGroup.SetParentComponent(MyDataset);
    MyDatasetGroup.Name := 'MyDatasetGroup';
    MyDatasetGroup.FieldName := 'Group';
    MyDatasetGroup.Size := 256;
    MyDatasetCountry.SetParentComponent(MyDataset);
    MyDatasetCountry.Name := 'MyDatasetCountry';
    MyDatasetCountry.FieldName := 'Country';
    MyDatasetCountry.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 484;
    MyDataSource.Top := 488;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 283;
    DatasetTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.URI := 'https://beta.instatrack.eu/php/default_poi_list.php?sessionid=407bdc1b9d3fd4f278cee14cda390594';
    WebClientConnection1.Left := 191;
    WebClientConnection1.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    FilterEdit.AfterLoadDFMValues;
    POIGroupEdit.AfterLoadDFMValues;
    POIGroupClearButton.AfterLoadDFMValues;
    SearchClearButton.AfterLoadDFMValues;
    POIGroupSearchButton.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    ViewButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    ImportButton.AfterLoadDFMValues;
    POIGroupButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    POIGroupList.AfterLoadDFMValues;
    POIGroupPopup.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetPKey.AfterLoadDFMValues;
    MyDatasetID.AfterLoadDFMValues;
    MyDatasetLocation.AfterLoadDFMValues;
    MyDatasetLatitude.AfterLoadDFMValues;
    MyDatasetLongitude.AfterLoadDFMValues;
    MyDatasetType.AfterLoadDFMValues;
    MyDatasetGroup.AfterLoadDFMValues;
    MyDatasetCountry.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
  end;
end;

end.

unit uModelList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel;

type
  TModelListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    ModelGrid: TTMSFNCGrid;
    DatasetTimer: TTimer;
    ToolbarPanel: TPanel;
    FilterEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebClientConnection1: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    SelectButton: TSpeedButton;
    MyDatasetPKey: TStringField;
    MyDatasetID: TStringField;
    MyDatasetBrandPKey: TStringField;
    MyDatasetBrandID: TStringField;
    ListButtonOnlinePanel: TTMSFNCPanel;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure EditButtonClick(Sender: TObject); async;
    procedure DatasetTimerTimer(Sender: TObject);
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure FilterEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    procedure SelectButtonClick(Sender: TObject);
    procedure ModelGridDblClick(Sender: TObject);
  private
    FocusPKey: Int64;
    { Private declarations }
    [async]
    procedure LoadList(SearchValue: String); async;
    [async]
    procedure ShowEditDialog(PKey: Integer); async;
    [async]
    function CanDeleteRecord(PKey: Integer): Boolean; async;
    [async]
    procedure DeleteRecord(PKey: Integer); async;
  public
    BrandPKey: String;
    ModelPKey: String;
  protected procedure LoadDFMValues; override; end;

var
  ModelListForm: TModelListForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceEdit, uBrandEdit, uModelEdit;

procedure TModelListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  SetGridStyle(ModelGrid, False);
end;

procedure TModelListForm.WebFormShow(Sender: TObject);
begin
  FilterEdit.SetFocus();
  LoadList(EmptyStr);
end;

procedure TModelListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TModelListForm.DeleteButtonClick(Sender: TObject);
var
  Result: Boolean;
begin
  if MyDataSet.Active and not(MyDataSet.IsEmpty) then begin
    Result := await(CanDeleteRecord(MyDataset.FieldByName('PKey').AsInteger));
    if Result then begin
      DeleteRecord(MyDataset.FieldByName('PKey').AsInteger);
    end;
  end;
end;

procedure TModelListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowEditDialog(-1));
end;

procedure TModelListForm.EditButtonClick(Sender: TObject);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    await(ShowEditDialog(MyDataset.FieldByName('PKey').AsInteger));
  end;
end;

procedure TModelListForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  if (FocusPKey = -1) then begin
    MyDataset.First;
  end else begin
    MyDataset.Locate('PKey', FocusPKey, []);
  end;
  GridDatabaseAdapter.Active := True;
  DatasetTimer.Enabled := True;
end;

procedure TModelListForm.ModelGridDblClick(Sender: TObject);
begin
  if EditButton.Visible then begin
    EditButton.Click();
  end;
end;

procedure TModelListForm.FilterEditChange(Sender: TObject);
begin
  FocusPKey := -1;
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TModelListForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  WebWaitMessage.Hide;
end;

procedure TModelListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_model_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TModelListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadList(FilterEdit.Text);
  end;
end;

procedure TModelListForm.SelectButtonClick(Sender: TObject);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    ModelPKey := Trim(MyDataset.FieldByName('PKey').AsString);
    BrandPKey := Trim(MyDataset.FieldByName('BrandPKey').AsString);
    ModalResult := mrOK;
  end;
end;

procedure TModelListForm.ShowEditDialog(PKey: Integer);
var
  MyWebForm: TModelEditForm;
  MyModalResult: TModalResult;
begin
  if
    MyDataset.Active
  and
    (
      (PKey = -1)
    or
      ((PKey > 0) and not(MyDataset.IsEmpty))
    )
  then begin
    MyWebForm := TModelEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.PKey := PKey;
    await(TModelEditForm, MyWebForm.Load());
    try
      MyWebForm.PKey := PKey;
      MyWebForm.IsSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        FocusPKey := MyWebForm.PKey;
        LoadList(FilterEdit.Text);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

function TModelListForm.CanDeleteRecord(PKey: Integer): Boolean;
var
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_model_can_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        // Build Message
        if (MyJO.GetJSONValue('AssetPKey') = '0') then begin
          // No Links
          MyMessage := 'Delete asset?';
        end else begin
          MyMessage :=
            'The device is linked to asset "' + MyJO.GetJSONValue('AssetID') + '". ' +
            'This link will be removed. Delete device?';
        end;
        MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog(MyMessage, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
        Result := MyModalResult = mrYes;
      end else begin
        Result := False;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TModelListForm.DeleteRecord(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_model_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        FocusPKey := -1;
        SearchTimer.Enabled := True;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TModelListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ModelGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  FilterEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetPKey := TStringField.Create(Self);
  MyDatasetID := TStringField.Create(Self);
  MyDatasetBrandPKey := TStringField.Create(Self);
  MyDatasetBrandID := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ModelGrid.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  FilterEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetPKey.BeforeLoadDFMValues;
  MyDatasetID.BeforeLoadDFMValues;
  MyDatasetBrandPKey.BeforeLoadDFMValues;
  MyDatasetBrandID.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  try
    Name := 'ModelListForm';
    Width := 741;
    Height := 628;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 741;
    FormPanel.Height := 628;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 735;
    ContentPanel.Height := 585;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ModelGrid.SetParentComponent(ContentPanel);
    ModelGrid.Name := 'ModelGrid';
    ModelGrid.AlignWithMargins := True;
    ModelGrid.Left := 20;
    ModelGrid.Top := 54;
    ModelGrid.Width := 695;
    ModelGrid.Height := 472;
    ModelGrid.Margins.Left := 20;
    ModelGrid.Margins.Top := 0;
    ModelGrid.Margins.Right := 20;
    ModelGrid.Margins.Bottom := 10;
    ModelGrid.AdaptToStyle := True;
    ModelGrid.Align := alClient;
    ModelGrid.BevelInner := bvNone;
    ModelGrid.BevelOuter := bvNone;
    ModelGrid.BevelWidth := 2;
    ModelGrid.Ctl3D := False;
    ModelGrid.ParentCtl3D := False;
    ModelGrid.ParentDoubleBuffered := False;
    ModelGrid.DoubleBuffered := True;
    ModelGrid.Font.Charset := DEFAULT_CHARSET;
    ModelGrid.Font.Color := clWindowText;
    ModelGrid.Font.Height := -11;
    ModelGrid.Font.Name := 'Tahoma';
    ModelGrid.Font.Style := [];
    ModelGrid.ParentFont := False;
    ModelGrid.TabOrder := 1;
    SetEvent(ModelGrid, Self, 'OnDblClick', 'ModelGridDblClick');
    ModelGrid.DefaultRowHeight := 34.000000000000000000;
    ModelGrid.FixedColumns := 0;
    ModelGrid.RowCount := 1;
    ModelGrid.Options.Borders.FixedCellBorders := bNone;
    ModelGrid.Options.ColumnSize.Stretch := True;
    ModelGrid.Options.ColumnSize.StretchColumn := 0;
    ModelGrid.Options.Editing.CalcFormat := '%g';
    ModelGrid.Options.Grouping.CalcFormat := '%g';
    ModelGrid.Options.Grouping.GroupCountFormat := '(%d)';
    ModelGrid.Options.HTMLExport.CellPadding := 10;
    ModelGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    ModelGrid.Options.Mouse.WheelScrollKeepSelection := True;
    ModelGrid.Options.Mouse.ClickMargin := 0;
    ModelGrid.Options.Mouse.ColumnSizeMargin := 6;
    ModelGrid.Options.Mouse.RowSizeMargin := 6;
    ModelGrid.Options.URL.Color := clSkyBlue;
    ModelGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    ModelGrid.Options.Selection.Mode := smSingleRow;
    ModelGrid.Adapter := GridDatabaseAdapter;
    ModelGrid.HorizontalScrollBarVisible := False;
    ModelGrid.Columns.Clear;
    with ModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 68.000000000000000000;
    end;
    with ModelGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 300.000000000000000000;
    end;
    ModelGrid.Fill.ColorTo := clSilver;
    ModelGrid.DefaultFont.Charset := ANSI_CHARSET;
    ModelGrid.DefaultFont.Color := clWindowText;
    ModelGrid.DefaultFont.Height := -15;
    ModelGrid.DefaultFont.Name := 'Segoe UI';
    ModelGrid.DefaultFont.Style := [];
    ModelGrid.Stroke.Color := 15987699;
    ModelGrid.Stroke.Width := 2.000000000000000000;
    ModelGrid.TopRow := 1;
    ModelGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    ModelGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    ModelGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    ModelGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    ModelGrid.Appearance.FixedLayout.Font.Color := clWhite;
    ModelGrid.Appearance.FixedLayout.Font.Height := -16;
    ModelGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    ModelGrid.Appearance.FixedLayout.Font.Style := [];
    ModelGrid.Appearance.NormalLayout.Fill.Color := -1;
    ModelGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    ModelGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    ModelGrid.Appearance.NormalLayout.Font.Color := clGray;
    ModelGrid.Appearance.NormalLayout.Font.Height := -11;
    ModelGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    ModelGrid.Appearance.NormalLayout.Font.Style := [];
    ModelGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    ModelGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    ModelGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    ModelGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    ModelGrid.Appearance.GroupLayout.Font.Color := clBlack;
    ModelGrid.Appearance.GroupLayout.Font.Height := -11;
    ModelGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    ModelGrid.Appearance.GroupLayout.Font.Style := [];
    ModelGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    ModelGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    ModelGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    ModelGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    ModelGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    ModelGrid.Appearance.SummaryLayout.Font.Height := -11;
    ModelGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    ModelGrid.Appearance.SummaryLayout.Font.Style := [];
    ModelGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    ModelGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    ModelGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    ModelGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    ModelGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    ModelGrid.Appearance.SelectedLayout.Font.Height := -16;
    ModelGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    ModelGrid.Appearance.SelectedLayout.Font.Style := [];
    ModelGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    ModelGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    ModelGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    ModelGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    ModelGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    ModelGrid.Appearance.FocusedLayout.Font.Height := -16;
    ModelGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    ModelGrid.Appearance.FocusedLayout.Font.Style := [];
    ModelGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    ModelGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    ModelGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    ModelGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    ModelGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    ModelGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    ModelGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    ModelGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    ModelGrid.Appearance.BandLayout.Fill.Color := 14062646;
    ModelGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    ModelGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    ModelGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    ModelGrid.Appearance.BandLayout.Font.Color := clBlack;
    ModelGrid.Appearance.BandLayout.Font.Height := -16;
    ModelGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    ModelGrid.Appearance.BandLayout.Font.Style := [];
    ModelGrid.Appearance.ProgressLayout.Color := 15385233;
    ModelGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    ModelGrid.LeftCol := 0;
    ModelGrid.ScrollMode := scmItemScrolling;
    ModelGrid.GlobalFont.Scale := 1.000000000000000000;
    ModelGrid.GlobalFont.Style := [];
    ModelGrid.DesignTimeSampleData := True;
    WebMessageDlg1.SetParentComponent(ModelGrid);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 51;
    WebMessageDlg1.Top := 392;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 447;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := ModelGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  Model';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'BrandID';
      Header := '  Merk';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 735;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    FilterEdit.SetParentComponent(ToolbarPanel);
    FilterEdit.Name := 'FilterEdit';
    FilterEdit.Left := 20;
    FilterEdit.Top := 10;
    FilterEdit.Width := 345;
    FilterEdit.Height := 34;
    FilterEdit.AutoFocus := True;
    FilterEdit.ChildOrder := 1;
    FilterEdit.Font.Charset := ANSI_CHARSET;
    FilterEdit.Font.Color := clWindowText;
    FilterEdit.Font.Height := -16;
    FilterEdit.Font.Name := 'Segoe UI';
    FilterEdit.Font.Style := [];
    FilterEdit.HeightPercent := 100.000000000000000000;
    FilterEdit.ParentFont := False;
    FilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterEdit, Self, 'OnChange', 'FilterEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 536;
    StatusBarPanel.Width := 735;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 615;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uModelList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 252;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uModelList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 144;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uModelList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 360;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uModelList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 0;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 24;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uModelList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 4;
    SelectButton.Visible := False;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 5;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 741;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 82;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Model - List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 712;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uModelList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uModelList.WebWaitMessage.Picture.gif');
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 488;
    MyDatasetPKey.SetParentComponent(MyDataset);
    MyDatasetPKey.Name := 'MyDatasetPKey';
    MyDatasetPKey.FieldName := 'PKey';
    MyDatasetPKey.Visible := False;
    MyDatasetPKey.Size := 256;
    MyDatasetID.SetParentComponent(MyDataset);
    MyDatasetID.Name := 'MyDatasetID';
    MyDatasetID.FieldName := 'ID';
    MyDatasetID.Size := 256;
    MyDatasetBrandPKey.SetParentComponent(MyDataset);
    MyDatasetBrandPKey.Name := 'MyDatasetBrandPKey';
    MyDatasetBrandPKey.FieldName := 'BrandPKey';
    MyDatasetBrandPKey.Visible := False;
    MyDatasetBrandPKey.Size := 256;
    MyDatasetBrandID.SetParentComponent(MyDataset);
    MyDatasetBrandID.Name := 'MyDatasetBrandID';
    MyDatasetBrandID.FieldName := 'BrandID';
    MyDatasetBrandID.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 484;
    MyDataSource.Top := 488;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 283;
    DatasetTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.URI := 'https://instatrack.eu:44321/php/default_model_list.php?sessionid=990264ff86b3801cd9256ae04b28756d';
    WebClientConnection1.Left := 191;
    WebClientConnection1.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ModelGrid.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    FilterEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetPKey.AfterLoadDFMValues;
    MyDatasetID.AfterLoadDFMValues;
    MyDatasetBrandPKey.AfterLoadDFMValues;
    MyDatasetBrandID.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
  end;
end;

end.

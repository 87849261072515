unit uAssetGroupEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLIB.Rest, WEBLIB.JSON, WEBLib.RegularExpressions, Math,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCDigitalTimePicker, VCL.TMSFNCCustomControl, VCL.TMSFNCCustomPicker,
  VCL.TMSFNCAnalogTimePicker, VCL.TMSFNCCustomComponent, VCL.TMSFNCPopup,
  VCL.TMSFNCCustomSelector, VCL.TMSFNCColorSelector, VCL.TMSFNCColorPicker,
  VCL.TMSFNCPageControl, VCL.TMSFNCTabSet, Data.DB, WEBLib.DB, WEBLib.CDS,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCPanel, VCL.TMSFNCCustomScrollControl,
  VCL.TMSFNCGridData, VCL.TMSFNCGrid;

type
  TAssetGroupEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    MyMessageDlg: TMessageDlg;
    PageControl: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    UsernameLabel: TLabel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    LanguageEdit: TLookupComboBox;
    NameEdit: TEdit;
    GSMNumberEdit: TEdit;
    ColorEdit: TTMSFNCColorPicker;
    EmailOneEdit: TEdit;
    EmailTwoEdit: TEdit;
    DateFromEdit: TDateTimePicker;
    DateTillEdit: TDateTimePicker;
    AlertDBAdapter: TTMSFNCGridDatabaseAdapter;
    AlertDataset: TClientDataSet;
    AlertDatasetPKey: TStringField;
    AlertDatasetName: TStringField;
    AlertDataSource: TDataSource;
    AlertGrid: TTMSFNCGrid;
    TMSFNCPanel1: TTMSFNCPanel;
    AddAlertButton: TSpeedButton;
    TMSFNCPanel2: TTMSFNCPanel;
    DeleteAlertButton: TSpeedButton;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    procedure ColorEditItemSelected(Sender: TObject; AItemIndex: Integer);
    procedure PageControlBeforeChangePage(Sender: TObject; ACurrentPageIndex,
      ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure PageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    procedure AlertDatasetAfterOpen(DataSet: TDataSet);
    [async]
    procedure AddAlertButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteAlertButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadAssetGroupColorList(S: String);
    [async]
    procedure LoadData(); async;
    [async]
    procedure LoadAlertList(); async;
    [async]
    procedure AddAlert(AlertPKey: String); async;
    [async]
    procedure DeleteAlert(AlertPKey: String); async;
    [async]
    procedure UpdateUserInterface(); async;
    function GetColorIndex(PKey: String): Integer;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    IsSet: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  AssetGroupEditForm: TAssetGroupEditForm;

implementation

{$R *.dfm}

uses uCommon, uAlertList;

procedure TAssetGroupEditForm.ColorEditItemSelected(Sender: TObject;
  AItemIndex: Integer);
begin
  RecordChange(TObject(Sender));
end;

procedure TAssetGroupEditForm.DeleteAlertButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  StringList: TStringList;
  I: Integer;
begin
  if AlertGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to AlertGrid.RowSelectionCount-1 do begin
        StringList.Add(AlertGrid.Cells[0, AlertGrid.SelectedRow[I]]);
      end;
      if StringList.Count > 0 then begin
        MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selection?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
        if MyModalResult = mrYes then begin
          DeleteAlert(StringList.DelimitedText);
        end;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

function TAssetGroupEditForm.GetColorIndex(PKey: String): Integer;
var
  I: Integer;
begin
  Result := -1;
  for I := 0 to ColorEdit.Items.Count-1 do begin
    if ColorEdit.Items[I].Text = PKey then Result := I;
  end;
end;

procedure TAssetGroupEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
//    ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TAssetGroupEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Visible := False;
end;

procedure TAssetGroupEditForm.WebFormCreate(Sender: TObject);
begin
  SetGridStyle(AlertGrid, False);
end;

procedure TAssetGroupEditForm.WebFormShow(Sender: TObject);
begin
//WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TAssetGroupEditForm.ShowTimerTimer(Sender: TObject);
begin
  ShowTimer.Enabled := False;
  // Load Lookup Values
  await(LoadLookupValues());
  // Load Data
  if PKey >= 0 then begin
    await(LoadData());
  end else begin
    LanguageEdit.Value := '0';
    DateFromEdit.Date := StrToDate('01/01/2010');
    DateTillEdit.Date := StrToDate('01/01/2020');
  end;
  WebWaitMessage.Hide;
  RecordChanged := False;
end;

procedure TAssetGroupEditForm.LoadLookupValues();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_group_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;

  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadAssetGroupColorList(TJSONPair(MyJSONObject.Get('asset_group_color')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetGroupEditForm.PageControlBeforeChangePage(Sender: TObject;
  ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
begin
  if not(PKey > 0) then begin
    ACanChange := False;
    MessageDlg('Save asset group before linking alerts.', TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TAssetGroupEditForm.PageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  // Alerts
  if ACurrentPageIndex = 1 then begin
    LoadAlertList();
  end;
end;

procedure TAssetGroupEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TAssetGroupEditForm.AlertDatasetAfterOpen(DataSet: TDataSet);
begin
  AlertDBAdapter.Active := True;
  AlertDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TAssetGroupEditForm.CloseImageClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      Result := await(ValidInput(ErrorMessage));
      if Result then begin
        Result := await(SaveData(ErrorMessage));
        if Result then begin
          ModalResult := mrOK;
        end else begin
          MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
        end;
      end else begin
        MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    ModalResult := mrCancel;
  end;
end;

procedure TAssetGroupEditForm.LoadAssetGroupColorList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  Item: TTMSFNCColorSelectorItem;
begin
  ColorEdit.Items.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    ColorEdit.Rows := Ceil(MyJSONArray.Count / ColorEdit.Columns);
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      Item := ColorEdit.Items.Add;
      Item.Text := MyJSONObject.GetJSONValue('pkey');
      Item.Color := StrToInt(MyJSONObject.GetJSONValue('color_integer'));
    end;
    ColorEdit.DropDownWidth := ColorEdit.Width;
    ColorEdit.DropDownHeight := ColorEdit.Height * ColorEdit.Rows;
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetGroupEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyColorIndex: Integer;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_group_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      NameEdit.Text := MyJSONObject.GetJSONValue('id');
      MyColorIndex := GetColorIndex(MyJSONObject.GetJSONValue('color_pkey'));
//    MyColorIndex := StrToInt(MyJSONObject.GetJSONValue('color_pkey'))-1;
      ColorEdit.SelectedItemIndex := MyColorIndex;
      ColorEdit.SelectedColor := ColorEdit.Items[MyColorIndex].Color;
      LanguageEdit.Value := MyJSONObject.GetJSONValue('language_pkey');
      EmailOneEdit.Text := MyJSONObject.GetJSONValue('email_one');
      EmailTwoEdit.Text := MyJSONObject.GetJSONValue('email_two');
      GSMNumberEdit.Text := MyJSONObject.GetJSONValue('gsm_number');
      DateFromEdit.Date := StrToDate(MyJSONObject.GetJSONValue('no_gsm_from'));
      DateTillEdit.Date := StrToDate(MyJSONObject.GetJSONValue('no_gsm_till'));
      UpdateUserInterface();
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetGroupEditForm.UpdateUserInterface();
begin
  //
end;

function TAssetGroupEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // ID
  if Result then begin
    if Trim(NameEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'NAME is a required field.';
    end;
  end;
  // Color
  if Result then begin
    if ColorEdit.SelectedItemIndex < 0 then begin
      Result := False;
      ErrorMessage := 'COLOR is a required field.';
    end;
  end;
  // Email 1
  if Result then begin
    if Trim(EmailOneEdit.Text) <> EmptyStr then begin
      if not(TRegEx.IsMatch(EmailOneEdit.Text, RegExEmail)) then begin
        Result := False;
        ErrorMessage := 'EMAIL 1 is not valid.';
      end;
    end;
  end;
  // Email 2
  if Result then begin
    if Trim(EmailTwoEdit.Text) <> EmptyStr then begin
      if not(TRegEx.IsMatch(EmailTwoEdit.Text, RegExEmail)) then begin
        Result := False;
        ErrorMessage := 'EMAIL 2 is not valid.';
      end;
    end;
  end;
end;


function TAssetGroupEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id": "' + Trim(NameEdit.Text) + '",' +
      '"color_pkey": "' + ColorEdit.Items[ColorEdit.SelectedItemIndex].Text + '",' +
      '"language_pkey": "' + LanguageEdit.Value + '",' +
      '"email_one": "' + Trim(EmailOneEdit.Text) + '",' +
      '"email_two": "' + Trim(EmailTwoEdit.Text) + '",' +
      '"gsm_number": "' + Trim(GSMNumberEdit.Text) + '",' +
      '"no_gsm_from": "' + FormatDateTime('yyyy-mm-dd', DateFromEdit.Date) + '",' +
      '"no_gsm_till": "' + FormatDateTime('yyyy-mm-dd', DateTillEdit.Date) + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_group_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetGroupEditForm.LoadAlertList;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_group_alert_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  AlertDataset.Close;
  AlertDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(AlertDataset.Open);
end;

procedure TAssetGroupEditForm.AddAlertButtonClick(Sender: TObject);
var
  MyPermission: Integer;
  MyWebForm: TAlertListForm;
  MyModalResult: TModalResult;
begin
  // Get Permission
  MyPermission := GetUserPermissionValue(Permissions, 'alerts');
  if MyPermission > 0 then begin
    // Create Form
    MyWebForm := TAlertListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    // Load Form
    await(TAlertListForm, MyWebForm.Load());
    try
      // Set Permission
      MyWebForm.SetPermission(MyPermission);
      // Allow Select
      MyWebForm.SelectButton.Visible := True;
//    MyWebForm.MyGrid.Options.Selection.Mode := smDisjunctRow;
      // Show Form
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        // Add Alerts
        AddAlert(MyWebForm.PKey);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TAssetGroupEditForm.AddAlert(AlertPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_group_alert_add.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + AlertPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAlertList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetGroupEditForm.DeleteAlert(AlertPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_group_alert_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + AlertPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAlertList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;


procedure TAssetGroupEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  PageControl := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  AlertGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  AddAlertButton := TSpeedButton.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  DeleteAlertButton := TSpeedButton.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  LanguageEdit := TLookupComboBox.Create(Self);
  NameEdit := TEdit.Create(Self);
  GSMNumberEdit := TEdit.Create(Self);
  ColorEdit := TTMSFNCColorPicker.Create(Self);
  EmailOneEdit := TEdit.Create(Self);
  EmailTwoEdit := TEdit.Create(Self);
  DateFromEdit := TDateTimePicker.Create(Self);
  DateTillEdit := TDateTimePicker.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  AlertDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ShowTimer := TTimer.Create(Self);
  AlertDataset := TClientDataSet.Create(Self);
  AlertDatasetPKey := TStringField.Create(Self);
  AlertDatasetName := TStringField.Create(Self);
  AlertDataSource := TDataSource.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  PageControl.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  AlertGrid.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  AddAlertButton.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  DeleteAlertButton.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  LanguageEdit.BeforeLoadDFMValues;
  NameEdit.BeforeLoadDFMValues;
  GSMNumberEdit.BeforeLoadDFMValues;
  ColorEdit.BeforeLoadDFMValues;
  EmailOneEdit.BeforeLoadDFMValues;
  EmailTwoEdit.BeforeLoadDFMValues;
  DateFromEdit.BeforeLoadDFMValues;
  DateTillEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  AlertDBAdapter.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  AlertDataset.BeforeLoadDFMValues;
  AlertDatasetPKey.BeforeLoadDFMValues;
  AlertDatasetName.BeforeLoadDFMValues;
  AlertDataSource.BeforeLoadDFMValues;
  try
    Name := 'AssetGroupEditForm';
    Width := 502;
    Height := 694;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 502;
    FormPanel.Height := 694;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 496;
    ContentPanel.Height := 651;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 602;
    StatusBarPanel.Width := 496;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 376;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetGroupEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 261;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetGroupEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    PageControl.SetParentComponent(ContentPanel);
    PageControl.Name := 'PageControl';
    PageControl.AlignWithMargins := True;
    PageControl.Left := 10;
    PageControl.Top := 0;
    PageControl.Width := 476;
    PageControl.Height := 594;
    PageControl.Margins.Left := 10;
    PageControl.Margins.Top := 0;
    PageControl.Margins.Right := 10;
    PageControl.Margins.Bottom := 8;
    PageControl.Align := alClient;
    PageControl.BevelEdges := [];
    PageControl.BevelInner := bvNone;
    PageControl.BevelOuter := bvNone;
    PageControl.ParentDoubleBuffered := False;
    PageControl.Color := clBtnFace;
    PageControl.DoubleBuffered := True;
    PageControl.Font.Charset := DEFAULT_CHARSET;
    PageControl.Font.Color := clWindowText;
    PageControl.Font.Height := -16;
    PageControl.Font.Name := 'Tahoma';
    PageControl.Font.Style := [];
    PageControl.ParentFont := False;
    PageControl.TabOrder := 1;
    PageControl.Fill.Kind := gfkNone;
    PageControl.Fill.ColorTo := clWhite;
    PageControl.Stroke.Kind := gskNone;
    PageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    PageControl.TabAppearance.Font.Color := clAqua;
    PageControl.TabAppearance.Font.Height := -16;
    PageControl.TabAppearance.Font.Name := 'Segoe UI';
    PageControl.TabAppearance.Font.Style := [];
    PageControl.TabAppearance.Fill.Color := 14408667;
    PageControl.TabAppearance.Stroke.Color := -1;
    PageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    PageControl.TabAppearance.HoverFill.Color := 15658734;
    PageControl.TabAppearance.HoverStroke.Color := -1;
    PageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    PageControl.TabAppearance.Shape := tsRectangle;
    PageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    PageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    PageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    PageControl.TabAppearance.TextColor := clGrayText;
    PageControl.TabAppearance.ActiveTextColor := clGrayText;
    PageControl.TabAppearance.HoverTextColor := clGrayText;
    PageControl.TabAppearance.ShowFocus := False;
    PageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    PageControl.TabAppearance.BadgeFont.Color := 139;
    PageControl.TabAppearance.BadgeFont.Height := -11;
    PageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    PageControl.TabAppearance.BadgeFont.Style := [fsBold];
    PageControl.ButtonAppearance.CloseIcon.LoadFromFile('uAssetGroupEdit.ContentPanel.PageControl.ButtonAppearance.CloseIcon.svg');
    PageControl.ButtonAppearance.InsertIcon.LoadFromFile('uAssetGroupEdit.ContentPanel.PageControl.ButtonAppearance.InsertIcon.svg');
    PageControl.ButtonAppearance.TabListIcon.LoadFromFile('uAssetGroupEdit.ContentPanel.PageControl.ButtonAppearance.TabListIcon.svg');
    PageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uAssetGroupEdit.ContentPanel.PageControl.ButtonAppearance.ScrollNextIcon.svg');
    PageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uAssetGroupEdit.ContentPanel.PageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    PageControl.TabSize.Height := 45.000000000000000000;
    PageControl.TabSize.Margins.Left := 8.000000000000000000;
    PageControl.TabSize.Margins.Top := 8.000000000000000000;
    PageControl.TabSize.Margins.Right := 8.000000000000000000;
    PageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    PageControl.Pages.Clear;
    with PageControl.Pages.Add do
    begin
      Text := 'GENERAL';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'ALERTS';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'BMW';
      TabVisible := False;
      Visible := False;
      UseDefaultAppearance := True;
    end;
    SetEvent(PageControl, Self, 'OnBeforeChangePage', 'PageControlBeforeChangePage');
    SetEvent(PageControl, Self, 'OnChangePage', 'PageControlChangePage');
    TMSFNCPageControl1Page2.SetParentComponent(PageControl);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 476;
    TMSFNCPageControl1Page2.Height := 542;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    TMSFNCPageControl1Page1.SetParentComponent(PageControl);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 476;
    TMSFNCPageControl1Page1.Height := 542;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    AlertGrid.SetParentComponent(TMSFNCPageControl1Page1);
    AlertGrid.Name := 'AlertGrid';
    AlertGrid.AlignWithMargins := True;
    AlertGrid.Left := 20;
    AlertGrid.Top := 20;
    AlertGrid.Width := 436;
    AlertGrid.Height := 453;
    AlertGrid.Margins.Left := 20;
    AlertGrid.Margins.Top := 20;
    AlertGrid.Margins.Right := 20;
    AlertGrid.Margins.Bottom := 10;
    AlertGrid.AdaptToStyle := True;
    AlertGrid.Align := alClient;
    AlertGrid.BevelInner := bvNone;
    AlertGrid.BevelOuter := bvNone;
    AlertGrid.BevelWidth := 2;
    AlertGrid.Ctl3D := False;
    AlertGrid.ParentCtl3D := False;
    AlertGrid.ParentDoubleBuffered := False;
    AlertGrid.DoubleBuffered := True;
    AlertGrid.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Font.Color := clWindowText;
    AlertGrid.Font.Height := -11;
    AlertGrid.Font.Name := 'Tahoma';
    AlertGrid.Font.Style := [];
    AlertGrid.ParentFont := False;
    AlertGrid.TabOrder := 0;
    AlertGrid.DefaultRowHeight := 34.000000000000000000;
    AlertGrid.FixedColumns := 0;
    AlertGrid.ColumnCount := 4;
    AlertGrid.RowCount := 1;
    AlertGrid.Options.Borders.FixedCellBorders := bNone;
    AlertGrid.Options.ColumnSize.Stretch := True;
    AlertGrid.Options.ColumnSize.StretchColumn := 1;
    AlertGrid.Options.Editing.CalcFormat := '%g';
    AlertGrid.Options.Grouping.CalcFormat := '%g';
    AlertGrid.Options.Grouping.GroupCountFormat := '(%d)';
    AlertGrid.Options.HTMLExport.CellPadding := 10;
    AlertGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    AlertGrid.Options.Mouse.WheelScrollKeepSelection := True;
    AlertGrid.Options.Mouse.ClickMargin := 0;
    AlertGrid.Options.Mouse.ColumnSizeMargin := 6;
    AlertGrid.Options.Mouse.RowSizeMargin := 6;
    AlertGrid.Options.URL.Color := clSkyBlue;
    AlertGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    AlertGrid.Options.Selection.Mode := smDisjunctRow;
    AlertGrid.Adapter := AlertDBAdapter;
    AlertGrid.HorizontalScrollBarVisible := False;
    AlertGrid.Columns.Clear;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 299.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    with AlertGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    AlertGrid.Fill.ColorTo := clSilver;
    AlertGrid.DefaultFont.Charset := ANSI_CHARSET;
    AlertGrid.DefaultFont.Color := clWindowText;
    AlertGrid.DefaultFont.Height := -15;
    AlertGrid.DefaultFont.Name := 'Segoe UI';
    AlertGrid.DefaultFont.Style := [];
    AlertGrid.Stroke.Color := 15987699;
    AlertGrid.Stroke.Width := 2.000000000000000000;
    AlertGrid.TopRow := 1;
    AlertGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    AlertGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    AlertGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FixedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.FixedLayout.Font.Height := -16;
    AlertGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FixedLayout.Font.Style := [];
    AlertGrid.Appearance.NormalLayout.Fill.Color := -1;
    AlertGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    AlertGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.NormalLayout.Font.Color := clGray;
    AlertGrid.Appearance.NormalLayout.Font.Height := -11;
    AlertGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.NormalLayout.Font.Style := [];
    AlertGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    AlertGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Appearance.GroupLayout.Font.Color := clBlack;
    AlertGrid.Appearance.GroupLayout.Font.Height := -11;
    AlertGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    AlertGrid.Appearance.GroupLayout.Font.Style := [];
    AlertGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    AlertGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    AlertGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    AlertGrid.Appearance.SummaryLayout.Font.Height := -11;
    AlertGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    AlertGrid.Appearance.SummaryLayout.Font.Style := [];
    AlertGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    AlertGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.SelectedLayout.Font.Height := -16;
    AlertGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.SelectedLayout.Font.Style := [];
    AlertGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    AlertGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    AlertGrid.Appearance.FocusedLayout.Font.Height := -16;
    AlertGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FocusedLayout.Font.Style := [];
    AlertGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    AlertGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    AlertGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    AlertGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    AlertGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    AlertGrid.Appearance.BandLayout.Fill.Color := 14062646;
    AlertGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    AlertGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    AlertGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    AlertGrid.Appearance.BandLayout.Font.Color := clBlack;
    AlertGrid.Appearance.BandLayout.Font.Height := -16;
    AlertGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    AlertGrid.Appearance.BandLayout.Font.Style := [];
    AlertGrid.Appearance.ProgressLayout.Color := 15385233;
    AlertGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    AlertGrid.LeftCol := 1;
    AlertGrid.ScrollMode := scmItemScrolling;
    AlertGrid.GlobalFont.Scale := 1.000000000000000000;
    AlertGrid.GlobalFont.Style := [];
    AlertGrid.DesignTimeSampleData := True;
    TMSFNCPanel1.SetParentComponent(TMSFNCPageControl1Page1);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 486;
    TMSFNCPanel1.Width := 470;
    TMSFNCPanel1.Height := 53;
    TMSFNCPanel1.Align := alBottom;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 1;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    AddAlertButton.SetParentComponent(TMSFNCPanel1);
    AddAlertButton.Name := 'AddAlertButton';
    AddAlertButton.AlignWithMargins := True;
    AddAlertButton.Left := 20;
    AddAlertButton.Top := 8;
    AddAlertButton.Width := 100;
    AddAlertButton.Height := 35;
    AddAlertButton.Margins.Left := 0;
    AddAlertButton.Margins.Top := 8;
    AddAlertButton.Margins.Right := 8;
    AddAlertButton.Margins.Bottom := 10;
    AddAlertButton.Align := alLeft;
    AddAlertButton.Caption := 'Add';
    AddAlertButton.Color := clNone;
    AddAlertButton.ElementClassName := 'MyWebButton';
    AddAlertButton.Glyph.LoadFromFile('uAssetGroupEdit.TMSFNCPanel1.AddAlertButton.Glyph.png');
    AddAlertButton.HeightPercent := 100.000000000000000000;
    AddAlertButton.TabOrder := 0;
    AddAlertButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddAlertButton, Self, 'OnClick', 'AddAlertButtonClick');
    TMSFNCPanel2.SetParentComponent(TMSFNCPanel1);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 2;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 3;
    TMSFNCPanel2.Width := 14;
    TMSFNCPanel2.Height := 45;
    TMSFNCPanel2.Margins.Bottom := 5;
    TMSFNCPanel2.Align := alLeft;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 1;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
    DeleteAlertButton.SetParentComponent(TMSFNCPanel1);
    DeleteAlertButton.Name := 'DeleteAlertButton';
    DeleteAlertButton.AlignWithMargins := True;
    DeleteAlertButton.Left := 128;
    DeleteAlertButton.Top := 8;
    DeleteAlertButton.Width := 100;
    DeleteAlertButton.Height := 35;
    DeleteAlertButton.Margins.Left := 0;
    DeleteAlertButton.Margins.Top := 8;
    DeleteAlertButton.Margins.Right := 8;
    DeleteAlertButton.Margins.Bottom := 10;
    DeleteAlertButton.Align := alLeft;
    DeleteAlertButton.Caption := 'Delete';
    DeleteAlertButton.Color := clNone;
    DeleteAlertButton.ElementClassName := 'MyWebButton';
    DeleteAlertButton.Glyph.LoadFromFile('uAssetGroupEdit.TMSFNCPanel1.DeleteAlertButton.Glyph.png');
    DeleteAlertButton.HeightPercent := 100.000000000000000000;
    DeleteAlertButton.TabOrder := 2;
    DeleteAlertButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteAlertButton, Self, 'OnClick', 'DeleteAlertButtonClick');
    TMSFNCPageControl1Page0.SetParentComponent(PageControl);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 476;
    TMSFNCPageControl1Page0.Height := 542;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    UsernameLabel.SetParentComponent(TMSFNCPageControl1Page0);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 20;
    UsernameLabel.Top := 165;
    UsernameLabel.Width := 81;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'LANGUAGE';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 21;
    WebLabel1.Width := 44;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'NAME';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 20;
    WebLabel2.Top := 93;
    WebLabel2.Width := 51;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'COLOR';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 20;
    WebLabel3.Top := 385;
    WebLabel3.Width := 272;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'GSM NUMBER (FORMAT: 32xxxxxxxxxx)';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 24;
    WebLabel4.Top := 459;
    WebLabel4.Width := 60;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'NO SMS';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 23;
    WebLabel5.Top := 490;
    WebLabel5.Width := 44;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'FROM';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 284;
    WebLabel6.Top := 490;
    WebLabel6.Width := 28;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'TILL';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := 9010544;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 24;
    WebLabel7.Top := 242;
    WebLabel7.Width := 57;
    WebLabel7.Height := 21;
    WebLabel7.Caption := 'EMAIL 1';
    WebLabel7.Font.Charset := ANSI_CHARSET;
    WebLabel7.Font.Color := 9010544;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Segoe UI';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 23;
    WebLabel8.Top := 314;
    WebLabel8.Width := 57;
    WebLabel8.Height := 21;
    WebLabel8.Caption := 'EMAIL 2';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := 9010544;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    LanguageEdit.SetParentComponent(TMSFNCPageControl1Page0);
    LanguageEdit.Name := 'LanguageEdit';
    LanguageEdit.AlignWithMargins := True;
    LanguageEdit.Left := 20;
    LanguageEdit.Top := 193;
    LanguageEdit.Width := 436;
    LanguageEdit.Height := 34;
    LanguageEdit.Margins.Left := 20;
    LanguageEdit.Margins.Top := 8;
    LanguageEdit.Margins.Bottom := 8;
    LanguageEdit.Color := clWhite;
    LanguageEdit.ElementClassName := 'myselect_white';
    LanguageEdit.Font.Charset := DEFAULT_CHARSET;
    LanguageEdit.Font.Color := clWindowText;
    LanguageEdit.Font.Height := 12;
    LanguageEdit.Font.Name := 'Segoe UI';
    LanguageEdit.Font.Style := [];
    LanguageEdit.HeightPercent := 100.000000000000000000;
    LanguageEdit.ParentFont := False;
    LanguageEdit.WidthPercent := 100.000000000000000000;
    SetEvent(LanguageEdit, Self, 'OnChange', 'RecordChange');
    LanguageEdit.ItemIndex := -1;
    LanguageEdit.LookupValues.Clear;
    with LanguageEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'Dutch';
    end;
    with LanguageEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'French';
    end;
    with LanguageEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'English';
    end;
    NameEdit.SetParentComponent(TMSFNCPageControl1Page0);
    NameEdit.Name := 'NameEdit';
    NameEdit.Left := 20;
    NameEdit.Top := 48;
    NameEdit.Width := 436;
    NameEdit.Height := 34;
    NameEdit.ChildOrder := 1;
    NameEdit.ElementClassName := 'MyWebEdit';
    NameEdit.Font.Charset := ANSI_CHARSET;
    NameEdit.Font.Color := clWindowText;
    NameEdit.Font.Height := -16;
    NameEdit.Font.Name := 'Segoe UI';
    NameEdit.Font.Style := [];
    NameEdit.HeightPercent := 100.000000000000000000;
    NameEdit.ParentFont := False;
    NameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameEdit, Self, 'OnChange', 'RecordChange');
    GSMNumberEdit.SetParentComponent(TMSFNCPageControl1Page0);
    GSMNumberEdit.Name := 'GSMNumberEdit';
    GSMNumberEdit.Left := 20;
    GSMNumberEdit.Top := 412;
    GSMNumberEdit.Width := 436;
    GSMNumberEdit.Height := 34;
    GSMNumberEdit.ChildOrder := 1;
    GSMNumberEdit.EditType := weNumeric;
    GSMNumberEdit.ElementClassName := 'MyWebEdit';
    GSMNumberEdit.Font.Charset := ANSI_CHARSET;
    GSMNumberEdit.Font.Color := clWindowText;
    GSMNumberEdit.Font.Height := -16;
    GSMNumberEdit.Font.Name := 'Segoe UI';
    GSMNumberEdit.Font.Style := [];
    GSMNumberEdit.HeightPercent := 100.000000000000000000;
    GSMNumberEdit.ParentFont := False;
    GSMNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(GSMNumberEdit, Self, 'OnChange', 'RecordChange');
    ColorEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ColorEdit.Name := 'ColorEdit';
    ColorEdit.Left := 20;
    ColorEdit.Top := 120;
    ColorEdit.Width := 436;
    ColorEdit.Height := 34;
    ColorEdit.ParentDoubleBuffered := False;
    ColorEdit.DoubleBuffered := True;
    ColorEdit.Font.Charset := DEFAULT_CHARSET;
    ColorEdit.Font.Color := clWindowText;
    ColorEdit.Font.Height := -11;
    ColorEdit.Font.Name := 'Tahoma';
    ColorEdit.Font.Style := [];
    ColorEdit.TabOrder := 3;
    ColorEdit.DropDownWidth := 397.000000000000000000;
    ColorEdit.Appearance.Stroke.Color := 11119017;
    ColorEdit.Appearance.Fill.Color := 15329769;
    ColorEdit.Appearance.StrokeHover.Color := 10061943;
    ColorEdit.Appearance.FillHover.Color := 13419707;
    ColorEdit.Appearance.StrokeDown.Color := 9470064;
    ColorEdit.Appearance.FillDown.Color := 13156536;
    ColorEdit.Appearance.StrokeSelected.Color := 5197615;
    ColorEdit.Appearance.FillSelected.Color := 13156536;
    ColorEdit.Appearance.StrokeDisabled.Color := 11119017;
    ColorEdit.Appearance.FillDisabled.Color := clSilver;
    ColorEdit.Appearance.VerticalSpacing := 0.000000000000000000;
    ColorEdit.Appearance.HorizontalSpacing := 0.000000000000000000;
    ColorEdit.Appearance.SeparatorStroke.Color := 11119017;
    ColorEdit.Appearance.Font.Charset := DEFAULT_CHARSET;
    ColorEdit.Appearance.Font.Color := clWindowText;
    ColorEdit.Appearance.Font.Height := 0;
    ColorEdit.Appearance.Font.Name := 'Tahoma';
    ColorEdit.Appearance.Font.Style := [];
    ColorEdit.Rows := 5;
    ColorEdit.Columns := 5;
    ColorEdit.Items.Clear;
    with ColorEdit.Items.Add do
    begin
      Text := 'A';
      Color := clBlack;
    end;
    with ColorEdit.Items.Add do
    begin
      Text := 'B';
      Color := clMaroon;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clGreen;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clOlive;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clNavy;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clPurple;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clTeal;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clSilver;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clGray;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clRed;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clLime;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clYellow;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clBlue;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clFuchsia;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clAqua;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clWhite;
    end;
    with ColorEdit.Items.Add do
    begin
      Color := clSkyBlue;
    end;
    SetEvent(ColorEdit, Self, 'OnItemSelected', 'ColorEditItemSelected');
    EmailOneEdit.SetParentComponent(TMSFNCPageControl1Page0);
    EmailOneEdit.Name := 'EmailOneEdit';
    EmailOneEdit.Left := 24;
    EmailOneEdit.Top := 269;
    EmailOneEdit.Width := 432;
    EmailOneEdit.Height := 34;
    EmailOneEdit.ChildOrder := 1;
    EmailOneEdit.ElementClassName := 'MyWebEdit';
    EmailOneEdit.Font.Charset := ANSI_CHARSET;
    EmailOneEdit.Font.Color := clWindowText;
    EmailOneEdit.Font.Height := -16;
    EmailOneEdit.Font.Name := 'Segoe UI';
    EmailOneEdit.Font.Style := [];
    EmailOneEdit.HeightPercent := 100.000000000000000000;
    EmailOneEdit.ParentFont := False;
    EmailOneEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailOneEdit, Self, 'OnChange', 'RecordChange');
    EmailTwoEdit.SetParentComponent(TMSFNCPageControl1Page0);
    EmailTwoEdit.Name := 'EmailTwoEdit';
    EmailTwoEdit.Left := 23;
    EmailTwoEdit.Top := 341;
    EmailTwoEdit.Width := 433;
    EmailTwoEdit.Height := 34;
    EmailTwoEdit.ChildOrder := 1;
    EmailTwoEdit.ElementClassName := 'MyWebEdit';
    EmailTwoEdit.Font.Charset := ANSI_CHARSET;
    EmailTwoEdit.Font.Color := clWindowText;
    EmailTwoEdit.Font.Height := -16;
    EmailTwoEdit.Font.Name := 'Segoe UI';
    EmailTwoEdit.Font.Style := [];
    EmailTwoEdit.HeightPercent := 100.000000000000000000;
    EmailTwoEdit.ParentFont := False;
    EmailTwoEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailTwoEdit, Self, 'OnChange', 'RecordChange');
    DateFromEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DateFromEdit.Name := 'DateFromEdit';
    DateFromEdit.AlignWithMargins := True;
    DateFromEdit.Left := 80;
    DateFromEdit.Top := 484;
    DateFromEdit.Width := 133;
    DateFromEdit.Height := 34;
    DateFromEdit.Margins.Left := 20;
    DateFromEdit.Margins.Top := 8;
    DateFromEdit.Margins.Bottom := 8;
    DateFromEdit.ElementClassName := 'tms-calendar-background-default';
    DateFromEdit.BorderStyle := bsSingle;
    DateFromEdit.ChildOrder := 2;
    DateFromEdit.Color := clWhite;
    DateFromEdit.Date := 44545.380967847220000000;
    DateFromEdit.Font.Charset := ANSI_CHARSET;
    DateFromEdit.Font.Color := clWindowText;
    DateFromEdit.Font.Height := -15;
    DateFromEdit.Font.Name := 'Segoe UI';
    DateFromEdit.Font.Style := [];
    DateFromEdit.ParentFont := False;
    DateFromEdit.Role := '';
    DateFromEdit.Text := '';
    SetEvent(DateFromEdit, Self, 'OnChange', 'RecordChange');
    DateTillEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DateTillEdit.Name := 'DateTillEdit';
    DateTillEdit.AlignWithMargins := True;
    DateTillEdit.Left := 323;
    DateTillEdit.Top := 484;
    DateTillEdit.Width := 133;
    DateTillEdit.Height := 34;
    DateTillEdit.Margins.Left := 20;
    DateTillEdit.Margins.Top := 8;
    DateTillEdit.Margins.Bottom := 8;
    DateTillEdit.ElementClassName := 'tms-calendar-background-default';
    DateTillEdit.BorderStyle := bsSingle;
    DateTillEdit.ChildOrder := 2;
    DateTillEdit.Color := clWhite;
    DateTillEdit.Date := 44545.380967847220000000;
    DateTillEdit.Font.Charset := ANSI_CHARSET;
    DateTillEdit.Font.Color := clWindowText;
    DateTillEdit.Font.Height := -15;
    DateTillEdit.Font.Name := 'Segoe UI';
    DateTillEdit.Font.Style := [];
    DateTillEdit.ParentFont := False;
    DateTillEdit.Role := '';
    DateTillEdit.Text := '';
    SetEvent(DateTillEdit, Self, 'OnChange', 'RecordChange');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 502;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 130;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Group - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 473;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetGroupEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 178;
    WebWaitMessage.Top := 172;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAssetGroupEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 83;
    MyMessageDlg.Top := 172;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    AlertDBAdapter.SetParentComponent(Self);
    AlertDBAdapter.Name := 'AlertDBAdapter';
    AlertDBAdapter.Left := 83;
    AlertDBAdapter.Top := 235;
    AlertDBAdapter.Width := 26;
    AlertDBAdapter.Height := 26;
    AlertDBAdapter.Margins.Left := 12;
    AlertDBAdapter.Visible := True;
    AlertDBAdapter.Grid := AlertGrid;
    AlertDBAdapter.DataSource := AlertDataSource;
    AlertDBAdapter.Columns.Clear;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with AlertDBAdapter.Columns.Add do
    begin
      FieldName := 'Name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    AlertDBAdapter.AutoCreateColumns := False;
    AlertDBAdapter.AutoRemoveColumns := False;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 281;
    ShowTimer.Top := 174;
    AlertDataset.SetParentComponent(Self);
    AlertDataset.Name := 'AlertDataset';
    AlertDataset.AfterOpen := AlertDatasetAfterOpen;
    AlertDataset.Left := 173;
    AlertDataset.Top := 234;
    AlertDatasetPKey.SetParentComponent(AlertDataset);
    AlertDatasetPKey.Name := 'AlertDatasetPKey';
    AlertDatasetPKey.FieldName := 'PKey';
    AlertDatasetPKey.Size := 256;
    AlertDatasetName.SetParentComponent(AlertDataset);
    AlertDatasetName.Name := 'AlertDatasetName';
    AlertDatasetName.FieldName := 'Name';
    AlertDatasetName.Size := 256;
    AlertDataSource.SetParentComponent(Self);
    AlertDataSource.Name := 'AlertDataSource';
    AlertDataSource.DataSet := AlertDataset;
    AlertDataSource.Left := 281;
    AlertDataSource.Top := 234;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    PageControl.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    AlertGrid.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    AddAlertButton.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    DeleteAlertButton.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    LanguageEdit.AfterLoadDFMValues;
    NameEdit.AfterLoadDFMValues;
    GSMNumberEdit.AfterLoadDFMValues;
    ColorEdit.AfterLoadDFMValues;
    EmailOneEdit.AfterLoadDFMValues;
    EmailTwoEdit.AfterLoadDFMValues;
    DateFromEdit.AfterLoadDFMValues;
    DateTillEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    AlertDBAdapter.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    AlertDataset.AfterLoadDFMValues;
    AlertDatasetPKey.AfterLoadDFMValues;
    AlertDatasetName.AfterLoadDFMValues;
    AlertDataSource.AfterLoadDFMValues;
  end;
end;

end.

unit uUserList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel;

type
  TUserListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MasterDataset: TClientDataSet;
    MasterSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    MyDBAdapter: TTMSFNCGridDatabaseAdapter;
    MyGrid: TTMSFNCGrid;
    MasterTimer: TTimer;
    ToolbarPanel: TPanel;
    SearchEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebClientConnection1: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    SelectButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    ViewButton: TSpeedButton;
    [async]
    ManagerButton: TSpeedButton;
    GridClipboardButton: TSpeedButton;
    MasterDatasetpkey: TStringField;
    MasterDatasetactive: TStringField;
    MasterDatasetusername: TStringField;
    MasterDatasetmanager: TStringField;
    MasterDatasetusertype: TStringField;
    MasterDatasetexpires: TStringField;
    MasterDatasetlastlogin: TStringField;
    MasterDatasetauthorization: TStringField;
    CellClipboardButton: TSpeedButton;
    MasterDatasetdriver: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure EditButtonClick(Sender: TObject); async;
    procedure MasterTimerTimer(Sender: TObject);
    procedure MasterDatasetAfterOpen(DataSet: TDataSet);
    procedure SearchEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure SelectButtonClick(Sender: TObject);
    procedure MyGridDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure ManagerButtonClick(Sender: TObject); async;
    procedure GridClipboardButtonClick(Sender: TObject);
    procedure MyGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
    procedure CellClipboardButtonClick(Sender: TObject);
    procedure MyGridCanSortColumn(Sender: TObject; ACol: Integer;
      var Allow: Boolean);
    procedure MyGridColumnSorted(Sender: TObject; ACol: Integer;
      Direction: TTMSFNCGridSortDirection);
    procedure MyGridCanSizeColumn(Sender: TObject; ACol: Integer;
      var Allow: Boolean);
    procedure MyGridAfterApplyFilter(Sender: TObject; Col: Integer;
      Condition: string; var UpdateCalculations: Boolean);
  private
    { Private declarations }
    FocusPKey: Int64;
    Permission: Integer;
    [async]
    procedure LoadList(SearchValue: String); async;
    [async]
    procedure ShowEditDialog(PKey: Integer); async;
    [async]
    procedure DeleteItem(PKey: Integer); async;
    [async]
    procedure SetManager(UserList, UserPKey: String); async;
    [async]
    procedure SetManagerPost(UserList, ManagerList: String); async;
  public
    UserPKey: String;
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  UserListForm: TUserListForm;

implementation

{$R *.dfm}

uses
  uCommon, uUserEdit, uManagerList;

procedure TUserListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  SetGridStyle(MyGrid, False);
end;

procedure TUserListForm.SetPermission(MyPermission: Integer);
begin
  Permission := MyPermission;
  AddButton.Visible := MyPermission in [2,3,6,7];
  ViewButton.Visible := MyPermission in [1,5];
  EditButton.Visible := MyPermission in [2,3,7];
  DeleteButton.Visible := MyPermission in [4,5,6,7];
end;

procedure TUserListForm.WebFormShow(Sender: TObject);
begin
  SearchEdit.SetFocus();
  SearchTimer.Enabled := True;
end;

procedure TUserListForm.GridClipboardButtonClick(Sender: TObject);
begin
  GridToClipBoard(MyGrid, True);
end;

procedure TUserListForm.CellClipboardButtonClick(Sender: TObject);
begin
  CellToClipBoard(MyGrid);
end;

procedure TUserListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TUserListForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if MasterDataSet.Active and not(MasterDataSet.IsEmpty) then begin
    MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog('Delete user?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      DeleteItem(StrToInt(MyGrid.Cells[0, MyGrid.SelectedRow[0]]));
    end;
  end;
end;

procedure TUserListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowEditDialog(-1));
end;

procedure TUserListForm.EditButtonClick(Sender: TObject);
begin
  if MyGrid.RowSelectionCount = 1 then begin
    await(ShowEditDialog(StrToInt(MyGrid.Cells[0, MyGrid.SelectedRow[0]])));
  end else begin
    MessageDlg('Please select exactly one record', WEBLib.Dialogs.mtError, [mbOK], nil);
  end;
end;

procedure TUserListForm.ManagerButtonClick(Sender: TObject);
var
  MyWebForm: TManagerListForm;
  MyModalResult: TModalResult;
  S: String;
  I: Integer;
  StringList: TStringList;
begin
  if ((MyGrid.VisibleRowCount > 1) and (MyGrid.GetSelectedRowCount > 0)) then begin
    StringList := TStringList.Create;
    try
      for I := 0 to MyGrid.GetSelectedRowCount-1 do begin
        S := MyGrid.Cells[0, MyGrid.SelectedRow[I]];
        StringList.Add(S);
        FocusPKey := StrToInt(S);
      end;
      // Show Managers
      MyWebForm := TManagerListForm.Create(Self);
      MyWebForm.Popup := True;
      MyWebForm.Border := fbNone;
      await(TManagerListForm, MyWebForm.Load());
      try
        MyModalResult := await(TModalResult, MyWebForm.Execute);
        if MyModalResult = mrOK then begin
          WebWaitMessage.Show;
          SetManagerPost(StringList.DelimitedText, MyWebForm.UserPKey);
        end;
      finally
        MyWebForm.Free;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TUserListForm.MasterDatasetAfterOpen(DataSet: TDataSet);
begin
  if (FocusPKey = -1) then begin
    MasterDataset.First;
  end else begin
    if not(MasterDataset.Locate('PKey', FocusPKey, [])) then FocusPKey := -1;
  end;
  MyGrid.OnSelectedCell := nil;
  MyDBAdapter.Active := True;
  MyDBAdapter.LoadAllDataAndDisconnect;
  if MyGrid.SortColumn >= 0 then begin
    MyGrid.SortData(MyGrid.SortColumn, MyGrid.SortDirection);
  end;
  if MyGrid.Filter.Count > 0 then begin
    MyGrid.ApplyFilter;
  end;
  if FocusPKey > 0 then GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  MyGrid.OnSelectedCell := MyGridSelectedCell;
  // Enable Timer
  MasterTimer.Enabled := True;
end;

procedure TUserListForm.MyGridAfterApplyFilter(Sender: TObject; Col: Integer;
  Condition: string; var UpdateCalculations: Boolean);
begin
  MyGrid.ClearSelection;
  if ((MyGrid.VisibleRowCount > 1) and (FocusPKey <> -1)) then begin
    GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  end;
end;

procedure TUserListForm.MyGridCanSizeColumn(Sender: TObject; ACol: Integer;
  var Allow: Boolean);
begin
  if ACol = 0 then Allow := False;
end;

procedure TUserListForm.MyGridCanSortColumn(Sender: TObject; ACol: Integer;
  var Allow: Boolean);
var
  AValue: String;
begin
  Allow := True;
  if ((MyGrid.VisibleRowCount > 0) and (MyGrid.GetSelectedRowCount > 0)) then begin
    FocusPKey := StrToInt(Trim(MyGrid.Cells[0, MyGrid.SelectedRow[0]]));
  end else begin
    FocusPKey := -1;
  end;
end;

procedure TUserListForm.MyGridColumnSorted(Sender: TObject; ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
  if ((MyGrid.VisibleRowCount > 0) and (FocusPKey <> -1)) then begin
    GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  end;
end;

procedure TUserListForm.MyGridDblClick(Sender: TObject);
begin
  if ViewButton.Visible or EditButton.Visible then begin
    EditButton.Click();
  end;
end;

procedure TUserListForm.MyGridSelectedCell(Sender: TObject; ACol,
  ARow: Integer);
begin
  if ((MyGrid.VisibleRowCount > 0) and (ARow > 0)) then begin
    FocusPKey := StrToInt(Trim(MyGrid.Cells[0, ARow]));
  end;
end;

procedure TUserListForm.SearchEditChange(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TUserListForm.MasterTimerTimer(Sender: TObject);
begin
  MasterTimer.Enabled := False;
  MyGrid.Invalidate;
  WebWaitMessage.Hide;
end;

procedure TUserListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  if not(WebWaitMessage.Showing) then WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    if MasterDataset.Filtered then begin
      MyWebRequest.URL := MyWebRequest.URL + '&d=1';
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  if MasterDataset.Active then MasterDataset.EmptyDataSet;
  MasterDataset.Close;
  MasterDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MasterDataset.Open);
//  // Open DB Adapter
//  MyDBAdapter.Active := True;
//  MyDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TUserListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    WebWaitMessage.Show;
    LoadList(SearchEdit.Text);
  end;
end;

procedure TUserListForm.SelectButtonClick(Sender: TObject);
begin
  if MyGrid.RowSelectionCount = 1 then begin
    UserPKey := MyGrid.Cells[0, MyGrid.SelectedRow[0]];
    ModalResult := mrOK;
  end;
end;

procedure TUserListForm.ShowEditDialog(PKey: Integer);
var
  MyWebForm: TUserEditForm;
  MyModalResult: TModalResult;
begin
  if
    MasterDataset.Active
  and
    (
      (PKey = -1)
    or
      ((PKey > 0) and not(MasterDataset.IsEmpty))
    )
  then begin
    MyWebForm := TUserEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.PKey := PKey;
    await(TUserEditForm, MyWebForm.Load());
    try
      MyWebForm.PKey := PKey;
      // Permission
      if Permission in [1,5] then begin
        MyWebForm.SetReadOnly;
      end;
      MyWebForm.IsSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        FocusPKey := MyWebForm.PKey;
        LoadList(SearchEdit.Text);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TUserListForm.SetManager(UserList, UserPKey: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_user_set_manager.php?sessionid=' + SessionId +
      '&userlist=' + UserList +
      '&userpkey=' + UserPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
//      FocusPKey := -1;
        SearchTimer.Enabled := True;
      end else begin
        MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TUserListForm.SetManagerPost(UserList, ManagerList: String);
var
  MyString, ErrorMessage: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyString :=
    '{' +
      '"sessionid":"' + SessionId + '",' +
      '"userlist": "' + UserList + '",' +
      '"managerlist": "' + ManagerList + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_user_set_manager_post.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          LoadList(SearchEdit.Text);
        end else begin
          WebWaitMessage.Hide;
          ErrorMessage := MyJSONObject.GetJSONValue('error_message');
          MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end
    end else begin
      WebWaitMessage.Hide;
      ErrorMessage := 'HTTP Error ' + IntToStr(MyRequest.Status);
      MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
    end;
  finally
    MyWebRequest.Free;
  end;
end;


procedure TUserListForm.DeleteItem(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_user_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        FocusPKey := -1;
        SearchTimer.Enabled := True;
      end else begin
        MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TUserListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  SearchEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  ViewButton := TSpeedButton.Create(Self);
  ManagerButton := TSpeedButton.Create(Self);
  GridClipboardButton := TSpeedButton.Create(Self);
  CellClipboardButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MasterDataset := TClientDataSet.Create(Self);
  MasterDatasetpkey := TStringField.Create(Self);
  MasterDatasetactive := TStringField.Create(Self);
  MasterDatasetusername := TStringField.Create(Self);
  MasterDatasetmanager := TStringField.Create(Self);
  MasterDatasetusertype := TStringField.Create(Self);
  MasterDatasetexpires := TStringField.Create(Self);
  MasterDatasetlastlogin := TStringField.Create(Self);
  MasterDatasetdriver := TStringField.Create(Self);
  MasterDatasetauthorization := TStringField.Create(Self);
  MasterSource := TDataSource.Create(Self);
  MasterTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  SearchEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  ViewButton.BeforeLoadDFMValues;
  ManagerButton.BeforeLoadDFMValues;
  GridClipboardButton.BeforeLoadDFMValues;
  CellClipboardButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyDBAdapter.BeforeLoadDFMValues;
  MasterDataset.BeforeLoadDFMValues;
  MasterDatasetpkey.BeforeLoadDFMValues;
  MasterDatasetactive.BeforeLoadDFMValues;
  MasterDatasetusername.BeforeLoadDFMValues;
  MasterDatasetmanager.BeforeLoadDFMValues;
  MasterDatasetusertype.BeforeLoadDFMValues;
  MasterDatasetexpires.BeforeLoadDFMValues;
  MasterDatasetlastlogin.BeforeLoadDFMValues;
  MasterDatasetdriver.BeforeLoadDFMValues;
  MasterDatasetauthorization.BeforeLoadDFMValues;
  MasterSource.BeforeLoadDFMValues;
  MasterTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  try
    Name := 'UserListForm';
    Width := 1378;
    Height := 634;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1378;
    FormPanel.Height := 634;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1372;
    ContentPanel.Height := 591;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 54;
    MyGrid.Width := 1332;
    MyGrid.Height := 478;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 0;
    SetEvent(MyGrid, Self, 'OnDblClick', 'MyGridDblClick');
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 9;
    MyGrid.RowCount := 1;
    SetEvent(MyGrid, Self, 'OnAfterApplyFilter', 'MyGridAfterApplyFilter');
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 2;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Editing.Enabled := False;
    MyGrid.Options.Filtering.DropDown := True;
    MyGrid.Options.Filtering.DropDownWidth := 240;
    MyGrid.Options.Filtering.DropDownHeight := 240;
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ColumnSizing := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.Sorting.IgnoreCase := True;
    MyGrid.Options.Sorting.IgnoreBlanks := True;
    MyGrid.Options.Sorting.Mode := gsmNormal;
    MyGrid.Options.Sorting.Columns := scNormal;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    MyGrid.Adapter := MyDBAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 1.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Name := 'Active';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 365.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 300.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 165.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      ReadOnly := True;
      Width := 100.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 0;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.DesignTimeSampleData := True;
    SetEvent(MyGrid, Self, 'OnCanSizeColumn', 'MyGridCanSizeColumn');
    SetEvent(MyGrid, Self, 'OnColumnSorted', 'MyGridColumnSorted');
    SetEvent(MyGrid, Self, 'OnCanSortColumn', 'MyGridCanSortColumn');
    WebMessageDlg1.SetParentComponent(MyGrid);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 51;
    WebMessageDlg1.Top := 392;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1372;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    SearchEdit.SetParentComponent(ToolbarPanel);
    SearchEdit.Name := 'SearchEdit';
    SearchEdit.Left := 20;
    SearchEdit.Top := 10;
    SearchEdit.Width := 345;
    SearchEdit.Height := 34;
    SearchEdit.AutoFocus := True;
    SearchEdit.ChildOrder := 1;
    SearchEdit.Font.Charset := ANSI_CHARSET;
    SearchEdit.Font.Color := clWindowText;
    SearchEdit.Font.Height := -16;
    SearchEdit.Font.Name := 'Segoe UI';
    SearchEdit.Font.Style := [];
    SearchEdit.HeightPercent := 100.000000000000000000;
    SearchEdit.ParentFont := False;
    SearchEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SearchEdit, Self, 'OnChange', 'SearchEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 542;
    StatusBarPanel.Width := 1372;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1252;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 361;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 145;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 469;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 4;
    SelectButton.Visible := False;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 128;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 5;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ViewButton.SetParentComponent(StatusBarPanel);
    ViewButton.Name := 'ViewButton';
    ViewButton.AlignWithMargins := True;
    ViewButton.Left := 253;
    ViewButton.Top := 8;
    ViewButton.Width := 100;
    ViewButton.Height := 31;
    ViewButton.Margins.Left := 0;
    ViewButton.Margins.Top := 8;
    ViewButton.Margins.Right := 8;
    ViewButton.Margins.Bottom := 10;
    ViewButton.Align := alLeft;
    ViewButton.Caption := 'View';
    ViewButton.Color := clNone;
    ViewButton.ElementClassName := 'MyWebButton';
    ViewButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.ViewButton.Glyph.png');
    ViewButton.HeightPercent := 100.000000000000000000;
    ViewButton.TabOrder := 6;
    ViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewButton, Self, 'OnClick', 'EditButtonClick');
    ManagerButton.SetParentComponent(StatusBarPanel);
    ManagerButton.Name := 'ManagerButton';
    ManagerButton.AlignWithMargins := True;
    ManagerButton.Left := 849;
    ManagerButton.Top := 8;
    ManagerButton.Width := 112;
    ManagerButton.Height := 31;
    ManagerButton.Margins.Left := 0;
    ManagerButton.Margins.Top := 8;
    ManagerButton.Margins.Right := 8;
    ManagerButton.Margins.Bottom := 10;
    ManagerButton.Align := alLeft;
    ManagerButton.Caption := 'Manager';
    ManagerButton.Color := clNone;
    ManagerButton.ElementClassName := 'MyWebButton';
    ManagerButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.ManagerButton.Glyph.png');
    ManagerButton.HeightPercent := 100.000000000000000000;
    ManagerButton.TabOrder := 7;
    ManagerButton.WidthPercent := 100.000000000000000000;
    SetEvent(ManagerButton, Self, 'OnClick', 'ManagerButtonClick');
    GridClipboardButton.SetParentComponent(StatusBarPanel);
    GridClipboardButton.Name := 'GridClipboardButton';
    GridClipboardButton.AlignWithMargins := True;
    GridClipboardButton.Left := 577;
    GridClipboardButton.Top := 8;
    GridClipboardButton.Width := 128;
    GridClipboardButton.Height := 31;
    GridClipboardButton.Margins.Left := 0;
    GridClipboardButton.Margins.Top := 8;
    GridClipboardButton.Margins.Right := 8;
    GridClipboardButton.Margins.Bottom := 10;
    GridClipboardButton.Align := alLeft;
    GridClipboardButton.Caption := 'Copy Rows';
    GridClipboardButton.Color := clNone;
    GridClipboardButton.ElementClassName := 'MyWebButton';
    GridClipboardButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.GridClipboardButton.Glyph.png');
    GridClipboardButton.HeightPercent := 100.000000000000000000;
    GridClipboardButton.TabOrder := 8;
    GridClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(GridClipboardButton, Self, 'OnClick', 'GridClipboardButtonClick');
    CellClipboardButton.SetParentComponent(StatusBarPanel);
    CellClipboardButton.Name := 'CellClipboardButton';
    CellClipboardButton.AlignWithMargins := True;
    CellClipboardButton.Left := 713;
    CellClipboardButton.Top := 8;
    CellClipboardButton.Width := 128;
    CellClipboardButton.Height := 31;
    CellClipboardButton.Margins.Left := 0;
    CellClipboardButton.Margins.Top := 8;
    CellClipboardButton.Margins.Right := 8;
    CellClipboardButton.Margins.Bottom := 10;
    CellClipboardButton.Align := alLeft;
    CellClipboardButton.Caption := 'Copy Cell';
    CellClipboardButton.Color := clNone;
    CellClipboardButton.ElementClassName := 'MyWebButton';
    CellClipboardButton.Glyph.LoadFromFile('uUserList.StatusBarPanel.CellClipboardButton.Glyph.png');
    CellClipboardButton.HeightPercent := 100.000000000000000000;
    CellClipboardButton.TabOrder := 9;
    CellClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CellClipboardButton, Self, 'OnClick', 'CellClipboardButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1378;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 61;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'User List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1349;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uUserList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uUserList.WebWaitMessage.Picture.gif');
    MyDBAdapter.SetParentComponent(Self);
    MyDBAdapter.Name := 'MyDBAdapter';
    MyDBAdapter.Left := 349;
    MyDBAdapter.Top := 489;
    MyDBAdapter.Width := 26;
    MyDBAdapter.Height := 26;
    MyDBAdapter.Margins.Left := 12;
    MyDBAdapter.Visible := True;
    MyDBAdapter.Grid := MyGrid;
    MyDBAdapter.DataSource := MasterSource;
    MyDBAdapter.Columns.Clear;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'pkey';
      Header := ' PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'active';
      Header := '  Active';
      CheckBoxField := True;
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'username';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'manager';
      Header := '  Managers';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'usertype';
      Header := '  Type';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'expires';
      Header := '  Expires';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'lastlogin';
      Header := '  Last login';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'authorization';
      Header := '  Authorization';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'driver';
      Header := 'Driver';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    MyDBAdapter.AutoCreateColumns := False;
    MyDBAdapter.AutoRemoveColumns := False;
    MasterDataset.SetParentComponent(Self);
    MasterDataset.Name := 'MasterDataset';
    MasterDataset.AfterOpen := MasterDatasetAfterOpen;
    MasterDataset.Left := 412;
    MasterDataset.Top := 488;
    MasterDatasetpkey.SetParentComponent(MasterDataset);
    MasterDatasetpkey.Name := 'MasterDatasetpkey';
    MasterDatasetpkey.FieldName := 'pkey';
    MasterDatasetpkey.Size := 256;
    MasterDatasetactive.SetParentComponent(MasterDataset);
    MasterDatasetactive.Name := 'MasterDatasetactive';
    MasterDatasetactive.FieldName := 'active';
    MasterDatasetactive.Size := 256;
    MasterDatasetusername.SetParentComponent(MasterDataset);
    MasterDatasetusername.Name := 'MasterDatasetusername';
    MasterDatasetusername.FieldName := 'username';
    MasterDatasetusername.Size := 256;
    MasterDatasetmanager.SetParentComponent(MasterDataset);
    MasterDatasetmanager.Name := 'MasterDatasetmanager';
    MasterDatasetmanager.FieldName := 'manager';
    MasterDatasetmanager.Size := 256;
    MasterDatasetusertype.SetParentComponent(MasterDataset);
    MasterDatasetusertype.Name := 'MasterDatasetusertype';
    MasterDatasetusertype.FieldName := 'usertype';
    MasterDatasetusertype.Size := 256;
    MasterDatasetexpires.SetParentComponent(MasterDataset);
    MasterDatasetexpires.Name := 'MasterDatasetexpires';
    MasterDatasetexpires.FieldName := 'expires';
    MasterDatasetexpires.Size := 256;
    MasterDatasetlastlogin.SetParentComponent(MasterDataset);
    MasterDatasetlastlogin.Name := 'MasterDatasetlastlogin';
    MasterDatasetlastlogin.FieldName := 'lastlogin';
    MasterDatasetlastlogin.Size := 256;
    MasterDatasetdriver.SetParentComponent(MasterDataset);
    MasterDatasetdriver.Name := 'MasterDatasetdriver';
    MasterDatasetdriver.FieldName := 'driver';
    MasterDatasetauthorization.SetParentComponent(MasterDataset);
    MasterDatasetauthorization.Name := 'MasterDatasetauthorization';
    MasterDatasetauthorization.FieldName := 'authorization';
    MasterDatasetauthorization.Size := 256;
    MasterSource.SetParentComponent(Self);
    MasterSource.Name := 'MasterSource';
    MasterSource.DataSet := MasterDataset;
    MasterSource.Left := 492;
    MasterSource.Top := 488;
    MasterTimer.SetParentComponent(Self);
    MasterTimer.Name := 'MasterTimer';
    MasterTimer.Enabled := False;
    MasterTimer.Interval := 100;
    SetEvent(MasterTimer, Self, 'OnTimer', 'MasterTimerTimer');
    MasterTimer.Left := 283;
    MasterTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Delimiter := ';';
    WebClientConnection1.URI := 'https://instatrack.eu:44321/php/default_user_list.php?sessionid=fc897704f2c858a09437bd666393adb7';
    WebClientConnection1.Left := 191;
    WebClientConnection1.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    SearchEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    ViewButton.AfterLoadDFMValues;
    ManagerButton.AfterLoadDFMValues;
    GridClipboardButton.AfterLoadDFMValues;
    CellClipboardButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyDBAdapter.AfterLoadDFMValues;
    MasterDataset.AfterLoadDFMValues;
    MasterDatasetpkey.AfterLoadDFMValues;
    MasterDatasetactive.AfterLoadDFMValues;
    MasterDatasetusername.AfterLoadDFMValues;
    MasterDatasetmanager.AfterLoadDFMValues;
    MasterDatasetusertype.AfterLoadDFMValues;
    MasterDatasetexpires.AfterLoadDFMValues;
    MasterDatasetlastlogin.AfterLoadDFMValues;
    MasterDatasetdriver.AfterLoadDFMValues;
    MasterDatasetauthorization.AfterLoadDFMValues;
    MasterSource.AfterLoadDFMValues;
    MasterTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
  end;
end;

end.

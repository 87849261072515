unit uHistoryMapPrint;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.ExtCtrls,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  THistoryMapPrintForm = class(TForm)
    BackgroundPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    WebBrowser: TBrowserControl;
    procedure CloseImageClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  HistoryMapPrintForm: THistoryMapPrintForm;

implementation

{$R *.dfm}

procedure THistoryMapPrintForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure THistoryMapPrintForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BackgroundPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  WebBrowser := TBrowserControl.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);

  BackgroundPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  WebBrowser.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  try
    Name := 'HistoryMapPrintForm';
    Width := 1163;
    Height := 768;
    BackgroundPanel.SetParentComponent(Self);
    BackgroundPanel.Name := 'BackgroundPanel';
    BackgroundPanel.Left := 0;
    BackgroundPanel.Top := 0;
    BackgroundPanel.Width := 1163;
    BackgroundPanel.Height := 768;
    BackgroundPanel.HeightPercent := 100.000000000000000000;
    BackgroundPanel.WidthPercent := 100.000000000000000000;
    BackgroundPanel.Align := alClient;
    BackgroundPanel.BorderColor := -1;
    BackgroundPanel.BorderStyle := bsNone;
    BackgroundPanel.Color := 14062646;
    ContentPanel.SetParentComponent(BackgroundPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1157;
    ContentPanel.Height := 725;
    ContentPanel.Margins.Top := 0;
    ContentPanel.HeightPercent := 100.000000000000000000;
    ContentPanel.WidthPercent := 100.000000000000000000;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    WebBrowser.SetParentComponent(ContentPanel);
    WebBrowser.Name := 'WebBrowser';
    WebBrowser.Left := 0;
    WebBrowser.Top := 0;
    WebBrowser.Width := 1157;
    WebBrowser.Height := 725;
    WebBrowser.HeightPercent := 100.000000000000000000;
    WebBrowser.WidthPercent := 100.000000000000000000;
    WebBrowser.Align := alClient;
    WebBrowser.BorderStyle := bsNone;
    WebBrowser.ChildOrder := 4;
    WebBrowser.Sandbox := [];
    CaptionPanel.SetParentComponent(BackgroundPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1163;
    CaptionPanel.Height := 40;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 73;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Afdrukken';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1134;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uHistoryMapPrint.CaptionPanel.CloseImage.Picture.png');
  finally
    BackgroundPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    WebBrowser.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
  end;
end;

end.
unit uAssetGroupList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel;

type
  TAssetGroupListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    MyDBAdapter: TTMSFNCGridDatabaseAdapter;
    MyGrid: TTMSFNCGrid;
    LoadTimer: TTimer;
    ToolbarPanel: TPanel;
    SearchEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebConnection: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    SelectButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    [async]
    GridClipboardButton: TSpeedButton;
    MyDatasetog_pk: TStringField;
    MyDatasetog_naam: TStringField;
    CellClipboardButton: TSpeedButton;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure LoadTimerTimer(Sender: TObject); async;
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure SearchEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    procedure SelectButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure GridClipboardButtonClick(Sender: TObject); async;
    procedure CellClipboardButtonClick(Sender: TObject);
  private
    { Private declarations }
    FilterPKey: Int64;
    Permission: Integer;
    [async]
    procedure LoadList(SearchValue: String); async;
  public
    PKeyList: String;
  protected procedure LoadDFMValues; override; end;

var
  AssetGroupListForm: TAssetGroupListForm;

implementation

{$R *.dfm}

uses
  uCommon, uUserEdit, uManagerList;

procedure TAssetGroupListForm.WebFormCreate(Sender: TObject);
begin
  FilterPKey := -1;
  SetGridStyle(MyGrid, False);
end;

procedure TAssetGroupListForm.WebFormShow(Sender: TObject);
begin
  SearchEdit.SetFocus();
  LoadList(EmptyStr);
end;

procedure TAssetGroupListForm.GridClipboardButtonClick(Sender: TObject);
begin
  GridToClipBoard(MyGrid, True);
end;

procedure TAssetGroupListForm.CellClipboardButtonClick(Sender: TObject);
begin
  CellToClipBoard(MyGrid);
end;

procedure TAssetGroupListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TAssetGroupListForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  LoadTimer.Enabled := True;
end;

procedure TAssetGroupListForm.SearchEditChange(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TAssetGroupListForm.LoadTimerTimer(Sender: TObject);
begin
  LoadTimer.Enabled := False;
  MyGrid.Invalidate;
  WebWaitMessage.Hide;
end;

procedure TAssetGroupListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_group_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
  // Open DB Adapter
  MyDBAdapter.Active := True;
  MyDBAdapter.LoadAllDataAndDisconnect;
end;

procedure TAssetGroupListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadList(SearchEdit.Text);
  end;
end;

procedure TAssetGroupListForm.SelectButtonClick(Sender: TObject);
var
  StringList: TStringList;
  I: Integer;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to MyGrid.RowSelectionCount-1 do begin
        StringList.Add(MyGrid.Cells[0, MyGrid.SelectedRow[I]]);
      end;
      PKeyList := StringList.DelimitedText;
      ModalResult := mrOK;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TAssetGroupListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  SearchEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  GridClipboardButton := TSpeedButton.Create(Self);
  CellClipboardButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetog_pk := TStringField.Create(Self);
  MyDatasetog_naam := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  LoadTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  WebConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  SearchEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  GridClipboardButton.BeforeLoadDFMValues;
  CellClipboardButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyDBAdapter.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetog_pk.BeforeLoadDFMValues;
  MyDatasetog_naam.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  LoadTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  try
    Name := 'AssetGroupListForm';
    Width := 755;
    Height := 634;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 755;
    FormPanel.Height := 634;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 749;
    ContentPanel.Height := 591;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 54;
    MyGrid.Width := 709;
    MyGrid.Height := 478;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 0;
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 7;
    MyGrid.RowCount := 1;
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 1;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Editing.Enabled := False;
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    MyGrid.Adapter := MyDBAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 136.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 1;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    WebMessageDlg1.SetParentComponent(MyGrid);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 51;
    WebMessageDlg1.Top := 392;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 749;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    SearchEdit.SetParentComponent(ToolbarPanel);
    SearchEdit.Name := 'SearchEdit';
    SearchEdit.Left := 20;
    SearchEdit.Top := 10;
    SearchEdit.Width := 345;
    SearchEdit.Height := 34;
    SearchEdit.AutoFocus := True;
    SearchEdit.ChildOrder := 1;
    SearchEdit.Font.Charset := ANSI_CHARSET;
    SearchEdit.Font.Color := clWindowText;
    SearchEdit.Font.Height := -16;
    SearchEdit.Font.Name := 'Segoe UI';
    SearchEdit.Font.Style := [];
    SearchEdit.HeightPercent := 100.000000000000000000;
    SearchEdit.ParentFont := False;
    SearchEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SearchEdit, Self, 'OnChange', 'SearchEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 542;
    StatusBarPanel.Width := 749;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 629;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetGroupList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uAssetGroupList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 1;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 128;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 2;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    GridClipboardButton.SetParentComponent(StatusBarPanel);
    GridClipboardButton.Name := 'GridClipboardButton';
    GridClipboardButton.AlignWithMargins := True;
    GridClipboardButton.Left := 145;
    GridClipboardButton.Top := 8;
    GridClipboardButton.Width := 128;
    GridClipboardButton.Height := 31;
    GridClipboardButton.Margins.Left := 0;
    GridClipboardButton.Margins.Top := 8;
    GridClipboardButton.Margins.Right := 8;
    GridClipboardButton.Margins.Bottom := 10;
    GridClipboardButton.Align := alLeft;
    GridClipboardButton.Caption := 'Copy Rows';
    GridClipboardButton.Color := clNone;
    GridClipboardButton.ElementClassName := 'MyWebButton';
    GridClipboardButton.Glyph.LoadFromFile('uAssetGroupList.StatusBarPanel.GridClipboardButton.Glyph.png');
    GridClipboardButton.HeightPercent := 100.000000000000000000;
    GridClipboardButton.TabOrder := 3;
    GridClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(GridClipboardButton, Self, 'OnClick', 'GridClipboardButtonClick');
    CellClipboardButton.SetParentComponent(StatusBarPanel);
    CellClipboardButton.Name := 'CellClipboardButton';
    CellClipboardButton.AlignWithMargins := True;
    CellClipboardButton.Left := 281;
    CellClipboardButton.Top := 8;
    CellClipboardButton.Width := 128;
    CellClipboardButton.Height := 31;
    CellClipboardButton.Margins.Left := 0;
    CellClipboardButton.Margins.Top := 8;
    CellClipboardButton.Margins.Right := 8;
    CellClipboardButton.Margins.Bottom := 10;
    CellClipboardButton.Align := alLeft;
    CellClipboardButton.Caption := 'Copy Cell';
    CellClipboardButton.Color := clNone;
    CellClipboardButton.ElementClassName := 'MyWebButton';
    CellClipboardButton.Glyph.LoadFromFile('uAssetGroupList.StatusBarPanel.CellClipboardButton.Glyph.png');
    CellClipboardButton.HeightPercent := 100.000000000000000000;
    CellClipboardButton.TabOrder := 4;
    CellClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CellClipboardButton, Self, 'OnClick', 'CellClipboardButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 755;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 116;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Group List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 726;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetGroupList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAssetGroupList.WebWaitMessage.Picture.gif');
    MyDBAdapter.SetParentComponent(Self);
    MyDBAdapter.Name := 'MyDBAdapter';
    MyDBAdapter.Left := 349;
    MyDBAdapter.Top := 489;
    MyDBAdapter.Width := 26;
    MyDBAdapter.Height := 26;
    MyDBAdapter.Margins.Left := 12;
    MyDBAdapter.Visible := True;
    MyDBAdapter.Grid := MyGrid;
    MyDBAdapter.DataSource := MyDataSource;
    MyDBAdapter.Columns.Clear;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'og_pk';
      Header := ' PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'og_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    MyDBAdapter.AutoCreateColumns := False;
    MyDBAdapter.AutoRemoveColumns := False;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.Connection := WebConnection;
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 488;
    MyDatasetog_pk.SetParentComponent(MyDataset);
    MyDatasetog_pk.Name := 'MyDatasetog_pk';
    MyDatasetog_pk.FieldName := 'og_pk';
    MyDatasetog_pk.Size := 256;
    MyDatasetog_naam.SetParentComponent(MyDataset);
    MyDatasetog_naam.Name := 'MyDatasetog_naam';
    MyDatasetog_naam.FieldName := 'og_naam';
    MyDatasetog_naam.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 492;
    MyDataSource.Top := 488;
    LoadTimer.SetParentComponent(Self);
    LoadTimer.Name := 'LoadTimer';
    LoadTimer.Enabled := False;
    LoadTimer.Interval := 100;
    SetEvent(LoadTimer, Self, 'OnTimer', 'LoadTimerTimer');
    LoadTimer.Left := 283;
    LoadTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.URI := 'https://instatrack.eu:44321/php/default_asset_group_list.php?sessionid=f70c9c1ef62322b37a24584343c69495';
    WebConnection.Left := 191;
    WebConnection.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    SearchEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    GridClipboardButton.AfterLoadDFMValues;
    CellClipboardButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyDBAdapter.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetog_pk.AfterLoadDFMValues;
    MyDatasetog_naam.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    LoadTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
  end;
end;

end.

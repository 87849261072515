unit uUserEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLIB.REST, WEBLIB.JSON,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.RegularExpressions,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.Buttons, VCL.TMSFNCPanel,
  VCL.TMSFNCPageControl, VCL.TMSFNCCustomControl, VCL.TMSFNCTabSet,
  Vcl.Imaging.GIFImg, VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.CDS, Data.DB, WEBLib.DB,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCGrid,
  VCL.TMSFNCListBox, VCL.TMSFNCCheckedListBox, VCL.TMSFNCPopup;

type
  TUserEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    ListButtonAllPanel: TTMSFNCPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    PageControl: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    NameLabel: TLabel;
    UserNameLabel: TLabel;
    PasswordLabel: TLabel;
    EmailLabel: TLabel;
    ExpiresLabel: TLabel;
    UserTypeLabel: TLabel;
    ManagerLabel: TLabel;
    SecurityGroupLabel: TLabel;
    WebLabel1: TLabel;
    POILabel: TLabel;
    WebLabel2: TLabel;
    NameEdit: TEdit;
    UserNameEdit: TEdit;
    PasswordEdit: TEdit;
    EmailEdit: TEdit;
    UnlimitedEdit: TCheckBox;
    DateTillEdit: TDateTimePicker;
    UserTypeEdit: TLookupComboBox;
    SecurityGroupEdit: TLookupComboBox;
    Type2CheckBox: TCheckBox;
    Type1CheckBox: TCheckBox;
    Type3CheckBox: TCheckBox;
    Type4CheckBox: TCheckBox;
    MobileEdit: TEdit;
    Type5CheckBox: TCheckBox;
    POIEdit: TEdit;
    POIClearButton: TSpeedButton;
    FuelCardEdit: TEdit;
    MyGrid: TTMSFNCGrid;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    WebConnection: TClientConnection;
    MyDBAdapter: TTMSFNCGridDatabaseAdapter;
    AssetGroupButtonPanel: TPanel;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    TMSFNCPanel2: TTMSFNCPanel;
    MyDatasetog_pk: TStringField;
    MyDatasetog_naam: TStringField;
    MyMessageDlg: TMessageDlg;
    [async]
    TeamFilterButtonPanel: TPanel;
    TeamFilterAddButton: TSpeedButton;
    TeamFilterDeleteButton: TSpeedButton;
    TMSFNCPanel1: TTMSFNCPanel;
    TeamFilterDBAdapter: TTMSFNCGridDatabaseAdapter;
    TeamFilterDataSet: TClientDataSet;
    TeamFilterDataSource: TDataSource;
    TeamFilterGrid: TTMSFNCGrid;
    TeamFilterDataSetpkey: TStringField;
    TeamFilterDataSetname: TStringField;
    TeamFilterDataSetdefault: TStringField;
    TeamFilterEditButton: TSpeedButton;
    LoadTimer: TTimer;
    ManagerListBox: TTMSFNCCheckedListBox;
    ManagerEdit: TEdit;
    ManagerClearButton: TSpeedButton;
    ManagerPopup: TTMSFNCPopup;
    CreateTeamFilterButton: TSpeedButton;
    TMSFNCPanel3: TTMSFNCPanel;
    AssetGroupAllEdit: TCheckBox;
    PageControlPage3: TTMSFNCPageControlContainer;
    TypeFilterDBAdapter: TTMSFNCGridDatabaseAdapter;
    TypeFilterDataset: TClientDataSet;
    StringField1: TStringField;
    StringField2: TStringField;
    StringField3: TStringField;
    TypeFilterDataSource: TDataSource;
    TypeFilterGrid: TTMSFNCGrid;
    TypeFilterButtonPanel: TPanel;
    TypeFilterAddButton: TSpeedButton;
    TypeFilterDeleteButton: TSpeedButton;
    TMSFNCPanel4: TTMSFNCPanel;
    TypeFilterEditButton: TSpeedButton;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    procedure UserTypeEditChange(Sender: TObject);
    procedure UnlimitedEditClick(Sender: TObject);
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    [async]
    procedure POILabelClick(Sender: TObject); async;
    procedure POIClearButtonClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    procedure PageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure PageControlBeforeChangePage(Sender: TObject; ACurrentPageIndex,
      ANewPageIndex: Integer; var ACanChange: Boolean);
    procedure RecordChange(Sender: TObject);
    procedure CheckBoxClick(Sender: TObject);
    [async]
    procedure TeamFilterAddButtonClick(Sender: TObject); async;
    [async]
    procedure TeamFilterEditButtonClick(Sender: TObject); async;
    procedure LoadTimerTimer(Sender: TObject);
    procedure ManagerClearButtonClick(Sender: TObject);
    procedure ManagerListBoxItemCheckChanged(Sender: TObject;
      AItem: TTMSFNCCheckedListBoxItem);
    procedure ManagerEditClick(Sender: TObject);
    procedure ManagerPopupPopup(Sender: TObject);
    procedure ManagerPopupClosePopup(Sender: TObject);
    [async]
    procedure CreateTeamFilterButtonClick(Sender: TObject); async;
    [async]
    procedure TeamFilterDeleteButtonClick(Sender: TObject); async;
    [async]
    procedure TypeFilterAddButtonClick(Sender: TObject); async;
    [async]
    procedure TypeFilterEditButtonClick(Sender: TObject); async;
    [async]
    procedure TypeFilterDeleteButtonClick(Sender: TObject); async;
  private
    ManagerList: String;
    { Private declarations }
    [async]
    procedure LoadManagerList(); async;
    [async]
    procedure LoadAuthorizationList(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    procedure LoadData(); async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
    [async]
    procedure LoadAssetGroupList(); async;
    [async]
    procedure LoadGroupFilterList(); async;
    [async]
    procedure LoadTypeFilterList(); async;
    [async]
    procedure AddAssetGroup(PKeyList: String); async;
    [async]
    procedure DeleteAssetGroup(PKeyList: String); async;
    [async]
    procedure ShowTeamFilterEditDialog(FilterPKey: Integer; FilterId: String; Default: Boolean); async;
    [async]
    procedure ShowTypeFilterEditDialog(FilterPKey: Integer; FilterId: String; Default: Boolean); async;
  public
    { Public declarations }
    IsSet: Boolean;
    PKey: Integer;
    RecordChanged: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  UserEditForm: TUserEditForm;

implementation

{$R *.dfm}

uses uCommon, uPOIList, uAssetGroupList, uUserTeamFilterEdit,
  uUserTypeFilterEdit;

procedure TUserEditForm.WebFormCreate(Sender: TObject);
begin
  with UserTypeEdit.LookupValues do begin
    Clear;
    AddPair('0', 'User');
    if StrToInt(SessionType) = 2 then begin
      AddPair('1', 'Manager');
      AddPair('2', 'Administrator');
    end;
  end;
  if StrToInt(SessionType) <> 2 then begin
    PasswordEdit.PasswordChar := '*';
  end;
  UserTypeEdit.Value := '-1';
  SetGridStyle(MyGrid, False);
  SetGridStyle(TeamFilterGrid, False);
  SetGridStyle(TypeFilterGrid, False);
end;

procedure TUserEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Enabled := False;
end;

procedure TUserEditForm.PageControlBeforeChangePage(Sender: TObject;
  ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
begin
  case ANewPageIndex of
    1: begin
      ACanChange := PKey >= 0;
    end;
  end;
  if not(ACanChange) then begin
    MessageDlg('You need to save the user before you can add asset groups.', TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TUserEditForm.PageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  case ACurrentPageIndex of
    1: LoadAssetGroupList();
    2: LoadGroupFilterList();
    3: LoadTypeFilterList();
  end;
end;

procedure TUserEditForm.POIClearButtonClick(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  POIEdit.Tag := 0;
  POIEdit.Text := EmptyStr;
end;

procedure TUserEditForm.POILabelClick(Sender: TObject);
var
  MyWebForm: TPOIListForm;
begin
  if SaveButton.Visible then begin
    MyWebForm := TPOIListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TPOIListForm, MyWebForm.Load());
    try
      MyWebForm.SelectButton.Visible := True;
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        RecordChange(TObject(Sender));
        // Set POI
        POIEdit.Tag := MyWebForm.PKey;
        POIEdit.Text := MyWebForm.Text;
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TUserEditForm.AddButtonClick(Sender: TObject);
var
  MyWebForm: TAssetGroupListForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TAssetGroupListForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  // Load Form
  await(TAssetGroupListForm, MyWebForm.Load());
  try
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      // Add Alerts
      AddAssetGroup(MyWebForm.PKeyList);
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TUserEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TUserEditForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  StringList: TStringList;
  I: Integer;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to MyGrid.RowSelectionCount-1 do begin
        StringList.Add(MyGrid.Cells[0, MyGrid.SelectedRow[I]]);
      end;
      MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selected records?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        DeleteAssetGroup(StringList.DelimitedText);
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TUserEditForm.TeamFilterAddButtonClick(Sender: TObject);
begin
  await(ShowTeamFilterEditDialog(-1, EmptyStr, False));
end;

procedure TUserEditForm.CreateTeamFilterButtonClick(Sender: TObject);
var
  StringList: TStringList;
  I: Integer;
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to MyGrid.RowSelectionCount-1 do begin
        StringList.Add(MyGrid.Cells[0, MyGrid.SelectedRow[I]]);
      end;
      MyString :=
        '{' +
          '"sessionid": "' + SessionID + '",' +
          '"pkey": "' + IntToStr(PKey) + '",' +
          '"list": "' + StringList.DelimitedText + '"' +
        '}';
      // Save Changes
      MyWebRequest := THTTPRequest.Create(Self);
      try
        // Request
        MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
        MyWebRequest.URL := url_php + 'default_user_team_filter_create.php';
        MyWebRequest.Command := THTTPCommand.httpPOST;
        MyWebRequest.PostData := MyString;
        MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        // Response
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') <> 'OK' then begin
            MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
          end;
        finally
          MyJSON.Free;
        end
      finally
        MyWebRequest.Free;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TUserEditForm.TeamFilterDeleteButtonClick(Sender: TObject);
var
  I: Integer;
  StringList: TStringList;
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  StatusCode: String;
begin
  if TeamFilterDataSet.Active and not(TeamFilterDataSet.IsEmpty) then begin
    StringList := TStringList.Create();
    try
      for I := 0 to TeamFilterGrid.RowSelectionCount-1 do begin
        StringList.Add(TeamFilterGrid.Cells[0, TeamFilterGrid.SelectedRow[I]]);
      end;
      MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selection?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        MyWebRequest := THTTPRequest.Create(Self);
        try
          // Request
          MyWebRequest.URL :=
            url_php + 'default_user_team_filter_delete.php?sessionid=' + SessionId +
            '&pkey=' + IntToStr(PKey) +
            '&list=' + StringList.DelimitedText;
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          // Response
          MyJSON := TJSON.Create;
          try
            MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
            StatusCode := MyJSONObject.GetJSONValue('status_code');
            if StatusCode = 'OK' then begin
              LoadGroupFilterList();
            end else begin
              MessageDlg(MyJSONObject.GetJSONValue('status_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
            end;
          finally
            MyJSON.Free;
          end
        finally
          MyWebRequest.Free;
        end;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TUserEditForm.TeamFilterEditButtonClick(Sender: TObject);
var
  FilterPKey: Integer;
begin
  if TeamFilterGrid.RowSelectionCount = 1 then begin
    FilterPKey := StrToInt(TeamFilterGrid.Cells[0, TeamFilterGrid.SelectedRow[0]]);
    if TeamFilterDataset.Locate('pkey', FilterPKey, []) then begin
      await(ShowTeamFilterEditDialog(
        TeamFilterDataset.FieldByName('pkey').AsInteger,
        Trim(TeamFilterDataset.FieldByName('name').AsString),
        TeamFilterDataset.FieldByName('default').AsString = '1'
      ));
    end;
  end;
end;

procedure TUserEditForm.TypeFilterAddButtonClick(Sender: TObject);
begin
  await(ShowTypeFilterEditDialog(-1, EmptyStr, False));
end;

procedure TUserEditForm.TypeFilterDeleteButtonClick(Sender: TObject);
var
  I: Integer;
  StringList: TStringList;
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  StatusCode: String;
begin
  if TypeFilterDataSet.Active and not(TypeFilterDataSet.IsEmpty) then begin
    StringList := TStringList.Create();
    try
      for I := 0 to TypeFilterGrid.RowSelectionCount-1 do begin
        StringList.Add(TypeFilterGrid.Cells[0, TypeFilterGrid.SelectedRow[I]]);
      end;
      MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selection?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        MyWebRequest := THTTPRequest.Create(Self);
        try
          // Request
          MyWebRequest.URL :=
            url_php + 'default_user_type_filter_delete.php?sessionid=' + SessionId +
            '&pkey=' + IntToStr(PKey) +
            '&list=' + StringList.DelimitedText;
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          // Response
          MyJSON := TJSON.Create;
          try
            MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
            StatusCode := MyJSONObject.GetJSONValue('status_code');
            if StatusCode = 'OK' then begin
              LoadTypeFilterList();
            end else begin
              MessageDlg(MyJSONObject.GetJSONValue('status_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
            end;
          finally
            MyJSON.Free;
          end
        finally
          MyWebRequest.Free;
        end;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TUserEditForm.TypeFilterEditButtonClick(Sender: TObject);
var
  FilterPKey: Integer;
begin
  if TypeFilterGrid.RowSelectionCount = 1 then begin
    FilterPKey := StrToInt(TypeFilterGrid.Cells[0, TypeFilterGrid.SelectedRow[0]]);
    if TypeFilterDataset.Locate('pkey', FilterPKey, []) then begin
      await(ShowTypeFilterEditDialog(
        TypeFilterDataset.FieldByName('pkey').AsInteger,
        Trim(TypeFilterDataset.FieldByName('name').AsString),
        TypeFilterDataset.FieldByName('default').AsString = '1'
      ));
    end;
  end;

end;

procedure TUserEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TUserEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      RecordChanged := False;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TUserEditForm.ShowTimerTimer(Sender: TObject);
begin
  if IsSet then begin
    ShowTimer.Enabled := False;
    // Set Focus
    if PKey = -1 then begin
      NameEdit.SetFocus;
      Type5CheckBox.Enabled := True;
    end;
    // Load Manager
    await(LoadManagerList());
    // Load Data
    if PKey >= 0 then begin
      LoadData();
      RecordChanged := False;
    end;
    WebWaitMessage.Hide;
  end;
end;

procedure TUserEditForm.CheckBoxClick(Sender: TObject);
begin
  RecordChange(TObject(Sender));
end;

procedure TUserEditForm.UnlimitedEditClick(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  if TCheckBox(Sender).Checked then begin
    DateTillEdit.Enabled := False;
    DateTillEdit.ReadOnly := True;
  end else begin
    DateTillEdit.Enabled := True;
    DateTillEdit.ReadOnly := False;
  end;
end;

procedure TUserEditForm.UserTypeEditChange(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  if StrToInt(UserTypeEdit.Value) = 0 then begin
    ManagerEdit.Enabled := True;
  end else begin
    ManagerEdit.Enabled := False;
    ManagerEdit.Text := EmptyStr;
  end;
  LoadAuthorizationList();
end;

procedure TUserEditForm.LoadManagerList();
var
  StringList: TStringList;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  Item: TTMSFNCCheckedListBoxItem;
  I: Integer;
begin
  StringList := TStringList.Create;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_manager_list.php?' +
      'sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Success
    if MyRequest.Status = 200 then begin
      ManagerListBox.BeginUpdate;
      try
        ManagerListBox.Items.Clear;
        // Parse JSON
        MyJSON := TJSON.Create;
        try
          MyJSONArray := TJSONArray(MyJSON.Parse(String(MyRequest.response)));
          for i := 0 to MyJSONArray.Count-1 do begin
            MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
            Item := TTMSFNCCheckedListBoxItem(ManagerListBox.Items.Add);
            Item.Tag := StrToInt(MyJSONObject.GetJSONValue('pkey'));
            Item.Text := MyJSONObject.GetJSONValue('name');
            if PKey = -1 then begin
              Item.Checked := MyJSONObject.GetJSONValue('default') = 't';
              if Item.Checked then StringList.Add(Item.Text);
            end;
          end;
          ManagerEdit.Text := StringList.DelimitedText;
        finally
          MyJSON.Free;
        end;
      finally
        ManagerListBox.EndUpdate;
      end;
    end;
  finally
    MyWebRequest.Free;
    StringList.Free;
  end;
end;

procedure TUserEditForm.LoadTimerTimer(Sender: TObject);
begin
  LoadTimer.Enabled := False;
  TeamFilterGrid.Invalidate();
  TypeFilterGrid.Invalidate();
end;

procedure TUserEditForm.ManagerClearButtonClick(Sender: TObject);
var
  I: Integer;
begin
  if ManagerEdit.Text <> EmptyStr then begin
    ManagerEdit.Text := EmptyStr;
    for I := 0 to ManagerListBox.Items.Count-1 do begin
      ManagerListBox.Items[I].Checked := False;
    end;
    RecordChanged := True;
  end;
end;

procedure TUserEditForm.ManagerEditClick(Sender: TObject);
begin
  ManagerListBox.BeginUpdate;
  ManagerPopup.Popup;
  ManagerListBox.EndUpdate;
end;

procedure TUserEditForm.ManagerListBoxItemCheckChanged(Sender: TObject;
  AItem: TTMSFNCCheckedListBoxItem);
var
  I: Integer;
  StringList: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  StringList := TStringList.Create();
  try
    for I := 0 to ManagerListBox.Items.Count-1 do begin
      Item := TTMSFNCCheckedListBoxItem(ManagerListBox.Items[I]);
      if Item.Checked then StringList.Add(Item.Text);
    end;
    ManagerEdit.Text := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
end;

procedure TUserEditForm.ManagerPopupClosePopup(Sender: TObject);
begin
  //
end;

procedure TUserEditForm.ManagerPopupPopup(Sender: TObject);
begin
  ManagerPopup.DropDownHeight := ManagerListBox.Height;
  ManagerPopup.DropDownWidth := ManagerListBox.Width;
end;

procedure TUserEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TUserEditForm.LoadAuthorizationList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  MyData: String;
  I: Integer;
begin
  SecurityGroupEdit.LookupValues.Clear;
  if StrToInt(UserTypeEdit.Value) < 2 then begin
    SecurityGroupEdit.Enabled := True;
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_security_group_list.php?' +
        'sessionid=' + SessionId;
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Success
      if MyRequest.Status = 200 then begin
        // Get Values
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            MyData := TJSONPair(MyJSONObject.Get('result')).JsonValue.ToString;
            MyJSONArray := TJSONArray(MyJSON.Parse(MyData));
            for I := 0 to MyJSONArray.Count-1 do begin
              MyJSONObject := TJSONObject(MyJSONArray.Items[I]);
              if (StrToInt(MyJSONObject.GetJSONValue('type')) = StrToInt(UserTypeEdit.Value)+1) then begin
                SecurityGroupEdit.LookupValues.AddPair(
                  MyJSONObject.GetJSONValue('pkey'),
                  MyJSONObject.GetJSONValue('name')
                );
              end;
            end;
          end else begin
            //
          end;
          SecurityGroupEdit.Value := '-1';
        finally
          MyJSON.Free;
        end;
      end;
    finally
      MyWebRequest.Free;
    end;
  end else begin
    SecurityGroupEdit.Value := '-1';
    SecurityGroupEdit.Enabled := False;
  end;
end;

procedure TUserEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  I: Integer;
  StringListPKey, StringListName: TStringList;
  Item: TTMSFNCCheckedListBoxItem;
begin
  if PKey >= 0 then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_user_load.php?' +
        'sessionid=' + SessionId + '&pkey=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Success
      if MyRequest.Status = 200 then begin
        // Get Values
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            NameEdit.Text := MyJSONObject.GetJSONValue('name');
            UsernameEdit.Text := MyJSONObject.GetJSONValue('username');
            PasswordEdit.Text := MyJSONObject.GetJSONValue('password');
            EmailEdit.Text := MyJSONObject.GetJSONValue('email');
            MobileEdit.Text := MyJSONObject.GetJSONValue('mobile');
            FuelCardEdit.Text := MyJSONObject.GetJSONValue('fuelcard');
            POIEdit.Tag := StrToInt(MyJSONObject.GetJSONValue('poi_pkey'));
            POIEdit.Text := MyJSONObject.GetJSONValue('poi_name');
            UnlimitedEdit.Checked := MyJSONObject.GetJSONValue('unlimited') = '1';
            if not(UnlimitedEdit.Checked) then begin
              DateTillEdit.Date := StrToDate(MyJSONObject.GetJSONValue('datetill'));
            end;
            DateTillEdit.Enabled := not(UnlimitedEdit.Checked);
            DateTillEdit.ReadOnly := UnlimitedEdit.Checked;
            UserTypeEdit.Value := MyJSONObject.GetJSONValue('usertype');
            Type1CheckBox.Checked := MyJSONObject.GetJSONValue('type_1') = '1';
            Type2CheckBox.Checked := MyJSONObject.GetJSONValue('type_2') = '1';
            Type3CheckBox.Checked := MyJSONObject.GetJSONValue('type_3') = '1';
            Type4CheckBox.Checked := MyJSONObject.GetJSONValue('type_4') = '1';
            Type5CheckBox.Checked := MyJSONObject.GetJSONValue('type_5') = '1';
            Type5CheckBox.Enabled := not(Type5CheckBox.Checked);
            // Load Authorization List
            await(LoadAuthorizationList());
            // Manager
            StringListPKey := TStringList.Create();
            StringListName := TStringList.Create();
            try
              StringListPKey.DelimitedText := MyJSONObject.GetJSONValue('manager');
              for I := 0 to ManagerListBox.Items.Count-1 do begin
                Item := TTMSFNCCheckedListBoxItem(ManagerListBox.Items[I]);
                if StringListPKey.IndexOf(IntToStr(Item.Tag)) >= 0 then begin
                  Item.Checked := True;
                  StringListName.Add(Item.Text);
                end;
              end;
              ManagerEdit.Text := StringListName.DelimitedText;
            finally
              StringListPKey.Free;
              StringListName.Free;
            end;
//          ManagerEdit.Value := MyJSONObject.GetJSONValue('manager');
            SecurityGroupEdit.Value := MyJSONObject.GetJSONValue('securitygroup');
            AssetGroupAllEdit.Checked := MyJSONObject.GetJSONValue('assetgroup_all') = '1';
          end else begin
            MessageDlg(MyJSONObject.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
          end;
        finally
          MyJSON.Free;
        end;
      end;
    finally
      MyWebRequest.Free;
    end;
  end;
end;

function TUserEditForm.ValidInput(var ErrorMessage: String): Boolean;
var
  StringList: TStringList;
  I: Integer;
  MyUserType, MySecurityGroup: Integer;
  Item: TTMSFNCCheckedListBoxItem;
begin
  Result := True;
  // Name
  if Result and (Trim(NameEdit.Text) = EmptyStr) then begin
    Result := False;
    ErrorMessage := 'Displayname is a required field!';
  end;
  // Username
  if Result and (Trim(UserNameEdit.Text) = EmptyStr) then begin
    Result := False;
    ErrorMessage := 'Username is a required field!';
  end;
  // Password
  if Result and (Trim(PasswordEdit.Text) = EmptyStr) then begin
    Result := False;
    ErrorMessage := 'Password is a required field!';
  end;
  // Email
  if Result and (Trim(EmailEdit.Text) <> EmptyStr) then begin
    if not(TRegEx.IsMatch(Trim(EmailEdit.Text), RegExEmail)) then begin
      Result := False;
      ErrorMessage := 'Email is not valid!';
    end;
  end;
  // User Type
  if Result then begin
    if TryStrToInt(UserTypeEdit.Value, MyUserType) then begin
      // Manager
      if Result and (MyUserType = 0) then begin
        if ManagerEdit.Text <> EmptyStr then begin
          StringList := TStringList.Create();
          try
            for I := 0 to ManagerListBox.Items.Count-1 do begin
              Item := TTMSFNCCheckedListBoxItem(ManagerListBox.Items[I]);
              if Item.Checked then StringList.Add(IntToStr(Item.Tag));
            end;
            ManagerList := StringList.DelimitedText;
          finally
            StringList.Free;
          end;
        end else begin
          Result := False;
          ErrorMessage := 'Manager is a required field for user type "User"!';
        end;
      end else begin
        ManagerList := '-1';
      end;
      // Security Group
      if Result and (MyUserType < 2) then begin
        if not(TryStrToInt(SecurityGroupEdit.Value, MySecurityGroup)) then begin
          Result := False;
          ErrorMessage := 'Authorization is a required field for user type "User" or "Manager"!';
        end;
      end;
    end else begin
      Result := False;
      ErrorMessage := 'User type is a required field!';
    end;
  end;
end;

procedure TUserEditForm.AddAssetGroup(PKeyList: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_user_asset_group_add.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + PKeyList;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          LoadAssetGroupList();
        end else begin
          MyMessage := MyJSONObject.GetJSONValue('error_message');
          MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
        end;
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TUserEditForm.LoadAssetGroupList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyData: String;
begin
//WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_asset_group_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        MyData := TJSONPair(MyJSONObject.Get('result')).JsonValue.ToString;
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          MyDataset.Close;
          MyDataset.Rows := TJSArray(TJSJSON.parseObject(MyData));
          await(MyDataset.Open);
          // Open DB Adapter
          MyDBAdapter.Active := True;
          MyDBAdapter.LoadAllDataAndDisconnect;
        end else begin
          MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
        end;
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TUserEditForm.LoadGroupFilterList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyData: String;
begin
//WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_team_filter_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        MyData := TJSONPair(MyJSONObject.Get('result')).JsonValue.ToString;
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          TeamFilterDataset.Close;
          TeamFilterDataset.Rows := TJSArray(TJSJSON.parseObject(MyData));
          await(TeamFilterDataset.Open);
          // Open DB Adapter
          TeamFilterDBAdapter.Active := True;
          TeamFilterDBAdapter.LoadAllDataAndDisconnect;
          LoadTimer.Enabled := True;
        end else begin
          MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
        end;
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
  TeamFilterGrid.Invalidate;
end;

procedure TUserEditForm.LoadTypeFilterList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyData: String;
begin
//WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_type_filter_list.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        MyData := TJSONPair(MyJSONObject.Get('result')).JsonValue.ToString;
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          TypeFilterDataset.Close;
          TypeFilterDataset.Rows := TJSArray(TJSJSON.parseObject(MyData));
          await(TypeFilterDataset.Open);
          // Open DB Adapter
          TypeFilterDBAdapter.Active := True;
          TypeFilterDBAdapter.LoadAllDataAndDisconnect;
          LoadTimer.Enabled := True;
        end else begin
          MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
        end;
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
  TypeFilterGrid.Invalidate;
end;

procedure TUserEditForm.DeleteAssetGroup(PKeyList: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_user_asset_group_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey) +
      '&list=' + PKeyList;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        LoadAssetGroupList();
      end else begin
        MyMessage := MyJSONObject.GetJSONValue('error_message');
        MessageDlg(MyMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

function TUserEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := True;
  MyString :=
    '{' +
      '"sessionid":"' + SessionId + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"name": "' + Trim(NameEdit.Text) + '",' +
      '"username": "' + Trim(UsernameEdit.Text) + '",' +
      '"password": "' + Trim(PasswordEdit.Text) + '",' +
      '"fuelcard": "' + Trim(FuelCardEdit.Text) + '",' +
      '"email": "' + Trim(EmailEdit.Text) + '",' +
      '"mobile": "' + Trim(MobileEdit.Text) + '",' +
      '"poi": "' + IntToStr(POIEdit.Tag) + '",' +
      '"type_1": "' + BooleanToString(Type1CheckBox.Checked) + '",' +
      '"type_2": "' + BooleanToString(Type2CheckBox.Checked) + '",' +
      '"type_3": "' + BooleanToString(Type3CheckBox.Checked) + '",' +
      '"type_4": "' + BooleanToString(Type4CheckBox.Checked) + '",' +
      '"type_5": "' + BooleanToString(Type5CheckBox.Checked) + '",' +
      '"unlimited": "' + BooleanToString(UnlimitedEdit.Checked) + '",';
  if not(UnlimitedEdit.Checked) then begin
    MyString := MyString +
      '"datetill": "' + FormatDateTime('yyyy-mm-dd', DateTillEdit.Date) + '",';
  end;
  MyString := MyString +
    '"usertype": "' + UserTypeEdit.Value + '",' +
//  '"manager": "' + ManagerEdit.Value + '",' +
    '"manager": "' + ManagerList + '",' +
    '"securitygroup": "' + SecurityGroupEdit.Value + '",' +
    '"assetgroup_all": "' + BooleanToString(AssetGroupAllEdit.Checked) + '"';
  MyString := MyString +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_user_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        Result := False;
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TUserEditForm.ShowTeamFilterEditDialog(FilterPKey: Integer; FilterId: String; Default: Boolean);
var
  MyModalResult: TModalResult;
  MyForm: TUserTeamFilterEditForm;
begin
  MyForm := TUserTeamFilterEditForm.Create(Self);
  MyForm.Popup := True;
  MyForm.Border := fbNone;
  MyForm.UserPKey := PKey;
  MyForm.TeamFilterInt := FilterPKey;
  await(TUserTeamFilterEditForm, MyForm.Load());
  try
    if PKey > 0 then begin
      MyForm.TeamFilterName := FilterId;
      MyForm.TeamFilterPublic := False;
      MyForm.TeamFilterDefault := Default;
    end;
    MyForm.TeamFilterSet := True;
    // excute form and wait for close
    MyModalResult := await(TModalResult, MyForm.Execute);
    if MyModalResult = mrOK then begin
      LoadGroupFilterList();
    end;
  finally
    MyForm.Free;
  end;
end;

procedure TUserEditForm.ShowTypeFilterEditDialog(FilterPKey: Integer; FilterId: String; Default: Boolean);
var
  MyModalResult: TModalResult;
  MyForm: TUserTypeFilterEditForm;
begin
  MyForm := TUserTypeFilterEditForm.Create(Self);
  MyForm.Popup := True;
  MyForm.Border := fbNone;
  MyForm.UserPKey := PKey;
  MyForm.TypeFilterInt := FilterPKey;
  await(TUserTeamFilterEditForm, MyForm.Load());
  try
    if PKey > 0 then begin
      MyForm.TypeFilterName := FilterId;
      MyForm.TypeFilterPublic := False;
      MyForm.TypeFilterDefault := Default;
    end;
    MyForm.TypeFilterSet := True;
    // excute form and wait for close
    MyModalResult := await(TModalResult, MyForm.Execute);
    if MyModalResult = mrOK then begin
      LoadTypeFilterList();
    end;
  finally
    MyForm.Free;
  end;
end;


procedure TUserEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ListButtonAllPanel := TTMSFNCPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  PageControl := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  AssetGroupButtonPanel := TPanel.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  CreateTeamFilterButton := TSpeedButton.Create(Self);
  TMSFNCPanel3 := TTMSFNCPanel.Create(Self);
  AssetGroupAllEdit := TCheckBox.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  TeamFilterButtonPanel := TPanel.Create(Self);
  TeamFilterAddButton := TSpeedButton.Create(Self);
  TeamFilterDeleteButton := TSpeedButton.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TeamFilterEditButton := TSpeedButton.Create(Self);
  TeamFilterGrid := TTMSFNCGrid.Create(Self);
  PageControlPage3 := TTMSFNCPageControlContainer.Create(Self);
  TypeFilterGrid := TTMSFNCGrid.Create(Self);
  TypeFilterButtonPanel := TPanel.Create(Self);
  TypeFilterAddButton := TSpeedButton.Create(Self);
  TypeFilterDeleteButton := TSpeedButton.Create(Self);
  TMSFNCPanel4 := TTMSFNCPanel.Create(Self);
  TypeFilterEditButton := TSpeedButton.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  NameLabel := TLabel.Create(Self);
  UserNameLabel := TLabel.Create(Self);
  PasswordLabel := TLabel.Create(Self);
  EmailLabel := TLabel.Create(Self);
  ExpiresLabel := TLabel.Create(Self);
  UserTypeLabel := TLabel.Create(Self);
  ManagerLabel := TLabel.Create(Self);
  SecurityGroupLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  POILabel := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  NameEdit := TEdit.Create(Self);
  UserNameEdit := TEdit.Create(Self);
  PasswordEdit := TEdit.Create(Self);
  EmailEdit := TEdit.Create(Self);
  UnlimitedEdit := TCheckBox.Create(Self);
  DateTillEdit := TDateTimePicker.Create(Self);
  UserTypeEdit := TLookupComboBox.Create(Self);
  SecurityGroupEdit := TLookupComboBox.Create(Self);
  Type2CheckBox := TCheckBox.Create(Self);
  Type1CheckBox := TCheckBox.Create(Self);
  Type3CheckBox := TCheckBox.Create(Self);
  Type4CheckBox := TCheckBox.Create(Self);
  MobileEdit := TEdit.Create(Self);
  Type5CheckBox := TCheckBox.Create(Self);
  POIEdit := TEdit.Create(Self);
  POIClearButton := TSpeedButton.Create(Self);
  FuelCardEdit := TEdit.Create(Self);
  ManagerListBox := TTMSFNCCheckedListBox.Create(Self);
  ManagerEdit := TEdit.Create(Self);
  ManagerClearButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  TeamFilterDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ManagerPopup := TTMSFNCPopup.Create(Self);
  TypeFilterDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ShowTimer := TTimer.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetog_pk := TStringField.Create(Self);
  MyDatasetog_naam := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  WebConnection := TClientConnection.Create(Self);
  TeamFilterDataSet := TClientDataSet.Create(Self);
  TeamFilterDataSetpkey := TStringField.Create(Self);
  TeamFilterDataSetname := TStringField.Create(Self);
  TeamFilterDataSetdefault := TStringField.Create(Self);
  TeamFilterDataSource := TDataSource.Create(Self);
  LoadTimer := TTimer.Create(Self);
  TypeFilterDataset := TClientDataSet.Create(Self);
  StringField1 := TStringField.Create(Self);
  StringField2 := TStringField.Create(Self);
  StringField3 := TStringField.Create(Self);
  TypeFilterDataSource := TDataSource.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ListButtonAllPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  PageControl.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  AssetGroupButtonPanel.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  CreateTeamFilterButton.BeforeLoadDFMValues;
  TMSFNCPanel3.BeforeLoadDFMValues;
  AssetGroupAllEdit.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  TeamFilterButtonPanel.BeforeLoadDFMValues;
  TeamFilterAddButton.BeforeLoadDFMValues;
  TeamFilterDeleteButton.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  TeamFilterEditButton.BeforeLoadDFMValues;
  TeamFilterGrid.BeforeLoadDFMValues;
  PageControlPage3.BeforeLoadDFMValues;
  TypeFilterGrid.BeforeLoadDFMValues;
  TypeFilterButtonPanel.BeforeLoadDFMValues;
  TypeFilterAddButton.BeforeLoadDFMValues;
  TypeFilterDeleteButton.BeforeLoadDFMValues;
  TMSFNCPanel4.BeforeLoadDFMValues;
  TypeFilterEditButton.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  NameLabel.BeforeLoadDFMValues;
  UserNameLabel.BeforeLoadDFMValues;
  PasswordLabel.BeforeLoadDFMValues;
  EmailLabel.BeforeLoadDFMValues;
  ExpiresLabel.BeforeLoadDFMValues;
  UserTypeLabel.BeforeLoadDFMValues;
  ManagerLabel.BeforeLoadDFMValues;
  SecurityGroupLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  POILabel.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  NameEdit.BeforeLoadDFMValues;
  UserNameEdit.BeforeLoadDFMValues;
  PasswordEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  UnlimitedEdit.BeforeLoadDFMValues;
  DateTillEdit.BeforeLoadDFMValues;
  UserTypeEdit.BeforeLoadDFMValues;
  SecurityGroupEdit.BeforeLoadDFMValues;
  Type2CheckBox.BeforeLoadDFMValues;
  Type1CheckBox.BeforeLoadDFMValues;
  Type3CheckBox.BeforeLoadDFMValues;
  Type4CheckBox.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  Type5CheckBox.BeforeLoadDFMValues;
  POIEdit.BeforeLoadDFMValues;
  POIClearButton.BeforeLoadDFMValues;
  FuelCardEdit.BeforeLoadDFMValues;
  ManagerListBox.BeforeLoadDFMValues;
  ManagerEdit.BeforeLoadDFMValues;
  ManagerClearButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyDBAdapter.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  TeamFilterDBAdapter.BeforeLoadDFMValues;
  ManagerPopup.BeforeLoadDFMValues;
  TypeFilterDBAdapter.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetog_pk.BeforeLoadDFMValues;
  MyDatasetog_naam.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  TeamFilterDataSet.BeforeLoadDFMValues;
  TeamFilterDataSetpkey.BeforeLoadDFMValues;
  TeamFilterDataSetname.BeforeLoadDFMValues;
  TeamFilterDataSetdefault.BeforeLoadDFMValues;
  TeamFilterDataSource.BeforeLoadDFMValues;
  LoadTimer.BeforeLoadDFMValues;
  TypeFilterDataset.BeforeLoadDFMValues;
  StringField1.BeforeLoadDFMValues;
  StringField2.BeforeLoadDFMValues;
  StringField3.BeforeLoadDFMValues;
  TypeFilterDataSource.BeforeLoadDFMValues;
  try
    Name := 'UserEditForm';
    Width := 792;
    Height := 691;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 792;
    FormPanel.Height := 691;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 786;
    ContentPanel.Height := 648;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ListButtonAllPanel.SetParentComponent(ContentPanel);
    ListButtonAllPanel.Name := 'ListButtonAllPanel';
    ListButtonAllPanel.Tag := 1;
    ListButtonAllPanel.Left := 0;
    ListButtonAllPanel.Top := 599;
    ListButtonAllPanel.Width := 786;
    ListButtonAllPanel.Height := 49;
    ListButtonAllPanel.Align := alBottom;
    ListButtonAllPanel.BevelInner := bvNone;
    ListButtonAllPanel.BevelOuter := bvNone;
    ListButtonAllPanel.Ctl3D := True;
    ListButtonAllPanel.ParentCtl3D := False;
    ListButtonAllPanel.ParentDoubleBuffered := False;
    ListButtonAllPanel.DoubleBuffered := True;
    ListButtonAllPanel.TabOrder := 0;
    ListButtonAllPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonAllPanel.SectionsAppearance.Font.Height := -11;
    ListButtonAllPanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonAllPanel.SectionsAppearance.Font.Style := [];
    ListButtonAllPanel.Stroke.Kind := gskNone;
    ListButtonAllPanel.Header.Text := 'Header';
    ListButtonAllPanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.Header.Font.Color := clWindowText;
    ListButtonAllPanel.Header.Font.Height := -11;
    ListButtonAllPanel.Header.Font.Name := 'Tahoma';
    ListButtonAllPanel.Header.Font.Style := [];
    ListButtonAllPanel.Header.Visible := False;
    ListButtonAllPanel.Footer.Text := 'Footer';
    ListButtonAllPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.Footer.Font.Color := clWindowText;
    ListButtonAllPanel.Footer.Font.Height := -11;
    ListButtonAllPanel.Footer.Font.Name := 'Tahoma';
    ListButtonAllPanel.Footer.Font.Style := [];
    ListButtonAllPanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonAllPanel.GlobalFont.Style := [];
    SaveButton.SetParentComponent(ListButtonAllPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 551;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uUserEdit.ListButtonAllPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(ListButtonAllPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 666;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uUserEdit.ListButtonAllPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    PageControl.SetParentComponent(ContentPanel);
    PageControl.Name := 'PageControl';
    PageControl.AlignWithMargins := True;
    PageControl.Left := 10;
    PageControl.Top := 0;
    PageControl.Width := 766;
    PageControl.Height := 591;
    PageControl.Margins.Left := 10;
    PageControl.Margins.Top := 0;
    PageControl.Margins.Right := 10;
    PageControl.Margins.Bottom := 8;
    PageControl.Align := alClient;
    PageControl.BevelEdges := [];
    PageControl.BevelInner := bvNone;
    PageControl.BevelOuter := bvNone;
    PageControl.ParentDoubleBuffered := False;
    PageControl.Color := clBtnFace;
    PageControl.DoubleBuffered := True;
    PageControl.Font.Charset := DEFAULT_CHARSET;
    PageControl.Font.Color := clWindowText;
    PageControl.Font.Height := -16;
    PageControl.Font.Name := 'Tahoma';
    PageControl.Font.Style := [];
    PageControl.ParentFont := False;
    PageControl.TabOrder := 1;
    PageControl.Fill.Kind := gfkNone;
    PageControl.Fill.ColorTo := clWhite;
    PageControl.Stroke.Kind := gskNone;
    PageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    PageControl.TabAppearance.Font.Color := clAqua;
    PageControl.TabAppearance.Font.Height := -16;
    PageControl.TabAppearance.Font.Name := 'Segoe UI';
    PageControl.TabAppearance.Font.Style := [];
    PageControl.TabAppearance.Fill.Color := 14408667;
    PageControl.TabAppearance.Stroke.Color := -1;
    PageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    PageControl.TabAppearance.HoverFill.Color := 15658734;
    PageControl.TabAppearance.HoverStroke.Color := -1;
    PageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    PageControl.TabAppearance.Shape := tsRectangle;
    PageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    PageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    PageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    PageControl.TabAppearance.TextColor := clGrayText;
    PageControl.TabAppearance.ActiveTextColor := clGrayText;
    PageControl.TabAppearance.HoverTextColor := clGrayText;
    PageControl.TabAppearance.ShowFocus := False;
    PageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    PageControl.TabAppearance.BadgeFont.Color := 139;
    PageControl.TabAppearance.BadgeFont.Height := -11;
    PageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    PageControl.TabAppearance.BadgeFont.Style := [fsBold];
    PageControl.ButtonAppearance.CloseIcon.LoadFromFile('uUserEdit.ContentPanel.PageControl.ButtonAppearance.CloseIcon.svg');
    PageControl.ButtonAppearance.InsertIcon.LoadFromFile('uUserEdit.ContentPanel.PageControl.ButtonAppearance.InsertIcon.svg');
    PageControl.ButtonAppearance.TabListIcon.LoadFromFile('uUserEdit.ContentPanel.PageControl.ButtonAppearance.TabListIcon.svg');
    PageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uUserEdit.ContentPanel.PageControl.ButtonAppearance.ScrollNextIcon.svg');
    PageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uUserEdit.ContentPanel.PageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    PageControl.TabSize.Height := 45.000000000000000000;
    PageControl.TabSize.Margins.Left := 8.000000000000000000;
    PageControl.TabSize.Margins.Top := 8.000000000000000000;
    PageControl.TabSize.Margins.Right := 8.000000000000000000;
    PageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    PageControl.Pages.Clear;
    with PageControl.Pages.Add do
    begin
      Text := 'General';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Asset groups';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Asset group filters';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'Asset type filters';
      UseDefaultAppearance := True;
    end;
    SetEvent(PageControl, Self, 'OnBeforeChangePage', 'PageControlBeforeChangePage');
    SetEvent(PageControl, Self, 'OnChangePage', 'PageControlChangePage');
    TMSFNCPageControl1Page1.SetParentComponent(PageControl);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 766;
    TMSFNCPageControl1Page1.Height := 539;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    MyGrid.SetParentComponent(TMSFNCPageControl1Page1);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 51;
    MyGrid.Width := 726;
    MyGrid.Height := 427;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 10;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 0;
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 4;
    MyGrid.RowCount := 1;
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 1;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    MyGrid.Adapter := MyDBAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 136.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 1;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    AssetGroupButtonPanel.SetParentComponent(TMSFNCPageControl1Page1);
    AssetGroupButtonPanel.Name := 'AssetGroupButtonPanel';
    AssetGroupButtonPanel.AlignWithMargins := True;
    AssetGroupButtonPanel.Left := 1;
    AssetGroupButtonPanel.Top := 489;
    AssetGroupButtonPanel.Width := 764;
    AssetGroupButtonPanel.Height := 49;
    AssetGroupButtonPanel.Margins.Left := 1;
    AssetGroupButtonPanel.Margins.Top := 1;
    AssetGroupButtonPanel.Margins.Right := 1;
    AssetGroupButtonPanel.Margins.Bottom := 1;
    AssetGroupButtonPanel.Align := alBottom;
    AssetGroupButtonPanel.BorderColor := clNone;
    AssetGroupButtonPanel.BorderStyle := bsNone;
    AssetGroupButtonPanel.ChildOrder := 1;
    AssetGroupButtonPanel.Color := clWhite;
    AddButton.SetParentComponent(AssetGroupButtonPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 20;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uUserEdit.AssetGroupButtonPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 0;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(AssetGroupButtonPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 128;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uUserEdit.AssetGroupButtonPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 1;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    TMSFNCPanel2.SetParentComponent(AssetGroupButtonPanel);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 2;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 3;
    TMSFNCPanel2.Width := 14;
    TMSFNCPanel2.Height := 41;
    TMSFNCPanel2.Margins.Bottom := 5;
    TMSFNCPanel2.Align := alLeft;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 2;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
    CreateTeamFilterButton.SetParentComponent(AssetGroupButtonPanel);
    CreateTeamFilterButton.Name := 'CreateTeamFilterButton';
    CreateTeamFilterButton.AlignWithMargins := True;
    CreateTeamFilterButton.Left := 236;
    CreateTeamFilterButton.Top := 8;
    CreateTeamFilterButton.Width := 128;
    CreateTeamFilterButton.Height := 31;
    CreateTeamFilterButton.Margins.Left := 0;
    CreateTeamFilterButton.Margins.Top := 8;
    CreateTeamFilterButton.Margins.Right := 8;
    CreateTeamFilterButton.Margins.Bottom := 10;
    CreateTeamFilterButton.Align := alLeft;
    CreateTeamFilterButton.Caption := 'Create Filter';
    CreateTeamFilterButton.Color := clNone;
    CreateTeamFilterButton.ElementClassName := 'MyWebButton';
    CreateTeamFilterButton.Glyph.LoadFromFile('uUserEdit.AssetGroupButtonPanel.CreateTeamFilterButton.Glyph.png');
    CreateTeamFilterButton.HeightPercent := 100.000000000000000000;
    CreateTeamFilterButton.TabOrder := 3;
    CreateTeamFilterButton.WidthPercent := 100.000000000000000000;
    SetEvent(CreateTeamFilterButton, Self, 'OnClick', 'CreateTeamFilterButtonClick');
    TMSFNCPanel3.SetParentComponent(TMSFNCPageControl1Page1);
    TMSFNCPanel3.Name := 'TMSFNCPanel3';
    TMSFNCPanel3.Tag := 1;
    TMSFNCPanel3.AlignWithMargins := True;
    TMSFNCPanel3.Left := 3;
    TMSFNCPanel3.Top := 1;
    TMSFNCPanel3.Width := 760;
    TMSFNCPanel3.Height := 40;
    TMSFNCPanel3.Margins.Top := 1;
    TMSFNCPanel3.Margins.Bottom := 0;
    TMSFNCPanel3.Align := alTop;
    TMSFNCPanel3.BevelInner := bvNone;
    TMSFNCPanel3.BevelOuter := bvNone;
    TMSFNCPanel3.Ctl3D := True;
    TMSFNCPanel3.ParentCtl3D := False;
    TMSFNCPanel3.ParentDoubleBuffered := False;
    TMSFNCPanel3.DoubleBuffered := True;
    TMSFNCPanel3.TabOrder := 2;
    TMSFNCPanel3.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel3.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel3.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel3.SectionsAppearance.Font.Style := [];
    TMSFNCPanel3.Stroke.Kind := gskNone;
    TMSFNCPanel3.Header.Text := 'Header';
    TMSFNCPanel3.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Header.Font.Color := clWindowText;
    TMSFNCPanel3.Header.Font.Height := -11;
    TMSFNCPanel3.Header.Font.Name := 'Tahoma';
    TMSFNCPanel3.Header.Font.Style := [];
    TMSFNCPanel3.Header.Visible := False;
    TMSFNCPanel3.Footer.Text := 'Footer';
    TMSFNCPanel3.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Footer.Font.Color := clWindowText;
    TMSFNCPanel3.Footer.Font.Height := -11;
    TMSFNCPanel3.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel3.Footer.Font.Style := [];
    TMSFNCPanel3.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel3.GlobalFont.Style := [];
    AssetGroupAllEdit.SetParentComponent(TMSFNCPanel3);
    AssetGroupAllEdit.Name := 'AssetGroupAllEdit';
    AssetGroupAllEdit.AlignWithMargins := True;
    AssetGroupAllEdit.Left := 20;
    AssetGroupAllEdit.Top := 15;
    AssetGroupAllEdit.Width := 720;
    AssetGroupAllEdit.Height := 22;
    AssetGroupAllEdit.Margins.Left := 20;
    AssetGroupAllEdit.Margins.Top := 15;
    AssetGroupAllEdit.Margins.Right := 20;
    AssetGroupAllEdit.Align := alClient;
    AssetGroupAllEdit.Caption := 'View all asset groups';
    AssetGroupAllEdit.ChildOrder := 2;
    AssetGroupAllEdit.Font.Charset := ANSI_CHARSET;
    AssetGroupAllEdit.Font.Color := 9010544;
    AssetGroupAllEdit.Font.Height := -16;
    AssetGroupAllEdit.Font.Name := 'Segoe UI';
    AssetGroupAllEdit.Font.Style := [];
    AssetGroupAllEdit.HeightPercent := 100.000000000000000000;
    AssetGroupAllEdit.ParentFont := False;
    AssetGroupAllEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetGroupAllEdit, Self, 'OnClick', 'RecordChange');
    TMSFNCPageControl1Page2.SetParentComponent(PageControl);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 766;
    TMSFNCPageControl1Page2.Height := 539;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    TeamFilterButtonPanel.SetParentComponent(TMSFNCPageControl1Page2);
    TeamFilterButtonPanel.Name := 'TeamFilterButtonPanel';
    TeamFilterButtonPanel.AlignWithMargins := True;
    TeamFilterButtonPanel.Left := 1;
    TeamFilterButtonPanel.Top := 489;
    TeamFilterButtonPanel.Width := 764;
    TeamFilterButtonPanel.Height := 49;
    TeamFilterButtonPanel.Margins.Left := 1;
    TeamFilterButtonPanel.Margins.Top := 1;
    TeamFilterButtonPanel.Margins.Right := 1;
    TeamFilterButtonPanel.Margins.Bottom := 1;
    TeamFilterButtonPanel.Align := alBottom;
    TeamFilterButtonPanel.BorderColor := clNone;
    TeamFilterButtonPanel.BorderStyle := bsNone;
    TeamFilterButtonPanel.ChildOrder := 1;
    TeamFilterButtonPanel.Color := clWhite;
    TeamFilterAddButton.SetParentComponent(TeamFilterButtonPanel);
    TeamFilterAddButton.Name := 'TeamFilterAddButton';
    TeamFilterAddButton.AlignWithMargins := True;
    TeamFilterAddButton.Left := 20;
    TeamFilterAddButton.Top := 8;
    TeamFilterAddButton.Width := 100;
    TeamFilterAddButton.Height := 31;
    TeamFilterAddButton.Margins.Left := 0;
    TeamFilterAddButton.Margins.Top := 8;
    TeamFilterAddButton.Margins.Right := 8;
    TeamFilterAddButton.Margins.Bottom := 10;
    TeamFilterAddButton.Align := alLeft;
    TeamFilterAddButton.Caption := 'Add';
    TeamFilterAddButton.Color := clNone;
    TeamFilterAddButton.ElementClassName := 'MyWebButton';
    TeamFilterAddButton.Glyph.LoadFromFile('uUserEdit.TeamFilterButtonPanel.TeamFilterAddButton.Glyph.png');
    TeamFilterAddButton.HeightPercent := 100.000000000000000000;
    TeamFilterAddButton.TabOrder := 0;
    TeamFilterAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(TeamFilterAddButton, Self, 'OnClick', 'TeamFilterAddButtonClick');
    TeamFilterDeleteButton.SetParentComponent(TeamFilterButtonPanel);
    TeamFilterDeleteButton.Name := 'TeamFilterDeleteButton';
    TeamFilterDeleteButton.AlignWithMargins := True;
    TeamFilterDeleteButton.Left := 236;
    TeamFilterDeleteButton.Top := 8;
    TeamFilterDeleteButton.Width := 100;
    TeamFilterDeleteButton.Height := 31;
    TeamFilterDeleteButton.Margins.Left := 0;
    TeamFilterDeleteButton.Margins.Top := 8;
    TeamFilterDeleteButton.Margins.Right := 8;
    TeamFilterDeleteButton.Margins.Bottom := 10;
    TeamFilterDeleteButton.Align := alLeft;
    TeamFilterDeleteButton.Caption := 'Delete';
    TeamFilterDeleteButton.Color := clNone;
    TeamFilterDeleteButton.ElementClassName := 'MyWebButton';
    TeamFilterDeleteButton.Glyph.LoadFromFile('uUserEdit.TeamFilterButtonPanel.TeamFilterDeleteButton.Glyph.png');
    TeamFilterDeleteButton.HeightPercent := 100.000000000000000000;
    TeamFilterDeleteButton.TabOrder := 1;
    TeamFilterDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(TeamFilterDeleteButton, Self, 'OnClick', 'TeamFilterDeleteButtonClick');
    TMSFNCPanel1.SetParentComponent(TeamFilterButtonPanel);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 2;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 3;
    TMSFNCPanel1.Width := 14;
    TMSFNCPanel1.Height := 41;
    TMSFNCPanel1.Margins.Bottom := 5;
    TMSFNCPanel1.Align := alLeft;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 2;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    TeamFilterEditButton.SetParentComponent(TeamFilterButtonPanel);
    TeamFilterEditButton.Name := 'TeamFilterEditButton';
    TeamFilterEditButton.AlignWithMargins := True;
    TeamFilterEditButton.Left := 128;
    TeamFilterEditButton.Top := 8;
    TeamFilterEditButton.Width := 100;
    TeamFilterEditButton.Height := 31;
    TeamFilterEditButton.Margins.Left := 0;
    TeamFilterEditButton.Margins.Top := 8;
    TeamFilterEditButton.Margins.Right := 8;
    TeamFilterEditButton.Margins.Bottom := 10;
    TeamFilterEditButton.Align := alLeft;
    TeamFilterEditButton.Caption := 'Edit';
    TeamFilterEditButton.Color := clNone;
    TeamFilterEditButton.ElementClassName := 'MyWebButton';
    TeamFilterEditButton.Glyph.LoadFromFile('uUserEdit.TeamFilterButtonPanel.TeamFilterEditButton.Glyph.png');
    TeamFilterEditButton.HeightPercent := 100.000000000000000000;
    TeamFilterEditButton.TabOrder := 3;
    TeamFilterEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(TeamFilterEditButton, Self, 'OnClick', 'TeamFilterEditButtonClick');
    TeamFilterGrid.SetParentComponent(TMSFNCPageControl1Page2);
    TeamFilterGrid.Name := 'TeamFilterGrid';
    TeamFilterGrid.AlignWithMargins := True;
    TeamFilterGrid.Left := 20;
    TeamFilterGrid.Top := 20;
    TeamFilterGrid.Width := 726;
    TeamFilterGrid.Height := 458;
    TeamFilterGrid.Margins.Left := 20;
    TeamFilterGrid.Margins.Top := 20;
    TeamFilterGrid.Margins.Right := 20;
    TeamFilterGrid.Margins.Bottom := 10;
    TeamFilterGrid.AdaptToStyle := True;
    TeamFilterGrid.Align := alClient;
    TeamFilterGrid.BevelInner := bvNone;
    TeamFilterGrid.BevelOuter := bvNone;
    TeamFilterGrid.BevelWidth := 2;
    TeamFilterGrid.Ctl3D := False;
    TeamFilterGrid.ParentCtl3D := False;
    TeamFilterGrid.ParentDoubleBuffered := False;
    TeamFilterGrid.DoubleBuffered := True;
    TeamFilterGrid.Font.Charset := DEFAULT_CHARSET;
    TeamFilterGrid.Font.Color := clWindowText;
    TeamFilterGrid.Font.Height := -11;
    TeamFilterGrid.Font.Name := 'Tahoma';
    TeamFilterGrid.Font.Style := [];
    TeamFilterGrid.ParentFont := False;
    TeamFilterGrid.TabOrder := 1;
    TeamFilterGrid.DefaultRowHeight := 34.000000000000000000;
    TeamFilterGrid.FixedColumns := 0;
    TeamFilterGrid.ColumnCount := 3;
    TeamFilterGrid.RowCount := 1;
    TeamFilterGrid.Options.Borders.FixedCellBorders := bNone;
    TeamFilterGrid.Options.ColumnSize.Stretch := True;
    TeamFilterGrid.Options.ColumnSize.StretchColumn := 1;
    TeamFilterGrid.Options.Editing.CalcFormat := '%g';
    TeamFilterGrid.Options.Grouping.CalcFormat := '%g';
    TeamFilterGrid.Options.Grouping.GroupCountFormat := '(%d)';
    TeamFilterGrid.Options.HTMLExport.CellPadding := 10;
    TeamFilterGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    TeamFilterGrid.Options.Mouse.WheelScrollKeepSelection := True;
    TeamFilterGrid.Options.Mouse.ClickMargin := 0;
    TeamFilterGrid.Options.Mouse.ColumnSizeMargin := 6;
    TeamFilterGrid.Options.Mouse.RowSizeMargin := 6;
    TeamFilterGrid.Options.URL.Color := clSkyBlue;
    TeamFilterGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    TeamFilterGrid.Options.Selection.Mode := smDisjunctRow;
    TeamFilterGrid.Adapter := TeamFilterDBAdapter;
    TeamFilterGrid.HorizontalScrollBarVisible := False;
    TeamFilterGrid.Columns.Clear;
    with TeamFilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with TeamFilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 645.000000000000000000;
    end;
    with TeamFilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'Default';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    TeamFilterGrid.Fill.ColorTo := clSilver;
    TeamFilterGrid.DefaultFont.Charset := ANSI_CHARSET;
    TeamFilterGrid.DefaultFont.Color := clWindowText;
    TeamFilterGrid.DefaultFont.Height := -15;
    TeamFilterGrid.DefaultFont.Name := 'Segoe UI';
    TeamFilterGrid.DefaultFont.Style := [];
    TeamFilterGrid.Stroke.Color := 15987699;
    TeamFilterGrid.Stroke.Width := 2.000000000000000000;
    TeamFilterGrid.TopRow := 1;
    TeamFilterGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    TeamFilterGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    TeamFilterGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    TeamFilterGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    TeamFilterGrid.Appearance.FixedLayout.Font.Color := clWhite;
    TeamFilterGrid.Appearance.FixedLayout.Font.Height := -16;
    TeamFilterGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    TeamFilterGrid.Appearance.FixedLayout.Font.Style := [];
    TeamFilterGrid.Appearance.NormalLayout.Fill.Color := -1;
    TeamFilterGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    TeamFilterGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    TeamFilterGrid.Appearance.NormalLayout.Font.Color := clGray;
    TeamFilterGrid.Appearance.NormalLayout.Font.Height := -11;
    TeamFilterGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    TeamFilterGrid.Appearance.NormalLayout.Font.Style := [];
    TeamFilterGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    TeamFilterGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    TeamFilterGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    TeamFilterGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TeamFilterGrid.Appearance.GroupLayout.Font.Color := clBlack;
    TeamFilterGrid.Appearance.GroupLayout.Font.Height := -11;
    TeamFilterGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TeamFilterGrid.Appearance.GroupLayout.Font.Style := [];
    TeamFilterGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    TeamFilterGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    TeamFilterGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    TeamFilterGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TeamFilterGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    TeamFilterGrid.Appearance.SummaryLayout.Font.Height := -11;
    TeamFilterGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TeamFilterGrid.Appearance.SummaryLayout.Font.Style := [];
    TeamFilterGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    TeamFilterGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    TeamFilterGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    TeamFilterGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    TeamFilterGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    TeamFilterGrid.Appearance.SelectedLayout.Font.Height := -16;
    TeamFilterGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    TeamFilterGrid.Appearance.SelectedLayout.Font.Style := [];
    TeamFilterGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    TeamFilterGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    TeamFilterGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    TeamFilterGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    TeamFilterGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    TeamFilterGrid.Appearance.FocusedLayout.Font.Height := -16;
    TeamFilterGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    TeamFilterGrid.Appearance.FocusedLayout.Font.Style := [];
    TeamFilterGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    TeamFilterGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    TeamFilterGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    TeamFilterGrid.Appearance.BandLayout.Fill.Color := 14062646;
    TeamFilterGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    TeamFilterGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    TeamFilterGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    TeamFilterGrid.Appearance.BandLayout.Font.Color := clBlack;
    TeamFilterGrid.Appearance.BandLayout.Font.Height := -16;
    TeamFilterGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    TeamFilterGrid.Appearance.BandLayout.Font.Style := [];
    TeamFilterGrid.Appearance.ProgressLayout.Color := 15385233;
    TeamFilterGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    TeamFilterGrid.LeftCol := 1;
    TeamFilterGrid.ScrollMode := scmItemScrolling;
    TeamFilterGrid.GlobalFont.Scale := 1.000000000000000000;
    TeamFilterGrid.GlobalFont.Style := [];
    TeamFilterGrid.DesignTimeSampleData := True;
    PageControlPage3.SetParentComponent(PageControl);
    PageControlPage3.Name := 'PageControlPage3';
    PageControlPage3.AlignWithMargins := True;
    PageControlPage3.Left := 0;
    PageControlPage3.Top := 52;
    PageControlPage3.Width := 766;
    PageControlPage3.Height := 539;
    PageControlPage3.Margins.Left := 0;
    PageControlPage3.Margins.Top := 52;
    PageControlPage3.Margins.Right := 0;
    PageControlPage3.Margins.Bottom := 0;
    PageControlPage3.Align := alClient;
    PageControlPage3.ParentDoubleBuffered := False;
    PageControlPage3.DoubleBuffered := True;
    PageControlPage3.TabStop := False;
    PageControlPage3.TabOrder := 3;
    PageControlPage3.Visible := False;
    PageControlPage3.PageIndex := 3;
    PageControlPage3.IsActive := False;
    TypeFilterGrid.SetParentComponent(PageControlPage3);
    TypeFilterGrid.Name := 'TypeFilterGrid';
    TypeFilterGrid.AlignWithMargins := True;
    TypeFilterGrid.Left := 20;
    TypeFilterGrid.Top := 20;
    TypeFilterGrid.Width := 726;
    TypeFilterGrid.Height := 458;
    TypeFilterGrid.Margins.Left := 20;
    TypeFilterGrid.Margins.Top := 20;
    TypeFilterGrid.Margins.Right := 20;
    TypeFilterGrid.Margins.Bottom := 10;
    TypeFilterGrid.AdaptToStyle := True;
    TypeFilterGrid.Align := alClient;
    TypeFilterGrid.BevelInner := bvNone;
    TypeFilterGrid.BevelOuter := bvNone;
    TypeFilterGrid.BevelWidth := 2;
    TypeFilterGrid.Ctl3D := False;
    TypeFilterGrid.ParentCtl3D := False;
    TypeFilterGrid.ParentDoubleBuffered := False;
    TypeFilterGrid.DoubleBuffered := True;
    TypeFilterGrid.Font.Charset := DEFAULT_CHARSET;
    TypeFilterGrid.Font.Color := clWindowText;
    TypeFilterGrid.Font.Height := -11;
    TypeFilterGrid.Font.Name := 'Tahoma';
    TypeFilterGrid.Font.Style := [];
    TypeFilterGrid.ParentFont := False;
    TypeFilterGrid.TabOrder := 0;
    TypeFilterGrid.DefaultRowHeight := 34.000000000000000000;
    TypeFilterGrid.FixedColumns := 0;
    TypeFilterGrid.ColumnCount := 3;
    TypeFilterGrid.RowCount := 1;
    TypeFilterGrid.Options.Borders.FixedCellBorders := bNone;
    TypeFilterGrid.Options.ColumnSize.Stretch := True;
    TypeFilterGrid.Options.ColumnSize.StretchColumn := 1;
    TypeFilterGrid.Options.Editing.CalcFormat := '%g';
    TypeFilterGrid.Options.Grouping.CalcFormat := '%g';
    TypeFilterGrid.Options.Grouping.GroupCountFormat := '(%d)';
    TypeFilterGrid.Options.HTMLExport.CellPadding := 10;
    TypeFilterGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    TypeFilterGrid.Options.Mouse.WheelScrollKeepSelection := True;
    TypeFilterGrid.Options.Mouse.ClickMargin := 0;
    TypeFilterGrid.Options.Mouse.ColumnSizeMargin := 6;
    TypeFilterGrid.Options.Mouse.RowSizeMargin := 6;
    TypeFilterGrid.Options.URL.Color := clSkyBlue;
    TypeFilterGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    TypeFilterGrid.Options.Selection.Mode := smDisjunctRow;
    TypeFilterGrid.Adapter := TypeFilterDBAdapter;
    TypeFilterGrid.HorizontalScrollBarVisible := False;
    TypeFilterGrid.Columns.Clear;
    with TypeFilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with TypeFilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 645.000000000000000000;
    end;
    with TypeFilterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'Default';
      ReadOnly := True;
      Width := 80.000000000000000000;
    end;
    TypeFilterGrid.Fill.ColorTo := clSilver;
    TypeFilterGrid.DefaultFont.Charset := ANSI_CHARSET;
    TypeFilterGrid.DefaultFont.Color := clWindowText;
    TypeFilterGrid.DefaultFont.Height := -15;
    TypeFilterGrid.DefaultFont.Name := 'Segoe UI';
    TypeFilterGrid.DefaultFont.Style := [];
    TypeFilterGrid.Stroke.Color := 15987699;
    TypeFilterGrid.Stroke.Width := 2.000000000000000000;
    TypeFilterGrid.TopRow := 1;
    TypeFilterGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    TypeFilterGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    TypeFilterGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    TypeFilterGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    TypeFilterGrid.Appearance.FixedLayout.Font.Color := clWhite;
    TypeFilterGrid.Appearance.FixedLayout.Font.Height := -16;
    TypeFilterGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    TypeFilterGrid.Appearance.FixedLayout.Font.Style := [];
    TypeFilterGrid.Appearance.NormalLayout.Fill.Color := -1;
    TypeFilterGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    TypeFilterGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    TypeFilterGrid.Appearance.NormalLayout.Font.Color := clGray;
    TypeFilterGrid.Appearance.NormalLayout.Font.Height := -11;
    TypeFilterGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    TypeFilterGrid.Appearance.NormalLayout.Font.Style := [];
    TypeFilterGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    TypeFilterGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    TypeFilterGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    TypeFilterGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TypeFilterGrid.Appearance.GroupLayout.Font.Color := clBlack;
    TypeFilterGrid.Appearance.GroupLayout.Font.Height := -11;
    TypeFilterGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TypeFilterGrid.Appearance.GroupLayout.Font.Style := [];
    TypeFilterGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    TypeFilterGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    TypeFilterGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    TypeFilterGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TypeFilterGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    TypeFilterGrid.Appearance.SummaryLayout.Font.Height := -11;
    TypeFilterGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TypeFilterGrid.Appearance.SummaryLayout.Font.Style := [];
    TypeFilterGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    TypeFilterGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    TypeFilterGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    TypeFilterGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    TypeFilterGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    TypeFilterGrid.Appearance.SelectedLayout.Font.Height := -16;
    TypeFilterGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    TypeFilterGrid.Appearance.SelectedLayout.Font.Style := [];
    TypeFilterGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    TypeFilterGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    TypeFilterGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    TypeFilterGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    TypeFilterGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    TypeFilterGrid.Appearance.FocusedLayout.Font.Height := -16;
    TypeFilterGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    TypeFilterGrid.Appearance.FocusedLayout.Font.Style := [];
    TypeFilterGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    TypeFilterGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    TypeFilterGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    TypeFilterGrid.Appearance.BandLayout.Fill.Color := 14062646;
    TypeFilterGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    TypeFilterGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    TypeFilterGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    TypeFilterGrid.Appearance.BandLayout.Font.Color := clBlack;
    TypeFilterGrid.Appearance.BandLayout.Font.Height := -16;
    TypeFilterGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    TypeFilterGrid.Appearance.BandLayout.Font.Style := [];
    TypeFilterGrid.Appearance.ProgressLayout.Color := 15385233;
    TypeFilterGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    TypeFilterGrid.LeftCol := 1;
    TypeFilterGrid.ScrollMode := scmItemScrolling;
    TypeFilterGrid.GlobalFont.Scale := 1.000000000000000000;
    TypeFilterGrid.GlobalFont.Style := [];
    TypeFilterGrid.DesignTimeSampleData := True;
    TypeFilterButtonPanel.SetParentComponent(PageControlPage3);
    TypeFilterButtonPanel.Name := 'TypeFilterButtonPanel';
    TypeFilterButtonPanel.AlignWithMargins := True;
    TypeFilterButtonPanel.Left := 1;
    TypeFilterButtonPanel.Top := 489;
    TypeFilterButtonPanel.Width := 764;
    TypeFilterButtonPanel.Height := 49;
    TypeFilterButtonPanel.Margins.Left := 1;
    TypeFilterButtonPanel.Margins.Top := 1;
    TypeFilterButtonPanel.Margins.Right := 1;
    TypeFilterButtonPanel.Margins.Bottom := 1;
    TypeFilterButtonPanel.Align := alBottom;
    TypeFilterButtonPanel.BorderColor := clNone;
    TypeFilterButtonPanel.BorderStyle := bsNone;
    TypeFilterButtonPanel.ChildOrder := 1;
    TypeFilterButtonPanel.Color := clWhite;
    TypeFilterAddButton.SetParentComponent(TypeFilterButtonPanel);
    TypeFilterAddButton.Name := 'TypeFilterAddButton';
    TypeFilterAddButton.AlignWithMargins := True;
    TypeFilterAddButton.Left := 20;
    TypeFilterAddButton.Top := 8;
    TypeFilterAddButton.Width := 100;
    TypeFilterAddButton.Height := 31;
    TypeFilterAddButton.Margins.Left := 0;
    TypeFilterAddButton.Margins.Top := 8;
    TypeFilterAddButton.Margins.Right := 8;
    TypeFilterAddButton.Margins.Bottom := 10;
    TypeFilterAddButton.Align := alLeft;
    TypeFilterAddButton.Caption := 'Add';
    TypeFilterAddButton.Color := clNone;
    TypeFilterAddButton.ElementClassName := 'MyWebButton';
    TypeFilterAddButton.Glyph.LoadFromFile('uUserEdit.TypeFilterButtonPanel.TypeFilterAddButton.Glyph.png');
    TypeFilterAddButton.HeightPercent := 100.000000000000000000;
    TypeFilterAddButton.TabOrder := 0;
    TypeFilterAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(TypeFilterAddButton, Self, 'OnClick', 'TypeFilterAddButtonClick');
    TypeFilterDeleteButton.SetParentComponent(TypeFilterButtonPanel);
    TypeFilterDeleteButton.Name := 'TypeFilterDeleteButton';
    TypeFilterDeleteButton.AlignWithMargins := True;
    TypeFilterDeleteButton.Left := 236;
    TypeFilterDeleteButton.Top := 8;
    TypeFilterDeleteButton.Width := 100;
    TypeFilterDeleteButton.Height := 31;
    TypeFilterDeleteButton.Margins.Left := 0;
    TypeFilterDeleteButton.Margins.Top := 8;
    TypeFilterDeleteButton.Margins.Right := 8;
    TypeFilterDeleteButton.Margins.Bottom := 10;
    TypeFilterDeleteButton.Align := alLeft;
    TypeFilterDeleteButton.Caption := 'Delete';
    TypeFilterDeleteButton.Color := clNone;
    TypeFilterDeleteButton.ElementClassName := 'MyWebButton';
    TypeFilterDeleteButton.Glyph.LoadFromFile('uUserEdit.TypeFilterButtonPanel.TypeFilterDeleteButton.Glyph.png');
    TypeFilterDeleteButton.HeightPercent := 100.000000000000000000;
    TypeFilterDeleteButton.TabOrder := 1;
    TypeFilterDeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(TypeFilterDeleteButton, Self, 'OnClick', 'TypeFilterDeleteButtonClick');
    TMSFNCPanel4.SetParentComponent(TypeFilterButtonPanel);
    TMSFNCPanel4.Name := 'TMSFNCPanel4';
    TMSFNCPanel4.Tag := 2;
    TMSFNCPanel4.AlignWithMargins := True;
    TMSFNCPanel4.Left := 3;
    TMSFNCPanel4.Top := 3;
    TMSFNCPanel4.Width := 14;
    TMSFNCPanel4.Height := 41;
    TMSFNCPanel4.Margins.Bottom := 5;
    TMSFNCPanel4.Align := alLeft;
    TMSFNCPanel4.BevelInner := bvNone;
    TMSFNCPanel4.BevelOuter := bvNone;
    TMSFNCPanel4.Ctl3D := True;
    TMSFNCPanel4.ParentCtl3D := False;
    TMSFNCPanel4.ParentDoubleBuffered := False;
    TMSFNCPanel4.DoubleBuffered := True;
    TMSFNCPanel4.TabOrder := 2;
    TMSFNCPanel4.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel4.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel4.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel4.SectionsAppearance.Font.Style := [];
    TMSFNCPanel4.Stroke.Kind := gskNone;
    TMSFNCPanel4.Header.Text := 'Header';
    TMSFNCPanel4.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.Header.Font.Color := clWindowText;
    TMSFNCPanel4.Header.Font.Height := -11;
    TMSFNCPanel4.Header.Font.Name := 'Tahoma';
    TMSFNCPanel4.Header.Font.Style := [];
    TMSFNCPanel4.Header.Visible := False;
    TMSFNCPanel4.Footer.Text := 'Footer';
    TMSFNCPanel4.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel4.Footer.Font.Color := clWindowText;
    TMSFNCPanel4.Footer.Font.Height := -11;
    TMSFNCPanel4.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel4.Footer.Font.Style := [];
    TMSFNCPanel4.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel4.GlobalFont.Style := [];
    TypeFilterEditButton.SetParentComponent(TypeFilterButtonPanel);
    TypeFilterEditButton.Name := 'TypeFilterEditButton';
    TypeFilterEditButton.AlignWithMargins := True;
    TypeFilterEditButton.Left := 128;
    TypeFilterEditButton.Top := 8;
    TypeFilterEditButton.Width := 100;
    TypeFilterEditButton.Height := 31;
    TypeFilterEditButton.Margins.Left := 0;
    TypeFilterEditButton.Margins.Top := 8;
    TypeFilterEditButton.Margins.Right := 8;
    TypeFilterEditButton.Margins.Bottom := 10;
    TypeFilterEditButton.Align := alLeft;
    TypeFilterEditButton.Caption := 'Edit';
    TypeFilterEditButton.Color := clNone;
    TypeFilterEditButton.ElementClassName := 'MyWebButton';
    TypeFilterEditButton.Glyph.LoadFromFile('uUserEdit.TypeFilterButtonPanel.TypeFilterEditButton.Glyph.png');
    TypeFilterEditButton.HeightPercent := 100.000000000000000000;
    TypeFilterEditButton.TabOrder := 3;
    TypeFilterEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(TypeFilterEditButton, Self, 'OnClick', 'TypeFilterEditButtonClick');
    TMSFNCPageControl1Page0.SetParentComponent(PageControl);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 766;
    TMSFNCPageControl1Page0.Height := 539;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    NameLabel.SetParentComponent(TMSFNCPageControl1Page0);
    NameLabel.Name := 'NameLabel';
    NameLabel.Left := 20;
    NameLabel.Top := 16;
    NameLabel.Width := 90;
    NameLabel.Height := 21;
    NameLabel.Caption := 'Displayname';
    NameLabel.Font.Charset := ANSI_CHARSET;
    NameLabel.Font.Color := 9010544;
    NameLabel.Font.Height := -16;
    NameLabel.Font.Name := 'Segoe UI';
    NameLabel.Font.Style := [];
    NameLabel.HeightPercent := 100.000000000000000000;
    NameLabel.ParentFont := False;
    NameLabel.WidthPercent := 100.000000000000000000;
    UserNameLabel.SetParentComponent(TMSFNCPageControl1Page0);
    UserNameLabel.Name := 'UserNameLabel';
    UserNameLabel.Left := 20;
    UserNameLabel.Top := 88;
    UserNameLabel.Width := 71;
    UserNameLabel.Height := 21;
    UserNameLabel.Caption := 'Username';
    UserNameLabel.Font.Charset := ANSI_CHARSET;
    UserNameLabel.Font.Color := 9010544;
    UserNameLabel.Font.Height := -16;
    UserNameLabel.Font.Name := 'Segoe UI';
    UserNameLabel.Font.Style := [];
    UserNameLabel.HeightPercent := 100.000000000000000000;
    UserNameLabel.ParentFont := False;
    UserNameLabel.WidthPercent := 100.000000000000000000;
    PasswordLabel.SetParentComponent(TMSFNCPageControl1Page0);
    PasswordLabel.Name := 'PasswordLabel';
    PasswordLabel.Left := 20;
    PasswordLabel.Top := 160;
    PasswordLabel.Width := 66;
    PasswordLabel.Height := 21;
    PasswordLabel.Caption := 'Password';
    PasswordLabel.Font.Charset := ANSI_CHARSET;
    PasswordLabel.Font.Color := 9010544;
    PasswordLabel.Font.Height := -16;
    PasswordLabel.Font.Name := 'Segoe UI';
    PasswordLabel.Font.Style := [];
    PasswordLabel.HeightPercent := 100.000000000000000000;
    PasswordLabel.ParentFont := False;
    PasswordLabel.WidthPercent := 100.000000000000000000;
    EmailLabel.SetParentComponent(TMSFNCPageControl1Page0);
    EmailLabel.Name := 'EmailLabel';
    EmailLabel.Left := 20;
    EmailLabel.Top := 232;
    EmailLabel.Width := 44;
    EmailLabel.Height := 21;
    EmailLabel.Caption := 'E-mail';
    EmailLabel.Font.Charset := ANSI_CHARSET;
    EmailLabel.Font.Color := 9010544;
    EmailLabel.Font.Height := -16;
    EmailLabel.Font.Name := 'Segoe UI';
    EmailLabel.Font.Style := [];
    EmailLabel.HeightPercent := 100.000000000000000000;
    EmailLabel.ParentFont := False;
    EmailLabel.WidthPercent := 100.000000000000000000;
    ExpiresLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ExpiresLabel.Name := 'ExpiresLabel';
    ExpiresLabel.Left := 20;
    ExpiresLabel.Top := 317;
    ExpiresLabel.Width := 49;
    ExpiresLabel.Height := 21;
    ExpiresLabel.Caption := 'Expires';
    ExpiresLabel.Font.Charset := ANSI_CHARSET;
    ExpiresLabel.Font.Color := 9010544;
    ExpiresLabel.Font.Height := -16;
    ExpiresLabel.Font.Name := 'Segoe UI';
    ExpiresLabel.Font.Style := [];
    ExpiresLabel.HeightPercent := 100.000000000000000000;
    ExpiresLabel.ParentFont := False;
    ExpiresLabel.WidthPercent := 100.000000000000000000;
    UserTypeLabel.SetParentComponent(TMSFNCPageControl1Page0);
    UserTypeLabel.Name := 'UserTypeLabel';
    UserTypeLabel.Left := 20;
    UserTypeLabel.Top := 383;
    UserTypeLabel.Width := 68;
    UserTypeLabel.Height := 21;
    UserTypeLabel.Caption := 'User Type';
    UserTypeLabel.Font.Charset := ANSI_CHARSET;
    UserTypeLabel.Font.Color := 9010544;
    UserTypeLabel.Font.Height := -16;
    UserTypeLabel.Font.Name := 'Segoe UI';
    UserTypeLabel.Font.Style := [];
    UserTypeLabel.HeightPercent := 100.000000000000000000;
    UserTypeLabel.ParentFont := False;
    UserTypeLabel.WidthPercent := 100.000000000000000000;
    ManagerLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ManagerLabel.Name := 'ManagerLabel';
    ManagerLabel.Left := 404;
    ManagerLabel.Top := 383;
    ManagerLabel.Width := 62;
    ManagerLabel.Height := 21;
    ManagerLabel.Caption := 'Manager';
    ManagerLabel.Font.Charset := ANSI_CHARSET;
    ManagerLabel.Font.Color := 9010544;
    ManagerLabel.Font.Height := -16;
    ManagerLabel.Font.Name := 'Segoe UI';
    ManagerLabel.Font.Style := [];
    ManagerLabel.HeightPercent := 100.000000000000000000;
    ManagerLabel.ParentFont := False;
    ManagerLabel.WidthPercent := 100.000000000000000000;
    SecurityGroupLabel.SetParentComponent(TMSFNCPageControl1Page0);
    SecurityGroupLabel.Name := 'SecurityGroupLabel';
    SecurityGroupLabel.Left := 20;
    SecurityGroupLabel.Top := 455;
    SecurityGroupLabel.Width := 94;
    SecurityGroupLabel.Height := 21;
    SecurityGroupLabel.Caption := 'Authorization';
    SecurityGroupLabel.Font.Charset := ANSI_CHARSET;
    SecurityGroupLabel.Font.Color := 9010544;
    SecurityGroupLabel.Font.Height := -16;
    SecurityGroupLabel.Font.Name := 'Segoe UI';
    SecurityGroupLabel.Font.Style := [];
    SecurityGroupLabel.HeightPercent := 100.000000000000000000;
    SecurityGroupLabel.ParentFont := False;
    SecurityGroupLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 404;
    WebLabel1.Top := 232;
    WebLabel1.Width := 48;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Mobile';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    POILabel.SetParentComponent(TMSFNCPageControl1Page0);
    POILabel.Name := 'POILabel';
    POILabel.Left := 404;
    POILabel.Top := 455;
    POILabel.Width := 112;
    POILabel.Height := 21;
    POILabel.Caption := 'Point Of Interest';
    POILabel.Font.Charset := ANSI_CHARSET;
    POILabel.Font.Color := 16755027;
    POILabel.Font.Height := -16;
    POILabel.Font.Name := 'Segoe UI';
    POILabel.Font.Style := [fsUnderline];
    POILabel.HeightPercent := 100.000000000000000000;
    POILabel.ParentFont := False;
    POILabel.WidthPercent := 100.000000000000000000;
    SetEvent(POILabel, Self, 'OnClick', 'POILabelClick');
    WebLabel2.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 404;
    WebLabel2.Top := 160;
    WebLabel2.Width := 63;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Fuel card';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    NameEdit.SetParentComponent(TMSFNCPageControl1Page0);
    NameEdit.Name := 'NameEdit';
    NameEdit.Left := 20;
    NameEdit.Top := 43;
    NameEdit.Width := 716;
    NameEdit.Height := 34;
    NameEdit.ChildOrder := 1;
    NameEdit.ElementClassName := 'MyWebEdit';
    NameEdit.Font.Charset := ANSI_CHARSET;
    NameEdit.Font.Color := clWindowText;
    NameEdit.Font.Height := -16;
    NameEdit.Font.Name := 'Segoe UI';
    NameEdit.Font.Style := [];
    NameEdit.HeightPercent := 100.000000000000000000;
    NameEdit.ParentFont := False;
    NameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameEdit, Self, 'OnChange', 'RecordChange');
    UserNameEdit.SetParentComponent(TMSFNCPageControl1Page0);
    UserNameEdit.Name := 'UserNameEdit';
    UserNameEdit.Left := 20;
    UserNameEdit.Top := 115;
    UserNameEdit.Width := 716;
    UserNameEdit.Height := 34;
    UserNameEdit.ChildOrder := 1;
    UserNameEdit.ElementClassName := 'MyWebEdit';
    UserNameEdit.Font.Charset := ANSI_CHARSET;
    UserNameEdit.Font.Color := clWindowText;
    UserNameEdit.Font.Height := -16;
    UserNameEdit.Font.Name := 'Segoe UI';
    UserNameEdit.Font.Style := [];
    UserNameEdit.HeightPercent := 100.000000000000000000;
    UserNameEdit.ParentFont := False;
    UserNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UserNameEdit, Self, 'OnChange', 'RecordChange');
    PasswordEdit.SetParentComponent(TMSFNCPageControl1Page0);
    PasswordEdit.Name := 'PasswordEdit';
    PasswordEdit.Left := 20;
    PasswordEdit.Top := 187;
    PasswordEdit.Width := 345;
    PasswordEdit.Height := 34;
    PasswordEdit.ChildOrder := 1;
    PasswordEdit.ElementClassName := 'MyWebEdit';
    PasswordEdit.Font.Charset := ANSI_CHARSET;
    PasswordEdit.Font.Color := clWindowText;
    PasswordEdit.Font.Height := -16;
    PasswordEdit.Font.Name := 'Segoe UI';
    PasswordEdit.Font.Style := [];
    PasswordEdit.HeightPercent := 100.000000000000000000;
    PasswordEdit.ParentFont := False;
    PasswordEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PasswordEdit, Self, 'OnChange', 'RecordChange');
    EmailEdit.SetParentComponent(TMSFNCPageControl1Page0);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 20;
    EmailEdit.Top := 259;
    EmailEdit.Width := 345;
    EmailEdit.Height := 34;
    EmailEdit.ChildOrder := 1;
    EmailEdit.ElementClassName := 'MyWebEdit';
    EmailEdit.Font.Charset := ANSI_CHARSET;
    EmailEdit.Font.Color := clWindowText;
    EmailEdit.Font.Height := -16;
    EmailEdit.Font.Name := 'Segoe UI';
    EmailEdit.Font.Style := [];
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.ParentFont := False;
    EmailEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailEdit, Self, 'OnChange', 'RecordChange');
    UnlimitedEdit.SetParentComponent(TMSFNCPageControl1Page0);
    UnlimitedEdit.Name := 'UnlimitedEdit';
    UnlimitedEdit.Left := 20;
    UnlimitedEdit.Top := 344;
    UnlimitedEdit.Width := 100;
    UnlimitedEdit.Height := 22;
    UnlimitedEdit.Caption := 'Unlimited';
    UnlimitedEdit.Checked := True;
    UnlimitedEdit.ChildOrder := 2;
    UnlimitedEdit.Font.Charset := ANSI_CHARSET;
    UnlimitedEdit.Font.Color := 9010544;
    UnlimitedEdit.Font.Height := -16;
    UnlimitedEdit.Font.Name := 'Segoe UI';
    UnlimitedEdit.Font.Style := [];
    UnlimitedEdit.HeightPercent := 100.000000000000000000;
    UnlimitedEdit.ParentFont := False;
    UnlimitedEdit.State := cbChecked;
    UnlimitedEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UnlimitedEdit, Self, 'OnClick', 'UnlimitedEditClick');
    DateTillEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DateTillEdit.Name := 'DateTillEdit';
    DateTillEdit.AlignWithMargins := True;
    DateTillEdit.Left := 131;
    DateTillEdit.Top := 336;
    DateTillEdit.Width := 143;
    DateTillEdit.Height := 34;
    DateTillEdit.Margins.Left := 20;
    DateTillEdit.Margins.Top := 8;
    DateTillEdit.Margins.Bottom := 8;
    DateTillEdit.BorderStyle := bsSingle;
    DateTillEdit.ChildOrder := 2;
    DateTillEdit.Color := clWhite;
    DateTillEdit.Date := 44545.380967847220000000;
    DateTillEdit.Enabled := False;
    DateTillEdit.Font.Charset := ANSI_CHARSET;
    DateTillEdit.Font.Color := clWindowText;
    DateTillEdit.Font.Height := -16;
    DateTillEdit.Font.Name := 'Segoe UI';
    DateTillEdit.Font.Style := [];
    DateTillEdit.ParentFont := False;
    DateTillEdit.Role := '';
    DateTillEdit.Text := '';
    SetEvent(DateTillEdit, Self, 'OnChange', 'RecordChange');
    UserTypeEdit.SetParentComponent(TMSFNCPageControl1Page0);
    UserTypeEdit.Name := 'UserTypeEdit';
    UserTypeEdit.AlignWithMargins := True;
    UserTypeEdit.Left := 20;
    UserTypeEdit.Top := 410;
    UserTypeEdit.Width := 345;
    UserTypeEdit.Height := 34;
    UserTypeEdit.Margins.Left := 20;
    UserTypeEdit.Margins.Top := 8;
    UserTypeEdit.Margins.Bottom := 8;
    UserTypeEdit.Color := clWhite;
    UserTypeEdit.ElementClassName := 'myselect';
    UserTypeEdit.Font.Charset := DEFAULT_CHARSET;
    UserTypeEdit.Font.Color := clWindowText;
    UserTypeEdit.Font.Height := 12;
    UserTypeEdit.Font.Name := 'Segoe UI';
    UserTypeEdit.Font.Style := [];
    UserTypeEdit.HeightPercent := 100.000000000000000000;
    UserTypeEdit.ParentFont := False;
    UserTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(UserTypeEdit, Self, 'OnChange', 'UserTypeEditChange');
    UserTypeEdit.ItemIndex := -1;
    UserTypeEdit.LookupValues.Clear;
    with UserTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'ROUTES';
    end;
    with UserTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'INACTIVE ASSETS';
    end;
    SecurityGroupEdit.SetParentComponent(TMSFNCPageControl1Page0);
    SecurityGroupEdit.Name := 'SecurityGroupEdit';
    SecurityGroupEdit.AlignWithMargins := True;
    SecurityGroupEdit.Left := 20;
    SecurityGroupEdit.Top := 482;
    SecurityGroupEdit.Width := 345;
    SecurityGroupEdit.Height := 34;
    SecurityGroupEdit.Margins.Left := 20;
    SecurityGroupEdit.Margins.Top := 8;
    SecurityGroupEdit.Margins.Bottom := 8;
    SecurityGroupEdit.Color := clWhite;
    SecurityGroupEdit.ElementClassName := 'myselect';
    SecurityGroupEdit.Font.Charset := DEFAULT_CHARSET;
    SecurityGroupEdit.Font.Color := clWindowText;
    SecurityGroupEdit.Font.Height := 12;
    SecurityGroupEdit.Font.Name := 'Segoe UI';
    SecurityGroupEdit.Font.Style := [];
    SecurityGroupEdit.HeightPercent := 100.000000000000000000;
    SecurityGroupEdit.ParentFont := False;
    SecurityGroupEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SecurityGroupEdit, Self, 'OnChange', 'RecordChange');
    SecurityGroupEdit.ItemIndex := -1;
    Type2CheckBox.SetParentComponent(TMSFNCPageControl1Page0);
    Type2CheckBox.Name := 'Type2CheckBox';
    Type2CheckBox.Left := 404;
    Type2CheckBox.Top := 346;
    Type2CheckBox.Width := 122;
    Type2CheckBox.Height := 22;
    Type2CheckBox.Caption := 'Site manager';
    Type2CheckBox.ChildOrder := 2;
    Type2CheckBox.Font.Charset := ANSI_CHARSET;
    Type2CheckBox.Font.Color := 9010544;
    Type2CheckBox.Font.Height := -16;
    Type2CheckBox.Font.Name := 'Segoe UI';
    Type2CheckBox.Font.Style := [];
    Type2CheckBox.HeightPercent := 100.000000000000000000;
    Type2CheckBox.ParentFont := False;
    Type2CheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(Type2CheckBox, Self, 'OnClick', 'CheckBoxClick');
    Type1CheckBox.SetParentComponent(TMSFNCPageControl1Page0);
    Type1CheckBox.Name := 'Type1CheckBox';
    Type1CheckBox.Left := 404;
    Type1CheckBox.Top := 318;
    Type1CheckBox.Width := 138;
    Type1CheckBox.Height := 22;
    Type1CheckBox.Caption := 'Project manager';
    Type1CheckBox.ChildOrder := 2;
    Type1CheckBox.Font.Charset := ANSI_CHARSET;
    Type1CheckBox.Font.Color := 9010544;
    Type1CheckBox.Font.Height := -16;
    Type1CheckBox.Font.Name := 'Segoe UI';
    Type1CheckBox.Font.Style := [];
    Type1CheckBox.HeightPercent := 100.000000000000000000;
    Type1CheckBox.ParentFont := False;
    Type1CheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(Type1CheckBox, Self, 'OnClick', 'CheckBoxClick');
    Type3CheckBox.SetParentComponent(TMSFNCPageControl1Page0);
    Type3CheckBox.Name := 'Type3CheckBox';
    Type3CheckBox.Left := 564;
    Type3CheckBox.Top := 318;
    Type3CheckBox.Width := 100;
    Type3CheckBox.Height := 22;
    Type3CheckBox.Caption := 'Foreman';
    Type3CheckBox.ChildOrder := 2;
    Type3CheckBox.Font.Charset := ANSI_CHARSET;
    Type3CheckBox.Font.Color := 9010544;
    Type3CheckBox.Font.Height := -16;
    Type3CheckBox.Font.Name := 'Segoe UI';
    Type3CheckBox.Font.Style := [];
    Type3CheckBox.HeightPercent := 100.000000000000000000;
    Type3CheckBox.ParentFont := False;
    Type3CheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(Type3CheckBox, Self, 'OnClick', 'CheckBoxClick');
    Type4CheckBox.SetParentComponent(TMSFNCPageControl1Page0);
    Type4CheckBox.Name := 'Type4CheckBox';
    Type4CheckBox.Left := 564;
    Type4CheckBox.Top := 346;
    Type4CheckBox.Width := 100;
    Type4CheckBox.Height := 22;
    Type4CheckBox.Caption := 'Worker';
    Type4CheckBox.ChildOrder := 2;
    Type4CheckBox.Font.Charset := ANSI_CHARSET;
    Type4CheckBox.Font.Color := 9010544;
    Type4CheckBox.Font.Height := -16;
    Type4CheckBox.Font.Name := 'Segoe UI';
    Type4CheckBox.Font.Style := [];
    Type4CheckBox.HeightPercent := 100.000000000000000000;
    Type4CheckBox.ParentFont := False;
    Type4CheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(Type4CheckBox, Self, 'OnClick', 'CheckBoxClick');
    MobileEdit.SetParentComponent(TMSFNCPageControl1Page0);
    MobileEdit.Name := 'MobileEdit';
    MobileEdit.Left := 391;
    MobileEdit.Top := 259;
    MobileEdit.Width := 345;
    MobileEdit.Height := 34;
    MobileEdit.ChildOrder := 1;
    MobileEdit.ElementClassName := 'MyWebEdit';
    MobileEdit.Font.Charset := ANSI_CHARSET;
    MobileEdit.Font.Color := clWindowText;
    MobileEdit.Font.Height := -16;
    MobileEdit.Font.Name := 'Segoe UI';
    MobileEdit.Font.Style := [];
    MobileEdit.HeightPercent := 100.000000000000000000;
    MobileEdit.ParentFont := False;
    MobileEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MobileEdit, Self, 'OnChange', 'RecordChange');
    Type5CheckBox.SetParentComponent(TMSFNCPageControl1Page0);
    Type5CheckBox.Name := 'Type5CheckBox';
    Type5CheckBox.Left := 670;
    Type5CheckBox.Top := 346;
    Type5CheckBox.Width := 75;
    Type5CheckBox.Height := 22;
    Type5CheckBox.Caption := 'Driver';
    Type5CheckBox.ChildOrder := 2;
    Type5CheckBox.Enabled := False;
    Type5CheckBox.Font.Charset := ANSI_CHARSET;
    Type5CheckBox.Font.Color := 9010544;
    Type5CheckBox.Font.Height := -16;
    Type5CheckBox.Font.Name := 'Segoe UI';
    Type5CheckBox.Font.Style := [];
    Type5CheckBox.HeightPercent := 100.000000000000000000;
    Type5CheckBox.ParentFont := False;
    Type5CheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(Type5CheckBox, Self, 'OnClick', 'CheckBoxClick');
    POIEdit.SetParentComponent(TMSFNCPageControl1Page0);
    POIEdit.Name := 'POIEdit';
    POIEdit.Left := 391;
    POIEdit.Top := 482;
    POIEdit.Width := 345;
    POIEdit.Height := 34;
    POIEdit.ChildOrder := 1;
    POIEdit.ElementClassName := 'MyWebEdit';
    POIEdit.Font.Charset := ANSI_CHARSET;
    POIEdit.Font.Color := clWindowText;
    POIEdit.Font.Height := -16;
    POIEdit.Font.Name := 'Segoe UI';
    POIEdit.Font.Style := [];
    POIEdit.HeightPercent := 100.000000000000000000;
    POIEdit.ParentFont := False;
    POIEdit.ReadOnly := True;
    POIEdit.WidthPercent := 100.000000000000000000;
    SetEvent(POIEdit, Self, 'OnChange', 'RecordChange');
    POIClearButton.SetParentComponent(TMSFNCPageControl1Page0);
    POIClearButton.Name := 'POIClearButton';
    POIClearButton.AlignWithMargins := True;
    POIClearButton.Left := 704;
    POIClearButton.Top := 484;
    POIClearButton.Width := 30;
    POIClearButton.Height := 30;
    POIClearButton.Margins.Left := 10;
    POIClearButton.Margins.Top := 8;
    POIClearButton.Margins.Right := 0;
    POIClearButton.Margins.Bottom := 8;
    POIClearButton.Color := clNone;
    POIClearButton.ElementClassName := 'MyClearButton';
    POIClearButton.Glyph.LoadFromFile('uUserEdit.TMSFNCPageControl1Page0.POIClearButton.Glyph.png');
    POIClearButton.HeightPercent := 100.000000000000000000;
    POIClearButton.TabOrder := 15;
    POIClearButton.WidthPercent := 100.000000000000000000;
    SetEvent(POIClearButton, Self, 'OnClick', 'POIClearButtonClick');
    FuelCardEdit.SetParentComponent(TMSFNCPageControl1Page0);
    FuelCardEdit.Name := 'FuelCardEdit';
    FuelCardEdit.Left := 391;
    FuelCardEdit.Top := 187;
    FuelCardEdit.Width := 345;
    FuelCardEdit.Height := 34;
    FuelCardEdit.ChildOrder := 1;
    FuelCardEdit.ElementClassName := 'MyWebEdit';
    FuelCardEdit.Font.Charset := ANSI_CHARSET;
    FuelCardEdit.Font.Color := clWindowText;
    FuelCardEdit.Font.Height := -16;
    FuelCardEdit.Font.Name := 'Segoe UI';
    FuelCardEdit.Font.Style := [];
    FuelCardEdit.HeightPercent := 100.000000000000000000;
    FuelCardEdit.ParentFont := False;
    FuelCardEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FuelCardEdit, Self, 'OnChange', 'RecordChange');
    ManagerListBox.SetParentComponent(TMSFNCPageControl1Page0);
    ManagerListBox.Name := 'ManagerListBox';
    ManagerListBox.Left := 391;
    ManagerListBox.Top := 43;
    ManagerListBox.Width := 345;
    ManagerListBox.Height := 356;
    ManagerListBox.AllowFocus := False;
    ManagerListBox.BevelInner := bvNone;
    ManagerListBox.BevelOuter := bvNone;
    ManagerListBox.ParentDoubleBuffered := False;
    ManagerListBox.DoubleBuffered := True;
    ManagerListBox.Font.Charset := ANSI_CHARSET;
    ManagerListBox.Font.Color := clWindowText;
    ManagerListBox.Font.Height := -16;
    ManagerListBox.Font.Name := 'Segoe UI';
    ManagerListBox.Font.Style := [];
    ManagerListBox.ParentFont := False;
    ManagerListBox.TabOrder := 17;
    ManagerListBox.Visible := False;
    ManagerListBox.Stroke.Color := 11119017;
    ManagerListBox.ItemsAppearance.Font.Charset := ANSI_CHARSET;
    ManagerListBox.ItemsAppearance.Font.Color := clGray;
    ManagerListBox.ItemsAppearance.Font.Height := -16;
    ManagerListBox.ItemsAppearance.Font.Name := 'Segoe UI';
    ManagerListBox.ItemsAppearance.Font.Style := [];
    ManagerListBox.ItemsAppearance.SelectedFill.Color := 16578806;
    ManagerListBox.ItemsAppearance.SelectedStroke.Color := 15702829;
    ManagerListBox.ItemsAppearance.SelectedStroke.Width := 2.000000000000000000;
    ManagerListBox.ItemsAppearance.FixedHeight := 30.000000000000000000;
    ManagerListBox.ItemsAppearance.ShowFocus := False;
    ManagerListBox.DefaultItem.TextColor := clGray;
    ManagerListBox.Items.Clear;
    with ManagerListBox.Items.Add do
    begin
      Text := 'Mercedes';
      TextColor := clGray;
      Tag := 1;
    end;
    with ManagerListBox.Items.Add do
    begin
      Text := 'Audi';
      TextColor := clGray;
      Tag := 2;
    end;
    with ManagerListBox.Items.Add do
    begin
      Text := 'BMW';
      TextColor := clGray;
      Tag := 3;
    end;
    with ManagerListBox.Items.Add do
    begin
      Text := 'Land Rover';
      TextColor := clGray;
      Tag := 4;
    end;
    with ManagerListBox.Items.Add do
    begin
      Text := 'Bugatti';
      TextColor := clGray;
      Tag := 5;
    end;
    ManagerListBox.Header.Text := 'Header';
    ManagerListBox.Header.Fill.Color := 16380654;
    ManagerListBox.Header.Font.Charset := DEFAULT_CHARSET;
    ManagerListBox.Header.Font.Color := clBlack;
    ManagerListBox.Header.Font.Height := -11;
    ManagerListBox.Header.Font.Name := 'Tahoma';
    ManagerListBox.Header.Font.Style := [];
    ManagerListBox.Header.Stroke.Kind := gskNone;
    ManagerListBox.Header.Size := 25.000000000000000000;
    ManagerListBox.Header.SortIndicatorColor := 11829830;
    ManagerListBox.Interaction.Filtering.Enabled := True;
    ManagerListBox.GlobalFont.Scale := 1.000000000000000000;
    ManagerListBox.GlobalFont.Style := [];
    SetEvent(ManagerListBox, Self, 'OnItemCheckChanged', 'ManagerListBoxItemCheckChanged');
    ManagerEdit.SetParentComponent(TMSFNCPageControl1Page0);
    ManagerEdit.Name := 'ManagerEdit';
    ManagerEdit.Left := 391;
    ManagerEdit.Top := 410;
    ManagerEdit.Width := 345;
    ManagerEdit.Height := 34;
    ManagerEdit.ChildOrder := 1;
    ManagerEdit.ElementClassName := 'MyWebEdit';
    ManagerEdit.Font.Charset := ANSI_CHARSET;
    ManagerEdit.Font.Color := clWindowText;
    ManagerEdit.Font.Height := -16;
    ManagerEdit.Font.Name := 'Segoe UI';
    ManagerEdit.Font.Style := [];
    ManagerEdit.HeightPercent := 100.000000000000000000;
    ManagerEdit.ParentFont := False;
    ManagerEdit.ReadOnly := True;
    ManagerEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ManagerEdit, Self, 'OnChange', 'RecordChange');
    SetEvent(ManagerEdit, Self, 'OnClick', 'ManagerEditClick');
    ManagerClearButton.SetParentComponent(TMSFNCPageControl1Page0);
    ManagerClearButton.Name := 'ManagerClearButton';
    ManagerClearButton.AlignWithMargins := True;
    ManagerClearButton.Left := 704;
    ManagerClearButton.Top := 412;
    ManagerClearButton.Width := 30;
    ManagerClearButton.Height := 30;
    ManagerClearButton.Margins.Left := 10;
    ManagerClearButton.Margins.Top := 8;
    ManagerClearButton.Margins.Right := 0;
    ManagerClearButton.Margins.Bottom := 8;
    ManagerClearButton.Color := clNone;
    ManagerClearButton.ElementClassName := 'MyClearButton';
    ManagerClearButton.Glyph.LoadFromFile('uUserEdit.TMSFNCPageControl1Page0.ManagerClearButton.Glyph.png');
    ManagerClearButton.HeightPercent := 100.000000000000000000;
    ManagerClearButton.TabOrder := 19;
    ManagerClearButton.WidthPercent := 100.000000000000000000;
    SetEvent(ManagerClearButton, Self, 'OnClick', 'ManagerClearButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 792;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 64;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Edit User';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 763;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uUserEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 33;
    WebWaitMessage.Top := 642;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uUserEdit.WebWaitMessage.Picture.gif');
    MyDBAdapter.SetParentComponent(Self);
    MyDBAdapter.Name := 'MyDBAdapter';
    MyDBAdapter.Left := 142;
    MyDBAdapter.Top := 198;
    MyDBAdapter.Width := 26;
    MyDBAdapter.Height := 26;
    MyDBAdapter.Margins.Left := 12;
    MyDBAdapter.Visible := True;
    MyDBAdapter.Grid := MyGrid;
    MyDBAdapter.DataSource := MyDataSource;
    MyDBAdapter.Columns.Clear;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'og_pk';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'og_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    MyDBAdapter.AutoCreateColumns := False;
    MyDBAdapter.AutoRemoveColumns := False;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 80;
    MyMessageDlg.Top := 642;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    TeamFilterDBAdapter.SetParentComponent(Self);
    TeamFilterDBAdapter.Name := 'TeamFilterDBAdapter';
    TeamFilterDBAdapter.Left := 271;
    TeamFilterDBAdapter.Top := 198;
    TeamFilterDBAdapter.Width := 26;
    TeamFilterDBAdapter.Height := 26;
    TeamFilterDBAdapter.Margins.Left := 12;
    TeamFilterDBAdapter.Visible := True;
    TeamFilterDBAdapter.Grid := TeamFilterGrid;
    TeamFilterDBAdapter.DataSource := TeamFilterDataSource;
    TeamFilterDBAdapter.Columns.Clear;
    with TeamFilterDBAdapter.Columns.Add do
    begin
      FieldName := 'pkey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with TeamFilterDBAdapter.Columns.Add do
    begin
      FieldName := 'name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with TeamFilterDBAdapter.Columns.Add do
    begin
      FieldName := 'default';
      Header := 'Default';
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    TeamFilterDBAdapter.AutoCreateColumns := False;
    TeamFilterDBAdapter.AutoRemoveColumns := False;
    ManagerPopup.SetParentComponent(Self);
    ManagerPopup.Name := 'ManagerPopup';
    ManagerPopup.Left := 409;
    ManagerPopup.Top := 506;
    ManagerPopup.Width := 26;
    ManagerPopup.Height := 26;
    ManagerPopup.Visible := True;
    ManagerPopup.DropDownHeight := 356.000000000000000000;
    ManagerPopup.DropDownWidth := 345.000000000000000000;
    ManagerPopup.PlacementControl := ManagerEdit;
    ManagerPopup.ContentControl := ManagerListBox;
    SetEvent(ManagerPopup, Self, 'OnClosePopup', 'ManagerPopupClosePopup');
    SetEvent(ManagerPopup, Self, 'OnPopup', 'ManagerPopupPopup');
    TypeFilterDBAdapter.SetParentComponent(Self);
    TypeFilterDBAdapter.Name := 'TypeFilterDBAdapter';
    TypeFilterDBAdapter.Left := 395;
    TypeFilterDBAdapter.Top := 198;
    TypeFilterDBAdapter.Width := 26;
    TypeFilterDBAdapter.Height := 26;
    TypeFilterDBAdapter.Margins.Left := 12;
    TypeFilterDBAdapter.Visible := True;
    TypeFilterDBAdapter.Grid := TypeFilterGrid;
    TypeFilterDBAdapter.DataSource := TypeFilterDataSource;
    TypeFilterDBAdapter.Columns.Clear;
    with TypeFilterDBAdapter.Columns.Add do
    begin
      FieldName := 'pkey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with TypeFilterDBAdapter.Columns.Add do
    begin
      FieldName := 'name';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with TypeFilterDBAdapter.Columns.Add do
    begin
      FieldName := 'default';
      Header := 'Default';
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    TypeFilterDBAdapter.AutoCreateColumns := False;
    TypeFilterDBAdapter.AutoRemoveColumns := False;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 523;
    ShowTimer.Top := 192;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.Left := 140;
    MyDataset.Top := 234;
    MyDatasetog_pk.SetParentComponent(MyDataset);
    MyDatasetog_pk.Name := 'MyDatasetog_pk';
    MyDatasetog_pk.FieldName := 'og_pk';
    MyDatasetog_pk.Size := 256;
    MyDatasetog_naam.SetParentComponent(MyDataset);
    MyDatasetog_naam.Name := 'MyDatasetog_naam';
    MyDatasetog_naam.FieldName := 'og_naam';
    MyDatasetog_naam.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 140;
    MyDataSource.Top := 282;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.DataNode := 'result';
    WebConnection.Delimiter := ';';
    WebConnection.URI := 'https://instatrack.eu:44321/php/default_user_team_filter_list.php?sessionid=77bf1b605df39b3529f9843c09ba0e23&pkey=2';
    WebConnection.Left := 704;
    WebConnection.Top := 45;
    TeamFilterDataSet.SetParentComponent(Self);
    TeamFilterDataSet.Name := 'TeamFilterDataSet';
    TeamFilterDataSet.Connection := WebConnection;
    TeamFilterDataSet.Left := 268;
    TeamFilterDataSet.Top := 234;
    TeamFilterDataSetpkey.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetpkey.Name := 'TeamFilterDataSetpkey';
    TeamFilterDataSetpkey.FieldName := 'pkey';
    TeamFilterDataSetpkey.Size := 256;
    TeamFilterDataSetname.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetname.Name := 'TeamFilterDataSetname';
    TeamFilterDataSetname.FieldName := 'name';
    TeamFilterDataSetname.Size := 256;
    TeamFilterDataSetdefault.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetdefault.Name := 'TeamFilterDataSetdefault';
    TeamFilterDataSetdefault.FieldName := 'default';
    TeamFilterDataSetdefault.Size := 256;
    TeamFilterDataSource.SetParentComponent(Self);
    TeamFilterDataSource.Name := 'TeamFilterDataSource';
    TeamFilterDataSource.DataSet := TeamFilterDataSet;
    TeamFilterDataSource.Left := 268;
    TeamFilterDataSource.Top := 282;
    LoadTimer.SetParentComponent(Self);
    LoadTimer.Name := 'LoadTimer';
    LoadTimer.Enabled := False;
    LoadTimer.Interval := 50;
    SetEvent(LoadTimer, Self, 'OnTimer', 'LoadTimerTimer');
    LoadTimer.Left := 523;
    LoadTimer.Top := 240;
    TypeFilterDataset.SetParentComponent(Self);
    TypeFilterDataset.Name := 'TypeFilterDataset';
    TypeFilterDataset.Connection := WebConnection;
    TypeFilterDataset.Left := 392;
    TypeFilterDataset.Top := 234;
    StringField1.SetParentComponent(TypeFilterDataset);
    StringField1.Name := 'StringField1';
    StringField1.FieldName := 'pkey';
    StringField1.Size := 256;
    StringField2.SetParentComponent(TypeFilterDataset);
    StringField2.Name := 'StringField2';
    StringField2.FieldName := 'name';
    StringField2.Size := 256;
    StringField3.SetParentComponent(TypeFilterDataset);
    StringField3.Name := 'StringField3';
    StringField3.FieldName := 'default';
    StringField3.Size := 256;
    TypeFilterDataSource.SetParentComponent(Self);
    TypeFilterDataSource.Name := 'TypeFilterDataSource';
    TypeFilterDataSource.DataSet := TypeFilterDataset;
    TypeFilterDataSource.Left := 392;
    TypeFilterDataSource.Top := 282;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ListButtonAllPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    PageControl.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    AssetGroupButtonPanel.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    CreateTeamFilterButton.AfterLoadDFMValues;
    TMSFNCPanel3.AfterLoadDFMValues;
    AssetGroupAllEdit.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    TeamFilterButtonPanel.AfterLoadDFMValues;
    TeamFilterAddButton.AfterLoadDFMValues;
    TeamFilterDeleteButton.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    TeamFilterEditButton.AfterLoadDFMValues;
    TeamFilterGrid.AfterLoadDFMValues;
    PageControlPage3.AfterLoadDFMValues;
    TypeFilterGrid.AfterLoadDFMValues;
    TypeFilterButtonPanel.AfterLoadDFMValues;
    TypeFilterAddButton.AfterLoadDFMValues;
    TypeFilterDeleteButton.AfterLoadDFMValues;
    TMSFNCPanel4.AfterLoadDFMValues;
    TypeFilterEditButton.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    NameLabel.AfterLoadDFMValues;
    UserNameLabel.AfterLoadDFMValues;
    PasswordLabel.AfterLoadDFMValues;
    EmailLabel.AfterLoadDFMValues;
    ExpiresLabel.AfterLoadDFMValues;
    UserTypeLabel.AfterLoadDFMValues;
    ManagerLabel.AfterLoadDFMValues;
    SecurityGroupLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    POILabel.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    NameEdit.AfterLoadDFMValues;
    UserNameEdit.AfterLoadDFMValues;
    PasswordEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    UnlimitedEdit.AfterLoadDFMValues;
    DateTillEdit.AfterLoadDFMValues;
    UserTypeEdit.AfterLoadDFMValues;
    SecurityGroupEdit.AfterLoadDFMValues;
    Type2CheckBox.AfterLoadDFMValues;
    Type1CheckBox.AfterLoadDFMValues;
    Type3CheckBox.AfterLoadDFMValues;
    Type4CheckBox.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    Type5CheckBox.AfterLoadDFMValues;
    POIEdit.AfterLoadDFMValues;
    POIClearButton.AfterLoadDFMValues;
    FuelCardEdit.AfterLoadDFMValues;
    ManagerListBox.AfterLoadDFMValues;
    ManagerEdit.AfterLoadDFMValues;
    ManagerClearButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyDBAdapter.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    TeamFilterDBAdapter.AfterLoadDFMValues;
    ManagerPopup.AfterLoadDFMValues;
    TypeFilterDBAdapter.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetog_pk.AfterLoadDFMValues;
    MyDatasetog_naam.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
    TeamFilterDataSet.AfterLoadDFMValues;
    TeamFilterDataSetpkey.AfterLoadDFMValues;
    TeamFilterDataSetname.AfterLoadDFMValues;
    TeamFilterDataSetdefault.AfterLoadDFMValues;
    TeamFilterDataSource.AfterLoadDFMValues;
    LoadTimer.AfterLoadDFMValues;
    TypeFilterDataset.AfterLoadDFMValues;
    StringField1.AfterLoadDFMValues;
    StringField2.AfterLoadDFMValues;
    StringField3.AfterLoadDFMValues;
    TypeFilterDataSource.AfterLoadDFMValues;
  end;
end;

end.
unit uDummy;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls;

type
  TDummyForm = class(TWebForm)
    FormPanel: TWebPanel;
    ContentPanel: TWebPanel;
    CaptionPanel: TWebPanel;
    CaptionLabel: TWebLabel;
    CloseImage: TWebImageControl;
  private
    { Private declarations }
  public
    { Public declarations }
  end;

var
  DummyForm: TDummyForm;

implementation

{$R *.dfm}

end.
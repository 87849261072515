unit uDeviceModelEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLIB.Rest, WEBLIB.JSON,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGridDatabaseAdapter, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Imaging.pngimage,
  Vcl.Imaging.GIFImg, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCPageControl, VCL.TMSFNCCustomControl,
  VCL.TMSFNCTabSet, VCL.TMSFNCPanel, VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCGrid, Data.DB,
  WEBLib.DB, WEBLib.CDS;

type
  TDeviceModelEditForm = class(TForm)
    FormPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    ContentPanel: TPanel;
    TopPanel: TPanel;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    MyMessageDlg: TMessageDlg;
    [async]
    TMSFNCPageControl1: TTMSFNCPageControl;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    WebPanel2: TPanel;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    TMSFNCPanel1: TTMSFNCPanel;
    EditButton: TSpeedButton;
    ModelBrandLabel: TLabel;
    WebLabel1: TLabel;
    ModelNameLabel: TLabel;
    DeviceVersionEdit: TEdit;
    DeviceModelEdit: TEdit;
    DeviceBrandEdit: TLookupComboBox;
    DocumentGrid: TTMSFNCGrid;
    MyDBAdapter: TTMSFNCGridDatabaseAdapter;
    MyDataset: TClientDataSet;
    MyDatasetog_pk: TStringField;
    MyDatasetog_naam: TStringField;
    MyDataSource: TDataSource;
    [async]
    BatteryListEdit: TCheckBox;
    WebLabel2: TLabel;
    BatteryAverageEdit: TCheckBox;
    BatteryVoltageEdit: TEdit;
    WebLabel4: TLabel;
    BatteryDaysEdit: TEdit;
    WebLabel5: TLabel;

    WebLabel3: TLabel;
    BatteryReportEdit: TCheckBox;
    WebLabel6: TLabel;
    DeviceIOIgnitionEdit: TLookupComboBox;
    WebLabel7: TLabel;
    DigitalInputEdit: TEdit;
    WebLabel8: TLabel;
    AnalogInputEdit: TEdit;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    DigitalOutputEdit: TEdit;
    AnalogOutputEdit: TEdit;
    CircleEdit: TCheckBox;
    TriangleEdit: TCheckBox;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadDeviceBrandList(S: String);
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    IsSet: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  DeviceModelEditForm: TDeviceModelEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure StringToIO(S: String; var DIN, AIN, DOUT, AOUT: Integer);
var
  I: Integer;
  StringList: TStringList;
begin
  DIN := 0;
  AIN := 0;
  DOUT := 0;
  AOUT := 0;
  StringList := TStringList.Create();
  try
    StringList.DelimitedText := S;
    for I := 0 to StringList.Count-1 do begin
      case StrToInt(StringList[I]) of
        01..10: Inc(DIN);
        11..20: Inc(AIN);
        21..30: Inc(DOUT);
        31..40: Inc(AOUT);
      end;
    end;
  finally
    StringList.Free;
  end;
end;

procedure IOToString(var S: String; DIN, AIN, DOUT, AOUT: Integer);
var
  I: Integer;
  StringList: TStringList;
begin
  S := EmptyStr;
  StringList := TStringList.Create();
  try
    for I := 1 to DIN do StringList.Add(IntToStr(I));
    for I := 1 to AIN do StringList.Add(IntToStr(10 + I));
    for I := 1 to DOUT do StringList.Add(IntToStr(20 + I));
    for I := 1 to AOUT do StringList.Add(IntToStr(30 + I));
    S := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
end;

procedure TDeviceModelEditForm.WebFormCreate(Sender: TObject);
begin
  PKey := -1;
end;

procedure TDeviceModelEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Visible := False;
end;

procedure TDeviceModelEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TDeviceModelEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TDeviceModelEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TDeviceModelEditForm.ShowTimerTimer(Sender: TObject);
begin
  ShowTimer.Enabled := False;
  // Load Manager
  await(LoadLookupValues());
  // Load Data
  if PKey >= 0 then begin
    await(LoadData());
  end;
  WebWaitMessage.Hide;
  RecordChanged := False;
end;

procedure TDeviceModelEditForm.LoadDeviceBrandList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  DeviceBrandEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      DeviceBrandEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    DeviceBrandEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TDeviceModelEditForm.LoadLookupValues();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_device_brand_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadDeviceBrandList(string(MyRequest.response));
//  LoadDeviceModelList(TJSONPair(MyJSONObject.Get('device_model')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TDeviceModelEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TDeviceModelEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  IOList: String;
  DIN, AIN, DOUT, AOUT: Integer;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_device_model_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      // Parse Result
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          DeviceBrandEdit.Value := MyJSONObject.GetJSONValue('Brand');
          DeviceModelEdit.Text := MyJSONObject.GetJSONValue('ID');
          DeviceVersionEdit.Text := MyJSONObject.GetJSONValue('Version');
          DeviceIOIgnitionEdit.Value := MyJSONObject.GetJSONValue('IO Ignition');
          CircleEdit.Checked := MyJSONObject.GetJSONValue('Circle') = '1';
          TriangleEdit.Checked := MyJSONObject.GetJSONValue('Triangle') = '1';
          IOList := MyJSONObject.GetJSONValue('IO List');
          StringToIO(IOList, DIN, DOUT, AIN, AOUT);
          DigitalInputEdit.Text := IntToStr(DIN);
          AnalogInputEdit.Text := IntToStr(AIN);
          DigitalOutputEdit.Text := IntToStr(DOUT);
          AnalogOutputEdit.Text := IntToStr(AOUT);
          BatteryVoltageEdit.Text := MyJSONObject.GetJSONValue('Battery Voltage');
          BatteryAverageEdit.Checked := MyJSONObject.GetJSONValue('Battery Average') = 't';
          BatteryDaysEdit.Text := MyJSONObject.GetJSONValue('Battery Days');
          BatteryListEdit.Checked := MyJSONObject.GetJSONValue('Battery List') = 't';
          BatteryReportEdit.Checked := MyJSONObject.GetJSONValue('Battery Report') = 't';
        end else begin
          MessageDlg(MyJSONObject.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end
    end;
  finally
    MyWebRequest.Free;
  end;
end;

function TDeviceModelEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Model
  if Result then begin
    if Trim(DeviceModelEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'MODEL NAME is a required field.';
    end;
  end;
  // Ignition
  if Result then begin
    if StrToInt(EmptyStrToZero(DeviceIOIgnitionEdit.Value)) = 0 then begin
      Result := False;
      ErrorMessage := 'IGNITION is a required field.';
    end;
  end;

//  // PKey
//  if Result then begin
//    if TryStrToInt(DevicePKeyEdit.Text, MyInteger) then begin
//    end else begin
//      Result := False;
//      ErrorMessage := 'Device ID is a required field.';
//    end;
//  end;
//  // Model
//  if Result then begin
//    if StrToInt(EmptyStrToZero(DeviceModelEdit.Value)) = 0 then begin
//      Result := False;
//      ErrorMessage := 'Model is a required field.';
//    end;
//  end;
end;

function TDeviceModelEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  // Temp Fields
  IOList: String;
  DIN, AIN, DOUT, AOUT: Integer;
begin
  Result := False;
  // Get IO Values
  DIN := StrToInt(EmptyStrToZero(DigitalInputEdit.Text));
  AIN := StrToInt(EmptyStrToZero(AnalogInputEdit.Text));
  DOUT := StrToInt(EmptyStrToZero(DigitalOutputEdit.Text));
  AOUT := StrToInt(EmptyStrToZero(AnalogOutputEdit.Text));
  // Create IO List
  IOToString(IOList, DIN, AIN, DOUT, AOUT);
  // Create JSON String
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id": "' + Trim(DeviceModelEdit.Text) + '",' +
      '"brand": "' + EmptyStrToZero(DeviceBrandEdit.Value) + '",' +
      '"version": "' + Trim(DeviceVersionEdit.Text) + '",' +
      '"circle": "' + BooleanToString(CircleEdit.Checked) + '",' +
      '"triangle": "' + BooleanToString(TriangleEdit.Checked) + '",' +
      '"battery voltage": "' + EmptyStrToZero(BatteryVoltageEdit.Text) + '",' +
      '"battery average": "' + BooleanToPostgreSQL(BatteryAverageEdit.Checked) + '",' +
      '"battery days": "' + EmptyStrToZero(BatteryDaysEdit.Text) + '",' +
      '"battery list": "' + BooleanToPostgreSQL(BatteryListEdit.Checked) + '",' +
      '"battery report": "' + BooleanToPostgreSQL(BatteryReportEdit.Checked) + '",' +
      '"io ignition": "' + DeviceIOIgnitionEdit.Value + '",' +
      '"io list": "' + IOList  + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_device_model_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TDeviceModelEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  ContentPanel := TPanel.Create(Self);
  TopPanel := TPanel.Create(Self);
  TMSFNCPageControl1 := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  DocumentGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  ModelBrandLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  ModelNameLabel := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  DeviceVersionEdit := TEdit.Create(Self);
  DeviceModelEdit := TEdit.Create(Self);
  DeviceBrandEdit := TLookupComboBox.Create(Self);
  BatteryListEdit := TCheckBox.Create(Self);
  BatteryAverageEdit := TCheckBox.Create(Self);
  BatteryVoltageEdit := TEdit.Create(Self);
  BatteryDaysEdit := TEdit.Create(Self);
  BatteryReportEdit := TCheckBox.Create(Self);
  DeviceIOIgnitionEdit := TLookupComboBox.Create(Self);
  DigitalInputEdit := TEdit.Create(Self);
  AnalogInputEdit := TEdit.Create(Self);
  DigitalOutputEdit := TEdit.Create(Self);
  AnalogOutputEdit := TEdit.Create(Self);
  CircleEdit := TCheckBox.Create(Self);
  TriangleEdit := TCheckBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  MyDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ShowTimer := TTimer.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetog_pk := TStringField.Create(Self);
  MyDatasetog_naam := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  TMSFNCPageControl1.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  DocumentGrid.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  ModelBrandLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  ModelNameLabel.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  DeviceVersionEdit.BeforeLoadDFMValues;
  DeviceModelEdit.BeforeLoadDFMValues;
  DeviceBrandEdit.BeforeLoadDFMValues;
  BatteryListEdit.BeforeLoadDFMValues;
  BatteryAverageEdit.BeforeLoadDFMValues;
  BatteryVoltageEdit.BeforeLoadDFMValues;
  BatteryDaysEdit.BeforeLoadDFMValues;
  BatteryReportEdit.BeforeLoadDFMValues;
  DeviceIOIgnitionEdit.BeforeLoadDFMValues;
  DigitalInputEdit.BeforeLoadDFMValues;
  AnalogInputEdit.BeforeLoadDFMValues;
  DigitalOutputEdit.BeforeLoadDFMValues;
  AnalogOutputEdit.BeforeLoadDFMValues;
  CircleEdit.BeforeLoadDFMValues;
  TriangleEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  MyDBAdapter.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetog_pk.BeforeLoadDFMValues;
  MyDatasetog_naam.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  try
    Name := 'DeviceModelEditForm';
    Width := 856;
    Height := 704;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 856;
    FormPanel.Height := 704;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 856;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 136;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Device Model - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 827;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uDeviceModelEdit.CaptionPanel.CloseImage.Picture.png');
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 850;
    ContentPanel.Height := 661;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    TopPanel.SetParentComponent(ContentPanel);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 0;
    TopPanel.Width := 850;
    TopPanel.Height := 606;
    TopPanel.Align := alTop;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 2;
    TopPanel.Color := clWhite;
    TMSFNCPageControl1.SetParentComponent(TopPanel);
    TMSFNCPageControl1.Name := 'TMSFNCPageControl1';
    TMSFNCPageControl1.AlignWithMargins := True;
    TMSFNCPageControl1.Left := 10;
    TMSFNCPageControl1.Top := 0;
    TMSFNCPageControl1.Width := 830;
    TMSFNCPageControl1.Height := 598;
    TMSFNCPageControl1.Margins.Left := 10;
    TMSFNCPageControl1.Margins.Top := 0;
    TMSFNCPageControl1.Margins.Right := 10;
    TMSFNCPageControl1.Margins.Bottom := 8;
    TMSFNCPageControl1.Align := alClient;
    TMSFNCPageControl1.BevelEdges := [];
    TMSFNCPageControl1.BevelInner := bvNone;
    TMSFNCPageControl1.BevelOuter := bvNone;
    TMSFNCPageControl1.ParentDoubleBuffered := False;
    TMSFNCPageControl1.Color := clBtnFace;
    TMSFNCPageControl1.DoubleBuffered := True;
    TMSFNCPageControl1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl1.Font.Color := clWindowText;
    TMSFNCPageControl1.Font.Height := -16;
    TMSFNCPageControl1.Font.Name := 'Tahoma';
    TMSFNCPageControl1.Font.Style := [];
    TMSFNCPageControl1.ParentFont := False;
    TMSFNCPageControl1.TabOrder := 0;
    TMSFNCPageControl1.Fill.Kind := gfkNone;
    TMSFNCPageControl1.Fill.ColorTo := clWhite;
    TMSFNCPageControl1.Stroke.Kind := gskNone;
    TMSFNCPageControl1.TabAppearance.Font.Charset := ANSI_CHARSET;
    TMSFNCPageControl1.TabAppearance.Font.Color := clAqua;
    TMSFNCPageControl1.TabAppearance.Font.Height := -16;
    TMSFNCPageControl1.TabAppearance.Font.Name := 'Segoe UI';
    TMSFNCPageControl1.TabAppearance.Font.Style := [];
    TMSFNCPageControl1.TabAppearance.Fill.Color := 14408667;
    TMSFNCPageControl1.TabAppearance.Stroke.Color := -1;
    TMSFNCPageControl1.TabAppearance.ActiveFill.ColorTo := clWhite;
    TMSFNCPageControl1.TabAppearance.HoverFill.Color := 15658734;
    TMSFNCPageControl1.TabAppearance.HoverStroke.Color := -1;
    TMSFNCPageControl1.TabAppearance.TextSpacing := 10.000000000000000000;
    TMSFNCPageControl1.TabAppearance.Shape := tsRectangle;
    TMSFNCPageControl1.TabAppearance.ShapeOverlap := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.ShapeSlope := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.ShapeRounding := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.TextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.ActiveTextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.HoverTextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.ShowFocus := False;
    TMSFNCPageControl1.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Color := 139;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Height := -11;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Name := 'Tahoma';
    TMSFNCPageControl1.TabAppearance.BadgeFont.Style := [fsBold];
    TMSFNCPageControl1.ButtonAppearance.CloseIcon.LoadFromFile('uDeviceModelEdit.TopPanel.TMSFNCPageControl1.ButtonAppearance.CloseIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.InsertIcon.LoadFromFile('uDeviceModelEdit.TopPanel.TMSFNCPageControl1.ButtonAppearance.InsertIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.TabListIcon.LoadFromFile('uDeviceModelEdit.TopPanel.TMSFNCPageControl1.ButtonAppearance.TabListIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.ScrollNextIcon.LoadFromFile('uDeviceModelEdit.TopPanel.TMSFNCPageControl1.ButtonAppearance.ScrollNextIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uDeviceModelEdit.TopPanel.TMSFNCPageControl1.ButtonAppearance.ScrollPreviousIcon.svg');
    TMSFNCPageControl1.TabSize.Height := 45.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Left := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Top := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Right := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Bottom := 8.000000000000000000;
    TMSFNCPageControl1.Pages.Clear;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'GENERAL';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'I/O';
      TabVisible := False;
      Visible := False;
      UseDefaultAppearance := True;
    end;
    TMSFNCPageControl1Page1.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 830;
    TMSFNCPageControl1Page1.Height := 546;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    WebPanel2.SetParentComponent(TMSFNCPageControl1Page1);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.AlignWithMargins := True;
    WebPanel2.Left := 1;
    WebPanel2.Top := 496;
    WebPanel2.Width := 828;
    WebPanel2.Height := 49;
    WebPanel2.Margins.Left := 1;
    WebPanel2.Margins.Top := 1;
    WebPanel2.Margins.Right := 1;
    WebPanel2.Margins.Bottom := 1;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clNone;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 1;
    WebPanel2.Color := clWhite;
    AddButton.SetParentComponent(WebPanel2);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 20;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uDeviceModelEdit.WebPanel2.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 0;
    AddButton.WidthPercent := 100.000000000000000000;
    DeleteButton.SetParentComponent(WebPanel2);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 236;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uDeviceModelEdit.WebPanel2.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 1;
    DeleteButton.WidthPercent := 100.000000000000000000;
    TMSFNCPanel1.SetParentComponent(WebPanel2);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 2;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 3;
    TMSFNCPanel1.Width := 14;
    TMSFNCPanel1.Height := 41;
    TMSFNCPanel1.Margins.Bottom := 5;
    TMSFNCPanel1.Align := alLeft;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 2;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    EditButton.SetParentComponent(WebPanel2);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 128;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uDeviceModelEdit.WebPanel2.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 3;
    EditButton.WidthPercent := 100.000000000000000000;
    DocumentGrid.SetParentComponent(TMSFNCPageControl1Page1);
    DocumentGrid.Name := 'DocumentGrid';
    DocumentGrid.AlignWithMargins := True;
    DocumentGrid.Left := 20;
    DocumentGrid.Top := 20;
    DocumentGrid.Width := 790;
    DocumentGrid.Height := 465;
    DocumentGrid.Margins.Left := 20;
    DocumentGrid.Margins.Top := 20;
    DocumentGrid.Margins.Right := 20;
    DocumentGrid.Margins.Bottom := 10;
    DocumentGrid.AdaptToStyle := True;
    DocumentGrid.Align := alClient;
    DocumentGrid.BevelInner := bvNone;
    DocumentGrid.BevelOuter := bvNone;
    DocumentGrid.BevelWidth := 2;
    DocumentGrid.Ctl3D := False;
    DocumentGrid.ParentCtl3D := False;
    DocumentGrid.ParentDoubleBuffered := False;
    DocumentGrid.DoubleBuffered := True;
    DocumentGrid.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Font.Color := clWindowText;
    DocumentGrid.Font.Height := -11;
    DocumentGrid.Font.Name := 'Tahoma';
    DocumentGrid.Font.Style := [];
    DocumentGrid.ParentFont := False;
    DocumentGrid.TabOrder := 1;
    DocumentGrid.DefaultRowHeight := 34.000000000000000000;
    DocumentGrid.FixedColumns := 0;
    DocumentGrid.RowCount := 1;
    DocumentGrid.Options.Borders.FixedCellBorders := bNone;
    DocumentGrid.Options.ColumnSize.Stretch := True;
    DocumentGrid.Options.ColumnSize.StretchColumn := 1;
    DocumentGrid.Options.Editing.CalcFormat := '%g';
    DocumentGrid.Options.Grouping.CalcFormat := '%g';
    DocumentGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DocumentGrid.Options.HTMLExport.CellPadding := 10;
    DocumentGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DocumentGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DocumentGrid.Options.Mouse.ClickMargin := 0;
    DocumentGrid.Options.Mouse.ColumnSizeMargin := 6;
    DocumentGrid.Options.Mouse.RowSizeMargin := 6;
    DocumentGrid.Options.URL.Color := clSkyBlue;
    DocumentGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DocumentGrid.Options.Selection.Mode := smDisjunctRow;
    DocumentGrid.Adapter := MyDBAdapter;
    DocumentGrid.HorizontalScrollBarVisible := False;
    DocumentGrid.Columns.Clear;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 469.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 120.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    DocumentGrid.Fill.ColorTo := clSilver;
    DocumentGrid.DefaultFont.Charset := ANSI_CHARSET;
    DocumentGrid.DefaultFont.Color := clWindowText;
    DocumentGrid.DefaultFont.Height := -15;
    DocumentGrid.DefaultFont.Name := 'Segoe UI';
    DocumentGrid.DefaultFont.Style := [];
    DocumentGrid.Stroke.Color := 15987699;
    DocumentGrid.Stroke.Width := 2.000000000000000000;
    DocumentGrid.TopRow := 1;
    DocumentGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DocumentGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DocumentGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.FixedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FixedLayout.Font.Style := [];
    DocumentGrid.Appearance.NormalLayout.Fill.Color := -1;
    DocumentGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DocumentGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.NormalLayout.Font.Color := clGray;
    DocumentGrid.Appearance.NormalLayout.Font.Height := -11;
    DocumentGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.NormalLayout.Font.Style := [];
    DocumentGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DocumentGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.GroupLayout.Font.Height := -11;
    DocumentGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DocumentGrid.Appearance.GroupLayout.Font.Style := [];
    DocumentGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DocumentGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.SummaryLayout.Font.Height := -11;
    DocumentGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DocumentGrid.Appearance.SummaryLayout.Font.Style := [];
    DocumentGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DocumentGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.SelectedLayout.Font.Height := -16;
    DocumentGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.SelectedLayout.Font.Style := [];
    DocumentGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.FocusedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FocusedLayout.Font.Style := [];
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DocumentGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DocumentGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DocumentGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.BandLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.BandLayout.Font.Height := -16;
    DocumentGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DocumentGrid.Appearance.BandLayout.Font.Style := [];
    DocumentGrid.Appearance.ProgressLayout.Color := 15385233;
    DocumentGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DocumentGrid.LeftCol := 1;
    DocumentGrid.ScrollMode := scmItemScrolling;
    DocumentGrid.GlobalFont.Scale := 1.000000000000000000;
    DocumentGrid.GlobalFont.Style := [];
    DocumentGrid.DesignTimeSampleData := True;
    TMSFNCPageControl1Page0.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 830;
    TMSFNCPageControl1Page0.Height := 546;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    ModelBrandLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ModelBrandLabel.Name := 'ModelBrandLabel';
    ModelBrandLabel.Left := 28;
    ModelBrandLabel.Top := 90;
    ModelBrandLabel.Width := 52;
    ModelBrandLabel.Height := 21;
    ModelBrandLabel.Caption := 'BRAND';
    ModelBrandLabel.Font.Charset := ANSI_CHARSET;
    ModelBrandLabel.Font.Color := 9010544;
    ModelBrandLabel.Font.Height := -16;
    ModelBrandLabel.Font.Name := 'Segoe UI';
    ModelBrandLabel.Font.Style := [];
    ModelBrandLabel.HeightPercent := 100.000000000000000000;
    ModelBrandLabel.ParentFont := False;
    ModelBrandLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 28;
    WebLabel1.Top := 173;
    WebLabel1.Width := 65;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'VERSION';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    ModelNameLabel.SetParentComponent(TMSFNCPageControl1Page0);
    ModelNameLabel.Name := 'ModelNameLabel';
    ModelNameLabel.Left := 28;
    ModelNameLabel.Top := 15;
    ModelNameLabel.Width := 101;
    ModelNameLabel.Height := 21;
    ModelNameLabel.Caption := 'MODEL NAME';
    ModelNameLabel.Font.Charset := ANSI_CHARSET;
    ModelNameLabel.Font.Color := 9010544;
    ModelNameLabel.Font.Height := -16;
    ModelNameLabel.Font.Name := 'Segoe UI';
    ModelNameLabel.Font.Style := [];
    ModelNameLabel.HeightPercent := 100.000000000000000000;
    ModelNameLabel.ParentFont := False;
    ModelNameLabel.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 28;
    WebLabel2.Top := 350;
    WebLabel2.Width := 176;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'LOW BATTERY WARNING';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 28;
    WebLabel4.Top := 397;
    WebLabel4.Width := 77;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Limit Value';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := clBlack;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 361;
    WebLabel5.Top := 449;
    WebLabel5.Width := 32;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'days';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := clBlack;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 361;
    WebLabel3.Top := 397;
    WebLabel3.Width := 26;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'volt';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 460;
    WebLabel6.Top := 15;
    WebLabel6.Width := 67;
    WebLabel6.Height := 21;
    WebLabel6.Caption := 'IGNITION';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := 9010544;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Segoe UI';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 460;
    WebLabel7.Top := 122;
    WebLabel7.Width := 92;
    WebLabel7.Height := 21;
    WebLabel7.Caption := 'Digital Inputs';
    WebLabel7.Font.Charset := ANSI_CHARSET;
    WebLabel7.Font.Color := clBlack;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Segoe UI';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 460;
    WebLabel8.Top := 203;
    WebLabel8.Width := 96;
    WebLabel8.Height := 21;
    WebLabel8.Caption := 'Analog Inputs';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := clBlack;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Segoe UI';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 632;
    WebLabel9.Top := 122;
    WebLabel9.Width := 105;
    WebLabel9.Height := 21;
    WebLabel9.Caption := 'Digital Outputs';
    WebLabel9.Font.Charset := ANSI_CHARSET;
    WebLabel9.Font.Color := clBlack;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Segoe UI';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(TMSFNCPageControl1Page0);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 632;
    WebLabel10.Top := 203;
    WebLabel10.Width := 109;
    WebLabel10.Height := 21;
    WebLabel10.Caption := 'Analog Outputs';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := clBlack;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    DeviceVersionEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DeviceVersionEdit.Name := 'DeviceVersionEdit';
    DeviceVersionEdit.Left := 28;
    DeviceVersionEdit.Top := 200;
    DeviceVersionEdit.Width := 397;
    DeviceVersionEdit.Height := 34;
    DeviceVersionEdit.ChildOrder := 1;
    DeviceVersionEdit.ElementClassName := 'MyWebEdit';
    DeviceVersionEdit.Font.Charset := ANSI_CHARSET;
    DeviceVersionEdit.Font.Color := clWindowText;
    DeviceVersionEdit.Font.Height := -16;
    DeviceVersionEdit.Font.Name := 'Segoe UI';
    DeviceVersionEdit.Font.Style := [];
    DeviceVersionEdit.HeightPercent := 100.000000000000000000;
    DeviceVersionEdit.ParentFont := False;
    DeviceVersionEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceVersionEdit, Self, 'OnChange', 'RecordChange');
    DeviceModelEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DeviceModelEdit.Name := 'DeviceModelEdit';
    DeviceModelEdit.Left := 28;
    DeviceModelEdit.Top := 42;
    DeviceModelEdit.Width := 397;
    DeviceModelEdit.Height := 34;
    DeviceModelEdit.ChildOrder := 1;
    DeviceModelEdit.ElementClassName := 'MyWebEdit';
    DeviceModelEdit.Font.Charset := ANSI_CHARSET;
    DeviceModelEdit.Font.Color := clWindowText;
    DeviceModelEdit.Font.Height := -16;
    DeviceModelEdit.Font.Name := 'Segoe UI';
    DeviceModelEdit.Font.Style := [];
    DeviceModelEdit.HeightPercent := 100.000000000000000000;
    DeviceModelEdit.ParentFont := False;
    DeviceModelEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceModelEdit, Self, 'OnChange', 'RecordChange');
    DeviceBrandEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DeviceBrandEdit.Name := 'DeviceBrandEdit';
    DeviceBrandEdit.AlignWithMargins := True;
    DeviceBrandEdit.Left := 28;
    DeviceBrandEdit.Top := 122;
    DeviceBrandEdit.Width := 397;
    DeviceBrandEdit.Height := 34;
    DeviceBrandEdit.Margins.Left := 20;
    DeviceBrandEdit.Margins.Top := 8;
    DeviceBrandEdit.Margins.Bottom := 8;
    DeviceBrandEdit.Color := clWhite;
    DeviceBrandEdit.ElementClassName := 'myselect_white';
    DeviceBrandEdit.Font.Charset := DEFAULT_CHARSET;
    DeviceBrandEdit.Font.Color := clWindowText;
    DeviceBrandEdit.Font.Height := 12;
    DeviceBrandEdit.Font.Name := 'Segoe UI';
    DeviceBrandEdit.Font.Style := [];
    DeviceBrandEdit.HeightPercent := 100.000000000000000000;
    DeviceBrandEdit.ParentFont := False;
    DeviceBrandEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceBrandEdit, Self, 'OnChange', 'RecordChange');
    DeviceBrandEdit.ItemIndex := -1;
    BatteryListEdit.SetParentComponent(TMSFNCPageControl1Page0);
    BatteryListEdit.Name := 'BatteryListEdit';
    BatteryListEdit.Left := 468;
    BatteryListEdit.Top := 394;
    BatteryListEdit.Width := 329;
    BatteryListEdit.Height := 26;
    BatteryListEdit.Caption := 'Show low battery icon in navigation list';
    BatteryListEdit.ChildOrder := 28;
    BatteryListEdit.Font.Charset := ANSI_CHARSET;
    BatteryListEdit.Font.Color := clWindowText;
    BatteryListEdit.Font.Height := -16;
    BatteryListEdit.Font.Name := 'Segoe UI';
    BatteryListEdit.Font.Style := [];
    BatteryListEdit.HeightPercent := 100.000000000000000000;
    BatteryListEdit.ParentFont := False;
    BatteryListEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryListEdit, Self, 'OnClick', 'RecordChange');
    BatteryAverageEdit.SetParentComponent(TMSFNCPageControl1Page0);
    BatteryAverageEdit.Name := 'BatteryAverageEdit';
    BatteryAverageEdit.Left := 28;
    BatteryAverageEdit.Top := 446;
    BatteryAverageEdit.Width := 246;
    BatteryAverageEdit.Height := 26;
    BatteryAverageEdit.Caption := 'Calculate using average of last ';
    BatteryAverageEdit.ChildOrder := 28;
    BatteryAverageEdit.Font.Charset := ANSI_CHARSET;
    BatteryAverageEdit.Font.Color := clWindowText;
    BatteryAverageEdit.Font.Height := -16;
    BatteryAverageEdit.Font.Name := 'Segoe UI';
    BatteryAverageEdit.Font.Style := [];
    BatteryAverageEdit.HeightPercent := 100.000000000000000000;
    BatteryAverageEdit.ParentFont := False;
    BatteryAverageEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryAverageEdit, Self, 'OnClick', 'RecordChange');
    BatteryVoltageEdit.SetParentComponent(TMSFNCPageControl1Page0);
    BatteryVoltageEdit.Name := 'BatteryVoltageEdit';
    BatteryVoltageEdit.Left := 280;
    BatteryVoltageEdit.Top := 390;
    BatteryVoltageEdit.Width := 69;
    BatteryVoltageEdit.Height := 34;
    BatteryVoltageEdit.ChildOrder := 1;
    BatteryVoltageEdit.EditType := weFloat;
    BatteryVoltageEdit.ElementClassName := 'MyWebEdit';
    BatteryVoltageEdit.Font.Charset := ANSI_CHARSET;
    BatteryVoltageEdit.Font.Color := clWindowText;
    BatteryVoltageEdit.Font.Height := -16;
    BatteryVoltageEdit.Font.Name := 'Segoe UI';
    BatteryVoltageEdit.Font.Style := [];
    BatteryVoltageEdit.HeightPercent := 100.000000000000000000;
    BatteryVoltageEdit.ParentFont := False;
    BatteryVoltageEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryVoltageEdit, Self, 'OnChange', 'RecordChange');
    BatteryDaysEdit.SetParentComponent(TMSFNCPageControl1Page0);
    BatteryDaysEdit.Name := 'BatteryDaysEdit';
    BatteryDaysEdit.Left := 280;
    BatteryDaysEdit.Top := 444;
    BatteryDaysEdit.Width := 69;
    BatteryDaysEdit.Height := 34;
    BatteryDaysEdit.ChildOrder := 1;
    BatteryDaysEdit.EditType := weNumeric;
    BatteryDaysEdit.ElementClassName := 'MyWebEdit';
    BatteryDaysEdit.Font.Charset := ANSI_CHARSET;
    BatteryDaysEdit.Font.Color := clWindowText;
    BatteryDaysEdit.Font.Height := -16;
    BatteryDaysEdit.Font.Name := 'Segoe UI';
    BatteryDaysEdit.Font.Style := [];
    BatteryDaysEdit.HeightPercent := 100.000000000000000000;
    BatteryDaysEdit.ParentFont := False;
    BatteryDaysEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryDaysEdit, Self, 'OnChange', 'RecordChange');
    BatteryReportEdit.SetParentComponent(TMSFNCPageControl1Page0);
    BatteryReportEdit.Name := 'BatteryReportEdit';
    BatteryReportEdit.Left := 468;
    BatteryReportEdit.Top := 446;
    BatteryReportEdit.Width := 221;
    BatteryReportEdit.Height := 26;
    BatteryReportEdit.Caption := 'Show low battery in reports';
    BatteryReportEdit.ChildOrder := 28;
    BatteryReportEdit.Font.Charset := ANSI_CHARSET;
    BatteryReportEdit.Font.Color := clWindowText;
    BatteryReportEdit.Font.Height := -16;
    BatteryReportEdit.Font.Name := 'Segoe UI';
    BatteryReportEdit.Font.Style := [];
    BatteryReportEdit.HeightPercent := 100.000000000000000000;
    BatteryReportEdit.ParentFont := False;
    BatteryReportEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryReportEdit, Self, 'OnClick', 'RecordChange');
    DeviceIOIgnitionEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DeviceIOIgnitionEdit.Name := 'DeviceIOIgnitionEdit';
    DeviceIOIgnitionEdit.AlignWithMargins := True;
    DeviceIOIgnitionEdit.Left := 460;
    DeviceIOIgnitionEdit.Top := 42;
    DeviceIOIgnitionEdit.Width := 337;
    DeviceIOIgnitionEdit.Height := 34;
    DeviceIOIgnitionEdit.Margins.Left := 20;
    DeviceIOIgnitionEdit.Margins.Top := 8;
    DeviceIOIgnitionEdit.Margins.Bottom := 8;
    DeviceIOIgnitionEdit.Color := clWhite;
    DeviceIOIgnitionEdit.ElementClassName := 'myselect_white';
    DeviceIOIgnitionEdit.Font.Charset := DEFAULT_CHARSET;
    DeviceIOIgnitionEdit.Font.Color := clWindowText;
    DeviceIOIgnitionEdit.Font.Height := 12;
    DeviceIOIgnitionEdit.Font.Name := 'Segoe UI';
    DeviceIOIgnitionEdit.Font.Style := [];
    DeviceIOIgnitionEdit.HeightPercent := 100.000000000000000000;
    DeviceIOIgnitionEdit.ParentFont := False;
    DeviceIOIgnitionEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceIOIgnitionEdit, Self, 'OnChange', 'RecordChange');
    DeviceIOIgnitionEdit.ItemIndex := -1;
    DeviceIOIgnitionEdit.LookupValues.Clear;
    with DeviceIOIgnitionEdit.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'Please select digital input';
    end;
    with DeviceIOIgnitionEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'Digital Input 1';
    end;
    with DeviceIOIgnitionEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Digital Input 2';
    end;
    with DeviceIOIgnitionEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'Digital Input 3';
    end;
    with DeviceIOIgnitionEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'Digital Input 4';
    end;
    with DeviceIOIgnitionEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'Digital Input 5';
    end;
    DigitalInputEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DigitalInputEdit.Name := 'DigitalInputEdit';
    DigitalInputEdit.Left := 566;
    DigitalInputEdit.Top := 119;
    DigitalInputEdit.Width := 39;
    DigitalInputEdit.Height := 34;
    DigitalInputEdit.ChildOrder := 1;
    DigitalInputEdit.EditType := weNumeric;
    DigitalInputEdit.ElementClassName := 'MyWebEdit';
    DigitalInputEdit.Font.Charset := ANSI_CHARSET;
    DigitalInputEdit.Font.Color := clWindowText;
    DigitalInputEdit.Font.Height := -16;
    DigitalInputEdit.Font.Name := 'Segoe UI';
    DigitalInputEdit.Font.Style := [];
    DigitalInputEdit.HeightPercent := 100.000000000000000000;
    DigitalInputEdit.ParentFont := False;
    DigitalInputEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DigitalInputEdit, Self, 'OnChange', 'RecordChange');
    AnalogInputEdit.SetParentComponent(TMSFNCPageControl1Page0);
    AnalogInputEdit.Name := 'AnalogInputEdit';
    AnalogInputEdit.Left := 566;
    AnalogInputEdit.Top := 200;
    AnalogInputEdit.Width := 39;
    AnalogInputEdit.Height := 34;
    AnalogInputEdit.ChildOrder := 1;
    AnalogInputEdit.EditType := weNumeric;
    AnalogInputEdit.ElementClassName := 'MyWebEdit';
    AnalogInputEdit.Font.Charset := ANSI_CHARSET;
    AnalogInputEdit.Font.Color := clWindowText;
    AnalogInputEdit.Font.Height := -16;
    AnalogInputEdit.Font.Name := 'Segoe UI';
    AnalogInputEdit.Font.Style := [];
    AnalogInputEdit.HeightPercent := 100.000000000000000000;
    AnalogInputEdit.ParentFont := False;
    AnalogInputEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AnalogInputEdit, Self, 'OnChange', 'RecordChange');
    DigitalOutputEdit.SetParentComponent(TMSFNCPageControl1Page0);
    DigitalOutputEdit.Name := 'DigitalOutputEdit';
    DigitalOutputEdit.Left := 758;
    DigitalOutputEdit.Top := 119;
    DigitalOutputEdit.Width := 39;
    DigitalOutputEdit.Height := 34;
    DigitalOutputEdit.ChildOrder := 1;
    DigitalOutputEdit.EditType := weNumeric;
    DigitalOutputEdit.ElementClassName := 'MyWebEdit';
    DigitalOutputEdit.Font.Charset := ANSI_CHARSET;
    DigitalOutputEdit.Font.Color := clWindowText;
    DigitalOutputEdit.Font.Height := -16;
    DigitalOutputEdit.Font.Name := 'Segoe UI';
    DigitalOutputEdit.Font.Style := [];
    DigitalOutputEdit.HeightPercent := 100.000000000000000000;
    DigitalOutputEdit.ParentFont := False;
    DigitalOutputEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DigitalOutputEdit, Self, 'OnChange', 'RecordChange');
    AnalogOutputEdit.SetParentComponent(TMSFNCPageControl1Page0);
    AnalogOutputEdit.Name := 'AnalogOutputEdit';
    AnalogOutputEdit.Left := 758;
    AnalogOutputEdit.Top := 200;
    AnalogOutputEdit.Width := 39;
    AnalogOutputEdit.Height := 34;
    AnalogOutputEdit.ChildOrder := 1;
    AnalogOutputEdit.EditType := weNumeric;
    AnalogOutputEdit.ElementClassName := 'MyWebEdit';
    AnalogOutputEdit.Font.Charset := ANSI_CHARSET;
    AnalogOutputEdit.Font.Color := clWindowText;
    AnalogOutputEdit.Font.Height := -16;
    AnalogOutputEdit.Font.Name := 'Segoe UI';
    AnalogOutputEdit.Font.Style := [];
    AnalogOutputEdit.HeightPercent := 100.000000000000000000;
    AnalogOutputEdit.ParentFont := False;
    AnalogOutputEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AnalogOutputEdit, Self, 'OnChange', 'RecordChange');
    CircleEdit.SetParentComponent(TMSFNCPageControl1Page0);
    CircleEdit.Name := 'CircleEdit';
    CircleEdit.Left := 28;
    CircleEdit.Top := 254;
    CircleEdit.Width := 769;
    CircleEdit.Height := 26;
    CircleEdit.Caption := 'Show movement indicator in navigation list';
    CircleEdit.ChildOrder := 28;
    CircleEdit.Font.Charset := ANSI_CHARSET;
    CircleEdit.Font.Color := clWindowText;
    CircleEdit.Font.Height := -16;
    CircleEdit.Font.Name := 'Segoe UI';
    CircleEdit.Font.Style := [];
    CircleEdit.HeightPercent := 100.000000000000000000;
    CircleEdit.ParentFont := False;
    CircleEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CircleEdit, Self, 'OnClick', 'RecordChange');
    TriangleEdit.SetParentComponent(TMSFNCPageControl1Page0);
    TriangleEdit.Name := 'TriangleEdit';
    TriangleEdit.Left := 28;
    TriangleEdit.Top := 294;
    TriangleEdit.Width := 397;
    TriangleEdit.Height := 26;
    TriangleEdit.Caption := 'Show problem indicator in navigation list';
    TriangleEdit.ChildOrder := 28;
    TriangleEdit.Font.Charset := ANSI_CHARSET;
    TriangleEdit.Font.Color := clWindowText;
    TriangleEdit.Font.Height := -16;
    TriangleEdit.Font.Name := 'Segoe UI';
    TriangleEdit.Font.Style := [];
    TriangleEdit.HeightPercent := 100.000000000000000000;
    TriangleEdit.ParentFont := False;
    TriangleEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TriangleEdit, Self, 'OnClick', 'RecordChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 612;
    StatusBarPanel.Width := 850;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 730;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uDeviceModelEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 615;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uDeviceModelEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 189;
    WebWaitMessage.Top := 58;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uDeviceModelEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 270;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    MyDBAdapter.SetParentComponent(Self);
    MyDBAdapter.Name := 'MyDBAdapter';
    MyDBAdapter.Left := 191;
    MyDBAdapter.Top := 112;
    MyDBAdapter.Width := 26;
    MyDBAdapter.Height := 26;
    MyDBAdapter.Margins.Left := 12;
    MyDBAdapter.Visible := True;
    MyDBAdapter.Grid := DocumentGrid;
    MyDBAdapter.DataSource := MyDataSource;
    MyDBAdapter.Columns.Clear;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'og_pk';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with MyDBAdapter.Columns.Add do
    begin
      FieldName := 'og_naam';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    MyDBAdapter.AutoCreateColumns := False;
    MyDBAdapter.AutoRemoveColumns := False;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 353;
    ShowTimer.Top := 58;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.Left := 272;
    MyDataset.Top := 114;
    MyDatasetog_pk.SetParentComponent(MyDataset);
    MyDatasetog_pk.Name := 'MyDatasetog_pk';
    MyDatasetog_pk.FieldName := 'og_pk';
    MyDatasetog_pk.Size := 256;
    MyDatasetog_naam.SetParentComponent(MyDataset);
    MyDatasetog_naam.Name := 'MyDatasetog_naam';
    MyDatasetog_naam.FieldName := 'og_naam';
    MyDatasetog_naam.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 352;
    MyDataSource.Top := 114;
  finally
    FormPanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    TMSFNCPageControl1.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    DocumentGrid.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    ModelBrandLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    ModelNameLabel.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    DeviceVersionEdit.AfterLoadDFMValues;
    DeviceModelEdit.AfterLoadDFMValues;
    DeviceBrandEdit.AfterLoadDFMValues;
    BatteryListEdit.AfterLoadDFMValues;
    BatteryAverageEdit.AfterLoadDFMValues;
    BatteryVoltageEdit.AfterLoadDFMValues;
    BatteryDaysEdit.AfterLoadDFMValues;
    BatteryReportEdit.AfterLoadDFMValues;
    DeviceIOIgnitionEdit.AfterLoadDFMValues;
    DigitalInputEdit.AfterLoadDFMValues;
    AnalogInputEdit.AfterLoadDFMValues;
    DigitalOutputEdit.AfterLoadDFMValues;
    AnalogOutputEdit.AfterLoadDFMValues;
    CircleEdit.AfterLoadDFMValues;
    TriangleEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    MyDBAdapter.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetog_pk.AfterLoadDFMValues;
    MyDatasetog_naam.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
  end;
end;

end.
unit uAssetList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLib.Clipboard,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel;

type
  TAssetListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MyGrid: TTMSFNCGrid;
    DatasetTimer: TTimer;
    ToolbarPanel: TPanel;
    FilterEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    MyConnection: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    MyDatasetPKey: TStringField;
    MyDatasetSymbol: TStringField;
    MyDatasetID: TStringField;
    MyDatasetStatus: TStringField;
    MyDatasetBrand: TStringField;
    MyDatasetModel: TStringField;
    MyDatasetGroup: TStringField;
    MyDatasetOwner: TStringField;
    MyDatasetTenant: TStringField;
    GridClipboardButton: TSpeedButton;
    ViewButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    ClearSearchEditButton: TSpeedButton;
    ClearFilterButton: TSpeedButton;
    SelectButton: TSpeedButton;
    MyDatasetLicensePlate: TStringField;
    MyDatasetYear: TStringField;
    MyDatasetMOT: TStringField;
    CellClipboardButton: TSpeedButton;
    FilterActiveEdit: TCheckBox;
    MyDatasetDriver: TStringField;
    MyDatasetType: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure DatasetTimerTimer(Sender: TObject); async;
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure FilterEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    procedure GridClipboardButtonClick(Sender: TObject);
    procedure MyGridColumnSorted(Sender: TObject; ACol: Integer;
      Direction: TTMSFNCGridSortDirection);
    procedure MyGridSelectedCell(Sender: TObject; ACol, ARow: Integer);
    procedure MyGridDblClick(Sender: TObject);
    procedure ClearSearchEditButtonClick(Sender: TObject);
    procedure ClearFilterButtonClick(Sender: TObject);
    procedure MyGridAfterApplyFilter(Sender: TObject; Col: Integer;
      Condition: string; var UpdateCalculations: Boolean);
    procedure SelectButtonClick(Sender: TObject);
    procedure CellClipboardButtonClick(Sender: TObject);
    procedure FilterActiveEditClick(Sender: TObject);
    procedure MyGridRawCompare(Sender: TObject; Col, Row1, Row2: Integer;
      var res: Integer);
    procedure MyGridCanSizeColumn(Sender: TObject; ACol: Integer;
      var Allow: Boolean);
    procedure MyGridCanSortColumn(Sender: TObject; ACol: Integer;
      var Allow: Boolean);
    procedure EditButtonClick(Sender: TObject);
  private
    FocusPKey: Int64;
    Permission: Integer;
    { Private declarations }
    [async]
    procedure LoadList(SearchValue: String); async;
    [async]
    procedure ShowEditDialog(PKey: Integer); async;
    [async]
    function CanDeleteRecord(PKey: Integer): Boolean; async;
    [async]
    procedure DeleteRecord(PKey: Integer); async;
  public
    Selection: String;
    procedure SetPermission(MyPermission: Integer);
  protected procedure LoadDFMValues; override; end;

var
  AssetListForm: TAssetListForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceEdit, uAssetEdit;

procedure TAssetListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  Selection := EmptyStr;
  FilterActiveEdit.Visible := SessionType = '2';
  SetGridStyle(MyGrid, False);
end;

procedure TAssetListForm.SetPermission(MyPermission: Integer);
begin
  Permission := MyPermission;
  AddButton.Visible := MyPermission in [2,3,6,7];
  ViewButton.Visible := MyPermission in [1,5];
  EditButton.Visible := MyPermission in [2,3,7];
  DeleteButton.Visible := MyPermission in [4,5,6,7];
end;

procedure TAssetListForm.WebFormShow(Sender: TObject);
begin
  FilterEdit.SetFocus();
  LoadList(EmptyStr);
end;

procedure TAssetListForm.ClearFilterButtonClick(Sender: TObject);
begin
  MyGrid.RemoveFilter;
  ClearFilterButton.Visible := False;
end;

procedure TAssetListForm.ClearSearchEditButtonClick(Sender: TObject);
begin
  FilterEdit.Text := EmptyStr;
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TAssetListForm.GridClipboardButtonClick(Sender: TObject);
begin
  GridToClipBoard(MyGrid, True);
end;

procedure TAssetListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TAssetListForm.DeleteButtonClick(Sender: TObject);
var
  StringList: TStringList;
  Result: Boolean;
  RowIdx: Integer;
  Value: String;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for RowIdx := 0 to MyGrid.RowSelectionCount-1 do begin
        if MyGrid.SelectedRow[RowIdx] > 0 then begin
          StringList.Add(IntToStr(MyGrid.SelectedRow[RowIdx]));
        end;
      end;
      if StringList.Count = 1 then begin
        Value := MyGrid.Cells[0, StrToInt(StringList[0])];
        Result := await(CanDeleteRecord(StrToInt(Value)));
        if Result then begin
          DeleteRecord(StrToInt(Value));
        end;
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TAssetListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowEditDialog(-1));
end;

procedure TAssetListForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  // Update Caption
  CaptionLabel.Caption :=
    'Asset - List (' + IntToStr(Dataset.RecordCount) + ')';
  // Selected Record
  if not(Dataset.IsEmpty) then begin
    if FocusPKey > 0 then begin
      if not(MyDataset.Locate('PKey', FocusPKey, [])) then begin
        FocusPKey := Dataset.FieldByName('PKey').AsInteger;
      end;
    end;
  end else begin
    FocusPKey := -1;
  end;
  // Link Grid
  MyGrid.OnSelectedCell := nil;
  GridDatabaseAdapter.Active := True;
  GridDatabaseAdapter.LoadAllDataAndDisconnect;
  if MyGrid.SortColumn > 0 then begin
    MyGrid.SortData(MyGrid.SortColumn, MyGrid.SortDirection);
  end;
  if MyGrid.Filter.Count > 0 then begin
    MyGrid.ApplyFilter;
  end;
  if FocusPKey > 0 then begin
     GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  end;
  MyGrid.OnSelectedCell := MyGridSelectedCell;
  DatasetTimer.Enabled := True;

//  if (FocusPKey = -1) then begin
//    MasterDataset.First;
//  end else begin
//    if not(MasterDataset.Locate('PKey', FocusPKey, [])) then FocusPKey := -1;
//  end;
//  MyGrid.OnSelectedCell := nil;
//  MyDBAdapter.Active := True;
//  MyDBAdapter.LoadAllDataAndDisconnect;
//  if MyGrid.SortColumn >= 0 then begin
//    MyGrid.SortData(MyGrid.SortColumn, MyGrid.SortDirection);
//  end;
//  if MyGrid.Filter.Count > 0 then begin
//    MyGrid.ApplyFilter;
//  end;
//  if FocusPKey > 0 then GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
//  MyGrid.OnSelectedCell := MyGridSelectedCell;
//  // Enable Timer
//  MasterTimer.Enabled := True;

end;

procedure TAssetListForm.MyGridAfterApplyFilter(Sender: TObject; Col: Integer;
  Condition: string; var UpdateCalculations: Boolean);
begin
  ClearFilterButton.Visible := MyGrid.Filter.Count > 0;
  MyGrid.ClearSelection;
  if ((MyGrid.VisibleRowCount > 1) and (FocusPKey <> -1)) then begin
    GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  end;
end;

procedure TAssetListForm.MyGridCanSizeColumn(Sender: TObject; ACol: Integer;
  var Allow: Boolean);
begin
  if ACol = 0 then Allow := False;
end;

procedure TAssetListForm.MyGridCanSortColumn(Sender: TObject; ACol: Integer;
  var Allow: Boolean);
begin
  Allow := True;
  if ((MyGrid.VisibleRowCount > 0) and (MyGrid.GetSelectedRowCount > 0)) then begin
    FocusPKey := StrToInt(Trim(MyGrid.Cells[0, MyGrid.SelectedRow[0]]));
  end else begin
    FocusPKey := -1;
  end;
end;

procedure TAssetListForm.MyGridColumnSorted(Sender: TObject; ACol: Integer;
  Direction: TTMSFNCGridSortDirection);
begin
  MyGrid.ClearSelection;
  if ((MyGrid.VisibleRowCount > 0) and (FocusPKey <> -1)) then begin
    GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
  end;
//GridSelectRow(MyGrid, 0, IntToStr(FocusPKey));
end;

procedure TAssetListForm.MyGridDblClick(Sender: TObject);
begin
  EditButton.Click();
end;

procedure TAssetListForm.MyGridRawCompare(Sender: TObject; Col, Row1,
  Row2: Integer; var res: Integer);
var
  s1, s2: String;
  c1, c2: Integer;
begin
  if Col = 1 then begin
    s1 := EmptyStrToZero(Trim(TTMSFNCGrid(Sender).Cells[Col, Row1]));
    s2 := EmptyStrToZero(Trim(TTMSFNCGrid(Sender).Cells[Col, Row2]));
    if s1 = s2 then begin
      res := 0;
    end else begin
      if TryStrToInt(s1, c1) and TryStrToInt(s2, c2) then begin
        if (c1 > c2) then begin
          Res := 1
        end else begin
          Res := -1;
        end;
      end else begin
        Res := 0;
      end;
    end;
  end;
end;

procedure TAssetListForm.MyGridSelectedCell(Sender: TObject; ACol,
  ARow: Integer);
begin
  if ((MyGrid.VisibleRowCount > 0) and (ARow > 0)) then begin
    FocusPKey := StrToInt(Trim(MyGrid.Cells[0, ARow]));
  end;
//FocusPKey := StrToInt(Trim(MyGrid.Cells[0, ARow]));
end;

procedure TAssetListForm.FilterActiveEditClick(Sender: TObject);
begin
  LoadList(FilterEdit.Text);
end;

procedure TAssetListForm.FilterEditChange(Sender: TObject);
begin
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TAssetListForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  WebWaitMessage.Hide;
end;

procedure TAssetListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    if FilterActiveEdit.Checked then begin
      MyWebRequest.URL := MyWebRequest.URL + '&active=0';
    end else begin
      MyWebRequest.URL := MyWebRequest.URL + '&active=1';
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TAssetListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadList(FilterEdit.Text);
  end;
end;

procedure TAssetListForm.SelectButtonClick(Sender: TObject);
var
  StringList: TStringList;
  I, RowIdx: Integer;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for I := 0 to MyGrid.RowSelectionCount-1 do begin
        RowIdx := MyGrid.SelectedRow[I];
        StringList.Add(MyGrid.Cells[0, RowIdx]);
      end;
      Selection := StringList.DelimitedText;
    finally
      StringList.Free;
    end;
    ModalResult := mrOK;
  end;
end;

procedure TAssetListForm.ShowEditDialog(PKey: Integer);
var
  MyWebForm: TAssetEditForm;
  MyModalResult: TModalResult;
begin
  MyWebForm := TAssetEditForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  await(TAssetEditForm, MyWebForm.Load());
  try
    MyWebForm.PKey := PKey;
    if PKey >= 0 then begin
      if GetUserPermissionValue(Permissions, 'assetdevice') in [2,3,5,6,7] then begin
        MyWebForm.SelectDeviceButton.Enabled := True;
      end;
      if GetUserPermissionValue(Permissions, 'assetdevice') in [4,5,6,7] then begin
        MyWebForm.ClearDeviceButton.Enabled := True;
      end;
      if GetUserPermissionValue(Permissions, 'assetdriver') in [2,3,5,6,7] then begin
        MyWebForm.SelectDriverButton.Enabled := True;
      end;
      if GetUserPermissionValue(Permissions, 'assetdriver') in [4,5,6,7] then begin
        MyWebForm.ClearDriverButton.Enabled := True;
      end;
    end;
    // Permission
    if Permission in [1,5] then begin
      MyWebForm.SetReadOnly;
    end;
    MyWebForm.IsSet := True;
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyWebForm.PKey > 0 then begin
      FocusPKey := MyWebForm.PKey;
      LoadList(FilterEdit.Text);
    end;
  finally
    MyWebForm.Free;
  end;
end;

function TAssetListForm.CanDeleteRecord(PKey: Integer): Boolean;
var
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
  MyMessage: String;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_can_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        // Build Message
        if (MyJO.GetJSONValue('DevicePKey') = '0') and (MyJO.GetJSONValue('DriverPKey') = '0') then begin
          // No Links
          MyMessage := 'Delete asset?';
        end else begin
          if (MyJO.GetJSONValue('DevicePKey') <> '0') and (MyJO.GetJSONValue('DriverPKey') <> '0') then begin
            MyMessage :=
              'The asset is linked to device "' + MyJO.GetJSONValue('DevicePKey') + '" ' +
              'and driver "' + MyJO.GetJSONValue('DriverName') + '". ' +
              'These links will be removed. Delete asset?';
          end else begin
            if (MyJO.GetJSONValue('DevicePKey') <> '0') then begin
              MyMessage :=
                'The asset is linked to device "' + MyJO.GetJSONValue('DevicePKey') + '". ' +
                'This link will be removed. Delete asset?';
            end;
            if (MyJO.GetJSONValue('DriverPKey') <> '0') then begin
              MyMessage :=
                'The asset is linked to driver "' + MyJO.GetJSONValue('DriverName') + '". ' +
                'This linkswill be removed. Delete asset?';
            end;
          end;
        end;
        MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog(MyMessage, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
        Result := MyModalResult = mrYes;
      end else begin
        Result := False;
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetListForm.CellClipboardButtonClick(Sender: TObject);
begin
  //
end;

procedure TAssetListForm.DeleteRecord(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_asset_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        FocusPKey := -1;
        SearchTimer.Enabled := True;
      end else begin
        MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetListForm.EditButtonClick(Sender: TObject);
var
  StringList: TStringList;
  RowIdx: Integer;
  Value: String;
begin
  if MyGrid.RowSelectionCount > 0 then begin
    StringList := TStringList.Create();
    try
      for RowIdx := 0 to MyGrid.RowSelectionCount-1 do begin
        if MyGrid.SelectedRow[RowIdx] > 0 then begin
          StringList.Add(IntToStr(MyGrid.SelectedRow[RowIdx]));
        end;
      end;
      if StringList.Count = 1 then begin
        Value := MyGrid.Cells[0, StrToInt(StringList[0])];
        ShowEditDialog(StrToInt(Value));
      end;
    finally
      StringList.Free;
    end;
  end;
end;

procedure TAssetListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  MyGrid := TTMSFNCGrid.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  FilterEdit := TEdit.Create(Self);
  ClearSearchEditButton := TSpeedButton.Create(Self);
  ClearFilterButton := TSpeedButton.Create(Self);
  FilterActiveEdit := TCheckBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  GridClipboardButton := TSpeedButton.Create(Self);
  ViewButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  CellClipboardButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetPKey := TStringField.Create(Self);
  MyDatasetSymbol := TStringField.Create(Self);
  MyDatasetID := TStringField.Create(Self);
  MyDatasetStatus := TStringField.Create(Self);
  MyDatasetBrand := TStringField.Create(Self);
  MyDatasetModel := TStringField.Create(Self);
  MyDatasetGroup := TStringField.Create(Self);
  MyDatasetOwner := TStringField.Create(Self);
  MyDatasetTenant := TStringField.Create(Self);
  MyDatasetLicensePlate := TStringField.Create(Self);
  MyDatasetYear := TStringField.Create(Self);
  MyDatasetMOT := TStringField.Create(Self);
  MyDatasetDriver := TStringField.Create(Self);
  MyDatasetType := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  MyConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  MyGrid.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  FilterEdit.BeforeLoadDFMValues;
  ClearSearchEditButton.BeforeLoadDFMValues;
  ClearFilterButton.BeforeLoadDFMValues;
  FilterActiveEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  GridClipboardButton.BeforeLoadDFMValues;
  ViewButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  CellClipboardButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetPKey.BeforeLoadDFMValues;
  MyDatasetSymbol.BeforeLoadDFMValues;
  MyDatasetID.BeforeLoadDFMValues;
  MyDatasetStatus.BeforeLoadDFMValues;
  MyDatasetBrand.BeforeLoadDFMValues;
  MyDatasetModel.BeforeLoadDFMValues;
  MyDatasetGroup.BeforeLoadDFMValues;
  MyDatasetOwner.BeforeLoadDFMValues;
  MyDatasetTenant.BeforeLoadDFMValues;
  MyDatasetLicensePlate.BeforeLoadDFMValues;
  MyDatasetYear.BeforeLoadDFMValues;
  MyDatasetMOT.BeforeLoadDFMValues;
  MyDatasetDriver.BeforeLoadDFMValues;
  MyDatasetType.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  MyConnection.BeforeLoadDFMValues;
  try
    Name := 'AssetListForm';
    Width := 1500;
    Height := 700;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 1500;
    FormPanel.Height := 700;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 1494;
    ContentPanel.Height := 657;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    MyGrid.SetParentComponent(ContentPanel);
    MyGrid.Name := 'MyGrid';
    MyGrid.AlignWithMargins := True;
    MyGrid.Left := 20;
    MyGrid.Top := 54;
    MyGrid.Width := 1454;
    MyGrid.Height := 544;
    MyGrid.Margins.Left := 20;
    MyGrid.Margins.Top := 0;
    MyGrid.Margins.Right := 20;
    MyGrid.Margins.Bottom := 10;
    MyGrid.AdaptToStyle := True;
    MyGrid.Align := alClient;
    MyGrid.BevelInner := bvNone;
    MyGrid.BevelOuter := bvNone;
    MyGrid.BevelWidth := 2;
    MyGrid.Ctl3D := False;
    MyGrid.ParentCtl3D := False;
    MyGrid.ParentDoubleBuffered := False;
    MyGrid.DoubleBuffered := True;
    MyGrid.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Font.Color := clWindowText;
    MyGrid.Font.Height := -11;
    MyGrid.Font.Name := 'Tahoma';
    MyGrid.Font.Style := [];
    MyGrid.ParentFont := False;
    MyGrid.TabOrder := 1;
    SetEvent(MyGrid, Self, 'OnDblClick', 'MyGridDblClick');
    MyGrid.DefaultRowHeight := 34.000000000000000000;
    MyGrid.FixedColumns := 0;
    MyGrid.ColumnCount := 14;
    MyGrid.RowCount := 1;
    SetEvent(MyGrid, Self, 'OnRawCompare', 'MyGridRawCompare');
    SetEvent(MyGrid, Self, 'OnAfterApplyFilter', 'MyGridAfterApplyFilter');
    MyGrid.Options.Borders.FixedCellBorders := bNone;
    MyGrid.Options.ColumnSize.Stretch := True;
    MyGrid.Options.ColumnSize.StretchColumn := 9;
    MyGrid.Options.Editing.CalcFormat := '%g';
    MyGrid.Options.Editing.Enabled := False;
    MyGrid.Options.Filtering.Rows := frAll;
    MyGrid.Options.Filtering.DropDown := True;
    MyGrid.Options.Filtering.DropDownWidth := 240;
    MyGrid.Options.Filtering.DropDownHeight := 240;
    MyGrid.Options.Filtering.MultiColumn := True;
    MyGrid.Options.Grouping.CalcFormat := '%g';
    MyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MyGrid.Options.HTMLExport.CellPadding := 10;
    MyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MyGrid.Options.Mouse.ColumnSizing := True;
    MyGrid.Options.Mouse.ClickMargin := 0;
    MyGrid.Options.Mouse.ColumnSizeMargin := 6;
    MyGrid.Options.Mouse.RowSizeMargin := 6;
    MyGrid.Options.Sorting.IgnoreCase := True;
    MyGrid.Options.Sorting.IgnoreBlanks := True;
    MyGrid.Options.Sorting.Mode := gsmNormal;
    MyGrid.Options.URL.Color := clSkyBlue;
    MyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MyGrid.Options.Selection.Mode := smDisjunctRow;
    MyGrid.Adapter := GridDatabaseAdapter;
    MyGrid.HorizontalScrollBarVisible := False;
    MyGrid.Columns.Clear;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      SortFormat := ssRaw;
      Width := 80.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 150.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 150.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 150.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 200.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 160.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    with MyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 100.000000000000000000;
    end;
    MyGrid.Fill.ColorTo := clSilver;
    MyGrid.DefaultFont.Charset := ANSI_CHARSET;
    MyGrid.DefaultFont.Color := clWindowText;
    MyGrid.DefaultFont.Height := -15;
    MyGrid.DefaultFont.Name := 'Segoe UI';
    MyGrid.DefaultFont.Style := [];
    MyGrid.Stroke.Color := 15987699;
    MyGrid.Stroke.Width := 2.000000000000000000;
    MyGrid.TopRow := 1;
    MyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FixedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedLayout.Font.Style := [];
    MyGrid.Appearance.NormalLayout.Fill.Color := -1;
    MyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.NormalLayout.Font.Color := clGray;
    MyGrid.Appearance.NormalLayout.Font.Height := -11;
    MyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.NormalLayout.Font.Style := [];
    MyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MyGrid.Appearance.GroupLayout.Font.Height := -11;
    MyGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.GroupLayout.Font.Style := [];
    MyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MyGrid.Appearance.SummaryLayout.Font.Height := -11;
    MyGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MyGrid.Appearance.SummaryLayout.Font.Style := [];
    MyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MyGrid.Appearance.SelectedLayout.Font.Height := -16;
    MyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.SelectedLayout.Font.Style := [];
    MyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MyGrid.Appearance.FocusedLayout.Font.Height := -16;
    MyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FocusedLayout.Font.Style := [];
    MyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MyGrid.Appearance.BandLayout.Font.Color := clBlack;
    MyGrid.Appearance.BandLayout.Font.Height := -16;
    MyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MyGrid.Appearance.BandLayout.Font.Style := [];
    MyGrid.Appearance.ProgressLayout.Color := 15385233;
    MyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MyGrid.LeftCol := 1;
    MyGrid.ScrollMode := scmItemScrolling;
    MyGrid.GlobalFont.Scale := 1.000000000000000000;
    MyGrid.GlobalFont.Style := [];
    MyGrid.DesignTimeSampleData := True;
    SetEvent(MyGrid, Self, 'OnCanSizeColumn', 'MyGridCanSizeColumn');
    SetEvent(MyGrid, Self, 'OnColumnSorted', 'MyGridColumnSorted');
    SetEvent(MyGrid, Self, 'OnCanSortColumn', 'MyGridCanSortColumn');
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 447;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Active := True;
    GridDatabaseAdapter.Grid := MyGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Symbol';
      Header := '  No.';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  Asset ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'License Plate';
      Header := '  Reg. Plate';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Brand';
      Header := '  Brand';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Model';
      Header := '  Model';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Status';
      Header := '  Status';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Group';
      Header := '  Asset Group';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Type';
      Header := '  Type';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Driver';
      Header := '  Driver';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Owner';
      Header := '  Owner';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Tenant';
      Header := '  Tenant';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Year';
      Header := '  Year';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'MOT';
      Header := '  MOT';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1494;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    FilterEdit.SetParentComponent(ToolbarPanel);
    FilterEdit.Name := 'FilterEdit';
    FilterEdit.Left := 20;
    FilterEdit.Top := 10;
    FilterEdit.Width := 345;
    FilterEdit.Height := 34;
    FilterEdit.AutoFocus := True;
    FilterEdit.ChildOrder := 1;
    FilterEdit.Font.Charset := ANSI_CHARSET;
    FilterEdit.Font.Color := clWindowText;
    FilterEdit.Font.Height := -16;
    FilterEdit.Font.Name := 'Segoe UI';
    FilterEdit.Font.Style := [];
    FilterEdit.HeightPercent := 100.000000000000000000;
    FilterEdit.ParentFont := False;
    FilterEdit.TextHint := 'No. / Asset ID / Reg. Plate';
    FilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterEdit, Self, 'OnChange', 'FilterEditChange');
    ClearSearchEditButton.SetParentComponent(ToolbarPanel);
    ClearSearchEditButton.Name := 'ClearSearchEditButton';
    ClearSearchEditButton.AlignWithMargins := True;
    ClearSearchEditButton.Left := 332;
    ClearSearchEditButton.Top := 12;
    ClearSearchEditButton.Width := 30;
    ClearSearchEditButton.Height := 30;
    ClearSearchEditButton.Margins.Left := 10;
    ClearSearchEditButton.Margins.Top := 8;
    ClearSearchEditButton.Margins.Right := 0;
    ClearSearchEditButton.Margins.Bottom := 8;
    ClearSearchEditButton.Color := clNone;
    ClearSearchEditButton.ElementClassName := 'MyClearButton';
    ClearSearchEditButton.Glyph.LoadFromFile('uAssetList.ToolbarPanel.ClearSearchEditButton.Glyph.png');
    ClearSearchEditButton.HeightPercent := 100.000000000000000000;
    ClearSearchEditButton.TabOrder := 1;
    ClearSearchEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearSearchEditButton, Self, 'OnClick', 'ClearSearchEditButtonClick');
    ClearFilterButton.SetParentComponent(ToolbarPanel);
    ClearFilterButton.Name := 'ClearFilterButton';
    ClearFilterButton.AlignWithMargins := True;
    ClearFilterButton.Left := 555;
    ClearFilterButton.Top := 8;
    ClearFilterButton.Width := 125;
    ClearFilterButton.Height := 36;
    ClearFilterButton.Margins.Left := 20;
    ClearFilterButton.Margins.Top := 8;
    ClearFilterButton.Margins.Right := 5;
    ClearFilterButton.Margins.Bottom := 10;
    ClearFilterButton.Caption := 'Clear Filters';
    ClearFilterButton.Color := clNone;
    ClearFilterButton.ElementClassName := 'MyWebButton';
    ClearFilterButton.Glyph.LoadFromFile('uAssetList.ToolbarPanel.ClearFilterButton.Glyph.png');
    ClearFilterButton.HeightPercent := 100.000000000000000000;
    ClearFilterButton.TabOrder := 2;
    ClearFilterButton.Visible := False;
    ClearFilterButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearFilterButton, Self, 'OnClick', 'ClearFilterButtonClick');
    FilterActiveEdit.SetParentComponent(ToolbarPanel);
    FilterActiveEdit.Name := 'FilterActiveEdit';
    FilterActiveEdit.Left := 384;
    FilterActiveEdit.Top := 16;
    FilterActiveEdit.Width := 153;
    FilterActiveEdit.Height := 22;
    FilterActiveEdit.Caption := 'Show inactive only';
    FilterActiveEdit.ChildOrder := 1;
    FilterActiveEdit.HeightPercent := 100.000000000000000000;
    FilterActiveEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterActiveEdit, Self, 'OnClick', 'FilterActiveEditClick');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 608;
    StatusBarPanel.Width := 1494;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 1374;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 361;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 145;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 469;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    GridClipboardButton.SetParentComponent(StatusBarPanel);
    GridClipboardButton.Name := 'GridClipboardButton';
    GridClipboardButton.AlignWithMargins := True;
    GridClipboardButton.Left := 577;
    GridClipboardButton.Top := 8;
    GridClipboardButton.Width := 128;
    GridClipboardButton.Height := 31;
    GridClipboardButton.Margins.Left := 0;
    GridClipboardButton.Margins.Top := 8;
    GridClipboardButton.Margins.Right := 8;
    GridClipboardButton.Margins.Bottom := 10;
    GridClipboardButton.Align := alLeft;
    GridClipboardButton.Caption := 'Copy Rows';
    GridClipboardButton.Color := clNone;
    GridClipboardButton.ElementClassName := 'MyWebButton';
    GridClipboardButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.GridClipboardButton.Glyph.png');
    GridClipboardButton.HeightPercent := 100.000000000000000000;
    GridClipboardButton.TabOrder := 4;
    GridClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(GridClipboardButton, Self, 'OnClick', 'GridClipboardButtonClick');
    ViewButton.SetParentComponent(StatusBarPanel);
    ViewButton.Name := 'ViewButton';
    ViewButton.AlignWithMargins := True;
    ViewButton.Left := 253;
    ViewButton.Top := 8;
    ViewButton.Width := 100;
    ViewButton.Height := 31;
    ViewButton.Margins.Left := 0;
    ViewButton.Margins.Top := 8;
    ViewButton.Margins.Right := 8;
    ViewButton.Margins.Bottom := 10;
    ViewButton.Align := alLeft;
    ViewButton.Caption := 'View';
    ViewButton.Color := clNone;
    ViewButton.ElementClassName := 'MyWebButton';
    ViewButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.ViewButton.Glyph.png');
    ViewButton.HeightPercent := 100.000000000000000000;
    ViewButton.TabOrder := 5;
    ViewButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewButton, Self, 'OnClick', 'EditButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 128;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 6;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 20;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 5;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 7;
    SelectButton.Visible := False;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    CellClipboardButton.SetParentComponent(StatusBarPanel);
    CellClipboardButton.Name := 'CellClipboardButton';
    CellClipboardButton.AlignWithMargins := True;
    CellClipboardButton.Left := 713;
    CellClipboardButton.Top := 8;
    CellClipboardButton.Width := 128;
    CellClipboardButton.Height := 31;
    CellClipboardButton.Margins.Left := 0;
    CellClipboardButton.Margins.Top := 8;
    CellClipboardButton.Margins.Right := 8;
    CellClipboardButton.Margins.Bottom := 10;
    CellClipboardButton.Align := alLeft;
    CellClipboardButton.Caption := 'Copy Cell';
    CellClipboardButton.Color := clNone;
    CellClipboardButton.ElementClassName := 'MyWebButton';
    CellClipboardButton.Glyph.LoadFromFile('uAssetList.StatusBarPanel.CellClipboardButton.Glyph.png');
    CellClipboardButton.HeightPercent := 100.000000000000000000;
    CellClipboardButton.TabOrder := 8;
    CellClipboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CellClipboardButton, Self, 'OnClick', 'CellClipboardButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 1500;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 78;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset - List';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 1471;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAssetList.WebWaitMessage.Picture.gif');
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 51;
    WebMessageDlg1.Top := 489;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.Active := True;
    MyDataset.Connection := MyConnection;
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 488;
    MyDatasetPKey.SetParentComponent(MyDataset);
    MyDatasetPKey.Name := 'MyDatasetPKey';
    MyDatasetPKey.FieldName := 'PKey';
    MyDatasetPKey.Visible := False;
    MyDatasetPKey.Size := 256;
    MyDatasetSymbol.SetParentComponent(MyDataset);
    MyDatasetSymbol.Name := 'MyDatasetSymbol';
    MyDatasetSymbol.FieldName := 'Symbol';
    MyDatasetSymbol.Size := 256;
    MyDatasetID.SetParentComponent(MyDataset);
    MyDatasetID.Name := 'MyDatasetID';
    MyDatasetID.FieldName := 'ID';
    MyDatasetID.Size := 256;
    MyDatasetStatus.SetParentComponent(MyDataset);
    MyDatasetStatus.Name := 'MyDatasetStatus';
    MyDatasetStatus.FieldName := 'Status';
    MyDatasetStatus.Size := 256;
    MyDatasetBrand.SetParentComponent(MyDataset);
    MyDatasetBrand.Name := 'MyDatasetBrand';
    MyDatasetBrand.FieldName := 'Brand';
    MyDatasetBrand.Size := 256;
    MyDatasetModel.SetParentComponent(MyDataset);
    MyDatasetModel.Name := 'MyDatasetModel';
    MyDatasetModel.FieldName := 'Model';
    MyDatasetModel.Size := 256;
    MyDatasetGroup.SetParentComponent(MyDataset);
    MyDatasetGroup.Name := 'MyDatasetGroup';
    MyDatasetGroup.FieldName := 'Group';
    MyDatasetGroup.Size := 256;
    MyDatasetOwner.SetParentComponent(MyDataset);
    MyDatasetOwner.Name := 'MyDatasetOwner';
    MyDatasetOwner.FieldName := 'Owner';
    MyDatasetOwner.Size := 256;
    MyDatasetTenant.SetParentComponent(MyDataset);
    MyDatasetTenant.Name := 'MyDatasetTenant';
    MyDatasetTenant.FieldName := 'Tenant';
    MyDatasetTenant.Size := 256;
    MyDatasetLicensePlate.SetParentComponent(MyDataset);
    MyDatasetLicensePlate.Name := 'MyDatasetLicensePlate';
    MyDatasetLicensePlate.FieldName := 'License Plate';
    MyDatasetLicensePlate.Size := 256;
    MyDatasetYear.SetParentComponent(MyDataset);
    MyDatasetYear.Name := 'MyDatasetYear';
    MyDatasetYear.FieldName := 'Year';
    MyDatasetYear.Size := 256;
    MyDatasetMOT.SetParentComponent(MyDataset);
    MyDatasetMOT.Name := 'MyDatasetMOT';
    MyDatasetMOT.FieldName := 'MOT';
    MyDatasetMOT.Size := 256;
    MyDatasetDriver.SetParentComponent(MyDataset);
    MyDatasetDriver.Name := 'MyDatasetDriver';
    MyDatasetDriver.FieldName := 'Driver';
    MyDatasetDriver.Size := 256;
    MyDatasetType.SetParentComponent(MyDataset);
    MyDatasetType.Name := 'MyDatasetType';
    MyDatasetType.FieldName := 'Type';
    MyDatasetType.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 484;
    MyDataSource.Top := 488;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 283;
    DatasetTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    MyConnection.SetParentComponent(Self);
    MyConnection.Name := 'MyConnection';
    MyConnection.Active := False;
    MyConnection.Delimiter := ';';
    MyConnection.URI := 'https://gamma.instatrack.eu/php/default_asset_list.php?sessionid=0b20c4017536001f47334ee6708b2d0c';
    MyConnection.Left := 191;
    MyConnection.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    MyGrid.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    FilterEdit.AfterLoadDFMValues;
    ClearSearchEditButton.AfterLoadDFMValues;
    ClearFilterButton.AfterLoadDFMValues;
    FilterActiveEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    GridClipboardButton.AfterLoadDFMValues;
    ViewButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    CellClipboardButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetPKey.AfterLoadDFMValues;
    MyDatasetSymbol.AfterLoadDFMValues;
    MyDatasetID.AfterLoadDFMValues;
    MyDatasetStatus.AfterLoadDFMValues;
    MyDatasetBrand.AfterLoadDFMValues;
    MyDatasetModel.AfterLoadDFMValues;
    MyDatasetGroup.AfterLoadDFMValues;
    MyDatasetOwner.AfterLoadDFMValues;
    MyDatasetTenant.AfterLoadDFMValues;
    MyDatasetLicensePlate.AfterLoadDFMValues;
    MyDatasetYear.AfterLoadDFMValues;
    MyDatasetMOT.AfterLoadDFMValues;
    MyDatasetDriver.AfterLoadDFMValues;
    MyDatasetType.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    MyConnection.AfterLoadDFMValues;
  end;
end;

end.

unit uAssetDocumentEdit;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.WebCtrls, VCL.TMSFNCTypes,
  WEBLIB.REST, WEBLIB.JSON,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, WEBLib.Buttons;

type
  TAssetDocumentEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    UsernameLabel: TLabel;
    DocumentTypeEdit: TLookupComboBox;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    WebLabel2: TLabel;
    DocumentIDEdit: TEdit;
    ExpiryDateEdit: TDateTimePicker;
    WebLabel3: TLabel;
    MyMessageDlg: TMessageDlg;
    FileNameEdit: TEdit;
    OpenFileButton: TSpeedButton;
    MyOpenFileDialog: TOpenDialog;
    FileNameLabel: TLabel;
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure WebFormCreate(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    procedure OpenFileButtonClick(Sender: TObject);
    procedure MyOpenFileDialogChange(Sender: TObject);
    procedure MyOpenFileDialogGetFileAsBase64(Sender: TObject;
      AFileIndex: Integer; ABase64: string);
    procedure DocumentTypeEditChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    FileBase64: String;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadDocumentTypeList(S: String);
    [async]
    procedure LoadData(); async;
    [async]
    procedure SaveData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    AssetPKey: Int64;
  protected procedure LoadDFMValues; override; end;

var
  AssetDocumentEditForm: TAssetDocumentEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetDocumentEditForm.WebFormCreate(Sender: TObject);
begin
  FileBase64 := EmptyStr;
  FileBase64 := '';
end;

procedure TAssetDocumentEditForm.WebFormShow(Sender: TObject);
begin
  // Load Lookup Values
  await(LoadLookupValues());
  // Load Data
  if PKey >= 0 then begin
    await(LoadData());
  end else begin
    ExpiryDateEdit.Date := IncYear(Date(), 1);
  end;
  RecordChanged := False;
end;

procedure TAssetDocumentEditForm.LoadLookupValues;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_document_type.php' +
      '?sessionid=' + SessionId +
      '&assetpkey=' + IntToStr(AssetPKey) +
      '&documentpkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadDocumentTypeList(TJSONPair(MyJSONObject.Get('document_type')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TAssetDocumentEditForm.MyOpenFileDialogChange(Sender: TObject);
begin
  RecordChanged := True;
  FileNameEdit.Text := MyOpenFileDialog.Files[0].Name;
  MyOpenFileDialog.Files[0].GetFileAsBase64;
end;

procedure TAssetDocumentEditForm.MyOpenFileDialogGetFileAsBase64(
  Sender: TObject; AFileIndex: Integer; ABase64: string);
begin
  FileBase64 := ABase64;  //
end;

procedure TAssetDocumentEditForm.OpenFileButtonClick(Sender: TObject);
begin
  MyOpenFileDialog.Execute;
end;

procedure TAssetDocumentEditForm.DocumentTypeEditChange(Sender: TObject);
begin
  RecordChanged := True;
  if not(DocumentTypeEdit.Value = '0') then begin
    if DocumentIDEdit.Text = EmptyStr then begin
      DocumentIDEdit.Text := FormatDateTime('dd-mm-yyyy', Date());
    end;
  end;
end;

procedure TAssetDocumentEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_object_document_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      DocumentTypeEdit.Value := MyJSONObject.GetJSONValue('Type');
      if DocumentTypeEdit.Value = '0' then begin
        DocumentIDEdit.Text := MyJSONObject.GetJSONValue('ID');
        ExpiryDateEdit.Date := StrToDate(MyJSONObject.GetJSONValue('Expiry Date'));
        FileNameEdit.Text := MyJSONObject.GetJSONValue('FileName');
      end else begin
        DocumentIDEdit.Text := FormatDateTime('dd-mm-yyyy', Date());
        ExpiryDateEdit.Date := IncYear(Date(), 1);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetDocumentEditForm.LoadDocumentTypeList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  DocumentTypeEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      DocumentTypeEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('pkey'),
        MyJSONObject.GetJSONValue('id')
      );
    end;
    // Set Value
    DocumentTypeEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetDocumentEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetDocumentEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

function TAssetDocumentEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  if DocumentTypeEdit.Value = '0' then begin
    if Result and (Trim(DocumentIDEdit.Text) = EmptyStr) then begin
      Result := False;
      ErrorMessage := 'ID is a required field.';
    end;
  end;
  if Result and (FileBase64 = EmptyStr) then begin
    if (PKey = -1) or (DocumentTypeEdit.Value <> '0') then begin
      Result := False;
      ErrorMessage := 'No document to upload.';
    end;
  end;
end;

procedure TAssetDocumentEditForm.SaveButtonClick(Sender: TObject);
var
  ErrorMessage: String;
begin
  if await(ValidInput(ErrorMessage)) then begin
    SaveData();
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TAssetDocumentEditForm.SaveData();
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"asset_pkey": "' + IntToStr(AssetPKey) + '",' +
      '"id": "' + DocumentIDEdit.Text + '",' +
      '"type": "' + DocumentTypeEdit.Value + '",' +
      '"expirydate": "' + FormatDateTime('yyyy-mm-dd', ExpiryDateEdit.Date) + '",' +
      '"filename": "' + FileNameEdit.Text + '",' +
      '"filetype": "' + '0' + '",' +
      '"file": "' + FileBase64 + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_document_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        ModalResult := mrOK;
      end else begin
        MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetDocumentEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  FileNameLabel := TLabel.Create(Self);
  DocumentTypeEdit := TLookupComboBox.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  OpenFileButton := TSpeedButton.Create(Self);
  DocumentIDEdit := TEdit.Create(Self);
  ExpiryDateEdit := TDateTimePicker.Create(Self);
  FileNameEdit := TEdit.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  MyOpenFileDialog := TOpenDialog.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  FileNameLabel.BeforeLoadDFMValues;
  DocumentTypeEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  OpenFileButton.BeforeLoadDFMValues;
  DocumentIDEdit.BeforeLoadDFMValues;
  ExpiryDateEdit.BeforeLoadDFMValues;
  FileNameEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  MyOpenFileDialog.BeforeLoadDFMValues;
  try
    Name := 'AssetDocumentEditForm';
    Width := 467;
    Height := 441;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 467;
    FormPanel.Height := 441;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 461;
    ContentPanel.Height := 398;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    UsernameLabel.SetParentComponent(ContentPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 32;
    UsernameLabel.Top := 16;
    UsernameLabel.Width := 34;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'TYPE';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 32;
    WebLabel2.Top := 101;
    WebLabel2.Width := 15;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'ID';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(ContentPanel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 32;
    WebLabel3.Top := 177;
    WebLabel3.Width := 78;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Expiry Date';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    FileNameLabel.SetParentComponent(ContentPanel);
    FileNameLabel.Name := 'FileNameLabel';
    FileNameLabel.Left := 31;
    FileNameLabel.Top := 262;
    FileNameLabel.Width := 28;
    FileNameLabel.Height := 21;
    FileNameLabel.Caption := 'FILE';
    FileNameLabel.Font.Charset := ANSI_CHARSET;
    FileNameLabel.Font.Color := 9010544;
    FileNameLabel.Font.Height := -16;
    FileNameLabel.Font.Name := 'Segoe UI';
    FileNameLabel.Font.Style := [];
    FileNameLabel.HeightPercent := 100.000000000000000000;
    FileNameLabel.ParentFont := False;
    FileNameLabel.WidthPercent := 100.000000000000000000;
    DocumentTypeEdit.SetParentComponent(ContentPanel);
    DocumentTypeEdit.Name := 'DocumentTypeEdit';
    DocumentTypeEdit.AlignWithMargins := True;
    DocumentTypeEdit.Left := 32;
    DocumentTypeEdit.Top := 48;
    DocumentTypeEdit.Width := 397;
    DocumentTypeEdit.Height := 34;
    DocumentTypeEdit.Margins.Left := 20;
    DocumentTypeEdit.Margins.Top := 8;
    DocumentTypeEdit.Margins.Bottom := 8;
    DocumentTypeEdit.Color := clWhite;
    DocumentTypeEdit.ElementClassName := 'myselect_white';
    DocumentTypeEdit.Font.Charset := DEFAULT_CHARSET;
    DocumentTypeEdit.Font.Color := clWindowText;
    DocumentTypeEdit.Font.Height := 12;
    DocumentTypeEdit.Font.Name := 'Segoe UI';
    DocumentTypeEdit.Font.Style := [];
    DocumentTypeEdit.HeightPercent := 100.000000000000000000;
    DocumentTypeEdit.ParentFont := False;
    DocumentTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DocumentTypeEdit, Self, 'OnChange', 'DocumentTypeEditChange');
    DocumentTypeEdit.ItemIndex := -1;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 349;
    StatusBarPanel.Width := 461;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 341;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetDocumentEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 226;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetDocumentEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 2;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    OpenFileButton.SetParentComponent(StatusBarPanel);
    OpenFileButton.Name := 'OpenFileButton';
    OpenFileButton.AlignWithMargins := True;
    OpenFileButton.Left := 31;
    OpenFileButton.Top := 8;
    OpenFileButton.Width := 127;
    OpenFileButton.Height := 31;
    OpenFileButton.Margins.Left := 20;
    OpenFileButton.Margins.Top := 8;
    OpenFileButton.Margins.Right := 5;
    OpenFileButton.Margins.Bottom := 10;
    OpenFileButton.Caption := 'Choose &File';
    OpenFileButton.Color := clNone;
    OpenFileButton.ElementClassName := 'MyWebButton';
    OpenFileButton.HeightPercent := 100.000000000000000000;
    OpenFileButton.TabOrder := 3;
    OpenFileButton.WidthPercent := 100.000000000000000000;
    SetEvent(OpenFileButton, Self, 'OnClick', 'OpenFileButtonClick');
    DocumentIDEdit.SetParentComponent(ContentPanel);
    DocumentIDEdit.Name := 'DocumentIDEdit';
    DocumentIDEdit.Left := 32;
    DocumentIDEdit.Top := 128;
    DocumentIDEdit.Width := 396;
    DocumentIDEdit.Height := 34;
    DocumentIDEdit.ChildOrder := 1;
    DocumentIDEdit.ElementClassName := 'MyWebEdit';
    DocumentIDEdit.Font.Charset := ANSI_CHARSET;
    DocumentIDEdit.Font.Color := clWindowText;
    DocumentIDEdit.Font.Height := -16;
    DocumentIDEdit.Font.Name := 'Segoe UI';
    DocumentIDEdit.Font.Style := [];
    DocumentIDEdit.HeightPercent := 100.000000000000000000;
    DocumentIDEdit.ParentFont := False;
    DocumentIDEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DocumentIDEdit, Self, 'OnChange', 'RecordChange');
    ExpiryDateEdit.SetParentComponent(ContentPanel);
    ExpiryDateEdit.Name := 'ExpiryDateEdit';
    ExpiryDateEdit.AlignWithMargins := True;
    ExpiryDateEdit.Left := 32;
    ExpiryDateEdit.Top := 209;
    ExpiryDateEdit.Width := 143;
    ExpiryDateEdit.Height := 33;
    ExpiryDateEdit.Margins.Left := 20;
    ExpiryDateEdit.Margins.Top := 8;
    ExpiryDateEdit.Margins.Bottom := 8;
    ExpiryDateEdit.BorderStyle := bsSingle;
    ExpiryDateEdit.ChildOrder := 2;
    ExpiryDateEdit.Color := clWhite;
    ExpiryDateEdit.Date := 44545.380967847220000000;
    ExpiryDateEdit.Font.Charset := ANSI_CHARSET;
    ExpiryDateEdit.Font.Color := clWindowText;
    ExpiryDateEdit.Font.Height := -16;
    ExpiryDateEdit.Font.Name := 'Segoe UI';
    ExpiryDateEdit.Font.Style := [];
    ExpiryDateEdit.ParentFont := False;
    ExpiryDateEdit.Role := '';
    ExpiryDateEdit.Text := '';
    FileNameEdit.SetParentComponent(ContentPanel);
    FileNameEdit.Name := 'FileNameEdit';
    FileNameEdit.Left := 32;
    FileNameEdit.Top := 289;
    FileNameEdit.Width := 396;
    FileNameEdit.Height := 34;
    FileNameEdit.ChildOrder := 1;
    FileNameEdit.ElementClassName := 'MyWebEdit';
    FileNameEdit.Font.Charset := ANSI_CHARSET;
    FileNameEdit.Font.Color := clWindowText;
    FileNameEdit.Font.Height := -16;
    FileNameEdit.Font.Name := 'Segoe UI';
    FileNameEdit.Font.Style := [];
    FileNameEdit.HeightPercent := 100.000000000000000000;
    FileNameEdit.ParentFont := False;
    FileNameEdit.ReadOnly := True;
    FileNameEdit.WidthPercent := 100.000000000000000000;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 467;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 115;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Document - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 438;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetDocumentEdit.CaptionPanel.CloseImage.Picture.png');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 246;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    MyOpenFileDialog.SetParentComponent(Self);
    MyOpenFileDialog.Name := 'MyOpenFileDialog';
    MyOpenFileDialog.Accept := '.pdf';
    SetEvent(MyOpenFileDialog, Self, 'OnChange', 'MyOpenFileDialogChange');
    SetEvent(MyOpenFileDialog, Self, 'OnGetFileAsBase64', 'MyOpenFileDialogGetFileAsBase64');
    MyOpenFileDialog.Left := 129;
    MyOpenFileDialog.Top := 58;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    FileNameLabel.AfterLoadDFMValues;
    DocumentTypeEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    OpenFileButton.AfterLoadDFMValues;
    DocumentIDEdit.AfterLoadDFMValues;
    ExpiryDateEdit.AfterLoadDFMValues;
    FileNameEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    MyOpenFileDialog.AfterLoadDFMValues;
  end;
end;

end.

{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016  - 2022                              }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCPageControl;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF VCLLIB}
{$DEFINE VCLWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE VCLWEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCTabSet, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCTypes, WEBLib.Controls,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCPopup
  {$IFNDEF LCLLIB}
  , Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 7; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.0.1 : Fixed: Issue with clearing tabs at designtime
  //          : Fixed: Issue switching tabs at designtime
  // v1.0.0.2 : Fixed: Issue with adding pages at designtime with page collection editor
  // v1.0.0.3 : Fixed: Issue setting Visible property to False at designtime causing tab key to jump to next page
  // v1.0.0.4 : Fixed: Issue with focusing control when changing pages
  // v1.0.0.5 : Fixed: Issue with InitSample in Lazarus
  // v1.0.0.6 : Improved : Updated initial look
  // v1.0.0.7 : Fixed : Issue with page registration in combination with responsive manager

type
  TTMSFNCCustomPageControl = class;
  TTMSFNCPageControlPage = class;

  TTMSFNCPageControlContainer = class(TTMSFNCCustomControl)
  private
    FIsDestroying: Boolean;
    FPage: TTMSFNCPageControlPage;
    FPageControl: TTMSFNCCustomPageControl;
    FPageIndex: Integer;
    FIsActive: Boolean;
    procedure SetPageControl(const Value: TTMSFNCCustomPageControl);
    function GetPageControl: TTMSFNCCustomPageControl;
    procedure SetIsActive(const Value: Boolean);
    procedure SetPageIndex(const Value: Integer);
  protected
    procedure RegisterRuntimeClasses; override;
    {$IFNDEF WEBLIB}
    procedure ReadState(Reader: TReader); override;
    procedure DefineProperties(Filer : TFiler); override;
    procedure ReadPageIndex(Reader: TReader);
    procedure WritePageIndex(Writer: TWriter);
    procedure ReadIsActive(Reader: TReader);
    procedure WriteIsActive(Writer: TWriter);
    {$ENDIF}
    procedure SetPage;
    procedure UpdateControlLayout({%H-}AForceUpdate: Boolean = False); virtual;
    {$IFDEF FMXLIB}
    procedure SetParentComponent(Value: TComponent); override;
    {$ENDIF}
    {$IFDEF CMNLIB}
    procedure SetParent(Value: TWinControl); override;
    {$ENDIF}
    {$IFDEF WEBLIB}
    procedure SetParent(Value: TControl); override;
    {$ENDIF}
  public
    constructor Create(AOwner: TComponent); overload; override;
    constructor Create(AOwner: TComponent; APage: TTMSFNCPageControlPage); reintroduce; overload; virtual;
    destructor Destroy; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    property PageControl: TTMSFNCCustomPageControl read GetPageControl write SetPageControl;
    property PageIndex: Integer read FPageIndex write SetPageIndex;
    property IsActive: Boolean read FIsActive write SetIsActive;
  published
    property Fill;
    property Stroke;
  end;

  TTMSFNCPageControlContainerClass = class of TTMSFNCPageControlContainer;

  TTMSFNCPageControlPage = class(TTMSFNCTabSetTab)
  private
    FContainer: TTMSFNCPageControlContainer;
    FPageControl: TTMSFNCCustomPageControl;
  protected
    procedure UpdatePage;
    function GetFriendlyName: String; override;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    function PageControl: TTMSFNCCustomPageControl;
    property Container: TTMSFNCPageControlContainer read FContainer;
  end;

  TTMSFNCPageControlPages = class(TTMSFNCTabSetTabs)
  private
    FPageControl: TTMSFNCCustomPageControl;
  protected
    function GetTabClass: TCollectionItemClass; override;
    function PageControl: TTMSFNCCustomPageControl;
  public
    constructor Create(ATabSet: TTMSFNCCustomTabSet); override;
    procedure Clear; override;
    function Add: TTMSFNCPageControlPage;
    function Insert(Index: Integer): TTMSFNCPageControlPage;
    function IndexOfContainer(AValue: TComponent): Integer;
  end;

  TTMSFNCPageControlPageCloseAction = (ttcaNone, ttcaFree, ttcaHide);

  TTMSFNCPageControlBeforeReorderPageEvent = procedure(Sender: TObject; ACurrentPageIndex: Integer; ANewPageIndex: Integer; var ACanReorder: Boolean) of object;
  TTMSFNCPageControlBeforeChangePageEvent = procedure(Sender: TObject; ACurrentPageIndex: Integer; ANewPageIndex: Integer; var ACanChange: Boolean) of object;
  TTMSFNCPageControlChangePageEvent = procedure(Sender: TObject; APreviousPageIndex: Integer; ACurrentPageIndex: Integer) of object;
  TTMSFNCPageControlReorderPageEvent = procedure(Sender: TObject; APreviousPageIndex: Integer; ACurrentPageIndex: Integer) of object;
  TTMSFNCPageControlBeforeClosePageEvent = procedure(Sender: TObject; APageIndex: Integer; var ACloseAction: TTMSFNCPageControlPageCloseAction) of object;
  TTMSFNCPageControlClosePageEvent = procedure(Sender: TObject; APageIndex: Integer; ACloseAction: TTMSFNCPageControlPageCloseAction) of object;
  TTMSFNCPageControlBeforeInsertPageEvent = procedure(Sender: TObject; var ANewPageIndex: Integer; var ACanInsert: Boolean) of object;
  TTMSFNCPageControlInsertPageEvent = procedure(Sender: TObject; ANewPageIndex: Integer) of object;

  TTMSFNCPageControlPopupForm = class(TTMSFNCCustomPopupForm);

  TTMSFNCPageControlPopup = class(TTMSFNCNonFocusablePopup)
  protected
    function GetPopupFormClass: TTMSFNCCustomPopupFormClass; override;
  end;

  TTMSFNCCustomPageControl = class(TTMSFNCCustomTabSet)
  private
    FBlockAssign: Boolean;
    FSaveActivePage: TTMSFNCPageControlPage;
    FPopup: TTMSFNCPageControlPopup;
    FSaveHeight: Single;
    FSaveWidth: Single;
    FUpdateMargins, FUpdateVisibility: Boolean;
    FPrevActivePageContainer: TTMSFNCPageControlContainer;
    FUpdateCount: Integer;
    FOnInsertPage: TTMSFNCPageControlInsertPageEvent;
    FOnBeforeClosePage: TTMSFNCPageControlBeforeClosePageEvent;
    FOnBeforeInsertPage: TTMSFNCPageControlBeforeInsertPageEvent;
    FOnChangePage: TTMSFNCPageControlChangePageEvent;
    FOnReorderPage: TTMSFNCPageControlReorderPageEvent;
    FOnClosePage: TTMSFNCPageControlClosePageEvent;
    FOnBeforeChangePage: TTMSFNCPageControlBeforeChangePageEvent;
    FOnBeforeReorderPage: TTMSFNCPageControlBeforeReorderPageEvent;
    FOnInternalInsertPage: TNotifyEvent;
    FCollapsed: Boolean;
    function GetActivePageIndex: Integer;
    procedure SetActivePageIndex(const Value: Integer);
    procedure SetPages(const Value: TTMSFNCPageControlPages);
    function GetActivePage: TTMSFNCPageControlPage;
    procedure SetActivePage(const Value: TTMSFNCPageControlPage);
    function GetPageContainer(AIndex: Integer): TTMSFNCPageControlContainer;
    procedure SetPageContainer({%H-}AIndex: Integer;
      const Value: TTMSFNCPageControlContainer);
  protected
    function GetDocURL: string; override;
    function GetTabClass: TCollectionItemClass; override;
    function GetVersion: string; override;
    function CreateTabs: TTMSFNCTabSetTabs; override;
    function GetContainerClass: TTMSFNCPageControlContainerClass; virtual;
    function GetPages: TTMSFNCPageControlPages;
    {$IFDEF WEBLIB}
    procedure Loaded; override;
    {$ENDIF}
    procedure SetCollapsed(const Value: Boolean); virtual;
    procedure DoClosePopup(Sender: TObject); virtual;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure UpdateAllPages(Sender: TObject);
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure UpdatePages; virtual;
    procedure UpdateTabs(ACalculateScrolling: Boolean = False; AForceUpdate: Boolean = False); override;
    procedure UpdateMargins(AContainer: TTMSFNCPageControlContainer); virtual;
    procedure UpdatePageIndexes; virtual;
    procedure UpdateVisibility; virtual;
    procedure DoInvalidate; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;

    procedure DoBeforeChangeTab(ACurrentTabIndex: Integer; ANewTabIndex: Integer; var ACanChange: Boolean); override;
    procedure DoChangeTab(APreviousTabIndex: Integer; ACurrentTabIndex: Integer); override;
    procedure DoBeforeReorderTab(ACurrentTabIndex: Integer; ANewTabIndex: Integer; var ACanReorder: Boolean); override;
    procedure DoReorderTab(APreviousTabIndex: Integer; ACurrentTabIndex: Integer); override;
    procedure DoBeforeCloseTab(ATabIndex: Integer; var ACloseAction: TTMSFNCTabSetTabCloseAction); override;
    procedure DoCloseTab(ATabIndex: Integer; ACloseAction: TTMSFNCTabSetTabCloseAction); override;
    procedure DoBeforeInsertTab(var ANewTabIndex: Integer; var ACanInsert: Boolean); override;
    procedure DoInsertTab(ANewTabIndex: Integer); override;

    procedure DoBeforeChangePage(ACurrentPageIndex: Integer; ANewPageIndex: Integer; var ACanChange: Boolean); virtual;
    procedure DoChangePage(APreviousPageIndex: Integer; ACurrentPageIndex: Integer); virtual;
    procedure DoBeforeReorderPage(ACurrentPageIndex: Integer; ANewPageIndex: Integer; var ACanReorder: Boolean); virtual;
    procedure DoReorderPage(APreviousPageIndex: Integer; ACurrentPageIndex: Integer); virtual;
    procedure DoBeforeClosePage(APageIndex: Integer; var ACloseAction: TTMSFNCPageControlPageCloseAction); virtual;
    procedure DoClosePage(APageIndex: Integer; ACloseAction: TTMSFNCPageControlPageCloseAction); virtual;
    procedure DoBeforeInsertPage(var ANewPageIndex: Integer; var ACanInsert: Boolean); virtual;
    procedure DoInsertPage(ANewPageIndex: Integer); virtual;

    property ActivePage: TTMSFNCPageControlPage read GetActivePage write SetActivePage;
    property ActivePageIndex: Integer read GetActivePageIndex write SetActivePageIndex default 0;
    property OnBeforeClosePage: TTMSFNCPageControlBeforeClosePageEvent read FOnBeforeClosePage write FOnBeforeClosePage;
    property OnClosePage: TTMSFNCPageControlClosePageEvent read FOnClosePage write FOnClosePage;
    property OnBeforeInsertPage: TTMSFNCPageControlBeforeInsertPageEvent read FOnBeforeInsertPage write FOnBeforeInsertPage;
    property OnInsertPage: TTMSFNCPageControlInsertPageEvent read FOnInsertPage write FOnInsertPage;
    property OnBeforeChangePage: TTMSFNCPageControlBeforeChangePageEvent read FOnBeforeChangePage write FOnBeforeChangePage;
    property OnChangePage: TTMSFNCPageControlChangePageEvent read FOnChangePage write FOnChangePage;
    property OnBeforeReorderPage: TTMSFNCPageControlBeforeReorderPageEvent read FOnBeforeReorderPage write FOnBeforeReorderPage;
    property OnReorderPage: TTMSFNCPageControlReorderPageEvent read FOnReorderPage write FOnReorderPage;
    property Pages: TTMSFNCPageControlPages read GetPages write SetPages;
    property PageContainers[AIndex: Integer]: TTMSFNCPageControlContainer read GetPageContainer write SetPageContainer;

    property OnInternalInsertPage: TNotifyEvent read FOnInternalInsertPage write FOnInternalInsertPage;
    property Collapsed: Boolean read FCollapsed write SetCollapsed default False;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function AddPage(AText: string = ''): TTMSFNCPageControlPage; virtual;
    function InsertPage(APageIndex: Integer; AText: String = ''): TTMSFNCPageControlPage; virtual;
    {$IFDEF CMNWEBLIB}
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    {$ENDIF}
    procedure ClosePopup; virtual;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure MovePage(AFromPageIndex, AToPageIndex: Integer); virtual;
    procedure RemovePage(APageIndex: Integer);
    procedure UpdateControlAfterResize; override;
    procedure LoadSettingsFromFile(AFileName: string); override;
    procedure LoadSettingsFromStream(AStream: TStreamEx); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCPageControl = class(TTMSFNCCustomPageControl)
  private
    FInitialize: Boolean;
  protected
    procedure RegisterRuntimeClasses; override;
    procedure SetName(const Value: TComponentName); override;
  public
    constructor Create(AOwner: TComponent); override;
    property ActivePage;
    property PageContainers;
    procedure InitSample; reintroduce; virtual;
  published
    property ActivePageIndex;
    property Collapsed;
    property Fill;
    property Stroke;
    property TabAppearance;
    property ButtonAppearance;
    property BitmapContainer;
    property Interaction;
    property Options;
    property TabSize;
    property Layout;
    property Version;
    property Pages;
    property OnBeforeClosePage;
    property OnBeforeChangePage;
    property OnClosePage;
    property OnChangePage;
    property OnAnchorTabClick;
    property OnBeforeInsertPage;
    property OnInsertPage;
    property OnBeforeUpdateTab;
    property OnUpdateTab;
    property OnBeforeOpenInplaceEditor;
    property OnCloseInplaceEditor;
    property OnOpenInplaceEditor;
    property OnGetInplaceEditor;
    property OnCustomizeInplaceEditor;
    property OnGetInplaceEditorRect;
    property OnBeforeReorderPage;
    property OnReorderPage;
    property OnBeforeDrawTabBackground;
    property OnBeforeDrawTabProgress;
    property OnBeforeDrawTabBadge;
    property OnBeforeDrawTabText;
    property OnBeforeDrawTabCloseButton;
    property OnBeforeDrawTabBitmap;
    property OnAfterDrawTabBackground;
    property OnAfterDrawTabProgress;
    property OnAfterDrawTabBadge;
    property OnAfterDrawTabText;
    property OnAfterDrawTabCloseButton;
    property OnAfterDrawTabBitmap;
    property OnBeforeDrawMenuButton;
    property OnAfterDrawMenuButton;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, WEBLib.Graphics, SysUtils, Math,
  WEBLib.TMSFNCGraphicsTypes
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

type
  TTMSFNCTabSetTabOpen = class(TTMSFNCTabSetTab);

function TTMSFNCCustomPageControl.AddPage(AText: String = ''): TTMSFNCPageControlPage;
begin
  Result := Pages.Add;
  Result.Text := AText;

  if Assigned(OnInternalInsertPage) then
    OnInternalInsertPage(Result);
end;

procedure TTMSFNCCustomPageControl.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCCustomPageControl.ClosePopup;
begin
  if Assigned(FPopup) then
    FPopup.Deactivate;
end;

constructor TTMSFNCCustomPageControl.Create(AOwner: TComponent);
begin
  inherited;
  FPopup := TTMSFNCPageControlPopup.Create(Self);
  FPopup.OnClosePopup := DoClosePopup;

  {$IFDEF CMNLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  Transparent := True;
  {$ENDIF}
  {$ENDIF}

  if IsDesignTime then
    DisableBackground;

  {$IFDEF CMNLIB}
  ParentColor := True;
  {$IFDEF VCLLIB}
  ParentBackground := True;
  {$ENDIF}
  {$ENDIF}

  TabSize.OnChange := @UpdateAllPages;
  Layout.OnChange := @UpdateAllPages;

  FSaveWidth := -1;
  FSaveHeight := -1;
end;

function TTMSFNCCustomPageControl.CreateTabs: TTMSFNCTabSetTabs;
begin
  Result := TTMSFNCPageControlPages.Create(Self);
end;

destructor TTMSFNCCustomPageControl.Destroy;
begin
  FPopup.Free;
  inherited;
end;

procedure TTMSFNCCustomPageControl.DoBeforeChangePage(ACurrentPageIndex,
  ANewPageIndex: Integer; var ACanChange: Boolean);
begin
  if Assigned(OnBeforeChangePage) then
    OnBeforeChangePage(Self, ACurrentPageIndex, ANewPageIndex, ACanChange);
end;

procedure TTMSFNCCustomPageControl.DoBeforeChangeTab(ACurrentTabIndex,
  ANewTabIndex: Integer; var ACanChange: Boolean);
begin
  inherited;
  DoBeforeChangePage(ACurrentTabIndex, ANewTabIndex, ACanChange);
end;

procedure TTMSFNCCustomPageControl.DoBeforeClosePage(APageIndex: Integer;
  var ACloseAction: TTMSFNCPageControlPageCloseAction);
begin
  if Assigned(OnBeforeClosePage) then
    OnBeforeClosePage(Self, APageIndex, ACloseAction);
end;

procedure TTMSFNCCustomPageControl.DoBeforeCloseTab(ATabIndex: Integer;
  var ACloseAction: TTMSFNCTabSetTabCloseAction);
var
  c: TTMSFNCPageControlPageCloseAction;
  i: Integer;
begin
  inherited;
  i := Integer(ACloseAction);
  c := TTMSFNCPageControlPageCloseAction(i);
  DoBeforeClosePage(ATabIndex, c);
  i := Integer(c);
  ACloseAction := TTMSFNCTabSetTabCloseAction(i);
end;

procedure TTMSFNCCustomPageControl.DoBeforeInsertPage(
  var ANewPageIndex: Integer; var ACanInsert: Boolean);
begin
  if Assigned(OnBeforeInsertPage) then
    OnBeforeInsertPage(Self, ANewPageIndex, ACanInsert);
end;

procedure TTMSFNCCustomPageControl.DoBeforeInsertTab(var ANewTabIndex: Integer;
  var ACanInsert: Boolean);
begin
  inherited;
  DoBeforeInsertPage(ANewTabIndex, ACanInsert);
end;

procedure TTMSFNCCustomPageControl.DoBeforeReorderPage(ACurrentPageIndex,
  ANewPageIndex: Integer; var ACanReorder: Boolean);
begin
  if Assigned(OnBeforeReorderPage) then
    OnBeforeReorderPage(Self, ACurrentPageIndex, ANewPageIndex, ACanReorder);
end;

procedure TTMSFNCCustomPageControl.DoBeforeReorderTab(ACurrentTabIndex,
  ANewTabIndex: Integer; var ACanReorder: Boolean);
begin
  inherited;
  DoBeforeReorderPage(ACurrentTabIndex, ANewTabIndex, ACanReorder);
end;

procedure TTMSFNCCustomPageControl.DoChangePage(APreviousPageIndex,
  ACurrentPageIndex: Integer);
begin
  if Assigned(OnChangePage) then
    OnChangePage(Self, APreviousPageIndex, ACurrentPageIndex);
end;

procedure TTMSFNCCustomPageControl.DoChangeTab(APreviousTabIndex,
  ACurrentTabIndex: Integer);
var
  p: TTMSFNCPageControlContainer;
begin
  inherited;
  UpdatePages;
  DoChangePage(APreviousTabIndex, ACurrentTabIndex);

  if Collapsed and Assigned(ActivePage) then
  begin
    FSaveActivePage := ActivePage;
    FBlockAssign := True;
    FPopup.Deactivate;
    FBlockAssign := False;
    p := ActivePage.Container;
    FPopup.ContentControl := p;
    FPopup.PlacementControl := Self;

    case Layout.Position of
      tlpLeft:
      begin
        FPopup.DropDownWidth := FSaveWidth - Round(TabSize.Height + TabSize.Margins.Top + 1);
        FPopup.DropDownHeight := Height;
      end;
      tlpRight:
      begin
        FPopup.DropDownWidth := FSaveWidth - Round(TabSize.Height + TabSize.Margins.Right + 1);
        FPopup.DropDownHeight := Height;
      end;
      tlpTop:
      begin
        FPopup.DropDownWidth := Width;
        FPopup.DropDownHeight := FSaveHeight - Round(TabSize.Height + TabSize.Margins.Top + 1);
      end;
      tlpBottom:
      begin
        FPopup.DropDownWidth := Width;
        FPopup.DropDownHeight := FSaveHeight - Round(TabSize.Height + TabSize.Margins.Bottom + 1);
      end;
    end;

    case Layout.Position of
      tlpLeft, tlpRight:
      begin
        FPopup.HorizontalOffset := -1;
        FPopup.VerticalOffset := 0;
        FPopup.DropDownWidth := FPopup.DropDownWidth + 2;
      end;
      tlpTop, tlpBottom:
      begin
        FPopup.VerticalOffset := -1;
        FPopup.HorizontalOffset := 0;
        FPopup.DropDownHeight := FPopup.DropDownHeight + 2;
      end;
    end;

    case Layout.Position of
      tlpLeft: FPopup.Placement := ppRight;
      tlpTop: FPopup.Placement := ppBottom;
      tlpBottom: FPopup.Placement := ppTop;
      tlpRight: FPopup.Placement := ppLeft;
    end;
    FPopup.Activate;
  end;
end;

procedure TTMSFNCCustomPageControl.DoClosePage(APageIndex: Integer;
  ACloseAction: TTMSFNCPageControlPageCloseAction);
begin
  if Assigned(OnClosePage) then
    OnClosePage(Self, APageIndex, ACloseAction);
end;

procedure TTMSFNCCustomPageControl.DoClosePopup(Sender: TObject);
begin
  if FBlockAssign then
    Exit;

  ActivePage := nil;
end;

procedure TTMSFNCCustomPageControl.DoCloseTab(ATabIndex: Integer;
  ACloseAction: TTMSFNCTabSetTabCloseAction);
var
  c: TTMSFNCPageControlPageCloseAction;
  i: Integer;
begin
  inherited;
  i := Integer(ACloseAction);
  c := TTMSFNCPageControlPageCloseAction(i);
  DoClosePage(ATabIndex, c);
  FUpdateMargins := True;
end;

procedure TTMSFNCCustomPageControl.DoInsertPage(ANewPageIndex: Integer);
begin
  if Assigned(OnInsertPage) then
    OnInsertPage(Self, ANewPageIndex);
end;

procedure TTMSFNCCustomPageControl.DoInsertTab(ANewTabIndex: Integer);
begin
  inherited;
  DoInsertPage(ANewTabIndex);
  FUpdateMargins := True;
end;

procedure TTMSFNCCustomPageControl.DoReorderPage(APreviousPageIndex,
  ACurrentPageIndex: Integer);
begin
  if Assigned(OnReorderPage) then
    OnReorderPage(Self, APreviousPageIndex, ACurrentPageIndex);
end;

procedure TTMSFNCCustomPageControl.DoReorderTab(APreviousTabIndex,
  ACurrentTabIndex: Integer);
begin
  inherited;
  DoReorderPage(APreviousTabIndex, ACurrentTabIndex);
  FUpdateMargins := True;
end;

procedure TTMSFNCCustomPageControl.DoInvalidate;
var
  c: TTMSFNCPageControlContainer;
begin
  inherited;
  if Assigned(ActivePage) then
  begin
    c := ActivePage.Container;
    if Assigned(c) then
      c.Invalidate;
  end;
end;

procedure TTMSFNCCustomPageControl.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  inherited;
  if (csDesigning in ComponentState) and (Pages.Count = 0) then
  begin
    AGraphics.DrawFocusRectangle(ARect);
    AGraphics.Font.Height := ScalePaintValue(AGraphics.Font.Height);
    AGraphics.DrawText(ARect, 'Please add pages by clicking "New Page" in the context menu', False, gtaCenter, gtaCenter);
  end;
end;

procedure TTMSFNCCustomPageControl.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
  begin
    {$IFDEF WEBLIB}
    {$ENDIF}
    UpdatePages;
  end;
end;

function TTMSFNCCustomPageControl.GetActivePage: TTMSFNCPageControlPage;
begin
  Result := ActiveTab as TTMSFNCPageControlPage;
end;

function TTMSFNCCustomPageControl.GetActivePageIndex: Integer;
begin
  Result := ActiveTabIndex;
end;

{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomPageControl.GetChildren(Proc: TGetChildProc; Root: TComponent);
var
  I: Integer;
begin
  {$IFNDEF WEBLIB}
  inherited GetChildren(Proc, Root);
  if AllowGetChildren then
  {$ENDIF}
  begin
    for I := 0 to Pages.Count - 1 do
      Proc(PageContainers[I]);
  end;
end;
{$ENDIF}

function TTMSFNCCustomPageControl.GetContainerClass: TTMSFNCPageControlContainerClass;
begin
  Result := TTMSFNCPageControlContainer;
end;

function TTMSFNCCustomPageControl.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomPageControl.GetPageContainer(
  AIndex: Integer): TTMSFNCPageControlContainer;
begin
  Result := nil;
  if (AIndex >= 0) and (AIndex <= Pages.Count - 1) then
    Result := (Pages[AIndex] as TTMSFNCPageControlPage).Container;
end;

function TTMSFNCCustomPageControl.GetPages: TTMSFNCPageControlPages;
begin
  Result := Tabs as TTMSFNCPageControlPages;
end;

function TTMSFNCCustomPageControl.GetTabClass: TCollectionItemClass;
begin
  Result := TTMSFNCPageControlPage;
end;

function TTMSFNCCustomPageControl.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomPageControl.InsertPage(APageIndex: Integer; AText: String = ''): TTMSFNCPageControlPage;
begin
  Result := Pages.Insert(APageIndex);
  Result.Text := AText;

  if Assigned(OnInternalInsertPage) then
    OnInternalInsertPage(Result);
end;

procedure TTMSFNCCustomPageControl.LoadSettingsFromFile(AFileName: string);
var
  I: Integer;
begin
  inherited;
  for I := 0 to Pages.Count - 1 do
  begin
    PageContainers[I].Fill.Assign(TabAppearance.ActiveFill);
    PageContainers[I].Stroke.Assign(TabAppearance.ActiveStroke);
  end;
end;

procedure TTMSFNCCustomPageControl.LoadSettingsFromStream(AStream: TStreamEx);
var
  I: Integer;
begin
  inherited;
  for I := 0 to Pages.Count - 1 do
  begin
    PageContainers[I].Fill.Assign(TabAppearance.ActiveFill);
    PageContainers[I].Stroke.Assign(TabAppearance.ActiveStroke);
  end;
end;

procedure TTMSFNCCustomPageControl.MovePage(AFromPageIndex,
  AToPageIndex: Integer);
var
  act: Integer;
begin
  if (AFromPageIndex >= 0) and (AFromPageIndex <= Pages.Count - 1) and (AToPageIndex >= 0) and (AToPageIndex <= Pages.Count - 1) then
  begin
    act := ActivePageIndex;
    Pages[AFromPageIndex].Index := Pages[AToPageIndex].Index;
    if act = AFromPageIndex then
      ActivePageIndex := AToPageIndex;
    if act = AToPageIndex then
      ActivePageIndex := AFromPageIndex;
  end;
end;

procedure TTMSFNCCustomPageControl.Notification(AComponent: TComponent;
  Operation: TOperation);
var
  c: TTMSFNCPageControlContainer;
begin
  if not (csDestroying in ComponentState) and (Operation = opRemove) and (AComponent is TTMSFNCPageControlContainer) then
  begin
    c := (AComponent as TTMSFNCPageControlContainer);
    if not c.FIsDestroying and Assigned(c.FPage) then
    begin
      if FPrevActivePageContainer = c.FPage.FContainer then
        FPrevActivePageContainer := nil;
      c.FPage.FContainer := nil;
      c.FPage.Free;
      c.FPage := nil;
      ActivePageIndex := Max(0, Min(ActivePageIndex, Pages.Count - 1));
    end;
  end;

  inherited;
end;

procedure TTMSFNCCustomPageControl.RemovePage(APageIndex: Integer);
begin
  if (APageIndex >= 0) and (APageIndex <= Pages.Count - 1) then
    Pages.Delete(APageIndex);
end;

procedure TTMSFNCCustomPageControl.UpdateControlAfterResize;
begin
  FUpdateMargins := True;
  inherited;
end;

procedure TTMSFNCCustomPageControl.SetActivePage(
  const Value: TTMSFNCPageControlPage);
begin
  ActiveTab := Value;
end;

procedure TTMSFNCCustomPageControl.SetActivePageIndex(const Value: Integer);
begin
  ActiveTabIndex := Value;
end;

procedure TTMSFNCCustomPageControl.SetAdaptToStyle(const Value: Boolean);
var
  I: Integer;
  c: TTMSFNCPageControlContainer;
begin
  inherited;
  for I := 0 to Pages.Count - 1 do
  begin
    c := (Pages[I] as TTMSFNCPageControlPage).Container;
    if Assigned(c) then
      c.AdaptToStyle := AdaptToStyle;
  end;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomPageControl.Loaded;
var
  I: Integer;
  c: TTMSFNCPageControlContainer;
begin
  inherited;
  for I := 0 to Pages.Count - 1 do
  begin
    c := (Pages[I] as TTMSFNCPageControlPage).Container;
    if Assigned(c) then
      c.Visible := True;
  end;

  UpdatePages;

  for I := 0 to Pages.Count - 1 do
  begin
    c := (Pages[I] as TTMSFNCPageControlPage).Container;
    if Assigned(c) then
      c.Visible := c.IsActive;
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomPageControl.SetCollapsed(const Value: Boolean);
begin
  if FCollapsed <> Value then
  begin
    FCollapsed := Value;

    FBlockAssign := not FCollapsed;
    FPopup.Deactivate;
    FBlockAssign := False;

    case Layout.Position of
      tlpLeft, tlpRight:
      begin
        if FCollapsed and (FSaveWidth = -1) then
          FSaveWidth := Width;
      end;
      tlpTop, tlpBottom:
      begin
        if FCollapsed and (FSaveHeight = -1) then
          FSaveHeight := Round(Height);
      end;
    end;

    if Collapsed then
    begin
      FSaveActivePage := ActivePage;
      ActivePage := nil;
      FUpdateVisibility := True;
      FUpdateMargins := True;
      UpdatePages;
    end
    else if Assigned(FSaveActivePage) then
      ActivePage := FSaveActivePage;

    case Layout.Position of
      tlpLeft, tlpRight:
      begin
        if FCollapsed then
        begin
          if Layout.Position = tlpLeft then
            Width := Round(TabSize.Height + TabSize.Margins.Left)
          else
            Width := Round(TabSize.Height + TabSize.Margins.Right);

          if Layout.Position = tlpRight then
            Left := Left + Round(FSaveWidth - Width);
        end
        else
        begin
          if Layout.Position = tlpRight then
            Left := Left - Round(FSaveWidth - Width);

          Width := Round(FSaveWidth);
        end;
      end;
      tlpTop, tlpBottom:
      begin
        if FCollapsed then
        begin
          if Layout.Position = tlpTop then
            Height := Round(TabSize.Height + TabSize.Margins.Top)
          else
            Height := Round(TabSize.Height + TabSize.Margins.Bottom);

          if Layout.Position = tlpBottom then
            Top := Top + Round(FSaveHeight - Height);
        end
        else
        begin
          if Layout.Position = tlpBottom then
            Top := Top - Round(FSaveHeight - Height);
          Height := Round(FSaveHeight);
        end;
      end;
    end;

    if not Collapsed then
    begin
      FUpdateVisibility := True;
      FUpdateMargins := True;
      UpdatePages;
    end;
  end;
end;

procedure TTMSFNCCustomPageControl.SetPageContainer(AIndex: Integer;
  const Value: TTMSFNCPageControlContainer);
var
  c: TTMSFNCPageControlContainer;
begin
  if not (csDestroying in ComponentState) then
  begin
    c := Value;
    if not c.FIsDestroying and Assigned(c.FPage) then
    begin
      if FPrevActivePageContainer = c.FPage.FContainer then
        FPrevActivePageContainer := nil;
      c.FPage.FContainer := nil;
      c.FPage.Free;
      c.FPage := nil;
      ActivePageIndex := Max(0, Min(ActivePageIndex, Pages.Count - 1));
    end;
  end;
end;

procedure TTMSFNCCustomPageControl.SetPages(
  const Value: TTMSFNCPageControlPages);
begin
  Tabs.Assign(Value);
end;

procedure TTMSFNCCustomPageControl.UpdateAllPages(Sender: TObject);
begin
  FUpdateMargins := True;
end;

procedure TTMSFNCCustomPageControl.UpdateMargins(
  AContainer: TTMSFNCPageControlContainer);
var
  h: Single;
begin
  h := GetTotalTabHeight;

  if Collapsed or (h <= 0) then
  begin
    case Layout.Position of
      tlpLeft, tlpRight:
      begin
        AContainer.Width := Round(FSaveWidth);
        AContainer.Height := Round(Height);
      end;
      tlpTop, tlpBottom:
      begin
        AContainer.Width := Round(Width);
        AContainer.Height := Round(FSaveHeight);
      end;
    end;

    case Layout.Position of
      tlpLeft: AContainer.Width := AContainer.Width - Round(TabSize.Height - TabSize.Margins.Left + 1);
      tlpTop: AContainer.Height := AContainer.Height - Round(TabSize.Height - TabSize.Margins.Top + 1);
      tlpBottom: AContainer.Height := AContainer.Height - Round(TabSize.Height - TabSize.Margins.Bottom + 1);
      tlpRight: AContainer.Width := AContainer.Width - Round(TabSize.Height - TabSize.Margins.Right + 1);
    end;

    {$IFDEF FMXLIB}
    AContainer.Margins.Top := 0;
    AContainer.Margins.Left := 0;
    AContainer.Margins.Right := 0;
    AContainer.Margins.Bottom := 0;
    {$ENDIF}
    {$IFDEF VCLWEBLIB}
    AContainer.Margins.Top := 0;
    AContainer.Margins.Left := 0;
    AContainer.Margins.Right := 0;
    AContainer.Margins.Bottom := 0;
    {$ENDIF}
    {$IFDEF LCLLIB}
    AContainer.BorderSpacing.Top := 0;
    AContainer.BorderSpacing.Left := 0;
    AContainer.BorderSpacing.Right := 0;
    AContainer.BorderSpacing.Bottom := 0;
    {$ENDIF}
  end
  else
  begin
    {$IFDEF FMXLIB}
    case Layout.Position of
      tlpLeft:
      begin
        AContainer.Margins.Top := 0;
        AContainer.Margins.Left := h + TabSize.Margins.Left - 1;
        AContainer.Margins.Right := 0;
        AContainer.Margins.Bottom := 0;
      end;
      tlpTop:
      begin
        AContainer.Margins.Top := h + TabSize.Margins.Top - 1;
        AContainer.Margins.Left := 0;
        AContainer.Margins.Right := 0;
        AContainer.Margins.Bottom := 0;
      end;
      tlpBottom:
      begin
        AContainer.Margins.Top := 0;
        AContainer.Margins.Left := 0;
        AContainer.Margins.Right := 0;
        AContainer.Margins.Bottom := TabSize.Margins.Bottom + h - 1;
      end;
      tlpRight:
      begin
        AContainer.Margins.Top := 0;
        AContainer.Margins.Left := 0;
        AContainer.Margins.Right := TabSize.Margins.Right + h - 1;
        AContainer.Margins.Bottom := 0;
      end;
    end;
    {$ENDIF}
    {$IFDEF VCLWEBLIB}
    case Layout.Position of
      tlpLeft:
      begin
        AContainer.Margins.Top := 0;
        AContainer.Margins.Left := Round(h + TabSize.Margins.Left - 1);
        AContainer.Margins.Right := 0;
        AContainer.Margins.Bottom := 0;
      end;
      tlpTop:
      begin
        AContainer.Margins.Top := Round(h + TabSize.Margins.Top - 1);
        AContainer.Margins.Left := 0;
        AContainer.Margins.Right := 0;
        AContainer.Margins.Bottom := 0;
      end;
      tlpBottom:
      begin
        AContainer.Margins.Top := 0;
        AContainer.Margins.Left := 0;
        AContainer.Margins.Right := 0;
        AContainer.Margins.Bottom := Round(TabSize.Margins.Bottom + h - 1);
      end;
      tlpRight:
      begin
        AContainer.Margins.Top := 0;
        AContainer.Margins.Left := 0;
        AContainer.Margins.Right := Round(TabSize.Margins.Right + h - 1);
        AContainer.Margins.Bottom := 0;
      end;
    end;
    {$ENDIF}
    {$IFDEF LCLLIB}
    case Layout.Position of
      tlpLeft:
      begin
        AContainer.BorderSpacing.Top := 0;
        AContainer.BorderSpacing.Left := Round(h + TabSize.Margins.Left - 1);
        AContainer.BorderSpacing.Right := 0;
        AContainer.BorderSpacing.Bottom := 0;
      end;
      tlpTop:
      begin
        AContainer.BorderSpacing.Top := Round(h + TabSize.Margins.Top - 1);
        AContainer.BorderSpacing.Left := 0;
        AContainer.BorderSpacing.Right := 0;
        AContainer.BorderSpacing.Bottom := 0;
      end;
      tlpBottom:
      begin
        AContainer.BorderSpacing.Top := 0;
        AContainer.BorderSpacing.Left := 0;
        AContainer.BorderSpacing.Right := 0;
        AContainer.BorderSpacing.Bottom := Round(TabSize.Margins.Bottom + h - 1);
      end;
      tlpRight:
      begin
        AContainer.BorderSpacing.Top := 0;
        AContainer.BorderSpacing.Left := 0;
        AContainer.BorderSpacing.Right := Round(TabSize.Margins.Right + h - 1);
        AContainer.BorderSpacing.Bottom := 0;
      end;
    end;
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomPageControl.UpdatePageIndexes;
var
  c: TTMSFNCPageControlContainer;
  I: Integer;
begin
  for I := 0 to Pages.Count - 1 do
  begin
    c := (Pages[I] as TTMSFNCPageControlPage).Container;
    if Assigned(c) then
    begin
      c.FPageIndex := I;
      c.FIsActive := c.FPageIndex = ActivePageIndex;
      if FUpdateMargins then
        UpdateMargins(c);
    end;
  end;

  FUpdateMargins := False;
end;

procedure TTMSFNCCustomPageControl.UpdatePages;
var
  p: TTMSFNCPageControlPage;
  c: TTMSFNCPageControlContainer;
  a: Integer;
  l: Boolean;
begin
  if (FUpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  l := True;

  UpdatePageIndexes;
  UpdateVisibility;

  a := ActivePageIndex;
  if (a >= 0) and (a <= Pages.Count - 1) then
  begin
    p := Pages[a] as TTMSFNCPageControlPage;
    c := p.FContainer;
    if Assigned(c) then
    begin
      UpdateMargins(c);
      if l then
        c.Visible := not Collapsed;

      if Assigned(c.Parent) then
        c.BringToFront;

      c.Invalidate;

      if Assigned(FPrevActivePageContainer) and (FPrevActivePageContainer <> c) and l then
        FPrevActivePageContainer.Visible := False;

      FPrevActivePageContainer := c;
    end;
  end
  else if Assigned(FPrevActivePageContainer) then
  begin
    if l then
      FPrevActivePageContainer.Visible := False;
    FPrevActivePageContainer.SendToBack;
  end;
end;

procedure TTMSFNCCustomPageControl.UpdateTabs(ACalculateScrolling,
  AForceUpdate: Boolean);
begin
  inherited;
  UpdatePages;
end;

procedure TTMSFNCCustomPageControl.UpdateVisibility;
var
  c: TTMSFNCPageControlContainer;
  I: Integer;
begin
  if not FUpdateVisibility or not Collapsed then
    Exit;

  for I := 0 to Pages.Count - 1 do
  begin
    c := (Pages[I] as TTMSFNCPageControlPage).Container;
    if Assigned(c) then
      c.Visible := not Collapsed;
  end;
end;

{ TTMSFNCPageControl }

constructor TTMSFNCPageControl.Create(AOwner: TComponent);
begin
  inherited;
  Width := 500;
  Height := 500;
  FInitialize := IsDesignTime;
end;

procedure TTMSFNCPageControl.InitSample;
begin
  BeginUpdate;

  ResetToDefaultStyle;

//  {$IFDEF FMXLIB}
//  TabAppearance.Fill.Color := $FFF6F8FC;
//  TabAppearance.HoverFill.Color := $FFEEF2F9;
//  TabAppearance.DownFill.Color := $FFA8BCF0;
//  TabAppearance.HoverStroke.Color := $FF2D9BEF;
//  TabAppearance.TextColor := $FF7A7A7A;
//  TabAppearance.ActiveTextColor := $FF454545;
//  {$ENDIF}
//  {$IFNDEF FMXLIB}
//  TabAppearance.Fill.Color := $FCF8F6;
//  TabAppearance.HoverFill.Color := $F9F2EE;
//  TabAppearance.DownFill.Color := $F0BCA8;
//  TabAppearance.HoverStroke.Color := $EF9B2D;
//  TabAppearance.TextColor := $7A7A7A;
//  TabAppearance.ActiveTextColor := $454545;
//  {$ENDIF}
//
//  TabAppearance.ActiveFill.Color :=gcWhite;
//  TabAppearance.HoverTextColor := TabAppearance.TextColor;
//  TabAppearance.DownTextColor := TabAppearance.ActiveTextColor;
//  TabAppearance.DownStroke.Color := TabAppearance.HoverStroke.Color;
//  TabAppearance.HoverFill.Kind := gfkSolid;
//  TabAppearance.DownFill.Kind := gfkSolid;
//  TabAppearance.ActiveFill.Kind := gfkSolid;
//  TabAppearance.Fill.Kind := gfkSolid;
//  TabAppearance.ShowFocus := False;

  Pages.Clear;
  AddPage('Page 1').UseDefaultAppearance := True;
  AddPage('Page 2').UseDefaultAppearance := True;
  AddPage('Page 3').UseDefaultAppearance := True;
  EndUpdate;
end;

procedure TTMSFNCPageControl.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCPageControl, TTMSFNCPageControlPage]);
end;

procedure TTMSFNCPageControl.SetName(const Value: TComponentName);
begin
  inherited;
  if FInitialize then
  begin
    {$IFNDEF LCLLIB}
    InitSample;
    {$ENDIF}
    FInitialize := False;
  end;
end;

{ TTMSFNCPageControlPages }

function TTMSFNCPageControlPages.Add: TTMSFNCPageControlPage;
begin
  Result := TTMSFNCPageControlPage(inherited Add);
end;

procedure TTMSFNCPageControlPages.Clear;
begin
  if Assigned(FPageControl) then
  begin
    FPageControl.FPrevActivePageContainer := nil;
    FPageControl.BeginUpdate;
  end;
  inherited;
  if Assigned(FPageControl) then
    FPageControl.EndUpdate;
end;

constructor TTMSFNCPageControlPages.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  inherited;
  FPageControl := (ATabSet as TTMSFNCCustomPageControl);
end;

function TTMSFNCPageControlPages.GetTabClass: TCollectionItemClass;
begin
  Result := TTMSFNCPageControlPage;
end;

function TTMSFNCPageControlPages.IndexOfContainer(
  AValue: TComponent): Integer;
var
  i: integer;
begin
  Result := -1;
  for i := 0 to Count - 1 do
  begin
    if (Items[i] as TTMSFNCPageControlPage).Container = AValue then
    begin
      Result := i;
      break;
    end;
  end;
end;

function TTMSFNCPageControlPages.Insert(Index: Integer): TTMSFNCPageControlPage;
begin
  Result := TTMSFNCPageControlPage(inherited Insert(Index));
end;

function TTMSFNCPageControlPages.PageControl: TTMSFNCCustomPageControl;
begin
  Result := FPageControl;
end;

{ TTMSFNCPageControlPage }

constructor TTMSFNCPageControlPage.Create(ACollection: TCollection);
var
  t: TTMSFNCCustomPageControl;
begin
  inherited;
  if Assigned(Collection) then
  begin
    FPageControl := (Collection as TTMSFNCPageControlPages).PageControl;

    t := PageControl;
    if Assigned(t) and not (t.IsReading or t.IsLoading or (csLoading in t.ComponentState)) then
    begin
      FContainer := t.GetContainerClass.Create(t.Owner, Self);
      FContainer.PageControl := t;
      FContainer.AdaptToStyle := t.AdaptToStyle;
      FContainer.TabStop := False;
      if t.IsDesigning then
        FContainer.Name := t.Name + 'Page' + IntToStr(Index);
      {$IFDEF FMXLIB}
      FContainer.Align := TAlignLayout.Client;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      {$IFNDEF LCLLIB}
      FContainer.AlignWithMargins := True;
      {$ENDIF}
      FContainer.Align := alClient;
      {$ENDIF}
      {$IFDEF WEBLIB}
      FContainer.Visible := False;
      {$ENDIF}
      t.UpdateMargins(FContainer);
      Text := GetFriendlyName;
    end;
  end;

  if Assigned(FPageControl) then
  begin
    FPageControl.FUpdateMargins := True;
    if Assigned(FPageControl.OnInternalInsertPage) then
      FPageControl.OnInternalInsertPage(Self);
  end;
  UpdatePage;
end;

destructor TTMSFNCPageControlPage.Destroy;
begin
  if Assigned(FContainer) then
  begin
    FContainer.FIsDestroying := True;
    if Assigned(FPageControl) and (FPageControl.FPrevActivePageContainer = FContainer) then
      FPageControl.FPrevActivePageContainer := nil;
    if Assigned(FContainer.Parent) and Assigned(FPageControl) and not FPageControl.IsDestroying then
      FContainer.Free;
    FContainer := nil;
  end;

  inherited;
  if Assigned(FPageControl) then
    FPageControl.FUpdateMargins := True;
  UpdatePage;
end;

function TTMSFNCPageControlPage.GetFriendlyName: String;
begin
  Result := GetStrippedHTMLText;
  if Result = '' then
    Result := 'Page ' + IntToStr(Index);
end;

function TTMSFNCPageControlPage.PageControl: TTMSFNCCustomPageControl;
begin
  Result := FPageControl;
end;

procedure TTMSFNCPageControlPage.UpdatePage;
begin
  if Assigned(FPageControl) then
    FPageControl.UpdatePages;
end;

{ TTMSFNCPageControlContainer }

constructor TTMSFNCPageControlContainer.Create(AOwner: TComponent);
begin
  Create(AOwner, nil);
end;

constructor TTMSFNCPageControlContainer.Create(AOwner: TComponent;
  APage: TTMSFNCPageControlPage);
begin
  inherited Create(AOwner);
  FPage := APage;
  if Assigned(FPage) and Assigned(FPage.FPageControl) then
  begin
    Transparent := FPage.FPageControl.Transparent;
    BufferedPainting := FPage.FPageControl.BufferedPainting;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCPageControlContainer.DefineProperties(Filer: TFiler);
begin
  inherited;
  {$IFDEF LCLLIB}
  Filer.DefineProperty('PageIndex', @ReadPageIndex, @WritePageIndex, True);
  Filer.DefineProperty('IsActive', @ReadIsActive, @WriteIsActive, True);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Filer.DefineProperty('PageIndex', ReadPageIndex, WritePageIndex, True);
  Filer.DefineProperty('IsActive', ReadIsActive, WriteIsActive, True);
  {$ENDIF}
end;
{$ENDIF}

destructor TTMSFNCPageControlContainer.Destroy;
begin
  inherited;
  FPage := nil;
end;

procedure TTMSFNCPageControlContainer.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  p: TTMSFNCCustomPageControl;
  tbd: TTMSFNCTabSetDisplayTab;
  tb: TTMSFNCTabSetTabOpen;
  r: TRectF;
  shz: Single;
begin
  inherited;
  if csDestroying in ComponentState then
    Exit;

  if Assigned(FPage) and Assigned(FPage.FContainer) and (FPage.FContainer = Self) then
  begin
    if IsDesignTime then
    begin
      AGraphics.Font.Height := ScalePaintValue(AGraphics.Font.Height);
      AGraphics.DrawText(LocalRect, FPage.GetFriendlyName, False, gtaCenter, gtaCenter);
    end;

    p := FPage.PageControl;
    if Assigned(p) and (p.ActivePage = FPage) then
    begin
      tbd := p.GetDisplayTab(FPage.Index);
      if Assigned(tbd.Tab) and (tbd.Tab = FPage) then
      begin
        tb := TTMSFNCTabSetTabOpen(tbd.Tab);
        if Assigned(p.DragTabDisplay.Tab) and (p.DragTabDisplay.Tab = FPage) then
          r := tb.GetFixedRect(p.DragTabDisplay.Rect, p.DragTabDisplay)
        else
          r := tb.GetFixedRect(tbd.Rect, tbd);

        if ((r.Right - r.Left) <= 0) or ((r.Bottom - r.Top) <= 0) then
          Exit;

        if p.TabAppearance.ActiveFill.Kind = gfkGradient then
          AGraphics.Stroke.Color := p.TabAppearance.ActiveFill.ColorTo
        else
        begin
          if tb.UseDefaultAppearance then
            AGraphics.Stroke.Color := p.TabAppearance.ActiveFill.Color
          else
            AGraphics.Stroke.Color := tb.ActiveColor;
        end;

        AGraphics.Stroke.Width := AGraphics.Stroke.Width * TTMSFNCUtils.GetDPIScale;

        case p.Layout.Position of
          tlpLeft, tlpRight:
          begin
            r.Top := r.Top + AGraphics.Stroke.Width;
            r.Bottom := r.Bottom - AGraphics.Stroke.Width;
            if p.Collapsed then
            begin
              case p.Layout.Position of
                tlpLeft:
                begin
                  r.Left := 0.5;
                  r.Right := 0.5;
                end;
                tlpRight:
                begin
                  r.Left := Width - 0.5;
                  r.Right := Width - 0.5;
                end;
              end;
            end;
          end;
          tlpTop, tlpBottom:
          begin
            r.Left := r.Left + AGraphics.Stroke.Width;
            r.Right := r.Right - AGraphics.Stroke.Width;
            if p.Collapsed then
            begin
              case p.Layout.Position of
                tlpTop:
                begin
                  r.Top := 0.5;
                  r.Bottom := 0.5;
                end;
                tlpBottom:
                begin
                  r.Top := Height - 0.5;
                  r.Bottom := Height - 0.5;
                end;
              end;
            end;
          end;
        end;

        shz := Round(4 * TTMSFNCUtils.GetDPIScale);

        case p.Layout.Position of
          tlpRight:
          begin
            case tb.GetShape of
              tsPyramidLeft: r.Top := r.Top + shz;
              tsPyramidRight: r.Bottom := r.Bottom - shz;
              tsPyramid:
              begin
                r.Top := r.Top + shz;
                r.Bottom := r.Bottom - shz;
              end;
            end;

            AGraphics.DrawLine(PointF(r.Left - Left, r.Top - Top), PointF(r.Left - Left, r.Bottom - Top), gcpmNone, gcpmNone);
          end;
          tlpLeft:
          begin
            case tb.GetShape of
              tsPyramidLeft: r.Bottom := r.Bottom - shz;
              tsPyramidRight: r.Top := r.Top + shz;
              tsPyramid:
              begin
                r.Top := r.Top + shz;
                r.Bottom := r.Bottom - shz;
              end;
            end;

            AGraphics.DrawLine(PointF(r.Right - Left, r.Top - Top), PointF(r.Right - Left, r.Bottom - Top), gcpmNone, gcpmNone);
          end;
          tlpTop, tlpBottom:
          begin
            case tb.GetShape of
              tsPyramidLeft: r.Left := r.Left + shz;
              tsPyramidRight: r.Right := r.Right - shz;
              tsPyramid:
              begin
                r.Left := r.Left + shz;
                r.Right := r.Right - shz;
              end;
            end;

            case p.Layout.Position of
              tlpTop: AGraphics.DrawLine(PointF(r.Left - Left, r.Bottom - Top), PointF(r.Right - Left, r.Bottom - Top), gcpmNone, gcpmNone);
              tlpBottom: AGraphics.DrawLine(PointF(r.Left - Left, r.Top - Top), PointF(r.Right - Left, r.Top - Top), gcpmNone, gcpmNone);
            end;
          end;
        end;
      end;
    end;
  end;
end;

function TTMSFNCPageControlContainer.GetPageControl: TTMSFNCCustomPageControl;
begin
  Result := FPageControl;
  if not Assigned(Result) and Assigned(Parent) and (Parent is TTMSFNCCustomPageControl) then
    Result := (Parent as TTMSFNCCustomPageControl)
end;

{$IFDEF FMXLIB}
procedure TTMSFNCPageControlContainer.SetParentComponent(Value: TComponent);
{$ENDIF}
{$IFDEF CMNLIB}
procedure TTMSFNCPageControlContainer.SetParent(Value: TWinControl);
{$ENDIF}
{$IFDEF WEBLIB}
procedure TTMSFNCPageControlContainer.SetParent(Value: TControl);
{$ENDIF}
begin
  if Value is TTMSFNCCustomPageControl then
    FPageControl := Value as TTMSFNCCustomPageControl;

  inherited;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCPageControlContainer.ReadIsActive(Reader: TReader);
begin
  FIsActive := Reader.ReadBoolean;
  SetPage;
end;

procedure TTMSFNCPageControlContainer.ReadPageIndex(Reader: TReader);
begin
  FPageIndex := Reader.ReadInteger;
  SetPage;
end;

procedure TTMSFNCPageControlContainer.ReadState(Reader: TReader);
begin
  inherited ReadState(Reader);
  if Reader.Parent is TTMSFNCCustomPageControl then
    PageControl := TTMSFNCCustomPageControl(Reader.Parent);
end;
{$ENDIF}

procedure TTMSFNCPageControlContainer.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCPageControlContainer);
end;

procedure TTMSFNCPageControlContainer.SetIsActive(const Value: Boolean);
begin
  FIsActive := Value;
  {$IFDEF WEBLIB}
  SetPage;
  {$ENDIF}
end;

procedure TTMSFNCPageControlContainer.SetPage;
var
  p: TTMSFNCPageControlPage;
begin
  if not Assigned(FPageControl) then
    Exit;

  if (PageIndex >= 0) and (PageIndex <= FPageControl.Pages.Count - 1) then
  begin
    p := FPageControl.Pages[PageIndex] as TTMSFNCPageControlPage;
    p.FContainer := Self;
    FPage := p;
  end;

  if IsActive then
    FPageControl.ActivePageIndex := Max(0, Min(PageIndex, FPageControl.Pages.Count - 1));

  FPageControl.UpdatePages;
end;

procedure TTMSFNCPageControlContainer.SetPageControl(
  const Value: TTMSFNCCustomPageControl);
begin
  Parent := Value;
  FPageControl := Value;
end;

procedure TTMSFNCPageControlContainer.SetPageIndex(const Value: Integer);
begin
  FPageIndex := Value;
  {$IFDEF WEBLIB}
  SetPage;
  {$ENDIF}
end;

procedure TTMSFNCPageControlContainer.UpdateControlLayout(
  AForceUpdate: Boolean);
begin

end;

{$IFNDEF WEBLIB}
procedure TTMSFNCPageControlContainer.WriteIsActive(Writer: TWriter);
begin
  Writer.WriteBoolean(IsActive);
end;

procedure TTMSFNCPageControlContainer.WritePageIndex(Writer: TWriter);
begin
  Writer.WriteInteger(PageIndex);
end;
{$ENDIF}

{ TTMSFNCPageControlPopup }

function TTMSFNCPageControlPopup.GetPopupFormClass: TTMSFNCCustomPopupFormClass;
begin
  Result := TTMSFNCPageControlPopupForm;
end;

initialization
  RegisterClass(TTMSFNCPageControlContainer);

end.

unit uComponent;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCPageControl,
  Vcl.Controls, VCL.TMSFNCCustomControl, VCL.TMSFNCTabSet, VCL.TMSFNCPanel;

type
  TComponentForm = class(TForm)
    TMSFNCPageControl1: TTMSFNCPageControl;
    TMSFNCPageControl1Page2: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page1: TTMSFNCPageControlContainer;
    TMSFNCPageControl1Page0: TTMSFNCPageControlContainer;
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCPanel2: TTMSFNCPanel;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ComponentForm: TComponentForm;

implementation

{$R *.dfm}

procedure TComponentForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCPageControl1 := TTMSFNCPageControl.Create(Self);
  TMSFNCPageControl1Page2 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControl1Page1 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPageControl1Page0 := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);

  TMSFNCPageControl1.BeforeLoadDFMValues;
  TMSFNCPageControl1Page2.BeforeLoadDFMValues;
  TMSFNCPageControl1Page1.BeforeLoadDFMValues;
  TMSFNCPageControl1Page0.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  try
    Name := 'ComponentForm';
    Width := 640;
    Height := 480;
    TMSFNCPageControl1.SetParentComponent(Self);
    TMSFNCPageControl1.Name := 'TMSFNCPageControl1';
    TMSFNCPageControl1.AlignWithMargins := True;
    TMSFNCPageControl1.Left := 10;
    TMSFNCPageControl1.Top := 10;
    TMSFNCPageControl1.Width := 300;
    TMSFNCPageControl1.Height := 200;
    TMSFNCPageControl1.Margins.Left := 10;
    TMSFNCPageControl1.Margins.Top := 0;
    TMSFNCPageControl1.Margins.Right := 10;
    TMSFNCPageControl1.Margins.Bottom := 8;
    TMSFNCPageControl1.BevelEdges := [];
    TMSFNCPageControl1.BevelInner := bvNone;
    TMSFNCPageControl1.BevelOuter := bvNone;
    TMSFNCPageControl1.ParentDoubleBuffered := False;
    TMSFNCPageControl1.Color := clBtnFace;
    TMSFNCPageControl1.DoubleBuffered := True;
    TMSFNCPageControl1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl1.Font.Color := clWindowText;
    TMSFNCPageControl1.Font.Height := -16;
    TMSFNCPageControl1.Font.Name := 'Tahoma';
    TMSFNCPageControl1.Font.Style := [];
    TMSFNCPageControl1.ParentFont := False;
    TMSFNCPageControl1.TabOrder := 0;
    TMSFNCPageControl1.Fill.Kind := gfkNone;
    TMSFNCPageControl1.Fill.ColorTo := clWhite;
    TMSFNCPageControl1.Stroke.Kind := gskNone;
    TMSFNCPageControl1.TabAppearance.Font.Charset := ANSI_CHARSET;
    TMSFNCPageControl1.TabAppearance.Font.Color := clAqua;
    TMSFNCPageControl1.TabAppearance.Font.Height := -16;
    TMSFNCPageControl1.TabAppearance.Font.Name := 'Segoe UI';
    TMSFNCPageControl1.TabAppearance.Font.Style := [];
    TMSFNCPageControl1.TabAppearance.Fill.Color := 14408667;
    TMSFNCPageControl1.TabAppearance.Stroke.Color := -1;
    TMSFNCPageControl1.TabAppearance.ActiveFill.ColorTo := clWhite;
    TMSFNCPageControl1.TabAppearance.HoverFill.Color := 15658734;
    TMSFNCPageControl1.TabAppearance.HoverStroke.Color := -1;
    TMSFNCPageControl1.TabAppearance.TextSpacing := 10.000000000000000000;
    TMSFNCPageControl1.TabAppearance.Shape := tsRectangle;
    TMSFNCPageControl1.TabAppearance.ShapeOverlap := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.ShapeSlope := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.ShapeRounding := 0.000000000000000000;
    TMSFNCPageControl1.TabAppearance.TextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.ActiveTextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.HoverTextColor := clGrayText;
    TMSFNCPageControl1.TabAppearance.ShowFocus := False;
    TMSFNCPageControl1.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Color := 139;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Height := -11;
    TMSFNCPageControl1.TabAppearance.BadgeFont.Name := 'Tahoma';
    TMSFNCPageControl1.TabAppearance.BadgeFont.Style := [fsBold];
    TMSFNCPageControl1.ButtonAppearance.CloseIcon.LoadFromFile('uComponent.TMSFNCPageControl1.ButtonAppearance.CloseIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.InsertIcon.LoadFromFile('uComponent.TMSFNCPageControl1.ButtonAppearance.InsertIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.TabListIcon.LoadFromFile('uComponent.TMSFNCPageControl1.ButtonAppearance.TabListIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.ScrollNextIcon.LoadFromFile('uComponent.TMSFNCPageControl1.ButtonAppearance.ScrollNextIcon.svg');
    TMSFNCPageControl1.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uComponent.TMSFNCPageControl1.ButtonAppearance.ScrollPreviousIcon.svg');
    TMSFNCPageControl1.TabSize.Height := 45.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Left := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Top := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Right := 8.000000000000000000;
    TMSFNCPageControl1.TabSize.Margins.Bottom := 8.000000000000000000;
    TMSFNCPageControl1.Pages.Clear;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'Mercedes';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'Reports';
      UseDefaultAppearance := True;
    end;
    with TMSFNCPageControl1.Pages.Add do
    begin
      Text := 'BMW';
      UseDefaultAppearance := True;
    end;
    TMSFNCPageControl1Page2.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page2.Name := 'TMSFNCPageControl1Page2';
    TMSFNCPageControl1Page2.AlignWithMargins := True;
    TMSFNCPageControl1Page2.Left := 0;
    TMSFNCPageControl1Page2.Top := 52;
    TMSFNCPageControl1Page2.Width := 300;
    TMSFNCPageControl1Page2.Height := 148;
    TMSFNCPageControl1Page2.Margins.Left := 0;
    TMSFNCPageControl1Page2.Margins.Top := 52;
    TMSFNCPageControl1Page2.Margins.Right := 0;
    TMSFNCPageControl1Page2.Margins.Bottom := 0;
    TMSFNCPageControl1Page2.Align := alClient;
    TMSFNCPageControl1Page2.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page2.DoubleBuffered := True;
    TMSFNCPageControl1Page2.TabStop := False;
    TMSFNCPageControl1Page2.TabOrder := 2;
    TMSFNCPageControl1Page2.Visible := False;
    TMSFNCPageControl1Page2.PageIndex := 2;
    TMSFNCPageControl1Page2.IsActive := False;
    TMSFNCPageControl1Page1.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page1.Name := 'TMSFNCPageControl1Page1';
    TMSFNCPageControl1Page1.AlignWithMargins := True;
    TMSFNCPageControl1Page1.Left := 0;
    TMSFNCPageControl1Page1.Top := 52;
    TMSFNCPageControl1Page1.Width := 300;
    TMSFNCPageControl1Page1.Height := 148;
    TMSFNCPageControl1Page1.Margins.Left := 0;
    TMSFNCPageControl1Page1.Margins.Top := 52;
    TMSFNCPageControl1Page1.Margins.Right := 0;
    TMSFNCPageControl1Page1.Margins.Bottom := 0;
    TMSFNCPageControl1Page1.Align := alClient;
    TMSFNCPageControl1Page1.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page1.DoubleBuffered := True;
    TMSFNCPageControl1Page1.TabStop := False;
    TMSFNCPageControl1Page1.TabOrder := 1;
    TMSFNCPageControl1Page1.Visible := False;
    TMSFNCPageControl1Page1.PageIndex := 1;
    TMSFNCPageControl1Page1.IsActive := False;
    TMSFNCPageControl1Page0.SetParentComponent(TMSFNCPageControl1);
    TMSFNCPageControl1Page0.Name := 'TMSFNCPageControl1Page0';
    TMSFNCPageControl1Page0.AlignWithMargins := True;
    TMSFNCPageControl1Page0.Left := 0;
    TMSFNCPageControl1Page0.Top := 52;
    TMSFNCPageControl1Page0.Width := 300;
    TMSFNCPageControl1Page0.Height := 148;
    TMSFNCPageControl1Page0.Margins.Left := 0;
    TMSFNCPageControl1Page0.Margins.Top := 52;
    TMSFNCPageControl1Page0.Margins.Right := 0;
    TMSFNCPageControl1Page0.Margins.Bottom := 0;
    TMSFNCPageControl1Page0.Align := alClient;
    TMSFNCPageControl1Page0.ParentDoubleBuffered := False;
    TMSFNCPageControl1Page0.DoubleBuffered := True;
    TMSFNCPageControl1Page0.TabStop := False;
    TMSFNCPageControl1Page0.TabOrder := 0;
    TMSFNCPageControl1Page0.PageIndex := 0;
    TMSFNCPageControl1Page0.IsActive := True;
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.Left := 400;
    TMSFNCPanel1.Top := 62;
    TMSFNCPanel1.Width := 168;
    TMSFNCPanel1.Height := 83;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 1;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    TMSFNCPanel2.SetParentComponent(Self);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 1;
    TMSFNCPanel2.Left := 400;
    TMSFNCPanel2.Top := 198;
    TMSFNCPanel2.Width := 168;
    TMSFNCPanel2.Height := 83;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 2;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Header.Fill.Color := 16380654;
    TMSFNCPanel2.Header.Stroke.Kind := gskNone;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.Footer.Fill.Color := 16380654;
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
  finally
    TMSFNCPageControl1.AfterLoadDFMValues;
    TMSFNCPageControl1Page2.AfterLoadDFMValues;
    TMSFNCPageControl1Page1.AfterLoadDFMValues;
    TMSFNCPageControl1Page0.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
  end;
end;

end.
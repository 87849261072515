unit uPOIGroupList;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, WEBLib.CDS, WEBLIB.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, WEBLib.Grids,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCScrollBar, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCGridDatabaseAdapter, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  VCL.TMSFNCPanel;

type
  TPOIGroupListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MyDataset: TClientDataSet;
    MyDataSource: TDataSource;
    [async]
    StatusBarPanel: TPanel;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    ReportGrid: TTMSFNCGrid;
    DatasetTimer: TTimer;
    ToolbarPanel: TPanel;
    FilterEdit: TEdit;
    SearchTimer: TTimer;
    CloseButton: TSpeedButton;
    EditButton: TSpeedButton;
    AddButton: TSpeedButton;
    DeleteButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    MyWebConnection: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    SelectButton: TSpeedButton;
    MyDatasetPKey: TStringField;
    MyDatasetID: TStringField;
    ListButtonOnlinePanel: TTMSFNCPanel;
    MyDatasetPicture: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure AddButtonClick(Sender: TObject); async;
    [async]
    procedure EditButtonClick(Sender: TObject); async;
    procedure DatasetTimerTimer(Sender: TObject);
    procedure MyDatasetAfterOpen(DataSet: TDataSet);
    procedure FilterEditChange(Sender: TObject);
    procedure SearchTimerTimer(Sender: TObject);
    [async]
    [async]
    procedure DeleteButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    procedure SelectButtonClick(Sender: TObject);
    procedure ReportGridDblClick(Sender: TObject);
  private
    FocusPKey: Int64;
    { Private declarations }
    [async]
    procedure LoadList(SearchValue: String); async;
    [async]
    procedure ShowEditDialog(PKey: Integer); async;
    [async]
    procedure DeleteRecord(PKey: Integer); async;
  public
    PKey: String;
  protected procedure LoadDFMValues; override; end;

var
  POIGroupListForm: TPOIGroupListForm;

implementation

{$R *.dfm}

uses uCommon, uPOIGroupEdit;

procedure TPOIGroupListForm.WebFormCreate(Sender: TObject);
begin
  FocusPKey := -1;
  SetGridStyle(ReportGrid, False);
end;

procedure TPOIGroupListForm.WebFormShow(Sender: TObject);
begin
  FilterEdit.SetFocus();
  SearchTimer.Enabled := True;
//LoadList(EmptyStr);
end;

procedure TPOIGroupListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TPOIGroupListForm.DeleteButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if MyDataSet.Active and not(MyDataSet.IsEmpty) then begin
    if MyDataset.FieldByName('PKey').AsInteger > 0 then begin
      MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog('Delete alert?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
      if MyModalResult = mrYes then begin
        DeleteRecord(MyDataset.FieldByName('PKey').AsInteger);
      end;
    end else begin
      MessageDlg('This group can''t be deleted.', TMsgDlgType.mtError, [mbOK]);
    end;
  end;
end;

procedure TPOIGroupListForm.AddButtonClick(Sender: TObject);
begin
  await(ShowEditDialog(-1));
end;

procedure TPOIGroupListForm.EditButtonClick(Sender: TObject);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    await(ShowEditDialog(MyDataset.FieldByName('PKey').AsInteger));
  end;
end;

procedure TPOIGroupListForm.MyDatasetAfterOpen(DataSet: TDataSet);
begin
  // Focus Record
  if (FocusPKey = -1) then begin
    MyDataset.First;
  end else begin
    MyDataset.Locate('PKey', FocusPKey, []);
  end;
  GridDatabaseAdapter.Active := True;
  DatasetTimer.Enabled := True;
end;

procedure TPOIGroupListForm.ReportGridDblClick(Sender: TObject);
begin
  if EditButton.Visible then begin
    EditButton.Click();
  end;
end;

procedure TPOIGroupListForm.FilterEditChange(Sender: TObject);
begin
  FocusPKey := -1;
  SearchTimer.Enabled := False;
  SearchTimer.Enabled := True;
end;

procedure TPOIGroupListForm.DatasetTimerTimer(Sender: TObject);
begin
  DatasetTimer.Enabled := False;
  ReportGrid.UpdateGridCells;
  WebWaitMessage.Hide;
end;

procedure TPOIGroupListForm.LoadList(SearchValue: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_poi_group_list.php?sessionid=' + SessionId;
    if Length(SearchValue) > 0 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&q=' + SearchValue;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  MyDataset.Close;
  MyDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(MyDataset.Open);
end;

procedure TPOIGroupListForm.SearchTimerTimer(Sender: TObject);
begin
  if not(WebWaitMessage.Showing) then begin
    SearchTimer.Enabled := False;
    LoadList(FilterEdit.Text);
  end;
end;

procedure TPOIGroupListForm.SelectButtonClick(Sender: TObject);
begin
  if MyDataset.Active and not(MyDataset.IsEmpty) then begin
    PKey := Trim(MyDataset.FieldByName('PKey').AsString);
    ModalResult := mrOK;
  end;
end;

procedure TPOIGroupListForm.ShowEditDialog(PKey: Integer);
var
  MyWebForm: TPOIGroupEditForm;
  MyModalResult: TModalResult;
begin
  if
    MyDataset.Active
  and
    (
      (PKey = -1)
    or
      ((PKey >= 0) and not(MyDataset.IsEmpty))
    )
  then begin
    MyWebForm := TPOIGroupEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.PKey := PKey;
    await(TPOIGroupEditForm, MyWebForm.Load());
    try
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        FocusPKey := MyWebForm.PKey;
        LoadList(FilterEdit.Text);
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TPOIGroupListForm.DeleteRecord(PKey: Integer);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_poi_group_delete.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      if MyJO.GetJSONValue('status') = 'OK' then begin
        FocusPKey := -1;
        SearchTimer.Enabled := True;
      end else begin
        MessageDlg(MyJO.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TPOIGroupListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ReportGrid := TTMSFNCGrid.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  FilterEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  EditButton := TSpeedButton.Create(Self);
  AddButton := TSpeedButton.Create(Self);
  DeleteButton := TSpeedButton.Create(Self);
  SelectButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyDataset := TClientDataSet.Create(Self);
  MyDatasetPKey := TStringField.Create(Self);
  MyDatasetID := TStringField.Create(Self);
  MyDatasetPicture := TStringField.Create(Self);
  MyDataSource := TDataSource.Create(Self);
  DatasetTimer := TTimer.Create(Self);
  SearchTimer := TTimer.Create(Self);
  MyWebConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ReportGrid.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  FilterEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  DeleteButton.BeforeLoadDFMValues;
  SelectButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyDataset.BeforeLoadDFMValues;
  MyDatasetPKey.BeforeLoadDFMValues;
  MyDatasetID.BeforeLoadDFMValues;
  MyDatasetPicture.BeforeLoadDFMValues;
  MyDataSource.BeforeLoadDFMValues;
  DatasetTimer.BeforeLoadDFMValues;
  SearchTimer.BeforeLoadDFMValues;
  MyWebConnection.BeforeLoadDFMValues;
  try
    Name := 'POIGroupListForm';
    Width := 710;
    Height := 628;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 710;
    FormPanel.Height := 628;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    FormPanel.ElementFont := efCSS;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 704;
    ContentPanel.Height := 585;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ReportGrid.SetParentComponent(ContentPanel);
    ReportGrid.Name := 'ReportGrid';
    ReportGrid.AlignWithMargins := True;
    ReportGrid.Left := 20;
    ReportGrid.Top := 54;
    ReportGrid.Width := 664;
    ReportGrid.Height := 472;
    ReportGrid.Margins.Left := 20;
    ReportGrid.Margins.Top := 0;
    ReportGrid.Margins.Right := 20;
    ReportGrid.Margins.Bottom := 10;
    ReportGrid.AdaptToStyle := True;
    ReportGrid.Align := alClient;
    ReportGrid.BevelInner := bvNone;
    ReportGrid.BevelOuter := bvNone;
    ReportGrid.BevelWidth := 2;
    ReportGrid.Ctl3D := False;
    ReportGrid.ParentCtl3D := False;
    ReportGrid.ParentDoubleBuffered := False;
    ReportGrid.DoubleBuffered := True;
    ReportGrid.Font.Charset := DEFAULT_CHARSET;
    ReportGrid.Font.Color := clWindowText;
    ReportGrid.Font.Height := -11;
    ReportGrid.Font.Name := 'Tahoma';
    ReportGrid.Font.Style := [];
    ReportGrid.ParentFont := False;
    ReportGrid.TabOrder := 1;
    SetEvent(ReportGrid, Self, 'OnDblClick', 'ReportGridDblClick');
    ReportGrid.DefaultRowHeight := 34.000000000000000000;
    ReportGrid.FixedColumns := 0;
    ReportGrid.ColumnCount := 2;
    ReportGrid.RowCount := 1;
    ReportGrid.Options.Borders.FixedCellBorders := bNone;
    ReportGrid.Options.ColumnSize.Stretch := True;
    ReportGrid.Options.ColumnSize.StretchColumn := 0;
    ReportGrid.Options.Editing.CalcFormat := '%g';
    ReportGrid.Options.Grouping.CalcFormat := '%g';
    ReportGrid.Options.Grouping.GroupCountFormat := '(%d)';
    ReportGrid.Options.HTMLExport.CellPadding := 10;
    ReportGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    ReportGrid.Options.Mouse.WheelScrollKeepSelection := True;
    ReportGrid.Options.Mouse.ClickMargin := 0;
    ReportGrid.Options.Mouse.ColumnSizeMargin := 6;
    ReportGrid.Options.Mouse.RowSizeMargin := 6;
    ReportGrid.Options.URL.Color := clSkyBlue;
    ReportGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    ReportGrid.Options.Selection.Mode := smSingleRow;
    ReportGrid.Adapter := GridDatabaseAdapter;
    ReportGrid.HorizontalScrollBarVisible := False;
    ReportGrid.Columns.Clear;
    with ReportGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 595.000000000000000000;
    end;
    with ReportGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 68.000000000000000000;
    end;
    ReportGrid.Fill.ColorTo := clSilver;
    ReportGrid.DefaultFont.Charset := ANSI_CHARSET;
    ReportGrid.DefaultFont.Color := clWindowText;
    ReportGrid.DefaultFont.Height := -15;
    ReportGrid.DefaultFont.Name := 'Segoe UI';
    ReportGrid.DefaultFont.Style := [];
    ReportGrid.Stroke.Color := 15987699;
    ReportGrid.Stroke.Width := 2.000000000000000000;
    ReportGrid.TopRow := 1;
    ReportGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    ReportGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    ReportGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.FixedLayout.Font.Color := clWhite;
    ReportGrid.Appearance.FixedLayout.Font.Height := -16;
    ReportGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.FixedLayout.Font.Style := [];
    ReportGrid.Appearance.NormalLayout.Fill.Color := -1;
    ReportGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    ReportGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.NormalLayout.Font.Color := clGray;
    ReportGrid.Appearance.NormalLayout.Font.Height := -11;
    ReportGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.NormalLayout.Font.Style := [];
    ReportGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    ReportGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    ReportGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    ReportGrid.Appearance.GroupLayout.Font.Color := clBlack;
    ReportGrid.Appearance.GroupLayout.Font.Height := -11;
    ReportGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    ReportGrid.Appearance.GroupLayout.Font.Style := [];
    ReportGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    ReportGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    ReportGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    ReportGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    ReportGrid.Appearance.SummaryLayout.Font.Height := -11;
    ReportGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    ReportGrid.Appearance.SummaryLayout.Font.Style := [];
    ReportGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    ReportGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    ReportGrid.Appearance.SelectedLayout.Font.Height := -16;
    ReportGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.SelectedLayout.Font.Style := [];
    ReportGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    ReportGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    ReportGrid.Appearance.FocusedLayout.Font.Height := -16;
    ReportGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.FocusedLayout.Font.Style := [];
    ReportGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    ReportGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    ReportGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    ReportGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    ReportGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    ReportGrid.Appearance.BandLayout.Fill.Color := 14062646;
    ReportGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    ReportGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    ReportGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    ReportGrid.Appearance.BandLayout.Font.Color := clBlack;
    ReportGrid.Appearance.BandLayout.Font.Height := -16;
    ReportGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    ReportGrid.Appearance.BandLayout.Font.Style := [];
    ReportGrid.Appearance.ProgressLayout.Color := 15385233;
    ReportGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    ReportGrid.LeftCol := 0;
    ReportGrid.ScrollMode := scmItemScrolling;
    ReportGrid.GlobalFont.Scale := 1.000000000000000000;
    ReportGrid.GlobalFont.Style := [];
    ReportGrid.DesignTimeSampleData := True;
    WebMessageDlg1.SetParentComponent(ReportGrid);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 51;
    WebMessageDlg1.Top := 392;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 349;
    GridDatabaseAdapter.Top := 447;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := ReportGrid;
    GridDatabaseAdapter.DataSource := MyDataSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'Picture';
      Header := '  Picture';
      PictureField := True;
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    ToolbarPanel.SetParentComponent(ContentPanel);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 704;
    ToolbarPanel.Height := 54;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    FilterEdit.SetParentComponent(ToolbarPanel);
    FilterEdit.Name := 'FilterEdit';
    FilterEdit.Left := 20;
    FilterEdit.Top := 10;
    FilterEdit.Width := 345;
    FilterEdit.Height := 34;
    FilterEdit.AutoFocus := True;
    FilterEdit.ChildOrder := 1;
    FilterEdit.Font.Charset := ANSI_CHARSET;
    FilterEdit.Font.Color := clWindowText;
    FilterEdit.Font.Height := -16;
    FilterEdit.Font.Name := 'Segoe UI';
    FilterEdit.Font.Style := [];
    FilterEdit.HeightPercent := 100.000000000000000000;
    FilterEdit.ParentFont := False;
    FilterEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FilterEdit, Self, 'OnChange', 'FilterEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 536;
    StatusBarPanel.Width := 704;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 584;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uPOIGroupList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    EditButton.SetParentComponent(StatusBarPanel);
    EditButton.Name := 'EditButton';
    EditButton.AlignWithMargins := True;
    EditButton.Left := 244;
    EditButton.Top := 8;
    EditButton.Width := 100;
    EditButton.Height := 31;
    EditButton.Margins.Left := 0;
    EditButton.Margins.Top := 8;
    EditButton.Margins.Right := 8;
    EditButton.Margins.Bottom := 10;
    EditButton.Align := alLeft;
    EditButton.Caption := 'Edit';
    EditButton.Color := clNone;
    EditButton.ElementClassName := 'MyWebButton';
    EditButton.Glyph.LoadFromFile('uPOIGroupList.StatusBarPanel.EditButton.Glyph.png');
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.TabOrder := 1;
    EditButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditButton, Self, 'OnClick', 'EditButtonClick');
    AddButton.SetParentComponent(StatusBarPanel);
    AddButton.Name := 'AddButton';
    AddButton.AlignWithMargins := True;
    AddButton.Left := 136;
    AddButton.Top := 8;
    AddButton.Width := 100;
    AddButton.Height := 31;
    AddButton.Margins.Left := 0;
    AddButton.Margins.Top := 8;
    AddButton.Margins.Right := 8;
    AddButton.Margins.Bottom := 10;
    AddButton.Align := alLeft;
    AddButton.Caption := 'Add';
    AddButton.Color := clNone;
    AddButton.ElementClassName := 'MyWebButton';
    AddButton.Glyph.LoadFromFile('uPOIGroupList.StatusBarPanel.AddButton.Glyph.png');
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.TabOrder := 2;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    DeleteButton.SetParentComponent(StatusBarPanel);
    DeleteButton.Name := 'DeleteButton';
    DeleteButton.AlignWithMargins := True;
    DeleteButton.Left := 352;
    DeleteButton.Top := 8;
    DeleteButton.Width := 100;
    DeleteButton.Height := 31;
    DeleteButton.Margins.Left := 0;
    DeleteButton.Margins.Top := 8;
    DeleteButton.Margins.Right := 8;
    DeleteButton.Margins.Bottom := 10;
    DeleteButton.Align := alLeft;
    DeleteButton.Caption := 'Delete';
    DeleteButton.Color := clNone;
    DeleteButton.ElementClassName := 'MyWebButton';
    DeleteButton.Glyph.LoadFromFile('uPOIGroupList.StatusBarPanel.DeleteButton.Glyph.png');
    DeleteButton.HeightPercent := 100.000000000000000000;
    DeleteButton.TabOrder := 3;
    DeleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteButton, Self, 'OnClick', 'DeleteButtonClick');
    SelectButton.SetParentComponent(StatusBarPanel);
    SelectButton.Name := 'SelectButton';
    SelectButton.AlignWithMargins := True;
    SelectButton.Left := 20;
    SelectButton.Top := 8;
    SelectButton.Width := 100;
    SelectButton.Height := 31;
    SelectButton.Margins.Left := 0;
    SelectButton.Margins.Top := 8;
    SelectButton.Margins.Right := 16;
    SelectButton.Margins.Bottom := 10;
    SelectButton.Align := alLeft;
    SelectButton.Caption := 'Select';
    SelectButton.Color := clNone;
    SelectButton.ElementClassName := 'MyWebButton';
    SelectButton.Glyph.LoadFromFile('uPOIGroupList.StatusBarPanel.SelectButton.Glyph.png');
    SelectButton.HeightPercent := 100.000000000000000000;
    SelectButton.TabOrder := 4;
    SelectButton.Visible := False;
    SelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(SelectButton, Self, 'OnClick', 'SelectButtonClick');
    ListButtonOnlinePanel.SetParentComponent(StatusBarPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 41;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 5;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 710;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 123;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'POI Group - Lists';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 681;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uPOIGroupList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 124;
    WebWaitMessage.Top := 489;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uPOIGroupList.WebWaitMessage.Picture.gif');
    MyDataset.SetParentComponent(Self);
    MyDataset.Name := 'MyDataset';
    MyDataset.AfterOpen := MyDatasetAfterOpen;
    MyDataset.Left := 412;
    MyDataset.Top := 488;
    MyDatasetPKey.SetParentComponent(MyDataset);
    MyDatasetPKey.Name := 'MyDatasetPKey';
    MyDatasetPKey.FieldName := 'PKey';
    MyDatasetPKey.Visible := False;
    MyDatasetPKey.Size := 256;
    MyDatasetID.SetParentComponent(MyDataset);
    MyDatasetID.Name := 'MyDatasetID';
    MyDatasetID.FieldName := 'ID';
    MyDatasetID.Size := 256;
    MyDatasetPicture.SetParentComponent(MyDataset);
    MyDatasetPicture.Name := 'MyDatasetPicture';
    MyDatasetPicture.FieldName := 'Picture';
    MyDatasetPicture.Size := 256;
    MyDataSource.SetParentComponent(Self);
    MyDataSource.Name := 'MyDataSource';
    MyDataSource.DataSet := MyDataset;
    MyDataSource.Left := 484;
    MyDataSource.Top := 488;
    DatasetTimer.SetParentComponent(Self);
    DatasetTimer.Name := 'DatasetTimer';
    DatasetTimer.Enabled := False;
    DatasetTimer.Interval := 100;
    SetEvent(DatasetTimer, Self, 'OnTimer', 'DatasetTimerTimer');
    DatasetTimer.Left := 283;
    DatasetTimer.Top := 488;
    SearchTimer.SetParentComponent(Self);
    SearchTimer.Name := 'SearchTimer';
    SearchTimer.Enabled := False;
    SearchTimer.Interval := 300;
    SetEvent(SearchTimer, Self, 'OnTimer', 'SearchTimerTimer');
    SearchTimer.Left := 195;
    SearchTimer.Top := 488;
    MyWebConnection.SetParentComponent(Self);
    MyWebConnection.Name := 'MyWebConnection';
    MyWebConnection.Active := False;
    MyWebConnection.Left := 191;
    MyWebConnection.Top := 350;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ReportGrid.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    FilterEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    DeleteButton.AfterLoadDFMValues;
    SelectButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyDataset.AfterLoadDFMValues;
    MyDatasetPKey.AfterLoadDFMValues;
    MyDatasetID.AfterLoadDFMValues;
    MyDatasetPicture.AfterLoadDFMValues;
    MyDataSource.AfterLoadDFMValues;
    DatasetTimer.AfterLoadDFMValues;
    SearchTimer.AfterLoadDFMValues;
    MyWebConnection.AfterLoadDFMValues;
  end;
end;

end.

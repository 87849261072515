unit uCompanyEdit;

interface

uses
  System.SysUtils, System.Classes, System.StrUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs,
  Data.DB, WEBLib.DB, WEBLib.CDS, WEBLib.RegularExpressions, WEBLib.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, WEBLIB.REST, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, VCL.TMSFNCCustomControl,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter,
  WEBLib.Buttons, Vcl.Imaging.GIFImg;

type
  TCompanyEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    StatusBarPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    CompanyDataset: TClientDataSet;
    CompanySource: TDataSource;
    TopPanel: TPanel;
    LoadTimer: TTimer;
    UsernameLabel: TLabel;
    CompanyNameEdit: TEdit;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    CompanyGrid: TTMSFNCGrid;
    ShowTimer: TTimer;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebLabel1: TLabel;
    CompanyDatasetDepartmentPKey: TStringField;
    CompanyDatasetDepartmentName: TStringField;
    CompanyDatasetDepartmentMail: TStringField;
    MyMessageDlg: TMessageDlg;
    procedure WebFormShow(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure LoadTimerTimer(Sender: TObject);
    [async]
    procedure FilterDBGridKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState); async;
    procedure CompanyDatasetAfterOpen(DataSet: TDataSet);
    procedure ShowTimerTimer(Sender: TObject);
    [async]
    procedure CloseButtonClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure CompanyGridCellEditValidateData(Sender: TObject; ACol,
      ARow: Integer; CellEditor: TWinControl; var CellString: string;
      var Allow: Boolean);
    procedure RecordChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadData(); async;
    [async]
    function SaveCompany(): Boolean; async;
    function ValidInput(): Boolean;
  public
    { Public declarations }
    PKey: Integer;
    IsSet: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  CompanyEditForm: TCompanyEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TCompanyEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Visible := False;
end;

procedure TCompanyEditForm.CompanyDatasetAfterOpen(DataSet: TDataSet);
begin
  GridDatabaseAdapter.Active := True;
  LoadTimer.Enabled := True;
end;

procedure TCompanyEditForm.FilterDBGridKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
{
  if Key = VK_Space then begin
    if FilterDataset.Active and not(FilterDataset.IsEmpty) then begin
      FilterDataset.Edit;
      FilterDataset.FieldByName('object_groep_checkbox').AsInteger :=
        Abs(FilterDataset.FieldByName('object_groep_checkbox').AsInteger - 1);
      FilterDataset.Post;
    end;
  end;
}
end;

procedure TCompanyEditForm.CompanyGridCellEditValidateData(Sender: TObject;
  ACol, ARow: Integer; CellEditor: TWinControl; var CellString: string;
  var Allow: Boolean);
var
  ErrorMessage: String;
begin
  if ACol = 1 then begin
    // Your Email
    if not(Trim(CellString)).IsEmpty then begin
      try
        Allow := TRegEx.IsMatch(Trim(CellString), RegExEmail);
        if not(Allow) then ErrorMessage := 'Your Email is not valid.';
      except
        on E: Exception do begin
          Allow := False;
          ErrorMessage := E.Message;
        end;
      end;
    end;
  end;
end;

procedure TCompanyEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TCompanyEditForm.WebFormCreate(Sender: TObject);
begin
  IsSet := False;
  PKey := 0;
  SetGridStyle(CompanyGrid, True);
end;

procedure TCompanyEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TCompanyEditForm.LoadTimerTimer(Sender: TObject);
begin
  LoadTimer.Enabled := False;
  CompanyDataset.First;
  WebWaitMessage.Hide;
end;

procedure TCompanyEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_company_edit.php?' +
      'sessionid=' + SessionId + '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  CompanyDataset.Close;
  CompanyDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
  CompanyDataset.Open;
end;

procedure TCompanyEditForm.CloseButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TCompanyEditForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

function TCompanyEditForm.ValidInput(): Boolean;
begin
  Result := True;
end;

procedure TCompanyEditForm.SaveButtonClick(Sender: TObject);
begin
  if ValidInput() then begin
    if await(SaveCompany()) then begin
      ModalResult := mrOK;
    end;
  end;
end;

function TCompanyEditForm.SaveCompany(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyString: String;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  // Get Values
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_company_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyString :=
      '{' +
        '"sessionid":"' + SessionID + '",' +
        '"pkey":"' + IntToStr(PKey) + '",' +
        '"name":"' + Trim(CompanyNameEdit.Text) + '",' +
        '"departments":[';
    CompanyDataset.First;
    while not(CompanyDataset.Eof) do begin
      if CompanyDataset.RecNo > 1 then MyString := MyString + ',';
      MyString := MyString +
        '{' +
          '"pkey":"' + CompanyDataset.FieldByName('DepartmentPKey').AsString + '",' +
          '"mail":"' + CompanyDataset.FieldByName('DepartmentMail').AsString + '"' +
        '}';
       CompanyDataset.Next;
    end;
    MyString := MyString +
        ']' +
      '}';
    // Post Request
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      PKey := StrToInt(MyJO.GetJSONValue('pkey'));
      Result := PKey > 0;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;

end;

procedure TCompanyEditForm.ShowTimerTimer(Sender: TObject);
begin
  if IsSet then begin
    ShowTimer.Enabled := False;
    // Set Focus
    if PKey = 0 then begin
      CompanyNameEdit.SetFocus;
    end else begin
      CompanyGrid.SetFocus;
    end;
    // Load Filter Data
    LoadData();
  end;
end;

procedure TCompanyEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  TopPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  CompanyNameEdit := TEdit.Create(Self);
  CompanyGrid := TTMSFNCGrid.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  CompanyDataset := TClientDataSet.Create(Self);
  CompanyDatasetDepartmentPKey := TStringField.Create(Self);
  CompanyDatasetDepartmentName := TStringField.Create(Self);
  CompanyDatasetDepartmentMail := TStringField.Create(Self);
  CompanySource := TDataSource.Create(Self);
  LoadTimer := TTimer.Create(Self);
  ShowTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  CompanyNameEdit.BeforeLoadDFMValues;
  CompanyGrid.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  CompanyDataset.BeforeLoadDFMValues;
  CompanyDatasetDepartmentPKey.BeforeLoadDFMValues;
  CompanyDatasetDepartmentName.BeforeLoadDFMValues;
  CompanyDatasetDepartmentMail.BeforeLoadDFMValues;
  CompanySource.BeforeLoadDFMValues;
  LoadTimer.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  try
    Name := 'CompanyEditForm';
    Width := 786;
    Height := 544;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 786;
    FormPanel.Height := 544;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 780;
    ContentPanel.Height := 501;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := ANSI_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Segoe UI';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    TopPanel.SetParentComponent(ContentPanel);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 0;
    TopPanel.Width := 780;
    TopPanel.Height := 129;
    TopPanel.Align := alTop;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 2;
    TopPanel.Color := clWhite;
    UsernameLabel.SetParentComponent(TopPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 20;
    UsernameLabel.Top := 10;
    UsernameLabel.Width := 42;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'Name';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TopPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 90;
    WebLabel1.Width := 90;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Departments';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    CompanyNameEdit.SetParentComponent(TopPanel);
    CompanyNameEdit.Name := 'CompanyNameEdit';
    CompanyNameEdit.Left := 20;
    CompanyNameEdit.Top := 40;
    CompanyNameEdit.Width := 345;
    CompanyNameEdit.Height := 34;
    CompanyNameEdit.ChildOrder := 1;
    CompanyNameEdit.ElementClassName := 'MyWebEdit';
    CompanyNameEdit.Font.Charset := ANSI_CHARSET;
    CompanyNameEdit.Font.Color := clWindowText;
    CompanyNameEdit.Font.Height := -16;
    CompanyNameEdit.Font.Name := 'Segoe UI';
    CompanyNameEdit.Font.Style := [];
    CompanyNameEdit.HeightPercent := 100.000000000000000000;
    CompanyNameEdit.ParentFont := False;
    CompanyNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CompanyNameEdit, Self, 'OnChange', 'RecordChange');
    CompanyGrid.SetParentComponent(ContentPanel);
    CompanyGrid.Name := 'CompanyGrid';
    CompanyGrid.AlignWithMargins := True;
    CompanyGrid.Left := 20;
    CompanyGrid.Top := 129;
    CompanyGrid.Width := 740;
    CompanyGrid.Height := 313;
    CompanyGrid.Margins.Left := 20;
    CompanyGrid.Margins.Top := 0;
    CompanyGrid.Margins.Right := 20;
    CompanyGrid.Margins.Bottom := 10;
    CompanyGrid.AdaptToStyle := True;
    CompanyGrid.Align := alClient;
    CompanyGrid.BevelInner := bvNone;
    CompanyGrid.BevelOuter := bvNone;
    CompanyGrid.BevelWidth := 2;
    CompanyGrid.Ctl3D := False;
    CompanyGrid.ParentCtl3D := False;
    CompanyGrid.ParentDoubleBuffered := False;
    CompanyGrid.DoubleBuffered := True;
    CompanyGrid.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Font.Color := clWindowText;
    CompanyGrid.Font.Height := -16;
    CompanyGrid.Font.Name := 'Segoe UI';
    CompanyGrid.Font.Style := [];
    CompanyGrid.ParentFont := False;
    CompanyGrid.TabOrder := 1;
    CompanyGrid.DefaultRowHeight := 34.000000000000000000;
    CompanyGrid.FixedColumns := 0;
    CompanyGrid.ColumnCount := 2;
    CompanyGrid.RowCount := 1;
    CompanyGrid.Options.Borders.FixedCellBorders := bNone;
    CompanyGrid.Options.ColumnSize.Stretch := True;
    CompanyGrid.Options.ColumnSize.StretchColumn := 1;
    CompanyGrid.Options.Editing.CalcFormat := '%g';
    CompanyGrid.Options.Grouping.CalcFormat := '%g';
    CompanyGrid.Options.Grouping.GroupCountFormat := '(%d)';
    CompanyGrid.Options.HTMLExport.CellPadding := 10;
    CompanyGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    CompanyGrid.Options.Mouse.WheelScrollKeepSelection := True;
    CompanyGrid.Options.Mouse.ClickMargin := 0;
    CompanyGrid.Options.Mouse.ColumnSizeMargin := 6;
    CompanyGrid.Options.Mouse.RowSizeMargin := 6;
    CompanyGrid.Options.URL.Color := clSkyBlue;
    CompanyGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    CompanyGrid.Options.Selection.Mode := smSingleRow;
    CompanyGrid.Adapter := GridDatabaseAdapter;
    CompanyGrid.HorizontalScrollBarVisible := False;
    CompanyGrid.Columns.Clear;
    with CompanyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 240.000000000000000000;
    end;
    with CompanyGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 499.000000000000000000;
    end;
    CompanyGrid.Fill.ColorTo := clSilver;
    CompanyGrid.DefaultFont.Charset := ANSI_CHARSET;
    CompanyGrid.DefaultFont.Color := clWindowText;
    CompanyGrid.DefaultFont.Height := -16;
    CompanyGrid.DefaultFont.Name := 'Segoe UI';
    CompanyGrid.DefaultFont.Style := [];
    CompanyGrid.Stroke.Color := 15987699;
    CompanyGrid.Stroke.Width := 2.000000000000000000;
    CompanyGrid.TopRow := 1;
    CompanyGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    CompanyGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    CompanyGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.FixedLayout.Font.Color := clWhite;
    CompanyGrid.Appearance.FixedLayout.Font.Height := -16;
    CompanyGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.FixedLayout.Font.Style := [];
    CompanyGrid.Appearance.NormalLayout.Fill.Color := -1;
    CompanyGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    CompanyGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.NormalLayout.Font.Color := clGray;
    CompanyGrid.Appearance.NormalLayout.Font.Height := -16;
    CompanyGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.NormalLayout.Font.Style := [];
    CompanyGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    CompanyGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    CompanyGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    CompanyGrid.Appearance.GroupLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.GroupLayout.Font.Height := -16;
    CompanyGrid.Appearance.GroupLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.GroupLayout.Font.Style := [];
    CompanyGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    CompanyGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    CompanyGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    CompanyGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.SummaryLayout.Font.Height := -16;
    CompanyGrid.Appearance.SummaryLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.SummaryLayout.Font.Style := [];
    CompanyGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    CompanyGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    CompanyGrid.Appearance.SelectedLayout.Font.Height := -16;
    CompanyGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.SelectedLayout.Font.Style := [];
    CompanyGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    CompanyGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    CompanyGrid.Appearance.FocusedLayout.Font.Height := -16;
    CompanyGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.FocusedLayout.Font.Style := [];
    CompanyGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    CompanyGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    CompanyGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    CompanyGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    CompanyGrid.Appearance.BandLayout.Fill.Color := 14062646;
    CompanyGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    CompanyGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    CompanyGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    CompanyGrid.Appearance.BandLayout.Font.Color := clBlack;
    CompanyGrid.Appearance.BandLayout.Font.Height := -16;
    CompanyGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    CompanyGrid.Appearance.BandLayout.Font.Style := [];
    CompanyGrid.Appearance.ProgressLayout.Color := 15385233;
    CompanyGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    CompanyGrid.LeftCol := 0;
    CompanyGrid.ScrollMode := scmItemScrolling;
    CompanyGrid.GlobalFont.Scale := 1.000000000000000000;
    CompanyGrid.GlobalFont.Style := [];
    CompanyGrid.DesignTimeSampleData := True;
    SetEvent(CompanyGrid, Self, 'OnCellEditValidateData', 'CompanyGridCellEditValidateData');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 452;
    StatusBarPanel.Width := 780;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 660;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uCompanyEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 20;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alLeft;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uCompanyEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 786;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 107;
    CaptionLabel.Height := 21;
    CaptionLabel.Caption := 'Company - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Segoe UI';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 757;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uCompanyEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 572;
    WebWaitMessage.Top := 64;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uCompanyEdit.WebWaitMessage.Picture.gif');
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 577;
    GridDatabaseAdapter.Top := 263;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := CompanyGrid;
    GridDatabaseAdapter.DataSource := CompanySource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'DepartmentName';
      Header := 'Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'DepartmentMail';
      Header := 'Email';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 222;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    CompanyDataset.SetParentComponent(Self);
    CompanyDataset.Name := 'CompanyDataset';
    CompanyDataset.AfterOpen := CompanyDatasetAfterOpen;
    CompanyDataset.Left := 572;
    CompanyDataset.Top := 104;
    CompanyDatasetDepartmentPKey.SetParentComponent(CompanyDataset);
    CompanyDatasetDepartmentPKey.Name := 'CompanyDatasetDepartmentPKey';
    CompanyDatasetDepartmentPKey.FieldName := 'DepartmentPKey';
    CompanyDatasetDepartmentPKey.Visible := False;
    CompanyDatasetDepartmentPKey.Size := 256;
    CompanyDatasetDepartmentName.SetParentComponent(CompanyDataset);
    CompanyDatasetDepartmentName.Name := 'CompanyDatasetDepartmentName';
    CompanyDatasetDepartmentName.FieldName := 'DepartmentName';
    CompanyDatasetDepartmentName.Size := 256;
    CompanyDatasetDepartmentMail.SetParentComponent(CompanyDataset);
    CompanyDatasetDepartmentMail.Name := 'CompanyDatasetDepartmentMail';
    CompanyDatasetDepartmentMail.FieldName := 'DepartmentMail';
    CompanyDatasetDepartmentMail.Size := 256;
    CompanySource.SetParentComponent(Self);
    CompanySource.Name := 'CompanySource';
    CompanySource.DataSet := CompanyDataset;
    CompanySource.Left := 572;
    CompanySource.Top := 176;
    LoadTimer.SetParentComponent(Self);
    LoadTimer.Name := 'LoadTimer';
    LoadTimer.Enabled := False;
    LoadTimer.Interval := 50;
    SetEvent(LoadTimer, Self, 'OnTimer', 'LoadTimerTimer');
    LoadTimer.Left := 571;
    LoadTimer.Top := 392;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 571;
    ShowTimer.Top := 336;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    CompanyNameEdit.AfterLoadDFMValues;
    CompanyGrid.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    CompanyDataset.AfterLoadDFMValues;
    CompanyDatasetDepartmentPKey.AfterLoadDFMValues;
    CompanyDatasetDepartmentName.AfterLoadDFMValues;
    CompanyDatasetDepartmentMail.AfterLoadDFMValues;
    CompanySource.AfterLoadDFMValues;
    LoadTimer.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
  end;
end;

end.

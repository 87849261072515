unit uModelEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, WEBLib.JSON,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, Vcl.Imaging.GIFImg,
  WEBLib.WebCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCImage,
  VCL.TMSFNCPageControl, VCL.TMSFNCTabSet, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, Data.DB, WEBLib.DB, WEBLib.CDS,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCPanel;

type
  TModelEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    ModelBrandLabel: TLabel;
    ModelBrandEdit: TLookupComboBox;
    ModelNameLabel: TLabel;
    ModelNameEdit: TEdit;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    WebLabel11: TLabel;
    FuelTypeEdit: TLookupComboBox;
    WebLabel1: TLabel;
    FuelConsumptionEdit: TEdit;
    WebLabel10: TLabel;
    CarbonDioxideEdit: TEdit;
    ModelImage: TTMSFNCImage;
    MyMessageDlg: TMessageDlg;
    PageControl: TTMSFNCPageControl;
    DocumentPage: TTMSFNCPageControlContainer;
    InstructionPage: TTMSFNCPageControlContainer;
    ImagePage: TTMSFNCPageControlContainer;
    MyOpenFileDialog: TOpenDialog;
    DocumentDBAdapter: TTMSFNCGridDatabaseAdapter;
    DocumentDataSet: TClientDataSet;
    DocumentSource: TDataSource;
    DocumentGrid: TTMSFNCGrid;
    InstructionSource: TDataSource;
    InstructionDataset: TClientDataSet;
    InstructionDBAdapter: TTMSFNCGridDatabaseAdapter;
    TMSFNCPanel1: TTMSFNCPanel;
    DeleteInstructionButton: TSpeedButton;
    EditInstructionButton: TSpeedButton;
    AddInstructionButton: TSpeedButton;
    ListButtonOnlinePanel: TTMSFNCPanel;
    WebConnection: TClientConnection;
    InstructionDatasetPKey: TStringField;
    InstructionDatasetID: TStringField;
    InstructionGrid: TTMSFNCGrid;
    TMSFNCPanel2: TTMSFNCPanel;
    DeleteDocumentButton: TSpeedButton;
    AddDocumentButton: TSpeedButton;
    TMSFNCPanel3: TTMSFNCPanel;
    DocumentDataSetPKey: TStringField;
    DocumentDataSetID: TStringField;
    ViewDocumentButton: TSpeedButton;
    OpenFileButton: TSpeedButton;
    BatteryLockEdit: TCheckBox;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    procedure MyOpenFileDialogChange(Sender: TObject);
    procedure MyOpenFileDialogGetFileAsBase64(Sender: TObject; AFileIndex: Integer;
      ABase64: string);
    [async]
    procedure AddInstructionButtonClick(Sender: TObject); async;
    procedure PageControlChangePage(Sender: TObject; APreviousPageIndex,
      ACurrentPageIndex: Integer);
    [async]
    procedure EditInstructionButtonClick(Sender: TObject); async;
    procedure InstructionDatasetAfterOpen(DataSet: TDataSet);
    procedure DocumentDataSetAfterOpen(DataSet: TDataSet);
    [async]
    procedure AddDocumentButtonClick(Sender: TObject); async;
    [async]
    procedure ViewDocumentButtonClick(Sender: TObject); async;
    procedure PageControlBeforeChangePage(Sender: TObject; ACurrentPageIndex,
      ANewPageIndex: Integer; var ACanChange: Boolean);
    [async]
    procedure DeleteInstructionButtonClick(Sender: TObject); async;
    [async]
    procedure DeleteDocumentButtonClick(Sender: TObject); async;
    procedure OpenFileButtonClick(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    ImageBase64: String;

    [async]
    procedure LoadLookupValues(); async;
    procedure LoadBrandList(S: String);
    [async]
    procedure LoadInstructionList(); async;
    [async]
    procedure LoadDocumentList(); async;
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
    [async]
    procedure ShowInstructionEditDialog(MyPKey: Integer); async;
    [async]
    procedure ShowDocumentEditDialog(MyPKey: Integer); async;
  public
    { Public declarations }
    PKey: Integer;
    IsSet: Boolean;
  protected procedure LoadDFMValues; override; end;

var
  ModelEditForm: TModelEditForm;

implementation

{$R *.dfm}

uses uCommon, uHTMLMemo, uAssetModelInstructionEdit, uAssetModelDocumentEdit,
  uReportPrint;

procedure TModelEditForm.WebFormCreate(Sender: TObject);
begin
  PKey := -1;
  SetGridStyle(InstructionGrid, False);
  SetGridStyle(DocumentGrid, False);
end;

procedure TModelEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;


procedure TModelEditForm.AddDocumentButtonClick(Sender: TObject);
begin
  await(ShowDocumentEditDialog(-1));
end;

procedure TModelEditForm.AddInstructionButtonClick(Sender: TObject);
begin
  await(ShowInstructionEditDialog(-1));
end;

procedure TModelEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  Result: Boolean;
  ErrorMessage: String;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      Result := await(ValidInput(ErrorMessage));
      if Result then begin
        Result := await(SaveData(ErrorMessage));
        if Result then begin
          ModalResult := mrOK;
        end else begin
          MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
        end;
      end else begin
        MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
      end;
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TModelEditForm.DeleteDocumentButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  if not(DocumentDataset.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selected record?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      // Save Changes
      MyWebRequest := THTTPRequest.Create(Self);
      try
        // Request
        MyWebRequest.URL :=
          url_php + 'default_asset_model_document_delete.php?sessionid=' + SessionId +
          '&pkey=' + DocumentDataset.FieldByName('PKey').AsString;
        MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        // Response
        MyJS := TJSON.Create;
        try
          MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
          if MyJO.GetJSONValue('status') = 'OK' then begin
            DocumentPage.Tag := 0;
            LoadDocumentList();
          end else begin
            MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
          end;
        finally
          MyJS.Free;
        end
      finally
        MyWebRequest.Free;
      end;
    end;
  end;
end;

procedure TModelEditForm.DeleteInstructionButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  if not(InstructionDataset.IsEmpty) then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Delete selected record?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      // Save Changes
      MyWebRequest := THTTPRequest.Create(Self);
      try
        // Request
        MyWebRequest.URL :=
          url_php + 'default_asset_model_instruction_delete.php?sessionid=' + SessionId +
          '&pkey=' + InstructionDataset.FieldByName('PKey').AsString;
        MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
        // Response
        MyJS := TJSON.Create;
        try
          MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
          if MyJO.GetJSONValue('status') = 'OK' then begin
            InstructionPage.Tag := 0;
            LoadInstructionList();
          end else begin
            MessageDlg(MyJO.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
          end;
        finally
          MyJS.Free;
        end
      finally
        MyWebRequest.Free;
      end;
    end;
  end;
end;

procedure TModelEditForm.DocumentDataSetAfterOpen(DataSet: TDataSet);
begin
  if not(DocumentDataset.Locate('PKey', DocumentPage.Tag, [])) then begin
    DocumentDataset.First;
  end;
  DocumentDBAdapter.Active := True;
  WebWaitMessage.Hide;
end;

procedure TModelEditForm.EditInstructionButtonClick(Sender: TObject);
begin
  if InstructionDataset.Active and not(InstructionDataset.IsEmpty) then begin
    await(ShowInstructionEditDialog(InstructionDataset.FieldByName('PKey').AsInteger));
  end;
end;

procedure TModelEditForm.InstructionDatasetAfterOpen(DataSet: TDataSet);
begin
  if not(InstructionDataset.Locate('PKey', InstructionPage.Tag, [])) then begin
    InstructionDataset.First;
  end;
  InstructionDBAdapter.Active := True;
  WebWaitMessage.Hide;  //
end;

procedure TModelEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      RecordChanged := False;
      if (Sender as TButton).Tag = 1 then ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

procedure TModelEditForm.ShowTimerTimer(Sender: TObject);
begin
  if IsSet then begin
    ShowTimer.Enabled := False;
    // Load Manager
    await(LoadLookupValues());
    // Load Data
    if PKey >= 0 then begin
      await(LoadData());
    end;
    WebWaitMessage.Hide;
    // Set Focus
    ModelBrandEdit.SetFocus;
    RecordChanged := False;
  end;
end;

procedure TModelEditForm.LoadLookupValues;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_model_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadBrandList(TJSONPair(MyJSONObject.Get('asset_model')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TModelEditForm.MyOpenFileDialogChange(Sender: TObject);
begin
  RecordChange(TObject(Sender));
  MyOpenFileDialog.Files[0].GetFileAsBase64;
end;

procedure TModelEditForm.MyOpenFileDialogGetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
begin
  ImageBase64 := 'data:image/png;base64,' + ABase64;
  ModelImage.Bitmap.LoadFromURL(ImageBase64);
end;

procedure TModelEditForm.OpenFileButtonClick(Sender: TObject);
begin
//WebOpenDialog1.Accept := '.png,.jpg';
  MyOpenFileDialog.Execute;
end;

procedure TModelEditForm.PageControlBeforeChangePage(Sender: TObject;
  ACurrentPageIndex, ANewPageIndex: Integer; var ACanChange: Boolean);
begin
  if ANewPageIndex > 0 then begin
    ACanChange := PKey > 0;
    if not(ACanChange) then begin
      MyMessageDlg.ShowDialog('Please save before adding instructions or documents?', WEBLib.Dialogs.mtConfirmation,[mbOK])
    end;
  end;
end;

procedure TModelEditForm.PageControlChangePage(Sender: TObject;
  APreviousPageIndex, ACurrentPageIndex: Integer);
begin
  case ACurrentPageIndex of
    1: begin
      LoadInstructionList();
    end;
    2: begin
      LoadDocumentList();
    end;
  end;
end;

procedure TModelEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TModelEditForm.LoadBrandList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  ModelBrandEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      ModelBrandEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    ModelBrandEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TModelEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_model_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      ModelNameEdit.Text := MyJSONObject.GetJSONValue('ID');
      ModelBrandEdit.Value := MyJSONObject.GetJSONValue('BrandPKey');
      FuelTypeEdit.Value := MyJSONObject.GetJSONValue('fuel_type');
      FuelConsumptionEdit.Text := MyJSONObject.GetJSONValue('fuel_consumption');
      CarbonDioxideEdit.Text := MyJSONObject.GetJSONValue('carbon_dioxide');
      BatteryLockEdit.Checked := MyJSONObject.GetJSONValue('battery_lock') = 't';
      ImageBase64 := MyJSONObject.GetJSONValue('Picture');
      if ImageBase64 <> EmptyStr then begin
        ModelImage.Bitmap.LoadFromURL(ImageBase64);
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

function TModelEditForm.ValidInput(var ErrorMessage: String): Boolean;
var
  MyInteger: Integer;
begin
  Result := True;
  // Name
  if Result then begin
    if Trim(ModelNameEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := 'Model name is a required field.';
    end;
  end;
  // Brand
  if Result then begin
    if not(TryStrToInt(ModelBrandEdit.Value, MyInteger)) then begin
      Result := False;
      ErrorMessage := 'Brand is a required field.';
    end;
  end;
end;

procedure TModelEditForm.ViewDocumentButtonClick(Sender: TObject);
var
  DocumentPKey: Integer;
  MyURL: String;
  MyWebForm: TReportPrintForm;
begin
  if not(DocumentDataset.IsEmpty) then begin
    DocumentPKey := DocumentDataset.FieldByName('PKey').AsInteger;
    MyURL :=
      url_php + 'default_asset_model_document_view.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(DocumentPKey);
    MyWebForm := TReportPrintForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TReportPrintForm, MyWebForm.Load());
    try
      // Initialize
      MyWebForm.WebBrowser.URL := MyURL;
      // Show Form
      await(TModalResult, MyWebForm.Execute);
    finally
      MyWebForm.Free;
    end;
  end;
end;

function TModelEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"id": "' + ModelNameEdit.Text + '",' +
      '"fuel_type": "' + FuelTypeEdit.Value + '",' +
      '"fuel_consumption": "' + FuelConsumptionEdit.Text + '",' +
      '"carbon_dioxide": "' + CarbonDioxideEdit.Text + '",' +
      '"battery_lock": "' + BooleanToString(BatteryLockEdit.Checked) + '",' +
      '"picture": "' + ImageBase64 + '",' +
      '"brand_pkey": "' + ModelBrandEdit.Value + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_model_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        Result := True;
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TModelEditForm.ShowInstructionEditDialog(MyPKey: Integer);
var
  MyWebForm: TAssetModelInstructionEditForm;
  MyModalResult: TModalResult;
begin
  if
    InstructionDataset.Active
  and
    (
      (MyPKey = -1)
    or
      ((MyPKey > 0) and not(InstructionDataset.IsEmpty))
    )
  then begin
    // Save Position
    if not(InstructionDataset.IsEmpty) then begin
      InstructionPage.Tag := InstructionDataset.FieldByName('PKey').AsInteger;
    end;
    MyWebForm := TAssetModelInstructionEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.ModelPKey := PKey;
    MyWebForm.PKey := MyPKey;
    await(TAssetModelInstructionEditForm, MyWebForm.Load());
    try
      // excute form and wait for close
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        // Refresh
        InstructionPage.Tag := MyWebForm.PKey;
        LoadInstructionList();
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TModelEditForm.ShowDocumentEditDialog(MyPKey: Integer);
var
  MyWebForm: TAssetModelDocumentEditForm;
  MyModalResult: TModalResult;
begin
  if
    DocumentDataset.Active
  and
    (
      (MyPKey = -1)
    or
      ((MyPKey > 0) and not(DocumentDataset.IsEmpty))
    )
  then begin
    // Save Position
    if not(DocumentDataset.IsEmpty) then begin
      DocumentPage.Tag := DocumentDataset.FieldByName('PKey').AsInteger;
    end;
    // Create Form
    MyWebForm := TAssetModelDocumentEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.ModelPKey := PKey;
    MyWebForm.PKey := MyPKey;
    await(TAssetModelDocumentEditForm, MyWebForm.Load());
    try
      // Execute form and wait for close
      MyModalResult := await(TModalResult, MyWebForm.Execute);
      if MyModalResult = mrOK then begin
        // Refresh
        DocumentPage.Tag := MyWebForm.PKey;
        LoadDocumentList();
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TModelEditForm.LoadInstructionList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
//WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_model_instruction_list.php' +
        '?sessionid=' + SessionId +
        '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  InstructionDataset.Close;
  InstructionDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(InstructionDataset.Open);
end;

procedure TModelEditForm.LoadDocumentList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
//WebWaitMessage.Show;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_model_document_list.php' +
        '?sessionid=' + SessionId +
        '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  DocumentDataset.Close;
  DocumentDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
  await(DocumentDataset.Open);
end;


procedure TModelEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  ModelNameLabel := TLabel.Create(Self);
  ModelBrandLabel := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  ModelBrandEdit := TLookupComboBox.Create(Self);
  ModelNameEdit := TEdit.Create(Self);
  FuelTypeEdit := TLookupComboBox.Create(Self);
  FuelConsumptionEdit := TEdit.Create(Self);
  CarbonDioxideEdit := TEdit.Create(Self);
  PageControl := TTMSFNCPageControl.Create(Self);
  InstructionPage := TTMSFNCPageControlContainer.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  DeleteInstructionButton := TSpeedButton.Create(Self);
  EditInstructionButton := TSpeedButton.Create(Self);
  AddInstructionButton := TSpeedButton.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  InstructionGrid := TTMSFNCGrid.Create(Self);
  DocumentPage := TTMSFNCPageControlContainer.Create(Self);
  DocumentGrid := TTMSFNCGrid.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  DeleteDocumentButton := TSpeedButton.Create(Self);
  AddDocumentButton := TSpeedButton.Create(Self);
  TMSFNCPanel3 := TTMSFNCPanel.Create(Self);
  ViewDocumentButton := TSpeedButton.Create(Self);
  ImagePage := TTMSFNCPageControlContainer.Create(Self);
  ModelImage := TTMSFNCImage.Create(Self);
  OpenFileButton := TSpeedButton.Create(Self);
  BatteryLockEdit := TCheckBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  DocumentDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  InstructionDBAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  ShowTimer := TTimer.Create(Self);
  MyOpenFileDialog := TOpenDialog.Create(Self);
  DocumentDataSet := TClientDataSet.Create(Self);
  DocumentDataSetPKey := TStringField.Create(Self);
  DocumentDataSetID := TStringField.Create(Self);
  DocumentSource := TDataSource.Create(Self);
  InstructionSource := TDataSource.Create(Self);
  InstructionDataset := TClientDataSet.Create(Self);
  InstructionDatasetPKey := TStringField.Create(Self);
  InstructionDatasetID := TStringField.Create(Self);
  WebConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  ModelNameLabel.BeforeLoadDFMValues;
  ModelBrandLabel.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  ModelBrandEdit.BeforeLoadDFMValues;
  ModelNameEdit.BeforeLoadDFMValues;
  FuelTypeEdit.BeforeLoadDFMValues;
  FuelConsumptionEdit.BeforeLoadDFMValues;
  CarbonDioxideEdit.BeforeLoadDFMValues;
  PageControl.BeforeLoadDFMValues;
  InstructionPage.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  DeleteInstructionButton.BeforeLoadDFMValues;
  EditInstructionButton.BeforeLoadDFMValues;
  AddInstructionButton.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  InstructionGrid.BeforeLoadDFMValues;
  DocumentPage.BeforeLoadDFMValues;
  DocumentGrid.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  DeleteDocumentButton.BeforeLoadDFMValues;
  AddDocumentButton.BeforeLoadDFMValues;
  TMSFNCPanel3.BeforeLoadDFMValues;
  ViewDocumentButton.BeforeLoadDFMValues;
  ImagePage.BeforeLoadDFMValues;
  ModelImage.BeforeLoadDFMValues;
  OpenFileButton.BeforeLoadDFMValues;
  BatteryLockEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  DocumentDBAdapter.BeforeLoadDFMValues;
  InstructionDBAdapter.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  MyOpenFileDialog.BeforeLoadDFMValues;
  DocumentDataSet.BeforeLoadDFMValues;
  DocumentDataSetPKey.BeforeLoadDFMValues;
  DocumentDataSetID.BeforeLoadDFMValues;
  DocumentSource.BeforeLoadDFMValues;
  InstructionSource.BeforeLoadDFMValues;
  InstructionDataset.BeforeLoadDFMValues;
  InstructionDatasetPKey.BeforeLoadDFMValues;
  InstructionDatasetID.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  try
    Name := 'ModelEditForm';
    Width := 616;
    Height := 701;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 616;
    FormPanel.Height := 701;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 610;
    ContentPanel.Height := 658;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    ModelNameLabel.SetParentComponent(ContentPanel);
    ModelNameLabel.Name := 'ModelNameLabel';
    ModelNameLabel.Left := 20;
    ModelNameLabel.Top := 87;
    ModelNameLabel.Width := 44;
    ModelNameLabel.Height := 21;
    ModelNameLabel.Caption := 'Model';
    ModelNameLabel.Font.Charset := ANSI_CHARSET;
    ModelNameLabel.Font.Color := 9010544;
    ModelNameLabel.Font.Height := -16;
    ModelNameLabel.Font.Name := 'Segoe UI';
    ModelNameLabel.Font.Style := [];
    ModelNameLabel.HeightPercent := 100.000000000000000000;
    ModelNameLabel.ParentFont := False;
    ModelNameLabel.WidthPercent := 100.000000000000000000;
    ModelBrandLabel.SetParentComponent(ContentPanel);
    ModelBrandLabel.Name := 'ModelBrandLabel';
    ModelBrandLabel.Left := 20;
    ModelBrandLabel.Top := 10;
    ModelBrandLabel.Width := 41;
    ModelBrandLabel.Height := 21;
    ModelBrandLabel.Caption := 'Brand';
    ModelBrandLabel.Font.Charset := ANSI_CHARSET;
    ModelBrandLabel.Font.Color := 9010544;
    ModelBrandLabel.Font.Height := -16;
    ModelBrandLabel.Font.Name := 'Segoe UI';
    ModelBrandLabel.Font.Style := [];
    ModelBrandLabel.HeightPercent := 100.000000000000000000;
    ModelBrandLabel.ParentFont := False;
    ModelBrandLabel.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(ContentPanel);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 20;
    WebLabel11.Top := 163;
    WebLabel11.Width := 63;
    WebLabel11.Height := 21;
    WebLabel11.Caption := 'Fuel type';
    WebLabel11.Font.Charset := ANSI_CHARSET;
    WebLabel11.Font.Color := 9010544;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Segoe UI';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 210;
    WebLabel1.Top := 162;
    WebLabel1.Width := 63;
    WebLabel1.Height := 21;
    WebLabel1.Caption := ' l/100km';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(ContentPanel);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 317;
    WebLabel10.Top := 162;
    WebLabel10.Width := 67;
    WebLabel10.Height := 21;
    WebLabel10.Caption := 'CO2 level';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := 9010544;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 609;
    StatusBarPanel.Width := 610;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.Tag := 1;
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 490;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uModelEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 375;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uModelEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    ModelBrandEdit.SetParentComponent(ContentPanel);
    ModelBrandEdit.Name := 'ModelBrandEdit';
    ModelBrandEdit.AlignWithMargins := True;
    ModelBrandEdit.Left := 20;
    ModelBrandEdit.Top := 42;
    ModelBrandEdit.Width := 397;
    ModelBrandEdit.Height := 34;
    ModelBrandEdit.Margins.Left := 20;
    ModelBrandEdit.Margins.Top := 8;
    ModelBrandEdit.Margins.Bottom := 8;
    ModelBrandEdit.ChildOrder := 1;
    ModelBrandEdit.Color := clWhite;
    ModelBrandEdit.ElementClassName := 'myselect_white';
    ModelBrandEdit.Font.Charset := DEFAULT_CHARSET;
    ModelBrandEdit.Font.Color := clWindowText;
    ModelBrandEdit.Font.Height := 12;
    ModelBrandEdit.Font.Name := 'Segoe UI';
    ModelBrandEdit.Font.Style := [];
    ModelBrandEdit.HeightPercent := 100.000000000000000000;
    ModelBrandEdit.ParentFont := False;
    ModelBrandEdit.TabOrder := 1000;
    ModelBrandEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ModelBrandEdit, Self, 'OnChange', 'RecordChange');
    ModelBrandEdit.ItemIndex := -1;
    ModelNameEdit.SetParentComponent(ContentPanel);
    ModelNameEdit.Name := 'ModelNameEdit';
    ModelNameEdit.Left := 20;
    ModelNameEdit.Top := 114;
    ModelNameEdit.Width := 397;
    ModelNameEdit.Height := 34;
    ModelNameEdit.ChildOrder := 2;
    ModelNameEdit.ElementClassName := 'myselect_white';
    ModelNameEdit.Font.Charset := ANSI_CHARSET;
    ModelNameEdit.Font.Color := clWindowText;
    ModelNameEdit.Font.Height := -16;
    ModelNameEdit.Font.Name := 'Segoe UI';
    ModelNameEdit.Font.Style := [];
    ModelNameEdit.HeightPercent := 100.000000000000000000;
    ModelNameEdit.ParentFont := False;
    ModelNameEdit.TabOrder := 1001;
    ModelNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ModelNameEdit, Self, 'OnChange', 'RecordChange');
    FuelTypeEdit.SetParentComponent(ContentPanel);
    FuelTypeEdit.Name := 'FuelTypeEdit';
    FuelTypeEdit.AlignWithMargins := True;
    FuelTypeEdit.Left := 20;
    FuelTypeEdit.Top := 190;
    FuelTypeEdit.Width := 147;
    FuelTypeEdit.Height := 34;
    FuelTypeEdit.Margins.Left := 20;
    FuelTypeEdit.Margins.Top := 8;
    FuelTypeEdit.Margins.Bottom := 8;
    FuelTypeEdit.ChildOrder := 3;
    FuelTypeEdit.Color := clWhite;
    FuelTypeEdit.ElementClassName := 'myselect_white';
    FuelTypeEdit.Font.Charset := DEFAULT_CHARSET;
    FuelTypeEdit.Font.Color := clWindowText;
    FuelTypeEdit.Font.Height := 12;
    FuelTypeEdit.Font.Name := 'Segoe UI';
    FuelTypeEdit.Font.Style := [];
    FuelTypeEdit.HeightPercent := 100.000000000000000000;
    FuelTypeEdit.ParentFont := False;
    FuelTypeEdit.TabOrder := 1002;
    FuelTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FuelTypeEdit, Self, 'OnChange', 'RecordChange');
    FuelTypeEdit.ItemIndex := -1;
    FuelTypeEdit.LookupValues.Clear;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'GASOLINE';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'DIESEL';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'HYDROGEN';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'LPG';
    end;
    with FuelTypeEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'ELECTRICITY';
    end;
    FuelConsumptionEdit.SetParentComponent(ContentPanel);
    FuelConsumptionEdit.Name := 'FuelConsumptionEdit';
    FuelConsumptionEdit.Left := 209;
    FuelConsumptionEdit.Top := 190;
    FuelConsumptionEdit.Width := 65;
    FuelConsumptionEdit.Height := 34;
    FuelConsumptionEdit.ChildOrder := 4;
    FuelConsumptionEdit.EditType := weNumeric;
    FuelConsumptionEdit.ElementClassName := 'myselect_white';
    FuelConsumptionEdit.Font.Charset := ANSI_CHARSET;
    FuelConsumptionEdit.Font.Color := clWindowText;
    FuelConsumptionEdit.Font.Height := -16;
    FuelConsumptionEdit.Font.Name := 'Segoe UI';
    FuelConsumptionEdit.Font.Style := [];
    FuelConsumptionEdit.HeightPercent := 100.000000000000000000;
    FuelConsumptionEdit.ParentFont := False;
    FuelConsumptionEdit.TabOrder := 1003;
    FuelConsumptionEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FuelConsumptionEdit, Self, 'OnChange', 'RecordChange');
    CarbonDioxideEdit.SetParentComponent(ContentPanel);
    CarbonDioxideEdit.Name := 'CarbonDioxideEdit';
    CarbonDioxideEdit.Left := 317;
    CarbonDioxideEdit.Top := 190;
    CarbonDioxideEdit.Width := 100;
    CarbonDioxideEdit.Height := 34;
    CarbonDioxideEdit.ChildOrder := 5;
    CarbonDioxideEdit.EditType := weNumeric;
    CarbonDioxideEdit.ElementClassName := 'myselect_white';
    CarbonDioxideEdit.Font.Charset := ANSI_CHARSET;
    CarbonDioxideEdit.Font.Color := clWindowText;
    CarbonDioxideEdit.Font.Height := -16;
    CarbonDioxideEdit.Font.Name := 'Segoe UI';
    CarbonDioxideEdit.Font.Style := [];
    CarbonDioxideEdit.HeightPercent := 100.000000000000000000;
    CarbonDioxideEdit.ParentFont := False;
    CarbonDioxideEdit.TabOrder := 1004;
    CarbonDioxideEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CarbonDioxideEdit, Self, 'OnChange', 'RecordChange');
    PageControl.SetParentComponent(ContentPanel);
    PageControl.Name := 'PageControl';
    PageControl.AlignWithMargins := True;
    PageControl.Left := 20;
    PageControl.Top := 232;
    PageControl.Width := 570;
    PageControl.Height := 372;
    PageControl.Margins.Left := 10;
    PageControl.Margins.Top := 0;
    PageControl.Margins.Right := 10;
    PageControl.Margins.Bottom := 8;
    PageControl.BevelEdges := [];
    PageControl.BevelInner := bvNone;
    PageControl.BevelOuter := bvNone;
    PageControl.ParentDoubleBuffered := False;
    PageControl.Color := clBtnFace;
    PageControl.DoubleBuffered := True;
    PageControl.Font.Charset := DEFAULT_CHARSET;
    PageControl.Font.Color := clWindowText;
    PageControl.Font.Height := -16;
    PageControl.Font.Name := 'Tahoma';
    PageControl.Font.Style := [];
    PageControl.ParentFont := False;
    PageControl.TabOrder := 6;
    PageControl.Fill.Kind := gfkNone;
    PageControl.Fill.ColorTo := clWhite;
    PageControl.Stroke.Kind := gskNone;
    PageControl.TabAppearance.Font.Charset := ANSI_CHARSET;
    PageControl.TabAppearance.Font.Color := clAqua;
    PageControl.TabAppearance.Font.Height := -16;
    PageControl.TabAppearance.Font.Name := 'Segoe UI';
    PageControl.TabAppearance.Font.Style := [];
    PageControl.TabAppearance.Fill.Color := 14408667;
    PageControl.TabAppearance.Stroke.Color := -1;
    PageControl.TabAppearance.ActiveFill.ColorTo := clWhite;
    PageControl.TabAppearance.HoverFill.Color := 15658734;
    PageControl.TabAppearance.HoverStroke.Color := -1;
    PageControl.TabAppearance.TextSpacing := 10.000000000000000000;
    PageControl.TabAppearance.Shape := tsRectangle;
    PageControl.TabAppearance.ShapeOverlap := 0.000000000000000000;
    PageControl.TabAppearance.ShapeSlope := 0.000000000000000000;
    PageControl.TabAppearance.ShapeRounding := 0.000000000000000000;
    PageControl.TabAppearance.TextColor := clGrayText;
    PageControl.TabAppearance.ActiveTextColor := clGrayText;
    PageControl.TabAppearance.HoverTextColor := clGrayText;
    PageControl.TabAppearance.ShowFocus := False;
    PageControl.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    PageControl.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    PageControl.TabAppearance.BadgeFont.Color := 139;
    PageControl.TabAppearance.BadgeFont.Height := -11;
    PageControl.TabAppearance.BadgeFont.Name := 'Tahoma';
    PageControl.TabAppearance.BadgeFont.Style := [fsBold];
    PageControl.ButtonAppearance.CloseIcon.LoadFromFile('uModelEdit.ContentPanel.PageControl.ButtonAppearance.CloseIcon.svg');
    PageControl.ButtonAppearance.InsertIcon.LoadFromFile('uModelEdit.ContentPanel.PageControl.ButtonAppearance.InsertIcon.svg');
    PageControl.ButtonAppearance.TabListIcon.LoadFromFile('uModelEdit.ContentPanel.PageControl.ButtonAppearance.TabListIcon.svg');
    PageControl.ButtonAppearance.ScrollNextIcon.LoadFromFile('uModelEdit.ContentPanel.PageControl.ButtonAppearance.ScrollNextIcon.svg');
    PageControl.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uModelEdit.ContentPanel.PageControl.ButtonAppearance.ScrollPreviousIcon.svg');
    PageControl.TabSize.Height := 45.000000000000000000;
    PageControl.TabSize.Margins.Left := 8.000000000000000000;
    PageControl.TabSize.Margins.Top := 8.000000000000000000;
    PageControl.TabSize.Margins.Right := 8.000000000000000000;
    PageControl.TabSize.Margins.Bottom := 8.000000000000000000;
    PageControl.Pages.Clear;
    with PageControl.Pages.Add do
    begin
      Text := 'IMAGE';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'INSTRUCTIONS';
      UseDefaultAppearance := True;
    end;
    with PageControl.Pages.Add do
    begin
      Text := 'DOCUMENTS';
      UseDefaultAppearance := True;
    end;
    SetEvent(PageControl, Self, 'OnBeforeChangePage', 'PageControlBeforeChangePage');
    SetEvent(PageControl, Self, 'OnChangePage', 'PageControlChangePage');
    InstructionPage.SetParentComponent(PageControl);
    InstructionPage.Name := 'InstructionPage';
    InstructionPage.AlignWithMargins := True;
    InstructionPage.Left := 0;
    InstructionPage.Top := 52;
    InstructionPage.Width := 570;
    InstructionPage.Height := 320;
    InstructionPage.Margins.Left := 0;
    InstructionPage.Margins.Top := 52;
    InstructionPage.Margins.Right := 0;
    InstructionPage.Margins.Bottom := 0;
    InstructionPage.Align := alClient;
    InstructionPage.ParentDoubleBuffered := False;
    InstructionPage.DoubleBuffered := True;
    InstructionPage.TabStop := False;
    InstructionPage.TabOrder := 1;
    InstructionPage.Visible := False;
    InstructionPage.PageIndex := 1;
    InstructionPage.IsActive := False;
    TMSFNCPanel1.SetParentComponent(InstructionPage);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.AlignWithMargins := True;
    TMSFNCPanel1.Left := 3;
    TMSFNCPanel1.Top := 265;
    TMSFNCPanel1.Width := 564;
    TMSFNCPanel1.Height := 52;
    TMSFNCPanel1.Align := alBottom;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel1.GlobalFont.Style := [];
    DeleteInstructionButton.SetParentComponent(TMSFNCPanel1);
    DeleteInstructionButton.Name := 'DeleteInstructionButton';
    DeleteInstructionButton.AlignWithMargins := True;
    DeleteInstructionButton.Left := 236;
    DeleteInstructionButton.Top := 8;
    DeleteInstructionButton.Width := 100;
    DeleteInstructionButton.Height := 34;
    DeleteInstructionButton.Margins.Left := 0;
    DeleteInstructionButton.Margins.Top := 8;
    DeleteInstructionButton.Margins.Right := 8;
    DeleteInstructionButton.Margins.Bottom := 10;
    DeleteInstructionButton.Align := alLeft;
    DeleteInstructionButton.Caption := 'Delete';
    DeleteInstructionButton.Color := clNone;
    DeleteInstructionButton.ElementClassName := 'MyWebButton';
    DeleteInstructionButton.Glyph.LoadFromFile('uModelEdit.TMSFNCPanel1.DeleteInstructionButton.Glyph.png');
    DeleteInstructionButton.HeightPercent := 100.000000000000000000;
    DeleteInstructionButton.TabOrder := 0;
    DeleteInstructionButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteInstructionButton, Self, 'OnClick', 'DeleteInstructionButtonClick');
    EditInstructionButton.SetParentComponent(TMSFNCPanel1);
    EditInstructionButton.Name := 'EditInstructionButton';
    EditInstructionButton.AlignWithMargins := True;
    EditInstructionButton.Left := 128;
    EditInstructionButton.Top := 8;
    EditInstructionButton.Width := 100;
    EditInstructionButton.Height := 34;
    EditInstructionButton.Margins.Left := 0;
    EditInstructionButton.Margins.Top := 8;
    EditInstructionButton.Margins.Right := 8;
    EditInstructionButton.Margins.Bottom := 10;
    EditInstructionButton.Align := alLeft;
    EditInstructionButton.Caption := 'Edit';
    EditInstructionButton.Color := clNone;
    EditInstructionButton.ElementClassName := 'MyWebButton';
    EditInstructionButton.Glyph.LoadFromFile('uModelEdit.TMSFNCPanel1.EditInstructionButton.Glyph.png');
    EditInstructionButton.HeightPercent := 100.000000000000000000;
    EditInstructionButton.TabOrder := 1;
    EditInstructionButton.WidthPercent := 100.000000000000000000;
    SetEvent(EditInstructionButton, Self, 'OnClick', 'EditInstructionButtonClick');
    AddInstructionButton.SetParentComponent(TMSFNCPanel1);
    AddInstructionButton.Name := 'AddInstructionButton';
    AddInstructionButton.AlignWithMargins := True;
    AddInstructionButton.Left := 20;
    AddInstructionButton.Top := 8;
    AddInstructionButton.Width := 100;
    AddInstructionButton.Height := 34;
    AddInstructionButton.Margins.Left := 0;
    AddInstructionButton.Margins.Top := 8;
    AddInstructionButton.Margins.Right := 8;
    AddInstructionButton.Margins.Bottom := 10;
    AddInstructionButton.Align := alLeft;
    AddInstructionButton.Caption := 'Add';
    AddInstructionButton.Color := clNone;
    AddInstructionButton.ElementClassName := 'MyWebButton';
    AddInstructionButton.Glyph.LoadFromFile('uModelEdit.TMSFNCPanel1.AddInstructionButton.Glyph.png');
    AddInstructionButton.HeightPercent := 100.000000000000000000;
    AddInstructionButton.TabOrder := 2;
    AddInstructionButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddInstructionButton, Self, 'OnClick', 'AddInstructionButtonClick');
    ListButtonOnlinePanel.SetParentComponent(TMSFNCPanel1);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.AlignWithMargins := True;
    ListButtonOnlinePanel.Left := 3;
    ListButtonOnlinePanel.Top := 3;
    ListButtonOnlinePanel.Width := 14;
    ListButtonOnlinePanel.Height := 44;
    ListButtonOnlinePanel.Margins.Bottom := 5;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 3;
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlinePanel.GlobalFont.Scale := 1.000000000000000000;
    ListButtonOnlinePanel.GlobalFont.Style := [];
    InstructionGrid.SetParentComponent(InstructionPage);
    InstructionGrid.Name := 'InstructionGrid';
    InstructionGrid.AlignWithMargins := True;
    InstructionGrid.Left := 20;
    InstructionGrid.Top := 20;
    InstructionGrid.Width := 530;
    InstructionGrid.Height := 232;
    InstructionGrid.Margins.Left := 20;
    InstructionGrid.Margins.Top := 20;
    InstructionGrid.Margins.Right := 20;
    InstructionGrid.Margins.Bottom := 10;
    InstructionGrid.AdaptToStyle := True;
    InstructionGrid.Align := alClient;
    InstructionGrid.BevelInner := bvNone;
    InstructionGrid.BevelOuter := bvNone;
    InstructionGrid.BevelWidth := 2;
    InstructionGrid.Ctl3D := False;
    InstructionGrid.ParentCtl3D := False;
    InstructionGrid.ParentDoubleBuffered := False;
    InstructionGrid.DoubleBuffered := True;
    InstructionGrid.Font.Charset := DEFAULT_CHARSET;
    InstructionGrid.Font.Color := clWindowText;
    InstructionGrid.Font.Height := -11;
    InstructionGrid.Font.Name := 'Tahoma';
    InstructionGrid.Font.Style := [];
    InstructionGrid.ParentFont := False;
    InstructionGrid.TabOrder := 1;
    InstructionGrid.DefaultRowHeight := 34.000000000000000000;
    InstructionGrid.FixedColumns := 0;
    InstructionGrid.ColumnCount := 2;
    InstructionGrid.RowCount := 1;
    InstructionGrid.Options.Borders.FixedCellBorders := bNone;
    InstructionGrid.Options.ColumnSize.Stretch := True;
    InstructionGrid.Options.ColumnSize.StretchColumn := 1;
    InstructionGrid.Options.Editing.CalcFormat := '%g';
    InstructionGrid.Options.Grouping.CalcFormat := '%g';
    InstructionGrid.Options.Grouping.GroupCountFormat := '(%d)';
    InstructionGrid.Options.HTMLExport.CellPadding := 10;
    InstructionGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    InstructionGrid.Options.Mouse.WheelScrollKeepSelection := True;
    InstructionGrid.Options.Mouse.ClickMargin := 0;
    InstructionGrid.Options.Mouse.ColumnSizeMargin := 6;
    InstructionGrid.Options.Mouse.RowSizeMargin := 6;
    InstructionGrid.Options.URL.Color := clSkyBlue;
    InstructionGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    InstructionGrid.Options.Selection.Mode := smDisjunctRow;
    InstructionGrid.Adapter := InstructionDBAdapter;
    InstructionGrid.HorizontalScrollBarVisible := False;
    InstructionGrid.Columns.Clear;
    with InstructionGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with InstructionGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 529.000000000000000000;
    end;
    InstructionGrid.Fill.ColorTo := clSilver;
    InstructionGrid.DefaultFont.Charset := ANSI_CHARSET;
    InstructionGrid.DefaultFont.Color := clWindowText;
    InstructionGrid.DefaultFont.Height := -15;
    InstructionGrid.DefaultFont.Name := 'Segoe UI';
    InstructionGrid.DefaultFont.Style := [];
    InstructionGrid.Stroke.Color := 15987699;
    InstructionGrid.Stroke.Width := 2.000000000000000000;
    InstructionGrid.TopRow := 1;
    InstructionGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    InstructionGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    InstructionGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    InstructionGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    InstructionGrid.Appearance.FixedLayout.Font.Color := clWhite;
    InstructionGrid.Appearance.FixedLayout.Font.Height := -16;
    InstructionGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    InstructionGrid.Appearance.FixedLayout.Font.Style := [];
    InstructionGrid.Appearance.NormalLayout.Fill.Color := -1;
    InstructionGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    InstructionGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    InstructionGrid.Appearance.NormalLayout.Font.Color := clGray;
    InstructionGrid.Appearance.NormalLayout.Font.Height := -11;
    InstructionGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    InstructionGrid.Appearance.NormalLayout.Font.Style := [];
    InstructionGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    InstructionGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    InstructionGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    InstructionGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    InstructionGrid.Appearance.GroupLayout.Font.Color := clBlack;
    InstructionGrid.Appearance.GroupLayout.Font.Height := -11;
    InstructionGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    InstructionGrid.Appearance.GroupLayout.Font.Style := [];
    InstructionGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    InstructionGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    InstructionGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    InstructionGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    InstructionGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    InstructionGrid.Appearance.SummaryLayout.Font.Height := -11;
    InstructionGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    InstructionGrid.Appearance.SummaryLayout.Font.Style := [];
    InstructionGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    InstructionGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    InstructionGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    InstructionGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    InstructionGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    InstructionGrid.Appearance.SelectedLayout.Font.Height := -16;
    InstructionGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    InstructionGrid.Appearance.SelectedLayout.Font.Style := [];
    InstructionGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    InstructionGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    InstructionGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    InstructionGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    InstructionGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    InstructionGrid.Appearance.FocusedLayout.Font.Height := -16;
    InstructionGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    InstructionGrid.Appearance.FocusedLayout.Font.Style := [];
    InstructionGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    InstructionGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    InstructionGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    InstructionGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    InstructionGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    InstructionGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    InstructionGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    InstructionGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    InstructionGrid.Appearance.BandLayout.Fill.Color := 14062646;
    InstructionGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    InstructionGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    InstructionGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    InstructionGrid.Appearance.BandLayout.Font.Color := clBlack;
    InstructionGrid.Appearance.BandLayout.Font.Height := -16;
    InstructionGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    InstructionGrid.Appearance.BandLayout.Font.Style := [];
    InstructionGrid.Appearance.ProgressLayout.Color := 15385233;
    InstructionGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    InstructionGrid.LeftCol := 1;
    InstructionGrid.ScrollMode := scmItemScrolling;
    InstructionGrid.GlobalFont.Scale := 1.000000000000000000;
    InstructionGrid.GlobalFont.Style := [];
    InstructionGrid.DesignTimeSampleData := True;
    DocumentPage.SetParentComponent(PageControl);
    DocumentPage.Name := 'DocumentPage';
    DocumentPage.AlignWithMargins := True;
    DocumentPage.Left := 0;
    DocumentPage.Top := 52;
    DocumentPage.Width := 570;
    DocumentPage.Height := 320;
    DocumentPage.Margins.Left := 0;
    DocumentPage.Margins.Top := 52;
    DocumentPage.Margins.Right := 0;
    DocumentPage.Margins.Bottom := 0;
    DocumentPage.Align := alClient;
    DocumentPage.ParentDoubleBuffered := False;
    DocumentPage.DoubleBuffered := True;
    DocumentPage.TabStop := False;
    DocumentPage.TabOrder := 2;
    DocumentPage.Visible := False;
    DocumentPage.PageIndex := 2;
    DocumentPage.IsActive := False;
    DocumentGrid.SetParentComponent(DocumentPage);
    DocumentGrid.Name := 'DocumentGrid';
    DocumentGrid.AlignWithMargins := True;
    DocumentGrid.Left := 20;
    DocumentGrid.Top := 20;
    DocumentGrid.Width := 530;
    DocumentGrid.Height := 232;
    DocumentGrid.Margins.Left := 20;
    DocumentGrid.Margins.Top := 20;
    DocumentGrid.Margins.Right := 20;
    DocumentGrid.Margins.Bottom := 10;
    DocumentGrid.AdaptToStyle := True;
    DocumentGrid.Align := alClient;
    DocumentGrid.BevelInner := bvNone;
    DocumentGrid.BevelOuter := bvNone;
    DocumentGrid.BevelWidth := 2;
    DocumentGrid.Ctl3D := False;
    DocumentGrid.ParentCtl3D := False;
    DocumentGrid.ParentDoubleBuffered := False;
    DocumentGrid.DoubleBuffered := True;
    DocumentGrid.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Font.Color := clWindowText;
    DocumentGrid.Font.Height := -11;
    DocumentGrid.Font.Name := 'Tahoma';
    DocumentGrid.Font.Style := [];
    DocumentGrid.ParentFont := False;
    DocumentGrid.TabOrder := 0;
    DocumentGrid.DefaultRowHeight := 34.000000000000000000;
    DocumentGrid.FixedColumns := 0;
    DocumentGrid.ColumnCount := 2;
    DocumentGrid.RowCount := 1;
    DocumentGrid.Options.Borders.FixedCellBorders := bNone;
    DocumentGrid.Options.ColumnSize.Stretch := True;
    DocumentGrid.Options.ColumnSize.StretchColumn := 1;
    DocumentGrid.Options.Editing.CalcFormat := '%g';
    DocumentGrid.Options.Grouping.CalcFormat := '%g';
    DocumentGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DocumentGrid.Options.HTMLExport.CellPadding := 10;
    DocumentGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DocumentGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DocumentGrid.Options.Mouse.ClickMargin := 0;
    DocumentGrid.Options.Mouse.ColumnSizeMargin := 6;
    DocumentGrid.Options.Mouse.RowSizeMargin := 6;
    DocumentGrid.Options.URL.Color := clSkyBlue;
    DocumentGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DocumentGrid.Options.Selection.Mode := smDisjunctRow;
    DocumentGrid.Adapter := DocumentDBAdapter;
    DocumentGrid.HorizontalScrollBarVisible := False;
    DocumentGrid.Columns.Clear;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 0.000000000000000000;
    end;
    with DocumentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      ReadOnly := True;
      Width := 529.000000000000000000;
    end;
    DocumentGrid.Fill.ColorTo := clSilver;
    DocumentGrid.DefaultFont.Charset := ANSI_CHARSET;
    DocumentGrid.DefaultFont.Color := clWindowText;
    DocumentGrid.DefaultFont.Height := -15;
    DocumentGrid.DefaultFont.Name := 'Segoe UI';
    DocumentGrid.DefaultFont.Style := [];
    DocumentGrid.Stroke.Color := 15987699;
    DocumentGrid.Stroke.Width := 2.000000000000000000;
    DocumentGrid.TopRow := 1;
    DocumentGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DocumentGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DocumentGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.FixedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FixedLayout.Font.Style := [];
    DocumentGrid.Appearance.NormalLayout.Fill.Color := -1;
    DocumentGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DocumentGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.NormalLayout.Font.Color := clGray;
    DocumentGrid.Appearance.NormalLayout.Font.Height := -11;
    DocumentGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.NormalLayout.Font.Style := [];
    DocumentGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DocumentGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.GroupLayout.Font.Height := -11;
    DocumentGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DocumentGrid.Appearance.GroupLayout.Font.Style := [];
    DocumentGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DocumentGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DocumentGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.SummaryLayout.Font.Height := -11;
    DocumentGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DocumentGrid.Appearance.SummaryLayout.Font.Style := [];
    DocumentGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DocumentGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.SelectedLayout.Font.Height := -16;
    DocumentGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.SelectedLayout.Font.Style := [];
    DocumentGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DocumentGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DocumentGrid.Appearance.FocusedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FocusedLayout.Font.Style := [];
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DocumentGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DocumentGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DocumentGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DocumentGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DocumentGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DocumentGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DocumentGrid.Appearance.BandLayout.Font.Color := clBlack;
    DocumentGrid.Appearance.BandLayout.Font.Height := -16;
    DocumentGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DocumentGrid.Appearance.BandLayout.Font.Style := [];
    DocumentGrid.Appearance.ProgressLayout.Color := 15385233;
    DocumentGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DocumentGrid.LeftCol := 1;
    DocumentGrid.ScrollMode := scmItemScrolling;
    DocumentGrid.GlobalFont.Scale := 1.000000000000000000;
    DocumentGrid.GlobalFont.Style := [];
    DocumentGrid.DesignTimeSampleData := True;
    TMSFNCPanel2.SetParentComponent(DocumentPage);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Tag := 1;
    TMSFNCPanel2.AlignWithMargins := True;
    TMSFNCPanel2.Left := 3;
    TMSFNCPanel2.Top := 265;
    TMSFNCPanel2.Width := 564;
    TMSFNCPanel2.Height := 52;
    TMSFNCPanel2.Align := alBottom;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.Ctl3D := True;
    TMSFNCPanel2.ParentCtl3D := False;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 1;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel2.GlobalFont.Style := [];
    DeleteDocumentButton.SetParentComponent(TMSFNCPanel2);
    DeleteDocumentButton.Name := 'DeleteDocumentButton';
    DeleteDocumentButton.AlignWithMargins := True;
    DeleteDocumentButton.Left := 128;
    DeleteDocumentButton.Top := 8;
    DeleteDocumentButton.Width := 100;
    DeleteDocumentButton.Height := 34;
    DeleteDocumentButton.Margins.Left := 0;
    DeleteDocumentButton.Margins.Top := 8;
    DeleteDocumentButton.Margins.Right := 8;
    DeleteDocumentButton.Margins.Bottom := 10;
    DeleteDocumentButton.Align := alLeft;
    DeleteDocumentButton.Caption := 'Delete';
    DeleteDocumentButton.Color := clNone;
    DeleteDocumentButton.ElementClassName := 'MyWebButton';
    DeleteDocumentButton.Glyph.LoadFromFile('uModelEdit.TMSFNCPanel2.DeleteDocumentButton.Glyph.png');
    DeleteDocumentButton.HeightPercent := 100.000000000000000000;
    DeleteDocumentButton.TabOrder := 0;
    DeleteDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteDocumentButton, Self, 'OnClick', 'DeleteDocumentButtonClick');
    AddDocumentButton.SetParentComponent(TMSFNCPanel2);
    AddDocumentButton.Name := 'AddDocumentButton';
    AddDocumentButton.AlignWithMargins := True;
    AddDocumentButton.Left := 20;
    AddDocumentButton.Top := 8;
    AddDocumentButton.Width := 100;
    AddDocumentButton.Height := 34;
    AddDocumentButton.Margins.Left := 0;
    AddDocumentButton.Margins.Top := 8;
    AddDocumentButton.Margins.Right := 8;
    AddDocumentButton.Margins.Bottom := 10;
    AddDocumentButton.Align := alLeft;
    AddDocumentButton.Caption := 'Add';
    AddDocumentButton.Color := clNone;
    AddDocumentButton.ElementClassName := 'MyWebButton';
    AddDocumentButton.Glyph.LoadFromFile('uModelEdit.TMSFNCPanel2.AddDocumentButton.Glyph.png');
    AddDocumentButton.HeightPercent := 100.000000000000000000;
    AddDocumentButton.TabOrder := 1;
    AddDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddDocumentButton, Self, 'OnClick', 'AddDocumentButtonClick');
    TMSFNCPanel3.SetParentComponent(TMSFNCPanel2);
    TMSFNCPanel3.Name := 'TMSFNCPanel3';
    TMSFNCPanel3.Tag := 2;
    TMSFNCPanel3.AlignWithMargins := True;
    TMSFNCPanel3.Left := 3;
    TMSFNCPanel3.Top := 3;
    TMSFNCPanel3.Width := 14;
    TMSFNCPanel3.Height := 44;
    TMSFNCPanel3.Margins.Bottom := 5;
    TMSFNCPanel3.Align := alLeft;
    TMSFNCPanel3.BevelInner := bvNone;
    TMSFNCPanel3.BevelOuter := bvNone;
    TMSFNCPanel3.Ctl3D := True;
    TMSFNCPanel3.ParentCtl3D := False;
    TMSFNCPanel3.ParentDoubleBuffered := False;
    TMSFNCPanel3.DoubleBuffered := True;
    TMSFNCPanel3.TabOrder := 2;
    TMSFNCPanel3.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel3.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel3.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel3.SectionsAppearance.Font.Style := [];
    TMSFNCPanel3.Stroke.Kind := gskNone;
    TMSFNCPanel3.Header.Text := 'Header';
    TMSFNCPanel3.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Header.Font.Color := clWindowText;
    TMSFNCPanel3.Header.Font.Height := -11;
    TMSFNCPanel3.Header.Font.Name := 'Tahoma';
    TMSFNCPanel3.Header.Font.Style := [];
    TMSFNCPanel3.Header.Visible := False;
    TMSFNCPanel3.Footer.Text := 'Footer';
    TMSFNCPanel3.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel3.Footer.Font.Color := clWindowText;
    TMSFNCPanel3.Footer.Font.Height := -11;
    TMSFNCPanel3.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel3.Footer.Font.Style := [];
    TMSFNCPanel3.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPanel3.GlobalFont.Style := [];
    ViewDocumentButton.SetParentComponent(TMSFNCPanel2);
    ViewDocumentButton.Name := 'ViewDocumentButton';
    ViewDocumentButton.AlignWithMargins := True;
    ViewDocumentButton.Left := 444;
    ViewDocumentButton.Top := 8;
    ViewDocumentButton.Width := 100;
    ViewDocumentButton.Height := 34;
    ViewDocumentButton.Margins.Left := 0;
    ViewDocumentButton.Margins.Top := 8;
    ViewDocumentButton.Margins.Right := 20;
    ViewDocumentButton.Margins.Bottom := 10;
    ViewDocumentButton.Align := alRight;
    ViewDocumentButton.Caption := 'View';
    ViewDocumentButton.Color := clNone;
    ViewDocumentButton.ElementClassName := 'MyWebButton';
    ViewDocumentButton.Glyph.LoadFromFile('uModelEdit.TMSFNCPanel2.ViewDocumentButton.Glyph.png');
    ViewDocumentButton.HeightPercent := 100.000000000000000000;
    ViewDocumentButton.TabOrder := 3;
    ViewDocumentButton.WidthPercent := 100.000000000000000000;
    SetEvent(ViewDocumentButton, Self, 'OnClick', 'ViewDocumentButtonClick');
    ImagePage.SetParentComponent(PageControl);
    ImagePage.Name := 'ImagePage';
    ImagePage.AlignWithMargins := True;
    ImagePage.Left := 0;
    ImagePage.Top := 52;
    ImagePage.Width := 570;
    ImagePage.Height := 320;
    ImagePage.Margins.Left := 0;
    ImagePage.Margins.Top := 52;
    ImagePage.Margins.Right := 0;
    ImagePage.Margins.Bottom := 0;
    ImagePage.Align := alClient;
    ImagePage.ParentDoubleBuffered := False;
    ImagePage.DoubleBuffered := True;
    ImagePage.TabStop := False;
    ImagePage.TabOrder := 0;
    ImagePage.PageIndex := 0;
    ImagePage.IsActive := True;
    ModelImage.SetParentComponent(ImagePage);
    ModelImage.Name := 'ModelImage';
    ModelImage.Left := 21;
    ModelImage.Top := 16;
    ModelImage.Width := 356;
    ModelImage.Height := 257;
    ModelImage.ParentDoubleBuffered := False;
    ModelImage.Color := clBtnFace;
    ModelImage.DoubleBuffered := True;
    ModelImage.TabOrder := 0;
    ModelImage.Fill.Kind := gfkNone;
    ModelImage.Stroke.Kind := gskNone;
    OpenFileButton.SetParentComponent(ImagePage);
    OpenFileButton.Name := 'OpenFileButton';
    OpenFileButton.AlignWithMargins := True;
    OpenFileButton.Left := 20;
    OpenFileButton.Top := 282;
    OpenFileButton.Width := 127;
    OpenFileButton.Height := 31;
    OpenFileButton.Margins.Left := 20;
    OpenFileButton.Margins.Top := 8;
    OpenFileButton.Margins.Right := 5;
    OpenFileButton.Margins.Bottom := 10;
    OpenFileButton.Caption := 'Choose &File';
    OpenFileButton.Color := clNone;
    OpenFileButton.ElementClassName := 'MyWebButton';
    OpenFileButton.HeightPercent := 100.000000000000000000;
    OpenFileButton.TabOrder := 1;
    OpenFileButton.WidthPercent := 100.000000000000000000;
    SetEvent(OpenFileButton, Self, 'OnClick', 'OpenFileButtonClick');
    BatteryLockEdit.SetParentComponent(ContentPanel);
    BatteryLockEdit.Name := 'BatteryLockEdit';
    BatteryLockEdit.Left := 440;
    BatteryLockEdit.Top := 196;
    BatteryLockEdit.Width := 153;
    BatteryLockEdit.Height := 26;
    BatteryLockEdit.Caption := 'Battery Lock';
    BatteryLockEdit.ChildOrder := 28;
    BatteryLockEdit.Font.Charset := ANSI_CHARSET;
    BatteryLockEdit.Font.Color := clWindowText;
    BatteryLockEdit.Font.Height := -16;
    BatteryLockEdit.Font.Name := 'Segoe UI';
    BatteryLockEdit.Font.Style := [];
    BatteryLockEdit.HeightPercent := 100.000000000000000000;
    BatteryLockEdit.ParentFont := False;
    BatteryLockEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BatteryLockEdit, Self, 'OnClick', 'RecordChange');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 616;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 85;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Model - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 587;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uModelEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 93;
    WebWaitMessage.Top := 47;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uModelEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 182;
    MyMessageDlg.Top := 47;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    DocumentDBAdapter.SetParentComponent(Self);
    DocumentDBAdapter.Name := 'DocumentDBAdapter';
    DocumentDBAdapter.Left := 291;
    DocumentDBAdapter.Top := 214;
    DocumentDBAdapter.Width := 26;
    DocumentDBAdapter.Height := 26;
    DocumentDBAdapter.Margins.Left := 12;
    DocumentDBAdapter.Visible := True;
    DocumentDBAdapter.Grid := DocumentGrid;
    DocumentDBAdapter.DataSource := DocumentSource;
    DocumentDBAdapter.Columns.Clear;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      Header := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with DocumentDBAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    DocumentDBAdapter.AutoCreateColumns := False;
    DocumentDBAdapter.AutoRemoveColumns := False;
    InstructionDBAdapter.SetParentComponent(Self);
    InstructionDBAdapter.Name := 'InstructionDBAdapter';
    InstructionDBAdapter.Left := 185;
    InstructionDBAdapter.Top := 214;
    InstructionDBAdapter.Width := 26;
    InstructionDBAdapter.Height := 26;
    InstructionDBAdapter.Visible := True;
    InstructionDBAdapter.Grid := InstructionGrid;
    InstructionDBAdapter.DataSource := InstructionSource;
    InstructionDBAdapter.Columns.Clear;
    with InstructionDBAdapter.Columns.Add do
    begin
      FieldName := 'PKey';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with InstructionDBAdapter.Columns.Add do
    begin
      FieldName := 'ID';
      Header := '  ID';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 89;
    ShowTimer.Top := 98;
    MyOpenFileDialog.SetParentComponent(Self);
    MyOpenFileDialog.Name := 'MyOpenFileDialog';
    MyOpenFileDialog.Accept := '.png';
    SetEvent(MyOpenFileDialog, Self, 'OnChange', 'MyOpenFileDialogChange');
    SetEvent(MyOpenFileDialog, Self, 'OnGetFileAsBase64', 'MyOpenFileDialogGetFileAsBase64');
    MyOpenFileDialog.Left := 89;
    MyOpenFileDialog.Top := 154;
    DocumentDataSet.SetParentComponent(Self);
    DocumentDataSet.Name := 'DocumentDataSet';
    DocumentDataSet.Connection := WebConnection;
    DocumentDataSet.AfterOpen := DocumentDataSetAfterOpen;
    DocumentDataSet.Left := 289;
    DocumentDataSet.Top := 98;
    DocumentDataSetPKey.SetParentComponent(DocumentDataSet);
    DocumentDataSetPKey.Name := 'DocumentDataSetPKey';
    DocumentDataSetPKey.FieldName := 'PKey';
    DocumentDataSetPKey.Size := 256;
    DocumentDataSetID.SetParentComponent(DocumentDataSet);
    DocumentDataSetID.Name := 'DocumentDataSetID';
    DocumentDataSetID.FieldName := 'ID';
    DocumentDataSetID.Size := 256;
    DocumentSource.SetParentComponent(Self);
    DocumentSource.Name := 'DocumentSource';
    DocumentSource.DataSet := DocumentDataSet;
    DocumentSource.Left := 289;
    DocumentSource.Top := 154;
    InstructionSource.SetParentComponent(Self);
    InstructionSource.Name := 'InstructionSource';
    InstructionSource.DataSet := InstructionDataset;
    InstructionSource.Left := 185;
    InstructionSource.Top := 154;
    InstructionDataset.SetParentComponent(Self);
    InstructionDataset.Name := 'InstructionDataset';
    InstructionDataset.AfterOpen := InstructionDatasetAfterOpen;
    InstructionDataset.Left := 185;
    InstructionDataset.Top := 98;
    InstructionDatasetPKey.SetParentComponent(InstructionDataset);
    InstructionDatasetPKey.Name := 'InstructionDatasetPKey';
    InstructionDatasetPKey.FieldName := 'PKey';
    InstructionDatasetPKey.Size := 256;
    InstructionDatasetID.SetParentComponent(InstructionDataset);
    InstructionDatasetID.Name := 'InstructionDatasetID';
    InstructionDatasetID.FieldName := 'ID';
    InstructionDatasetID.Size := 256;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.URI := 'https://instatrack.eu:44321/php/default_asset_model_document_list.php?sessionid=0bbec681979b8483ae1f9cb49703b2b6&pkey=7';
    WebConnection.Left := 89;
    WebConnection.Top := 213;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    ModelNameLabel.AfterLoadDFMValues;
    ModelBrandLabel.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    ModelBrandEdit.AfterLoadDFMValues;
    ModelNameEdit.AfterLoadDFMValues;
    FuelTypeEdit.AfterLoadDFMValues;
    FuelConsumptionEdit.AfterLoadDFMValues;
    CarbonDioxideEdit.AfterLoadDFMValues;
    PageControl.AfterLoadDFMValues;
    InstructionPage.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    DeleteInstructionButton.AfterLoadDFMValues;
    EditInstructionButton.AfterLoadDFMValues;
    AddInstructionButton.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    InstructionGrid.AfterLoadDFMValues;
    DocumentPage.AfterLoadDFMValues;
    DocumentGrid.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    DeleteDocumentButton.AfterLoadDFMValues;
    AddDocumentButton.AfterLoadDFMValues;
    TMSFNCPanel3.AfterLoadDFMValues;
    ViewDocumentButton.AfterLoadDFMValues;
    ImagePage.AfterLoadDFMValues;
    ModelImage.AfterLoadDFMValues;
    OpenFileButton.AfterLoadDFMValues;
    BatteryLockEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    DocumentDBAdapter.AfterLoadDFMValues;
    InstructionDBAdapter.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    MyOpenFileDialog.AfterLoadDFMValues;
    DocumentDataSet.AfterLoadDFMValues;
    DocumentDataSetPKey.AfterLoadDFMValues;
    DocumentDataSetID.AfterLoadDFMValues;
    DocumentSource.AfterLoadDFMValues;
    InstructionSource.AfterLoadDFMValues;
    InstructionDataset.AfterLoadDFMValues;
    InstructionDatasetPKey.AfterLoadDFMValues;
    InstructionDatasetID.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
  end;
end;

end.

unit uAssetMOTEdit;

interface

uses
  System.SysUtils, System.Classes, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCCustomPicker, VCL.TMSFNCDatePicker;

type
  TAssetMOTEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    AssetIDLabel: TLabel;
    WebLabel1: TLabel;
    DateIntervalEdit: TEdit;
    WebLabel2: TLabel;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    DateFirstEdit: TDateTimePicker;
    ReminderEdit: TCheckBox;
    procedure SaveButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    procedure SetValues(MOTDateFirst, MOTDateInterval: Int64; MOTReminder: Boolean);
  protected procedure LoadDFMValues; override; end;

var
  AssetMOTEditForm: TAssetMOTEditForm;

implementation

{$R *.dfm}

uses
  uCommon;

procedure TAssetMOTEditForm.SetValues(MOTDateFirst, MOTDateInterval: Int64; MOTReminder: Boolean);
begin
  DateFirstEdit.Date := UnixToDateTime(MOTDateFirst);
  DateIntervalEdit.Text := IntToStr(MOTDateInterval);
  ReminderEdit.Checked := MOTReminder;
end;

procedure TAssetMOTEditForm.WebFormShow(Sender: TObject);
begin
  DateFirstEdit.SetFocus;
end;

procedure TAssetMOTEditForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TAssetMOTEditForm.SaveButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TAssetMOTEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  AssetIDLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  DateIntervalEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  DateFirstEdit := TDateTimePicker.Create(Self);
  ReminderEdit := TCheckBox.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  AssetIDLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  DateIntervalEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DateFirstEdit.BeforeLoadDFMValues;
  ReminderEdit.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  try
    Name := 'AssetMOTEditForm';
    Width := 382;
    Height := 299;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 382;
    FormPanel.Height := 299;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 376;
    ContentPanel.Height := 256;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    AssetIDLabel.SetParentComponent(ContentPanel);
    AssetIDLabel.Name := 'AssetIDLabel';
    AssetIDLabel.Left := 30;
    AssetIDLabel.Top := 41;
    AssetIDLabel.Width := 64;
    AssetIDLabel.Height := 21;
    AssetIDLabel.Caption := 'First date';
    AssetIDLabel.Font.Charset := ANSI_CHARSET;
    AssetIDLabel.Font.Color := 9010544;
    AssetIDLabel.Font.Height := -16;
    AssetIDLabel.Font.Name := 'Segoe UI';
    AssetIDLabel.Font.Style := [];
    AssetIDLabel.HeightPercent := 100.000000000000000000;
    AssetIDLabel.ParentFont := False;
    AssetIDLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 30;
    WebLabel1.Top := 92;
    WebLabel1.Width := 72;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Frequency';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 256;
    WebLabel2.Top := 92;
    WebLabel2.Width := 53;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'Months';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    DateIntervalEdit.SetParentComponent(ContentPanel);
    DateIntervalEdit.Name := 'DateIntervalEdit';
    DateIntervalEdit.Left := 136;
    DateIntervalEdit.Top := 89;
    DateIntervalEdit.Width := 100;
    DateIntervalEdit.Height := 34;
    DateIntervalEdit.ChildOrder := 1;
    DateIntervalEdit.EditType := weNumeric;
    DateIntervalEdit.ElementClassName := 'MyWebEdit';
    DateIntervalEdit.Font.Charset := ANSI_CHARSET;
    DateIntervalEdit.Font.Color := clWindowText;
    DateIntervalEdit.Font.Height := -16;
    DateIntervalEdit.Font.Name := 'Segoe UI';
    DateIntervalEdit.Font.Style := [];
    DateIntervalEdit.HeightPercent := 100.000000000000000000;
    DateIntervalEdit.ParentFont := False;
    DateIntervalEdit.TabOrder := 1;
    DateIntervalEdit.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 207;
    StatusBarPanel.Width := 376;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 256;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetMOTEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 141;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&OK';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetMOTEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    DateFirstEdit.SetParentComponent(ContentPanel);
    DateFirstEdit.Name := 'DateFirstEdit';
    DateFirstEdit.AlignWithMargins := True;
    DateFirstEdit.Left := 136;
    DateFirstEdit.Top := 36;
    DateFirstEdit.Width := 173;
    DateFirstEdit.Height := 34;
    DateFirstEdit.Margins.Left := 20;
    DateFirstEdit.Margins.Top := 8;
    DateFirstEdit.Margins.Bottom := 8;
    DateFirstEdit.BorderStyle := bsSingle;
    DateFirstEdit.Color := clWhite;
    DateFirstEdit.Date := 44545.380967847220000000;
    DateFirstEdit.Font.Charset := ANSI_CHARSET;
    DateFirstEdit.Font.Color := clWindowText;
    DateFirstEdit.Font.Height := -16;
    DateFirstEdit.Font.Name := 'Segoe UI';
    DateFirstEdit.Font.Style := [];
    DateFirstEdit.ParentFont := False;
    DateFirstEdit.Role := '';
    DateFirstEdit.Text := '';
    ReminderEdit.SetParentComponent(ContentPanel);
    ReminderEdit.Name := 'ReminderEdit';
    ReminderEdit.Left := 136;
    ReminderEdit.Top := 141;
    ReminderEdit.Width := 153;
    ReminderEdit.Height := 26;
    ReminderEdit.Hint := 'Includes the asset in the MOT report.';
    ReminderEdit.Caption := 'Email reminder';
    ReminderEdit.ChildOrder := 2;
    ReminderEdit.Font.Charset := ANSI_CHARSET;
    ReminderEdit.Font.Color := 9010544;
    ReminderEdit.Font.Height := -16;
    ReminderEdit.Font.Name := 'Segoe UI';
    ReminderEdit.Font.Style := [];
    ReminderEdit.HeightPercent := 100.000000000000000000;
    ReminderEdit.ParentFont := False;
    ReminderEdit.ShowHint := True;
    ReminderEdit.TabOrder := 2;
    ReminderEdit.WidthPercent := 100.000000000000000000;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 382;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 34;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'MOT';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 353;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseButtonClick');
    CloseImage.Picture.LoadFromFile('uAssetMOTEdit.CaptionPanel.CloseImage.Picture.png');
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    AssetIDLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    DateIntervalEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DateFirstEdit.AfterLoadDFMValues;
    ReminderEdit.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
  end;
end;

end.
unit uDepartmentList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, WEBLib.CDS,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, VCL.TMSFNCCustomControl,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter,
  Vcl.Controls, WEBLIB.REST, WEBLib.Buttons, Vcl.Imaging.GIFImg;

type
  TDepartmentListForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    DepartmentGrid: TTMSFNCGrid;
    StatusBarPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    DepartmentDataset: TClientDataSet;
    DepartmentSource: TDataSource;
    LoadTimer: TTimer;
    ShowTimer: TTimer;
    DepartmentDatasetda_checkbox: TStringField;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    WebConnection: TClientConnection;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    DepartmentDatasetda_pk: TStringField;
    DepartmentDatasetda_naam: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure ShowTimerTimer(Sender: TObject);
    procedure CloseImageClick(Sender: TObject);
    procedure DepartmentDatasetAfterOpen(DataSet: TDataSet);
    procedure LoadTimerTimer(Sender: TObject);
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure DepartmentGridCellClick(Sender: TObject; ACol, ARow: Integer);
    procedure DepartmentGridKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(): Boolean; async;
  public
    { Public declarations }
    PKeyList: TStringList;
    Department: String;
    IsSet: Boolean;
  protected procedure LoadDFMValues; override; end;

var
  DepartmentListForm: TDepartmentListForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TDepartmentListForm.SaveButtonClick(Sender: TObject);
begin
  PKeyList.Clear;
  Department := EmptyStr;
  DepartmentDataset.DisableControls;
  try
    DepartmentDataset.First;
    while not(DepartmentDataset.Eof) do begin
      if DepartmentDataset.FieldByName('da_checkbox').AsInteger = 1 then begin
        PKeyList.Add(DepartmentDataset.FieldByName('da_pk').AsString);
        if not(Department.IsEmpty) then Department := Department + ', ';
        Department := Department + DepartmentDataset.FieldByName('da_naam').AsString;
      end;
      DepartmentDataset.Next;
    end;
  finally
    DepartmentDataset.EnableControls;
  end;
  ModalResult := mrOK;
end;

procedure TDepartmentListForm.ShowTimerTimer(Sender: TObject);
begin
  if IsSet then begin
    ShowTimer.Enabled := False;
    // Focus
    DepartmentGrid.SetFocus;
    // Load Data
    LoadData();
  end;
end;

procedure TDepartmentListForm.WebFormCreate(Sender: TObject);
begin
  IsSet := False;
  PKeyList := TStringList.Create;
  Department := EmptyStr;
  SetGridStyle(DepartmentGrid, False);
end;

procedure TDepartmentListForm.WebFormDestroy(Sender: TObject);
begin
  PKeyList.Free;
end;

procedure TDepartmentListForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TDepartmentListForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TDepartmentListForm.DepartmentDatasetAfterOpen(DataSet: TDataSet);
begin
  // Update CheckBox
  DepartmentDataset.First;
  while not(DepartmentDataset.Eof) do begin
    DepartmentDataset.Edit;
    if PKeyList.IndexOf(DepartmentDataset.FieldByName('da_pk').AsString) >= 0 then begin
      DepartmentDataset.FieldByName('da_checkbox').AsInteger := 1;
    end else begin
      DepartmentDataset.FieldByName('da_checkbox').AsInteger := 0;
    end;
    DepartmentDataset.Post;
    // Next Record
    DepartmentDataset.Next;
  end;
  //
  GridDatabaseAdapter.Active := True;
  LoadTimer.Enabled := True;
end;

procedure TDepartmentListForm.DepartmentGridCellClick(Sender: TObject; ACol,
  ARow: Integer);
begin
  if ACol = 0 then begin
    if DepartmentDataset.Active and not(DepartmentDataset.IsEmpty) then begin
      DepartmentDataset.Edit;
      DepartmentDataset.FieldByName('da_checkbox').AsInteger :=
        Abs(DepartmentDataset.FieldByName('da_checkbox').AsInteger - 1);
      DepartmentDataset.Post;
    end;
  end;
end;

procedure TDepartmentListForm.DepartmentGridKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = vk_space then begin
    if DepartmentDataset.Active and not(DepartmentDataset.IsEmpty) then begin
      DepartmentDataset.Edit;
      DepartmentDataset.FieldByName('da_checkbox').AsInteger :=
        Abs(DepartmentDataset.FieldByName('da_checkbox').AsInteger - 1);
      DepartmentDataset.Post;
    end;
  end;
end;

procedure TDepartmentListForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_department_list.php?' +
      'sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Fill Dataset
  DepartmentDataset.Close;
  DepartmentDataset.Rows := TJSArray(TJSJSON.parseObject(string(MyRequest.response)));
  DepartmentDataset.Open;
end;


procedure TDepartmentListForm.LoadTimerTimer(Sender: TObject);
begin
  LoadTimer.Enabled := False;
  DepartmentDataset.First;
  WebWaitMessage.Hide;
end;

function TDepartmentListForm.ValidInput(): Boolean;
begin
  Result := True;
end;

procedure TDepartmentListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  DepartmentGrid := TTMSFNCGrid.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  DepartmentDataset := TClientDataSet.Create(Self);
  DepartmentDatasetda_pk := TStringField.Create(Self);
  DepartmentDatasetda_naam := TStringField.Create(Self);
  DepartmentDatasetda_checkbox := TStringField.Create(Self);
  DepartmentSource := TDataSource.Create(Self);
  LoadTimer := TTimer.Create(Self);
  ShowTimer := TTimer.Create(Self);
  WebConnection := TClientConnection.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  DepartmentGrid.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  DepartmentDataset.BeforeLoadDFMValues;
  DepartmentDatasetda_pk.BeforeLoadDFMValues;
  DepartmentDatasetda_naam.BeforeLoadDFMValues;
  DepartmentDatasetda_checkbox.BeforeLoadDFMValues;
  DepartmentSource.BeforeLoadDFMValues;
  LoadTimer.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  WebConnection.BeforeLoadDFMValues;
  try
    Name := 'DepartmentListForm';
    Width := 640;
    Height := 544;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 640;
    FormPanel.Height := 544;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 634;
    ContentPanel.Height := 501;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    DepartmentGrid.SetParentComponent(ContentPanel);
    DepartmentGrid.Name := 'DepartmentGrid';
    DepartmentGrid.AlignWithMargins := True;
    DepartmentGrid.Left := 20;
    DepartmentGrid.Top := 20;
    DepartmentGrid.Width := 594;
    DepartmentGrid.Height := 422;
    DepartmentGrid.Margins.Left := 20;
    DepartmentGrid.Margins.Top := 20;
    DepartmentGrid.Margins.Right := 20;
    DepartmentGrid.Margins.Bottom := 10;
    DepartmentGrid.AdaptToStyle := True;
    DepartmentGrid.Align := alClient;
    DepartmentGrid.BevelInner := bvNone;
    DepartmentGrid.BevelOuter := bvNone;
    DepartmentGrid.BevelWidth := 2;
    DepartmentGrid.Ctl3D := False;
    DepartmentGrid.ParentCtl3D := False;
    DepartmentGrid.ParentDoubleBuffered := False;
    DepartmentGrid.DoubleBuffered := True;
    DepartmentGrid.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Font.Color := clWindowText;
    DepartmentGrid.Font.Height := -16;
    DepartmentGrid.Font.Name := 'Segoe UI';
    DepartmentGrid.Font.Style := [];
    DepartmentGrid.ParentFont := False;
    DepartmentGrid.TabOrder := 0;
    SetEvent(DepartmentGrid, Self, 'OnKeyDown', 'DepartmentGridKeyDown');
    DepartmentGrid.DefaultRowHeight := 34.000000000000000000;
    DepartmentGrid.FixedColumns := 0;
    DepartmentGrid.ColumnCount := 3;
    DepartmentGrid.RowCount := 1;
    DepartmentGrid.Options.Borders.FixedCellBorders := bNone;
    DepartmentGrid.Options.ColumnSize.Stretch := True;
    DepartmentGrid.Options.ColumnSize.StretchColumn := 1;
    DepartmentGrid.Options.Editing.CalcFormat := '%g';
    DepartmentGrid.Options.Grouping.CalcFormat := '%g';
    DepartmentGrid.Options.Grouping.GroupCountFormat := '(%d)';
    DepartmentGrid.Options.HTMLExport.CellPadding := 10;
    DepartmentGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    DepartmentGrid.Options.Mouse.WheelScrollKeepSelection := True;
    DepartmentGrid.Options.Mouse.ClickMargin := 0;
    DepartmentGrid.Options.Mouse.ColumnSizeMargin := 6;
    DepartmentGrid.Options.Mouse.RowSizeMargin := 6;
    DepartmentGrid.Options.URL.Color := clSkyBlue;
    DepartmentGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    DepartmentGrid.Options.Selection.Mode := smSingleRow;
    DepartmentGrid.Adapter := GridDatabaseAdapter;
    DepartmentGrid.HorizontalScrollBarVisible := False;
    DepartmentGrid.Columns.Clear;
    with DepartmentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      ColumnType := ctCheckBox;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := 5920859;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Name := 'View';
      ReadOnly := True;
      Width := 60.000000000000000000;
    end;
    with DepartmentGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWhite;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Name := 'Name';
      ReadOnly := True;
      Width := 76.000000000000000000;
    end;
    DepartmentGrid.Fill.ColorTo := clSilver;
    DepartmentGrid.DefaultFont.Charset := ANSI_CHARSET;
    DepartmentGrid.DefaultFont.Color := clWindowText;
    DepartmentGrid.DefaultFont.Height := -15;
    DepartmentGrid.DefaultFont.Name := 'Segoe UI';
    DepartmentGrid.DefaultFont.Style := [];
    DepartmentGrid.Stroke.Color := 15987699;
    DepartmentGrid.Stroke.Width := 2.000000000000000000;
    DepartmentGrid.TopRow := 1;
    DepartmentGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    DepartmentGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    DepartmentGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.FixedLayout.Font.Color := clWhite;
    DepartmentGrid.Appearance.FixedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.FixedLayout.Font.Style := [];
    DepartmentGrid.Appearance.NormalLayout.Fill.Color := -1;
    DepartmentGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    DepartmentGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.NormalLayout.Font.Color := clGray;
    DepartmentGrid.Appearance.NormalLayout.Font.Height := -11;
    DepartmentGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.NormalLayout.Font.Style := [];
    DepartmentGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    DepartmentGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    DepartmentGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    DepartmentGrid.Appearance.GroupLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.GroupLayout.Font.Height := -11;
    DepartmentGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    DepartmentGrid.Appearance.GroupLayout.Font.Style := [];
    DepartmentGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    DepartmentGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    DepartmentGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    DepartmentGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.SummaryLayout.Font.Height := -11;
    DepartmentGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    DepartmentGrid.Appearance.SummaryLayout.Font.Style := [];
    DepartmentGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    DepartmentGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    DepartmentGrid.Appearance.SelectedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.SelectedLayout.Font.Style := [];
    DepartmentGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    DepartmentGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    DepartmentGrid.Appearance.FocusedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.FocusedLayout.Font.Style := [];
    DepartmentGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    DepartmentGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    DepartmentGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    DepartmentGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    DepartmentGrid.Appearance.BandLayout.Fill.Color := 14062646;
    DepartmentGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    DepartmentGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    DepartmentGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    DepartmentGrid.Appearance.BandLayout.Font.Color := clBlack;
    DepartmentGrid.Appearance.BandLayout.Font.Height := -16;
    DepartmentGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    DepartmentGrid.Appearance.BandLayout.Font.Style := [];
    DepartmentGrid.Appearance.ProgressLayout.Color := 15385233;
    DepartmentGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    DepartmentGrid.LeftCol := 0;
    DepartmentGrid.ScrollMode := scmItemScrolling;
    DepartmentGrid.GlobalFont.Scale := 1.000000000000000000;
    DepartmentGrid.GlobalFont.Style := [];
    DepartmentGrid.DesignTimeSampleData := True;
    SetEvent(DepartmentGrid, Self, 'OnCellClick', 'DepartmentGridCellClick');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 452;
    StatusBarPanel.Width := 634;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 3;
    StatusBarPanel.Color := clWhite;
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 20;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alLeft;
    SaveButton.Caption := 'OK';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uDepartmentList.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 514;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := 'Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uDepartmentList.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 640;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 90;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Departments';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 611;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uDepartmentList.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 574;
    WebWaitMessage.Top := 64;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uDepartmentList.WebWaitMessage.Picture.gif');
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 569;
    GridDatabaseAdapter.Top := 263;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := DepartmentGrid;
    GridDatabaseAdapter.DataSource := DepartmentSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'da_checkbox';
      Header := 'View';
      CheckBoxField := True;
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'da_naam';
      Header := 'Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    DepartmentDataset.SetParentComponent(Self);
    DepartmentDataset.Name := 'DepartmentDataset';
    DepartmentDataset.AfterOpen := DepartmentDatasetAfterOpen;
    DepartmentDataset.Left := 572;
    DepartmentDataset.Top := 104;
    DepartmentDatasetda_pk.SetParentComponent(DepartmentDataset);
    DepartmentDatasetda_pk.Name := 'DepartmentDatasetda_pk';
    DepartmentDatasetda_pk.FieldName := 'da_pk';
    DepartmentDatasetda_pk.Visible := False;
    DepartmentDatasetda_pk.Size := 256;
    DepartmentDatasetda_naam.SetParentComponent(DepartmentDataset);
    DepartmentDatasetda_naam.Name := 'DepartmentDatasetda_naam';
    DepartmentDatasetda_naam.FieldName := 'da_naam';
    DepartmentDatasetda_naam.Size := 256;
    DepartmentDatasetda_checkbox.SetParentComponent(DepartmentDataset);
    DepartmentDatasetda_checkbox.Name := 'DepartmentDatasetda_checkbox';
    DepartmentDatasetda_checkbox.FieldName := 'da_checkbox';
    DepartmentDatasetda_checkbox.Size := 256;
    DepartmentSource.SetParentComponent(Self);
    DepartmentSource.Name := 'DepartmentSource';
    DepartmentSource.DataSet := DepartmentDataset;
    DepartmentSource.Left := 572;
    DepartmentSource.Top := 176;
    LoadTimer.SetParentComponent(Self);
    LoadTimer.Name := 'LoadTimer';
    LoadTimer.Enabled := False;
    LoadTimer.Interval := 50;
    SetEvent(LoadTimer, Self, 'OnTimer', 'LoadTimerTimer');
    LoadTimer.Left := 571;
    LoadTimer.Top := 392;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 571;
    ShowTimer.Top := 336;
    WebConnection.SetParentComponent(Self);
    WebConnection.Name := 'WebConnection';
    WebConnection.Active := False;
    WebConnection.URI := 'https://instatrack.eu:44321/php/default_department_list.php?sessionid=83285ca640d98737d4daff0be222dc0f';
    WebConnection.Left := 420;
    WebConnection.Top := 101;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    DepartmentGrid.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    DepartmentDataset.AfterLoadDFMValues;
    DepartmentDatasetda_pk.AfterLoadDFMValues;
    DepartmentDatasetda_naam.AfterLoadDFMValues;
    DepartmentDatasetda_checkbox.AfterLoadDFMValues;
    DepartmentSource.AfterLoadDFMValues;
    LoadTimer.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    WebConnection.AfterLoadDFMValues;
  end;
end;

end.
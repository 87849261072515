
// Options-> MapID = bc22a23721e40da2

unit uRealTimeMap;

interface

uses
  uTeamFilterList, uTypeFilterList, uHistoryMap,
  System.SysUtils, System.Classes, System.Math, System.DateUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLIB.JSON,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.REST, Data.DB, WEBLib.CDS, WEBLib.ExtCtrls, VCL.TMSFNCTypes,
  VCL.TMSFNCMapsCommonTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCWebBrowser, VCL.TMSFNCMaps,
  VCL.TMSFNCGoogleMaps, Vcl.Imaging.pngimage, Vcl.Menus, WEBLib.Menus,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCPopup, WEBLib.Buttons, WEBLib.WebCtrls,
  VCL.TMSFNCHTMLText, VCL.TMSFNCPanel, Vcl.Imaging.GIFImg;

const
  ObjectHeight = 95;

type
  TRealtimeMapForm = class(TForm)
    ObjectDataset: TClientDataSet;
    BottomPanel: TPanel;
    ObjectListTimer: TTimer;
    ToolbarPanel: TPanel;
    TeamFilterDataSet: TClientDataSet;
    TeamFilterDataSetfilter_pk: TStringField;
    TeamFilterDataSetfilter_naam: TStringField;
    TeamFilterDataSetfilter_publiek: TStringField;
    TeamFilterDataSetfilter_standaard: TStringField;
    TypeComboBox: TLookupComboBox;
    TypeFilterDataSet: TClientDataSet;
    StringField1: TStringField;
    StringField2: TStringField;
    StringField3: TStringField;
    StringField4: TStringField;
    SearchEdit: TEdit;
    SearchButton: TButton;
    ObjectDatasetIMEI: TStringField;
    ObjectDatasetDateTime: TStringField;
    ObjectDatasetLatitude: TStringField;
    ObjectDatasetLongitude: TStringField;
    ObjectDatasetSpeed: TStringField;
    ObjectDatasetStreet: TStringField;
    ObjectDatasetPostalCode: TStringField;
    ObjectDatasetMunicipality: TStringField;
    ObjectDatasetCountry: TStringField;
    ObjectDatasetAssetPKey: TStringField;
    ObjectDatasetSymbol: TStringField;
    ObjectDatasetObjectId: TStringField;
    ObjectDatasetLicensePlate: TStringField;
    ObjectDatasetURL: TStringField;
    ObjectDatasetEmployeeName: TStringField;
    ObjectDatasetIOStatus: TStringField;
    TeamFilterButton: TSpeedButton;
    TeamComboBox: TLookupComboBox;
    TypeFilterButton: TSpeedButton;
    ListPopupMenu: TPopupMenu;
    HourMenuItem: TMenuItem;
    TodayMenuItem: TMenuItem;
    YesterdayMenuItem: TMenuItem;
    CustomDateMenuItem: TMenuItem;
    LastTripMenuItem: TMenuItem;
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCPanel2: TTMSFNCPanel;
    ObjectListPanel: TPanel;
    ListButtonPanel: TTMSFNCPanel;
    ListButtonInactivePanel: TTMSFNCPanel;
    ListButtonInactiveLabel: TLabel;
    ListButtonOfflinePanel: TTMSFNCPanel;
    ListButtonOfflineLabel: TLabel;
    ListButtonOnlinePanel: TTMSFNCPanel;
    ListButtonOnlineLabel: TLabel;
    ListButtonAllPanel: TTMSFNCPanel;
    ListButtonAllLabel: TLabel;
    ListButtonIndicatorPanel: TTMSFNCPanel;
    WebWaitMessage: TWaitMessage;
    ObjectDatasetModel: TStringField;
    ObjectDatasetGroup: TStringField;
    ObjectDatasetErrorStatus: TStringField;
    ObjectDatasetAssetStatus: TStringField;
    SearchTypeEdit: TLookupComboBox;
    ChangeDriverMenuItem: TMenuItem;
    AssetEditMenuItem: TMenuItem;
    N1: TMenuItem;
    ClearSearchEditButton: TSpeedButton;
    [async]
    ObjectDatasetType: TStringField;
    ObjectDatasetBrand: TStringField;
    MarkerClusterButton: TBitBtn;
    MarkerOverlayButton: TBitBtn;
    AssetHideButton: TBitBtn;
    ZoomToBoundButton: TBitBtn;
    POIGroupButton: TBitBtn;
    GeofenceButton: TBitBtn;
    DistanceRulerButton: TBitBtn;
    MyWebConnection: TClientConnection;
    WebMessageDlg1: TMessageDlg;
    ChangeStatusMenuItem: TMenuItem;
    AssetStatusComboBox: TLookupComboBox;
    ObjectDatasetIOIgnition: TStringField;
    ObjectDatasetBatteryStatus: TStringField;
    RealtimeMap: TTMSFNCGoogleMaps;
    ObjectDatasetAssetStatusHexColor: TStringField;
    ObjectDatasetDeviceTriangle: TStringField;
    ObjectDatasetDeviceCircle: TStringField;
    ObjectRefreshTimer: TTimer;
    procedure RealtimeMapMarkerClick(Sender: TObject; AEventData: TTMSFNCMapsEventData);
    [async]
    procedure ObjectListTimerTimer(Sender: TObject); async;
    [async]
    procedure SearchButtonClick(Sender: TObject); async;
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure MarkerClusterButtonClick(Sender: TObject); async;
    [async]
    procedure ObjectPanelClick(Sender: TObject); async;
    [async]
    procedure ObjectRefreshTimerTimer(Sender: TObject); async;
    [async]
    procedure ZoomToBoundButtonClick(Sender: TObject); async;
    procedure TeamFilterButtonClick(Sender: TObject);
    procedure TypeFilterButtonClick(Sender: TObject);
    procedure DotsImageClick(Sender: TObject);
    procedure HistoryMenuItemClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure ListButtonPanelClick(Sender: TObject);
    procedure FilterChange(Sender: TObject);
    procedure ClearSearchEditButtonClick(Sender: TObject);
    procedure SearchEditKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure ClearSearchEditButtonDblClick(Sender: TObject);
    procedure MarkerOverlayButtonClick(Sender: TObject);
    [async]
    procedure AssetEditMenuItemClick(Sender: TObject); async;
    [async]
    procedure RealtimeMapZoomChanged(Sender: TObject;
      AEventData: TTMSFNCMapsEventData); async;
    procedure RealtimeMapGetZoomLevel(Sender: TObject; AZoomLevel: Double);
    [async]
    procedure POIGroupButtonClick(Sender: TObject); async;
    [async]
    procedure AssetHideButtonClick(Sender: TObject); async;
    [async]
    procedure ChangeDriverMenuItemClick(Sender: TObject); async;
    [async]
    procedure ChangeStatusMenuItemClick(Sender: TObject); async;
    [async]
    procedure SearchTypeEditChange(Sender: TObject); async;
    [async]
    procedure RealtimeMapMapInitialized(Sender: TObject); async;
    procedure GeofenceButtonClick(Sender: TObject);
    procedure DistanceRulerButtonClick(Sender: TObject);
    procedure RealtimeMapMapClick(Sender: TObject;
      AEventData: TTMSFNCMapsEventData);
    procedure RealtimeMapElementContainers0Actions0Execute(Sender: TObject;
      AEventData: TTMSFNCMapsEventData);
    procedure RealtimeMapMapMouseMove(Sender: TObject;
      AEventData: TTMSFNCMapsEventData);
    procedure RealtimeMapPolyElementClick(Sender: TObject;
      AEventData: TTMSFNCMapsEventData);
    procedure RealtimeMapElementContainers0Actions1Execute(Sender: TObject;
      AEventData: TTMSFNCMapsEventData);
  private
    // Google Maps
    MapInitialized: Boolean;
    DistanceTotal: Double;
    // Markers
    MarkerCount: Int64;
    MarkerLatMin: Double;
    MarkerLatMax: Double;
    MarkerLonMin: Double;
    MarkerLonMax: Double;
    // Points Of Interest
    POICount: Int64;
    POILatMin: Double;
    POILatMax: Double;
    POILonMin: Double;
    POILonMax: Double;

    TempBounds: TTMSFNCMapsBoundsRec;
    MapZoomLevel: Double;
    DefaultTeam, DefaultType: Integer;
    Geofences: Boolean;
    FilterAddress, FilterCountry, FilterLat, FilterLng, FilterKM: String;
    { Private declarations }
    [async]
    procedure PerformSearch(); async;
    [async]
    procedure LoadEverything(); async;
    [async]
    procedure LoadTeamList(); async;
    [async]
    procedure LoadTypeList(); async;
    [async]
    procedure LoadObjectList(); async;
    [async]
    procedure LoadGeofences(); async;
    [async]
    procedure LoadAssetStatusList(); async;
    [async]
    procedure ZoomObjectsMap(); async;
    [async]
    procedure LoadPOIList(ZoomToBounds: Boolean); async;
    [async]
    procedure ShowObjectList(); async;
    [async]
    procedure SelectObject(Index: Integer); async;
    [async]
    procedure ShowTeamFilterListDialog(); async;
    [async]
    procedure ShowTypeFilterListDialog(); async;
    [async]
    procedure ShowHistoryDialog(HistoryRecNo, HistoryMode: Integer); async;
    [async]
    procedure SelectListButtonPanel(ID: Integer); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    procedure ClearFilter(); async;
    procedure AddRulerCoordinate(Latitude, Longitude: Double);
    procedure SetRulerDistance(AZoomLevel, ADistance: Double);
  public
    { Public declarations }
    MachineMax: Boolean;
    [async]
    procedure RefreshThirdPartyMarkers(); async;
    [async]
    procedure ShowObjectsMap(ShowCluster: Boolean); async;
  protected procedure LoadDFMValues; override; end;

var
  RealtimeMapForm: TRealtimeMapForm;

implementation

{$R *.dfm}

uses uCommon, uAssetEdit, uPOIGroupSelect, uUserList, uAssetStatusList,
  uAssetStatusChange;

procedure TRealtimeMapForm.POIGroupButtonClick(Sender: TObject);
var
  MyWebForm: TPOIGroupSelectForm;
  MyModalResult: TModalResult;
begin
  MyWebForm := TPOIGroupSelectForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  MyWebForm.List := POIGroupList;
  await(TPOIGroupSelectForm, MyWebForm.Load());
  try
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
      POIGroupList := MyWebForm.List;
      if ObjectRefreshTimer.Enabled then begin
        LoadPOIList(True);
      end;
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TRealtimeMapForm.WebFormCreate(Sender: TObject);
begin
  // Clear Search Criteria
  ClearFilter();
  // Clear Selection
  Geofences := False;
  MarkerCount := 0;
  POICount := 0;
  RealTimeObject.IMEI := EmptyStr;
  RealTimeObject.Symbol := 0;
  RealTimeObject.LicensePlate := EmptyStr;
  // Default Search Type
  SearchTypeEdit.Value := '1';
end;

procedure TRealtimeMapForm.WebFormShow(Sender: TObject);
begin
  // InstaTrack
  RealTimeMap.APIKey := 'AIzaSyDERvbp-qyXf1NxjyaKWRQHC-zH7B3n5q4';
  // Meet And Wash
//  RealTimeMap.APIKey := 'AIzaSyA1n__mycagDhltoge3eRqpU86e_fWm5uU';
end;

procedure TRealtimeMapForm.ListButtonPanelClick(Sender: TObject);
var
  MyPanel: TTMSFNCPanel;
begin
  // Get Panel
  if Sender is TTMSFNCPanel then MyPanel := TTMSFNCPanel(Sender);
  if Sender is TLabel then MyPanel := TTMSFNCPanel(TLabel(Sender).Parent);
  // Update Content
  ObjectRefreshTimer.Enabled := False;
  WebWaitMessage.Show;
  try
    SelectListButtonPanel(MyPanel.Tag);
  finally
    WebWaitMessage.Hide;
    ObjectRefreshTimer.Enabled := True;
  end;
end;

procedure TRealtimeMapForm.SelectListButtonPanel(ID: Integer);
begin
  ListButtonPanel.Tag := ID;
  // Select TabSheet
  case ID of
    1: ListButtonIndicatorPanel.Parent := ListButtonAllPanel;
    2: ListButtonIndicatorPanel.Parent := ListButtonOnlinePanel;
    3: ListButtonIndicatorPanel.Parent := ListButtonOfflinePanel;
    4: ListButtonIndicatorPanel.Parent := ListButtonInactivePanel;
  end;
  // Load / Show
  await(LoadObjectList());
  await(ShowObjectList());
  await(ShowObjectsmap(MarkerClustering));
  await(RefreshThirdPartyMarkers());
  await(ZoomObjectsMap());
end;

procedure TRealtimeMapForm.LoadEveryThing();
var
  MyResult: Boolean;
  MyErrorMessage: String;
begin
  await(LoadTeamList());
  await(LoadTypeList());
  // Validate Input
  MyResult := await(ValidInput(MyErrorMessage));
  if MyResult then begin
    await(LoadPOIList(False));
    await(LoadObjectList());
    await(ShowObjectList());
    await(ShowObjectsmap(MarkerClustering));
    await(RefreshThirdPartyMarkers());
    await(ZoomObjectsMap());
    ObjectRefreshTimer.Enabled := True;
  end;
end;

procedure TRealtimeMapForm.LoadTeamList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_team_list_v2.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Status Code
    if MyRequest.Status = 200 then begin
      // Fill Dataset
      TeamFilterDataset.Close;
      TeamFilterDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
      TeamFilterDataset.Open;
      // Fill ComboBox
      DefaultTeam := -1;
      TeamFilterDataset.First;
      TeamComboBox.LookupValues.Clear;
      while not(TeamFilterDataset.Eof) do begin
        TeamCombobox.LookupValues.AddPair(
          TeamFilterDataset.FieldByName('filter_pk').AsString,
          TeamFilterDataset.FieldByName('filter_naam').AsString
        );
        if TeamFilterDataset.FieldByName('filter_standaard').AsString = 't' then begin
          DefaultTeam := TeamFilterDataset.FieldByName('filter_pk').AsInteger;
        end;
        TeamFilterDataset.Next;
      end;
      // Set Default Value
      if DefaultTeam >= 0 then begin
        TeamComboBox.Value := IntToStr(DefaultTeam);
      end else begin
//      TeamComboBox.ItemIndex := -1;
        TeamComboBox.ItemIndex := 0;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TRealtimeMapForm.LoadTypeList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
begin
  // Get Data
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_object_type_list_v2.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Status Code
    if MyRequest.Status = 200 then begin
      // Fill Dataset
      TypeFilterDataset.Close;
      TypeFilterDataset.Rows := TJSArray(TJSJSON.parseObject(String(MyRequest.response)));
      TypeFilterDataset.Open;
      // Fill ComboBox
      DefaultType := 0;
      TypeFilterDataset.First;
      TypeComboBox.LookupValues.Clear;
      while not(TypeFilterDataset.Eof) do begin
        TypeComboBox.LookupValues.AddPair(
          TypeFilterDataset.FieldByName('filter_pk').AsString,
          TypeFilterDataset.FieldByName('filter_naam').AsString
        );
        if TypeFilterDataset.FieldByName('filter_standaard').AsString = 't' then begin
          DefaultType := TypeFilterDataset.FieldByName('filter_pk').AsInteger;
        end;
        TypeFilterDataset.Next;
      end;
      // Set Default Value
      if DefaultType >= 0 then begin
        TypeComboBox.Value := IntToStr(DefaultType);
      end else begin
        TypeComboBox.ItemIndex := DefaultType;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TRealtimeMapForm.HistoryMenuItemClick(Sender: TObject);
begin
  ShowHistoryDialog(ListPopupMenu.Tag+1, TMenuItem(Sender).Tag);
end;

procedure TRealtimeMapForm.ClearSearchEditButtonClick(Sender: TObject);
begin
  SearchEdit.Text := EmptyStr;
  SearchEdit.SetFocus;
end;

procedure TRealtimeMapForm.ClearSearchEditButtonDblClick(Sender: TObject);
begin
  // Team
  if DefaultTeam >= 0 then begin
    TeamComboBox.Value := IntToStr(DefaultTeam);
  end else begin
    TeamComboBox.ItemIndex := -1;
  end;
  // Type
  if DefaultType >= 0 then begin
    TypeComboBox.Value := IntToStr(DefaultType);
  end else begin
    TypeComboBox.ItemIndex := -1;
  end;
end;

procedure TRealtimeMapForm.MarkerClusterButtonClick(Sender: TObject);
begin
  MarkerClustering := not(MarkerClustering);
  await(ShowObjectsMap(MarkerClustering));
  await(RefreshThirdPartyMarkers());
end;

procedure TRealtimeMapForm.MarkerOverlayButtonClick(Sender: TObject);
var
  I: Integer;
begin
  with RealtimeMap do begin
    BeginUpdate;
    try
      for I := 0 to Markers.Count-1 do begin
        Markers[I].OverlayView.Visible := not(Markers[I].OverlayView.Visible);
      end;
    finally
      EndUpdate;
    end;
  end;
end;

procedure TRealtimeMapForm.DistanceRulerButtonClick(Sender: TObject);
var
  I: Integer;
begin
  DistanceTotal := 0;
  SetRulerDistance(MapZoomLevel, DistanceTotal);
  RealtimeMap.ElementContainers[0].Visible :=
    not(RealtimeMap.ElementContainers[0].Visible);
  // Update GUI
  if RealtimeMap.ElementContainers[0].Visible then begin
    DistanceRulerButton.Tag := 1;
    RealtimeMap.ExecuteJavaScript(
      'document.getElementById("customRulerDistanceStopButton1").innerText = "Stop";'
    );
  end else begin
    DistanceRulerButton.Tag := 0;
    RealtimeMap.ExecuteJavaScript(
      'document.getElementById("customRulerDistanceStopButton1").innerText = "Start";'
    );
    RealTimeMap.BeginUpdate;
    try
      RealtimeMap.Polylines[0].Recreate := True;
      RealTimeMap.Polylines[0].Coordinates.Clear;
      RealTimeMap.Polylines[1].Visible := False;
      RealTimeMap.PolyLines[1].Coordinates[1].Latitude :=
        RealTimeMap.PolyLines[1].Coordinates[0].Latitude;
      RealTimeMap.PolyLines[1].Coordinates[1].Longitude :=
        RealTimeMap.PolyLines[1].Coordinates[0].Longitude;
    finally
      RealtimeMap.EndUpdate;
    end;
  end;
end;

procedure TRealtimeMapForm.DotsImageClick(Sender: TObject);
const
  MenuItemHeight = 43;
var
  I: Integer;
  WebPanel: TPanel;
  ScrollBox: TScrollBox;
  MenuLeft: Integer;
  MenuTop: Integer;
  MenuItemCount: Integer;
  MenuHeight: Integer;
begin
  if Sender is TImageControl then begin
    WebPanel := TPanel(TImageControl(Sender).Parent);
    ScrollBox := TScrollBox(WebPanel.Parent);
    // Set Popup RecNo
    ListPopupMenu.Tag := WebPanel.Tag;
    // Show / Hide Items
    AssetEditMenuItem.Visible := GetUserPermissionValue(Permissions, 'assets') in [1,2,3,5,7];
    ChangeDriverMenuItem.Visible := GetUserPermissionValue(Permissions, 'assetdriver') in [2,3,5,6,7];
    ChangeStatusMenuItem.Visible := GetUserPermissionValue(Permissions, 'assetstatus') in [2,3,5,6,7];
    // Calculate Height
    MenuItemCount := 0;
    for I := 0 to ListPopupMenu.Items.Count-1 do begin
      if ListPopupMenu.Items.Items[I].Visible then MenuItemCount := MenuItemCount + 1;
    end;
    MenuHeight := MenuItemHeight * MenuItemCount;
    // Calculate Position
    MenuTop :=
      ToolBarPanel.Height + 2 +
      ListButtonPanel.Height + ListButtonPanel.Margins.Top + ListButtonPanel.Margins.Bottom +
      (WebPanel.Top - ScrollBox.ScrollTop);
    if (MenuTop + MenuHeight) > Self.Height then begin
      MenuTop := Self.Height - MenuHeight;
    end;
    MenuLeft := ObjectListPanel.Width + 2;
    // Show Popup Menu
    ListPopupMenu.Popup(MenuLeft, MenuTop);
  end;
end;

procedure TRealtimeMapForm.LoadGeofences();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  // Geometry Values
  GeomName: String;
  GeomType: Integer;
  GeomJSON: String;
  GeomRadius: Double;
  GeomFillColor: Integer;
  GeomFillOpacity: String;
  GeomStrokeColor: Integer;
  GeomStrokeWidth: String;
  GeomStrokeOpacity: String;
  // Cirlce / Polygon
  MyString: String;
  p1, p2: Integer;
  StringList: TStringList;
  Latitude, Longitude: Double;
  cc: TTMSFNCMapsCoordinateRec;
  p: TTMSFNCGoogleMapsPolygon;
  c: TTMSFNCMapsCircle;
begin
  // Download Geofencesend;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_geofence_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Status
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONArray := TJSONArray(MyJSON.Parse(string(MyRequest.response)));
        for I := 0 to MyJSONArray.Count-1 do begin
          // Get Object
          MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
          // Get Geofence Values
          GeomName := MyJSONObject.GetJSONValue('ID');
          GeomType := StrToInt(MyJSONObject.GetJSONValue('geomtype'));
          GeomJSON := MyJSONObject.GetJSONValue('geomjson');
          GeomRadius := StrToFloatDot(MyJSONObject.GetJSONValue('radius'));
          GeomFillColor := StrToInt(MyJSONObject.GetJSONValue('fillcolor'));
          GeomFillOpacity := MyJSONObject.GetJSONValue('fillopacity');
          GeomStrokeColor := StrToInt(MyJSONObject.GetJSONValue('strokecolor'));
          GeomStrokeWidth := MyJSONObject.GetJSONValue('strokewidth');
          GeomStrokeOpacity := MyJSONObject.GetJSONValue('strokeopacity');
          // Show Geofence
          MyString := window.atob(GeomJSON);
          case GeomType of
            // Circle
            1: begin
              if Length(MyString) > 0 then begin
                p1 := Pos('[', MyString);
                p2 := Pos(']', MyString);
                MyString := Copy(MyString, p1+1, p2-(p1+1));
                StringList := TStringList.Create();
                try
                  StringList.DelimitedText := MyString;
                  Longitude  := StrToFloatDot(StringList[0]);
                  Latitude := StrToFloatDot(StringList[1]);
                finally
                  StringList.Free;
                end;
                cc := CreateCoordinate(Latitude, Longitude);
                c := RealTimeMap.AddCircle(cc, GeomRadius);
                with c do begin
                  FillColor := GeomFillColor; //gcOrange;
                  FillOpacity := StrToInt(EmptyStrToZero(GeomFillOpacity)) / 100;  // 0.2;
                  StrokeColor := GeomStrokeColor; //gcGreen;
                  StrokeWidth := StrToInt(EmptyStrToZero(GeomStrokeWidth)); //1;
                  StrokeOpacity := StrToInt(EmptyStrToZero(GeomStrokeOpacity)) / 100; // 0.5
                end;
              end;
            end;
            // Polygon
            2: begin
              RealTimeMap.BeginUpdate;
              MyString :=
                '{' +
                  '"type": "FeatureCollection", ' +
                  '"features": [' +
                    MyString +
                  ']' +
                '}';
              RealTimeMap.LoadGeoJSONFromText(MyString, True, False);
              p := RealTimeMap.Polygons[RealTimeMap.Polygons.Count-1];
              with p do begin
                FillColor := GeomFillColor; //gcOrange;
                FillOpacity := StrToInt(EmptyStrToZero(GeomFillOpacity)) / 100;  // 0.2;
                StrokeColor := GeomStrokeColor; //gcGreen;
                StrokeWidth := StrToInt(EmptyStrToZero(GeomStrokeWidth)); //1;
                StrokeOpacity := StrToInt(EmptyStrToZero(GeomStrokeOpacity)) / 100; // 0.5
                Editable := False;
              end;
              RealTimeMap.EndUpdate;
            end;
          end;
        end;
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TRealtimeMapForm.LoadAssetStatusList();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetStatusComboBox.LookupValues.Clear;
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_status_list.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Status
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONArray := TJSONArray(MyJSON.Parse(string(MyRequest.response)));
        for I := 0 to MyJSONArray.Count-1 do begin
          MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
          AssetStatusComboBox.LookupValues.AddPair(
            MyJSONObject.GetJSONValue('obs_pk'),
            MyJSONObject.GetJSONValue('obs_naam')
          );
        end;
        // Set Value
        AssetStatusComboBox.Value := '0';
      finally
        MyJSON.Free;
      end;
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TRealtimeMapForm.LoadObjectList();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyJSONPair: TJSONPair;
  MyRecords: String;
begin
  // Get Data
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
    url_php + 'default_tracker_list_tabsheet_search.php?sessionid=' + SessionId +
      '&teamfilter=' + TeamComboBox.Value +
      '&typefilter=' + TypeComboBox.Value +
      '&tabsheet=' + IntToStr(ListButtonPanel.Tag-1);
    if (Trim(SearchEdit.Text) <> EmptyStr) then begin
      case StrToInt(SearchTypeEdit.Value) of
        1: // License Plate
          MyWebRequest.URL := MyWebRequest.URL + '&licenseplate=' + SearchEdit.Text;
        2: // Object No
          MyWebRequest.URL := MyWebRequest.URL + '&objectno=' + SearchEdit.Text;
        3: // Object ID
          MyWebRequest.URL := MyWebRequest.URL + '&objectid=' + SearchEdit.Text;
        4: // Address
          MyWebRequest.URL := MyWebRequest.URL + '&lat=' + FilterLat + '&lng=' + FilterLng + '&km=' + FilterKM;
        5: // Driver
          MyWebRequest.URL := MyWebRequest.URL + '&driver=' + SearchEdit.Text;
       end;
    end;
    if StrToInt(SearchTypeEdit.Value) = 6 then begin
      MyWebRequest.URL := MyWebRequest.URL + '&status=' + AssetStatusComboBox.Value;
    end;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Status Code
    if MyRequest.Status = 200 then begin
      // Parse Result
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        // Status
        ListButtonAllLabel.Caption :=
          'All (' + MyJSONObject.GetJSONValue('status_all') + ')';
        ListButtonOnlineLabel.Caption :=
          'Online (' + MyJSONObject.GetJSONValue('status_online') + ')';
        ListButtonOfflineLabel.Caption :=
          'Offline (' + MyJSONObject.GetJSONValue('status_offline') + ')';
        ListButtonInactiveLabel.Caption :=
          'Inactive (' + MyJSONObject.GetJSONValue('status_inactive') + ')';
        MyJSONPair := MyJSONObject.Get('records');
        MyRecords := MyJSONPair.JsonValue.ToString;
        // Fill Dataset
        ObjectDataset.Close;
        ObjectDataset.Rows := TJSArray(TJSJSON.parseObject(MyRecords));
        await(ObjectDataset.Open);
      finally
        MyJSON.Free;
      end
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TRealtimeMapForm.ObjectListTimerTimer(Sender: TObject);
begin
  ObjectListTimer.Enabled := False;
end;

procedure TRealtimeMapForm.ObjectPanelClick(Sender: TObject);
var
  Index: Integer;
begin
  Index := -1;
  if (Sender is TPanel) then Index := TPanel(Sender).Tag;
  if Sender is TLabel then Index := TPanel(TLabel(Sender).Parent).Tag;
  if Sender is TImageControl then Index := TPanel(TImageControl(Sender).Parent).Tag;
  if Index >= 0 then begin
    SelectObject(Index);
  end;
end;

procedure TRealtimeMapForm.ObjectRefreshTimerTimer(Sender: TObject);
var
  MyResult: Boolean;
  ErrorMessage: String;
  DebugTime: TDateTime;
  MyModalResult: TModalResult;
  MessageText: String;
begin
  if MarkerVisible then begin
    ObjectRefreshTimer.Enabled := False;
    try
      MyResult := await(ValidInput(ErrorMessage));
      if MyResult then begin
        if ObjectDataset.Active and (ObjectDataset.RecordCount >= RealtimeRefreshAssetCountMax) then begin
          MessageText := 'Refresh asset list?';
          MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog(MessageText, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
          if MyModalResult = mrYes then begin
            await(LoadObjectList());
            await(ShowObjectList());
            await(ShowObjectsMap(MarkerClustering));
            await(RefreshThirdPartyMarkers());
            // Restore Selection
            if RealTimeObject.IMEI <> EmptyStr then begin
              if ObjectDataset.Locate('IMEI', RealTimeObject.IMEI, []) then begin
                ClearRealTimeObject();
                SelectObject(ObjectDataset.RecNo-1);
              end;
            end;
          end;
        end else begin
        //WebWaitMessage.Show;
          await(LoadObjectList());
          await(ShowObjectList());
          await(ShowObjectsMap(MarkerClustering));
          await(RefreshThirdPartyMarkers());
          // Restore Selection
          if RealTimeObject.IMEI <> EmptyStr then begin
            if ObjectDataset.Locate('IMEI', RealTimeObject.IMEI, []) then begin
              ClearRealTimeObject();
              SelectObject(ObjectDataset.RecNo-1);
            end;
          end;
        end;
      end;
    finally
      ObjectRefreshTimer.Enabled := True;
    end;
  end;
end;

procedure TRealtimeMapForm.RealtimeMapElementContainers0Actions0Execute(
  Sender: TObject; AEventData: TTMSFNCMapsEventData);
begin
  // Reset Distance
  DistanceTotal := 0;
  SetRulerDistance(MapZoomLevel, DistanceTotal);
  // Clear PolyLine
  RealTimeMap.BeginUpdate;
  try
    RealtimeMap.Polylines[0].Recreate := True;
    RealTimeMap.Polylines[0].Coordinates.Clear;
    RealTimeMap.Polylines[1].Visible := False;
    RealTimeMap.PolyLines[1].Coordinates[1].Latitude :=
      RealTimeMap.PolyLines[1].Coordinates[0].Latitude;
    RealTimeMap.PolyLines[1].Coordinates[1].Longitude :=
      RealTimeMap.PolyLines[1].Coordinates[0].Longitude;
  finally
    RealtimeMap.EndUpdate;
  end;
  DistanceRulerButton.Tag := 0;
  RealtimeMap.ExecuteJavaScript(
    'document.getElementById("customRulerDistanceStopButton1").innerText = "Start";'
  );
end;

procedure TRealtimeMapForm.RealtimeMapElementContainers0Actions1Execute(
  Sender: TObject; AEventData: TTMSFNCMapsEventData);
begin
  if DistanceRulerButton.Tag = 0 then begin
    DistanceRulerButton.Tag := 1;
    RealtimeMap.ExecuteJavaScript(
      'document.getElementById("customRulerDistanceStopButton1").innerText = "Stop";'
    );
  end else begin
    DistanceRulerButton.Tag := 0;
    RealtimeMap.BeginUpdate;
    try
      RealTimeMap.Polylines[1].Visible := False;
      RealTimeMap.PolyLines[1].Coordinates[1].Latitude :=
        RealTimeMap.PolyLines[1].Coordinates[0].Latitude;
      RealTimeMap.PolyLines[1].Coordinates[1].Longitude :=
        RealTimeMap.PolyLines[1].Coordinates[0].Longitude;
    finally
      RealtimeMap.EndUpdate;
    end;
    DistanceTotal := RealTimeMap.Polylines[0].Distance;
    SetRulerDistance(MapZoomLevel, DistanceTotal);
    RealtimeMap.ExecuteJavaScript(
      'document.getElementById("customRulerDistanceStopButton1").innerText = "Start";'
    );
  end;
end;

procedure TRealtimeMapForm.RealtimeMapGetZoomLevel(Sender: TObject; AZoomLevel: Double);
var
  AString: String;
begin
  MapZoomLevel := AZoomLevel;
  SetRulerDistance(MapZoomLevel, DistanceTotal);
end;

procedure TRealtimeMapForm.RealtimeMapMapClick(Sender: TObject;
  AEventData: TTMSFNCMapsEventData);
var
  MyCoordinate: TTMSFNCMapsCoordinateItem;
begin
  if RealtimeMap.ElementContainers[0].Visible then begin
    if DistanceRulerButton.Tag = 1 then begin
      AddRulerCoordinate(
        AEventData.Coordinate.Latitude,
        AEventData.Coordinate.Longitude
      );
    end;
  end;
end;

procedure TRealtimeMapForm.RealtimeMapMapInitialized(Sender: TObject);
begin
//RealTimeMap.Options.MapStyle := MAPSTYLENIGHT;
  ObjectRefreshTimer.Enabled := False;
  WebWaitMessage.Show;
  try
    await(LoadEveryThing());
  finally
    WebWaitMessage.Hide;
    ObjectRefreshTimer.Enabled := True;
  end;
end;

procedure TRealtimeMapForm.RealtimeMapMapMouseMove(Sender: TObject;
  AEventData: TTMSFNCMapsEventData);
var
  MyPolyLeg: TTMSFNCGoogleMapsPolyline;
  MySymbol: TTMSFNCGoogleMapsPolylineSymbol;
  TargetRec: TTMSFNCMapsCoordinateRec;
begin
  if RealtimeMap.ElementContainers[0].Visible then begin
    if DistanceRulerButton.Tag = 1 then begin
      RealtimeMap.BeginUpdate;
      try
        if RealtimeMap.Polylines[0].Coordinates.Count > 0 then begin
          RealTimeMap.Polylines[1].Recreate := True;
          RealTimeMap.Polylines[1].Coordinates[1].Latitude := AEventData.Coordinate.Latitude;
          RealTimeMap.Polylines[1].Coordinates[1].Longitude := AEventData.Coordinate.Longitude;
          RealTimeMap.Polylines[1].Visible := True;
        end;
        DistanceTotal :=
          RealtimeMap.Polylines[0].Distance + RealtimeMap.Polylines[1].Distance;
      finally
        RealTimeMap.EndUpdate;
      end;
      SetRulerDistance(MapZoomLevel, DistanceTotal);
    end;
  end;
end;

procedure TRealtimeMapForm.RealtimeMapMarkerClick(Sender: TObject;
  AEventData: TTMSFNCMapsEventData);
begin
  if AEventData.Marker.DataString = EmptyStr then begin
    SelectObject(AEventData.Marker.DataInteger);
  end else begin
    RealTimeMap.CloseAllPopups;
    RealTimeMap.ShowPopup(
      AEventData.Marker.Coordinate.ToRec, AEventData.Marker.DataString, 0, -20
    );
  end;
end;

procedure TRealtimeMapForm.RealtimeMapPolyElementClick(Sender: TObject;
  AEventData: TTMSFNCMapsEventData);
var
  APolyLine: TTMSFNCGoogleMapsPolyline;
begin
  if Assigned(AEventData.PolyElement) then begin
    if (AEventData.PolyElement is TTMSFNCGoogleMapsPolyline) then begin
      if RealtimeMap.ElementContainers[0].Visible then begin
        if DistanceRulerButton.Tag = 1 then begin
          AddRulerCoordinate(
            AEventData.Coordinate.Latitude,
            AEventData.Coordinate.Longitude
          );
        end;
      end;
    end;
  end;
end;

procedure TRealtimeMapForm.RealtimeMapZoomChanged(Sender: TObject;
  AEventData: TTMSFNCMapsEventData);
begin
  RealTimeMap.GetZoomLevel();
end;

procedure TRealtimeMapForm.SearchButtonClick(Sender: TObject);
begin
  PerformSearch();
  RealTimeMap.CloseAllPopups;
end;

procedure TRealtimeMapForm.SearchEditKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if Key = VK_Return then begin
    Key := 0;
    PerformSearch();
  end;
end;

procedure TRealtimeMapForm.SearchTypeEditChange(Sender: TObject);
var
  StatusPKey: Integer;
begin
  StatusPKey := StrToInt(TLookupCombobox(Sender).Value);
  case StatusPKey of
    6: begin
      Await(LoadAssetStatusList());
      AssetStatusComboBox.Visible := True;
    end;
    else begin
      AssetStatusComboBox.Visible := False;
    end;
  end;
end;

procedure TRealtimeMapForm.ShowObjectList();
var
  I: Integer;
  AString: String;
  IMEI: String;
  IOStatus: String;
  IOIgnition: Integer;
  ErrorStatus: Integer;
  ShowTriangle: Boolean;
  ShowCircle: Boolean;
  APanel: TPanel;
  ALabel: TLabel;
  AImage: TImageControl;
  WebScrollBox: TScrollBox;
  WebHTMLContainer: THTMLContainer;
begin

  try
    Self.BeginUpdate;
    try

      // Delete ScrollBox
      for I := ObjectListPanel.ControlCount-1 downto 0 do begin
        WebScrollBox := TScrollBox(ObjectListPanel.Controls[I]);
        WebScrollBox.Free;
      end;

      // Create ScrollBox
      WebScrollBox := TScrollBox.Create(ObjectListPanel);
      WebScrollBox.Color := clWhite;
      WebScrollBox.ScrollBars := ssVertical;
      WebScrollBox.BorderStyle := bsNone;

      ObjectDataset.First;
      for I := 0 to ObjectDataset.RecordCount-1 do begin

        // IO-Status
        IMEI := ObjectDataset.FieldByName('IMEI').AsString;
        IOStatus := ObjectDataset.FieldByName('IO Status').AsString;
        IOIgnition := ObjectDataset.FieldByName('IO Ignition').AsInteger;
        ErrorStatus := ObjectDataset.FieldByName('Error Status').AsInteger;
        ShowTriangle := (ObjectDataset.FieldByName('Device Triangle').AsInteger > 0);
        ShowCircle := (ObjectDataset.FieldByName('Device Circle').AsInteger > 0);

        // Panel
        APanel := TPanel.Create(WebScrollBox);
        with APanel do begin
          Tag := I;
          Parent := WebScrollBox;
          Align := AlTop;
          Height := ObjectHeight;
          BorderStyle := bsNone;
          Color := clWhite;
          OnClick := ObjectPanelClick;
        end;

        // Control 0 - License Plat
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          Name := 'LicensePlate' + IntToStr(I);
          Top := 5;
          Left := 10;
          Font.Name := 'Verdana';
          Font.Size := 11;
          Caption := ObjectDataset.FieldByName('License Plate').AsString;
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;;
        end;

        // Control 1 - DateTime
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          Top := 8;
          Left := 100;
          Font.Name := 'Verdana';
          Font.Color := clGray;
          AString := ObjectDataset.FieldByName('DateTime').AsString;
          // Reverse Date
          Caption :=
            Copy(AString, 9, 2) + '-' + Copy(AString, 6, 2) + '-' +
            Copy(AString, 1, 4) + ' ' + Copy(Astring, 12, 5);
//        Caption := Copy(AString, 1, Length(AString)-3);
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 2 - Speed
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          Top := 8;
          Left := 220;
          Font.Name := 'Verdana';
          Font.Color := clGray;
          if (IOStatus[(8-IOIgnition)+1] = '1') then begin
            Caption := ObjectDataset.FieldByName('Speed').AsString + ' kph';
          end else begin
            Caption := '0 kph';
          end;
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 3 - Model - Employee
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          Top := 27;
          Left := 11;
          Font.Name := 'Verdana';
          Font.Color := clGray;
          AString := EmptyStr;
//        AString := ObjectDataset.FieldByName('Symbol').AsString;
          if not(ObjectDataset.FieldByName('Object Id').AsString.IsEmpty) then begin
            AString := ObjectDataset.FieldByName('Object Id').AsString + ' - ';
          end;
          AString := AString +
            Trim(
              ObjectDataset.FieldByName('Brand').AsString + ' ' +
              ObjectDataset.FieldByName('Model').AsString
            );
          if not(ObjectDataset.FieldByName('Employee Name').AsString.IsEmpty) then begin
            AString := AString + ' (' + ObjectDataset.FieldByName('Employee Name').AsString + ')';
          end;
          Caption := AString;
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 4
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          Top := 46;
          Left := 11;
          Font.Name := 'Verdana';
          Caption :=
            ObjectDataset.FieldByName('Street').AsString + ', ' +
            ObjectDataset.FieldByName('Postal Code').AsString + ' ' +
            ObjectDataset.FieldByName('Municipality').AsString + ', ' +
            ObjectDataset.FieldByName('Country').AsString;
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 5
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          Top := 72;
          Left := 31;
          Font.Name := 'Verdana';
          Caption := ObjectDataset.FieldByName('Group').AsString;
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 6
        ALabel := TLabel.Create(APanel);
        with ALabel do begin
          Parent := APanel;
          AutoSize := False;
          Alignment := taRightJustify;
          Top := 70;
          Left := 203;
          Width := 152;
          Height := 17;
          Transparent := False;
          Font.Name := 'Verdana';
          html := '&nbsp;' + ObjectDataset.FieldByName('Asset Status').AsString + '&nbsp;';
          Color := HexToColor(ObjectDataset.FieldByName('Asset Status HexColor').AsString);
          OnClick := Parent.OnClick;
//        OnMouseDown := FormMouseDown;
        end;

        // Control 7
        AImage := TImageControl.Create(APanel);
        with AImage do begin
          Parent := APanel;
          Height := 16;
          Width := 16;
          Top := 71;
          Left := 10;
          URL := url_img + 'work_user_16px.png';
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 8
        AImage := TImageControl.Create(APanel);
        with AImage do begin
          Parent := APanel;
          Height := 5;
          Width := Self.Width;
          Top := 92;
          Left := 0;
          URL := url_img + 'line.png';
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 9
        AImage := TImageControl.Create(APanel);
        with AImage do begin
          Parent := APanel;
          Height := 84;
          Width := 3;
          Top := 5;
          Left := 0;
          URL := url_img + 'line2.png';
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
          Visible := (RealTimeObject.IMEI = IMEI);
        end;

        // Control 10
        AImage := TImageControl.Create(APanel);
        with AImage do begin
          Parent := APanel;
          Height := 16;
          Width := 16;
          Top := 6;
          Left := 296;
          case ErrorStatus of
            1: URL := url_img + 'important-16-orange.png';
            2: URL := url_img + 'important-16-yellow.png';
            3: URL := url_img + 'important-16-red.png';
          end;
          Visible := ShowTriangle and (ErrorStatus > 0);
          OnClick := Parent.OnClick;
//            OnMouseDown := FormMouseDown;
        end;

        // Control 11
        AImage := TImageControl.Create(APanel);
        with AImage do begin
          Parent := APanel;
          Height := 16;
          Width := 16;
          Top := 6;
          Left := 320;
          if (IOStatus[(8-IOIgnition)+1] = '1') then begin
            if ObjectDataset.FieldByName('Speed').AsFloat > 0 then begin
              URL := url_img + 'speed.png';
            end else begin
              URL := url_img + 'idle.png';
            end;
          end else begin
            URL := url_img + 'stopped.png';
          end;
          Visible := ShowCircle;
          OnClick := Parent.OnClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 12
        AImage := TImageControl.Create(APanel);
        with AImage do begin
          Parent := APanel;
          Height := 16;
          Width := 16;
          Top := 6;
          Left := 344;
          URL := url_img + 'dots.png';
          OnClick := DotsImageClick;
//          OnMouseDown := FormMouseDown;
        end;

        // Control 13
        if ObjectDataset.FieldByName('Battery Status').AsString = 'Low' then begin
          AImage := TImageControl.Create(APanel);
          with AImage do begin
            Parent := APanel;
            Height := 16;
            Width := 16;
            Top := 6;
            Left := 272;
  //        if ObjectDataset.FieldByName('Battery Status').AsString = 'High' then begin
  //          URL := url_img + 'battery-full.png';
  //        end else begin
              URL := url_img + 'battery-empty.png';
  //        end;
            OnClick := Parent.OnClick;
  //        OnMouseDown := FormMouseDown;
          end;
        end;

        // Next Record
        ObjectDataset.Next;

      end;

    finally
      Self.EndUpdate;
    end;

  except
    on E: Exception do begin
      ShowMessage(E.Message);
    end;
  end;

  WebScrollBox.Parent := ObjectListPanel;
  WebScrollBox.Align := alClient;

end;

procedure TRealTimeMapForm.ShowObjectsMap(ShowCluster: Boolean);
var
  IMEI, URL: String;
  I: Integer;
  S: String;
  m: TTMSFNCGoogleMapsMarker;
  c: TTMSFNCGoogleMapsCluster;
  MyMapCircle: TTMSFNCMapsCircle;
begin
  // Clear All
  I := 0;
  RealtimeMap.ClearMarkers;
  RealtimeMap.ClearClusters;
  RealtimeMap.ClearOverlayViews;
  // Add Markers
  RealtimeMap.BeginUpdate;
  try
    if ObjectDataset.Active and not(ObjectDataset.IsEmpty) then begin
      // Create Cluster
      if ShowCluster then begin
        c := RealtimeMap.Clusters.Add;
        c.MinimumNumberOfMarkers := 0;
      end;
      ObjectDataset.First;
      while not(ObjectDataset.Eof) do begin
        IMEI := ObjectDataset.FieldByName('IMEI').AsString;
        m := RealtimeMap.Markers.Add;
        m.DataInteger := ObjectDataset.RecNo-1;
        m.Latitude := StrToFloatDot(ObjectDataset.FieldByName('Latitude').AsString);
        m.Longitude := StrToFloatDot(ObjectDataset.FieldByName('Longitude').AsString);
        URL := url_img + 'images/' + ObjectDataset.FieldByName('URL').AsString;
        if RealTimeObject.IMEI = IMEI then begin
          URL := StringReplace(URL, 'a.png', 'c.png', [rfReplaceAll]);
          I := RealTimeMap.Markers.Count-1;
        end;
        m.IconURL := URL;
        m.Title := ObjectDataset.FieldByName('License Plate').AsString;
        m.Draggable := False;
        // Overlay
        case MarkerOverlayType of
          1: S := ObjectDataset.FieldByName('License Plate').AsString;
          2: S := ObjectDataset.FieldByName('Symbol').AsString;
          3: S := ObjectDataset.FieldByName('Object ID').AsString;
          4: S := ObjectDataset.FieldByName('Employee Name').AsString;
        end;
        m.AddOverlayView(S);
        m.OverlayView.BorderColor := clGray;
        m.OverlayView.BackgroundColor := clWhite;
        m.OverlayView.Font.Color := clGray;
//      m.OverlayView.Font.Size := 18;
        m.OverlayView.Visible := MarkerOverlayVisible;
//      // Cluster
        if ShowCluster then m.Cluster := c;
        // Next
        ObjectDataset.Next;
      end;
      if I > 0 then begin
        RealTimeMap.Markers[I].zIndex := RealTimeMap.Markers.Count;
      end;
    end;
    // Add Circle
    if (FilterLat <> EmptyStr) and (FilterLng <> EmptyStr) and (FilterKM <> EmptyStr) then begin
      if not(Geofences) then begin
        RealTimeMap.Circles.Clear;
        RealTimeMap.Polygons.Clear;
        if RealTimeMap.Circles.Count = 0 then begin
          MyMapCircle :=
            RealTimeMap.AddCircle(CreateCoordinate(StrToFloatDot(FilterLat), StrToFloatDot(FilterLng)), StrToInt(FilterKM) * 1000);
          with MyMapCircle do begin
            FillColor := gcOrange;
            FillOpacity := 0.1;
            StrokeColor := gcGreen;
            StrokeWidth := 1;
          end;
        end else begin
          with RealTimeMap.Circles[0] do begin
            Center.Latitude := StrToFloatDot(FilterLat);
            Center.Longitude := StrToFloatDot(FilterLng);
            Radius := StrToInt(FilterKM) * 1000;
          end;
        end;
      end;
    end else begin
      if not(Geofences) then begin
        RealTimeMap.Circles.Clear;
        RealTimeMap.Polygons.Clear;
      end;
    end;
  finally
    RealtimeMap.EndUpdate;
  end;
end;

procedure TRealTimeMapForm.ZoomObjectsMap();
var
  I: Integer;
  WebMarker: TTMSFNCGoogleMapsMarker;
  WebBounds: TTMSFNCMapsBoundsRec;
begin
  MarkerCount := RealTimeMap.Markers.Count;
  if MarkerCount > 0 then begin
    // Default Values
    MarkerLatMin := 999;
    MarkerLatMax := -999;
    MarkerLonMin := 999;
    MarkerLonMax := -999;
    // Loop Markers
    for I := 0 to MarkerCount-1 do begin
      WebMarker := RealTimeMap.Markers[I];
      if WebMarker.DataInteger >= 0 then begin
        // Update Bounds
        if WebMarker.Latitude < MarkerLatMin then MarkerLatMin := WebMarker.Latitude;
        if WebMarker.Latitude > MarkerLatMax then MarkerLatMax := WebMarker.Latitude;
        if WebMarker.Longitude < MarkerLonMin then MarkerLonMin := WebMarker.Longitude;
        if WebMarker.Longitude > MarkerLonMax then MarkerLonMax := WebMarker.Longitude;
      end;
    end;
    // POI
    if (POICount = 0) or (POIZoomToBounds = False) then begin
      if MarkerCount = 1 then begin
        RealTimeMap.SetCenterCoordinate(MarkerLatMin, MarkerLonMin);
      end else begin
        WebBounds.NorthEast.Latitude := MarkerLatMax;
        WebBounds.NorthEast.Longitude := MarkerLonMax;
        WebBounds.SouthWest.Latitude := MarkerLatMin;
        WebBounds.SouthWest.Longitude := MarkerLonMin;
        RealTimeMap.ZoomToBounds(WebBounds);
      end;
    end else begin
      if POILatMin < MarkerLatMin then begin
        WebBounds.SouthWest.Latitude := POILatMin;
      end else begin
        WebBounds.SouthWest.Latitude := MarkerLatMin;
      end;
      if POILatMax > MarkerLatMax then begin
        WebBounds.NorthEast.Latitude := POILatMax;
      end else begin
        WebBounds.NorthEast.Latitude := MarkerLatMax;
      end;
      if POILonMin < MarkerLonMin then begin
        WebBounds.SouthWest.Longitude := POILonMin;
      end else begin
        WebBounds.SouthWest.Longitude := MarkerLonMin;
      end;
      if POILonMax > MarkerLonMax then begin
        WebBounds.NorthEast.Longitude := POILonMax;
      end else begin
        WebBounds.NorthEast.Longitude := MarkerLonMax;
      end;
      RealTimeMap.ZoomToBounds(WebBounds);
    end;
  end else begin
    if (POICount > 0) and (POIZoomToBounds = True) then begin
      WebBounds.NorthEast.Latitude := POILatMax;
      WebBounds.NorthEast.Longitude := POILonMax;
      WebBounds.SouthWest.Latitude := POILatMin;
      WebBounds.SouthWest.Longitude := POILonMin;
      RealTimeMap.ZoomToBounds(WebBounds);
    end;
  end;
end;

procedure TRealtimeMapForm.ZoomToBoundButtonClick(Sender: TObject);
begin
  await(ZoomObjectsMap());
end;

procedure TRealTimeMapForm.SelectObject(Index: Integer);
var
  I: Integer;
  URL: String;
  MyTop, MyBottom: Integer;
  ScrollBox: TScrollBox;
  WebPanel: TPanel;
  WebImage: TImageControl;
begin
  ObjectDataset.RecNo := Index+1;
  // Save Selection
  with RealTimeObject do begin
    if IMEI <> ObjectDataset.FieldByName('IMEI').AsString then begin
      Symbol := ObjectDataset.FieldByName('Symbol').AsInteger;
      IMEI := ObjectDataset.FieldByName('IMEI').AsString;
      LicensePlate := ObjectDataset.FieldByName('License Plate').AsString;
    end else begin
      Index := -1;
      ClearRealTimeObject;
    end;
  end;
  // Indicate Selected Opject
  ScrollBox := TScrollBox(ObjectListPanel.Controls[0]);
  for I := 0 to ScrollBox.ControlCount-1 do begin
    WebPanel := TPanel(ScrollBox.Controls[I]);
    WebImage := TImageControl(WebPanel.Controls[9]);
    WebImage.Visible := I = Index;
  end;
  // Scroll Into View
  if Index >= 0 then begin
     MyTop := Index * ObjectHeight;
     MyBottom := MyTop + ObjectHeight;
     if (MyTop < ScrollBox.ScrollTop) then begin
        ScrollBox.ScrollTop := MyTop;
     end else begin
        if
           (MyBottom > (ObjectListPanel.Height + ScrollBox.ScrollTop))
           or
           (MyTop > (ObjectListPanel.Height + ScrollBox.ScrollTop))
        then begin
           ScrollBox.ScrollTop := MyTop;
        end;
     end;
  end;
  // Indicate Selected Marker
  for I := 0 to RealTimeMap.Markers.Count-1 do begin
    URL := RealTimeMap.Markers[I].IconURL;
    if I = Index then begin
      URL := StringReplace(URL, 'a.png', 'c.png', [rfReplaceAll]);
      RealTimeMap.Markers[I].zIndex := RealTimeMap.Markers.Count;
    end else begin
      if Pos('c.png', URL) > 0 then begin
        URL := StringReplace(URL, 'c.png', 'a.png', [rfReplaceAll]);
        RealTimeMap.Markers[I].zIndex := I;
      end;
    end;
    RealTimeMap.Markers[I].IconURL := URL;
  end;
end;

procedure TRealTimeMapForm.ShowTeamFilterListDialog();
begin
  TeamFilterListForm := TTeamFilterListForm.Create(Self);
  TeamFilterListForm.Caption := 'Filter';
  TeamFilterListForm.Popup := True;
  TeamFilterListForm.Border := fbNone;
  await(TTeamFilterListForm, TeamFilterListForm.Load());
  try
    // excute form and wait for close
    await(TModalResult, TeamFilterListForm.Execute);
    if TeamFilterListForm.ModalResult = mrOK then begin
    end;
  finally
    TeamFilterListForm.Free;
  end;
end;

procedure TRealTimeMapForm.ShowTypeFilterListDialog();
begin
  TypeFilterListForm := TTypeFilterListForm.Create(Self);
  TypeFilterListForm.Popup := True;
  TypeFilterListForm.Border := fbNone;
  await(TTypeFilterListForm, TypeFilterListForm.Load());
  try
    // excute form and wait for close
    await(TModalResult, TypeFilterListForm.Execute);
    if TypeFilterListForm.ModalResult = mrOK then begin
    end;
  finally
    TypeFilterListForm.Free;
  end;
end;

procedure TRealTimeMapForm.ShowHistoryDialog(HistoryRecNo, HistoryMode: Integer);
var
  MyModalResult: TModalResult;
begin
  HistoryMapForm := THistoryMapForm.Create(Self);
  HistoryMapForm.Popup := True;
  HistoryMapForm.Border := fbNone;
  await(THistoryMapForm, HistoryMapForm.Load());
  try
    // Initialize
    ObjectDataset.RecNo := HistoryRecNo;
    HistoryMapForm.AssetPKey := ObjectDataset.FieldByName('AssetPKey').AsString;
    HistoryMapForm.LicensePlate := ObjectDataset.FieldByName('License Plate').AsString;
    HistoryMapForm.IMEI := ObjectDataset.FieldByName('IMEI').AsInteger;
    HistoryMapForm.Mode := HistoryMode;
    // Show Form
    MyModalResult := await(TModalResult, HistoryMapForm.Execute);
    if MyModalResult = mrOK then begin
    end;
  finally
    HistoryMapForm.Free;
  end;
end;

procedure TRealtimeMapForm.FilterChange(Sender: TObject);
begin
  ObjectRefreshTimer.Enabled := False;
end;

procedure TRealtimeMapForm.GeofenceButtonClick(Sender: TObject);
begin
  RealtimeMap.Polygons.Clear;
  RealtimeMap.Circles.Clear;
  if Geofences then begin
    Geofences := False;
  end else begin
    Geofences := True;
    LoadGeofences();
  end;
end;

procedure TRealtimeMapForm.TeamFilterButtonClick(Sender: TObject);
begin
  ShowTeamFilterListDialog();
end;

procedure TRealtimeMapForm.TypeFilterButtonClick(Sender: TObject);
begin
  ShowTypeFilterListDialog()
end;

procedure TRealtimeMapForm.PerformSearch;
var
  MyResult: Boolean;
  MyStringList: TStringList;
  MyErrorMessage: String;
begin

  // Validate Input
  MyResult := await(ValidInput(MyErrorMessage));
  if MyResult then begin

    // Disable Refresh
    ObjectRefreshTimer.Enabled := False;
    WebWaitMessage.Show;

    // Search Type = Address
    case StrToInt(SearchTypeEdit.Value) of
      1, 2, 3, 5, 6: begin
        await(ClearFilter());
      end;
      4: begin
        Geofences := False;
        if Trim(SearchEdit.Text) <> EmptyStr then begin
          // Compare Current / Old Search Criteria
          MyStringList := TStringList.Create;
          MyStringList.StrictDelimiter := True;
          try
            MyStringList.DelimitedText := Trim(SearchEdit.Text);
            if
              (FilterAddress <> Trim(UpperCase(MyStringList[0])))
            or
              (FilterCountry <> Trim(UpperCase(MyStringList[1])))
            then begin
              FilterAddress := Trim(UpperCase(MyStringList[0]));
              FilterCountry := Trim(UpperCase(MyStringList[1]));
              MyResult := await(GeocodeAddress(FilterAddress, FilterCountry, FilterLat, FilterLng, MyErrorMessage));
            end;
            FilterKM := Trim(MyStringList[2]);
          finally
            MyStringList.Free;
          end;
        end else begin
          await(ClearFilter());
        end;
      end;
    end;

    if MyResult then begin
      await(LoadObjectList());
      await(ShowObjectList());
      await(ShowObjectsMap(MarkerClustering));
      await(RefreshThirdPartyMarkers());
      await(ZoomObjectsMap());
      ObjectRefreshTimer.Enabled := True;
      WebWaitMessage.Hide;
    end else begin
      WebWaitMessage.Hide;
      MessageDlg(MyErrorMessage, mtError, [mbOK], nil);
    end;

    WebWaitMessage.Hide;

  end else begin

    // Invalid Input
    MessageDlg(MyErrorMessage, mtError, [mbOK], nil);

  end;
end;

function TRealtimeMapForm.ValidInput(var ErrorMessage: string): Boolean;
var
  MyInteger: Integer;
  MyStringList, CountryList: TStringList;
begin
  Result := True;
  if (TeamComboBox.ItemIndex >= 0) and (TypeComboBox.ItemIndex >= 0) then begin
//    GEEN ALL / ALL
//    if ((StrToInt(TeamComboBox.Value) = 0) and (StrToInt(TypeComboBox.Value) = 0)) then begin
//      if ((StrToInt(SearchTypeEdit.Value) <> 6) and (Trim(SearchEdit.Text) = EmptyStr)) then begin
//        Result := False;
//        ErrorMessage := 'Search for all / all are only allowed in combination with a search value!';
//      end;
//    end;
    if Result then begin
      if Trim(SearchEdit.Text) <> EmptyStr then begin
        case StrToInt(SearchTypeEdit.Value) of
          1: begin // License Plate
            // No Check Required
          end;
          2: begin // Object NO
            if not(TryStrToInt(Trim(SearchEdit.Text), MyInteger)) then begin
              Result := False;
              ErrorMessage := 'Asset No has to be a natural number!';
            end;
          end;
          3: begin // Object ID
            // No Check Required
          end;
          4: begin // Address
            MyStringList := TStringList.Create;
            MyStringList.StrictDelimiter := True;
            try
              MyStringList.DelimitedText := Trim(SearchEdit.Text);
              if MyStringList.Count = 3 then begin
                // Address
                if Result then begin
                  if Trim(MyStringList[0]) = EmptyStr then begin
                    Result := False;
                    ErrorMessage := 'Expected format: London,GB,5';
                  end;
                end;
                // Country
                if Result then begin
                  if (Trim(MyStringList[1]) <> EmptyStr) then begin
                    CountryList := TStringList.Create;
                    try
                      CountryList.DelimitedText := CountryList2;
                      if CountryList.IndexOf(Trim(MyStringList[1])) = -1 then begin
                        Result := False;
                        ErrorMessage := 'Invalid country code: ' + Trim(MyStringList[1]);
                      end;
                    finally
                      CountryList.Free;
                    end;
                  end else begin
                    Result := False;
                    ErrorMessage := 'Expected format: London,GB,5';
                  end;
                end;
                // Distance
                if Result then begin
                  if TryStrToInt(MyStringList[2], MyInteger) then begin
                    if MyInteger > RealTimeFilterDistanceMax then begin
                      Result := False;
                      ErrorMessage := 'Maximum distance: ' + IntToStr(RealTimeFilterDistanceMax) + ' Km';
                    end;
                  end else begin
                    Result := False;
                    ErrorMessage := 'Expected format: London,GB,5';
                  end;
                end;
              end else begin
                Result := False;
                ErrorMessage := 'Expected format: London,GB,5';
              end;
            finally
              MyStringList.Free;
            end;
          end;
          5: begin // Driver
            // No Check Required
          end;
        end;
      end;
    end;
  end;
end;

procedure TRealtimeMapForm.AssetEditMenuItemClick(Sender: TObject);
var
  AssetPKey: Int64;
  MyWebForm: TAssetEditForm;
  MyModalResult: TModalResult;
  Permission: Integer;
begin
  // Initialize
  if ObjectDataset.Active and not(ObjectDataset.IsEmpty) then begin
    // Get PKey
    ObjectDataset.RecNo := ListPopupMenu.Tag+1;
    AssetPKey := ObjectDataset.FieldByName('AssetPKey').AsInteger;
    // Create Form
    MyWebForm := TAssetEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.PKey := AssetPKey;
    await(TAssetEditForm, MyWebForm.Load());
    try
      MyWebForm.SelectDeviceButton.Enabled := True;
      MyWebForm.ClearDeviceButton.Enabled := True;
      MyWebForm.SelectDriverButton.Enabled := True;
      MyWebForm.ClearDriverButton.Enabled := True;
      // Permission
      Permission := GetUserPermissionValue(Permissions, 'assets');
      if Permission in [1,5] then begin
        MyWebForm.SetReadOnly;
      end;
      MyWebForm.IsSet := True;
      // excute form and wait for close
      MyModalResult := await(TModalResult, MyWebForm.Execute);
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TRealtimeMapForm.AssetHideButtonClick(Sender: TObject);
begin
  MarkerVisible := not(MarkerVisible);
  if MarkerVisible then begin
    await(ShowObjectsMap(MarkerClustering));
    await(RefreshThirdPartyMarkers());
    // Restore Selection
    if RealTimeObject.IMEI <> EmptyStr then begin
      if ObjectDataset.Locate('IMEI', RealTimeObject.IMEI, []) then begin
        ClearRealTimeObject();
        SelectObject(ObjectDataset.RecNo-1);
      end;
    end;
  end else begin
    RealTimeMap.BeginUpdate;
    try
      RealTimeMap.CloseAllPopups;
      RealtimeMap.ClearClusters;
      RealtimeMap.ClearOverlayViews;
      RealTimeMap.ClearMarkers;
    finally
      RealTimeMap.EndUpdate;
    end;
  end;
end;

procedure TRealtimeMapForm.ChangeDriverMenuItemClick(Sender: TObject);
var
  MyWebForm: TUserListForm;
  MyModalResult: TModalResult;
  UserPKey: String;
  DriverPKey, DriverName: String;
  CurrentObjectPKey: String;
  ObjectPKey: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MessageText: String;
begin
  // Vereisten
  // Assetgroep voertuig is gekoppeld bij gebruiker
  // Authorisatie - Asset Driver -> Ja
  // Authorisatie - Asset -> Nee

  if ObjectDataset.Active and not(ObjectDataset.IsEmpty) then begin
    ObjectDataset.RecNo := ListPopupMenu.Tag+1;
    CurrentObjectPKey := ObjectDataset.FieldByName('AssetPKey').AsString;
    MyWebForm := TUserListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TUserListForm, MyWebForm.Load());
    try
      MyWebForm.SelectButton.Visible := True;
      // Filter Driver = 1
//    MyWebForm.MasterDataset.Filter := 'driver=''1''';
      MyWebForm.MasterDataset.Filtered := True;
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        UserPKey := MyWebForm.UserPKey;
        // Get Driver / Assets Linked To User
        MyWebRequest := THTTPRequest.Create(Self);
        try
          // Request
          MyWebRequest.URL :=
            url_php + 'default_user_object_get.php?sessionid=' + SessionId +
            '&pkey=' + UserPKey;
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          // Status
          if MyRequest.Status = 200 then begin
            MyJSON := TJSON.Create;
            try
              MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
              if MyJSONObject.GetJSONValue('status') = 'OK' then begin
                DriverPKey := MyJSONObject.GetJSONValue('driver_pkey');
                DriverName := MyJSONObject.GetJSONValue('driver_name');
                ObjectPKey := MyJSONObject.GetJSONValue('license_plate');
                if ObjectPKey <> EmptyStr then begin
                  MessageText :=
                    'Driver is already linked with asset(s) "' + ObjectPKey + '". Continue?';
                end;
              end;
            finally
              MyJSON.Free;
            end;
          end;
        finally
          MyWebRequest.Free;
        end;
        // Confirmation
        if ObjectPKey <> EmptyStr then begin
          MyModalResult := await(TModalResult, WebMessageDlg1.ShowDialog(MessageText, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
        end else begin
          MyModalResult := mrYes;
        end;
        if MyModalResult = mrYes then begin
          // Update Link
          MyWebRequest := THTTPRequest.Create(Self);
          try
            // Request
            MyWebRequest.URL :=
              url_php + 'default_object_person_set.php?sessionid=' + SessionId +
              '&person=' + DriverPKey + '&object=' + CurrentObjectPKey;
            MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
            // Status
            if MyRequest.Status = 200 then begin
              // Response
              MyJSON := TJSON.Create;
              try
                MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
                if MyJSONObject.GetJSONValue('status') = 'OK' then begin
                  MessageDlg('Change driver success.', mtInformation, [mbOK], nil);
                end else begin
                  MessageDlg(MyJSONObject.GetJSONValue('error_message'), mtError, [mbOK], nil);
                end;
              finally
                MyJSON.Free;
              end;
            end;
          finally
            MyWebRequest.Free;
          end;
        end;
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TRealtimeMapForm.ChangeStatusMenuItemClick(Sender: TObject);
var
//  MyWebForm: TAssetStatusListForm;
//  MyModalResult: TModalResult;
//  StatusPKey: String;
    CurrentObjectPKey: String;
//  ObjectPKey: String;
//  MyWebRequest: TWebHTTPRequest;
//  MyRequest: TJSXMLHttpRequest;
//  MyJSON: TJSON;
//  MyJSONObject: TJSONObject;
//  MessageText: String;

  MyWebForm: TAssetStatusChangeForm;
begin
  if ObjectDataset.Active and not(ObjectDataset.IsEmpty) then begin
    ObjectDataset.RecNo := ListPopupMenu.Tag+1;
    CurrentObjectPKey := ObjectDataset.FieldByName('AssetPKey').AsString;
    MyWebForm := TAssetStatusChangeForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    MyWebForm.AssetPKey := CurrentObjectPKey;
    await(TAssetStatusChangeForm, MyWebForm.Load());
    try
      // Update Fields
//    MyWebForm.AssetIDEdit.Text := AssetIDEdit.Text;
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        // Update Status
        MessageDlg('Change status success.', mtInformation, [mbOK], nil);
      end;
    finally
      MyWebForm.Free;
    end;
  end;

{
  // Vereisten
  // Assetgroep voertuig is gekoppeld bij gebruiker
  // Authorisatie - Asset Status -> Ja
  // Authorisatie - Asset -> Nee

  if ObjectDataset.Active and not(ObjectDataset.IsEmpty) then begin
    ObjectDataset.RecNo := ListPopupMenu.Tag+1;
    CurrentObjectPKey := ObjectDataset.FieldByName('AssetPKey').AsString;
    MyWebForm := TAssetStatusListForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TAssetStatusListForm, MyWebForm.Load());
    try
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        StatusPKey := MyWebForm.StatusPKey;
        MyWebRequest := TWebHTTPRequest.Create(Self);
        try
          // Request
          MyWebRequest.URL :=
            url_php + 'default_object_status_set.php?sessionid=' + SessionId +
            '&object=' + CurrentObjectPKey +
            '&status=' + StatusPKey;
          MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
          // Response
          MyJSON := TJSON.Create;
          try
            MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
            if MyJSONObject.GetJSONValue('status') = 'OK' then begin
              MessageDlg('Change status success.', mtInformation, [mbOK], nil);
            end else begin
              MessageDlg(MyJSONObject.GetJSONValue('error_message'), mtError, [mbOK], nil);
            end;
          finally
            MyJSON.Free;
          end;
        finally
          MyWebRequest.Free;
        end;
      end;
    finally
      MyWebForm.Free;
    end;
  end;
}
end;

procedure TRealtimeMapForm.ClearFilter();
begin
  FilterAddress := EmptyStr;
  FilterCountry := EmptyStr;
  FilterLat := EmptyStr;
  FilterLng := EmptyStr;
  FilterKM := EmptyStr;
end;

procedure TRealtimeMapForm.LoadPOIList(ZoomToBounds: Boolean);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  WebBounds: TTMSFNCMapsBoundsRec;
begin
  if POIGroupList <> EmptyStr then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_poi_list_kml.php?sessionid=' + SessionId;
      if POIGroupList <> EmptyStr then begin
        MyWebRequest.URL := MyWebRequest.URL + '&g=' + POIGroupList;
      end else begin
        MyWebRequest.URL := MyWebRequest.URL + '&g=-1';
      end;
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Status Code
      if MyRequest.Status = 200 then begin
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          // Get POI Bounds
          POICount := StrToInt64(MyJSONObject.GetJSONValue('poi_count'));
          POILatMin := StrToFloatDot(MyJSONObject.GetJSONValue('poi_lat_min'));
          POILatMax := StrToFloatDot(MyJSONObject.GetJSONValue('poi_lat_max'));
          POILonMin := StrToFloatDot(MyJSONObject.GetJSONValue('poi_lon_min'));
          POILonMax := StrToFloatDot(MyJSONObject.GetJSONValue('poi_lon_max'));
          // Update Bounds
          if ZoomToBounds then begin
            if (POIZoomToBounds) and (POICount > 0) then begin
              if MarkerCount = 0 then begin
                WebBounds.NorthEast.Latitude := POILatMax;
                WebBounds.NorthEast.Longitude := POILonMax;
                WebBounds.SouthWest.Latitude := POILatMin;
                WebBounds.SouthWest.Longitude := POILonMin;
              end else begin
                if POILatMin < MarkerLatMin then begin
                  WebBounds.SouthWest.Latitude := POILatMin;
                end else begin
                  WebBounds.SouthWest.Latitude := MarkerLatMin;
                end;
                if POILatMax > MarkerLatMax then begin
                  WebBounds.NorthEast.Latitude := POILatMax;
                end else begin
                  WebBounds.NorthEast.Latitude := MarkerLatMax;
                end;
                if POILonMin < MarkerLonMin then begin
                  WebBounds.SouthWest.Longitude := POILonMin;
                end else begin
                  WebBounds.SouthWest.Longitude := MarkerLonMin;
                end;
                if POILonMax > MarkerLonMax then begin
                  WebBounds.NorthEast.Longitude := POILonMax;
                end else begin
                  WebBounds.NorthEast.Longitude := MarkerLonMax;
                end;
              end;
              RealTimeMap.ZoomToBounds(WebBounds);
            end else begin
              // Zoom To Markers
              if MarkerCount > 0 then begin
                WebBounds.NorthEast.Latitude := MarkerLatMax;
                WebBounds.NorthEast.Longitude := MarkerLonMax;
                WebBounds.SouthWest.Latitude := MarkerLatMin;
                WebBounds.SouthWest.Longitude := MarkerLonMin;
                RealTimeMap.ZoomToBounds(WebBounds);
              end;
            end;
          end;
          // Update Map
          RealTimeMap.BeginUpdate;
          try
            RealTimeMap.ClearKMLLayers;
            if POICount > 0 then begin
              RealTimeMap.AddKMLLayer(url_kml + MyJSONObject.GetJSONValue('filename'), False);
            end;
          finally
            RealTimeMap.EndUpdate;
          end;
        finally
          MyJSON.Free;
        end;
      end;
    finally
      MyWebRequest.Free;
    end;
  end else begin
    POICount := 0;
    RealTimeMap.BeginUpdate;
    try
      RealTimeMap.ClearKMLLayers;
    finally
      RealTimeMap.EndUpdate;
    end;
    if ZoomToBounds then begin
      // Zoom To Markers
      if MarkerCount > 0 then begin
        WebBounds.NorthEast.Latitude := MarkerLatMax;
        WebBounds.NorthEast.Longitude := MarkerLonMax;
        WebBounds.SouthWest.Latitude := MarkerLatMin;
        WebBounds.SouthWest.Longitude := MarkerLonMin;
        RealTimeMap.ZoomToBounds(WebBounds);
      end;
    end;
  end;
end;

procedure TRealtimeMapForm.RefreshThirdPartyMarkers();
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  MyMarkerURL: String;
  MyMarker: TTMSFNCGoogleMapsMarker;
begin
  if MachineMax then begin
    // Get List
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_asset_list_machinemax.php?sessionid=' + SessionId;
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Status
      if MyRequest.Status = 200 then begin
//      MyMarkerURL :=
//        '<svg height="24" width="24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m12.031 1030.4c-3.8657 0-6.9998 3.1-6.9998 7 0 1.3 0.4017 2.6 1.0938 3.7 0.0334 0.1 0.059 0.1 0.0938 0.2l4.3432 8c0.204 0.6 0.782 1.1 1.438 1.1s1.202-0.5 1.406-1.1l4.844-8.7c0.499-1 0.781-2.1 0.781-3.2 0-3.9-3.134-7-7-7zm-0.031 3.9c1.933 0 3.5 1.6 3.5 3.5 0 2-1.567 3.5-3.5 3.5s-3.5-1.5-3.5-3.5c0-1.9 1.567-3.5 3.5-3.5z" ' +
//        'fill="#' + MachineMaxFillColor + '"/><path d="m12.031 1.0312c-3.8657 0-6.9998 3.134-6.9998 7 0 1.383 0.4017 2.6648 1.0938 3.7498 0.0334 0.053 0.059 0.105 0.0938 0.157l4.3432 8.062c0.204 0.586 0.782 1.031 1.438 1.031s1.202-0.445 1.406-1.031l4.844-8.75c0.499-0.963 0.781-2.06 0.781-3.2188 0-3.866-3.134-7-7-7zm-0.031 3.9688c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5 1.567-3.5 3.5-3.5z" fill="#' + MachineMaxFillColor + '" transform="translate(0 1028.4)"/></g></svg>';

      MyMarkerURL :=
        '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="732.000000pt" height="1280.000000pt" viewBox="0 0 732.000000 1280.000000" preserveAspectRatio="xMidYMid meet">' +
        '<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#' + MachineMaxFillColor + '" stroke="none">' +
        '<path d="M3480 12794 c-25 -2 -101 -9 -170 -14 -1218 -106 -2341 -869 -2904 ' +
          '-1974 -238 -466 -363 -929 -396 -1469 -72 -1160 578 -3181 1950 -6062 204 ' +
          '-426 693 -1400 938 -1865 314 -597 751 -1399 762 -1400 5 0 260 456 416 745 ' +
          '1552 2875 2655 5432 3054 7079 131 543 184 921 184 1316 0 569 -114 1067 -359 ' +
          '1577 -552 1148 -1661 1923 -2935 2053 -112 11 -465 21 -540 14z"/>' +
        '</g>' +
        '</svg>';


//      StringReplace(MachineMaxSVG, 'c0392b',  MachineMaxFillColor, [rfReplaceAll]);
        // Parse List
        MyJSON := TJSON.Create;
        try
          MyJSONArray := TJSONArray(MyJSON.Parse(string(MyRequest.response)));
          RealTimeMap.BeginUpdate;
          try
            for i := 0 to MyJSONArray.Count-1 do begin
              MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
              // Add Marker
              MyMarker := RealtimeMap.Markers.Add;
              MyMarker.DataInteger := -1;
              MyMarker.DataString :=
                '<b>MachineMax</b><br>' +
                MyJSONObject.GetJSONValue('ID') + '<br>' +
                MyJSONObject.GetJSONValue('SN') + '<br>' +
                MyJSONObject.GetJSONValue('Brand') + '<br>' +
                MyJSONObject.GetJSONValue('Model') + '<br><br>' +
                MyJSONObject.GetJSONValue('Timestamp') + '<br>' +
                MyJSONObject.GetJSONValue('Address');
              MyMarker.Title := MyJSONObject.GetJSONValue('ID');
              MyMarker.Latitude :=  StrToFloatDot(MyJSONObject.GetJSONValue('Latitude'));
              MyMarker.Longitude := StrToFloatDot(MyJSONObject.GetJSONValue('Longitude'));
              MyMarker.DefaultIconSize := False;
              MyMarker.IconWidth := 16;
              MyMarker.IconHeight := 28;
              MyMarker.IconURL :=
                'data:image/svg+xml;utf-8, ' + encodeURIComponent(MyMarkerURL);
//              'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + MachineMaxFillColor;
              MyMarker.Draggable := False;
              // Add Overlay
              MyMarker.AddOverlayView(MyJSONObject.GetJSONValue('ID'));
              MyMarker.OverlayView.BorderColor := clGray;
              MyMarker.OverlayView.BackgroundColor := clWhite;
              MyMarker.OverlayView.Font.Color := clGray;
      //      m.OverlayView.Font.Size := 18;
              MyMarker.OverlayView.Visible := MarkerOverlayVisible;
            end;
          finally
            RealTimeMap.EndUpdate;
          end;
        finally
          MyJSON.Free;
        end;
      end;
    finally
      MyWebRequest.Free;
    end;
  end else begin
    RealTimeMap.BeginUpdate;
    RealTimeMap.CloseAllPopups;
    try
      for I := RealtimeMap.Markers.Count-1 downto 0 do begin
        // MachineMax
        if RealTimeMap.Markers[I].DataInteger = -1 then begin
          RealTimeMap.Markers.Delete(I);
        end;
      end;
    finally
      RealTimeMap.EndUpdate;
    end;
  end;
end;

procedure TRealtimeMapForm.AddRulerCoordinate(Latitude, Longitude: Double);
var
  MyCoordinate: TTMSFNCMapsCoordinateItem;
begin
  RealtimeMap.OnMapMouseMove := nil;
  RealtimeMap.BeginUpdate;
  try
    // Route
    RealtimeMap.Polylines[0].Recreate := True;
    MyCoordinate := RealtimeMap.Polylines[0].Coordinates.Add;
    MyCoordinate.Latitude := Latitude;
    MyCoordinate.Longitude := Longitude;
    // Movement
    RealtimeMap.Polylines[1].Coordinates[0].Latitude := MyCoordinate.Latitude;
    RealtimeMap.Polylines[1].Coordinates[0].Longitude := MyCoordinate.Longitude;
    RealtimeMap.Polylines[1].Coordinates[1].Latitude := MyCoordinate.Latitude;
    RealtimeMap.Polylines[1].Coordinates[1].Longitude := MyCoordinate.Longitude;
    // Update Distance
    DistanceTotal := RealtimeMap.Polylines[0].distance;
  finally
    RealtimeMap.EndUpdate;
    RealtimeMap.OnMapMouseMove := RealtimeMapMapMouseMove;
  end;
  SetRulerDistance(MapZoomLevel, DistanceTotal);
end;

procedure TRealtimeMapForm.SetRulerDistance(AZoomLevel, ADistance: Double);
var
  AString: String;
begin
  // Update Variables
  if AZoomLevel < 13 then begin
    AString := FormatFloat('0.00', ADistance * 0.001) + ' km';
  end else begin
    AString := FormatFloat('0.00', ADistance) + ' m';
  end;
  RealtimeMap.ExecuteJavaScript(
      'document.getElementById("customRulerDistance1").innerText = "' + AString + '";'
  );
end;

procedure TRealtimeMapForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BottomPanel := TPanel.Create(Self);
  ToolbarPanel := TPanel.Create(Self);
  SearchEdit := TEdit.Create(Self);
  TypeComboBox := TLookupComboBox.Create(Self);
  SearchButton := TButton.Create(Self);
  TeamFilterButton := TSpeedButton.Create(Self);
  TeamComboBox := TLookupComboBox.Create(Self);
  TypeFilterButton := TSpeedButton.Create(Self);
  SearchTypeEdit := TLookupComboBox.Create(Self);
  ClearSearchEditButton := TSpeedButton.Create(Self);
  MarkerClusterButton := TBitBtn.Create(Self);
  MarkerOverlayButton := TBitBtn.Create(Self);
  AssetHideButton := TBitBtn.Create(Self);
  ZoomToBoundButton := TBitBtn.Create(Self);
  POIGroupButton := TBitBtn.Create(Self);
  GeofenceButton := TBitBtn.Create(Self);
  DistanceRulerButton := TBitBtn.Create(Self);
  AssetStatusComboBox := TLookupComboBox.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  ObjectListPanel := TPanel.Create(Self);
  ListButtonPanel := TTMSFNCPanel.Create(Self);
  ListButtonInactivePanel := TTMSFNCPanel.Create(Self);
  ListButtonInactiveLabel := TLabel.Create(Self);
  ListButtonOfflinePanel := TTMSFNCPanel.Create(Self);
  ListButtonOfflineLabel := TLabel.Create(Self);
  ListButtonOnlinePanel := TTMSFNCPanel.Create(Self);
  ListButtonOnlineLabel := TLabel.Create(Self);
  ListButtonAllPanel := TTMSFNCPanel.Create(Self);
  ListButtonAllLabel := TLabel.Create(Self);
  ListButtonIndicatorPanel := TTMSFNCPanel.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  RealtimeMap := TTMSFNCGoogleMaps.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  ObjectDataset := TClientDataSet.Create(Self);
  ObjectDatasetIMEI := TStringField.Create(Self);
  ObjectDatasetDateTime := TStringField.Create(Self);
  ObjectDatasetLatitude := TStringField.Create(Self);
  ObjectDatasetLongitude := TStringField.Create(Self);
  ObjectDatasetSpeed := TStringField.Create(Self);
  ObjectDatasetStreet := TStringField.Create(Self);
  ObjectDatasetPostalCode := TStringField.Create(Self);
  ObjectDatasetMunicipality := TStringField.Create(Self);
  ObjectDatasetCountry := TStringField.Create(Self);
  ObjectDatasetAssetPKey := TStringField.Create(Self);
  ObjectDatasetSymbol := TStringField.Create(Self);
  ObjectDatasetObjectId := TStringField.Create(Self);
  ObjectDatasetLicensePlate := TStringField.Create(Self);
  ObjectDatasetType := TStringField.Create(Self);
  ObjectDatasetBrand := TStringField.Create(Self);
  ObjectDatasetModel := TStringField.Create(Self);
  ObjectDatasetGroup := TStringField.Create(Self);
  ObjectDatasetURL := TStringField.Create(Self);
  ObjectDatasetEmployeeName := TStringField.Create(Self);
  ObjectDatasetIOStatus := TStringField.Create(Self);
  ObjectDatasetIOIgnition := TStringField.Create(Self);
  ObjectDatasetErrorStatus := TStringField.Create(Self);
  ObjectDatasetAssetStatus := TStringField.Create(Self);
  ObjectDatasetAssetStatusHexColor := TStringField.Create(Self);
  ObjectDatasetBatteryStatus := TStringField.Create(Self);
  ObjectDatasetDeviceTriangle := TStringField.Create(Self);
  ObjectDatasetDeviceCircle := TStringField.Create(Self);
  ObjectListTimer := TTimer.Create(Self);
  TeamFilterDataSet := TClientDataSet.Create(Self);
  TeamFilterDataSetfilter_pk := TStringField.Create(Self);
  TeamFilterDataSetfilter_naam := TStringField.Create(Self);
  TeamFilterDataSetfilter_publiek := TStringField.Create(Self);
  TeamFilterDataSetfilter_standaard := TStringField.Create(Self);
  TypeFilterDataSet := TClientDataSet.Create(Self);
  StringField1 := TStringField.Create(Self);
  StringField2 := TStringField.Create(Self);
  StringField3 := TStringField.Create(Self);
  StringField4 := TStringField.Create(Self);
  ListPopupMenu := TPopupMenu.Create(Self);
  HourMenuItem := TMenuItem.Create(Self);
  TodayMenuItem := TMenuItem.Create(Self);
  YesterdayMenuItem := TMenuItem.Create(Self);
  LastTripMenuItem := TMenuItem.Create(Self);
  CustomDateMenuItem := TMenuItem.Create(Self);
  N1 := TMenuItem.Create(Self);
  AssetEditMenuItem := TMenuItem.Create(Self);
  ChangeDriverMenuItem := TMenuItem.Create(Self);
  ChangeStatusMenuItem := TMenuItem.Create(Self);
  MyWebConnection := TClientConnection.Create(Self);
  ObjectRefreshTimer := TTimer.Create(Self);

  BottomPanel.BeforeLoadDFMValues;
  ToolbarPanel.BeforeLoadDFMValues;
  SearchEdit.BeforeLoadDFMValues;
  TypeComboBox.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  TeamFilterButton.BeforeLoadDFMValues;
  TeamComboBox.BeforeLoadDFMValues;
  TypeFilterButton.BeforeLoadDFMValues;
  SearchTypeEdit.BeforeLoadDFMValues;
  ClearSearchEditButton.BeforeLoadDFMValues;
  MarkerClusterButton.BeforeLoadDFMValues;
  MarkerOverlayButton.BeforeLoadDFMValues;
  AssetHideButton.BeforeLoadDFMValues;
  ZoomToBoundButton.BeforeLoadDFMValues;
  POIGroupButton.BeforeLoadDFMValues;
  GeofenceButton.BeforeLoadDFMValues;
  DistanceRulerButton.BeforeLoadDFMValues;
  AssetStatusComboBox.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  TMSFNCPanel2.BeforeLoadDFMValues;
  ObjectListPanel.BeforeLoadDFMValues;
  ListButtonPanel.BeforeLoadDFMValues;
  ListButtonInactivePanel.BeforeLoadDFMValues;
  ListButtonInactiveLabel.BeforeLoadDFMValues;
  ListButtonOfflinePanel.BeforeLoadDFMValues;
  ListButtonOfflineLabel.BeforeLoadDFMValues;
  ListButtonOnlinePanel.BeforeLoadDFMValues;
  ListButtonOnlineLabel.BeforeLoadDFMValues;
  ListButtonAllPanel.BeforeLoadDFMValues;
  ListButtonAllLabel.BeforeLoadDFMValues;
  ListButtonIndicatorPanel.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  RealtimeMap.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  ObjectDataset.BeforeLoadDFMValues;
  ObjectDatasetIMEI.BeforeLoadDFMValues;
  ObjectDatasetDateTime.BeforeLoadDFMValues;
  ObjectDatasetLatitude.BeforeLoadDFMValues;
  ObjectDatasetLongitude.BeforeLoadDFMValues;
  ObjectDatasetSpeed.BeforeLoadDFMValues;
  ObjectDatasetStreet.BeforeLoadDFMValues;
  ObjectDatasetPostalCode.BeforeLoadDFMValues;
  ObjectDatasetMunicipality.BeforeLoadDFMValues;
  ObjectDatasetCountry.BeforeLoadDFMValues;
  ObjectDatasetAssetPKey.BeforeLoadDFMValues;
  ObjectDatasetSymbol.BeforeLoadDFMValues;
  ObjectDatasetObjectId.BeforeLoadDFMValues;
  ObjectDatasetLicensePlate.BeforeLoadDFMValues;
  ObjectDatasetType.BeforeLoadDFMValues;
  ObjectDatasetBrand.BeforeLoadDFMValues;
  ObjectDatasetModel.BeforeLoadDFMValues;
  ObjectDatasetGroup.BeforeLoadDFMValues;
  ObjectDatasetURL.BeforeLoadDFMValues;
  ObjectDatasetEmployeeName.BeforeLoadDFMValues;
  ObjectDatasetIOStatus.BeforeLoadDFMValues;
  ObjectDatasetIOIgnition.BeforeLoadDFMValues;
  ObjectDatasetErrorStatus.BeforeLoadDFMValues;
  ObjectDatasetAssetStatus.BeforeLoadDFMValues;
  ObjectDatasetAssetStatusHexColor.BeforeLoadDFMValues;
  ObjectDatasetBatteryStatus.BeforeLoadDFMValues;
  ObjectDatasetDeviceTriangle.BeforeLoadDFMValues;
  ObjectDatasetDeviceCircle.BeforeLoadDFMValues;
  ObjectListTimer.BeforeLoadDFMValues;
  TeamFilterDataSet.BeforeLoadDFMValues;
  TeamFilterDataSetfilter_pk.BeforeLoadDFMValues;
  TeamFilterDataSetfilter_naam.BeforeLoadDFMValues;
  TeamFilterDataSetfilter_publiek.BeforeLoadDFMValues;
  TeamFilterDataSetfilter_standaard.BeforeLoadDFMValues;
  TypeFilterDataSet.BeforeLoadDFMValues;
  StringField1.BeforeLoadDFMValues;
  StringField2.BeforeLoadDFMValues;
  StringField3.BeforeLoadDFMValues;
  StringField4.BeforeLoadDFMValues;
  ListPopupMenu.BeforeLoadDFMValues;
  HourMenuItem.BeforeLoadDFMValues;
  TodayMenuItem.BeforeLoadDFMValues;
  YesterdayMenuItem.BeforeLoadDFMValues;
  LastTripMenuItem.BeforeLoadDFMValues;
  CustomDateMenuItem.BeforeLoadDFMValues;
  N1.BeforeLoadDFMValues;
  AssetEditMenuItem.BeforeLoadDFMValues;
  ChangeDriverMenuItem.BeforeLoadDFMValues;
  ChangeStatusMenuItem.BeforeLoadDFMValues;
  MyWebConnection.BeforeLoadDFMValues;
  ObjectRefreshTimer.BeforeLoadDFMValues;
  try
    Name := 'RealtimeMapForm';
    Width := 1610;
    Height := 637;
    Align := alClient;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 580;
    BottomPanel.Width := 1610;
    BottomPanel.Height := 57;
    BottomPanel.ElementClassName := 'MyWebPanel';
    BottomPanel.Align := alBottom;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 6;
    BottomPanel.Visible := False;
    ToolbarPanel.SetParentComponent(Self);
    ToolbarPanel.Name := 'ToolbarPanel';
    ToolbarPanel.Left := 0;
    ToolbarPanel.Top := 0;
    ToolbarPanel.Width := 1610;
    ToolbarPanel.Height := 50;
    ToolbarPanel.Margins.Top := 0;
    ToolbarPanel.Align := alTop;
    ToolbarPanel.BorderColor := clWhite;
    ToolbarPanel.BorderStyle := bsNone;
    ToolbarPanel.ChildOrder := 4;
    ToolbarPanel.Color := clWhite;
    SearchEdit.SetParentComponent(ToolbarPanel);
    SearchEdit.Name := 'SearchEdit';
    SearchEdit.AlignWithMargins := True;
    SearchEdit.Left := 503;
    SearchEdit.Top := 8;
    SearchEdit.Width := 293;
    SearchEdit.Height := 34;
    SearchEdit.Margins.Top := 8;
    SearchEdit.Margins.Bottom := 8;
    SearchEdit.Align := alLeft;
    SearchEdit.ChildOrder := 1;
    SearchEdit.ElementClassName := 'MyWebEdit';
    SearchEdit.Font.Charset := ANSI_CHARSET;
    SearchEdit.Font.Color := clWindowText;
    SearchEdit.Font.Height := -16;
    SearchEdit.Font.Name := 'Segoe UI';
    SearchEdit.Font.Style := [];
    SearchEdit.HeightPercent := 100.000000000000000000;
    SearchEdit.ParentFont := False;
    SearchEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SearchEdit, Self, 'OnChange', 'FilterChange');
    SetEvent(SearchEdit, Self, 'OnKeyDown', 'SearchEditKeyDown');
    TypeComboBox.SetParentComponent(ToolbarPanel);
    TypeComboBox.Name := 'TypeComboBox';
    TypeComboBox.AlignWithMargins := True;
    TypeComboBox.Left := 314;
    TypeComboBox.Top := 8;
    TypeComboBox.Width := 183;
    TypeComboBox.Height := 34;
    TypeComboBox.Margins.Left := 0;
    TypeComboBox.Margins.Top := 8;
    TypeComboBox.Margins.Bottom := 8;
    TypeComboBox.Align := alLeft;
    TypeComboBox.Color := clWhite;
    TypeComboBox.ElementClassName := 'myselect';
    TypeComboBox.Font.Charset := DEFAULT_CHARSET;
    TypeComboBox.Font.Color := clWindowText;
    TypeComboBox.Font.Height := 12;
    TypeComboBox.Font.Name := 'Segoe UI';
    TypeComboBox.Font.Style := [];
    TypeComboBox.HeightPercent := 100.000000000000000000;
    TypeComboBox.ParentFont := False;
    TypeComboBox.WidthPercent := 100.000000000000000000;
    SetEvent(TypeComboBox, Self, 'OnChange', 'FilterChange');
    TypeComboBox.ItemIndex := -1;
    SearchButton.SetParentComponent(ToolbarPanel);
    SearchButton.Name := 'SearchButton';
    SearchButton.AlignWithMargins := True;
    SearchButton.Left := 948;
    SearchButton.Top := 8;
    SearchButton.Width := 120;
    SearchButton.Height := 34;
    SearchButton.Margins.Top := 8;
    SearchButton.Margins.Bottom := 8;
    SearchButton.Align := alLeft;
    SearchButton.Caption := 'Search';
    SearchButton.ChildOrder := 4;
    SearchButton.ElementClassName := 'MyWebButton';
    SearchButton.ElementFont := efCSS;
    SearchButton.HeightPercent := 100.000000000000000000;
    SearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(SearchButton, Self, 'OnClick', 'SearchButtonClick');
    TeamFilterButton.SetParentComponent(ToolbarPanel);
    TeamFilterButton.Name := 'TeamFilterButton';
    TeamFilterButton.AlignWithMargins := True;
    TeamFilterButton.Left := 10;
    TeamFilterButton.Top := 8;
    TeamFilterButton.Width := 35;
    TeamFilterButton.Height := 34;
    TeamFilterButton.Margins.Left := 10;
    TeamFilterButton.Margins.Top := 8;
    TeamFilterButton.Margins.Right := 0;
    TeamFilterButton.Margins.Bottom := 8;
    TeamFilterButton.Align := alLeft;
    TeamFilterButton.Color := clNone;
    TeamFilterButton.ElementClassName := 'MyWebButton';
    TeamFilterButton.Glyph.LoadFromFile('uRealTimeMap.ToolbarPanel.TeamFilterButton.Glyph.png');
    TeamFilterButton.HeightPercent := 100.000000000000000000;
    TeamFilterButton.TabOrder := 3;
    TeamFilterButton.WidthPercent := 100.000000000000000000;
    SetEvent(TeamFilterButton, Self, 'OnClick', 'TeamFilterButtonClick');
    TeamComboBox.SetParentComponent(ToolbarPanel);
    TeamComboBox.Name := 'TeamComboBox';
    TeamComboBox.AlignWithMargins := True;
    TeamComboBox.Left := 45;
    TeamComboBox.Top := 8;
    TeamComboBox.Width := 228;
    TeamComboBox.Height := 34;
    TeamComboBox.Margins.Left := 0;
    TeamComboBox.Margins.Top := 8;
    TeamComboBox.Margins.Bottom := 8;
    TeamComboBox.Align := alLeft;
    TeamComboBox.Color := clWhite;
    TeamComboBox.ElementClassName := 'myselect';
    TeamComboBox.Font.Charset := DEFAULT_CHARSET;
    TeamComboBox.Font.Color := clWindowText;
    TeamComboBox.Font.Height := 12;
    TeamComboBox.Font.Name := 'Segoe UI';
    TeamComboBox.Font.Style := [];
    TeamComboBox.HeightPercent := 100.000000000000000000;
    TeamComboBox.ParentFont := False;
    TeamComboBox.WidthPercent := 100.000000000000000000;
    SetEvent(TeamComboBox, Self, 'OnChange', 'FilterChange');
    TeamComboBox.ItemIndex := -1;
    TypeFilterButton.SetParentComponent(ToolbarPanel);
    TypeFilterButton.Name := 'TypeFilterButton';
    TypeFilterButton.AlignWithMargins := True;
    TypeFilterButton.Left := 279;
    TypeFilterButton.Top := 8;
    TypeFilterButton.Width := 35;
    TypeFilterButton.Height := 34;
    TypeFilterButton.Margins.Top := 8;
    TypeFilterButton.Margins.Right := 0;
    TypeFilterButton.Margins.Bottom := 8;
    TypeFilterButton.Align := alLeft;
    TypeFilterButton.Color := clNone;
    TypeFilterButton.ElementClassName := 'MyWebButton';
    TypeFilterButton.Glyph.LoadFromFile('uRealTimeMap.ToolbarPanel.TypeFilterButton.Glyph.png');
    TypeFilterButton.HeightPercent := 100.000000000000000000;
    TypeFilterButton.TabOrder := 5;
    TypeFilterButton.WidthPercent := 100.000000000000000000;
    SetEvent(TypeFilterButton, Self, 'OnClick', 'TypeFilterButtonClick');
    SearchTypeEdit.SetParentComponent(ToolbarPanel);
    SearchTypeEdit.Name := 'SearchTypeEdit';
    SearchTypeEdit.AlignWithMargins := True;
    SearchTypeEdit.Left := 802;
    SearchTypeEdit.Top := 8;
    SearchTypeEdit.Width := 140;
    SearchTypeEdit.Height := 34;
    SearchTypeEdit.Margins.Top := 8;
    SearchTypeEdit.Margins.Bottom := 8;
    SearchTypeEdit.Align := alLeft;
    SearchTypeEdit.Color := clWhite;
    SearchTypeEdit.ElementClassName := 'myselect';
    SearchTypeEdit.Font.Charset := DEFAULT_CHARSET;
    SearchTypeEdit.Font.Color := clWindowText;
    SearchTypeEdit.Font.Height := 12;
    SearchTypeEdit.Font.Name := 'Segoe UI';
    SearchTypeEdit.Font.Style := [];
    SearchTypeEdit.HeightPercent := 100.000000000000000000;
    SearchTypeEdit.ParentFont := False;
    SearchTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SearchTypeEdit, Self, 'OnChange', 'SearchTypeEditChange');
    SetEvent(SearchTypeEdit, Self, 'OnClick', 'FilterChange');
    SearchTypeEdit.ItemIndex := -1;
    SearchTypeEdit.LookupValues.Clear;
    with SearchTypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'REG. PLATE';
    end;
    with SearchTypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'DEVICE No';
    end;
    with SearchTypeEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'ASSET ID';
    end;
    with SearchTypeEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'ADDRESS';
    end;
    with SearchTypeEdit.LookupValues.Add do
    begin
      Value := '5';
      DisplayText := 'DRIVER';
    end;
    with SearchTypeEdit.LookupValues.Add do
    begin
      Value := '6';
      DisplayText := 'STATUS';
    end;
    ClearSearchEditButton.SetParentComponent(ToolbarPanel);
    ClearSearchEditButton.Name := 'ClearSearchEditButton';
    ClearSearchEditButton.AlignWithMargins := True;
    ClearSearchEditButton.Left := 763;
    ClearSearchEditButton.Top := 10;
    ClearSearchEditButton.Width := 30;
    ClearSearchEditButton.Height := 30;
    ClearSearchEditButton.Margins.Left := 10;
    ClearSearchEditButton.Margins.Top := 8;
    ClearSearchEditButton.Margins.Right := 0;
    ClearSearchEditButton.Margins.Bottom := 8;
    ClearSearchEditButton.Color := clNone;
    ClearSearchEditButton.ElementClassName := 'MyClearButton';
    ClearSearchEditButton.Glyph.LoadFromFile('uRealTimeMap.ToolbarPanel.ClearSearchEditButton.Glyph.png');
    ClearSearchEditButton.HeightPercent := 100.000000000000000000;
    ClearSearchEditButton.TabOrder := 7;
    ClearSearchEditButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearSearchEditButton, Self, 'OnClick', 'ClearSearchEditButtonClick');
    SetEvent(ClearSearchEditButton, Self, 'OnDblClick', 'ClearSearchEditButtonDblClick');
    MarkerClusterButton.SetParentComponent(ToolbarPanel);
    MarkerClusterButton.Name := 'MarkerClusterButton';
    MarkerClusterButton.AlignWithMargins := True;
    MarkerClusterButton.Left := 1569;
    MarkerClusterButton.Top := 8;
    MarkerClusterButton.Width := 36;
    MarkerClusterButton.Height := 34;
    MarkerClusterButton.Hint := 'Enable / Disable  Clustering';
    MarkerClusterButton.Margins.Left := 5;
    MarkerClusterButton.Margins.Top := 8;
    MarkerClusterButton.Margins.Right := 5;
    MarkerClusterButton.Margins.Bottom := 8;
    MarkerClusterButton.Align := alRight;
    MarkerClusterButton.Color := clNone;
    MarkerClusterButton.ElementClassName := 'MyWebButton';
    MarkerClusterButton.HeightPercent := 100.000000000000000000;
    MarkerClusterButton.MaterialGlyph := 'group_work';
    MarkerClusterButton.MaterialGlyphColor := clWhite;
    MarkerClusterButton.MaterialGlyphSize := 24;
    MarkerClusterButton.ShowHint := True;
    MarkerClusterButton.WidthPercent := 100.000000000000000000;
    SetEvent(MarkerClusterButton, Self, 'OnClick', 'MarkerClusterButtonClick');
    MarkerOverlayButton.SetParentComponent(ToolbarPanel);
    MarkerOverlayButton.Name := 'MarkerOverlayButton';
    MarkerOverlayButton.AlignWithMargins := True;
    MarkerOverlayButton.Left := 1475;
    MarkerOverlayButton.Top := 8;
    MarkerOverlayButton.Width := 38;
    MarkerOverlayButton.Height := 34;
    MarkerOverlayButton.Hint := 'Show marker overlays';
    MarkerOverlayButton.Margins.Left := 5;
    MarkerOverlayButton.Margins.Top := 8;
    MarkerOverlayButton.Margins.Right := 5;
    MarkerOverlayButton.Margins.Bottom := 8;
    MarkerOverlayButton.Align := alRight;
    MarkerOverlayButton.Color := clNone;
    MarkerOverlayButton.ElementClassName := 'MyWebButton';
    MarkerOverlayButton.HeightPercent := 100.000000000000000000;
    MarkerOverlayButton.MaterialGlyph := 'pin_drop';
    MarkerOverlayButton.MaterialGlyphColor := clWhite;
    MarkerOverlayButton.MaterialGlyphSize := 24;
    MarkerOverlayButton.ShowHint := True;
    MarkerOverlayButton.WidthPercent := 100.000000000000000000;
    SetEvent(MarkerOverlayButton, Self, 'OnClick', 'MarkerOverlayButtonClick');
    AssetHideButton.SetParentComponent(ToolbarPanel);
    AssetHideButton.Name := 'AssetHideButton';
    AssetHideButton.AlignWithMargins := True;
    AssetHideButton.Left := 1429;
    AssetHideButton.Top := 8;
    AssetHideButton.Width := 36;
    AssetHideButton.Height := 34;
    AssetHideButton.Hint := 'Show or hide assets on map';
    AssetHideButton.Margins.Left := 5;
    AssetHideButton.Margins.Top := 8;
    AssetHideButton.Margins.Right := 5;
    AssetHideButton.Margins.Bottom := 8;
    AssetHideButton.Align := alRight;
    AssetHideButton.Color := clNone;
    AssetHideButton.ElementClassName := 'MyWebButton';
    AssetHideButton.HeightPercent := 100.000000000000000000;
    AssetHideButton.MaterialGlyph := 'location_on';
    AssetHideButton.MaterialGlyphColor := clWhite;
    AssetHideButton.MaterialGlyphSize := 24;
    AssetHideButton.ShowHint := True;
    AssetHideButton.WidthPercent := 100.000000000000000000;
    SetEvent(AssetHideButton, Self, 'OnClick', 'AssetHideButtonClick');
    ZoomToBoundButton.SetParentComponent(ToolbarPanel);
    ZoomToBoundButton.Name := 'ZoomToBoundButton';
    ZoomToBoundButton.AlignWithMargins := True;
    ZoomToBoundButton.Left := 1523;
    ZoomToBoundButton.Top := 8;
    ZoomToBoundButton.Width := 36;
    ZoomToBoundButton.Height := 34;
    ZoomToBoundButton.Hint := 'Fit assets to map';
    ZoomToBoundButton.Margins.Left := 5;
    ZoomToBoundButton.Margins.Top := 8;
    ZoomToBoundButton.Margins.Right := 5;
    ZoomToBoundButton.Margins.Bottom := 8;
    ZoomToBoundButton.Align := alRight;
    ZoomToBoundButton.Color := clNone;
    ZoomToBoundButton.ElementClassName := 'MyWebButton';
    ZoomToBoundButton.HeightPercent := 100.000000000000000000;
    ZoomToBoundButton.MaterialGlyph := 'zoom_out_map';
    ZoomToBoundButton.MaterialGlyphColor := clWhite;
    ZoomToBoundButton.MaterialGlyphSize := 24;
    ZoomToBoundButton.ShowHint := True;
    ZoomToBoundButton.WidthPercent := 100.000000000000000000;
    SetEvent(ZoomToBoundButton, Self, 'OnClick', 'ZoomToBoundButtonClick');
    POIGroupButton.SetParentComponent(ToolbarPanel);
    POIGroupButton.Name := 'POIGroupButton';
    POIGroupButton.AlignWithMargins := True;
    POIGroupButton.Left := 1385;
    POIGroupButton.Top := 8;
    POIGroupButton.Width := 34;
    POIGroupButton.Height := 34;
    POIGroupButton.Hint := 'Show or hide POI';
    POIGroupButton.Margins.Left := 5;
    POIGroupButton.Margins.Top := 8;
    POIGroupButton.Margins.Right := 5;
    POIGroupButton.Margins.Bottom := 8;
    POIGroupButton.Align := alRight;
    POIGroupButton.Color := clNone;
    POIGroupButton.ElementClassName := 'MyWebButton';
    POIGroupButton.HeightPercent := 100.000000000000000000;
    POIGroupButton.MaterialGlyph := 'castle';
    POIGroupButton.MaterialGlyphColor := clWhite;
    POIGroupButton.MaterialGlyphSize := 24;
    POIGroupButton.ShowHint := True;
    POIGroupButton.WidthPercent := 100.000000000000000000;
    SetEvent(POIGroupButton, Self, 'OnClick', 'POIGroupButtonClick');
    GeofenceButton.SetParentComponent(ToolbarPanel);
    GeofenceButton.Name := 'GeofenceButton';
    GeofenceButton.AlignWithMargins := True;
    GeofenceButton.Left := 1339;
    GeofenceButton.Top := 8;
    GeofenceButton.Width := 36;
    GeofenceButton.Height := 34;
    GeofenceButton.Hint := 'Show or hide geofence';
    GeofenceButton.Margins.Left := 5;
    GeofenceButton.Margins.Top := 8;
    GeofenceButton.Margins.Right := 5;
    GeofenceButton.Margins.Bottom := 8;
    GeofenceButton.Align := alRight;
    GeofenceButton.Color := clNone;
    GeofenceButton.ElementClassName := 'MyWebButton';
    GeofenceButton.HeightPercent := 100.000000000000000000;
    GeofenceButton.MaterialGlyph := 'fence';
    GeofenceButton.MaterialGlyphColor := clWhite;
    GeofenceButton.MaterialGlyphSize := 24;
    GeofenceButton.ShowHint := True;
    GeofenceButton.WidthPercent := 100.000000000000000000;
    SetEvent(GeofenceButton, Self, 'OnClick', 'GeofenceButtonClick');
    DistanceRulerButton.SetParentComponent(ToolbarPanel);
    DistanceRulerButton.Name := 'DistanceRulerButton';
    DistanceRulerButton.AlignWithMargins := True;
    DistanceRulerButton.Left := 1293;
    DistanceRulerButton.Top := 8;
    DistanceRulerButton.Width := 36;
    DistanceRulerButton.Height := 34;
    DistanceRulerButton.Hint := 'Show distance ruler';
    DistanceRulerButton.Margins.Left := 5;
    DistanceRulerButton.Margins.Top := 8;
    DistanceRulerButton.Margins.Right := 5;
    DistanceRulerButton.Margins.Bottom := 8;
    DistanceRulerButton.Align := alRight;
    DistanceRulerButton.Color := clNone;
    DistanceRulerButton.ElementClassName := 'MyWebButton';
    DistanceRulerButton.HeightPercent := 100.000000000000000000;
    DistanceRulerButton.MaterialGlyph := 'straighten';
    DistanceRulerButton.MaterialGlyphColor := clWhite;
    DistanceRulerButton.MaterialGlyphSize := 24;
    DistanceRulerButton.ShowHint := True;
    DistanceRulerButton.WidthPercent := 100.000000000000000000;
    SetEvent(DistanceRulerButton, Self, 'OnClick', 'DistanceRulerButtonClick');
    AssetStatusComboBox.SetParentComponent(ToolbarPanel);
    AssetStatusComboBox.Name := 'AssetStatusComboBox';
    AssetStatusComboBox.AlignWithMargins := True;
    AssetStatusComboBox.Left := 503;
    AssetStatusComboBox.Top := 8;
    AssetStatusComboBox.Width := 293;
    AssetStatusComboBox.Height := 34;
    AssetStatusComboBox.Margins.Left := 0;
    AssetStatusComboBox.Margins.Top := 8;
    AssetStatusComboBox.Margins.Bottom := 8;
    AssetStatusComboBox.Color := clWhite;
    AssetStatusComboBox.ElementClassName := 'myselect';
    AssetStatusComboBox.Font.Charset := DEFAULT_CHARSET;
    AssetStatusComboBox.Font.Color := clWindowText;
    AssetStatusComboBox.Font.Height := 12;
    AssetStatusComboBox.Font.Name := 'Segoe UI';
    AssetStatusComboBox.Font.Style := [];
    AssetStatusComboBox.HeightPercent := 100.000000000000000000;
    AssetStatusComboBox.ParentFont := False;
    AssetStatusComboBox.Visible := False;
    AssetStatusComboBox.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusComboBox, Self, 'OnChange', 'FilterChange');
    AssetStatusComboBox.ItemIndex := -1;
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Tag := 1;
    TMSFNCPanel1.Left := 0;
    TMSFNCPanel1.Top := 50;
    TMSFNCPanel1.Width := 401;
    TMSFNCPanel1.Height := 530;
    TMSFNCPanel1.Align := alLeft;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 2;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Stroke.Kind := gskNone;
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Visible := False;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel2.SetParentComponent(TMSFNCPanel1);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Left := 0;
    TMSFNCPanel2.Top := 529;
    TMSFNCPanel2.Width := 401;
    TMSFNCPanel2.Height := 1;
    TMSFNCPanel2.Align := alBottom;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.Color := clHighlight;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 0;
    TMSFNCPanel2.Fill.Color := clHighlight;
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.Stroke.Kind := gskNone;
    TMSFNCPanel2.Header.Text := 'Header';
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Visible := False;
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    ObjectListPanel.SetParentComponent(TMSFNCPanel1);
    ObjectListPanel.Name := 'ObjectListPanel';
    ObjectListPanel.AlignWithMargins := True;
    ObjectListPanel.Left := 10;
    ObjectListPanel.Top := 41;
    ObjectListPanel.Width := 388;
    ObjectListPanel.Height := 485;
    ObjectListPanel.Margins.Left := 10;
    ObjectListPanel.Align := alClient;
    ObjectListPanel.BorderColor := -1;
    ObjectListPanel.BorderStyle := bsNone;
    ObjectListPanel.ChildOrder := 6;
    ObjectListPanel.Color := clWhite;
    ListButtonPanel.SetParentComponent(TMSFNCPanel1);
    ListButtonPanel.Name := 'ListButtonPanel';
    ListButtonPanel.Tag := 1;
    ListButtonPanel.AlignWithMargins := True;
    ListButtonPanel.Left := 10;
    ListButtonPanel.Top := 3;
    ListButtonPanel.Width := 381;
    ListButtonPanel.Height := 32;
    ListButtonPanel.Margins.Left := 10;
    ListButtonPanel.Margins.Right := 10;
    ListButtonPanel.Align := alTop;
    ListButtonPanel.Ctl3D := True;
    ListButtonPanel.ParentCtl3D := False;
    ListButtonPanel.ParentDoubleBuffered := False;
    ListButtonPanel.DoubleBuffered := True;
    ListButtonPanel.TabOrder := 2;
    ListButtonPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonPanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonPanel.SectionsAppearance.Font.Height := -11;
    ListButtonPanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonPanel.SectionsAppearance.Font.Style := [];
    ListButtonPanel.Stroke.Kind := gskNone;
    ListButtonPanel.Header.Text := 'Header';
    ListButtonPanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonPanel.Header.Font.Color := clWindowText;
    ListButtonPanel.Header.Font.Height := -11;
    ListButtonPanel.Header.Font.Name := 'Tahoma';
    ListButtonPanel.Header.Font.Style := [];
    ListButtonPanel.Header.Visible := False;
    ListButtonPanel.Footer.Text := 'Footer';
    ListButtonPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonPanel.Footer.Font.Color := clWindowText;
    ListButtonPanel.Footer.Font.Height := -11;
    ListButtonPanel.Footer.Font.Name := 'Tahoma';
    ListButtonPanel.Footer.Font.Style := [];
    ListButtonInactivePanel.SetParentComponent(ListButtonPanel);
    ListButtonInactivePanel.Name := 'ListButtonInactivePanel';
    ListButtonInactivePanel.Tag := 4;
    ListButtonInactivePanel.Left := 285;
    ListButtonInactivePanel.Top := 0;
    ListButtonInactivePanel.Width := 95;
    ListButtonInactivePanel.Height := 32;
    ListButtonInactivePanel.Align := alLeft;
    ListButtonInactivePanel.BevelInner := bvNone;
    ListButtonInactivePanel.BevelOuter := bvNone;
    ListButtonInactivePanel.Ctl3D := True;
    ListButtonInactivePanel.ParentCtl3D := False;
    ListButtonInactivePanel.ParentDoubleBuffered := False;
    ListButtonInactivePanel.DoubleBuffered := True;
    ListButtonInactivePanel.TabOrder := 0;
    SetEvent(ListButtonInactivePanel, Self, 'OnDblClick', 'ListButtonPanelClick');
    ListButtonInactivePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonInactivePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonInactivePanel.SectionsAppearance.Font.Height := -11;
    ListButtonInactivePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonInactivePanel.SectionsAppearance.Font.Style := [];
    ListButtonInactivePanel.Stroke.Kind := gskNone;
    ListButtonInactivePanel.Header.Text := 'Header';
    ListButtonInactivePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonInactivePanel.Header.Font.Color := clWindowText;
    ListButtonInactivePanel.Header.Font.Height := -11;
    ListButtonInactivePanel.Header.Font.Name := 'Tahoma';
    ListButtonInactivePanel.Header.Font.Style := [];
    ListButtonInactivePanel.Header.Visible := False;
    ListButtonInactivePanel.Footer.Text := 'Footer';
    ListButtonInactivePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonInactivePanel.Footer.Font.Color := clWindowText;
    ListButtonInactivePanel.Footer.Font.Height := -11;
    ListButtonInactivePanel.Footer.Font.Name := 'Tahoma';
    ListButtonInactivePanel.Footer.Font.Style := [];
    ListButtonInactiveLabel.SetParentComponent(ListButtonInactivePanel);
    ListButtonInactiveLabel.Name := 'ListButtonInactiveLabel';
    ListButtonInactiveLabel.Tag := 4;
    ListButtonInactiveLabel.AlignWithMargins := True;
    ListButtonInactiveLabel.Left := 3;
    ListButtonInactiveLabel.Top := 10;
    ListButtonInactiveLabel.Width := 89;
    ListButtonInactiveLabel.Height := 14;
    ListButtonInactiveLabel.Margins.Top := 10;
    ListButtonInactiveLabel.Align := alTop;
    ListButtonInactiveLabel.Alignment := taCenter;
    ListButtonInactiveLabel.Caption := 'Inactive (0)';
    ListButtonInactiveLabel.Font.Charset := DEFAULT_CHARSET;
    ListButtonInactiveLabel.Font.Color := clHighlight;
    ListButtonInactiveLabel.Font.Height := -12;
    ListButtonInactiveLabel.Font.Name := 'Verdana';
    ListButtonInactiveLabel.Font.Style := [];
    ListButtonInactiveLabel.HeightPercent := 100.000000000000000000;
    ListButtonInactiveLabel.ParentFont := False;
    ListButtonInactiveLabel.Transparent := False;
    ListButtonInactiveLabel.WidthPercent := 100.000000000000000000;
    SetEvent(ListButtonInactiveLabel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonOfflinePanel.SetParentComponent(ListButtonPanel);
    ListButtonOfflinePanel.Name := 'ListButtonOfflinePanel';
    ListButtonOfflinePanel.Tag := 3;
    ListButtonOfflinePanel.Left := 190;
    ListButtonOfflinePanel.Top := 0;
    ListButtonOfflinePanel.Width := 95;
    ListButtonOfflinePanel.Height := 32;
    ListButtonOfflinePanel.Align := alLeft;
    ListButtonOfflinePanel.BevelInner := bvNone;
    ListButtonOfflinePanel.BevelOuter := bvNone;
    ListButtonOfflinePanel.Ctl3D := True;
    ListButtonOfflinePanel.ParentCtl3D := False;
    ListButtonOfflinePanel.ParentDoubleBuffered := False;
    ListButtonOfflinePanel.DoubleBuffered := True;
    ListButtonOfflinePanel.TabOrder := 1;
    SetEvent(ListButtonOfflinePanel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonOfflinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOfflinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOfflinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOfflinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOfflinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOfflinePanel.Stroke.Kind := gskNone;
    ListButtonOfflinePanel.Header.Text := 'Header';
    ListButtonOfflinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOfflinePanel.Header.Font.Color := clWindowText;
    ListButtonOfflinePanel.Header.Font.Height := -11;
    ListButtonOfflinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOfflinePanel.Header.Font.Style := [];
    ListButtonOfflinePanel.Header.Visible := False;
    ListButtonOfflinePanel.Footer.Text := 'Footer';
    ListButtonOfflinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOfflinePanel.Footer.Font.Color := clWindowText;
    ListButtonOfflinePanel.Footer.Font.Height := -11;
    ListButtonOfflinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOfflinePanel.Footer.Font.Style := [];
    ListButtonOfflineLabel.SetParentComponent(ListButtonOfflinePanel);
    ListButtonOfflineLabel.Name := 'ListButtonOfflineLabel';
    ListButtonOfflineLabel.Tag := 3;
    ListButtonOfflineLabel.AlignWithMargins := True;
    ListButtonOfflineLabel.Left := 3;
    ListButtonOfflineLabel.Top := 10;
    ListButtonOfflineLabel.Width := 89;
    ListButtonOfflineLabel.Height := 14;
    ListButtonOfflineLabel.Margins.Top := 10;
    ListButtonOfflineLabel.Align := alTop;
    ListButtonOfflineLabel.Alignment := taCenter;
    ListButtonOfflineLabel.Caption := 'Offline (0)';
    ListButtonOfflineLabel.Font.Charset := DEFAULT_CHARSET;
    ListButtonOfflineLabel.Font.Color := clHighlight;
    ListButtonOfflineLabel.Font.Height := -12;
    ListButtonOfflineLabel.Font.Name := 'Verdana';
    ListButtonOfflineLabel.Font.Style := [];
    ListButtonOfflineLabel.HeightPercent := 100.000000000000000000;
    ListButtonOfflineLabel.ParentFont := False;
    ListButtonOfflineLabel.WidthPercent := 100.000000000000000000;
    SetEvent(ListButtonOfflineLabel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonOnlinePanel.SetParentComponent(ListButtonPanel);
    ListButtonOnlinePanel.Name := 'ListButtonOnlinePanel';
    ListButtonOnlinePanel.Tag := 2;
    ListButtonOnlinePanel.Left := 95;
    ListButtonOnlinePanel.Top := 0;
    ListButtonOnlinePanel.Width := 95;
    ListButtonOnlinePanel.Height := 32;
    ListButtonOnlinePanel.Align := alLeft;
    ListButtonOnlinePanel.BevelInner := bvNone;
    ListButtonOnlinePanel.BevelOuter := bvNone;
    ListButtonOnlinePanel.Ctl3D := True;
    ListButtonOnlinePanel.ParentCtl3D := False;
    ListButtonOnlinePanel.ParentDoubleBuffered := False;
    ListButtonOnlinePanel.DoubleBuffered := True;
    ListButtonOnlinePanel.TabOrder := 2;
    SetEvent(ListButtonOnlinePanel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonOnlinePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonOnlinePanel.SectionsAppearance.Font.Height := -11;
    ListButtonOnlinePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.SectionsAppearance.Font.Style := [];
    ListButtonOnlinePanel.Stroke.Kind := gskNone;
    ListButtonOnlinePanel.Header.Text := 'Header';
    ListButtonOnlinePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Header.Font.Color := clWindowText;
    ListButtonOnlinePanel.Header.Font.Height := -11;
    ListButtonOnlinePanel.Header.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Header.Font.Style := [];
    ListButtonOnlinePanel.Header.Visible := False;
    ListButtonOnlinePanel.Footer.Text := 'Footer';
    ListButtonOnlinePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlinePanel.Footer.Font.Color := clWindowText;
    ListButtonOnlinePanel.Footer.Font.Height := -11;
    ListButtonOnlinePanel.Footer.Font.Name := 'Tahoma';
    ListButtonOnlinePanel.Footer.Font.Style := [];
    ListButtonOnlineLabel.SetParentComponent(ListButtonOnlinePanel);
    ListButtonOnlineLabel.Name := 'ListButtonOnlineLabel';
    ListButtonOnlineLabel.Tag := 2;
    ListButtonOnlineLabel.AlignWithMargins := True;
    ListButtonOnlineLabel.Left := 3;
    ListButtonOnlineLabel.Top := 10;
    ListButtonOnlineLabel.Width := 89;
    ListButtonOnlineLabel.Height := 14;
    ListButtonOnlineLabel.Margins.Top := 10;
    ListButtonOnlineLabel.Align := alTop;
    ListButtonOnlineLabel.Alignment := taCenter;
    ListButtonOnlineLabel.Caption := 'Online (0)';
    ListButtonOnlineLabel.Font.Charset := DEFAULT_CHARSET;
    ListButtonOnlineLabel.Font.Color := clHighlight;
    ListButtonOnlineLabel.Font.Height := -12;
    ListButtonOnlineLabel.Font.Name := 'Verdana';
    ListButtonOnlineLabel.Font.Style := [];
    ListButtonOnlineLabel.HeightPercent := 100.000000000000000000;
    ListButtonOnlineLabel.ParentFont := False;
    ListButtonOnlineLabel.WidthPercent := 100.000000000000000000;
    SetEvent(ListButtonOnlineLabel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonAllPanel.SetParentComponent(ListButtonPanel);
    ListButtonAllPanel.Name := 'ListButtonAllPanel';
    ListButtonAllPanel.Tag := 1;
    ListButtonAllPanel.Left := 0;
    ListButtonAllPanel.Top := 0;
    ListButtonAllPanel.Width := 95;
    ListButtonAllPanel.Height := 32;
    ListButtonAllPanel.Align := alLeft;
    ListButtonAllPanel.BevelInner := bvNone;
    ListButtonAllPanel.BevelOuter := bvNone;
    ListButtonAllPanel.Ctl3D := True;
    ListButtonAllPanel.ParentCtl3D := False;
    ListButtonAllPanel.ParentDoubleBuffered := False;
    ListButtonAllPanel.DoubleBuffered := True;
    ListButtonAllPanel.TabOrder := 3;
    SetEvent(ListButtonAllPanel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonAllPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonAllPanel.SectionsAppearance.Font.Height := -11;
    ListButtonAllPanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonAllPanel.SectionsAppearance.Font.Style := [];
    ListButtonAllPanel.Stroke.Kind := gskNone;
    ListButtonAllPanel.Header.Text := 'Header';
    ListButtonAllPanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.Header.Font.Color := clWindowText;
    ListButtonAllPanel.Header.Font.Height := -11;
    ListButtonAllPanel.Header.Font.Name := 'Tahoma';
    ListButtonAllPanel.Header.Font.Style := [];
    ListButtonAllPanel.Header.Visible := False;
    ListButtonAllPanel.Footer.Text := 'Footer';
    ListButtonAllPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllPanel.Footer.Font.Color := clWindowText;
    ListButtonAllPanel.Footer.Font.Height := -11;
    ListButtonAllPanel.Footer.Font.Name := 'Tahoma';
    ListButtonAllPanel.Footer.Font.Style := [];
    ListButtonAllLabel.SetParentComponent(ListButtonAllPanel);
    ListButtonAllLabel.Name := 'ListButtonAllLabel';
    ListButtonAllLabel.Tag := 1;
    ListButtonAllLabel.AlignWithMargins := True;
    ListButtonAllLabel.Left := 3;
    ListButtonAllLabel.Top := 10;
    ListButtonAllLabel.Width := 89;
    ListButtonAllLabel.Height := 14;
    ListButtonAllLabel.Margins.Top := 10;
    ListButtonAllLabel.Align := alTop;
    ListButtonAllLabel.Alignment := taCenter;
    ListButtonAllLabel.Caption := 'All (0)';
    ListButtonAllLabel.Font.Charset := DEFAULT_CHARSET;
    ListButtonAllLabel.Font.Color := clHighlight;
    ListButtonAllLabel.Font.Height := -12;
    ListButtonAllLabel.Font.Name := 'Verdana';
    ListButtonAllLabel.Font.Style := [];
    ListButtonAllLabel.HeightPercent := 100.000000000000000000;
    ListButtonAllLabel.ParentFont := False;
    ListButtonAllLabel.WidthPercent := 100.000000000000000000;
    SetEvent(ListButtonAllLabel, Self, 'OnClick', 'ListButtonPanelClick');
    ListButtonIndicatorPanel.SetParentComponent(ListButtonAllPanel);
    ListButtonIndicatorPanel.Name := 'ListButtonIndicatorPanel';
    ListButtonIndicatorPanel.Left := 0;
    ListButtonIndicatorPanel.Top := 31;
    ListButtonIndicatorPanel.Width := 95;
    ListButtonIndicatorPanel.Height := 1;
    ListButtonIndicatorPanel.Align := alBottom;
    ListButtonIndicatorPanel.ParentDoubleBuffered := False;
    ListButtonIndicatorPanel.Color := clHighlight;
    ListButtonIndicatorPanel.DoubleBuffered := True;
    ListButtonIndicatorPanel.TabOrder := 0;
    ListButtonIndicatorPanel.Fill.Color := clHighlight;
    ListButtonIndicatorPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ListButtonIndicatorPanel.SectionsAppearance.Font.Color := clWindowText;
    ListButtonIndicatorPanel.SectionsAppearance.Font.Height := -11;
    ListButtonIndicatorPanel.SectionsAppearance.Font.Name := 'Tahoma';
    ListButtonIndicatorPanel.SectionsAppearance.Font.Style := [];
    ListButtonIndicatorPanel.Stroke.Kind := gskNone;
    ListButtonIndicatorPanel.Header.Text := 'Header';
    ListButtonIndicatorPanel.Header.Font.Charset := DEFAULT_CHARSET;
    ListButtonIndicatorPanel.Header.Font.Color := clWindowText;
    ListButtonIndicatorPanel.Header.Font.Height := -11;
    ListButtonIndicatorPanel.Header.Font.Name := 'Tahoma';
    ListButtonIndicatorPanel.Header.Font.Style := [];
    ListButtonIndicatorPanel.Header.Visible := False;
    ListButtonIndicatorPanel.Footer.Text := 'Footer';
    ListButtonIndicatorPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ListButtonIndicatorPanel.Footer.Font.Color := clWindowText;
    ListButtonIndicatorPanel.Footer.Font.Height := -11;
    ListButtonIndicatorPanel.Footer.Font.Name := 'Tahoma';
    ListButtonIndicatorPanel.Footer.Font.Style := [];
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 625;
    WebMessageDlg1.Top := 349;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    RealtimeMap.SetParentComponent(Self);
    RealtimeMap.Name := 'RealtimeMap';
    RealtimeMap.AlignWithMargins := True;
    RealtimeMap.Left := 404;
    RealtimeMap.Top := 53;
    RealtimeMap.Width := 1203;
    RealtimeMap.Height := 524;
    RealtimeMap.Align := alClient;
    RealtimeMap.ParentDoubleBuffered := False;
    RealtimeMap.DoubleBuffered := True;
    RealtimeMap.TabOrder := 5;
    SetEvent(RealtimeMap, Self, 'OnZoomChanged', 'RealtimeMapZoomChanged');
    SetEvent(RealtimeMap, Self, 'OnMapClick', 'RealtimeMapMapClick');
    SetEvent(RealtimeMap, Self, 'OnMapRightClick', 'RealtimeMapMapClick');
    SetEvent(RealtimeMap, Self, 'OnMapMouseMove', 'RealtimeMapMapMouseMove');
    SetEvent(RealtimeMap, Self, 'OnMarkerClick', 'RealtimeMapMarkerClick');
    SetEvent(RealtimeMap, Self, 'OnPolyElementClick', 'RealtimeMapPolyElementClick');
    SetEvent(RealtimeMap, Self, 'OnMapInitialized', 'RealtimeMapMapInitialized');
    SetEvent(RealtimeMap, Self, 'OnGetZoomLevel', 'RealtimeMapGetZoomLevel');
    RealtimeMap.Polylines.Clear;
    with RealtimeMap.Polylines.Add do
    begin
    end;
    with RealtimeMap.Polylines.Add do
    begin
      StrokeColor := clRed;
      Coordinates.Clear;
      with Coordinates.Add do
      begin
        Longitude := -74.044501999999990000;
        Latitude := 40.689247000000000000;
      end;
      with Coordinates.Add do
      begin
        Longitude := -74.044501999999990000;
        Latitude := 40.689247000000000000;
      end;
      Symbols.Clear;
      with Symbols.Add do
      begin
        FillColor := 11788021;
      end;
    end;
    RealtimeMap.Options.DefaultLatitude := 50.854907162897120000;
    RealtimeMap.Options.DefaultLongitude := 4.346292332766704000;
    RealtimeMap.Options.DefaultZoomLevel := 7.000000000000000000;
    RealtimeMap.Options.ShowScaleControl := True;
    RealtimeMap.Options.BackgroundColor := clBlack;
    RealtimeMap.Options.DisablePOI := False;
    RealtimeMap.Options.Version := 'weekly';
    RealtimeMap.ElementContainers.Clear;
    with RealtimeMap.ElementContainers.Add do
    begin
      HTML.BeginUpdate;
      try
        HTML.Clear;
        HTML.Add('<div>');
        HTML.Add('Distance');
        HTML.Add('</div>');
        HTML.Add('<div>');
        HTML.Add('&nbsp;<span id="customRulerDistance1">0.00 km</span>&nbsp;');
        HTML.Add('<button id="customRulerDistanceStopButton1">Stop</button>');
        HTML.Add('<button id="customRulerDistanceClearButton1">Clear</button>');
        HTML.Add('</div>');
      finally
        HTML.EndUpdate;
      end;
      Visible := False;
      Actions.Clear;
      with Actions.Add do
      begin
        HTMLElementID := 'customRulerDistanceClearButton1';
        Name := 'Action0';
        SetEvent(Self, 'OnExecute', 'RealtimeMapElementContainers0Actions0Execute');
      end;
      with Actions.Add do
      begin
        HTMLElementID := 'customRulerDistanceStopButton1';
        Name := 'Action1';
        SetEvent(Self, 'OnExecute', 'RealtimeMapElementContainers0Actions1Execute');
      end;
      Coordinate.Longitude := -74.044501999999990000;
      Coordinate.Latitude := 40.689247000000000000;
      Position := poTopRight;
      HTMLElementID := 'ElementContainer0';
      Margins.Left := 10.000000000000000000;
      Margins.Top := 100.000000000000000000;
      Margins.Right := 10.000000000000000000;
      Margins.Bottom := 10.000000000000000000;
    end;
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 504;
    WebWaitMessage.Top := 288;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uRealTimeMap.WebWaitMessage.Picture.gif');
    ObjectDataset.SetParentComponent(Self);
    ObjectDataset.Name := 'ObjectDataset';
    ObjectDataset.Left := 504;
    ObjectDataset.Top := 104;
    ObjectDatasetIMEI.SetParentComponent(ObjectDataset);
    ObjectDatasetIMEI.Name := 'ObjectDatasetIMEI';
    ObjectDatasetIMEI.FieldName := 'IMEI';
    ObjectDatasetIMEI.Size := 256;
    ObjectDatasetDateTime.SetParentComponent(ObjectDataset);
    ObjectDatasetDateTime.Name := 'ObjectDatasetDateTime';
    ObjectDatasetDateTime.FieldName := 'DateTime';
    ObjectDatasetDateTime.Size := 256;
    ObjectDatasetLatitude.SetParentComponent(ObjectDataset);
    ObjectDatasetLatitude.Name := 'ObjectDatasetLatitude';
    ObjectDatasetLatitude.FieldName := 'Latitude';
    ObjectDatasetLatitude.Size := 256;
    ObjectDatasetLongitude.SetParentComponent(ObjectDataset);
    ObjectDatasetLongitude.Name := 'ObjectDatasetLongitude';
    ObjectDatasetLongitude.FieldName := 'Longitude';
    ObjectDatasetLongitude.Size := 256;
    ObjectDatasetSpeed.SetParentComponent(ObjectDataset);
    ObjectDatasetSpeed.Name := 'ObjectDatasetSpeed';
    ObjectDatasetSpeed.FieldName := 'Speed';
    ObjectDatasetSpeed.Size := 256;
    ObjectDatasetStreet.SetParentComponent(ObjectDataset);
    ObjectDatasetStreet.Name := 'ObjectDatasetStreet';
    ObjectDatasetStreet.FieldName := 'Street';
    ObjectDatasetStreet.Size := 256;
    ObjectDatasetPostalCode.SetParentComponent(ObjectDataset);
    ObjectDatasetPostalCode.Name := 'ObjectDatasetPostalCode';
    ObjectDatasetPostalCode.FieldName := 'Postal Code';
    ObjectDatasetPostalCode.Size := 256;
    ObjectDatasetMunicipality.SetParentComponent(ObjectDataset);
    ObjectDatasetMunicipality.Name := 'ObjectDatasetMunicipality';
    ObjectDatasetMunicipality.FieldName := 'Municipality';
    ObjectDatasetMunicipality.Size := 256;
    ObjectDatasetCountry.SetParentComponent(ObjectDataset);
    ObjectDatasetCountry.Name := 'ObjectDatasetCountry';
    ObjectDatasetCountry.FieldName := 'Country';
    ObjectDatasetCountry.Size := 256;
    ObjectDatasetAssetPKey.SetParentComponent(ObjectDataset);
    ObjectDatasetAssetPKey.Name := 'ObjectDatasetAssetPKey';
    ObjectDatasetAssetPKey.FieldName := 'AssetPKey';
    ObjectDatasetAssetPKey.Size := 256;
    ObjectDatasetSymbol.SetParentComponent(ObjectDataset);
    ObjectDatasetSymbol.Name := 'ObjectDatasetSymbol';
    ObjectDatasetSymbol.FieldName := 'Symbol';
    ObjectDatasetSymbol.Size := 256;
    ObjectDatasetObjectId.SetParentComponent(ObjectDataset);
    ObjectDatasetObjectId.Name := 'ObjectDatasetObjectId';
    ObjectDatasetObjectId.FieldName := 'Object Id';
    ObjectDatasetObjectId.Size := 256;
    ObjectDatasetLicensePlate.SetParentComponent(ObjectDataset);
    ObjectDatasetLicensePlate.Name := 'ObjectDatasetLicensePlate';
    ObjectDatasetLicensePlate.FieldName := 'License Plate';
    ObjectDatasetLicensePlate.Size := 256;
    ObjectDatasetType.SetParentComponent(ObjectDataset);
    ObjectDatasetType.Name := 'ObjectDatasetType';
    ObjectDatasetType.FieldName := 'Type';
    ObjectDatasetType.Size := 256;
    ObjectDatasetBrand.SetParentComponent(ObjectDataset);
    ObjectDatasetBrand.Name := 'ObjectDatasetBrand';
    ObjectDatasetBrand.FieldName := 'Brand';
    ObjectDatasetBrand.Size := 256;
    ObjectDatasetModel.SetParentComponent(ObjectDataset);
    ObjectDatasetModel.Name := 'ObjectDatasetModel';
    ObjectDatasetModel.FieldName := 'Model';
    ObjectDatasetModel.Size := 256;
    ObjectDatasetGroup.SetParentComponent(ObjectDataset);
    ObjectDatasetGroup.Name := 'ObjectDatasetGroup';
    ObjectDatasetGroup.FieldName := 'Group';
    ObjectDatasetGroup.Size := 256;
    ObjectDatasetURL.SetParentComponent(ObjectDataset);
    ObjectDatasetURL.Name := 'ObjectDatasetURL';
    ObjectDatasetURL.FieldName := 'URL';
    ObjectDatasetURL.Size := 256;
    ObjectDatasetEmployeeName.SetParentComponent(ObjectDataset);
    ObjectDatasetEmployeeName.Name := 'ObjectDatasetEmployeeName';
    ObjectDatasetEmployeeName.FieldName := 'Employee Name';
    ObjectDatasetEmployeeName.Size := 256;
    ObjectDatasetIOStatus.SetParentComponent(ObjectDataset);
    ObjectDatasetIOStatus.Name := 'ObjectDatasetIOStatus';
    ObjectDatasetIOStatus.FieldName := 'IO Status';
    ObjectDatasetIOStatus.Size := 256;
    ObjectDatasetIOIgnition.SetParentComponent(ObjectDataset);
    ObjectDatasetIOIgnition.Name := 'ObjectDatasetIOIgnition';
    ObjectDatasetIOIgnition.FieldName := 'IO Ignition';
    ObjectDatasetIOIgnition.Size := 256;
    ObjectDatasetErrorStatus.SetParentComponent(ObjectDataset);
    ObjectDatasetErrorStatus.Name := 'ObjectDatasetErrorStatus';
    ObjectDatasetErrorStatus.FieldName := 'Error Status';
    ObjectDatasetErrorStatus.Size := 256;
    ObjectDatasetAssetStatus.SetParentComponent(ObjectDataset);
    ObjectDatasetAssetStatus.Name := 'ObjectDatasetAssetStatus';
    ObjectDatasetAssetStatus.FieldName := 'Asset Status';
    ObjectDatasetAssetStatus.Size := 256;
    ObjectDatasetAssetStatusHexColor.SetParentComponent(ObjectDataset);
    ObjectDatasetAssetStatusHexColor.Name := 'ObjectDatasetAssetStatusHexColor';
    ObjectDatasetAssetStatusHexColor.FieldName := 'Asset Status HexColor';
    ObjectDatasetAssetStatusHexColor.Size := 256;
    ObjectDatasetBatteryStatus.SetParentComponent(ObjectDataset);
    ObjectDatasetBatteryStatus.Name := 'ObjectDatasetBatteryStatus';
    ObjectDatasetBatteryStatus.FieldName := 'Battery Status';
    ObjectDatasetBatteryStatus.Size := 256;
    ObjectDatasetDeviceTriangle.SetParentComponent(ObjectDataset);
    ObjectDatasetDeviceTriangle.Name := 'ObjectDatasetDeviceTriangle';
    ObjectDatasetDeviceTriangle.FieldName := 'Device Triangle';
    ObjectDatasetDeviceTriangle.Size := 256;
    ObjectDatasetDeviceCircle.SetParentComponent(ObjectDataset);
    ObjectDatasetDeviceCircle.Name := 'ObjectDatasetDeviceCircle';
    ObjectDatasetDeviceCircle.FieldName := 'Device Circle';
    ObjectDatasetDeviceCircle.Size := 256;
    ObjectListTimer.SetParentComponent(Self);
    ObjectListTimer.Name := 'ObjectListTimer';
    ObjectListTimer.Enabled := False;
    SetEvent(ObjectListTimer, Self, 'OnTimer', 'ObjectListTimerTimer');
    ObjectListTimer.Left := 617;
    ObjectListTimer.Top := 104;
    TeamFilterDataSet.SetParentComponent(Self);
    TeamFilterDataSet.Name := 'TeamFilterDataSet';
    TeamFilterDataSet.Left := 504;
    TeamFilterDataSet.Top := 160;
    TeamFilterDataSetfilter_pk.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetfilter_pk.Name := 'TeamFilterDataSetfilter_pk';
    TeamFilterDataSetfilter_pk.FieldName := 'filter_pk';
    TeamFilterDataSetfilter_pk.Size := 256;
    TeamFilterDataSetfilter_naam.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetfilter_naam.Name := 'TeamFilterDataSetfilter_naam';
    TeamFilterDataSetfilter_naam.FieldName := 'filter_naam';
    TeamFilterDataSetfilter_naam.Size := 256;
    TeamFilterDataSetfilter_publiek.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetfilter_publiek.Name := 'TeamFilterDataSetfilter_publiek';
    TeamFilterDataSetfilter_publiek.FieldName := 'filter_publiek';
    TeamFilterDataSetfilter_publiek.Size := 256;
    TeamFilterDataSetfilter_standaard.SetParentComponent(TeamFilterDataSet);
    TeamFilterDataSetfilter_standaard.Name := 'TeamFilterDataSetfilter_standaard';
    TeamFilterDataSetfilter_standaard.FieldName := 'filter_standaard';
    TeamFilterDataSetfilter_standaard.Size := 256;
    TypeFilterDataSet.SetParentComponent(Self);
    TypeFilterDataSet.Name := 'TypeFilterDataSet';
    TypeFilterDataSet.Left := 504;
    TypeFilterDataSet.Top := 224;
    StringField1.SetParentComponent(TypeFilterDataSet);
    StringField1.Name := 'StringField1';
    StringField1.FieldName := 'filter_pk';
    StringField1.Size := 256;
    StringField2.SetParentComponent(TypeFilterDataSet);
    StringField2.Name := 'StringField2';
    StringField2.FieldName := 'filter_naam';
    StringField2.Size := 256;
    StringField3.SetParentComponent(TypeFilterDataSet);
    StringField3.Name := 'StringField3';
    StringField3.FieldName := 'filter_publiek';
    StringField3.Size := 256;
    StringField4.SetParentComponent(TypeFilterDataSet);
    StringField4.Name := 'StringField4';
    StringField4.FieldName := 'filter_standaard';
    StringField4.Size := 256;
    ListPopupMenu.SetParentComponent(Self);
    ListPopupMenu.Name := 'ListPopupMenu';
    ListPopupMenu.Appearance.BackgroundColor := 12498604;
    ListPopupMenu.Appearance.HamburgerMenu.Caption := 'Menu';
    ListPopupMenu.Appearance.HoverColor := 13485241;
    ListPopupMenu.Appearance.HoverFontColor := clWhite;
    ListPopupMenu.Appearance.SubmenuIndicator := '&#9658;';
    ListPopupMenu.Font.Charset := DEFAULT_CHARSET;
    ListPopupMenu.Font.Color := clWindowText;
    ListPopupMenu.Font.Height := -11;
    ListPopupMenu.Font.Name := 'Tahoma';
    ListPopupMenu.Font.Style := [];
    ListPopupMenu.Left := 616;
    ListPopupMenu.Top := 224;
    HourMenuItem.SetParentComponent(ListPopupMenu);
    HourMenuItem.Name := 'HourMenuItem';
    HourMenuItem.Tag := 1;
    HourMenuItem.Caption := 'History - Last Hour';
    SetEvent(HourMenuItem, Self, 'OnClick', 'HistoryMenuItemClick');
    TodayMenuItem.SetParentComponent(ListPopupMenu);
    TodayMenuItem.Name := 'TodayMenuItem';
    TodayMenuItem.Tag := 2;
    TodayMenuItem.Caption := 'History - Today';
    SetEvent(TodayMenuItem, Self, 'OnClick', 'HistoryMenuItemClick');
    YesterdayMenuItem.SetParentComponent(ListPopupMenu);
    YesterdayMenuItem.Name := 'YesterdayMenuItem';
    YesterdayMenuItem.Tag := 3;
    YesterdayMenuItem.Caption := 'History - Yesterday';
    SetEvent(YesterdayMenuItem, Self, 'OnClick', 'HistoryMenuItemClick');
    LastTripMenuItem.SetParentComponent(ListPopupMenu);
    LastTripMenuItem.Name := 'LastTripMenuItem';
    LastTripMenuItem.Tag := 4;
    LastTripMenuItem.Caption := 'History - Last known route';
    SetEvent(LastTripMenuItem, Self, 'OnClick', 'HistoryMenuItemClick');
    CustomDateMenuItem.SetParentComponent(ListPopupMenu);
    CustomDateMenuItem.Name := 'CustomDateMenuItem';
    CustomDateMenuItem.Tag := 5;
    CustomDateMenuItem.Caption := 'History - Custom Date';
    SetEvent(CustomDateMenuItem, Self, 'OnClick', 'HistoryMenuItemClick');
    N1.SetParentComponent(ListPopupMenu);
    N1.Name := 'N1';
    N1.Caption := '-';
    AssetEditMenuItem.SetParentComponent(ListPopupMenu);
    AssetEditMenuItem.Name := 'AssetEditMenuItem';
    AssetEditMenuItem.Caption := 'Asset Details';
    SetEvent(AssetEditMenuItem, Self, 'OnClick', 'AssetEditMenuItemClick');
    ChangeDriverMenuItem.SetParentComponent(ListPopupMenu);
    ChangeDriverMenuItem.Name := 'ChangeDriverMenuItem';
    ChangeDriverMenuItem.Caption := 'Change Driver';
    SetEvent(ChangeDriverMenuItem, Self, 'OnClick', 'ChangeDriverMenuItemClick');
    ChangeStatusMenuItem.SetParentComponent(ListPopupMenu);
    ChangeStatusMenuItem.Name := 'ChangeStatusMenuItem';
    ChangeStatusMenuItem.Caption := 'Change Status';
    SetEvent(ChangeStatusMenuItem, Self, 'OnClick', 'ChangeStatusMenuItemClick');
    MyWebConnection.SetParentComponent(Self);
    MyWebConnection.Name := 'MyWebConnection';
    MyWebConnection.Active := False;
    MyWebConnection.DataNode := 'records';
    MyWebConnection.Delimiter := ';';
    MyWebConnection.Left := 503;
    MyWebConnection.Top := 334;
    ObjectRefreshTimer.SetParentComponent(Self);
    ObjectRefreshTimer.Name := 'ObjectRefreshTimer';
    ObjectRefreshTimer.Enabled := False;
    ObjectRefreshTimer.Interval := 60000;
    SetEvent(ObjectRefreshTimer, Self, 'OnTimer', 'ObjectRefreshTimerTimer');
    ObjectRefreshTimer.Left := 618;
    ObjectRefreshTimer.Top := 162;
  finally
    BottomPanel.AfterLoadDFMValues;
    ToolbarPanel.AfterLoadDFMValues;
    SearchEdit.AfterLoadDFMValues;
    TypeComboBox.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    TeamFilterButton.AfterLoadDFMValues;
    TeamComboBox.AfterLoadDFMValues;
    TypeFilterButton.AfterLoadDFMValues;
    SearchTypeEdit.AfterLoadDFMValues;
    ClearSearchEditButton.AfterLoadDFMValues;
    MarkerClusterButton.AfterLoadDFMValues;
    MarkerOverlayButton.AfterLoadDFMValues;
    AssetHideButton.AfterLoadDFMValues;
    ZoomToBoundButton.AfterLoadDFMValues;
    POIGroupButton.AfterLoadDFMValues;
    GeofenceButton.AfterLoadDFMValues;
    DistanceRulerButton.AfterLoadDFMValues;
    AssetStatusComboBox.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    TMSFNCPanel2.AfterLoadDFMValues;
    ObjectListPanel.AfterLoadDFMValues;
    ListButtonPanel.AfterLoadDFMValues;
    ListButtonInactivePanel.AfterLoadDFMValues;
    ListButtonInactiveLabel.AfterLoadDFMValues;
    ListButtonOfflinePanel.AfterLoadDFMValues;
    ListButtonOfflineLabel.AfterLoadDFMValues;
    ListButtonOnlinePanel.AfterLoadDFMValues;
    ListButtonOnlineLabel.AfterLoadDFMValues;
    ListButtonAllPanel.AfterLoadDFMValues;
    ListButtonAllLabel.AfterLoadDFMValues;
    ListButtonIndicatorPanel.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    RealtimeMap.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    ObjectDataset.AfterLoadDFMValues;
    ObjectDatasetIMEI.AfterLoadDFMValues;
    ObjectDatasetDateTime.AfterLoadDFMValues;
    ObjectDatasetLatitude.AfterLoadDFMValues;
    ObjectDatasetLongitude.AfterLoadDFMValues;
    ObjectDatasetSpeed.AfterLoadDFMValues;
    ObjectDatasetStreet.AfterLoadDFMValues;
    ObjectDatasetPostalCode.AfterLoadDFMValues;
    ObjectDatasetMunicipality.AfterLoadDFMValues;
    ObjectDatasetCountry.AfterLoadDFMValues;
    ObjectDatasetAssetPKey.AfterLoadDFMValues;
    ObjectDatasetSymbol.AfterLoadDFMValues;
    ObjectDatasetObjectId.AfterLoadDFMValues;
    ObjectDatasetLicensePlate.AfterLoadDFMValues;
    ObjectDatasetType.AfterLoadDFMValues;
    ObjectDatasetBrand.AfterLoadDFMValues;
    ObjectDatasetModel.AfterLoadDFMValues;
    ObjectDatasetGroup.AfterLoadDFMValues;
    ObjectDatasetURL.AfterLoadDFMValues;
    ObjectDatasetEmployeeName.AfterLoadDFMValues;
    ObjectDatasetIOStatus.AfterLoadDFMValues;
    ObjectDatasetIOIgnition.AfterLoadDFMValues;
    ObjectDatasetErrorStatus.AfterLoadDFMValues;
    ObjectDatasetAssetStatus.AfterLoadDFMValues;
    ObjectDatasetAssetStatusHexColor.AfterLoadDFMValues;
    ObjectDatasetBatteryStatus.AfterLoadDFMValues;
    ObjectDatasetDeviceTriangle.AfterLoadDFMValues;
    ObjectDatasetDeviceCircle.AfterLoadDFMValues;
    ObjectListTimer.AfterLoadDFMValues;
    TeamFilterDataSet.AfterLoadDFMValues;
    TeamFilterDataSetfilter_pk.AfterLoadDFMValues;
    TeamFilterDataSetfilter_naam.AfterLoadDFMValues;
    TeamFilterDataSetfilter_publiek.AfterLoadDFMValues;
    TeamFilterDataSetfilter_standaard.AfterLoadDFMValues;
    TypeFilterDataSet.AfterLoadDFMValues;
    StringField1.AfterLoadDFMValues;
    StringField2.AfterLoadDFMValues;
    StringField3.AfterLoadDFMValues;
    StringField4.AfterLoadDFMValues;
    ListPopupMenu.AfterLoadDFMValues;
    HourMenuItem.AfterLoadDFMValues;
    TodayMenuItem.AfterLoadDFMValues;
    YesterdayMenuItem.AfterLoadDFMValues;
    LastTripMenuItem.AfterLoadDFMValues;
    CustomDateMenuItem.AfterLoadDFMValues;
    N1.AfterLoadDFMValues;
    AssetEditMenuItem.AfterLoadDFMValues;
    ChangeDriverMenuItem.AfterLoadDFMValues;
    ChangeStatusMenuItem.AfterLoadDFMValues;
    MyWebConnection.AfterLoadDFMValues;
    ObjectRefreshTimer.AfterLoadDFMValues;
  end;
end;

end.


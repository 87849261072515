unit uAssetModelInstructionEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.JSON, WEBLib.REST,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCPanel, WEBLib.Buttons, VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl, VCL.TMSFNCWXHTMLMemo, Vcl.Imaging.GIFImg;

type
  TAssetModelInstructionEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    HeaderPanel: TTMSFNCPanel;
    StatusBarPanel: TTMSFNCPanel;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    DescriptionLabel: TLabel;
    InstructionIDEdit: TEdit;
    WebWaitMessage: TWaitMessage;
    MyMessageDlg: TMessageDlg;
    HTMLMemo: TTMSFNCWXHTMLMemo;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    [async]
    procedure CloseButtonClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure InstructionIDEditChange(Sender: TObject);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    ModelPKey, PKey: Int64;
    HTMLString: String;
  protected procedure LoadDFMValues; override; end;

var
  AssetModelInstructionEditForm: TAssetModelInstructionEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetModelInstructionEditForm.WebFormCreate(Sender: TObject);
begin
  FormPanel.EnablePropagation := True;
  ContentPanel.EnablePropagation := True;
end;

procedure TAssetModelInstructionEditForm.WebFormShow(Sender: TObject);
begin
  if PKey > 0 then begin
    WebWaitMessage.Show;
    await(LoadData());
    WebWaitMessage.Hide;
    RecordChanged := False;
  end else begin
    InstructionIDEdit.SetFocus;
    RecordChanged := False;
  end;
end;

procedure TAssetModelInstructionEditForm.CloseButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if not(RecordChanged) then RecordChanged :=
    HTMLMemoValueChanged(HTMLString, HTMLMemo.HTML.Text);
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
    if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
  end;
end;

procedure TAssetModelInstructionEditForm.InstructionIDEditChange(
  Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TAssetModelInstructionEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_asset_model_instruction_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Parse Result
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      InstructionIDEdit.Text := MyJSONObject.GetJSONValue('ID');
      HTMLString := Window.atob(MyJSONObject.GetJSONValue('Content'));
      HTMLMemo.HTML.Text := HTMLString;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetModelInstructionEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

function TAssetModelInstructionEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Description
  if Result then begin
    if Trim(InstructionIDEdit.Text) = EmptyStr then begin
      Result := False;
      ErrorMessage := '"Description" is a required field.';
    end;
  end;
end;

function TAssetModelInstructionEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + IntToStr(PKey) + '",' +
      '"model": "' + IntToStr(ModelPKey) + '",' +
      '"id": "' + InstructionIDEdit.Text + '",' +
      '"content": "' + Window.btoa(HTMLMemo.HTML.Text) + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_model_instruction_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
        Result := True;
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end;
  finally
    MyWebRequest.Free;
  end;
end;


procedure TAssetModelInstructionEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  HeaderPanel := TTMSFNCPanel.Create(Self);
  DescriptionLabel := TLabel.Create(Self);
  InstructionIDEdit := TEdit.Create(Self);
  StatusBarPanel := TTMSFNCPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  HTMLMemo := TTMSFNCWXHTMLMemo.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  HeaderPanel.BeforeLoadDFMValues;
  DescriptionLabel.BeforeLoadDFMValues;
  InstructionIDEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  HTMLMemo.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  try
    Name := 'AssetModelInstructionEditForm';
    Width := 957;
    Height := 623;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 957;
    FormPanel.Height := 623;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 951;
    ContentPanel.Height := 580;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    HeaderPanel.SetParentComponent(ContentPanel);
    HeaderPanel.Name := 'HeaderPanel';
    HeaderPanel.Tag := 1;
    HeaderPanel.Left := 0;
    HeaderPanel.Top := 0;
    HeaderPanel.Width := 951;
    HeaderPanel.Height := 72;
    HeaderPanel.Align := alTop;
    HeaderPanel.BevelInner := bvNone;
    HeaderPanel.BevelOuter := bvNone;
    HeaderPanel.Ctl3D := True;
    HeaderPanel.ParentCtl3D := False;
    HeaderPanel.ParentDoubleBuffered := False;
    HeaderPanel.DoubleBuffered := True;
    HeaderPanel.TabOrder := 0;
    HeaderPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    HeaderPanel.SectionsAppearance.Font.Color := clWindowText;
    HeaderPanel.SectionsAppearance.Font.Height := -11;
    HeaderPanel.SectionsAppearance.Font.Name := 'Tahoma';
    HeaderPanel.SectionsAppearance.Font.Style := [];
    HeaderPanel.Stroke.Kind := gskNone;
    HeaderPanel.Header.Text := 'Header';
    HeaderPanel.Header.Font.Charset := DEFAULT_CHARSET;
    HeaderPanel.Header.Font.Color := clWindowText;
    HeaderPanel.Header.Font.Height := -11;
    HeaderPanel.Header.Font.Name := 'Tahoma';
    HeaderPanel.Header.Font.Style := [];
    HeaderPanel.Header.Visible := False;
    HeaderPanel.Footer.Text := 'Footer';
    HeaderPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    HeaderPanel.Footer.Font.Color := clWindowText;
    HeaderPanel.Footer.Font.Height := -11;
    HeaderPanel.Footer.Font.Name := 'Tahoma';
    HeaderPanel.Footer.Font.Style := [];
    HeaderPanel.GlobalFont.Scale := 1.000000000000000000;
    HeaderPanel.GlobalFont.Style := [];
    DescriptionLabel.SetParentComponent(HeaderPanel);
    DescriptionLabel.Name := 'DescriptionLabel';
    DescriptionLabel.AlignWithMargins := True;
    DescriptionLabel.Left := 8;
    DescriptionLabel.Top := 8;
    DescriptionLabel.Width := 935;
    DescriptionLabel.Height := 21;
    DescriptionLabel.Margins.Left := 8;
    DescriptionLabel.Margins.Top := 8;
    DescriptionLabel.Margins.Right := 8;
    DescriptionLabel.Align := alTop;
    DescriptionLabel.Caption := 'DESCRIPTION';
    DescriptionLabel.Font.Charset := ANSI_CHARSET;
    DescriptionLabel.Font.Color := 9010544;
    DescriptionLabel.Font.Height := -16;
    DescriptionLabel.Font.Name := 'Segoe UI';
    DescriptionLabel.Font.Style := [];
    DescriptionLabel.HeightPercent := 100.000000000000000000;
    DescriptionLabel.ParentFont := False;
    DescriptionLabel.WidthPercent := 100.000000000000000000;
    InstructionIDEdit.SetParentComponent(HeaderPanel);
    InstructionIDEdit.Name := 'InstructionIDEdit';
    InstructionIDEdit.AlignWithMargins := True;
    InstructionIDEdit.Left := 8;
    InstructionIDEdit.Top := 35;
    InstructionIDEdit.Width := 935;
    InstructionIDEdit.Height := 34;
    InstructionIDEdit.Margins.Left := 8;
    InstructionIDEdit.Margins.Right := 8;
    InstructionIDEdit.Align := alClient;
    InstructionIDEdit.CharCase := wecUpperCase;
    InstructionIDEdit.ChildOrder := 1;
    InstructionIDEdit.ElementClassName := 'MyWebEdit';
    InstructionIDEdit.Font.Charset := ANSI_CHARSET;
    InstructionIDEdit.Font.Color := clFuchsia;
    InstructionIDEdit.Font.Height := -16;
    InstructionIDEdit.Font.Name := 'Segoe UI';
    InstructionIDEdit.Font.Style := [];
    InstructionIDEdit.HeightPercent := 100.000000000000000000;
    InstructionIDEdit.ParentFont := False;
    InstructionIDEdit.WidthPercent := 100.000000000000000000;
    SetEvent(InstructionIDEdit, Self, 'OnChange', 'InstructionIDEditChange');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Tag := 1;
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 528;
    StatusBarPanel.Width := 951;
    StatusBarPanel.Height := 52;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BevelInner := bvNone;
    StatusBarPanel.BevelOuter := bvNone;
    StatusBarPanel.Ctl3D := True;
    StatusBarPanel.ParentCtl3D := False;
    StatusBarPanel.ParentDoubleBuffered := False;
    StatusBarPanel.DoubleBuffered := True;
    StatusBarPanel.TabOrder := 1;
    StatusBarPanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    StatusBarPanel.SectionsAppearance.Font.Color := clWindowText;
    StatusBarPanel.SectionsAppearance.Font.Height := -11;
    StatusBarPanel.SectionsAppearance.Font.Name := 'Tahoma';
    StatusBarPanel.SectionsAppearance.Font.Style := [];
    StatusBarPanel.Stroke.Kind := gskNone;
    StatusBarPanel.Header.Text := 'Header';
    StatusBarPanel.Header.Font.Charset := DEFAULT_CHARSET;
    StatusBarPanel.Header.Font.Color := clWindowText;
    StatusBarPanel.Header.Font.Height := -11;
    StatusBarPanel.Header.Font.Name := 'Tahoma';
    StatusBarPanel.Header.Font.Style := [];
    StatusBarPanel.Header.Visible := False;
    StatusBarPanel.Footer.Text := 'Footer';
    StatusBarPanel.Footer.Font.Charset := DEFAULT_CHARSET;
    StatusBarPanel.Footer.Font.Color := clWindowText;
    StatusBarPanel.Footer.Font.Height := -11;
    StatusBarPanel.Footer.Font.Name := 'Tahoma';
    StatusBarPanel.Footer.Font.Style := [];
    StatusBarPanel.GlobalFont.Scale := 1.000000000000000000;
    StatusBarPanel.GlobalFont.Style := [];
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 716;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 34;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetModelInstructionEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 831;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 34;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetModelInstructionEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    HTMLMemo.SetParentComponent(ContentPanel);
    HTMLMemo.Name := 'HTMLMemo';
    HTMLMemo.AlignWithMargins := True;
    HTMLMemo.Left := 8;
    HTMLMemo.Top := 75;
    HTMLMemo.Width := 935;
    HTMLMemo.Height := 450;
    HTMLMemo.Margins.Left := 8;
    HTMLMemo.Margins.Right := 8;
    HTMLMemo.Align := alClient;
    HTMLMemo.ParentDoubleBuffered := False;
    HTMLMemo.DoubleBuffered := True;
    HTMLMemo.TabOrder := 2;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 957;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 166;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Model Instruction';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 928;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    CloseImage.Picture.LoadFromFile('uAssetModelInstructionEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 53;
    WebWaitMessage.Top := 207;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uAssetModelInstructionEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 53;
    MyMessageDlg.Top := 255;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    HeaderPanel.AfterLoadDFMValues;
    DescriptionLabel.AfterLoadDFMValues;
    InstructionIDEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    HTMLMemo.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
  end;
end;

end.

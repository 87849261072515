unit uAssetRepairTicketView;

interface

uses
  System.SysUtils, System.Classes, System.StrUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Rest, WEBLib.JSon, WEBLib.RegularExpressions,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons, WEBLib.Grids,
  WEBLib.WebCtrls, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, VCL.TMSFNCImage;

type
  TAssetRepairTicketViewForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    WebLabel3: TLabel;
    AssetIDEdit: TEdit;
    WebLabel15: TLabel;
    EmailBodyEdit: TMemo;
    WebLabel4: TLabel;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    RecipientsEdit: TMemo;
    MyResponsiveGrid: TResponsiveGrid;
    ImagePanel: TTMSFNCPanel;
    PanelTMSFNCImage: TTMSFNCImage;
    WebLabel5: TLabel;
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure WebFormShow(Sender: TObject); async;
    procedure MyResponsiveGridItemClick(Sender: TObject; Index: Integer);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure PanelTMSFNCImageClick(Sender: TObject);
  private
    { Private declarations }
    AttachmentList: TStringList;
    [async]
    function LoadData(): Boolean; async;
    procedure ShowPictures(Count: Integer; List: String);
  public
    AssetPKey: String;
    HistoryPKey: String;
  protected procedure LoadDFMValues; override; end;

var
  AssetRepairTicketViewForm: TAssetRepairTicketViewForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetRepairTicketViewForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  AttachmentList.Free;
end;

procedure TAssetRepairTicketViewForm.WebFormShow(Sender: TObject);
begin
  AttachmentList := TStringList.Create;
  LoadData();
end;

function TAssetRepairTicketViewForm.LoadData(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  StringList: TStringList;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL := url_php +
      'repair_ticket_load.php?sessionid=' + SessionId +
      '&assetpkey=' + AssetPKey +
      '&ticketpkey=' + HistoryPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
//  AssetIDEdit.Text := MyJSONObject.GetJSONValue('asset_id');
    AssetIDEdit.Text := MyJSONObject.GetJSONValue('license_plate');
    EmailBodyEdit.Text := Window.atob(MyJSONObject.GetJSONValue('description'));
    // Recipients
    StringList := TStringList.Create;
    try
      StringList.DelimitedText := MyJSONObject.GetJSONValue('recipient_list');
      RecipientsEdit.Lines := StringList;
    finally
      StringList.Free;
    end;
    // Pictures
    ShowPictures(
      StrToInt(MyJSONObject.GetJSONValue('attachment_count')),
      MyJSONObject.GetJSONValue('attachment_list')
    );
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetRepairTicketViewForm.MyResponsiveGridItemClick(Sender: TObject;
  Index: Integer);
var
  Base64: String;
begin
  Base64 := AttachmentList[Index];
  PanelTMSFNCImage.Bitmap.LoadFromURL(
    'data:image/png;base64,' + Base64
  );
  ImagePanel.Visible := True;
  ImagePanel.BringToFront;
end;

procedure TAssetRepairTicketViewForm.PanelTMSFNCImageClick(Sender: TObject);
begin
  ImagePanel.Visible := False;
end;

procedure TAssetRepairTicketViewForm.ShowPictures(Count: Integer; List: String);
var
  I: Integer;
  Base64: String;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
  MyGridItem: TResponsiveGridItem;
  MyCheckBox: TCheckBox;
begin
  MyResponsiveGrid.Items.Clear;
  if Count > 0 then begin
    // Parse JSON
    MyJSON := TJSON.Create;
    try
      MyJSONArray := TJSONArray(MyJSON.Parse(window.atob(List)));
      for i := 0 to MyJSONArray.Count-1 do begin
        MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
        Base64 := MyJSONObject.GetJSONValue('file_base64');
        MyGridItem := MyResponsiveGrid.Items.Add;
        MyGridItem.HTML :=
          '<center>' +
            '<div>' +
            '<img style="max-width:132px;max-height:132px;width:auto;height:auto;" src="data:image/png;base64,' + Base64 + '"/>' +
            '</div>' +
          '</center>';
        AttachmentList.Add(Base64);
      end;
    finally
      MyJSON.Free;
    end;
  end;
end;

procedure TAssetRepairTicketViewForm.CloseImageClick(Sender: TObject);
begin
  if ImagePanel.Visible then begin
    ImagePanel.Visible := False;
  end else begin
    ModalResult := mrCancel;
  end;
end;

procedure TAssetRepairTicketViewForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ImagePanel := TTMSFNCPanel.Create(Self);
  PanelTMSFNCImage := TTMSFNCImage.Create(Self);
  ContentPanel := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  AssetIDEdit := TEdit.Create(Self);
  EmailBodyEdit := TMemo.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  RecipientsEdit := TMemo.Create(Self);
  MyResponsiveGrid := TResponsiveGrid.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ImagePanel.BeforeLoadDFMValues;
  PanelTMSFNCImage.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  AssetIDEdit.BeforeLoadDFMValues;
  EmailBodyEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  RecipientsEdit.BeforeLoadDFMValues;
  MyResponsiveGrid.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  try
    Name := 'AssetRepairTicketViewForm';
    Width := 829;
    Height := 647;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 829;
    FormPanel.Height := 647;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ImagePanel.SetParentComponent(FormPanel);
    ImagePanel.Name := 'ImagePanel';
    ImagePanel.Tag := 1;
    ImagePanel.Left := 0;
    ImagePanel.Top := 40;
    ImagePanel.Width := 829;
    ImagePanel.Height := 607;
    ImagePanel.Align := alClient;
    ImagePanel.BevelInner := bvNone;
    ImagePanel.BevelOuter := bvNone;
    ImagePanel.Ctl3D := True;
    ImagePanel.ParentCtl3D := False;
    ImagePanel.ParentDoubleBuffered := False;
    ImagePanel.DoubleBuffered := True;
    ImagePanel.TabOrder := 2;
    ImagePanel.Visible := False;
    ImagePanel.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    ImagePanel.SectionsAppearance.Font.Color := clWindowText;
    ImagePanel.SectionsAppearance.Font.Height := -11;
    ImagePanel.SectionsAppearance.Font.Name := 'Tahoma';
    ImagePanel.SectionsAppearance.Font.Style := [];
    ImagePanel.Header.Text := 'Header';
    ImagePanel.Header.Font.Charset := DEFAULT_CHARSET;
    ImagePanel.Header.Font.Color := clWindowText;
    ImagePanel.Header.Font.Height := -11;
    ImagePanel.Header.Font.Name := 'Tahoma';
    ImagePanel.Header.Font.Style := [];
    ImagePanel.Header.Visible := False;
    ImagePanel.Header.Fill.Color := 16380654;
    ImagePanel.Header.Stroke.Kind := gskNone;
    ImagePanel.Footer.Text := 'Footer';
    ImagePanel.Footer.Font.Charset := DEFAULT_CHARSET;
    ImagePanel.Footer.Font.Color := clWindowText;
    ImagePanel.Footer.Font.Height := -11;
    ImagePanel.Footer.Font.Name := 'Tahoma';
    ImagePanel.Footer.Font.Style := [];
    ImagePanel.Footer.Fill.Color := 16380654;
    ImagePanel.GlobalFont.Scale := 1.000000000000000000;
    ImagePanel.GlobalFont.Style := [];
    PanelTMSFNCImage.SetParentComponent(ImagePanel);
    PanelTMSFNCImage.Name := 'PanelTMSFNCImage';
    PanelTMSFNCImage.Left := 0;
    PanelTMSFNCImage.Top := 0;
    PanelTMSFNCImage.Width := 829;
    PanelTMSFNCImage.Height := 607;
    PanelTMSFNCImage.Align := alClient;
    PanelTMSFNCImage.ParentDoubleBuffered := False;
    PanelTMSFNCImage.Color := clBtnFace;
    PanelTMSFNCImage.DoubleBuffered := True;
    PanelTMSFNCImage.TabOrder := 0;
    SetEvent(PanelTMSFNCImage, Self, 'OnClick', 'PanelTMSFNCImageClick');
    PanelTMSFNCImage.Fill.Kind := gfkNone;
    PanelTMSFNCImage.Stroke.Kind := gskNone;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 823;
    ContentPanel.Height := 604;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    WebLabel3.SetParentComponent(ContentPanel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 19;
    WebLabel3.Top := 8;
    WebLabel3.Width := 56;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Asset ID';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(ContentPanel);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 19;
    WebLabel15.Top := 76;
    WebLabel15.Width := 142;
    WebLabel15.Height := 21;
    WebLabel15.Caption := 'Problem Description';
    WebLabel15.Font.Charset := ANSI_CHARSET;
    WebLabel15.Font.Color := 9010544;
    WebLabel15.Font.Height := -16;
    WebLabel15.Font.Name := 'Segoe UI';
    WebLabel15.Font.Style := [];
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ContentPanel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 19;
    WebLabel4.Top := 428;
    WebLabel4.Width := 71;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Recipients';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(ContentPanel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 384;
    WebLabel5.Top := 8;
    WebLabel5.Width := 50;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'Images';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    AssetIDEdit.SetParentComponent(ContentPanel);
    AssetIDEdit.Name := 'AssetIDEdit';
    AssetIDEdit.Left := 19;
    AssetIDEdit.Top := 32;
    AssetIDEdit.Width := 333;
    AssetIDEdit.Height := 32;
    AssetIDEdit.CharCase := wecUpperCase;
    AssetIDEdit.ChildOrder := 1;
    AssetIDEdit.ElementClassName := 'MyWebEdit';
    AssetIDEdit.Font.Charset := ANSI_CHARSET;
    AssetIDEdit.Font.Color := clFuchsia;
    AssetIDEdit.Font.Height := -16;
    AssetIDEdit.Font.Name := 'Segoe UI';
    AssetIDEdit.Font.Style := [];
    AssetIDEdit.HeightPercent := 100.000000000000000000;
    AssetIDEdit.ParentFont := False;
    AssetIDEdit.ReadOnly := True;
    AssetIDEdit.WidthPercent := 100.000000000000000000;
    EmailBodyEdit.SetParentComponent(ContentPanel);
    EmailBodyEdit.Name := 'EmailBodyEdit';
    EmailBodyEdit.Left := 19;
    EmailBodyEdit.Top := 103;
    EmailBodyEdit.Width := 333;
    EmailBodyEdit.Height := 319;
    EmailBodyEdit.AutoSize := False;
    EmailBodyEdit.ElementClassName := 'MyWebEdit';
    EmailBodyEdit.HeightPercent := 100.000000000000000000;
    EmailBodyEdit.ReadOnly := True;
    EmailBodyEdit.SelLength := 0;
    EmailBodyEdit.SelStart := 0;
    EmailBodyEdit.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 555;
    StatusBarPanel.Width := 823;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 703;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Close';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetRepairTicketView.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    RecipientsEdit.SetParentComponent(ContentPanel);
    RecipientsEdit.Name := 'RecipientsEdit';
    RecipientsEdit.Left := 19;
    RecipientsEdit.Top := 455;
    RecipientsEdit.Width := 333;
    RecipientsEdit.Height := 97;
    RecipientsEdit.AutoSize := False;
    RecipientsEdit.ElementClassName := 'MyWebEdit';
    RecipientsEdit.HeightPercent := 100.000000000000000000;
    RecipientsEdit.ReadOnly := True;
    RecipientsEdit.SelLength := 0;
    RecipientsEdit.SelStart := 0;
    RecipientsEdit.WidthPercent := 100.000000000000000000;
    MyResponsiveGrid.SetParentComponent(ContentPanel);
    MyResponsiveGrid.Name := 'MyResponsiveGrid';
    MyResponsiveGrid.AlignWithMargins := True;
    MyResponsiveGrid.Left := 384;
    MyResponsiveGrid.Top := 32;
    MyResponsiveGrid.Width := 419;
    MyResponsiveGrid.Height := 520;
    MyResponsiveGrid.Margins.Left := 10;
    MyResponsiveGrid.Margins.Top := 32;
    MyResponsiveGrid.Margins.Right := 20;
    MyResponsiveGrid.Align := alRight;
    MyResponsiveGrid.ChildOrder := 3;
    MyResponsiveGrid.ItemIndex := 0;
    MyResponsiveGrid.Options.ItemGap := 5;
    MyResponsiveGrid.Options.ItemSelectedColor := -1;
    MyResponsiveGrid.Options.ItemWidthMin := 120;
    SetEvent(MyResponsiveGrid, Self, 'OnItemClick', 'MyResponsiveGridItemClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 829;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 146;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset - Repair Ticket';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 800;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetRepairTicketView.CaptionPanel.CloseImage.Picture.png');
  finally
    FormPanel.AfterLoadDFMValues;
    ImagePanel.AfterLoadDFMValues;
    PanelTMSFNCImage.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    AssetIDEdit.AfterLoadDFMValues;
    EmailBodyEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    RecipientsEdit.AfterLoadDFMValues;
    MyResponsiveGrid.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
  end;
end;

end.
unit uAssetStatusChange;

interface

uses
  System.SysUtils, System.Classes, System.StrUtils,
  JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Rest, WEBLib.JSon, WEBLib.RegularExpressions,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WEBLib.Buttons;

type
  TAssetStatusChangeForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    WebLabel3: TLabel;
    AssetIDEdit: TEdit;
    WebLabel1: TLabel;
    AssetStatusOldEdit: TEdit;
    WebLabel2: TLabel;
    AssetStatusNewEdit: TLookupComboBox;
    WebLabel15: TLabel;
    EmailBodyEdit: TMemo;
    WebLabel4: TLabel;
    EmailToEdit: TEdit;
    WebLabel5: TLabel;
    EmailCCEdit: TEdit;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    procedure CloseImageClick(Sender: TObject);
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure WebFormShow(Sender: TObject);
    procedure AssetStatusNewEditChange(Sender: TObject);
  private
    { Private declarations }
    [async]
    function LoadData(): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
    procedure LoadAsset(S: String);
    procedure LoadEMail(S: String);
    procedure LoadAssetStatusList(S: String);
    [async]
    procedure LoadAssetStatusEmail(S: String); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    AssetPKey: String;
    StatusPKey: String;
  protected procedure LoadDFMValues; override; end;

var
  AssetStatusChangeForm: TAssetStatusChangeForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TAssetStatusChangeForm.WebFormShow(Sender: TObject);
begin
  LoadData();
  LoadAssetStatusEmail('0');
end;

function TAssetStatusChangeForm.LoadData(): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL := url_php +
      'default_asset_status_change_load.php?sessionid=' + SessionId +
      '&pkey=' + AssetPKey;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadAsset(TJSONPair(MyJSONObject.Get('asset')).JsonValue.ToString);
    LoadAssetStatusList(TJSONPair(MyJSONObject.Get('asset_status')).JsonValue.ToString);
    LoadEmail(TJSONPair(MyJSONObject.Get('email')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TAssetStatusChangeForm.LoadAsset(S: String);
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(S));
    AssetIDEdit.Text := MyJSONObject.GetJSONValue('ID');
    AssetStatusOldEdit.Text := MyJSONObject.GetJSONValue('Status ID');
  finally
    MyJSON.Free;
  end
end;

procedure TAssetStatusChangeForm.LoadAssetStatusList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  AssetStatusNewEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      AssetStatusNewEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    AssetStatusNewEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TAssetStatusChangeForm.LoadAssetStatusEmail(S: String);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL := url_php +
      'default_object_status_email_list.php?sessionid=' + SessionId +
      '&asset_pkey=' + AssetPKey + '&status_pkey=' + S;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    EMailCCEdit.Text := MyJSONObject.GetJSONValue('email_address_list');
  finally
    MyJSON.Free;
  end
end;

procedure TAssetStatusChangeForm.LoadEmail(S: String);
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(S));
    EMailToEdit.Text := MyJSONObject.GetJSONValue('EMail');
  finally
    MyJSON.Free;
  end
end;

procedure TAssetStatusChangeForm.AssetStatusNewEditChange(Sender: TObject);
begin
  LoadAssetStatusEmail((Sender as TLookupComboBox).Value);
end;

procedure TAssetStatusChangeForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TAssetStatusChangeForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      StatusPKey := AssetStatusNewEdit.Value;
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, TMsgDlgType.mtError, [mbOK]);
  end;
end;

function TAssetStatusChangeForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  // Your Email
  if Result = True then begin
    try
      Result := TRegEx.IsMatch(Trim(EmailToEdit.Text), RegExEmail);
      if not(Result) then ErrorMessage := 'Your Email is not valid.';
    except
      on E: Exception do begin
        Result := False;
        ErrorMessage := E.Message;
      end;
    end;
  end;
  // CC
  if Result = True then begin
    if not(Trim(EmailCcEdit.Text).IsEmpty) then begin
      try
        Result := TRegEx.IsMatch(Trim(EmailCcEdit.Text), RegExEmail);
        if not(Result) then ErrorMessage := 'CC is not valid.';
      except
        on E: Exception do begin
          Result := False;
          ErrorMessage := E.Message;
        end;
      end;
    end;
  end;
end;

function TAssetStatusChangeForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyString: String;
begin
  // Get Values
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_asset_status_change_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyString :=
      '{' +
        '"sessionid":"' + SessionID + '",' +
        '"assetimei":"' + '0' + '",' +
        '"assetpkey":"' + AssetPKey + '",' +
        '"statuspkey":"' + AssetStatusNewEdit.Value + '",' +
        '"to":"' + Trim(EmailToEdit.Text) + '",' +
        '"cc":"' + Trim(EMailCcEdit.Text) + '",' +
        '"subject":"' + CaptionLabel.Caption + ' ' + AssetIDEdit.Text + '",' +
        '"body":"' + window.btoa(EmailBodyEdit.Text) + '",' +
        '"attachments":"' + window.btoa('[]') + '"' +
      '}';
    MyWebRequest.PostData := MyString;
    // Execute Request
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status_code') = 'OK' then begin
          Result := True;
        end else begin
          Result := False;
          ErrorMessage := MyJSONObject.GetJSONValue('error_message');
        end;
      finally
        MyJSON.Free;
      end;
    end else begin
      Result := False;
      ErrorMessage := 'Http Error: ' + IntToStr(MyRequest.Status);
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TAssetStatusChangeForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  AssetIDEdit := TEdit.Create(Self);
  AssetStatusOldEdit := TEdit.Create(Self);
  AssetStatusNewEdit := TLookupComboBox.Create(Self);
  EmailBodyEdit := TMemo.Create(Self);
  EmailToEdit := TEdit.Create(Self);
  EmailCCEdit := TEdit.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  AssetIDEdit.BeforeLoadDFMValues;
  AssetStatusOldEdit.BeforeLoadDFMValues;
  AssetStatusNewEdit.BeforeLoadDFMValues;
  EmailBodyEdit.BeforeLoadDFMValues;
  EmailToEdit.BeforeLoadDFMValues;
  EmailCCEdit.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  try
    Name := 'AssetStatusChangeForm';
    Width := 378;
    Height := 647;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 378;
    FormPanel.Height := 647;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 372;
    ContentPanel.Height := 604;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    ContentPanel.Font.Charset := DEFAULT_CHARSET;
    ContentPanel.Font.Color := clWindowText;
    ContentPanel.Font.Height := -16;
    ContentPanel.Font.Name := 'Tahoma';
    ContentPanel.Font.Style := [];
    ContentPanel.ParentFont := False;
    WebLabel3.SetParentComponent(ContentPanel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 19;
    WebLabel3.Top := 8;
    WebLabel3.Width := 56;
    WebLabel3.Height := 21;
    WebLabel3.Caption := 'Asset ID';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := 9010544;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Segoe UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(ContentPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 19;
    WebLabel1.Top := 70;
    WebLabel1.Width := 99;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Current Status';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ContentPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 19;
    WebLabel2.Top := 133;
    WebLabel2.Width := 78;
    WebLabel2.Height := 21;
    WebLabel2.Caption := 'New Status';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := 9010544;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(ContentPanel);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 19;
    WebLabel15.Top := 196;
    WebLabel15.Width := 81;
    WebLabel15.Height := 21;
    WebLabel15.Caption := 'Notification';
    WebLabel15.Font.Charset := ANSI_CHARSET;
    WebLabel15.Font.Color := 9010544;
    WebLabel15.Font.Height := -16;
    WebLabel15.Font.Name := 'Segoe UI';
    WebLabel15.Font.Style := [];
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ContentPanel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 19;
    WebLabel4.Top := 428;
    WebLabel4.Width := 74;
    WebLabel4.Height := 21;
    WebLabel4.Caption := 'Your Email';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := 9010544;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(ContentPanel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 19;
    WebLabel5.Top := 489;
    WebLabel5.Width := 20;
    WebLabel5.Height := 21;
    WebLabel5.Caption := 'CC';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := 9010544;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Segoe UI';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    AssetIDEdit.SetParentComponent(ContentPanel);
    AssetIDEdit.Name := 'AssetIDEdit';
    AssetIDEdit.Left := 19;
    AssetIDEdit.Top := 32;
    AssetIDEdit.Width := 333;
    AssetIDEdit.Height := 32;
    AssetIDEdit.CharCase := wecUpperCase;
    AssetIDEdit.ChildOrder := 1;
    AssetIDEdit.ElementClassName := 'MyWebEdit';
    AssetIDEdit.Enabled := False;
    AssetIDEdit.Font.Charset := ANSI_CHARSET;
    AssetIDEdit.Font.Color := clFuchsia;
    AssetIDEdit.Font.Height := -16;
    AssetIDEdit.Font.Name := 'Segoe UI';
    AssetIDEdit.Font.Style := [];
    AssetIDEdit.HeightPercent := 100.000000000000000000;
    AssetIDEdit.ParentFont := False;
    AssetIDEdit.WidthPercent := 100.000000000000000000;
    AssetStatusOldEdit.SetParentComponent(ContentPanel);
    AssetStatusOldEdit.Name := 'AssetStatusOldEdit';
    AssetStatusOldEdit.Left := 19;
    AssetStatusOldEdit.Top := 95;
    AssetStatusOldEdit.Width := 333;
    AssetStatusOldEdit.Height := 32;
    AssetStatusOldEdit.CharCase := wecUpperCase;
    AssetStatusOldEdit.ChildOrder := 1;
    AssetStatusOldEdit.ElementClassName := 'MyWebEdit';
    AssetStatusOldEdit.Enabled := False;
    AssetStatusOldEdit.Font.Charset := ANSI_CHARSET;
    AssetStatusOldEdit.Font.Color := clFuchsia;
    AssetStatusOldEdit.Font.Height := -16;
    AssetStatusOldEdit.Font.Name := 'Segoe UI';
    AssetStatusOldEdit.Font.Style := [];
    AssetStatusOldEdit.HeightPercent := 100.000000000000000000;
    AssetStatusOldEdit.ParentFont := False;
    AssetStatusOldEdit.WidthPercent := 100.000000000000000000;
    AssetStatusNewEdit.SetParentComponent(ContentPanel);
    AssetStatusNewEdit.Name := 'AssetStatusNewEdit';
    AssetStatusNewEdit.AlignWithMargins := True;
    AssetStatusNewEdit.Left := 19;
    AssetStatusNewEdit.Top := 157;
    AssetStatusNewEdit.Width := 333;
    AssetStatusNewEdit.Height := 34;
    AssetStatusNewEdit.Margins.Left := 20;
    AssetStatusNewEdit.Margins.Top := 8;
    AssetStatusNewEdit.Margins.Bottom := 8;
    AssetStatusNewEdit.Color := clWhite;
    AssetStatusNewEdit.ElementClassName := 'myselect_white';
    AssetStatusNewEdit.Font.Charset := DEFAULT_CHARSET;
    AssetStatusNewEdit.Font.Color := clWindowText;
    AssetStatusNewEdit.Font.Height := 12;
    AssetStatusNewEdit.Font.Name := 'Segoe UI';
    AssetStatusNewEdit.Font.Style := [];
    AssetStatusNewEdit.HeightPercent := 100.000000000000000000;
    AssetStatusNewEdit.ParentFont := False;
    AssetStatusNewEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AssetStatusNewEdit, Self, 'OnChange', 'AssetStatusNewEditChange');
    AssetStatusNewEdit.ItemIndex := -1;
    EmailBodyEdit.SetParentComponent(ContentPanel);
    EmailBodyEdit.Name := 'EmailBodyEdit';
    EmailBodyEdit.Left := 19;
    EmailBodyEdit.Top := 221;
    EmailBodyEdit.Width := 333;
    EmailBodyEdit.Height := 201;
    EmailBodyEdit.AutoSize := False;
    EmailBodyEdit.ElementClassName := 'MyWebEdit';
    EmailBodyEdit.HeightPercent := 100.000000000000000000;
    EmailBodyEdit.SelLength := 0;
    EmailBodyEdit.SelStart := 0;
    EmailBodyEdit.WidthPercent := 100.000000000000000000;
    EmailToEdit.SetParentComponent(ContentPanel);
    EmailToEdit.Name := 'EmailToEdit';
    EmailToEdit.Left := 19;
    EmailToEdit.Top := 451;
    EmailToEdit.Width := 333;
    EmailToEdit.Height := 32;
    EmailToEdit.CharCase := wecUpperCase;
    EmailToEdit.ChildOrder := 1;
    EmailToEdit.ElementClassName := 'MyWebEdit';
    EmailToEdit.Font.Charset := ANSI_CHARSET;
    EmailToEdit.Font.Color := clFuchsia;
    EmailToEdit.Font.Height := -16;
    EmailToEdit.Font.Name := 'Segoe UI';
    EmailToEdit.Font.Style := [];
    EmailToEdit.HeightPercent := 100.000000000000000000;
    EmailToEdit.ParentFont := False;
    EmailToEdit.WidthPercent := 100.000000000000000000;
    EmailCCEdit.SetParentComponent(ContentPanel);
    EmailCCEdit.Name := 'EmailCCEdit';
    EmailCCEdit.Left := 19;
    EmailCCEdit.Top := 512;
    EmailCCEdit.Width := 333;
    EmailCCEdit.Height := 32;
    EmailCCEdit.CharCase := wecUpperCase;
    EmailCCEdit.ChildOrder := 1;
    EmailCCEdit.ElementClassName := 'MyWebEdit';
    EmailCCEdit.Font.Charset := ANSI_CHARSET;
    EmailCCEdit.Font.Color := clFuchsia;
    EmailCCEdit.Font.Height := -16;
    EmailCCEdit.Font.Name := 'Segoe UI';
    EmailCCEdit.Font.Style := [];
    EmailCCEdit.HeightPercent := 100.000000000000000000;
    EmailCCEdit.ParentFont := False;
    EmailCCEdit.WidthPercent := 100.000000000000000000;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 555;
    StatusBarPanel.Width := 372;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 252;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uAssetStatusChange.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 137;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uAssetStatusChange.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 378;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 155;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Asset Status - Change';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 349;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uAssetStatusChange.CaptionPanel.CloseImage.Picture.png');
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    AssetIDEdit.AfterLoadDFMValues;
    AssetStatusOldEdit.AfterLoadDFMValues;
    AssetStatusNewEdit.AfterLoadDFMValues;
    EmailBodyEdit.AfterLoadDFMValues;
    EmailToEdit.AfterLoadDFMValues;
    EmailCCEdit.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
  end;
end;

end.
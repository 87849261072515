unit uSecurityGroupEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, WEBLib.CDS,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, VCL.TMSFNCCustomControl,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCCustomComponent, VCL.TMSFNCGridDatabaseAdapter,
  Vcl.Controls, WEBLIB.REST, WEBLIB.JSON, WEBLib.Buttons, Vcl.Imaging.GIFImg;

const
  security_item =
    'alerts,' +
    'locations,' +
    'geofences, ' +
    'poi,' +
    'reports,' +
    'reports_mailto_route,' +
    'reports_mailto_asset,' +
    'reports_mailto_driver,' +
    'reports_mailto_mot,' +
    'reports_mailto_device,' +
    'reports_mailto_alert,' +
    'distanceruler,' +
    'showaddress,' +
    'assets,' +
    'assetdevice,' +
    'assetdriver,' +
    'assetstatus,' +
    'devices,' +
    'users,' +
    'authorization,' +
    'generalsettings';
  security_name =
    'Alerts,' +
    'Locations,' +
    'Geofences,' +
    'Point of Interest,' +
    'Reports,' +
    'Report schedule to routes,' +
    'Report schedule to assets,' +
    'Report schedule to drivers,' +
    'Report schedule to mot,' +
    'Report schedule to devices,' +
    'Report schedule to alerts,' +
    'Distance Ruler,' +
    'Show Address,' +
    'Assets,' +
    'Change Asset Device,' +
    'Change Asset Driver,' +
    'Change Asset Status,' +
    'Devices,' +
    'Users,' +
    'Authorization,' +
    'General Settings';

type
  TSecurityGroupEditForm = class(TForm)
    FormPanel: TPanel;
    ContentPanel: TPanel;
    TopPanel: TPanel;
    UsernameLabel: TLabel;
    NameEdit: TEdit;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    MasterGrid: TTMSFNCGrid;
    StatusBarPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    MasterDataset: TClientDataSet;
    MasterSource: TDataSource;
    LoadTimer: TTimer;
    ShowTimer: TTimer;
    SaveButton: TSpeedButton;
    CloseButton: TSpeedButton;
    WebWaitMessage: TWaitMessage;
    MasterDatasetid: TStringField;
    MasterDatasetcheckbox_view: TBooleanField;
    MasterDatasetcheckbox_edit: TBooleanField;
    MasterDatasetcheckbox_delete: TBooleanField;
    MasterDatasetno: TIntegerField;
    WebLabel1: TLabel;
    TypeEdit: TLookupComboBox;
    MyMessageDlg: TMessageDlg;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    procedure CloseImageClick(Sender: TObject);
    procedure LoadTimerTimer(Sender: TObject);
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    [async]
    procedure CloseButtonClick(Sender: TObject); async;
    procedure MasterGridCellClick(Sender: TObject; ACol, ARow: Integer);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure RecordChange(Sender: TObject);
    procedure MasterDatasetAfterEdit(DataSet: TDataSet);
  private
    { Private declarations }
    RecordChanged: Boolean;
    StringListItem, StringListName: TStringList;
    [async]
    procedure LoadData(); async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
    function ValidInput(var ErrorMessage: String): Boolean;
  public
    { Public declarations }
    PKey: Integer;
    IsSet: Boolean;
  protected procedure LoadDFMValues; override; end;

var
  SecurityGroupEditForm: TSecurityGroupEditForm;

implementation

{$R *.dfm}

uses uCommon;

procedure TSecurityGroupEditForm.SaveButtonClick(Sender: TObject);
var
  ErrorMessage: String;
begin
  if ValidInput(ErrorMessage) then begin
    if await(SaveData(ErrorMessage)) then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
    end;
  end else begin
    MessageDlg(ErrorMessage, WEBLib.Dialogs.mtError, [mbOK], nil);
  end;
end;

procedure TSecurityGroupEditForm.ShowTimerTimer(Sender: TObject);
begin
  if IsSet then begin
    ShowTimer.Enabled := False;
    // Set Focus
    if PKey = -1 then begin
      NameEdit.SetFocus;
    end else begin
      MasterGrid.SetFocus;
    end;
    Await(LoadData());
    RecordChanged := False;
  end;
end;

procedure TSecurityGroupEditForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  StringListItem.Free;
  StringListName.Free;
end;

procedure TSecurityGroupEditForm.WebFormCreate(Sender: TObject);
begin
  TypeEdit.Value := '0';
  SetGridStyle(MasterGrid, False);
  // Security Items
  StringListItem := TStringList.Create();
  StringListItem.StrictDelimiter := True;
  StringListItem.DelimitedText := security_item;
  // Security Names
  StringListName := TStringList.Create();
  StringListName.StrictDelimiter := True;
  StringListName.DelimitedText := security_name;
end;

procedure TSecurityGroupEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TSecurityGroupEditForm.CloseButtonClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      ModalResult := mrCancel;
    end;
  end else begin
    ModalResult := mrCancel;
  end;
end;

procedure TSecurityGroupEditForm.CloseImageClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TSecurityGroupEditForm.MasterDatasetAfterEdit(DataSet: TDataSet);
begin
  RecordChanged := True;
end;

procedure TSecurityGroupEditForm.MasterGridCellClick(Sender: TObject; ACol,
  ARow: Integer);
begin
  if MasterDataset.Active and not(MasterDataset.IsEmpty) then begin
    if  ACol in [1,2,3] then begin
      MasterDataset.Edit;
      case ACol of
        1: begin
          MasterDataset.FieldByName('checkbox_view').AsBoolean :=
            not(MasterDataset.FieldByName('checkbox_view').AsBoolean);
        end;
        2: begin
          MasterDataset.FieldByName('checkbox_edit').AsBoolean :=
            not(MasterDataset.FieldByName('checkbox_edit').AsBoolean);
        end;
        3: begin
          MasterDataset.FieldByName('checkbox_delete').AsBoolean :=
            not(MasterDataset.FieldByName('checkbox_delete').AsBoolean);
        end;
      end;
      MasterDataset.Post;
    end;
  end;
end;

procedure TSecurityGroupEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TSecurityGroupEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyPermissions: String;
  MyPermission: Integer;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  I: Integer;
begin
  if PKey >= 0 then begin
    MyWebRequest := THTTPRequest.Create(Self);
    try
      MyWebRequest.URL :=
        url_php + 'default_security_group_load.php?' +
        'sessionid=' + SessionId + '&pkey=' + IntToStr(PKey);
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      // Success
      if MyRequest.Status = 200 then begin
        // Get Values
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            NameEdit.Text := MyJSONObject.GetJSONValue('name');
            TypeEdit.Value := MyJSONObject.GetJSONValue('type');
            MyPermissions := TJSONPair(MyJSONObject.Get('permissions')).JsonValue.ToString;
          end else begin
            MessageDlg(MyJSONObject.GetJSONValue('error_message'), TMsgDlgType.mtError, [mbOK]);
          end;
        finally
          MyJSON.Free;
        end;
      end;
    finally
      MyWebRequest.Free;
    end;
  end else begin
    MyPermissions := '{}';
  end;
  // Open Dataset
  await(MasterDataset.Open);
  for I := 0 to StringListItem.Count-1 do begin
    MyPermission := GetSecurityPermissionValue(MyPermissions, StringListItem[I]);
    MasterDataset.Append;
    MasterDataset.FieldByName('no').AsInteger := I;
    MasterDataset.FieldByName('id').AsString := '  ' + StringListName[I];
    MasterDataset.FieldByName('checkbox_view').AsBoolean := MyPermission in [1,3,5,7];
    MasterDataset.FieldByName('checkbox_edit').AsBoolean := MyPermission in [2,3,6,7];
    MasterDataset.FieldByName('checkbox_delete').AsBoolean := MyPermission in [4,5,6,7];
    MasterDataset.Post;
  end;
  GridDatabaseAdapter.Active := True;
  LoadTimer.Enabled := True;
end;


procedure TSecurityGroupEditForm.LoadTimerTimer(Sender: TObject);
begin
  LoadTimer.Enabled := False;
  if MasterDataset.Active then begin
    MasterDataset.First;
  end;
  WebWaitMessage.Hide;
end;

function TSecurityGroupEditForm.ValidInput(var ErrorMessage: String): Boolean;
begin
  Result := True;
  if Result and (Trim(NameEdit.Text) = EmptyStr) then begin
    Result := False;
    ErrorMessage := 'Name is a required field.';
    NameEdit.SetFocus();
  end;
  if Result and (StrToInt(TypeEdit.Value) = 0) then begin
    Result := False;
    ErrorMessage := 'Type is a required field';
    TypeEdit.SetFocus();
  end;
end;

function TSecurityGroupEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  I: Integer;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyPermissions: String;
  MyPermission: Integer;
  MyString: String;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MasterDataset.DisableControls;
  try
    // Permissions
    for I := 0 to StringListItem.Count-1 do begin
      MyPermission := 0;
      MasterDataset.RecNo := I+1;
      if MasterDataset.FieldByName('checkbox_view').AsBoolean = True then MyPermission := MyPermission + 1;
      if MasterDataset.FieldByName('checkbox_edit').AsBoolean = True then MyPermission := MyPermission + 2;
      if MasterDataset.FieldByName('checkbox_delete').AsBoolean = True then MyPermission := MyPermission + 4;
      if not(MyPermissions = EmptyStr) then MyPermissions := MyPermissions + ',';
      MyPermissions := MyPermissions +
        '"' + StringListItem[I] + '":"' + IntToStr(MyPermission) + '"'
    end;
    MyString :=
      '{' +
        '"sessionid" : "' + SessionID + '",' +
        '"pkey": "' + IntToStr(PKey) + '",' +
        '"name": "' + NameEdit.Text + '",' +
        '"type": "' + TypeEdit.Value + '",' +
        '"permissions": {' + MyPermissions + '}' +
      '}';
    // Save Changes
    MyWebRequest := THTTPRequest.Create(Self);
    try
      // Request
      MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
      MyWebRequest.URL := url_php + 'default_security_group_save.php';
      MyWebRequest.Command := THTTPCommand.httpPOST;
      MyWebRequest.PostData := MyString;
      MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
      if MyRequest.Status = 200 then begin
        // Response
        MyJSON := TJSON.Create;
        try
          MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
          if MyJSONObject.GetJSONValue('status') = 'OK' then begin
            PKey := StrToInt(MyJSONObject.GetJSONValue('pkey'));
            Result := True;
          end else begin
            ErrorMessage := MyJSONObject.GetJSONValue('error_message');
            Result := False;
          end;
        finally
          MyJSON.Free;
        end;
      end;
    finally
      MyWebRequest.Free;
    end;
  finally
    MasterDataset.EnableControls;
  end;
end;


procedure TSecurityGroupEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  ContentPanel := TPanel.Create(Self);
  TopPanel := TPanel.Create(Self);
  UsernameLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  NameEdit := TEdit.Create(Self);
  TypeEdit := TLookupComboBox.Create(Self);
  MasterGrid := TTMSFNCGrid.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  MasterDataset := TClientDataSet.Create(Self);
  MasterDatasetno := TIntegerField.Create(Self);
  MasterDatasetid := TStringField.Create(Self);
  MasterDatasetcheckbox_view := TBooleanField.Create(Self);
  MasterDatasetcheckbox_edit := TBooleanField.Create(Self);
  MasterDatasetcheckbox_delete := TBooleanField.Create(Self);
  MasterSource := TDataSource.Create(Self);
  LoadTimer := TTimer.Create(Self);
  ShowTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  UsernameLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  NameEdit.BeforeLoadDFMValues;
  TypeEdit.BeforeLoadDFMValues;
  MasterGrid.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  MasterDataset.BeforeLoadDFMValues;
  MasterDatasetno.BeforeLoadDFMValues;
  MasterDatasetid.BeforeLoadDFMValues;
  MasterDatasetcheckbox_view.BeforeLoadDFMValues;
  MasterDatasetcheckbox_edit.BeforeLoadDFMValues;
  MasterDatasetcheckbox_delete.BeforeLoadDFMValues;
  MasterSource.BeforeLoadDFMValues;
  LoadTimer.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  try
    Name := 'SecurityGroupEditForm';
    Width := 640;
    Height := 604;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 640;
    FormPanel.Height := 604;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 634;
    ContentPanel.Height := 561;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    TopPanel.SetParentComponent(ContentPanel);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 0;
    TopPanel.Width := 634;
    TopPanel.Height := 177;
    TopPanel.Align := alTop;
    TopPanel.BorderColor := clWhite;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 2;
    TopPanel.Color := clWhite;
    UsernameLabel.SetParentComponent(TopPanel);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 20;
    UsernameLabel.Top := 10;
    UsernameLabel.Width := 42;
    UsernameLabel.Height := 21;
    UsernameLabel.Caption := 'Name';
    UsernameLabel.Font.Charset := ANSI_CHARSET;
    UsernameLabel.Font.Color := 9010544;
    UsernameLabel.Font.Height := -16;
    UsernameLabel.Font.Name := 'Segoe UI';
    UsernameLabel.Font.Style := [];
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.ParentFont := False;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(TopPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 90;
    WebLabel1.Width := 32;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'Type';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    NameEdit.SetParentComponent(TopPanel);
    NameEdit.Name := 'NameEdit';
    NameEdit.Left := 20;
    NameEdit.Top := 40;
    NameEdit.Width := 594;
    NameEdit.Height := 34;
    NameEdit.ChildOrder := 1;
    NameEdit.ElementClassName := 'MyWebEdit';
    NameEdit.Font.Charset := ANSI_CHARSET;
    NameEdit.Font.Color := clWindowText;
    NameEdit.Font.Height := -16;
    NameEdit.Font.Name := 'Segoe UI';
    NameEdit.Font.Style := [];
    NameEdit.HeightPercent := 100.000000000000000000;
    NameEdit.ParentFont := False;
    NameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(NameEdit, Self, 'OnChange', 'RecordChange');
    TypeEdit.SetParentComponent(TopPanel);
    TypeEdit.Name := 'TypeEdit';
    TypeEdit.AlignWithMargins := True;
    TypeEdit.Left := 20;
    TypeEdit.Top := 119;
    TypeEdit.Width := 345;
    TypeEdit.Height := 34;
    TypeEdit.Margins.Left := 20;
    TypeEdit.Margins.Top := 8;
    TypeEdit.Margins.Bottom := 8;
    TypeEdit.Color := clWhite;
    TypeEdit.ElementClassName := 'myselect';
    TypeEdit.Font.Charset := DEFAULT_CHARSET;
    TypeEdit.Font.Color := clWindowText;
    TypeEdit.Font.Height := 12;
    TypeEdit.Font.Name := 'Segoe UI';
    TypeEdit.Font.Style := [];
    TypeEdit.HeightPercent := 100.000000000000000000;
    TypeEdit.ParentFont := False;
    TypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TypeEdit, Self, 'OnChange', 'RecordChange');
    TypeEdit.ItemIndex := -1;
    TypeEdit.LookupValues.Clear;
    with TypeEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'User';
    end;
    with TypeEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Manager';
    end;
    MasterGrid.SetParentComponent(ContentPanel);
    MasterGrid.Name := 'MasterGrid';
    MasterGrid.AlignWithMargins := True;
    MasterGrid.Left := 20;
    MasterGrid.Top := 177;
    MasterGrid.Width := 594;
    MasterGrid.Height := 325;
    MasterGrid.Margins.Left := 20;
    MasterGrid.Margins.Top := 0;
    MasterGrid.Margins.Right := 20;
    MasterGrid.Margins.Bottom := 10;
    MasterGrid.AdaptToStyle := True;
    MasterGrid.Align := alClient;
    MasterGrid.BevelInner := bvNone;
    MasterGrid.BevelOuter := bvNone;
    MasterGrid.BevelWidth := 2;
    MasterGrid.Ctl3D := False;
    MasterGrid.ParentCtl3D := False;
    MasterGrid.ParentDoubleBuffered := False;
    MasterGrid.DoubleBuffered := True;
    MasterGrid.Font.Charset := ANSI_CHARSET;
    MasterGrid.Font.Color := clWindowText;
    MasterGrid.Font.Height := -16;
    MasterGrid.Font.Name := 'Segoe UI';
    MasterGrid.Font.Style := [];
    MasterGrid.ParentFont := False;
    MasterGrid.TabOrder := 1;
    MasterGrid.DefaultRowHeight := 34.000000000000000000;
    MasterGrid.FixedColumns := 0;
    MasterGrid.ColumnCount := 4;
    MasterGrid.RowCount := 1;
    MasterGrid.Options.Borders.FixedCellBorders := bNone;
    MasterGrid.Options.ColumnSize.Stretch := True;
    MasterGrid.Options.ColumnSize.StretchColumn := 0;
    MasterGrid.Options.Editing.CalcFormat := '%g';
    MasterGrid.Options.Grouping.CalcFormat := '%g';
    MasterGrid.Options.Grouping.GroupCountFormat := '(%d)';
    MasterGrid.Options.HTMLExport.CellPadding := 10;
    MasterGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    MasterGrid.Options.Mouse.WheelScrollKeepSelection := True;
    MasterGrid.Options.Mouse.ClickMargin := 0;
    MasterGrid.Options.Mouse.ColumnSizeMargin := 6;
    MasterGrid.Options.Mouse.RowSizeMargin := 6;
    MasterGrid.Options.URL.Color := clSkyBlue;
    MasterGrid.Options.ScrollBar.HorizontalScrollBarVisible := False;
    MasterGrid.Options.Selection.Mode := smSingleRow;
    MasterGrid.Adapter := GridDatabaseAdapter;
    MasterGrid.HorizontalScrollBarVisible := False;
    MasterGrid.Columns.Clear;
    with MasterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 413.000000000000000000;
    end;
    with MasterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 60.000000000000000000;
    end;
    with MasterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 60.000000000000000000;
    end;
    with MasterGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := ANSI_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -16;
      FixedFont.Name := 'Segoe UI';
      FixedFont.Style := [];
      Font.Charset := ANSI_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -16;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      ID := '';
      Width := 60.000000000000000000;
    end;
    MasterGrid.Fill.ColorTo := clSilver;
    MasterGrid.DefaultFont.Charset := ANSI_CHARSET;
    MasterGrid.DefaultFont.Color := clWindowText;
    MasterGrid.DefaultFont.Height := -15;
    MasterGrid.DefaultFont.Name := 'Segoe UI';
    MasterGrid.DefaultFont.Style := [];
    MasterGrid.Stroke.Color := 15987699;
    MasterGrid.Stroke.Width := 2.000000000000000000;
    MasterGrid.TopRow := 1;
    MasterGrid.Appearance.FixedLayout.Fill.Kind := gfkGradient;
    MasterGrid.Appearance.FixedLayout.Fill.Color := 12498604;
    MasterGrid.Appearance.FixedLayout.Fill.ColorTo := clCream;
    MasterGrid.Appearance.FixedLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.FixedLayout.Font.Charset := ANSI_CHARSET;
    MasterGrid.Appearance.FixedLayout.Font.Color := clWhite;
    MasterGrid.Appearance.FixedLayout.Font.Height := -16;
    MasterGrid.Appearance.FixedLayout.Font.Name := 'Segoe UI';
    MasterGrid.Appearance.FixedLayout.Font.Style := [];
    MasterGrid.Appearance.NormalLayout.Fill.Color := -1;
    MasterGrid.Appearance.NormalLayout.Fill.ColorTo := clSilver;
    MasterGrid.Appearance.NormalLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.NormalLayout.Font.Charset := ANSI_CHARSET;
    MasterGrid.Appearance.NormalLayout.Font.Color := clGray;
    MasterGrid.Appearance.NormalLayout.Font.Height := -11;
    MasterGrid.Appearance.NormalLayout.Font.Name := 'Segoe UI';
    MasterGrid.Appearance.NormalLayout.Font.Style := [];
    MasterGrid.Appearance.GroupLayout.Fill.Kind := gfkGradient;
    MasterGrid.Appearance.GroupLayout.Fill.Color := 12817262;
    MasterGrid.Appearance.GroupLayout.Fill.ColorTo := 15385233;
    MasterGrid.Appearance.GroupLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    MasterGrid.Appearance.GroupLayout.Font.Color := clBlack;
    MasterGrid.Appearance.GroupLayout.Font.Height := -11;
    MasterGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    MasterGrid.Appearance.GroupLayout.Font.Style := [];
    MasterGrid.Appearance.SummaryLayout.Fill.Kind := gfkGradient;
    MasterGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    MasterGrid.Appearance.SummaryLayout.Fill.ColorTo := 15385233;
    MasterGrid.Appearance.SummaryLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    MasterGrid.Appearance.SummaryLayout.Font.Color := clBlack;
    MasterGrid.Appearance.SummaryLayout.Font.Height := -11;
    MasterGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    MasterGrid.Appearance.SummaryLayout.Font.Style := [];
    MasterGrid.Appearance.SelectedLayout.Fill.Kind := gfkGradient;
    MasterGrid.Appearance.SelectedLayout.Fill.Color := 14062646;
    MasterGrid.Appearance.SelectedLayout.Fill.ColorTo := 5920859;
    MasterGrid.Appearance.SelectedLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.SelectedLayout.Font.Charset := ANSI_CHARSET;
    MasterGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    MasterGrid.Appearance.SelectedLayout.Font.Height := -16;
    MasterGrid.Appearance.SelectedLayout.Font.Name := 'Segoe UI';
    MasterGrid.Appearance.SelectedLayout.Font.Style := [];
    MasterGrid.Appearance.FocusedLayout.Fill.Kind := gfkGradient;
    MasterGrid.Appearance.FocusedLayout.Fill.Color := 14062646;
    MasterGrid.Appearance.FocusedLayout.Fill.ColorTo := 15385233;
    MasterGrid.Appearance.FocusedLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.FocusedLayout.Font.Charset := ANSI_CHARSET;
    MasterGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    MasterGrid.Appearance.FocusedLayout.Font.Height := -16;
    MasterGrid.Appearance.FocusedLayout.Font.Name := 'Segoe UI';
    MasterGrid.Appearance.FocusedLayout.Font.Style := [];
    MasterGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkGradient;
    MasterGrid.Appearance.FixedSelectedLayout.Fill.Color := 14062646;
    MasterGrid.Appearance.FixedSelectedLayout.Fill.ColorTo := clCream;
    MasterGrid.Appearance.FixedSelectedLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.FixedSelectedLayout.Font.Charset := ANSI_CHARSET;
    MasterGrid.Appearance.FixedSelectedLayout.Font.Color := clBlack;
    MasterGrid.Appearance.FixedSelectedLayout.Font.Height := -16;
    MasterGrid.Appearance.FixedSelectedLayout.Font.Name := 'Segoe UI';
    MasterGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    MasterGrid.Appearance.BandLayout.Fill.Color := 14062646;
    MasterGrid.Appearance.BandLayout.Fill.ColorTo := 14062646;
    MasterGrid.Appearance.BandLayout.Stroke.Color := 15987699;
    MasterGrid.Appearance.BandLayout.Font.Charset := ANSI_CHARSET;
    MasterGrid.Appearance.BandLayout.Font.Color := clBlack;
    MasterGrid.Appearance.BandLayout.Font.Height := -16;
    MasterGrid.Appearance.BandLayout.Font.Name := 'Segoe UI Semibold';
    MasterGrid.Appearance.BandLayout.Font.Style := [];
    MasterGrid.Appearance.ProgressLayout.Color := 15385233;
    MasterGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    MasterGrid.LeftCol := 0;
    MasterGrid.ScrollMode := scmItemScrolling;
    MasterGrid.GlobalFont.Scale := 1.000000000000000000;
    MasterGrid.GlobalFont.Style := [];
    MasterGrid.DesignTimeSampleData := True;
    SetEvent(MasterGrid, Self, 'OnCellClick', 'MasterGridCellClick');
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 512;
    StatusBarPanel.Width := 634;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 3;
    StatusBarPanel.Color := clWhite;
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 399;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uSecurityGroupEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 0;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 514;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uSecurityGroupEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 1;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 640;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 165;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Authorization Set - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 611;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uSecurityGroupEdit.CaptionPanel.CloseImage.Picture.png');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 582;
    WebWaitMessage.Top := 64;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uSecurityGroupEdit.WebWaitMessage.Picture.gif');
    GridDatabaseAdapter.SetParentComponent(Self);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 577;
    GridDatabaseAdapter.Top := 263;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Grid := MasterGrid;
    GridDatabaseAdapter.DataSource := MasterSource;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'id';
      Header := '  Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'checkbox_view';
      Header := 'View';
      CheckBoxField := True;
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'checkbox_edit';
      Header := 'Edit';
      CheckBoxField := True;
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'checkbox_delete';
      Header := 'Delete';
      CheckBoxField := True;
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 579;
    MyMessageDlg.Top := 454;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    MasterDataset.SetParentComponent(Self);
    MasterDataset.Name := 'MasterDataset';
    MasterDataset.AfterEdit := MasterDatasetAfterEdit;
    MasterDataset.Left := 580;
    MasterDataset.Top := 104;
    MasterDatasetno.SetParentComponent(MasterDataset);
    MasterDatasetno.Name := 'MasterDatasetno';
    MasterDatasetno.FieldName := 'no';
    MasterDatasetid.SetParentComponent(MasterDataset);
    MasterDatasetid.Name := 'MasterDatasetid';
    MasterDatasetid.FieldName := 'id';
    MasterDatasetid.Size := 40;
    MasterDatasetcheckbox_view.SetParentComponent(MasterDataset);
    MasterDatasetcheckbox_view.Name := 'MasterDatasetcheckbox_view';
    MasterDatasetcheckbox_view.FieldName := 'checkbox_view';
    MasterDatasetcheckbox_edit.SetParentComponent(MasterDataset);
    MasterDatasetcheckbox_edit.Name := 'MasterDatasetcheckbox_edit';
    MasterDatasetcheckbox_edit.FieldName := 'checkbox_edit';
    MasterDatasetcheckbox_delete.SetParentComponent(MasterDataset);
    MasterDatasetcheckbox_delete.Name := 'MasterDatasetcheckbox_delete';
    MasterDatasetcheckbox_delete.FieldName := 'checkbox_delete';
    MasterSource.SetParentComponent(Self);
    MasterSource.Name := 'MasterSource';
    MasterSource.DataSet := MasterDataset;
    MasterSource.Left := 580;
    MasterSource.Top := 176;
    LoadTimer.SetParentComponent(Self);
    LoadTimer.Name := 'LoadTimer';
    LoadTimer.Enabled := False;
    LoadTimer.Interval := 50;
    SetEvent(LoadTimer, Self, 'OnTimer', 'LoadTimerTimer');
    LoadTimer.Left := 579;
    LoadTimer.Top := 392;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 579;
    ShowTimer.Top := 336;
  finally
    FormPanel.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    UsernameLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    NameEdit.AfterLoadDFMValues;
    TypeEdit.AfterLoadDFMValues;
    MasterGrid.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    MasterDataset.AfterLoadDFMValues;
    MasterDatasetno.AfterLoadDFMValues;
    MasterDatasetid.AfterLoadDFMValues;
    MasterDatasetcheckbox_view.AfterLoadDFMValues;
    MasterDatasetcheckbox_edit.AfterLoadDFMValues;
    MasterDatasetcheckbox_delete.AfterLoadDFMValues;
    MasterSource.AfterLoadDFMValues;
    LoadTimer.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
  end;
end;

end.
{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDigitalTimePicker;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, Types,
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCDigitalTimeSelector, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils, WEBLib.StdCtrls,
  WEBLib.TMSFNCGraphicsTypes;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 3; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New : Property AllowNumericNullValue added
  //         : New : Method Clear added
  //         : Fixed : Issue with OnTimeSelected event
  //v1.0.1.1 : Fixed : Issue with setting the SelectedTime to 00:00:00
  //v1.0.1.2 : Fixed : Issue with control color in disabled state
  //v1.0.1.3 : Fixed : Issue with Column and Row property persistence
  //v1.0.1.4 : Improved : Exposed Font property
  //v1.0.2.0 : New : TimeSelector property added
  //         : Improved : Exposed OnItemBeforeDrawBackground, OnItemAfterDrawBackground, OnItemBeforeDrawContent, OnItemAfterDrawContent, OnItemBeforeDrawText and OnItemAfterDrawText events
  //v1.0.3.0 : New : Support for high dpi

type
  TTMSFNCCustomDigitalTimePicker = class(TTMSFNCDefaultPicker)
  private
    FPrevSelTime: TTime;
    FCloseOnSelection: Boolean;
    FTimeSelector: TTMSFNCDigitalTimeSelector;
    FOnTimeDeselected: TTMSFNCCustomDigitalTimeSelectorTimeDeselected;
    FOnTimeSelected: TTMSFNCCustomDigitalTimeSelectorTimeSelected;
    FOnAfterDrawArrow: TTMSFNCCustomDigitalTimeSelectorAfterDrawArrowEvent;
    FOnBeforeDrawHeader: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderEvent;
    FOnAfterDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderTextEvent;
    FOnBeforeDrawArrow: TTMSFNCCustomDigitalTimeSelectorBeforeDrawArrowEvent;
    FOnAfterDrawHeader: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderEvent;
    FOnBeforeDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderTextEvent;
    FAllowNumericNullValue: Boolean;
    FFont: TTMSFNCGraphicsFont;
    FOnItemBeforeDrawText: TTMSFNCCustomSelectorItemBeforeDrawText;
    FOnItemBeforeDrawContent: TTMSFNCCustomSelectorItemBeforeDrawContent;
    FOnItemAfterDrawBackground: TTMSFNCCustomSelectorItemAfterDrawBackground;
    FOnItemAfterDrawText: TTMSFNCCustomSelectorItemAfterDrawText;
    FOnItemAfterDrawContent: TTMSFNCCustomSelectorItemAfterDrawContent;
    FOnItemBeforeDrawBackground: TTMSFNCCustomSelectorItemBeforeDrawBackground;
    procedure SetHeaderSize(const Value: Integer);
    procedure SetItems(const Value: TTMSFNCDigitalTimeSelectorItems);
    procedure SetSelectedTime(const Value: TTime);
    procedure SetEndTime(const Value: TTime);
    procedure SetIntervalUnit(const Value: TTMSFNCDigitalTimeSelectorIntervalUnit);
    procedure SetStartTime(const Value: TTime);
    procedure SetTimeFormat(const Value: string);
    procedure SetTimeInterval(const Value: Integer);
    procedure SetAllowNumericNullValue(const Value: Boolean);
    function GetItems: TTMSFNCDigitalTimeSelectorItems;
    function GetSelectedTime: TTime;
    function GetEndTime: TTime;
    function GetIntervalUnit: TTMSFNCDigitalTimeSelectorIntervalUnit;
    function GetStartTime: TTime;
    function GetTimeFormat: string;
    function GetTimeInterval: Integer;
    function IsTimeFormatStored: Boolean;
    function IsIntervalUnitStored: Boolean;
    function GetHeaderSize: Integer;
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
  protected
    procedure Loaded; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure FontChange(Sender: TObject);
    procedure SetEditable(const Value: Boolean); override;
    procedure UpdateEditText(AText: string); override;
    procedure InitializePopup; override;
    procedure DoTimeSelected; virtual;
    procedure SetGraphicColors; override;
    procedure TimeSelected(Sender: TObject; ATime: TTime);
    procedure TimeDeselected(Sender: TObject; ATime: TTime);
    procedure DoBeforeDrawHeader(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeader(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF); virtual;
    procedure DoBeforeDrawHeaderText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeaderText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string); virtual;
    procedure DoBeforeDrawArrow(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawArrow(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean); virtual;
    procedure DoBeforeDrawItemBackground(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawItemBackground(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer); virtual;
    procedure DoBeforeDrawItemContent(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawItemContent(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer); virtual;
    procedure DoBeforeDrawItemText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var AText: string; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawItemText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; AText: string); virtual;
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    procedure EditUp; override;
    procedure EditDown; override;
    function GetVersion: string; override;
    function CreateSelector: TTMSFNCCustomSelector; override;
    property AllowNumericNullValue: Boolean read FAllowNumericNullValue write SetAllowNumericNullValue default True;
    property SelectedTime: TTime read GetSelectedTime write SetSelectedTime;
    property Items: TTMSFNCDigitalTimeSelectorItems read GetItems write SetItems;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default True;
    property TimeFormat: string read GetTimeFormat write SetTimeFormat stored IsTimeFormatStored nodefault;
    property TimeInterval: Integer read GetTimeInterval write SetTimeInterval default 5;
    property IntervalUnit: TTMSFNCDigitalTimeSelectorIntervalUnit read GetIntervalUnit write SetIntervalUnit stored IsIntervalUnitStored nodefault;
    property StartTime: TTime read GetStartTime write SetStartTime;
    property EndTime: TTime read GetEndTime write SetEndTime;
    property HeaderSize: Integer read GetHeaderSize write SetHeaderSize;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property OnTimeSelected: TTMSFNCCustomDigitalTimeSelectorTimeSelected read FOnTimeSelected write FOnTimeSelected;
    property OnTimeDeselected: TTMSFNCCustomDigitalTimeSelectorTimeDeselected read FOnTimeDeselected write FOnTimeDeselected;
    property OnBeforeDrawHeader: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderEvent read FOnBeforeDrawHeader write FOnBeforeDrawHeader;
    property OnAfterDrawHeader: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderEvent read FOnAfterDrawHeader write FOnAfterDrawHeader;
    property OnBeforeDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderTextEvent read FOnBeforeDrawHeaderText write FOnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderTextEvent read FOnAfterDrawHeaderText write FOnAfterDrawHeaderText;
    property OnBeforeDrawArrow: TTMSFNCCustomDigitalTimeSelectorBeforeDrawArrowEvent read FOnBeforeDrawArrow write FOnBeforeDrawArrow;
    property OnAfterDrawArrow: TTMSFNCCustomDigitalTimeSelectorAfterDrawArrowEvent read FOnAfterDrawArrow write FOnAfterDrawArrow;
    property OnItemBeforeDrawBackground: TTMSFNCCustomSelectorItemBeforeDrawBackground read FOnItemBeforeDrawBackground write FOnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground: TTMSFNCCustomSelectorItemAfterDrawBackground read FOnItemAfterDrawBackground write FOnItemAfterDrawBackground;
    property OnItemBeforeDrawContent: TTMSFNCCustomSelectorItemBeforeDrawContent read FOnItemBeforeDrawContent write FOnItemBeforeDrawContent;
    property OnItemAfterDrawContent: TTMSFNCCustomSelectorItemAfterDrawContent read FOnItemAfterDrawContent write FOnItemAfterDrawContent;
    property OnItemBeforeDrawText: TTMSFNCCustomSelectorItemBeforeDrawText read FOnItemBeforeDrawText write FOnItemBeforeDrawText;
    property OnItemAfterDrawText: TTMSFNCCustomSelectorItemAfterDrawText read FOnItemAfterDrawText write FOnItemAfterDrawText;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Clear;
    property TimeSelector: TTMSFNCDigitalTimeSelector read FTimeSelector;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCDigitalTimePicker = class(TTMSFNCCustomDigitalTimePicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property AllowNumericNullValue;
    property Editable;
    property DropDownWidth;
    property DropDownHeight;
    property Appearance;
    property Rows default 4;
    property Columns default 3;
    property CloseOnSelection;
    property Font;
    property SelectedTime;
    property TimeFormat;
    property TimeInterval;
    property IntervalUnit;
    property StartTime;
    property EndTime;
    property HeaderSize;
    property OnTimeSelected;
    property OnTimeDeselected;
    property OnBeforeDrawHeader;
    property OnAfterDrawHeader;
    property OnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText;
    property OnBeforeDrawArrow;
    property OnAfterDrawArrow;
  end;

implementation

uses
  Math, DateUtils, SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Edit
  {$ENDIF}
  {$IFDEF VCLLIB}
  , VCL.Graphics
  {$ENDIF}
  ;

type
  TCustomEditProtected = class(TCustomEdit);
  TTMSFNCDigitalTimeSelectorOpen = class(TTMSFNCDigitalTimeSelector);

{ TTMSFNCCustomDigitalTimePicker }

procedure TTMSFNCCustomDigitalTimePicker.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF (COMPILERVERSION >= 33) and (COMPILERVERSION < 35) }
  if Assigned(FTimeSelector) then
    FTimeSelector.ScaleForPPI(M);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}

  Font.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M, D);
  {$IFDEF CMNLIB}
  Edit.Font.Height := FFont.Height;
  {$ENDIF}

  EndUpdate;
end;

procedure TTMSFNCCustomDigitalTimePicker.Clear;
begin
  SelectedTime := 0.0;
end;

constructor TTMSFNCCustomDigitalTimePicker.Create(AOwner: TComponent);
begin
  inherited;
  Width := 90;
  FAllowNumericNullValue := True;
  DropDownWidth := 220;
  DropDownHeight := 150;
  FCloseOnSelection := True;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChange;
  {$IFDEF FMXLIB}
  Edit.StyledSettings := TCustomEditProtected(Edit).StyledSettings - [TStyledSetting.Family, TStyledSetting.Size, TStyledSetting.Style];
  {$IFDEF ANDROID}
  Edit.TextSettings.VertAlign := TTextAlign.Leading;
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCCustomDigitalTimePicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FTimeSelector := TTMSFNCDigitalTimeSelector.Create(Self);
  FTimeSelector.OnTimeSelected := TimeSelected;
  FTimeSelector.OnTimeDeselected := TimeDeselected;
  FTimeSelector.OnBeforeDrawHeader := DoBeforeDrawHeader;
  FTimeSelector.OnBeforeDrawHeaderText := DoBeforeDrawHeaderText;
  FTimeSelector.OnBeforeDrawArrow := DoBeforeDrawArrow;
  FTimeSelector.OnAfterDrawHeader := DoAfterDrawHeader;
  FTimeSelector.OnAfterDrawHeaderText := DoAfterDrawHeaderText;
  FTimeSelector.OnAfterDrawArrow := DoAfterDrawArrow;
  TTMSFNCDigitalTimeSelectorOpen(FTimeSelector).OnItemBeforeDrawBackground := DoBeforeDrawItemBackground;
  TTMSFNCDigitalTimeSelectorOpen(FTimeSelector).OnItemAfterDrawBackground := DoAfterDrawItemBackground;
  TTMSFNCDigitalTimeSelectorOpen(FTimeSelector).OnItemBeforeDrawContent := DoBeforeDrawItemContent;
  TTMSFNCDigitalTimeSelectorOpen(FTimeSelector).OnItemAfterDrawContent := DoAfterDrawItemContent;
  TTMSFNCDigitalTimeSelectorOpen(FTimeSelector).OnItemBeforeDrawText := DoBeforeDrawItemText;
  TTMSFNCDigitalTimeSelectorOpen(FTimeSelector).OnItemAfterDrawText := DoAfterDrawItemText;
  Result := FTimeSelector;
end;

destructor TTMSFNCCustomDigitalTimePicker.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCCustomDigitalTimePicker.DoAfterDrawArrow(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean);
begin
  if Assigned(OnAfterDrawArrow) then
    OnAfterDrawArrow(Self, AGraphics, ARectF, ALeft);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoAfterDrawHeader(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARectF: TRectF);
begin
  if Assigned(OnAfterDrawHeader) then
    OnAfterDrawHeader(Self, AGraphics, ARectF);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoAfterDrawHeaderText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string);
begin
  if Assigned(OnAfterDrawHeaderText) then
    OnAfterDrawHeaderText(Self, AGraphics, ARectF, AText);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoAfterDrawItemBackground(
  Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItemIndex: Integer);
begin
  if Assigned(OnItemAfterDrawBackground) then
    OnItemAfterDrawBackground(Self, AGraphics, ARect, AItemIndex);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoAfterDrawItemContent(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer);
begin
  if Assigned(OnItemAfterDrawContent) then
    OnItemAfterDrawContent(Self, AGraphics, ARect, AItemIndex);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoAfterDrawItemText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer;
  AText: string);
begin
  if Assigned(OnItemAfterDrawText) then
    OnItemAfterDrawText(Self, AGraphics, ARect, AItemIndex, AText);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoBeforeDrawArrow(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawArrow) then
    OnBeforeDrawArrow(Self, AGraphics, ARectF, ALeft, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoBeforeDrawHeader(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeader) then
    OnBeforeDrawHeader(Self, AGraphics, ARectF, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoBeforeDrawHeaderText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeaderText) then
    OnBeforeDrawHeaderText(Self, AGraphics, ARectF, AText, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoBeforeDrawItemBackground(
  Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItemIndex: Integer; var ADefaultDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawBackground) then
    OnItemBeforeDrawBackground(Self, AGraphics, ARect, AItemIndex, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoBeforeDrawItemContent(
  Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItemIndex: Integer; var ADefaultDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawContent) then
    OnItemBeforeDrawContent(Self, AGraphics, ARect, AItemIndex, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoBeforeDrawItemText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer;
  var AText: string; var ADefaultDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawText) then
    OnItemBeforeDrawText(Self, AGraphics, ARect, AItemIndex, AText, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimePicker.DoTimeSelected;
begin
  if Assigned(OnTimeSelected) then
    OnTimeSelected(Self, SelectedTime);

  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.DrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
begin
  inherited;
  if not Editable then
  begin
    AGraphics.Font.AssignSource(Font);

    if not Enabled then
      AGraphics.Font.Color := gcSilver;
    r := ARect;
    InflateRectEx(r, -3, -3);
    AGraphics.DrawText(r, Content);
  end;
end;

procedure TTMSFNCCustomDigitalTimePicker.EditDown;
begin
  inherited;
  if not Popup.IsOpen then
    DropDown;
end;

procedure TTMSFNCCustomDigitalTimePicker.EditUp;
begin
  inherited;
  if Popup.IsOpen then
    DropDown;
end;

procedure TTMSFNCCustomDigitalTimePicker.FontChange(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCCustomDigitalTimePicker.GetEndTime: TTime;
begin
  Result := FTimeSelector.EndTime;
end;

function TTMSFNCCustomDigitalTimePicker.GetHeaderSize: Integer;
begin
  Result := FTimeSelector.HeaderSize;
end;

function TTMSFNCCustomDigitalTimePicker.GetIntervalUnit: TTMSFNCDigitalTimeSelectorIntervalUnit;
begin
  Result := FTimeSelector.IntervalUnit;
end;

function TTMSFNCCustomDigitalTimePicker.GetItems: TTMSFNCDigitalTimeSelectorItems;
begin
  Result := FTimeSelector.Items;
end;

function TTMSFNCCustomDigitalTimePicker.GetSelectedTime: TTime;
begin
  Result := FTimeSelector.SelectedTime;
end;

function TTMSFNCCustomDigitalTimePicker.GetStartTime: TTime;
begin
  Result := FTimeSelector.StartTime;
end;

function TTMSFNCCustomDigitalTimePicker.GetTimeFormat: string;
begin
  Result := FTimeSelector.TimeFormat;
end;

function TTMSFNCCustomDigitalTimePicker.GetTimeInterval: Integer;
begin
  Result := FTimeSelector.TimeInterval;
end;

function TTMSFNCCustomDigitalTimePicker.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomDigitalTimePicker.InitializePopup;
var
  t: TDateTime;
begin
  inherited;
  if Editable and Assigned(Edit.Parent) and Edit.Visible then
  begin
    t := SelectedTime;
    if TryStrToTime(Edit.Text, t) then
      SelectedTime := t;
  end;
  FTimeSelector.InitializePage(SelectedTime);
end;

function TTMSFNCCustomDigitalTimePicker.IsIntervalUnitStored: Boolean;
begin
  Result := GetIntervalUnit <> tsuMinutes;
end;

function TTMSFNCCustomDigitalTimePicker.IsTimeFormatStored: Boolean;
begin
  Result := GetTimeFormat <> 'hh:nn:ss';
end;

procedure TTMSFNCCustomDigitalTimePicker.Loaded;
begin
  inherited;
end;

procedure TTMSFNCCustomDigitalTimePicker.SelectFirstValue;
begin
  inherited;
  SelectedTime := StartTime;
  DoTimeSelected;
end;

procedure TTMSFNCCustomDigitalTimePicker.SelectLastValue;
var
  n: Integer;
begin
  inherited;
  case IntervalUnit of
    tsuMilliseconds:
    begin
      n := (MilliSecondOfTheDay(EndTime) - MilliSecondOfTheDay(StartTime)) div Cardinal(TimeInterval);
      SelectedTime := IncMilliSecond(StartTime, n * TimeInterval);
    end;
    tsuSeconds:
    begin
      n := (SecondOfTheDay(EndTime) - SecondOfTheDay(StartTime)) div Cardinal(TimeInterval);
      SelectedTime := IncSecond(StartTime, n * TimeInterval);
    end;
    tsuMinutes:
    begin
      n := (MinuteOfTheDay(EndTime) - MinuteOfTheDay(StartTime)) div TimeInterval;
      SelectedTime := IncMinute(StartTime, n * TimeInterval);
    end;
    tsuHours:
    begin
      n := (HourOfTheDay(EndTime) - HourOfTheDay(StartTime)) div TimeInterval;
      SelectedTime := IncHour(StartTime, n * TimeInterval);
    end;
  end;
  DoTimeSelected;
end;

procedure TTMSFNCCustomDigitalTimePicker.SelectNextValue;
var
  t: TDateTime;
begin
  inherited;
  t := 0.0;
  case IntervalUnit of
    tsuMilliseconds: t := IncMilliSecond(SelectedTime, TimeInterval);
    tsuSeconds: t := IncSecond(SelectedTime, TimeInterval);
    tsuMinutes: t := IncMinute(SelectedTime, TimeInterval);
    tsuHours: t := IncHour(SelectedTime, TimeInterval);
  end;
  if CompareDateTime(t, EndTime) <= 0 then
    SelectedTime := t;

  DoTimeSelected;
end;

procedure TTMSFNCCustomDigitalTimePicker.SelectPreviousValue;
var
  t: TDateTime;
begin
  inherited;
  t := 0.0;
  case IntervalUnit of
    tsuMilliseconds: t := IncMilliSecond(SelectedTime, -TimeInterval);
    tsuSeconds: t := IncSecond(SelectedTime, -TimeInterval);
    tsuMinutes: t := IncMinute(SelectedTime, -TimeInterval);
    tsuHours: t := IncHour(SelectedTime, -TimeInterval);
  end;
  if CompareDateTime(t, StartTime) >= 0 then
    SelectedTime := t;

  DoTimeSelected;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetAllowNumericNullValue(
  const Value: Boolean);
begin
  if FAllowNumericNullValue <> Value then
  begin
    FAllowNumericNullValue := Value;
    if FAllowNumericNullValue and (Frac(SelectedTime) = 0.0) then
      Content := ''
    else
      Content := FormatDateTime(TimeFormat, SelectedTime);

    Invalidate;
  end;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetEditable(const Value: Boolean);
begin
  inherited;
  if FAllowNumericNullValue and (Frac(FPrevSelTime) = 0.0) then
    Content := ''
  else
    Content := FormatDateTime(TimeFormat, FPrevSelTime);

  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetEndTime(const Value: TTime);
begin
  FTimeSelector.EndTime := Value;
  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetFnt(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCustomDigitalTimePicker.SetGraphicColors;
begin
  inherited;
  TTMSFNCGraphics.DefaultTextFontColor := Font.Color;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetHeaderSize(const Value: Integer);
begin
  FTimeSelector.HeaderSize := Value;
  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetIntervalUnit(
  const Value: TTMSFNCDigitalTimeSelectorIntervalUnit);
begin
  FTimeSelector.IntervalUnit := Value;
  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetItems(
  const Value: TTMSFNCDigitalTimeSelectorItems);
begin
  FTimeSelector.Items.Assign(Value);
end;

procedure TTMSFNCCustomDigitalTimePicker.SetSelectedTime(const Value: TTime);
begin
  if FAllowNumericNullValue and (Frac(Value) = 0.0) then
    Content := ''
  else
  begin
    if (CompareTime(Value, StartTime) = -1) or (CompareTime(Value, EndTime) = 1) then
      Content := FormatDateTime(TimeFormat, StartTime)
    else
      Content := FormatDateTime(TimeFormat, Value);
  end;

  FPrevSelTime := Value;
  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetStartTime(const Value: TTime);
begin
  FTimeSelector.StartTime := Value;
  SelectedTime := Value;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetTimeFormat(const Value: string);
begin
  FTimeSelector.TimeFormat := Value;
  Content := FormatDateTime(Value, SelectedTime);
  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.SetTimeInterval(const Value: Integer);
begin
  FTimeSelector.TimeInterval := Value;
  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.TimeDeselected(Sender: TObject;
  ATime: TTime);
begin
  if Assigned(OnTimeDeselected) then
    OnTimeDeselected(Self, ATime);

  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.TimeSelected(Sender: TObject;
  ATime: TTime);
begin
  if Editable and Edit.IsFocused then
    PreventDropDown := True;

  if CloseOnSelection then
    DropDown;

  if Assigned(OnTimeSelected) then
    OnTimeSelected(Self, ATime);

  SelectedTime := ATime;

  Invalidate;
end;

procedure TTMSFNCCustomDigitalTimePicker.UpdateEditText(AText: string);
var
  t: TDateTime;
begin
  inherited;
  if AText = '' then
  begin
    if (0.0 <> FPrevSelTime) then
    begin
      FTimeSelector.SelectedTime := 0.0;
      DoTimeSelected;
    end;
  end
  else if TryStrToTime(AText, t) then
  begin
    if (Frac(t) <> SelectedTime) and (Frac(t) >= StartTime) and (Frac(t) <= EndTime) then
    begin
      FTimeSelector.SelectedTime := Frac(t);
      DoTimeSelected;
    end;
  end;
end;

{ TTMSFNCDigitalTimePicker }

procedure TTMSFNCDigitalTimePicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCDigitalTimePicker);
end;

end.

unit uHTMLMemo;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCPanel, WEBLib.Buttons, VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl, VCL.TMSFNCWXHTMLMemo;

type
  THTMLMemoForm = class(TWebForm)
    FormPanel: TWebPanel;
    ContentPanel: TWebPanel;
    CaptionPanel: TWebPanel;
    CaptionLabel: TWebLabel;
    CloseImage: TWebImageControl;
    HeaderPanel: TTMSFNCPanel;
    TMSFNCWXHTMLMemo1: TTMSFNCWXHTMLMemo;
    StatusBarPanel: TTMSFNCPanel;
    SaveButton: TWebSpeedButton;
    CloseButton: TWebSpeedButton;
    DescriptionLabel: TWebLabel;
    DescriptionEdit: TWebEdit;
  private
    { Private declarations }
  public
    { Public declarations }
  end;

var
  HTMLMemoForm: THTMLMemoForm;

implementation

{$R *.dfm}

end.

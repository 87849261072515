unit uCommon;

interface

uses
  VCL.TMSFNCGrid, VCL.TMSFNCUtils,
  WEBLIB.JSON;

type
  selection_record = record
    Symbol: Integer;
    IMEI: String;
    LicensePlate: String;
  end;

const
  CountryList2 = 'AD,AE,AF,AG,AI,AL,AM,AO,AQ,AR,AS,AT,AU,AW,AX,AZ,BA,BB,BD,BE,BF,BG,BH,BI,BJ,BL,BM,BN,BO,BQ,BR,BS,BT,BV,BW,BY,BZ,CA,CC,CD,CF,CG,CH,CI,CK,CL,CM,CN,CO,CR,CU,CV,CW,CX,CY,CZ,DE,DJ,DK,DM,DO,DZ,EC,EE,EG,EH,ER,ES,ET,FI,FJ,FK,FM,FO,FR,GA,GB,GD,GE,GF,GG,GH,GI,GL,GM,GN,GP,GQ,GR,GS,GT,GU,GW,GY,HK,HM,HN,HR,HT,HU,ID,IE,IL,IM,IN,IO,IQ,IR,IS,IT,JE,JM,JO,JP,KE,KG,KH,KI,KM,KN,KP,KR,KW,KY,KZ,LA,LB,LC,LI,LK,LR,LS,LT,LU,LV,LY,MA,MC,MD,ME,MF,MG,MH,MK,ML,MM,MN,MO,MP,MQ,MR,MS,MT,MU,MV,MW,MX,MY,MZ,NA,NC,NE,NF,NG,NI,NL,NO,NP,NR,NU,NZ,OM,PA,PE,PF,PG,PH,PK,PL,PM,PN,PR,PS,PT,PW,PY,QA,RE,RO,RS,RU,RW,SA,SB,SC,SD,SE,SG,SH,SI,SJ,SK,SL,SM,SN,SO,SR,SS,ST,SV,SX,SY,SZ,TC,TD,TF,TG,TH,TJ,TK,TL,TM,TN,TO,TR,TT,TV,TW,TZ,UA,UG,UM,US,UY,UZ,VA,VC,VE,VG,VI,VN,VU,WF,WS,YE,YT,ZA,ZM,ZW';
  RegExEmail = '^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$';
  RegExTime = '^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))$';
  RegExNumeric = '^[0-9_]*$';
  RegExAlphaNumeric = '^[a-zA-Z0-9_]*$';
  KeysAlphaNumeric = [
    '1','2','3','4','5','6','7','8','9','0',
    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'
  ];

var
  url_www: String;
  url_php: String;
  url_rpt: String;
  url_img: String;
  url_kml: String;

  // Current Session
  DebugMode: Boolean;
  SessionID, SessionType, Permissions, DebugValue: String;

  // General Settings
  RealTimeFilterDistanceMax: Integer;
  RealtimeRefreshAssetCountMax: Integer;
  ReportServerURL: String;
  AssetIDIsReadOnly: Boolean;
  MachineMaxEnabled: Boolean;
  MachineMaxInterval: Integer;
  MachineMaxFillColor: String;
  UserManagerAll: Boolean;

  // Personal Settings
  MarkerVisible: Boolean;         // Show Markers On Map
  MarkerClustering: Boolean;      // Show Clusters In Realtime Map
  MarkerOverlayVisible: Boolean;  // Show Overlay Under Markers
  MarkerOverlayType: Integer;
  POIZoomToBounds: Boolean;       // Zoom To POI Bounds
  POIGroupList: String;           // Startup POI Groups
  DefaultCountry: String;
  ReportObjectFilterType: String;

  // Selection
  RealTimeObject: selection_record;

function BooleanToInteger(ABoolean: Boolean): Integer;
function BooleanToString(ABoolean: Boolean): String;
function BooleanToPostgreSQL(ABoolean: Boolean): String;
function ConvertDate(AString: String): TDateTime;
function ConvertDateTime(AString: String): TDateTime;

function GetJSONObjectKeys(AJSONObject: TJSONObject): String;

function EmptyStrToZero(AString: String): String;
procedure PeriodToFromTill(PeriodPKey: Integer; var DateFrom, DateTill: TDate);
procedure CellToClipBoard(Grid: TTMSFNCGrid);
procedure GridToClipBoard(Grid: TTMSFNCGrid; ExportHeaders: Boolean);
procedure GridSelectRow(Grid: TTMSFNCGrid; ColIdx: Integer; Value: String);
procedure SetGridStyle(AGrid: TTMSFNCGrid; Enabled: Boolean);
function HTMLMemoValueChanged(OldValue, NewValue: String): Boolean;
function ParsePhoneNumber(S: String): String;

[async]
function GeocodeAddress(Address, Country: String; var Lat, Lng, ErrorMessage: String): Boolean; async;

[async]
function SetWaitFlag(WebFormID: String): Boolean; async;
[async]
function GetWaitFlag(WebFormID: String; var WebFormPKey: Int64): Boolean; async;
[async]
function UpdateWaitFlag(WebFormID: String; WebFormPKey: Int64): Boolean; async;
[async]
function DeleteWaitFlag(WebFormID: String): Boolean; async;
function GetUserPermissionValue(PermissionString, PermissionName: String): Integer;
function GetSecurityPermissionValue(PermissionString, PermissionName: String): Integer;
[async]
function GetUserEmailAddress(var EmailAddress: String): Boolean; async;
procedure UpdateGeneralSettings(MySettings: String);
procedure UpdatePersonalSettings(MySettings: String);
procedure ClearRealTimeObject();
function GetNextMOTDate(DateFirst, DateInterval: Int64): String;

implementation

uses
  System.Classes, System.SysUtils, System.DateUtils, JS, Web,
  VCL.TMSFNCGridData, VCL.TMSFNCGraphicsTypes,
  WEBLIB.REST, WEBLIB.Dialogs, WEBLib.Clipboard, WEBLib.Graphics;

function BooleanToInteger(ABoolean: Boolean): Integer;
begin
  if ABoolean then Result := 1 else Result := 0;
end;

function BooleanToString(ABoolean: Boolean): String;
begin
  if ABoolean then Result := '1' else Result := '0';
end;

function BooleanToPostgreSQL(ABoolean: Boolean): String;
begin
  if ABoolean then Result := 't' else Result := 'f';
end;

function EmptyStrToZero(AString: String): String;
begin
  if Trim(AString) = EmptyStr then Result := '0' else Result := AString;
end;

procedure PeriodToFromTill(PeriodPKey: Integer; var DateFrom, DateTill: TDate);
var
  CurrentDate: TDate;
  Year, Month, Day: Word;
begin
  CurrentDate := Date();
  case PeriodPKey of
    1: begin // Today
      DateFrom := CurrentDate;
      DateTill := IncDay(DateFrom);
    end;
    2: begin // Yesterday
      DateFrom := IncDay(CurrentDate, -1);
      DateTill := CurrentDate;
    end;
    3: begin // This Week
      DateFrom := IncDay(CurrentDate, - (DayOfTheWeek(CurrentDate)-1));
      DateTill := IncDay(DateFrom, 7);
    end;
    4: begin // Last Week
      DateFrom := IncDay(CurrentDate, - (7+DayOfTheWeek(CurrentDate)-1));
      DateTill := IncDay(DateFrom, 7);
    end;
    5: begin // Last Two Weeks
      DateFrom := IncDay(CurrentDate, - (14+DayOfTheWeek(CurrentDate)-1));
      DateTill := IncDay(DateFrom, 14);
    end;
    6: begin // This Month
      DecodeDate(CurrentDate, Year, Month, Day);
      DateFrom := EncodeDate(Year, Month, 1);
      DateTill := IncMonth(DateFrom);
    end;
    7: begin // Last Month
      DecodeDate(CurrentDate, Year, Month, Day);
      DateFrom := IncMonth(EncodeDate(Year, Month, 1), -1);
      DateTill := IncMonth(DateFrom);
    end;
    8: begin // This Year
      DecodeDate(CurrentDate, Year, Month, Day);
      DateFrom := EncodeDate(Year, 1, 1);
      DateTill := IncYear(DateFrom);
    end;
    9: begin // Last Year
      DecodeDate(CurrentDate, Year, Month, Day);
      DateFrom := IncYear(EncodeDate(Year, 1, 1), -1);
      DateTill := IncYear(DateFrom);
    end;
    10: begin // Next Week
      DateFrom := IncDay(CurrentDate, - (DayOfTheWeek(CurrentDate)-1) + 7);
      DateTill := IncDay(DateFrom, 7);
    end;
    11: begin // Next Month
      DecodeDate(CurrentDate, Year, Month, Day);
      DateFrom := EncodeDate(Year, Month+1, 1);
      DateTill := IncMonth(DateFrom);
    end;
    12: begin // Last Three Months
      DecodeDate(CurrentDate, Year, Month, Day);
      DateFrom := IncMonth(EncodeDate(Year, Month, 1), -3);
      DateTill := IncMonth(DateFrom, 3);
    end;
  end;
end;

function GeocodeAddress(Address, Country: String; var Lat, Lng, ErrorMessage: String): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyJSONLocation: TJSONObject;
begin
  // Get Data
  MyWebRequest := THTTPRequest.Create(nil);
  try
    MyWebRequest.URL :=
      url_php + 'geocode_address.php?sessionid=' + SessionId +
        '&address=' + Trim(Address) + '&country=' + Trim(Country);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  // Parse Data
  if MyRequest.Status = 200 then begin
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        MyJSONLocation := TJSONObject(MyJSON.Parse(TJSONPair(MyJSONObject.Get('location')).JsonValue.ToString));
        Lat := MyJSONLocation.GetJSONValue('lat');
        Lng := MyJSONLocation.GetJSONValue('lng');
        Result := True;
      end else begin
        Result := False;
        ErrorMessage := 'Geocode address - Status: ' + MyJSONObject.GetJSONValue('status');
      end;
    finally
      MyJSON.Free;
    end;
  end else begin
    Result := False;
    ErrorMessage := 'Geocode address - Http Error ' + IntToStr(MyRequest.Status);
  end;
end;

function GetUserEmailAddress(var EmailAddress: String): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyWebRequest := THTTPRequest.Create(nil);
  try
    MyWebRequest.URL :=
      url_php + 'default_user_email_address_v2.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    if MyRequest.Status = 200 then begin
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          EmailAddress := MyJSONObject.GetJSONValue('email_address');
          Result := True;
        end;
      finally
        MyJSON.Free;
      end
    end;
  finally
    MyWebRequest.Free;
  end;
end;

function GetUserPermissionValue(PermissionString, PermissionName: String): Integer;
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyInteger: Integer;
begin
  Result := 0;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(PermissionString));
    case StrToInt(SessionType) of
      0, 1: begin
        if TryStrToInt(MyJSONObject.GetJSONValue(PermissionName), MyInteger) then begin
          Result := MyInteger;
        end else begin
          Result := 0;
        end;
      end;
      2: begin
        Result := 7;
      end;
    end;
  finally
    MyJSON.Free;
  end;
end;

function GetSecurityPermissionValue(PermissionString, PermissionName: String): Integer;
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
  MyInteger: Integer;
begin
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(PermissionString));
    if TryStrToInt(MyJSONObject.GetJSONValue(PermissionName), MyInteger) then begin
      Result := MyInteger;
    end else begin
      Result := 0;
    end;
  finally
    MyJSON.Free;
  end;
end;

procedure UpdateGeneralSettings(MySettings: String);
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyJSON := TJSON.Create;
  try
    // Parse String
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MySettings)));
    // Parse Object
    RealTimeFilterDistanceMax := StrToInt(MyJSONObject.GetJSONValue('realtime_filter_distance_max'));
    RealTimeRefreshAssetCountMax := StrToInt(MyJSONObject.GetJSONValue('realtime_refresh_asset_count_max'));
    ReportServerURL := MyJSONObject.GetJSONValue('report_server_url');
    AssetIDIsReadOnly := MyJSONObject.GetJSONValue('asset_id_is_readonly') = 't';
    MachineMaxEnabled := MyJSONObject.GetJSONValue('machinemax_enabled') = 't';
    MachineMaxInterval := StrToInt(MyJSONObject.GetJSONValue('machinemax_interval'));
    MachineMaxFillColor := ColorToHex(StrToInt(MyJSONObject.GetJSONValue('machinemax_fillcolor')));
    UserManagerAll := MyJSONObject.GetJSONValue('user_manager_all') = 't';
  finally
    MyJSON.Free;
  end;
end;

procedure UpdatePersonalSettings(MySettings: String);
var
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MySettings)));
    // Markers
    MarkerClustering := StrToInt(MyJSONObject.GetJSONValue('clustering')) = 1;
    MarkerOverlayVisible := StrToInt(MyJSONObject.GetJSONValue('marker_overlay_visible')) = 1;
    MarkerOverlayType := StrToInt(MyJSONObject.GetJSONValue('marker_overlay_type'));
    POIZoomToBounds := StrToInt(MyJSONObject.GetJSONValue('poi_zoom_to_bounds')) = 1;
    if POIGroupList = EmptyStr then begin
      POIGroupList := MyJSONObject.GetJSONValue('poi_group_list');
    end;
    // Defaults
    DefaultCountry := MyJSONObject.GetJSONValue('default_country');
    // Reports
    ReportObjectFilterType := MyJSONObject.GetJSONValue('reportobjectfiltertype');
  finally
    MyJSON.Free;
  end
end;

procedure ClearRealTimeObject();
begin
  with RealtimeObject do begin
    IMEI := EmptyStr;
    Symbol := 0;
    LicensePlate := EmptyStr;
  end;
end;

procedure CellToClipBoard(Grid: TTMSFNCGrid);
var
  Tcp: TClipBoard;
  GridCell: TTMSFNCGridCellRec;
begin
  GridCell := Grid.FocusedCell;
  Tcp := TClipBoard.Create;
  Tcp.CopyToClipboard(Trim(Grid.Cells[GridCell.Col, GridCell.Row]));
  Tcp.Free;
end;

procedure GridToClipBoard(Grid: TTMSFNCGrid; ExportHeaders: Boolean);
var
  I, RowIdx, ColIdx: Integer;
  StringListRow, StringListFile: TStringList;
  Tcp: TClipBoard;
begin
  // File
  StringListFile := TStringList.Create();
  StringListFile.Delimiter := chr(10);
  StringListFile.StrictDelimiter := True;
  // Row
  StringListRow := TStringList.Create();
  StringListRow.Delimiter := chr(9);
  StringListRow.StrictDelimiter := True;
  try
    // Headers
    for ColIdx := 0 to Grid.ColumnCount-1 do begin
      if Grid.Columns[ColIdx].Width > 0 then begin
        StringListRow.Add(Trim(Grid.Cells[ColIdx, 0]));
      end;
    end;
    StringListFile.Add(StringListRow.DelimitedText);
    // Values
    for I := 0 to Grid.RowSelectionCount-1 do begin
      StringListRow.Clear;
      RowIdx := Grid.SelectedRow[I];
      for ColIdx := 0 to Grid.ColumnCount-1 do begin
        if Grid.Columns[ColIdx].Width > 0 then begin
          StringListRow.Add(Trim(Grid.Cells[ColIdx, RowIdx]));
        end;
      end;
      StringListFile.Add(StringListRow.DelimitedText);
    end;
    Tcp := TClipBoard.Create;
    Tcp.CopyToClipboard(StringListFile.DelimitedText);
    Tcp.Free;
  finally
    StringListRow.Free;
    StringListFile.Free;
  end;
end;

procedure GridSelectRow(Grid: TTMSFNCGrid; ColIdx: Integer; Value: String);
var
  RowIdx: Integer;
  CellValue: String;
begin
  // Values
  ColIdx := 0;
  Grid.BeginUpdate;
  try
    for RowIdx := 1 to Grid.RowCount-1 do begin
      Grid.RowSelect[RowIdx] := False;
    end;
  finally
    Grid.EndUpdate;
  end;
  for RowIdx := 1 to Grid.RowCount-1 do begin
    CellValue := Trim(Grid.Cells[ColIdx, RowIdx]);
    if CellValue = Trim(Value) then begin
      Grid.Selection := MakeCellRange(1, RowIdx, Grid.ColumnCount-1, RowIdx);
    end;
  end;
end;

function SetWaitFlag(WebFormID: String): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(nil);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_waitflag_set.php?sessionid=' + SessionId +
      '&webformid=' + WebFormID;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      Result := MyJO.GetJSONValue('status') = 'OK';
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

function GetWaitFlag(WebFormID: String; var WebFormPKey: Int64): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(nil);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_waitflag_get.php?sessionid=' + SessionId +
      '&webformid=' + WebFormID;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      Result := MyJO.GetJSONValue('status') = 'OK';
      if Result then begin
        WebFormPKey := StrToInt(MyJO.GetJSONValue('pkey'));
      end;
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

function UpdateWaitFlag(WebFormID: String; WebFormPKey: Int64): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(nil);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_waitflag_set.php?sessionid=' + SessionId +
      '&webformid=' + WebFormID +
      '&webformpkey=' + IntToStr(WebFormPKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      Result := MyJO.GetJSONValue('status') = 'OK';
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

function DeleteWaitFlag(WebFormID: String): Boolean;
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJS: TJSON;
  MyJO: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(nil);
  try
    // Request
    MyWebRequest.URL :=
      url_php + 'default_waitflag_delete.php?sessionid=' + SessionId +
      '&webformid=' + WebFormID;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJS := TJSON.Create;
    try
      MyJO := TJSONObject(MyJS.Parse(string(MyRequest.response)));
      Result := MyJO.GetJSONValue('status') = 'OK';
    finally
      MyJS.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

function HTMLMemoValueChanged(OldValue, NewValue: String): Boolean;
var
  SourceString, TargetString: String;
begin
  // Replace Double Quotes with Single Quotes
  SourceString := StringReplace(OldValue, '"', '''', [rfReplaceAll]);
  TargetString := StringReplace(NewValue, '"', '''', [rfReplaceAll]);
  // Compare
  Result := SourceString <> TargetString;
end;

function ConvertDate(AString: String): TDateTime;
var
  StringList: TStringList;
  ADay, AMonth, AYear: Integer;
begin
  StringList := TStringList.Create;
  try
    StringList.Delimiter := '-';
    StringList.StrictDelimiter := True;
    StringList.DelimitedText := AString;
    AYear := StrToInt(StringList[0]);
    AMonth := StrToInt(StringList[1]);
    ADay := StrToInt(StringList[2]);
    try
      Result :=
        EncodeDateTime(AYear, AMonth, ADay, 0, 0, 0, 0);
    except
      on E: Exception do begin
        ShowMessage(E.Message);
      end;
    end;
  finally
    StringList.Free;
  end;
end;

function ConvertDateTime(AString: String): TDateTime;
var
  StringList: TStringList;
  StringListDate: TStringList;
  StringListTime: TStringList;
  ADay, AMonth, AYear, AHour, AMin, ASec: Integer;
begin
  StringList := TStringList.Create();
  StringList.Delimiter := ' ';
  StringList.StrictDelimiter := True;
  StringListDate := TStringList.Create();
  StringListDate.Delimiter := '-';
  StringListDate.StrictDelimiter := True;
  StringListTime := TStringList.Create();
  StringListTime.Delimiter := ':';
  StringListTime.StrictDelimiter := True;
  try
    StringList.DelimitedText := AString;
    StringListDate.DelimitedText := StringList[0];
    StringListTime.DelimitedText := StringList[1];
    AYear := StrToInt(StringListDate[0]);
    AMonth := StrToInt(StringListDate[1]);
    ADay := StrToInt(StringListDate[2]);
    AHour := StrToInt(StringListTime[0]);
    AMin := StrToInt(StringListTime[1]);
    ASec := StrToInt(StringListTime[2]);
    try
      Result :=
        EncodeDateTime(AYear, AMonth, ADay, AHour, AMin, ASec, 0);
    except
      on E: Exception do begin
        ShowMessage(E.Message);
      end;
    end;
  finally
    StringList.Free;
    StringListDate.Free;
    StringListTime.Free;
  end;
end;

function GetNextMOTDate(DateFirst, DateInterval: Int64): String;
var
  ADate: TDateTime;
  AYear, AMonth, ADay: Integer;
  SYear, SMonth, SDay: String;
begin
  if DateFirst > 0 then begin
    ADate := UnixToDateTime(DateFirst);
    while ADate < Date() do begin
      ADate := IncMonth(ADate, DateInterval);
    end;
    ADay := DayOf(ADate);
    if ADay < 10 then SDay := '0' + IntToStr(ADay) else SDay := IntToStr(ADay);
    AMonth := MonthOf(ADate);
    if AMonth < 10 then SMonth := '0' + IntToStr(AMonth) else SMonth := IntToStr(AMonth);
    AYear := YearOf(ADate);
    SYear := IntToStr(AYear);
    // Combine Parts
    Result := SDay + '-' + SMonth + '-' + SYear;
  end else begin
    Result := EmptyStr;
  end;
end;

procedure SetGridStyle(AGrid: TTMSFNCGrid; Enabled: Boolean);
begin
  AGrid.Options.Bands.Enabled := False;
  AGrid.Options.Editing.Enabled := Enabled;
  AGrid.Appearance.FixedLayout.Fill.Kind := gfkSolid;
  AGrid.Appearance.FixedSelectedLayout.Fill.Kind := gfkSolid;
  AGrid.Appearance.FocusedLayout.Fill.Kind := gfkSolid;
  AGrid.Appearance.GroupLayout.Fill.Kind := gfkSolid;
  AGrid.Appearance.SelectedLayout.Fill.Kind := gfkSolid;
  AGrid.Appearance.SummaryLayout.Fill.Kind := gfkSolid;
end;

function ParsePhoneNumber(S: String): String;
var
  I, J: Integer;
begin
  Result := EmptyStr;
  if S.StartsWith('+') then begin
    Result := '00';
  end else begin
    if not(S.StartsWith('0')) then Result := '00';
  end;
  for I := 1 to Length(S) do begin
    case ord(S[i]) of
      48..57: Result := Result + S[I];
    end;
  end;
end;

function GetJSONObjectKeys(AJSONObject: TJSONObject): String;
var
  StringList: TStringList;
  ObjectSize, I: Integer;
  ObjectName: String;
begin
  StringList := TStringList.Create();
  try
    ObjectSize := TTMSFNCUtils.GetJSONObjectSize(AJSONObject);
    for I := 0 to ObjectSize-1 do begin
      ObjectName := TTMSFNCUtils.GetJSONObjectName(AJSONObject, I);
      StringList.Add(ObjectName);
    end;
    Result := StringList.DelimitedText;
  finally
    StringList.Free;
  end;
end;

end.

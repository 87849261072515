unit uDeviceEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLIB.Rest, WEBLIB.JSON,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGridDatabaseAdapter, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Imaging.pngimage,
  Vcl.Imaging.GIFImg;

type
  TDeviceEditForm = class(TForm)
    FormPanel: TPanel;
    CaptionPanel: TPanel;
    CaptionLabel: TLabel;
    CloseImage: TImageControl;
    ContentPanel: TPanel;
    TopPanel: TPanel;
    DevicePKeyLabel: TLabel;
    SerialNumberLabel: TLabel;
    SimcardNumberLabel: TLabel;
    PhoneNumberLabel: TLabel;
    DevicePKeyEdit: TEdit;
    SerialNumberEdit: TEdit;
    SimcardNumberEdit: TEdit;
    PhoneNumberEdit: TEdit;
    GridDatabaseAdapter: TTMSFNCGridDatabaseAdapter;
    StatusBarPanel: TPanel;
    CloseButton: TSpeedButton;
    SaveButton: TSpeedButton;
    DeviceModelEdit: TLookupComboBox;
    WebWaitMessage: TWaitMessage;
    ShowTimer: TTimer;
    DeviceNoLabel: TLabel;
    DeviceNoEdit: TEdit;
    MyMessageDlg: TMessageDlg;
    ReactivateButton: TSpeedButton;
    BluetoothIDLabel: TLabel;
    BluetoothIDEdit: TEdit;
    DeviceModelLabel: TLabel;
    SMSButton: TSpeedButton;
    WebLabel1: TLabel;
    OperatorEdit: TLookupComboBox;
    procedure WebFormShow(Sender: TObject);
    [async]
    procedure CloseImageClick(Sender: TObject); async;
    [async]
    procedure SaveButtonClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    [async]
    procedure ShowTimerTimer(Sender: TObject); async;
    procedure RecordChange(Sender: TObject);
    [async]
    procedure ReactivateButtonClick(Sender: TObject); async;
    [async]
    procedure DeviceModelLabelClick(Sender: TObject); async;
    [async]
    procedure SMSButtonClick(Sender: TObject); async;
    procedure SerialNumberEditKeyPress(Sender: TObject; var Key: Char);
  private
    { Private declarations }
    RecordChanged: Boolean;
    [async]
    procedure LoadLookupValues(); async;
    procedure LoadDeviceModelList(S: String);
    [async]
    procedure LoadData(); async;
    [async]
    function ValidInput(var ErrorMessage: String): Boolean; async;
    [async]
    function SaveData(var ErrorMessage: String): Boolean; async;
  public
    { Public declarations }
    PKey: Int64;
    IsSet: Boolean;
    procedure SetReadOnly();
  protected procedure LoadDFMValues; override; end;

var
  DeviceEditForm: TDeviceEditForm;

implementation

{$R *.dfm}

uses uCommon, uDeviceModelEdit, uSMS;

procedure TDeviceEditForm.WebFormCreate(Sender: TObject);
begin
  PKey := -1;
end;

procedure TDeviceEditForm.SerialNumberEditKeyPress(Sender: TObject;
  var Key: Char);
begin
  if (Key = '|') or (Key = '#') then begin
    Key := #0;
  end;
end;

procedure TDeviceEditForm.SetReadOnly();
begin
  // Disable Buttons
  SaveButton.Visible := False;
end;

procedure TDeviceEditForm.WebFormShow(Sender: TObject);
begin
  WebWaitMessage.Show;
  ShowTimer.Enabled := True;
end;

procedure TDeviceEditForm.CloseImageClick(Sender: TObject);
var
  MyModalResult: TModalResult;
begin
  if RecordChanged and SaveButton.Visible then begin
    MyModalResult := await(TModalResult, MyMessageDlg.ShowDialog('Save changes?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
    if MyModalResult = mrYes then begin
      SaveButtonClick(SaveButton);
    end else begin
      if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    end;
  end else begin
//  if PKey >= 0 then ModalResult := mrOK else ModalResult := mrCancel;
    ModalResult := mrCancel;
  end;
end;

procedure TDeviceEditForm.SaveButtonClick(Sender: TObject);
var
  Result: Boolean;
  ErrorMessage: String;
begin
  Result := await(ValidInput(ErrorMessage));
  if Result then begin
    Result := await(SaveData(ErrorMessage));
    if Result then begin
      ModalResult := mrOK;
    end else begin
      MessageDlg(ErrorMessage, mtError, [mbOK]);
    end;
  end else begin
    MessageDlg(ErrorMessage, mtError, [mbOK]);
  end;
end;

procedure TDeviceEditForm.ShowTimerTimer(Sender: TObject);
begin
  if IsSet then begin
    ShowTimer.Enabled := False;
{
    // Set Focus
    if PKey = -1 then begin
      NameEdit.SetFocus;
    end;
}
    // Load Manager
    await(LoadLookupValues());
    // Load Data
    if PKey >= 0 then begin
      await(LoadData());
    end;
    WebWaitMessage.Hide;
    RecordChanged := False;
  end;
end;

procedure TDeviceEditForm.SMSButtonClick(Sender: TObject);
var
  MyWebForm: TSMSForm;
  MyModalResult: TModalResult;
begin
  // Create Form
  MyWebForm := TSMSForm.Create(Self);
  MyWebForm.Popup := True;
  MyWebForm.Border := fbNone;
  // Load Form
  await(TSMSForm, MyWebForm.Load());
  MyWebForm.RecipientsEdit.Text := ParsePhoneNumber(PhoneNumberEdit.Text);
  try
    // Show Form
    MyModalResult := await(TModalResult, MyWebForm.Execute);
    if MyModalResult = mrOK then begin
    end;
  finally
    MyWebForm.Free;
  end;
end;

procedure TDeviceEditForm.LoadDeviceModelList(S: String);
var
  I: Integer;
  MyJSON: TJSON;
  MyJSONArray: TJSONArray;
  MyJSONObject: TJSONObject;
begin
  DeviceModelEdit.LookupValues.Clear;
  // Parse JSON
  MyJSON := TJSON.Create;
  try
    MyJSONArray := TJSONArray(MyJSON.Parse(S));
    for i := 0 to MyJSONArray.Count-1 do begin
      MyJSONObject := TJSONObject(MyJSONArray.Items[i]);
      DeviceModelEdit.LookupValues.AddPair(
        MyJSONObject.GetJSONValue('PKey'),
        MyJSONObject.GetJSONValue('ID')
      );
    end;
    // Set Value
    DeviceModelEdit.Value := '0';
  finally
    MyJSON.Free;
  end;
end;

procedure TDeviceEditForm.LoadLookupValues();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_device_lookup_values.php?sessionid=' + SessionId;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
  finally
    MyWebRequest.Free;
  end;
  MyJSON := TJSON.Create;
  try
    MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
    LoadDeviceModelList(TJSONPair(MyJSONObject.Get('device_model')).JsonValue.ToString);
  finally
    MyJSON.Free;
  end
end;

procedure TDeviceEditForm.ReactivateButtonClick(Sender: TObject);
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_device_reactivate.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      // Parse Result
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          ReactivateButton.Visible := False;
        end else begin
          MessageDlg(MyJSONObject.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end
    end;
  finally
    MyWebRequest.Free;
  end;
end;

procedure TDeviceEditForm.RecordChange(Sender: TObject);
begin
  RecordChanged := True;
end;

procedure TDeviceEditForm.DeviceModelLabelClick(Sender: TObject);
var
  MyWebForm: TDeviceModelEditForm;
begin
  if SaveButton.Visible then begin
    MyWebForm := TDeviceModelEditForm.Create(Self);
    MyWebForm.Popup := True;
    MyWebForm.Border := fbNone;
    await(TDeviceModelEditForm, MyWebForm.Load());
    try
      // Show Form
      await(TModalResult, MyWebForm.Execute);
      if MyWebForm.ModalResult = mrOK then begin
        // Refresh Model List
        await(LoadLookupValues());
      end;
    finally
      MyWebForm.Free;
    end;
  end;
end;

procedure TDeviceEditForm.LoadData();
var
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  MyWebRequest := THTTPRequest.Create(Self);
  try
    MyWebRequest.URL :=
      url_php + 'default_device_load.php?sessionid=' + SessionId +
      '&pkey=' + IntToStr(PKey);
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    if MyRequest.Status = 200 then begin
      // Parse Result
      MyJSON := TJSON.Create;
      try
        MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
        if MyJSONObject.GetJSONValue('status') = 'OK' then begin
          DeviceModelEdit.Value := MyJSONObject.GetJSONValue('Model');
          DevicePKeyEdit.Text := MyJSONObject.GetJSONValue('PKey');
          DeviceNoEdit.Text := MyJSONObject.GetJSONValue('ID');
          SerialNumberEdit.Text := MyJSONObject.GetJSONValue('SN');
          SimcardNumberEdit.Text := MyJSONObject.GetJSONValue('Simcard No');
          PhoneNumberEdit.Text := MyJSONObject.GetJSONValue('Phone No');
          OperatorEdit.Value := MyJSONObject.GetJSONValue('Operator');
          BluetoothIDEdit.Text := MyJSONObject.GetJSONValue('Bluetooth ID');
          ReactivateButton.Visible := MyJSONObject.GetJSONValue('Active') = '0';
        end else begin
          MessageDlg(MyJSONObject.GetJSONValue('error_message'), WEBLib.Dialogs.mtError, [mbOK], nil);
        end;
      finally
        MyJSON.Free;
      end
    end;
  finally
    MyWebRequest.Free;
  end;
end;

function TDeviceEditForm.ValidInput(var ErrorMessage: String): Boolean;
var
  MyInteger: Int64;
begin
  Result := True;
  // PKey
  if Result then begin
    if TryStrToInt64(DevicePKeyEdit.Text, MyInteger) then begin
    end else begin
      Result := False;
      ErrorMessage := 'Device ID is a required field.';
    end;
  end;
  // Model
  if Result then begin
    if StrToInt(EmptyStrToZero(DeviceModelEdit.Value)) = 0 then begin
      Result := False;
      ErrorMessage := 'Model is a required field.';
    end;
  end;
end;

function TDeviceEditForm.SaveData(var ErrorMessage: String): Boolean;
var
  MyString: String;
  MyWebRequest: THTTPRequest;
  MyRequest: TJSXMLHttpRequest;
  MyJSON: TJSON;
  MyJSONObject: TJSONObject;
begin
  Result := False;
  MyString :=
    '{' +
      '"sessionid": "' + SessionID + '",' +
      '"pkey": "' + Trim(DevicePKeyEdit.Text) + '",' +
      '"sn": "' + Trim(SerialNumberEdit.Text) + '",' +
      '"id": "' + Trim(DeviceNoEdit.Text) + '",' +
      '"simcard no": "' + Trim(SimcardNumberEdit.Text) + '",' +
      '"phone no": "' + Trim(PhoneNumberEdit.Text) + '",' +
      '"model": "' + DeviceModelEdit.Value + '",' +
      '"operator": "' + OperatorEdit.Value + '",' +
      '"bluetooth id": "' + Trim(BluetoothIDEdit.Text) + '"' +
    '}';
  // Save Changes
  MyWebRequest := THTTPRequest.Create(Self);
  try
    // Request
    MyWebRequest.Headers.AddPair('Content-Type', 'application/json');
    MyWebRequest.URL := url_php + 'default_device_save.php';
    MyWebRequest.Command := THTTPCommand.httpPOST;
    MyWebRequest.PostData := MyString;
    MyRequest := await(TJSXMLHttpRequest, MyWebRequest.Perform());
    // Response
    MyJSON := TJSON.Create;
    try
      MyJSONObject := TJSONObject(MyJSON.Parse(string(MyRequest.response)));
      if MyJSONObject.GetJSONValue('status') = 'OK' then begin
        Result := True;
        PKey := StrToInt(DevicePKeyEdit.Text);
      end else begin
        ErrorMessage := MyJSONObject.GetJSONValue('error_message');
      end;
    finally
      MyJSON.Free;
    end
  finally
    MyWebRequest.Free;
  end;
end;

procedure TDeviceEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormPanel := TPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  CaptionLabel := TLabel.Create(Self);
  CloseImage := TImageControl.Create(Self);
  ContentPanel := TPanel.Create(Self);
  TopPanel := TPanel.Create(Self);
  DevicePKeyLabel := TLabel.Create(Self);
  SerialNumberLabel := TLabel.Create(Self);
  SimcardNumberLabel := TLabel.Create(Self);
  PhoneNumberLabel := TLabel.Create(Self);
  DeviceNoLabel := TLabel.Create(Self);
  BluetoothIDLabel := TLabel.Create(Self);
  DeviceModelLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  DevicePKeyEdit := TEdit.Create(Self);
  SerialNumberEdit := TEdit.Create(Self);
  SimcardNumberEdit := TEdit.Create(Self);
  PhoneNumberEdit := TEdit.Create(Self);
  DeviceModelEdit := TLookupComboBox.Create(Self);
  DeviceNoEdit := TEdit.Create(Self);
  BluetoothIDEdit := TEdit.Create(Self);
  SMSButton := TSpeedButton.Create(Self);
  OperatorEdit := TLookupComboBox.Create(Self);
  GridDatabaseAdapter := TTMSFNCGridDatabaseAdapter.Create(Self);
  StatusBarPanel := TPanel.Create(Self);
  CloseButton := TSpeedButton.Create(Self);
  SaveButton := TSpeedButton.Create(Self);
  ReactivateButton := TSpeedButton.Create(Self);
  WebWaitMessage := TWaitMessage.Create(Self);
  MyMessageDlg := TMessageDlg.Create(Self);
  ShowTimer := TTimer.Create(Self);

  FormPanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  CaptionLabel.BeforeLoadDFMValues;
  CloseImage.BeforeLoadDFMValues;
  ContentPanel.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  DevicePKeyLabel.BeforeLoadDFMValues;
  SerialNumberLabel.BeforeLoadDFMValues;
  SimcardNumberLabel.BeforeLoadDFMValues;
  PhoneNumberLabel.BeforeLoadDFMValues;
  DeviceNoLabel.BeforeLoadDFMValues;
  BluetoothIDLabel.BeforeLoadDFMValues;
  DeviceModelLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  DevicePKeyEdit.BeforeLoadDFMValues;
  SerialNumberEdit.BeforeLoadDFMValues;
  SimcardNumberEdit.BeforeLoadDFMValues;
  PhoneNumberEdit.BeforeLoadDFMValues;
  DeviceModelEdit.BeforeLoadDFMValues;
  DeviceNoEdit.BeforeLoadDFMValues;
  BluetoothIDEdit.BeforeLoadDFMValues;
  SMSButton.BeforeLoadDFMValues;
  OperatorEdit.BeforeLoadDFMValues;
  GridDatabaseAdapter.BeforeLoadDFMValues;
  StatusBarPanel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  ReactivateButton.BeforeLoadDFMValues;
  WebWaitMessage.BeforeLoadDFMValues;
  MyMessageDlg.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  try
    Name := 'DeviceEditForm';
    Width := 453;
    Height := 654;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FormPanel.SetParentComponent(Self);
    FormPanel.Name := 'FormPanel';
    FormPanel.Left := 0;
    FormPanel.Top := 0;
    FormPanel.Width := 453;
    FormPanel.Height := 654;
    FormPanel.Align := alClient;
    FormPanel.BorderColor := -1;
    FormPanel.BorderStyle := bsNone;
    FormPanel.Color := 14062646;
    CaptionPanel.SetParentComponent(FormPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 453;
    CaptionPanel.Height := 40;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := -1;
    CaptionPanel.BorderStyle := bsNone;
    CaptionPanel.ChildOrder := 2;
    CaptionPanel.Color := 14062646;
    CaptionPanel.Font.Charset := ANSI_CHARSET;
    CaptionPanel.Font.Color := clWindowText;
    CaptionPanel.Font.Height := -16;
    CaptionPanel.Font.Name := 'Segoe UI';
    CaptionPanel.Font.Style := [];
    CaptionPanel.ParentFont := False;
    CaptionLabel.SetParentComponent(CaptionPanel);
    CaptionLabel.Name := 'CaptionLabel';
    CaptionLabel.Left := 11;
    CaptionLabel.Top := 10;
    CaptionLabel.Width := 89;
    CaptionLabel.Height := 19;
    CaptionLabel.Caption := 'Device - Edit';
    CaptionLabel.Font.Charset := DEFAULT_CHARSET;
    CaptionLabel.Font.Color := clWhite;
    CaptionLabel.Font.Height := -16;
    CaptionLabel.Font.Name := 'Tahoma';
    CaptionLabel.Font.Style := [];
    CaptionLabel.HeightPercent := 100.000000000000000000;
    CaptionLabel.ParentFont := False;
    CaptionLabel.WidthPercent := 100.000000000000000000;
    CloseImage.SetParentComponent(CaptionPanel);
    CloseImage.Name := 'CloseImage';
    CloseImage.AlignWithMargins := True;
    CloseImage.Left := 424;
    CloseImage.Top := 9;
    CloseImage.Width := 23;
    CloseImage.Height := 22;
    CloseImage.Margins.Top := 9;
    CloseImage.Margins.Right := 6;
    CloseImage.Margins.Bottom := 9;
    CloseImage.HeightPercent := 100.000000000000000000;
    CloseImage.WidthPercent := 100.000000000000000000;
    CloseImage.Align := alRight;
    CloseImage.ChildOrder := 1;
    SetEvent(CloseImage, Self, 'OnClick', 'CloseImageClick');
    CloseImage.Picture.LoadFromFile('uDeviceEdit.CaptionPanel.CloseImage.Picture.png');
    ContentPanel.SetParentComponent(FormPanel);
    ContentPanel.Name := 'ContentPanel';
    ContentPanel.AlignWithMargins := True;
    ContentPanel.Left := 3;
    ContentPanel.Top := 40;
    ContentPanel.Width := 447;
    ContentPanel.Height := 611;
    ContentPanel.Margins.Top := 0;
    ContentPanel.Align := alClient;
    ContentPanel.BorderColor := -1;
    ContentPanel.BorderStyle := bsNone;
    ContentPanel.ChildOrder := 1;
    ContentPanel.Color := clWhite;
    TopPanel.SetParentComponent(ContentPanel);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 0;
    TopPanel.Width := 447;
    TopPanel.Height := 556;
    TopPanel.Align := alTop;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 2;
    TopPanel.Color := clWhite;
    DevicePKeyLabel.SetParentComponent(TopPanel);
    DevicePKeyLabel.Name := 'DevicePKeyLabel';
    DevicePKeyLabel.Left := 28;
    DevicePKeyLabel.Top := 95;
    DevicePKeyLabel.Width := 286;
    DevicePKeyLabel.Height := 21;
    DevicePKeyLabel.Caption := 'DEVICE ID / MOBILE PHONE TRACKER N'#176;
    DevicePKeyLabel.Font.Charset := ANSI_CHARSET;
    DevicePKeyLabel.Font.Color := 9010544;
    DevicePKeyLabel.Font.Height := -16;
    DevicePKeyLabel.Font.Name := 'Segoe UI';
    DevicePKeyLabel.Font.Style := [];
    DevicePKeyLabel.HeightPercent := 100.000000000000000000;
    DevicePKeyLabel.ParentFont := False;
    DevicePKeyLabel.WidthPercent := 100.000000000000000000;
    SerialNumberLabel.SetParentComponent(TopPanel);
    SerialNumberLabel.Name := 'SerialNumberLabel';
    SerialNumberLabel.Left := 28;
    SerialNumberLabel.Top := 250;
    SerialNumberLabel.Width := 117;
    SerialNumberLabel.Height := 21;
    SerialNumberLabel.Caption := 'SERIAL NUMBER';
    SerialNumberLabel.Font.Charset := ANSI_CHARSET;
    SerialNumberLabel.Font.Color := 9010544;
    SerialNumberLabel.Font.Height := -16;
    SerialNumberLabel.Font.Name := 'Segoe UI';
    SerialNumberLabel.Font.Style := [];
    SerialNumberLabel.HeightPercent := 100.000000000000000000;
    SerialNumberLabel.ParentFont := False;
    SerialNumberLabel.WidthPercent := 100.000000000000000000;
    SimcardNumberLabel.SetParentComponent(TopPanel);
    SimcardNumberLabel.Name := 'SimcardNumberLabel';
    SimcardNumberLabel.Left := 28;
    SimcardNumberLabel.Top := 322;
    SimcardNumberLabel.Width := 140;
    SimcardNumberLabel.Height := 21;
    SimcardNumberLabel.Caption := 'SIM CARD NUMBER';
    SimcardNumberLabel.Font.Charset := ANSI_CHARSET;
    SimcardNumberLabel.Font.Color := 9010544;
    SimcardNumberLabel.Font.Height := -16;
    SimcardNumberLabel.Font.Name := 'Segoe UI';
    SimcardNumberLabel.Font.Style := [];
    SimcardNumberLabel.HeightPercent := 100.000000000000000000;
    SimcardNumberLabel.ParentFont := False;
    SimcardNumberLabel.WidthPercent := 100.000000000000000000;
    PhoneNumberLabel.SetParentComponent(TopPanel);
    PhoneNumberLabel.Name := 'PhoneNumberLabel';
    PhoneNumberLabel.Left := 28;
    PhoneNumberLabel.Top := 394;
    PhoneNumberLabel.Width := 149;
    PhoneNumberLabel.Height := 21;
    PhoneNumberLabel.Caption := 'SIMM CALL NUMBER';
    PhoneNumberLabel.Font.Charset := ANSI_CHARSET;
    PhoneNumberLabel.Font.Color := 9010544;
    PhoneNumberLabel.Font.Height := -16;
    PhoneNumberLabel.Font.Name := 'Segoe UI';
    PhoneNumberLabel.Font.Style := [];
    PhoneNumberLabel.HeightPercent := 100.000000000000000000;
    PhoneNumberLabel.ParentFont := False;
    PhoneNumberLabel.WidthPercent := 100.000000000000000000;
    DeviceNoLabel.SetParentComponent(TopPanel);
    DeviceNoLabel.Name := 'DeviceNoLabel';
    DeviceNoLabel.Left := 28;
    DeviceNoLabel.Top := 175;
    DeviceNoLabel.Width := 119;
    DeviceNoLabel.Height := 21;
    DeviceNoLabel.Caption := 'DEVICE NUMBER';
    DeviceNoLabel.Font.Charset := ANSI_CHARSET;
    DeviceNoLabel.Font.Color := 9010544;
    DeviceNoLabel.Font.Height := -16;
    DeviceNoLabel.Font.Name := 'Segoe UI';
    DeviceNoLabel.Font.Style := [];
    DeviceNoLabel.HeightPercent := 100.000000000000000000;
    DeviceNoLabel.ParentFont := False;
    DeviceNoLabel.WidthPercent := 100.000000000000000000;
    BluetoothIDLabel.SetParentComponent(TopPanel);
    BluetoothIDLabel.Name := 'BluetoothIDLabel';
    BluetoothIDLabel.Left := 28;
    BluetoothIDLabel.Top := 466;
    BluetoothIDLabel.Width := 104;
    BluetoothIDLabel.Height := 21;
    BluetoothIDLabel.Caption := 'BLUETOOTH ID';
    BluetoothIDLabel.Font.Charset := ANSI_CHARSET;
    BluetoothIDLabel.Font.Color := 9010544;
    BluetoothIDLabel.Font.Height := -16;
    BluetoothIDLabel.Font.Name := 'Segoe UI';
    BluetoothIDLabel.Font.Style := [];
    BluetoothIDLabel.HeightPercent := 100.000000000000000000;
    BluetoothIDLabel.ParentFont := False;
    BluetoothIDLabel.WidthPercent := 100.000000000000000000;
    DeviceModelLabel.SetParentComponent(TopPanel);
    DeviceModelLabel.Name := 'DeviceModelLabel';
    DeviceModelLabel.Left := 28;
    DeviceModelLabel.Top := 18;
    DeviceModelLabel.Width := 53;
    DeviceModelLabel.Height := 21;
    DeviceModelLabel.Caption := 'MODEL';
    DeviceModelLabel.Font.Charset := ANSI_CHARSET;
    DeviceModelLabel.Font.Color := 16755027;
    DeviceModelLabel.Font.Height := -16;
    DeviceModelLabel.Font.Name := 'Segoe UI';
    DeviceModelLabel.Font.Style := [fsUnderline];
    DeviceModelLabel.HeightPercent := 100.000000000000000000;
    DeviceModelLabel.ParentFont := False;
    DeviceModelLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceModelLabel, Self, 'OnClick', 'DeviceModelLabelClick');
    WebLabel1.SetParentComponent(TopPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 285;
    WebLabel1.Top := 322;
    WebLabel1.Width := 77;
    WebLabel1.Height := 21;
    WebLabel1.Caption := 'OPERATOR';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := 9010544;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    DevicePKeyEdit.SetParentComponent(TopPanel);
    DevicePKeyEdit.Name := 'DevicePKeyEdit';
    DevicePKeyEdit.Left := 28;
    DevicePKeyEdit.Top := 122;
    DevicePKeyEdit.Width := 397;
    DevicePKeyEdit.Height := 34;
    DevicePKeyEdit.ChildOrder := 1;
    DevicePKeyEdit.EditType := weNumeric;
    DevicePKeyEdit.ElementClassName := 'MyWebEdit';
    DevicePKeyEdit.Font.Charset := ANSI_CHARSET;
    DevicePKeyEdit.Font.Color := clWindowText;
    DevicePKeyEdit.Font.Height := -16;
    DevicePKeyEdit.Font.Name := 'Segoe UI';
    DevicePKeyEdit.Font.Style := [];
    DevicePKeyEdit.HeightPercent := 100.000000000000000000;
    DevicePKeyEdit.ParentFont := False;
    DevicePKeyEdit.TabOrder := 1;
    DevicePKeyEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DevicePKeyEdit, Self, 'OnChange', 'RecordChange');
    SerialNumberEdit.SetParentComponent(TopPanel);
    SerialNumberEdit.Name := 'SerialNumberEdit';
    SerialNumberEdit.Left := 28;
    SerialNumberEdit.Top := 277;
    SerialNumberEdit.Width := 397;
    SerialNumberEdit.Height := 34;
    SerialNumberEdit.ChildOrder := 1;
    SerialNumberEdit.ElementClassName := 'MyWebEdit';
    SerialNumberEdit.Font.Charset := ANSI_CHARSET;
    SerialNumberEdit.Font.Color := clWindowText;
    SerialNumberEdit.Font.Height := -16;
    SerialNumberEdit.Font.Name := 'Segoe UI';
    SerialNumberEdit.Font.Style := [];
    SerialNumberEdit.HeightPercent := 100.000000000000000000;
    SerialNumberEdit.ParentFont := False;
    SerialNumberEdit.TabOrder := 3;
    SerialNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SerialNumberEdit, Self, 'OnChange', 'RecordChange');
    SetEvent(SerialNumberEdit, Self, 'OnKeyPress', 'SerialNumberEditKeyPress');
    SimcardNumberEdit.SetParentComponent(TopPanel);
    SimcardNumberEdit.Name := 'SimcardNumberEdit';
    SimcardNumberEdit.Left := 28;
    SimcardNumberEdit.Top := 349;
    SimcardNumberEdit.Width := 239;
    SimcardNumberEdit.Height := 34;
    SimcardNumberEdit.ChildOrder := 1;
    SimcardNumberEdit.ElementClassName := 'MyWebEdit';
    SimcardNumberEdit.Font.Charset := ANSI_CHARSET;
    SimcardNumberEdit.Font.Color := clWindowText;
    SimcardNumberEdit.Font.Height := -16;
    SimcardNumberEdit.Font.Name := 'Segoe UI';
    SimcardNumberEdit.Font.Style := [];
    SimcardNumberEdit.HeightPercent := 100.000000000000000000;
    SimcardNumberEdit.ParentFont := False;
    SimcardNumberEdit.TabOrder := 4;
    SimcardNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SimcardNumberEdit, Self, 'OnChange', 'RecordChange');
    PhoneNumberEdit.SetParentComponent(TopPanel);
    PhoneNumberEdit.Name := 'PhoneNumberEdit';
    PhoneNumberEdit.Left := 28;
    PhoneNumberEdit.Top := 421;
    PhoneNumberEdit.Width := 325;
    PhoneNumberEdit.Height := 34;
    PhoneNumberEdit.ChildOrder := 1;
    PhoneNumberEdit.ElementClassName := 'MyWebEdit';
    PhoneNumberEdit.Font.Charset := ANSI_CHARSET;
    PhoneNumberEdit.Font.Color := clWindowText;
    PhoneNumberEdit.Font.Height := -16;
    PhoneNumberEdit.Font.Name := 'Segoe UI';
    PhoneNumberEdit.Font.Style := [];
    PhoneNumberEdit.HeightPercent := 100.000000000000000000;
    PhoneNumberEdit.ParentFont := False;
    PhoneNumberEdit.TabOrder := 6;
    PhoneNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PhoneNumberEdit, Self, 'OnChange', 'RecordChange');
    DeviceModelEdit.SetParentComponent(TopPanel);
    DeviceModelEdit.Name := 'DeviceModelEdit';
    DeviceModelEdit.AlignWithMargins := True;
    DeviceModelEdit.Left := 28;
    DeviceModelEdit.Top := 50;
    DeviceModelEdit.Width := 397;
    DeviceModelEdit.Height := 34;
    DeviceModelEdit.Margins.Left := 20;
    DeviceModelEdit.Margins.Top := 8;
    DeviceModelEdit.Margins.Bottom := 8;
    DeviceModelEdit.Color := clWhite;
    DeviceModelEdit.ElementClassName := 'myselect_white';
    DeviceModelEdit.Font.Charset := DEFAULT_CHARSET;
    DeviceModelEdit.Font.Color := clWindowText;
    DeviceModelEdit.Font.Height := 12;
    DeviceModelEdit.Font.Name := 'Segoe UI';
    DeviceModelEdit.Font.Style := [];
    DeviceModelEdit.HeightPercent := 100.000000000000000000;
    DeviceModelEdit.ParentFont := False;
    DeviceModelEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceModelEdit, Self, 'OnChange', 'RecordChange');
    DeviceModelEdit.ItemIndex := -1;
    DeviceNoEdit.SetParentComponent(TopPanel);
    DeviceNoEdit.Name := 'DeviceNoEdit';
    DeviceNoEdit.Left := 28;
    DeviceNoEdit.Top := 202;
    DeviceNoEdit.Width := 397;
    DeviceNoEdit.Height := 34;
    DeviceNoEdit.ChildOrder := 1;
    DeviceNoEdit.EditType := weNumeric;
    DeviceNoEdit.ElementClassName := 'MyWebEdit';
    DeviceNoEdit.Font.Charset := ANSI_CHARSET;
    DeviceNoEdit.Font.Color := clWindowText;
    DeviceNoEdit.Font.Height := -16;
    DeviceNoEdit.Font.Name := 'Segoe UI';
    DeviceNoEdit.Font.Style := [];
    DeviceNoEdit.HeightPercent := 100.000000000000000000;
    DeviceNoEdit.ParentFont := False;
    DeviceNoEdit.TabOrder := 2;
    DeviceNoEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DeviceNoEdit, Self, 'OnChange', 'RecordChange');
    BluetoothIDEdit.SetParentComponent(TopPanel);
    BluetoothIDEdit.Name := 'BluetoothIDEdit';
    BluetoothIDEdit.Left := 28;
    BluetoothIDEdit.Top := 493;
    BluetoothIDEdit.Width := 397;
    BluetoothIDEdit.Height := 34;
    BluetoothIDEdit.ChildOrder := 1;
    BluetoothIDEdit.ElementClassName := 'MyWebEdit';
    BluetoothIDEdit.Font.Charset := ANSI_CHARSET;
    BluetoothIDEdit.Font.Color := clWindowText;
    BluetoothIDEdit.Font.Height := -16;
    BluetoothIDEdit.Font.Name := 'Segoe UI';
    BluetoothIDEdit.Font.Style := [];
    BluetoothIDEdit.HeightPercent := 100.000000000000000000;
    BluetoothIDEdit.ParentFont := False;
    BluetoothIDEdit.TabOrder := 8;
    BluetoothIDEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BluetoothIDEdit, Self, 'OnChange', 'RecordChange');
    SMSButton.SetParentComponent(TopPanel);
    SMSButton.Name := 'SMSButton';
    SMSButton.AlignWithMargins := True;
    SMSButton.Left := 368;
    SMSButton.Top := 421;
    SMSButton.Width := 57;
    SMSButton.Height := 34;
    SMSButton.Margins.Left := 20;
    SMSButton.Margins.Top := 8;
    SMSButton.Margins.Right := 5;
    SMSButton.Margins.Bottom := 10;
    SMSButton.Caption := 'SMS';
    SMSButton.Color := clNone;
    SMSButton.ElementClassName := 'MyWebButton';
    SMSButton.HeightPercent := 100.000000000000000000;
    SMSButton.TabOrder := 7;
    SMSButton.WidthPercent := 100.000000000000000000;
    SetEvent(SMSButton, Self, 'OnClick', 'SMSButtonClick');
    OperatorEdit.SetParentComponent(TopPanel);
    OperatorEdit.Name := 'OperatorEdit';
    OperatorEdit.AlignWithMargins := True;
    OperatorEdit.Left := 285;
    OperatorEdit.Top := 349;
    OperatorEdit.Width := 140;
    OperatorEdit.Height := 34;
    OperatorEdit.Margins.Left := 20;
    OperatorEdit.Margins.Top := 8;
    OperatorEdit.Margins.Bottom := 8;
    OperatorEdit.Color := clWhite;
    OperatorEdit.ElementClassName := 'myselect_white';
    OperatorEdit.Font.Charset := DEFAULT_CHARSET;
    OperatorEdit.Font.Color := clWindowText;
    OperatorEdit.Font.Height := 12;
    OperatorEdit.Font.Name := 'Segoe UI';
    OperatorEdit.Font.Style := [];
    OperatorEdit.HeightPercent := 100.000000000000000000;
    OperatorEdit.ParentFont := False;
    OperatorEdit.TabOrder := 5;
    OperatorEdit.WidthPercent := 100.000000000000000000;
    SetEvent(OperatorEdit, Self, 'OnChange', 'RecordChange');
    OperatorEdit.ItemIndex := -1;
    OperatorEdit.LookupValues.Clear;
    with OperatorEdit.LookupValues.Add do
    begin
      Value := '0';
    end;
    with OperatorEdit.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'PROXIMUS';
    end;
    with OperatorEdit.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'TELENET';
    end;
    with OperatorEdit.LookupValues.Add do
    begin
      Value := '3';
      DisplayText := 'ORANGE';
    end;
    with OperatorEdit.LookupValues.Add do
    begin
      Value := '4';
      DisplayText := 'TRUPHONE';
    end;
    GridDatabaseAdapter.SetParentComponent(ContentPanel);
    GridDatabaseAdapter.Name := 'GridDatabaseAdapter';
    GridDatabaseAdapter.Left := 989;
    GridDatabaseAdapter.Top := 10;
    GridDatabaseAdapter.Width := 26;
    GridDatabaseAdapter.Height := 26;
    GridDatabaseAdapter.Margins.Left := 12;
    GridDatabaseAdapter.Visible := True;
    GridDatabaseAdapter.Columns.Clear;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'object_groep_checkbox';
      Header := 'View';
      CheckBoxField := True;
      CheckFalse := '0';
      CheckTrue := '1';
    end;
    with GridDatabaseAdapter.Columns.Add do
    begin
      FieldName := 'object_groep_naam';
      Header := 'Name';
      CheckFalse := 'False';
      CheckTrue := 'True';
    end;
    GridDatabaseAdapter.AutoCreateColumns := False;
    GridDatabaseAdapter.AutoRemoveColumns := False;
    StatusBarPanel.SetParentComponent(ContentPanel);
    StatusBarPanel.Name := 'StatusBarPanel';
    StatusBarPanel.Left := 0;
    StatusBarPanel.Top := 562;
    StatusBarPanel.Width := 447;
    StatusBarPanel.Height := 49;
    StatusBarPanel.Align := alBottom;
    StatusBarPanel.BorderStyle := bsNone;
    StatusBarPanel.ChildOrder := 2;
    StatusBarPanel.Color := clWhite;
    CloseButton.SetParentComponent(StatusBarPanel);
    CloseButton.Name := 'CloseButton';
    CloseButton.AlignWithMargins := True;
    CloseButton.Left := 327;
    CloseButton.Top := 8;
    CloseButton.Width := 100;
    CloseButton.Height := 31;
    CloseButton.Margins.Left := 10;
    CloseButton.Margins.Top := 8;
    CloseButton.Margins.Right := 20;
    CloseButton.Margins.Bottom := 10;
    CloseButton.Align := alRight;
    CloseButton.Caption := '&Cancel';
    CloseButton.Color := clNone;
    CloseButton.ElementClassName := 'MyWebButton';
    CloseButton.Glyph.LoadFromFile('uDeviceEdit.StatusBarPanel.CloseButton.Glyph.png');
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 0;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseImageClick');
    SaveButton.SetParentComponent(StatusBarPanel);
    SaveButton.Name := 'SaveButton';
    SaveButton.AlignWithMargins := True;
    SaveButton.Left := 212;
    SaveButton.Top := 8;
    SaveButton.Width := 100;
    SaveButton.Height := 31;
    SaveButton.Margins.Left := 20;
    SaveButton.Margins.Top := 8;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 10;
    SaveButton.Align := alRight;
    SaveButton.Caption := '&Save';
    SaveButton.Color := clNone;
    SaveButton.ElementClassName := 'MyWebButton';
    SaveButton.Glyph.LoadFromFile('uDeviceEdit.StatusBarPanel.SaveButton.Glyph.png');
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 1;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    ReactivateButton.SetParentComponent(StatusBarPanel);
    ReactivateButton.Name := 'ReactivateButton';
    ReactivateButton.AlignWithMargins := True;
    ReactivateButton.Left := 20;
    ReactivateButton.Top := 8;
    ReactivateButton.Width := 120;
    ReactivateButton.Height := 31;
    ReactivateButton.Margins.Left := 20;
    ReactivateButton.Margins.Top := 8;
    ReactivateButton.Margins.Right := 5;
    ReactivateButton.Margins.Bottom := 10;
    ReactivateButton.Align := alLeft;
    ReactivateButton.Caption := '&Reactivate';
    ReactivateButton.Color := clNone;
    ReactivateButton.ElementClassName := 'MyWebButton';
    ReactivateButton.Glyph.LoadFromFile('uDeviceEdit.StatusBarPanel.ReactivateButton.Glyph.png');
    ReactivateButton.HeightPercent := 100.000000000000000000;
    ReactivateButton.TabOrder := 2;
    ReactivateButton.Visible := False;
    ReactivateButton.WidthPercent := 100.000000000000000000;
    SetEvent(ReactivateButton, Self, 'OnClick', 'ReactivateButtonClick');
    WebWaitMessage.SetParentComponent(Self);
    WebWaitMessage.Name := 'WebWaitMessage';
    WebWaitMessage.Left := 189;
    WebWaitMessage.Top := 58;
    WebWaitMessage.Width := 24;
    WebWaitMessage.Height := 24;
    WebWaitMessage.Opacity := 0.200000000000000000;
    WebWaitMessage.Picture.LoadFromFile('uDeviceEdit.WebWaitMessage.Picture.gif');
    MyMessageDlg.SetParentComponent(Self);
    MyMessageDlg.Name := 'MyMessageDlg';
    MyMessageDlg.Left := 246;
    MyMessageDlg.Top := 58;
    MyMessageDlg.Width := 24;
    MyMessageDlg.Height := 24;
    MyMessageDlg.Buttons := [];
    MyMessageDlg.Opacity := 0.200000000000000000;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 50;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 185;
    ShowTimer.Top := 98;
  finally
    FormPanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    CaptionLabel.AfterLoadDFMValues;
    CloseImage.AfterLoadDFMValues;
    ContentPanel.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    DevicePKeyLabel.AfterLoadDFMValues;
    SerialNumberLabel.AfterLoadDFMValues;
    SimcardNumberLabel.AfterLoadDFMValues;
    PhoneNumberLabel.AfterLoadDFMValues;
    DeviceNoLabel.AfterLoadDFMValues;
    BluetoothIDLabel.AfterLoadDFMValues;
    DeviceModelLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    DevicePKeyEdit.AfterLoadDFMValues;
    SerialNumberEdit.AfterLoadDFMValues;
    SimcardNumberEdit.AfterLoadDFMValues;
    PhoneNumberEdit.AfterLoadDFMValues;
    DeviceModelEdit.AfterLoadDFMValues;
    DeviceNoEdit.AfterLoadDFMValues;
    BluetoothIDEdit.AfterLoadDFMValues;
    SMSButton.AfterLoadDFMValues;
    OperatorEdit.AfterLoadDFMValues;
    GridDatabaseAdapter.AfterLoadDFMValues;
    StatusBarPanel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    ReactivateButton.AfterLoadDFMValues;
    WebWaitMessage.AfterLoadDFMValues;
    MyMessageDlg.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
  end;
end;

end.